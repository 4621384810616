import { EditFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { Button } from '../../../components';
import { companyTypes } from '../../../utils/lists';
import { CompanyTypeRenderer } from './companyTypeRenderer';

export function columns(
  onSelectItem: (record: any) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title: <div className="show-amount">Empresa</div>,
      dataIndex: 'name',
      sorter: {
        compare: (a: any, b: any) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      defaultSortOrder: 'ascend' as const,
      showSorterTooltip: false,
    },
    {
      title: 'Tipo da Empresa',
      dataIndex: 'company_type',
      sorter: {
        compare: (a: any, b: any) =>
          a.company_type.localeCompare(b.company_type),
        multiple: 1,
      },
      showSorterTooltip: false,
      render: (_, record: any) => {
        return record.company_type.map((companyType: any) => {
          const companyTypeName = companyTypes.find(
            (item) => item.id === companyType
          )?.name;
          return (
            <CompanyTypeRenderer
              key={companyTypeName}
              companyType={{
                value: companyType,
                name: companyTypeName,
              }}
            />
          );
        });
      },
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: {
        compare: (a: any, b: any) => a.email?.localeCompare(b.email),
        multiple: 1,
      },
      showSorterTooltip: false,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      sorter: {
        compare: (a: any, b: any) =>
          a.phone_number?.localeCompare(b.phone_number),
        multiple: 1,
      },
      showSorterTooltip: false,
    },
    {
      title: 'CNPJ/Código Int.',
      dataIndex: 'cnpj',
      sorter: {
        compare: (a: any, b: any) => a.cnpj?.localeCompare(b.cnpj),
        multiple: 1,
      },
      showSorterTooltip: false,
      render: (_, record: any) => {
        return record.cnpj || record.tin;
      },
    },
    {
      dataIndex: 'edit',
      align: 'center',
      width: '10%',
      render: (_, record: any) => {
        return (
          <Button
            icon={<EditFilled twoToneColor="#09D4AB" />}
            onClick={() => onSelectItem(record)}
          />
        );
      },
    },
  ];
}
