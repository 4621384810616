import moment from 'moment';

import { NotificationsType, NotificationType } from '../../../types';
import { createDateStringPtBr } from '../../../utils/utils';

export function createNotificationDataByPeriod(
  items: NotificationType[]
): NotificationsType {
  const notificationsByPeriods: NotificationsType = {} as NotificationsType;

  items.forEach((item) => {
    const itemToAdd = {
      ...item,
      formatted_created_at: createDateStringPtBr(item.created_at) || '',
    };
    if (moment(item.created_at) > moment().subtract(7, 'days')) {
      if (notificationsByPeriods.THIS_WEEK === undefined) {
        notificationsByPeriods.THIS_WEEK = {
          name: 'ESSA SEMANA',
          data: [],
        };
      }
      notificationsByPeriods.THIS_WEEK.data.push(itemToAdd);
    } else if (moment(item.created_at) > moment().startOf('month')) {
      if (notificationsByPeriods.THIS_MONTH === undefined) {
        notificationsByPeriods.THIS_MONTH = {
          name: 'ESSE MÊS',
          data: [],
        };
      }
      notificationsByPeriods.THIS_MONTH.data.push(itemToAdd);
    } else if (moment(item.created_at) > moment().startOf('year')) {
      if (notificationsByPeriods.BEFORE_MONTHS === undefined) {
        notificationsByPeriods.BEFORE_MONTHS = {
          name: 'MESES ANTERIORES',
          data: [],
        };
      }
      notificationsByPeriods.BEFORE_MONTHS.data.push(itemToAdd);
    } else if (
      moment(item.created_at) > moment().subtract(1, 'year').startOf('year')
    ) {
      if (notificationsByPeriods.LAST_YEAR === undefined) {
        notificationsByPeriods.LAST_YEAR = {
          name: 'ÚLTIMO ANO',
          data: [],
        };
      }
      notificationsByPeriods.LAST_YEAR.data.push(itemToAdd);
    }
  });
  return notificationsByPeriods;
}
