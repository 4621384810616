import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormItemInput } from '../../components';
import { Button } from '../../components/antd/Button';
import { LayoutLogin } from '../../components/common/layoutLogin/LayoutLogin';
import { useResetPasswordMutation } from '../../services/authApi';
import { Form, Title, Footer, ButtonBackLogin } from './styles';

export default function ForgotMyPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [send, setSend] = useState(false);

  const [resetPassword, { isLoading, isError, isSuccess }] =
    useResetPasswordMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setSend(true);
    }
  }, [isSuccess, isError]);

  async function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      await resetPassword(values.email);
    });
  }

  return (
    <LayoutLogin>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
        requiredMark="optional"
      >
        <Title>{send ? 'Verifique seu email' : 'Esqueci minha senha'}</Title>
        {send ? (
          <span>
            Enviamos um link de redefinição de senha para seu email (caso exista
            cadastrado no sistema), confira a caixa de entrada do email
            informado.
          </span>
        ) : (
          <FormItemInput
            label="Email"
            name="email"
            type="email"
            required
            readOnly={isLoading}
            placeholder="insira seu email"
          />
        )}
        <Footer>
          <ButtonBackLogin onClick={() => navigate(-1)}>
            <ArrowLeftOutlined /> voltar para o login
          </ButtonBackLogin>
          {!send && (
            <Button
              style={{ width: '106px', height: '40px' }}
              type="primary"
              htmlType="submit"
              block
              icon={!isLoading && <ArrowRightOutlined />}
            >
              {isLoading ? <LoadingOutlined /> : 'Enviar'}
            </Button>
          )}
        </Footer>
      </Form>
    </LayoutLogin>
  );
}
