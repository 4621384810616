import { ArrowLeftOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { Button } from '../../components/antd/Button';
import { Drawer } from '../../components/antd/Drawer';
import { Docking, Stopover, Vessel as VesselType } from '../../types';
import { DockingForm } from './dockingForm';

type DockingDrawerProps = {
  width: string | number;
  selectedDocking?: Docking;
  selectedVessel?: VesselType;
  setSelectedDocking?: (value: any) => void;
  currentDockingKey: any;
  setCurrentDockingKey: (value: any) => void;
  stopoverForm?: FormInstance;
  selectedStopover?: Stopover;
  setSelectedStopover?: (value: any) => void;
  allowedBerth: Array<any>;
  visible: boolean;
  setIsVisible: (value: boolean) => void;
  onBack: () => void;
  onAddDocking?: (defaultValue?: any, insertIndex?: number | undefined) => void;
  onRemoveDocking?: (index: number | number[]) => void;
  isDockingWindowContext?: boolean;
  onSaveUpdate?: (dockingId: number, updateObj: any) => void;
  screen: 'STOPOVER' | 'AGENT_AREA';
  openCollapsePanels: string | string[];
  setOpenCollapsePanels: Dispatch<SetStateAction<string | string[]>>;
};

export function DockingDrawer(props: DockingDrawerProps) {
  const { screen, openCollapsePanels, setOpenCollapsePanels } = props;
  return (
    <Drawer
      visible={props.visible}
      width={props.width}
      closeIcon={
        <Button type="text" icon={<ArrowLeftOutlined />}>
          Voltar
        </Button>
      }
      onClose={!props.isDockingWindowContext ? () => props.onBack() : () => {}}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <DockingForm
        stopoverForm={props.stopoverForm}
        name="docking_form"
        addDocking={props.onAddDocking}
        removeDocking={props.onRemoveDocking}
        isVisibleForm={props.visible}
        setIsVisibleForm={props.setIsVisible}
        selectedDocking={props.selectedDocking}
        setSelectedDocking={props.setSelectedDocking}
        selectedStopover={props.selectedStopover}
        setSelectedStopover={props.setSelectedStopover}
        selectedVessel={props.selectedVessel}
        allowedBerth={props.allowedBerth}
        currentDockingKey={props.currentDockingKey}
        setCurrentDockingKey={props.setCurrentDockingKey}
        isDockingWindowContext={props.isDockingWindowContext}
        onBack={props.onBack}
        onSaveUpdate={props.onSaveUpdate}
        screen={screen}
        openCollapsePanels={openCollapsePanels}
        setOpenCollapsePanels={setOpenCollapsePanels}
      />
    </Drawer>
  );
}
