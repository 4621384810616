import { Dispatch, SetStateAction, useState } from 'react';

import { Drawer } from '../../../components';
import { Stopover, StoppageType } from '../../../types';
import { WorkShiftStoppagesEditForm } from './workShiftStoppagesEditForm';

type WorkShiftStoppagesEditDrawerProps = {
  visible: boolean;
  onBack: () => void;
  setIsVisibleForm: (value: boolean) => void;
  selectedStopover: Stopover;
  setSelectedStopover: Dispatch<SetStateAction<Stopover>>;
  selectedStoppage?: StoppageType;
  setSelectedStoppage: Dispatch<SetStateAction<StoppageType>>;
};

export function WorkShiftStoppagesEditDrawer(
  props: WorkShiftStoppagesEditDrawerProps
) {
  const {
    visible,
    onBack,
    setIsVisibleForm,
    selectedStopover,
    setSelectedStopover,
    selectedStoppage,
    setSelectedStoppage,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  return (
    <Drawer
      visible={visible}
      width={690}
      onClose={() => onBack()}
      closable={false}
      maskClosable={false}
    >
      <WorkShiftStoppagesEditForm
        onBack={onBack}
        setIsVisibleForm={setIsVisibleForm}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        selectedStoppage={selectedStoppage}
        setSelectedStoppage={setSelectedStoppage}
      />
    </Drawer>
  );
}
