import 'react-calendar-timeline/lib/Timeline.css';
import {
  ArrowRightOutlined,
  EditFilled,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import 'moment/locale/pt-br';
import {
  ReactCalendarGroupRendererProps,
  TimelineGroupBase,
} from 'react-calendar-timeline';
import { useLocation } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  Button,
  Descriptions,
  Form,
  FormItemInput,
  FormatItem,
  Tag,
} from '../../components';
import { useGetPumpingsQuery } from '../../services/pumpingApi';
import {
  BerthMaintenance as BerthMaintenanceType,
  CompanyType,
  Product as ProductType,
  PumpingType,
  ShipType,
  Stopover as StopoverType,
} from '../../types';
import {
  berthRowHeight,
  createDateStringPtBr,
  formatNumberToLocale,
  itemRowHeight,
  returnDiffDaysInHours,
} from '../../utils/utils';
import { StatusButton } from '../anchoringWindow/statusButton';
import { TimelineWindow } from '../timelineWindow/TimelineWindow';
import { ConfirmPumpingFormDrawer } from './ConfirmPumpingFormDrawer';
import { PumpingFormDrawer } from './PumpingFormDrawer';

const itemStatusBackground = [
  { key: 'FORESEEN', value: ' foreseen-origin ', type: 'PUMPING_ORIGIN' },
  {
    key: 'FORESEEN',
    value: ' foreseen-destination ',
    type: 'PUMPING_DESTINATION',
  },
  { key: 'IN_PROGRESS', value: ' in-progress-origin ', type: 'PUMPING_ORIGIN' },
  {
    key: 'IN_PROGRESS',
    value: ' in-progress-destination ',
    type: 'PUMPING_DESTINATION',
  },
  { key: 'REALIZED', value: ' realized-origin ', type: 'PUMPING_ORIGIN' },
  {
    key: 'REALIZED',
    value: ' realized-destination ',
    type: 'PUMPING_DESTINATION',
  },
];

type GroupType = {
  id: string;
  height: number;
  title: ReactNode;
  type: string;
  name: string;
  dwt?: number;
  docking_place_type?: string;
  ship_types?: ShipType[];
};

export type ItemType = {
  canMove?: boolean;
  canResize?: boolean;
  canEdit?: boolean;
  index?: number;
  code?: string;
  expected_start?: string | null | Moment;
  expected_finish?: string | null | Moment;
  group: string;
  name?: string;
  title: string;
  subtitle?: string;
  hover?: string;
  start_time: number;
  end_time: number;
  docking_place?: { tag: string; name: string; place_type: string };
  stopover?: StopoverType;
  id: number;
  products?: ProductType[];
  type: 'DOCKING' | 'MAINTENANCE' | 'PUMPING_ORIGIN' | 'PUMPING_DESTINATION';
  input_dwt?: number;
  docking_in_line?: string;
  docking_alongside?: string;
  // campos de manutenção
  companies?: string;
  maintenanceId?: number;
  maintenance?: BerthMaintenanceType;
  lineName?: string;
  berthLineName?: string;
  pumping?: PumpingType;
  status?: string;
};

function popoverContent(
  item: ItemType,
  openPumpingDrawer: (item: PumpingType) => void
) {
  const pumpingDuration = () => {
    if (!item.pumping?.expected_start || !item.pumping?.expected_finish) {
      return 'Bombeio não finalizado';
    }
    return returnDiffDaysInHours(
      item.pumping?.expected_start as string,
      item.pumping?.expected_finish as string
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <span>{item.title}</span>
        <Button
          icon={<EditFilled />}
          onClick={() => openPumpingDrawer(item.pumping || ({} as PumpingType))}
          disabled={!item.canEdit}
        >
          Editar
        </Button>
      </div>
      <Descriptions
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        column={2}
      >
        <Descriptions.Item label="De">
          <FormatItem>
            {item.pumping?.origin_terminal?.name}
            {item.pumping?.origin_line &&
              `(
            ${item.pumping?.origin_line?.name})`}
          </FormatItem>
        </Descriptions.Item>
        <Descriptions.Item label="Para">
          <FormatItem>
            {item.pumping?.destination_terminal?.name}
            {item.pumping?.destination_line?.name &&
              `(
            ${item.pumping?.destination_line?.name})`}
          </FormatItem>
        </Descriptions.Item>
        <Descriptions.Item label="Previsão" span={2}>
          <div>
            <FormatItem>
              {createDateStringPtBr(item.pumping?.expected_start)}
            </FormatItem>
            <span> até </span>
            <FormatItem>
              {createDateStringPtBr(item.pumping?.expected_finish)}
            </FormatItem>
            <br />
            <i>({pumpingDuration()} de duração)</i>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="Berço" span={2}>
          <FormatItem>{item.pumping?.origin_line?.berth}</FormatItem>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

function ItemRenderer(props: {
  itemProps: any;
  itemsToRender: ItemType[];
  entries: {
    openPumpingDrawer: (item: PumpingType) => void;
  };
}) {
  const { itemProps, itemsToRender, entries } = props;
  const { openPumpingDrawer } = entries;

  const { left: leftResizeProps, right: rightResizeProps } =
    itemProps.getResizeProps();
  // caso seja paralisação sempre será no inicio da linha do berço

  const groupItems = itemsToRender.filter(
    (item) => item.group === itemProps.item.group
  );

  const itemTopPosition = groupItems.findIndex(
    (item) => item.id === itemProps.item.id
  );
  const itemTop =
    parseInt(itemProps.getItemProps(itemProps.item.itemProps).style.top, 10) +
    itemTopPosition * berthRowHeight;

  const topItem = itemProps.itemContext.dragging
    ? itemProps.getItemProps(itemProps.item.itemProps).style.top
    : itemTop;

  const heightItem = `${itemRowHeight}px`;

  const maxWidthContentName = `${
    parseInt(itemProps.getItemProps(itemProps.item.itemProps).style.width, 10) -
    6
  }px`;

  const className = itemStatusBackground.find(
    (item) =>
      item.key === itemProps.item.status && item.type === itemProps.item.type
  )?.value;

  return (
    <div
      {...itemProps.getItemProps(itemProps.item.itemProps)}
      style={Object.assign(
        itemProps.getItemProps(itemProps.item.itemProps).style,
        {
          top: topItem,
          height: heightItem,
        }
      )}
      className={`rct-item ${className}`}
    >
      {itemProps.itemContext.useResizeHandle ? (
        <div {...leftResizeProps} />
      ) : (
        ''
      )}

      <div
        className="rct-item-content"
        style={{
          height: heightItem,
          maxHeight: `${itemProps.itemContext.dimensions.height}`,
        }}
      >
        <Popover
          overlayStyle={{ width: '400px', zIndex: 99 }}
          placement="bottomRight"
          trigger="click"
          content={popoverContent(itemProps.item, openPumpingDrawer)}
        >
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {itemProps.item.type === 'PUMPING_DESTINATION' && (
              <ArrowRightOutlined />
            )}
            <span style={{ fontWeight: '600' }}>{itemProps.item.title}</span>
            {itemProps.item.type === 'PUMPING_ORIGIN' && <ArrowRightOutlined />}
          </div>
        </Popover>
      </div>

      {itemProps.itemContext.useResizeHandle ? (
        <div {...rightResizeProps} />
      ) : (
        ''
      )}
    </div>
  );
}

export function PumpWindow() {
  const location = useLocation();
  const pumpingIdToConfirm = new URLSearchParams(location.search).get('id');
  const { company: companyUser, isSuperuser } = useAppSelector(
    (state: RootState) => state.user.user
  );
  // initial and final time used to render calendar
  const [timeStartEnd, setTimeStartEnd] = useState({
    start: moment().set({ hour: 0, minute: 0, second: 0 }),
    end: moment().set({ hour: 0, minute: 0, second: 0 }).add(48, 'hour'),
  });
  // time used to render month and year
  const [timeRendering, setTimeRendering] = useState(moment().toDate());
  // initial and final time used to query
  const [queryStartEndTime, setQueryStartEndTime] = useState({
    start: moment().set({ hour: 0, minute: 0, second: 0 }).subtract(30, 'day'),
    end: moment().set({ hour: 0, minute: 0, second: 0 }).add(30, 'day'),
  });
  const [productName, setProductName] = useState('');
  // array with selected docking id, used to know where to render the popover, will always have a single item
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);

  const [filterNameOrImo, setFilterNameOrImo] = useState('');

  const [form] = Form.useForm();

  const {
    data: pumpingsData,
    isLoading,
    isSuccess,
  } = useGetPumpingsQuery({
    initial_expected_start: queryStartEndTime.start.format('YYYY-MM-DD'),
    final_expected_start: queryStartEndTime.end.format('YYYY-MM-DD'),
    product_or_berth_or_terminal: productName,
  });

  // states with items and groups to be rendered
  const [itemsToRender, setItemsToRender] = useState<ItemType[]>([]);
  const [groupsToRender, setGroupsToRender] = useState<GroupType[]>([]);

  const [isVisiblePumpingDrawer, setIsVisiblePumpingDrawer] = useState(false);
  const [selectedPumping, setSelectedPumping] = useState({} as PumpingType);

  // format groups
  function formatGroups(pumpings: PumpingType[]): GroupType[] {
    const companiesGroup: CompanyType[] = [];
    pumpings.forEach((pumping) => {
      if (
        (isSuperuser || companyUser?.id === pumping.origin_terminal.id) &&
        !companiesGroup.some((obj) => obj.id === pumping.origin_terminal.id)
      ) {
        companiesGroup.push(pumping.origin_terminal);
      }

      if (
        (isSuperuser || companyUser?.id === pumping.destination_terminal.id) &&
        pumping.is_confirmed &&
        !companiesGroup.some(
          (obj) => obj.id === pumping.destination_terminal.id
        )
      ) {
        companiesGroup.push(pumping.destination_terminal);
      }
    });
    return companiesGroup
      .sort((a, b) => a.id - b.id)
      .map((company) => ({
        id: String(company.id),
        title: company.name,
        name: company.name,
        height:
          itemsToRender.filter((item) => item.group === String(company.id))
            .length * 45,
        type: 'COMPANY',
      }));
  }

  function formatPumpingsItems(pumpings: PumpingType[]): ItemType[] {
    const items: ItemType[] = [];
    pumpings.forEach((pumping) => {
      const item = {
        id: pumping.id || 0,
        name: `${pumping.origin_line.name} - ${pumping.origin_line.berth}`,
        canMove: false,
        canResize: false,
        canEdit: isSuperuser || companyUser?.id === pumping.origin_terminal.id,
        subtitle: `${pumping.origin_line.name} - ${pumping.origin_line.berth}`,
        title: `${pumping.product.name} (${formatNumberToLocale(
          pumping.volume
        )} m³)`,
        start_time: moment(pumping.expected_start).valueOf(),
        end_time: moment(pumping.expected_finish).valueOf(),
        lineName: pumping.origin_line.name,
        berthLineName: pumping.origin_line.berth,
        status: pumping.status,
        pumping,
      };
      if (isSuperuser || companyUser?.id === pumping.origin_terminal.id) {
        items.push({
          ...item,
          type: 'PUMPING_ORIGIN',
          group: String(pumping.origin_terminal.id),
        });
      }

      if (
        (isSuperuser || companyUser?.id === pumping.destination_terminal.id) &&
        pumping.is_confirmed
      ) {
        items.push({
          ...item,
          type: 'PUMPING_DESTINATION',
          group: String(pumping.destination_terminal.id),
          id: -item.id,
        });
      }
    });
    return items;
  }

  // format items and groups to be rendered when these data change
  useEffect(() => {
    if (pumpingsData?.results) {
      setItemsToRender(formatPumpingsItems(pumpingsData?.results || []));
      setGroupsToRender(formatGroups(pumpingsData?.results || []));
    }
  }, [pumpingsData?.results]);
  // needs to call format groups when itemsToRender changes so it can resize group's height
  useEffect(() => {
    if (pumpingsData?.results) {
      setGroupsToRender(formatGroups(pumpingsData?.results || []));
    }
  }, [itemsToRender]);

  function onClickGroupItem(item: ItemType) {
    setTimeStartEnd({
      start: moment(item.start_time),
      end: moment(item.start_time).add(36, 'hour'),
    });
    setTimeRendering(new Date(item.start_time));
  }

  // called when tries to resize an item, validate if berth is occupied in the selected hour
  function itemResize(itemId: number, time: number, edge: string) {}

  // called when tries to move an item, validate if berth is occupied in the move hour
  function itemMove(itemId: any, dragTime: number, groupOrder: number) {}
  // actually move an item
  async function moveItem(
    itemId: number,
    newTimeStart: Moment,
    newTimeEnd: Moment,
    newGroup: GroupType,
    placeType: string
  ) {}
  // update selected items array
  function onItemSelect(itemId: number) {}

  function onItemDeselect() {
    setSelectedItems([]);
  }

  // called when callendar bounds change
  function onBoundsChange(canvasTimeStart: number, canvasTimeEnd: number) {
    setQueryStartEndTime({
      start: moment(canvasTimeStart).subtract(15, 'day'),
      end: moment(canvasTimeEnd).add(15, 'day'),
    });
  }

  function handleSearchPumpingByProductName(product: string) {
    setProductName(product);
    if (product === '') {
      setTimeStartEnd({
        start: moment().set({ hour: 0, minute: 0, second: 0 }),
        end: moment().set({ hour: 0, minute: 0, second: 0 }).add(36, 'hour'),
      });
      setTimeRendering(new Date());
    }
  }

  function sidebarButtonAction() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '400px',
          background: 'rgb(223 234 241)',
        }}
      >
        <div style={{ display: 'flex', gap: '10px', marginRight: '16px' }}>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => {
              setIsVisiblePumpingDrawer(true);
            }}
            icon={<PlusOutlined />}
          >
            Novo bombeio
          </Button>

          <Form form={form}>
            <FormItemInput
              name="search"
              placeholder="Pesquisar"
              onChange={debounce(
                (e) => handleSearchPumpingByProductName(e.target.value),
                1000
              )}
              prefix={
                <SearchOutlined
                  style={{ color: '#09d4ab', fontSize: '20px' }}
                />
              }
              size="large"
            />
          </Form>
        </div>
        <div className="sidebar-content">
          <span className="sidebar-column">TERMINAL</span>
          <span className="sidebar-column">LINHA</span>
        </div>
      </div>
    );
  }

  function onClickLineNameSideColumn(item: ItemType) {
    setTimeStartEnd({
      start: moment(item.start_time),
      end: moment(item.start_time).add(36, 'hour'),
    });
    setTimeRendering(new Date(item.start_time));
  }

  function groupRenderer(
    groupProps: ReactCalendarGroupRendererProps<TimelineGroupBase>,
    itemsToRender: ItemType[]
  ) {
    const terminalLines = itemsToRender.filter(
      (item) => item.group === groupProps.group.id
    );
    return (
      <div className="custom-group">
        <span className="title">{groupProps.group.title}</span>
        <div className="vessel-name-list">
          {terminalLines.map((item) => {
            let classNameBerth = '';
            if (item.berthLineName) {
              classNameBerth =
                {
                  '01.PGL1A': 'pgl-1',
                  '01.PGL1B': 'pgl-1',
                  '01.PGL2A': 'pgl-2',
                  '01.PGL2B': 'pgl-2',
                  '01.PGL3A': 'pgl-3a',
                  '01.PGL3B': 'pgl-3b',
                  '01.CAIS1': 'berth-1',
                  '01.CAIS2': 'berth-2',
                  '01.CAIS3': 'berth-3',
                  '01.CAIS4': 'berth-4',
                  '01.CAIS5': 'berth-5',
                  '01.CMUA': 'berth-6',
                  '01.CMUB': 'berth-6',
                  '01.EAS1S': 'berth-7',
                  '01.EAS2S': 'berth-7',
                }[item.berthLineName] || '';
            }
            return (
              <div
                key={item.id}
                className="vessel-name"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0 16px',
                  borderBottom: '1px solid var(--neutral_light)',
                }}
                onClick={() => onClickLineNameSideColumn(item)}
              >
                <span>{item.lineName}</span>
                <Tag className={`pumping-berth ${classNameBerth}`}>
                  {item.berthLineName?.substring(3)}
                </Tag>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function statusButtonsCol() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <StatusButton status="foreseen">
          <span style={{ fontSize: '14px' }}>
            PREVISTO (
            {
              itemsToRender.filter(
                (item) =>
                  item.status === 'FORESEEN' && item.type === 'PUMPING_ORIGIN'
              ).length
            }
            )
          </span>
        </StatusButton>
        <StatusButton status="in-progress">
          <span style={{ fontSize: '14px' }}>
            EM ANDAMENTO (
            {
              itemsToRender.filter(
                (item) =>
                  item.status === 'IN_PROGRESS' &&
                  item.type === 'PUMPING_ORIGIN'
              ).length
            }
            )
          </span>
        </StatusButton>
        <StatusButton status="realized">
          <span style={{ fontSize: '14px' }}>
            REALIZADO (
            {
              itemsToRender.filter(
                (item) =>
                  item.status === 'REALIZED' && item.type === 'PUMPING_ORIGIN'
              ).length
            }
            )
          </span>
        </StatusButton>
      </div>
    );
  }

  function openPumpingDrawer(item: PumpingType) {
    setSelectedPumping({
      ...item,
      expected_start: item.expected_start ? moment(item.expected_start) : '',
      expected_finish: item.expected_finish ? moment(item.expected_finish) : '',
    });
    setIsVisiblePumpingDrawer(true);
  }

  return (
    <>
      <PumpingFormDrawer
        isVisible={isVisiblePumpingDrawer}
        setIsVisible={setIsVisiblePumpingDrawer}
        pumping={selectedPumping}
        setSelectedPumping={setSelectedPumping}
      />
      <ConfirmPumpingFormDrawer pumpingId={Number(pumpingIdToConfirm)} />
      <TimelineWindow
        isLoadingData={isLoading}
        isSuccessData={isSuccess}
        onItemSelect={onItemSelect}
        onItemDeselect={onItemDeselect}
        ItemRenderer={ItemRenderer}
        sidebar={sidebarButtonAction}
        groupsToRender={groupsToRender}
        itemsToRender={itemsToRender}
        itemMove={itemMove}
        itemResize={itemResize}
        selectedItems={selectedItems}
        updateButton={
          <Button
            icon={<ReloadOutlined />}
            type="text"
            style={{ color: 'black', marginLeft: '10px' }}
            size="large"
            onClick={() => {}}
          >
            Atualizar
          </Button>
        }
        onBoundsChange={onBoundsChange}
        groupRenderer={groupRenderer}
        onClickGroupItem={onClickGroupItem}
        title="Janela de bombeios"
        subtitle={companyUser?.name}
        StatusButtonsCol={statusButtonsCol}
        timeStartEnd={timeStartEnd}
        setTimeStartEnd={setTimeStartEnd}
        timeRendering={timeRendering}
        setTimeRendering={setTimeRendering}
        setQueryStartEndTime={setQueryStartEndTime}
        queryStartEndTime={queryStartEndTime}
        rangeHour={1}
        propsItemRender={{ openPumpingDrawer }}
      />
    </>
  );
}
