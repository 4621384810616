import { Tabs as ANTDTabs } from 'antd';
import styled from 'styled-components';

type TabsProps = {
  type?: string;
  withshadowheader?: string;
  nobackground?: string;
};

export const Tabs = styled(ANTDTabs)<TabsProps>`
  ${(props) =>
    props.type === 'card'
      ? `
        &.ant-tabs-top > .ant-tabs-nav::before{
            border-bottom: 0px;
        }
        & .ant-tabs-nav-operations{
            background: var(--neutral_light);
            border-radius: 0 4px 4px 0;
            margin-left: -3px;
            border-bottom: 0px;
            & .ant-tabs-nav-more{
                cursor: pointer;
            }
        }
        &.space-between .ant-tabs-nav-list { 
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
        & .ant-tabs-tab .ant-tabs-tab-btn{
            padding: 6px 10px 6px 10px;
            border-radius: 4px !important;
        }
        & .ant-tabs-tab {
            border: 0px !important;
            padding: 6px 1px !important;
            margin-left: 0px !important;
            background: var(--neutral_light) !important;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.85);
        }
        & .ant-tabs-nav-wrap{
            background: var(--neutral_light);
            border-radius: 4px;
        }
        &  .ant-tabs-nav .ant-tabs-tab {
            border-radius: 4px !important;
        }
        & .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn{
            background: var(--neutral_lightest) !important;
        }
        & .ant-tabs-tab-btn:hover{
            color: rgba(0, 0, 0, 0.85)
        }
        & .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
        }
        @media only screen and (min-width: 1600px) { 
            & .ant-tabs-tab {
                font-size: 14px;
            }
        }
        `
      : `
        & .ant-tabs-nav {
            &::before {
                border-bottom: 1px solid var(--neutral_lighter);
            }
        }
        & .ant-tabs-ink-bar {
            height: 4px !important;
        }
        & .ant-tabs-tab {
            font-size: 16px;
            color: var(--neutral_light);
    
            &.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #000
            }
        }
        `}

  & .ant-tabs-nav {
    margin: 0;
  }

  & .ant-tabs-nav-list {
    margin-left: 5px;
  }

  ${(props) =>
    props.withshadowheader === 'true' &&
    `& .ant-tabs-nav-wrap {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    padding-left: 10px;`}

  ${(props) =>
    props.nobackground === 'true' && `background: var(--neutral_lightest);`}
`;

export const CardTab = styled(ANTDTabs)``;

export const TabPane = styled(ANTDTabs.TabPane)``;
