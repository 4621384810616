import { Col, InputRef } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { ChangeEvent, ReactNode } from 'react';

import { FormItem } from '../Form';
import { Input } from '../Input';

type FormItemInputProps = {
  label?: string;
  name?: NamePath;
  required?: boolean;
  type?: 'email' | 'password';
  placeholder?: string;
  maxLength?: number;
  colSpan?: number;
  rules?: Rule[];
  suffix?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  style?: React.CSSProperties;
  requiredMessage?: string;
  disabled?: boolean;
  ref?: React.Ref<InputRef> | undefined;
  showCount?: boolean;
  className?: string;
  validateStatus?: 'success' | 'warning' | 'error' | 'validating' | '';
  prefix?: ReactNode;
  size?: SizeType;
  value?: string;
};

export function FormItemInput(props: FormItemInputProps) {
  const {
    label,
    name,
    type,
    required,
    placeholder,
    maxLength,
    colSpan,
    rules,
    suffix,
    onChange,
    readOnly,
    style,
    requiredMessage,
    disabled,
    ref,
    showCount,
    className,
    validateStatus,
    prefix,
    size,
    value,
  } = props;

  const getRules = () => {
    const rulesSelected: Rule[] = [];

    rulesSelected.push({
      required,
      message: requiredMessage || 'Campo obrigatório',
    });
    if (type === 'email') {
      rulesSelected.push({
        pattern: /^[a-z\d._-]+@([\d]*[a-zA-Z]+|[a-zA-Z]+[\d]*)+\.[a-z]/,
        message: 'Informe um E-mail válido',
      });
    }

    if (type === 'password') {
      rulesSelected.push({
        min: 8,
        message: 'A senha deve conter no mínimo 8 caracteres',
      });
    }

    return rulesSelected;
  };

  return (
    <Col span={colSpan || 24}>
      <FormItem
        label={label}
        name={name}
        rules={rules || getRules()}
        style={style || {}}
        validateStatus={validateStatus}
      >
        <Input
          readOnly={readOnly}
          disabled={disabled}
          type={type || 'text'}
          placeholder={placeholder}
          maxLength={maxLength || 100}
          onChange={onChange}
          suffix={suffix}
          ref={ref}
          showCount={showCount}
          className={className}
          prefix={prefix}
          size={size || 'middle'}
          value={value}
        />
      </FormItem>
    </Col>
  );
}
