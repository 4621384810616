import { useMemo } from 'react';

import {
  Descriptions,
  Drawer,
  FormatItem,
  GenericDrawerHeader,
} from '../../../../components';
import { AllTableItemsType, ChargeType } from '../../../../typesFinancial';
import { chargeTypeList } from '../../../../utils/lists';
import {
  createDateStringPtBr,
  formatNumberToLocale,
} from '../../../../utils/utils';
import { ApportmentTable } from './tables/ApportmentTable';
import { DiscountItemsTable } from './tables/DiscountItemsTable';

type ItemTableViewDrawerProps = {
  isVisible: boolean;
  selectedItem: AllTableItemsType;
  selectedTable: number;
  selectedCharge: ChargeType;
  onClose: () => void;
};

const { Item } = Descriptions;

export function ItemTableViewDrawer(props: ItemTableViewDrawerProps) {
  const { isVisible, selectedItem, selectedTable, selectedCharge, onClose } =
    props;

  const dockingTitle = useMemo(() => {
    if (selectedCharge.charge_type === 'DOCKING') {
      return `${selectedCharge.docking?.code} - ${selectedCharge.stopover?.vessel_name}`;
    }
    return null;
  }, [selectedItem]);

  function getTitle() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ fontWeight: 400, color: 'var(--neutral_light)' }}>
          Tabela {selectedTable}
        </span>
        <strong style={{ fontSize: '16px' }}>{selectedItem.name}</strong>
      </div>
    );
  }

  const totalAmount = () => {
    if (selectedTable === 1 && selectedItem.dwt) {
      return selectedItem.dwt;
    }
    if (selectedTable === 3 && selectedItem.movimented_cargo) {
      return selectedItem.movimented_cargo;
    }
    return 0;
  };

  return (
    <Drawer
      visible={isVisible}
      placement="right"
      width={690}
      closable={false}
      destroyOnClose
      onClose={() => onClose()}
    >
      <GenericDrawerHeader
        title={getTitle()}
        showBackButton
        onBack={() => onClose()}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBlockStart: '24px',
          marginInline: '16px',
        }}
      >
        <Descriptions column={2}>
          <Item label="Tipo de faturamento">
            <FormatItem>
              {chargeTypeList.find(
                (type) => type.id === selectedCharge.charge_type
              )?.name || null}
            </FormatItem>
          </Item>
          <Item label="Atracação">
            <FormatItem>{dockingTitle}</FormatItem>
          </Item>
          {[1, 2].includes(selectedTable) && (
            <Item label="Navegação">
              <FormatItem>
                {selectedItem.navigation_type?.description}
              </FormatItem>
            </Item>
          )}
          {selectedTable === 1 && (
            <Item label="Grupo de produtos">
              <FormatItem>
                {selectedItem.product_group
                  ?.map((pg) => (typeof pg === 'number' ? pg : pg.name))
                  .join(', ')}
              </FormatItem>
            </Item>
          )}
          {selectedTable === 1 && (
            <>
              <Item label="TPB/DWT">
                <FormatItem suffix="ton">{selectedItem.dwt}</FormatItem>
              </Item>
              <Item label="Tipo de operação">
                <FormatItem>
                  {selectedItem.operation_type?.description}
                </FormatItem>
              </Item>
            </>
          )}
          {selectedTable === 2 && (
            <>
              <Item label="LOA">
                <FormatItem suffix="m">{selectedItem.loa}</FormatItem>
              </Item>
              <Item label="Local de atracação">
                <FormatItem>{selectedItem.docking_place?.name}</FormatItem>
              </Item>
              <Item label="Fração de minutos após o limite">
                <FormatItem suffix="min">
                  {selectedItem.fraction_of_minutes_after_limit}
                </FormatItem>
              </Item>
              <Item label="Fração de minutos antes do limite">
                <FormatItem suffix="min">
                  {selectedItem.fraction_of_minutes_before_limit}
                </FormatItem>
              </Item>
            </>
          )}
          {[3, 5].includes(selectedTable) && (
            <Item label="Tipo de mercadoria">
              <FormatItem>{selectedItem.cargo_type?.name}</FormatItem>
            </Item>
          )}
          {selectedTable === 3 && (
            <Item label="Carga movimentada">
              <FormatItem suffix="ton">
                {selectedItem.movimented_cargo}
              </FormatItem>
            </Item>
          )}
          {selectedTable === 5 && (
            <>
              <Item label="Quantidade">
                <FormatItem suffix="un">{selectedItem.quantity}</FormatItem>
              </Item>
              <Item label="Início de armazenamento">
                <FormatItem suffix="un">
                  {createDateStringPtBr(selectedItem.cargo_arrival_date)}
                </FormatItem>
              </Item>
              <Item label="Fim do armazenamento">
                <FormatItem suffix="un">
                  {createDateStringPtBr(selectedItem.cargo_departure_date)}
                </FormatItem>
              </Item>
            </>
          )}
          {selectedTable === 9 && (
            <>
              <Item label="Tipo de complemento">
                <FormatItem suffix="ton">
                  {selectedItem.complement_type?.description}
                </FormatItem>
              </Item>
              <Item label="Total movimentado">
                <FormatItem suffix="ton">
                  {selectedItem.movimented_cargo}
                </FormatItem>
              </Item>
            </>
          )}
          <Item label="Valor">
            <FormatItem>
              {formatNumberToLocale(selectedItem.value, true)}
            </FormatItem>
          </Item>
          <Item label="Observações">
            <FormatItem>{selectedItem.observations}</FormatItem>
          </Item>
        </Descriptions>
        {[1, 3].includes(selectedTable) &&
          selectedItem.childs &&
          selectedItem.childs.length > 0 && (
            <ApportmentTable
              dataSource={selectedItem.childs || []}
              rowKey="id"
              totalAmount={totalAmount()}
            />
          )}
        {selectedItem.discountItems &&
          selectedItem.discountItems.length > 0 && (
            <DiscountItemsTable
              dataSource={selectedItem.discountItems || []}
              rowKey="id"
              totalValue={selectedItem.value || 0}
            />
          )}
      </div>
    </Drawer>
  );
}
