import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';

import {
  Button,
  CargoStorageMerchantTable,
  Collapse,
} from '../../../components';
import {
  CompanyType,
  ConsigneeWithCargosType,
  Stopover as StopoverType,
} from '../../../types';
import { ConfirmStorageModal } from './confirmStorageModal/ConfirmStorageModal';

const { Panel } = Collapse;

type ConsigneeDataProps = {
  consignees: ConsigneeWithCargosType[];
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
};

export function ConsigneeData(props: ConsigneeDataProps) {
  const { consignees, setSelectedStopover } = props;

  const [isVisibleConfirmStorageModal, setIsVisibleConfirmStorageModal] =
    useState(false);
  const [selectedConsignee, setSelectedConsignee] = useState(
    {} as ConsigneeWithCargosType
  );

  function getHeader(company: CompanyType) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          gap: '8px',
          width: '100%',
        }}
      >
        <span
          style={{
            maxWidth: '250px',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {company.name}
        </span>
        <span
          style={{
            fontSize: '12px',
            color: 'var(--neutral_medium)',
            fontWeight: '400',
          }}
        >
          {company.identifier}
        </span>
      </div>
    );
  }

  function extraPanel(consignee: ConsigneeWithCargosType) {
    function handleOpenConfirmStorageModal(
      e: MouseEvent<HTMLElement>,
      consignee: ConsigneeWithCargosType
    ) {
      e.stopPropagation();
      setIsVisibleConfirmStorageModal(true);
      setSelectedConsignee(consignee);
    }

    const hasPending = consignee.items.some(
      (item) => item.status === 'PENDING' || item.status === 'REJECTED'
    );

    return (
      <Button
        onClick={(e) => handleOpenConfirmStorageModal(e, consignee)}
        type="primary"
        style={{
          right: '30px',
        }}
        disabled={!hasPending}
      >
        Responder
      </Button>
    );
  }

  return (
    <>
      <ConfirmStorageModal
        isVisible={isVisibleConfirmStorageModal}
        setIsVisible={setIsVisibleConfirmStorageModal}
        consignee={selectedConsignee}
        setSelectedStopover={setSelectedStopover}
      />
      <Collapse expandIconPosition="end">
        {consignees.map((consignee) => (
          <Panel
            key={consignee.operator_company.identifier || 0}
            header={getHeader(consignee.operator_company)}
            extra={extraPanel(consignee)}
          >
            <CargoStorageMerchantTable dataSource={consignee.items} />
          </Panel>
        ))}
      </Collapse>
    </>
  );
}
