import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import confirm from 'antd/lib/modal/confirm';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../../../app/hooks';
import { RootState } from '../../../../../app/store';
import {
  Form,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
  FormItemsCheckbox,
  GenericDrawerHeader,
} from '../../../../../components';
import { useGetCompaniesQuery } from '../../../../../services/companyApi';
import { useGetBerthsQuery } from '../../../../../services/dockingPlacesApi';
import {
  useCreateMaintenanceMutation,
  useDeleteMaintenanceMutation,
  useUpdateMaintenanceMutation,
} from '../../../../../services/maintenancesApi';
import {
  BerthMaintenance,
  DockingPlace,
  DockingPlaceForm,
} from '../../../../../types';

const BerthMaintenanceFormWrapper = styled.div`
  padding: 24px 16px;
`;

type BerthMaintenanceFormProps = {
  berthForm: FormInstance<DockingPlaceForm>;
  isEdit: boolean;
  selectedBerth: DockingPlace;
  selectedBerthMaintenance: BerthMaintenance;
  addMaintenanceToForm?: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
  removeMaintenanceFromForm?: (index: number | number[]) => void;
  onBack: () => void;
  berthMaintenanceRowIndex?: number;
  isDockingWindowContext?: boolean;
};

export function BerthMaintenanceForm(props: BerthMaintenanceFormProps) {
  const {
    berthForm,
    isEdit,
    selectedBerth,
    selectedBerthMaintenance,
    addMaintenanceToForm,
    removeMaintenanceFromForm,
    onBack,
    berthMaintenanceRowIndex,
    isDockingWindowContext,
  } = props;

  const [form] = Form.useForm<BerthMaintenance>();

  const [companySearchName, setCompanySearchName] = useState('');
  const [companiesIds, setCompaniesIds] = useState<number[]>([]);

  const [
    updateMaintenance,
    {
      isSuccess: isSuccessUpdateMaintenance,
      isError: isErrorUpdateMaintenance,
    },
  ] = useUpdateMaintenanceMutation();

  const [
    createMaintenance,
    {
      isSuccess: isSuccessCreateMaintenance,
      isError: isErrorCreateMaintenance,
    },
  ] = useCreateMaintenanceMutation();

  const [
    deleteMaintenance,
    {
      isSuccess: isSuccessDeleteMaintenance,
      isError: isErrorDeleteMaintenance,
    },
  ] = useDeleteMaintenanceMutation();

  const { data: berthData, isLoading: isLoadingBerthData } = useGetBerthsQuery({
    placeType: 'BERTH',
  });

  const { data: companiesData, isLoading: isLoadingCompaniesData } =
    useGetCompaniesQuery({
      name_or_cnpj: companySearchName,
      companies_ids: companiesIds,
    });

  const { user } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if (isSuccessCreateMaintenance) {
      message.success('Parada de manutenção cadastrada com sucesso!');
    }

    if (isSuccessUpdateMaintenance) {
      message.success('Parada de manutenção atualizada com sucesso!');
    }

    if (isSuccessDeleteMaintenance) {
      message.success('Parada de manutenção removida com sucesso!');
      onBack();
    }
  }, [
    isSuccessCreateMaintenance,
    isSuccessUpdateMaintenance,
    isSuccessDeleteMaintenance,
  ]);

  useEffect(() => {
    if (selectedBerthMaintenance.companies) {
      setCompaniesIds(selectedBerthMaintenance.companies as number[]);
    }
  }, [selectedBerthMaintenance]);

  function getHeaderTitle() {
    return isEdit ? (
      <span style={{ fontWeight: '700', fontSize: '16px' }}>
        Nova Manutenção
      </span>
    ) : (
      <span style={{ fontWeight: '700', fontSize: '16px' }}>
        Editar Manutenção
      </span>
    );
  }

  function handleFormSubmit(values: any) {
    form.validateFields().then(async (values) => {
      if (!validateBerthMaintenance(values)) return;
      // berco existente
      if (isEdit && selectedBerth.tag) {
        // manutencao existente
        if (!isEmpty(selectedBerthMaintenance)) {
          updateMaintenance({
            ...values,
            id: selectedBerthMaintenance.id,
            responsible: selectedBerthMaintenance.responsible,
            berths: [...(values.berths || []), selectedBerth.tag] as string[],
          });

          // manutencao nao existente
        } else {
          createMaintenance({
            ...values,
            responsible: user,
            berths: [...(values.berths || []), selectedBerth.tag] as string[],
          });
        }
        // berco nao existente
        // manutencao existente
      } else if (!isEmpty(selectedBerthMaintenance)) {
        const berthMaintenances = berthForm.getFieldValue([
          'berth_maintenances',
        ]);
        berthForm.setFieldsValue({
          berth_maintenances: berthMaintenances.map(
            (maintenance: BerthMaintenance, index: number) =>
              index === berthMaintenanceRowIndex ? { ...values } : maintenance
          ),
        });
        // manutencao nao existente
      } else {
        const newBerthName = berthForm.getFieldValue(['name']);
        if (addMaintenanceToForm)
          addMaintenanceToForm({
            ...values,
            berths: [...(values.berths || []), newBerthName],
            responsible: {
              ...user,
              first_name: (user as any).firstName,
              last_name: (user as any).lastName,
            },
          });
      }
      onBack();
    });
  }

  function getAllowedBerths(berths: DockingPlace[] | undefined) {
    if (isEmpty(berths)) return [];

    if (isEdit && !isEmpty(selectedBerth)) {
      return berths?.filter(
        (berth: DockingPlace) => berth.tag !== selectedBerth.tag
      );
    }

    return berths;
  }

  function validateBerthMaintenance(maintenance: BerthMaintenance) {
    if (
      maintenance.expected_start &&
      maintenance.expected_finish &&
      moment(maintenance.expected_start) > moment(maintenance.expected_finish)
    ) {
      message.error(
        'A data de previsão de início da manutenção não pode ser posterior à data de previsão de fim',
        15
      );
      return false;
    }
    return true;
  }

  function handleClearCompaniesInput() {
    setCompanySearchName('');
    setCompaniesIds([]);
  }

  function handleSearchCompany(value: string) {
    if (!isEmpty(value)) {
      setCompaniesIds([]);
      setCompanySearchName(value);
    }
  }

  function handleDeleteMaintenance() {
    confirm({
      title: 'Deseja realmente remover essa parada de manutenção?',
      icon: <ExclamationCircleOutlined />,
      content: 'A parada de manutenção removida não poderá ser recuperada',
      async onOk() {
        if (!isEmpty(selectedBerthMaintenance) && selectedBerthMaintenance.id) {
          deleteMaintenance(selectedBerthMaintenance.id);
        }
      },
    });
  }

  return (
    <Form
      form={form}
      name="berth-maintenance"
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={selectedBerthMaintenance}
    >
      <GenericDrawerHeader
        title={getHeaderTitle()}
        deleteButtonType="primary"
        formName="berth-maintenance"
        onBack={onBack}
        onDelete={
          !isEmpty(selectedBerthMaintenance)
            ? handleDeleteMaintenance
            : undefined
        }
        showBackButton
      />
      <BerthMaintenanceFormWrapper>
        <Row>
          <FormItemInput
            name={['description']}
            label="Descrição"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          />
        </Row>
        <Row gutter={24}>
          <FormItemSelect
            name={['berths']}
            label="Outros berços impactados"
            dataList={getAllowedBerths(berthData?.results)}
            mode="multiple"
            maxSelectOptions={100}
            tagTheme="neutral"
            isLoading={isLoadingBerthData}
          />
        </Row>
        <Row gutter={24}>
          <FormItemSelect
            name={['companies']}
            label="Empresas envolvidas"
            dataList={
              companiesData?.results
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name)) || []
            }
            mode="multiple"
            maxSelectOptions={100}
            tagTheme="neutral"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            isLoading={isLoadingCompaniesData}
            onSearch={(value) => handleSearchCompany(value)}
            debounceDelay={400}
            onClear={() => handleClearCompaniesInput()}
            allowClear
            disabled={isLoadingCompaniesData}
          />
        </Row>
        <Row gutter={24}>
          <FormItemDatePicker
            colSpan={12}
            name={['expected_start']}
            label="Previsão de início"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            required
          />
          <FormItemDatePicker
            colSpan={12}
            name={['expected_finish']}
            label="Previsão de término"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            required
          />
        </Row>
        <Row>
          <FormItemsCheckbox name={['allow_night_pump']}>
            Permitir bombeios (das 18:00 às 06:00)
          </FormItemsCheckbox>
        </Row>
        <Row>
          <FormItemsCheckbox name={['allow_weekend_pump_and_vessel']}>
            Permitir bombeios e navios nos fins de semana
          </FormItemsCheckbox>
        </Row>
      </BerthMaintenanceFormWrapper>
    </Form>
  );
}
