import { Col } from 'antd';
import { Rule } from 'antd/lib/form';

import { TreeType } from '../../../types';
import { FormItem } from '../Form';
import { TreeSelect } from '../TreeSelect';

type FormItemTreeSelectProps = {
  colSpan?: number;
  name: string | string[];
  label: string;
  treeData: TreeType[];
  showCheckedStrategy?: 'SHOW_ALL' | 'SHOW_PARENT' | 'SHOW_CHILD';
  tagTheme?: 'neutral';
  rules?: Rule[];
};

export function FormItemTreeSelect(props: FormItemTreeSelectProps) {
  const {
    name,
    label,
    treeData,
    colSpan,
    showCheckedStrategy,
    tagTheme,
    rules,
  } = props;
  return (
    <Col span={colSpan || 24}>
      <FormItem name={name} label={label} rules={rules || []}>
        <TreeSelect
          $tagTheme={tagTheme}
          treeData={treeData}
          treeCheckable
          showCheckedStrategy={showCheckedStrategy || 'SHOW_PARENT'}
          treeNodeFilterProp="title"
        />
      </FormItem>
    </Col>
  );
}
