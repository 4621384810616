import { InnerCollapse, LightTable } from '../../../components';
import { CountTag } from '../../../components/common/CountTag';
import { ParkingSpaceType } from '../../../typesWarehousing';
import { OverviewChassisTableColumns } from './overviewChassisTableColumns';

type ParkingSpaceDetailsProps = {
  direction: 'IMPORT' | 'EXPORT';
  storageCompanyData?: ParkingSpaceType;
};
const { Panel } = InnerCollapse;

export function ParkingSpaceDetails(props: ParkingSpaceDetailsProps) {
  const { direction, storageCompanyData } = props;
  function createBlockHeader(blockName: string, totalVehicles: number) {
    return (
      <div>
        {blockName}
        <CountTag className="light">{totalVehicles} veículos</CountTag>
      </div>
    );
  }

  return (
    <div>
      {storageCompanyData &&
        Object.keys(storageCompanyData)
          .filter((key) => key !== 'counter')
          .map((parking_place) => {
            const { counter } = storageCompanyData[parking_place];
            return (
              <InnerCollapse
                expandIconPosition="right"
                style={{ marginTop: '16px' }}
                destroyInactivePanel
              >
                <Panel
                  key={parking_place}
                  header={
                    parking_place === 'vehicles_pool'
                      ? direction === 'IMPORT'
                        ? createBlockHeader('Bolsão provisório', counter)
                        : createBlockHeader('Bolsão de carregamento', counter)
                      : createBlockHeader(`Bloco`, counter)
                  }
                >
                  {storageCompanyData[parking_place].vehicles_resume?.map(
                    (block) => {
                      return (
                        <div style={{ padding: '8px 16px 8px 16px' }}>
                          <InnerCollapse
                            expandIconPosition="right"
                            style={{ marginTop: '16px' }}
                            destroyInactivePanel
                            className="white-header"
                          >
                            <Panel
                              key={parking_place}
                              header={createBlockHeader(
                                block.block.replace('QD', 'QUADRA '),
                                block.counter
                              )}
                            >
                              <div style={{ padding: '16px' }}>
                                <LightTable
                                  size="small"
                                  columns={OverviewChassisTableColumns()}
                                  dataSource={block.vehicles}
                                />
                              </div>
                            </Panel>
                          </InnerCollapse>
                        </div>
                      );
                    }
                  )}
                </Panel>
              </InnerCollapse>
            );
          })}
    </div>
  );
}
