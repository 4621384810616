import { EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import Flag from 'react-flagpack';

import { Button, ErrorBoundary } from '../../../components';

export function OverviewChassisTableColumns(): ColumnsType<
  Record<string, any>
> {
  return [
    {
      title: 'Chassi',
      dataIndex: 'chassis',
      render: (value) => {
        return <span style={{ fontFamily: 'Consolas' }}>{value}</span>;
      },
    },
    { title: 'Modelo', dataIndex: 'description' },
    {
      title: 'Embarcação',
      dataIndex: 'vessel_name',
      render: (value, record) => {
        return (
          <div>
            {record.vessel_flag && (
              <ErrorBoundary replace="">
                <Flag code={record.vessel_flag} hasBorder={false} size="s" />
              </ErrorBoundary>
            )}
            <div style={{ marginLeft: '5px', display: 'inline-block' }}>
              {value}
            </div>
          </div>
        );
      },
    },
    { title: 'Chegada', dataIndex: 'posting_date' },
    { title: 'Vaga', dataIndex: 'parking_space' },
    {
      title: '',
      render: () => {
        return <Button icon={<EyeOutlined />} style={{ float: 'right' }} />;
      },
    },
  ];
}
