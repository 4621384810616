import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import moment from 'moment';

import { Stopover as StopoverType } from '../../types';

type StopoverCancelationDataProps = {
  selectedStopover: StopoverType;
};

export function OperationCancelationData(props: StopoverCancelationDataProps) {
  const { selectedStopover } = props;
  return (
    <div>
      <Alert
        message={
          <>
            <span>
              Operação cancelada em:{' '}
              {moment(selectedStopover.operation.archived_at).format(
                'DD/MM/YYYY HH:mm'
              )}
            </span>
            <br />
          </>
        }
        type="warning"
        showIcon
        style={{ margin: '20px' }}
        icon={<InfoCircleOutlined />}
      />
    </div>
  );
}
