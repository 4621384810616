import { Space, message } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';

import { Button, Form, FormItemDatePicker } from '../../components';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type StartDockingModalProps = BaseActionModalProps & {
  onStartDocking: () => void;
};

export function StartDockingModal(props: StartDockingModalProps) {
  const {
    setIsVisible,
    onStartDocking,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
  } = props;
  const [form] = Form.useForm();

  function validate(pilotOnBoard: string, firstCableTiedIn: Moment) {
    const pilotOnBoardMoment = moment(pilotOnBoard);

    if (firstCableTiedIn <= pilotOnBoardMoment) {
      message.error(
        `A data do primeiro cabo amarrado não pode ser menor que a data de prático a bordo(${createDateStringPtBr(
          pilotOnBoardMoment
        )})`
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (updateDocking) {
        if (
          !validate(
            currentDocking?.docking_manoeuvre?.pilot_on_board || '',
            values.first_cable_tied_in
          )
        )
          return;

        updateDocking(
          {
            ...values,
            first_cable_tied_in: createDateString(values.first_cable_tied_in),
            id: currentDocking?.id,
          },
          onStartDocking
        );
      }
    });
  }

  useEffect(() => {
    if (currentDocking) {
      form.setFieldsValue({
        first_cable_tied_in: currentDocking?.first_cable_tied_in,
      });
    }
  }, [currentDocking]);
  return (
    <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
      <FormItemDatePicker
        name="first_cable_tied_in"
        label="Primeiro cabo amarrado em:"
        disabledDate={disabledDateAfterToday}
        colSpan={12}
        tooltip="Data e horário do primeiro cabo amarrado"
        rules={[
          {
            required: true,
            message:
              'Por favor, preencha a data e hora do primeiro cabo amarrado',
          },
        ]}
      />
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingUpdateDocking}
        >
          Amarrar
        </Button>
      </Space>
    </Form>
  );
}
