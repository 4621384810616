import { Col, Row, Skeleton } from 'antd';

import { NcmItemGroupByConsigneeType } from '../../../types';
import { InnerCollapse } from '../../antd/Collapse';
import { LightTable } from '../../antd/Table';
import { ncmStorageByMerchantCETableColumns } from './ncmStorageByMerchantCETableColumns';

const { Panel } = InnerCollapse;

type CeMerchantGroupbyNcmCollapseProps = {
  ncmItemGroupByOperatorData: NcmItemGroupByConsigneeType;
  isFetchingNcmItemGroupByOperator: boolean;
};

export function CeMerchantGroupbyNcmCollapse(
  props: CeMerchantGroupbyNcmCollapseProps
) {
  const { ncmItemGroupByOperatorData, isFetchingNcmItemGroupByOperator } =
    props;

  function getTableFooter(consigneeId: string, ncmId: string) {
    if (ncmItemGroupByOperatorData) {
      const totalVolume =
        ncmItemGroupByOperatorData[consigneeId].ncms[ncmId].total_volume;
      return (
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            TOTAIS: {ncmItemGroupByOperatorData[consigneeId].ncms[ncmId].total}
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            {totalVolume > 0 ? `${totalVolume} m³` : ''}
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            {ncmItemGroupByOperatorData[consigneeId].ncms[ncmId].total_weight}{' '}
            ton
          </Col>
        </Row>
      );
    }
  }

  return (
    <Skeleton loading={isFetchingNcmItemGroupByOperator}>
      <InnerCollapse expandIconPosition="end" className="white-header">
        {ncmItemGroupByOperatorData !== undefined &&
          Object.keys(ncmItemGroupByOperatorData).map((consigneeId) => {
            return (
              <Panel
                header={
                  <>
                    {ncmItemGroupByOperatorData[consigneeId].name}{' '}
                    <span className="code">
                      {ncmItemGroupByOperatorData[consigneeId].cnpj ||
                        ncmItemGroupByOperatorData[consigneeId].tin}
                    </span>
                    <span className="count-tag">
                      {
                        Object.keys(
                          ncmItemGroupByOperatorData[consigneeId].ncms
                        ).length
                      }
                    </span>
                  </>
                }
                key={consigneeId}
              >
                {Object.keys(ncmItemGroupByOperatorData[consigneeId].ncms).map(
                  (ncmId) => {
                    return (
                      <LightTable
                        key={ncmId}
                        columns={ncmStorageByMerchantCETableColumns(
                          !!ncmItemGroupByOperatorData[consigneeId].ncms[ncmId]
                            .ce_merchants[0].chassis
                        )}
                        dataSource={
                          ncmItemGroupByOperatorData[consigneeId].ncms[ncmId]
                            .ce_merchants
                        }
                        pagination={{
                          position: ['bottomCenter'],
                          defaultPageSize: 10,
                          showSizeChanger: false,
                          total:
                            ncmItemGroupByOperatorData[consigneeId].ncms[ncmId]
                              .ce_merchants?.length || 0,
                        }}
                        className="read-only"
                        style={{
                          marginBottom: '16px',
                          marginTop: '8px',
                        }}
                        title={() => (
                          <div>
                            {ncmId !== 'null' ? (
                              <>
                                <strong>{ncmId}</strong> -{' '}
                              </>
                            ) : (
                              'NCM não informado'
                            )}
                            {
                              ncmItemGroupByOperatorData[consigneeId].ncms[
                                ncmId
                              ].description
                            }
                          </div>
                        )}
                        footer={() => getTableFooter(consigneeId, ncmId)}
                      />
                    );
                  }
                )}
              </Panel>
            );
          })}
      </InnerCollapse>
    </Skeleton>
  );
}
