import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import FormList from 'antd/lib/form/FormList';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';

import { Button, Form, Modal, TabPane, Tabs } from '../../../components';
import {
  CompanyType,
  MerchantOperatorCompanyDescriptionType,
  OperationProductCeMercanteType,
  OperationProductStoragesMercanteType,
} from '../../../types';
import { FormItemsOperationMerchantCE } from './formItemsOperationMerchantCE';
import { FormItemsStorageMerchant } from './formItemsStorageMerchant';
import { ModalOperationMerchantCEHeader, StorageCompanyTabs } from './styles';

type ModalOperationMerchantCEProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  selectedMerchantCE: OperationProductCeMercanteType | undefined;
  setSelectedMerchantCE: (value: OperationProductCeMercanteType) => void;
  selectedConsigneeCompany: MerchantOperatorCompanyDescriptionType;
  setSelectedConsigneeCompany: (
    value: MerchantOperatorCompanyDescriptionType
  ) => void;
  isContainer?: boolean;
  storageCompany?: CompanyType;
};

export function ModalOperationMerchantCE(props: ModalOperationMerchantCEProps) {
  const {
    selectedMerchantCE,
    setSelectedMerchantCE,
    selectedConsigneeCompany,
    setSelectedConsigneeCompany,
    storageCompany,
    isContainer,
  } = props;
  const [form] = Form.useForm();

  const [formIsChanged, setIsFormChanged] = useState(false);
  const isEdit = !!selectedMerchantCE?.code;

  useEffect(() => {
    if (selectedMerchantCE) {
      setDefaultStorage();
      form.setFieldsValue(selectedMerchantCE);
    } else {
      form.resetFields();
    }
  }, [selectedMerchantCE, isContainer]);

  function setDefaultStorage() {
    const storages: OperationProductStoragesMercanteType[] =
      selectedMerchantCE?.storages || [];

    if (isContainer && storages.length === 0) {
      const storage: OperationProductStoragesMercanteType = {
        id: 0,
        storage_company: storageCompany || ({} as CompanyType),
        merchant_ce_id: selectedMerchantCE?.id || 0,
        total_amount: 0,
        total_weight: 0,
        total_density: 0,
        cargos_storage: [],
      };
      storages.push(storage);
      form?.setFieldsValue({ storages });
    }
  }

  useEffect(() => {
    setSelectedMerchantCE(
      selectedMerchantCE || ({} as OperationProductCeMercanteType)
    );
  }, [props.showModal]);

  function getModalTitle() {
    return (
      <ModalOperationMerchantCEHeader>
        <span className="title">
          {!isEdit
            ? 'Novo CE Mercante'
            : `CE Mercante ${selectedMerchantCE?.code}`}
        </span>
        <div>
          <Button
            type="text"
            style={{ marginRight: '8px' }}
            onClick={() => props.setShowModal(false)}
          >
            Voltar
          </Button>
          {isEdit && (
            <Button
              type="ghost"
              danger
              style={{ marginRight: '8px' }}
              icon={<DeleteOutlined />}
              onClick={onTryDelete}
            >
              Excluir
            </Button>
          )}
          <Button
            type="primary"
            htmlType="submit"
            form="merchant_ce_edit_form"
            icon={<CheckOutlined />}
          >
            Salvar
          </Button>
        </div>
      </ModalOperationMerchantCEHeader>
    );
  }

  // submit form after validation
  async function handleFormSubmit(values: any) {
    form
      .validateFields()
      .then(async (values) => {
        const merchantCE: OperationProductCeMercanteType = {
          ...selectedMerchantCE,
          ...values,
        };

        if (isEdit) {
          const merchantsCE = selectedConsigneeCompany.merchants_ce?.map(
            (ce: OperationProductCeMercanteType) => {
              if (ce.id === merchantCE.id) {
                return merchantCE;
              }
              return ce;
            }
          );
          setSelectedConsigneeCompany({
            ...selectedConsigneeCompany,
            merchants_ce: merchantsCE,
          });
        } else {
          setSelectedConsigneeCompany({
            ...selectedConsigneeCompany,
            merchants_ce: [
              ...(selectedConsigneeCompany?.merchants_ce || []),
              merchantCE,
            ],
          });
        }
      })
      .finally(() => {
        props.setShowModal(false);
      });
  }

  function onTryDelete() {
    confirm({
      title: 'Deseja realmente remover este CE Mercante?',
      icon: <ExclamationCircleOutlined />,
      content: 'O CE Mercante removido não poderá ser recuperado',
      onOk() {
        setSelectedConsigneeCompany({
          ...selectedConsigneeCompany,
          merchants_ce: selectedConsigneeCompany.merchants_ce.filter(
            (ce: any) => ce.code !== selectedMerchantCE?.code
          ),
        });
        props.setShowModal(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function afterCloseModal() {
    form.resetFields();
    setSelectedMerchantCE({} as OperationProductCeMercanteType);
  }
  function getStorageTabHeader(index: number, remove: (index: number) => void) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          {form?.getFieldValue(['storages', index])?.storage_company
            ? form?.getFieldValue(['storages', index])?.storage_company.name
            : 'Novo local'}
        </span>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => remove(index)}
        />
      </div>
    );
  }

  return (
    <Modal
      visible={props.showModal}
      className="TOSPrimaryModal"
      width={1120}
      title={getModalTitle()}
      footer={null}
      closable={false}
      afterClose={afterCloseModal}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        name="merchant_ce_edit_form"
        id="merchant_ce_edit_form"
        onFinish={handleFormSubmit}
        initialValues={selectedMerchantCE}
        onValuesChange={() => setIsFormChanged(true)}
      >
        {!isContainer && (
          <Tabs>
            <TabPane tab="Dados gerais" key="Dados gerais">
              <FormItemsOperationMerchantCE
                form={form}
                selectedMerchantCE={selectedMerchantCE}
                style={{ padding: '24px 16px' }}
              />
            </TabPane>
            <TabPane
              tab="Locais de armazenamento"
              key="Locais de armazenamento"
            >
              <FormList name={['storages']}>
                {(storages, { add: addStorage, remove: removeStorage }) => (
                  <>
                    <StorageCompanyTabs tabPosition="left">
                      {storages.map((_field, index) => {
                        return (
                          <TabPane
                            tab={getStorageTabHeader(index, removeStorage)}
                            key={index}
                          >
                            <FormItemsStorageMerchant
                              form={form}
                              selectedMerchantCE={selectedMerchantCE}
                              storageIndex={index}
                              style={{ padding: '24px 16px' }}
                            />
                          </TabPane>
                        );
                      })}
                    </StorageCompanyTabs>
                    <Button
                      type="ghost"
                      size="middle"
                      onClick={() =>
                        addStorage({
                          storage_company: null,
                          cargos_storage: [],
                        })
                      }
                      style={{ marginTop: '16px' }}
                    >
                      Novo local de armazenamento
                    </Button>
                  </>
                )}
              </FormList>
            </TabPane>
          </Tabs>
        )}
        {isContainer && (
          <>
            <FormItemsOperationMerchantCE
              form={form}
              selectedMerchantCE={selectedMerchantCE}
              style={{ padding: '24px 16px' }}
            />
            <FormList name={['storages']}>
              {() => (
                <FormItemsStorageMerchant
                  form={form}
                  selectedMerchantCE={selectedMerchantCE}
                  storageIndex={0}
                  style={{ padding: '24px 16px' }}
                  isContainer
                />
              )}
            </FormList>
          </>
        )}
      </Form>
    </Modal>
  );
}
