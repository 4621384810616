import { Dispatch, SetStateAction, useState } from 'react';

import { Drawer, GenericDrawerHeader } from '../../../../../components';
import {
  CargoManifestType,
  CeMerchantsGroupbyOperatorCompanyType,
  CEMerchantType,
  NcmItemsGroupbyStorageType,
} from '../../../../../types';
import { groupNCMItemByStorage } from '../formatters';
import { NcmItemsModal } from './ncmItemsModal';
import { ButtonOperatorCompany, DrawerTitle } from './styles';

type CeMerchantsDrawerProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  cargoManifest: CargoManifestType;
  operatorCompanySelected: CeMerchantsGroupbyOperatorCompanyType;
  setOperatorCompanySelected: Dispatch<
    SetStateAction<CeMerchantsGroupbyOperatorCompanyType>
  >;
};

export function CeMerchantsDrawer(props: CeMerchantsDrawerProps) {
  const {
    isVisible,
    setIsVisible,
    cargoManifest,
    operatorCompanySelected,
    setOperatorCompanySelected,
  } = props;
  const [isNCMItemsModalVisible, setIsNCMItemsModalVisible] = useState(false);

  const [ceMerchantSelected, setCeMerchantSelected] = useState(
    {} as CEMerchantType
  );

  function handleSelectCeMerchant(ceMerchant: CEMerchantType) {
    setIsNCMItemsModalVisible(true);
    setCeMerchantSelected({
      ...ceMerchant,
      ncm_items_groupby_storage: groupNCMItemByStorage(ceMerchant.ncm_items),
    });
  }

  const getStoragesByAmount = (storages: NcmItemsGroupbyStorageType[]) => {
    return storages
      ? storages
          .map((storage) => `${storage.name} (${storage.items.length})`)
          .join(', ')
      : 'Nenhum terminal informado';
  };

  function handleCloseDrawing() {
    setIsVisible(false);
    setOperatorCompanySelected({} as CeMerchantsGroupbyOperatorCompanyType);
  }

  return (
    <>
      <NcmItemsModal
        isVisible={isNCMItemsModalVisible}
        setIsVisible={setIsNCMItemsModalVisible}
        cargoManifest={cargoManifest}
        ceMerchantSelected={ceMerchantSelected}
        setCeMerchantSelected={setCeMerchantSelected}
        operatorCompanySelected={operatorCompanySelected}
      />
      <Drawer
        visible={isVisible}
        placement="right"
        width={650}
        closable={false}
        destroyOnClose
        onClose={() => handleCloseDrawing()}
      >
        <GenericDrawerHeader
          title={
            <DrawerTitle>
              <span className="consignee-name">
                {operatorCompanySelected.name}
              </span>
              <span className="consignee-cnpj">
                {operatorCompanySelected.cnpj}
              </span>
            </DrawerTitle>
          }
          showBackButton
          onBack={handleCloseDrawing}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
          }}
        >
          {operatorCompanySelected.items?.map((ceMerchant, index) => (
            <ButtonOperatorCompany
              key={ceMerchant.id}
              type="link"
              style={{
                borderTop: index > 0 ? '1px solid #F0F0F0' : 'none',
              }}
              onClick={() => handleSelectCeMerchant(ceMerchant)}
            >
              <span className="cemerchant-code">{ceMerchant.code}</span>
              <span className="storages-by-amount">
                {getStoragesByAmount(ceMerchant.ncm_items_groupby_storage)}
              </span>
            </ButtonOperatorCompany>
          )) || <span>Nenhum CE Mercante encontrado</span>}
        </div>
      </Drawer>
    </>
  );
}
