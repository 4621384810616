import { ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import { useState } from 'react';

import { Drawer } from '../../../components';
import { useGetCargoTypeQuery } from '../../../services/cargoApi';
import { useCancelDockingMutation } from '../../../services/dockingApi';
import { useGetOperatorsQuery } from '../../../services/stopoverApi';
import { useGetTugboatsQuery } from '../../../services/vesselApi';
import { Docking, Stopover as StopoverType } from '../../../types';
import { collapsePanelsListDocking } from '../../../utils/lists';
import { AnchoringForm } from '../anchoringForm';
import { DockingDrawer } from '../DockingDrawer';
import { DockingStaymentDrawer } from '../dockingStaymentDrawer/DockingStaymentDrawer';
import { DockingDescription } from './dockingDescription';

type DockingsDataProps = {
  selectedStopover?: StopoverType;
  setSelectedStopover: (value: StopoverType) => void;
  activeKey: string | string[];
  onOpenPanel: (panel: any) => void;
  allowedBerth: any[];
  screen: 'STOPOVER' | 'STAYMENT' | 'AGENT_AREA';
  // allowedOperators: any[];
  // onSelectBerth: (val: any, option: any) => void;
};

export function DockingsData(props: DockingsDataProps) {
  const {
    selectedStopover,
    setSelectedStopover,
    activeKey,
    onOpenPanel,
    allowedBerth,
    screen,
  } = props;
  const [selectedDocking, setSelectedDocking] = useState<Docking>(
    {} as Docking
  );
  const [selectedDockingPosition, setSelectedDockingPosition] = useState<
    number | null
  >(null);

  const [isDockingDrawerVisible, setIsDockingDrawerVisible] = useState(false);

  const [isVisibleAnchoringDrawer, setIsVisibleAnchoringDrawer] =
    useState(false);

  const [openCollapsePanels, setOpenCollapsePanels] = useState<
    string | string[]
  >(collapsePanelsListDocking);

  const { data: cargosData } = useGetCargoTypeQuery();
  const { data: operatorsData } = useGetOperatorsQuery();
  const { data: tugboatsData } = useGetTugboatsQuery({});

  const [cancelDocking] = useCancelDockingMutation();

  function onEdit(evt: any, indexToEdit: number) {
    evt.stopPropagation();
    if (selectedStopover && selectedStopover.dockings) {
      setSelectedDocking(selectedStopover.dockings[indexToEdit]);
      setSelectedDockingPosition(indexToEdit);
      const dockingToEdit = selectedStopover.dockings[indexToEdit];
      if (
        dockingToEdit?.purpose === 'ANCHORING_SERVICES' ||
        dockingToEdit?.purpose === 'ANCHORING_WAIT'
      ) {
        setIsVisibleAnchoringDrawer(true);
      } else {
        setIsDockingDrawerVisible(true);
      }
    }
  }

  function handleCancelDocking(evt: any, indexToCancel: number) {
    evt.stopPropagation();
    confirm({
      title: 'Deseja realmente cancelar esta atracação?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Depois que salvar a escala a atracação cancelada não poderá ser reativada',
      onOk() {
        onCancelDocking(indexToCancel);
      },
      okText: 'Sim',
      cancelText: 'Não',
    });
  }

  function onCancelDocking(indexToCancel: number): void {
    if (selectedStopover && selectedStopover.dockings) {
      const canceledDocking = selectedStopover.dockings[indexToCancel];
      const allDockings: Docking[] = selectedStopover.dockings;

      const updatedDockings: Docking[] = allDockings.map((docking): Docking => {
        if (docking.index === canceledDocking.index) {
          return {
            ...docking,
            archived_at: moment().format('YYYY-MM-DD HH:mm'),
          };
        }
        return docking;
      });

      selectedStopover.dockings = updatedDockings;

      if (canceledDocking.id) {
        cancelDocking({ id: canceledDocking.id });
      }
    }
  }

  function onBack() {
    setIsDockingDrawerVisible(false);
  }

  return (
    <>
      <DockingDescription
        selectedDockings={selectedStopover?.dockings}
        activeKey={activeKey}
        onOpenPanel={onOpenPanel}
        setIsVisibleAnchoringDrawer={setIsVisibleAnchoringDrawer}
        setIsDockingDrawerVisible={setIsDockingDrawerVisible}
        isDockingDrawerVisible={isDockingDrawerVisible}
        onClickEdit={onEdit}
        screen={screen}
        blockAddDocking={
          selectedStopover?.current_status === 'DONE' ||
          selectedStopover?.current_status === 'CANCELED'
        }
        setOpenCollapsePanels={setOpenCollapsePanels}
      />
      <DockingStaymentDrawer
        visible={isDockingDrawerVisible && screen === 'STAYMENT'}
        setIsVisible={setIsDockingDrawerVisible}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        currentDockingKey={selectedDockingPosition}
        setCurrentDockingKey={setSelectedDockingPosition}
        selectedDocking={selectedDocking}
        setSelectedDocking={setSelectedDocking}
        width={800}
        allowedBerth={allowedBerth}
        tugboatsData={tugboatsData?.results}
        cargosData={cargosData?.results}
        operatorsData={operatorsData?.results}
        onBack={() => onBack()}
      />
      <DockingDrawer
        visible={
          isDockingDrawerVisible &&
          (screen === 'STOPOVER' || screen === 'AGENT_AREA')
        }
        setIsVisible={setIsDockingDrawerVisible}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        selectedVessel={props.selectedStopover?.vessel}
        currentDockingKey={selectedDockingPosition}
        setCurrentDockingKey={setSelectedDockingPosition}
        selectedDocking={selectedDocking}
        setSelectedDocking={setSelectedDocking}
        width={900}
        allowedBerth={allowedBerth}
        onBack={() => onBack()}
        screen={screen === 'STAYMENT' ? 'STOPOVER' : screen}
        openCollapsePanels={openCollapsePanels}
        setOpenCollapsePanels={setOpenCollapsePanels}
      />
      <Drawer closable={false} visible={isVisibleAnchoringDrawer} width={820}>
        <AnchoringForm
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
          selectedDocking={selectedDocking}
          setSelectedDocking={setSelectedDocking}
          onClose={() => setIsVisibleAnchoringDrawer(false)}
          screen={screen}
        />
      </Drawer>
    </>
  );
}
