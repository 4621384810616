/* eslint-disable react/jsx-no-useless-fragment */
import { Alert, Col, Row, Skeleton, Tooltip } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import moment from 'moment';

// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { useEffect, useState, ReactNode, useRef } from 'react';
import styled from 'styled-components';

import {
  FormItemSelect,
  FormItem,
  Section,
  Collapse,
  FormItemsOperation,
  Title,
  FormItemDatePicker,
} from '../../../components';
import { useGetDockingAlertsQuery } from '../../../services/dockingApi';
import { useGetBerthsQuery } from '../../../services/dockingPlacesApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import { Docking as DockingType } from '../../../types';
import {
  dockingAlongsideList,
  dockingInLineList,
  positionDockingList,
} from '../../../utils/lists';
import { createDateStringPtBr, isNullOrUndefined } from '../../../utils/utils';

const { Panel } = Collapse;
const Container = styled.div`
  padding: 18px;

  & .ant-skeleton,
  & ant-skeleton-element,
  & ant-skeleton-active {
    display: flex;
    width: 100%;
    padding: 5px 20px;
  }

  & .ant-skeleton-input {
    width: 100%;
  }
`;

type FormItemsSummaryProps = {
  selectedDocking: DockingType | undefined;
  selectedStopover: any;
  onSelectBerth: (option: any) => void;
  allowedBerth: any | undefined;
  panelHeaderTitle: (title: string) => ReactNode;
  openCollapsePanels: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  manoeuvreBoardContext?: boolean;
  allowedCargo: any | undefined;
  allowedOperators: any | undefined;
  form: FormInstance;
};

export function FormItemsStaymentSummary(props: FormItemsSummaryProps) {
  const berthFieldRef = useRef<BaseSelectRef>(null);
  const berthFieldRowRef = useRef<HTMLDivElement>(null);
  const {
    panelHeaderTitle,
    openCollapsePanels,
    onOpenPanel,
    selectedDocking,
    manoeuvreBoardContext,
  } = props;

  const [selectedDockingGoalCode, setSelectedDockingGoalCode] = useState('');

  const [searchAlerts, setSearchAlerts] = useState({
    id: props.selectedDocking?.id,
    berthId: props.selectedDocking?.docking_place?.tag,
  });
  const [skipSearchAlerts, setSkipSearchAlerts] = useState(
    isNullOrUndefined(props.selectedDocking?.docking_place?.tag)
  );
  const [allowAddCargo, setAllowAddCargo] = useState(true);

  const {
    data: dockingAlerts,
    isLoading: dockingAlertsIsLoading,
    isFetching: dockingAlertsIsFetching,
  } = useGetDockingAlertsQuery(searchAlerts, {
    skip: skipSearchAlerts,
  });

  const { data: dockingGoalsData, isLoading: isLoadingDockingGoalsData } =
    useGetSystemParamByNameQuery({
      name: 'DOCKING_GOAL',
    });

  const { data: berthData } = useGetBerthsQuery({});

  function getAlerts(berthTag: string | undefined) {
    if (props.selectedDocking && props.selectedDocking.id && berthTag) {
      setSearchAlerts({ id: props.selectedDocking.id, berthId: berthTag });
      setSkipSearchAlerts(false);
    }
  }

  useEffect(() => {
    getAlerts(props.selectedDocking?.docking_place?.tag);
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(props.form.getFieldValue(['docking_place', 'tag']))) {
      setAllowAddCargo(false);
    }
  });

  function getPendingDocuments() {
    const pendingDocuments: string[] = props.selectedStopover.documents
      ?.filter((doc: any) => !doc.checked)
      .map((doc: any) => doc.label);

    if (pendingDocuments.length > 0) {
      return pendingDocuments.join(', ');
    }
    return '';
  }

  function onChangePilotOnBoardExpectedBerthing(
    selectedDate: any,
    manoeuvre: 'DOCKING' | 'UNDOCKING'
  ) {
    if (selectedDate) {
      if (manoeuvre === 'DOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board: moment(selectedDate).add({
            minutes: 15,
          }),
          pilot_expected_on_board: moment(selectedDate).subtract(1, 'hour'),
        });
      } else if (manoeuvre === 'UNDOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board_undocking: moment(selectedDate).add({
            minutes: 15,
          }),
          pilot_expected_on_board_undocking: moment(selectedDate).subtract(
            1,
            'hour'
          ),
        });
      }
    }
  }

  function onChangePilotOnBoard(
    selectedDate: any,
    manoeuvre: 'DOCKING' | 'UNDOCKING'
  ) {
    if (selectedDate) {
      if (manoeuvre === 'DOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board: moment(selectedDate).add({
            hours: 1,
            minutes: 15,
          }),
          expected_berthing: moment(selectedDate).add(1, 'hour'),
        });
      } else if (manoeuvre === 'UNDOCKING') {
        props.form.setFieldsValue({
          pilot_expected_leaving_on_board_undocking: moment(selectedDate).add({
            hours: 1,
            minutes: 15,
          }),
          expected_unberthing: moment(selectedDate).add(1, 'hour'),
        });
      }
    }
  }

  function formatDockingUserTracking(
    last_user: boolean | undefined,
    last_date: string | undefined
  ): string {
    if (isNullOrUndefined(last_date)) {
      return '';
    }
    return `Atualizado ${
      last_user ? 'por Suape' : 'pelo Agente'
    } em ${createDateStringPtBr(last_date)}`;
  }

  function onSelectBerthAndLoadAlerts(option: any) {
    props.onSelectBerth(option);
    getAlerts(option);
    setAllowAddCargo(true);
  }

  const getSubTitleBerth = () => {
    const berth = props.form.getFieldValue(['docking_place', 'tag']);
    return berth ? null : (
      <span
        style={{
          fontWeight: '400',
          fontSize: '12px',
          color: 'var(--neutral_medium)',
        }}
      >
        Preencha as Mercadorias para visualizar os berços correspondentes.
      </span>
    );
  };

  function onSelectDockingGoal(value: any) {
    const selectedDockingGoal = dockingGoalsData?.items.find(
      (goal) => goal.id === value
    );
    if (selectedDockingGoal) {
      setSelectedDockingGoalCode(selectedDockingGoal.code);
    }
  }

  function getBerthData() {
    if (
      selectedDockingGoalCode &&
      selectedDockingGoalCode !== 'CARGO_HANDLING'
    ) {
      return berthData?.results;
    }
    return props.allowedBerth || [];
  }

  return (
    <>
      <Container>
        <FormItem noStyle name="index" />
        <FormItem noStyle name="id" />
        <FormItem noStyle name="code" />
        <Row gutter={40}>
          {dockingAlertsIsLoading || dockingAlertsIsFetching ? (
            <Skeleton.Input active />
          ) : (
            dockingAlerts?.map((alert) => {
              return (
                <Col key={alert.name} span={12}>
                  <Alert
                    message={
                      <Tooltip arrowPointAtCenter title={alert.items.join()}>
                        <b>{alert.name}</b>
                      </Tooltip>
                    }
                    type={alert.type.toLowerCase()}
                    showIcon
                  />
                </Col>
              );
            })
          )}
        </Row>

        {props.selectedStopover.documents?.filter((doc: any) => !doc.checked)
          .length > 0 && (
          <Row
            gutter={40}
            align="bottom"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
          >
            <Col span={24}>
              <Alert
                message={
                  <Tooltip arrowPointAtCenter title={getPendingDocuments()}>
                    <b>
                      {
                        props.selectedStopover.documents?.filter(
                          (doc: any) => !doc.checked
                        ).length
                      }{' '}
                      Documentações pendentes
                    </b>
                  </Tooltip>
                }
                type="warning"
                showIcon
              />
            </Col>
          </Row>
        )}
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={12}
            name={['docking_goal', 'id']}
            label="Finalidade da atracação?"
            dataList={dockingGoalsData?.items
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            required
            isLoading={isLoadingDockingGoalsData}
            onSelect={onSelectDockingGoal}
          />
        </Row>
      </Container>
      <Collapse
        expandIconPosition="end"
        activeKey={openCollapsePanels}
        onChange={onOpenPanel}
      >
        <Panel
          forceRender
          header={panelHeaderTitle('Operações')}
          key="operation"
        >
          <FormItemsOperation
            berthFieldRef={berthFieldRef}
            berthFieldRowRef={berthFieldRowRef}
            readOnly={false}
            form={props.form}
            allowAddCargo={allowAddCargo}
            allowedCargo={props.allowedCargo}
            allowedOperators={props.allowedOperators}
            isStayment
          />
        </Panel>
      </Collapse>
      <Section
        activeKey={openCollapsePanels}
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        <Panel
          forceRender
          header={panelHeaderTitle('Dados gerais')}
          key="generalData"
        >
          <Row gutter={40} align="top" ref={berthFieldRowRef}>
            <FormItemSelect
              ref={berthFieldRef}
              colSpan={12}
              name={['docking_place', 'tag']}
              label="Berço / Local de ancoragem"
              notFoundContent="Não existem locais ativos"
              onSelect={onSelectBerthAndLoadAlerts}
              allowClear={isNullOrUndefined(
                props.selectedDocking?.docking_manoeuvre?.pilot_on_board
              )}
              dataList={
                getBerthData()
                  ? getBerthData().filter(
                      (dockingPlace: any) => dockingPlace.place_type === 'BERTH'
                    )
                  : []
              }
              // extra={getSubTitleBerth()}
            />
            <FormItemSelect
              colSpan={12}
              name="position"
              label="Posição de atracação"
              dataList={positionDockingList}
            />
          </Row>
          <Row gutter={40} align="bottom">
            <FormItemSelect
              colSpan={12}
              name="docking_in_line"
              label="Atracação em linha?"
              tooltip="Dois navios no mesmo berço"
              dataList={dockingInLineList}
            />
            <FormItemSelect
              colSpan={12}
              name="docking_alongside"
              label="Atracação à contrabordo?"
              dataList={dockingAlongsideList}
            />
          </Row>
        </Panel>
      </Section>
    </>
  );
}
