import styled from 'styled-components';

import { LightTable } from '../../components';

export const ReportsTable = styled(LightTable)`
  table {
    font-size: 12px;
    background: var(--neutral_lighter) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    tbody tr {
      height: 47.5px;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    th {
      padding-top: 10px;
      padding-bottom: 5px;
    }
    .ant-table-expanded-row {
      background: var(--neutral_lightest);
      top: -6px;
      & td {
        border-radius: 0 0 5px 5px !important;
        top: -6px;
        border-top: 1px solid var(--neutral_lighter);
      }
    }
    .ant-table-filter-column {
      justify-content: flex-start;
      .ant-table-column-title {
        max-width: fit-content;
      }
      .ant-dropdown-trigger {
        color: var(--neutral_medium);
        :hover {
          color: var(--neutral_dark);
        }
      }
    }
  }

  .ant-table-pagination {
    background: var(--neutral_lighter) !important;
    border-radius: 0px 0px 5px 5px;
    & .ant-pagination-item-active {
      background: var(--green-sea_medium);
      & a {
        color: #000;
      }
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  width: 100%;
`;
export const OperationReportDrawerHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: auto;
  z-index: 10;
  padding: 16px 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  height: 72px;
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
  background-color: white;

  .title {
    font-size: 16px;
    color: var(--suape-black);
    font-weight: 700;
    margin-inline-start: 10px;
    display: flex;

    .subtitle {
      font-family: 'Monospace';
      font-size: 14px;
      margin-left: 16px;
      font-weight: 400;
      align-self: center;
      color: var(--neutral_medium);
    }
  }
`;
