import { UploadOutlined } from '@ant-design/icons';
import { Alert, message, Row, Upload, Select, Space, Col } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Flag from 'react-flagpack';

import {
  Button,
  FormItemSelect,
  Form,
  ErrorBoundary,
  Collapse,
  Checkbox,
  FormItemDatePicker,
  Descriptions,
  FormatItem,
} from '../../components';
import { Modal } from '../../components/antd/Modal';
import { useGetCompaniesQuery } from '../../services/companyApi';
import {
  useUploadPredictedCinMutation,
  useUploadPredictedDueMutation,
} from '../../services/dueApi';
import { useGetStopoveresQuery } from '../../services/stopoverApi';
import { useGetVesselsQuery } from '../../services/vesselApi';
import { useRequestStopoverCreationMutation } from '../../services/warehousingVehiclesApi';
import { Stopover as StopoverType, SystemParamItemType } from '../../types';
import {
  createDateStringPtBr,
  disableDateTimeBeforeAndAfterDay,
  enableDatesBetween,
  isNullOrUndefined,
  removeSpecialCharacters,
} from '../../utils/utils';

type ModalDueUploadProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onFinish?: () => void;
};
const { confirm } = Modal;

const style = {
  btnImport: { padding: '16px 0px 0px 0px' },
  container: { padding: '16px' },
  upload: { padding: '12px 12px 12px 0px' },
};

const { Panel } = Collapse;
const { Item } = Descriptions;

export function ModalDueUpload(props: ModalDueUploadProps) {
  const [form] = Form.useForm();

  const { showModal, setShowModal, onFinish } = props;

  const [exportFilesLoading, setExportFilesLoading] = useState<File[]>([]);
  const [importFilesLoading, setImportFilesLoading] = useState<File[]>([]);

  const [exportFilesList, setExportFilesList] = useState<
    Array<UploadFile<any>>
  >([]);
  const [importFilesList, setImportFilesList] = useState<
    Array<UploadFile<any>>
  >([]);

  const [selectedVesselId, setSelectedVesselId] = useState('');

  const [stopover, setStopover] = useState<StopoverType>({} as StopoverType);

  const [filter, setFilter] = useState({ vessel_imo: '' });

  const [SearchCompany, setSearchCompany] = useState<string | undefined>(
    undefined
  );
  const [
    uploadFiles,
    { isSuccess: isSuccessUploadFiles, isLoading: isLoadingExportFile },
  ] = useUploadPredictedDueMutation();
  const [
    uploadCinFiles,
    { isSuccess: isSuccessUploadCinFiles, isLoading: isLoadingImportFile },
  ] = useUploadPredictedCinMutation();
  const [
    requestCreation,
    {
      isSuccess: isSuccessRequestCreation,
      isLoading: isLoadingRequestCreation,
    },
  ] = useRequestStopoverCreationMutation();

  const { data: stopoveresData, isFetching: isLoadingStopoveresData } =
    useGetStopoveresQuery(filter);

  const { data: filteredCompanies, isFetching: isLoadingCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      company_type: 'AUTOMOBILE_MANUFACTURER',
    });
  const { data: allCompanies, isFetching: isLoadingAllCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      name_or_cnpj: SearchCompany,
    });

  useEffect(() => {
    if (isSuccessRequestCreation) {
      closeModal();
      confirm({
        title: 'Atenção',
        content:
          'Somente após a criação da escala por Suape será possível concluir a criação da requisição.',
        width: 500,
        okText: 'Ok',
        cancelButtonProps: {
          hidden: true,
        },
      });
    }
  }, [isSuccessRequestCreation]);

  const filteredShipOwnerTrip = useMemo(() => {
    if (stopoveresData?.results && selectedVesselId) {
      return [
        ...stopoveresData.results.filter(
          (stopover) =>
            stopover.status !== 'CANCELED' &&
            stopover.status !== 'DONE' &&
            !isNullOrUndefined(stopover.shipowner_trip)
        ),
      ];
    }
    return [];
  }, [stopoveresData, selectedVesselId]);

  useEffect(() => {
    if (isSuccessUploadFiles) {
      closeModal();
      message.success('Arquivo importado com sucesso');
    }
  }, [isSuccessUploadFiles]);

  function optionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {option.shipowner_trip} - (ETA:{' '}
        {createDateStringPtBr(option.expected_arrival)})
      </Select.Option>
    );
  }

  function optionVesselRenderer(option: Record<string, any>) {
    const value = JSON.stringify({
      imo: option.imo,
      id: option.id,
    });
    return (
      <Select.Option key={option.id} value={value}>
        {option.name}
      </Select.Option>
    );
  }

  function closeModal() {
    setExportFilesLoading([]);
    setImportFilesLoading([]);
    setExportFilesList([]);
    setImportFilesList([]);
    onClear();
    form.resetFields();
    setrequireCreation(false);
    setStopover({} as StopoverType);
    setShowModal(false);
  }

  function onRemoveExportFiles() {
    setExportFilesLoading([]);
    setExportFilesList([]);
  }
  function onRemoveImportFiles() {
    setImportFilesLoading([]);
    setImportFilesList([]);
  }

  function beforeUploadExport(file: File) {
    setExportFilesLoading([file]);
    setExportFilesList((prev: any) => [...prev, file]);
  }
  function beforeUploadImport(file: File) {
    setImportFilesLoading([file]);
    setImportFilesList((prev: any) => [...prev, file]);
  }

  function onChangeExportFiles(info: UploadChangeParam<UploadFile<any>>) {
    if (info.file.status !== 'removed') {
      setExportFilesList((prev) => {
        return prev.map((prevItem) => {
          return {
            ...prevItem,
            ...info.file,
            status: 'done',
            url: 'tossuape',
          };
        });
      });
    }
  }
  function onChangeImportFiles(info: UploadChangeParam<UploadFile<any>>) {
    if (info.file.status !== 'removed') {
      setImportFilesList((prev) => {
        return prev.map((prevItem) => {
          return {
            ...prevItem,
            ...info.file,
            status: 'done',
            url: 'tossuape',
          };
        });
      });
    }
  }

  function onClear() {
    setStopover({} as StopoverType);
  }

  function onChangeShipownerTrip(stopover: any) {
    if (stopover) {
      setStopover(
        filteredShipOwnerTrip.find((item) => stopover === item.id) ||
          ({} as StopoverType)
      );
    }
  }

  function onChangeVesselSelected(vessel: any) {
    if (vessel) {
      setFilter((prev) => ({ ...prev, vessel_imo: JSON.parse(vessel).imo }));
      setSelectedVesselId(JSON.parse(vessel).id);
      form.setFieldsValue({ shipowner_trip: null });
      // setFilter(vessel);
    }
  }
  const [requireCreation, setrequireCreation] = useState(false);
  const [selectedExportCompany, setSelectedExportCompany] = useState();
  const [selectedImportCompany, setSelectedImportCompany] = useState();
  const [selectedExportFinancial, setSelectedExportFinancial] = useState();
  const [selectedImportFinancial, setSelectedImportFinancial] = useState();

  async function sendFiles() {
    if (requireCreation) {
      form.validateFields().then(async (values) => {
        if (!values.expected_arrival)
          return message.error(
            'Informe a previsão de chegada para criar uma solicitação'
          );
        await requestCreation({
          vessel_id: selectedVesselId,
          expected_arrival: values.expected_arrival,
        });
      });
    } else if (stopover.shipowner_trip) {
      confirm({
        title: 'Tem certeza que deseja importar a requisição?',
        width: 500,
        cancelText: 'Cancelar',
        async onOk() {
          if (exportFilesLoading.length === 1) {
            if (selectedExportCompany && selectedExportFinancial) {
              await uploadFiles({
                files: exportFilesLoading,
                stopover_id: stopover.id || 0,
                automobile_manufacturer_id: selectedExportCompany || 0,
                financial_responsible_id: selectedExportFinancial || 0,
              });
            } else {
              message.error(
                'Os campos Montadora e Responsável financeiro são obrigatórios para arquivos de Exportação'
              );
            }
          }
          if (importFilesLoading.length === 1) {
            if (selectedImportCompany && selectedImportFinancial) {
              await uploadCinFiles({
                files: importFilesLoading,
                stopover_id: stopover.id || 0,
                automobile_manufacturer_id: selectedImportCompany || 0,
                financial_responsible_id: selectedImportFinancial || 0,
              });
            } else {
              message.error(
                'Os campos Montadora e Responsável financeiro são obrigatórios para arquivos de Importação'
              );
            }
          }
          if (onFinish) {
            setShowModal(false);
            onFinish();
          }
        },
      });
    }
  }

  const [searchVesselName, setsearchVesselName] = useState('');

  const { data: vesselData, isFetching: isFetchingVesselData } =
    useGetVesselsQuery({ page_size: 100, imo_or_name: searchVesselName });

  function onChangeCheckbox(evt: CheckboxChangeEvent) {
    setrequireCreation(evt.target.checked);
    setStopover({} as StopoverType);
    form.setFieldsValue({
      shipowner_trip: null,
      exportCompany: null,
      importCompany: null,
    });
    setExportFilesLoading([]);
    setImportFilesLoading([]);
    setExportFilesList([]);
    setImportFilesList([]);
  }

  function checkIsDisabledSendButton() {
    if (requireCreation) {
      return false;
    }
    if (
      stopover.id &&
      (exportFilesLoading.length === 1 || importFilesLoading.length === 1)
    ) {
      return false;
    }
    return true;
  }

  function onSearchCompany(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchCompany(search || evt);
  }
  return (
    <Modal
      visible={showModal}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
          }}
        >
          Nova requisição de pátio de veículos{' '}
          <Button
            type="primary"
            onClick={() => sendFiles()}
            disabled={checkIsDisabledSendButton()}
            form="due_upload"
            htmlType="submit"
            loading={
              isLoadingRequestCreation ||
              isLoadingExportFile ||
              isLoadingImportFile
            }
          >
            Enviar
          </Button>
        </div>
      }
      className="TOSPrimaryModal"
      onCancel={closeModal}
      width={726}
      footer={null}
      bodyStyle={{ padding: '0px' }}
      destroyOnClose
      closable={false}
    >
      <Form form={form} name="due_upload" id="due_upload" layout="vertical">
        <div style={style.container}>
          <span style={{ display: 'inline-block', marginBottom: '10px' }}>
            Preencha os dados da viagem e anexe o(s) arquivo(s) de armazenamento
            de veículos. Se houver um armazenamento registrado para essa viagem,
            esta ação substituirá todas as informações anteriores
          </span>
          <Row gutter={16} align="middle">
            <FormItemSelect
              label="Selecione a embarcação"
              name="vessel_id"
              required
              colSpan={12}
              allowClear
              onClear={onClear}
              showSearch
              onSearch={setsearchVesselName}
              dataList={vesselData?.results
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              isLoading={isFetchingVesselData}
              optionRenderer={optionVesselRenderer}
              onSelect={onChangeVesselSelected}
            />
            <FormItemSelect
              label="Selecione o número da viagem"
              name="shipowner_trip"
              required={requireCreation !== true}
              colSpan={12}
              allowClear
              onClear={onClear}
              showSearch
              dataList={filteredShipOwnerTrip}
              isLoading={isLoadingStopoveresData}
              optionRenderer={optionRenderer}
              onSelect={onChangeShipownerTrip}
              disabled={requireCreation === true}
            />
            {stopover?.id && requireCreation === false && (
              <div
                style={{
                  padding: '16px',
                  borderRadius: '5px',
                  border: '1px solid var(--neutral_lighter)',
                  marginTop: '-10px',
                  marginBottom: '20px',
                }}
              >
                <Descriptions column={2}>
                  <Item label="Agência de navegação">
                    <FormatItem>{stopover.shipping_agency?.name}</FormatItem>
                  </Item>
                  <Item label="Nº escala mercante">
                    <FormatItem>{stopover.mercante_scale}</FormatItem>
                  </Item>
                  <Item label="Armador afretador">
                    <FormatItem>{stopover.charterer?.name}</FormatItem>
                  </Item>
                  <Item label="Nº viagem do armador">
                    <FormatItem>{stopover.shipowner_trip}</FormatItem>
                  </Item>
                  <Item label="Agência protetora">
                    <FormatItem>{stopover.protective_agency?.name}</FormatItem>
                  </Item>
                  <Item label="Tipo de navegação">
                    <FormatItem>
                      {
                        (stopover.navigation_type as SystemParamItemType)
                          ?.description
                      }
                    </FormatItem>
                  </Item>
                  <Item label="Porto de origem">
                    <FormatItem>{stopover.last_port?.name}</FormatItem>
                  </Item>
                  <Item label="Porto de destino">
                    <FormatItem>{stopover.next_port?.name}</FormatItem>
                  </Item>
                  <Item label="Previsão de chegada (ETA)">
                    <FormatItem>
                      {createDateStringPtBr(stopover.expected_arrival)}
                    </FormatItem>
                  </Item>
                  <Item label="Previsão de saída (ETA)">
                    <FormatItem>
                      {createDateStringPtBr(stopover.expected_departure)}
                    </FormatItem>
                  </Item>
                  <Item label="Embarcação">
                    {stopover.vessel?.flag && (
                      <ErrorBoundary replace="">
                        <Flag code={stopover.vessel.flag} size="small" />
                      </ErrorBoundary>
                    )}
                    <FormatItem>
                      {stopover.vessel?.imo} - {stopover.vessel?.name}
                    </FormatItem>
                  </Item>
                  <Item label="Natureza da estadia">
                    <FormatItem>{stopover.nature_of_stayment}</FormatItem>
                  </Item>
                </Descriptions>
              </div>
            )}
            <Col span={24}>
              <Checkbox onChange={(evt) => onChangeCheckbox(evt)}>
                Não encontrei minha viagem. Quero solicitar a criação desta
                escala para Suape
              </Checkbox>
            </Col>
          </Row>
          {requireCreation && (
            <Row gutter={16}>
              <FormItemDatePicker
                label="Previsão de chegada"
                name="expected_arrival"
                required
                colSpan={12}
                allowClear
                disabledDate={(e) =>
                  enableDatesBetween(
                    e,
                    moment().subtract(24, 'hour').startOf('day'),
                    null
                  )
                }
                disabledTime={(e) =>
                  disableDateTimeBeforeAndAfterDay(
                    e,
                    moment().subtract(24, 'hour'),
                    null
                  )
                }
              />
            </Row>
          )}

          {/* <Alert
            type="warning"
            message="Ao importar um novo arquivo, ocorrerá a substituição de todas as
                informações de veículos e, consequente, perda de qualquer edição feita
                anteriormente."
          /> */}
          {/* <Row justify="end" style={style.btnImport}>
            <Space size={12}>
              <Button type="text" onClick={() => closeModal()}>
                Voltar
              </Button>
              <Button
                type="primary"
                onClick={() => uploadPredictedDue()}
                disabled={!stopover.id || filesLoading.length === 0}
              >
                Importar
              </Button>
            </Space>
          </Row> */}
        </div>
        <Collapse
          expandIconPosition="end"
          defaultActiveKey={['export', 'import']}
        >
          <Panel key="export" header="Exportação">
            <div style={style.upload}>
              <span style={{ display: 'inline-block', marginBottom: '10px' }}>
                Anexe o arquivo da previsão em formato <b>xlsx</b> com os dados
                dos chassis a embarcar no Porto de Suape:
              </span>
              <Space style={{ alignItems: 'flex-start' }}>
                <Upload
                  maxCount={1}
                  accept=".xlsx"
                  onRemove={onRemoveExportFiles}
                  beforeUpload={beforeUploadExport}
                  onChange={onChangeExportFiles}
                  fileList={exportFilesList}
                >
                  <Button
                    icon={<UploadOutlined />}
                    type="primary"
                    disabled={requireCreation}
                  >
                    Anexar arquivo de previsão
                  </Button>
                </Upload>
                {requireCreation && (
                  <Alert
                    type="warning"
                    message="Não é possível anexar arquivos antes da criação da escala por
                Suape"
                  />
                )}
              </Space>
              <Row gutter={12} align="top">
                <FormItemSelect
                  name={['exportCompany', 'id']}
                  style={{
                    marginBottom: '8px',
                    marginTop: '12px',
                  }}
                  placeholder="Montadora"
                  isLoading={isLoadingCompanies}
                  dataList={filteredCompanies?.results
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  onSelect={setSelectedExportCompany}
                  disabled={requireCreation}
                  allowClear
                  colSpan={12}
                />
                <FormItemSelect
                  name={['financialExport', 'id']}
                  style={{
                    marginBottom: '8px',
                    marginTop: '12px',
                  }}
                  placeholder="Responsável financeiro"
                  isLoading={isLoadingAllCompanies}
                  dataList={allCompanies?.results
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  onSelect={setSelectedExportFinancial}
                  disabled={requireCreation}
                  allowClear
                  colSpan={12}
                  showSearch
                  onSearch={debounce(onSearchCompany, 300)}
                />
              </Row>
            </div>
          </Panel>
          <Panel key="import" header="Importação">
            <div style={style.upload}>
              <span style={{ display: 'inline-block', marginBottom: '10px' }}>
                Anexe o arquivo CIN em formato <b>txt</b> com os dados dos
                chassis a desembarcar no Porto de Suape:
              </span>
              <Row gutter={16} align="middle">
                <Space style={{ alignItems: 'flex-start' }}>
                  <Upload
                    maxCount={1}
                    accept=".txt"
                    onRemove={onRemoveImportFiles}
                    beforeUpload={beforeUploadImport}
                    onChange={onChangeImportFiles}
                    fileList={importFilesList}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      type="primary"
                      disabled={requireCreation}
                    >
                      Anexar arquivo CIN
                    </Button>
                  </Upload>
                  {requireCreation && (
                    <Alert
                      type="warning"
                      message="Não é possível anexar arquivos antes da criação da escala por
                Suape"
                    />
                  )}
                </Space>
              </Row>
              <Row gutter={12} align="top">
                <FormItemSelect
                  name={['importCompany', 'id']}
                  style={{
                    marginBottom: '8px',
                    marginTop: '12px',
                  }}
                  placeholder="Montadora"
                  isLoading={isLoadingCompanies}
                  dataList={filteredCompanies?.results
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  onSelect={setSelectedImportCompany}
                  allowClear
                  colSpan={12}
                  disabled={requireCreation}
                />
                <FormItemSelect
                  name={['financialImport', 'id']}
                  style={{
                    marginBottom: '8px',
                    marginTop: '12px',
                  }}
                  placeholder="Responsável financeiro"
                  isLoading={isLoadingAllCompanies}
                  dataList={allCompanies?.results
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  onSelect={setSelectedImportFinancial}
                  disabled={requireCreation}
                  allowClear
                  showSearch
                  onSearch={debounce(onSearchCompany, 300)}
                  colSpan={12}
                />
              </Row>
            </div>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
}
