import { ShipType } from '../types';

export function translateShipType(type: ShipType) {
  switch (type.name) {
    case 'FERRY':
      return 'Balsa';
    case 'GENERAL_CARGO':
      return 'Carga Geral';
    case 'REFRIGERATED_CARGO':
      return 'Carga Refrigerada';
    case 'FLOATING_DAM':
      return 'Dique Flutuante';
    case 'DREDGE':
      return 'Draga';
    case 'FERRY_BOAT':
      return 'Ferry Boat';
    case 'LIQUEFIED_GASES':
      return 'Gases Liquefeitos';
    case 'BULK_CARRIER':
      return 'Graneleiro';
    case 'SISTER_SHIP':
      return 'Navio Cisterna';
    case 'MILITARY_SHIP':
      return 'Navio Militar';
    case 'DRILLSHIP':
      return 'Navio Sonda';
    case 'PASSENGER':
      return 'Passageiro';
    case 'FISHERMAN':
      return 'Pesqueiro';
    case 'RESEARCH':
      return 'Pesquisa';
    case 'TANKER':
      return 'Petroleiro';
    case 'PLATFORM':
      return 'Plataforma';
    case 'CONTAINER_DOOR':
      return 'Porta Contentor';
    case 'ICE_BREAKER':
      return 'Quebra-gelo';
    case 'CHEMICAL':
      return 'Químico';
    case 'TUGBOAT':
      return 'Rebocador Empurrador';
    case 'ROLL_ON_OFF':
      return 'Roll-On Roll-off';
    case 'SUPPLY':
      return 'Supply';
    default:
      return '';
  }
}
