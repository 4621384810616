import { Form, ModalBlockNavigation } from '../../../../../components';
import { useUpdateStopoverMutation } from '../../../../../services/stopoverApi';
import { Stopover as StopoverType } from '../../../../../types';
import {
  formatStopoverToForm,
  formatStopoverToSave,
} from '../../../../../utils/formatters';
import { validateHasUnallowedMaxDraught } from '../../../../../utils/validators';
import { DockingFormHeader } from '../../../../docking/dockingFormHeader/DockingFormHeader';
import { FormItemsStopover } from '../../../stopoverForm/formItemsStopover';

type StopoverEditFormProps = {
  selectedStopover?: StopoverType;
  setSelectedStopover?: (value: StopoverType) => void;
  onFinishSubmit: () => void;
  onBack: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  setIsVisibleForm: (value: boolean) => void;
  blockNavigate: boolean;
};

export function StopoverEditForm(props: StopoverEditFormProps) {
  const {
    selectedStopover,
    setSelectedStopover,
    onFinishSubmit,
    onBack,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    setIsVisibleForm,
    blockNavigate,
  } = props;
  const [form] = Form.useForm();

  // update stopover hook
  const [updateStopover, { isLoading: isLoadingUpdateStopover }] =
    useUpdateStopoverMutation();

  // submit form after validation
  async function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      values.vessel = { id: selectedStopover?.vessel?.id };
      values.dockings = selectedStopover?.dockings;
      values.id = selectedStopover?.id;
      const update = {
        ...selectedStopover,
        ...values,
        ...formatStopoverToSave(values),
      };

      delete update[''];
      delete update.key;
      if (
        selectedStopover?.dockings &&
        selectedStopover?.dockings?.length > 0 &&
        selectedStopover.dockings[0].docking_place
      ) {
        if (
          !validateHasUnallowedMaxDraught(update, [
            selectedStopover.dockings[0].docking_place,
          ])
        )
          return;
      }
      const updatedStopover = await updateStopover(update);
      if ('data' in updatedStopover && setSelectedStopover) {
        const formattedStopover = formatStopoverToForm(updatedStopover.data);
        setSelectedStopover(formattedStopover);
        onFinishSubmit();
        setBlockNavigate(false);
        setIsFormChanged(false);
      }
    });
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      setIsVisibleForm(false);
    }
  }

  function afterCloseModal() {
    form.resetFields();
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name="stopover_edit_form"
      id="stopover_edit_form"
      onFinish={handleFormSubmit}
      initialValues={selectedStopover}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <DockingFormHeader
        formName="stopover_edit_form"
        isLoading={isLoadingUpdateStopover}
        onBack={onClose}
        editFormTitle="Dados da escala"
        code={selectedStopover?.code}
        saveButtonTitle="Salvar"
      />
      <ModalBlockNavigation
        name="stopover_edit_form"
        key="stopover_edit_form"
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <FormItemsStopover
        form={form}
        selectedStopover={selectedStopover}
        style={{ padding: '24px 16px' }}
      />
    </Form>
  );
}
