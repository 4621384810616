import { DatePicker as ANTDDatePicker } from 'antd';
import styled from 'styled-components';

const { RangePicker } = ANTDDatePicker;

export const DatePickerStylized = styled(ANTDDatePicker)`
  border: 0px;
  border-bottom: 1px solid var(--neutral_light);
  border-radius: 0px;
  width: 100%;
  & .ant-picker-input input {
    font-weight: 600;
    &::placeholder {
      color: transparent;
    }
  }

  &.ant-picker-focused {
    box-shadow: 0 0 0 transparent;
    border-bottom: 1px solid var(--green-sea_medium);
  }

  &.ant-picker-disabled {
    background: transparent;
    border-color: var(--neutral_light);
    border-bottom: 0px;
    cursor: default;
    padding-left: 0px;

    & [disabled] {
      color: #000;
      cursor: default;
    }
  }
`;
export const RangePickerStylized = styled(RangePicker)`
  border: 0px;
  border-bottom: 1px solid var(--neutral_light);
  border-radius: 0px;
  width: 100%;
  & .ant-picker-input input {
    font-weight: 600;
    &::placeholder {
      color: transparent;
    }
  }

  &.ant-picker-focused {
    box-shadow: 0 0 0 transparent;
    border-bottom: 1px solid var(--green-sea_medium);
  }

  &.ant-picker-disabled {
    background: transparent;
    border-color: var(--neutral_light);
    border-bottom: 0px;
    cursor: default;
    padding-left: 0px;

    & [disabled] {
      color: #000;
      cursor: default;
    }
  }
`;
