import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

export function CheckListItem(props: {
  checked: boolean;
  title: string;
  checkedIcon?: React.ReactNode;
  tooltipText?: string;
}) {
  return (
    <li style={{ marginBottom: '10px' }}>
      {props.checkedIcon ? (
        <Tooltip title={props.tooltipText}>{props.checkedIcon}</Tooltip>
      ) : (
        <Tooltip title={props.tooltipText}>
          {props.checked && (
            <CheckCircleFilled
              style={{ color: 'var(--success-dark)', marginRight: '10px' }}
            />
          )}
          {!props.checked && (
            <CloseCircleFilled
              style={{ color: 'var(--error-medium)', marginRight: '10px' }}
            />
          )}{' '}
        </Tooltip>
      )}
      {props.title}
    </li>
  );
}
