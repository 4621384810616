import {
  DockingPlace,
  GenericQueryParamsType,
  GenericResponse,
} from '../types';
import { getHeaders, tosApi } from './tosApi';

type BerthsResponse = GenericResponse & {
  results: Array<any>;
};
export const dockingPlacesApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getBerths: build.query<
      { results: Array<any>; count: number },
      GenericQueryParamsType & {
        shipType?: string | void;
        placeType?: string | void;
        berthName?: string;
      }
    >({
      query: (arg) => {
        const { shipType, placeType, berthName, page_size, page } = arg;
        return {
          url: `/maritime/docking-places`,
          method: 'GET',
          headers: getHeaders,
          params: {
            active: 'True',
            ship_type: shipType,
            place_type: placeType,
            name: berthName,
            page,
            page_size,
          },
        };
      },
      transformResponse: (response: BerthsResponse) => {
        response.results.forEach((berth: any) => {
          berth.key = berth.tag;
          berth.id = berth.tag;
        });
        return { results: response.results, count: response.count };
      },
      providesTags: () => [{ type: 'DockingPlace', code: '1' }],
    }),
    createBerth: build.mutation<DockingPlace, DockingPlace>({
      query: (berth) => {
        return {
          url: '/maritime/docking-places',
          method: 'POST',
          headers: getHeaders,
          body: berth,
        };
      },
      invalidatesTags: () => [{ type: 'DockingPlace', code: '1' }],
    }),
    updateBerth: build.mutation<DockingPlace, DockingPlace>({
      query: (berth) => {
        return {
          url: `/maritime/docking-places/${berth.tag}`,
          method: 'PUT',
          headers: getHeaders,
          body: berth,
        };
      },
      invalidatesTags: () => [{ type: 'DockingPlace', code: '1' }],
    }),
  }),
});

export const {
  useGetBerthsQuery,
  useLazyGetBerthsQuery,
  useCreateBerthMutation,
  useUpdateBerthMutation,
} = dockingPlacesApi;
