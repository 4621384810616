import { ConfigProvider, Spin, message } from 'antd';
import { useEffect, useState } from 'react';

import { Modal, TableWrapper } from '../../../../components';
import { useCreateDockingChargeMutation } from '../../../../services/dockingApi';
import { StopoverGroupbyDockingStatisticsType } from '../../../../types';
import { TablePageProps } from '../../Reports';
import { ReportsTable } from '../../styles';
import { columns } from './columns';

const { confirm } = Modal;

type DockingsTableProps = {
  onOpenDrawer: (docking: StopoverGroupbyDockingStatisticsType) => void;
  onSelectItem: (e: boolean, record: number) => void;
  dockingsIdToEdit: number[];
  checkedAll: boolean;
  onCheckedAll: (value: boolean) => void;
};

export function DockingsTable(props: TablePageProps & DockingsTableProps) {
  const {
    dataSource,
    scrollOptions,
    total,
    itemsPerPage,
    onChangePagination,
    isLoading,
    pageSize,
    showSizeChanger,
    rowKey,
    queryPage,
    onOpenDrawer,
    onSelectItem,
    dockingsIdToEdit,
    checkedAll,
    onCheckedAll,
  } = props;

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  const [createCharge, { isSuccess: isSuccessCreateCharge }] =
    useCreateDockingChargeMutation();

  const [isLoadingCreateCharge, setIsLoadingCreateCharge] = useState(false);

  function onCreateCharge(docking_id: number) {
    confirm({
      title: `Tem certeza que deseja gerar o faturamento para essa atracação?`,
      onOk() {
        setIsLoadingCreateCharge(true);
        createCharge(docking_id);
      },
      okButtonProps: { loading: isLoadingCreateCharge },
      okText: 'Sim',
      cancelText: 'Não',
    });
  }

  useEffect(() => {
    if (isSuccessCreateCharge) {
      message.success('Faturamento gerado com sucesso');
      setIsLoadingCreateCharge(false);
    }
    if (isLoadingCreateCharge) {
      message.info('Gerando faturamento...');
    }
  }, [isSuccessCreateCharge, isLoadingCreateCharge]);
  return (
    <Spin spinning={isLoading} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <ReportsTable
            rowKey={rowKey || 'key'}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: itemsPerPage,
              pageSize,
              total,
              onChange: onChangePagination,
              showSizeChanger,
              current: queryPage,
            }}
            columns={columns(
              dockingsIdToEdit,
              onSelectItem,
              onOpenDrawer,
              checkedAll,
              onCheckedAll,
              onCreateCharge
            )}
            dataSource={dataSource}
            scroll={
              scrollOptions || { y: '60vh', scrollToFirstRowOnChange: false }
            }
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
