import { CalendarOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { CraneIcon } from '../../../assets/icons/craneIcon';
import { DropIcon } from '../../../assets/icons/dropIcon';
import { Button, StatusTag } from '../../../components';
import { ResourceQuantityTag } from '../../../components/common/ResourceQuantityTag/ResourceQuantityTag';
import { createDateStringPtBr } from '../../../utils/utils';

export function columns(
  onSelectItem: (item: any) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title: 'Nome do berço',
      key: 'name',
      width: '14%',
      render: (_: any, record: any) => (
        <>
          <span>{record.name}</span>
          {record.total_cranes && record.total_cranes > 0 ? (
            <>
              &nbsp;
              <ResourceQuantityTag
                style={{ background: '#D46B08' }}
                resourceIcon={<CraneIcon />}
                quantity={record.total_cranes}
              />
            </>
          ) : null}
          {record.total_lines && record.total_lines > 0 ? (
            <>
              &nbsp;
              <ResourceQuantityTag
                style={{ background: 'var(--ocean_medium)' }}
                resourceIcon={<DropIcon />}
                quantity={record.total_lines}
              />
            </>
          ) : null}
        </>
      ),
    },
    {
      title: 'Profundidade',
      key: 'depth',
      dataIndex: 'depth',
      width: '11%',
      render: (_: any, record: any) => `${record.depth} m`,
    },
    {
      title: 'Calado CMA',
      key: 'max_draught',
      dataIndex: 'max_draught',
      width: '11%',
      render: (_: any, record: any) => `${record.max_draught} m`,
    },
    {
      title: 'Capacidade DWT',
      key: 'dwt',
      dataIndex: 'dwt',
      width: '13%',
      render: (_: any, record: any) => `${record.dwt} ton`,
    },
    {
      title: 'Comprimento',
      key: 'length',
      dataIndex: 'length',
      width: '11%',
      render: (_: any, record: any) => `${record.length} m`,
    },
    {
      title: 'LOA',
      key: 'loa',
      dataIndex: 'loa',
      width: '10%',
      render: (_: any, record: any) => `${record.loa} m`,
    },
    {
      title: 'Manutenções previstas',
      key: 'next_scheduled_maintenance_date',
      width: '27%',
      render: (_: any, record: any) =>
        record.next_scheduled_maintenance_date ? (
          <>
            <StatusTag>
              <span className="neutral-blue">manutenção programada</span>
            </StatusTag>
            &nbsp;
            <CalendarOutlined />
            &nbsp;
            <span style={{ fontSize: '12px' }}>
              {createDateStringPtBr(record.next_scheduled_maintenance_date)}
            </span>
          </>
        ) : (
          <span
            style={{
              fontSize: '14px',
              color: 'var(--neutral_medium)',
              fontStyle: 'italic',
            }}
          >
            Não há parada de manutenção prevista
          </span>
        ),
    },
    {
      dataIndex: 'edit',
      align: 'center',
      render: (_: any, record: any) => (
        <Button
          icon={<EyeOutlined />}
          style={{ float: 'right' }}
          onClick={(e: any) => {
            e.stopPropagation();
            onSelectItem(record);
          }}
        />
      ),
    },
  ];
}
