import { ConfigProvider } from 'antd';

import { SlimTable, TableWrapper } from '../../../../../../components';
import { StorageGroupbyProductType } from '../../../../../../types';
import { columns } from './columns';

type NcmByOperatorTableProps = {
  dataSource: Partial<StorageGroupbyProductType>[];
};

export function NcmByOperatorTable(props: NcmByOperatorTableProps) {
  const { dataSource } = props;

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  const direction =
    dataSource && dataSource.length > 0
      ? dataSource[0].operation_direction || ''
      : '';

  return (
    <TableWrapper>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <SlimTable
          rowKey="id"
          columns={columns(direction)}
          dataSource={dataSource}
          className="read-only"
          pagination={false}
        />
      </ConfigProvider>
    </TableWrapper>
  );
}
