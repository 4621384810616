import styled from 'styled-components';

export const GeneralCargoDescriptionSection = styled.div`
  padding: 16px 32px;

  & .ant-collapse-content {
    border: 1px solid #ccdde9 !important;
  }

  & .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

export const DescriptionSection = styled.div`
  padding: 16px;
  background-color: #ebf2f7;

  & .ant-row {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    & .ant-col {
      padding-left: 4px;
      padding-right: 4px;

      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
  }
`;

export const DescriptionCard = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  height: 100%;

  & .header {
    align-items: center;
    margin-bottom: 12px;

    .title {
      font-weight: bold;
      text-transform: capitalize;
    }
    .flag {
      margin-right: 8px;
    }
  }

  &
    .ant-descriptions:last-child
    .ant-descriptions-row:last-child
    .ant-descriptions-item {
    padding-bottom: 0px;
  }
`;
