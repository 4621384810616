import { FormInstance, Row } from 'antd';
import { debounce } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';

import { FormItem, FormItemInput, FormItemSelect } from '../../../components';
import {
  useGetPortsCountriesQuery,
  useGetPortsQuery,
} from '../../../services/vesselApi';
import { OperationProductCeMercanteType } from '../../../types';

type FormItemsOperationMerchantCEProps = {
  form: FormInstance;
  readOnly?: boolean;
  selectedMerchantCE: OperationProductCeMercanteType | undefined;
  style?: CSSProperties;
};

export function FormItemsOperationMerchantCE(
  props: FormItemsOperationMerchantCEProps
) {
  const { form, selectedMerchantCE } = props;

  const [selectedOriginPort, setSelectedOriginPort] = useState(Object);
  const [selectedOriginPortCountry, setSelectedOriginPortCountry] =
    useState<string>();
  const [searchOriginPortCountry, setSearchOriginPortCountry] = useState({});
  const [skipSearchOriginPortCountry, setSkipSearchOriginPortCountry] =
    useState(true);
  const [searchOriginPort, setSearchOriginPort] = useState({});
  const [skipSearchOriginPort, setSkipSearchOriginPort] = useState(true);

  const [selectedDestinyPort, setSelectedDestinyPort] = useState(Object);
  const [selectedDestinyPortCountry, setSelectedDestinyPortCountry] =
    useState<string>();
  const [searchDestinyPortCountry, setSearchDestinyPortCountry] = useState({});
  const [skipSearchDestinyPortCountry, setSkipSearchDestinyPortCountry] =
    useState(true);
  const [searchDestinyPort, setSearchDestinyPort] = useState({});
  const [skipSearchDestinyPort, setSkipSearchDestinyPort] = useState(true);

  const { data: originPortsData, isLoading: isLoadingOriginPortData } =
    useGetPortsQuery(searchOriginPort, {
      skip: skipSearchOriginPort,
    });
  const { data: destinyPortsData, isLoading: isLoadingDestinyPortData } =
    useGetPortsQuery(searchDestinyPort, {
      skip: skipSearchDestinyPort,
    });
  const {
    data: countriesDataOriginPort,
    isLoading: isLoadingCountriesDataOriginPort,
  } = useGetPortsCountriesQuery(searchOriginPortCountry, {
    skip: skipSearchOriginPortCountry,
  });
  const {
    data: countriesDataDestinyPort,
    isLoading: isLoadingCountriesDataDestinyPort,
  } = useGetPortsCountriesQuery(searchDestinyPortCountry, {
    skip: skipSearchDestinyPortCountry,
  });

  useEffect(() => {
    if (!props.readOnly) {
      const destiny_port_country_code = form.getFieldValue([
        'destiny_port',
        'country_code',
      ]);
      const origin_port_country_code = form.getFieldValue([
        'origin_port',
        'country_code',
      ]);

      if (destiny_port_country_code) {
        setSelectedDestinyPortCountry(destiny_port_country_code);
        setSearchDestinyPort({ countryCode: destiny_port_country_code });
        setSkipSearchDestinyPort(false);
      }
      if (origin_port_country_code) {
        setSelectedOriginPortCountry(origin_port_country_code);
        setSearchOriginPort({ countryCode: origin_port_country_code });
        setSkipSearchOriginPort(false);
      }
    }
  }, [props.readOnly]);

  useEffect(() => {
    form.setFieldsValue({
      destiny_port: {
        ...selectedDestinyPort,
      },
      origin_port: {
        ...selectedOriginPort,
      },
    });
  }, [selectedDestinyPort, selectedOriginPort]);

  useEffect(() => {
    const destinyPortObject = destinyPortsData?.results.find((port) => {
      return port.id === selectedMerchantCE?.destiny_port?.bi_trigram;
    });
    setSelectedDestinyPort({ ...destinyPortObject });

    const originPortObject = originPortsData?.results.find((port) => {
      return port.id === selectedMerchantCE?.origin_port?.bi_trigram;
    });
    setSelectedOriginPort({ ...originPortObject });
  }, [selectedMerchantCE, destinyPortsData, originPortsData]);

  function onSearchCountryOriginPortByName(val: string) {
    setSearchOriginPortCountry({ countryName: val });
    setSkipSearchOriginPortCountry(false);
  }
  function onSearchOriginPortByName(val: string) {
    setSearchOriginPort({
      name: val,
      countryCode: selectedOriginPortCountry,
    });
    setSkipSearchOriginPort(false);
  }
  function onSelectOriginPortCountry(val: any) {
    setSelectedOriginPortCountry(val);
    setSearchOriginPort({ countryCode: val });
    setSkipSearchOriginPort(false);
    setSelectedOriginPort({
      country_code: val,
      name: null,
      bi_trigram: null,
    });
  }
  function onSelectOriginPort(option: string) {
    const portObject = originPortsData?.results.find((port) => {
      return port.id === option;
    });
    setSelectedOriginPort({ ...portObject });
  }
  function onClearSelectedOriginPortCountry() {
    setSelectedOriginPortCountry('');
    setSelectedOriginPort({});
  }
  function onClearSelectedOriginPort() {
    setSelectedOriginPort({});
  }

  function onSearchCountryDestinyPortByName(val: string) {
    setSearchDestinyPortCountry({ countryName: val });
    setSkipSearchDestinyPortCountry(false);
  }
  function onSearchDestinyPortByName(val: string) {
    setSearchDestinyPort({
      name: val,
      countryCode: selectedDestinyPortCountry,
    });
    setSkipSearchDestinyPort(false);
  }
  function onSelectDestinyPortCountry(val: any) {
    setSelectedDestinyPortCountry(val);
    setSearchDestinyPort({ countryCode: val });
    setSkipSearchDestinyPort(false);
    setSelectedDestinyPort({
      country_code: val,
      name: null,
      bi_trigram: null,
    });
  }
  function onSelectDestinyPort(option: string) {
    const portObject = destinyPortsData?.results.find((port) => {
      return port.id === option;
    });
    setSelectedDestinyPort({ ...portObject });
  }
  function onClearSelectedDestinyPortCountry() {
    setSelectedDestinyPortCountry('');
    setSelectedDestinyPort({});
  }
  function onClearSelectedDestinyPort() {
    setSelectedDestinyPort({});
  }

  return (
    <div style={props.style}>
      <Row gutter={40} align="bottom">
        <FormItemInput
          colSpan={24}
          name="code"
          label="Número"
          readOnly={props.readOnly}
          maxLength={20}
          rules={[{ required: true, message: 'campo obrigatório' }]}
        />
      </Row>
      <Row gutter={40} align="bottom">
        <FormItemSelect
          colSpan={6}
          disabled={props.readOnly}
          name={['origin_port', 'country_name']}
          label="País de origem"
          allowClear
          showSearch
          onSearch={debounce(onSearchCountryOriginPortByName, 400)}
          onSelect={onSelectOriginPortCountry}
          onClear={onClearSelectedOriginPortCountry}
          dataList={countriesDataOriginPort?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCountriesDataOriginPort}
          notFoundContent="Nenhum país encontrado para o filtro informado"
        />
        <FormItem name={['origin_port', 'bi_trigram']} noStyle />
        <FormItemSelect
          colSpan={6}
          disabled={props.readOnly}
          name={['origin_port', 'name']}
          label="Porto de origem"
          allowClear
          showSearch
          onSearch={debounce((e) => onSearchOriginPortByName(e), 400)}
          onSelect={onSelectOriginPort}
          onClear={onClearSelectedOriginPort}
          dataList={originPortsData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingOriginPortData}
          notFoundContent="Nenhum porto encontrado para o filtro informado"
          rules={[{ required: true, message: 'campo obrigatório' }]}
        />
        <FormItemSelect
          colSpan={6}
          disabled={props.readOnly}
          name={['destiny_port', 'country_name']}
          label="País de destino"
          allowClear
          showSearch
          onSearch={debounce(onSearchCountryDestinyPortByName, 400)}
          onSelect={onSelectDestinyPortCountry}
          onClear={onClearSelectedDestinyPortCountry}
          dataList={countriesDataDestinyPort?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCountriesDataDestinyPort}
          notFoundContent="Nenhum país encontrado para o filtro informado"
        />
        <FormItem name={['destiny_port', 'bi_trigram']} noStyle />
        <FormItemSelect
          colSpan={6}
          disabled={props.readOnly}
          name={['destiny_port', 'name']}
          label="Porto de destino"
          allowClear
          showSearch
          onSearch={debounce((e) => onSearchDestinyPortByName(e), 400)}
          onSelect={onSelectDestinyPort}
          onClear={onClearSelectedDestinyPort}
          dataList={destinyPortsData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingDestinyPortData}
          notFoundContent="Nenhum porto encontrado para o filtro informado"
          rules={[{ required: true, message: 'campo obrigatório' }]}
        />
      </Row>
    </div>
  );
}
