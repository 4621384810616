import { LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { removeToken } from '../../../app/store/authStore';
import { logout } from '../../../app/store/userStore';
import { LogoPmsi } from '../../../assets/logoPmis/logoPmis';
import { SideNavbarItensType } from '../../../types';
import { VehiclesWarehousingOperatorsCompanies } from '../../../utils/lists';
import {
  ADMIN_ID,
  OPERATOR_GROUP_ID,
  VEHICLES_WAREHOUSING_OPERATOR_GROUP_ID,
} from '../../../utils/utils';
import {
  ButtonLogout,
  LogoWrapper,
  MenuItem,
  Sider,
  StyledMenu,
  StyledSubMenu,
} from './styles';

type SideNavbarProps = {
  navbarItens: SideNavbarItensType[];
};

export function SideNavbar(props: SideNavbarProps) {
  const { navbarItens } = props;
  const [collapsed, setCollapsed] = useState(true);

  // para selecionar a opção certa do menu ao dar refresh na pagina
  const selectedPage = window.location.pathname;
  const firstPage = selectedPage.split('/')[1];
  const dispatch = useDispatch();
  const { groups, isSuperuser, company } = useAppSelector(
    (state: RootState) => state.user.user
  );

  const isSuperuserAdmin =
    isSuperuser || groups?.find((group) => group.id === ADMIN_ID);
  const isVehiclesWarehousingOperator =
    !isSuperuserAdmin &&
    VehiclesWarehousingOperatorsCompanies.some((companyName) =>
      company?.name.toLocaleLowerCase().includes(companyName)
    );

  async function handleLogout() {
    dispatch(removeToken());
    dispatch(logout());
  }
  function hasPermission(item: any) {
    if (
      isVehiclesWarehousingOperator &&
      groups?.find((group) => group.id === OPERATOR_GROUP_ID) &&
      item.permissions?.includes(VEHICLES_WAREHOUSING_OPERATOR_GROUP_ID)
    ) {
      return true;
    }
    return (
      isSuperuserAdmin ||
      item.permissions?.some((permission: number) =>
        groups?.some((group) => group.id === permission)
      )
    );
  }
  return (
    <Sider
      theme="light"
      width={250}
      trigger={null}
      collapsible
      collapsed={collapsed}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
    >
      <LogoWrapper collapsed={collapsed}>
        <LogoPmsi />
      </LogoWrapper>
      <div
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
          marginTop: '15px',
        }}
      >
        <StyledMenu
          theme="light"
          defaultSelectedKeys={[selectedPage]}
          defaultOpenKeys={[firstPage]}
          mode="inline"
          style={{
            border: '0px',
          }}
        >
          {navbarItens.map((item) => {
            if (hasPermission(item)) {
              return item.subItens ? (
                <StyledSubMenu
                  key={item.key}
                  title={item.title}
                  icon={
                    <div>
                      <item.icon />
                    </div>
                  }
                >
                  {item.subItens.map((subItem) => {
                    return hasPermission(subItem) ? (
                      <Menu.Item key={subItem.key}>
                        <Link to={subItem.key}>{subItem.title}</Link>
                      </Menu.Item>
                    ) : null;
                  })}
                </StyledSubMenu>
              ) : (
                <MenuItem
                  key={item.key}
                  icon={
                    <div>
                      <item.icon />
                    </div>
                  }
                >
                  <Link to={item.key}>{item.title}</Link>
                </MenuItem>
              );
            }
            return null;
          })}
        </StyledMenu>
      </div>
      <div>
        <ButtonLogout
          style={{
            width: collapsed ? '60px' : '90%',
            backgroundColor: collapsed
              ? 'var(--neutral_lightest)'
              : 'var(--brand-deep-green)',
          }}
          onClick={handleLogout}
          icon={
            <LogoutOutlined
              rotate={180}
              style={{ color: collapsed ? '#000' : 'var(--neutral_lightest)' }}
            />
          }
        >
          {!collapsed && 'Deslogar'}
        </ButtonLogout>
      </div>
    </Sider>
  );
}
