import styled from 'styled-components';

export const FormHeaderOperatorArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-end: 16px;

  .top-title {
    color: var(--neutral_light);
    margin-block-end: 6px;
  }

  .title {
    display: flex;
    color: var(--suape-black);
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    margin-block-end: 10px;
  }

  .subtitle {
    color: var(--neutral_medium);
  }
`;

export const CardRenderer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  .item-data {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  .space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .color-neutral-medium {
    color: var(--neutral_medium);
  }

  .text-hide {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
  }

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  .subtitle {
    font-weight: 600;
  }

  .code {
    font-family: 'Consolas';
  }
`;

export const OperationProductsResumeHeader = styled.div`
  background-color: #fff;
  /* margin-block-start: 4px; */
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;

  & .header {
    align-items: center;
    margin-bottom: 5px;

    & .flag {
      margin-right: 4px;
    }

    & .title {
      text-transform: capitalize;
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }

  & .title {
    margin-bottom: 8px;

    &__berth {
      font-weight: 600;
      font-size: 14px;
    }
    &__shipping-agency {
      font-size: 12px;
      color: #5f7b91;
      margin-left: 8px;
    }
  }

  & .ant-descriptions-item-content {
    /* text-transform: capitalize; */
  }
`;
