import {
  ReactCalendarGroupRendererProps,
  TimelineGroupBase,
} from 'react-calendar-timeline';
import Flag from 'react-flagpack';

import ErrorBoundary from '../../components/common/ErrorBoundary';
import { ItemType } from './AnchoringWindow';

export function dockingPlaceRenderer(
  groupProps: ReactCalendarGroupRendererProps<TimelineGroupBase>,
  itemsToRender: ItemType[],
  onClickVesselNameSideColumn: (item: ItemType) => void
) {
  const dockingPlaceVessels = itemsToRender.filter(
    (item) => item.group === groupProps.group.id
  );
  return (
    <div className="custom-group">
      <span className="title">{groupProps.group.title}</span>
      <div className="vessel-name-list">
        {dockingPlaceVessels
          .filter(
            (item) =>
              ![
                'PUMPING_ORIGIN',
                'PUMPING_DESTINATION',
                'MAINTENANCE',
              ].includes(item.type)
          )
          .map((dockingPlaceVessel) => {
            if (dockingPlaceVessel.stopover?.vessel) {
              return (
                <div
                  key={dockingPlaceVessel.id}
                  className="vessel-name"
                  onClick={() =>
                    onClickVesselNameSideColumn(dockingPlaceVessel)
                  }
                >
                  <div className="vessel-flag">
                    <ErrorBoundary replace="">
                      {dockingPlaceVessel.stopover.vessel.flag && (
                        <Flag
                          size="m"
                          code={dockingPlaceVessel.stopover.vessel.flag}
                          hasBorder={false}
                        />
                      )}
                    </ErrorBoundary>
                  </div>
                  {dockingPlaceVessel.stopover.vessel.name.toLowerCase()}
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
