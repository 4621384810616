import styled from 'styled-components';

import { Collapse } from '../../../components';

export const DockingsCollapse = styled(Collapse)`
  & .ant-collapse-item {
    margin-block-start: 24px;
    border: 1px solid var(--neutral_lighter);

    & .ant-collapse-header {
      display: flex;
      flex-direction: row;
      background: var(--neutral_lightest);
      font-size: 16px;
      color: var(--neutral_medium) !important;
      padding-left: 16px !important;
      /* & .ant-collapse-header-text {
      } */
      & .docking-header-title {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: end;

        & .docking-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }

        & .docking-code {
          font-family: Consolas;
          font-weight: normal;
          font-size: 14px;
          display: inline-block;
        }
      }
    }
  }
`;

export const ContainerProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral_lighter);
  padding: 16px;

  hr {
    border: none;
    border-top: 1px dashed var(--neutral_light);
    height: 1px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const TitleEdit = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
`;
