import { EditFilled } from '@ant-design/icons';

import { Button } from '../../../components';

export function columns(onSelectItem: (item: any) => void) {
  return [
    {
      title: <div className="show-amount">Rebocadores</div>,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Código',
      key: 'imo',
      dataIndex: 'imo',
    },
    {
      title: 'Bollard pull',
      key: 'bollard_pull',
      dataIndex: 'bollard_pull',
    },
    {
      render: (_: any, record: any) => (
        <Button
          icon={<EditFilled />}
          style={{ float: 'right' }}
          onClick={() => onSelectItem(record)}
        />
      ),
    },
  ];
}
