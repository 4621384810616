import { Dispatch, SetStateAction } from 'react';

import { Drawer } from '../../../components';
import { Stopover as StopoverType } from '../../../types';
import { ChargeForm } from './chargeForm';

type ChargeDrawerProps = {
  visibleDrawer: boolean;
  closeDrawer: () => void;
  hasEdited: boolean;
  setHasEdited: (value: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate: (value: boolean) => void;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
};

export function ChargeDrawer(props: ChargeDrawerProps) {
  const {
    visibleDrawer,
    closeDrawer,
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
    setSelectedStopover,
  } = props;

  function onCloseDrawer() {
    if (hasEdited) {
      setBlockNavigate(true);
    } else {
      closeDrawer();
    }
  }

  return (
    <Drawer
      visible={visibleDrawer}
      placement="right"
      width={820}
      closable={false}
      destroyOnClose
      onClose={() => onCloseDrawer()}
    >
      <ChargeForm
        selectedStopover={{} as StopoverType}
        setHasEdited={setHasEdited}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        onCloseDrawer={onCloseDrawer}
        onCloseBlockNavigation={closeDrawer}
        setSelectedStopover={setSelectedStopover}
      />
    </Drawer>
  );
}
