import { Button as ANTDButton } from 'antd';
import styled from 'styled-components';

export const Button = styled(ANTDButton)`
  font-weight: bold;
  color: var(--green-sea_dark) !important;
  border: 2px solid var(--green-sea_dark);
  &:hover {
    color: #000;
  }
  &.ant-btn-sm {
    font-size: 12px;
  }
  &.ant-btn-dangerous {
    border-color: var(--error-medium) !important;
    & span {
      color: var(--error-medium);
    }
  }
  &.ant-btn-primary {
    border: 2px solid var(--green-sea_medium);
    & span {
      color: #000;
    }
  }
  &.ant-btn-primary.ant-btn-dangerous {
    & span {
      color: #fff;
    }
    &[disabled] {
      & span {
        color: var(--neutral_light) !important;
      }
    }
  }
  &[disabled] {
    background: var(--neutral_lighter);
    border: 0px;
    cursor: default;
    &:hover {
      background: var(--neutral_lighter);
    }
    & span {
      color: var(--neutral_light);
    }
  }
  &.ant-btn-text {
    border: 0px !important;
    color: var(--neutral_dark) !important;
    &[disabled] {
      background: transparent;
    }
  }
  @media only screen and (max-width: 1280px) {
    /* &.ant-btn { 
            font-size: 0.75rem; 
        } */
  }
  &.ant-btn-link {
    color: var(--ant-primary-color);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    font-weight: 400;
    &[disabled] {
      background: transparent;
    }
  }
`;
