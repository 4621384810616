import { FlagOutlined, UnlockOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Flag from 'react-flagpack';

import {
  Button,
  Collapse,
  Descriptions,
  Drawer,
  ErrorBoundary,
  FormatItem,
  GenericDrawerHeader,
  InnerCollapse,
  StaymentLog,
  Title,
} from '../../../../components';
import { useCollapsePanels } from '../../../../hooks';
import { useLazyGetStopoverQuery } from '../../../../services/stopoverApi';
import {
  Stopover as StopoverType,
  StopoverGroupbyDockingStatisticsType,
  StopoverTypeDescription,
  VesselTypeDescription,
  Vessel as VesselType,
  DockingTypeDescription,
  Docking as DockingType,
} from '../../../../types';
import {
  formatStopoverToDescription,
  formatVesselToDescription,
} from '../../../../utils/formatters';
import { translateCountryCode } from '../../../../utils/translateCountryCode';
import { createDateStringPtBr } from '../../../../utils/utils';
import { formatDockingToDescription } from '../../../docking/dockingsData/formatDockingToDescription';
import { InvoicingStatusTag } from '../InvoicingStatusTag';
import { ManifestsGroupbyOperatorCollapse } from './manifestsGroupbyOperatorCollapse';
import { ManoeuvreDescriptionCollapse } from './manoeuvreDescriptionCollapse';
import { StoppagesTotalTable } from './stoppagesTotalTable/StoppagesTotalTable';
import { Container, Header } from './styles';

type DockingReportDrawerProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  selectedDocking: StopoverGroupbyDockingStatisticsType;
  setSelectedDocking: Dispatch<
    SetStateAction<StopoverGroupbyDockingStatisticsType>
  >;
};

const { Panel } = Collapse;
const { Panel: InnerPanel } = InnerCollapse;
const { Item } = Descriptions;

const collapsePanels = [
  'generalDataStopover',
  'generalDataDocking',
  'operations',
  'bollardsAndFenders',
  'docking_manoeuvre',
  'undocking_manoeuvre',
];

export function DockingReportDrawer(props: DockingReportDrawerProps) {
  const { isVisible, setIsVisible, selectedDocking, setSelectedDocking } =
    props;

  const [stopoverDescription, setStopoverDescription] = useState(
    {} as StopoverTypeDescription
  );

  const [dockingDescription, setDockingDescription] = useState(
    {} as DockingTypeDescription
  );

  const [vesselDescription, setVesselDescription] = useState(
    {} as VesselTypeDescription
  );

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const [getStopoverById] = useLazyGetStopoverQuery();

  useEffect(() => {
    if (isVisible) {
      getStopoverById(selectedDocking.stopover__id).then((response) => {
        setStopoverDescription(
          formatStopoverToDescription(response.data || ({} as StopoverType))
        );
        setDockingDescription(
          formatDockingToDescription(
            response.data?.dockings?.find(
              (docking) => docking.id === selectedDocking.docking__id
            ) || ({} as DockingType)
          )
        );
        setVesselDescription(
          formatVesselToDescription(response.data?.vessel || ({} as VesselType))
        );
      });
    }
  }, [isVisible]);

  function onCloseDrawer() {
    setIsVisible(false);
    setSelectedDocking({} as StopoverGroupbyDockingStatisticsType);
  }

  function getTitle() {
    return (
      <Header>
        <div className="title">
          <span style={{ fontSize: '16px', fontWeight: '600' }}>
            {selectedDocking.code}
          </span>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '20px' }}>
              <ErrorBoundary replace="">
                <Flag code={selectedDocking.vessel__flag} size="s" />
              </ErrorBoundary>
            </div>
            <span style={{ color: 'var(--neutral_medium)' }}>
              {selectedDocking.vessel__name}
            </span>
          </div>
          <InvoicingStatusTag
            status={selectedDocking?.invoicing_status || ''}
          />
        </div>
        <span className="subtitle">
          {selectedDocking.last_date_xml
            ? `XML gerado em ${createDateStringPtBr(
                selectedDocking.last_date_xml
              )}`
            : 'XML ainda não gerado'}
        </span>
        <div className="buttons">
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              style={{ fontSize: '12px', padding: '5px' }}
              icon={<FlagOutlined />}
            >
              Marcar para revisão
            </Button>
            <Button
              style={{ fontSize: '12px', padding: '5px' }}
              icon={<UnlockOutlined />}
            >
              Desbloquear edição
            </Button>
          </div>
          <div className="expand">
            <Switch
              defaultChecked
              onChange={onChangeSwitch}
              checked={
                (openCollapsePanels || []).length === collapsePanels.length
              }
            />
            <span className="switchLabel">Exibir tudo</span>
          </div>
        </div>
      </Header>
    );
  }

  return (
    <Drawer visible={isVisible} closable={false} width={1100} destroyOnClose>
      <GenericDrawerHeader
        title={getTitle()}
        showBackButton
        onBack={() => onCloseDrawer()}
      />
      <Container>
        <StaymentLog selectedDocking={selectedDocking.docking__id} />
        <Collapse
          expandIconPosition="end"
          onChange={onOpenPanel}
          activeKey={openCollapsePanels}
        >
          <Panel
            forceRender
            header="Dados gerais da escala"
            key="generalDataStopover"
          >
            <Descriptions>
              <Item label="Trigrama do porto">
                <FormatItem>{null}</FormatItem>
              </Item>
              <Item label="Embarcação de apoio?">
                <FormatItem>{null}</FormatItem>
              </Item>
              <Item label="Embarcação de comboio">
                <FormatItem>{null}</FormatItem>
              </Item>
            </Descriptions>
            <div className="div-border">
              <div
                style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}
              >
                <ErrorBoundary replace="">
                  <Flag code={vesselDescription.flag} size="s" />
                </ErrorBoundary>
                <strong>{vesselDescription.name}</strong>
                <span style={{ color: 'var(--neutral_medium)' }}>
                  {vesselDescription.imo}
                </span>
              </div>
              <Descriptions>
                <Item label="Tipo de embarcação">
                  <FormatItem>{vesselDescription.ship_type}</FormatItem>
                </Item>
                <Item label="MMSI">
                  <FormatItem>{vesselDescription.mmsi}</FormatItem>
                </Item>
                <Item label="Inscrição da capitania">
                  <FormatItem>
                    {vesselDescription.captaincy_registration}
                  </FormatItem>
                </Item>
                <Item label="Bandeira">
                  <FormatItem>
                    <div
                      style={{ marginRight: '4px', display: 'inline-block' }}
                    >
                      <ErrorBoundary replace="">
                        <Flag code={vesselDescription.flag} size="s" />
                      </ErrorBoundary>
                    </div>
                    {translateCountryCode(vesselDescription.flag || '')}
                  </FormatItem>
                </Item>
                <Item label="Avante à meia-nau?">
                  <FormatItem>{vesselDescription.forward_amidship}</FormatItem>
                </Item>
                <Item label="Boca - Largura">
                  <FormatItem>{vesselDescription.width}</FormatItem>
                </Item>
                <Item label="Armador proprietário">
                  <FormatItem>{vesselDescription.shipowner}</FormatItem>
                </Item>
                <Item label="Calado mínimo">
                  <FormatItem>{vesselDescription.draught_min}</FormatItem>
                </Item>
                <Item label="Calado máximo">
                  <FormatItem>{vesselDescription.draught_max}</FormatItem>
                </Item>
                <Item label="Tipo de cabo amarração">
                  <FormatItem>{vesselDescription.dock_line_type}</FormatItem>
                </Item>
                <Item label="LOA comprimento">
                  <FormatItem>{vesselDescription.loa}</FormatItem>
                </Item>
                <Item label="DWT Porte">
                  <FormatItem>{vesselDescription.dwt}</FormatItem>
                </Item>
              </Descriptions>
            </div>
            <div className="div-border">
              <strong style={{ marginBottom: '10px' }}>DUV </strong>
              <span
                style={{
                  color: 'var(--neutral_medium)',
                }}
              >
                <FormatItem>{stopoverDescription.duv}</FormatItem>
              </span>
              <Descriptions>
                <Item label="Agência de navegação">
                  <FormatItem>{stopoverDescription.shipping_agency}</FormatItem>
                </Item>
                <Item label="Agência protetora">
                  <FormatItem>
                    {stopoverDescription.protective_agency}
                  </FormatItem>
                </Item>
                <Item label="Previsão de chegada (ETA)">
                  <FormatItem>
                    {stopoverDescription.expected_arrival_ais}
                  </FormatItem>
                </Item>
                <Item label="Nº escala mercante">
                  <FormatItem>{stopoverDescription.mercante_scale}</FormatItem>
                </Item>
                <Item label="Tipo de navegação">
                  <FormatItem>{stopoverDescription.navigation_type}</FormatItem>
                </Item>
                <Item label="Previsão de saída(ETD)">
                  <FormatItem>
                    {stopoverDescription.expected_departure}
                  </FormatItem>
                </Item>
                <Item label="Armador afretador">
                  <FormatItem>{stopoverDescription.charterer}</FormatItem>
                </Item>
                <Item label="Porto de origem">
                  <FormatItem>
                    {stopoverDescription.last_port
                      ? `${stopoverDescription.last_port}, 
          ${stopoverDescription.last_port_country}`
                      : null}
                  </FormatItem>
                </Item>
                <Item label="Embarcação">
                  <FormatItem>{stopoverDescription.vessel}</FormatItem>
                </Item>
                <Item label="Nº viagem do armador">
                  <FormatItem>{stopoverDescription.shipowner_trip}</FormatItem>
                </Item>
                <Item label="Porto de destino">
                  <FormatItem>
                    {stopoverDescription.next_port
                      ? `${stopoverDescription.next_port}, 
          ${stopoverDescription.next_port_country}`
                      : null}
                  </FormatItem>
                </Item>
                <Item label="Natureza da estadia">
                  <FormatItem>
                    {stopoverDescription.nature_of_stayment}
                  </FormatItem>
                </Item>
              </Descriptions>
            </div>
          </Panel>
          <Panel
            forceRender
            header="Dados gerais da atracação"
            key="generalDataDocking"
          >
            <Descriptions>
              <Item label="Finalidade da atracação">
                <FormatItem>
                  {selectedDocking.docking_goal?.description}
                </FormatItem>
              </Item>
              <Item label="Berço:">
                <FormatItem>{selectedDocking.docking_place?.name}</FormatItem>
              </Item>
              <Item label="Posição de atracação">
                <FormatItem>{dockingDescription.position}</FormatItem>
              </Item>
              <Item label="Atracação em linha?">
                <FormatItem>{dockingDescription.docking_in_line}</FormatItem>
              </Item>
              <Item label="Atracação à contrabordo?">
                <FormatItem>{dockingDescription.docking_alongside}</FormatItem>
              </Item>
            </Descriptions>
          </Panel>
          <Panel header="Operações" key="operations">
            <Descriptions column={2}>
              <Item label="Natureza da carga">
                <FormatItem>
                  {dockingDescription.operation_summary?.nature_of_cargo}
                </FormatItem>
              </Item>
              <Item label="Duração de todas as operações">
                <div>
                  <FormatItem>
                    {
                      dockingDescription.operation_summary
                        ?.start_real_time_of_operation
                    }
                  </FormatItem>
                  <span> até </span>
                  <FormatItem>
                    {
                      dockingDescription.operation_summary
                        ?.end_real_time_of_operation
                    }
                  </FormatItem>
                </div>
              </Item>
            </Descriptions>
            {dockingDescription.operations &&
              dockingDescription.operations.length > 0 && (
                <InnerCollapse expandIconPosition="end">
                  {dockingDescription.operations?.map((operation) => (
                    <InnerPanel
                      key={`operation_${operation.id}`}
                      header={operation.total_products_handled_description}
                    >
                      <div style={{ margin: '16px' }}>
                        <Descriptions column={2}>
                          <Item label="Houve inspeção?">
                            <div>
                              <FormatItem>{operation.inspection}</FormatItem>
                              {operation.inspection_type && (
                                <>
                                  <span>, </span>
                                  <FormatItem>
                                    {operation.inspection_type}
                                  </FormatItem>
                                </>
                              )}
                            </div>
                          </Item>
                          <Item label="Duração da operação">
                            <div>
                              <FormatItem>
                                {operation.real_operation_start}
                              </FormatItem>
                              <span> até </span>
                              <FormatItem>
                                {operation.real_operation_finish}
                              </FormatItem>
                            </div>
                          </Item>
                        </Descriptions>
                        <ManifestsGroupbyOperatorCollapse
                          operationId={operation.id || 0}
                        />
                        <div className="stoppages">
                          <Title>PARALISAÇÕES</Title>
                          <StoppagesTotalTable
                            stoppages={operation.stoppages.filter(
                              (stoppage) => stoppage.is_total === true
                            )}
                          />
                        </div>
                      </div>
                    </InnerPanel>
                  ))}
                </InnerCollapse>
              )}
          </Panel>
          <ManoeuvreDescriptionCollapse
            dockingDescription={dockingDescription}
            manoeuvre="docking_manoeuvre"
            onOpenPanel={onOpenPanel}
            openCollapsePanels={openCollapsePanels}
          />
          <Panel
            forceRender
            header="Cabeços e defensas"
            key="bollardsAndFenders"
          >
            <Descriptions column={2} title="PROA">
              <Item label="Cabeços">
                <div className="span-neutral-medium">
                  <FormatItem>
                    {dockingDescription.bollard_bow_launcher}
                  </FormatItem>
                  <span>(lançante), </span>
                  <FormatItem>
                    {dockingDescription.bollard_bow_spring}
                  </FormatItem>
                  <span>(spring), </span>
                  <FormatItem>
                    {dockingDescription.bollard_bow_across}
                  </FormatItem>
                  <span>(través)</span>
                </div>
              </Item>
              <Item label="Primeira defensa de apoio">
                <FormatItem>
                  {dockingDescription.first_bow_support_fender}
                </FormatItem>
              </Item>
            </Descriptions>
            <Descriptions column={2} title="POPA">
              <Item label="Cabeços">
                <div className="span-neutral-medium">
                  <FormatItem>
                    {dockingDescription.bollard_stern_launcher}
                  </FormatItem>
                  <span>(lançante), </span>
                  <FormatItem>
                    {dockingDescription.bollard_stern_spring}
                  </FormatItem>
                  <span>(spring), </span>
                  <FormatItem>
                    {dockingDescription.bollard_stern_across}
                  </FormatItem>
                  <span>(través)</span>
                </div>
              </Item>
              <Item label="Última defensa de apoio">
                <FormatItem>
                  {dockingDescription.last_stern_support_fender}
                </FormatItem>
              </Item>
            </Descriptions>
          </Panel>
          <ManoeuvreDescriptionCollapse
            dockingDescription={dockingDescription}
            manoeuvre="undocking_manoeuvre"
            onOpenPanel={onOpenPanel}
            openCollapsePanels={openCollapsePanels}
          />
        </Collapse>
      </Container>
    </Drawer>
  );
}
