import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { ConfigProvider, Input, Select, Skeleton, Spin } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

import { ScreenLayout, Button, List, Wrapper } from '../../components';
import { useChangePagination } from '../../hooks';
import { useGetCargoTypeQuery } from '../../services/cargoApi';
import { useGetBerthsQuery } from '../../services/dockingPlacesApi';
import { useGetManoeuvresBoardQuery } from '../../services/manoeuvreApi';
import {
  useGetOperatorsQuery,
  useLazyGetStopoverQuery,
} from '../../services/stopoverApi';
import { useGetTugboatsQuery } from '../../services/vesselApi';
import { Docking, ManoeuvreBoardType } from '../../types';
import { formatDockingToForm } from '../../utils/formatters';
import { getAllowedBerth } from '../../utils/validators';
import { DockingStaymentDrawer } from '../docking/dockingStaymentDrawer/DockingStaymentDrawer';
import { ManoeuvreBoardHeader } from './manoeuvreBoardHeader';
import { ManoeuvreListItem } from './manoeuvreListItem';
import { DockingPilotExpectDrawer } from '../docking/dockingStaymentDrawer/DockingPilotExpectDrawer';

type ManeuverStatus = 'CONFIRMED' | 'PREDICTED' | 'STARTED' | 'ENDED';
type StatusButtonProps = {
  status: ManeuverStatus;
};
const StatusButton = styled.div<StatusButtonProps>`
  display: inline-flex;
  align-items: center;
  padding: 7px 10px 8px 0px;
  line-height: 1.67;
  color: var(--neutral_medium);
  font-size: 13px;
  &:before {
    content: ' ';
    display: inline-block;
    margin-right: 8px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--error-light);
  }
  ${(p) => setBackground(p.status)}
`;

function setBackground(status: string) {
  if (status === 'CONFIRMED') {
    return `&:before{
      background: var(--success-dark)
  }`;
  }
  if (status === 'PREDICTED') {
    return `&:before{
      background: var(--predicted-docking)
  }`;
  }
  if (status === 'STARTED') {
    return `&:before{
      background: var(--green-sea_dark)
  }`;
  }
  if (status === 'ENDED') {
    return `&:before{
      background: var(--error-medium)
  }`;
  }
}

export function ManoeuvreBoard() {
  const [getStopoverSelected, selectedStopover] = useLazyGetStopoverQuery();

  const { onChangePagination, queryPage } = useChangePagination();
  const [filter, setFilter] = useState<{
    page: number;
    filter: string;
  }>({
    page: queryPage,
    filter: '',
  });

  const {
    data: manoeuvreData,
    refetch: refetchManoevre,
    isFetching: isFetchingManoeuvre,
  } = useGetManoeuvresBoardQuery(filter);

  const [filteredManoeuvres, setFilteredManoeuvres] = useState<
    Array<ManoeuvreBoardType>
  >([]);

  const [selectedManoeuvre, setSelectedManoeuvre] =
    useState<ManoeuvreBoardType | null>(null);
  const [selectedDocking, setSelectedDocking] = useState<Docking | undefined>(
    undefined
  );
  const [selectedDockingPosition, setSelectedDockingPosition] = useState<
    number | null
  >(null);
  const [isVisibleManoeuvreDrawer, setIsVisibleManoeuvreDrawer] =
    useState(false);

  const [isVisiblePilotExpectDrawer, setIsVisiblePilotExpectDrawer] =
    useState(false);

  const { data: cargosData } = useGetCargoTypeQuery();
  const { data: operatorsData } = useGetOperatorsQuery();

  const { data: tugboatsData } = useGetTugboatsQuery({});
  const { data: berthData } = useGetBerthsQuery({ shipType: '' });

  const [allowedBerth, setAllowedBerth] = useState([]);
  const [isLoadMoreData, setIsLoadMoreData] = useState(true);

  const [refreshInterval, setRefreshInterval] = useState<number>();
  const [lastRefreshTime, setLastRefreshTime] = useState<string>();

  useEffect(() => {
    setLastRefreshTime(new Date().toLocaleTimeString('pt-BR'));
  }, [manoeuvreData]);

  useEffect(() => {
    if (refreshInterval) {
      const intervalId = setInterval(
        () => refreshManoeuvre(),
        60000 * refreshInterval // multiple by minutes
      );
      return () => clearInterval(intervalId);
    }
  }, [refreshInterval]);

  useEffect(
    () =>
      setAllowedBerth(
        getAllowedBerth(selectedStopover?.data?.vessel, berthData)
      ),
    [berthData, selectedStopover.data]
  );

  useEffect(() => {
    createDockingWithStopover();
  }, [selectedStopover.data]);

  // Carrega os dados do infinit scroll ou caso mude a aba de status
  useEffect(() => {
    if (isLoadMoreData && manoeuvreData?.results) {
      setFilteredManoeuvres((prev) => [...prev, ...manoeuvreData.results]);
      setIsLoadMoreData(false);
    } else {
      setFilteredManoeuvres(manoeuvreData?.results || []);
    }
  }, [manoeuvreData?.results]);

  function refreshManoeuvre() {
    refetchManoevre();
    setLastRefreshTime(new Date().toLocaleTimeString('pt-BR'));
  }

  function customizeRenderEmpty() {
    return <>Não há manobras cadastradas.</>;
  }

  function onOpenManoeuvre(manoeuvre: ManoeuvreBoardType) {
    setSelectedManoeuvre(manoeuvre);
    setSelectedDockingPosition(manoeuvre.docking_id || null);
    if (manoeuvre.stopover_id) getStopoverSelected(manoeuvre.stopover_id);
    createDockingWithStopover();
    if (manoeuvre.docking_purpose === 'DOCK_IN_BERTH') {
      setIsVisibleManoeuvreDrawer(true);
    }
  }

  function onOpenPilotExpectForm(manoeuvre: ManoeuvreBoardType) {
    setSelectedManoeuvre(manoeuvre);
    setSelectedDockingPosition(manoeuvre.docking_id || null);
    if (manoeuvre.stopover_id) getStopoverSelected(manoeuvre.stopover_id);
    createDockingWithStopover();
    if (manoeuvre.docking_purpose === 'DOCK_IN_BERTH') {
      setIsVisiblePilotExpectDrawer(true);
    }
  }

  function createDockingWithStopover() {
    if (selectedManoeuvre?.docking_id) {
      const docking = selectedStopover.data?.dockings?.find(
        (stopoverDocking) => stopoverDocking.id === selectedManoeuvre.docking_id
      );
      if (docking) {
        setSelectedDocking(
          formatDockingToForm({
            ...docking,
            stopover: {
              ...selectedStopover.data,
              expected_arrival: moment(selectedStopover.data?.expected_arrival),
            },
          })
        );
      }
    }
  }

  function handleFilterChange(term: string) {
    onChangePagination(1);
    setFilter({
      page: 1,
      filter: term,
    });
  }

  const loadMoreData = () => {
    if (manoeuvreData?.next) {
      setIsLoadMoreData(true);
      onChangePagination((queryPage || 0) + 1);
      setFilter((prev) => ({ ...prev, page: (queryPage || 0) + 1 }));
    }
  };

  return (
    <ScreenLayout title="Quadro de manobras">
      {selectedStopover.data &&
        selectedDocking?.id === selectedManoeuvre?.docking_id && (
          <>
            <DockingStaymentDrawer
              visible={isVisibleManoeuvreDrawer}
              setIsVisible={setIsVisibleManoeuvreDrawer}
              selectedStopover={selectedStopover.data}
              currentDockingKey={selectedDockingPosition}
              setCurrentDockingKey={setSelectedDockingPosition}
              selectedDocking={selectedDocking}
              setSelectedDocking={setSelectedDocking}
              manoeuvreBoardContext
              width={800}
              allowedBerth={allowedBerth}
              tugboatsData={tugboatsData?.results}
              cargosData={cargosData?.results}
              operatorsData={operatorsData?.results}
            />
            <DockingPilotExpectDrawer
              visible={isVisiblePilotExpectDrawer}
              setIsVisible={setIsVisiblePilotExpectDrawer}
              selectedStopover={selectedStopover.data}
              currentDockingKey={selectedDockingPosition}
              setCurrentDockingKey={setSelectedDockingPosition}
              selectedDocking={selectedDocking}
              setSelectedDocking={setSelectedDocking}
              width={800}
            />
          </>
        )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Input
            size="small"
            prefix={
              <SearchOutlined style={{ color: '#09d4ab', fontSize: '20px' }} />
            }
            allowClear
            placeholder="Pesquisar embarcação, armador, agência, IMO ou berço"
            type="text"
            style={{
              width: '500px',
              height: '40px',
            }}
            onChange={debounce(
              (evt) => handleFilterChange(evt.target.value),
              500
            )}
          />
          <div style={{ padding: '15px' }}>
            <div
              style={{
                fontSize: '12px',
                color: 'var(--neutral_medium)',
                fontWeight: 'bold',
              }}
            >
              LEGENDA DE SITUAÇÃO DA MANOBRA:
            </div>
            <StatusButton status="STARTED">Em andamento</StatusButton>
            <StatusButton status="CONFIRMED">Confirmada</StatusButton>
            <StatusButton status="PREDICTED">Prevista</StatusButton>
            <StatusButton status="ENDED">Atrasada</StatusButton>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              type="text"
              icon={<ReloadOutlined />}
              size="large"
              onClick={() => refreshManoeuvre()}
              style={{ paddingRight: '5px' }}
            >
              Atualizar
            </Button>
            <Select
              aria-label="Atualização automática"
              defaultValue={refreshInterval}
              optionLabelProp="label"
              onChange={setRefreshInterval}
              bordered={false}
              dropdownMatchSelectWidth={170}
              style={{
                width: refreshInterval && refreshInterval > 0 ? 85 : 30,
              }}
            >
              <Select.OptGroup label="Atualização automática">
                <Select.Option value="" label="">
                  Desligada
                </Select.Option>
                <Select.Option value="5" label="5 min">
                  a cada 5 minutos
                </Select.Option>
                <Select.Option value="10" label="10 min">
                  a cada 10 minutos
                </Select.Option>
              </Select.OptGroup>
            </Select>
          </div>
          <span style={{ alignSelf: 'flex-end', paddingRight: '15px' }}>
            Última atualização às {lastRefreshTime}
          </span>
        </div>
      </div>
      <Spin spinning={isFetchingManoeuvre} tip="Carregando...">
        <Wrapper
          style={{
            padding: '15px',
            height: 'calc(95vh - 200px)',
          }}
        >
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <div
              style={{
                height: '100%',
                position: 'relative',
              }}
            >
              <div
                id="scrollableDiv"
                style={{ height: '100%', overflow: 'auto', width: '100%' }}
              >
                <ManoeuvreBoardHeader />
                <InfiniteScroll
                  dataLength={filteredManoeuvres?.length || 0}
                  next={loadMoreData}
                  hasMore={
                    (filteredManoeuvres?.length || 0) <
                    (manoeuvreData?.count || 0)
                  }
                  loader={<Skeleton active />}
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    dataSource={
                      filteredManoeuvres.length > 0
                        ? filteredManoeuvres
                        : manoeuvreData?.results
                    }
                    renderItem={(item: any) => (
                      <ManoeuvreListItem
                        onOpenManoeuvre={onOpenManoeuvre}
                        manoeuvre={item}
                        onOpenPilotExpectForm={onOpenPilotExpectForm}
                        canOpenManoeuvre={!refreshInterval}
                      />
                    )}
                  />
                </InfiniteScroll>
              </div>
            </div>
          </ConfigProvider>
        </Wrapper>
      </Spin>
    </ScreenLayout>
  );
}
