import { ColumnsType } from 'antd/lib/table';

import { User, UsersGroup } from '../../types';
import { GroupsRenderer } from './groupsRenderer';
import { UsersRenderer } from './usersRenderer';

export function columns(
  selectUser: (user: User) => void
): ColumnsType<Record<string, any>> {
  //
  return [
    {
      title: <div className="show-amount">Usuários</div>,
      dataIndex: 'user',
      key: 'user',
      render: (user: User) => <UsersRenderer user={user} />,
      sorter: {
        compare: (a: any, b: any) =>
          a.user.first_name.localeCompare(b.user.first_name),
        multiple: 1,
      },
      defaultSortOrder: 'ascend' as const,
      showSorterTooltip: false,
    },
    {
      title: 'Empresa',
      dataIndex: ['company', 'name'],
      key: 'company',
      render: (text: string) => <span>{text}</span>,
      sorter: {
        compare: (a: any, b: any) => a.company.localeCompare(b.company),
        multiple: 1,
      },
      showSorterTooltip: false,
    },
    {
      title: 'Grupos a que pertence',
      dataIndex: 'groups',
      key: 'groups',
      render: (groups: Array<UsersGroup>, record: any) => (
        <GroupsRenderer
          selectUser={selectUser}
          record={record}
          groups={groups}
        />
      ),
    },
  ];
}
