import Timeline from 'react-calendar-timeline';
import styled from 'styled-components';

import { berthRowHeight } from '../../utils/utils';

export const AnchoringWindowTimeLine = styled(Timeline)`
  & .rct-dateHeader {
    background: white;
    color: black;
    border: 0px;
  }

  & .rct-header-root {
    border: 0px;
    background: transparent;
  }
  & .rct-calendar-header {
    border: 0px;
  }

  & .rct-dateHeader.day {
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    height: auto;
    border-top: 1px solid var(--neutral_lighter);
    border-left: 1px solid var(--neutral_lighter);
    padding-left: 10px;
    & .day-number {
      font-size: 40px;
      line-height: 40px;
    }
    & .day-name {
      color: var(--neutral_medium);
    }
  }
  & .rct-dateHeader.hour {
    border: 0px;
    color: #fff;
    font-weight: bold;
    &.night {
      background: var(--ocean_dark);
    }
    &.mid-night {
      background: var(--marine_dark);
    }
    &.earlier-day {
      background: var(--marine_medium);
      color: var(--ocean_dark);
    }
    &.early-day {
      background: var(--marine_light);
      color: var(--ocean_dark);
    }
    &.day-light {
      background: var(--marine_lighter);
      color: var(--ocean_dark);
    }
  }
  & .rct-vl {
    border-left: 0px !important;
    &.rct-day-6,
    &.rct-day-0 {
      background: unset !important;
    }
    &.rct-vl-first {
      border-left: 1px solid var(--neutral_light) !important;
    }
  }

  & .rct-vl:nth-child(even) {
    background: rgba(242, 246, 249, 0.8) !important;
  }
  & .rct-vl:nth-child(odd) {
    background: rgba(255, 255, 255, 0.8) !important;
  }

  & .rct-item {
    border: 1px solid var(--green-sea_dark) !important;
    border-radius: 4px;
    box-shadow: 0px 0px 0px transparent;
    transition: 150ms ease-in-out;
    opacity: 0.9;
    &:hover {
      margin-left: -10px;
      opacity: 1 !important;
      z-index: 90 !important;
    }

    &.rct-item-selected {
      box-shadow: 0px 0px 5px var(--neutral_light);
    }
    &.foreseen-origin {
      background: linear-gradient(90deg, #27c0d6 0%, #fff 100%) !important;
      border: 1px solid #27c0d6 !important;
    }
    &.foreseen-destination {
      background: linear-gradient(270deg, #27c0d6 0%, #fff 100%) !important;
      border: 1px solid #27c0d6 !important;
    }
    &.in-progress-origin {
      background: linear-gradient(
        90deg,
        var(--green-sea_medium) 0%,
        #fff 100%
      ) !important;
      border: 1px solid var(--green-sea_medium) !important;
    }
    &.in-progress-destination {
      background: linear-gradient(
        270deg,
        var(--green-sea_medium) 0%,
        #fff 100%
      ) !important;

      border: 1px solid var(--green-sea_medium) !important;
    }
    &.realized-origin {
      background: linear-gradient(90deg, #ccdde9 0%, #fff 100%) !important;
      border: 1px solid #ccdde9 !important;
    }
    &.realized-destination {
      background: linear-gradient(270deg, #ccdde9 0%, #fff 100%) !important;
      border: 1px solid #ccdde9 !important;
    }
    &.maintenance {
      color: var(--error-medium) !important;
      border: 1px solid var(--error-light) !important;
      background-image: repeating-linear-gradient(
        135deg,
        #fcf3f3,
        #fcf3f3 10px,
        #f9e7e7 15px,
        #f9e7e7 15px
      ) !important;
    }
    &.pump {
      background-color: var(--neutral_light) !important;
    }
    /* overflow: hidden; */
    & .rct-item-content {
      color: #000;
      display: flex;
      justify-content: space-between;
      & .rct-item-content-name {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        /* width: auto; */
        /* justify-content: center; */
        & .rct-item-content-vessel-name {
          line-height: 15px;
          font-weight: 600;
        }
        & .rct-item-content-maintenance {
          display: flex;
          flex-direction: column;
          font-weight: 600;
          color: var(--error-medium) !important;

          & .name {
            display: flex;
            gap: 4px;
            align-items: center;
            line-height: 18px;
          }
          & .subtitle {
            line-height: 12px;
            font-weight: 400;
            font-size: 10px;
          }
        }
        & .rct-item-content-product {
          display: flex;
          font-family: 'Consolas';
          color: var(--neutral_medium);
          text-transform: capitalize;
          font-weight: 12px;
          line-height: 15px;
        }
      }
      & .rct-item-operation-icon {
        align-items: center;
        display: flex;
        & span {
          font-size: 15px;
        }
      }
      & .rct-item-popup {
        background: #fff;
        width: 300px;
        height: 200px;
        padding: 12px 10px;
        margin-top: 10px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
        z-index: 1000;
        &:before {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #fff;
          content: '';
          position: absolute;
          top: 23px;
        }
      }
    }
  }

  & .tos-today-marker {
    z-index: 90;
    background: var(--green-sea_dark) !important;
  }

  & .custom-group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  & .custom-group .title {
    font-weight: bold;
    text-transform: uppercase;
    padding-left: 5px;
  }
  & .custom-group .title,
  & .custom-group .vessel-name-list {
    display: inline-block;
  }
  & .custom-group .vessel-name-list {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
  }
  & .custom-group .vessel-name-list .vessel-name {
    cursor: pointer;
    height: ${berthRowHeight}px;
    line-height: ${berthRowHeight}px;
    color: #2e3439ed;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
    &:hover {
      color: #000;
    }
    & .vessel-flag {
      display: inline-block;
      margin-right: 10px;
    }
  }
  & .rct-hl-even,
  & .rct-hl-odd {
    background: #fff !important;
    border-bottom: solid 1px var(--neutral_medium) !important;
  }
  & .rct-hl-odd {
    background: rgba(160, 186, 206, 1) !important;
  }

  & .rct-sidebar-row-even {
    background: #fff !important;
  }
  & .rct-sidebar-row-odd {
    background: rgba(204, 221, 233, 0.3) !important;
  }
  & .sidebar-content {
    display: flex;
    align-items: flex-end;
    & .sidebar-column {
      width: 50%;
      background: var(--neutral_medium);
      height: 30px;
      padding: 5px;
      color: #fff;
      font-weight: bold;
    }
  }

  & .rct-sidebar {
    border-right: 1px solid var(--neutral_lighter);
    & .rct-sidebar-row {
      border-bottom: 1px solid var(--neutral_lighter);
    }
  }
`;
