import { ColumnsType } from 'antd/lib/table';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import moment from 'moment';
import Flag from 'react-flagpack';

import { MoonIcon } from '../../../assets/icons/moonIcon';
import { SunIcon } from '../../../assets/icons/sunIcon';
import { ErrorBoundary, FormatItem, StatusTag } from '../../../components';
import { TrackingLogType } from '../../../types';
import {
  createTableColumnsFilters,
  removeDuplicateFromObjectArray,
} from '../../../utils/utils';

export const dockingStatusList = [
  { value: 'DOCKING', label: 'Atracação' },
  { value: 'UNDOCKING', label: 'Desatracação' },
  // { value: 'ANCHORING', label: 'Fundeio' },
  { value: 'UNANCHORING', label: 'Fundeio' },
];

function checkDuty(date: moment.Moment): 'DAY' | 'NIGHT' {
  if (date.hour() >= 7 && date.hour() <= 19) {
    return 'DAY';
  }
  return 'NIGHT';
}

export type ColumnsFilterType = {
  text: string;
  value: string;
};

function getUserNamesFilters(dataSource?: TrackingLogType[]) {
  if (dataSource) {
    const users = removeDuplicateFromObjectArray(
      dataSource.map((data) => {
        if (data.status === 'DOCKING') {
          return {
            text: data.last_cable_tied_in_user_name,
            value: data.last_cable_tied_in_user_name,
          };
        }
        if (data.status === 'UNDOCKING') {
          return {
            text: data.last_cable_untied_in_user_name,
            value: data.last_cable_untied_in_user_name,
          };
        }
        if (data.status === 'ANCHORING' || data.status === 'UNANCHORING') {
          return {
            text: data.real_time_of_anchoring_user_name,
            value: data.real_time_of_anchoring_user_name,
          };
        }
        return {};
      }),
      'value'
    );
    return users.filter(
      (user) => user.value !== null
    ) as unknown as ColumnFilterItem[];
  }
}

function getStatusFilters(dataSource?: TrackingLogType[]) {
  if (dataSource) {
    return removeDuplicateFromObjectArray(
      dataSource.map((data) => {
        if (data.status === 'DOCKING') {
          return {
            text: 'Atracação',
            value: data.status,
          };
        }
        if (data.status === 'UNDOCKING') {
          return {
            text: 'Desatracação',
            value: data.status,
          };
        }
        if (data.status === 'ANCHORING' || data.status === 'UNANCHORING') {
          return {
            text: 'Fundeio',
            value: data.status,
          };
        }
        return {};
      }),
      'value'
    ).filter((user) => user.value !== null) as unknown as ColumnFilterItem[];
  }
}
export function vesselsTableColumns(
  dataSource?: TrackingLogType[]
): ColumnsType<Record<string, any>> {
  const usersFilters = getUserNamesFilters(dataSource);
  const statusFilters = getStatusFilters(dataSource);
  const vesselFilters = dataSource
    ? createTableColumnsFilters(dataSource, 'vessel_name')
    : [];
  const dockingFilters = dataSource
    ? createTableColumnsFilters(dataSource, 'docking_code')
    : [];
  const berthFilters = dataSource
    ? createTableColumnsFilters(dataSource, 'docking_place_name')
    : [];
  const shippingAgencyFilters = dataSource
    ? createTableColumnsFilters(dataSource, 'shipping_agency_name')
    : [];

  return [
    {
      title: 'Data/hora',
      dataIndex: 'event_date',
      sorter: {
        compare: (a, b) => a.event_date?.localeCompare(b.event_date),
        multiple: 1,
      },
      render: (value) => {
        return (
          <div>
            {moment(value).format('DD/MM/YYYY HH:mm')}
            {checkDuty(moment(value)) === 'DAY' ? (
              <SunIcon
                style={{
                  fontSize: '20px',
                  marginLeft: '10px',
                  color: 'var(--neutral_medium)',
                }}
              />
            ) : (
              <MoonIcon
                style={{
                  fontSize: '20px',
                  marginLeft: '10px',
                  color: 'var(--neutral_medium)',
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Embarcação',
      dataIndex: 'vessel_name',
      filters: vesselFilters,
      onFilter: (value, record) => record.vessel_name === value,
      render: (value, record) => {
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <div style={{ width: '20px' }}>
              {record.vessel_flag && (
                <ErrorBoundary replace="">
                  <Flag code={record.vessel_flag} size="m" />
                </ErrorBoundary>
              )}
            </div>
            <span
              style={{
                textTransform: 'capitalize',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {value?.toLowerCase() || ''}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: statusFilters,
      onFilter: (value, record) => record.status === value,
      render: (value) => {
        return (
          <StatusTag>
            <span
              className={
                value === 'DOCKING'
                  ? 'green-sea'
                  : value === 'UNDOCKING'
                  ? 'ocean-blue'
                  : value === 'ANCHORING' || value === 'UNANCHORING'
                  ? 'blue'
                  : ''
              }
            >
              {
                dockingStatusList.find((status) => value === status.value)
                  ?.label
              }
            </span>
          </StatusTag>
        );
      },
    },
    {
      title: 'Berço',
      dataIndex: 'docking_place_name',
      filters: berthFilters,
      onFilter: (value, record) => record.docking_place_name === value,
    },
    {
      title: 'Atracação',
      dataIndex: 'docking_code',
      filters: dockingFilters,
      onFilter: (value, record) => record.docking_code === value,
      render: (value) => {
        return <FormatItem>{value}</FormatItem>;
      },
    },
    {
      title: 'Agente de navegação',
      dataIndex: 'shipping_agency_name',
      filters: shippingAgencyFilters,
      onFilter: (value, record) => record.shipping_agency_name === value,
      render: (value) => {
        return <FormatItem>{value}</FormatItem>;
      },
    },
    {
      title: 'Usuário',
      filters: usersFilters,
      onFilter: (value, record) => {
        return (
          record.last_cable_tied_in_user_name === value ||
          record.last_cable_untied_in_user_name === value ||
          record.real_time_of_anchoring_user_name === value ||
          record.real_time_of_unanchoring_user_name === value
        );
      },
      render: (_value, record) => {
        if (record.status === 'DOCKING') {
          return <FormatItem>{record.last_cable_tied_in_user_name}</FormatItem>;
        }
        if (record.status === 'UNDOCKING') {
          return (
            <FormatItem>{record.last_cable_untied_in_user_name}</FormatItem>
          );
        }
        if (record.status === 'ANCHORING') {
          return (
            <FormatItem>{record.real_time_of_anchoring_user_name}</FormatItem>
          );
        }
        if (record.status === 'UNANCHORING') {
          return (
            <FormatItem>{record.real_time_of_unanchoring_user_name}</FormatItem>
          );
        }
      },
    },
  ];
}
