import { ColumnsType } from 'antd/lib/table';
import styled from 'styled-components';

import {
  capitalizeFirstLetter,
  formatNumberToLocale,
} from '../../../../../utils/utils';

const NoStorageStyle = styled.div`
  color: var(--neutral_light);
  font-style: italic;
`;

export function storageSumaryTableColumns(
  showOperators?: boolean
): ColumnsType<Record<string, any>> {
  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'Armazenamento',
      key: 'storage_name',
      dataIndex: 'storage_name',
      render: (value: any, record: any) => {
        if (record.direct_discharge) {
          return <NoStorageStyle>Descarga direta</NoStorageStyle>;
        }
        return capitalizeFirstLetter(value);
      },
      width: '60%',
    },
    {
      title: 'Unidades',
      key: 'total_amount',
      dataIndex: 'total_amount',
      render(value) {
        return value;
      },
      align: 'right' as const,
      width: '20%',
    },
    {
      title: 'Peso',
      key: 'total_weight',
      dataIndex: 'total_weight',
      render(value) {
        return <>{formatNumberToLocale(value)} ton</>;
      },
      align: 'right' as const,
      width: '20%',
    },
  ];

  if (showOperators) {
    columns.splice(1, 0, {
      title: 'Operador',
      key: 'operator',
      dataIndex: 'operator',
      render(value) {
        return capitalizeFirstLetter(value);
      },
    });
  }
  return columns;
}
