import { Space } from 'antd';

import { Button, Form, FormItemDatePicker } from '../../components';
import { Docking } from '../../types';
import { createDateString, disabledDateAfterToday } from '../../utils/utils';
import { validateCablesDates } from '../docking/dockingStaymentDrawer/validators';
import { BaseActionModalProps } from './staymentActions';

type PilotLeaveOnBoardUndockingProps = BaseActionModalProps & {
  onPilotLeaveOnBoard: () => void;
};

export function PilotLeaveOnBoardUndocking(
  props: PilotLeaveOnBoardUndockingProps
) {
  const {
    setIsVisible,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
    onPilotLeaveOnBoard,
  } = props;
  const [form] = Form.useForm();

  function handleForm(values: any) {
    if (currentDocking) {
      const docking = {
        ...currentDocking,
        undocking_manoeuvre: {
          ...currentDocking.undocking_manoeuvre,
          pilot_leave_on_board: values.pilot_leave_on_board_undocking,
        },
      } as Docking;

      if (!validateCablesDates(docking)) return;

      if (updateDocking) {
        updateDocking(
          {
            pilot_leave_on_board_undocking: createDateString(
              values.pilot_leave_on_board_undocking
            ),
            id: currentDocking.id,
          },
          onPilotLeaveOnBoard
        );
      }
    }
  }
  return (
    <Form form={form} layout="vertical" onFinish={handleForm}>
      <FormItemDatePicker
        name="pilot_leave_on_board_undocking"
        label="Hora de saída do Prático:"
        disabledDate={disabledDateAfterToday}
        colSpan={12}
        tooltip=""
        rules={[
          {
            required: true,
            message:
              'Por favor, preencha o horário de saída do prático a bordo na desatracação',
          },
        ]}
      />
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingUpdateDocking}
        >
          Continuar
        </Button>
      </Space>
    </Form>
  );
}
