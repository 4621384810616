export function AnchoringWindowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#clip0_2686_20434)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.12 16.5112H16.88V6.8512H5.12V16.5112ZM4.8 5.7312H17.2C17.4122 5.7312 17.6157 5.81549 17.7657 5.96552C17.9157 6.11554 18 6.31903 18 6.5312V16.8312C18 17.0434 17.9157 17.2469 17.7657 17.3969C17.6157 17.5469 17.4122 17.6312 17.2 17.6312H4.8C4.58783 17.6312 4.38434 17.5469 4.23431 17.3969C4.08429 17.2469 4 17.0434 4 16.8312V6.5312C4 6.31903 4.08429 6.11554 4.23431 5.96552C4.38434 5.81549 4.58783 5.7312 4.8 5.7312ZM14.8896 8.5736C14.9972 8.68118 15.0576 8.82707 15.0576 8.9792H15.0536C15.0536 9.13133 14.9932 9.27723 14.8856 9.3848C14.778 9.49237 14.6321 9.5528 14.48 9.5528H9.54561C9.39348 9.5528 9.24758 9.49237 9.14001 9.3848C9.03244 9.27723 8.97201 9.13133 8.97201 8.9792C8.97201 8.82707 9.03244 8.68118 9.14001 8.5736C9.24758 8.46603 9.39348 8.4056 9.54561 8.4056H14.484C14.6361 8.4056 14.782 8.46603 14.8896 8.5736ZM10.8248 13.8112H12.136C12.2881 13.8112 12.434 13.8716 12.5416 13.9792C12.6491 14.0868 12.7096 14.2327 12.7096 14.3848C12.7096 14.5369 12.6491 14.6828 12.5416 14.7904C12.434 14.898 12.2881 14.9584 12.136 14.9584H10.8248C10.7494 14.9584 10.6749 14.9436 10.6053 14.9147C10.5357 14.8859 10.4724 14.8437 10.4192 14.7904C10.3659 14.7371 10.3237 14.6739 10.2948 14.6043C10.266 14.5347 10.2512 14.4601 10.2512 14.3848C10.2512 14.3095 10.266 14.2349 10.2948 14.1653C10.3237 14.0957 10.3659 14.0325 10.4192 13.9792C10.4724 13.9259 10.5357 13.8837 10.6053 13.8549C10.6749 13.826 10.7494 13.8112 10.8248 13.8112ZM11.6472 11.6816C11.6472 11.5294 11.5868 11.3834 11.4792 11.2757C11.3717 11.168 11.2258 11.1074 11.0736 11.1072H7.51599C7.36746 11.1127 7.22687 11.1756 7.12375 11.2827C7.02063 11.3897 6.96302 11.5326 6.96302 11.6812C6.96302 11.8298 7.02063 11.9727 7.12375 12.0797C7.22687 12.1868 7.36746 12.2497 7.51599 12.2552H11.0736C11.2257 12.2552 11.3716 12.1948 11.4792 12.0872C11.5868 11.9796 11.6472 11.8337 11.6472 11.6816Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2686_20434">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(4 4.6814)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
