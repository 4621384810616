import { useMemo, useState } from 'react';

import { Descriptions, FormatItem, Title } from '../../../../components';
import {
  OperationCalculusType,
  OperationProductCalculusType,
  OperationProductTypeDescription,
} from '../../../../types';
import { addSuffix } from '../../../../utils/utils';
import { StoppagesByPeriodDrawer } from '../stoppagesByPeriodDrawer/StoppagesByPeriodDrawer';
import { OperationResumeDescriptionContainer } from './styles';

const { Item } = Descriptions;

type ProductResumeDescriptionProps = {
  operationProduct: OperationProductTypeDescription;
  operationProductCalculusData: OperationProductCalculusType;
  operationCalculusData: OperationCalculusType;
  isProvisioned?: boolean;
};

export function ProductResumeDescription(props: ProductResumeDescriptionProps) {
  const {
    operationProduct,
    operationProductCalculusData,
    operationCalculusData,
    isProvisioned,
  } = props;

  const [
    isStoppagesByPeriodDrawerVisible,
    setIsStoppagesByPeriodDrawerVisible,
  ] = useState(false);

  const netProduction = useMemo(() => {
    if (
      operationProductCalculusData.total_movimented &&
      operationCalculusData.total_time
    ) {
      return addSuffix(
        (
          (operationProductCalculusData.total_movimented || 0) /
          (operationCalculusData.total_time || 0)
        ).toFixed(2),
        'ton/h'
      );
    }
  }, [operationCalculusData]);

  const grossProduction = useMemo(() => {
    if (
      operationProductCalculusData.total_movimented &&
      operationCalculusData.total_time
    ) {
      const totalTime =
        operationCalculusData.total_time -
        operationCalculusData.total_stoppage_time;
      return addSuffix(
        (
          (operationProductCalculusData.total_movimented || 0) / totalTime
        ).toFixed(2),
        'ton/h'
      );
    }
  }, [operationCalculusData]);

  return (
    <OperationResumeDescriptionContainer>
      {/* TODO: verificar como sera carregado todas as paralisações dos work_periods */}
      <StoppagesByPeriodDrawer
        // selectedWorkPeriods={operationProduct.work_periods}
        visible={isStoppagesByPeriodDrawerVisible}
        setIsVisible={setIsStoppagesByPeriodDrawerVisible}
      />
      <div className="infos">
        <Title>RESUMO DO PRODUTO</Title>
        <div className="production">
          <div className="production-item">
            <Title>Produção</Title>
            <Descriptions column={1}>
              <Item label="Operado">
                <FormatItem>
                  {operationProductCalculusData.total_movimented &&
                    addSuffix(
                      operationProductCalculusData.total_movimented,
                      'ton'
                    )}
                </FormatItem>
              </Item>
              <Item label="Manifestado">
                <FormatItem>
                  {operationProductCalculusData.total_weight &&
                    addSuffix(operationProductCalculusData.total_weight, 'ton')}
                </FormatItem>
              </Item>
            </Descriptions>
          </div>
          {!isProvisioned && (
            <>
              <div className="production-item">
                <Title>Produção por período</Title>
                <Descriptions column={1}>
                  {operationProduct.periodProduction.map(
                    (workPeriod, index) => (
                      <Item key={index} label={`Período ${index + 1}`}>
                        <FormatItem>{workPeriod.sum} ton</FormatItem>
                      </Item>
                    )
                  )}
                </Descriptions>
              </div>
              <div className="production-item">
                <Title>Produtividade</Title>
                <Descriptions column={1}>
                  <Item label="Produtivid. líquida">
                    <FormatItem>{netProduction}</FormatItem>
                  </Item>
                  <Item label="Produtivid. bruta:">
                    <FormatItem>{grossProduction}</FormatItem>
                  </Item>
                </Descriptions>
              </div>
            </>
          )}
        </div>
      </div>
    </OperationResumeDescriptionContainer>
  );
}
