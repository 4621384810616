import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useState } from 'react';

import { DefaultLayout } from '../../components';
import { useChangePagination } from '../../hooks';
import { useGetStopoversResumeQuery } from '../../services/stopoverApi';
import { FilterDefaultLayoutType, Stopover as StopoverType } from '../../types';
import { AgentAreaDetails } from './agentAreaDetails';
import { AgentAreaDrawer } from './agentAreaDrawer/AgentAreaDrawer';

const tabs = [
  { label: 'Todas', value: '' },
  { label: 'Aguardadas', value: 'WAITING' },
  { label: 'Em andamento', value: 'IN_PROGRESS' },
  { label: 'Finalizadas', value: 'DONE' },
  { label: 'Canceladas', value: 'CANCELED' },
];

const { confirm } = Modal;

export function AgentArea() {
  const { onChangePagination, queryPage } = useChangePagination();
  const [filter, setFilter] = useState<FilterDefaultLayoutType>({
    page_size: 50,
    page: queryPage,
    order_by_delay_property: 'stopover_is_delayed',
  });

  const {
    data: stopoveresData,
    isLoading: isLoadingStopoveresData,
    isFetching: isFetchingStopoveresData,
  } = useGetStopoversResumeQuery(filter);

  const [filteredStopoveres, setFilteredStopoveres] = useState(
    [] as StopoverType[]
  );

  const [selectedStopover, setSelectedStopover] = useState<StopoverType>(
    {} as StopoverType
  );
  const [hasEdited, setHasEdited] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  const [visibleAddStopoverDrawer, setVisibleAddStopoverDrawer] =
    useState(false);

  function closeDrawer() {
    if (hasEdited) {
      confirm({
        title: 'Tem certeza que deseja sair?',
        icon: <ExclamationCircleOutlined />,
        content:
          'Você tem alterações não salvas na escala, ao sair perderá os dados preenchidos.',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        onOk() {
          close();
        },
      });
    } else {
      close();
    }
  }

  function close() {
    setVisibleAddStopoverDrawer(false);
    setHasEdited(false);
    setBlockNavigate(false);
  }

  return (
    <>
      <DefaultLayout
        title="Área do agente"
        tabs={tabs}
        filterField="status"
        setFilter={setFilter}
        stopoveresData={stopoveresData}
        filteredStopoveres={filteredStopoveres}
        setFilteredStopoveres={setFilteredStopoveres}
        isLoadingStopoveresData={isLoadingStopoveresData}
        isFetchingStopoveresData={isFetchingStopoveresData}
        queryPage={queryPage}
        onChangePagination={onChangePagination}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        visibleAddDataDrawer={visibleAddStopoverDrawer}
        setVisibleAddDataDrawer={setVisibleAddStopoverDrawer}
        labelAddDataDrawer="Nova escala"
        labelPlural="ESCALAS(S)"
        pageContext="STOPOVER"
      >
        <AgentAreaDetails
          name="agent_area_form"
          setHasEdited={setHasEdited}
          hasEdited={hasEdited}
          blockNavigate={blockNavigate}
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
          setBlockNavigate={setBlockNavigate}
        />
      </DefaultLayout>
      <AgentAreaDrawer
        visibleDrawer={visibleAddStopoverDrawer}
        closeDrawer={closeDrawer}
        hasEdited={hasEdited}
        setHasEdited={setHasEdited}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        setSelectedStopover={setSelectedStopover}
        close={close}
        setFilteredStopoveres={setFilteredStopoveres}
      />
    </>
  );
}
