import { Col } from 'antd';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import { debounce } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectRef } from 'rc-select';
import { ReactNode, RefObject, useState } from 'react';

import { FormItem } from '../Form';
import { Select } from '../Select';

type ItemSelect = { id?: number | string; name?: string; disabled?: boolean };

type FormItemSelectProps = {
  name: NamePath;
  label?: string | (string | number)[];
  placeholder?: string;
  // Verificar se seria melhor key, pensando nas telas de cadastro e remover opcional
  // dataList?: Array<{ id?: number | string; name: string }>;
  // Futuramente remover opcional do id
  dataList?: Array<ItemSelect>;
  allowClear?: boolean;
  required?: boolean;
  colSpan?: number;
  showSearch?: boolean;
  notFoundContent?: string;
  debounceDelay?: number;
  onSearch?: (val: string) => void;
  optionRenderer?: (option: Record<string, any>) => ReactNode;
  onSelect?: (event: any) => void;
  onClick?: (event: any) => void | void;
  mode?: 'multiple';
  maxSelectOptions?: number;
  onClear?: () => void;
  initialSelectedOptions?: any;
  filterOption?: (input: any, option: any) => any;
  optionFilterProp?: any;
  disabled?: boolean;
  tooltip?: ReactNode;
  isLoading?: boolean;
  style?: React.CSSProperties;
  rules?: Rule[];
  shouldUpdate?: (prevValues: any, curValues: any) => boolean;
  dependencies?: NamePath[];
  extra?: ReactNode;
  ref?: RefObject<BaseSelectRef>;
  noStyle?: boolean;
  help?: ReactNode;
  tagTheme?: string;
};

export function FormItemSelect(props: FormItemSelectProps) {
  const {
    name,
    label,
    placeholder,
    dataList,
    allowClear,
    required,
    colSpan,
    showSearch,
    notFoundContent,
    debounceDelay,
    optionRenderer,
    onSearch,
    onSelect,
    onClick,
    mode,
    maxSelectOptions,
    onClear,
    initialSelectedOptions,
    optionFilterProp,
    filterOption,
    disabled,
    tooltip,
    isLoading,
    style,
    rules,
    shouldUpdate,
    dependencies,
    extra,
    ref,
    noStyle,
    help,
    tagTheme,
  } = props;
  const [optionsSelected, setOptionsSelected] = useState<number[]>();

  const handleChange = (value: any) => {
    setOptionsSelected(value);
    if (onSelect) {
      onSelect(value);
    }
  };
  return (
    <Col span={colSpan || 24}>
      <FormItem
        name={name}
        label={label}
        tooltip={tooltip}
        rules={rules || [{ required, message: 'Campo obrigatório' }]}
        style={style || {}}
        shouldUpdate={shouldUpdate}
        dependencies={dependencies}
        extra={extra}
        noStyle={noStyle}
        help={help}
      >
        <Select
          onClick={onClick}
          $tagTheme={tagTheme}
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          onSelect={onSelect}
          onChange={handleChange}
          allowClear={allowClear}
          onClear={onClear}
          showSearch={showSearch}
          notFoundContent={isLoading ? 'Carregando...' : notFoundContent}
          mode={mode}
          filterOption={
            filterOption ||
            ((input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0)
          }
          optionFilterProp={optionFilterProp}
          loading={isLoading}
          onSearch={onSearch && debounce(onSearch, debounceDelay || 0)}
        >
          {dataList?.map((data, idx) => {
            if (optionRenderer) {
              return optionRenderer(data);
            }
            return (
              <Select.Option
                key={idx}
                value={data.id}
                disabled={
                  data.disabled === true || mode === 'multiple'
                    ? optionsSelected
                      ? optionsSelected.length >
                        (maxSelectOptions ? maxSelectOptions - 1 : 1)
                      : initialSelectedOptions
                      ? initialSelectedOptions.length >
                        (maxSelectOptions ? maxSelectOptions - 1 : 1)
                      : false
                    : false
                }
              >
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </FormItem>
    </Col>
  );
}
