import {
  Descriptions,
  FormatItem,
  GenericDrawerHeader,
} from '../../../../../../components';
import { WorkPeriodTypeDescription } from '../../../../../../types';
import { ShiftsDescription } from './shiftsDescription';
import { Container } from './styles';

type WorkPeriodDescriptionProps = {
  selectedWorkPeriod: WorkPeriodTypeDescription;
  setDrawerVisible: (value: boolean) => void;
  periodIndex: number;
  operationCode: string;
};

const { Item } = Descriptions;

export function WorkPeriodDescription(props: WorkPeriodDescriptionProps) {
  const { selectedWorkPeriod, setDrawerVisible, periodIndex, operationCode } =
    props;

  function getHeader() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: 'var(--neutral_light)',
          }}
        >
          OPERAÇÃO {operationCode}
        </span>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}>
          <span
            style={{
              fontSize: '16px',
              fontWeight: '700',
              color: 'var(--suape-black)',
            }}
          >
            Período {periodIndex + 1}
          </span>
          <span
            style={{
              fontSize: '12px',
              fontWeight: '400',
              color: 'var(--neutral_medium)',
            }}
          >
            {selectedWorkPeriod.worked_time}
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <GenericDrawerHeader
        onBack={() => setDrawerVisible(false)}
        title={getHeader()}
        showBackButton
      />
      <Container>
        <Descriptions column={2}>
          <Item label="Início do período">
            <FormatItem>{selectedWorkPeriod.start_of_period}</FormatItem>
          </Item>
          <Item label="Fim do período">
            <FormatItem>{selectedWorkPeriod.end_of_period}</FormatItem>
          </Item>
          <Item label="Modalidade dos turnos" span={2}>
            <FormatItem>{selectedWorkPeriod.shift_mode}</FormatItem>
          </Item>
          <Item span={2}>
            <ShiftsDescription workShifts={selectedWorkPeriod.work_shifts} />
          </Item>
        </Descriptions>
        <Descriptions>
          <Item label="Movimentação">
            <FormatItem>{selectedWorkPeriod.total_cargo_operated}</FormatItem>
          </Item>
          <Item label="Horas operacionais">
            <FormatItem>{selectedWorkPeriod.worked_time}</FormatItem>
          </Item>
          <Item label="Produtividade do período">
            <FormatItem>{selectedWorkPeriod.period_productivity}</FormatItem>
          </Item>
        </Descriptions>
      </Container>
    </>
  );
}
