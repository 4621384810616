import { EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { Button, FormatItem, Tag } from '../../components';
import { VehicleType } from '../../typesWarehousing';
import { chassisStatus } from '../../utils/lists';
import { createDateStringPtBr } from '../../utils/utils';

type Params = {
  onSelect: (vehicle: VehicleType) => void;
  isWarehousingOperatorArea: boolean;
};
export function importChassisTableColumnsAdmin(
  params: Params
): ColumnsType<Record<string, any>> {
  const { onSelect, isWarehousingOperatorArea } = params;
  const columns = [
    {
      title: 'Chassi',
      dataIndex: 'chassis',
      render: (value: any) => {
        return <div style={{ fontFamily: 'Consolas' }}>{value}</div>;
      },
    },
    {
      title: 'Modelo',
      dataIndex: 'description',
    },
    {
      title: 'Status Chassi',
      dataIndex: 'status',
      render: (value: any) => {
        return (
          <Tag>
            {
              chassisStatus.find((chassiStatus) => chassiStatus.value === value)
                ?.label
            }
          </Tag>
        );
      },
    },
    {
      title: '',
      render: (value: any, record: any) => {
        return (
          <Button
            icon={<EyeOutlined />}
            style={{ float: 'right' }}
            onClick={() => onSelect(record as VehicleType)}
          />
        );
      },
    },
  ];

  if (!isWarehousingOperatorArea) {
    columns.splice(3, 0, {
      title: 'Vaga',
      dataIndex: 'parking_space',
      render: (value: any) => {
        return value ? (
          <div style={{ fontFamily: 'Consolas' }}>
            {value.block}.{value.row}.{value.position}
          </div>
        ) : (
          <div />
        );
      },
    });
  } else {
    columns.splice(3, 0, {
      title: 'Chegada',
      dataIndex: 'posting_date',
      render: (value: any) => {
        return (
          <div>
            <FormatItem>{createDateStringPtBr(value)}</FormatItem>
          </div>
        );
      },
    });
    columns.splice(4, 0, {
      title: 'Saida',
      dataIndex: 'departure_date',
      render: (value: any) => {
        return (
          <div>
            <FormatItem>{createDateStringPtBr(value)}</FormatItem>
          </div>
        );
      },
    });
  }

  return columns;
}
