import { Row } from 'antd';
import Flag from 'react-flagpack';

import {
  Descriptions,
  ErrorBoundary,
  FormatItem,
  Title,
} from '../../../../../components';
import { StopoverGroupByOperatorType } from '../../../../../types';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import { DescriptionCard, DescriptionSection } from './styles';

const { Item } = Descriptions;

type GeneralDataDescriptionSectionProps = {
  stopover: StopoverGroupByOperatorType;
};

export function GeneralDataDescriptionSection(
  props: GeneralDataDescriptionSectionProps
) {
  const { stopover } = props;
  return (
    <DescriptionSection>
      <Title>DADOS GERAIS</Title>
      <DescriptionCard>
        <Row className="header">
          <ErrorBoundary replace="">
            <Flag code={stopover.vessel_flag} hasBorder={false} size="m" />
          </ErrorBoundary>
          <span className="title">{stopover.vessel_name}</span>
        </Row>
        <Descriptions column={4}>
          <Item label="Berço">
            <FormatItem>
              {capitalizeFirstLetter(stopover.docking_place)}
            </FormatItem>
          </Item>
          <Item label="Atracação">
            <FormatItem>{stopover.docking_code}</FormatItem>
          </Item>
          <Item label="Último porto de escala">
            <FormatItem>
              {capitalizeFirstLetter(stopover.last_port?.name)},{' '}
              {capitalizeFirstLetter(stopover.last_port?.country_name)}
            </FormatItem>
          </Item>
          <Item label="Próximo porto de escala">
            <FormatItem>
              {capitalizeFirstLetter(stopover.next_port?.name)},{' '}
              {capitalizeFirstLetter(stopover.next_port?.country_name)}
            </FormatItem>
          </Item>
        </Descriptions>
        <Descriptions column={4}>
          <Item label="Operador">
            <FormatItem>{stopover.operator}</FormatItem>
          </Item>
          <Item label="DUV">
            <FormatItem>{stopover.duv}</FormatItem>
          </Item>
          <Item label="Finalidade da atracação">
            <FormatItem>{stopover.docking_goal}</FormatItem>
          </Item>
          <Item label="Grupo de produto">
            <FormatItem>
              {capitalizeFirstLetter(stopover.product_group)}
            </FormatItem>
          </Item>
        </Descriptions>
      </DescriptionCard>
    </DescriptionSection>
  );
}
