import { OperationCalculusType } from '../types';
import { getHeaders, tosApi } from './tosApi';

export const operationCalculusApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getOperationCalculus: build.query<OperationCalculusType, number>({
      query(id) {
        return {
          url: `/operational/operation-calculus/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      providesTags: (_result, _error, code) => [
        { type: 'OperationCalculus', code },
      ],
    }),
  }),
  overrideExisting: true,
});

export const { useGetOperationCalculusQuery } = operationCalculusApi;
