import { Dispatch, SetStateAction, useState } from 'react';

import { Drawer } from '../../../../../../../../../components';
import {
  ContainerTypeDescription,
  Stopover as StopoverType,
} from '../../../../../../../../../types';
import { ContainerForm } from './containerForm';

type ContainerDrawerProps = {
  isVisible: boolean;
  onBack: () => void;
  selectedContainer: ContainerTypeDescription;
  setSelectedContainer: (value: ContainerTypeDescription) => void;
  setIsVisibleForm: (value: boolean) => void;
  operationCraneId?: number;
  craneId?: string;
  selectedStopover: StopoverType;
  selectedContainers: ContainerTypeDescription[];
  setSelectedContainers: Dispatch<SetStateAction<ContainerTypeDescription[]>>;
  setSelectedStopover?: Dispatch<SetStateAction<StopoverType>>;
};

export function ContainerDrawer(props: ContainerDrawerProps) {
  const {
    isVisible,
    onBack,
    selectedContainer,
    setSelectedContainer,
    setIsVisibleForm,
    operationCraneId,
    selectedStopover,
    selectedContainers,
    setSelectedContainers,
    setSelectedStopover,
    craneId,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  return (
    <Drawer
      visible={isVisible}
      placement="right"
      width={690}
      closable={false}
      destroyOnClose
      onClose={() => {
        onBack();
      }}
    >
      <ContainerForm
        selectedContainer={selectedContainer}
        setSelectedContainer={setSelectedContainer}
        onFinishSubmit={onBack}
        onBack={onBack}
        setIsVisibleForm={setIsVisibleForm}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
        operationCraneId={operationCraneId}
        selectedStopover={selectedStopover}
        setSelectedContainers={setSelectedContainers}
        selectedContainers={selectedContainers}
        isVisible={isVisible}
        craneId={craneId}
      />
    </Drawer>
  );
}
