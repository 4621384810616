import { ColumnsType } from 'antd/lib/table';

import { formatNumberToLocale } from '../../../../../../utils/utils';

export function columns(): ColumnsType<Record<string, any>> {
  return [
    {
      title: 'NCM',
      key: 'ncm',
      dataIndex: 'ncm_code',
      width: '70%',
    },
    // {
    //   title: 'Unidades',
    //   key: 'amount',
    //   dataIndex: 'amount',
    //   width: '10%',
    //   align: 'right' as const,
    // },
    {
      title: 'Peso total',
      key: 'total_weight',
      dataIndex: 'weight',
      width: '30%',
      align: 'right' as const,
      render(value) {
        return <>{formatNumberToLocale(value)} ton</>;
      },
    },
  ];
}
