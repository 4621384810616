import styled from 'styled-components';

export const Wrapper = styled.div`
  background: rgba(204, 221, 233, 0.5);
  border-radius: 5px;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
  & > .ant-row {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    /* padding: 16px 15px 14.3px */
  }
  & .amount {
    display: inline-block;
    padding-right: 20px;
    color: #5f7b91;
    font-weight: normal;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 20px;
    & span {
      color: #000;
      display: inline-block;
      padding-right: 8px;
    }
  }
  @media only screen and (max-width: 1600px) {
    & > .ant-row {
      /* padding: 10px 9px 11px */
      padding-top: 8px;
      padding-bottom: 8px;
    }
    & .amount {
      font-size: 11px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 1280px) {
      height: 88%;
    }
  }
`;
