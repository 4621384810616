import { message, Row } from 'antd';
import { useEffect, useState } from 'react';
import { debounce, isEmpty } from 'lodash';
import { PageContentPlaceholder } from '../../../components/common/PageContentPlaceholder';
import { VesselInfos } from '../../../components/common/vesselInfos/VesselInfos';

import {
  Form,
  FormItem,
  FormItemSelect,
  Select,
  FormItemInput,
} from '../../../components';
import { FormItemInputNumber } from '../../../components/antd/formItems/FormItemInputNumber';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import {
  useDeleteVesselMutation,
  useGetPortsCountriesQuery,
  useUpdateVesselMutation,
  useGetVesselsQuery,
} from '../../../services/vesselApi';
import { Vessel as VesselType } from '../../../types';
import { formatVesselToSave } from '../../../utils/formatters';
import { isNullOrUndefined } from '../../../utils/utils';
import {
  captaincyRegistrationRules,
  mmsiRules,
} from '../../../utils/validators';
import { FormItemsWrapper } from '../company/companyForm';
import { RegisterHeaderForm } from '../registerHeaderForm';
import { imoRules } from '../vessel/vesselForm';

type TugboatFormProps = {
  name: string;
  onClose: () => void;
  selectedTugboat: any;
  onSaveTugboat: (vessel?: VesselType) => void;
  onSelectVessel: (value: any) => void;
};
export function TugboatForm(props: TugboatFormProps) {
  const { name, onClose, selectedTugboat, onSaveTugboat, onSelectVessel } =
    props;
  const [searchCountryName, setCountryName] = useState('');
  const [searchCountryCode, setCountryCode] = useState('');

  const { data: countriesData } = useGetPortsCountriesQuery({
    countryName: searchCountryName,
    countryCode: searchCountryCode,
  });

  const [form] = Form.useForm();
  const isEditForm = !!selectedTugboat?.id;
  const [readOnly] = useState(isEditForm);
  const [searchVessel, setSearchVessel] = useState({
    imo_or_name: selectedTugboat?.name || '',
    ship_type: isEditForm ? 'PORT_TUGBOAT' : 'TUGBOAT',
  });

  const { data: foundVessel } = useGetVesselsQuery(searchVessel);

  const [
    updateVessel,
    { isSuccess: isSuccessUpdateVessel, isError: isErrorUpdateVessel },
  ] = useUpdateVesselMutation();
  const [
    deleteVessel,
    { isSuccess: isSuccessDeleteVessel, isError: isErrorDeleteVessel },
  ] = useDeleteVesselMutation();
  const { data: towingCompanies, isFetching: isLoadingTowingCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      company_type: 'TOWING',
    });

  function validateTugboats(vessel: VesselType): boolean {
    if (
      isNullOrUndefined(vessel.mmsi) &&
      isNullOrUndefined(vessel.captaincy_registration) &&
      isNullOrUndefined(vessel.imo)
    ) {
      message.error(
        'É necessario informar pelo menos o IMO, MMSI ou Inscrição da capitania.'
      );
      return false;
    }
    return true;
  }

  async function handleFormSubmit(values: any) {
    if (!validateTugboats(values)) return;
    const tugboat = {
      ...selectedTugboat,
      ...values,
      ship_type: 'PORT_TUGBOAT',
    };
    const updatedVessel = await updateVessel(formatVesselToSave(tugboat));
    if ('data' in updatedVessel) {
      onSaveTugboat(updatedVessel.data);
    }
  }

  async function onDeleteVessel() {
    if (selectedTugboat && selectedTugboat.id) {
      await deleteVessel(selectedTugboat.id);
    }
  }
  useEffect(() => {
    if (isSuccessUpdateVessel) {
      message.success('Embarcação salva');
      onClose();
    } else if (isErrorUpdateVessel) {
      message.error(
        'Ocorreu um erro ao salvar a embarcação, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessUpdateVessel, isErrorUpdateVessel]);

  useEffect(() => {
    if (isSuccessDeleteVessel) {
      message.success('Embarcação excluída');
      onClose();
      onSaveTugboat();
    }
  }, [isSuccessDeleteVessel]);
  useEffect(() => {
    if (selectedTugboat?.flag) {
      setCountryCode(selectedTugboat.flag);
    }
  }, [selectedTugboat]);
  function formatTugboatToForm() {
    return selectedTugboat
      ? {
          ...selectedTugboat,
          tugboat_company: selectedTugboat.tugboat_company?.id,
        }
      : {};
  }
  function handleSearchCountryName(value: string) {
    if (!isEmpty(searchCountryCode)) {
      setCountryCode('');
    }
    setCountryName(value);
  }
  function countryOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.country_code}>
        {option.country_name}
      </Select.Option>
    );
  }

  function onSearchByName(val: any) {
    setSearchVessel((searchVessel) => ({ ...searchVessel, imo_or_name: val }));
  }

  function handleSelectVessel(option: number) {
    const fullVessel = foundVessel?.results?.find((v) => v.id === option);
    onSelectVessel(fullVessel || ({} as VesselType));
    form.setFieldsValue({
      imo: fullVessel?.imo,
      flag: fullVessel?.flag,
      mmsi: fullVessel?.mmsi,
      captaincy_registration: fullVessel?.captaincy_registration,
    });
  }

  function onClearSelectedVessel() {
    onSelectVessel({} as VesselType);
  }

  function vesselOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {`${option.imo} - ${option.name}`}
      </Select.Option>
    );
  }

  return (
    <Form
      name={name}
      form={form}
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={formatTugboatToForm()}
    >
      <RegisterHeaderForm
        onClose={onClose}
        formName={name}
        allowDelete={!!selectedTugboat}
        onDelete={onDeleteVessel}
      >
        {selectedTugboat ? (
          <div className="title">{selectedTugboat.name}</div>
        ) : (
          <div className="title">NOVO REBOCADOR</div>
        )}
      </RegisterHeaderForm>
      <FormItemsWrapper>
        <Row>
          <FormItemSelect
            colSpan={16}
            name={['id']}
            label={readOnly ? 'Nome da embarcação' : 'Selecione uma embarcação'}
            // isLoading={isLoading}
            dataList={foundVessel?.results}
            showSearch
            allowClear
            disabled={readOnly}
            onSearch={debounce(onSearchByName, 400)}
            onSelect={handleSelectVessel}
            onClear={onClearSelectedVessel}
            notFoundContent="Digite uma busca"
            optionRenderer={vesselOptionRenderer}
            required
            shouldUpdate={(prevValues: any, currentValues: any) =>
              prevValues.vessel !== currentValues.vessel
            }
          />
        </Row>
        <Row gutter={16}>
          <FormItemInput
            label="Código (IMO)"
            name="imo"
            rules={imoRules}
            placeholder="XXXXXXX"
            colSpan={12}
          />
          <FormItemInputNumber
            addonAfter="F"
            label="Bollard pull"
            name="bollard_pull"
            tooltip="Força de tração (em toneladas-força)"
            colSpan={12}
          />
        </Row>
        <Row gutter={16}>
          <FormItemInput
            colSpan={12}
            label="MMSI"
            name="mmsi"
            rules={mmsiRules}
            maxLength={9}
          />
          <FormItemInput
            colSpan={12}
            label="Inscrição da capitania"
            name="captaincy_registration"
            rules={captaincyRegistrationRules}
            maxLength={9}
          />
        </Row>
        <Row>
          <FormItemSelect
            label="Bandeira"
            placeholder="Selecionar"
            name="flag"
            showSearch
            onSearch={handleSearchCountryName}
            debounceDelay={400}
            dataList={countriesData?.results}
            optionRenderer={countryOptionRenderer}
            onClear={() => setCountryName('')}
            rules={[{ required: true, message: 'Campo obrigatório' }]}
            allowClear
          />
        </Row>
        <Row>
          <FormItemSelect
            label="Empresa"
            name="tugboat_company"
            dataList={towingCompanies?.results || []}
            isLoading={isLoadingTowingCompanies}
          />
        </Row>
      </FormItemsWrapper>
    </Form>
  );
}
