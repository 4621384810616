import { BellOutlined, BellTwoTone } from '@ant-design/icons';
import { Popover } from 'antd';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  useLazyGetNotificationsByUserQuery,
  useUpdateNotificationMutation,
} from '../../../services/notificationApi';
import { NotificationsType, NotificationType } from '../../../types';
import { createNotificationDataByPeriod } from './formatNotifications';
import NotificationItem from './notificationItem';
import {
  Container,
  ButtonNotification,
  GroupNotification,
  Title,
} from './styles';

export function Notification() {
  const [visible, setVisible] = useState(false);
  const [hasNewNotification] = useState(true);
  const [allNotifications, setallNotifications] = useState(
    {} as NotificationsType
  );
  const [allUnreadNotifications, setallUnreadNotifications] = useState(0);
  const { id } = useAppSelector((state: RootState) => state.user.user);

  const [getNotificationsEndpoint, { data: notificationsData }] =
    useLazyGetNotificationsByUserQuery();

  const [updateNotification] = useUpdateNotificationMutation();

  useEffect(() => {
    if (id !== undefined) {
      getNotificationsEndpoint(id);
    }
  }, []);

  useEffect(() => {
    function countUnreadNotifications(allNotifications: NotificationsType) {
      let allUnread = 0;
      for (const [_key, value] of Object.entries(allNotifications)) {
        for (const notification of value.data) {
          if (!notification.read) {
            allUnread++;
          }
        }
      }
      return allUnread;
    }
    const notifications = createNotificationDataByPeriod(
      notificationsData?.results || []
    );
    setallNotifications(notifications);
    setallUnreadNotifications(countUnreadNotifications(notifications));
  }, [notificationsData]);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  function handleNotificationRead(
    notificationKey: string,
    notificationId: number,
    notificationIsRead: boolean
  ) {
    if (notificationIsRead) {
      return;
    }
    const updatedAllNotifications = allNotifications;
    for (const [key, value] of Object.entries(updatedAllNotifications)) {
      if (notificationKey === key) {
        value.data.forEach((notification) => {
          if (notification.id === notificationId) {
            updateNotification({ id: notificationId, read: true });
            notification.read = true;
          }
        });
      }
    }

    setallNotifications(updatedAllNotifications);
    if (allUnreadNotifications > 0) {
      setallUnreadNotifications(allUnreadNotifications - 1);
    }
  }

  function getNotifications() {
    return (
      <Container>
        {Object.entries(allNotifications).map(([key, value]) => (
          <GroupNotification key={key}>
            <strong
              style={{ fontSize: '12px', color: 'var(--neutral_medium)' }}
            >
              {value.name}
            </strong>
            {value.data.map((notification: NotificationType) => (
              <NotificationItem
                key={notification.id}
                keyGroup={key}
                notification={notification}
                handleNotificationRead={handleNotificationRead}
              />
            ))}
          </GroupNotification>
        ))}
      </Container>
    );
  }

  function getTitle() {
    return (
      <Title>
        <strong
          style={{
            color: 'var(--neutral_medium)',
            fontSize: '16px',
            lineHeight: '24px',
            paddingTop: '5px',
          }}
        >
          Notificações
        </strong>
        <span style={{ fontSize: '12px' }}>
          Você tem <strong>{allUnreadNotifications}</strong> notificações{' '}
          <strong>não lida(s)</strong>
        </span>
      </Title>
    );
  }

  return (
    <Popover
      placement="bottomRight"
      title={getTitle}
      color="var(--neutral_lightest)"
      content={getNotifications}
      trigger="click"
    >
      <ButtonNotification
        onClick={handleToggleVisible}
        hasNewNotification={hasNewNotification}
      >
        {allUnreadNotifications > 0 ? (
          <BellTwoTone
            twoToneColor={['black', 'var(--green-sea_medium)']}
            style={{ fontSize: '20px' }}
          />
        ) : (
          <BellOutlined style={{ fontSize: '20px' }} />
        )}
      </ButtonNotification>
    </Popover>
  );
}
