import { Space } from 'antd';

import { Button, Form, FormItemDatePicker } from '../../components';
import { createDateString } from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type PilotExpectedOnBoardDockingProps = BaseActionModalProps & {
  onPilotExpectedOnBoard: () => void;
};
export function PilotExpectedOnBoardDocking(
  props: PilotExpectedOnBoardDockingProps
) {
  const {
    setIsVisible,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
    onPilotExpectedOnBoard,
  } = props;
  const [form] = Form.useForm();

  function handleForm(values: any) {
    if (updateDocking) {
      updateDocking(
        {
          ...values,
          pilot_expected_on_board: createDateString(
            values.pilot_expected_on_board
          ),
          id: currentDocking?.id,
        },
        onPilotExpectedOnBoard
      );
    }
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleForm}>
      <b>
        Para liberar a atracação é necessário informar a previsão de prático a
        bordo
        <br />
        <br />
      </b>
      <FormItemDatePicker
        name="pilot_expected_on_board"
        label="Previsão de prático a bordo na atracação:"
        colSpan={12}
        tooltip=""
        rules={[
          {
            required: true,
            message:
              'Por favor, preencha a previsão de prático a bordo na atracação',
          },
        ]}
      />
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingUpdateDocking}
        >
          Continuar
        </Button>
      </Space>
    </Form>
  );
}
