import { DeleteOutlined } from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { valueType } from 'antd/lib/statistic/utils';
import { debounce, isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Descriptions,
  Form,
  FormatItem,
  FormItemInputNumber,
  FormItemSelect,
  Modal,
  Select,
  Title,
} from '../../../../../components';
import { TableFormList } from '../../../../../components/common/tableFormList/TableFormList';
import { useGetCargoTypeQuery } from '../../../../../services/cargoApi';
import { useGetCompaniesQuery } from '../../../../../services/companyApi';
import { useCreateOrUpdateNcmItemsMutation } from '../../../../../services/ncmItemApi';
import { useGetSystemParamByNameQuery } from '../../../../../services/systemParamsApi';
import {
  CeMerchantGroupbyNcmItemType,
  NCMItemType,
} from '../../../../../types';
import {
  isNullOrUndefined,
  removeSpecialCharacters,
} from '../../../../../utils/utils';
import { SelectOperatorModalContainer } from './styles';

const { Item } = Descriptions;

function ItemLoading(props: {
  index: number;
  onRemove?: (index: number | number[]) => void;
  itemRowProps?: {
    selectedLoading: CeMerchantGroupbyNcmItemType;
    form: FormInstance;
    isVehicleManifest: boolean;
  };
}) {
  const { index, onRemove, itemRowProps } = props;

  const { data: ImdgCodeItems, isFetching: isLoadingImdgCodeItems } =
    useGetSystemParamByNameQuery({
      name: 'IMDG_CODE',
    });
  const { selectedLoading, form, isVehicleManifest } =
    itemRowProps ||
    ({} as {
      selectedLoading: CeMerchantGroupbyNcmItemType;
      form: FormInstance;
      isVehicleManifest: boolean;
    });
  const [searchStorageName, setSearchStorageName] = useState('');
  const { data: storagesData, isLoading: isLoadingStoragesData } =
    useGetCompaniesQuery({
      company_type: 'STORAGE',
      name_or_cnpj: searchStorageName,
      page_size: 15,
    });

  const [searchOperatorName, setSearchOperatorName] = useState('');
  const { data: operatorsData, isLoading: isLoadingOperatorsData } =
    useGetCompaniesQuery({
      company_type: 'OPERATOR',
      name_or_cnpj: searchOperatorName,
      page_size: 15,
    });

  const [searchCargoName, setSearchCargoName] = useState('');

  const { data: cargoData, isLoading: isLoadingCargoData } =
    useGetCargoTypeQuery({
      name: searchCargoName,
      page_size: 15,
    });
  function onSearchCargo(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchCargoName(search || evt);
  }

  function onSearchStorage(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchStorageName(search || evt);
  }

  function onSearchOperator(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchOperatorName(search || evt);
  }

  function onChangeWeight(weightParcial: valueType) {
    if (selectedLoading.volume) {
      const volumeParcial =
        (Number(weightParcial) * selectedLoading.volume) /
        selectedLoading.weight;
      const items = form.getFieldValue('loadings');
      form.setFieldsValue({
        loadings: items.map((item: NCMItemType, idx: number) => {
          if (idx === index) {
            return { ...item, volume: +volumeParcial };
          }
          return item;
        }),
      });
    }
  }

  function imdgCodeRenderer(option: Record<string, any>) {
    const optionName = `${option.code} - ${option.name}`;
    return (
      <Select.Option key={option.id} value={option.id}>
        {optionName}
      </Select.Option>
    );
  }

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      <FormItemSelect
        colSpan={isVehicleManifest ? 8 : 4}
        name={[index, 'cargo_type', 'name']}
        dataList={cargoData?.results
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))}
        required
        showSearch
        isLoading={isLoadingCargoData}
        onSearch={debounce(onSearchCargo, 300)}
      />
      <FormItemSelect
        colSpan={isVehicleManifest ? 8 : 4}
        name={[index, 'storage_terminal', 'id']}
        dataList={storagesData?.results}
        required
        showSearch
        isLoading={isLoadingStoragesData}
        onSearch={debounce(onSearchStorage, 300)}
      />
      {!isVehicleManifest && (
        <>
          <FormItemInputNumber
            colSpan={4}
            name={[index, 'weight']}
            addonAfter="ton"
            style={{ marginBottom: '0px' }}
            onChange={(e) => {
              onChangeWeight(e);
            }}
            required
            format={false}
          />
          {selectedLoading.volume && (
            <FormItemInputNumber
              colSpan={4}
              name={[index, 'volume']}
              addonAfter="m³"
              format={false}
              disabled
            />
          )}
          <FormItemSelect
            colSpan={3}
            name={[index, 'imdg_code']}
            dataList={ImdgCodeItems?.items}
            showSearch
            isLoading={isLoadingImdgCodeItems}
            placeholder="IMDG"
            optionRenderer={imdgCodeRenderer}
          />
        </>
      )}
      <FormItemSelect
        colSpan={isVehicleManifest ? 8 : 4}
        name={[index, 'operator', 'id']}
        dataList={operatorsData?.results
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))}
        required
        showSearch
        isLoading={isLoadingOperatorsData}
        onSearch={debounce(onSearchOperator, 300)}
      />
      <Col span={1}>
        <Button
          type="default"
          danger
          icon={<DeleteOutlined />}
          onClick={() => onRemove && onRemove(index)}
          disabled={index === 0}
        />
      </Col>
    </Row>
  );
}

type SelectOperatorModalProps = {
  isVisible: boolean;
  handleCancelModal: () => void;
  selectedLoading: CeMerchantGroupbyNcmItemType;
  ncmItem: NCMItemType;
  selectedOpDirectionManifest: string;
  isVehicleManifest: boolean;
};

export function SelectOperatorModal(props: SelectOperatorModalProps) {
  const {
    isVisible,
    handleCancelModal,
    selectedLoading,
    ncmItem,
    selectedOpDirectionManifest,
    isVehicleManifest,
  } = props;
  const [form] = Form.useForm();
  useEffect(() => {
    if (isVisible && !isEmpty(ncmItem)) {
      form.setFieldsValue({ loadings: [ncmItem] });
    }
  }, [ncmItem, isVisible]);

  const [createOrUpdateNcmItem, { isSuccess: isSucessCreateNcmItem }] =
    useCreateOrUpdateNcmItemsMutation();

  useEffect(() => {
    if (isSucessCreateNcmItem) {
      handleCancelModalSelectOperator();
    }
  }, [isSucessCreateNcmItem]);

  const tableFormListColumns = useMemo(() => {
    if (isVehicleManifest) {
      return [
        { title: 'Mercadoria', colSpan: 8 },
        { title: 'Local de armazenamento', colSpan: 8 },
        { title: 'Operador', colSpan: 8 },
      ];
    }
    return [
      { title: 'Mercadoria', colSpan: 4 },
      { title: 'Local de armazenamento', colSpan: 4 },
      { title: 'Peso Total', colSpan: 4 },
      { title: 'Volume', colSpan: 4 },
      { title: 'Código IMDG', colSpan: 3 },
      { title: 'Operador', colSpan: 4 },
    ];
  }, [isVehicleManifest]);

  function handleFormSubmit() {
    form.validateFields().then((values: { loadings: NCMItemType[] }) => {
      let hasError = false;
      let totalWeight = values.loadings
        .map((item) => item.weight)
        .reduce((sum, value) => sum + value * 1000, 0);
      totalWeight /= 1000;
      if (selectedLoading.weight !== totalWeight) {
        message.error(
          `Soma das divisões do peso da carga não é igual ao peso total da carga.Peso total:${
            selectedLoading.weight
          }/ Soma das divisões:${totalWeight}. Diferença de ${
            selectedLoading.weight - totalWeight
          }`,
          15
        );
        hasError = true;
      }
      if (values.loadings.some((item) => item.weight === 0)) {
        message.error('Não é possível dividir a carga com peso igual a 0');
        hasError = true;
      }

      if (!hasError) {
        const formattedLoadings = values.loadings.map((item) => {
          const parent_id = isNullOrUndefined(item.id)
            ? selectedLoading.ncm_item_id
            : null;
          return {
            id: item.id,
            ncm_code: selectedLoading.ncm_code,
            ce_merchant_id: selectedLoading.id,
            weight: item.weight,
            volume: item.volume,
            operator: item.operator?.id,
            storage_terminal: item.storage_terminal?.id,
            parent_id,
            cargo_type: item.cargo_type?.name,
            description: selectedLoading.description,
            amount: item.amount,
            chassis: item.chassis,
            imdg_code: item.imdg_code,
          };
        });

        createOrUpdateNcmItem(formattedLoadings);
      }
    });
  }

  function handleCancelModalSelectOperator() {
    form.resetFields();
    handleCancelModal();
  }

  return (
    <Modal
      className="TOSPrimaryModal"
      width={1080}
      title="Selecionar operador(es)"
      visible={isVisible}
      destroyOnClose
      onCancel={handleCancelModalSelectOperator}
      cancelText="Voltar"
      okText="Concluir"
      onOk={handleFormSubmit}
      okButtonProps={{ style: { marginRight: '16px' } }}
      paddingBody="0"
    >
      <SelectOperatorModalContainer>
        <div className="ncm-description">
          <Title>CARGA</Title>
          <Descriptions column={2}>
            <Item label="Manifesto">
              <FormatItem>{selectedLoading.manifest_code}</FormatItem>
            </Item>
            <Item
              label={
                selectedOpDirectionManifest === 'LOADING'
                  ? 'Embarcador'
                  : 'Consignatário'
              }
            >
              <FormatItem>
                {!isNullOrUndefined(selectedLoading.operator_company)
                  ? `${selectedLoading.operator_company.cnpj} - ${selectedLoading.operator_company.name}`
                  : ''}
              </FormatItem>
            </Item>
            <Item label="NCM">
              <FormatItem>{selectedLoading.ncm_code}</FormatItem>
            </Item>
            <Item label="CE Mercante">
              <FormatItem>{selectedLoading.code}</FormatItem>
            </Item>
            {!isVehicleManifest && (
              <>
                <Item label="Peso total">
                  <FormatItem>{`${selectedLoading.weight} ton`}</FormatItem>
                </Item>
                {selectedLoading.volume && (
                  <Item label="Volume total">
                    <FormatItem>{`${selectedLoading.volume} ton`}</FormatItem>
                  </Item>
                )}
              </>
            )}
          </Descriptions>
        </div>
        <div className="select-operator-info">
          {!isVehicleManifest && (
            <span style={{ lineHeight: '28px' }}>
              Indique a mercadoria, a quantidade e o operador. Revise o local de
              armazenamento e o peso total.
              <br />
              Caso necessário, é possível dividir a carga entre outros locais de
              armazenamento ou operadores.
            </span>
          )}
          <Form form={form} name="selectLoading" onFinish={handleFormSubmit}>
            <TableFormList
              formListName="loadings"
              FormListItemRow={ItemLoading}
              columns={
                selectedLoading.volume
                  ? tableFormListColumns
                  : tableFormListColumns.filter(
                      (item) => item.title !== 'Volume'
                    )
              }
              style={{ marginTop: '0px', marginBottom: '20px' }}
              itemToAdd={
                isVehicleManifest
                  ? null
                  : {
                      weight: 0,
                      storage: null,
                      operator: null,
                      cargo_type: null,
                      quantity: null,
                    }
              }
              addButtonText="Dividir carga"
              itemRowProps={{ selectedLoading, form, isVehicleManifest }}
            />
          </Form>
        </div>
      </SelectOperatorModalContainer>
    </Modal>
  );
}
