import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  Button,
  FormItemSelect,
  FormItemInputNumber,
  Select,
} from '../../../../components';
import { useGetCargosQuery } from '../../../../services/cargoApi';
import {
  OperationProductStoragesMercanteType,
  OperationStorageMercanteCargoTypeCargoType,
} from '../../../../types';
import { CargoStorageTableStyle } from './styles';

type TableTypeType = number;
type CargoStorageTableProps = {
  cargosStorage: FormListFieldData[];
  onRemoveItem: (itemIndex: number) => void;
  storageIndex?: TableTypeType;
  form?: FormInstance;
  storages: OperationProductStoragesMercanteType[];
};
export function CargoStorageTable(props: CargoStorageTableProps) {
  const { cargosStorage, storageIndex, storages, form } = props;

  return (
    <CargoStorageTableStyle>
      <div className="table">
        <Row gutter={16} className="table-head">
          <Col span={14}>NCM</Col>
          <Col span={4}>Unidades</Col>
          <Col span={4}>Peso</Col>
        </Row>
        {cargosStorage.map((_field, index) => {
          return (
            <ItemRow
              key={index}
              rowIndex={index}
              onRemove={props.onRemoveItem}
              storageIndex={storageIndex || 0}
              storages={storages}
              form={form}
            />
          );
        })}
        {cargosStorage.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </CargoStorageTableStyle>
  );
}

type ItemRowProps = {
  rowIndex: number;
  onRemove: (itemIndex: number) => void;
  storageIndex: number;
  storages: OperationProductStoragesMercanteType[];
  form?: FormInstance;
};
function ItemRow(props: ItemRowProps) {
  const { rowIndex, onRemove, storageIndex, storages, form } = props;
  const [searchCargoName, setSearchCargoName] = useState('');
  const [selectedCargo, setSelectedCargo] = useState(Object);

  useEffect(() => {
    const oldStorages: OperationProductStoragesMercanteType[] =
      form?.getFieldValue('storages');
    const newStorages = oldStorages.map((storage: any, index) => {
      if (index === storageIndex) {
        return {
          ...storage,
          cargos_storage: storage.cargos_storage?.map((cargoStorage: any) => {
            if (cargoStorage.cargo?.id === selectedCargo?.id) {
              cargoStorage.cargo = selectedCargo;
              return cargoStorage;
            }
            return cargoStorage;
          }),
        };
      }
      return storage;
    });
    form?.setFieldsValue({ storages: newStorages });
  }, [selectedCargo]);

  const { data: filteredCargos, isFetching: isLoadingCargos } =
    useGetCargosQuery({
      page_size: 100,
      name: searchCargoName,
    });

  function onSearch(evt: string) {
    setSearchCargoName(evt);
  }
  function cargoOptionRenderer(cargo: any) {
    return (
      <Select.Option key={cargo.id} value={cargo.id}>
        {cargo.ncm ? `${cargo.ncm} - ${cargo.name}` : `${cargo.name}`}
      </Select.Option>
    );
  }
  function onSelectCargo(option: number) {
    const cargoObject = filteredCargos?.results.find((cargo) => {
      return cargo.id === option;
    });
    setSelectedCargo({ ...cargoObject });
  }

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      <FormItemSelect
        colSpan={14}
        placeholder="NCM"
        name={[`${rowIndex}`, 'cargo', 'id']}
        onSearch={debounce(onSearch, 300)}
        isLoading={isLoadingCargos}
        showSearch
        style={{ margin: 0 }}
        dataList={filteredCargos?.results
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))}
        optionRenderer={cargoOptionRenderer}
        onSelect={onSelectCargo}
      />
      <FormItemInputNumber
        colSpan={4}
        placeholder="Unidades"
        name={[`${rowIndex}`, 'amount']}
        addonAfter="un"
      />
      <FormItemInputNumber
        colSpan={4}
        placeholder="Peso"
        name={[`${rowIndex}`, 'weight']}
        addonAfter="ton"
      />
      <Col span={2} style={{ textAlign: 'right' }}>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() => onRemove(rowIndex)}
        />
      </Col>
    </Row>
  );
}
