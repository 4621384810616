import { Spin } from 'antd';
import { Table, TableWrapper } from '../../../../../../components';
import { useChangePagination } from '../../../../../../hooks';
import { useGetNcmItemsQuery } from '../../../../../../services/ncmItemApi';

import { formatNumberToLocale } from '../../../../../../utils/utils';
import { StorageMerchantTableFooter } from '../../../../../operation/OperationsData/operationProductDetailsDrawer/styles';
import { columns } from './columns';
import { NcmItemGroupByStorageTerminalType } from '../../../../../../types';

type StorageMerchantManifestTableProps = {
  ceMerchantCode: string;
  storageTerminal: NcmItemGroupByStorageTerminalType;
};

export function StorageMerchantManifestTable(
  props: StorageMerchantManifestTableProps
) {
  const { ceMerchantCode, storageTerminal } = props;

  const PAGE_SIZE = 15;

  const { onChangePagination, queryPage } = useChangePagination();

  const { data: ncmItemsData, isFetching: isFetchingNcmItemsData } =
    useGetNcmItemsQuery({
      ce_merchant_code: ceMerchantCode,
      storage_terminal_name: storageTerminal.storage_name || 'NOT_INFORMED',
      page: queryPage,
      page_size: PAGE_SIZE,
    });

  function getFooter() {
    return (
      <StorageMerchantTableFooter>
        <span>TOTAIS:</span>
        {/* <div className="amount">
          {formatNumberToLocale(storage.total_amount)}
        </div> */}
        <div className="weight">
          {formatNumberToLocale(storageTerminal.total_ncm_weight)} ton
        </div>
      </StorageMerchantTableFooter>
    );
  }
  return (
    <Spin spinning={isFetchingNcmItemsData} tip="Carregando...">
      <TableWrapper style={{ background: '#ebf2f7' }}>
        <Table
          rowKey="id"
          columns={columns()}
          dataSource={ncmItemsData?.results || []}
          size="small"
          className="read-only"
          style={{ marginBlockEnd: '24px' }}
          pagination={{
            position: ['bottomCenter'],
            defaultPageSize: PAGE_SIZE,
            total: ncmItemsData?.count || 0,
            showSizeChanger: false,
            onChange: onChangePagination,
          }}
          footer={() => getFooter()}
        />
      </TableWrapper>
    </Spin>
  );
}
