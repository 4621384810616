import { formatPermissionsArray } from '../features/permissionGroups/formatPermissions';
import { GenericResponse, GroupPermissions } from '../types';
import { getHeaders, tosApi } from './tosApi';

type PermissionGroupResponse = GenericResponse & {
  results: GroupPermissions[];
};

export const groupsApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getPermissionsGroup: build.query<PermissionGroupResponse, void>({
      query: () => {
        return {
          url: `core/groups`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    createPermissionGroup: build.mutation<any, GroupPermissions>({
      query: (permissionGroup) => {
        return {
          url: `core/groups`,
          method: 'POST',
          body: permissionGroup,
        };
      },
    }),
    updatePermissionGrup: build.mutation<
      any,
      Partial<GroupPermissions> & Pick<GroupPermissions, 'id'>
    >({
      query: ({ id, ...permissionGroup }) => {
        return {
          url: `core/groups/${id}`,
          method: 'PUT',
          body: permissionGroup,
        };
      },
    }),
    deletePermissionGroup: build.mutation<any, number>({
      query: (id) => {
        return {
          url: `core/groups/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getPermissions: build.query<any, void>({
      query: () => {
        return {
          url: `core/permissions`,
          method: 'GET',
        };
      },
      transformResponse: (response: any) => {
        return formatPermissionsArray(response);
      },
    }),
  }),
});
export const {
  useGetPermissionsGroupQuery,
  useCreatePermissionGroupMutation,
  useUpdatePermissionGrupMutation,
  useDeletePermissionGroupMutation,
  useGetPermissionsQuery,
} = groupsApi;
