import { TabPane, Tabs, ScreenLayout } from '../../components';
import { PermissionGroups } from '../permissionGroups/PermissionGroups';
import { Users } from '../users/Users';

export function Permissions() {
  const tabs = [
    { name: 'Usuários', key: 'USERS', table: Users },
    { name: 'Grupos de permissões', key: 'GROUPS', table: PermissionGroups },
  ];

  return (
    <ScreenLayout title="Usuários e permissões">
      <Tabs
        defaultActiveKey="USERS"
        size="large"
        style={{
          height: 'calc(100vh - 82px)',
          position: 'relative',
          background: 'var(--neutral_lightest)',
        }}
      >
        {tabs.map((tab) => {
          return (
            <TabPane
              tab={tab.name}
              key={tab.key}
              style={{ height: 'calc(100% - 57px)' }}
            >
              <tab.table />
            </TabPane>
          );
        })}
      </Tabs>
    </ScreenLayout>
  );
}
