import { Collapse, Descriptions, FormatItem } from '../../../../components';
import { DockingTypeDescription } from '../../../../types';

const { Item } = Descriptions;
const { Panel } = Collapse;

type ManoeuvreDescriptionCollapseProps = {
  dockingDescription: DockingTypeDescription;
  manoeuvre: 'docking_manoeuvre' | 'undocking_manoeuvre';
  onOpenPanel(panel: string | string[]): void;
  openCollapsePanels: string | string[];
};

export function ManoeuvreDescriptionCollapse(
  props: ManoeuvreDescriptionCollapseProps
) {
  const { dockingDescription, manoeuvre, onOpenPanel, openCollapsePanels } =
    props;

  const manoeuvreTitle =
    manoeuvre === 'docking_manoeuvre' ? 'atracação' : 'desatracação';

  const moviment = manoeuvre === 'docking_manoeuvre' ? 'entrada' : 'saída';

  return (
    <Collapse
      expandIconPosition="end"
      onChange={onOpenPanel}
      activeKey={openCollapsePanels}
    >
      <Panel
        forceRender
        header={`Manobra de ${manoeuvreTitle}`}
        key={manoeuvre}
      >
        <Descriptions>
          <Item label={`Deslocamento para ${moviment}`}>
            <FormatItem>
              {manoeuvre === 'docking_manoeuvre'
                ? dockingDescription.entrance_displacement
                : dockingDescription.exit_displacement}
            </FormatItem>
          </Item>
          <Item label="Manobra acompanhada?">
            {dockingDescription[manoeuvre]?.supervised}
          </Item>
          <Item label="Plantonista que acomp.">
            <FormatItem>
              {dockingDescription[manoeuvre]?.on_duty_that_accompanied}
            </FormatItem>
          </Item>
          <Item label="Calado de proa">
            <FormatItem>
              {dockingDescription[manoeuvre]?.bow_draught}
            </FormatItem>
          </Item>
          <Item label="Calado de popa">
            <FormatItem>
              {dockingDescription[manoeuvre]?.stern_draught}
            </FormatItem>
          </Item>
          <Item label={`Calado na ${moviment}`}>
            <FormatItem>
              {manoeuvre === 'docking_manoeuvre'
                ? dockingDescription.input_draught
                : dockingDescription.output_draught}
            </FormatItem>
          </Item>
          <Item label={`DWT na ${moviment}`}>
            <FormatItem>
              {manoeuvre === 'docking_manoeuvre'
                ? dockingDescription.input_dwt
                : dockingDescription.output_dwt}
            </FormatItem>
          </Item>
          {/* <Item label="Manobra confirmada?" span={3}>
              {dockingDescription[manoeuvre]?.confirmed}
            </Item> */}
        </Descriptions>
        <Descriptions title="PRATICAGEM">
          <Item label="Empresa">
            <FormatItem>
              {dockingDescription[manoeuvre]?.pilotage_company}
            </FormatItem>
          </Item>
          <Item label="Período a bordo">
            <div>
              <FormatItem>
                {dockingDescription[manoeuvre]?.start_period_on_board}
              </FormatItem>
              <span> até </span>
              <FormatItem>
                {dockingDescription[manoeuvre]?.end_period_on_board}
              </FormatItem>
            </div>
          </Item>
          <Item label="Práticos">
            <FormatItem>{dockingDescription[manoeuvre]?.pilots}</FormatItem>
          </Item>
          <Item label="Praticante">
            <FormatItem>
              {dockingDescription[manoeuvre]?.practitioner}
            </FormatItem>
          </Item>
          <Item label="Fez navegação interna?">
            {dockingDescription[manoeuvre]?.did_internal_navigation}
          </Item>
          <Item
            label={
              manoeuvre === 'docking_manoeuvre'
                ? 'Início da navegação interna'
                : 'Saída na barra'
            }
          >
            <FormatItem>
              {manoeuvre === 'docking_manoeuvre'
                ? dockingDescription.start_of_internal_navigation
                : dockingDescription.end_of_internal_navigation}
            </FormatItem>
          </Item>
        </Descriptions>
        <Descriptions title="REBOCAGEM">
          <Item label="Empresa">
            <FormatItem>
              {dockingDescription[manoeuvre]?.towage_company}
            </FormatItem>
          </Item>
          <Item label="Rebocadores" span={2}>
            <div className="span-neutral-medium">
              <FormatItem>
                {dockingDescription[manoeuvre]?.bow_tugboat}
              </FormatItem>
              <span>(proa), </span>
              <FormatItem>
                {dockingDescription[manoeuvre]?.stern_tugboat}
              </FormatItem>
              <span>(popa), </span>
              <FormatItem>
                {dockingDescription[manoeuvre]?.standby_tugboat}
              </FormatItem>
              <span>(stand by), </span>
              <FormatItem>
                {dockingDescription[manoeuvre]?.amidship_tugboat}
              </FormatItem>
              <span>(meia-nau)</span>
            </div>
          </Item>
        </Descriptions>
        <Descriptions
          title={
            manoeuvre === 'docking_manoeuvre' ? 'AMARRAÇÃO' : 'DESAMARRAÇÃO'
          }
        >
          <Item label="Empresa">
            <FormatItem>
              {dockingDescription[manoeuvre]?.mooring_company}
            </FormatItem>
          </Item>
          <Item
            label={
              manoeuvre === 'docking_manoeuvre' ? 'Amarração' : 'Desamarração'
            }
            span={2}
          >
            <div>
              <FormatItem>
                {manoeuvre === 'docking_manoeuvre'
                  ? dockingDescription.start_tied
                  : dockingDescription.start_untied}
              </FormatItem>
              <span> até </span>
              <FormatItem>
                {manoeuvre === 'docking_manoeuvre'
                  ? dockingDescription.end_tied
                  : dockingDescription.end_untied}
              </FormatItem>
            </div>
          </Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
}
