import { Skeleton } from 'antd';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Collapse } from '../../../../components';
import {
  OperationCraneType,
  Stopover as StopoverType,
} from '../../../../types';
import { formatCraneWorkPeriodToForm } from '../../../../utils/formatters';
import { formatOperationCraneToDescription } from '../../../operation/OperationsData/formatOperationToDescription';
import { CraneResumeDescription } from '../../../operation/OperationsData/operationDescriptionsSections/generalCargo/containers/craneResumeDescription';
import { ModalEditPeriod } from './modalEditPeriod';
import { OperatedContainersTable } from './operatedContainersTable';
import { OperatingPeriodsTable } from './operatingPeriodsTable';
import { Header } from './styles';

type ContainersByCranesCollapseDataProps = {
  operationCranes: OperationCraneType[];
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  isLoadingOperationCranes: boolean;
};

const { Panel } = Collapse;

export function ContainersByCranesCollapseData(
  props: ContainersByCranesCollapseDataProps
) {
  const {
    operationCranes,
    selectedStopover,
    setSelectedStopover,
    isLoadingOperationCranes,
  } = props;

  const [isVisibleEditPeriodModal, setIsVisibleEditPeriodModal] =
    useState(false);
  const [selectedWorkPeriod, setSelectedWorkPeriod] = useState<any | undefined>(
    {}
  );
  const [selectedOperationCrane, setSelectedOperationCrane] =
    useState<OperationCraneType>({} as OperationCraneType);

  const selectedOperationCranes = useMemo(() => {
    return operationCranes;
  }, [operationCranes]);

  function getHeader(name: string, length: number) {
    return (
      <Header>
        {/* <span>{name}</span> */}
        <span className="container-without-name">{length} contêineres</span>
      </Header>
    );
  }

  // function onAddWorkPeriod(operationCrane: OperationCraneType) {
  //   setSelectedWorkPeriod(undefined);
  //   setSelectedOperationCrane(operationCrane);
  //   setIsVisibleEditPeriodModal(true);
  // }

  // function onEditWorkPeriod(
  //   operationCrane: OperationCraneType,
  //   indexToUpdate: number
  // ) {
  //   const workPeriods = operationCrane.work_periods || [];
  //   if (workPeriods.length > 0) {
  //     setSelectedWorkPeriod(
  //       formatCraneWorkPeriodToForm(workPeriods[indexToUpdate])
  //     );
  //     setSelectedOperationCrane(operationCrane);
  //     setIsVisibleEditPeriodModal(true);
  //   }
  // }

  return (
    <Skeleton active loading={isLoadingOperationCranes}>
      <Collapse expandIconPosition="end" className="inner-collapse">
        {selectedOperationCranes?.map((operationCrane, index) => {
          const formattedCrane =
            formatOperationCraneToDescription(operationCrane);
          return (
            <Panel
              key={index}
              header={getHeader(
                operationCrane.crane_name,
                operationCrane.containers?.length || 0
              )}
            >
              <CraneResumeDescription
                operationCrane={formattedCrane}
                selectedStopover={selectedStopover}
                setSelectedStopover={setSelectedStopover}
                pageContext="OPERATOR_AREA"
              />
              <OperatedContainersTable
                containers={operationCrane?.containers}
              />
              {/* <OperatingPeriodsTable
              periods={formattedCrane.work_periods}
              totalPeriodsDuration={formattedCrane.total_periods_duration}
              onAdd={() => onAddWorkPeriod(operationCrane)}
              onEdit={(index: number) =>
                onEditWorkPeriod(operationCrane, index)
              }
            /> */}
            </Panel>
          );
        })}
        {/* <ModalEditPeriod
        showModal={isVisibleEditPeriodModal}
        setShowModal={setIsVisibleEditPeriodModal}
        selectedWorkPeriod={selectedWorkPeriod}
        setSelectedWorkPeriod={setSelectedWorkPeriod}
        operationCraneId={selectedOperationCrane?.id}
        setSelectedOperationCranes={setSelectedOperationCranes}
      /> */}
      </Collapse>
    </Skeleton>
  );
}
