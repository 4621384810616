import { Row } from 'antd';
import { debounce } from 'lodash';
import { useState } from 'react';

import {
  FormItemDatePicker,
  FormItemSelect,
  FormItemTextArea,
  FormItemTreeSelect,
} from '../../../components';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import { useGetUsersQuery } from '../../../services/usersApi';
import { BreakdownOccurrenceType, Docking, TreeType } from '../../../types';
import { onDutyList } from '../../../utils/lists';
import { dockingOptionRenderer, shipownerOptionRenderer } from './common';

type BreakdownFormItemsProps = {
  hideDocking?: boolean;
  breakdownSelected: BreakdownOccurrenceType;
  dockings: Docking[] | null | undefined;
  equipments: TreeType[];
};

export function BreakdownFormItems(props: BreakdownFormItemsProps) {
  const { hideDocking, breakdownSelected, dockings, equipments } = props;

  const [userOnDutyName, setUserOnDutyName] = useState('');

  const [searchShipownerName, setSearchShipownerName] = useState(
    breakdownSelected?.shipowner?.name || ''
  );
  const [hasShipowner, setHasShipowner] = useState(
    breakdownSelected?.breakdown_occurrence_type?.id === 14 || false
  );

  const { data: shipownerData } = useGetCompaniesQuery(
    { name_or_cnpj: searchShipownerName, company_type: 'SHIPOWNER' },
    { skip: searchShipownerName === '' }
  );

  const { data: usersOnDutyData, isLoading: isLoadingUsersOnDutyData } =
    useGetUsersQuery({
      role: 'ON_DUTY',
      first_name_or_last_name: userOnDutyName,
    });

  const { data: BreakdownOccurrenceTypes } = useGetSystemParamByNameQuery({
    name: 'BREAKDOWN_TYPE',
  });
  function onSearchShipowner(val: string) {
    setSearchShipownerName(val);
  }

  function checkIfNeedShipowner(breakdownId: number) {
    const BreakdownOccurrenceTypeSelected =
      BreakdownOccurrenceTypes?.items.find((item) => item.id === breakdownId);
    setHasShipowner(false);
    if (BreakdownOccurrenceTypeSelected?.code === 'DAMAGE_TO_PORT_FACILITIES') {
      setHasShipowner(true);
    }
  }
  return (
    <>
      <Row gutter={40}>
        <FormItemDatePicker
          colSpan={12}
          label="Data/Hora da avaria"
          name="date"
        />
        <FormItemSelect
          colSpan={12}
          name="duty"
          label="Plantão"
          dataList={onDutyList}
        />
      </Row>
      <Row gutter={40}>
        <FormItemSelect
          colSpan={12}
          name={['on_duty', 'id']}
          label="Plantonista"
          allowClear
          notFoundContent="Não existem plantonistas cadastrados"
          dataList={usersOnDutyData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          onSearch={debounce((e: string) => setUserOnDutyName(e), 400)}
          isLoading={isLoadingUsersOnDutyData}
        />
        <FormItemSelect
          colSpan={12}
          name={['breakdown_occurrence_type', 'id']}
          label="Tipo de avaria"
          dataList={BreakdownOccurrenceTypes?.items}
          onSelect={checkIfNeedShipowner}
        />
      </Row>
      <Row gutter={40}>
        {!hideDocking && (
          <FormItemSelect
            colSpan={12}
            name={['docking', 'id']}
            label="Atracação"
            dataList={dockings || []}
            optionRenderer={dockingOptionRenderer}
          />
        )}
        {hasShipowner && (
          <FormItemSelect
            colSpan={12}
            name={['shipowner', 'id']}
            label="Armador avariador"
            dataList={shipownerData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            showSearch
            allowClear
            onSearch={debounce(onSearchShipowner, 400)}
            notFoundContent="Digite uma busca"
            optionRenderer={shipownerOptionRenderer}
          />
        )}
      </Row>
      <Row gutter={40}>
        <FormItemTreeSelect
          name="equipments"
          label="Equipamento/Instalação avariada"
          treeData={equipments}
        />
      </Row>
      <Row gutter={40}>
        <FormItemTextArea
          name="description"
          label="Descrição da avaria"
          maxLength={500}
          rows={5}
        />
      </Row>
    </>
  );
}
