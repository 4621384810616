import { Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';

import { Modal, Form, FormItemInput } from '../../components';
import { StopoverVehiclesImportType } from '../../typesWarehousing';

type ModalEditDIProps = {
  isVisibleEditDIModal: boolean;
  setIsVisibleEditDIModal: (visible: boolean) => void;
  isPatchingImportVehiclesGroup: boolean;
  selectedImportGroup: StopoverVehiclesImportType;
  setSelectedImportGroup: (importGroup: StopoverVehiclesImportType) => void;
  onUpdateDI: () => void;
  form: FormInstance;
};
export function ModalEditDI(props: ModalEditDIProps) {
  const {
    isVisibleEditDIModal,
    setIsVisibleEditDIModal,
    isPatchingImportVehiclesGroup,
    selectedImportGroup,
    setSelectedImportGroup,
    onUpdateDI,
    form,
  } = props;

  function onCancel() {
    setSelectedImportGroup({} as StopoverVehiclesImportType);
    setIsVisibleEditDIModal(false);
  }

  return (
    <Modal
      visible={isVisibleEditDIModal}
      className="TOSPrimaryModal"
      width={520}
      title="Atualizar Número da DI"
      onCancel={onCancel}
      okText="Confirmar"
      cancelText="Cancelar"
      okButtonProps={{
        form: 'edit_di',
        htmlType: 'submit',
        loading: isPatchingImportVehiclesGroup,
      }}
    >
      <Form
        name="edit_di"
        layout="vertical"
        form={form}
        onFinish={onUpdateDI}
        initialValues={selectedImportGroup}
      >
        <Row gutter={24}>
          <FormItemInput
            name="di_number"
            label="Número DI"
            required
            maxLength={40}
          />
        </Row>
      </Form>
    </Modal>
  );
}
