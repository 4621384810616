import { Dispatch, SetStateAction, useState } from 'react';

import { Drawer } from '../../../../components';
import { OperationProductAndCraneType, Stopover } from '../../../../types';
import { CompartmentEditForm } from './CompartmentEditForm';

type CompartmentEditDrawerProps = {
  isVisibleForm: boolean;
  setIsVisibleForm: (value: boolean) => void;
  selectedProduct: OperationProductAndCraneType;
  selectedStopover: Stopover;
  setSelectedStopover?: Dispatch<SetStateAction<Stopover>>;
};

export function CompartmentEditDrawer(props: CompartmentEditDrawerProps) {
  const {
    isVisibleForm,
    setIsVisibleForm,
    selectedProduct,
    selectedStopover,
    setSelectedStopover,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  function onBack() {
    setIsVisibleForm(false);
  }

  return (
    <Drawer
      visible={isVisibleForm}
      width={690}
      destroyOnClose
      onClose={() => onBack()}
      closable={false}
      maskClosable={false}
    >
      <CompartmentEditForm
        onFinishSubmit={onBack}
        onBack={onBack}
        setIsVisibleForm={setIsVisibleForm}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
        selectedProduct={selectedProduct}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
      />
    </Drawer>
  );
}
