import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './app/store/index';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.variable.min.css';
import './styles/index.css';

ConfigProvider.config({
  theme: {
    primaryColor: '#09D4AB',
    errorColor: '#E65050',
    warningColor: '#FCCC0A',
    successColor: '#54D25D',
    infoColor: '#25BFD5',
  },
});
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={pt_BR}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
