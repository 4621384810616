import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    token: Cookies.get('token'),
    refresh: Cookies.get('refresh'),
  },
  reducers: {
    changeToken(state, { payload }) {
      Cookies.set('token', payload.access, {
        expires: 7,
        secure: true,
        sameSite: 'Lax',
      });
      Cookies.set('refresh', payload.refresh, {
        secure: true,
        sameSite: 'Lax',
      });
      return {
        ...state,
        token: payload.access,
        refresh: payload.refresh,
      };
    },
    removeToken(state) {
      Cookies.remove('token');
      Cookies.remove('refresh');
      return { ...state, token: '', refresh: '' };
    },
    refreshToken: (state, { payload }) => {
      Cookies.set('token', payload.access, {
        expires: 7,
        secure: true,
        sameSite: 'Lax',
      });
      return {
        ...state,
        token: payload.access,
        refresh: payload.refresh,
      };
    },
  },
});

export const { changeToken, removeToken, refreshToken } = slice.actions;

export default slice.reducer;
