import { Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { isEmpty } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';

import {
  Checkbox,
  FormItem,
  FormItemDatePicker,
  FormItemSelect,
  FormItemTextArea,
  CheckBoxButtonGroup,
  FormItemsCheckbox,
} from '../../../components';
import { useGetCompartmentsQuery } from '../../../services/compartmentsApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import {
  OperationCraneType,
  OperationProductCompartmentType,
  StoppageType,
} from '../../../types';
import { compartmentTypeList } from '../../../utils/lists';
import { createDateString } from '../../../utils/utils';

type FormItemsWorkShifStoppagesProps = {
  style?: CSSProperties;
  form: FormInstance;
  operationId?: number;
  setIsFormChanged: (changed: boolean) => void;
  setShiftImpactedFilter: (filter: any) => void;
  operationCranes?: OperationCraneType[];
  selectedStoppage: StoppageType;
};

export function FormItemsWorkShifStoppages(
  props: FormItemsWorkShifStoppagesProps
) {
  const {
    style,
    form,
    operationId,
    setIsFormChanged,
    setShiftImpactedFilter,
    operationCranes,
    selectedStoppage,
  } = props;

  const { data: stoppageMotivesData, isLoading: isLoadingStoppageMotivesData } =
    useGetSystemParamByNameQuery({
      name: 'STOPPAGE_MOTIVES',
    });

  const { data: compartmentsData } = useGetCompartmentsQuery({
    operation_id: operationId,
  });

  const [isAllCompartmentsChecked, setIsAllcompartmentsChecked] =
    useState(false);

  const [isAllCranesChecked, setIsAllCranesChecked] = useState(false);
  const [isTotalChecked, setIsTotalChecked] = useState(false);

  useEffect(() => {
    const compartments: OperationProductCompartmentType[] =
      form.getFieldValue('compartments');
    if (compartments) {
      setIsAllcompartmentsChecked(
        compartments.length === getCompartmentList().length
      );
    }
  }, [compartmentsData?.results, form.getFieldValue('compartments')]);

  useEffect(() => {
    const opCranes = selectedStoppage?.operation_cranes;
    if (opCranes) {
      setIsAllCranesChecked(opCranes.length === getCranesList().length);
    }
  }, [selectedStoppage]);

  useEffect(() => {
    if (isEmpty(selectedStoppage)) {
      setIsAllcompartmentsChecked(false);
      setIsAllCranesChecked(false);
    }
    setIsTotalChecked(selectedStoppage.is_total || false);
  }, [selectedStoppage]);

  function onChangePeriod() {
    const start = form?.getFieldValue(['start']);
    const end = form?.getFieldValue(['end']);

    if (start && end) {
      setShiftImpactedFilter({
        start_of_period: createDateString(start),
        end_of_period: createDateString(end),
        operation_id: operationId,
      });
    }
  }

  const onChange = (list: CheckboxValueType[]) => {
    form.setFieldsValue({ compartments: list });
    setIsAllcompartmentsChecked(list.length === getCompartmentList().length);
  };

  const onChangeSelectedCranes = (list: CheckboxValueType[]) => {
    form.setFieldsValue({ operation_cranes: list });
    setIsAllCranesChecked(list.length === getCranesList().length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const idList = getCompartmentList().map((op) => op.value);
    form.setFieldsValue({ compartments: e.target.checked ? idList : [] });
    setIsAllcompartmentsChecked(e.target.checked);
    setIsFormChanged(true);
  };

  const onCheckAllChangeCrane = (e: CheckboxChangeEvent) => {
    const idList = getCranesList().map((op) => op.value);
    form.setFieldsValue({ operation_cranes: e.target.checked ? idList : [] });
    setIsAllCranesChecked(e.target.checked);
    setIsFormChanged(true);
  };

  const onCheckTotalStoppageChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({ is_total: e.target.checked });
  };

  function getCompartmentDescription(
    compartment: OperationProductCompartmentType
  ) {
    const compartmentType = compartmentTypeList.find(
      (type) => type.id === compartment.compartment_type
    );

    if (compartmentType) {
      return `${compartmentType.name} ${compartment.name}`;
    }

    return null;
  }

  function getCompartmentList() {
    return (compartmentsData?.results || []).map((compartment) => {
      return {
        label: getCompartmentDescription(compartment),
        value: compartment.id,
      };
    });
  }

  function getCranesList() {
    return (operationCranes || []).map((crane) => ({
      label:
        crane.crane_name === 'Guindaste Não Definido'
          ? 'Guindaste geral'
          : crane.crane_name,
      value: crane.id,
    }));
  }

  function handleChangeIsTotal(e: CheckboxChangeEvent) {
    setIsTotalChecked(false);
    if (e.target.checked === true) {
      form.setFieldsValue({ operation_cranes: [], compartments: [] });
      setIsAllcompartmentsChecked(false);
      setIsAllCranesChecked(false);
      setIsTotalChecked(true);
    }
  }

  return (
    <div style={style}>
      {operationCranes && operationCranes.length > 0 && (
        <Row gutter={40} align="bottom" style={{ marginBottom: '24px' }}>
          <Col span={24}>
            <FormItem
              style={{ marginBottom: '8px' }}
              label="Selecione os guindastes impactados"
              name="operation_cranes"
            >
              <CheckBoxButtonGroup
                disabled={isTotalChecked}
                options={getCranesList()}
                value={form.getFieldValue('operation_cranes')}
                onChange={onChangeSelectedCranes}
              />
            </FormItem>
            <Checkbox
              disabled={isTotalChecked}
              onChange={onCheckAllChangeCrane}
              checked={isAllCranesChecked}
            >
              Selecionar todos os guindastes desta operação
            </Checkbox>
          </Col>
        </Row>
      )}
      {compartmentsData && compartmentsData?.count > 0 && (
        <Row gutter={40} align="bottom" style={{ marginBottom: '24px' }}>
          <Col span={24}>
            <FormItem
              style={{ marginBottom: '8px' }}
              label="Selecione os compartimentos impactados"
              name="compartments"
            >
              <CheckBoxButtonGroup
                disabled={isTotalChecked}
                options={getCompartmentList()}
                value={form.getFieldValue('compartments')}
                onChange={onChange}
              />
            </FormItem>
            <Checkbox
              disabled={isTotalChecked}
              onChange={onCheckAllChange}
              checked={isAllCompartmentsChecked}
            >
              Selecionar todos os compartimentos desta operação
            </Checkbox>
          </Col>
        </Row>
      )}
      <Row gutter={24} align="bottom" style={{ marginBottom: '24px' }}>
        <FormItemsCheckbox name="is_total" onChange={handleChangeIsTotal}>
          Paralisação total (será compartilhada com todos os operadores desta
          operação)
        </FormItemsCheckbox>
      </Row>
      <Row gutter={40} align="bottom">
        <FormItemDatePicker
          colSpan={12}
          label="Início da paralisação"
          name="start"
          required
          rules={[{ required: true, message: 'campo obrigatório' }]}
          onChange={onChangePeriod}
        />
        <FormItemDatePicker
          colSpan={12}
          label="Fim da paralisação"
          name="end"
          required
          rules={[{ required: true, message: 'campo obrigatório' }]}
          onChange={onChangePeriod}
        />
      </Row>
      <Row gutter={40} align="bottom">
        <FormItemSelect
          name="motive"
          label="Motivo da paralisação para ANTAQ"
          dataList={stoppageMotivesData?.items}
          allowClear
          showSearch
          notFoundContent="Nenhum motivo encontrado para o filtro informado"
          required
          isLoading={isLoadingStoppageMotivesData}
        />
      </Row>
      <Row gutter={24} align="bottom">
        <FormItemTextArea
          name="observations"
          label="Observações"
          hideCount
          rows={2}
        />
      </Row>
    </div>
  );
}
