import { Row, Col } from 'antd';
import { useState } from 'react';

import { Button, Modal } from '../../../../components';
import {
  Stopover as StopoverType,
  Vessel as VesselType,
} from '../../../../types';
import { VesselDescription } from './vesselDescription';
import { VesselStopoverEditDrawer } from './vesselStopoverEditDrawer/VesselStopoverEditDrawer';

type VesselDataProps = {
  selectedVessel: VesselType;
  selectedStopover: StopoverType;
  setSelectedStopover: (value: StopoverType) => void;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
};

export function VesselData(props: VesselDataProps) {
  const {
    selectedVessel,
    selectedStopover,
    setSelectedStopover,
    activeKey,
    onOpenPanel,
  } = props;
  const [showVesselNamesHistoryModal, setShowVesselNamesHistoryModal] =
    useState(false);

  const [
    isVesselStopoverEditDrawerVisible,
    setIsVesselStopoverEditDrawerVisible,
  ] = useState(false);

  function onBack() {
    setIsVesselStopoverEditDrawerVisible(false);
  }

  function onEdit(evt: any) {
    evt.stopPropagation();
    setIsVesselStopoverEditDrawerVisible(true);
  }

  return (
    <>
      {selectedStopover && (
        <Modal
          footer={null}
          title="Histórico de nomes"
          width={600}
          className="TOSPrimaryModal"
          visible={showVesselNamesHistoryModal}
          onCancel={() => setShowVesselNamesHistoryModal(false)}
        >
          <p>
            <b>
              Nomes anteriores da embarcação {selectedStopover?.vessel?.name}
            </b>
          </p>
          <br />
          <Row gutter={24} style={{ fontWeight: 600, marginBottom: '15px' }}>
            <Col span={6}>Data da alteração</Col>
            <Col span={9}>Nome da embarcação</Col>
            <Col span={9}>Armador</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              borderBottom: 'solid 1px var(--neutral_lighter)',
              paddingBottom: '10px',
            }}
          >
            <Col span={6}>02/10/2020</Col>
            <Col span={9}>Nome</Col>
            <Col span={9}>Columbia</Col>
          </Row>
          <Row justify="end" style={{ marginTop: '15px' }}>
            <Button
              type="primary"
              onClick={() => setShowVesselNamesHistoryModal(false)}
            >
              OK
            </Button>
          </Row>
        </Modal>
      )}
      <VesselStopoverEditDrawer
        visible={isVesselStopoverEditDrawerVisible}
        setIsVisibleForm={setIsVesselStopoverEditDrawerVisible}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        width={800}
        onBack={onBack}
      />
      <VesselDescription
        selectedVessel={selectedVessel}
        updatedAt={selectedStopover.updated_at}
        activeKey={activeKey}
        onOpenPanel={onOpenPanel}
        onEdit={onEdit}
        screen="STOPOVER"
      />
    </>
  );
}
