import {
  ArrowLeftOutlined,
  CheckOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Alert, Col, message, Row } from 'antd';
import { useEffect, useState } from 'react';

import {
  Button,
  Form,
  FormItem,
  Input,
  Select,
  FormHeaderWrapper,
  FormItemSelect,
  FormItemInput,
} from '../../components';
import {
  useGetCompaniesQuery,
  useGetCompanyRolesQuery,
} from '../../services/companyApi';
import { useGetPermissionsGroupQuery } from '../../services/groupsApi';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateUserPermissionsGroupMutation,
  useUpdateUserStatusMutation,
} from '../../services/usersApi';
import { CompanyType, GroupPermissions, User } from '../../types';
import { userAccessStatus } from '../../utils/lists';
import { formatUser } from './formatUser';

export type UserFormProps = {
  name: string;
  selectedUser?: User | null;
  readOnly: boolean;
  onCloseDrawer: any;
  onSaveUser: any;
};

export function UserForm(props: UserFormProps) {
  const [userForm] = Form.useForm();

  const [changedPermissions, setChangedPermissions] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState<number[]>([]);

  const { data: permissionGroups } = useGetPermissionsGroupQuery();

  const { data: companiesData } = useGetCompaniesQuery({
    name_or_cnpj:
      companyName === '' && props.selectedUser
        ? props.selectedUser?.company?.name
        : companyName,
    page_size: 100,
  });
  const { data: companyRolesData } = useGetCompanyRolesQuery();

  const [
    updateUser,
    { isSuccess: isSuccessUpdateUser, isError: isErrorUpdateUser },
  ] = useUpdateUserMutation();

  const [
    updateUserPermissionsGroup,
    {
      isSuccess: isSuccessUpdateUserPermissionsGroup,
      isError: isErrorUpdateUserPermissionsGroup,
    },
  ] = useUpdateUserPermissionsGroupMutation();

  const [
    createUser,
    { isSuccess: isSuccessCreateUser, isError: isErrorCreateUser },
  ] = useCreateUserMutation();

  const [
    updateUserStatus,
    { isSuccess: isSuccessUpdateUserStatus, isError: isErrorUpdateUserStatus },
  ] = useUpdateUserStatusMutation();

  function onBack() {
    props.onCloseDrawer();
  }

  function onChangePermission(_value: any) {
    if (!changedPermissions) {
      setChangedPermissions(true);
    }
  }

  async function handleForm(values: any) {
    if (props.selectedUser) {
      // Is editing a user
      putUser(values);
    } else {
      // Is creating a user
      postUser(values);
    }
    return onBack(); // Close drawer and reset form
  }

  async function postUser(values: any) {
    const createdUser = await createUser(formatUser(values));
    if ('data' in createdUser) {
      props.onSaveUser(createdUser.data); // Add user to table
      if (changedPermissions) {
        const createdUserId = createdUser.data.id;
        const updatedPermissions = await updateUserPermissionsGroup({
          groups: values.groups,
          id: createdUserId,
        }); // Update only user permissions
        if ('data' in updatedPermissions) {
          props.onSaveUser(updatedPermissions.data); // Add permissions to table user
        }
      }
    }
  }

  async function putUser(values: any) {
    const updatedUser = await updateUser(formatUser(values)); // Update user infos
    if ('data' in updatedUser) {
      props.onSaveUser(updatedUser.data); // Update table user info
    }
    let updatedPermissions;
    if (changedPermissions) {
      updatedPermissions = await updateUserPermissionsGroup({
        groups: values.groups,
        id: values.id,
      }); // Update only user permissions
      if ('data' in updatedPermissions) {
        props.onSaveUser(updatedPermissions.data); // Update table user permissions
      }
    }
  }

  async function reactivateUser() {
    if (props.selectedUser?.id) {
      const updatedUser = await updateUserStatus({
        status: 'PENDING',
        id: props.selectedUser?.id,
      });
      if ('data' in updatedUser) {
        props.onSaveUser(updatedUser.data);
      }
    }
    return onBack(); // Close drawer and reset form
  }

  useEffect(() => {
    if (changedPermissions) {
      if (isSuccessUpdateUser && isSuccessUpdateUserPermissionsGroup) {
        message.success('O usuário e os grupos de permissão foram atualizados');
      } else if (isErrorUpdateUserPermissionsGroup) {
        message.error(
          'Ocorreu um erro ao atualizar os grupos de permissão do usuário. Se o erro persistir entre em contato com o suporte'
        );
      } else if (isErrorUpdateUser) {
        message.error(
          'Ocorreu um erro ao atualizar o usuário. Se o erro persistir entre em contato com o suporte'
        );
      }
    } else {
      if (isSuccessUpdateUser) {
        message.success('Usuário atualizado');
      } else if (isErrorUpdateUser) {
        message.error(
          'Ocorreu um erro ao atualizar o usuário. Se o erro persistir entre em contato com o suporte'
        );
      }
      if (isSuccessUpdateUserStatus) {
        message.success('Usuário reativado');
      } else if (isErrorUpdateUserStatus) {
        message.error(
          'Ocorreu um erro ao reativar o usuário. Se o erro persistir entre em contato com o suporte'
        );
      }
    }
  }, [
    isSuccessUpdateUser,
    isSuccessUpdateUserPermissionsGroup,
    isErrorUpdateUserPermissionsGroup,
    isErrorUpdateUser,
    isSuccessUpdateUserStatus,
    isErrorUpdateUserStatus,
  ]);

  useEffect(() => {
    if (isSuccessCreateUser) {
      message.success('O usuário foi convidado');
    } else if (isErrorCreateUser) {
      message.error(
        'Ocorreu um erro ao convidar o usuário. Se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessCreateUser, isErrorCreateUser]);

  function onSelectCompany(value: any) {
    if (companiesData?.results) {
      const selectedCompany = companiesData.results.find(
        (company: CompanyType) => {
          return company.id === value;
        }
      );
      setCompanyType(selectedCompany?.company_type || []);
    }
  }

  useEffect(() => {
    if (props.selectedUser) {
      setCompanyType(
        props.selectedUser?.company?.company_type.flatMap(
          (company_type: any) => [company_type.id]
        ) || []
      );
    }
  }, [props.selectedUser]);

  function getCompanyRoles() {
    const rolesTranslation: { [code: string]: string } = {
      PILOT: 'Prático',
      PRACTITIONER: 'Praticante',
      ON_DUTY: 'Plantonista',
    };

    return companyRolesData?.results.map((role) => {
      return {
        ...role,
        name: rolesTranslation[role.name],
      };
    });
  }

  return (
    <Form
      form={userForm}
      name={props.name}
      id={props.name}
      initialValues={props.selectedUser || {}}
      onFinish={handleForm}
      layout="vertical"
      autoComplete="off"
    >
      <FormHeaderWrapper>
        <div className="buttons">
          <div style={{ marginLeft: '-15px' }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={() => onBack()}
            >
              Voltar
            </Button>
          </div>
          <div>
            {props.selectedUser?.status !== 'DEACTIVATED' ? (
              <Button
                type="primary"
                icon={<CheckOutlined />}
                htmlType="submit"
                form={props.name}
                key={props.name}
              >
                {props.selectedUser ? 'Salvar alterações' : 'Enviar convite'}
              </Button>
            ) : (
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => reactivateUser()}
              >
                Reabilitar
              </Button>
            )}
          </div>
        </div>
        <div className="title">
          {props.selectedUser ? '' : 'CONVIDAR NOVO USUÁRIO'}
        </div>
      </FormHeaderWrapper>
      <FormItem name="id" noStyle />
      <div style={{ padding: '32px' }}>
        <Row gutter={32}>
          <Col span={12}>
            <FormItem
              label="Nome"
              name={['user', 'first_name']}
              rules={[{ required: true, message: 'campo obrigatório' }]}
            >
              <Input readOnly={props.readOnly} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Sobrenome"
              name={['user', 'last_name']}
              rules={[{ required: true, message: 'campo obrigatório' }]}
            >
              <Input readOnly={props.readOnly} />
            </FormItem>
          </Col>
          <FormItemInput
            label="E-mail"
            name={['user', 'email']}
            type="email"
            maxLength={50}
            required
            readOnly={props.readOnly}
          />
          <FormItemSelect
            colSpan={12}
            name={['company', 'id']}
            label="Empresa"
            onSearch={setCompanyName}
            onSelect={onSelectCompany}
            debounceDelay={300}
            showSearch
            allowClear
            dataList={companiesData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
          <FormItemSelect
            colSpan={12}
            name={['role', 'id']}
            label="Cargo"
            dataList={getCompanyRoles()}
          />
          <Col span={24}>
            <FormItem label="Grupos de permissão" name={['groups']}>
              <Select
                disabled={props.readOnly}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Selecione os grupos de permissão do usuário"
                onChange={onChangePermission}
              >
                {permissionGroups?.results.map((group: GroupPermissions) => {
                  return (
                    <Select.Option value={group.id}>{group.name}</Select.Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          {!props.selectedUser && (
            <Col span={24}>
              <Alert
                type="info"
                icon={<InfoCircleOutlined />}
                showIcon
                message="O usuário receberá um email com instruções para criação de sua própria senha"
              />
            </Col>
          )}
        </Row>
        {props.selectedUser && (
          <Row gutter={32}>
            <Col span={12}>
              <FormItem label="Criado em" name={['created_at']}>
                <Input readOnly />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Status de acesso" name={['status']}>
                <Select disabled style={{ width: '100%' }}>
                  {userAccessStatus.map((group: any) => {
                    return (
                      <Select.Option value={group.value}>
                        {group.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
        )}
      </div>
    </Form>
  );
}
