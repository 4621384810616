import { EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { Button, Tag } from '../../components';
import { VehicleType } from '../../typesWarehousing';
import { chassisStatus } from '../../utils/lists';

type OperatorExportVehiclesTableColumnsProps = {
  onSelect: (vehicle: VehicleType) => void;
  vehicleDescription?: string | null;
};
export function OperatorExportVehiclesTableColumns(
  props: OperatorExportVehiclesTableColumnsProps
): ColumnsType<Record<string, any>> {
  const { vehicleDescription, onSelect } = props;
  return [
    {
      title: 'Chassi',
      dataIndex: 'chassis',
      render: (value) => {
        return <div style={{ fontFamily: 'Consolas' }}>{value}</div>;
      },
    },
    {
      title: 'Modelo',
      render: () => {
        return vehicleDescription;
      },
    },
    {
      title: 'Status chassi',
      dataIndex: 'chassis_status',
      render: (value) => {
        return (
          <Tag>
            {
              chassisStatus.find((chassiStatus) => chassiStatus.value === value)
                ?.label
            }
          </Tag>
        );
      },
    },
    {
      title: 'Vaga',
      dataIndex: 'parking_space',
      render: (value) => {
        if (value === 'bolsao') {
          return 'Bolsão';
        }
        return value;
      },
    },
    {
      title: '',
      render: (_value, record) => {
        return (
          <Button
            icon={<EyeOutlined />}
            style={{ float: 'right' }}
            onClick={() => onSelect(record as VehicleType)}
          />
        );
      },
    },
  ];
}
