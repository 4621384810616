import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

import { Button, Collapse, Dropdown } from '../../../components';
import { Alert } from '../../../components/antd/Alert';
import { CargoManifestType } from '../../../types';
import { isNullOrUndefined } from '../../../utils/utils';
import { EditCargoNavigationTypeModal } from '../manifestsData/manifestsImportedCollapse/editCargoNavigationTypeModal';
import { AssociateLoadingModal } from './associateLoadingModal/AssociateLoadingModal';
import { LoadingDataCollapse } from './loadingDataCollapse/LoadingDataCollapse';
import { LoadingDataContainer } from './styles';

type LoadingDataProps = {
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  stopoverId: number;
  uploadManifestsData?: CargoManifestType[];
  setUploadManifestsData: Dispatch<SetStateAction<CargoManifestType[]>>;
  isFetchingUploadManifests: boolean;
};

const { Panel } = Collapse;

export function LoadingData(props: LoadingDataProps) {
  const {
    activeKey,
    onOpenPanel,
    stopoverId,
    uploadManifestsData,
    setUploadManifestsData,
    isFetchingUploadManifests,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectedManifest, setSelectedManifest] = useState('');
  const [
    isModalEditCargoNavigationTypeVisible,
    setIsModalEditCargoNavigationTypeVisible,
  ] = useState(false);

  const [selectedCargoManifest, setSelectedCargoManifest] = useState(
    {} as CargoManifestType
  );

  const [selectedOpDirectionManifest, setSelectedOpDirectionManifest] =
    useState('');

  function handleOpenModalLoading(manifest: CargoManifestType) {
    if (isNullOrUndefined(manifest.cargo_navigation_type)) {
      setIsModalEditCargoNavigationTypeVisible(true);
      setSelectedCargoManifest(manifest);
    } else {
      setSelectedManifest(manifest.code);
      setSelectedOpDirectionManifest(manifest.operation_direction);
      setIsVisibleModal(true);
    }
  }

  const manifestItems: { key: number; label: ReactNode }[] = useMemo(() => {
    return (
      uploadManifestsData?.map((manifest, index) => ({
        key: index,
        label: (
          <Button type="link" onClick={() => handleOpenModalLoading(manifest)}>
            Manifesto {manifest.code}
          </Button>
        ),
      })) || []
    );
  }, [uploadManifestsData]);

  function handleCancelModal() {
    setIsVisibleModal(false);
    setSelectedManifest('');
    setSelectedOpDirectionManifest('');
  }

  function handleCancelEditCargoNavigationType() {
    setIsModalEditCargoNavigationTypeVisible(false);
    setSelectedCargoManifest({} as CargoManifestType);
  }

  function onAfterSubmit(manifestCode: string) {
    handleCancelEditCargoNavigationType();
    setSelectedManifest(manifestCode);
    setIsVisibleModal(true);
  }

  return (
    <>
      <EditCargoNavigationTypeModal
        isVisible={isModalEditCargoNavigationTypeVisible}
        setIsVisible={setIsModalEditCargoNavigationTypeVisible}
        handleCancel={handleCancelEditCargoNavigationType}
        selectedCargoManifest={selectedCargoManifest}
        setUploadManifestsData={setUploadManifestsData}
        isContextPending
        onAfterSubmit={onAfterSubmit}
      />
      <AssociateLoadingModal
        isVisible={isVisibleModal}
        handleCancelModal={handleCancelModal}
        selectedManifest={selectedManifest}
        selectedOpDirectionManifest={selectedOpDirectionManifest}
        stopoverId={stopoverId}
      />
      <Collapse
        activeKey={activeKey}
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        <Panel forceRender header="Carga" key="loading">
          <LoadingDataContainer>
            <div className="dropdown-manifests">
              <Dropdown
                overlayClassName="overlay-style"
                overlay={<Menu items={manifestItems} />}
                disabled={manifestItems.length < 1 || isFetchingUploadManifests}
              >
                <div>
                  <span style={{ marginRight: '10px' }}>Atribuir cargas</span>
                  <DownOutlined />
                </div>
              </Dropdown>
              <Alert
                type="info"
                message="Para atribuir cargas, deve haver manifesto importado."
              />
            </div>
            <LoadingDataCollapse stopoverId={stopoverId} />
          </LoadingDataContainer>
        </Panel>
      </Collapse>
    </>
  );
}
