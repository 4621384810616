import { EyeOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';

import { Button, InnerCollapse, Title } from '../../../../components';
import { useGetStorageGroupbyProductQuery } from '../../../../services/operationApi';
import { useGetStopoverGroupbyOperatorQuery } from '../../../../services/stopoverApi';
import { StopoverGroupByOperatorType } from '../../../../types';
import { maskString } from '../../../../utils/utils';
import { OperationReportDrawer } from '../../operationsSection/operationReportDrawer/OperationReportDrawer';
import { groupOperationProductByOperator } from './formatters';
import { NcmsGroupbyManifestData } from './ncmsGroupbyManifestData/NcmsGroupbyManifestData';

type ManifestsGroupbyOperatorCollapseProps = {
  operationId: number;
};

const { Panel: InnerPanel } = InnerCollapse;

export function ManifestsGroupbyOperatorCollapse(
  props: ManifestsGroupbyOperatorCollapseProps
) {
  const { operationId } = props;
  const { data } = useGetStorageGroupbyProductQuery(operationId);
  const [isVisibleReportDrawer, setIsVisibleReportDrawer] = useState(false);

  const [detailFilter, setDetailFilter] = useState({});

  const dataGroupbyOperator = useMemo(() => {
    if (data !== undefined) return groupOperationProductByOperator(data);
  }, [data, operationId]);

  const { data: stopoverData, isFetching: isFetchingStopoverData } =
    useGetStopoverGroupbyOperatorQuery(detailFilter);

  function getHeader(operatorName: string, operatorCnpj: string) {
    return (
      <div style={{ display: 'flex', gap: '8px' }}>
        <span>{operatorName}</span>
        <span style={{ fontWeight: '400' }}>
          {maskString(operatorCnpj, '##.###.###/####-##')}
        </span>
      </div>
    );
  }

  function onCloseDrawer() {
    setIsVisibleReportDrawer(false);
  }

  function handleOpenOperationReportDrawer(operatorName: string) {
    setDetailFilter({
      detail_filter_operation_id: operationId,
      detail_filter_operator: operatorName,
    });
    setIsVisibleReportDrawer(true);
  }

  return (
    <InnerCollapse
      className="white-header"
      expandIconPosition="end"
      activeKey={dataGroupbyOperator?.map((item) => item.id)}
    >
      {dataGroupbyOperator?.map((groupbyOperator) => (
        <>
          <OperationReportDrawer
            isVisible={isVisibleReportDrawer}
            onClose={onCloseDrawer}
            stopover={stopoverData || ({} as StopoverGroupByOperatorType)}
            isLoading={isFetchingStopoverData}
          />
          <InnerPanel
            key={groupbyOperator.id}
            header={getHeader(groupbyOperator.name, groupbyOperator.cnpj)}
            extra={
              <Button
                style={{ marginRight: '45px' }}
                icon={<EyeOutlined />}
                onClick={() =>
                  handleOpenOperationReportDrawer(groupbyOperator.name)
                }
              >
                Ver relatório operacional
              </Button>
            }
          >
            <div style={{ margin: '10px 16px' }}>
              <Title>CARGAS</Title>
              <NcmsGroupbyManifestData data={groupbyOperator.items} />
            </div>
          </InnerPanel>
        </>
      ))}
    </InnerCollapse>
  );
}
