import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Navigate,
  Routes as RRDRoutes,
  Outlet,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { useAppSelector } from './app/hooks';
import { RootState } from './app/store';
// features
import { removeToken } from './app/store/authStore';
import { changeUser, logout } from './app/store/userStore';
import { AgentArea } from './features/agentArea/AgentArea';
import { AnchoringWindow } from './features/anchoringWindow/AnchoringWindow';
import { ChangeYourPassword } from './features/changeYourPassword';
import { ConfirmFinancialOfficers } from './features/confirmFinancialOfficers/ConfirmFinancialOfficers';
import { Dashboard } from './features/dashboard/Dashboard';
import ForgotMyPassword from './features/forgotMyPassword';
import { Invoicing } from './features/invoicing/Invoicing';
import { ManoeuvreBoard } from './features/manoeuvreBoard/ManoeuvreBoard';
import { OperatiorArea } from './features/operatorArea/OperatorArea';
import { Permissions } from './features/permissions/Permissions';
import { PumpWindow } from './features/pumpWindow/PumpWindow';
import { ReadingInvoices } from './features/readingInvoices/ReadingInvoices';
import { Registers } from './features/registers/Registers';
import { Reports } from './features/reports/Reports';
import SignIn from './features/signIn';
import { Stayment } from './features/stayment/Stayment';
import { Stopover } from './features/stopover/Stopover';
import { Storage } from './features/storage/Storage';
import { WarehousingVehicles } from './features/warehousingVehicles/WarehousingVehicles';
import { useGetAuthUserQuery } from './services/authApi';
import { VehiclesWarehousingOperatorsCompanies } from './utils/lists';
import {
  ADMIN_ID,
  AGENT_GROUP_ID,
  OPERATOR_GROUP_ID,
  STORAGE_TERMINAL_GROUP_ID,
} from './utils/utils';

function PrivateRoutes(props: { isTokenValid: boolean }) {
  return props.isTokenValid ? <Outlet /> : <Navigate to="/login" />;
}

export function Routes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = useSelector((state: RootState) => state.auth);

  const { data: responseDataUser, isError: isErrorGetUsers } =
    useGetAuthUserQuery(null, { skip: !token });
  const { groups, isSuperuser, company } = useAppSelector(
    (state: RootState) => state.user.user
  );

  const [isTokenValid, setIsTokenValid] = useState(false);

  const firstUrl = useMemo(() => {
    return location.pathname + location.search;
  }, []);

  useEffect(() => {
    if (responseDataUser) {
      setIsTokenValid(true);
      dispatch(changeUser(responseDataUser));
      const currentUrl = location.pathname + location.search;
      if (currentUrl === '/login') navigate(firstUrl);
    }
  }, [responseDataUser, token]);

  useEffect(() => {
    if (isErrorGetUsers) {
      setIsTokenValid(false);
      dispatch(removeToken());
      dispatch(logout());
    }
  }, [isErrorGetUsers, token]);

  const isSuperuserAdmin =
    isSuperuser || groups?.find((group) => group.id === ADMIN_ID);
  const isOperator =
    !isSuperuserAdmin &&
    groups?.find((group) => group.id === OPERATOR_GROUP_ID);
  const isVehiclesWarehousingOperator =
    !isSuperuserAdmin &&
    VehiclesWarehousingOperatorsCompanies.some((companyName) =>
      company?.name.toLocaleLowerCase().includes(companyName)
    );
  const isAgentArea =
    !isSuperuserAdmin && groups?.find((group) => group.id === AGENT_GROUP_ID);
  const isStorageTerminal =
    !isSuperuserAdmin &&
    groups?.find((group) => group.id === STORAGE_TERMINAL_GROUP_ID);

  const defaultRoute = isOperator
    ? '/operator-area'
    : isAgentArea
    ? '/agent-area'
    : isStorageTerminal
    ? '/warehousing/storage-terminal'
    : '/stopover-management';

  return (
    <RRDRoutes>
      <Route
        path="/login"
        element={
          isTokenValid ? <Navigate to={defaultRoute} replace /> : <SignIn />
        }
      />
      <Route
        path="/forgot-my-password"
        element={
          isTokenValid ? (
            <Navigate to={defaultRoute} replace />
          ) : (
            <ForgotMyPassword />
          )
        }
      />
      <Route
        path="/change-your-password/:token"
        element={
          isTokenValid ? (
            <Navigate to={defaultRoute} replace />
          ) : (
            <ChangeYourPassword />
          )
        }
      />
      <Route
        path="/confirm-financial-officers/:token/:table_id"
        element={
          isTokenValid ? (
            <Navigate to="/confirm-financial-officers" replace />
          ) : (
            <ConfirmFinancialOfficers />
          )
        }
      />
      <Route element={<PrivateRoutes isTokenValid={isTokenValid} />}>
        {isOperator && (
          <Route path="/operator-area" element={<OperatiorArea />} />
        )}
        {isVehiclesWarehousingOperator && (
          <Route
            path="/warehousing/vehicles/operator-area-warehousing"
            element={<WarehousingVehicles />}
          />
        )}
        {isAgentArea && <Route path="/agent-area" element={<AgentArea />} />}
        {isStorageTerminal && (
          <>
            <Route path="/warehousing/storage-terminal" element={<Storage />} />
            <Route path="/pump-window" element={<PumpWindow />} />
          </>
        )}
        {isSuperuserAdmin && (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stopover-management" element={<Stopover />} />
            <Route path="/stayment-management" element={<Stayment />} />
            <Route path="/anchoring-window" element={<AnchoringWindow />} />
            <Route path="/settings/permissions" element={<Permissions />} />
            <Route path="/settings/registers" element={<Registers />} />
            <Route path="/manoeuvre-board" element={<ManoeuvreBoard />} />
            <Route path="/operator-area" element={<OperatiorArea />} />
            {/* <Route path="/operations" element={<Operation />} /> */}
            <Route path="/reports" element={<Reports />} />
            <Route path="/agent-area" element={<AgentArea />} />
            <Route path="/invoicing" element={<Invoicing />} />
            <Route path="/pump-window" element={<PumpWindow />} />

            <Route
              path="/warehousing/vehicles"
              element={<WarehousingVehicles />}
            />
            <Route
              path="/warehousing/vehicles/operator-area-warehousing"
              element={<WarehousingVehicles />}
            />
            <Route
              path="/warehousing/reading-invoices"
              element={<ReadingInvoices />}
            />
            <Route path="/warehousing/storage-terminal" element={<Storage />} />
          </>
        )}
      </Route>
      <Route index element={<Navigate to="/login" replace />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </RRDRoutes>
  );
}
