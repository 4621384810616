import { Divider } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import {
  OperationContextType,
  OperationProductAndCraneType,
  OperationProductCalculusType,
  Stopover,
} from '../../../types';
import { formatOperationProductToDescription } from '../../operation/OperationsData/formatOperationToDescription';
import { ProductOperationDescription } from '../../operation/OperationsData/operationDescriptionsSections';
import { LiquidBulkProductivityDescription } from '../../operation/OperationsData/operationDescriptionsSections/liquidBulk/liquidBulkProductivityDescription';
import { LiquidBulkResumeDescription } from '../../operation/OperationsData/operationDescriptionsSections/liquidBulk/liquidBulkResumeDescription';

type LiquidBulkOperationProductsDescriptionProps = {
  operationProduct: OperationProductAndCraneType;
  operationType: OperationContextType;
  selectedStopover: Stopover;
  setSelectedStopover: Dispatch<SetStateAction<Stopover>>;
  operationProductCalculusData: OperationProductCalculusType;
  isProvisioned?: boolean;
};

export function LiquidBulkOperationProductDescription(
  props: LiquidBulkOperationProductsDescriptionProps
) {
  const {
    operationProduct,
    operationType,
    selectedStopover,
    setSelectedStopover,
    operationProductCalculusData,
    isProvisioned,
  } = props;
  const productDescription = formatOperationProductToDescription(
    operationProduct,
    operationType
  );

  return (
    <>
      <LiquidBulkResumeDescription
        operationProduct={productDescription}
        operationProductCalculusData={operationProductCalculusData}
        isProvisioned={isProvisioned}
      />
      {!isProvisioned && (
        <>
          <ProductOperationDescription
            operationProduct={productDescription}
            selectedOperationProduct={operationProduct}
            unity="m³"
            isLiquidBulk
            setSelectedStopover={setSelectedStopover}
            selectedStopover={selectedStopover}
          />
          <Divider />
          <LiquidBulkProductivityDescription
            connectionTimeStart={productDescription.connection_time_start}
            connectionTimeEnd={productDescription.connection_time_end}
            pumpTimeStart={productDescription.pump_time_start}
            pumpTimeEnd={productDescription.pump_time_end}
          />
        </>
      )}
    </>
  );
}
