import { GenericQueryParamsType } from '../types';
import { FareToSaveType, FareType } from '../typesFinancial';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export type ChargeGenericQueryParamsType = GenericQueryParamsType;

export const FareApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    createFare: build.mutation<FareType, FareToSaveType>({
      query: (fare) => ({
        url: '/financial/fare',
        method: 'POST',
        body: fare,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.charge_id },
        ]),
    }),
    updateFare: build.mutation<FareType, FareToSaveType>({
      query: (fare) => ({
        url: `/financial/fare/${fare.id}`,
        method: 'PUT',
        body: fare,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.charge_id },
        ]),
    }),
    deleteFare: build.mutation<void, number>({
      query: (id) => ({
        url: `/financial/fare/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [{ type: 'Charge', code: queryArgs }]),
    }),
    apportion: build.mutation<
      FareType,
      { chargeId: number; items: FareToSaveType[] }
    >({
      query: (fares) => ({
        url: `/financial/fare/apportions`,
        method: 'PUT',
        body: fares.items,
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.chargeId },
        ]),
    }),
    patchFare: build.mutation<
      FareType,
      {
        id: number;
        chargeId: number;
        action: 'generate_statement' | 'cancel_statement';
      }
    >({
      query: (params) => ({
        url: `/financial/fare/${params.id}/${params.action}`,
        method: 'PATCH',
      }),
      invalidatesTags: (_result, error, queryArgs) =>
        getInvalidatesTags(error, [
          { type: 'Charge', code: queryArgs.chargeId },
        ]),
    }),
  }),
  overrideExisting: true,
});

export const {
  useCreateFareMutation,
  useUpdateFareMutation,
  useDeleteFareMutation,
  useApportionMutation,
  usePatchFareMutation,
} = FareApi;
