import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';

import { Button, Title } from '../../components';
import {
  useDeleteImportingDocumentMutation,
  useLazyGetImportingDocumentQuery,
  useGetStopoverVehiclesImportGroupQuery,
  useUploadImportingDocumentationsMutation,
} from '../../services/warehousingVehiclesApi';
import { downloadFile } from '../../utils/utils';
import { DocumentCardContainer } from './styles';

type DocumentCardProps = {
  folderName: string;
  title: string;
  itemGroupId: number;
};
export function DocumentCard(props: DocumentCardProps) {
  const { title, folderName, itemGroupId } = props;
  const {
    data: documentGroup,
    isLoading: isLoadingDocumentGroup,
    refetch,
  } = useGetStopoverVehiclesImportGroupQuery(
    { id: itemGroupId },
    { refetchOnMountOrArgChange: true }
  );

  const [fileList, setFileList] = useState<Array<UploadFile<any>>>(
    buildFileList()
  );

  const [uploadFile] = useUploadImportingDocumentationsMutation();

  const [deleteImportingDocument] = useDeleteImportingDocumentMutation();

  const [getImportDocument] = useLazyGetImportingDocumentQuery();

  useEffect(() => {
    if (!isLoadingDocumentGroup) {
      setFileList(buildFileList());
    }
  }, [documentGroup]);

  function buildFileList() {
    return documentGroup?.documents
      .filter((docItem) => docItem.last_folder === folderName)
      .map((doc) => {
        return {
          uid: doc.id.toString(),
          status: 'done',
          name: doc.name,
          url: 'tossuape',
        };
      }) as Array<UploadFile<any>>;
  }

  function getParamsFromFile(file: UploadFile<any>) {
    const document = documentGroup?.documents.find(
      (doc) => doc.id.toString() === file.uid
    );
    return {
      path: document?.path || '',
      name: document?.name || '',
      file_obj_id: document?.id || 0,
    };
  }

  function onChange(info: UploadChangeParam<UploadFile<any>>) {
    if (info.file.status === 'uploading') {
      setFileList((prev) => [
        ...prev,
        {
          status: 'uploading',
          name: info.file.name,
          url: 'tossuape',
          uid: info.file.uid,
        },
      ]);
    }
  }

  async function onRemove(file: UploadFile<any>) {
    await deleteImportingDocument(getParamsFromFile(file)).then((response) => {
      if (!('error' in response)) {
        setFileList(fileList.filter((doc) => doc.uid !== file.uid));
      }
    });
  }

  function customUploadRequest(options: any) {
    const { file, onSuccess, onError } = options;

    uploadFile({
      file,
      id: itemGroupId,
      folderName,
    })
      .then(() => {
        refetch();
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }
  async function handleDownload(file: UploadFile<any>) {
    await getImportDocument(getParamsFromFile(file)).then((response) => {
      if ('data' in response) {
        downloadFile(response.data || '', file.name);
      }
    });
  }

  return (
    <DocumentCardContainer>
      <div className="document-card">
        <Title>{title}</Title>
        <div>
          <Upload
            maxCount={1}
            accept=".pdf, .txt"
            onRemove={onRemove}
            onChange={onChange}
            customRequest={customUploadRequest}
            fileList={fileList}
            defaultFileList={buildFileList()}
            onPreview={handleDownload}
          >
            <Button icon={<UploadOutlined />}>Importar</Button>
          </Upload>
        </div>
      </div>
    </DocumentCardContainer>
  );
}
