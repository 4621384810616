import {
  ArrowLeftOutlined,
  CheckOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Space, message } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { Alert, Button, Drawer, Table, Tag } from '../../components';
import {
  useGetVehicleDetailsQuery,
  useQueryDUEStatusQuery,
  useReceiptInvoicesMutation,
} from '../../services/warehousingVehiclesApi';
import { Stopover } from '../../types';
import {
  DuesGroupByStopover,
  VehicleInvoice,
  VehicleType,
} from '../../typesWarehousing';
import { chassisStatus } from '../../utils/lists';
import { exportChassisInvoicesTableColumns } from './exportChassisInvoicesTableColumns';
import { formatInvoicesToRegisterReception } from './formatInvoicesToRegisterReception';
import { ImportChassisDetailsDrawer } from './importChassisDetailsDrawer';
import { importChassisTableColumnsAdmin } from './importChassisTableColumnsAdmin';
import { OperatorExportVehiclesTableColumns } from './operatorExportVehiclesTableColumns';

type ChassisTableProps = {
  totalChassis?: number;
  stopover: Stopover;
  chassis?: VehicleInvoice[] | null;
  dueChassis?: VehicleType[] | null;
  importChassis?: VehicleType[] | null;
  isLoadingData?: boolean;
  onUpdate: () => void;
  due?: DuesGroupByStopover;
  type: 'EXPORT' | 'IMPORT';
  isWarehousingOperatorArea: boolean;
  vehicleDescription?: string | null;
  onChangePagination?: (newPage?: number | undefined) => number;
  pageSize?: number;
  queryPage?: number;
};
export function ChassisTable(props: ChassisTableProps) {
  const {
    totalChassis,
    stopover,
    chassis,
    dueChassis,
    importChassis,
    isLoadingData,
    onUpdate,
    due,
    type,
    isWarehousingOperatorArea,
    vehicleDescription,
    onChangePagination,
    pageSize,
    queryPage,
  } = props;

  const [allowSelection, setAlowSelection] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [IsVisibleDetailsChassisDrawer, setIsVisibleDetailsChassisDrawer] =
    useState(false);
  const [SelectedChassis, setSelectedChassis] = useState<VehicleType>(
    {} as VehicleType
  );

  const [
    receiptInvoices,
    {
      isLoading: isLoadingReceiptInvoices,
      isSuccess: isSuccessReceiptInvoices,
      isError: isErrorReceiptInvoices,
    },
  ] = useReceiptInvoicesMutation();

  const { data: vehicleDetailsData } = useGetVehicleDetailsQuery(
    SelectedChassis.chassis,
    { skip: SelectedChassis.chassis === undefined }
  );

  function onToggleSelection() {
    setAlowSelection(!allowSelection);
  }

  function onClickRegister() {
    if (stopover.id === -1) {
      receiptInvoices(
        selectedRows.flatMap((item) => {
          return { id: item.id };
        })
      );
    } else {
      receiptInvoices(formatInvoicesToRegisterReception(selectedRows)).then(
        () => {
          setAlowSelection(false);
          setSelectedRowKeys([]);
          setSelectedRows([]);
          onUpdate();
        }
      );
    }
  }

  useEffect(() => {
    if (isSuccessReceiptInvoices) {
      return message.success('Recepção informada');
    }
    if (isErrorReceiptInvoices) {
      return message.error('Erro ao informar recepção no PUCOMEX');
    }
  }, [isSuccessReceiptInvoices, isErrorReceiptInvoices]);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection: TableRowSelection<Record<string, any>> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function formatDataList(data?: any[] | null) {
    if (data) {
      return data.map((item, index) => {
        return { ...item, key: index };
      });
    }
    return [];
  }

  function showAlert() {
    const outdatedInvoices = dueChassis
      ?.flatMap((chassi) => chassi.invoices)
      .filter((invoice) => invoice?.portal_status === 'OUTDATED');
    if (outdatedInvoices && outdatedInvoices.length > 0) {
      return (
        <Alert
          type="warning"
          showIcon
          description={`Existe ${outdatedInvoices.length} ${
            outdatedInvoices.length > 1 ? 'notas' : 'nota'
          } com recepção desatualizada: Por favor, acessar o Portal Único para fazer o cancelamento da NF anterior e registrar nova Recepção de Carga.`}
        />
      );
    }
  }

  function onSelectChassisDetails(chassiSelected: VehicleType) {
    setSelectedChassis(chassiSelected);
    setIsVisibleDetailsChassisDrawer(true);
  }

  function chassiDetailsDrawerHeader() {
    return (
      <>
        <div style={{ marginBottom: '5px' }}>
          {SelectedChassis.chassis}
          <Tag style={{ marginLeft: '10px' }}>
            {
              chassisStatus.find(
                (chassiStatus) =>
                  chassiStatus.value === vehicleDetailsData?.status
              )?.label
            }
          </Tag>
        </div>
        <span
          style={{
            fontSize: '14px',
            display: 'block',
            color: 'var(--neutral_medium)',
            fontWeight: 400,
          }}
        >
          {vehicleDetailsData?.description}
        </span>
      </>
    );
  }

  const [DUEToQueryStatus, setDUEToQueryStatus] = useState<string | null>(null);

  const { data: DUE, isLoading: isLoadingQueryStatus } = useQueryDUEStatusQuery(
    DUEToQueryStatus,
    {
      skip: DUEToQueryStatus === null,
    }
  );

  function queryDUEStatus() {
    if (due && due.number) {
      setDUEToQueryStatus(due?.number);
    }
  }

  function changePagination(page: number) {
    if (stopover.id === -1) {
      if (onChangePagination) {
        onChangePagination(page);
      }
    }
  }

  useEffect(() => {
    if (DUE) {
      message.success('Due e notas no portal sincronizadas com sucesso.');
      onUpdate();
    }
  }, [DUE]);

  return (
    <div style={{ margin: '10px 0 0 0' }}>
      <Drawer
        visible={IsVisibleDetailsChassisDrawer}
        headerStyle={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
        onClose={() => setIsVisibleDetailsChassisDrawer(false)}
        width={590}
        title={chassiDetailsDrawerHeader()}
        closeIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ borderRadius: '25px' }}
          />
        }
      >
        <ImportChassisDetailsDrawer chassis={vehicleDetailsData} />
      </Drawer>
      {showAlert()}
      {type === 'EXPORT' && !isWarehousingOperatorArea && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '10px 0 10px 0',
          }}
        >
          <div>{totalChassis} chaves de notas</div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            {stopover.id !== -1 && (
              <Button
                onClick={() => queryDUEStatus()}
                icon={<ReloadOutlined />}
                loading={isLoadingQueryStatus}
              >
                Sincronizar status DU-E
              </Button>
            )}
            {allowSelection ? (
              <Space size={10}>
                <Button type="text" onClick={() => onToggleSelection()}>
                  Voltar
                </Button>
                <Button
                  onClick={() => onClickRegister()}
                  loading={isLoadingReceiptInvoices}
                >
                  Marcar
                </Button>
              </Space>
            ) : (
              <Button
                icon={<CheckOutlined />}
                onClick={() => onToggleSelection()}
              >
                Recepção no portal
              </Button>
            )}
          </div>
        </div>
      )}
      <Table
        size="small"
        columns={
          type === 'EXPORT'
            ? isWarehousingOperatorArea
              ? OperatorExportVehiclesTableColumns({
                  onSelect: onSelectChassisDetails,
                  vehicleDescription,
                })
              : exportChassisInvoicesTableColumns({
                  stopoverId: stopover.id,
                  onUpdate,
                  due,
                  isWarehousingOperatorArea,
                })
            : importChassisTableColumnsAdmin({
                onSelect: onSelectChassisDetails,
                isWarehousingOperatorArea,
              })
        }
        pagination={{
          total: totalChassis,
          pageSize: pageSize || 10,
          showSizeChanger: false,
          position: ['bottomCenter'],
          current: queryPage,
          onChange: (page) => changePagination(page),
        }}
        scroll={
          stopover.id === -1
            ? { y: '500px', scrollToFirstRowOnChange: false }
            : {}
        }
        dataSource={
          type === 'EXPORT'
            ? stopover.id === -1
              ? formatDataList(chassis)
              : formatDataList(dueChassis)
            : type === 'IMPORT'
            ? formatDataList(importChassis)
            : []
        }
        loading={isLoadingData}
        rowSelection={allowSelection ? rowSelection : undefined}
        rowKey="key"
      />
    </div>
  );
}
