import styled from 'styled-components';

export const Title = styled.span`
  color: var(--ocean_dark);
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 15px;
`;

export const Subtitle = styled.span`
  color: var(--neutral_medium);
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
`;
