import { DeleteOutlined } from '@ant-design/icons';
import { Col, Space, Select } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { debounce, isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

import {
  Button,
  FormItemSelect,
  TableFormList,
  FormItemInputNumber,
} from '../../../components';
import { FormListItemRowStyle } from '../../../components/common/tableFormList/styles';
import { useGetCargoTypeQuery } from '../../../services/cargoApi';
import {
  useGetCompaniesQuery,
  useGetCompanyQuery,
} from '../../../services/companyApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import { OperationalOperationType } from '../../../types';
import {
  isNullOrUndefined,
  removeSpecialCharacters,
} from '../../../utils/utils';

type ProvisionedCargoTableProps = {
  operation: OperationalOperationType;
  hasAmount: boolean;
  hasNcm: boolean;
};

export function ProvisionedCargoTable(props: ProvisionedCargoTableProps) {
  const { operation, hasAmount, hasNcm } = props;

  function CargoProvisionedRow(props: {
    index: number;
    onRemove?: (index: number | number[]) => void;
  }) {
    const { index, onRemove } = props;
    const { data: company, isFetching: isLoadingCurrentCompany } =
      useGetCompanyQuery(
        {
          tag:
            operation &&
            !isEmpty(operation) &&
            operation?.provisioned_cargos?.length > 0
              ? `${operation?.provisioned_cargos[index]?.supplier_company?.id}`
              : '',
        },
        {
          skip:
            operation &&
            !isEmpty(operation) &&
            operation?.provisioned_cargos?.length > 0
              ? !operation?.provisioned_cargos[index]?.supplier_company?.id
              : true,
        }
      );
    const [searchCompanyName, setSearchCompanyName] = useState('');

    const { data: filteredCompanies, isFetching: isLoadingCompanies } =
      useGetCompaniesQuery({
        page_size: 100,
        name_or_cnpj: searchCompanyName,
      });

    const { data: cargoTypeData, isFetching: isLoadingCargoTypeData } =
      useGetCargoTypeQuery();

    const { data: imdgCodeData, isFetching: isLoadingImdgCodeData } =
      useGetSystemParamByNameQuery({
        name: 'IMDG_CODE',
      });

    const alreadyExists = useMemo(() => {
      return (
        operation?.provisioned_cargos &&
        operation?.provisioned_cargos?.length > 0 &&
        !isNullOrUndefined(index) &&
        !isNullOrUndefined(operation?.provisioned_cargos[index]?.id)
      );
    }, [index]);

    function onSearch(evt: string) {
      const search = removeSpecialCharacters(evt, /[./-]/g);
      setSearchCompanyName(search || evt);
    }

    function companyOptionRenderer(option: Record<string, any>) {
      const optionName = `${option.cnpj ? option.cnpj.concat(' - ') : ''}${
        option.name
      }`;
      return (
        <Select.Option key={option.id} value={option.id}>
          {optionName}
        </Select.Option>
      );
    }
    function filterOption(input: string, option: BaseOptionType) {
      const cleanLabel =
        removeSpecialCharacters(
          option?.props.children.toLowerCase(),
          /[./-]/g
        ) || '';
      const cleanInput =
        removeSpecialCharacters(input.toLowerCase(), /[./-]/g) || '';
      return cleanLabel.indexOf(cleanInput) >= 0;
    }

    function imdgCodeRenderer(option: Record<string, any>) {
      const optionName = `${option.code} - ${option.name}`;
      return (
        <Select.Option key={option.id} value={option.id}>
          {optionName}
        </Select.Option>
      );
    }
    return (
      <FormListItemRowStyle gutter={8}>
        <FormItemSelect
          colSpan={hasAmount || hasNcm ? 5 : 7}
          name={[index, 'supply_type', 'name']}
          style={{ marginBottom: '0px' }}
          placeholder="Provisão"
          isLoading={isLoadingCargoTypeData}
          dataList={cargoTypeData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          required
          disabled={alreadyExists}
        />
        {hasNcm && (
          <FormItemInputNumber
            colSpan={3}
            placeholder="NCM"
            name={[index, 'ncm_code']}
            required
            maxLength={4}
            readOnly={alreadyExists}
          />
        )}
        <FormItemSelect
          colSpan={hasNcm && hasAmount ? 4 : hasAmount ? 5 : 7}
          name={[index, 'supplier_company', 'id']}
          style={{ marginBottom: '0px' }}
          placeholder="Empresa fornecedora"
          onSearch={debounce(onSearch, 300)}
          isLoading={isLoadingCurrentCompany || isLoadingCompanies}
          dataList={
            company
              ? [company].concat(filteredCompanies?.results || [])
              : filteredCompanies?.results
          }
          showSearch
          optionRenderer={companyOptionRenderer}
          filterOption={filterOption}
          optionFilterProp="label"
          required
          disabled={alreadyExists}
        />
        {hasAmount && (
          <FormItemInputNumber
            colSpan={hasNcm ? 3 : 5}
            placeholder="Quant."
            name={[index, 'amount']}
            addonAfter="un"
            readOnly={alreadyExists}
          />
        )}
        <FormItemSelect
          colSpan={4}
          name={[index, 'imdg_code', 'id']}
          placeholder="IMDG"
          isLoading={isLoadingImdgCodeData}
          dataList={imdgCodeData?.items}
          showSearch
          disabled={alreadyExists}
          optionRenderer={imdgCodeRenderer}
        />
        <FormItemInputNumber
          colSpan={4}
          placeholder="Peso total"
          name={[index, 'total_weight']}
          addonAfter="ton"
          required
          readOnly={alreadyExists}
        />
        <Col span={1}>
          <Space size={8}>
            {/* {showRowButtons && (
              <Button type="default" icon={<MailOutlined />} />
            )} */}
            <Button
              type="default"
              danger
              icon={<DeleteOutlined />}
              onClick={onRemove ? () => onRemove(index) : () => {}}
              disabled={alreadyExists}
            />
          </Space>
        </Col>
      </FormListItemRowStyle>
    );
  }
  const columns = useMemo(() => {
    const list = [
      {
        title: 'PROVISÃO',
        colSpan: hasAmount || hasNcm ? 5 : 7,
      },
    ];
    if (hasNcm) {
      list.push({ title: 'NCM', colSpan: 3 });
    }
    list.push({
      title: 'EMPRESA',
      colSpan: hasNcm && hasAmount ? 4 : hasAmount ? 5 : 7,
    });

    if (hasAmount) {
      list.push({ title: 'QUANT', colSpan: hasNcm ? 3 : 5 });
    }
    list.push(
      { title: 'CÓDIGO IMDG', colSpan: 4 },
      { title: 'PESO TOTAL', colSpan: 4 },
      { title: '', colSpan: 1 }
    );
    return list;
  }, [hasAmount, hasNcm]);

  return (
    <TableFormList
      FormListItemRow={CargoProvisionedRow}
      formListName={['provisioned_cargos']}
      columns={columns}
      itemToAdd={{ supply_type: {}, supplier_company: {} }}
      addButtonText="Adicionar provisão"
      addButtonType="primary"
      addButtonStyle={{ margin: '0px 0px 8px' }}
      style={{ marginTop: '0px' }}
    />
  );
}
