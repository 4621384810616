import styled from 'styled-components';

export const StatusTag = styled.div`
  display: inline;
  font-weight: normal;
  & span {
    display: inline-block;
    padding: 1px 8px 1px;
    line-height: 15px;
    border-radius: 3px;
    border: 1px solid;
    font-size: 12px;
    &.green {
      background: #f6ffed;
      color: #52c41a;
      border-color: #b7eb8f;
    }
    &.orange {
      background: #fff7e6;
      color: #fa8c16;
      border-color: #ffd591;
    }
    &.red {
      background: #fff1f0;
      color: #f5222d;
      border-color: #ffa39e;
    }
    &.blue {
      background: var(--info-lighter);
      color: var(--info-medium);
      border-color: var(--info-light);
    }
    &.green-sea {
      background: #d6fff7;
      color: var(--green-sea_dark);
      border-color: var(--green-sea_light);
    }
    &.operation {
      background: #fff0f6;
      color: #eb2f96;
      border-color: #ffadd2;
    }
    &.neutral-blue {
      background: var(--neutral_lightest);
      color: var(--neutral_medium);
      border-color: var(--neutral_lighter);
    }
    &.ocean-blue {
      background: var(--ocean_lighter);
      color: var(--ocean_dark);
      border-color: var(--ocean_light);
    }
  }
`;
