import { useState } from 'react';

import { Stopover as StopoverType } from '../../../types';
import { StaymentDescription } from './staymentDescription';
import { StaymentDrawer } from './staymentDrawer';

type FormItemsStaymentProps = {
  selectedStopover: StopoverType;
  setSelectedStopover: (value: any) => void;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
};

export function FormItemsStayment(props: FormItemsStaymentProps) {
  const { selectedStopover, setSelectedStopover, activeKey, onOpenPanel } =
    props;

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  function onEdit(evt: any, _indexToEdit: number) {
    evt.stopPropagation();
    setIsDrawerVisible(true);
  }

  return (
    <>
      <StaymentDescription
        selectedStopover={selectedStopover}
        onOpenPanel={onOpenPanel}
        activeKey={activeKey}
        onClickEdit={onEdit}
      />
      <StaymentDrawer
        visible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
      />
    </>
  );
}
