import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

type OperationTypeIconProps = {
  operation?: 'LOAD' | 'UNLOAD' | 'TRANSSHIPMENT_LOAD' | 'TRANSSHIPMENT_UNLOAD';
};
export function OperationTypeIcon(props: OperationTypeIconProps) {
  return (
    <>
      {props.operation === 'LOAD' && <ArrowUpOutlined />}
      {props.operation === 'UNLOAD' && <ArrowDownOutlined />}
      {(props.operation === 'TRANSSHIPMENT_LOAD' ||
        props.operation === 'TRANSSHIPMENT_UNLOAD') && (
        <>
          <ArrowUpOutlined />
          <ArrowDownOutlined />
        </>
      )}
    </>
  );
}
