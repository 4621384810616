import styled from 'styled-components';

export const ContainerManifest = styled.div`
  padding: 16px;

  .manifest-header {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .manifest-text {
      font-weight: 700;
      color: var(--neutral_medium);
    }

    .manifest-code {
      font-size: 16px;
      color: var(--neutral_medium);
      font-family: Consolas;
    }
  }
`;

export const ContainerOperatorCompany = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .consignee-name {
    font-weight: 700;
    color: var(--neutral_medium);
    font-size: 12px;
  }

  .consignee-cnpj {
    font-size: 12px;
    color: var(--neutral_medium);
    margin: 0 10px;
  }

  .consignee-items {
    background-color: var(--neutral_medium);
    color: #fff;
    border-radius: 20px;
    width: 50px;
    height: 18px;
    text-align: center;
    font-size: 12px;
  }
`;
