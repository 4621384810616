import { EquipmentType, TreeType } from '../../../types';

export function formatEquipmentsToForm(
  fenders: EquipmentType[] | [] | undefined,
  bollards: EquipmentType[] | [] | undefined
): TreeType[] {
  const treeData = [
    {
      title: 'Defensas',
      value: 'FENDER',
      key: 'FENDER',
      children: fenders?.map((fender) => {
        return {
          title: fender.name,
          value: fender.id,
          key: fender.id,
        };
      }),
    },
    {
      title: 'Cabeços',
      value: 'BOLLARD',
      key: 'BOLLARD',
      children: bollards?.map((bollard) => {
        return {
          title: bollard.name,
          value: bollard.id,
          key: bollard.id,
        };
      }),
    },
  ];

  return treeData;
}
