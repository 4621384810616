import { OperationCraneType } from '../types';
import { getHeaders, tosApi } from './tosApi';

export const operationCranesApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getOperationCranes: build.query<
      OperationCraneType[],
      { operationId: number }
    >({
      query(params) {
        const { operationId } = params;
        return {
          url: `/operational/operation-cranes`,
          method: 'GET',
          headers: getHeaders,
          params: { operation_id: operationId },
        };
      },
      providesTags: () => [{ type: 'OperationCrane' }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetOperationCranesQuery } = operationCranesApi;
