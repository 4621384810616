import { Collapse } from '../../components';
import { StopoverVehiclesImportType } from '../../typesWarehousing';
import { BLCards } from '../../utils/lists';
import { DocumentCard } from './DocumentCard';
import { DocumentCardWrapper } from './styles';

const { Panel } = Collapse;

type CEDocsDrawerProps = {
  BLList?: StopoverVehiclesImportType[];
};
export function BLDocsDrawer(props: CEDocsDrawerProps) {
  const { BLList } = props;
  return (
    <div>
      <Collapse expandIconPosition="right">
        {BLList?.map((BLItem, index) => {
          return (
            <Panel header={`BL ${BLItem.bl_number}`} key={index}>
              <DocumentCardWrapper>
                {BLCards.map((doc, key) => {
                  return (
                    <DocumentCard
                      folderName={doc.folderName}
                      title={doc.title}
                      itemGroupId={BLItem.id}
                      key={key}
                    />
                  );
                })}
              </DocumentCardWrapper>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
