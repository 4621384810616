import { Divider } from 'antd';

import { Descriptions, FormatItem } from '../../../../../../components';
import { ShiftTypeDescription } from '../../../../../../types';
import { ShiftContainer, DescriptionSection } from '../../styles';

const { Item } = Descriptions;

type ShiftDescriptionProps = {
  workShifts: ShiftTypeDescription[];
};

export function ShiftsDescription(props: ShiftDescriptionProps) {
  const { workShifts } = props;
  return (
    <ShiftContainer>
      {workShifts?.map((workShift, index) => (
        <DescriptionSection key={index}>
          <Descriptions title={`Turno ${index + 1}`}>
            <Item label="Início">
              <FormatItem>{workShift.shift_start}</FormatItem>
            </Item>
            <Item label="Fim">
              <FormatItem>{workShift.shift_end}</FormatItem>
            </Item>
            <Item label="Paralisações">
              {/* <FormatItem>{workShift.stoppages.length}</FormatItem> */}
            </Item>
            <Item label="Quantidade de Ternos">
              <FormatItem>{workShift.gang_amount}</FormatItem>
            </Item>
            <Item label="Produtividade hora">
              <FormatItem>{workShift.hour_productivity}</FormatItem>
            </Item>
            <Item label="Inoperante por">
              <FormatItem>{workShift.inoperative_time}</FormatItem>
            </Item>
            <Item label="Qtd. carga operada">
              <FormatItem>{workShift.operated_cargo_amount}</FormatItem>
            </Item>
          </Descriptions>
          {index + 1 < workShifts.length && <Divider dashed />}
        </DescriptionSection>
      ))}
    </ShiftContainer>
  );
}
