import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { Button } from '../../components';

export function columns(
  _onSelectItem: any,
  onDeleteItem: (invoiceId: number) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title: 'Nº',
      width: '3%',
      render: (value, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'Horário de leitura',
      dataIndex: 'date_of_access_key_read',
      render: (value) => {
        return moment(value).format('DD/MM/yyyy HH:mm');
      },
      width: '15%',
    },
    {
      title: 'Chave de nota',
      dataIndex: 'access_key',
    },
    {
      width: '10%',
      render: (value, record, index) => (
        <Button
          style={{ float: 'right' }}
          icon={<DeleteOutlined />}
          danger
          onClick={() => onDeleteItem(record.id)}
        />
      ),
    },
  ];
}
