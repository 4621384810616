import { Col, Row } from 'antd';
import { ReactNode } from 'react';

import {
  DockingReleaseStatus,
  DockingLogStatus,
  StopoverHeader,
  GenericStopoverHeader,
} from '../../components';
import { Stopover as StopoverType } from '../../types';

type StaymentFormHeaderProps = {
  selectedStopover: StopoverType;
  buttons?: () => ReactNode;
};

export function StaymentFormHeader(props: StaymentFormHeaderProps) {
  const { selectedStopover, buttons } = props;
  return (
    <StopoverHeader>
      <Row align="middle">
        <Col span={24}>
          <div style={{ height: '100%' }}>
            <GenericStopoverHeader
              selectedStopover={selectedStopover}
              style={{ width: 'auto', position: 'absolute' }}
              tagName={
                <>
                  <DockingLogStatus
                    dockingLogStatus={selectedStopover?.current_docking_status}
                  />
                  <DockingReleaseStatus
                    dockingRelease={selectedStopover?.docking_release}
                  />
                </>
              }
            />
            {buttons && <Row align="bottom">{buttons()}</Row>}
          </div>
        </Col>
      </Row>
    </StopoverHeader>
  );
}
