import {
  OperationProductGroupbyOperatorType,
  OperatorGroupbyManifestType,
  StorageGroupbyProductType,
} from '../../../../types';

export const groupOperationProductByOperator = (
  input: StorageGroupbyProductType[]
) => {
  const groupsList: OperationProductGroupbyOperatorType[] = [];
  input.forEach((operationProduct) => {
    const groupKey = operationProduct.operator?.id || 0;
    if (!groupsList.some((item) => item.id === groupKey)) {
      const newGroup = {
        id: groupKey,
        name: operationProduct.operator?.name || 'Operador não informado',
        cnpj: operationProduct.operator?.cnpj,
        items: [],
      };
      groupsList.push(newGroup);
    }
    groupsList.forEach((item) => {
      if (item.id === groupKey) {
        item.items.push(operationProduct);
      }
    });
  }, []);
  return groupsList;
};

export const groupOperatorByManifest = (input: StorageGroupbyProductType[]) => {
  const groupsList: OperatorGroupbyManifestType[] = [];
  input.forEach((operator) => {
    const groupKey = Number(operator.manifest_code) || 0;
    if (!groupsList.some((item) => item.id === groupKey)) {
      const newGroup = {
        id: groupKey,
        name: operator.operation_direction || '',
        items: [],
      };
      groupsList.push(newGroup);
    }
    groupsList.forEach((item) => {
      if (item.id === groupKey) {
        item.items.push({
          id: operator.id,
          operator_company: operator.operator_company,
          merchant_ce_code: operator.merchant_ce_code,
          cargo: operator.cargo,
          ncm_code: operator.ncm_code,
          ncm_description: operator.ncm_description,
          storage_company: operator.storage_company,
          volume: operator.volume,
          weight: operator.weight,
          operation_direction: operator.operation_direction,
        });
      }
    });
  }, []);
  return groupsList;
};
