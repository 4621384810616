import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

function DropSvg() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.5V6.875C5.24162 6.85409 5.46812 6.7486 5.63961 6.57711C5.8111 6.40562 5.91659 6.17912 5.9375 5.9375H6.5625C6.54231 6.34538 6.37121 6.73118 6.08245 7.01995C5.79368 7.30871 5.40788 7.47981 5 7.5Z"
        fill="white"
      />
      <path
        d="M5 8.75003C4.25437 8.74909 3.53955 8.45247 3.0123 7.92523C2.48506 7.39799 2.18844 6.68316 2.1875 5.93753C2.20431 5.38928 2.36536 4.85514 2.65441 4.38897L4.73525 1.074C4.7653 1.03179 4.80501 0.997378 4.85107 0.973634C4.89712 0.949889 4.94818 0.9375 5 0.9375C5.05182 0.9375 5.10288 0.949889 5.14893 0.973634C5.19499 0.997378 5.2347 1.03179 5.26475 1.074L7.33628 4.37287C7.63024 4.8433 7.79454 5.38311 7.8125 5.93753C7.81156 6.68316 7.51494 7.39799 6.9877 7.92523C6.46046 8.45247 5.74563 8.74909 5 8.75003ZM5 1.82762L3.19322 4.70509C2.96038 5.07539 2.82908 5.50043 2.8125 5.93753C2.8125 6.51769 3.04297 7.07409 3.4532 7.48433C3.86344 7.89456 4.41984 8.12503 5 8.12503C5.58016 8.12503 6.13656 7.89456 6.5468 7.48433C6.95703 7.07409 7.1875 6.51769 7.1875 5.93753C7.1697 5.49428 7.03516 5.06358 6.7975 4.689L5 1.82762Z"
        fill="white"
      />
    </svg>
  );
}

export function DropIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={DropSvg} {...props} />;
}
