import { message } from 'antd';
import Tooltip from 'antd/es/tooltip';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  FormHeader,
  ModalBlockNavigation,
  Button,
  Modal,
  Form,
  FormItemDatePicker,
  OperationResumeDescription,
} from '../../components';
import { OperationCancelationData } from '../../components/common/OperationCancelationData';
import { useCollapsePanels } from '../../hooks';
import { usePatchDockingMutation } from '../../services/dockingApi';
import { usePatchOperationMutation } from '../../services/operationApi';
import { useGetOperationCalculusQuery } from '../../services/operationCalculusApi';
import {
  NatureOfCargoType,
  OperationalOperationType,
  OperationCalculusType,
  OperationProductAndCraneType,
  Stopover as StopoverType,
} from '../../types';
import { createDateString, createDateStringPtBr } from '../../utils/utils';
import {
  formatOperationProductTotalMovimentedByShift,
  formatOperationToDescription,
  getTypeOfOperation,
} from '../operation/OperationsData/formatOperationToDescription';
import { ConfirmNewOperationAlert } from './confirmNewOperationAlert';
import { ContainersImportModal } from './containersImportModal';
import { OperationCranesData } from './operationCranesData/OperationCranesData';
import { OperationPeriods } from './operationPeriods';
import { OperationProductsData } from './operationProductsData/OperationProductsData';
import { OperationResume } from './operationResume';
import { OperationStatusTag } from './operationStatusTag';
import { StoppagesDrawer } from './stoppagesDrawer/StoppagesDrawer';
import { FormHeaderOperatorArea } from './styles';

type OperatorAreaDetailsProps = {
  selectedStopover: StopoverType;
  hasEdited: boolean;
  blockNavigate: boolean;
  name: string;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  setHasEdited: (hasEdited: boolean) => void;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  onCloseDrawer?: () => void;
  hideCollapseAll?: boolean;
  setIsVisibleEditOperationDrawer: (value: boolean) => void;
};

export function OperatorAreaDetails(props: OperatorAreaDetailsProps) {
  const {
    selectedStopover,
    hasEdited,
    blockNavigate,
    name,
    setBlockNavigate,
    setHasEdited,
    setSelectedStopover,
    onCloseDrawer,
    hideCollapseAll,
    setIsVisibleEditOperationDrawer,
  } = props;
  const collapsePanels = ['products', 'containers'];

  const operationType = useMemo(() => {
    return getTypeOfOperation(
      selectedStopover.operation || ({} as OperationalOperationType)
    );
  }, [selectedStopover.operation]);

  const [selectedProducts, setSelectedProducts] = useState(
    operationType !== 'CONTAINER'
      ? selectedStopover.operation?.items
      : selectedStopover.operation?.operation_cranes
  );

  const [isVisibleContainersImportModal, setIsVisibleContainersImportModal] =
    useState(false);

  const [updateOperation, { isLoading: isLoadingUpdateOperation }] =
    usePatchOperationMutation();

  useEffect(() => {
    setSelectedProducts(
      operationType !== 'CONTAINER'
        ? selectedStopover.operation?.items
        : selectedStopover.operation?.operation_cranes
    );
  }, [selectedStopover]);

  const { data: operationCalculusData, isLoading: isLoadingOperationCalculus } =
    useGetOperationCalculusQuery(selectedStopover?.operation?.id || 0, {
      skip: selectedStopover?.operation?.id === undefined,
    });

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const [isVisibleStoppagesDrawer, setIsVisibleStoppagesDrawer] =
    useState(false);
  const [isVisibleStartOperationModal, setIsVisibleStartOperationModal] =
    useState(false);

  const [status, setStatus] = useState(
    selectedStopover.operation.operation_status || ''
  );
  useEffect(() => {
    setStatus(selectedStopover.operation.operation_status || '');
  }, [isVisibleStartOperationModal]);

  function getHeaderTitle() {
    return (
      <FormHeaderOperatorArea>
        <div className="top-title">
          <span>
            {selectedStopover.code} {'>'} Atracação{' '}
            {selectedStopover.docking_code}
          </span>
        </div>
        <div className="title">
          {operationType === 'CONTAINER' ? (
            <div>
              <span style={{ fontWeight: '700' }}>Contêineres </span>
              <span style={{ fontWeight: '500' }}>
                ({selectedStopover.total_operation_containers} unidade(s))
              </span>
            </div>
          ) : (
            <span>
              {selectedStopover.operation?.total_products_handled_description}
            </span>
          )}

          <OperationStatusTag
            operationStatus={selectedStopover.operation.operation_status}
          />
        </div>
        <span className="subtitle">
          Atualizado em{' '}
          {createDateStringPtBr(selectedStopover.operation?.updated_at)}
        </span>
      </FormHeaderOperatorArea>
    );
  }
  function showStoppagesButton() {
    const { operation } = selectedStopover;
    return (
      operation?.real_operation_start &&
      ((operationType === 'LIQUID_BULK' &&
        operation?.operation_lines?.length) ||
        (operationType !== 'LIQUID_BULK' && operation?.work_periods?.length) ||
        (operationType === 'CONTAINER' && operation?.operation_cranes?.length))
    );
  }

  function getButtons() {
    return (
      <>
        {operationType === 'CONTAINER' && (
          <Button
            type="primary"
            onClick={() => setIsVisibleContainersImportModal(true)}
          >
            Importar TXT
          </Button>
        )}
        {showStoppagesButton() ? (
          <Button
            type="ghost"
            onClick={() => setIsVisibleStoppagesDrawer(true)}
          >
            Paralisações
          </Button>
        ) : null}
      </>
    );
  }

  function titleButtons() {
    return status === 'WAITING_OPERATION' ? (
      <Tooltip
        title={
          !selectedStopover.operation_release ||
          selectedStopover.operation_release === 'PENDING'
            ? 'Antes de iniciar a operação é necessário que SUAPE tenha liberado a operação para essa escala'
            : null
        }
      >
        <Button
          type="primary"
          size="large"
          style={{ width: 'auto' }}
          onClick={() => setIsVisibleStartOperationModal(true)}
          disabled={
            !selectedStopover.operation_release ||
            selectedStopover.operation_release === 'PENDING'
          }
        >
          Iniciar operação
        </Button>
      </Tooltip>
    ) : status === 'IN_OPERATION' ? (
      <Button
        type="primary"
        size="large"
        style={{ width: 'auto' }}
        onClick={() => setIsVisibleStartOperationModal(true)}
        disabled={
          !selectedStopover.operation_release ||
          selectedStopover.operation_release === 'PENDING'
        }
      >
        Finalizar operação
      </Button>
    ) : null;
  }

  const [form] = Form.useForm();
  const [patchDockingMutation, { isLoading: isLoadingUpdateDocking }] =
    usePatchDockingMutation();

  function onEditOperation() {
    form.validateFields().then((values) => {
      const operationToSave: any = {
        docking_id: selectedStopover.docking_id,
        id: selectedStopover.operation.id,
      };
      const dockingToSave: any = {
        id: selectedStopover.docking_id,
      };
      if (values.real_operation_start) {
        operationToSave.real_operation_start = createDateString(
          values.real_operation_start
        );
        dockingToSave.operation_summary = {};
        dockingToSave.operation_summary.real_start = createDateString(
          values.real_operation_start
        );
      }
      if (values.real_operation_finish) {
        operationToSave.real_operation_finish = createDateString(
          values.real_operation_finish
        );
      }
      updateOperation(operationToSave).then(() => {
        patchDockingMutation(dockingToSave).then(() => {
          message.success('Operação atualizada');
          setIsVisibleStartOperationModal(false);
        });
      });
    });
  }

  const isProvisioned = useMemo(() => {
    return selectedStopover.operation.items?.some((oProduct) =>
      oProduct.operation_manifests?.some((item) => item.manifest_code === '01')
    );
  }, [selectedStopover]);

  return (
    <>
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
      />
      <Modal
        visible={isVisibleStartOperationModal}
        className="TOSPrimaryModal"
        width={520}
        title={
          !status || status === 'WAITING_OPERATION'
            ? 'Iniciar operação'
            : status === 'IN_OPERATION'
            ? 'Finalizar operação'
            : ''
        }
        onCancel={() => setIsVisibleStartOperationModal(false)}
        okText={
          !status || status === 'WAITING_OPERATION'
            ? 'Iniciar'
            : status === 'IN_OPERATION'
            ? 'Finalizar'
            : ''
        }
        cancelText="Cancelar"
        okButtonProps={{
          form: 'edit_operation',
          htmlType: 'submit',
          loading: isLoadingUpdateOperation || isLoadingUpdateDocking,
        }}
      >
        <Form
          name="edit_operation"
          layout="vertical"
          form={form}
          onFinish={onEditOperation}
        >
          {status === 'IN_OPERATION' ? (
            <FormItemDatePicker
              label="Fim da operação"
              name="real_operation_finish"
            />
          ) : (
            <FormItemDatePicker
              label="Início da operação"
              name="real_operation_start"
            />
          )}
        </Form>
      </Modal>
      <ContainersImportModal
        isVisible={isVisibleContainersImportModal}
        setIsVisible={setIsVisibleContainersImportModal}
        operationId={selectedStopover.operation.id || 0}
      />
      <FormHeader
        selectedStopover={selectedStopover}
        title={getHeaderTitle()}
        openCollapsePanels={openCollapsePanels}
        buttons={getButtons}
        onChangeSwitch={(checked: boolean) => onChangeSwitch(checked)}
        name={name}
        headerTitle="operação"
        hideCollapseAll={hideCollapseAll}
        onCloseDrawer={onCloseDrawer}
        hideEdit
        titleButtons={titleButtons}
      />
      {selectedStopover.operation.items?.map((operationProduct) => {
        if (
          operationProduct.operation_release === 'PENDING' &&
          operationProduct.id &&
          selectedStopover.operation.operation_status !== 'CANCELED'
        ) {
          return (
            <ConfirmNewOperationAlert
              key={operationProduct.id}
              operatorName={operationProduct.operator?.name || ''}
              operationProductId={operationProduct.id}
              operationCargoTypeName={`${
                operationProduct.cargo_type?.name || ''
              }(${operationProduct.total_weight_handled} ton)`}
              isProvisioned={isProvisioned}
              operationId={selectedStopover.operation.id || 0}
              opProductTotalWeight={operationProduct.total_weight_handled || 0}
              opNatureOfCargo={
                selectedStopover.operation.nature_of_cargo ||
                ({} as NatureOfCargoType)
              }
            />
          );
        }
      })}
      {/* aguardando definição de logica para exibir alerta */}
      {/* <StoppageTotalAlert
        stoppages={selectedStopover.operation.stoppages || []}
      /> */}
      {selectedStopover && selectedStopover.operation.archived_at && (
        <OperationCancelationData selectedStopover={selectedStopover} />
      )}
      {operationType !== 'CONTAINER' && !isProvisioned && (
        <OperationResume
          operationCalculusData={
            operationCalculusData || ({} as OperationCalculusType)
          }
          isLoadingOperationCalculus={isLoadingOperationCalculus}
          setIsVisibleStoppagesDrawer={setIsVisibleStoppagesDrawer}
          stoppages={selectedStopover.operation?.stoppages || []}
          operationType={operationType}
          operationStart={selectedStopover.operation.real_operation_start}
          operationFinish={selectedStopover.operation.real_operation_finish}
        />
      )}

      <OperationResumeDescription
        selectedOperation={formatOperationToDescription(
          selectedStopover.operation
        )}
        shippingAgencyName={selectedStopover.shipping_agency?.name}
        vesselFlag={selectedStopover.vessel?.flag || ''}
        vesselName={selectedStopover.vessel?.name || ''}
        dockingCode={selectedStopover.docking_code}
        dockingPlace={selectedStopover.docking_docking_place_name}
        showRealTimeOperation
      />
      {operationType === 'CONTAINER' ? (
        <OperationCranesData
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
          activeKey={openCollapsePanels}
          onOpenPanel={onOpenPanel}
        />
      ) : (
        <>
          {!isProvisioned && (
            <OperationPeriods
              selectedStopover={selectedStopover}
              operation={selectedStopover.operation}
              movimentationProductsByPeriod={formatOperationProductTotalMovimentedByShift(
                selectedStopover.operation
              )}
              selectedDockingPlaceTag={
                selectedStopover.docking_docking_place_tag || ''
              }
              operationType={operationType}
            />
          )}
          <OperationProductsData
            selectedOperationProducts={
              (selectedProducts as OperationProductAndCraneType[]) || []
            }
            operation={
              selectedStopover.operation || ({} as OperationalOperationType)
            }
            operationType={operationType}
            selectedStopover={selectedStopover}
            setSelectedStopover={setSelectedStopover}
            activeKey={openCollapsePanels}
            onOpenPanel={onOpenPanel}
            operationCalculusData={
              operationCalculusData || ({} as OperationCalculusType)
            }
            isProvisioned={isProvisioned}
          />
        </>
      )}
      <StoppagesDrawer
        visible={isVisibleStoppagesDrawer}
        onBack={() => setIsVisibleStoppagesDrawer(false)}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
      />
    </>
  );
}
