import moment from 'moment';

export function dayRenderer(dayProps: any) {
  return (
    <div {...dayProps.getIntervalProps()} className="rct-dateHeader day">
      <div style={{ display: 'block' }} className="day-number">
        {moment(dayProps.intervalContext.interval.startTime).format('DD')}
      </div>
      <div className="day-name">
        {moment(dayProps.intervalContext.interval.startTime).format('dddd')}
      </div>
    </div>
  );
}
