import { Input as ANTDInput } from 'antd';
import styled from 'styled-components';

export const TOSInput = styled(ANTDInput)`
  border: 1px solid #ccdde9;
  border-radius: 2px;
  & input::placeholder {
    color: #a0bace;
    opacity: 1;
  }
`;
