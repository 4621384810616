import { FileOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

type PageContentPlaceholderProps = {
  message?: ReactNode;
};

export function PageContentPlaceholder(props: PageContentPlaceholderProps) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        color: 'var(--neutral_light)',
        backgroundColor: 'var(--neutral_lightest)',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <FileOutlined style={{ fontSize: '30px' }} />
        <br />
        <br />
        <span style={{ fontSize: '16px' }}>
          {props.message || (
            <>
              Selecione uma
              <br /> embarcação
            </>
          )}
        </span>
      </div>
    </div>
  );
}
