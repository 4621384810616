import { EyeFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { ApportionmentIcon } from '../../../../assets/icons/apportionmentIcon';
import { Button } from '../../../../components';
import { BaseTableItemType } from '../../../../typesFinancial';
import { formatNumberToLocale } from '../../../../utils/utils';

export function fareItemsTableColumns(
  onEdit?: (item: BaseTableItemType, tableIndex?: number) => void,
  tableIndex?: 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9
): ColumnsType<Record<string, any>> {
  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'Item',
      key: 'name',
      dataIndex: 'name',
      align: 'left' as const,
      width: '50%',
      render(value, record) {
        let companies = '';
        if (record.childs && record.childs.length > 0) {
          companies = record.childs
            ?.map(
              (child: { fare_company_name: string }) => child.fare_company_name
            )
            ?.join(', ');
        }
        return (
          <div style={{ display: 'flex', gap: '9px', alignItems: 'center' }}>
            <span>
              {record.number} {value}
            </span>
            {companies && (
              <Tooltip title={`Rateado com o responsável ${companies}`}>
                <div style={{ display: 'flex' }}>
                  <ApportionmentIcon />
                </div>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Taxa',
      key: 'fee',
      dataIndex: 'fee',
      align: 'right' as const,
      width: '20%',
      render(value) {
        return (
          <span style={{ fontFamily: '"Consolas"' }}>
            {formatNumberToLocale(value, true)}
          </span>
        );
      },
    },
    {
      title: 'Valor',
      key: 'total_value',
      dataIndex: 'total_value',
      align: 'right' as const,
      width: onEdit ? '30%' : '20%',
      render(value, record) {
        const isDescount = record.name === 'Descontos';
        return (
          <span
            style={{
              fontFamily: 'Consolas',
              color: isDescount ? 'var(--error-dark)' : 'black',
            }}
          >
            {isDescount && '- '}
            {formatNumberToLocale(isDescount ? record.value : value, true)}
          </span>
        );
      },
    },
  ];

  if (onEdit) {
    columns.push({
      title: '',
      key: 'view',
      dataIndex: 'view',
      align: 'right' as const,
      width: '10%',
      render: (_value, record) => {
        return (
          <Button
            icon={<EyeFilled />}
            type="ghost"
            onClick={() => onEdit(record, tableIndex)}
          />
        );
      },
    });
  }

  return columns;
}
