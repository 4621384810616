import {
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { Col, Input, Row, Switch } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { changeUser } from '../../app/store/userStore';
import { Button } from '../../components/antd/Button';
import { Modal } from '../../components/antd/Modal';
import { useChangePagination } from '../../hooks';
import {
  useDisableUsersMutation,
  useGetUsersQuery,
} from '../../services/usersApi';
import { User } from '../../types';
import { RegistersTable } from '../registers/registersTable';
import { columns } from './columns';
import { formatDisableUser } from './formatUser';
import { formatUserFormObj, formatUserTableObj } from './formatUserFormObj';
import { UserDrawer } from './userDrawer';

const { confirm } = Modal;

export function Users() {
  // const usersPerPage = 15;
  const { onChangePagination, queryPage } = useChangePagination();
  const [searchUserName, setSearchUserName] = useState('');

  const [usersFilters, setUsersFilters] = useState({
    page: queryPage,
    // page_size: usersPerPage,
    first_name_or_last_name: searchUserName,
  });

  const { data: usersData, isLoading: isLoadingUsersData } =
    useGetUsersQuery(usersFilters);

  useEffect(() => {
    setUsersFilters((prev) => ({ ...prev, page: queryPage }));
  }, [queryPage]);

  const dispatch = useAppDispatch();
  const { user: loggedUser } = useAppSelector((state: RootState) => state.user);
  const [disableUsers] = useDisableUsersMutation();

  const [allUsers, setAllUsers] = useState<Array<User> | undefined>([]);

  const [usersToRender, setUsersToRender] = useState<Array<User> | undefined>(
    undefined
  );

  const [allowSelect, setAllowSelect] = useState(false);
  const [rowSelection, setRowSelection] = useState<
    TableRowSelection<Record<string, unknown>> | undefined
  >(undefined);
  const [selectedUsers, setSelectedUsers] = useState<Array<User> | null>(null);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const [showDisabledUsers, setShowDisabledUsers] = useState(false);

  const [isVisibleUserDrawer, setIsVisbileUserDrawer] = useState(false);

  function onSearchUser(search: string) {
    setSearchUserName(search);
    setUsersFilters((prev) => ({ ...prev, first_name_or_last_name: search }));
  }

  useEffect(() => {
    if (usersData?.results) {
      setAllUsers(usersData.results);
    }
  }, [usersData?.results]);

  useEffect(() => {
    if (allUsers) {
      setUsersToRender(filterDisabled(allUsers));
    }
  }, [allUsers, showDisabledUsers]);

  function filterDisabled(usersToFilter: Array<User>) {
    if (!showDisabledUsers) {
      return usersToFilter.filter((user) => user.status !== 'DEACTIVATED');
    }
    return usersToFilter;
  }

  function showDeleteConfirm() {
    confirm({
      title: 'Desabilitar em lote',
      icon: <ExclamationCircleOutlined />,
      content: `Tem certeza que deseja desabilitar ${selectedUsers?.length} usuário(s)?`,
      okText: 'Sim, desabilitar',
      okType: 'danger',
      cancelText: 'Voltar',
      onOk() {
        confirmDisableUsers();
      },
      onCancel() {
        setAllowSelect(false);
        setSelectedUsers(null);
      },
    });
  }

  async function confirmDisableUsers() {
    if (selectedUsers) {
      const disabledUsers = await disableUsers(
        formatDisableUser(selectedUsers)
      );
      setAllowSelect(false);
      setSelectedUsers(null);
      setRowSelection(undefined);
      if ('data' in disabledUsers) {
        onSaveUsers(disabledUsers.data);
      }
    }
  }

  function onChangeSwitch(checked: boolean) {
    setShowDisabledUsers(checked);
  }

  function onSelectUser(user: User) {
    setIsVisbileUserDrawer(true);
    setSelectedUser(formatUserFormObj(user));
  }

  function onCloseDrawer() {
    setIsVisbileUserDrawer(false);
    setSelectedUser(null);
  }

  function onSaveUser(user: any) {
    const hasUser = allUsers?.find((item) => item.id === user.id);
    if (hasUser) {
      setAllUsers(
        allUsers?.map((item) =>
          item.id === user.id ? { ...item, ...formatUserTableObj(user) } : item
        )
      );
      if (user.id === loggedUser.id) dispatch(changeUser(user));
    } else {
      setAllUsers(allUsers?.concat([formatUserTableObj(user)]));
    }
  }

  function onSaveUsers(users: Array<User>) {
    if (allUsers) {
      const updatedUsers = allUsers.map((aItem) => {
        const bItem = users.find(
          (currentBItem) => currentBItem.id === aItem.id
        );
        if (bItem) {
          return { ...aItem, ...formatUserTableObj(bItem) };
        }
        return { ...aItem };
      });
      setAllUsers(updatedUsers);
    }
  }

  function onClickSelectUsers(shouldSelect?: boolean) {
    setAllowSelect(!shouldSelect);
    if (!shouldSelect === false) {
      setSelectedUsers(null);
    }
    if (!shouldSelect === true) {
      setRowSelection({
        hideSelectAll: true,
        type: 'checkbox',
        onChange: (_selectedRowKeys: React.Key[], selectedRows: any) => {
          setSelectedUsers(selectedRows);
        },
        getCheckboxProps: (record: Record<string, any>) => ({
          disabled: record.status === 'DEACTIVATED', // Column configuration not to be checked
        }),
      });
    } else {
      setRowSelection(undefined);
    }
  }
  return (
    <>
      <UserDrawer
        visible={isVisibleUserDrawer}
        selectedUser={selectedUser}
        width={650}
        onClose={onCloseDrawer}
        onSaveUser={onSaveUser}
      />
      <Row style={{ marginTop: '5px', marginBottom: '15px' }} align="middle">
        <Col xl={7} xxl={10}>
          <Input
            size="large"
            placeholder="Pesquisar grupo ou usuário"
            type="text"
            onChange={debounce((evt) => onSearchUser(evt.target.value), 500)}
            prefix={
              <SearchOutlined style={{ color: '#09d4ab', fontSize: '20px' }} />
            }
            allowClear
          />
        </Col>
        <Col
          xl={17}
          xxl={14}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {allowSelect ? (
            <>
              <Button
                icon={<CloseOutlined />}
                size="large"
                type="text"
                style={{ marginRight: '20px' }}
                onClick={() => onClickSelectUsers(allowSelect)}
              >
                Cancelar desabilitar em lote
              </Button>
              <Button
                icon={<UsergroupDeleteOutlined />}
                size="large"
                danger
                type="primary"
                style={{ marginRight: '20px' }}
                disabled={!selectedUsers}
                onClick={() => showDeleteConfirm()}
              >
                Desabilitar usuários em lote
              </Button>
            </>
          ) : (
            <Button
              icon={<UsergroupDeleteOutlined />}
              size="large"
              danger
              style={{ marginRight: '20px' }}
              onClick={() => onClickSelectUsers(allowSelect)}
            />
          )}
          <Button
            icon={<PlusOutlined />}
            type="primary"
            size="large"
            onClick={() => setIsVisbileUserDrawer(true)}
          >
            Novo usuário
          </Button>
        </Col>
      </Row>
      <div style={{ marginBottom: '25px' }}>
        <Switch defaultChecked={showDisabledUsers} onChange={onChangeSwitch} />
        <span style={{ display: 'inline-block', marginLeft: '10px' }}>
          Exibir desabilitados (
          {allUsers?.filter((user) => user.status === 'DEACTIVATED').length})
        </span>
      </div>
      <RegistersTable
        columns={columns}
        total={usersData?.count}
        itemsPerPage={100}
        dataSource={usersToRender || []}
        onChangePagination={onChangePagination}
        isLoading={isLoadingUsersData}
        queryPage={queryPage}
        onSelectItem={onSelectUser}
        rowSelection={rowSelection}
        showSizeChanger={false}
      />
    </>
  );
}
