import Column from 'antd/lib/table/Column';
import { TdHTMLAttributes, useEffect, useState } from 'react';

import { OperationProductTotalMovimentedByShiftType } from '../../../types';
import { removeDuplicateFromObjectArray } from '../../../utils/utils';
import { mergeSameShiftAndProductTotalMovimented } from './formatters';
import { MovimentationsTable } from './styles';

type OnCellType = TdHTMLAttributes<
  Record<string, string | number | OperationProductTotalMovimentedByShiftType>
>;

type ItemProps = {
  total_by_shift: string;
  total_movimented: number;
  operation_product_name: string;
  total_by_product: number;
  total_by_product_by_period: number;
  total_by_period: number;
};

type CustomTableCellProps = {
  dataIndex: string;
  record: ItemProps;
};

type MovimentationByPeriodProps = {
  movimentationsByPeriod: OperationProductTotalMovimentedByShiftType[];
  operationType: string;
};

export function MovimentationByPeriod(props: MovimentationByPeriodProps) {
  const { movimentationsByPeriod, operationType } = props;
  const [TableData, setTableData] = useState<
    OperationProductTotalMovimentedByShiftType[]
  >([]);
  const [WorkShifts, setWorkShifts] = useState<
    OperationProductTotalMovimentedByShiftType[]
  >([]);

  const UNIT = operationType === 'VEHICLES' ? 'un' : 'ton';
  useEffect(() => {
    if (operationType !== 'VEHICLES') {
      setTableData(
        removeDuplicateFromObjectArray(
          movimentationsByPeriod,
          'operation_product_id'
        ).concat({
          operation_product_name: 'TOTAIS',
        }) as OperationProductTotalMovimentedByShiftType[]
      );
      setWorkShifts(
        removeDuplicateFromObjectArray(
          movimentationsByPeriod,
          'work_shift_id'
        ) as OperationProductTotalMovimentedByShiftType[]
      );
    } else {
      setTableData(
        removeDuplicateFromObjectArray(
          mergeDuplicateMovimentations(movimentationsByPeriod),
          'operation_product_id'
        ).concat({
          name: 'TOTAIS',
        }) as OperationProductTotalMovimentedByShiftType[]
      );
      setWorkShifts(
        removeDuplicateFromObjectArray(
          mergeDuplicateMovimentations(movimentationsByPeriod),
          'work_shift_id'
        ) as OperationProductTotalMovimentedByShiftType[]
      );
    }
  }, [movimentationsByPeriod]);

  console.log(movimentationsByPeriod);

  function mergeDuplicateMovimentations(
    array: OperationProductTotalMovimentedByShiftType[]
  ) {
    return array.reduce((total, current) => {
      const x = total.find(
        (item) =>
          item.operation_product_id === current.operation_product_id &&
          item.work_shift_id === current.work_shift_id
      );
      const xIndex = total.findIndex((item) => item === x);
      if (!x) {
        return total.concat([current]);
      }
      const newCurrent = {
        ...current,
        total_movimented: x.total_movimented + current.total_movimented,
      };
      total.splice(xIndex, 1, newCurrent);
      return total;
    }, [] as OperationProductTotalMovimentedByShiftType[]);
  }

  function CustomTableCell({
    dataIndex,
    record,
    ...restProps
  }: CustomTableCellProps) {
    if (dataIndex === 'total_by_shift') {
      return (
        <td {...restProps}>
          {record?.total_by_shift || 0} {UNIT}
        </td>
      );
    }
    if (dataIndex === 'total_movimented') {
      return (
        <td {...restProps}>
          {record?.total_movimented || 0} {UNIT}
        </td>
      );
    }
    if (dataIndex === 'total_by_product') {
      return (
        <td {...restProps}>
          {record?.total_by_product || 0} {UNIT}
        </td>
      );
    }
    if (dataIndex === 'operation_product_name') {
      return <td {...restProps}>{record?.operation_product_name} </td>;
    }
    if (dataIndex === 'total_by_product_by_period') {
      return (
        <td {...restProps}>
          {record?.total_by_product_by_period || 0} {UNIT}
        </td>
      );
    }
    if (dataIndex === 'total_by_period') {
      return (
        <td {...restProps}>
          {record?.total_by_period || 0} {UNIT}
        </td>
      );
    }
    return <td {...restProps} />;
  }
  console.log('TESTE', TableData);
  console.log('TESTE', movimentationsByPeriod);
  console.log(
    'TESTE 2',
    mergeSameShiftAndProductTotalMovimented(movimentationsByPeriod)
  );

  return (
    <MovimentationsTable
      dataSource={TableData}
      components={{
        body: {
          cell: CustomTableCell,
        },
      }}
      pagination={false}
    >
      <Column
        dataIndex={
          operationType === 'VEHICLES' ? 'name' : 'operation_product_name'
        }
        onCell={(
          record: OperationProductTotalMovimentedByShiftType,
          index?: number
        ) => {
          return {
            dataIndex:
              operationType === 'VEHICLES' ? 'name' : 'operation_product_name',
            index,
            record,
          } as OnCellType;
        }}
        render={(value) => {
          return operationType === 'VEHICLES'
            ? value === 'IMPORTING'
              ? 'IMPORTAÇÃO'
              : value === 'EXPORTING'
              ? 'EXPORTAÇÃO'
              : value
            : value;
        }}
        width="20%"
      />

      {WorkShifts.map((workShift, workShiftIndex) => {
        return (
          <Column
            key={workShiftIndex}
            title={
              <div className="work-shift">
                <div className="work-shift-name">
                  {`${workShift.work_shift_period_time}`}
                </div>
                <div className="work-shift-gang-amount">
                  {workShift.work_shift_gang_amount || '-'} ternos
                </div>
              </div>
            }
            dataIndex="total_movimented"
            onCell={(
              record: OperationProductTotalMovimentedByShiftType,
              index?: number
            ) => {
              const DataArray =
                operationType === 'VEHICLES'
                  ? WorkShifts
                  : mergeSameShiftAndProductTotalMovimented(
                      movimentationsByPeriod
                    );
              if (index !== undefined && index === TableData.length - 1) {
                // Se for a última linha mostrar totais por turno
                return {
                  record: DataArray[workShiftIndex],
                  index: workShiftIndex,
                  dataIndex: 'total_by_shift',
                } as OnCellType;
              }

              return {
                record: DataArray.filter(
                  (movimentation) =>
                    movimentation.operation_product_id ===
                    record.operation_product_id
                )[workShiftIndex],
                dataIndex: 'total_movimented',
                index,
              } as OnCellType;
            }}
          />
        );
      })}
      <Column
        dataIndex="total_by_product_by_period"
        title="TOTAIS"
        onCell={(
          record: OperationProductTotalMovimentedByShiftType,
          index?: number
        ) => {
          if (index !== undefined && index === TableData.length - 1) {
            return {
              dataIndex: 'total_by_period',
              index,
              record: {
                total_by_period: TableData.reduce(
                  (previous: number, current) => {
                    previous += current.total_by_product_by_period || 0;
                    return previous;
                  },
                  0
                ),
              },
            } as OnCellType;
          }
          return {
            dataIndex: 'total_by_product_by_period',
            index,
            record,
          } as OnCellType;
        }}
      />
    </MovimentationsTable>
  );
}
