import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  .stopoverCode {
    font-family: 'Consolas';
    color: var(--neutral_light);
  }
`;
