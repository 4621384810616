import {
  GenericQueryParamsType,
  GenericResponse,
  SystemParamType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';

export type SystemParamResponse = GenericResponse & {
  results: Array<SystemParamType> | [];
};

export type SystemParamGenericQueryParamsType = GenericQueryParamsType & {
  name: string;
};

export const systemParamApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getSystemParamByName: build.query<
      SystemParamType,
      SystemParamGenericQueryParamsType
    >({
      query: (params: SystemParamGenericQueryParamsType) => {
        const { name } = params;
        return {
          url: `/core/system-params/${name}`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: SystemParamType) => {
        response.items.forEach((item) => {
          // criado atributo para atender o FormItemSelect
          item.name = item.description;
        });
        return response;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetSystemParamByNameQuery } = systemParamApi;
