import { useEffect, useState } from 'react';

import { Modal } from '../../components';
import { Stopover } from '../../types';
import { EndDockingModal } from './endDockingModal';
import { ManoeuvreModal } from './manoeuvreModal';
import { PilotExpectedOnBoardDocking } from './pilotExpectedOnBoardDocking';
import { ReleaseDockingModal } from './releaseDockingModal';
import { StartDockingModal } from './startDockingModal';
import { BaseActionModalProps } from './staymentActions';
import { disabledDateAfterToday } from '../../utils/utils';

type DockingActionsModalProps = BaseActionModalProps & {
  startManoeuvre: () => void;
  releaseDocking: (releasedWithPendings?: boolean) => void;
  startDocking: () => void;
  endDocking: () => void;
  pilotExpectedOnBoard: () => void;
  stopover?: Stopover;
  disabledDate?: (current: any) => boolean;
};
export function DockingActionsModal(props: DockingActionsModalProps) {
  const {
    startManoeuvre,
    releaseDocking,
    startDocking,
    endDocking,
    pilotExpectedOnBoard,
    isVisible,
    setIsVisible,
    stopover,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
    status,
    disabledDate,
  } = props;
  const [modalTitle, setModalTitle] = useState('');
  useEffect(() => {
    if (
      status === 'RELEASE_DOCKING' ||
      status === 'PILOT_EXPECTED_ON_BOARD_DOCKING'
    ) {
      setModalTitle('Liberar atracação');
    } else if (status === 'START_MANOEUVRE') {
      setModalTitle('Iniciar manobra');
    } else if (status === 'TIE') {
      setModalTitle('Amarrar');
    } else if (status === 'END_MANOEUVRE') {
      setModalTitle('Finalizar atracação');
    }
  });
  return (
    <Modal
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      title={modalTitle}
      className="TOSPrimaryModal"
      width={624}
      footer={null}
    >
      {status === 'PILOT_EXPECTED_ON_BOARD_DOCKING' ? (
        <PilotExpectedOnBoardDocking
          setIsVisible={setIsVisible}
          onPilotExpectedOnBoard={pilotExpectedOnBoard}
          updateDocking={updateDocking}
          currentDocking={currentDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
          disabledDate={disabledDateAfterToday}
        />
      ) : status === 'RELEASE_DOCKING' ? (
        <ReleaseDockingModal
          setIsVisible={setIsVisible}
          onRelease={releaseDocking}
          stopover={stopover}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
          readOnly={!currentDocking?.pilot_expected_on_board}
          disabledDate={disabledDateAfterToday}
        />
      ) : status === 'START_MANOEUVRE' ? (
        <ManoeuvreModal
          setIsVisible={setIsVisible}
          onManoeuvre={startManoeuvre}
          stopover={stopover}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
          disabledDate={disabledDateAfterToday}
        />
      ) : status === 'TIE' ? (
        <StartDockingModal
          setIsVisible={setIsVisible}
          onStartDocking={startDocking}
          stopover={stopover}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
          disabledDate={disabledDateAfterToday}
        />
      ) : status === 'END_MANOEUVRE' ? (
        <EndDockingModal
          setIsVisible={setIsVisible}
          onEndDocking={endDocking}
          stopover={stopover}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
          disabledDate={disabledDateAfterToday}
        />
      ) : null}
    </Modal>
  );
}
