import { InputNumber as ANTDInputNumber } from 'antd';
import styled from 'styled-components';

export const InputNumber = styled(ANTDInputNumber)`
  border: 0px;
  box-shadow: 0 0 0 transparent !important;
  width: 100%;
  & input {
    font-weight: 600 !important;
  }

  & [readonly] {
    border-bottom: 0px !important;
  }

  &.ant-input-number-readonly {
    border-bottom: 0px !important;
    & input {
      padding-left: 0px;
    }
    & .ant-input-number-input-wrap {
      border-bottom: 0px !important;
    }
  }

  & .ant-input-number-wrapper.ant-input-number-group {
    border-bottom: 1px solid var(--neutral_light);
    & .ant-input-number-input-wrap {
      border-bottom: 0px solid var(--neutral_light);
    }
  }

  &.ant-input-number-group-wrapper {
    border-bottom: 0px solid var(--neutral_light);
  }

  & .ant-input-number-in-form-item {
    border-bottom: 0px solid var(--neutral_light);
  }
  & .ant-input-number-input-wrap {
    border-bottom: 1px solid var(--neutral_light);
  }
  &.hide-controls {
    & .ant-input-number-handler-wrap {
      display: none;
    }
  }

  /* &.ant-input-number-in-form-item {
    border-bottom: 0px;
  } */
  & .ant-input-number-group-addon {
    border: 0px;
    background: var(--neutral_lightest);
    color: var(--neutral_medium);
    font-weight: bold;
  }
`;
