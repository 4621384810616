import styled from 'styled-components';

export const CountTag = styled.span`
  background: var(--neutral_medium);
  border-radius: 100px;
  color: #fff;
  margin-left: 10px;
  padding: 2px 10px;
  font-weight: normal;
  transition: 150ms;
  font-size: 12px;
  font-family: 'Consolas';
  height: auto;
  line-height: normal;
  &:hover {
    background: #3b4f5f;
  }
  &.light {
    background: transparent;
    color: var(--neutral_medium);
    border: 1px solid var(--neutral_light);
  }
`;
