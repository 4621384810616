import { EditFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { Button } from '../../../components';
import { CargoNatureRenderer } from './cargoNatureRenderer';

export function columns(
  onSelectItem: (record: any) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title: <div className="show-amount">Mercadorias</div>,
      dataIndex: 'name',
      sorter: {
        compare: (a: any, b: any) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      defaultSortOrder: 'ascend' as const,
      showSorterTooltip: false,
    },
    {
      title: 'Tipo de mercadoria',
      dataIndex: ['cargo_type', 'name'],
      sorter: {
        compare: (a: any, b: any) =>
          a.cargo_type.name.localeCompare(b.cargo_type.name),
        multiple: 1,
      },
      showSorterTooltip: false,
    },
    {
      title: 'Grupo de produto',
      dataIndex: ['cargo_type', 'product_group', 'name'],
      sorter: {
        compare: (a: any, b: any) =>
          a.cargo_type.product_group.name.localeCompare(
            b.cargo_type.product_group.name
          ),
        multiple: 1,
      },
      showSorterTooltip: false,
    },
    {
      title: 'Natureza da carga',
      dataIndex: ['cargo_type', 'product_group', 'nature_of_cargo'],
      sorter: {
        compare: (a: any, b: any) =>
          a.cargo_type.product_group.nature_of_cargo.name.localeCompare(
            b.cargo_type.product_group.nature_of_cargo.name
          ),
        multiple: 1,
      },
      showSorterTooltip: false,
      render: (_groups: Array<any>, record: any) => (
        <CargoNatureRenderer
          cargoNature={{
            value: record.cargo_type.product_group.nature_of_cargo.id,
            name: record.cargo_type.product_group.nature_of_cargo.name,
          }}
        />
      ),
    },
    {
      dataIndex: 'edit',
      align: 'center',
      width: '10%',
      render: (_, record: any) => {
        return (
          <Button
            icon={<EditFilled twoToneColor="#09D4AB" />}
            onClick={() => onSelectItem(record)}
          />
        );
      },
    },
  ];
}
