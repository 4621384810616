import { Row, FormInstance, Col } from 'antd';
import { debounce } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';

import {
  FormItemInput,
  FormItemInputNumber,
  FormItemSelect,
  Select,
} from '../../../../components';
import { FormItem } from '../../../../components/antd/Form';
import { useGetCargoTypeQuery } from '../../../../services/cargoApi';
import { useGetSystemParamByNameQuery } from '../../../../services/systemParamsApi';
import {
  useGetPortsCountriesQuery,
  useGetPortsQuery,
} from '../../../../services/vesselApi';
import { OperationProductType } from '../../../../types';
import { operationDirectionList } from '../../../../utils/lists';
import { isNullOrUndefined } from '../../../../utils/utils';

type FormItemsOperationProductProps = {
  form: FormInstance;
  readOnly?: boolean;
  selectedProduct?: OperationProductType;
  smallForm?: boolean;
  style?: CSSProperties;
};

export function FormItemsOperationProduct(
  props: FormItemsOperationProductProps
) {
  const { form, readOnly, selectedProduct, smallForm, style } = props;
  const [searchCargoTypeName, setSearchCargoTypeName] = useState(
    form.getFieldValue(['cargo_type', 'name'])
  );
  const [searchOperationTypeName, setSearchOperationTypeName] = useState(
    form.getFieldValue(['operation_type', 'description'])
  );
  const [selectedOperationType, setSelectedOperationType] = useState(Object);
  const [selectedLoadingPort, setSelectedLoadingPort] = useState(Object);
  const [selectedLoadingPortCountry, setSelectedLoadingPortCountry] =
    useState<string>();
  const [searchLoadingPortCountry, setSearchLoadingPortCountry] = useState({});
  const [skipSearchLoadingPortCountry, setSkipSearchLoadingPortCountry] =
    useState(true);
  const [searchLoadingPort, setSearchLoadingPort] = useState({});
  const [skipSearchLoadingPort, setSkipSearchLoadingPort] = useState(true);

  const [selectedUnloadingPort, setSelectedUnloadingPort] = useState(Object);
  const [selectedUnloadingPortCountry, setSelectedUnloadingPortCountry] =
    useState<string>();
  const [searchUnloadingPortCountry, setSearchUnloadingPortCountry] = useState(
    {}
  );
  const [skipSearchUnloadingPortCountry, setSkipSearchUnloadingPortCountry] =
    useState(true);
  const [searchUnloadingPort, setSearchUnloadingPort] = useState({});
  const [skipSearchUnloadingPort, setSkipSearchUnloadingPort] = useState(true);

  const { data: cargoTypesData } = useGetCargoTypeQuery();
  const { data: operationTypesData } = useGetSystemParamByNameQuery({
    name: 'OPERATION_TYPE',
  });
  const { data: loadingPortsData, isLoading: isLoadingLoadingPortData } =
    useGetPortsQuery(searchLoadingPort, {
      skip: skipSearchLoadingPort,
    });
  const { data: unloadingPortsData, isLoading: isLoadingUnloadingPortData } =
    useGetPortsQuery(searchUnloadingPort, {
      skip: skipSearchUnloadingPort,
    });
  const {
    data: countriesDataLoadingPort,
    isLoading: isLoadingCountriesDataLoadingPort,
  } = useGetPortsCountriesQuery(searchLoadingPortCountry, {
    skip: skipSearchLoadingPortCountry,
  });
  const {
    data: countriesDataUnloadingPort,
    isLoading: isLoadingCountriesDataUnloadingPort,
  } = useGetPortsCountriesQuery(searchUnloadingPortCountry, {
    skip: skipSearchUnloadingPortCountry,
  });

  useEffect(() => {
    if (!readOnly) {
      const unloading_port_country_code = form.getFieldValue([
        'unloading_port',
        'country_code',
      ]);
      const loading_port_country_code = form.getFieldValue([
        'loading_port',
        'country_code',
      ]);

      if (unloading_port_country_code) {
        setSelectedUnloadingPortCountry(unloading_port_country_code);
        setSearchUnloadingPort({ countryCode: unloading_port_country_code });
        setSkipSearchUnloadingPort(false);
      }
      if (loading_port_country_code) {
        setSelectedLoadingPortCountry(loading_port_country_code);
        setSearchLoadingPort({ countryCode: loading_port_country_code });
        setSkipSearchLoadingPort(false);
      }
    }
  }, [readOnly]);

  useEffect(() => {
    form.setFieldsValue({
      unloading_port: {
        ...selectedUnloadingPort,
      },
      loading_port: {
        ...selectedLoadingPort,
      },
      operation_type: {
        ...selectedOperationType,
      },
    });
  }, [selectedUnloadingPort, selectedLoadingPort, selectedOperationType]);

  // useEffect(() => {
  //   const unloadingPortObject = unloadingPortsData?.results.find((port) => {
  //     return port.id === selectedProduct?.unloading_port?.bi_trigram;
  //   });
  //   setSelectedUnloadingPort({ ...unloadingPortObject });

  //   const loadingPortObject = loadingPortsData?.results.find((port) => {
  //     return port.id === selectedProduct?.loading_port?.bi_trigram;
  //   });
  //   setSelectedLoadingPort({ ...loadingPortObject });
  // }, [selectedProduct, unloadingPortsData, loadingPortsData]);
  function onSelectOperationType(e: any) {
    if (!isNullOrUndefined(e)) {
      const operationType = operationTypesData?.items.find(
        (t: any) => t.id === e
      );
      setSelectedOperationType(operationType);
    }
  }
  function onSeachCargoTypes(val: string) {
    setSearchCargoTypeName(val);
  }
  function onSeachOperationTypes(val: string) {
    setSearchOperationTypeName(val);
  }
  function onSearchCountryLoadingPortByName(val: string) {
    setSearchLoadingPortCountry({ countryName: val });
    setSkipSearchLoadingPortCountry(false);
  }
  function onSearchLoadingPortByName(val: string) {
    setSearchLoadingPort({
      name: val,
      countryCode: selectedLoadingPortCountry,
    });
    setSkipSearchLoadingPort(false);
  }
  function onSelectLoadingPortCountry(val: any) {
    setSelectedLoadingPortCountry(val);
    setSearchLoadingPort({ countryCode: val });
    setSkipSearchLoadingPort(false);
    setSelectedLoadingPort({
      country_code: val,
      name: null,
      bi_trigram: null,
    });
  }
  function onSelectLoadingPort(option: string) {
    const portObject = loadingPortsData?.results.find((port) => {
      return port.id === option;
    });
    setSelectedLoadingPort({ ...portObject });
  }
  function onClearSelectedLoadingPortCountry() {
    setSelectedLoadingPortCountry('');
    setSelectedLoadingPort({});
  }
  function onClearSelectedLoadingPort() {
    setSelectedLoadingPort({});
  }

  function onSearchCountryUnloadingPortByName(val: string) {
    setSearchUnloadingPortCountry({ countryName: val });
    setSkipSearchUnloadingPortCountry(false);
  }
  function onSearchUnloadingPortByName(val: string) {
    setSearchUnloadingPort({
      name: val,
      countryCode: selectedUnloadingPortCountry,
    });
    setSkipSearchUnloadingPort(false);
  }
  function onSelectUnloadingPortCountry(val: any) {
    setSelectedUnloadingPortCountry(val);
    setSearchUnloadingPort({ countryCode: val });
    setSkipSearchUnloadingPort(false);
    setSelectedUnloadingPort({
      country_code: val,
      name: null,
      bi_trigram: null,
    });
  }
  function onSelectUnloadingPort(option: string) {
    const portObject = unloadingPortsData?.results.find((port) => {
      return port.id === option;
    });
    setSelectedUnloadingPort({ ...portObject });
  }
  function onClearSelectedUnloadingPortCountry() {
    setSelectedUnloadingPortCountry('');
    setSelectedUnloadingPort({});
  }
  function onClearSelectedUnloadingPort() {
    setSelectedUnloadingPort({});
  }
  function cargoOptionsRenderer(cargoType: any) {
    return (
      <Select.Option key={cargoType.name} value={cargoType.name}>
        {cargoType.name}
      </Select.Option>
    );
  }

  return (
    <div style={style}>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={12}
          name={['cargo_type', 'name']}
          label="Mercadoria"
          dataList={cargoTypesData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          allowClear
          disabled={readOnly}
          onSearch={debounce(onSeachCargoTypes, 400)}
          optionRenderer={cargoOptionsRenderer}
          notFoundContent="Digite uma busca"
          rules={[{ required: true, message: 'campo obrigatório' }]}
        />
        <FormItemSelect
          colSpan={12}
          name="operation_direction"
          label="Sentido do produto"
          dataList={operationDirectionList}
          showSearch
          allowClear
          disabled={readOnly}
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemInput
          colSpan={12}
          name="manifest_code"
          label="Código do manifesto"
          readOnly={readOnly}
          maxLength={20}
        />
        <FormItemInputNumber
          colSpan={12}
          label="Total manifestado"
          name="manifest_total"
          addonAfter="ton"
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={12}
          disabled={readOnly}
          name={['loading_port', 'country_name']}
          label="País de carregamento"
          allowClear
          showSearch
          onSearch={debounce(onSearchCountryLoadingPortByName, 400)}
          onSelect={onSelectLoadingPortCountry}
          onClear={onClearSelectedLoadingPortCountry}
          dataList={countriesDataLoadingPort?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCountriesDataLoadingPort}
          notFoundContent="Nenhum país encontrado para o filtro informado"
        />
        <FormItem name={['loading_port', 'id']} noStyle />
        <FormItemSelect
          colSpan={12}
          disabled={readOnly}
          name={['loading_port', 'name']}
          label="Porto de carregamento"
          allowClear
          showSearch
          onSearch={debounce((e) => onSearchLoadingPortByName(e), 400)}
          onSelect={onSelectLoadingPort}
          onClear={onClearSelectedLoadingPort}
          dataList={loadingPortsData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingLoadingPortData}
          notFoundContent="Nenhum porto encontrado para o filtro informado"
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItemSelect
          colSpan={12}
          disabled={readOnly}
          name={['unloading_port', 'country_name']}
          label="País de descarregamento"
          allowClear
          showSearch
          onSearch={debounce(onSearchCountryUnloadingPortByName, 400)}
          onSelect={onSelectUnloadingPortCountry}
          onClear={onClearSelectedUnloadingPortCountry}
          dataList={countriesDataUnloadingPort?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCountriesDataUnloadingPort}
          notFoundContent="Nenhum país encontrado para o filtro informado"
        />
        <FormItem name={['unloading_port', 'id']} noStyle />
        <FormItemSelect
          colSpan={12}
          disabled={readOnly}
          name={['unloading_port', 'name']}
          label="Porto de descarregamento"
          allowClear
          showSearch
          onSearch={debounce((e) => onSearchUnloadingPortByName(e), 400)}
          onSelect={onSelectUnloadingPort}
          onClear={onClearSelectedUnloadingPort}
          dataList={unloadingPortsData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingUnloadingPortData}
          notFoundContent="Nenhum porto encontrado para o filtro informado"
        />
      </Row>
      <Row gutter={smallForm ? 20 : 40} align="bottom">
        <FormItem name={['operation_type', 'id']} noStyle />
        <FormItemSelect
          colSpan={12}
          name={['operation_type', 'description']}
          label="Tipo de operação da carga"
          dataList={operationTypesData?.items}
          showSearch
          allowClear
          disabled={readOnly}
          onSearch={debounce(onSeachOperationTypes, 400)}
          onSelect={onSelectOperationType}
          notFoundContent="Digite uma busca"
        />
      </Row>
    </div>
  );
}
