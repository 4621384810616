import { ColumnsType } from 'antd/lib/table';

import {
  capitalizeFirstLetter,
  formatNumberToLocale,
} from '../../../../../utils/utils';

export function liquidBulkStorageSumaryTableColumns(): ColumnsType<
  Record<string, any>
> {
  return [
    {
      title: 'Armazenamento',
      key: 'storage_name',
      dataIndex: 'storage_name',
      render: (value: any) => {
        return capitalizeFirstLetter(value);
      },
      width: '20%',
    },
    {
      title: 'Distribuidora',
      key: 'distributor',
      dataIndex: 'distributor',
      width: '25%',
    },
    {
      title: 'Volume',
      key: 'total_amount',
      dataIndex: 'total_amount',
      render(value) {
        return <>{formatNumberToLocale(value)} m³</>;
      },
      align: 'right' as const,
      width: '15%',
    },
    {
      title: 'Peso',
      key: 'total_weight',
      dataIndex: 'total_weight',
      render(value) {
        return <>{formatNumberToLocale(value)} ton</>;
      },
      align: 'right' as const,
      width: '15%',
    },
    {
      title: 'Densidade',
      key: 'total_density',
      dataIndex: 'total_density',
      render(value) {
        return <>{formatNumberToLocale(value)}</>;
      },
      width: '5%',
      align: 'right' as const,
    },
  ];
}
