import { Layout, Menu } from 'antd';
import styled from 'styled-components';

import { Button } from '../../antd/Button';

export const Sider = styled(Layout.Sider)`
  position: absolute;
  height: 100vh;
  border-right: 1px solid var(--neutral_lighter);
  z-index: 100;
  display: flex;
  flex-direction: column;
  & .ant-layout-sider-children {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSubMenu = styled(Menu.SubMenu)`
  color: var(--neutral_medium);
  & .ant-menu-item a {
    color: var(--neutral_medium) !important;
  }
  & .ant-menu-submenu-title {
    &:hover {
      color: var(--neutral_medium) !important;
    }
    & .ant-menu-item-icon {
      display: flex !important;
      align-items: center;
      height: 100%;
      width: 24px;
      & svg {
        fill: var(--neutral_medium);
      }
    }
  }
  &.ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: transparent !important;
    box-shadow: inset 6px 0px var(--green-sea_medium);
    & .ant-menu-title-content {
      color: var(--neutral_dark) !important;
      font-weight: bold;
    }
    & .ant-menu-item-icon {
      & svg {
        fill: var(--neutral_dark);
      }
    }
  }
  & .ant-menu.ant-menu-sub.ant-menu-inline {
    background: var(--neutral_lightest);
  }
  &.ant-menu-submenu-selected {
    & .ant-menu.ant-menu-sub.ant-menu-inline {
      & .ant-menu-item {
        color: var(--neutral_lighter) !important;
        background: transparent !important;
        & .ant-menu-title-content a {
          color: var(--neutral_medium) !important;
        }
        &.ant-menu-item-selected {
          box-shadow: inset 6px 0px var(--green-sea_medium);
          & .ant-menu-title-content a {
            color: var(--neutral_dark) !important;
          }
        }
        &:after {
          border-right: 1px solid var(--neutral_lighter);
        }
      }
    }
  }
`;

export const StyledMenu = styled(Menu)`
  height: 100%;
  flex-grow: 1;
`;

export const MenuItem = styled(Menu.Item)`
  background-color: transparent;
  color: var(--neutral_dark);
  &:after {
    border-right: 1px solid var(--neutral_lighter);
  }
  &.ant-menu-item-selected {
    background-color: transparent !important;
    box-shadow: inset 6px 0px var(--green-sea_medium);
    &:after {
      border-right: 0px;
    }
    & a {
      color: var(--neutral_dark);
      &:hover {
        color: var(--neutral_dark);
      }
    }
    & .ant-menu-item-icon {
      & svg {
        fill: var(--neutral_dark);
      }
    }
    & a {
      font-weight: 600;
    }
  }
  &.ant-menu-item-active {
    background-color: transparent !important;
    & a {
      color: var(--neutral_dark);
      &:hover {
        color: var(--neutral_dark);
      }
    }
    & .ant-menu-item-icon {
      & svg {
        fill: var(--neutral_dark);
      }
    }
  }
  &:hover {
    background-color: var(--neutral_lightest);
    color: var(--neutral_medium);
    border-right: 1px solid var(--neutral_lighter);
  }
  & a {
    color: var(--neutral_medium);
    &:hover {
      color: var(--neutral_medium);
    }
  }
  & .ant-menu-item-icon {
    display: flex !important;
    align-items: center;
    height: 100%;
    width: 24px;
    & svg {
      fill: var(--neutral_light);
    }
  }
`;

export const ButtonLogout = styled(Button)`
  bottom: 0;
  text-align: left;
  border: none;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  padding: 2px;

  & span {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
`;

type LogoProps = {
  collapsed: boolean;
};

export const LogoWrapper = styled.div<LogoProps>`
  padding: 20px 25px 20px;
  overflow: hidden;
  transition: 300ms;
  & svg {
    transition: 300ms;
    width: ${(props) => (props.collapsed ? '70px' : '200px')};
  }
  & #Caminho_7764,
  & #Caminho_7765 {
    transition: 300ms;
    opacity: ${(props) => (props.collapsed ? '0' : '1')};
    display: ${(props) => (props.collapsed ? 'none' : 'block')};
  }
`;
