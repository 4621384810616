import { useState } from 'react';

import { Stopover as StopoverType } from '../../../../types';
import { StopoverDescription } from './stopoverDescription';
import { StopoverEditDrawer } from './stopoverEditDrawer/StopoverEditDrawer';

type StopoverDataProps = {
  selectedStopover: StopoverType;
  setSelectedStopover: (value: StopoverType) => void;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  screen: 'AGENT_AREA' | 'STOPOVER';
};

export function StopoverData(props: StopoverDataProps) {
  const {
    selectedStopover,
    setSelectedStopover,
    activeKey,
    onOpenPanel,
    screen,
  } = props;

  const [isStopoverEditDrawerVisible, setIsStopoverEditDrawerVisible] =
    useState(false);

  function onBack() {
    setIsStopoverEditDrawerVisible(false);
  }

  function onEdit(evt: any) {
    evt.stopPropagation();
    setIsStopoverEditDrawerVisible(true);
  }

  return (
    <>
      <StopoverEditDrawer
        visible={isStopoverEditDrawerVisible}
        setIsVisibleForm={setIsStopoverEditDrawerVisible}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        width={800}
        onBack={onBack}
      />
      <StopoverDescription
        selectedStopover={selectedStopover}
        activeKey={activeKey}
        onOpenPanel={onOpenPanel}
        onEdit={onEdit}
        screen={screen}
      />
    </>
  );
}
