import { InfoCircleFilled, WarningFilled } from '@ant-design/icons';
import { Col, Menu, MenuProps, Row, Tooltip } from 'antd';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import Flag from 'react-flagpack';

// import { useGetEtaQuery } from '../../../services/etaApi';
import { StatusTag } from '../..';
import { Stopover } from '../../../types';
import { getStopoverStatus } from '../../../utils/utils';
import { DockingLogStatus } from '../DockingLogStatusTag';
import { DockingReleaseStatus } from '../DockingReleaseStatus';
import ErrorBoundary from '../ErrorBoundary';
import { StopoverCardCollapsiblePanel, TOSStopoverCard } from './styles';

type StopoverCardProps = {
  stopover: Stopover;
  selectedStopover: Stopover;
  onSelectStopover: (stopover: Stopover) => void;
  expanded?: number;
  collapsibleCard?: boolean;
  onSelectMenuItem?: (
    operationKey?: string | null,
    dockingIndex?: string | null
  ) => void;
  pageContext?:
    | 'STOPOVER'
    | 'STAYMENT'
    | 'OPERATION'
    | 'OPERATOR_AREA'
    | 'INVOICING'
    | 'STORAGE';
  cardRenderer?: (stopover: Stopover) => ReactNode;
  disabled?: boolean;
};

type MenuItem = Required<MenuProps>['items'][number];

export function StopoverCard(props: StopoverCardProps) {
  const {
    stopover,
    selectedStopover,
    onSelectStopover,
    expanded,
    collapsibleCard,
    onSelectMenuItem,
    pageContext,
    cardRenderer,
    disabled,
  } = props;
  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      children,
      label,
    } as MenuItem;
  }

  const [StopoverDockingsMenuItems, setStopoverDockingsMenuItems] = useState<
    MenuItem[]
  >([]);

  useEffect(() => {
    const items: MenuItem[] = [];
    if (selectedStopover && selectedStopover.dockings) {
      selectedStopover.dockings
        .filter((docking) => docking.code !== null)
        .forEach((docking, index) => {
          const subItems: any[] = [];
          docking.operations?.map((operation) => {
            return subItems.push(
              getItem(`Operação ${operation.id}`, operation.id)
            );
          });
          items.push(
            getItem(`Atracação ${docking.code}`, `docking_${index}`, subItems)
          );
        });
    }
    setStopoverDockingsMenuItems(items);
  }, [selectedStopover]);

  const onClickMenuItem: MenuProps['onClick'] = (e) => {
    if (onSelectMenuItem) {
      onSelectMenuItem(e.keyPath[0], e.keyPath[1]);
    }
  };

  function getStopoverStatusClassName(currentDockingStatus: string) {
    return getStopoverStatus(currentDockingStatus)?.id === 2 ||
      getStopoverStatus(currentDockingStatus)?.id === 3
      ? 'green-sea'
      : getStopoverStatus(currentDockingStatus)?.id === 5
      ? 'blue'
      : getStopoverStatus(currentDockingStatus)?.id === 4
      ? 'operation'
      : 'green-sea';
  }

  function getDelayedStatusTag() {
    return (
      <StatusTag style={{ paddingTop: '5px' }}>
        <span className="red">
          <WarningFilled style={{ border: 'none', padding: '0px' }} /> atrasada
        </span>
      </StatusTag>
    );
  }

  return (
    <>
      <TOSStopoverCard
        className={
          pageContext === 'INVOICING'
            ? selectedStopover?.charge?.id === stopover.charge?.id
              ? 'active'
              : ''
            : pageContext === 'OPERATOR_AREA'
            ? selectedStopover?.operation_id === stopover.operation_id
              ? 'active'
              : ''
            : pageContext === 'STORAGE'
            ? selectedStopover?.operation?.id === stopover?.operation_id
              ? 'active'
              : ''
            : selectedStopover?.id === stopover?.id
            ? 'active'
            : ''
        }
        onClick={() => onSelectStopover(stopover)}
        disabled={disabled}
      >
        {(cardRenderer && cardRenderer(stopover)) || (
          <>
            <Row className="header">
              {/* <Col lg={6} xl={2} xxl={2} style={{ padding: '3px' }}> */}
              {stopover.vessel?.flag && (
                <ErrorBoundary replace="">
                  <Flag code={stopover.vessel?.flag} hasBorder={false} />
                </ErrorBoundary>
              )}
              {/* </Col> */}
              {/* <Col lg={10} xl={14} xxl={14} style={{ paddingLeft: '5px' }}> */}
              <span className="title">
                {stopover.vessel?.name.toLowerCase()}
              </span>
              {/* </Col> */}
              {/* <Col lg={8} xl={8} xxl={8}> */}
              <span className="code">{stopover.code}</span>
              {/* </Col> */}
            </Row>
            <Row>
              <Col xl={2} xxl={2} />
              <Col xl={22} xxl={22}>
                <span className="shipping_agency">
                  {stopover.shipping_agency?.name}
                </span>
              </Col>
            </Row>
            <Row className="body" style={{ marginTop: '10px' }}>
              <Col xl={2} xxl={2} />
              <Col xl={15} xxl={14} style={{ paddingLeft: '5px' }}>
                {stopover.actual_time_of_arrival ? (
                  <span className="eta">
                    ESCALA EFETIVADA EM:{' '}
                    <span className="val">
                      {moment(stopover.actual_time_of_arrival).format(
                        'DD/MM/yyyy HH:mm'
                      )}
                    </span>
                  </span>
                ) : (
                  <span className="eta">
                    ESCALA AGUARDADA EM:{' '}
                    <span className="val">
                      {moment(stopover.expected_arrival).format(
                        'DD/MM/yyyy HH:mm'
                      )}
                    </span>
                  </span>
                )}
              </Col>
              <Col xl={7} xxl={8}>
                <span className="imo">
                  IMO: <span className="val">{stopover.vessel?.imo}</span>
                </span>
              </Col>
            </Row>
            <Row className="body">
              <Col xl={2} xxl={2} />
              <Col xl={15} xxl={14} style={{ paddingLeft: '5px' }}>
                <span className="berth">
                  BERÇO:{' '}
                  {stopover.dockings && (
                    <span className="val">
                      {stopover.dockings[0]?.docking_place?.name || 'PENDENTE'}
                    </span>
                  )}
                </span>
              </Col>
              <Col xl={7} xxl={8}>
                <span className="duv">
                  DUV: <span className="val">{stopover.duv}</span>
                </span>
              </Col>
            </Row>
            <Row className="body">
              <Col xl={2} xxl={2} />
              <Col xl={11} xxl={11} style={{ paddingLeft: '5px' }}>
                {pageContext === 'STAYMENT' ? (
                  <>
                    <DockingLogStatus
                      style={{ paddingTop: '5px', paddingRight: '5px' }}
                      dockingLogStatus={stopover?.current_docking_status_by_sql}
                    />
                    {stopover.is_current_docking_delayed_by_sql &&
                      getDelayedStatusTag()}
                  </>
                ) : (
                  // TODO: add tag de status da operação
                  <>
                    <DockingReleaseStatus
                      style={{ paddingTop: '5px', paddingRight: '5px' }}
                      dockingRelease={stopover.docking_release}
                      stopoverStatus={stopover.current_status_by_sql}
                    />
                    {stopover.is_delayed_by_sql && getDelayedStatusTag()}
                    {stopover.current_docking_status_by_sql &&
                      getStopoverStatus(stopover.current_docking_status_by_sql)
                        ?.id &&
                      getStopoverStatus(stopover.current_docking_status_by_sql)
                        ?.id !== 1 && (
                        <StatusTag>
                          <span
                            className={getStopoverStatusClassName(
                              stopover.current_docking_status_by_sql
                            )}
                          >
                            {
                              getStopoverStatus(
                                stopover.current_docking_status_by_sql
                              )?.label
                            }
                          </span>
                        </StatusTag>
                      )}
                  </>
                )}
              </Col>
              <Col xl={11} xxl={11}>
                <span className="trip">
                  VIAGEM:{' '}
                  <span className="val">
                    {stopover.shipowner_trip || 'PENDENTE'}
                  </span>
                </span>
              </Col>
            </Row>
          </>
        )}
      </TOSStopoverCard>
      {collapsibleCard &&
        selectedStopover?.id === stopover?.id &&
        stopover.dockings &&
        stopover.dockings?.length > 0 && (
          <StopoverCardCollapsiblePanel
            isVisible={selectedStopover?.id === stopover?.id && collapsibleCard}
          >
            <Menu
              onClick={onClickMenuItem}
              defaultOpenKeys={['sub1']}
              // selectedKeys={[current]}
              mode="vertical"
              items={StopoverDockingsMenuItems}
              style={{
                background: 'transparent',
                boxShadow: 'none',
                border: '0px',
              }}
            />
          </StopoverCardCollapsiblePanel>
        )}
    </>
  );
}
