import { VesselMtType } from '../types';
import { getHeaders, tosApi } from './tosApi';

export const marineTrafficApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getVesselMtData: build.query<VesselMtType, string>({
      query: (imoOrMmsi) => {
        return {
          url: `/integration/vessel-mt-data/${imoOrMmsi}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useLazyGetVesselMtDataQuery } = marineTrafficApi;
