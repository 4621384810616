import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';
import { refreshToken, removeToken } from '../app/store/authStore';
import { logout } from '../app/store/userStore';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: any = await baseQuery(args, api, extraOptions);
  const tokenErrorMsg = 'Given token not valid for any token type';
  if (
    result?.error?.status === 401 &&
    result?.error?.data?.message.includes(tokenErrorMsg)
  ) {
    const { refresh } = (api.getState() as RootState).auth;
    if (refresh) {
      const refreshResult: any = await baseQuery(
        {
          url: '/core/auth/refresh',
          method: 'POST',
          body: { refresh },
        },
        api,
        extraOptions
      );
      if (refreshResult?.data) {
        api.dispatch(refreshToken({ ...refreshResult.data, refresh }));
        return baseQuery(args, api, extraOptions);
      }
    }
    api.dispatch(removeToken());
    api.dispatch(logout());
    window.location.replace('/login');
  }
  return result;
};

export const tosApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Stopover',
    'StopoverLog',
    'Vessel',
    'Company',
    'Manoeuvre',
    'Docking',
    'BreakdownOccurrence',
    'NcmItem',
    'OperationCalculus',
    'Charge',
    'Compartments',
    'Pilot',
    'OperationCrane',
    'Cargo',
    'User',
    'Line',
    'WarehousingInvoices',
    'BerthMaintenance',
    'DockingPlace',
    'Pumping',
    'VesselRequestUpdate',
    'OperationLine',
    'WarehousingImporting',
  ],
  endpoints: () => ({}),
});

export const getHeaders = {
  'Content-Type': 'application/json',
};
