import styled from 'styled-components';

export const TableDescriptionWrapper = styled.div`
  & thead > tr > th {
    font-weight: 700;
  }

  & .table-container {
    margin-bottom: 24px;
  }

  & .table-container:last-child {
    margin-bottom: 0px;
  }

  & .table-description {
    color: var(--neutral_medium);
  }

  & .confirmed-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  & .confirmed-circle {
    background-color: var(--neutral_lightest);
    border: solid 2px var(--neutral_lighter);
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
`;
