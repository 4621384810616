import { LoginType, LoginResponse, User } from '../types';
import { getHeaders, tosApi } from './tosApi';

type UpdatePasswordType = {
  password: string;
  token: string;
};

export const authApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, LoginType>({
      query(login) {
        return {
          url: `/core/auth/login`,
          method: 'POST',
          body: login,
        };
      },
    }),
    getAuthUser: build.query<User | null, null>({
      query: () => {
        return {
          url: `/core/auth/user`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    resetPassword: build.mutation<void, string>({
      query: (email) => ({
        url: '/core/auth/user/reset-password',
        method: 'POST',
        body: { email },
      }),
    }),
    updatePassword: build.mutation<void, UpdatePasswordType>({
      query: (params) => ({
        url: '/core/auth/user/update-password',
        method: 'POST',
        body: { password: params.password },
        headers: { authorization: `Bearer ${params.token}` },
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useGetAuthUserQuery,
  useResetPasswordMutation,
  useUpdatePasswordMutation,
} = authApi;
