import { LoadingOutlined } from '@ant-design/icons';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Alert, Col, Space } from 'antd';
import { useEffect, useState } from 'react';

import { Button, Expire } from '../../components';
import { usePatchDockingMutation } from '../../services/dockingApi';
import { usePatchStopoverMutation } from '../../services/stopoverApi';
import { Docking as DockingType, Stopover as StopoverType } from '../../types';
import { formatDockingToForm } from '../../utils/formatters';
import { isNullOrUndefined } from '../../utils/utils';
import { DockingActionsModal } from './dockingActionsModal';
import { getCurrentDocking } from './getCurrentDocking';
import { OperationActionsModal } from './operationActionsModal';
import { StaymentActionsModal } from './staymentActionsModal';
import { UndockingActionsModal } from './undockingActionsModal';

type StaymentActionsProps = {
  openStopoverDrawer: (isVisible: boolean) => void;
  stopover?: StopoverType;
  openBreakdownOccurrenceDrawer: (isVisible: boolean) => void;
  selectedStopover: StopoverType;
  setSelectedStopover: (stopover: StopoverType) => void;
};

type ActionsTypes =
  | 'NONE'
  | 'PILOT_EXPECTED_ON_BOARD_DOCKING'
  | 'RELEASE_DOCKING'
  | 'START_MANOEUVRE'
  | 'TIE'
  | 'END_MANOEUVRE'
  | 'RELEASE_OPERATION'
  | 'START_OPERATION'
  | 'END_OPERATION'
  | 'PILOT_EXPECTED_ON_BOARD_UNDOCKING'
  | 'RELEASE_UNDOCKING'
  | 'UNDOCK'
  | 'END_UNDOCKING'
  | 'END_OF_UNDOCKING_MANOEUVRE'
  | 'END_STAYMENT'
  | 'STAYMENT_ENDED'
  | 'END_ANCHORING';

export function StaymentActions(props: StaymentActionsProps) {
  const {
    openStopoverDrawer,
    openBreakdownOccurrenceDrawer,
    stopover,
    selectedStopover,
    setSelectedStopover,
  } = props;

  const [isVisibleDockingActionsModal, setIsVisibleDockingActionsModal] =
    useState(false);
  const [isVisibleOperationActionsModal, setIsVisibleOperationActionsModal] =
    useState(false);
  const [isVisibleUndockingActionsModal, setIsVisibleUndockingActionsModal] =
    useState(false);
  const [isVisibleStaymentActionsModal, setIsVisibleStaymentActionsModal] =
    useState(false);

  const [currentDocking, setCurrentDocking] = useState(
    getCurrentDocking(stopover)
  );

  const isOperatingCargo = true;
  const [isLastDocking, setIsLastDocking] = useState(false);
  const [successAlertMessage, setSuccessAlertMessage] = useState('');
  const [warningAlertMessage, setWarningAlertMessage] = useState('');

  const [status, setStatus] = useState<ActionsTypes>('NONE');

  // Update docking endpoint
  const [
    patchDockingMutation,
    { isSuccess: isSuccessUpdateDocking, isLoading: isLoadingUpdateDocking },
  ] = usePatchDockingMutation();
  // Update stopover endpoint
  const [
    updateStopover,
    { isSuccess: isSuccessUpdateStopover, isLoading: isLoadingUpdateStopover },
  ] = usePatchStopoverMutation();

  async function patchDocking(
    values: any,
    callback: (releasedWithPending?: boolean) => void,
    releasedWithPending?: boolean
  ) {
    const updatedDocking = await patchDockingMutation({
      ...values,
    });
    if ('data' in updatedDocking && setSelectedStopover) {
      const { data } = updatedDocking;
      setSelectedStopover({
        ...selectedStopover,
        dockings: selectedStopover.dockings?.map((docking: DockingType) => {
          if (docking.id === data.id) {
            return formatDockingToForm(data);
          }
          return docking;
        }),
      });
      setWarningAlertMessage('');
      setSuccessAlertMessage('');
      callback(releasedWithPending);
    }
    return updatedDocking;
  }

  async function patchStopover(values: any) {
    await updateStopover({ ...values });
  }

  useEffect(() => {
    if (stopover) {
      setCurrentDocking(getCurrentDocking(stopover));
      setSelectedStopover(stopover);
    }
  }, [stopover]);

  useEffect(() => {
    const currentDockingIndex = stopover?.dockings?.findIndex(
      (docking) => docking.id === currentDocking?.id
    );
    if (
      stopover?.dockings?.length &&
      stopover.dockings.length - 1 === currentDockingIndex
    ) {
      setIsLastDocking(true);
    } else {
      setIsLastDocking(false);
    }
    if (currentDocking) {
      dockingStateControl();
      if (isOperatingCargo) {
        operationStateControl();
      }
      undockingStateControl();
      staymentStateControl();
    }
  }, [currentDocking]);

  useEffect(() => {
    staymentStateControl();
  }, [isLastDocking]);

  // Controls docking states
  function dockingStateControl() {
    if (currentDocking) {
      if (currentDocking?.pilot_expected_on_board) {
        if (currentDocking.purpose === 'DOCK_IN_BERTH') {
          setStatus('RELEASE_DOCKING');
        }
      } else {
        setStatus('PILOT_EXPECTED_ON_BOARD_DOCKING');
      }
      if (
        currentDocking.docking_manoeuvre?.manoeuvre_release &&
        currentDocking.docking_manoeuvre?.manoeuvre_release !== 'PENDING'
      ) {
        setStatus('START_MANOEUVRE');
      }
      if (currentDocking.status === 'IN_DOCKING_MANOEUVRE') {
        setStatus('TIE');
      }
      if (currentDocking.status === 'DOCKING') {
        setStatus('END_MANOEUVRE');
      }
      if (currentDocking.status === 'DOCKED') {
        if (isOperatingCargo) {
          setStatus('RELEASE_OPERATION');
        } else {
          setStatus('RELEASE_UNDOCKING');
        }
      }
    }
  }

  // Controls operation states
  function operationStateControl() {
    if (currentDocking) {
      if (
        currentDocking.status === 'DOCKED' &&
        (currentDocking.operation_summary?.operation_release === null ||
          currentDocking.operation_summary?.operation_release === 'PENDING')
      ) {
        return setStatus('RELEASE_OPERATION');
      }
      if (
        currentDocking.status === 'DOCKED' &&
        currentDocking.operation_summary?.operation_release !== null &&
        currentDocking.operation_summary?.operation_release !== 'PENDING'
      ) {
        setStatus('START_OPERATION');
      }
      if (
        currentDocking.operation_summary?.real_start &&
        !currentDocking.operation_summary?.real_finish
      ) {
        setStatus('END_OPERATION');
      }
      if (
        currentDocking.operation_summary?.real_start &&
        currentDocking.operation_summary?.real_finish &&
        currentDocking.status === 'OPERATION_FINISHED'
      ) {
        setStatus('RELEASE_UNDOCKING');
        if (!currentDocking?.pilot_expected_on_board_undocking) {
          setStatus('PILOT_EXPECTED_ON_BOARD_UNDOCKING');
        }
      }
    }
  }

  // Controls undocking states
  function undockingStateControl() {
    if (currentDocking) {
      if (
        currentDocking.status === 'DOCKED' &&
        currentDocking.operation_summary?.real_finish
      ) {
        if (currentDocking?.pilot_expected_on_board_undocking) {
          if (isOperatingCargo) {
            if (currentDocking.operation_summary?.real_finish) {
              setStatus('RELEASE_UNDOCKING');
            }
          }
        } else if (status === 'NONE') {
          setStatus('PILOT_EXPECTED_ON_BOARD_UNDOCKING');
        }
      }
      if (
        currentDocking.undocking_manoeuvre?.manoeuvre_release &&
        currentDocking.undocking_manoeuvre?.manoeuvre_release !== 'PENDING'
      ) {
        setStatus('UNDOCK');
      }
      if (currentDocking.status === 'IN_UNDOCKING_MANOEUVRE') {
        setStatus('UNDOCK');
      }
      if (currentDocking.status === 'UNDOCKING') {
        setStatus('END_UNDOCKING');
      }
      if (currentDocking.status === 'UNDOCKED') {
        setStatus('END_OF_UNDOCKING_MANOEUVRE');
      }
    }
  }

  // Controls stayment states
  function staymentStateControl() {
    if (currentDocking) {
      if (stopover?.port_stay_finished) {
        setStatus('STAYMENT_ENDED');
      } else if (
        // currentDocking.purpose !== 'DOCK_IN_BERTH' ||
        ((currentDocking.status === 'END_OF_UNDOCKING_MANOEUVRE' ||
          !isNullOrUndefined(currentDocking.archived_at)) &&
          isLastDocking) ||
        (currentDocking.status === 'DONE' &&
          currentDocking.operation_summary?.real_finish)
      ) {
        setStatus('END_STAYMENT');
      } else if (
        currentDocking.status === 'WAITING_PRINCIPAL_DEFINITION' ||
        currentDocking.status === 'WAITING_FOR_ANCHORING_SERVICES_TO_END'
      ) {
        setStatus('END_ANCHORING');
      }
    }
  }

  // Docking
  function pilotExpectedOnBoardDocking() {
    setIsVisibleDockingActionsModal(false);
    setStatus('RELEASE_DOCKING');
    setIsVisibleDockingActionsModal(true);
  }

  function releaseDocking(releasedWithPendings?: boolean) {
    setIsVisibleDockingActionsModal(false);
    setStatus('START_MANOEUVRE');
    if (releasedWithPendings) {
      setWarningAlertMessage('Atracação liberada com pendências.');
    } else {
      setSuccessAlertMessage('Atracação liberada');
    }
  }

  function startManoeuvre() {
    setIsVisibleDockingActionsModal(false);
    setStatus('TIE');
    setSuccessAlertMessage('Realizando manobra de atracação.');
  }

  function startDocking() {
    setIsVisibleDockingActionsModal(false);
    setStatus('END_MANOEUVRE');
    setSuccessAlertMessage('Atracação iniciada com sucesso.');
  }

  function endDocking() {
    setIsVisibleDockingActionsModal(false);
    if (isOperatingCargo) {
      setStatus('RELEASE_OPERATION');
    } else if (!currentDocking?.pilot_expected_on_board_undocking) {
      setStatus('PILOT_EXPECTED_ON_BOARD_UNDOCKING');
    } else {
      setStatus('RELEASE_UNDOCKING');
    }
    setSuccessAlertMessage('Atracação finalizada com sucesso.');
  }

  // Operation
  function releaseOperation() {
    setIsVisibleOperationActionsModal(false);
    setStatus('START_OPERATION');
    setSuccessAlertMessage('Operação liberada.');
  }

  function startOperation() {
    setIsVisibleOperationActionsModal(false);
    setStatus('END_OPERATION');
    setSuccessAlertMessage('Operação iniciada com sucesso.');
  }

  function endOperation() {
    setIsVisibleOperationActionsModal(false);
    if (!currentDocking?.pilot_expected_on_board_undocking) {
      setStatus('PILOT_EXPECTED_ON_BOARD_UNDOCKING');
    } else {
      setStatus('RELEASE_UNDOCKING');
    }
    setSuccessAlertMessage('Operação finalizada com sucesso.');
  }

  // Undocking
  function pilotExpectedOnBoardUndocking() {
    setIsVisibleDockingActionsModal(false);
    setStatus('RELEASE_UNDOCKING');
    setIsVisibleDockingActionsModal(true);
  }

  function releaseUndocking() {
    setIsVisibleUndockingActionsModal(false);
    setStatus('UNDOCK');
    setSuccessAlertMessage('Desatracação liberada.');
  }

  function startUndocking() {
    setIsVisibleUndockingActionsModal(false);
    setStatus('END_UNDOCKING');
    setSuccessAlertMessage('Desatracação iniciada com sucesso.');
  }

  function pilotLeaveOnBoardUndocking() {
    setIsVisibleUndockingActionsModal(false);
    if (isLastDocking) {
      setStatus('END_STAYMENT');
    } else {
      setStatus('RELEASE_DOCKING');
    }
    setSuccessAlertMessage(
      'Fim da manobra de desatracação realizada com sucesso.'
    );
  }

  function endUndocking() {
    setIsVisibleUndockingActionsModal(false);
    setStatus('END_OF_UNDOCKING_MANOEUVRE');
    setSuccessAlertMessage('Desatracação finalizada com sucesso.');
  }

  // Stayment
  function endStayment() {
    setIsVisibleStaymentActionsModal(false);
    setStatus('STAYMENT_ENDED');
  }
  return (
    <>
      <DockingActionsModal
        isVisible={isVisibleDockingActionsModal}
        setIsVisible={setIsVisibleDockingActionsModal}
        startManoeuvre={startManoeuvre}
        releaseDocking={releaseDocking}
        startDocking={startDocking}
        endDocking={endDocking}
        stopover={stopover}
        currentDocking={currentDocking}
        updateDocking={patchDocking}
        status={status}
        isLoadingUpdateDocking={isLoadingUpdateDocking}
        pilotExpectedOnBoard={pilotExpectedOnBoardDocking}
      />
      <OperationActionsModal
        isVisible={isVisibleOperationActionsModal}
        setIsVisible={setIsVisibleOperationActionsModal}
        releaseOperation={releaseOperation}
        startOperation={startOperation}
        endOperation={endOperation}
        stopover={stopover}
        currentDocking={currentDocking}
        updateDocking={patchDocking}
        status={status}
        isLoadingUpdateDocking={isLoadingUpdateDocking}
      />
      <UndockingActionsModal
        isVisible={isVisibleUndockingActionsModal}
        setIsVisible={setIsVisibleUndockingActionsModal}
        releaseUndocking={releaseUndocking}
        startUndocking={startUndocking}
        endUndocking={endUndocking}
        pilotExpectedOnBoard={pilotExpectedOnBoardUndocking}
        pilotLeaveOnBoardUndocking={pilotLeaveOnBoardUndocking}
        stopover={stopover}
        currentDocking={currentDocking}
        updateDocking={patchDocking}
        status={status}
        isLoadingUpdateDocking={isLoadingUpdateDocking}
      />
      <StaymentActionsModal
        isVisible={isVisibleStaymentActionsModal}
        setIsVisible={setIsVisibleStaymentActionsModal}
        stopover={stopover}
        currentDocking={currentDocking}
        updateStopover={patchStopover}
        endStayment={endStayment}
        status={status}
      />
      <Col span={18}>
        <Space size={12} style={{ width: '100%' }}>
          {currentDocking?.docking_goal &&
          (status === 'RELEASE_DOCKING' ||
            status === 'PILOT_EXPECTED_ON_BOARD_DOCKING') ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setIsVisibleDockingActionsModal(true)}
              disabled={stopover?.current_status === 'CANCELED'}
            >
              Liberar atracação
            </Button>
          ) : status === 'START_MANOEUVRE' ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setIsVisibleDockingActionsModal(true)}
              disabled={stopover?.current_status === 'CANCELED'}
            >
              Manobrar
            </Button>
          ) : status === 'RELEASE_OPERATION' ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setIsVisibleOperationActionsModal(true)}
              disabled={stopover?.current_status === 'CANCELED'}
            >
              Liberar operação
            </Button>
          ) : status === 'RELEASE_UNDOCKING' ||
            status === 'PILOT_EXPECTED_ON_BOARD_UNDOCKING' ? (
            <Button
              type="primary"
              size="small"
              onClick={() => setIsVisibleUndockingActionsModal(true)}
              disabled={stopover?.current_status === 'CANCELED'}
            >
              Liberar desatracação
            </Button>
          ) : null}
          <Button
            size="small"
            onClick={() => openBreakdownOccurrenceDrawer(true)}
            disabled={stopover?.current_status === 'CANCELED'}
          >
            Avarias e ocorrências
          </Button>
          <Button
            size="small"
            onClick={() => openStopoverDrawer(true)}
            disabled={stopover?.current_status === 'CANCELED'}
          >
            Visualizar programação
          </Button>
        </Space>
      </Col>
      <Col
        span={6}
        style={{ justifyContent: 'flex-end', display: 'flex', height: '90px' }}
      >
        {status === 'TIE' ? (
          <Button
            type="primary"
            disabled={
              status !== 'TIE' || stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleDockingActionsModal(true)}
            size="large"
          >
            Amarrar
          </Button>
        ) : status === 'END_MANOEUVRE' ? (
          <Button
            type="primary"
            disabled={
              status !== 'END_MANOEUVRE' ||
              stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleDockingActionsModal(true)}
            size="large"
          >
            Finalizar manobra de atracação
          </Button>
        ) : status === 'START_OPERATION' ? (
          <Button
            type="primary"
            disabled={
              status !== 'START_OPERATION' ||
              stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleOperationActionsModal(true)}
            size="large"
          >
            Iniciar operação
          </Button>
        ) : status === 'END_OPERATION' ? (
          <Button
            type="primary"
            disabled={
              status !== 'END_OPERATION' ||
              stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleOperationActionsModal(true)}
            size="large"
          >
            Finalizar operação
          </Button>
        ) : status === 'UNDOCK' ? (
          <Button
            type="primary"
            disabled={
              status !== 'UNDOCK' || stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleUndockingActionsModal(true)}
            size="large"
          >
            Desatracar
          </Button>
        ) : status === 'END_UNDOCKING' ? (
          <Button
            type="primary"
            disabled={
              status !== 'END_UNDOCKING' ||
              stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleUndockingActionsModal(true)}
            size="large"
          >
            Finalizar desatracação
          </Button>
        ) : status === 'END_OF_UNDOCKING_MANOEUVRE' ? (
          <Button
            type="primary"
            disabled={
              status !== 'END_OF_UNDOCKING_MANOEUVRE' ||
              stopover?.current_status === 'CANCELED'
            }
            onClick={() => setIsVisibleUndockingActionsModal(true)}
            size="large"
          >
            Finalizar manobra de desatracação
          </Button>
        ) : status === 'END_STAYMENT' ? (
          <Button
            type="primary"
            size="large"
            onClick={() => setIsVisibleStaymentActionsModal(true)}
            disabled={stopover?.current_status === 'CANCELED'}
          >
            Finalizar estadia
          </Button>
        ) : null}
      </Col>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        {(isLoadingUpdateDocking || isLoadingUpdateStopover) && (
          <LoadingOutlined style={{ marginTop: '20px' }} />
        )}
        {isSuccessUpdateDocking && successAlertMessage && (
          <Expire type="success" message={successAlertMessage} />
        )}
        {isSuccessUpdateDocking && warningAlertMessage && (
          <Expire type="warning" message={warningAlertMessage} />
        )}
        {isSuccessUpdateStopover && (
          <Expire type="success" message="Estadia finalizada com sucesso" />
        )}
      </div>
      {status === 'END_ANCHORING' && (
        <Alert
          style={{ marginTop: '10px' }}
          type="info"
          showIcon
          message={
            !isLastDocking
              ? 'Para iniciar a atracação primeiro finalize o fundeio em andamento'
              : 'Para finalizar a estadia primeiro finalize o fundeio em andamento'
          }
        />
      )}
    </>
  );
}

export type BaseActionModalProps = {
  stopover?: StopoverType;
  isVisible?: boolean;
  setIsVisible: (show: boolean) => void;
  disabledDate?: (current: any) => boolean;
  currentDocking?: DockingType;
  status?: ActionsTypes;
  updateDocking?: (
    update: any,
    callback: (releasedWithPending?: boolean) => void,
    releasedWithPending?: boolean
  ) => Promise<
    | {
        data: any;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      }
  >;
  isLoadingUpdateDocking?: boolean;
  updateStopover?: (update: any) => void;
  // Docking
  isOnManoeuvre?: boolean;
  isReleasedDocking?: boolean;
  isStartedDocking?: boolean;
  isEndedDocking?: boolean;
  // Operation
  isReleasedOperation?: boolean;
  isStartedOperation?: boolean;
  isEndedOperation?: boolean;
  // Undocking
  isReleasedUndocking?: boolean;
  isStartedUndocking?: boolean;
  isEndedUndocking?: boolean;
  // Stayment
  isReleasedEndStayment?: boolean;
  isEndedStayment?: boolean;
};
