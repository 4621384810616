import { ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Alert, Skeleton, message } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';

import { Button, Collapse, Modal } from '../../../components';
import { useAddStopoverPspManifestsMutation } from '../../../services/stopoverApi';
import { CargoManifestType } from '../../../types';
import { removeSpecialCharacters } from '../../../utils/utils';
import { ManifestsImportedCollapse } from './manifestsImportedCollapse/ManifestsImportedCollapse';
import { ModalImportManifests } from './modalImportManifests/ModalImportManifests';

const { Panel } = Collapse;
const { confirm } = Modal;
type ManifestsDataProps = {
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  stopoverId: number;
  duv: string;
  uploadManifestsData?: CargoManifestType[];
  setUploadManifestsData: Dispatch<SetStateAction<CargoManifestType[]>>;
  isFetchingUploadManifests: boolean;
};

export function ManifestsData(props: ManifestsDataProps) {
  const {
    activeKey,
    onOpenPanel,
    stopoverId,
    duv,
    uploadManifestsData,
    setUploadManifestsData,
    isFetchingUploadManifests,
  } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const [
    addStopoverPspManifests,
    { isLoading: isLoadingAddStopoverPspManifests },
  ] = useAddStopoverPspManifestsMutation();

  function handleImportManifests() {
    setIsVisibleModal(true);
  }

  function handleImportPspManifests() {
    if (!duv) {
      message.error('A escala selecionada não tem DUV informado');
      return;
    }
    confirm({
      title: 'Tem certeza que deseja sincronizar os dados com o PSP?',
      content:
        'Ao sincronizar os dados anteriormente importados serão sobreescrevidos',
      width: 500,
      okText: 'Sincronizar',
      cancelText: 'Voltar',
      cancelButtonProps: { danger: true },
      onOk() {
        addStopoverPspManifests({
          id: stopoverId,
          duv: removeSpecialCharacters(duv) || '',
        });
      },
      onCancel() {},
      icon: <ExclamationCircleOutlined />,
    });
  }

  return (
    <>
      <ModalImportManifests
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        stopoverId={stopoverId}
      />
      <Collapse
        activeKey={activeKey}
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        <Panel forceRender header="Manifestos" key="manifests">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            {uploadManifestsData && uploadManifestsData.length > 0 && (
              <Alert
                showIcon
                type="warning"
                message="Caso deseje fazer alterações nos manifestos após a importação, é necessário anexar um novo arquivo ou alterar os dados manualmente no campo de edição de carga."
              />
            )}
            <Button
              type="primary"
              icon={<SyncOutlined />}
              onClick={handleImportPspManifests}
              style={{ width: '200px' }}
              loading={isLoadingAddStopoverPspManifests}
              disabled={isFetchingUploadManifests}
            >
              Sincronizar manifestos
            </Button>
            {isFetchingUploadManifests ? (
              <Skeleton active />
            ) : (
              uploadManifestsData?.map((cargoManifestType) => (
                <ManifestsImportedCollapse
                  key={cargoManifestType.id}
                  cargoManifest={cargoManifestType}
                  setUploadManifestsData={setUploadManifestsData}
                />
              ))
            )}
          </div>
        </Panel>
      </Collapse>
    </>
  );
}
