import { message, Row } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { FormItemInput, FormItemInputNumber } from '../../../components';
import { Form, FormItem } from '../../../components/antd/Form';
import { Select } from '../../../components/antd/Select';
import {
  useCreateCargoMutation,
  useUpdateCargoMutation,
  useDeleteCargoMutation,
} from '../../../services/cargoApi';
import { Cargo, CargoType, ProductGroup } from '../../../types';
import { cargoNatureList } from '../../../utils/lists';
import { ncmPattern } from '../../../utils/regexPatterns';
import { isNullOrUndefined } from '../../../utils/utils';
import {
  RegisterHeaderForm,
  RegisterHeaderFormProps,
} from '../registerHeaderForm';

export const FormItemsWrapper = styled.div`
  padding: 24px 16px;
`;

type CargoFormProps = RegisterHeaderFormProps & {
  cargosType: CargoType[] | [];
  onSaveCargo: (cargo: any) => void;
  selectedCargo: CargoType | undefined;
  setQueryPage: (page: number) => void;
  refetchCargoData: () => void;
  productGroups: Array<ProductGroup> | [];
};

export function CargoForm(props: CargoFormProps) {
  const [form] = Form.useForm();
  const { selectedCargo } = props;
  const [cargoNatureSelected, setCargoNatureSelected] = useState(0);
  const [productGroupSelected, setProductGroupSelected] = useState(0);

  const [
    createCargo,
    { isSuccess: isSuccessCreateCargo, isError: isErrorCreateCargo },
  ] = useCreateCargoMutation();
  const [updateCargo, { isSuccess: isSuccessUpdateCargo }] =
    useUpdateCargoMutation();

  const [
    deleteCargo,
    { isSuccess: isSuccessDeleteCargo, isError: isErrorDeleteCargo },
  ] = useDeleteCargoMutation();

  async function onDeleteCargo() {
    if (selectedCargo && selectedCargo.id) {
      props.setQueryPage(1);
      await deleteCargo(selectedCargo.id);
      props.refetchCargoData();
    }
  }

  async function handleFormSubmit(values: any) {
    const cargo: Cargo = {
      ...values,
    };
    if (selectedCargo && selectedCargo.id) {
      const updatedCargo = await updateCargo(cargo);
      if ('data' in updatedCargo) {
        props.onSaveCargo(updatedCargo.data);
      }
    } else {
      const createdCargo = await createCargo(cargo);
      if ('data' in createdCargo) {
        props.onSaveCargo(createdCargo.data);
      }
    }
  }

  useEffect(() => {
    if (isSuccessCreateCargo) {
      message.success('Mercadoria criada');
      props.onClose(null);
    } else if (isSuccessUpdateCargo) {
      message.success('Mercadoria atualizada');
      props.onClose(null);
    } else if (isErrorCreateCargo) {
      message.error(
        'Ocorreu um erro ao salvar a mercadoria, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessCreateCargo, isErrorCreateCargo, isSuccessUpdateCargo]);
  useEffect(() => {
    if (isSuccessDeleteCargo) {
      message.success('Mercadoria excluída');
      props.onClose(null);
    } else if (isErrorDeleteCargo) {
      message.error(
        'Ocorreu um erro ao excluir a Mercadoria, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessDeleteCargo, isErrorDeleteCargo]);

  function onChangeCargoNature(e: any) {
    if (isNullOrUndefined(e)) {
      setCargoNatureSelected(0);
    } else {
      setCargoNatureSelected(e);
    }
    setProductGroupSelected(0);
    form.setFieldsValue({ product_group: null, cargo_type: null });
  }

  function onChangeProductGroup(e: any) {
    if (isNullOrUndefined(e)) {
      setProductGroupSelected(0);
    } else {
      setProductGroupSelected(e);
      const productGroup = props.productGroups.find((p) => p.id === e);
      form.setFieldsValue({
        cargo_nature: productGroup?.nature_of_cargo.id,
      });
      setCargoNatureSelected(productGroup?.nature_of_cargo.id || 0);
    }

    form.setFieldsValue({ cargo_type: null });
  }

  function onChangeCargoType(e: any) {
    if (!isNullOrUndefined(e)) {
      const cargoTypeSelected = props.cargosType.find((p) => p.name === e);
      form.setFieldsValue({
        cargo_nature: cargoTypeSelected?.product_group.nature_of_cargo.id,
        product_group: cargoTypeSelected?.product_group.id,
      });
      setCargoNatureSelected(
        cargoTypeSelected?.product_group.nature_of_cargo.id || 0
      );
      setProductGroupSelected(cargoTypeSelected?.product_group.id || 0);
    }
  }

  return (
    <Form
      form={form}
      name="cargo"
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={selectedCargo}
    >
      <RegisterHeaderForm
        onClose={props.onClose}
        formName="cargo"
        allowDelete={!!selectedCargo?.id}
        onDelete={onDeleteCargo}
      >
        <div className="title">
          {selectedCargo ? 'EDITAR MERCADORIA' : 'NOVA MERCADORIA'}
        </div>
      </RegisterHeaderForm>
      <FormItemsWrapper>
        <FormItem noStyle name="id" />
        <FormItem name="cargo_nature" label="Natureza da carga">
          <Select allowClear onChange={(e) => onChangeCargoNature(e)}>
            {cargoNatureList.map((currentCargoNature) => {
              return (
                <Select.Option
                  key={currentCargoNature.id}
                  value={currentCargoNature.id}
                >
                  {currentCargoNature.name}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem name="product_group" label="Grupo de produto">
          <Select
            allowClear
            onChange={(e) => onChangeProductGroup(e)}
            notFoundContent="Selecione a natureza da carga"
          >
            {props.productGroups
              .filter((productGroup) =>
                cargoNatureSelected === 0
                  ? productGroup
                  : productGroup.nature_of_cargo.id === cargoNatureSelected
              )
              .map((productGroup) => {
                return (
                  <Select.Option key={productGroup.id} value={productGroup.id}>
                    {productGroup.name}
                  </Select.Option>
                );
              })}
          </Select>
        </FormItem>
        <FormItem
          name="cargo_type"
          label="Tipo da mercadoria"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select
            allowClear
            notFoundContent="Selecione o grupo de produto"
            onChange={(e) => onChangeCargoType(e)}
          >
            {props.cargosType
              .filter((cargoType) =>
                productGroupSelected === 0
                  ? cargoType
                  : cargoType.product_group.id === productGroupSelected
              )
              .map((cargoType) => {
                return (
                  <Select.Option key={cargoType.name} value={cargoType.name}>
                    {cargoType.name}
                  </Select.Option>
                );
              })}
          </Select>
        </FormItem>
        <Row gutter={32} align="top">
          <FormItemInput
            colSpan={12}
            name="name"
            label="Nome da mercadoria"
            required
          />
          <FormItemInput
            colSpan={12}
            name="ncm"
            label="NCM"
            rules={[{ pattern: ncmPattern, message: 'Informe um NCM válido' }]}
          />
        </Row>
        <Row gutter={32}>
          <FormItemInputNumber
            colSpan={12}
            name="density_min"
            label="Densidade mínima"
          />
          <FormItemInputNumber
            colSpan={12}
            name="density_max"
            label="Densidade máxima"
          />
        </Row>
      </FormItemsWrapper>
    </Form>
  );
}
