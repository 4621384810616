import { GenericQueryParamsType, GenericResponse, PumpingType } from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type PumpingResponse = GenericResponse & {
  results: Array<PumpingType> | [];
};

export type PumpingGenericQueryParamsType = GenericQueryParamsType & {
  initial_expected_start?: string;
  final_expected_start?: string;
  product_or_berth_or_terminal?: string;
};

const BASE_URL = 'operational/pumpings';

export const PumpingApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getPumpings: build.query<PumpingResponse, PumpingGenericQueryParamsType>({
      query: (params: PumpingGenericQueryParamsType) => {
        return {
          url: BASE_URL,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'Pumping' }],
    }),
    getPumpingById: build.query<PumpingType, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      providesTags: () => [{ type: 'Pumping' }],
    }),
    createPumping: build.mutation<PumpingType, PumpingType>({
      query: (pumping) => ({
        url: BASE_URL,
        method: 'POST',
        body: pumping,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Pumping' }]),
    }),
    updatePumping: build.mutation<PumpingType, PumpingType>({
      query: (pumping) => ({
        url: `${BASE_URL}/${pumping.id}`,
        method: 'PUT',
        body: pumping,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Pumping' }]),
    }),
    patchPumping: build.mutation<PumpingType, Partial<PumpingType>>({
      query: (pumping) => ({
        url: `${BASE_URL}/${pumping.id}`,
        method: 'PATCH',
        body: pumping,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Pumping' }]),
    }),
    deletePumping: build.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Pumping' }]),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPumpingsQuery,
  useGetPumpingByIdQuery,
  useCreatePumpingMutation,
  useUpdatePumpingMutation,
  useDeletePumpingMutation,
  usePatchPumpingMutation,
} = PumpingApi;
