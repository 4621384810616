import { EditFilled } from '@ant-design/icons';
import { Switch } from 'antd';
import { isObject } from 'lodash';

import { Button } from '../../../../../components';

export function columns(onSelectItem: (item: any, index: number) => void) {
  return [
    {
      title: 'Ativa?',
      key: 'active',
      width: '15%',
      render: (_: any, record: any) => (
        <Switch checked={record.active} disabled />
      ),
    },
    {
      title: 'Nome da linha',
      key: 'name',
      dataIndex: 'name',
      width: '25%',
    },
    {
      title: 'Terminal',
      key: 'terminal',
      dataIndex: ['storage_company_terminal', 'name'],
      render: (_: any, record: any) =>
        isObject(record.storage_company_terminal)
          ? record.storage_company_terminal.name
          : record.storage_company_terminal_name,
      width: '22%',
    },
    {
      title: 'Diâmetro',
      key: 'diameter',
      dataIndex: 'diameter',
      width: '20%',
      render: (_: any, record: any) => <span>{record.diameter}&quot;</span>,
    },
    {
      title: 'Vazão',
      key: 'max_flow_rate',
      dataIndex: 'max_flow_rate',
      width: '18%',
      render: (value: any) => (value ? `${value} m³/h` : ''),
    },
    {
      render: (_: any, record: any, index: number) => (
        <Button
          icon={<EditFilled />}
          style={{ float: 'right' }}
          onClick={(e: any) => {
            e.stopPropagation();
            onSelectItem(record, index);
          }}
        />
      ),
    },
  ];
}
