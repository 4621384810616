import { Col } from 'antd';
import { Rule } from 'antd/lib/form';

import { FormItem } from '../Form';
import { InputDUV } from '../InputDUV';

type FormItemDUVProps = {
  name: string | string[];
  colSpan?: number;
  required?: boolean;
  readOnly?: boolean;
  onBlur?: (value: any) => void;
  onChange?: (value: any) => void;
};

export function FormItemDUV(props: FormItemDUVProps) {
  const { name, colSpan, required, readOnly, onBlur, onChange } = props;

  const getRules = () => {
    const rules: Rule[] = [];

    rules.push({ required, message: 'Campo obrigatório' });
    return rules;
  };

  return (
    <Col span={colSpan || 24}>
      <FormItem label="DUV" name={name} rules={getRules()}>
        <InputDUV readOnly={readOnly} onBlur={onBlur} onChange={onChange} />
      </FormItem>
    </Col>
  );
}
