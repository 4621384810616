import { EditFilled, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { Dispatch, MouseEvent, SetStateAction, useEffect } from 'react';

import { Button, InnerCollapse, Title } from '../../../components';
import { usePatchFareMutation } from '../../../services/fareApi';
import {
  AllTableItemsType,
  AllTableType,
  FareType,
  TableType,
} from '../../../typesFinancial';
import { tableTypeList } from '../../../utils/lists';
import { formatNumberToLocale } from '../../../utils/utils';
import { FareItemsTable } from './fareItemsTable/FareItemsTable';

const { Panel: InnerPanel } = InnerCollapse;
const tablesList: TableType[] = [
  'table_one',
  'table_two',
  'table_three',
  'table_four',
  'table_five',
  'table_six',
  'table_seven',
  'table_eight',
  'table_nine',
];

type FaresTablesDataProps = {
  setSelectedItemTable: Dispatch<SetStateAction<AllTableItemsType>>;
  setItemTableViewDrawerVisible: Dispatch<SetStateAction<boolean>>;
  fare: FareType;
  onClickEdit: (
    e: MouseEvent<HTMLElement>,
    fare: FareType,
    isAddNewTableProp?: boolean,
    indexTable?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
    editTableProp?: AllTableType
  ) => void;
  setSelectedTable: (value: React.SetStateAction<number>) => void;
  title?: string;
  openStatementDrawer: (fare: FareType) => void;
  chargeId?: number;
};

export function FaresTablesData(props: FaresTablesDataProps) {
  const {
    setSelectedItemTable,
    setItemTableViewDrawerVisible,
    fare,
    onClickEdit,
    setSelectedTable,
    title,
    openStatementDrawer,
    chargeId,
  } = props;

  const [
    patchFare,
    { isSuccess: isSuccessPatchFare, isLoading: isLoadingPatchFare },
  ] = usePatchFareMutation();

  useEffect(() => {
    if (isSuccessPatchFare)
      message.success('Demonstrativo estornado com sucesso');
  }, [isSuccessPatchFare]);

  function handleViewItem(
    item: AllTableItemsType,
    tableIndex?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  ) {
    setItemTableViewDrawerVisible(true);
    setSelectedItemTable(item);
    if (tableIndex) setSelectedTable(tableIndex);
  }

  function getHeaderFareTable(typeTable: number, item: AllTableType) {
    const totalValue = item.discount
      ? item.total_value - (item.discount.total_value || 0)
      : item.total_value;
    return (
      <div className="header-fare-table">
        <strong style={{ fontWeight: '700' }}>Tabela {typeTable}</strong>
        <span style={{ fontWeight: '600' }}>
          ({formatNumberToLocale(totalValue, true)})
        </span>
        <span style={{ fontWeight: '400' }}>
          {tableTypeList.find((item) => item.index === typeTable)?.label}
        </span>
      </div>
    );
  }

  function getExtraHeaderFareTable(
    typeTable: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
    fare: FareType,
    item: AllTableType
  ) {
    return (
      <Button
        className="extra-fare-table-edit"
        icon={<EditFilled />}
        htmlType="button"
        type="text"
        onClick={(e) => onClickEdit(e, fare, false, typeTable, item)}
        disabled={fare.status === 'BILLED' || fare.status === 'REBILLED'}
      >
        Editar
      </Button>
    );
  }

  function addDiscountToItems(table: AllTableType) {
    if (table && !isEmpty(table.discount)) {
      const discount: AllTableItemsType = {
        id: 0,
        name: 'Descontos',
        value: table?.discount?.total_value || 0,
        discountItems: table.discount?.items,
      };
      return [...(table?.items || []), discount];
    }
    return table?.items || [];
  }

  function handleCancelStayment(fare: FareType) {
    if (fare.id && chargeId)
      patchFare({
        action: 'cancel_statement',
        id: fare.id,
        chargeId,
      });
  }

  function hasSomeTableItem(fare: FareType) {
    return (
      (fare.table_one?.items && fare.table_one.items.length > 0) ||
      (fare.table_two?.items && fare.table_two.items.length > 0) ||
      (fare.table_three?.items && fare.table_three.items.length > 0) ||
      (fare.table_five?.items && fare.table_five.items.length > 0) ||
      (fare.table_six?.items && fare.table_six.items.length > 0) ||
      (fare.table_seven?.items && fare.table_seven.items.length > 0) ||
      (fare.table_nine?.items && fare.table_nine.items.length > 0)
    );
  }

  return (
    <>
      {fare.statement ? (
        <Button
          className="button-demonstrative"
          onClick={() => handleCancelStayment(fare)}
          loading={isLoadingPatchFare}
        >
          Estornar demonstrativo
        </Button>
      ) : (
        <Button
          type="primary"
          className="button-demonstrative"
          onClick={() => openStatementDrawer(fare)}
          disabled={!hasSomeTableItem(fare)}
        >
          Gerar demonstrativo
        </Button>
      )}
      <div className="title-fares-by-table">
        <Title>
          {title || 'TARIFAS POR TABELA'} (
          {formatNumberToLocale(fare.total_value, true)})
        </Title>
        <div>
          {fare.statement && (
            <Button
              type="primary"
              icon={<EyeOutlined />}
              onClick={() => openStatementDrawer(fare)}
            >
              Ver demonstrativo
            </Button>
          )}
          <Button
            icon={<PlusOutlined />}
            onClick={(e) => onClickEdit(e, fare, true)}
            disabled={fare.status === 'BILLED' || fare.status === 'REBILLED'}
          >
            Adicionar tabela
          </Button>
        </div>
      </div>
      <InnerCollapse className="white-header" expandIconPosition="end">
        {tablesList.map((table: TableType, index) => {
          const tableIndex = index + 1;
          return (
            fare[table] &&
            tableIndex !== 0 &&
            (tableIndex === 1 ||
              tableIndex === 2 ||
              tableIndex === 3 ||
              tableIndex === 5 ||
              tableIndex === 6 ||
              tableIndex === 7 ||
              tableIndex === 8 ||
              tableIndex === 9) && (
              <InnerPanel
                key={`fareTableItems${tableIndex}`}
                header={getHeaderFareTable(
                  tableIndex,
                  fare[table] || ({} as AllTableType)
                )}
                extra={getExtraHeaderFareTable(
                  tableIndex,
                  fare,
                  fare[table] || ({} as AllTableType)
                )}
                className="panel-fare-items-table"
              >
                <FareItemsTable
                  fareItems={
                    addDiscountToItems(fare[table] || ({} as AllTableType)) ||
                    []
                  }
                  onEdit={handleViewItem}
                  tableIndex={tableIndex}
                />
              </InnerPanel>
            )
          );
        })}
      </InnerCollapse>
    </>
  );
}
