import {
  BaseObject,
  CompanyTypeType,
  GenericQueryParamsType,
  GenericResponse,
} from '../types';
import { formatCompanyTypeName } from '../utils/formatters';
import { getHeaders, tosApi } from './tosApi';

export type CompanyTypeTypeResponse = GenericResponse & {
  results: Array<CompanyTypeType> | [];
};

export const companyTypeApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanyTypes: build.query<
      CompanyTypeTypeResponse,
      GenericQueryParamsType | void
    >({
      query: (params: GenericQueryParamsType) => {
        return {
          url: `/core/companies/types`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: CompanyTypeTypeResponse) => {
        response.results.forEach((result) => {
          result.name = formatCompanyTypeName(result.id) || '';
        });
        return response;
      },
    }),
    getCompanyType: build.query<BaseObject & CompanyTypeType, number | null>({
      query: (id: number) => {
        return {
          url: `/core/companies/types/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetCompanyTypesQuery, useGetCompanyTypeQuery } =
  companyTypeApi;
