import { TreeSelect as AntTreeSelect } from 'antd';
import styled from 'styled-components';

import { SelectFieldStyle } from './Select';

export const TreeSelect = styled(AntTreeSelect)<{ $tagTheme?: string }>`
  ${SelectFieldStyle}
  ${(props) =>
    props.$tagTheme === 'neutral' &&
    `
      &.ant-select-multiple .ant-select-selection-item {
        background: var(--neutral_lightest); 
        border-color: var(--neutral_lighter);
        font-weight: 500;
      }
    `}
  & .ant-select-selector {
    border: 0px !important;
  }
  & * {
    box-shadow: 0 0 0 transparent !important;
  }
`;
