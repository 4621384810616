import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const LayoutContent = styled(Layout)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 90vh;
  border-radius: 4px;

  background-color: var(--neutral_lighter);
  padding: 12px;
  gap: 15px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  @media (max-width: 882px) {
    height: 88vh;
  }

  @media (max-height: 695px) {
    height: 89vh;
  }

  & .amount {
    display: inline-block;
    padding-right: 20px;
    color: #5f7b91;
    font-weight: normal;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 7px 0;

    & span {
      color: #000;
      display: inline-block;
      padding-right: 8px;
    }
  }
`;

export const LeftContent = styled(Content)`
  display: flex;
  flex-direction: column;
  width: 40%;
  max-width: 520px;

  @media (max-width: 1100px) {
    width: 100%;
    height: 50%;
    max-width: none;
    margin-block-end: 50px;
  }

  .search-stopover {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

export const RightContent = styled(Content)`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 1100px) {
    width: 100%;
    height: 50%;
  }
`;

export const FormWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #f5f5f5;
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
`;

export const ContentWrapper = styled.div`
  background: #fff;
`;
