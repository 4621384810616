import { PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useState } from 'react';

import { Button } from '../../../components/antd/Button';
import { useChangePagination } from '../../../hooks';
import {
  useGetCargosQuery,
  useGetProductGroupsQuery,
  useGetCargoTypeQuery,
} from '../../../services/cargoApi';
import {
  Cargo as CargoType,
  ProductGroup,
  CargoType as CargoTypeType,
} from '../../../types';
import { PanelHeader } from '../panelHeader';
import { RegistersDrawer } from '../registersDrawer';
import { RegistersTable } from '../registersTable';
import { CargoForm } from './cargoForm';
import { CargoTypeForm } from './cargoTypeForm';
import { columns } from './columns';
import { GroupForm } from './groupForm';

type FormToViewType = 'CARGO' | 'CARGO_TYPE' | 'PRODUCT_GROUP' | null;

export function Cargo() {
  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();
  const [searchQueryTerm, setSearchQueryTerm] = useState('');
  const [selectedCargo, setSelectedCargo] = useState<CargoType>(
    {} as CargoType
  );
  const {
    data: cargoData,
    isFetching: isFetchingCargoData,
    refetch: refetchCargoData,
  } = useGetCargosQuery({
    page: onChangePagination(),
    name: searchQueryTerm,
    page_size: 10,
  });

  const { data: cargoTypeData, refetch: refetchCargoTypeData } =
    useGetCargoTypeQuery();
  const { data: productGroupData, refetch: refetchProductGroupData } =
    useGetProductGroupsQuery();

  const [formToView, setFormToView] = useState<FormToViewType>(null);

  function onSaveCargo(savedCargo: CargoType) {
    if (savedCargo) {
      refetchCargoData();
    }
  }

  function onSaveProductGroup(savedProductGroup: ProductGroup) {
    if (savedProductGroup) {
      refetchProductGroupData();
    }
  }

  function onSaveCargoType(savedCargoType: CargoTypeType) {
    if (savedCargoType) {
      refetchCargoTypeData();
    }
  }
  function onSelectCargo(cargo: Record<string, any>) {
    setFormToView('CARGO');
    setSelectedCargo({
      name: cargo.name,
      density_min: cargo.density_min,
      density_max: cargo.density_max,
      cargo_type: cargo.cargo_type.name,
      id: cargo.id,
      product_group: cargo.cargo_type.product_group.name,
      cargo_nature: cargo.cargo_type.product_group.nature_of_cargo.id,
      ncm: cargo.ncm,
    });
  }
  function onCloseForm() {
    setFormToView(null);
    setSelectedCargo({} as CargoType);
  }

  function actionButtons() {
    return (
      <Space size={18}>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size="large"
          onClick={() => setFormToView('PRODUCT_GROUP')}
        >
          Novo grupo
        </Button>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size="large"
          onClick={() => setFormToView('CARGO_TYPE')}
        >
          Novo tipo
        </Button>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size="large"
          onClick={() => setFormToView('CARGO')}
        >
          Nova mercadoria
        </Button>
      </Space>
    );
  }

  return (
    <>
      <RegistersDrawer visible={formToView !== null} width={640}>
        {formToView === 'CARGO' && (
          <CargoForm
            onClose={onCloseForm}
            onSaveCargo={onSaveCargo}
            selectedCargo={selectedCargo}
            setQueryPage={setQueryPage}
            refetchCargoData={refetchCargoData}
            cargosType={cargoTypeData?.results || []}
            productGroups={productGroupData?.results || []}
          />
        )}
        {formToView === 'CARGO_TYPE' && (
          <CargoTypeForm
            onClose={setFormToView}
            productGroups={productGroupData?.results || []}
            onSaveCargoType={onSaveCargoType}
          />
        )}
        {formToView === 'PRODUCT_GROUP' && (
          <GroupForm
            onClose={setFormToView}
            onSaveProductGroup={onSaveProductGroup}
          />
        )}
      </RegistersDrawer>
      <PanelHeader
        panelName="mercadoria"
        actionButtons={actionButtons()}
        onSearch={setSearchQueryTerm}
      />
      <RegistersTable
        columns={columns}
        total={cargoData?.count}
        itemsPerPage={10}
        onSelectItem={onSelectCargo}
        dataSource={cargoData?.results || []}
        onChangePagination={onChangePagination}
        isLoading={isFetchingCargoData}
        queryPage={queryPage}
      />
    </>
  );
}
