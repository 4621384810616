import styled from 'styled-components';

export const Container = styled.div`
  .button-demonstrative {
    margin-top: 19px;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  .division-line {
    border-top: 1px solid var(--neutral_lighter);
  }

  .title-fares-by-table {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-block-start: 10px;
    margin-inline-start: 16px;
    margin-inline-end: 15px;

    div {
      display: flex;
      gap: 8px;
    }
  }

  .panel-fare-items-table {
    margin: 4px 8px;
    padding: 4px 10px;
    border: 1px solid var(--neutral_lighter);
  }

  .header-fare-company {
    display: flex;
    flex-direction: row;
    gap: 8px;

    span {
      font-size: 14px;
    }

    .strong {
      font-weight: 700;
    }

    .thin {
      font-weight: 400;
    }
  }

  .extra-header-fare-company {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;

    .fare-total {
      color: var(--suape-black);
      font-size: 12px;
      font-weight: 400;
      font-family: Consolas;
    }

    .button-edit-fare {
      font-size: 12px;
      margin-inline-end: 12px;
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .header-fare-table {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .extra-fare-table-edit {
    font-size: 12px;
    margin-right: 30px;
  }
`;
