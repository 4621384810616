import { PlusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useState, useEffect } from 'react';

import { Button } from '../../../components';
import { useChangePagination } from '../../../hooks';
import { useGetVesselsQuery } from '../../../services/vesselApi';
import { useLazyGetVesselRequestsUpdateQuery } from '../../../services/vesselRequestUpdateApi';
import { Vessel as VesselType } from '../../../types';
import { PanelHeader } from '../panelHeader';
import { RegistersDrawer } from '../registersDrawer';
import { RegistersTable } from '../registersTable';
import { columns } from './columns';
import { VesselForm } from './vesselForm';

export type VesselToForm = Omit<VesselType, 'ship_type'> & {
  ship_type?: string | null;
};

export function Vessel() {
  const [searchQueryTerm, setSearchQueryTerm] = useState('');

  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState<VesselToForm>(
    {} as VesselToForm
  );
  const [filteredVesselData, setFilteredVesselData] = useState<VesselType[]>();

  const { data: vesselData, isFetching: isFetchingVesselData } =
    useGetVesselsQuery({
      page: onChangePagination(),
      imo_or_name: searchQueryTerm,
    });

  const [
    getVesselRequestsUpdate,
    {
      data: vesselRequestsUpdateData,
      isLoading: isFetchingVesselRequestsUpdateData,
    },
  ] = useLazyGetVesselRequestsUpdateQuery();

  useEffect(() => {
    if (vesselData && !isEmpty(vesselData)) {
      setFilteredVesselData(
        vesselData.results.filter(
          (vessel: VesselType) => vessel.ship_type?.name !== 'PORT_TUGBOAT'
        )
      );
    }
  }, [vesselData]);

  function onSelectVessel(vessel: VesselType) {
    getVesselRequestsUpdate({ vessel: vessel.id, is_confirmed: false });
    setSelectedVessel({
      ...vessel,
      ship_type: vessel.ship_type?.name,
      forward_amidship:
        vessel.forward_amidship !== null
          ? vessel.forward_amidship === 'true'
            ? 'true'
            : 'false'
          : null,
    });
    setIsFormVisible(true);
  }

  function onCloseForm() {
    setIsFormVisible(false);
    setSelectedVessel({} as VesselToForm);
  }

  function actionButtons() {
    return (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => setIsFormVisible(true)}
      >
        Nova embarcação
      </Button>
    );
  }

  function searchData(value: any) {
    setQueryPage(1);
    setSearchQueryTerm(value);
  }

  return (
    <>
      <RegistersDrawer visible={isFormVisible} width={640}>
        <VesselForm
          name="vessel"
          onClose={() => onCloseForm()}
          selectedVessel={selectedVessel}
          vesselRequestsUpdateData={vesselRequestsUpdateData?.results || []}
          isLoadingVesselRequestsUpdateData={isFetchingVesselRequestsUpdateData}
        />
      </RegistersDrawer>
      <PanelHeader
        panelName="embarcação"
        actionButtons={actionButtons()}
        onSearch={searchData}
      />
      <RegistersTable
        columns={columns}
        total={vesselData?.count}
        dataSource={filteredVesselData || []}
        onChangePagination={onChangePagination}
        isLoading={isFetchingVesselData}
        showSizeChanger={false}
        onSelectItem={onSelectVessel}
        queryPage={queryPage}
      />
    </>
  );
}
