import { useState } from 'react';

import { ModalBlockNavigation } from '../components';

export function useBlockNavigation() {
  const [hasEdited, setHasEdited] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  function BlockNavigationModal(props: { name: string }) {
    const { name } = props;
    return (
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
      />
    );
  }
  return {
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
    BlockNavigationModal,
  };
}
