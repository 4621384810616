import styled from 'styled-components';

export const StopoverHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #fff;
  width: 100%;
  height: auto;
  z-index: 10;
  padding: 20px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
  & h1 {
    margin-bottom: 0px;
    font-size: 1.375rem;
    font-weight: bold;
  }
  & .switchLabel {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
  }
  & .stopoverCode {
    font-family: 'Consolas';
    color: var(--neutral_light);
  }
  & .expand {
    cursor: default;
    display: inline-block;
    float: right;
  }

  @media only screen and (max-width: 1280px) {
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const FormHeaderWrapper = styled.div`
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
  padding: 32px;
  display: flex;
  flex-direction: column;
  & .buttons {
    display: flex;
    justify-content: space-between;
    & .ant-btn-text {
      color: black !important;
    }
  }
  & .title {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
  }
`;
