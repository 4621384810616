import { WarningFilled } from '@ant-design/icons';
import { SetStateAction } from 'react';

import { Button, Modal } from '../../../components';
import { Vessel as VesselType } from '../../../types';

type ModalVesselPspDifferentProps = {
  showModalVesselPspDifferent: boolean;
  setShowModalVesselPspDifferent: (value: SetStateAction<boolean>) => void;
  setPspVesselInStopoverForm: () => void;
  vesselsDataResults: VesselType[] | undefined;
};

export function ModalVesselPspDifferent(props: ModalVesselPspDifferentProps) {
  const {
    showModalVesselPspDifferent,
    setShowModalVesselPspDifferent,
    setPspVesselInStopoverForm,
    vesselsDataResults,
  } = props;
  return (
    <Modal
      visible={showModalVesselPspDifferent}
      className="TOSWarningModal"
      closable
      onCancel={() => setShowModalVesselPspDifferent(false)}
      destroyOnClose
      title={
        <>
          <WarningFilled /> Atenção
        </>
      }
      footer={
        <>
          <Button danger onClick={() => setShowModalVesselPspDifferent(false)}>
            Voltar
          </Button>
          <Button type="primary" onClick={setPspVesselInStopoverForm}>
            Sobrescrever
          </Button>
        </>
      }
    >
      {vesselsDataResults && vesselsDataResults?.length > 0 && (
        <p style={{ marginBottom: '65px' }}>
          O número DUV informado é sobre a embarcação{' '}
          <span>{vesselsDataResults[0]?.name}</span>. Deseja sobrescrever a
          informação anteriormente informada?
        </p>
      )}
    </Modal>
  );
}
