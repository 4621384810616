import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Descriptions, Form, GenericDrawerHeader } from '../../../components';
import { TabItemType } from '../../../components/common/GenericDrawerHeader/GenericDrawerHeader';
import {
  useUpdateCargoStorageMerchantTotalMovimentedMutation,
  useUpdateMovimentationMutation,
} from '../../../services/movimentationApi';
import {
  OperationProductType,
  OperationalOperationType,
  Stopover,
  WorkPeriodType,
} from '../../../types';
import { shiftModeList } from '../../../utils/lists';
import {
  formatCargoStorageMerchantMovimentationToSave,
  formatOperationMovimentationToForm,
  formatOperationMovimentationToSave,
} from './formatters';
import { ProductOperationMovimentation } from './productOperationMovimentation';

type OperationMovimentationProps = {
  onCloseDrawer?: (show: boolean) => void;
  operation?: OperationalOperationType;
  selectedStopover?: Stopover;
};
export function OperationMovimentation(props: OperationMovimentationProps) {
  const [form] = Form.useForm();
  const { onCloseDrawer, operation, selectedStopover } = props;
  const [activeTab, setActiveTab] = useState<string | null | undefined>(
    `${operation?.work_periods[0].id}`
  );
  const [periodTabs, setperiodTabs] = useState<TabItemType[]>([]);
  const [selectedPeriod, setselectedPeriod] = useState<
    WorkPeriodType | undefined
  >(undefined);
  const [selectedPeriodIndex, setselectedPeriodIndex] = useState<number>(0);

  const [updateMovimentations, { isLoading: isLoadingUpdateMovimentations }] =
    useUpdateMovimentationMutation();

  const [updateCargoStorageMerchantTotalMovimented] =
    useUpdateCargoStorageMerchantTotalMovimentedMutation();

  function closeDrawer() {
    if (onCloseDrawer) {
      onCloseDrawer(false);
    }
  }

  useEffect(() => {
    if (operation) {
      setperiodTabs(
        operation?.work_periods.map((workPeriod, index) => {
          return {
            tabTitle: `Período ${index + 1}`,
            tabKey: `${workPeriod.id}`,
          } as TabItemType;
        })
      );
    }
  }, [operation]);

  useEffect(() => {
    if (activeTab) {
      setselectedPeriod(
        operation?.work_periods.find(
          (workPeriod) => workPeriod.id === parseInt(activeTab, 10)
        )
      );
      setselectedPeriodIndex(
        operation?.work_periods.findIndex(
          (workPeriod) => workPeriod.id === parseInt(activeTab, 10)
        ) || 0
      );
    }
  }, [activeTab, operation]);

  function saveMovimentation() {
    form
      .validateFields()
      .then(async (values) => {
        updateMovimentations({
          movimentations: formatOperationMovimentationToSave(
            values.work_periods
          ),
          operation_id: operation?.id,
        }).then(() => {
          updateCargoStorageMerchantTotalMovimented(
            formatCargoStorageMerchantMovimentationToSave(values)
          );
        });
      })
      .finally(() => {
        message.success('Movimentação atualizada com sucesso!', 5);
        closeDrawer();
      });
  }

  const tableFormListColumns = [
    { title: 'Turno', colSpan: 8 },
    { title: 'Ternos', colSpan: 8 },
    { title: 'Quantidade Operada', colSpan: 8 },
  ];

  return (
    <Form
      form={form}
      name="movimentation"
      initialValues={formatOperationMovimentationToForm(
        selectedStopover?.cargos_storage_merchant,
        operation
      )}
      onFinish={saveMovimentation}
    >
      <GenericDrawerHeader
        title={<strong>Registrar movimentação</strong>}
        formName="movimentation"
        tabs={periodTabs}
        showBackButton
        onBack={closeDrawer}
        onChangeActiveTab={setActiveTab}
        isLoadingSave={isLoadingUpdateMovimentations}
      />
      <div style={{ padding: '24px 16px' }}>
        <Descriptions column={2}>
          <Descriptions.Item label="Início do período">
            {moment(selectedPeriod?.start_of_period).format('DD/MM/yyyy')}
          </Descriptions.Item>
          <Descriptions.Item label="Modalidade dos turnos">
            {
              shiftModeList.find(
                (mode) => mode.id === selectedPeriod?.shift_mode
              )?.name
            }
          </Descriptions.Item>
        </Descriptions>
        {/* {operation?.items?.map((operationProduct, index) => {
          return selectedStopover?.cargos_storage_merchant?.map(
            (cargoStorageMerchant) => {
              return (
                <ProductOperationMovimentation
                  key={index}
                  cargoStorageMerchant={cargoStorageMerchant}
                  currentOperationProduct={operationProduct}
                  tableFormListColumns={tableFormListColumns}
                  selectedPeriod={selectedPeriod}
                  selectedPeriodIndex={selectedPeriodIndex}
                  form={form}
                />
              );
            }
          );
        })} */}
        {selectedStopover?.cargos_storage_merchant?.map(
          (cargoStorageMerchant, index) => {
            return (
              <ProductOperationMovimentation
                key={index}
                cargoStorageMerchant={cargoStorageMerchant}
                currentOperationProduct={
                  operation?.items?.find(
                    (operationItem) =>
                      operationItem.id === cargoStorageMerchant.item__id
                  ) || ({} as OperationProductType)
                }
                tableFormListColumns={tableFormListColumns}
                selectedPeriod={selectedPeriod}
                selectedPeriodIndex={selectedPeriodIndex}
                form={form}
              />
            );
          }
        )}
        {/* {operation?.items?.map((operationProduct, index) => {
          return operationProduct.operation_manifests?.map(
            (operationManifest) => {
              return operationManifest.merchants_ce?.map((merchantCE) => {
                return merchantCE.storages?.map((storage) => {
                  return storage.cargos_storage?.map((cargoStorage) => {
                    return (
                      <ProductOperationMovimentation
                        key={index}
                        cargoStorageMerchant={cargoStorage}
                        merchantCE={merchantCE}
                        currentOperationProduct={operationProduct}
                        tableFormListColumns={tableFormListColumns}
                        selectedPeriod={selectedPeriod}
                        selectedPeriodIndex={selectedPeriodIndex}
                        form={form}
                      />
                    );
                  });
                });
              });
            }
          );
        })} */}
      </div>
    </Form>
  );
}
