import { GenericResponse, NotificationType } from '../types';
import { getHeaders, tosApi } from './tosApi';

type NotificationResponse = GenericResponse & {
  results: Array<NotificationType> | [];
};

export const notificationApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    updateNotification: build.mutation<
      NotificationType,
      { id: number; read: boolean }
    >({
      query: (data) => {
        const { id, read } = data;
        return {
          url: `core/notifications/${id}`,
          method: 'PATCH',
          headers: getHeaders,
          body: { read },
        };
      },
    }),
    getNotificationsByUser: build.query<NotificationResponse, number>({
      query: (userId) => {
        return {
          url: `/core/users/${userId}/notifications`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useUpdateNotificationMutation,
  useLazyGetNotificationsByUserQuery,
} = notificationApi;
