import { Timeline } from 'antd';
import moment from 'moment';

import { FormatedStaymentLog, StaymentLogItemType } from '../../../types';
import { SubStatusWrapper } from './styles';

type StaymentSubStatusProps = {
  selectedStatus?: FormatedStaymentLog;
};

export function StaymentSubStatus(props: StaymentSubStatusProps) {
  const { selectedStatus } = props;

  function renderTimeLineItemLabel(logDate: string) {
    if (logDate) {
      return moment(logDate).format('DD/MM/YYYY HH:mm');
    }
    return (
      <span style={{ color: 'var(--error-medium)', fontStyle: 'italic' }}>
        Pendente
      </span>
    );
  }
  return (
    <SubStatusWrapper>
      {selectedStatus && (
        <div className="sub-status-title">
          <span className="title">{selectedStatus?.name}</span>
          <br />
          <span className="id">#{selectedStatus?.key}</span>
        </div>
      )}
      <Timeline mode="left">
        {selectedStatus?.log_items?.map(
          (subStatus: StaymentLogItemType, index: number) => {
            return (
              <Timeline.Item
                key={index}
                label={renderTimeLineItemLabel(subStatus.log_date)}
                color={
                  index + 1 === selectedStatus?.log_items?.length
                    ? '#09D4AB'
                    : '#5F7B91'
                } // Marca o último sub status como atual
              >
                {subStatus.name}
              </Timeline.Item>
            );
          }
        )}
      </Timeline>
    </SubStatusWrapper>
  );
}
