import {
  EditFilled,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Row, Col } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormList from 'antd/lib/form/FormList';
import confirm from 'antd/lib/modal/confirm';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import styled from 'styled-components';

import { Descriptions, DockingLogStatus, Drawer } from '../../../components';
import { Button } from '../../../components/antd/Button';
import { Collapse } from '../../../components/antd/Collapse';
import { FormItem } from '../../../components/antd/Form';
import { InputNumber } from '../../../components/antd/InputNumber';
import { Select } from '../../../components/antd/Select';
import { DatePicker } from '../../../components/common/datepicker/DatePicker';
import { FinancialOfficersTable } from '../../../components/common/financialOfficersTable/financialOfficersTable';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetOperatorsQuery } from '../../../services/stopoverApi';
import {
  Docking,
  FinancialTableType,
  Stopover,
  Vessel as VesselType,
} from '../../../types';
import {
  unitsList,
  operationTypes,
  purposeList,
  collapsePanelsListDocking,
} from '../../../utils/lists';
import { isNullOrUndefined } from '../../../utils/utils';
import { AnchoringForm } from '../../docking/anchoringForm';
import { berthingLabel } from '../../docking/berthingPredictionFieldLabel';
import { DockingDrawer } from '../../docking/DockingDrawer';

const { Panel } = Collapse;
const { Item } = Descriptions;

export const DockingsSection = styled(Collapse)`
  background: #fff;
  border-radius: 5px;

  & .title {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 18px;
    display: block;
  }
  & .ant-collapse {
    border-radius: 5px;
    border: 1px solid var(--neutral_lighter);
    overflow: hidden;
    margin-top: 16px;
    & .ant-collapse-header {
      background: #fff;
      font-size: 16px;
      color: var(--neutral_medium) !important;
      padding-left: 16px !important;
      & .ant-collapse-header-text {
        width: 100%;
      }
      & .docking-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        & .docking-title {
          margin-right: 10px;
          display: inline-block;
        }
        & .docking-id {
          font-family: Consolas;
          font-weight: normal;
          display: inline-block;
          margin-right: 10px;
        }
        & .icons {
          & div {
            padding: 5px 15px;
            display: inline-block;
            opacity: 0.6;
            transition: 300ms;
            &:hover {
              opacity: 1;
              color: var(--neutral_dark);
            }
          }
        }
      }
    }
    & .manoeuvre-title {
      font-size: 12px;
      color: var(--neutral_medium);
      font-weight: bold;
      margin-bottom: 16px;
    }
    & .ant-collapse-content-box {
      padding: 16px !important;
      & .docking-content-form {
        padding-left: 40px;
      }
      & .docking-product {
        border-top: 1px solid var(--neutral_lighter);
        padding-top: 16px;
        & .docking-product-title {
          font-size: 14px;
          color: var(--neutral_medium);
          font-weight: bold;
          margin-bottom: 16px;
        }
      }
    }
  }
`;

type FormItemsDockingsProps = {
  form: FormInstance;
  selectedStopover: Stopover;
  setSelectedStopover?: (stopover: Stopover) => void;
  selectedVessel: VesselType;
  readOnly: boolean;
  openCollapsePanels: string | string[];
  onOpenPanel: (panel: any) => void;
  allowedBerth: any[];
  allowedCargo: any[];
  // allowedOperators: any[];
};

export function FormItemsDockings(props: FormItemsDockingsProps) {
  const [selectedDocking, setSelectedDocking] = useState<Docking>(
    {} as Docking
  );
  const [selectedDockingPosition, setSelectedDockingPosition] = useState<
    number | null
  >(null);

  const [isDockingDrawerVisible, setIsDockingDrawerVisible] = useState(false);
  const [isVisibleAnchoringDrawer, setIsVisibleAnchoringDrawer] =
    useState(false);

  const { data: storages } = useGetCompaniesQuery({ company_type: 'STORAGE' });

  const { data: operators } = useGetOperatorsQuery();

  const [openCollapsePanelsDocking, setOpenCollapsePanelsDocking] = useState<
    string | string[]
  >(collapsePanelsListDocking);

  function dockingCollapseHeader(index: number) {
    const docking = props.form.getFieldValue(['dockings', index]);
    const isAnchoring =
      docking.purpose === 'ANCHORING_SERVICES' ||
      docking.purpose === 'ANCHORING_WAIT';
    return (
      <div className="docking-header-title">
        <div>
          <div className="docking-title">
            {isAnchoring ? 'Fundeio' : 'Atracação'} {index + 1}
          </div>
          {props.form.getFieldValue(['dockings', index, 'id']) && (
            <span className="docking-id">
              #{props.form.getFieldValue(['dockings', index, 'code'])}
            </span>
          )}
          {props.form.getFieldValue(['dockings', index, 'status']) && (
            <DockingLogStatus
              dockingLogStatus={props.form.getFieldValue([
                'dockings',
                index,
                'status',
              ])}
            />
          )}
        </div>
        {(!props.selectedStopover || !props.readOnly) && !isAnchoring ? (
          <div className="icons">
            {props.form.getFieldValue(['dockings', index, 'status']) !==
              'CANCELED' && (
              <>
                <div>
                  <Button
                    disabled={
                      props.form.getFieldValue([
                        'dockings',
                        index,
                        'status',
                      ]) === 'CANCELED'
                    }
                    danger
                    size="small"
                    onClick={(evt) => onCancelDocking(evt, index)}
                  >
                    Cancelar atracação
                  </Button>
                </div>
                <div onClick={(evt) => onEdit(evt, index)}>
                  <EditFilled />
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  }

  function onEdit(evt: any, indexToEdit: number) {
    evt.stopPropagation();
    setSelectedDocking(props.form.getFieldValue(['dockings', indexToEdit]));
    setSelectedDockingPosition(indexToEdit);
    setIsDockingDrawerVisible(true);
  }

  function onCancelDocking(evt: any, indexToCancel: number) {
    evt.stopPropagation();
    confirm({
      title: 'Deseja realmente cancelar esta atracação?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Depois que salvar a escala a atracação cancelada não poderá ser reativada',
      onOk() {
        cancelDocking(indexToCancel);
      },
      okText: 'Sim',
      cancelText: 'Não',
    });
  }

  function cancelDocking(indexToCancel: number): void {
    const canceledDocking = props.form.getFieldValue([
      'dockings',
      indexToCancel,
    ]);
    const allDockings: Docking[] = props.form.getFieldValue('dockings');

    const updatedDockings: Docking[] = allDockings.map((docking): Docking => {
      if (docking.index === canceledDocking.index) {
        return {
          ...docking,
          // status: 'CANCELED', TODO: CANCELAR
        };
      }
      return docking;
    });

    props.form.setFieldsValue({ dockings: updatedDockings });
  }

  function onBack() {
    setIsDockingDrawerVisible(false);
  }

  function berthingDuration(timeArray: Array<Moment | null>) {
    const expected_berthing = timeArray ? timeArray[0] : null;
    const expected_unberthing = timeArray ? timeArray[1] : null;
    if (expected_berthing && expected_unberthing) {
      return moment.duration(expected_unberthing.diff(expected_berthing));
    }
    return null;
  }

  function getFinancialTableItems(
    financial_table: Array<FinancialTableType>,
    index: number
  ) {
    if (!isNullOrUndefined(financial_table)) {
      return financial_table;
      // if (!isNullOrUndefined(financial_table[index])) {
      // }
    }
    return [] as FinancialTableType[];
  }

  return (
    <DockingsSection
      activeKey={props.openCollapsePanels}
      expandIconPosition="end"
      onChange={props.onOpenPanel}
    >
      <FormList name="dockings">
        {(fields, { add, remove }) => {
          return (
            <>
              {!props.readOnly && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    style={{ marginInlineEnd: '10px' }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setIsDockingDrawerVisible(true)}
                  >
                    Programar atracação
                  </Button>
                  <Button
                    type="ghost"
                    icon={<PlusOutlined />}
                    onClick={() => setIsVisibleAnchoringDrawer(true)}
                  >
                    Programar fundeio
                  </Button>
                </div>
              )}
              <DockingDrawer
                visible={isDockingDrawerVisible}
                setIsVisible={setIsDockingDrawerVisible}
                selectedStopover={props.selectedStopover}
                selectedVessel={props.selectedVessel}
                currentDockingKey={selectedDockingPosition}
                setCurrentDockingKey={setSelectedDockingPosition}
                selectedDocking={selectedDocking}
                setSelectedDocking={setSelectedDocking}
                width={800}
                stopoverForm={props.form}
                allowedBerth={props.allowedBerth}
                onAddDocking={add}
                onRemoveDocking={remove}
                onBack={() => onBack()}
                screen="STOPOVER"
                openCollapsePanels={openCollapsePanelsDocking}
                setOpenCollapsePanels={setOpenCollapsePanelsDocking}
              />
              <Drawer
                closable={false}
                visible={isVisibleAnchoringDrawer}
                width={820}
              >
                <AnchoringForm
                  selectedStopover={props.selectedStopover}
                  setSelectedStopover={props.setSelectedStopover}
                  selectedDocking={selectedDocking}
                  setSelectedDocking={setSelectedDocking}
                  onClose={() => setIsVisibleAnchoringDrawer(false)}
                  screen="STOPOVER"
                  onAddDocking={add}
                />
              </Drawer>
              {props.form.getFieldValue('dockings')?.length > 0 && (
                <Collapse
                  expandIconPosition="end"
                  defaultActiveKey={props.form
                    .getFieldValue('dockings')
                    .map((_docking: any, index: number) => `docking${index}`)}
                >
                  {fields.map((_field, index) => {
                    const isAnchoring =
                      props.form.getFieldValue(['dockings', index]).purpose ===
                        'ANCHORING_SERVICES' ||
                      props.form.getFieldValue(['dockings', index]).purpose ===
                        'ANCHORING_WAIT';
                    return (
                      <Panel
                        forceRender
                        header={dockingCollapseHeader(index)}
                        key={`docking${index}`}
                      >
                        {!isAnchoring && (
                          <div className="docking-content-form">
                            <Row align="bottom">
                              <Col span={12}>
                                <FormItem
                                  name={[index, 'purpose']}
                                  label="Finalidade da estadia"
                                >
                                  <Select disabled>
                                    {purposeList.map((purpose) => {
                                      return (
                                        <Select.Option
                                          key={purpose.id}
                                          value={purpose.id}
                                        >
                                          {purpose.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </FormItem>
                              </Col>
                              {props.form.getFieldValue([
                                'dockings',
                                index,
                                'purpose',
                              ]) === 'DOCK_IN_BERTH' && (
                                <Col span={12}>
                                  <FormItem
                                    name={[index, 'position']}
                                    label="Posição da atracação no berço"
                                  >
                                    <Select disabled>
                                      <Select.Option
                                        key="STARBOARD"
                                        value="STARBOARD"
                                      >
                                        Boroeste
                                      </Select.Option>
                                      <Select.Option
                                        key="PORT_SIDE"
                                        value="PORT_SIDE"
                                      >
                                        Bombordo
                                      </Select.Option>
                                    </Select>
                                  </FormItem>
                                </Col>
                              )}
                            </Row>
                            <Row align="bottom">
                              {props.form.getFieldValue([
                                'dockings',
                                index,
                                'purpose',
                              ]) === 'DOCK_IN_BERTH' && (
                                <Col span={12}>
                                  <FormItem
                                    name={[index, 'docking_in_line']}
                                    label="Atracação em linha?"
                                    tooltip="Dois navios no mesmo berço"
                                  >
                                    <Select disabled>
                                      <Select.Option
                                        key="FIRST_VESSEL"
                                        value="FIRST_VESSEL"
                                      >
                                        Sim, primeira embarcação
                                      </Select.Option>
                                      <Select.Option
                                        key="SECOND_VESSEL"
                                        value="SECOND_VESSEL"
                                      >
                                        Sim, segunda embarcação
                                      </Select.Option>
                                      <Select.Option key="NO" value="NO">
                                        Não
                                      </Select.Option>
                                    </Select>
                                  </FormItem>
                                </Col>
                              )}
                              <Col span={12}>
                                <FormItem
                                  label={berthingLabel(
                                    berthingDuration(
                                      props.form.getFieldValue([
                                        'dockings',
                                        index,
                                        'berthing',
                                      ])
                                    ),
                                    props.form.getFieldValue([
                                      'dockings',
                                      index,
                                      'purpose',
                                    ])
                                  )}
                                  name={[index, 'berthing']}
                                  tooltip="Previsão de Atracação/Desatracação"
                                >
                                  <DatePicker
                                    isRangePicker
                                    allowEmpty={[true, true]}
                                    disabled
                                    minuteStep={15}
                                  />
                                </FormItem>
                              </Col>
                            </Row>
                            <Row align="bottom">
                              <Col span={12}>
                                <FormItem
                                  name={[index, 'docking_place', 'tag']}
                                  label="Berço / Local de ancoragem"
                                  rules={[{ required: false }]}
                                >
                                  <Select
                                    notFoundContent="Selecione uma embarcação primeiro"
                                    disabled
                                  >
                                    {props.allowedBerth?.map((berth: any) => {
                                      return (
                                        <Select.Option
                                          key={berth.key}
                                          value={berth.tag}
                                        >
                                          {berth.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem
                                  label="Previsão de prático"
                                  name={[index, 'pilot']}
                                >
                                  <DatePicker
                                    isRangePicker
                                    allowEmpty={[true, true]}
                                    disabled
                                    minuteStep={15}
                                  />
                                </FormItem>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {props.form
                          .getFieldValue(['dockings', index, 'products'])
                          ?.map((product: any, productIndex: number) => {
                            return (
                              <div
                                key={productIndex}
                                className="docking-product"
                              >
                                <div className="docking-product-title">
                                  Mercadoria {productIndex + 1}
                                </div>
                                <div className="docking-content-form">
                                  <Row align="bottom">
                                    <Col span={8}>
                                      <FormItem
                                        name={[
                                          index,
                                          'products',
                                          productIndex,
                                          'cargo_type',
                                          'name',
                                        ]}
                                        label="Tipo de mercadoria"
                                      >
                                        <Select disabled>
                                          {props.allowedCargo?.map(
                                            (cargo_type: any) => {
                                              return (
                                                <Select.Option
                                                  key={cargo_type.name}
                                                  value={cargo_type.name}
                                                >
                                                  {cargo_type.name}
                                                </Select.Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormItem>
                                    </Col>
                                    <Col span={8}>
                                      <FormItem
                                        name={[
                                          index,
                                          'products',
                                          productIndex,
                                          'operator',
                                          'id',
                                        ]}
                                        label="Operador"
                                      >
                                        <Select disabled>
                                          {operators?.results?.map(
                                            (operator: any) => {
                                              return (
                                                <Select.Option
                                                  key={operator.id}
                                                  value={operator.id}
                                                >
                                                  {operator.name}
                                                </Select.Option>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormItem>
                                    </Col>
                                    <Col span={8}>
                                      <FormItem
                                        name={[
                                          index,
                                          'products',
                                          productIndex,
                                          'direct_discharge',
                                        ]}
                                        label="Descarga direta?"
                                        tooltip="Não será necessário armazenamento"
                                      >
                                        <Select disabled>
                                          <Select.Option
                                            key="true"
                                            value="true"
                                          >
                                            Sim
                                          </Select.Option>
                                          <Select.Option
                                            key="false"
                                            value="false"
                                          >
                                            Não
                                          </Select.Option>
                                        </Select>
                                      </FormItem>
                                    </Col>
                                  </Row>
                                  <Row align="bottom">
                                    <Col span={8}>
                                      <FormItem
                                        name={[
                                          index,
                                          'products',
                                          productIndex,
                                          'quantity',
                                        ]}
                                        label="Quantidade"
                                      >
                                        <InputNumber
                                          readOnly
                                          formatter={(value) =>
                                            `${value} ${
                                              unitsList.find(
                                                (unit) =>
                                                  unit.value === product.unit
                                              )?.abbreviation
                                            }`
                                          }
                                        />
                                      </FormItem>
                                    </Col>
                                    <Col span={8}>
                                      <FormItem
                                        name={[
                                          index,
                                          'products',
                                          productIndex,
                                          'operation_type',
                                        ]}
                                        label="Tipo de operação"
                                      >
                                        <Select disabled>
                                          {operationTypes.map((operation) => {
                                            return (
                                              <Select.Option
                                                key={operation.value}
                                                value={operation.value}
                                              >
                                                {operation.name}
                                              </Select.Option>
                                            );
                                          })}
                                        </Select>
                                      </FormItem>
                                    </Col>
                                    <Col span={8}>
                                      {product.direct_discharge === 'false' && (
                                        <FormItem
                                          name={[
                                            index,
                                            'products',
                                            productIndex,
                                            'tanking_confirmed',
                                          ]}
                                          label="Armazenamento confirmado?"
                                        >
                                          <Select disabled>
                                            <Select.Option
                                              key="true"
                                              value="true"
                                            >
                                              Sim
                                            </Select.Option>
                                            <Select.Option
                                              key="false"
                                              value="false"
                                            >
                                              Não
                                            </Select.Option>
                                          </Select>
                                        </FormItem>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row align="bottom">
                                    <Col span={16} />
                                    <Col span={8}>
                                      {product.direct_discharge === 'false' && (
                                        <FormItem
                                          name={[
                                            index,
                                            'products',
                                            productIndex,
                                            'storage',
                                            'id',
                                          ]}
                                          label="Local de armazenamento"
                                        >
                                          <Select disabled>
                                            {storages?.results?.map(
                                              (storage: any) => {
                                                return (
                                                  <Select.Option
                                                    key={storage.id}
                                                    value={storage.id}
                                                  >
                                                    {storage.name}
                                                  </Select.Option>
                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormItem>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            );
                          })}
                        {!isAnchoring && (
                          <div className="docking-product">
                            <div className="docking-product-title">
                              Responsáveis financeiros
                            </div>
                            <FinancialOfficersTable
                              docking={
                                props.selectedStopover &&
                                props.selectedStopover.dockings &&
                                props.selectedStopover.dockings[index]
                              }
                            />
                          </div>
                        )}
                        {isAnchoring && (
                          <>
                            <Row align="bottom">
                              <Col span={12}>
                                <FormItem
                                  name={[index, 'docking_place', 'tag']}
                                  label="Fundeadouro"
                                  rules={[{ required: false }]}
                                >
                                  <Select
                                    notFoundContent="Selecione uma embarcação primeiro"
                                    disabled
                                  >
                                    {props.allowedBerth?.map((berth: any) => {
                                      return (
                                        <Select.Option
                                          key={berth.key}
                                          value={berth.tag}
                                        >
                                          {berth.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem
                                  name={[index, 'purpose']}
                                  label="Finalidade do fundeio"
                                >
                                  <Select disabled>
                                    {purposeList.map((purpose) => {
                                      return (
                                        <Select.Option
                                          key={purpose.id}
                                          value={purpose.id}
                                        >
                                          {purpose.name}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </FormItem>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <FormItem
                                  label="Previsão de entrada no fundeio"
                                  name={[index, 'expected_anchoring']}
                                >
                                  <DatePicker disabled minuteStep={15} />
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem
                                  label="Previsão de saída do fundeio"
                                  name={[index, 'expected_unanchoring']}
                                >
                                  <DatePicker disabled minuteStep={15} />
                                </FormItem>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            </>
          );
        }}
      </FormList>
    </DockingsSection>
  );
}
