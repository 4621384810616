import { Descriptions, Collapse } from '../../../components';
import { panelHeaderTitle } from '../../../components/common/utils';
import { Stopover as StopoverType } from '../../../types';
import {
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../../utils/utils';

const { Item } = Descriptions;
const { Panel } = Collapse;

type StaymenyDescriptionProps = {
  selectedStopover: StopoverType;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  onClickEdit: (evt: any, indexToEdit: number) => void;
};

export function StaymentDescription(props: StaymenyDescriptionProps) {
  const { selectedStopover, activeKey, onOpenPanel, onClickEdit } = props;

  return (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel
        forceRender
        header="Dados da estadia"
        key="stayment_data"
        extra={panelHeaderTitle(selectedStopover.updated_at, onClickEdit, 0)}
      >
        <Descriptions column={2}>
          <Item label="Hora real da chegada (ATA)">
            {createDateStringPtBr(selectedStopover.actual_time_of_arrival)}
          </Item>
          <Item label="Estadia no porto finalizada em">
            {createDateStringPtBr(selectedStopover.port_stay_finished)}
          </Item>
        </Descriptions>
      </Panel>
    </Collapse>
  );
}
