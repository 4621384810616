import { FormInstance } from 'antd/lib/form';

import { Drawer } from '../../../../../components';
import {
  BerthMaintenance,
  DockingPlace,
  DockingPlaceForm,
} from '../../../../../types';
import { BerthMaintenanceForm } from './berthMaintenanceForm';

type BerthMaintenanceDrawerProps = {
  visibleDrawer: boolean;
  form: FormInstance<DockingPlaceForm>;
  selectedBerth: DockingPlace;
  selectedBerthMaintenance: BerthMaintenance;
  onBack: () => void;
  isEdit: boolean;
  addMaintenanceToForm?: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
  removeMaintenanceFromForm?: (index: number | number[]) => void;
  berthMaintenanceRowIndex?: number;
  isDockingWindowContext?: boolean;
};

export function BerthMaintenanceDrawer(props: BerthMaintenanceDrawerProps) {
  const {
    visibleDrawer,
    form,
    selectedBerth,
    selectedBerthMaintenance,
    onBack,
    isEdit,
    addMaintenanceToForm,
    removeMaintenanceFromForm,
    berthMaintenanceRowIndex,
    isDockingWindowContext,
  } = props;
  return (
    <Drawer
      visible={visibleDrawer}
      placement="right"
      width={720}
      closable={false}
      onClose={() => onBack()}
      destroyOnClose
    >
      <BerthMaintenanceForm
        berthForm={form}
        isEdit={isEdit}
        selectedBerth={selectedBerth}
        selectedBerthMaintenance={selectedBerthMaintenance}
        addMaintenanceToForm={addMaintenanceToForm}
        removeMaintenanceFromForm={removeMaintenanceFromForm}
        onBack={onBack}
        berthMaintenanceRowIndex={berthMaintenanceRowIndex}
        isDockingWindowContext={isDockingWindowContext}
      />
    </Drawer>
  );
}
