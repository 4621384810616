import styled from 'styled-components';

export const CompanyCollapseTitle = styled.div`
  & .code {
    color: var(--neutral_medium);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
    display: inline-block;
  }
`;

export const MerchantCEItem = styled.div`
  padding: 12px 0px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  cursor: pointer;
  & .title {
    color: var(--marine_dark);
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }
  & .subtitile {
    color: var(--neutral_light);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 0px;
  }
`;
