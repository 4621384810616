import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { message, Row } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  Button,
  Collapse,
  Form,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
  ModalBlockNavigation,
  Select,
} from '../../../components';
import { useGetProductGroupsQuery } from '../../../services/cargoApi';
import {
  useCreateOperationMutation,
  useUpdateOperationMutation,
} from '../../../services/operationApi';
import { useGetStopoversResumeQuery } from '../../../services/stopoverApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import {
  OperationalOperationType,
  OperationProductAndCraneType,
  OperationProductType,
  Stopover as StopoverType,
} from '../../../types';
import { formatOperationToSave } from '../../../utils/formatters';
import { booleanList, cargoNatureList } from '../../../utils/lists';
import { createDateStringPtBr } from '../../../utils/utils';
import { FormItemsProduct } from './formItemsProduct/FormItemsProduct';
import { OperationProductsEditDrawer } from './operationProductsEditDrawer/OperationProductsEditDrawer';
import { OperatorAreaFormHeader } from './styles';

type OperationsFormProps = {
  operationFormType: 'EDIT' | 'CREATE';
  selectedStopover: StopoverType;
  blockNavigate: boolean;
  setBlockNavigate: (blockNavigate: boolean) => void;
  setHasEdited: (hasEdited: boolean) => void;
  setSelectedStopover: (stopover: StopoverType) => void;
  onCloseDrawer: () => void;
  setIsVisible: (value: boolean) => void;
};
const { Panel } = Collapse;
export function OperationsForm(props: OperationsFormProps) {
  const {
    operationFormType,
    selectedStopover,
    blockNavigate,
    setBlockNavigate,
    setHasEdited,
    setSelectedStopover,
    onCloseDrawer,
    setIsVisible,
  } = props;

  const [isEditMode] = useState(operationFormType === 'EDIT');
  const [form] = Form.useForm();
  const [filterStopover, setFilterStopover] = useState(0);
  const { data: stopoveresData, isLoading: isLoadingStopoveresData } =
    useGetStopoversResumeQuery();

  const { data: inspectionTypesData } = useGetSystemParamByNameQuery({
    name: 'INSPECTION_TYPE',
  });
  const [createOperation, { isSuccess: isSucessCreateOperation }] =
    useCreateOperationMutation();

  const [updateOperation, { isSuccess: isSucessUpdateOperation }] =
    useUpdateOperationMutation();

  const { data: productGroupData } = useGetProductGroupsQuery({
    nature_of_cargo: selectedStopover?.operation?.nature_of_cargo?.name,
  });

  const [isProductEditDrawerVisible, setIsProductEditDrawerVisible] =
    useState(false);

  const [selectedOperationProduct, setSelectedOperationProduct] =
    useState<OperationProductType>({} as OperationProductType);

  function onClickEditProduct(operationProduct?: OperationProductType) {
    if (operationProduct) {
      setSelectedOperationProduct(operationProduct);
    }
  }

  function formatToForm(stopover: StopoverType) {
    return {
      ...stopover,
      operation: {
        ...stopover.operation,
        real_operation_start: stopover.operation?.real_operation_start
          ? moment(stopover.operation?.real_operation_start)
          : null,
        real_operation_finish: stopover.operation?.real_operation_finish
          ? moment(stopover.operation?.real_operation_finish)
          : null,
        inspection: stopover.operation?.inspection?.toString() || 'false',
      },
    };
  }

  useEffect(() => {
    if (isSucessCreateOperation) message.success('Operação criada com sucesso');
    if (isSucessUpdateOperation)
      message.success('Operação atualizada com sucesso');
  }, [isSucessCreateOperation, isSucessUpdateOperation]);

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue({
        stopover_id: selectedStopover.key,
      });
      setFilterStopover(form.getFieldValue('stopover_id'));
    }
  }, [isLoadingStopoveresData, isEditMode]);

  function filterDockingsByStopover() {
    if (filterStopover !== form.getFieldValue('stopover_id')) {
      setFilterStopover(form.getFieldValue('stopover_id'));
      const stopover = stopoveresData?.results?.find(
        (stopover) => stopover.id === form.getFieldValue('stopover_id')
      );
      form.setFieldsValue({
        docking_id: '',
        docking_docking_place_name: '',
        shipping_agency_name: stopover?.shipping_agency?.name,
      });
    }
  }

  function stopoverOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {`${option.code} (${option.vessel?.name})`}
      </Select.Option>
    );
  }

  function dockingOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {`Atracação ${option.code || 'Não informado'} (ETB: ${
          createDateStringPtBr(option.expected_berthing) || 'Não informado'
        })`}
      </Select.Option>
    );
  }

  function onSelectDocking() {
    const docking = filteredDockings.find(
      (docking) => docking.id === form.getFieldValue('docking_id')
    );
    form.setFieldsValue({
      docking_docking_place_name: docking?.docking_place?.name,
    });
  }

  const filteredDockings =
    stopoveresData?.results?.find((stopover) => stopover.id === filterStopover)
      ?.dockings || [];

  // submit form after validation
  async function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        const stopoverGroupByOperation = {
          ...selectedStopover,
          operation: {
            ...selectedStopover.operation,
            ...values.operation,
          },
          id: selectedStopover.operation?.id,
        };
        if (isEditMode) {
          const updatedOperation = await updateOperation(
            formatOperationToSave({
              ...stopoverGroupByOperation.operation,
              docking_id: stopoverGroupByOperation.docking_id,
            })
          );
          if ('data' in updatedOperation) {
            const stopover = stopoveresData?.results?.find(
              (stopover) => stopover.id === values.stopover_id
            );
            const docking = filteredDockings.find(
              (docking) => docking.id === values.docking_id
            );
            setSelectedStopover({
              ...selectedStopover,
              id: stopover?.id,
              code: stopover?.code,
              docking_code: docking?.code,
              docking_docking_place_name: docking?.docking_place?.name,
              operation: updatedOperation.data,
            });
          }
        } else {
          const createdOperation = await createOperation(
            formatOperationToSave({
              ...stopoverGroupByOperation.operation,
              docking_id: stopoverGroupByOperation.docking_id,
            })
          );
          if ('data' in createdOperation) {
            const stopover = stopoveresData?.results?.find(
              (stopover) => stopover.id === values.stopover_id
            );
            const docking = filteredDockings.find(
              (docking) => docking.id === values.docking_id
            );
            setSelectedStopover({
              ...selectedStopover,
              id: stopover?.id,
              code: stopover?.code,
              docking_code: docking?.code,
              docking_docking_place_name: docking?.docking_place?.name,
              operation: createdOperation.data,
            });
          }
        }
      })
      .finally(() => {
        setBlockNavigate(false);
        setHasEdited(false);
        setIsVisible(false);
      });
  }
  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={formatToForm(selectedStopover)}
      layout="vertical"
      name="stopover_operation_form"
      id="stopover_operation_form"
      onFinish={handleFormSubmit}
      onValuesChange={() => {
        if (setHasEdited) {
          setHasEdited(true);
        }
      }}
    >
      <OperationProductsEditDrawer
        visible={isProductEditDrawerVisible}
        setIsVisibleForm={setIsProductEditDrawerVisible}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        width={800}
        onBack={() => setIsProductEditDrawerVisible(false)}
        operationForm={form}
        selectedProduct={
          selectedOperationProduct as OperationProductAndCraneType
        }
      />
      <ModalBlockNavigation
        name="stopover_operation_form"
        key="stopover_operation_form"
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        setShowModal={setIsVisible}
      />
      <OperatorAreaFormHeader>
        <div className="container-title">
          <Button
            type="text"
            className="button-back"
            icon={
              <ArrowLeftOutlined
                style={{ color: 'var(--green-sea_dark)', fontSize: '20px' }}
              />
            }
            onClick={() => onCloseDrawer()}
          />
          {!isEditMode ? (
            <span className="title">Nova operação</span>
          ) : (
            <span className="title">
              Operação {selectedStopover?.operation?.id}
            </span>
          )}
        </div>
        <Button type="primary" htmlType="submit" icon={<CheckOutlined />}>
          Salvar
        </Button>
      </OperatorAreaFormHeader>
      {!isEditMode && (
        <Row
          gutter={40}
          align="bottom"
          style={{ marginTop: '24px', marginLeft: '0', marginRight: '0' }}
        >
          <FormItemSelect
            colSpan={12}
            label="Selecione uma estadia"
            name="stopover_id"
            allowClear
            showSearch
            notFoundContent="Não existem estadias cadastradas"
            dataList={stopoveresData?.results}
            isLoading={isLoadingStopoveresData}
            onSelect={filterDockingsByStopover}
            optionRenderer={stopoverOptionRenderer}
            required
            disabled={isEditMode}
          />
          <FormItemSelect
            colSpan={12}
            label="Atracação"
            name="docking_id"
            allowClear
            showSearch
            notFoundContent="Não existem atracações cadastradas para a estadias selecionada"
            dataList={filteredDockings}
            onSelect={onSelectDocking}
            optionRenderer={dockingOptionRenderer}
            required
            disabled={isEditMode}
          />
        </Row>
      )}
      <Collapse expandIconPosition="end" defaultActiveKey={['operation_data']}>
        <Panel key="operation_data" header="Dados da operação" forceRender>
          {!isEditMode && (
            <>
              <Row gutter={40} align="bottom">
                <FormItemInput
                  colSpan={8}
                  name="docking_docking_place_name"
                  label="Berço"
                  readOnly
                />
                <FormItemInput
                  colSpan={16}
                  name="shipping_agency_name"
                  label="Agência de navegação"
                  readOnly
                />
              </Row>
              <Row gutter={40} align="bottom">
                <FormItemSelect
                  colSpan={6}
                  name={['operation', 'nature_of_cargo', 'id']}
                  label="Natureza da Carga"
                  dataList={cargoNatureList
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  allowClear
                  required
                  disabled={isEditMode}
                />
                <FormItemSelect
                  colSpan={6}
                  name={['operation', 'product_group', 'id']}
                  label="Grupo do produto"
                  dataList={productGroupData?.results
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  allowClear
                  disabled={isEditMode}
                />
                <FormItemSelect
                  colSpan={4}
                  name={['operation', 'inspection']}
                  label="Haverá inspeção?"
                  allowClear
                  dataList={booleanList}
                />
                <FormItemSelect
                  colSpan={8}
                  name={['operation', 'inspection_type', 'id']}
                  label="Inspeção"
                  dataList={inspectionTypesData?.items
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  allowClear
                />
              </Row>
            </>
          )}
          <Row gutter={24} align="bottom">
            <FormItemDatePicker
              colSpan={8}
              label="Início da operação"
              name={['operation', 'real_operation_start']}
            />
            <FormItemDatePicker
              colSpan={8}
              label="Fim da operação"
              name={['operation', 'real_operation_finish']}
            />
          </Row>
        </Panel>
      </Collapse>
      <FormItemsProduct
        operationForm={form}
        operation={
          selectedStopover.operation || ({} as OperationalOperationType)
        }
        setSelectedProduct={(product) => onClickEditProduct(product)}
        setIsProductEditDrawerVisible={setIsProductEditDrawerVisible}
      />
    </Form>
  );
}
