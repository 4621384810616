import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { DefaultLayout } from '../../components';
import { useChangePagination } from '../../hooks';
import {
  useGetStopoverGroupbyDueQuery,
  useGetStopoveresGroupbyDuesQuery,
} from '../../services/stopoverApi';
import {
  FilterInvoicesType,
  useGetInvoicesGroupedByVehiclesQuery,
  useGetVehiclesGroupByParkingSpaceQuery,
} from '../../services/warehousingVehiclesApi';
import { FilterDefaultLayoutType, Stopover } from '../../types';
import { DueIsNullCardRenderer } from './dueIsNullCardRenderer';
import { ModalDueUpload } from './modalDueUpload';
import { warehousingCardRenderer } from './warehousingCardRenderer';
import { WarehousingVehiclesDetails } from './warehousingVehiclesDetails';
import { WarehousingOverviewCardRenderer } from './warehousingVehiclesOverview/warehousingOverviewCardRenderer';

export function WarehousingVehicles() {
  const tabs = [
    { label: 'Todas', value: '' },
    {
      label: 'Importação',
      value: 'IMPORT',
    },
    {
      label: 'Exportação',
      value: 'EXPORT',
    },
  ];
  const location = useLocation();
  const isWarehousingOperatorArea = location.pathname.includes(
    '/vehicles/operator-area-warehousing'
  );

  const { onChangePagination, queryPage } = useChangePagination();
  const {
    onChangePagination: onChangeDueNullPagination,
    queryPage: dueNullQueryPage,
  } = useChangePagination();

  const [filter, setFilter] = useState<FilterDefaultLayoutType>({
    page: queryPage,
  });

  console.log(dueNullQueryPage);

  const [isSelectedFixedCard, setisSelectedFixedCard] = useState(false);
  const [isSelectedOverviewCard, setisSelectedOverviewCard] = useState(false);

  const {
    data: stopoveresDuesData,
    isLoading: isLoadingStopoveresDueData,
    refetch: refetchStopovers,
  } = useGetStopoveresGroupbyDuesQuery(filter);

  const [InvoicesDueNullFilters, setInvoicesDueNullFilters] =
    useState<FilterInvoicesType>({});

  const {
    data: unidentifiedDUEInvoices,
    isLoading: isLoadingUnidentifiedDUEInvoices,
    refetch: refetchUnidentifiedDuesInvoices,
  } = useGetInvoicesGroupedByVehiclesQuery({
    ...InvoicesDueNullFilters,
    page: dueNullQueryPage,
  });

  const [filteredStopoveres, setFilteredStopoveres] = useState(
    [] as Stopover[]
  );

  const [selectedStopover, setSelectedStopover] = useState<Stopover>(
    {} as Stopover
  );
  const [showModalDueUpload, setShowModalDueUpload] = useState(false);
  const { data: stopoverDueData, isLoading: isLoadingStopoverDueData } =
    useGetStopoverGroupbyDueQuery(selectedStopover.id, {
      skip: !!(
        isEmpty(selectedStopover) ||
        selectedStopover.id === -1 ||
        selectedStopover.id === -2
      ),
    });

  function onSelectCard(stopover?: Stopover) {
    if (stopover) {
      setSelectedStopover(stopover);
      setisSelectedFixedCard(false);
    } else {
      setSelectedStopover({ id: -1 } as Stopover);
      setisSelectedFixedCard(true);
    }
    setisSelectedOverviewCard(false);
  }

  function onSelecteOverviewCard() {
    setSelectedStopover({ id: -2 } as Stopover);
    setisSelectedOverviewCard(true);
    setisSelectedFixedCard(false);
  }

  function onUpdate() {
    refetchUnidentifiedDuesInvoices();
  }

  useEffect(() => {
    if (stopoverDueData) {
      setSelectedStopover({ ...selectedStopover, ...stopoverDueData });
    }
  }, [stopoverDueData]);

  const { data: vehiclesGroupByParkingSpaceData } =
    useGetVehiclesGroupByParkingSpaceQuery();

  console.log(unidentifiedDUEInvoices);
  return (
    <>
      <DefaultLayout
        title="Armazenamento"
        subTitle="PÁTIO DE VEÍCULOS"
        tabs={tabs}
        filterField="status"
        setFilter={setFilter}
        stopoveresData={stopoveresDuesData}
        filteredStopoveres={filteredStopoveres}
        setFilteredStopoveres={setFilteredStopoveres}
        isLoadingStopoveresData={isLoadingStopoveresDueData}
        queryPage={queryPage}
        onChangePagination={onChangePagination}
        selectedStopover={selectedStopover}
        setSelectedStopover={onSelectCard}
        pageContext="STAYMENT"
        labelPlural="ESCALAS(S)"
        cardRenderer={warehousingCardRenderer}
        visibleAddDataDrawer={showModalDueUpload}
        setVisibleAddDataDrawer={setShowModalDueUpload}
        labelAddDataDrawer="Nova requisição"
        fixedCardRenderer={
          !isWarehousingOperatorArea ? (
            <>
              <WarehousingOverviewCardRenderer
                onSelectCard={onSelecteOverviewCard}
                isSelectedFixedCard={isSelectedOverviewCard}
                vehiclesByStorageCompany={vehiclesGroupByParkingSpaceData}
              />
              <DueIsNullCardRenderer
                invoices={unidentifiedDUEInvoices?.results}
                totalInvoices={unidentifiedDUEInvoices?.count}
                onSelectCard={onSelectCard}
                isSelectedFixedCard={isSelectedFixedCard}
              />
            </>
          ) : null
        }
      >
        <WarehousingVehiclesDetails
          stopoverDues={
            stopoverDueData && selectedStopover.id !== -1
              ? stopoverDueData
              : selectedStopover
          }
          stopover={selectedStopover}
          chassis={unidentifiedDUEInvoices?.results}
          totalChassis={unidentifiedDUEInvoices?.count}
          isLoading={isLoadingStopoverDueData}
          invoicesDueNullFilters={InvoicesDueNullFilters}
          setInvoicesDueNullFilters={setInvoicesDueNullFilters}
          onUpdateUnidentifiedDuesInvoices={onUpdate}
          isWarehousingOperatorArea={isWarehousingOperatorArea}
          onChangePagination={onChangeDueNullPagination}
          queryPage={dueNullQueryPage}
        />
      </DefaultLayout>
      <ModalDueUpload
        showModal={showModalDueUpload}
        setShowModal={setShowModalDueUpload}
        onFinish={() => refetchStopovers()}
      />
    </>
  );
}
