import styled from 'styled-components';

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  .title {
    color: var(--neutral_medium);
    font-weight: 700;
    font-size: 16px;
  }
  .subtitle {
    color: var(--neutral_light);
    font-weight: 400;
    font-size: 14px;
  }
`;
