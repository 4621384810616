import {
  GenericQueryParamsType,
  GenericResponse,
  OperationProductCompartmentType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type CompartmentsResponse = GenericResponse & {
  results: Array<OperationProductCompartmentType> | [];
};

type CompartmentsQueryParamsType = GenericQueryParamsType & {
  operation_id?: number;
};

const BASE_URL = '/operational/operation-compartments';

export const compartmentsApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getCompartments: build.query<
      CompartmentsResponse,
      CompartmentsQueryParamsType | void
    >({
      query: (params: CompartmentsQueryParamsType) => {
        return {
          url: BASE_URL,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'Compartments', code: '1' }],
    }),
    updateCompartments: build.mutation<
      OperationProductCompartmentType[],
      OperationProductCompartmentType[]
    >({
      query: (body) => ({
        url: BASE_URL,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Compartments', code: '1' }]),
    }),
    deleteCompartment: build.mutation<void, number>({
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Compartments', code: '1' }]),
    }),
  }),
});

export const {
  useGetCompartmentsQuery,
  useUpdateCompartmentsMutation,
  useDeleteCompartmentMutation,
} = compartmentsApi;
