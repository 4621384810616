import { message, Row } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';

import {
  Form,
  Collapse,
  ModalBlockNavigation,
  Title,
  FormItemDatePicker,
} from '../../../components';
import { useCollapsePanels } from '../../../hooks';
import { useUpdateDockingMutation } from '../../../services/dockingApi';
import { Docking as DockingType, Vessel } from '../../../types';
import { formatDockingToSave } from '../../../utils/formatters';
import {
  createDateString,
  isNullOrUndefined,
  createDateStringPtBr,
} from '../../../utils/utils';
import { ActualTimeOfArrivalModal } from './ActualTimeOfArrivalModal';
import { DockingFormHeader } from '../dockingFormHeader/DockingFormHeader';

const { Panel } = Collapse;

type DockingPilotExpectFormProps = {
  name: string;
  selectedDocking: DockingType | undefined;
  setSelectedDocking: (value: any) => void;
  selectedStopover: any;
  currentDockingKey: number;
  setCurrentDockingKey: (value: any) => void;
  isVisibleForm: boolean;
  setIsVisibleForm: (value: boolean) => void;
  onBack?: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  blockNavigate: boolean;
};

const collapsePanels = ['generalData'];

export function DockingPilotExpectForm(props: DockingPilotExpectFormProps) {
  const {
    name,
    selectedDocking,
    setSelectedDocking,
    selectedStopover,
    currentDockingKey,
    setCurrentDockingKey,
    isVisibleForm,
    setIsVisibleForm,
    onBack,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    blockNavigate,
  } = props;

  const [form] = Form.useForm();

  const { onChangeSwitch, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const [updateDocking, { isLoading: isLoadingUpdateDocking }] =
    useUpdateDockingMutation();

  function onChangePilotOnBoardExpectedBerthing(
    selectedDate: any,
    manoeuvre: 'DOCKING' | 'UNDOCKING'
  ) {
    if (selectedDate) {
      if (manoeuvre === 'DOCKING') {
        form.setFieldsValue({
          pilot_expected_leaving_on_board: moment(selectedDate).add({
            minutes: 15,
          }),
          pilot_expected_on_board: moment(selectedDate).subtract(1, 'hour'),
        });
      } else if (manoeuvre === 'UNDOCKING') {
        form.setFieldsValue({
          pilot_expected_leaving_on_board_undocking: moment(selectedDate).add({
            minutes: 15,
          }),
          pilot_expected_on_board_undocking: moment(selectedDate).subtract(
            1,
            'hour'
          ),
        });
      }
    }
  }

  function onChangePilotOnBoard(
    selectedDate: any,
    manoeuvre: 'DOCKING' | 'UNDOCKING'
  ) {
    if (selectedDate) {
      if (manoeuvre === 'DOCKING') {
        form.setFieldsValue({
          pilot_expected_leaving_on_board: moment(selectedDate).add({
            hours: 1,
            minutes: 15,
          }),
          expected_berthing: moment(selectedDate).add(1, 'hour'),
        });
      } else if (manoeuvre === 'UNDOCKING') {
        form.setFieldsValue({
          pilot_expected_leaving_on_board_undocking: moment(selectedDate).add({
            hours: 1,
            minutes: 15,
          }),
          expected_unberthing: moment(selectedDate).add(1, 'hour'),
        });
      }
    }
  }

  function formatDockingUserTracking(
    last_user: boolean | undefined,
    last_date: string | undefined
  ): string {
    if (isNullOrUndefined(last_date)) {
      return '';
    }
    return `Atualizado ${
      last_user ? 'por Suape' : 'pelo Agente'
    } em ${createDateStringPtBr(last_date)}`;
  }

  function handleFailedForm() {
    const errorFields = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (errorFields.length > 0) {
      form.getFieldInstance(errorFields[0].name).focus();
    }
  }

  function validateDocking(docking: DockingType): boolean {
    if (
      docking.expected_berthing &&
      docking.expected_unberthing &&
      !docking.docking_place?.tag
    ) {
      // Só é permitido salvar previsão de estadia se informar o berço
      message.error(
        'Não é possível informar a previsão de estadia sem informar o berço.'
      );
      return false;
    }

    if (
      docking.expected_berthing &&
      docking.expected_unberthing &&
      moment(docking.expected_berthing) > moment(docking.expected_unberthing)
    ) {
      message.error(
        'A data de previsão da atracação não pode ser maior que a data de previsão de desatracação',
        15
      );
      return false;
    }

    if (
      docking.pilot_expected_on_board &&
      docking.pilot_expected_leaving_on_board &&
      moment(docking.pilot_expected_on_board) >
        moment(docking.pilot_expected_leaving_on_board)
    ) {
      message.error(
        'A data de previsão da prático a bordo não pode ser maior que a data de previsão de saída do prático a bordo',
        15
      );
      return false;
    }

    return true;
  }

  async function handleFormSubmit(values: any) {
    if (!validateDocking({ ...selectedDocking, ...values })) return;

    if (currentDockingKey !== null) {
      const docking = {
        ...selectedDocking,
        ...values,
      };
      const update = formatDockingToSave(docking);
      const updatedDocking = await updateDocking(update);
      if ('data' in updatedDocking) {
        message.success('Alterações salvas com sucesso!', 5);
      }
    }

    setSelectedDocking(undefined);
    setCurrentDockingKey(null);
    setIsVisibleForm(false);
    setBlockNavigate(false);
    setIsFormChanged(false);

    form.resetFields();
  }

  useEffect(() => {
    if (isVisibleForm === false) {
      setSelectedDocking(undefined);
      setCurrentDockingKey(null);
      form.resetFields();
    }
  }, [isVisibleForm]);

  function panelHeaderTitle(title: string) {
    const date = form.getFieldValue('updated_at');

    return (
      <>
        {title}
        {!isNullOrUndefined(date) && (
          <span
            className="header-description"
            style={{ float: 'right', fontWeight: 'normal', fontSize: '13px' }}
          >
            Atualizado em: {moment(date).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </>
    );
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      afterCloseModal();
      setIsVisibleForm(false);
    }
  }

  function afterCloseModal() {
    setSelectedDocking(undefined);
    setCurrentDockingKey(null);
    form.resetFields();
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name={name}
      id={name}
      onFinish={handleFormSubmit}
      onFinishFailed={handleFailedForm}
      initialValues={selectedDocking}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />

      <DockingFormHeader
        formName={name}
        isLoading={isLoadingUpdateDocking}
        onBack={onClose}
        dockingRelease={selectedStopover.docking_release}
        showExpandAll
        onChangeSwitch={onChangeSwitch}
        openCollapsePanels={openCollapsePanels}
        editFormTitle={`Atracação ${currentDockingKey + 1}`}
        code={selectedStopover?.code}
        dockingCode={form.getFieldValue('code')}
        dockingStatus={form.getFieldValue('status')}
        saveButtonTitle="Salvar Atracação"
        showCancelButton
      />
      <Collapse expandIconPosition="end" activeKey={openCollapsePanels}>
        <Panel
          forceRender
          header={panelHeaderTitle('Dados gerais')}
          key="generalData"
        >
          <Row>
            <Title>ATRACAÇÃO</Title>
            <Row gutter={24} align="top">
              <FormItemDatePicker
                colSpan={8}
                label="Previsão de prático a bordo"
                name="pilot_expected_on_board"
                minuteStep={15}
                onChange={(date) => onChangePilotOnBoard(date, 'DOCKING')}
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_on_board_last_user,
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_on_board_last_date
                )}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Previsão de saída do prático a bordo"
                name="pilot_expected_leaving_on_board"
                minuteStep={15}
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_leaving_on_board_last_user,
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_leaving_on_board_last_date
                )}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Previsão de atracação"
                name="expected_berthing"
                minuteStep={15}
                tooltip="Início da atracação"
                onChange={(date) =>
                  onChangePilotOnBoardExpectedBerthing(date, 'DOCKING')
                }
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.expected_berthing_last_user,
                  selectedDocking?.docking_user_tracking
                    ?.expected_berthing_last_date
                )}
              />
            </Row>
            <Title>DESATRACAÇÃO</Title>
            <Row gutter={24} align="top">
              <FormItemDatePicker
                colSpan={8}
                label="Previsão de prático a bordo"
                name="pilot_expected_on_board_undocking"
                minuteStep={15}
                onChange={(date) => onChangePilotOnBoard(date, 'UNDOCKING')}
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_on_board_undocking_last_user,
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_on_board_undocking_last_date
                )}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Previsão de saída do prático a bordo"
                name="pilot_expected_leaving_on_board_undocking"
                minuteStep={15}
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_leaving_on_board_undocking_last_user,
                  selectedDocking?.docking_user_tracking
                    ?.pilot_expected_leaving_on_board_undocking_last_date
                )}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Previsão de desatracação"
                name="expected_unberthing"
                minuteStep={15}
                tooltip="Fim da atracação"
                onChange={(date) =>
                  onChangePilotOnBoardExpectedBerthing(date, 'UNDOCKING')
                }
                help={formatDockingUserTracking(
                  selectedDocking?.docking_user_tracking
                    ?.expected_unberthing_last_user,
                  selectedDocking?.docking_user_tracking
                    ?.expected_unberthing_last_date
                )}
              />
            </Row>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
}
