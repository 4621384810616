import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ConfigProvider, Spin } from 'antd';

import { Button, TableWrapper } from '../../../components';
import { BreakdownOccurrenceType } from '../../../types';
import { TablePageProps } from '../Reports';
import { ReportsTable } from '../styles';
import { breakdownsTableColumns } from './breakdownsTableColumns';
import { ExpandedTableRowDescription } from './expandedTableRowDescription';

type BreakdownsTableProps = {
  onOpenDrawer?: (breakdownOccurrence: BreakdownOccurrenceType) => void;
};

export function BreakdownsTable(props: TablePageProps & BreakdownsTableProps) {
  const {
    dataSource,
    scrollOptions,
    total,
    itemsPerPage,
    onChangePagination,
    isLoading,
    pageSize,
    showSizeChanger,
    rowKey,
    queryPage,
    onOpenDrawer,
  } = props;

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  return (
    <Spin spinning={isLoading} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <ReportsTable
            rowKey={rowKey || 'key'}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: itemsPerPage,
              pageSize,
              total,
              onChange: onChangePagination,
              showSizeChanger,
              current: queryPage,
            }}
            columns={breakdownsTableColumns(onOpenDrawer)}
            dataSource={dataSource}
            scroll={
              scrollOptions || { y: '60vh', scrollToFirstRowOnChange: false }
            }
            expandable={{
              expandedRowRender: (record: any) => (
                <ExpandedTableRowDescription record={record} />
              ),
              expandIcon: ({ expanded, onExpand, record }) => {
                return expanded ? (
                  <Button
                    icon={
                      <UpOutlined style={{ color: 'var(--green-sea_dark)' }} />
                    }
                    type="text"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <Button
                    icon={
                      <DownOutlined
                        style={{ color: 'var(--green-sea_dark)' }}
                      />
                    }
                    type="text"
                    onClick={(e) => onExpand(record, e)}
                  />
                );
              },
              expandRowByClick: true,
            }}
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
