import {
  OperationLineMovimentationToSave,
  OperationLineMovimentationType,
  WorkShiftMovimentationType,
} from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const movimentationApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    updateMovimentation: build.mutation<
      WorkShiftMovimentationType[],
      { movimentations: WorkShiftMovimentationType[]; operation_id?: number }
    >({
      query: (workShiftMovimentation) => {
        return {
          url: `/operational/work-shift-movimentation`,
          method: 'PUT',
          body: workShiftMovimentation.movimentations,
        };
      },
      invalidatesTags: (result, error, queryArg) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: queryArg.operation_id },
          { type: 'OperationCalculus', code: queryArg.operation_id },
        ]),
    }),
    updateLinesMovimentation: build.mutation<
      OperationLineMovimentationType[],
      {
        movimentations: OperationLineMovimentationToSave[];
        operation_id?: number;
      }
    >({
      query: (params) => {
        return {
          url: '/operational/operation-line-movimentations',
          method: 'PUT',
          body: params.movimentations,
        };
      },
      invalidatesTags: (result, error, queryArg) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: queryArg.operation_id },
          { type: 'OperationCalculus', code: queryArg.operation_id },
        ]),
    }),
    updateCargoStorageMerchantTotalMovimented: build.mutation<
      { id: number; total_movimented: number }[],
      { id: number; total_movimented: number }[]
    >({
      query: (movimentations) => {
        return {
          url: '/operational/cargos-storage-merchant/total-movimented',
          method: 'PATCH',
          body: movimentations,
        };
      },
    }),
    patchCargoStorageMerchantVehicleMovimentation: build.mutation<
      { id: number }[],
      { id: number }[]
    >({
      query: (vehicles) => {
        return {
          url: '/operational/cargos-storage-merchant/group-by-vehicles-exporting',
          method: 'PATCH',
          body: vehicles,
        };
      },
    }),
  }),
});

export const {
  useUpdateMovimentationMutation,
  useUpdateLinesMovimentationMutation,
  useUpdateCargoStorageMerchantTotalMovimentedMutation,
  usePatchCargoStorageMerchantVehicleMovimentationMutation,
} = movimentationApi;
