import { message } from 'antd';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';

import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  TableFormList,
} from '../../../../components';
import { usePatchCSMMutation } from '../../../../services/cargosStorageMerchantApi';
import {
  CargoStorageMerchantType,
  ConsigneeWithCargosType,
  Stopover as StopoverType,
} from '../../../../types';
import { ConfirmCargoRow, confirmCargoRowCollumns } from './ConfirmCargoRow';

type ConfirmStorageModalProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  consignee: ConsigneeWithCargosType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
};

export type RemainingProductType = {
  id: number;
  name: string;
  value: number;
};

export function ConfirmStorageModal(props: ConfirmStorageModalProps) {
  const { isVisible, setIsVisible, consignee, setSelectedStopover } = props;
  const [form] = Form.useForm();
  const [remainingProducts, setRemainingProducts] = useState<
    RemainingProductType[]
  >([]);

  const [rejectionReason, setRejectionReason] = useState('');
  const [isVisibleModalRejection, setIsVisibleModalRejection] = useState(false);
  const [rejectAll, setRejectAll] = useState(false);

  const [patchCSM, { isLoading: isLoadingPatchCSM }] = usePatchCSMMutation();

  function handleFormSubmit() {
    form
      .validateFields()
      .then((values: { items: CargoStorageMerchantType[] }) => {
        if (rejectAll) {
          values.items = values.items.map((cargo) => ({
            ...cargo,
            rejection_reason: rejectionReason,
            status: 'REJECTED',
          }));
          patchCSM(values.items).then((response) => {
            if ('data' in response) {
              message.success('Carga(s) rejeitada(s) com sucesso');
            }
            setSelectedStopover({} as StopoverType);
          });
          return;
        }
        const newCargos: CargoStorageMerchantType[] = [];
        values.items = values.items.map((cargo) => {
          const selectedItem = consignee.items.find(
            (item) => item.id === cargo.id
          );
          if (selectedItem) {
            const remainingCargoVolume =
              (selectedItem.volume || 0) - (cargo.volume || 0);
            const remainingCargoWeight =
              (selectedItem.weight || 0) - (cargo.weight || 0);
            cargo.confirmed_volume = cargo.volume;
            if (remainingCargoVolume > 0) {
              newCargos.push({
                ...cargo,
                id: undefined,
                volume: +remainingCargoVolume.toFixed(3),
                weight: +remainingCargoWeight.toFixed(3),
                confirmed_volume: remainingCargoVolume,
                status: 'REJECTED',
              });
            }
          }
          let status: 'CONFIRMED' | 'NEW_SCHEDULE' = 'CONFIRMED';
          if (cargo.expected_storage) {
            status = 'NEW_SCHEDULE';
          }
          return { ...cargo, status };
        });
        const totalItems = [...values.items, ...newCargos];
        patchCSM(totalItems).then((response) => {
          if ('data' in response) {
            message.success('Carga(s) confirmada(s) com sucesso');
            form.resetFields();
            setRejectAll(false);
          }
        });
      });
  }

  function getFooter() {
    return (
      <div>
        <Button
          type="text"
          onClick={() => {
            form.resetFields();
            setIsVisible(false);
          }}
        >
          Voltar
        </Button>
        <Button
          danger
          onClick={() => {
            setIsVisibleModalRejection(true);
            setRejectAll(true);
          }}
        >
          <span style={{ color: 'var(--error-medium)' }}>Rejeitar todos</span>
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          form="confirmCargoForm"
          key="confirmCargoForm"
          loading={isLoadingPatchCSM}
        >
          Confirmar
        </Button>
      </div>
    );
  }

  function handleCloseModalReject() {
    setRejectionReason('');
    setIsVisibleModalRejection(false);
    setRejectAll(false);
  }

  return (
    <Modal
      title="Responder armazenamento de carga"
      width={1000}
      visible={isVisible}
      className="TOSPrimaryModal"
      footer={getFooter()}
      onCancel={() => setIsVisible(false)}
    >
      <Modal
        width={535}
        visible={isVisibleModalRejection}
        title="Rejeição de armazenamento"
        className="TOSDangerModal"
        okButtonProps={{
          style: { color: 'var(--error-medium)' },
          loading: isLoadingPatchCSM,
          disabled: !rejectionReason,
        }}
        okText="Rejeitar"
        onOk={() => handleFormSubmit()}
        cancelText="Voltar"
        onCancel={() => handleCloseModalReject()}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <span>
            Para efetuar a rejeição deste armazenamento, preencha o motivo.
          </span>
          <Input.TextArea
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            maxLength={1000}
            placeholder="Motivo da rejeição"
          />
        </div>
      </Modal>
      <span>
        Ao confirmar, a Transpetro será responsável pelos produtos abaixo do
        consignatário <strong>{consignee.operator_company?.name}</strong>.
        <br />
        Caso necessário, edite as datas abaixo:
      </span>
      <Form
        form={form}
        name="confirmCargoForm"
        onFinish={handleFormSubmit}
        initialValues={{
          ...consignee,
          items: consignee.items?.map((item) => ({
            ...item,
            expected_storage: item.expected_storage
              ? moment(item.expected_storage)
              : null,
          })),
        }}
      >
        <TableFormList
          formListName="items"
          FormListItemRow={ConfirmCargoRow}
          columns={confirmCargoRowCollumns}
          itemRowProps={{ consignee, form, setRemainingProducts }}
        />
      </Form>

      {remainingProducts.length > 0 ? (
        <Alert
          type="warning"
          message={`Restam confirmar ${remainingProducts
            .map((item) => `${item.value} m³ de ${item.name}`)
            .join(
              ', '
            )}. Após confirmar, o agente de navegação responsável será notificado e poderá gerenciar o volume restante. de alguma informação, após concluir o agente será notificado.`}
        />
      ) : (
        <Alert
          type="info"
          message="No caso de alteração de alguma informação, após concluir o agente será notificado."
        />
      )}
    </Modal>
  );
}
