import { DeleteOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import {
  Button,
  FormItemInput,
  FormItemInputNumber,
} from '../../../../components';

type ItemDescountRowProps = {
  index: number;
  onRemove?: (index: number | number[]) => void;
};

export const itemDescountRowColumns = [{ title: 'Desconto', colSpan: 24 }];

export function ItemDescountRow(props: ItemDescountRowProps) {
  const { index, onRemove } = props;

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      <FormItemInput
        colSpan={9}
        label="Nome"
        name={[index, 'name']}
        required
        maxLength={100}
      />
      <FormItemInputNumber
        colSpan={6}
        label="Percentual"
        name={[index, 'percentual']}
        addonAfter="%"
        style={{ marginBottom: '0px' }}
        required
        maxLength={6}
        max={100}
      />
      <FormItemInputNumber
        colSpan={7}
        label="Valor"
        name={[index, 'value']}
        addonBefore="R$"
        style={{ marginBottom: '0px' }}
      />
      <Col span={2}>
        <Button
          type="default"
          danger
          icon={<DeleteOutlined />}
          onClick={() => onRemove && onRemove(index)}
        />
      </Col>
    </Row>
  );
}
