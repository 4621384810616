import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { useEffect, useState } from 'react';

import {
  Button,
  Form,
  FormItem,
  FormItemSelect,
  GenericDrawerHeader,
  InnerCollapse,
  Select,
} from '../../../components';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import {
  useCreateMerchantCeMutation,
  useDeleteMerchantCeMutation,
  useUpdateMerchantCeMutation,
} from '../../../services/merchantCEApi';
import {
  CompanyType,
  MerchantOperatorCompanyDescriptionType,
  OperationContainerType,
  OperationProductAndContainerType,
  OperationProductCeMercanteType,
} from '../../../types';
import { formatOperationMerchantCEToSave } from '../../../utils/formatters';
import { maskString } from '../../../utils/utils';
import { ModalOperationMerchantCE } from '../modalOperationMerchantCE/ModalOperationMerchantCE';
import { MerchantCEListItem } from './MerchantCEListItem';
import { ConsigneeDrawerHeader } from './styles';

type OperationProductConsigneeFormProps = {
  onCloseDrawer?: () => void;
  selectedConsigneeCompanyId: number;
  selectedConsigneeCompany: MerchantOperatorCompanyDescriptionType;
  setSelectedConsigneeCompany: (
    value: MerchantOperatorCompanyDescriptionType
  ) => void;
  selectedProduct?: OperationProductAndContainerType;
  setSelectedProduct?: (value: OperationProductAndContainerType) => void;
  isContainer?: boolean;
};

const { Panel } = InnerCollapse;
export function OperationProductConsigneeForm(
  props: OperationProductConsigneeFormProps
) {
  const {
    onCloseDrawer,
    selectedConsigneeCompanyId,
    selectedConsigneeCompany,
    setSelectedConsigneeCompany,
    selectedProduct,
    setSelectedProduct,
    isContainer,
  } = props;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMerchantCE, setSelectedMerchantCE] = useState<
    OperationProductCeMercanteType | undefined
  >(undefined);
  const [selectedOperatorCompany, setSelectedOperatorCompany] =
    useState<CompanyType>(Object);

  const isEdit = !!selectedConsigneeCompany?.company_name;

  const { data: filteredCompanies, isFetching: isLoadingCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      company_type: 'CONSIGNEE',
    });

  const [updateMerchantCe] = useUpdateMerchantCeMutation();

  const [createMerchantCe] = useCreateMerchantCeMutation();

  const [deleteMerchantCe] = useDeleteMerchantCeMutation();

  useEffect(() => {
    form.setFieldsValue({
      company_name: selectedConsigneeCompany?.company_name,
    });
  }, [selectedConsigneeCompany]);

  useEffect(() => {
    const company = filteredCompanies?.results.find((company) => {
      return company.id === selectedConsigneeCompanyId;
    });
    if (company) {
      setSelectedOperatorCompany({ ...company });
    }
  }, [selectedConsigneeCompany, filteredCompanies]);

  function onTryDelete() {
    confirm({
      title: 'Deseja realmente remover este consignatário?',
      icon: <ExclamationCircleOutlined />,
      content: 'O Consignatário removido não poderá ser recuperado',
      onOk() {
        const merchantsCECodes = selectedConsigneeCompany.merchants_ce.map(
          (ce) => ce.code
        );
        if (selectedProduct && setSelectedProduct) {
          if (selectedProduct.id > 0) {
            for (const ce of selectedConsigneeCompany.merchants_ce) {
              if (ce?.id) {
                deleteMerchantCe(ce.id);
              }
            }
          }
        }
        if (onCloseDrawer) {
          onCloseDrawer();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function onBack() {
    form.resetFields();
    if (setSelectedConsigneeCompany) {
      setSelectedConsigneeCompany({} as MerchantOperatorCompanyDescriptionType);
    }
    if (onCloseDrawer) {
      onCloseDrawer();
    }
  }

  function getDrawerTitle() {
    const hasCode = !!selectedConsigneeCompany?.company_code;
    return (
      <ConsigneeDrawerHeader>
        {!isEdit ? (
          <span className="company-name">Novo consignatário</span>
        ) : (
          <>
            <span
              className={`company-name${hasCode ? ' truncate' : ''}`}
              title={selectedConsigneeCompany?.company_name}
            >
              {selectedConsigneeCompany?.company_name}
            </span>
            <span className="company-code">
              {maskString(
                selectedConsigneeCompany?.company_code,
                '##.###.###/####-##'
              )}
            </span>
          </>
        )}
      </ConsigneeDrawerHeader>
    );
  }

  function onSelectOperatorCompany(option: number) {
    const companyObject = filteredCompanies?.results.find(
      (company: CompanyType) => {
        return company.id === option;
      }
    );
    if (companyObject) {
      setSelectedOperatorCompany({ ...companyObject });
    }
  }

  function consigneeOptionRenderer(consigneeCompany: any) {
    return (
      <Select.Option key={consigneeCompany.name} value={consigneeCompany.id}>
        {consigneeCompany.cnpj ? `${consigneeCompany.cnpj} - ` : ''}
        {consigneeCompany.name}
      </Select.Option>
    );
  }
  function onEditMerchantCE(merchantCE?: OperationProductCeMercanteType) {
    setSelectedMerchantCE(merchantCE);
    setIsModalVisible(true);
  }
  // submit form after validation
  function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        const consigneeMerchantsCE =
          selectedConsigneeCompany?.merchants_ce?.map((ce) => {
            const operationIdProperty = isContainer
              ? 'operation_container_id'
              : 'operation_product_id';
            return {
              ...ce,
              operator_company: selectedOperatorCompany,
              [operationIdProperty]:
                selectedProduct?.id || ce[operationIdProperty],
              storages: ce.storages || [],
            };
          }) || [];

        const productCECodes = (selectedProduct?.merchants_ce || []).map(
          (ce) => ce.code
        );

        const consigneeCECodes = selectedConsigneeCompany?.merchants_ce?.map(
          (ce) => ce.code
        );

        if (selectedProduct && selectedProduct.id > 0) {
          const merchantsIdsToDelete = (selectedProduct?.merchants_ce || [])
            .filter(
              (ce) =>
                ce.operator_company.id === selectedOperatorCompany.id &&
                !consigneeCECodes?.includes(ce.code)
            )
            .map((ce) => ce.id);

          for (const ceId of merchantsIdsToDelete) {
            if (ceId && ceId > 0) {
              deleteMerchantCe(ceId);
            }
          }

          for (const ce of consigneeMerchantsCE) {
            const merchantToSave = formatOperationMerchantCEToSave(ce);

            if (!merchantsIdsToDelete.includes(ce.id)) {
              if (ce?.id) {
                updateMerchantCe(merchantToSave);
              } else {
                createMerchantCe(merchantToSave);
              }
            }
          }
        }

        const items = (selectedProduct?.merchants_ce || [])
          // Delete
          .filter(
            (ce) =>
              ce.operator_company.id !== selectedOperatorCompany.id ||
              consigneeCECodes?.includes(ce.code)
          )
          // Update
          .map((ce) => {
            const updated = consigneeMerchantsCE.find(
              (u) => (u?.id && u.id > 0 && u.id === ce.id) || u.code === ce.code
            );
            if (updated) {
              return updated;
            }
            return ce;
          })
          .concat(
            consigneeMerchantsCE.filter(
              (ce) => !productCECodes?.includes(ce.code)
            )
          );

        if (selectedProduct && setSelectedProduct) {
          setSelectedProduct({
            ...selectedProduct,
            merchants_ce: items,
          });
        }
      })
      .finally(() => {
        onBack();
      });
  }
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="consignee_edit_form"
        id="consignee_edit_form"
        onFinish={handleFormSubmit}
        initialValues={selectedConsigneeCompany}
      >
        <GenericDrawerHeader
          onBack={onBack}
          showBackButton
          title={getDrawerTitle()}
          formName="consignee_edit_form"
          onDelete={isEdit ? () => onTryDelete() : undefined}
        />
        <div style={{ padding: '16px' }}>
          <Row gutter={24}>
            <FormItem name="company_code" noStyle />
            <FormItemSelect
              colSpan={24}
              name="company_name"
              label="Consignatário"
              dataList={filteredCompanies?.results
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              rules={[{ required: true, message: 'campo obrigatório' }]}
              optionRenderer={consigneeOptionRenderer}
              onSelect={onSelectOperatorCompany}
            />
          </Row>
          <InnerCollapse expandIconPosition="end">
            <Panel key="merchants" header="CEs Mercantes">
              <div style={{ padding: '16px' }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => onEditMerchantCE()}
                >
                  Novo CE Mercante
                </Button>
              </div>
              {selectedConsigneeCompany?.merchants_ce?.map(
                (merchantCE, index) => (
                  <MerchantCEListItem
                    key={index}
                    merchantCE={merchantCE}
                    onEdit={() => onEditMerchantCE(merchantCE)}
                    isContainer={isContainer}
                  />
                )
              )}
            </Panel>
          </InnerCollapse>
        </div>
      </Form>
      <ModalOperationMerchantCE
        showModal={isModalVisible}
        setShowModal={setIsModalVisible}
        selectedMerchantCE={selectedMerchantCE}
        setSelectedMerchantCE={setSelectedMerchantCE}
        selectedConsigneeCompany={selectedConsigneeCompany}
        setSelectedConsigneeCompany={setSelectedConsigneeCompany}
        isContainer={isContainer}
        storageCompany={
          isContainer
            ? (selectedProduct as OperationContainerType)?.storage_company
            : undefined
        }
      />
    </>
  );
}
