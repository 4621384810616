import {
  ArrowLeftOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Col, message, Row } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { debounce } from 'lodash';
import { useEffect } from 'react';

import {
  Button,
  Form,
  FormHeaderWrapper,
  FormItem,
  Input,
  TreeSelect,
} from '../../components';
import {
  useCreatePermissionGroupMutation,
  useDeletePermissionGroupMutation,
  useGetPermissionsQuery,
  useUpdatePermissionGrupMutation,
} from '../../services/groupsApi';
import { formatPermissionGroupToSave } from './formatPermissionGroupToSave';

type PermissionGroupFormProps = {
  name: string;
  onCloseDrawer: () => void;
  selectedGroup: null | Record<string, any>;
  onSavePermissionGroup: (saved: any) => void;
  onDeletePermissionGroup: () => void;
};

export function PermissionGroupForm(props: PermissionGroupFormProps) {
  const [permissionGroupForm] = Form.useForm();

  const [
    createPermissionGroup,
    {
      isSuccess: isSuccessCreatePermissionGroup,
      isError: isErrorCreatePermissionGroup,
    },
  ] = useCreatePermissionGroupMutation();

  const [
    updatePermissionGroup,
    {
      isSuccess: isSuccessUpdatePermissionGroup,
      isError: isErrorUpdatePermissionGroup,
    },
  ] = useUpdatePermissionGrupMutation();

  const [
    deletePermissionGroup,
    {
      isSuccess: isSuccessDeletePermissionGroup,
      isError: isErrorDeletePermissionGroup,
    },
  ] = useDeletePermissionGroupMutation();

  const { data: permissions } = useGetPermissionsQuery();

  function onBack() {
    props.onCloseDrawer();
  }

  async function handleForm(values: any) {
    let savedPermissionGroup;
    if (props.selectedGroup) {
      savedPermissionGroup = await updatePermissionGroup(
        formatPermissionGroupToSave(values)
      );
    } else {
      savedPermissionGroup = await createPermissionGroup(
        formatPermissionGroupToSave(values)
      );
    }
    if ('data' in savedPermissionGroup) {
      props.onSavePermissionGroup(savedPermissionGroup.data);
    }
  }

  function deleteGroup(id: number) {
    if (props.selectedGroup?.users_count <= 0) {
      confirm({
        title: 'Deseja realmente remover este grupo?',
        icon: <ExclamationCircleOutlined />,
        content: 'Depois que o grupo for removido não poderá ser recuperado',
        onOk: async () => {
          await deletePermissionGroup(id);
        },
        okText: 'Sim',
        cancelText: 'Não',
      });
    } else {
      message.error(
        'Só é possível remover grupos que não possuem nenhum usuário'
      );
    }
  }

  useEffect(() => {
    if (isSuccessCreatePermissionGroup || isSuccessUpdatePermissionGroup) {
      message.success('Grupo de permissão salvo');
      props.onCloseDrawer();
    } else if (isErrorCreatePermissionGroup || isErrorUpdatePermissionGroup) {
      message.error(
        'Ocorreu um erro ao salvar o grupo de permissão, se o erro persistir contate o suporte'
      );
    }
  }, [
    isSuccessCreatePermissionGroup,
    isErrorCreatePermissionGroup,
    isSuccessUpdatePermissionGroup,
    isErrorUpdatePermissionGroup,
  ]);

  useEffect(() => {
    if (isSuccessDeletePermissionGroup) {
      message.success('Grupo de permissão apagado');
      props.onDeletePermissionGroup();
      props.onCloseDrawer();
    } else if (isErrorDeletePermissionGroup) {
      message.error(
        'Ocorreu um erro ao apagar o grupo de permissão, se o erro persistir contate o suporte'
      );
    }
  }, [isSuccessDeletePermissionGroup, isErrorDeletePermissionGroup]);

  return (
    <Form
      name={props.name}
      form={permissionGroupForm}
      layout="vertical"
      onFinish={handleForm}
      initialValues={props.selectedGroup || {}}
    >
      <FormItem name="id" noStyle />
      <FormHeaderWrapper>
        <div className="buttons">
          <div style={{ marginLeft: '-15px' }}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={() => onBack()}
            >
              Voltar
            </Button>
          </div>
          <div>
            {props.selectedGroup && (
              <Button
                danger
                icon={<DeleteOutlined />}
                style={{ marginRight: '15px' }}
                onClick={debounce(
                  () => deleteGroup(props.selectedGroup?.id),
                  400
                )}
              >
                Excluir
              </Button>
            )}
            <Button
              type="primary"
              icon={<CheckOutlined />}
              htmlType="submit"
              form={props.name}
              key={props.name}
            >
              Salvar alterações
            </Button>
          </div>
        </div>
        <div className="title">
          {props.selectedGroup
            ? props.selectedGroup.name.toUpperCase()
            : 'NOVO GRUPO'}
        </div>
      </FormHeaderWrapper>
      <div style={{ padding: '32px' }}>
        <Row>
          <Col span={24}>
            <FormItem name="name" label="Nome do grupo">
              <Input placeholder="Nome do grupo" />
            </FormItem>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <FormItem name="members" label="Membros">
              <Input placeholder="Membros" />
            </FormItem>
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <FormItem name="description" label="Descrição">
              <Input.TextArea maxLength={500} placeholder="Descrição" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem name="permissions" label="Permissões">
              <TreeSelect
                treeData={permissions}
                treeCheckable
                showCheckedStrategy="SHOW_PARENT"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
    </Form>
  );
}
