import { Form as ANTDForm } from 'antd';
import styled from 'styled-components';

export const Form = styled(ANTDForm)`
  box-shadow: 0 0 0 transparent !important;
  overflow-x: clip;
  & .ant-form-item .ant-form-item-label {
    /* text-align: left; */
    padding-bottom: 0px;
  }
  & .ant-form-item .ant-form-item-label label {
    font-weight: 300;
    font-size: 12px;
    color: #333337;
    line-height: 1.58;
  }
  & input[mask] {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--neutral_light);
    border-radius: 0px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    padding-bottom: 6px;
    box-shadow: 0 0 0 transparent;
    &:focus {
      border-bottom: 1px solid var(--green-sea_medium);
    }
  }
  & .ant-form-item-has-error .ant-input {
    box-shadow: 0 0 0 transparent !important;
  }
  & .ant-input:placeholder-shown {
    font-weight: 100 !important;
  }
`;

export const FormItem = styled(ANTDForm.Item)`
  @keyframes fadeBackground {
    0% {
      background-color: #ffffe7;
    }
    100% {
      background-color: white;
    }
  }
  @-webkit-keyframes fadeBackground {
    0% {
      background-color: #ffffe7;
    }
    100% {
      background-color: white;
    }
  }

  * .ant-form-item-explain {
    font-size: 12px;
    color: var(--neutral_medium);
    line-height: 20px;
  }

  /* & label {
    width: 100%;
  } */
  &.ant-form-item-has-error {
    & .ant-form-item-control-input {
      & .ant-select-in-form-item {
        background-color: transparent !important;

        & .ant-select-selector {
          background-color: transparent;
        }
      }
      &[class^='ant-'],
      [class*=' ant-'] {
        &[class$='-focused'],
        [class*='-focused '] {
          background-color: #ffffe7 !important;
          animation-name: fadeBackground;
        }
      }
    }

    & label {
      color: var(--error-medium) !important;
    }
    & .ant-select {
      border-bottom: 1px solid var(--error-medium) !important;
    }
    & .ant-form-item-explain-error {
      font-size: 12px;
    }
    & .ant-form-item-explain-error > [role='alert'] {
      ${(props) => {
        const requiredRule = props?.rules?.find((rule: any) => rule.required);
        if (requiredRule) {
          return `
                        position: absolute;
                        background: var(--error-medium);
                        padding: 1px 8px 2px;
                        color: #fff;
                        border-radius: 2px;
                        right: 30px;
                        top: 5px;
                        font-size: 10px;
                    `;
        }
      }}
    }
  }
  &.horizontal {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: flex-end !important;
    align-items: center !important;
    margin-bottom: 5px !important;
    & .ant-form-item-control {
      flex-grow: 0 !important;
      height: 16px;
      & .ant-form-item-control-input {
        display: inline-block;
        height: 16px;
        min-height: auto;
      }
    }
    & .ant-form-item-label {
      margin-left: 10px;
      line-height: 14px;
      padding-top: 6px;
      padding-bottom: 3px;
      & label {
        font-size: 14px;
        line-height: 14px;
        cursor: pointer !important;
      }
    }
  }

  & textarea {
    border: 1px solid var(--neutral_light);
    box-shadow: 0px 0px transparent;
    &:hover {
      border: 1px solid var(--neutral_light);
    }
    &:focus {
      box-shadow: 0px 0px transparent;
      border: 1px solid var(--green-sea_medium);
    }
    &[readonly] {
      color: var(--neutral_light);
    }
  }

  & .ant-select-selection-item {
    font-weight: 600;
  }
`;
