import { Drawer, GenericDrawerHeader } from '../../../../components';
import { OperationProductTypeDescription } from '../../../../types';
import { ProductOperationDetailsDrawerHeader } from './styles';

type OperationProductDetailsDrawerProps = {
  product?: OperationProductTypeDescription;
  isVisible: boolean;
  onCloseDrawer?: () => void;
  isVehicles?: boolean;
};
export function OperationProductDetailsDrawer(
  props: OperationProductDetailsDrawerProps
) {
  const { product, onCloseDrawer, isVisible, isVehicles } = props;

  function drawerHeaderTitle() {
    return (
      <ProductOperationDetailsDrawerHeader>
        <div className="manifest-code">
          Manifesto:
          {/* <span className="code">{product?.manifest_code}</span> */}
        </div>
        <h1>{product?.cargo_type}</h1>
        <h2>{product?.product_group}</h2>
        {/* {!isVehicles && (
          <Tag className="rounded">{product?.manifest_total} ton</Tag>
        )} */}
      </ProductOperationDetailsDrawerHeader>
    );
  }

  return (
    <Drawer width={600} closable={false} visible={isVisible}>
      <GenericDrawerHeader
        title={drawerHeaderTitle()}
        showBackButton
        onBack={onCloseDrawer}
      />
      {/* <MerchantCeSection merchantsCe={product?.merchants_ce || []} /> */}
    </Drawer>
  );
}
