import { ColumnsType } from 'antd/lib/table';

import {
  capitalizeFirstLetter,
  formatNumberToLocale,
} from '../../../../../../utils/utils';

export function vehiclesStorageSumaryTableColumns(): ColumnsType<
  Record<string, any>
> {
  return [
    {
      title: 'Unidades',
      key: 'total_amount',
      dataIndex: 'total_amount',
      width: '5%',
      align: 'right' as const,
    },
    {
      title: 'Armazenamento',
      key: 'storage_name',
      dataIndex: 'storage_name',
      render: (value: any) => {
        return capitalizeFirstLetter(value);
      },
      width: '95%',
    },
  ];
}
