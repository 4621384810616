import { Dispatch, SetStateAction, useState } from 'react';

import {
  Button,
  Descriptions,
  FormatItem,
  Title,
} from '../../../../../../components';
import {
  OperationCraneTypeDescription,
  Stopover as StopoverType,
} from '../../../../../../types';
import { convertToPercent } from '../../../../../../utils/utils';
import { PartialTotalResumeDescription } from '../../partialTotalResumeDescription';
import { OperationResumeDescriptionContainer } from '../../styles';
import { ContainersByCraneModal } from './containersByCraneModal/ContainersByCraneModal';

type CraneResumeDescriptionProps = {
  operationCrane: OperationCraneTypeDescription;
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  pageContext?: 'OPERATOR_AREA';
};

const { Item } = Descriptions;

export function CraneResumeDescription(props: CraneResumeDescriptionProps) {
  const { operationCrane, selectedStopover, setSelectedStopover, pageContext } =
    props;
  const [
    isVisibleContainersByCraneDrawer,
    setIsVisibleContainersByCraneDrawer,
  ] = useState(false);
  return (
    <>
      <ContainersByCraneModal
        craneName={operationCrane.crane}
        containers={operationCrane.containers || []}
        isVisible={isVisibleContainersByCraneDrawer}
        setIsVisible={setIsVisibleContainersByCraneDrawer}
        operationCraneId={operationCrane.id}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        craneId={operationCrane.crane_id}
        pageContext={pageContext}
      />
      <OperationResumeDescriptionContainer>
        <div className="infos">
          <div className="partial-total">
            <Title>Progresso total</Title>
            <Descriptions>
              <Item label="Total % operado">
                <FormatItem>
                  {convertToPercent(
                    operationCrane.containers_is_finished,
                    operationCrane.containers?.length || 1
                  )}
                </FormatItem>
              </Item>
              <Item label="Quantidade operada">
                <FormatItem>
                  <Button
                    style={{ marginTop: '-8px', marginLeft: '-10px' }}
                    type="link"
                    onClick={() => setIsVisibleContainersByCraneDrawer(true)}
                  >
                    {operationCrane.containers_is_finished || 0} operado(s) (
                    {operationCrane.containers &&
                    operationCrane.containers?.length > 0
                      ? operationCrane.containers.length -
                        operationCrane.containers_is_finished
                      : 0}{' '}
                    pendente(s))
                  </Button>
                </FormatItem>
              </Item>
            </Descriptions>
          </div>
          <PartialTotalResumeDescription
            operationLength={operationCrane.operation_length}
            netProduction={operationCrane.net_production}
            grossProduction={operationCrane.gross_production}
            operatingHours={operationCrane.operating_hours}
            nonWorkingHours={operationCrane.non_working_hours}
            handledTotal={operationCrane.total_handled_str || null}
            totalStoppages={0}
            setIsStoppagesVisible={() => {}}
          />
        </div>
      </OperationResumeDescriptionContainer>
    </>
  );
}
