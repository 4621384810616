import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Collapse, Form } from '../../../components';
import {
  useCreateBreakdownOccurrenceMutation,
  useDeleteBreakdownOccurrenceMutation,
  useUpdateBreakdownOccurrenceMutation,
} from '../../../services/breakdownOccurrenceApi';
import {
  Docking as DockingType,
  BreakdownOccurrenceType,
} from '../../../types';
import { formatBreakdownOccurrenceToSave } from './formatToSave';
import { OccurrenceFormItems } from './occurrenceFormItems';
import { PanelHeaderTitle } from './panelHeaderTitle';
import { BreakdownOccurenceCollapse } from './styles';

type OccurenceFormProps = {
  index: number;
  dockings: DockingType[] | null | undefined;
  occurenceSelected: BreakdownOccurrenceType;
  setOccurences: (value: any) => void;
  stopoverId?: number;
};

const { Panel } = Collapse;

export function OccurenceForm(props: OccurenceFormProps) {
  const { index, dockings, occurenceSelected, setOccurences, stopoverId } =
    props;

  const [form] = Form.useForm();

  const [isChanged, setIsChanged] = useState(false);
  const [updatedAt, setUpdatedAt] = useState<string | null | undefined>(
    occurenceSelected?.updated_at
  );

  const [
    createOccurence,
    { isSuccess: isSucessCreateOccurence, data: createCurrenceData },
  ] = useCreateBreakdownOccurrenceMutation();
  const [
    updateOccurence,
    { isSuccess: isSucessUpdateOccurence, data: updateCurrenceData },
  ] = useUpdateBreakdownOccurrenceMutation();
  const [deleteOccurence, { isSuccess: isSuccessDeleteOccurence }] =
    useDeleteBreakdownOccurrenceMutation();

  useEffect(() => {
    if (isSucessCreateOccurence && createCurrenceData) {
      message.success('Ocorrência criada com sucesso');
      setUpdatedAt(
        moment(createCurrenceData?.updated_at).format('DD/MM/YYYY HH:mm')
      );
    }
  }, [isSucessCreateOccurence]);

  useEffect(() => {
    // spread operator doesnt work to set fields I don't know why
    form.setFieldsValue({
      description: occurenceSelected.description,
      date: occurenceSelected.date,
      id: occurenceSelected.id,
      primary_type: occurenceSelected.primary_type,
      duty: occurenceSelected.duty,
      on_duty: occurenceSelected.on_duty,
      docking: occurenceSelected.docking,
      breakdown_occurrence_type: occurenceSelected.breakdown_occurrence_type,
      shipowner: occurenceSelected.shipowner,
      equipments: occurenceSelected.equipments,
      fenders: occurenceSelected.fenders,
      bollards: occurenceSelected.bollards,
    });
  }, [occurenceSelected]);

  useEffect(() => {
    if (isSucessUpdateOccurence && updateCurrenceData) {
      message.success('Ocorrência atualizada com sucesso');
      setUpdatedAt(
        moment(updateCurrenceData?.updated_at).format('DD/MM/YYYY HH:mm')
      );
    }
  }, [isSucessUpdateOccurence]);

  useEffect(() => {
    if (isSuccessDeleteOccurence) {
      message.success('Ocorrência removida com sucesso');
    }
  }, [isSuccessDeleteOccurence]);

  function removeItemIndex(selectedIndex: number) {
    setOccurences((prevOccurence: BreakdownOccurrenceType[]) =>
      prevOccurence.filter((_, i) => i !== selectedIndex)
    );
  }

  function onSaveOccurence(values: any) {
    values.stopover_id = stopoverId;
    const saveData = formatBreakdownOccurrenceToSave(
      { ...values, primary_type: 'OCCURRENCE' },
      []
    );
    setIsChanged(false);

    if (occurenceSelected.id) {
      saveData.id = occurenceSelected.id;
      updateOccurence(saveData);
    } else {
      createOccurence(saveData);
      removeItemIndex(index);
    }
  }

  function onRemoveOccurence(e: any) {
    e.stopPropagation();
    confirm({
      title: 'Deseja realmente remover esta ocorrência?',
      icon: <ExclamationCircleOutlined />,
      content: 'A ocorrência removida não poderá ser recuperada',
      onOk() {
        if (occurenceSelected?.id) {
          deleteOccurence(occurenceSelected.id);
        } else {
          removeItemIndex(index);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
    <Form
      form={form}
      initialValues={occurenceSelected}
      layout="vertical"
      onValuesChange={() => setIsChanged(true)}
      onFinish={onSaveOccurence}
    >
      <BreakdownOccurenceCollapse expandIconPosition="end">
        <Panel
          key={index}
          header={
            <PanelHeaderTitle
              title={`Ocorrência ${index + 1}`}
              isChanged={isChanged}
              onRemove={onRemoveOccurence}
              updatedAt={updatedAt}
            />
          }
        >
          <OccurrenceFormItems dockings={dockings} />
        </Panel>
      </BreakdownOccurenceCollapse>
    </Form>
  );
}
