import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import {
  CeMerchantGroupbyNcmCollapse,
  Descriptions,
  FormatItem,
  InnerCollapse,
  Tag,
  Title,
} from '../../../../components';
import { useLazyGetNcmItemsGroupByOperatorQuery } from '../../../../services/ncmItemApi';
import {
  NcmItemGroupByConsigneeType,
  OperationProductAndCraneType,
} from '../../../../types';
import { operationDirectionList } from '../../../../utils/lists';
import { OperationProductManifestHeader } from './styles';

const { Panel } = InnerCollapse;
const { Item } = Descriptions;

type OperationProductManifestsType = {
  operationProduct?: OperationProductAndCraneType;
};

export function OperationProductManifests(
  props: OperationProductManifestsType
) {
  const { operationProduct } = props;
  const [
    getNcmItemGroupByConsignee,
    {
      data: ncmItemGroupByOperatorData,
      isFetching: isFetchingNcmItemGroupByOperator,
    },
  ] = useLazyGetNcmItemsGroupByOperatorQuery();

  function onChangeActive(key: string | string[]) {
    if (key !== undefined) getNcmItemGroupByConsignee(Number(key));
  }

  function getHeader(manifest_code: string, direction: string) {
    return (
      <OperationProductManifestHeader>
        <span>Manifesto</span>
        <span className="manifest-code"> {manifest_code}</span>
        <Tag style={{ marginLeft: '10px' }}>
          {direction === 'LOADING' ? (
            <ArrowUpOutlined />
          ) : (
            <ArrowDownOutlined />
          )}{' '}
          {operationDirectionList.find((item) => item.id === direction)?.name}
        </Tag>
      </OperationProductManifestHeader>
    );
  }

  return (
    <div>
      <Title>MANIFESTOS</Title>
      <InnerCollapse
        expandIconPosition="end"
        style={{
          marginTop: '16px',
        }}
        onChange={onChangeActive}
        accordion
      >
        {operationProduct?.operation_manifests?.map((operationManifest) => {
          return (
            <Panel
              header={getHeader(
                operationManifest.manifest_code,
                operationManifest.operation_direction || ''
              )}
              key={
                operationManifest.id || Number(operationManifest.manifest_code)
              }
            >
              <div style={{ padding: '16px' }}>
                <Descriptions column={2}>
                  <Item label="Porto de carregamento">
                    <FormatItem>
                      {operationManifest.loading_port?.name}
                    </FormatItem>
                  </Item>
                  <Item label="Porto de descarregamento">
                    <FormatItem>
                      {operationManifest.unloading_port?.name}
                    </FormatItem>
                  </Item>
                  <Item label="Navegação da carga">
                    <FormatItem>
                      {operationManifest.cargo_navigation_type?.description}
                    </FormatItem>
                  </Item>
                  <Item label="Sentido do produto">
                    <FormatItem>
                      {operationManifest.operation_direction === 'UNLOADING'
                        ? 'Descarga'
                        : 'Carga'}
                    </FormatItem>
                  </Item>
                  <Item label="Tipo de operação da carga">
                    <FormatItem>
                      {operationManifest.operation_type?.description}
                    </FormatItem>
                  </Item>
                  <Item label="Total manifestado">
                    <FormatItem>
                      {operationManifest.manifest_total} ton
                    </FormatItem>
                  </Item>
                  <Item label="Total a ser operado">
                    <FormatItem>
                      {operationProduct.total_weight_handled} ton
                    </FormatItem>
                  </Item>
                </Descriptions>
                <CeMerchantGroupbyNcmCollapse
                  isFetchingNcmItemGroupByOperator={
                    isFetchingNcmItemGroupByOperator
                  }
                  ncmItemGroupByOperatorData={
                    ncmItemGroupByOperatorData ||
                    ({} as NcmItemGroupByConsigneeType)
                  }
                />
              </div>
            </Panel>
          );
        })}
      </InnerCollapse>
    </div>
  );
}
