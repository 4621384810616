import { Form, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, FormItemRadioGroup, FormItemTextArea } from '../../components';
import { LayoutLogin } from '../../components/common/layoutLogin/LayoutLogin';
import {
  useGetFinancialTableTemporaryAcessQuery,
  usePatchFinancialTableMutation,
} from '../../services/financialTableTemporaryAcessApi';
import { createDateStringPtBr, sumDaysToDate } from '../../utils/utils';
import { Attention, Container } from './styles';

export function ConfirmFinancialOfficers() {
  const [form] = Form.useForm();
  const [send, setSend] = useState(false);
  const [isCompanyConfirmed, setIsCompanyConfirmed] = useState(false);
  const { token, table_id } = useParams();

  const { data: financialTableData } = useGetFinancialTableTemporaryAcessQuery({
    authorization: token || '',
    id: Number(table_id),
  });

  const [patchFinancialTable, { isSuccess: isSucessPatchFinancialTable }] =
    usePatchFinancialTableMutation();

  useEffect(() => {
    if (isSucessPatchFinancialTable) {
      setSend(true);
      message.info('Confirmação enviada com sucesso');
    }
  }, [isSucessPatchFinancialTable]);

  async function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      await patchFinancialTable({
        ...values,
        authorization: token || '',
        id: table_id,
      });
    });
  }

  return (
    <LayoutLogin>
      {financialTableData?.financial_table__id ? (
        <Container>
          <h1 className="title">
            Confirmação de dados do responsável financeiro
          </h1>
          <div>
            <span>
              Olá, {financialTableData.financial_table__company__name}!
            </span>
            <br />
            <span>
              Um representante da empresa{' '}
              {financialTableData.stopover__shipping_agency__name
                ? financialTableData.stopover__shipping_agency__name
                : '(Agência não informada)'}{' '}
              informou que {financialTableData.financial_table__company__name}{' '}
              será responsável pelo pagamento de algumas tabelas de faturamento
              ao porto de Suape, referentes aos dados abaixo:
            </span>
            <br />
            <br />
            <span>Escala: </span>
            <span className="strong">{financialTableData.stopover__code}</span>
            <br />
            <span>Embarcação: </span>
            <span className="strong">
              {financialTableData.stopover__vessel__name}
            </span>
            <br />
            <span>Cód. atracação: </span>
            <span className="strong">
              {financialTableData.docking__code
                ? financialTableData.docking__code
                : 'Não gerado'}
            </span>
            <br />
            <span>Atracação: </span>
            <span className="strong">
              {createDateStringPtBr(
                financialTableData.docking__expected_berthing
              )}
            </span>
          </div>
          <Attention>
            <span className="title">ATENÇÃO:</span>
            <div>
              <span>O prazo de envio dessa confirmação é de </span>
              <span className="warning">48 horas</span>
              <span> antes da atracação prevista (confirme até </span>
              <span className="warning">
                {financialTableData.docking__expected_berthing
                  ? sumDaysToDate(
                      financialTableData.docking__expected_berthing,
                      2
                    )
                  : 'Não informado'}
              </span>
              <span>)</span>
            </div>
          </Attention>
          <div>
            {send === true ||
            financialTableData.financial_table__company_confirmed === true ? (
              <h1>Confirmação enviada com sucesso</h1>
            ) : (
              <Form
                form={form}
                autoComplete="off"
                layout="vertical"
                name="confirm_financial_officer"
                id="confirm_financial_officer"
                onFinish={handleFormSubmit}
              >
                <Row gutter={24} align="bottom">
                  <FormItemRadioGroup
                    label=""
                    name="company_confirmed"
                    required
                    isOptionTypeDefault
                    onChange={(e) => setIsCompanyConfirmed(e.target.value)}
                    options={[
                      {
                        label:
                          'Confirmo que sou o responsável financeiro pela escala descrita acima',
                        value: true,
                      },
                      {
                        label:
                          'Não sou o responsável financeiro correto da escala descrita acima',
                        value: false,
                      },
                    ]}
                  />
                </Row>
                {!isCompanyConfirmed && (
                  <Row gutter={24} align="bottom">
                    <FormItemTextArea
                      name="rejection_reason"
                      label="Informe o motivo da recusa"
                      rows={3}
                      maxLength={160}
                      required
                      placeholder="Exemplo: Problema com  os dados da embarcação"
                    />
                  </Row>
                )}
                <br />
                <Button
                  type="primary"
                  htmlType="submit"
                  form="confirm_financial_officer"
                >
                  Enviar
                </Button>
              </Form>
            )}
          </div>
        </Container>
      ) : (
        <h1>Token não válido</h1>
      )}
    </LayoutLogin>
  );
}
