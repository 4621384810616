import { Dispatch, SetStateAction, useState } from 'react';

import {
  OperationalOperationType,
  OperationCalculusType,
  OperationContextType,
  OperationProductAndCraneType,
  OperationProductType,
  Stopover as StopoverType,
} from '../../../types';
import { OperationProductsEditDrawer } from '../../operation/OperationsForm/operationProductsEditDrawer/OperationProductsEditDrawer';
import { OperationProductsDescription } from './operationProductDescription';

type OperationProductsDataProps = {
  selectedOperationProducts: OperationProductAndCraneType[];
  operation: OperationalOperationType;
  operationType: OperationContextType;
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  operationCalculusData: OperationCalculusType;
  isProvisioned?: boolean;
};

export function OperationProductsData(props: OperationProductsDataProps) {
  const {
    selectedOperationProducts,
    operation,
    operationType,
    selectedStopover,
    setSelectedStopover,
    activeKey,
    onOpenPanel,
    operationCalculusData,
    isProvisioned,
  } = props;

  const [isProductEditDrawerVisible, setIsProductEditDrawerVisible] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<
    OperationProductType | undefined
  >({} as OperationProductType);

  function onBack() {
    setIsProductEditDrawerVisible(false);
  }

  function onEdit(evt: any, index: number) {
    evt.stopPropagation();
    setSelectedProduct(selectedOperationProducts[index]);
    setIsProductEditDrawerVisible(true);
  }

  return (
    <>
      <OperationProductsEditDrawer
        visible={isProductEditDrawerVisible}
        setIsVisibleForm={setIsProductEditDrawerVisible}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        width={800}
        onBack={onBack}
      />
      <OperationProductsDescription
        selectedOperationProducts={
          selectedOperationProducts as OperationProductAndCraneType[]
        }
        operation={operation}
        operationType={operationType}
        activeKey={activeKey}
        onOpenPanel={onOpenPanel}
        onEdit={onEdit}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        operationCalculusData={operationCalculusData}
        isProvisioned={isProvisioned}
      />
    </>
  );
}
