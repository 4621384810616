import styled from 'styled-components';

export const OperatorAreaFormHeader = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: auto;
  z-index: 1;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
  padding: 16px 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: 16px;
  padding-inline-end: 16px;
  height: 72px;

  .title {
    font-size: 16px;
    color: var(--suape-black);
    font-weight: 700;
    margin-inline-start: 10px;
  }
`;
