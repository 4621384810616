import { message } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { Form, FormItemSelect, Modal } from '../../../../components';
import { usePatchManifestMutation } from '../../../../services/stopoverApi';
import { useGetSystemParamByNameQuery } from '../../../../services/systemParamsApi';
import { CargoManifestType, SystemParamItemType } from '../../../../types';

type EditCargoNavigationTypeModalProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  handleCancel: () => void;
  selectedCargoManifest: CargoManifestType;
  isContextPending?: boolean;
  onAfterSubmit?: (manifestCode: string) => void;
  setUploadManifestsData: Dispatch<SetStateAction<CargoManifestType[]>>;
};

export function EditCargoNavigationTypeModal(
  props: EditCargoNavigationTypeModalProps
) {
  const {
    isVisible,
    setIsVisible,
    handleCancel,
    selectedCargoManifest,
    isContextPending,
    onAfterSubmit,
    setUploadManifestsData,
  } = props;

  const [form] = Form.useForm();

  const {
    data: cargoNavigationTypesData,
    isLoading: isLoadingCargoNavigationTypesData,
  } = useGetSystemParamByNameQuery({
    name: 'CARGO_NAVIGATION_TYPE',
  });

  const [patchManifest, { isLoading: isLoadingPatchManifest }] =
    usePatchManifestMutation();

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      patchManifest({
        id: selectedCargoManifest.id,
        cargo_navigation_type: values.cargo_navigation_type?.id,
      }).then((response) => {
        console.log(response);
        if ('data' in response) {
          setIsVisible(false);
          message.success('Navegação da carga atualizada com sucesso');
          setUploadManifestsData((prev) =>
            prev.map((manifest) => {
              if (manifest.id === selectedCargoManifest.id) {
                return {
                  ...manifest,
                  cargo_navigation_type:
                    cargoNavigationTypesData?.items.find(
                      (item) => item.id === values.cargo_navigation_type.id
                    ) || ({} as SystemParamItemType),
                };
              }
              return manifest;
            })
          );
          if (onAfterSubmit) onAfterSubmit(selectedCargoManifest.code);
        }
      });
    });
  }

  return (
    <Modal
      className={isContextPending ? 'TOSWarningModal' : 'TOSPrimaryModal'}
      width={520}
      title={
        isContextPending ? 'Navegação da carga pendente' : 'Navegação da carga'
      }
      visible={isVisible}
      destroyOnClose
      onCancel={() => {
        form.resetFields();
        handleCancel();
      }}
      cancelText="Voltar"
      okText={isContextPending ? 'Continuar' : 'Salvar'}
      onOk={handleFormSubmit}
      confirmLoading={isLoadingPatchManifest}
      okButtonProps={{ style: { marginRight: '16px' } }}
    >
      <Form
        form={form}
        name="edit_cargo_navigation_type"
        id="edit_cargo_navigation_type"
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={selectedCargoManifest}
      >
        {isContextPending && (
          <span>
            Para continuar esta ação, será necessário preencher a informação
            abaixo:
          </span>
        )}
        <FormItemSelect
          label="Navegação da carga"
          name={['cargo_navigation_type', 'id']}
          dataList={cargoNavigationTypesData?.items}
          required
          showSearch
          isLoading={isLoadingCargoNavigationTypesData}
        />
      </Form>
    </Modal>
  );
}
