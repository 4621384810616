import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

function SunSvg() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 13"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99855 4.94456C4.86309 4.94456 3.94261 5.86504 3.94261 7.00051C3.94261 8.13597 4.86309 9.05645 5.99855 9.05645C7.13402 9.05645 8.0545 8.13597 8.0545 7.00051C8.0545 5.86504 7.13402 4.94456 5.99855 4.94456ZM3.01953 7.00051C3.01953 5.35524 4.35328 4.02148 5.99855 4.02148C7.64382 4.02148 8.97757 5.35524 8.97757 7.00051C8.97757 8.64577 7.64382 9.97953 5.99855 9.97953C4.35328 9.97953 3.01953 8.64577 3.01953 7.00051Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1C6.2549 1 6.46154 1.20664 6.46154 1.46154V2.46853C6.46154 2.72343 6.2549 2.93007 6 2.93007C5.7451 2.93007 5.53846 2.72343 5.53846 2.46853V1.46154C5.53846 1.20664 5.7451 1 6 1ZM1.74637 2.74637C1.92661 2.56613 2.21884 2.56613 2.39908 2.74637L3.10398 3.45127C3.28422 3.63151 3.28422 3.92374 3.10398 4.10398C2.92374 4.28422 2.63151 4.28422 2.45127 4.10398L1.74637 3.39908C1.56613 3.21884 1.56613 2.92661 1.74637 2.74637ZM10.2536 2.74637C10.4339 2.92661 10.4339 3.21884 10.2536 3.39908L9.54873 4.10398C9.36849 4.28422 9.07626 4.28422 8.89602 4.10398C8.71578 3.92374 8.71578 3.63151 8.89602 3.45127L9.60092 2.74637C9.78116 2.56613 10.0734 2.56613 10.2536 2.74637ZM0 7C0 6.7451 0.206638 6.53846 0.461538 6.53846H1.46853C1.72343 6.53846 1.93007 6.7451 1.93007 7C1.93007 7.2549 1.72343 7.46154 1.46853 7.46154H0.461538C0.206638 7.46154 0 7.2549 0 7ZM10.0699 7C10.0699 6.7451 10.2766 6.53846 10.5315 6.53846H11.5385C11.7934 6.53846 12 6.7451 12 7C12 7.2549 11.7934 7.46154 11.5385 7.46154H10.5315C10.2766 7.46154 10.0699 7.2549 10.0699 7ZM3.10398 9.89602C3.28422 10.0763 3.28422 10.3685 3.10398 10.5487L2.39908 11.2536C2.21884 11.4339 1.92661 11.4339 1.74637 11.2536C1.56613 11.0734 1.56613 10.7812 1.74637 10.6009L2.45127 9.89602C2.63151 9.71578 2.92374 9.71578 3.10398 9.89602ZM8.89602 9.89602C9.07626 9.71578 9.36849 9.71578 9.54873 9.89602L10.2536 10.6009C10.4339 10.7812 10.4339 11.0734 10.2536 11.2536C10.0734 11.4339 9.78116 11.4339 9.60092 11.2536L8.89602 10.5487C8.71578 10.3685 8.71578 10.0763 8.89602 9.89602ZM6 11.0699C6.2549 11.0699 6.46154 11.2766 6.46154 11.5315V12.5385C6.46154 12.7934 6.2549 13 6 13C5.7451 13 5.53846 12.7934 5.53846 12.5385V11.5315C5.53846 11.2766 5.7451 11.0699 6 11.0699Z"
      />
    </svg>
  );
}

export function SunIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={SunSvg} {...props} />;
}
