import styled from 'styled-components';

import { LightTable } from '../../../../../../components';

export const MovementDescription = styled.div`
  padding: 16px;
  background-color: #fff;

  & .ant-collapse {
    & .ant-collapse-content {
      border: 0px !important;
    }

    & .ant-collapse-item:first-child {
      margin-top: 0px;
    }
  }
`;

export const ManifestCollapseHeader = styled.div`
  & .manifest-number {
    font-family: Consolas;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
  }
`;

export const ConsigneeDataHeader = styled.div`
  & .title {
    font-weight: 700;
    font-size: 12px;
  }
  & .code {
    font-family: Segoe UI;
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
    color: var(--neutral_medium);
  }
`;

export const ConsigneeDataContainer = styled.div`
  border-top: 1px dashed #a0bace;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;

  &:first-of-type {
    border-top: none !important;
    padding-top: 0px;
  }
`;

export const ConsigneeTableHeader = styled.div`
  margin-top: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 12px;
  background-color: #ebf2f7;
  color: var(--neutral_medium);
  font-size: 12px;
  border-bottom: 1px solid var(--neutral_lighter);

  & .code {
    font-weight: 700;
  }
`;

export const ConsigneesTable = styled(LightTable)`
  table {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;

    thead > tr > th.ant-table-cell {
      text-transform: none !important;
    }

    & .ant-table-summary {
      background: #ebf2f7 !important;

      td {
        padding: 4px 16px 4px 16px;
        font-weight: 600;
        font-size: 12px;
        color: var(--neutral_dark);
        border-bottom: none;

        &:first-child {
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }
`;
