export function ReportIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4727_25954)">
        <path d="M16.8748 16.0569H6.12476V6.30688C6.12476 6.23813 6.06851 6.18188 5.99976 6.18188H5.12476C5.05601 6.18188 4.99976 6.23813 4.99976 6.30688V17.0569C4.99976 17.1256 5.05601 17.1819 5.12476 17.1819H16.8748C16.9435 17.1819 16.9998 17.1256 16.9998 17.0569V16.1819C16.9998 16.1131 16.9435 16.0569 16.8748 16.0569ZM7.77788 13.6459C7.82632 13.6944 7.90444 13.6944 7.95444 13.6459L10.1154 11.4959L12.1091 13.5022C12.1576 13.5506 12.2373 13.5506 12.2857 13.5022L16.5888 9.20064C16.6373 9.1522 16.6373 9.07251 16.5888 9.02407L15.9701 8.40532C15.9466 8.38206 15.9149 8.36901 15.8818 8.36901C15.8487 8.36901 15.817 8.38206 15.7935 8.40532L12.1998 11.9975L10.2091 9.99439C10.1856 9.97112 10.1539 9.95807 10.1209 9.95807C10.0878 9.95807 10.0561 9.97112 10.0326 9.99439L7.16069 12.8491C7.13743 12.8726 7.12438 12.9043 7.12438 12.9374C7.12438 12.9704 7.13743 13.0021 7.16069 13.0256L7.77788 13.6459Z" />
      </g>
      <defs>
        <clipPath id="clip0_4727_25954">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(4 4.6814)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
