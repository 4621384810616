import { EditFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table/interface';
import moment from 'moment';

import { Button, StatusTag } from '../../../../../components';
import { createDateStringPtBr } from '../../../../../utils/utils';

export function columns(
  onSelectItem: (item: any, index: number) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title: 'Manutenções',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
    },
    {
      title: 'Início',
      key: 'expected_start',
      dataIndex: 'expected_start',
      width: '21%',
      render: (value: any, record: any) => (
        <span>{createDateStringPtBr(value)}</span>
      ),
    },
    {
      title: 'Fim',
      key: 'expected_finish',
      dataIndex: 'expected_finish',
      width: '21%',
      render: (value: any, record: any) => (
        <span>{createDateStringPtBr(value)}</span>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: '20%',
      render: (_: any, record: any) => (
        <StatusTag>{getStatusLabel(record)}</StatusTag>
      ),
    },
    {
      render: (_: any, record: any, index: number) => (
        <Button
          icon={<EditFilled />}
          style={{ float: 'right' }}
          onClick={(e: any) => {
            e.stopPropagation();
            onSelectItem(record, index);
          }}
        />
      ),
    },
  ];

  function getStatusLabel(record: any) {
    const now = moment();
    const expectedStart = moment(record.expected_start);
    const expectedFinish = moment(record.expected_finish);

    return now.isBefore(expectedStart) ? (
      <span className="blue">Prevista</span>
    ) : now.isSameOrAfter(expectedStart) && now.isBefore(expectedFinish) ? (
      <span className="green">Em Andamento</span>
    ) : (
      <span className="ocean-blue">Finalizada</span>
    );
  }
}
