import { PlusOutlined } from '@ant-design/icons';
import { Alert, message } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { Dispatch, SetStateAction } from 'react';

import {
  Button,
  Form,
  GenericDrawerHeader,
  ModalBlockNavigation,
} from '../../../../components';
import { useUpdateCompartmentsMutation } from '../../../../services/compartmentsApi';
import {
  OperationProductCompartmentType,
  OperationProductType,
  Stopover,
} from '../../../../types';
import { CompartmentsTable } from './compartmentsTable/CompartmentsTable';
import { CompartmentsDrawerHeader } from './styles';

type CompartmentEditFormProps = {
  onFinishSubmit: () => void;
  onBack: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  setIsVisibleForm: (value: boolean) => void;
  blockNavigate: boolean;
  selectedProduct: OperationProductType;
  selectedStopover: Stopover;
  setSelectedStopover?: Dispatch<SetStateAction<Stopover>>;
};

export function CompartmentEditForm(props: CompartmentEditFormProps) {
  const {
    onFinishSubmit,
    onBack,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    setIsVisibleForm,
    blockNavigate,
    selectedProduct,
    selectedStopover,
    setSelectedStopover,
  } = props;
  const [form] = Form.useForm();

  // update operation product hook
  const [updateCompartments] = useUpdateCompartmentsMutation();

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      setIsVisibleForm(false);
    }
  }

  function afterCloseModal() {
    form.resetFields();
  }

  function drawerHeaderTitle() {
    return (
      <CompartmentsDrawerHeader>
        <div className="cargo">{selectedProduct.cargo_type?.name}</div>
        <h1>Adicionar compartimento</h1>
      </CompartmentsDrawerHeader>
    );
  }

  function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        const compartments: OperationProductCompartmentType[] =
          values.compartments?.map((item: OperationProductCompartmentType) => {
            return {
              ...item,
              operation_product_id: selectedProduct.id,
            };
          });

        const updatedProduct = await updateCompartments(compartments);

        if ('data' in updatedProduct && setSelectedStopover) {
          const items = selectedStopover.operation.items?.map(
            (item: OperationProductType) => {
              if (item.id === selectedProduct.id) {
                return {
                  ...item,
                  compartments: updatedProduct.data,
                };
              }
              return item;
            }
          );
          setSelectedStopover((prev) => ({
            ...prev,
            operation: { ...selectedStopover.operation, items },
          }));

          message.success('Alterações salvas com sucesso!', 5);
        }
      })
      .finally(() => {
        onFinishSubmit();
        setBlockNavigate(false);
        setIsFormChanged(false);
        setIsVisibleForm(false);
      });
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name="compartments_edit_form"
      id="compartments_edit_form"
      onFinish={handleFormSubmit}
      initialValues={selectedProduct}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <GenericDrawerHeader
        title={drawerHeaderTitle()}
        showBackButton
        onBack={onClose}
        formName="compartments_edit_form"
        disabledSaveButton={!formIsChanged}
      />
      <ModalBlockNavigation
        name="compartments_edit_form"
        key="compartments_edit_form"
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <div style={{ padding: '16px' }}>
        <FormList name={['compartments']}>
          {(compartments, { add: addItem, remove: removeItem }) => {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => addItem({ unit: 'TONNES' })}
                  >
                    Adicionar compartimento
                  </Button>
                  <Alert
                    type="info"
                    message="Cadastre apenas os compartimentos que contém este produto."
                  />
                </div>

                <CompartmentsTable
                  compartments={compartments}
                  onRemoveItem={removeItem}
                  form={form}
                  setIsFormChanged={setIsFormChanged}
                  selectedStopover={selectedStopover}
                  setSelectedStopover={setSelectedStopover}
                  selectedProduct={selectedProduct}
                />
              </>
            );
          }}
        </FormList>
      </div>
    </Form>
  );
}
