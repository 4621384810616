import styled from 'styled-components';

export const DescriptionSection = styled.div`
  padding: 0 16px;
`;

export const ShiftContainer = styled.div`
  border: 1px solid var(--neutral_lighter);
  border-radius: 5px;
  padding-top: 15px;
`;

export const OperationResumeDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ebf2f7;
  outline: 16px solid #ebf2f7;

  .infos {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .production {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .production-item {
      background-color: #fff;
      padding-left: 8px;
      border-radius: 4px;
    }
  }

  .partial-total {
    background-color: #fff;
    padding-top: 4px;
    padding-left: 8px;
    border-radius: 4px;
  }
`;

export const OperationProductManifestHeader = styled.div`
  .manifest-code {
    font-family: Consolas;
    font-weight: normal;
  }
`;
