import { Table as ANTDTable } from 'antd';
import styled from 'styled-components';

export const Table = styled(ANTDTable).attrs((props) => ({
  amountOfRows: props.pagination ? props.pagination.total : '',
}))`
  & .ant-table-content,
  & .ant-table-container,
  & .ant-table-empty {
    background: transparent;
  }
  & table {
    padding: 5px;
    padding-bottom: 0px;
    padding-top: 0px;
    border-radius: 5px;
    background: var(--neutral_lighter);
    border-spacing: 0 5px;
  }

  & .ant-table-header > table {
    border-radius: 5px 5px 0 0;
  }
  & .ant-table-body > table {
    border-radius: 0;
  }

  & .ant-table-body {
    max-height: 570px;
  }

  & .ant-table-footer {
    background: #ebf2f7;
    margin-top: -5px;
  }

  @media (max-height: 800px) {
    & .ant-table-body {
      max-height: 370px !important;
    }
  }

  & .ant-table-tbody > tr.ant-table-placeholder > td {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 16px;
  }

  & .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
  }

  & thead {
    & .ant-table-cell-scrollbar {
      display: none;
    }
    & > tr > th.ant-table-cell {
      background: transparent;
      border-bottom: 0px;
      color: var(--neutral_dark);
      text-transform: uppercase;
      &::before {
        display: none;
      }
      & .show-amount::after {
        content: '${(props) => props.amountOfRows}';
        display: ${(props) =>
          props.amountOfRows && props.amountOfRows > 0
            ? 'inline-block'
            : 'none'};
        background: var(--neutral_light);
        padding: 2px 10px;
        border-radius: 20px;
        margin-left: 10px;
        font-family: 'Consolas';
      }
    }
    & .ant-table-column-sorters {
      flex-direction: row-reverse;
    }

    & .ant-table-column-sorters .ant-table-column-sorter {
      color: #205366;
      margin-right: 10px;
      margin-left: -5px;
    }
  }

  & tbody {
    & > tr.ant-table-row {
      background: #fff;
      &.disabled-user {
        background: var(--neutral_lightest);
        color: var(--neutral_medium);
        &:hover {
          background: var(--neutral_lightest);
        }
      }
      &:hover {
        background: var(--neutral_lightest);
      }
    }
    & > tr > .ant-table-cell {
      background: transparent;
      &.ant-table-column-sort {
        background: transparent;
      }
      &.ant-table-cell-row-hover {
        background: transparent;
      }
    }
    & > tr > td:first-child {
      border-radius: 5px 0 0 5px;
    }
    & > tr > td:last-child {
      border-radius: 0 5px 5px 0;
    }
    & > tr > td {
      border-bottom: 0px;
    }
  }
  &.read-only {
    & table {
      padding: 5px;
      padding-bottom: 0px;
      padding-top: 0px;
      border-radius: 5px;
      background: #ebf2f7;
      border-spacing: 0 5px;
      & tbody,
      & thead {
        & > tr {
          padding: 0px;
          & .ant-table-cell {
            padding: 4px 16px 4px 16px;
            font-size: 12px;
          }
        }
      }
      & thead > tr > .ant-table-cell {
        color: var(--ocean_dark);
        font-size: 12px;
      }
    }
    & .ant-table-footer {
      background: #ebf2f7;
      font-size: 12px;
      font-weight: 600;
      color: var(--neutral_dark);
      font-style: normal;
    }
  }

  & .berth-line-row-expanded > td,
  .berth-maintenance-row-expanded > td {
    padding: 0;
  }
`;

export const LightTable = styled(Table)`
  & table {
    background: #ebf2f7 !important;
    & thead > tr > .ant-table-cell {
      color: var(--neutral_medium);
      font-size: 12px;
    }
  }
  & .ant-table-title {
    background: #ebf2f7 !important;
    margin-bottom: -3px;
    border-radius: 5px 5px 0px 0px;
    > div {
      color: var(--neutral_medium);
      border-bottom: 1px solid var(--neutral_lighter);
      padding-bottom: 16px;
    }
  }
  .ant-pagination {
    background-color: #ebf2f7 !important;
    margin: 0px;
    padding: 10px 0;
    & .ant-pagination-item-active {
      background: var(--green-sea_medium);
      & a {
        color: var(--suape-black);
      }
    }
  }
`;

export const SlimTable = styled(LightTable)`
  .expanded-row-stoppage {
    background: #f2f6f9;
  }
  table {
    margin-top: 0px !important;

    & .ant-table-summary {
      background: #ebf2f7 !important;

      td {
        padding: 4px 16px 4px 16px;
        font-weight: 600;
        font-size: 12px;
        color: var(--neutral_dark);
        border-bottom: none;

        &:first-child {
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }
`;
