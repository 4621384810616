import { BerthMaintenance, CompanyType, DockingPlace } from '../../../../types';
import { createDate, createDateString } from '../../../../utils/utils';

export function formatBerthMaintenanceToSave(
  maintenance: BerthMaintenance
): BerthMaintenance {
  return {
    id: maintenance.id,
    description: maintenance.description,
    allow_night_pump: maintenance.allow_night_pump,
    allow_weekend_pump_and_vessel: maintenance.allow_weekend_pump_and_vessel,
    berths: maintenance.berths,
    companies: maintenance.companies,
    expected_start: createDateString(maintenance.expected_start),
    expected_finish: createDateString(maintenance.expected_finish),
  };
}

export function formatBerthMaintenanceToForm(
  maintenance: BerthMaintenance
): BerthMaintenance {
  return {
    id: maintenance.id,
    description: maintenance.description,
    berths: maintenance.id
      ? (maintenance.berths as DockingPlace[]).map(
          (berth: DockingPlace) => berth.tag
        )
      : maintenance.berths,
    companies: maintenance.id
      ? (maintenance.companies as CompanyType[]).map(
          (company: CompanyType) => company.id
        )
      : maintenance.companies,
    responsible: maintenance.responsible,
    allow_night_pump: maintenance.allow_night_pump,
    allow_weekend_pump_and_vessel: maintenance.allow_weekend_pump_and_vessel,
    expected_finish: createDate(maintenance.expected_finish),
    expected_start: createDate(maintenance.expected_start),
  };
}

export function formatBerthMaintenancesToForm(
  maintenances: BerthMaintenance[]
) {
  return maintenances.map((maintenance: BerthMaintenance) =>
    formatBerthMaintenanceToForm(maintenance)
  );
}
