import { InfoCircleFilled } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';

import { Descriptions, FormatItem, Title } from '../../../../components';
import {
  OperationalOperationTypeDescription,
  OperationProductTypeDescription,
} from '../../../../types';
import { DescriptionSection } from './styles';

const { Item } = Descriptions;

type OperationMonitoringDescriptionProps = {
  selectedOperationProduct: OperationProductTypeDescription;
  selectedOperation: OperationalOperationTypeDescription;
};
export function OperationMonitoringDescription(
  props: OperationMonitoringDescriptionProps
) {
  const { selectedOperationProduct, selectedOperation } = props;
  return (
    <DescriptionSection>
      <Title>ACOMPANHAMENTO DA OPERAÇÃO</Title>
      <Descriptions column={2}>
        <Item label="Início do primeiro terno">
          <FormatItem>{selectedOperationProduct.first_gang_start}</FormatItem>
        </Item>
        <Item label="Início da operação">
          <FormatItem>{selectedOperation.real_operation_start}</FormatItem>
        </Item>
        <Item label="Fim do último terno">
          <FormatItem>{selectedOperationProduct.last_gang_finish}</FormatItem>
        </Item>
        <Item label="Fim da operação">
          <FormatItem>{selectedOperation.real_operation_finish}</FormatItem>
        </Item>
      </Descriptions>
      <Descriptions title="Totais de operação">
        <Item label="Duração da operação">
          <FormatItem>{selectedOperationProduct.operation_length}</FormatItem>
        </Item>
        <Item
          label={
            <div>
              <span>Produção líquida </span>
              <Tooltip title="Considera paralisações">
                <InfoCircleFilled />
              </Tooltip>
            </div>
          }
        >
          <FormatItem>{selectedOperationProduct.net_production}</FormatItem>
        </Item>
        <Item label="Produção bruta">
          <FormatItem>{selectedOperationProduct.gross_production}</FormatItem>
        </Item>
        <Item label="Horas operacionais">
          <FormatItem>{selectedOperationProduct.operating_hours}</FormatItem>
        </Item>
        <Item label="Horas paralisadas">
          <FormatItem>{selectedOperationProduct.non_working_hours}</FormatItem>
        </Item>
        <Item label="Total movimentado">
          {/* <FormatItem>{selectedOperationProduct.total_handled_str}</FormatItem> */}
        </Item>
      </Descriptions>
    </DescriptionSection>
  );
}
