import { List as ANTDList } from 'antd';
import styled from 'styled-components';

export const List = styled(ANTDList)`
  & .ant-list-header {
    border-bottom: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: rgb(223 234 241);
  }

  & .ant-list-item {
    border: none;
    padding: 6px 0;
  }
`;
