import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { useState } from 'react';

import { Button, Drawer, InnerCollapse, Title } from '../../components';
import {
  OperationalOperationType,
  Stopover,
  TotalMovimentationByPeriodIdType,
  WorkPeriodType,
} from '../../types';
import { OperationLineDescription } from './operationLineDescripion';
import { LiquidBulkOperationMovimentation } from './operationMovimentation/liquidBulkOperationMovimentation';
import { OperationMovimentation } from './operationMovimentation/operationMovimentation';
import { VehiclesOperationMovimentation } from './operationMovimentation/vehiclesOperationMovimentation';
import { OperationPeriodDescription } from './operationPeriodDescription';
import { OperationLineForm } from './operationPeriodsData/operationLineForm';
import { OperationPeriodForm } from './operationPeriodsData/operationPeriodForm';
import { OperationProductsResumeHeader } from './styles';

type OperationPeriodsProps = {
  operation: OperationalOperationType;
  movimentationProductsByPeriod: TotalMovimentationByPeriodIdType;
  selectedDockingPlaceTag: string;
  operationType: string;
  selectedStopover?: Stopover;
};

const { Panel } = InnerCollapse;

const LIQUID_BULK = 2;
const VEHICLES_PRODUCT_GROUP = 71;

export function OperationPeriods(props: OperationPeriodsProps) {
  const {
    operation,
    movimentationProductsByPeriod,
    selectedDockingPlaceTag,
    operationType,
    selectedStopover,
  } = props;
  const [isVisibleMovimentation, setisVisibleMovimentation] = useState(false);
  const [isVisibleFormDrawer, setisVisibleFormDrawer] = useState(false);

  const [selectedWorkPeriod, setSelectedWorkPeriod] = useState<WorkPeriodType>(
    {} as WorkPeriodType
  );
  const [selectedWorkPeriodIndex, setSelectedWorkPeriodIndex] = useState<
    number | null
  >(null);

  function onClickEditPeriod(
    evt: React.MouseEvent<HTMLElement, MouseEvent>,
    workPeriod: WorkPeriodType,
    workPeriodIndex: number
  ) {
    evt.stopPropagation();
    setSelectedWorkPeriod(workPeriod);
    setisVisibleFormDrawer(true);
    setSelectedWorkPeriodIndex(workPeriodIndex);
  }

  function onCloseDrawer() {
    setisVisibleFormDrawer(false);
    setSelectedWorkPeriod({} as WorkPeriodType);
    setSelectedWorkPeriodIndex(null);
  }

  function getIsDisabledRegisterMovimentation() {
    if (
      operation.operation_status === null ||
      operation.operation_status === 'WAITING_OPERATION'
    ) {
      return true;
    }
    if (
      operation.nature_of_cargo?.id === LIQUID_BULK &&
      operation.operation_lines?.length === 0
    ) {
      return true;
    }
    if (
      operation.nature_of_cargo?.id !== LIQUID_BULK &&
      operation.work_periods.length === 0
    ) {
      return true;
    }
    return false;
  }

  function getHeaderOperationLine(
    lineName: string,
    operationProductId: number
  ) {
    const operationProductCargoTypeName = operation.items?.find(
      (item) => item.id === operationProductId
    )?.cargo_type?.name;
    return (
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        <b>{lineName}</b>
        <span style={{ fontWeight: '400' }}>
          {operationProductCargoTypeName}
        </span>
      </div>
    );
  }

  return (
    <>
      <Drawer
        visible={isVisibleFormDrawer}
        closable={false}
        width={700}
        destroyOnClose
      >
        {operation.nature_of_cargo?.id === LIQUID_BULK ? (
          <OperationLineForm
            onCloseDrawer={() => onCloseDrawer()}
            operation={operation}
            selectedDockingPlaceTag={selectedDockingPlaceTag}
          />
        ) : (
          <OperationPeriodForm
            onCloseDrawer={() => onCloseDrawer()}
            operation={operation}
            selectedWorkPeriod={selectedWorkPeriod}
            setSelectedWorkPeriod={setSelectedWorkPeriod}
            selectedWorkPeriodPosition={selectedWorkPeriodIndex}
            setSelectedWorkPeriodPosition={setSelectedWorkPeriodIndex}
            operationWorkPeriods={operation.work_periods}
          />
        )}
      </Drawer>
      {operation.product_group?.id === VEHICLES_PRODUCT_GROUP ? (
        <VehiclesOperationMovimentation
          isVisible={isVisibleMovimentation}
          operation={operation}
          onClose={setisVisibleMovimentation}
        />
      ) : (
        <Drawer
          visible={isVisibleMovimentation}
          closable={false}
          width={700}
          destroyOnClose
          onClose={() => {
            setisVisibleMovimentation(false);
          }}
        >
          {operation.nature_of_cargo?.id === LIQUID_BULK ? (
            <LiquidBulkOperationMovimentation
              operation={operation}
              selectedStopover={selectedStopover}
              onCloseDrawer={() => setisVisibleMovimentation(false)}
            />
          ) : (
            <OperationMovimentation
              operation={operation}
              selectedStopover={selectedStopover}
              onCloseDrawer={() => setisVisibleMovimentation(false)}
            />
          )}
        </Drawer>
      )}
      <OperationProductsResumeHeader
        style={{ borderTop: '1px solid var(--neutral_lighter)' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title style={{ marginBottom: '0px' }}>TEMPOS</Title>
          <Space size={12}>
            <Tooltip
              title={
                operation.operation_status === null ||
                operation.operation_status === 'WAITING_OPERATION'
                  ? 'Para registrar movimentação primeiro inicie a operação'
                  : getIsDisabledRegisterMovimentation()
                  ? 'Para registrar movimentação primeiro adicione períodos ou linhas'
                  : null
              }
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setisVisibleMovimentation(true)}
                disabled={getIsDisabledRegisterMovimentation()}
              >
                Registrar movimentação
              </Button>
            </Tooltip>
            {operation.product_group?.id !== VEHICLES_PRODUCT_GROUP ? (
              <Button
                type={
                  operation.operation_status === 'IN_OPERATION'
                    ? 'default'
                    : 'primary'
                }
                icon={<PlusOutlined />}
                onClick={() => setisVisibleFormDrawer(true)}
              >
                {operation.nature_of_cargo?.id === LIQUID_BULK
                  ? 'Editar linhas'
                  : 'Novo período'}
              </Button>
            ) : null}
          </Space>
        </div>
        <InnerCollapse
          expandIconPosition="end"
          style={{
            marginTop: '16px',
          }}
        >
          {operation.nature_of_cargo?.id === LIQUID_BULK
            ? operation.operation_lines?.map((operationLine, index) => {
                return (
                  <Panel
                    header={getHeaderOperationLine(
                      operationLine.line.name,
                      operationLine.operation_product_id
                    )}
                    key={index}
                    style={{ marginBlockStart: '0' }}
                  >
                    <OperationLineDescription
                      operationLine={operationLine}
                      operationProducts={operation.items || []}
                    />
                  </Panel>
                );
              })
            : operation.work_periods.map((workPeriod, index) => {
                return (
                  <Panel
                    header={`Periodo ${index + 1}`}
                    key={index}
                    style={{ marginBlockStart: '0' }}
                    extra={
                      <Button
                        type="text"
                        icon={<EditFilled />}
                        size="small"
                        style={{ marginRight: '20px' }}
                        onClick={(evt) =>
                          onClickEditPeriod(evt, workPeriod, index)
                        }
                      >
                        Editar
                      </Button>
                    }
                  >
                    <OperationPeriodDescription
                      workPeriod={workPeriod}
                      movimentationProductsByPeriod={
                        movimentationProductsByPeriod
                      }
                      operationType={operationType}
                    />
                  </Panel>
                );
              })}
        </InnerCollapse>
        <br />
      </OperationProductsResumeHeader>
    </>
  );
}
