import { NcmItemGroupByStorageTerminalType } from '../types';
import { getHeaders, tosApi } from './tosApi';

export const ceMerchantApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getMerchantsCeGroupbyStorageTerminal: build.query<
      NcmItemGroupByStorageTerminalType[] | [],
      {
        code: string;
      }
    >({
      query: (params) => {
        const { code } = params;
        return {
          url: `/maritime/ce-merchants/groupby-storage-terminal/${code}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
});

export const { useLazyGetMerchantsCeGroupbyStorageTerminalQuery } =
  ceMerchantApi;
