import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import { debounce } from 'lodash';
import { ReactNode } from 'react';

type PanelHeaderProps = {
  panelName: string;
  actionButtons?: ReactNode;
  onSearch: (term: string) => void;
  inputWidth?: string;
};

export function PanelHeader(props: PanelHeaderProps) {
  const { panelName, actionButtons, onSearch, inputWidth } = props;
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '5px',
        marginBottom: '15px',
      }}
    >
      <div>
        <Input
          size="large"
          placeholder={`Pesquisar ${panelName}`}
          type="text"
          onChange={debounce((e) => onSearch(e.target.value), 600)}
          style={{ width: inputWidth || '280px' }}
          prefix={
            <SearchOutlined style={{ color: '#09d4ab', fontSize: '20px' }} />
          }
          allowClear
        />
      </div>
      <div
        style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}
      >
        {actionButtons}
      </div>
    </div>
  );
}
