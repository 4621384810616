import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import {
  StopoverCancelationData,
  StopoverReactivateData,
  Button,
  Expire,
  FormHeader,
  ModalBlockNavigation,
  ModalUpdateStopoverStatus,
  DocumentsConsentsData,
} from '../../components';
import { useCollapsePanels } from '../../hooks';
import {
  useCancelMutation,
  useGetUploadManifestsQuery,
  useReactivateMutation,
} from '../../services/stopoverApi';
import { CargoManifestType, Stopover as StopoverType } from '../../types';
import { isNullOrUndefined } from '../../utils/utils';
import { DockingsData } from '../docking/dockingsData/DockingsData';
import { StopoverData } from '../stopover/stopoverDetails/stopoverData/StopoverData';
import { LoadingData } from './loadingData/LoadingData';
import { ManifestsData } from './manifestsData/ManifestsData';
import { StorageData } from './storageData/StorageData';

type AgentAreaDetailsProps = {
  selectedStopover: StopoverType;
  hasEdited: boolean;
  blockNavigate: boolean;
  name: string;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  setHasEdited: (hasEdited: boolean) => void;
  setSelectedStopover: (stopover: StopoverType) => void;
  onCloseDrawer?: () => void;
  hideActionButtons?: boolean;
  hideCollapseAll?: boolean;
  showBackButton?: boolean;
};

const collapsePanels = [
  'vessel',
  'stopover',
  'operation',
  'documentsConsents',
  'dockings',
  'financialOfficers',
  'generalData',
  'manifests',
  'loading',
  'storages',
];

export function AgentAreaDetails(props: AgentAreaDetailsProps) {
  const {
    selectedStopover,
    hasEdited,
    blockNavigate,
    name,
    setBlockNavigate,
    setHasEdited,
    setSelectedStopover,
    onCloseDrawer,
    hideActionButtons,
    hideCollapseAll,
    showBackButton,
  } = props;

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [motivationCancel, setMotivationCancel] = useState<string>('');

  const [
    cancelStopover,
    {
      isLoading: isLoadingCancelStopover,
      isSuccess: isSuccessCancelStopover,
      isError: isErrorCancelStopover,
    },
  ] = useCancelMutation();

  const [
    reactivateStopover,
    {
      isLoading: isLoadingReactivateStopover,
      isSuccess: isSuccessReactivateStopover,
      isError: isErrorReactivateStopover,
    },
  ] = useReactivateMutation();

  const {
    data: uploadManifestsData,
    refetch: refetchUploadManifestsData,
    isFetching: isFetchingUploadManifests,
  } = useGetUploadManifestsQuery({
    id: selectedStopover.id || 0,
    with_ncm_items: false,
  });

  const [uploadManifestsDataSelected, setUploadManifestsDataSelected] =
    useState([] as CargoManifestType[]);

  useEffect(() => {
    refetchUploadManifestsData();
  }, [selectedStopover.id]);

  useEffect(() => {
    setUploadManifestsDataSelected(uploadManifestsData || []);
  }, [uploadManifestsData]);

  useEffect(() => {
    function setBlockNavigateAndHasEditedFalse() {
      if (setBlockNavigate) {
        setBlockNavigate(false);
      }
      if (setHasEdited) {
        setHasEdited(false);
      }
    }

    if (isSuccessCancelStopover) {
      setBlockNavigateAndHasEditedFalse();
      closeModal();
    }
  }, [isSuccessCancelStopover]);

  function closeModal() {
    if (setHasEdited) {
      setHasEdited(false);
    }
  }

  useBeforeunload((event) => {
    if (hasEdited && blockNavigate) {
      event.preventDefault();
    }
  });

  function headerDate() {
    const dates = selectedStopover
      ? {
          updatedAt: selectedStopover.updated_at,
          createdAt: selectedStopover.created_at,
        }
      : null;

    if (!isEmpty(selectedStopover)) {
      return (
        <div className="header-description">
          {dates?.updatedAt
            ? `Atualizado em: ${moment(dates?.updatedAt).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : dates?.createdAt
            ? `Escala cadastrada em: ${moment(dates?.createdAt).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : null}
        </div>
      );
    }
    return null;
  }

  function formActions() {
    return selectedStopover ? (
      <>
        <Button danger size="small" onClick={() => setShowCancelModal(true)}>
          {isNullOrUndefined(selectedStopover?.cancelation_date)
            ? 'Cancelar programação'
            : 'Reativar programação'}
        </Button>
        <ModalUpdateStopoverStatus
          showModal={showCancelModal}
          setShowModal={setShowCancelModal}
          vesselName={selectedStopover.vessel?.name || ''}
          stopoverId={selectedStopover.id}
          statusToChange={
            isNullOrUndefined(selectedStopover?.cancelation_date)
              ? 'CANCELED'
              : 'WAITING'
          }
          cancelStopoverConfirm={cancelStopoverConfirm}
          reactivateStopoverConfirm={reactivateStopoverConfirm}
          title="programação da escala"
        />
      </>
    ) : null;
  }

  function showAlert() {
    if (isLoadingCancelStopover || isLoadingReactivateStopover) {
      return (
        <div style={{ display: 'block', textAlign: 'center', padding: '20px' }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }
    if (!selectedStopover?.cancelation_date && isSuccessReactivateStopover) {
      return <StopoverReactivateData motivationCancel={motivationCancel} />;
    }
    if (isErrorCancelStopover || isErrorReactivateStopover) {
      return (
        <Expire
          type="error"
          closable
          showIcon
          message="Erro ao salvar alterações."
        />
      );
    }
  }

  function headerTitle() {
    return (
      <Row>
        <Col span={24}>
          {!isEmpty(selectedStopover) && (
            <span className="stopoverCode">{selectedStopover.code}</span>
          )}
          <h1>
            {isEmpty(selectedStopover)
              ? 'Nova escala'
              : selectedStopover.vessel?.name}
          </h1>
          {!isEmpty(selectedStopover) && (
            <div
              className="stopoverCode"
              style={hideActionButtons ? {} : { marginBottom: '1em' }}
            />
          )}
        </Col>
      </Row>
    );
  }

  async function cancelStopoverConfirm(id: number, motivation: string) {
    setMotivationCancel(motivation);
    const updatedStopover = await cancelStopover({ id, motivation });
    if ('data' in updatedStopover && setSelectedStopover) {
      setSelectedStopover(updatedStopover.data);
    }
  }

  async function reactivateStopoverConfirm(id: number, motivation: string) {
    setMotivationCancel(motivation);
    const updatedStopover = await reactivateStopover({ id, motivation });
    if ('data' in updatedStopover && setSelectedStopover) {
      setSelectedStopover(updatedStopover.data);
    }
  }

  return (
    <>
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
      />

      <FormHeader
        selectedStopover={selectedStopover}
        openCollapsePanels={openCollapsePanels}
        buttons={() => formActions()}
        onChangeSwitch={(checked: boolean) => onChangeSwitch(checked)}
        alert={showAlert}
        name={name}
        headerTitle={headerTitle()}
        hideEdit
        hideActionButtons={hideActionButtons}
        hideCollapseAll={hideCollapseAll}
        showBackButton={showBackButton}
        onCloseDrawer={onCloseDrawer}
      />
      {selectedStopover && selectedStopover.cancelation_date && (
        <StopoverCancelationData selectedStopover={selectedStopover} />
      )}
      <DocumentsConsentsData
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
        selectedStopover={selectedStopover}
      />
      <StopoverData
        screen="AGENT_AREA"
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
      />
      {!(selectedStopover.vessel?.ship_type?.name === 'CONTAINER_DOOR') && (
        <>
          <ManifestsData
            activeKey={openCollapsePanels}
            onOpenPanel={onOpenPanel}
            stopoverId={selectedStopover.id || 0}
            duv={selectedStopover.duv || ''}
            uploadManifestsData={uploadManifestsDataSelected}
            setUploadManifestsData={setUploadManifestsDataSelected}
            isFetchingUploadManifests={isFetchingUploadManifests}
          />
          <LoadingData
            activeKey={openCollapsePanels}
            onOpenPanel={onOpenPanel}
            stopoverId={selectedStopover.id || 0}
            uploadManifestsData={uploadManifestsDataSelected}
            setUploadManifestsData={setUploadManifestsDataSelected}
            isFetchingUploadManifests={isFetchingUploadManifests}
          />
        </>
      )}
      <StorageData
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
        stopoverId={selectedStopover.id || 0}
      />
      <DockingsData
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        onOpenPanel={onOpenPanel}
        activeKey={openCollapsePanels}
        allowedBerth={[]}
        screen="AGENT_AREA"
      />
    </>
  );
}
