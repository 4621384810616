import { ConfigProvider } from 'antd';

import { TableWrapper, SlimTable } from '../../../../../components';
import { StoppageTypeDescription } from '../../../../../types';
import { columns } from './columns';

type StoppagesTotalTableProps = {
  stoppages: StoppageTypeDescription[];
};

export function StoppagesTotalTable(props: StoppagesTotalTableProps) {
  const { stoppages } = props;

  function customizeRenderEmpty() {
    return <>Não existem paralisações gerais cadastradas.</>;
  }

  return (
    <TableWrapper>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <SlimTable
          rowKey="id"
          columns={columns()}
          dataSource={stoppages}
          className="read-only"
          pagination={false}
        />
      </ConfigProvider>
    </TableWrapper>
  );
}
