import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';

import { Button } from '../../../components';
import { GenericDrawerHeaderContainer } from '../../../components/common/GenericDrawerHeader/styles';

type StoppagesDrawerHeaderProps = {
  onBack?: () => void;
  onAdd?: () => void;
};
export function StoppagesDrawerHeader(props: StoppagesDrawerHeaderProps) {
  const { onBack, onAdd } = props;
  return (
    <GenericDrawerHeaderContainer>
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Button
          type="text"
          icon={
            <ArrowLeftOutlined style={{ color: 'var(--green-sea_dark)' }} />
          }
          onClick={() => (onBack ? onBack() : null)}
          style={{ marginRight: '10px', borderRadius: '25px' }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong style={{ fontSize: '16px' }}>Paralisações</strong>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAdd}
          // disabled={!hasWorkPeriods}
        >
          Nova paralisação
        </Button>
      </div>
    </GenericDrawerHeaderContainer>
  );
}
