import { Row, message } from 'antd';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  Alert,
  Form,
  FormItemDatePicker,
  FormItemInputNumber,
  FormItemSelect,
  FormDrawer,
} from '../../components';
import { useGetCargoTypeQuery } from '../../services/cargoApi';
import { useGetCompaniesQuery } from '../../services/companyApi';
import { useGetLinesQuery } from '../../services/linesApi';
import { useLazyGetMaintenancesQuery } from '../../services/maintenancesApi';
import {
  useCreatePumpingMutation,
  useUpdatePumpingMutation,
} from '../../services/pumpingApi';
import { Line as LineType, PumpingType } from '../../types';
import {
  createDateString,
  createDateStringPtBr,
  formatNumberToLocale,
} from '../../utils/utils';

type PumpingFormDrawerProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  pumping?: PumpingType;
  setSelectedPumping: Dispatch<SetStateAction<PumpingType>>;
};

export function PumpingFormDrawer(props: PumpingFormDrawerProps) {
  const { isVisible, setIsVisible, pumping, setSelectedPumping } = props;
  const { company: userCompany, isSuperuser } = useAppSelector(
    (state: RootState) => state.user.user
  );
  const [form] = Form.useForm();

  const [originLine, setOriginLine] = useState({} as LineType);
  const [originTerminalId, setOriginTerminalId] = useState(
    pumping?.origin_terminal?.id || userCompany?.id || 0
  );
  const [originTerminalName, setOriginTerminalName] = useState('');
  const [destinationTerminalName, setDestinationTerminalName] = useState('');

  const [
    messageHasMaintenanceInExpectedStart,
    setMessageHasMaintenanceInExpectedStart,
  ] = useState('');

  const { data: linesData } = useGetLinesQuery({
    storage_company_terminal: originTerminalId,
  });

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue(pumping);
      if (pumping?.origin_terminal) {
        setOriginTerminalId(pumping.origin_terminal.id);
        setDestinationTerminalName(pumping.destination_terminal.name);
      }
      if (!isSuperuser) {
        setOriginTerminalId(userCompany?.id || 0);
        setOriginTerminalName(userCompany?.name || '');
        form.setFieldsValue({ origin_terminal: { id: userCompany?.id } });
      }
    }
  }, [isVisible]);

  const { data: originCompanyData, isLoading: isLoadingOriginCompanyData } =
    useGetCompaniesQuery({
      page_size: 30,
      company_type: 'STORAGE',
      name_or_cnpj: originTerminalName,
    });

  const {
    data: destinationCompanyData,
    isLoading: isLoadingDestinationCompanyData,
  } = useGetCompaniesQuery({
    page_size: 30,
    company_type: 'STORAGE',
    name_or_cnpj: destinationTerminalName,
  });

  const [getMaintenancesData] = useLazyGetMaintenancesQuery();

  const { data: cargoTypesData, isLoading: isLoadingCargoTypesData } =
    useGetCargoTypeQuery();

  function handleSelectOriginTerminal(id: number) {
    setOriginTerminalId(id);
    form.setFieldsValue({ origin_line: null });
  }

  function handleSelectOriginLine(id: number) {
    setOriginLine(linesData?.find((l) => l.id === id) || ({} as LineType));
  }

  const [createPumping, { isLoading: isLoadingCreatePumping }] =
    useCreatePumpingMutation();

  const [updatePumping, { isLoading: isLoadingUpdatePumping }] =
    useUpdatePumpingMutation();

  function handleFormSubmit() {
    if (messageHasMaintenanceInExpectedStart) {
      message.error(messageHasMaintenanceInExpectedStart);
      return;
    }
    form.validateFields().then(async (values) => {
      if (values.origin_terminal.id === values.destination_terminal.id) {
        message.error(
          'Não é possível criar um bombeio com o mesmo terminal de origem e destino'
        );
        return;
      }
      if (values.expected_start > values.expected_finish) {
        message.error(
          'Previsão de início do bombeio não pode ser maior que previsão de fim do bombeio'
        );
        return;
      }
      const pumpingToSave: PumpingType = {
        id: Math.abs(pumping?.id || 0),
        origin_terminal: values.origin_terminal.id,
        origin_line: values.origin_line.id,
        destination_terminal: values.destination_terminal.id,
        expected_start: createDateString(values.expected_start) || '',
        expected_finish: createDateString(values.expected_finish) || '',
        product: values.product.name,
        volume: values.volume,
        uses_loop10: values.uses_loop10,
      };
      let response;
      if (isEmpty(pumping)) {
        response = await createPumping(pumpingToSave);
      } else {
        response = await updatePumping(pumpingToSave);
      }
      if ('data' in response) {
        setIsVisible(false);
        form.resetFields();
        setSelectedPumping({} as PumpingType);
        message.success(
          `Bombeio ${isEmpty(pumping) ? 'criado' : 'atualizado'} com sucesso`
        );
      }
    });
  }

  function getTitle() {
    if (isEmpty(pumping)) {
      return (
        <span style={{ fontSize: '16px', fontWeight: '700' }}>
          Novo bombeio
        </span>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginInlineStart: '10px',
        }}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <span style={{ fontSize: '16px', fontWeight: '700' }}>
            {pumping?.product.name}{' '}
            <span style={{ fontWeight: '600' }}>
              ({formatNumberToLocale(pumping?.volume)} m³)
            </span>
          </span>
          <span style={{ color: 'var(--neutral_medium)' }}>
            {createDateStringPtBr(pumping?.expected_start)}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            color: 'var(--neutral_medium)',
          }}
        >
          <div>
            <span>De: </span>
            <span style={{ fontStyle: 'italic' }}>
              {pumping?.origin_terminal.name} {pumping?.origin_line.name}(
              {pumping?.origin_line.berth})
            </span>
          </div>
          <div>
            <span>Para: </span>
            <span style={{ fontStyle: 'italic' }}>
              {pumping?.destination_terminal.name}{' '}
              {pumping?.destination_line ? (
                `${pumping?.destination_line?.name}(${pumping?.destination_line?.berth})`
              ) : (
                <span>(Linha não informada)</span>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }

  function handleSelectExpectedStart(selectedDate: Moment) {
    const lineId = form.getFieldValue(['origin_line', 'id']);
    let selectedLine = {} as LineType;
    if (lineId) {
      selectedLine =
        linesData?.find((line) => line.id === lineId) || ({} as LineType);
    }
    if (selectedDate && selectedLine.berth) {
      getMaintenancesData({ berth: selectedLine.berth || '' }).then(
        (response) => {
          if ('data' in response) {
            const selectedMaintenance = response.data?.results.find((item) => {
              if (item.expected_start && item.expected_finish) {
                const startDate = moment(item.expected_start);
                const finishDate = moment(item.expected_finish);
                return selectedDate >= startDate && selectedDate <= finishDate;
              }
            });
            setMessageHasMaintenanceInExpectedStart('');
            if (selectedMaintenance) {
              setMessageHasMaintenanceInExpectedStart(
                `Há uma manutenção programada para este horário no berço ${selectedLine.berth}`
              );
            }
          }
        }
      );
    }
  }

  return (
    <FormDrawer
      isVisible={isVisible}
      title={getTitle()}
      onClose={() => {
        setIsVisible(false);
        form.resetFields();
        setSelectedPumping({} as PumpingType);
      }}
      form={form}
      formName="create_pumping"
      handleFormSubmit={handleFormSubmit}
      disabledSaveButton={isLoadingCreatePumping || isLoadingUpdatePumping}
    >
      {!isEmpty(pumping?.destination_line) && (
        <Alert
          style={{ marginBlockEnd: '16px', fontSize: '12px' }}
          message={
            <div>
              <span style={{ color: 'var(--neutral_medium)' }}>
                Linha de destino:{' '}
              </span>
              {pumping?.destination_line?.name} (
              {pumping?.destination_line?.berth}), Diâmetro:{' '}
              {pumping?.destination_line?.diameter}&quot;, Vazão máxima:{' '}
              {pumping?.destination_line?.max_flow_rate}m³/h
            </div>
          }
        />
      )}
      <Row gutter={40}>
        <FormItemSelect
          colSpan={8}
          name={['origin_terminal', 'id']}
          label="Terminal de origem"
          onSearch={(value) => setOriginTerminalName(value)}
          onSelect={handleSelectOriginTerminal}
          onClear={() => setOriginTerminalName('')}
          debounceDelay={300}
          dataList={originCompanyData?.results || []}
          showSearch
          allowClear
          required
          isLoading={isLoadingOriginCompanyData}
          notFoundContent="Não foi encontrado nenhum terminal"
          disabled={!isSuperuser}
        />
        <FormItemSelect
          colSpan={8}
          name={['origin_line', 'id']}
          label="Linha de origem"
          dataList={linesData}
          onSelect={handleSelectOriginLine}
          required
          notFoundContent="Nenhuma linha encontrada para o terminal de origem selecionado"
          help={
            isEmpty(originLine) ? (
              <span>Selecione uma linha</span>
            ) : (
              <span>
                Diâmetro: {originLine.diameter}
                &quot;, Vazão máxima: {originLine.max_flow_rate} m³/h
              </span>
            )
          }
        />
        <FormItemSelect
          colSpan={8}
          name={['destination_terminal', 'id']}
          label="Terminal de destino"
          onSearch={(value) => setDestinationTerminalName(value)}
          onClear={() => setDestinationTerminalName('')}
          debounceDelay={300}
          dataList={destinationCompanyData?.results || []}
          showSearch
          allowClear
          required
          notFoundContent="Não foi encontrado nenhum terminal"
          isLoading={isLoadingDestinationCompanyData}
          help="O terminal de destino será notificado e selecionará a linha de destino."
          disabled={pumping?.is_confirmed}
        />
      </Row>
      <Row gutter={40}>
        <FormItemDatePicker
          colSpan={12}
          label="Previsão de início do bombeio"
          name="expected_start"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          allowClear
          onChange={handleSelectExpectedStart}
          help={
            <span style={{ color: 'var(--error-medium)' }}>
              {messageHasMaintenanceInExpectedStart}
            </span>
          }
        />
        <FormItemDatePicker
          colSpan={12}
          label="Previsão de fim do bombeio"
          name="expected_finish"
          required
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          allowClear
        />
        {/* faltando duração prevista */}
      </Row>
      <Row gutter={40}>
        <FormItemSelect
          label="Produto"
          name={['product', 'name']}
          colSpan={12}
          dataList={cargoTypesData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          isLoading={isLoadingCargoTypesData}
          required
        />
        <FormItemInputNumber
          colSpan={12}
          name="volume"
          label="Volume movimentado"
          addonAfter="m³"
          maxLength={8}
          required
        />
      </Row>
    </FormDrawer>
  );
}
