import { Drawer } from '../../../components';
import { Stopover as StopoverType } from '../../../types';
import { OperationsForm } from '../../operation/OperationsForm/OperationsForm';

type OperatorAreaDrawerProps = {
  selectedStopover: StopoverType;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  hasEdited: boolean;
  setHasEdited: (value: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate: (value: boolean) => void;
  setSelectedStopover: (stopover: StopoverType) => void;
  onCloseDrawer: () => void;
  isEditMode: boolean;
};

export function OperatorAreaDrawer(props: OperatorAreaDrawerProps) {
  const {
    selectedStopover,
    setSelectedStopover,
    isVisible,
    setIsVisible,
    onCloseDrawer,
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
    isEditMode,
  } = props;

  function onClose() {
    if (hasEdited) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      onCloseDrawer();
      setIsVisible(false);
    }
  }

  return (
    <Drawer
      visible={isVisible}
      placement="right"
      width={690}
      closable={false}
      destroyOnClose
      onClose={onClose}
    >
      <OperationsForm
        selectedStopover={selectedStopover}
        setHasEdited={setHasEdited}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        onCloseDrawer={onClose}
        setSelectedStopover={setSelectedStopover}
        setIsVisible={setIsVisible}
        operationFormType="EDIT"
      />
    </Drawer>
  );
}
