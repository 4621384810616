import { ColumnsType } from 'antd/lib/table';

import { ShipType, Vessel } from '../../../types';
import { translateShipType } from '../../../utils/translateShipType';
import { VesselEditRenderer } from './vesselEditRenderer';
import { VesselNameRenderer } from './vesselNameRenderer';

export function columns(
  onSelectItem: (vessel: Vessel) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title: <div className="show-amount">Embarcações</div>,
      key: 'name',
      dataIndex: 'name',
      render: (_, record: Record<string, any>) => (
        <VesselNameRenderer vessel={record} />
      ),
    },
    { title: 'Armadores', key: 'shipowner', dataIndex: ['shipowner', 'name'] },
    {
      title: 'Tipo de embarcação',
      key: 'ship_type',
      dataIndex: ['ship_type'],
      render: (record: ShipType) => {
        if (record) {
          return translateShipType(record);
        }
      },
    },
    {
      render: (_, record) => (
        <VesselEditRenderer vessel={record} onSelectItem={onSelectItem} />
      ),
    },
  ];
}
