import { OperationProductCeMercanteType } from '../types';
import { tosApi } from './tosApi';

export const merchantCEApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    updateMerchantCe: build.mutation<
      OperationProductCeMercanteType,
      OperationProductCeMercanteType
    >({
      query: (merchantCe) => {
        return {
          url: `/operational/merchant-ce/${merchantCe.id}`,
          method: 'PUT',
          body: merchantCe,
        };
      },
    }),
    createMerchantCe: build.mutation<
      OperationProductCeMercanteType,
      OperationProductCeMercanteType
    >({
      query: (merchantCe) => {
        return {
          url: `/operational/merchant-ce`,
          method: 'POST',
          body: merchantCe,
        };
      },
    }),
    deleteMerchantCe: build.mutation<void, number>({
      query: (merchantCeId) => {
        return {
          url: `/operational/merchant-ce/${merchantCeId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useUpdateMerchantCeMutation,
  useCreateMerchantCeMutation,
  useDeleteMerchantCeMutation,
} = merchantCEApi;
