import { ConfigProvider, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { TableWrapper } from '../../components';
import { ReportsTable } from '../reports/styles';

type RegistersTableProps = {
  columns: (
    onSelectItem: any,
    onDeleteItem: any
  ) => ColumnsType<Record<string, any>>;
  dataSource: Record<string, any>[];
  scrollOptions?: Record<string, any>;
  total?: number;
  itemsPerPage?: number;
  isLoading?: boolean;
  pageSize?: number;
  showSizeChanger?: boolean;
  rowKey?: string;
  onSelectItem?: (record: any) => void;
  onDeleteItem?: (id: number) => void;
  onChangePagination?: (page: number) => number;
  queryPage: number;
  rowSelection?: any; // tive que manter any, O tipo correto seria TableRowSelection<object> mas o esLint reclama de usar object como type e se tentar passar Record<string, any> não funciona pq não é object
};

export function RegistersTable(props: RegistersTableProps) {
  const {
    columns,
    dataSource,
    scrollOptions,
    total,
    itemsPerPage,
    onChangePagination,
    isLoading,
    pageSize,
    showSizeChanger,
    onSelectItem,
    onDeleteItem,
    rowKey,
    queryPage,
    rowSelection,
  } = props;
  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  return (
    <Spin spinning={isLoading} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <ReportsTable
            rowClassName={(record: any) =>
              record.status === 'DEACTIVATED' ? 'disabled-user' : ''
            }
            rowKey={rowKey || 'key'}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: itemsPerPage,
              pageSize,
              total,
              onChange: onChangePagination,
              showSizeChanger,
              current: queryPage,
            }}
            columns={columns(onSelectItem, onDeleteItem)}
            dataSource={dataSource}
            scroll={
              scrollOptions || { y: '60vh', scrollToFirstRowOnChange: false }
            }
            rowSelection={rowSelection}
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
