import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileExclamationOutlined,
  EditFilled,
} from '@ant-design/icons';
import { Alert, Space, Spin, message } from 'antd';
import { debounce, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Button,
  Descriptions,
  Drawer,
  FormItemSelect,
  FormatItem,
  InnerCollapse,
  Modal,
  SearchInput,
  Select,
  Form,
  Title,
} from '../../components';
import { CountTag } from '../../components/common/CountTag';
import {
  useGetAggregatedByStopoverQuery,
  useGetImportVehiclesGroupBLQuery,
  usePatchImportVehiclesGroupMutation,
  useRequestCargoRemovalMutation,
} from '../../services/warehousingVehiclesApi';
import { Stopover } from '../../types';
import {
  RequestCargoRemovalType,
  StopoverVehiclesImportType,
} from '../../typesWarehousing';
import {
  chassisStatus,
  portalStatus,
  warehousingImportStatus,
} from '../../utils/lists';
import { BLDocsDrawer } from './BLDocsDrawer';
import { CEDocsDrawer } from './CEDocsDrawer';
import { ChassisTable } from './chassisTable';
import { ModalEditDI } from './modalEditDI';
import { CustomTagSelect } from './styles';
import { WarehousingResume } from './warehousingResume';

type ImportDetailsProps = {
  onSearch: (value: string) => void;
  onSelectChassiStatus: (value: any) => void;
  onSelectInvoiceStatus: (value: any) => void;
  onClear: (to_clear: 'chassis_status' | 'portal_status') => void;

  stopover: Stopover;
  isLoading?: boolean;
  isWarehousingOperatorArea: boolean;
  onUpdate: () => void;
  refetchVehicleImport: () => void;
  importVehicles?: StopoverVehiclesImportType[];
  isFetchingVehicleImport: boolean;
};

const { Item } = Descriptions;

export function ImportDetails(props: ImportDetailsProps) {
  const {
    onSearch,
    onSelectChassiStatus,
    onSelectInvoiceStatus,
    onClear,
    stopover,
    isLoading,
    onUpdate,
    refetchVehicleImport,
    isWarehousingOperatorArea,
    importVehicles,
    isFetchingVehicleImport,
  } = props;
  const [IsVisibleDocumentsDrawer, setIsVisibleDocumentsDrawer] =
    useState(false);
  const [IsVisibleLoadRemovalModal, setIsVisibleLoadRemovalModal] =
    useState(false);

  const [requestCargoRemoval, { isSuccess: isSucessCargoRemoval }] =
    useRequestCargoRemovalMutation();

  const [
    patchImportVehiclesGroup,
    { isLoading: isPatchingImportVehiclesGroup },
  ] = usePatchImportVehiclesGroupMutation();

  const skip = {
    skip: isEmpty(stopover) || stopover.id === -1 || stopover.id === undefined,
  };
  const { data: resumeData } = useGetAggregatedByStopoverQuery(
    { stopoverId: stopover.id, direction: 'IMPORTING' },
    {
      ...skip,
    }
  );
  const [searchBl, setSearchBl] = useState('');
  const { data: importVehiclesGroupBL } = useGetImportVehiclesGroupBLQuery(
    {
      bl_number: searchBl,
      importing_vehicles_group_status: 'DONE_LOAD_PRESENCE',
    },
    {
      ...skip,
    }
  );

  const [isVisibleEditDIModal, setIsVisibleEditDIModal] = useState(false);
  const [selectedImportGroup, setSelectedImportGroup] = useState(
    {} as StopoverVehiclesImportType
  );

  function header(importGroup: StopoverVehiclesImportType) {
    return (
      <>
        {isWarehousingOperatorArea ? (
          <span>BL {importGroup.bl_number}</span>
        ) : (
          <span>CE {importGroup.merchant_ce}</span>
        )}
        <div
          style={{
            fontWeight: '400',
            marginLeft: '10px',
            display: 'inline-block',
          }}
        >
          {importGroup.automobile_manufacturer?.name ||
            'montadora não informada'}
        </div>
        <CountTag>{importGroup.total_number_of_vehicles} veículo(s)</CountTag>
        {isWarehousingOperatorArea && isMissingDocuments(importGroup) ? (
          <span style={{ marginLeft: '10px' }}>
            <FileExclamationOutlined />
          </span>
        ) : null}
      </>
    );
  }

  function isMissingDocuments(importGroup: StopoverVehiclesImportType) {
    return !importGroup.di_number || !importGroup.bl_number;
  }

  function showAlertMissingDocuments(importGroup: StopoverVehiclesImportType) {
    if (stopover.id !== -1) {
      if (isWarehousingOperatorArea && isMissingDocuments(importGroup)) {
        return (
          <Alert
            message="Documentação pendente"
            description="Acesse a área de Documentação para importar o(s) documento(s): CIN, DI, CE Mercante."
            type="warning"
            showIcon
            icon={<FileExclamationOutlined />}
            closable={false}
          />
        );
      }
    }
  }

  function showAlertUnloadingByOperator(
    importGroup: StopoverVehiclesImportType
  ) {
    if (stopover.id !== -1) {
      if (
        !isWarehousingOperatorArea &&
        importGroup.status === 'PENDING_RELEASE'
      ) {
        return (
          <Alert
            message="Retirada de carga solicitada pelo operador"
            description="Consulte o status de DI sobre a liberação da carga a ser entregue. Caso esteja liberado, atualize o status desta carga. Caso não esteja liberado, entre em contato com o operador."
            type="warning"
            showIcon
            icon={<ExclamationCircleOutlined />}
            closable={false}
          />
        );
      }
    }
  }
  async function onChangeStatus(
    importGroup: StopoverVehiclesImportType,
    value: any
  ) {
    const importVehiclesGroup = { id: importGroup.id, status: value };
    const response = await patchImportVehiclesGroup(importVehiclesGroup);
    if ('data' in response) {
      refetchVehicleImport();
    }
  }
  function extraStatusHeader(importGroup: StopoverVehiclesImportType) {
    const tagStyle = warehousingImportStatus.find(
      (status) => status.value === importGroup.status
    );
    const optionImportStatus = warehousingImportStatus.map((status) => {
      return { ...status, disabled: status.id < (tagStyle?.id || 0) };
    });
    return (
      <div style={{ marginRight: '24px' }}>
        <CustomTagSelect
          $tagStyle={tagStyle}
          options={optionImportStatus}
          defaultValue={importGroup.status}
          onClick={(e) => e.stopPropagation()}
          onChange={(value) => onChangeStatus(importGroup, value)}
          disabled={isWarehousingOperatorArea}
        />
      </div>
    );
  }
  const [form] = Form.useForm();

  const BLSelectList =
    importVehiclesGroupBL?.map((importGroup) => {
      return {
        name: importGroup.bl_number?.toString(),
        id: importGroup.bl_number?.toString(),
      };
    }) || [];

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      const bls: RequestCargoRemovalType[] = values.bl.map((bl: number) => {
        return { bl_number: bl };
      });
      requestCargoRemoval(bls);
    });
  }

  useEffect(() => {
    if (isSucessCargoRemoval) {
      setIsVisibleLoadRemovalModal(false);
      message.success('Solicitação realizada com sucesso.');
    }
  }, [isSucessCargoRemoval]);

  function onEditDI(importGroup: StopoverVehiclesImportType) {
    setSelectedImportGroup(importGroup);
    form.setFieldsValue({ di_number: importGroup.di_number });
    setIsVisibleEditDIModal(!isVisibleEditDIModal);
  }

  async function onUpdateDI() {
    form.validateFields().then(async (values) => {
      await patchImportVehiclesGroup({
        id: selectedImportGroup.id,
        di_number: values.di_number,
      });
      refetchVehicleImport();
      setSelectedImportGroup({} as StopoverVehiclesImportType);
      form.setFieldsValue({ di_number: '' });
      setIsVisibleEditDIModal(false);
    });
  }

  return (
    <>
      <Drawer
        visible={IsVisibleDocumentsDrawer}
        headerStyle={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          zIndex: 1,
        }}
        onClose={() => setIsVisibleDocumentsDrawer(false)}
        width={628}
        title="Ver documentação"
        closeIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ borderRadius: '25px' }}
          />
        }
      >
        {isWarehousingOperatorArea ? (
          <BLDocsDrawer BLList={importVehicles} />
        ) : (
          <CEDocsDrawer CEList={importVehicles} />
        )}
      </Drawer>

      <WarehousingResume type="IMPORT" resume={resumeData} />

      <Modal
        visible={IsVisibleLoadRemovalModal}
        title="Solicitar retirada de carga"
        className="TOSPrimaryModal"
        onCancel={() => setIsVisibleLoadRemovalModal(false)}
        okText="Solicitar"
        width={572}
        destroyOnClose
        onOk={handleFormSubmit}
      >
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <span style={{ display: 'inline-block', marginBottom: '8px' }}>
            Selecione os BLs para solicitar a retirada de carga.
          </span>
          <FormItemSelect
            name="bl"
            label="BL"
            mode="multiple"
            tagTheme="neutral"
            allowClear
            maxSelectOptions={100}
            dataList={BLSelectList}
            onSearch={(value) => setSearchBl(value)}
            required
          />
        </Form>
      </Modal>

      <Space size={16} style={{ marginBottom: '16px' }}>
        <Button
          icon={<FileDoneOutlined />}
          size="large"
          onClick={() => setIsVisibleDocumentsDrawer(true)}
        >
          Ver documentação
        </Button>
        {isWarehousingOperatorArea && (
          <Button
            size="large"
            onClick={() => setIsVisibleLoadRemovalModal(true)}
          >
            Solicitar retirada de carga
          </Button>
        )}
      </Space>
      <Space style={{ width: '100%' }} size={10}>
        <SearchInput
          placeholder="Pesquisar chassi/chave de nota"
          size="large"
          onChange={debounce((evt) => onSearch(evt.target.value), 500)}
        />
        <Select
          style={{ width: '200px' }}
          placeholder="Status do chassi"
          options={chassisStatus}
          onSelect={onSelectChassiStatus}
          allowClear
          onClear={() => onClear('chassis_status')}
        />
        <Select
          style={{ width: '200px' }}
          placeholder="Status de recepção"
          onSelect={onSelectInvoiceStatus}
          options={portalStatus}
          allowClear
          onClear={() => onClear('portal_status')}
        />
      </Space>
      <Spin spinning={isFetchingVehicleImport}>
        <InnerCollapse expandIconPosition="end" style={{ marginTop: '16px' }}>
          {importVehicles?.map((importGroup) => {
            const groupedByName = importGroup.vehicles.reduce(
              (groupedVehicles: any, person) => {
                const key = person.status;
                if (!groupedVehicles[key]) {
                  groupedVehicles[key] = [];
                }
                groupedVehicles[key].push(person);
                return groupedVehicles;
              },
              {}
            );
            return (
              <InnerCollapse.Panel
                header={header(importGroup)}
                key={importGroup.id}
                extra={extraStatusHeader(importGroup)}
              >
                {showAlertMissingDocuments(importGroup)}
                {showAlertUnloadingByOperator(importGroup)}
                <div style={{ padding: '16px' }}>
                  <Descriptions column={2}>
                    <Descriptions.Item label="DI">
                      <FormatItem>{importGroup.di_number}</FormatItem>
                      <FormatItem>
                        <Button
                          icon={<EditFilled />}
                          style={{ marginLeft: '8px', border: 'none' }}
                          onClick={() => onEditDI(importGroup)}
                        />
                      </FormatItem>
                    </Descriptions.Item>
                    <Descriptions.Item label="BL">
                      <FormatItem>{importGroup.bl_number}</FormatItem>
                    </Descriptions.Item>
                  </Descriptions>
                  <Title>Status dos chassis</Title>
                  <Descriptions
                    column={
                      groupedByName ? Object.keys(groupedByName).length : 3
                    }
                  >
                    {groupedByName &&
                      Object.keys(groupedByName).map((key) => (
                        <Item
                          key={key}
                          label={
                            chassisStatus.find(
                              (chassiStatus) => chassiStatus.value === key
                            )?.label
                          }
                        >
                          <FormatItem>
                            {groupedByName[key].length} un
                          </FormatItem>
                        </Item>
                      ))}
                  </Descriptions>
                  <ChassisTable
                    totalChassis={importGroup.total_number_of_vehicles}
                    stopover={stopover}
                    isLoadingData={isLoading}
                    onUpdate={onUpdate}
                    type="IMPORT"
                    importChassis={importGroup.vehicles}
                    isWarehousingOperatorArea={isWarehousingOperatorArea}
                  />
                </div>
              </InnerCollapse.Panel>
            );
          })}
        </InnerCollapse>
      </Spin>
      <ModalEditDI
        isVisibleEditDIModal={isVisibleEditDIModal}
        setIsVisibleEditDIModal={setIsVisibleEditDIModal}
        isPatchingImportVehiclesGroup={isPatchingImportVehiclesGroup}
        onUpdateDI={onUpdateDI}
        selectedImportGroup={selectedImportGroup}
        setSelectedImportGroup={setSelectedImportGroup}
        form={form}
      />
    </>
  );
}
