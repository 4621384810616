import styled from 'styled-components';

export const StyledCheckBoxButtonGroup = styled.div`
  div.ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;

    > label.ant-checkbox-wrapper {
      font-size: 14px;
      align-items: center;
      margin: 0;
      width: auto;
      padding: 5px 16px;
      border: 1px solid #ccdde9;
      font-weight: 600;
      margin-bottom: 2px;

      > span:first-child {
        display: none;
      }
      > span:last-child {
        padding: 0;
      }

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    > label.ant-checkbox-wrapper-checked {
      background: #09d4ab;
      border: 1px solid #ccdde9;
    }
    > label.ant-checkbox-wrapper-checked + label.ant-checkbox-wrapper {
      border-left-width: 0;
    }

    > label.ant-checkbox-wrapper:last-child {
      border-right-width: 1px;
    }
  }
`;
