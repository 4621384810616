import { EditFilled, PauseCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { parseInt } from 'lodash';

import { Button, Tag } from '../../../components';

export function stoppagesTableColumns(
  onEdit: (index: number) => void
): ColumnsType<Record<string, any>> {
  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'Motivo',
      key: 'motive',
      dataIndex: 'motive',
      align: 'left' as const,
      width: '38%',
      render: (value: any, record: any) => {
        return (
          <div>
            <span>{value} </span>
            {record.is_total && (
              <Tag icon={<PauseCircleOutlined />} className="error">
                Paralisação total
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Período',
      key: 'workPeriodsIndex',
      dataIndex: 'workPeriodsIndex',
      align: 'center' as const,
      width: '10%',
    },
    {
      title: 'Início',
      key: 'start',
      dataIndex: 'start',
      align: 'left' as const,
      width: '17%',
    },
    {
      title: 'Fim',
      key: 'end',
      dataIndex: 'end',
      align: 'left' as const,
      width: '17%',
    },
    {
      title: 'Duração',
      key: 'duration',
      dataIndex: 'duration',
      align: 'right' as const,
      width: '8%',
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'edit',
      align: 'left' as const,
      width: '10%',
      render: (value, record) => {
        return (
          <Button
            icon={<EditFilled />}
            type="ghost"
            onClick={() => onEdit(parseInt(record.id))}
          />
        );
      },
    },
  ];

  return columns;
}
