import { message } from 'antd';
import moment from 'moment';

import {
  Docking as DockingType,
  DockingPlace as DockingPlaceType,
  Docking,
} from '../../../types';

export function validateDraught(
  docking: DockingType,
  allowedBerth: DockingPlaceType[] | undefined
): boolean {
  const dockingPlaceSelected = allowedBerth?.find(
    (berth) => berth.tag === docking.docking_place?.tag
  );
  if (
    docking.input_draught &&
    dockingPlaceSelected?.max_draught &&
    docking.input_draught > dockingPlaceSelected?.max_draught
  ) {
    message.error(
      `O Calado de entrada informado(${docking.input_draught}m) é maior que o permitido no berço(${dockingPlaceSelected?.max_draught}m)`,
      8
    );
    return false;
  }
  if (
    docking.output_draught &&
    dockingPlaceSelected?.max_draught &&
    docking?.output_draught > dockingPlaceSelected?.max_draught
  ) {
    message.error(
      `O Calado de saída informado(${docking.output_draught}m) é maior que o permitido no berço(${dockingPlaceSelected?.max_draught}m)`,
      8
    );
    return false;
  }
  return true;
}

export function validateDwt(
  docking: DockingType,
  allowedBerth: DockingPlaceType[] | undefined
): boolean {
  const dockingPlaceSelected = allowedBerth?.find(
    (berth) => berth.tag === docking.docking_place?.tag
  );
  if (
    docking.input_dwt &&
    dockingPlaceSelected?.dwt &&
    docking.input_dwt > dockingPlaceSelected?.dwt
  ) {
    message.error(
      `O DWT de entrada informado(${docking.input_dwt}t) é maior que o permitido no berço(${dockingPlaceSelected?.dwt}t)`,
      10
    );
    return false;
  }
  if (
    docking.output_dwt &&
    dockingPlaceSelected?.dwt &&
    docking?.output_dwt > dockingPlaceSelected?.dwt
  ) {
    message.error(
      `O DWT de saída informado(${docking.output_dwt}t) é maior que o permitido no berço(${dockingPlaceSelected?.dwt}t)`,
      8
    );
    return false;
  }
  return true;
}

export function validateAnchoring(docking: DockingType) {
  if (!docking.real_time_of_anchoring && docking.real_time_of_unanchoring) {
    message.error(
      'Por favor, preencha primeiro o horário real da chegada no fundeio',
      8
    );
    return false;
  }
  return true;
}
export function validateStopoverDockings(
  dockingToSave: DockingType,
  stopoverDockings?: DockingType[] | Docking[] | null
): boolean {
  const activeDockings =
    stopoverDockings?.filter((docking) => docking.status !== 'CANCELED') || [];

  for (const docking of activeDockings) {
    if (docking.id !== dockingToSave.id) {
      // atracação
      if (docking.purpose === 'DOCK_IN_BERTH') {
        if (docking.first_cable_tied_in && !docking.last_cable_untied_in) {
          message.error(
            `Já existe uma atracação em andamento: #${docking.code}. É necessário preencher o ultimo cabo amarrado da atracação em curso.`,
            8
          );
          return false;
        }
      }
      // fundeio
      else if (
        docking.real_time_of_anchoring &&
        !docking.real_time_of_unanchoring
      ) {
        message.error(
          `Já existe um fundeio em andamento. É necessário preencher os horários reais de entrada e saida do fundeio em curso.`,
          8
        );
        return false;
      }
    }
  }

  return true;
}

export function validateManoeuvreDates(docking: DockingType): boolean {
  if (
    docking.docking_manoeuvre?.pilot_on_board &&
    docking.docking_manoeuvre?.pilot_leave_on_board &&
    moment(docking.docking_manoeuvre?.pilot_on_board) >
      moment(docking.docking_manoeuvre?.pilot_leave_on_board)
  ) {
    message.error(
      'A data de prático a bordo da manobra de atracação não pode ser maior que a data de saída do prático a bordo',
      8
    );
    return false;
  }

  if (
    docking.undocking_manoeuvre?.pilot_on_board &&
    docking.undocking_manoeuvre?.pilot_leave_on_board &&
    moment(docking.undocking_manoeuvre?.pilot_on_board) >
      moment(docking.undocking_manoeuvre?.pilot_leave_on_board)
  ) {
    message.error(
      'A data de prático a bordo da manobra de desatracação não pode ser maior que a data de saída do prático a bordo',
      8
    );
    return false;
  }

  if (
    docking.undocking_manoeuvre?.pilot_on_board &&
    docking.operation_summary?.real_finish &&
    moment(docking.undocking_manoeuvre?.pilot_on_board) <
      moment(docking.operation_summary?.real_finish)
  ) {
    message.error(
      'A data de prático a bordo da manobra de desatracação não pode ser menor que a hora real de fim da última operação',
      8
    );
    return false;
  }

  return true;
}

export function validateCablesDates(docking: DockingType): boolean {
  const firstCableTiedIn = moment(docking.first_cable_tied_in);
  const lastCableTiedIn = moment(docking.last_cable_tied_in);
  const firstCableUntiedIn = moment(docking.first_cable_untied_in);
  const lastCableUntiedIn = moment(docking.last_cable_untied_in);
  const MAX_TIME_WINDOW = 3;

  const pilotOnBoardDockingManoeuvre = moment(
    docking.docking_manoeuvre?.pilot_on_board
  );
  const pilotLeaveOnBoardDockingManoeuvre = moment(
    docking.docking_manoeuvre?.pilot_leave_on_board
  );
  const pilotOnBoardUndockingManoeuvre = moment(
    docking.undocking_manoeuvre?.pilot_on_board
  );
  const pilotLeaveOnBoardUndockingManoeuvre = moment(
    docking.undocking_manoeuvre?.pilot_leave_on_board
  );

  if (
    docking.first_cable_tied_in &&
    !docking.docking_manoeuvre?.pilot_on_board
  ) {
    message.error(
      'A data de primeiro cabo amarrado deve ser preenchida após a de prático a bordo da atracação',
      8
    );
    return false;
  }
  if (docking.last_cable_tied_in && !docking.first_cable_tied_in) {
    message.error(
      'A data do ultimo cabo amarrado deve ser preenchida após a do primeiro cabo amarrado da atracação',
      8
    );
    return false;
  }
  if (firstCableTiedIn > lastCableTiedIn) {
    message.error(
      'A data do primeiro cabo amarrado não pode ser maior que a data do último cabo amarrado',
      8
    );
    return false;
  }

  if (firstCableTiedIn < pilotOnBoardDockingManoeuvre) {
    message.error(
      'A data de primeiro cabo amarrado deve ser posterior a data de prático a bordo da atracação',
      8
    );
    return false;
  }

  if (firstCableTiedIn > pilotLeaveOnBoardDockingManoeuvre) {
    message.error(
      'A data de saída de prático a bordo da atracação deve ser maior que a data do primeiro cabo amarrado',
      8
    );
    return false;
  }

  if (
    lastCableTiedIn >
    pilotLeaveOnBoardDockingManoeuvre.add(MAX_TIME_WINDOW, 'hour')
  ) {
    message.error(
      'A data de último cabo amarrado não pode ultrapassar a data de saída do prático a bordo por mais de três horas',
      8
    );
    return false;
  }

  if (
    docking.first_cable_untied_in &&
    !docking.undocking_manoeuvre?.pilot_on_board
  ) {
    message.error(
      'A data de primeiro cabo desamarrado deve ser preenchida após a de prático a bordo da desatracação',
      8
    );
    return false;
  }

  if (docking.last_cable_untied_in && !docking.first_cable_untied_in) {
    message.error(
      'A data do último cabo desamarrado deve ser preenchida após o primeiro cabo desamarrado.',
      8
    );
    return false;
  }

  if (
    !docking.last_cable_untied_in &&
    docking.undocking_manoeuvre?.pilot_leave_on_board
  ) {
    message.error(
      'A data de saída do prático a bordo da desatracação só pode ser preenchida após a do último cabo desamarrado',
      8
    );
    return false;
  }

  if (
    !docking.undocking_manoeuvre?.pilot_on_board &&
    docking.undocking_manoeuvre?.pilot_leave_on_board
  ) {
    message.error(
      'A data de saída do prático a bordo só pode ser preenchida após a de chegada do prático a bordo',
      8
    );
    return false;
  }

  if (lastCableUntiedIn < firstCableUntiedIn) {
    message.error(
      'A data de último cabo desamarrado deve ser posterior a data do primeiro cabo desamarrado',
      8
    );
    return false;
  }

  if (firstCableUntiedIn < pilotOnBoardUndockingManoeuvre) {
    message.error(
      'A data de primeiro cabo desamarrado deve ser posterior a data de prático a bordo da desatracação',
      8
    );
    return false;
  }

  if (lastCableUntiedIn > pilotLeaveOnBoardUndockingManoeuvre) {
    message.error(
      'A data de último cabo desamarrado deve ser anterior a data de saída de prático a bordo da desatracação',
      8
    );
    return false;
  }

  if (lastCableUntiedIn < pilotOnBoardUndockingManoeuvre) {
    message.error(
      'A data de último cabo desamarrado deve ser posterior a data de prático a bordo da desatracação',
      8
    );
    return false;
  }

  if (firstCableUntiedIn > pilotLeaveOnBoardUndockingManoeuvre) {
    message.error(
      'A data de saída de prático a bordo da desatracação deve ser maior que a data do primeiro cabo desamarrado',
      8
    );
    return false;
  }
  return true;
}

export function validateOperationDates(docking: DockingType): boolean {
  if (docking.operation_summary?.real_start && !docking.first_cable_tied_in) {
    message.error(
      'Não é possível informar a hora real do início da operação sem informar o primeiro cabo amarrado',
      8
    );
    return false;
  }
  if (
    docking.operation_summary?.real_finish &&
    !docking.operation_summary?.real_start
  ) {
    message.error(
      'Não é possível informar a hora real do final da operação sem informar a hora do início',
      8
    );
    return false;
  }

  if (
    moment(docking.operation_summary?.expected_start) >
    moment(docking.operation_summary?.expected_finish)
  ) {
    message.error(
      'A previsão de início da operação não pode ser maior que a previsão de fim da operação',
      8
    );
    return false;
  }

  if (
    moment(docking.operation_summary?.real_start) >
    moment(docking.operation_summary?.real_finish)
  ) {
    message.error(
      'A hora real de início da operação não pode ser maior que a hora real de fim da operação',
      8
    );
    return false;
  }

  return true;
}
