import { CalendarOutlined, CopyOutlined } from '@ant-design/icons';
import { Row, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useState } from 'react';

import { Alert, Button, Modal, SearchInput, Tag } from '../../../components';
import { RangePickerStylized } from '../../../components/common/datepicker/styles';
import {
  GetVehiclesGroupByStatisticsParams,
  useExportAtaqXmlMutation,
  useGetVehiclesGroupByStatisticsDeatilsQuery,
} from '../../../services/warehousingVehiclesApi';
import { createDateString, downloadFile } from '../../../utils/utils';
import { SectionHeader } from '../styles';
import { WarehousingVehiclesTable } from './warehousingVehiclesTable';

const { Paragraph } = Typography;
const { CheckableTag } = Tag;

export function WarehousingVehiclesSection() {
  const [isVisibleNonBilledChassis, setisVisibleNonBilledChassis] =
    useState(false);

  const [SelectedTag, setSelectedTag] = useState('');

  const [datesFilter, setDatesFilter] =
    useState<GetVehiclesGroupByStatisticsParams>({
      start_date: createDateString(moment().startOf('month'), true),
      end_date: createDateString(moment().endOf('month'), true),
    });

  const { data: vehicles } = useGetVehiclesGroupByStatisticsDeatilsQuery(
    datesFilter,
    { skip: datesFilter.start_date === null || datesFilter.end_date === null }
  );

  const [exportXml, { isLoading: isLoadingExportAntaqXml }] =
    useExportAtaqXmlMutation();

  const NonBilledChassis = [
    '6CAYADS1APAXC5345',
    '7YCS1BV5KARL70327',
    '8DATRVUZN0DV40326',
    '6CAYADS1APAXC5345',
    '7YCS1BV5KARL70327',
    '7YCS1BV5KARL70327',
    '7UTPK7RHEAXB08261',
    '7UTPK7RHEAXB08261',
    '8DATRVUZN0DV40326',
    '7UTPK7RHEAXB08261',
  ];

  const checkableTags = [
    {
      key: 'last_month',
      label: 'Último mês',
    },
    {
      key: 'penultimate_month',
      label: 'Penúltimo mês',
    },
  ];

  function onChangeDatePicker(value: any) {
    console.log(value);
    if (value && value.length > 0 && value[0]) {
      setDatesFilter((prev) => ({
        ...prev,
        start_date: createDateString(value[0], true),
      }));
    }
    if (value && value.length > 1 && value[1]) {
      setDatesFilter((prev) => ({
        ...prev,
        end_date: createDateString(value[1], true),
      }));
    }
  }

  function handleTagSelection(checked: boolean, tag: string) {
    if (checked) {
      setSelectedTag(tag);
      if (tag === 'last_month') {
        setDatesFilter({
          start_date: createDateString(
            moment().subtract(1, 'month').startOf('month'),
            true
          ),
          end_date: createDateString(
            moment().subtract(1, 'month').endOf('month'),
            true
          ),
        });
      }
      if (tag === 'penultimate_month') {
        setDatesFilter({
          start_date: createDateString(
            moment().subtract(2, 'month').startOf('month'),
            true
          ),
          end_date: createDateString(
            moment().subtract(2, 'month').endOf('month'),
            true
          ),
        });
      }
    } else {
      setSelectedTag('');
      setDatesFilter({
        start_date: createDateString(moment().startOf('month')),
        end_date: createDateString(moment().endOf('month')),
      });
    }
  }

  async function getXml() {
    await exportXml({
      initial_date_report: createDateString(datesFilter.start_date, true),
      final_date_report: createDateString(datesFilter.end_date, true),
    }).then((response) => {
      if ('data' in response) {
        downloadFile(
          response.data,
          `ServicoRelacionadoCarga_${moment(datesFilter.start_date).format(
            'DD/MM/YYYY'
          )}-${moment(datesFilter.end_date).format('DD/MM/YYYY')}.xml`
        );
      }
    });
  }
  return (
    <div>
      <SectionHeader style={{ gap: '15px' }}>
        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
          <SearchInput
            size="large"
            allowClear
            placeholder="Pesquisar chassis"
            style={{ width: '400px' }}
            //   onChange={debounce((evt) => onSearch(evt.target.value), 500)}
          />

          <Space size={16}>
            {/* <Alert
              type="warning"
              message={`No periodo selecionado há ${NonBilledChassis.length} chassis não faturados`}
              action={
                <Button
                  type="text"
                  onClick={() => setisVisibleNonBilledChassis(true)}
                >
                  Ver chassis
                </Button>
              }
            /> */}
            <Button
              type="primary"
              size="large"
              onClick={() => getXml()}
              loading={isLoadingExportAntaqXml}
            >
              Gerar XML
            </Button>
          </Space>
        </Row>
      </SectionHeader>
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <div style={{ marginBottom: '16px' }}>
          <span style={{ width: '180px' }}>
            Filtro por período de saída:{'   '}
          </span>
          <RangePickerStylized
            allowClear
            onChange={onChangeDatePicker}
            minuteStep={1}
            showTime={false}
            format={['DD/MM/YYYY', 'DD/MM/YYYY']}
            style={{ width: '240px', background: 'var(--neutral_lightest)' }}
            value={[
              moment(datesFilter.start_date),
              moment(datesFilter.end_date),
            ]}
          />
          <div style={{ marginTop: '16px' }}>
            {checkableTags.map((tag) => {
              return (
                <CheckableTag
                  checked={SelectedTag === tag.key}
                  onChange={(checked) => {
                    handleTagSelection(checked, tag.key);
                  }}
                  key={tag.key}
                >
                  <CalendarOutlined style={{ marginRight: '5px' }} />
                  {tag.label}
                </CheckableTag>
              );
            })}
          </div>
        </div>
      </Row>

      <WarehousingVehiclesTable
        dataSource={vehicles?.results}
        total={vehicles?.count}
      />

      <Modal
        title="Chassis não faturados"
        visible={isVisibleNonBilledChassis}
        className="TOSWarningModal"
        footer={
          <Row justify="end">
            <Tooltip title="Copiado!" trigger="click">
              <Button
                type="text"
                icon={<CopyOutlined />}
                onClick={() =>
                  navigator.clipboard.writeText(NonBilledChassis.toString())
                }
              >
                Copiar chassis
              </Button>
            </Tooltip>
            <Button
              type="primary"
              onClick={() => setisVisibleNonBilledChassis(false)}
            >
              Ok
            </Button>
          </Row>
        }
        width={680}
        onCancel={() => setisVisibleNonBilledChassis(false)}
        closable
        // onOk={() => setisVisibleNonBilledChassis(false)}
        // okText="Ok"
        // cancelText="Copiar chassis"
        // cancelButtonProps={{ type: 'default', icon: <CopyOutlined /> }}
      >
        Os chassis abaixo ainda não estão faturados
        <Paragraph
          ellipsis={{ rows: 2, expandable: true, symbol: 'ver todos' }}
          style={{ marginTop: '16px' }}
        >
          {NonBilledChassis.map((chassi) => {
            return (
              <div style={{ display: 'inline-block', marginRight: '10px' }}>
                {chassi}
              </div>
            );
          })}
        </Paragraph>
      </Modal>
    </div>
  );
}
