import { Drawer } from '../../components';
import { PermissionGroupForm } from './permissionGroupForm';

type PermissionGroupDrawerProps = {
  visible: boolean;
  width?: number;
  onCloseDrawer: () => void;
  selectedGroup: null | Record<string, unknown>;
  onSavePermissionGroup: (saved: any) => void;
  onDeletePermissionGroup: () => void;
};

export function PermissionGroupDrawer(props: PermissionGroupDrawerProps) {
  return (
    <Drawer
      visible={props.visible}
      width={props.width}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <PermissionGroupForm
        name="addPermissionGroup"
        onCloseDrawer={props.onCloseDrawer}
        selectedGroup={props.selectedGroup}
        onSavePermissionGroup={props.onSavePermissionGroup}
        onDeletePermissionGroup={props.onDeletePermissionGroup}
      />
    </Drawer>
  );
}
