import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ConfigProvider, Spin } from 'antd';

import {
  Button,
  Descriptions,
  FormatItem,
  TableWrapper,
} from '../../../components';
import { TrackingLogType } from '../../../types';
import { isNullOrUndefined } from '../../../utils/utils';
import { ReportsTable } from '../styles';
import { vesselsTableColumns } from './vesselsTableColumns';

const { Item } = Descriptions;
type VesselsTableProps = {
  dataSource?: Array<TrackingLogType>;
  totalItems?: number;
  isLoadingVesselsData?: boolean;
  onChangePagination: React.Dispatch<React.SetStateAction<number>>;
  queryPage?: number;
};
export function VesselsTable(props: VesselsTableProps) {
  const {
    dataSource,
    totalItems,
    isLoadingVesselsData,
    onChangePagination,
    queryPage,
  } = props;
  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  function dockingExpandedRow(record: TrackingLogType) {
    return (
      <Descriptions column={3}>
        <Item label="Início da navegação interna">
          <FormatItem>{record.start_of_internal_navigation}</FormatItem>
        </Item>
        <Item label="Primeiro cabo amarrado em">
          <FormatItem>{record.first_cable_tied_in}</FormatItem>
        </Item>
        <Item label="Último cabo amarrado em">
          <FormatItem>{record.last_cable_tied_in}</FormatItem>
        </Item>
        <Item label="Calado de atracação">
          <FormatItem>{record.input_draught}</FormatItem>
        </Item>
        <Item label="Prático 1">
          <FormatItem>{record.first_pilot_name}</FormatItem>
        </Item>
        <Item label="Prático 2">
          <FormatItem>{record.second_pilot_name}</FormatItem>
        </Item>
        <Item label="Manobra acompanhada?">
          <FormatItem>
            {isNullOrUndefined(record.on_duty_that_accompanied) ? 'Não' : 'Sim'}
          </FormatItem>
        </Item>
      </Descriptions>
    );
  }

  function undockingExpandedRow(record: TrackingLogType) {
    return (
      <Descriptions column={3}>
        <Item label="Primeiro cabo desamarrado em">
          <FormatItem>{record.first_cable_untied_in}</FormatItem>
        </Item>
        <Item label="Último cabo desamarrado em">
          <FormatItem>{record.last_cable_untied_in}</FormatItem>
        </Item>
        <Item label="Saída na barra">
          <FormatItem>{record.departure_time_at_port_entrance}</FormatItem>
        </Item>
        <Item label="Calado de desatracação">
          <FormatItem>{record.input_draught}</FormatItem>
        </Item>
        <Item label="Prático 1">
          <FormatItem>{record.first_pilot_name}</FormatItem>
        </Item>
        <Item label="Prático 2">
          <FormatItem>{record.second_pilot_name}</FormatItem>
        </Item>
        <Item label="Manobra acompanhada?">
          <FormatItem>
            {isNullOrUndefined(record.on_duty_that_accompanied) ? 'Não' : 'Sim'}
          </FormatItem>
        </Item>
      </Descriptions>
    );
  }

  function anchoringExpandedRow(record: TrackingLogType) {
    return (
      <Descriptions column={3}>
        <Item label="LOA">
          <FormatItem>{record.vessel_loa}</FormatItem>
        </Item>
        <Item label="Largura">
          <FormatItem>{record.vessel_width}</FormatItem>
        </Item>
        <Item label="Calado na chegada">
          <FormatItem>{record.arrival_draught}</FormatItem>
        </Item>
        <Item label="Chegada">
          <FormatItem>{record.real_time_of_anchoring}</FormatItem>
        </Item>
        <Item label="Porto de destino">
          <FormatItem>{record.next_port_name}</FormatItem>
        </Item>
        <Item label="Porto de origem">
          <FormatItem>{record.last_port_name}</FormatItem>
        </Item>
      </Descriptions>
    );
  }

  function expandedRowRender(record: TrackingLogType) {
    return (
      <div
        style={{
          width: 'calc(100% - 47px)',
          float: 'right',
          padding: '10px 0',
        }}
      >
        {record.status === 'DOCKING'
          ? dockingExpandedRow(record)
          : record.status === 'UNDOCKING'
          ? undockingExpandedRow(record)
          : record.status === 'ANCHORING' || record.status === 'UNANCHORING'
          ? anchoringExpandedRow(record)
          : null}
      </div>
    );
  }

  return (
    <Spin spinning={isLoadingVesselsData} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <ReportsTable
            rowKey="key"
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: dataSource?.length,
              total: totalItems,
              showSizeChanger: false,
              onChange: (page) => onChangePagination(page),
              current: queryPage,
            }}
            columns={vesselsTableColumns(dataSource)}
            dataSource={dataSource}
            scroll={{ y: '60vh', scrollToFirstRowOnChange: false }}
            expandable={{
              expandedRowRender: (record) =>
                expandedRowRender(record as TrackingLogType),
              expandIcon: ({ expanded, onExpand, record }) => {
                return expanded ? (
                  <Button
                    icon={
                      <UpOutlined style={{ color: 'var(--green-sea_dark)' }} />
                    }
                    type="text"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <Button
                    icon={
                      <DownOutlined
                        style={{ color: 'var(--green-sea_dark)' }}
                      />
                    }
                    type="text"
                    onClick={(e) => onExpand(record, e)}
                  />
                );
              },
              expandRowByClick: true,
            }}
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
