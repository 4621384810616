import { Col, FormInstance, Row, Select } from 'antd';
import { debounce, isEmpty } from 'lodash';
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  FormItemSelect,
  Modal,
  PageContentPlaceholder,
  VesselInfos,
} from '..';

import { useGetVesselQuery } from '../../services/vesselApi';
import { Vessel as VesselType } from '../../types';
import { VesselRequestUpdateModal } from './VesselRequestUpdateModal';

type VesselSelectData = {
  style?: CSSProperties | undefined;
  readOnly?: boolean;
  selectedVessel: VesselType | undefined;
  visibleForm?: boolean;
  onSelectVessel: (value: any) => void;
  form: FormInstance;
  searchVessel: { name: string };
  setSearchVessel: Dispatch<SetStateAction<{ name: string }>>;
};

export function VesselSelectData(props: VesselSelectData) {
  const {
    style,
    readOnly,
    selectedVessel,
    visibleForm,
    onSelectVessel,
    form,
    searchVessel,
    setSearchVessel,
  } = props;

  const { data: foundVessel } = useGetVesselQuery(searchVessel);

  useEffect(() => {
    setSearchVessel({ name: selectedVessel?.name || '' });
  }, [visibleForm]);

  function vesselOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {`${option.imo} - ${option.name}`}
      </Select.Option>
    );
  }

  function onSearchByName(val: any) {
    setSearchVessel({ name: val });
  }

  function handleSelectVessel(option: number) {
    const fullVessel = foundVessel?.results?.find((v) => v.id === option);
    onSelectVessel(fullVessel || ({} as VesselType));
    form.setFieldsValue({
      vessel: {
        ...fullVessel,
      },
    });
  }

  // reset selectedVessel when it's cleared
  function onClearSelectedVessel() {
    onSelectVessel({} as VesselType);
  }

  const [showVesselRequestUpdateModal, setShowVesselRequestUpdateModal] =
    useState(false);

  return (
    <div style={style}>
      <VesselRequestUpdateModal
        showModal={showVesselRequestUpdateModal}
        setShowModal={setShowVesselRequestUpdateModal}
        vesselId={selectedVessel?.id || 0}
      />
      <Row gutter={40} align="middle">
        <FormItemSelect
          colSpan={16}
          name={['vessel', 'id']}
          label={readOnly ? 'Nome da embarcação' : 'Selecione uma embarcação'}
          dataList={foundVessel?.results}
          showSearch
          allowClear
          disabled={readOnly}
          onSearch={debounce(onSearchByName, 400)}
          onSelect={handleSelectVessel}
          onClear={onClearSelectedVessel}
          notFoundContent="Digite uma busca"
          optionRenderer={vesselOptionRenderer}
          required
          shouldUpdate={(prevValues: any, currentValues: any) =>
            prevValues.vessel !== currentValues.vessel
          }
        />
        <Col span={6}>
          <Button
            type="default"
            onClick={() => setShowVesselRequestUpdateModal(true)}
            disabled={isEmpty(selectedVessel)}
          >
            Alterar dados da embarcação
          </Button>
        </Col>
      </Row>
      {isEmpty(selectedVessel) ? (
        <div style={{ height: '250px' }}>
          <PageContentPlaceholder />
        </div>
      ) : (
        <VesselInfos selectedVessel={selectedVessel} />
      )}
    </div>
  );
}
