import styled from 'styled-components';

export const OperationProductHeader = styled.div`
  .cargo-type {
    color: var(--neutral_dark);
    font-weight: 700;
    font-size: 16px;
    margin-right: 8px;
  }
  .product-group {
    color: var(--neutral_medium);
    font-weight: 400;
    font-size: 14px;
    margin-right: 8px;
  }
  .operator-company {
    color: var(--neutral_medium);
    font-weight: 400;
    font-size: 10px;
    margin-right: 8px;
  }
`;
