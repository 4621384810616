import {
  ConsentRequirementDescriptionType,
  ConsentsByPhaseDescriptionType,
  PspConsentRequirementType,
  PspConsentsByPhaseType,
  PspPhaseConsentType,
  SituationConsentType,
} from '../../../types';
import { capitalizeFirstLetter } from '../../../utils/utils';

export function formatConsentsToDescription(
  consentsByPhase: PspConsentsByPhaseType
): ConsentsByPhaseDescriptionType[] {
  const consentsDescription: ConsentsByPhaseDescriptionType[] = [];
  consentsByPhase.phases?.forEach((phase) => {
    consentsDescription.push({
      step: phase.step,
      consents: phase.consents.map((phaseConsent) => {
        return {
          consenting_sector: createConsentingSectorName(
            phaseConsent.consenting_sector,
            phaseConsent.consent
          ),
          consent: phaseConsent.consent,
          checked_consent: isCheckedConsent(
            phaseConsent.requirements,
            phaseConsent.situation_consent
          ),
          requirements: formatConsentRequirement(phaseConsent),
          situation_consent: phaseConsent.situation_consent,
        };
      }),
    });
  });
  return consentsDescription;
}

function createConsentingSectorName(
  consenting_sector: string,
  consent: string
) {
  if (consenting_sector === 'Administração Portuária') {
    return `${consenting_sector} - ${consent}`;
  }
  if (consenting_sector === 'AGENCIA NACIONAL DE VIGILANCIA SANITARIA') {
    return 'Autoridade Sanitária';
  }
  return capitalizeFirstLetter(consenting_sector);
}

function isCheckedConsent(
  consentRequirementsList: PspConsentRequirementType[],
  situationConsent: SituationConsentType
): 'NONE' | 'ALL' | 'SOME' {
  if (situationConsent === 'Fornecida') {
    return 'ALL';
  }
  if (
    consentRequirementsList.every(
      (requirement) => requirement.requirement_situation !== 'Cumprida'
    )
  ) {
    return 'NONE';
  }
  if (
    consentRequirementsList.every(
      (requirement) => requirement.requirement_situation === 'Cumprida'
    )
  ) {
    return 'ALL';
  }
  if (
    consentRequirementsList.some(
      (requirement) => requirement.requirement_situation === 'Cumprida'
    )
  ) {
    return 'SOME';
  }
  return 'NONE';
}

function formatConsentRequirement(
  phaseConsent: PspPhaseConsentType
): ConsentRequirementDescriptionType[] {
  const phaseConsentRequirements: ConsentRequirementDescriptionType[] = [];
  // if (phaseConsent.consent === 'Provisão da Embarcação') {
  //   phaseConsentRequirements.push({
  //     name_requirement: 'Confirmação de fonte pagadora',
  //     checked: false, // false por enquanto ainda temos que ver de onde vamos tirar essa informação,
  //     requirement_situation: 'Aguardando Cumprimento',
  //   });
  //   phaseConsentRequirements.push({
  //     name_requirement: 'Associação a escala mercante',
  //     checked: false, // false por enquanto ainda temos que ver de onde vamos tirar essa informação,
  //     requirement_situation: 'Aguardando Cumprimento',
  //   });
  // }
  phaseConsent.requirements.forEach((requirement) => {
    phaseConsentRequirements.push({
      name_requirement: capitalizeFirstLetter(requirement.name_requirement),
      checked: requirement.requirement_situation === 'Cumprida',
      requirement_situation: requirement.requirement_situation,
    } as ConsentRequirementDescriptionType);
  });
  return phaseConsentRequirements;
}
