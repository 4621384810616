import { ConfigProvider } from 'antd';

import { SlimTable, TableWrapper } from '../../../../../components';
import {
  AllTableItemsType,
  ChildProratedType,
} from '../../../../../typesFinancial';
import { formatNumberToLocale } from '../../../../../utils/utils';

type ApportmentTableProps = {
  dataSource: AllTableItemsType[] & ChildProratedType[];
  rowKey?: string;
  totalAmount: number;
};

export function ApportmentTable(props: ApportmentTableProps) {
  const { dataSource, rowKey, totalAmount } = props;

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  function getTableSummary() {
    const total = (dataSource || []).reduce(
      (sum, current) => sum + (current.value || 0),
      0
    );

    const totalProratedAmount = (dataSource || []).reduce(
      (sum, current) => sum + (current.prorated_amount || 0),
      0
    );

    const proratedPercent = (totalProratedAmount * 100) / totalAmount || 0;

    return (
      <SlimTable.Summary fixed>
        <SlimTable.Summary.Row key="row_0">
          <SlimTable.Summary.Cell index={0} colSpan={1} key={0} align="left">
            Total Rateado:
          </SlimTable.Summary.Cell>
          <SlimTable.Summary.Cell index={1} colSpan={1} key={1} align="right">
            {formatNumberToLocale(proratedPercent)}%
          </SlimTable.Summary.Cell>
          <SlimTable.Summary.Cell index={2} colSpan={1} key={2} align="right">
            {formatNumberToLocale(total, true)}
          </SlimTable.Summary.Cell>
        </SlimTable.Summary.Row>
      </SlimTable.Summary>
    );
  }

  return (
    <TableWrapper>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <SlimTable
          rowKey={rowKey || 'key'}
          columns={[
            {
              title: 'Rateios',
              dataIndex: 'id',
              key: 'items',
              width: '70%',
              render(_value, record: any) {
                return (
                  <span>
                    {record.fare_company_identifier} -{' '}
                    {record.fare_company_name}
                  </span>
                );
              },
            },
            {
              title: '%',
              dataIndex: 'description',
              key: 'description',
              width: '10%',
              align: 'right',
              render(_value, record: any) {
                const proratedPercent =
                  (record.prorated_amount * 100) / totalAmount || 0;
                return (
                  <span style={{ fontFamily: 'Consolas' }}>
                    {formatNumberToLocale(proratedPercent)}%
                  </span>
                );
              },
            },
            {
              title: 'Valor',
              dataIndex: 'value',
              key: 'value',
              align: 'right',
              width: '20%',
              render(value) {
                return (
                  <span style={{ fontFamily: 'Consolas' }}>
                    {formatNumberToLocale(value, true)}
                  </span>
                );
              },
            },
          ]}
          dataSource={dataSource}
          className="read-only"
          pagination={false}
          summary={() => getTableSummary()}
        />
      </ConfigProvider>
    </TableWrapper>
  );
}
