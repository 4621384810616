import Icon, {
  CustomIconComponentProps,
} from '@ant-design/icons/lib/components/Icon';

function MoonSvg() {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90312 1.23494C5.99243 1.39343 5.98032 1.58959 5.87219 1.73589C5.32331 2.47846 5.05919 3.39337 5.12786 4.31422C5.19653 5.23506 5.59343 6.10068 6.24637 6.75363C6.89932 7.40657 7.76494 7.80348 8.68579 7.87215C9.60663 7.94082 10.5215 7.67669 11.2641 7.12781C11.4104 7.01968 11.6066 7.00757 11.7651 7.09689C11.9235 7.1862 12.0148 7.36029 11.998 7.54144C11.893 8.67847 11.4662 9.76205 10.7678 10.6654C10.0693 11.5688 9.12808 12.2545 8.05412 12.6425C6.98015 13.0304 5.81792 13.1044 4.70342 12.8559C3.58892 12.6074 2.56824 12.0466 1.76081 11.2392C0.953381 10.4318 0.392605 9.41109 0.144097 8.29658C-0.10441 7.18208 -0.0303718 6.01985 0.35755 4.94589C0.745472 3.87192 1.43123 2.93065 2.33459 2.23221C3.23795 1.53377 4.32154 1.10704 5.45856 1.00197C5.63971 0.985226 5.8138 1.07646 5.90312 1.23494ZM4.60048 2.09915C3.98677 2.27631 3.40907 2.56826 2.8992 2.96247C2.13455 3.55367 1.55409 4.35042 1.22573 5.25948C0.897368 6.16854 0.834698 7.15231 1.04505 8.09569C1.2554 9.03907 1.73007 9.90303 2.41352 10.5865C3.09697 11.2699 3.96093 11.7446 4.90431 11.955C5.84769 12.1653 6.83146 12.1026 7.74052 11.7743C8.64958 11.4459 9.44633 10.8654 10.0375 10.1008C10.4317 9.59093 10.7237 9.01323 10.9008 8.39952C10.1882 8.71381 9.40444 8.85138 8.61714 8.79267C7.47581 8.70755 6.40294 8.21562 5.59366 7.40634C4.78438 6.59706 4.29245 5.52419 4.20733 4.38286C4.14862 3.59556 4.28619 2.81175 4.60048 2.09915Z"
      />
    </svg>
  );
}

export function MoonIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={MoonSvg} {...props} />;
}
