import { Table, TableWrapper } from '../../../../../components';
import {
  OperationProductStoragesMercanteType,
  CargoStorageMerchantType,
} from '../../../../../types';
import { formatNumberToLocale } from '../../../../../utils/utils';
import { StorageMerchantTableFooter } from '../../operationProductDetailsDrawer/styles';
import { columns } from './columns';

type StorageMerchantTableProps = {
  storage?: OperationProductStoragesMercanteType;
  cargosStorages?: CargoStorageMerchantType[];
};

export function StorageMerchantTable(props: StorageMerchantTableProps) {
  const { storage, cargosStorages } = props;

  function getFooter() {
    if (storage) {
      return (
        <StorageMerchantTableFooter>
          <span>TOTAIS:</span>
          <div className="amount">
            {formatNumberToLocale(storage.total_amount)}
          </div>
          <div className="weight">
            {formatNumberToLocale(storage.total_weight)} ton
          </div>
        </StorageMerchantTableFooter>
      );
    }
    return null;
    const totalAmount =
      cargosStorages?.reduce(
        (sum, current) => sum + (current.amount || 0),
        0
      ) || 0;

    const totalWeight =
      cargosStorages?.reduce(
        (sum, current) => sum + (current.weight || 0),
        0
      ) || 0;

    return (
      <StorageMerchantTableFooter>
        <span>TOTAIS:</span>
        <div className="amount">{formatNumberToLocale(totalAmount)}</div>
        <div className="weight">{formatNumberToLocale(totalWeight)} ton</div>
      </StorageMerchantTableFooter>
    );
  }
  if (storage) {
    return (
      <TableWrapper style={{ background: '#ebf2f7' }}>
        <Table
          columns={columns()}
          dataSource={storage.cargos_storage}
          size="small"
          className="read-only"
          style={{ marginBlockEnd: '24px' }}
          pagination={{
            position: ['bottomCenter'],
            defaultPageSize: 10,
            total: storage.cargos_storage?.length,
            showSizeChanger: false,
          }}
          footer={() => getFooter()}
        />
      </TableWrapper>
    );
  }

  return (
    <TableWrapper style={{ background: '#ebf2f7' }}>
      <Table
        rowKey="ncm_code"
        columns={columns(true)}
        dataSource={cargosStorages}
        size="small"
        className="read-only"
        style={{ marginBlockEnd: '24px' }}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 10,
          total: cargosStorages?.length,
          showSizeChanger: false,
        }}
        footer={() => getFooter()}
      />
    </TableWrapper>
  );
}
