import { Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { valueType } from 'antd/lib/statistic/utils';
import moment from 'moment';
import { useState } from 'react';

import { ShiftType, WorkShiftFormType } from '../../../types';
import { isNullOrUndefined } from '../../../utils/utils';
import { FormItemInputNumber } from '../../antd/formItems/FormItemInputNumber';
import { TableFormList } from '../tableFormList/TableFormList';

type WorkShiftTableProps = {
  workPeriodForm?: FormInstance;
  dataSource?: ShiftType[] | WorkShiftFormType[];
  defaultWorkShifts: any[];
};
export type WorkShiftItemRowProps = {
  index: number;
};

export function WorkShiftTable(props: WorkShiftTableProps) {
  const { workPeriodForm, dataSource, defaultWorkShifts } = props;

  function WorkShiftItemRow(props: WorkShiftItemRowProps) {
    const { index } = props;

    const [workShiftHourStyle, setworkShiftHourStyle] = useState({
      color: 'var(--neutral_light)',
    });
    function onChangeGangAmount(event: valueType) {
      if (!isNullOrUndefined(event) && event !== '') {
        setworkShiftHourStyle({
          color: 'var(--neutral_medium)',
        });
      } else {
        setworkShiftHourStyle({
          color: 'var(--neutral_light)',
        });
      }
    }

    return (
      <Row
        gutter={16}
        style={{
          background: '#fff',
          margin: '8px 16px 8px 16px',
          padding: '5px',
        }}
      >
        <Col span={12} style={{ textAlign: 'center' }}>
          <span
            style={
              !isNullOrUndefined(
                workPeriodForm?.getFieldValue([
                  'work_shifts',
                  `${index}`,
                  'gang_amount',
                ])
              ) &&
              workPeriodForm?.getFieldValue([
                'work_shifts',
                `${index}`,
                'gang_amount',
              ]) !== ''
                ? { color: 'var(--neutral_medium)' }
                : workShiftHourStyle
            }
          >
            {dataSource && (
              <>
                {moment(
                  workPeriodForm?.getFieldValue([
                    'work_shifts',
                    `${index}`,
                    'shift_start',
                  ])
                ).format('HH:mm')}{' '}
                -{' '}
                {moment(
                  workPeriodForm?.getFieldValue([
                    'work_shifts',
                    `${index}`,
                    'shift_end',
                  ])
                ).format('HH:mm')}
              </>
            )}
          </span>
        </Col>
        <Col span={12}>
          <FormItemInputNumber
            style={{ margin: 0 }}
            name={[`${index}`, 'gang_amount']}
            onChange={(evt) => onChangeGangAmount(evt)}
          />
        </Col>
      </Row>
    );
  }

  return (
    <TableFormList
      columns={[
        { title: 'Turno', colSpan: 12, style: { textAlign: 'center' } },
        { title: 'Ternos', colSpan: 12 },
      ]}
      formListName={['work_shifts']}
      FormListItemRow={WorkShiftItemRow}
    />
  );
}
