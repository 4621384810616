import { PlusOutlined } from '@ant-design/icons';
import { FormInstance, Row } from 'antd';
import FormList, { FormListOperation } from 'antd/lib/form/FormList';
import moment from 'moment';
import { CSSProperties, ReactNode, useEffect, useState } from 'react';

import { Button, FormItem, FormItemSelect, Select } from '../../../components';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import {
  CompanyType,
  OperationProductCeMercanteType,
  OperationProductStoragesMercanteType,
  OperationStorageMercanteCargoTypeCargoType,
} from '../../../types';
import { CargoStorageTable } from './cargoStorageTable/CargoStorageTable';

type FormItemsStorageMerchantProps = {
  form: FormInstance;
  readOnly?: boolean;
  selectedStorage?: OperationProductStoragesMercanteType | undefined;
  selectedMerchantCE?: OperationProductCeMercanteType;
  storageIndex: number;
  style?: CSSProperties;
  isContainer?: boolean;
};

export function FormItemsStorageMerchant(props: FormItemsStorageMerchantProps) {
  const {
    form,
    readOnly,
    selectedStorage,
    selectedMerchantCE,
    storageIndex,
    isContainer,
  } = props;

  const [selectedCompany, setSelectedCompany] = useState<CompanyType>(Object);

  useEffect(() => {
    if (selectedCompany?.id) {
      const oldStorages: OperationProductStoragesMercanteType[] =
        form?.getFieldValue('storages');
      const newStorages = oldStorages.map((storage: any, index) => {
        if (index === storageIndex) {
          return {
            ...storage,
            storage_company: selectedCompany,
          };
        }
        return storage;
      });
      form?.setFieldsValue({ storages: newStorages });
    }
  }, [selectedCompany]);

  const { data: filteredCompanies, isFetching: isLoadingCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      company_type: 'STORAGE',
    });

  function companyOptionRenderer(company: any) {
    return (
      <Select.Option key={company.name} value={company.id}>
        {company.cnpj ? `${company.cnpj} - ` : ''}
        {company.name}
      </Select.Option>
    );
  }

  function onSelectCompany(option: number) {
    const companyObject = filteredCompanies?.results.find(
      (company: CompanyType) => {
        return company.id === option;
      }
    );
    if (companyObject) {
      setSelectedCompany({ ...companyObject });
    }
  }
  function onAddItem(
    onAdd: (
      item: OperationStorageMercanteCargoTypeCargoType,
      indexToAdd?: number
    ) => void
  ) {
    const lenght = form?.getFieldValue([
      'storages',
      storageIndex,
      'cargos_storage',
    ]).lenght;
    onAdd(
      {
        ...form?.getFieldValue([
          'storages',
          storageIndex,
          'cargos_storage',
          lenght - 1,
        ]),
        created_at: moment(),
      },
      lenght
    );
  }

  return (
    <div style={props.style}>
      {!isContainer && (
        <Row gutter={24}>
          <FormItem name={[storageIndex, 'storage_company', 'id']} noStyle />
          <FormItemSelect
            colSpan={24}
            name={[storageIndex, 'storage_company', 'name']}
            label="Local de armazenamento"
            dataList={filteredCompanies?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            rules={[{ required: true, message: 'campo obrigatório' }]}
            optionRenderer={companyOptionRenderer}
            onSelect={onSelectCompany}
          />
        </Row>
      )}
      <FormList name={[`${storageIndex}`, 'cargos_storage']}>
        {(cargosStorage, { add: addItem, remove: removeItem }) => {
          return (
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => onAddItem(addItem)}
              >
                Novo NCM
              </Button>
              <CargoStorageTable
                cargosStorage={cargosStorage}
                onRemoveItem={removeItem}
                storageIndex={storageIndex}
                storages={selectedMerchantCE?.storages || []}
                form={form}
              />
            </>
          );
        }}
      </FormList>
    </div>
  );
}
