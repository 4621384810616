import { Descriptions, FormatItem, Title } from '../../components';
import { AggregatedByStopoverType } from '../../typesWarehousing';
import { ResumeBackground, ResumeSection } from './styles';

const { Item } = Descriptions;

type WarehousingResumeProps = {
  type: 'EXPORT' | 'IMPORT';
  resume?: AggregatedByStopoverType;
};
export function WarehousingResume(props: WarehousingResumeProps) {
  const { type, resume } = props;

  const formatParkingSpace = (parkingSpace: string) =>
    parkingSpace.replace('QD', 'QUADRA ');

  return (
    <ResumeBackground>
      <ResumeSection
        style={{
          width: '100%',
        }}
      >
        <Title>Visão Geral</Title>
        {type === 'IMPORT' ? (
          <Descriptions column={3}>
            <Item label="Previsto">
              <FormatItem>{resume?.vehicle__status.PREDICTED} un</FormatItem>
            </Item>
            <Item label="No Pátio">
              <FormatItem>
                {resume?.vehicle__status.IN_WAREHOUSING} un
              </FormatItem>
            </Item>
            <Item label="Entregue">
              <FormatItem>{null}</FormatItem>
            </Item>
          </Descriptions>
        ) : type === 'EXPORT' ? (
          <Descriptions column={5}>
            <Item label="Previsto">
              <FormatItem>{resume?.vehicle__status.PREDICTED} un</FormatItem>
            </Item>
            <Item label="Vistoriado">
              <FormatItem>{null}</FormatItem>
            </Item>
            <Item label="Endereçados">
              <FormatItem>{null}</FormatItem>
            </Item>
            <Item label="Embarcados">
              <FormatItem>{null}</FormatItem>
            </Item>
            <Item label="Avariados">
              <FormatItem>{null}</FormatItem>
            </Item>
          </Descriptions>
        ) : null}
      </ResumeSection>
      <div style={{ display: 'flex', gap: '8px' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridGap: '8px',
          }}
        >
          <ResumeSection>
            <Title>Ocupação dos Blocos</Title>
            <Descriptions column={1}>
              {resume &&
                Object.keys(resume.vehicle__parking__space.blocos)
                  .filter((block) => block !== 'null')
                  .map((block) => (
                    <Item key={block} label={formatParkingSpace(block)}>
                      <FormatItem>
                        {resume.vehicle__parking__space.blocos[block]} un
                      </FormatItem>
                    </Item>
                  ))}
            </Descriptions>
          </ResumeSection>
          <ResumeSection>
            <Title>Bolsão de carregamento</Title>
            <Descriptions column={1}>
              {resume &&
                Object.keys(resume.vehicle__parking__space.bolsao)
                  .filter((bolsao) => bolsao !== 'null')
                  .map((bolsao) => (
                    <Item key={bolsao} label={formatParkingSpace(bolsao)}>
                      <FormatItem>
                        {resume.vehicle__parking__space.bolsao[bolsao]} un
                      </FormatItem>
                    </Item>
                  ))}
            </Descriptions>
          </ResumeSection>
          <ResumeSection>
            <Title>Modelos</Title>
            <Descriptions column={1}>
              {resume &&
                Object.keys(resume.vehicle__description)
                  .filter((model) => model !== 'null')
                  .map((model) => (
                    <Item key={model} label={model}>
                      <FormatItem>
                        {resume?.vehicle__description[model]} un
                      </FormatItem>
                    </Item>
                  ))}
            </Descriptions>
          </ResumeSection>
        </div>
      </div>
    </ResumeBackground>
  );
}
