import { message } from 'antd';
import { useEffect } from 'react';

import { Form, FormItem } from '../../../components/antd/Form';
import { Input } from '../../../components/antd/Input';
import { Select } from '../../../components/antd/Select';
import { useCreateProductGroupMutation } from '../../../services/cargoApi';
import { ProductGroup } from '../../../types';
import { cargoNatureList } from '../../../utils/lists';
import {
  RegisterHeaderForm,
  RegisterHeaderFormProps,
} from '../registerHeaderForm';
import { FormItemsWrapper } from './cargoForm';

type CargoTypeFormProps = RegisterHeaderFormProps & {
  onSaveProductGroup: (productGroup: ProductGroup) => void;
};

export function GroupForm(props: CargoTypeFormProps) {
  const [form] = Form.useForm();

  const [
    createProductGroup,
    { isSuccess: isSuccessProductGroup, isError: isErrorProductGroup },
  ] = useCreateProductGroupMutation();

  async function handleFormSubmit(values: any) {
    const productGroup: ProductGroup = {
      ...values,
    };
    const createdProductGroup = await createProductGroup(productGroup);
    if ('data' in createdProductGroup) {
      props.onSaveProductGroup(createdProductGroup.data);
    }
  }

  useEffect(() => {
    if (isSuccessProductGroup) {
      message.success('Grupo de produto criado');
      props.onClose(null);
    } else if (isErrorProductGroup) {
      message.error(
        'Ocorreu um erro ao salvar o grupo de produto, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessProductGroup, isErrorProductGroup]);

  return (
    <Form
      form={form}
      name="product_group"
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <RegisterHeaderForm onClose={props.onClose} formName="product_group">
        <div className="title">NOVO GRUPO DE PRODUTO</div>
      </RegisterHeaderForm>
      <FormItemsWrapper>
        <FormItem
          name="nature_of_cargo"
          label="Natureza da carga"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Select>
            {cargoNatureList.map((currentCargoNature) => {
              return (
                <Select.Option
                  key={currentCargoNature.id}
                  value={currentCargoNature.id}
                >
                  {currentCargoNature.name}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          name="name"
          label="Nome do grupo"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </FormItem>
      </FormItemsWrapper>
    </Form>
  );
}
