import { useState } from 'react';
import Flag from 'react-flagpack';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  CustomStatusTag,
  DefaultLayout,
  ErrorBoundary,
} from '../../components';
import { useBlockNavigation, useChangePagination } from '../../hooks';
import { useGetStorageGroupbyOperationsQuery } from '../../services/stopoverApi';
import { Stopover as StopoverType } from '../../types';
import { statusStorageList } from '../../utils/lists';
import { createDateStringPtBr } from '../../utils/utils';
import { CardRenderer } from '../operatorArea/styles';
import { StorageDetails } from './storageDetails';

const tabs = [
  { label: 'Todas', value: '' },
  { label: 'Confirmação pendente', value: 'PENDING' },
  { label: 'Confirmados', value: 'CONFIRMED' },
  { label: 'Alteração sugerida', value: 'NEW_SCHEDULE' },
];

function getCardRenderer(stopover: StopoverType) {
  return (
    <CardRenderer>
      <ErrorBoundary replace="">
        <Flag code={stopover.vessel_flag} hasBorder={false} size="m" />
      </ErrorBoundary>
      <div className="item-data">
        <div className="space-between">
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span>{stopover.vessel_name}</span>
          </div>
          <div>
            <span>Berço: </span>
            <span className="color-neutral-medium">
              {stopover.docking_place_name || 'Não informado'}
            </span>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '14px',
            textOverflow: 'ellipsis',
          }}
        >
          <span className="text-hide">{stopover.products}</span>
        </div>
        <span
          style={{ fontWeight: '600', fontSize: '12px' }}
          className="text-hide"
        >
          {stopover.shipping_agency_name}
        </span>
        <div>
          <span>Atracação: </span>
          <span className="color-neutral-medium">
            {stopover.docking_code || 'Código não gerado'}
          </span>
          {stopover.docking_expected_berthing && (
            <span className="color-neutral-medium">
              {' '}
              (ETB: {createDateStringPtBr(stopover.docking_expected_berthing)})
            </span>
          )}
        </div>
        <div style={{ width: '100%' }}>
          <CustomStatusTag
            list={statusStorageList}
            status={stopover.status || ''}
          />
        </div>
      </div>
    </CardRenderer>
  );
}

export function Storage() {
  const { onChangePagination, queryPage } = useChangePagination();
  const { blockNavigate, setBlockNavigate, setHasEdited } =
    useBlockNavigation();

  const { company } = useAppSelector((state: RootState) => state.user.user);

  const [filter, setFilter] = useState<{
    page: number;
    cargo_status?: string;
  }>({
    page: queryPage,
    cargo_status: '',
  });

  const { data: stopoveresData, isLoading: isLoadingStopoveresData } =
    useGetStorageGroupbyOperationsQuery(filter);

  const [selectedStopover, setSelectedStopover] = useState<StopoverType>(
    {} as StopoverType
  );

  const [filteredStopoveres, setFilteredStopoveres] = useState(
    [] as StopoverType[]
  );

  return (
    <DefaultLayout
      title="Armazenamento"
      subTitle={company?.name}
      tabs={tabs}
      filterField="status"
      setFilter={setFilter}
      stopoveresData={stopoveresData}
      filteredStopoveres={filteredStopoveres}
      setFilteredStopoveres={setFilteredStopoveres}
      isLoadingStopoveresData={isLoadingStopoveresData}
      queryPage={queryPage}
      onChangePagination={onChangePagination}
      selectedStopover={selectedStopover}
      setSelectedStopover={setSelectedStopover}
      labelPlural="ESTADIAS"
      pageContext="STORAGE"
      cardRenderer={getCardRenderer}
      inputSearchPlaceholder="Pesquisar produto"
    >
      <StorageDetails
        name="storage_form"
        selectedStopover={selectedStopover}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        setHasEdited={setHasEdited}
        companyName={company?.name}
        setSelectedStopover={setSelectedStopover}
      />
    </DefaultLayout>
  );
}
