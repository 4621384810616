import { Dispatch, SetStateAction } from 'react';

import { Collapse } from '../../../components';
import { useGetOperationCranesQuery } from '../../../services/operationCranesApi';
import {
  OperationalOperationType,
  Stopover as StopoverType,
} from '../../../types';
import { OperationCraneResumeDescription } from '../../operation/OperationsData/operationDescriptionsSections/generalCargo/containers/operationCraneResumeDescription';
import { ContainersByCranesCollapseData } from './containersCollapseData/ContainersByCranesCollapseData';

type OperationCranesDataProps = {
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
};

const { Panel } = Collapse;

export function OperationCranesData(props: OperationCranesDataProps) {
  const { selectedStopover, setSelectedStopover, activeKey, onOpenPanel } =
    props;

  const {
    data: operationCranesData,
    isFetching: isLoadingOperationCranesData,
  } = useGetOperationCranesQuery({
    operationId: selectedStopover.operation.id || 0,
  });

  return (
    <Collapse
      defaultActiveKey="containers"
      activeKey={activeKey}
      onChange={onOpenPanel}
    >
      <Panel header="Contêineres" key="containers">
        <OperationCraneResumeDescription
          selectedOperation={
            selectedStopover.operation || ({} as OperationalOperationType)
          }
          operationCranes={operationCranesData || []}
          isLoadingOperationCranes={isLoadingOperationCranesData}
          selectedStopover={selectedStopover}
        />
        <ContainersByCranesCollapseData
          operationCranes={operationCranesData || []}
          isLoadingOperationCranes={isLoadingOperationCranesData}
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
        />
      </Panel>
    </Collapse>
  );
}
