import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

import { BreakdownOccurrenceType } from '../../../types';
import { renderListOfItems } from '../../../utils/utils';

const { Paragraph } = Typography;

type ExpandedTableRowDescriptionProps = {
  record: BreakdownOccurrenceType;
};

const DescLabel = styled.span`
  font-size: 12px;
  color: var(--neutral_medium);
  display: inline-block;
  margin-right: 7px;
`;

export function ExpandedTableRowDescription(
  props: ExpandedTableRowDescriptionProps
) {
  const { record } = props;
  return (
    <div
      style={{ width: 'calc(100% - 47px)', float: 'right', padding: '10px 0' }}
    >
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <DescLabel>Plantão:</DescLabel>
          {record.duty === 'NIGHT' ? 'Noturno' : 'Diurno'}
        </Col>
        <Col span={14}>
          <Paragraph
            style={{
              marginBottom: '0px',
              display: 'inline-block',
            }}
            ellipsis={{ rows: 2, expandable: true, symbol: 'ver mais' }}
          >
            <DescLabel>Equipamento:</DescLabel>
            {renderListOfItems(record.equipments || [])}
          </Paragraph>
        </Col>
        <Col span={5}>
          <DescLabel>Armador:</DescLabel>
          {record.shipowner?.cnpj && `${record.shipowner?.cnpj} - `}
          {record.shipowner?.name}
        </Col>
        <Col span={24}>
          <DescLabel>Descrição:</DescLabel>
          {record.description}
        </Col>
      </Row>
    </div>
  );
}
