import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Flag from 'react-flagpack';

import { StopoverScheduleIcon } from '../../assets/icons/stopoverSchedule';
import { Button, DefaultLayout, ErrorBoundary, List } from '../../components';
import { useBlockNavigation, useChangePagination } from '../../hooks';
import { useGetChargesQuery } from '../../services/chargeApi';
import { FilterDefaultLayoutType, Stopover as StopoverType } from '../../types';
import { createDateStringPtBr } from '../../utils/utils';
import { CardRenderer } from '../operatorArea/styles';
import { ChargeDrawer } from './chargeDrawer/ChargeDrawer';
import { ChargeStatusTag } from './ChargeStatusTag';
import { InvoicingDetails } from './invoicingDetails';

const tabs = [
  { label: 'Todas', value: '' },
  { label: 'A faturar/estornado', value: 'BILLING,INVOICING,REVERSED' },
  { label: 'Faturado', value: 'BILLED,REBILLED' },
  { label: 'NF emitida', value: 'DONE' },
];

function getCardRenderer(stopover: StopoverType) {
  const [showList, setShowList] = useState(false);
  if (stopover.charge.charge_type === 'DOCKING') {
    return (
      <>
        <CardRenderer>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary replace="">
              <Flag
                code={stopover.charge.stopover?.vessel_flag}
                hasBorder={false}
                size="m"
              />
            </ErrorBoundary>
          </div>
          <div className="item-data">
            <div className="space-between">
              <div>
                <span className="title">
                  {stopover.charge?.stopover?.vessel_name}
                </span>
              </div>
              <span>
                ATR:{' '}
                <span className="color-neutral-medium code">
                  {stopover.charge.docking?.code || 'Código não gerado'}
                </span>
              </span>
            </div>
            <div className="space-between">
              <span className="subtitle">
                {stopover.charge.stopover?.shipping_agency_name}
              </span>
              <span className="color-neutral-medium code">
                {stopover.charge.stopover?.code}
              </span>
            </div>
            <div className="space-between" style={{ alignItems: 'baseline' }}>
              <div>
                <span>Primeiro cabo amarrado: </span>
                <span className="color-neutral-medium">
                  {createDateStringPtBr(
                    stopover.charge.docking?.first_cable_tied_in
                  )}
                </span>
              </div>
              <Button
                type="link"
                icon={showList ? <UpOutlined /> : <DownOutlined />}
                onClick={() => setShowList((prev) => !prev)}
              />
            </div>
            <div>
              <span>Último cabo desamarrado: </span>
              <span className="color-neutral-medium">
                {createDateStringPtBr(
                  stopover.charge.docking?.last_cable_untied_in
                )}
              </span>
            </div>
            <div className="space-between">
              <div>
                <span>Berço:</span>
                <span className="color-neutral-medium">
                  {stopover.charge.docking?.docking_place_name}
                </span>
              </div>
              <div>
                <span>IMO:</span>
                <span className="color-neutral-medium code">
                  {stopover.charge.stopover?.vessel_imo}
                </span>
              </div>
            </div>
            <ChargeStatusTag
              style={{
                width: '80px',
                textAlign: 'center',
                marginBottom: '10px',
              }}
              status={stopover.charge.status || 'BILLING'}
            />
          </div>
        </CardRenderer>
        {showList && (
          <List
            style={{ borderTop: '1px solid var(--neutral_lighter)' }}
            dataSource={stopover.charge.fare}
            renderItem={(item: any) => (
              <List.Item>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <span style={{ color: 'var(--neutral_medium)' }}>
                    {item.company.name}
                  </span>
                  <ChargeStatusTag status={item.status || 'BILLING'} />
                </div>
              </List.Item>
            )}
          />
        )}
      </>
    );
  }
  if (stopover.charge.charge_type === 'VESSEL') {
    return (
      <>
        <CardRenderer>
          <div style={{ paddingTop: '5px' }}>
            <ErrorBoundary replace="">
              <Flag
                code={stopover.charge?.vessel?.flag}
                hasBorder={false}
                size="m"
              />
            </ErrorBoundary>
          </div>
          <div className="item-data">
            <div className="space-between">
              <div>
                <span className="title">{stopover.charge?.vessel?.name}</span>
              </div>
              <span className="color-neutral-medium">
                EMBARCAÇÃO ESTACIONÁRIA
              </span>
            </div>
            <div className="space-between">
              <span className="subtitle">
                {stopover.charge?.vessel?.shipowner?.name}
              </span>
              <Button
                type="link"
                icon={showList ? <UpOutlined /> : <DownOutlined />}
                onClick={() => setShowList((prev) => !prev)}
              />
            </div>
            <div>
              <span>Inclusão do item: </span>
              <span className="color-neutral-medium">
                {createDateStringPtBr(stopover.charge.created_at)}
              </span>
            </div>
            <ChargeStatusTag
              style={{
                width: '80px',
                textAlign: 'center',
                marginBottom: '10px',
              }}
              status={stopover.charge.status || 'BILLING'}
            />
          </div>
        </CardRenderer>
        {showList && (
          <List
            style={{ borderTop: '1px solid var(--neutral_lighter)' }}
            dataSource={stopover.charge.fare}
            renderItem={(item: any) => (
              <List.Item>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <span style={{ fontWeight: '600' }}>{item.company.name}</span>
                  <span style={{ color: 'var(--neutral_medium)' }}>
                    {item.company.identifier}
                  </span>
                </div>
                <ChargeStatusTag status={item.status || 'BILLING'} />
              </List.Item>
            )}
          />
        )}
      </>
    );
  }

  return (
    <CardRenderer>
      <StopoverScheduleIcon />
      <div className="item-data">
        <div className="space-between">
          <div>
            <span className="title">{stopover.charge?.company?.name}</span>
          </div>
          <span className="color-neutral-medium">RESPONSÁVEL</span>
        </div>
        <div>
          <span>Inclusão do item: </span>
          <span className="color-neutral-medium">
            {createDateStringPtBr(stopover.charge.created_at)}
          </span>
        </div>
        <ChargeStatusTag
          style={{
            width: '80px',
            textAlign: 'center',
          }}
          status={stopover.charge.status || 'BILLING'}
        />
      </div>
    </CardRenderer>
  );
}

export function Invoicing() {
  const { onChangePagination, queryPage } = useChangePagination();
  const { blockNavigate, setBlockNavigate, hasEdited, setHasEdited } =
    useBlockNavigation();

  const [filter, setFilter] = useState<FilterDefaultLayoutType>({
    page: queryPage,
    status: '',
  });
  const [isVisibleAddRateDrawer, setisVisibleAddRateDrawer] = useState(false);
  const [selectedStopover, setSelectedStopover] = useState<StopoverType>(
    {} as StopoverType
  );

  const [filteredStopoveres, setFilteredStopoveres] = useState(
    [] as StopoverType[]
  );

  const { data: chargesData, isLoading: isLoadingChargesData } =
    useGetChargesQuery(filter);

  return (
    <>
      <ChargeDrawer
        visibleDrawer={isVisibleAddRateDrawer}
        closeDrawer={() => {
          setisVisibleAddRateDrawer(false);
        }}
        hasEdited={hasEdited}
        setHasEdited={setHasEdited}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
        setSelectedStopover={setSelectedStopover}
      />
      <DefaultLayout
        title="Faturamento"
        tabs={tabs}
        filterField="status"
        setFilter={setFilter}
        stopoveresData={chargesData}
        filteredStopoveres={filteredStopoveres}
        setFilteredStopoveres={setFilteredStopoveres}
        isLoadingStopoveresData={isLoadingChargesData}
        queryPage={queryPage}
        onChangePagination={onChangePagination}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        visibleAddDataDrawer={isVisibleAddRateDrawer}
        setVisibleAddDataDrawer={setisVisibleAddRateDrawer}
        labelAddDataDrawer="Tarifa avulsa"
        labelPlural="ITENS"
        pageContext="INVOICING"
        cardRenderer={getCardRenderer}
      >
        <InvoicingDetails
          name="invoicing_form"
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
          blockNavigate={blockNavigate}
          setBlockNavigate={setBlockNavigate}
          hasEdited={hasEdited}
          setHasEdited={setHasEdited}
        />
      </DefaultLayout>
    </>
  );
}
