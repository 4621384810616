import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-block-start: 29px;
  margin-block-end: 30px;
  width: 100%;

  .title {
    color: var(--ocean_dark);
    font-weight: 700;
    font-size: 24px;
    display: inline-block;
    margin-bottom: 15px;
  }

  .strong {
    font-weight: 600;
  }
`;

export const Attention = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--neutral_lightest);
  padding: 16px;
  margin: 24px 0;

  .title {
    font-weight: 700;
    color: var(--ocean_dark);
    font-size: 14px;
  }

  span {
    font-weight: 400;
  }

  .warning {
    color: var(--error-dark);
    font-weight: 400;
  }
`;
