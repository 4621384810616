import styled from 'styled-components';

const MembersCount = styled.div`
  padding: 4px 9px;
  border-radius: 11px;
  background-color: var(--marine_light);
  display: inline-block;
  font-family: 'Consolas';
  line-height: 13px;
  font-size: 13px;
  height: 100%;
`;

export function membersCountRenderer(text: string) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <MembersCount>{text}</MembersCount>
    </div>
  );
}
