import { message, Row } from 'antd';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Form,
  FormItem,
  FormItemDatePicker,
  FormItemSelect,
  GenericDrawerHeader,
  WorkShiftTable,
} from '../../../components';
import {
  useCreateWorkPeriodMutation,
  useUpdateWorkPeriodMutation,
} from '../../../services/workPeriodApi';
import {
  OperationalOperationType,
  OperationProductType,
  WorkPeriodType,
  WorkPeriodFormType,
} from '../../../types';
import { shiftModeList } from '../../../utils/lists';
import { enableDatesBetween } from '../../../utils/utils';
import {
  formatWorkPeriodToForm,
  formatWorkShiftToForm,
  formatWorkPeriodToSave,
} from './formatters';

type OperationPeriodFormProps = {
  onCloseDrawer?: () => void;
  operationWorkPeriods?: WorkPeriodType[];
  selectedWorkPeriod?: WorkPeriodType;
  setSelectedWorkPeriod?: Dispatch<SetStateAction<WorkPeriodType>>;
  selectedWorkPeriodPosition?: number | null;
  setSelectedWorkPeriodPosition?: Dispatch<SetStateAction<number | null>>;
  selectedProduct?: OperationProductType | undefined;
  setSelectedProduct?: (value: OperationProductType | undefined) => void;
  operation?: OperationalOperationType;
};

export function OperationPeriodForm(props: OperationPeriodFormProps) {
  const {
    onCloseDrawer,
    operationWorkPeriods,
    selectedWorkPeriod,
    setSelectedWorkPeriod,
    selectedProduct,
    setSelectedProduct,
    selectedWorkPeriodPosition,
    setSelectedWorkPeriodPosition,
    operation,
  } = props;

  const [form] = Form.useForm();
  const [shiftModeHelp, setshiftModeHelp] = useState<null | string>(null);
  const [workShifts, setWorkShifts] = useState<any[] | null>(null);

  const [workPeriodToForm, setWorkPeriodToForm] = useState<WorkPeriodFormType>(
    formatWorkPeriodToForm(
      !isEmpty(selectedWorkPeriod) && selectedWorkPeriod
        ? selectedWorkPeriod
        : ({} as WorkPeriodType)
    )
  );
  const [
    createWorkPeriod,
    {
      isLoading: isLoadingCreatePeriod,
      isSuccess: isSuccessCreatePeriod,
      isError: isErrorCreatePeriod,
    },
  ] = useCreateWorkPeriodMutation();

  const [
    updateWorkPeriod,
    {
      isLoading: isLoadingUpdatePeriod,
      isSuccess: isSuccessUpdatePeriod,
      isError: isErrorUpdatePeriod,
    },
  ] = useUpdateWorkPeriodMutation();
  function workPeriodFormHeaderTitle() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {isEmpty(selectedWorkPeriod) ? (
          <b>Novo período</b>
        ) : (
          <b>
            Período{' '}
            {selectedWorkPeriodPosition !== null &&
            selectedWorkPeriodPosition !== undefined
              ? selectedWorkPeriodPosition + 1
              : null}
          </b>
        )}
      </div>
    );
  }

  function onSaveWorkPeriod() {
    form
      .validateFields()
      .then(async (values) => {
        if (selectedWorkPeriod && !isEmpty(selectedWorkPeriod)) {
          updateWorkPeriod(
            formatWorkPeriodToSave({
              ...selectedWorkPeriod,
              ...values,
            })
          );
        }
        if (!selectedWorkPeriod || isEmpty(selectedWorkPeriod)) {
          createWorkPeriod(
            formatWorkPeriodToSave({
              ...values,
              operation_id: operation?.id,
            })
          );
        }
      })
      .finally(() => {
        if (onCloseDrawer) {
          form.resetFields();
          if (setSelectedWorkPeriod) {
            setSelectedWorkPeriod({} as WorkPeriodType);
          }
          if (setSelectedWorkPeriodPosition) {
            setSelectedWorkPeriodPosition(null);
          }
          onCloseDrawer();
        }
      });
  }

  useEffect(() => {
    if (isSuccessCreatePeriod || isSuccessUpdatePeriod) {
      message.success('Período salvo com sucesso!');
    }
  }, [isSuccessCreatePeriod, isSuccessUpdatePeriod]);
  useEffect(() => {
    if (isSuccessCreatePeriod || isSuccessUpdatePeriod) {
      message.error('Ocorreu um erro ao salvar o período');
    }
  }, [isErrorCreatePeriod, isErrorUpdatePeriod]);

  function onBackForm() {
    if (onCloseDrawer) {
      onCloseDrawer();
    }
    form.resetFields();
  }

  function createWorkShifts(shiftMode?: 'EIGHT' | 'SIX') {
    const workShifts: any[] = [];
    if (moment(form.getFieldValue('start_of_period')).isValid()) {
      const maxWorkShiftsAmount = shiftMode === 'EIGHT' ? 3 : 4;
      const shiftAmountNumber = shiftMode === 'EIGHT' ? 8 : 6;
      for (let i = 0; i < maxWorkShiftsAmount; i++) {
        workShifts.push({
          shift_start: moment(form.getFieldValue('start_of_period')).add(
            shiftAmountNumber * i,
            'hours'
          ),
          shift_end: moment(form.getFieldValue('start_of_period')).add(
            shiftAmountNumber * (i + 1),
            'hours'
          ),
        });
      }
    }
    return workShifts;
  }

  useEffect(() => {
    if (selectedWorkPeriod && selectedWorkPeriod.work_shifts) {
      if (selectedWorkPeriod.work_shifts.length === 0) {
        form.setFieldsValue({
          work_shifts: formatWorkShiftToForm(
            form.getFieldValue('work_shifts'),
            selectedWorkPeriod.shift_mode,
            moment(form.getFieldValue('start_of_period'))
          ),
        });
      }
    }
  }, [selectedWorkPeriod]);

  function onSelectShiftMode(evt: 'EIGHT' | 'SIX') {
    setWorkPeriodToForm(
      formatWorkPeriodToForm({
        ...workPeriodToForm,
        shift_mode: evt,
      } as unknown as WorkPeriodType)
    );
    // setWorkShifts(createWorkShifts(form.getFieldValue('shift_mode')));
    form.setFieldsValue({
      work_shifts: formatWorkShiftToForm(
        undefined,
        evt,
        moment(form.getFieldValue('start_of_period'))
      ),
    });
    if (form.getFieldValue('shift_mode') === 'EIGHT') {
      setshiftModeHelp('Trocas de turnos ocorrerão às 8:00, 16:00 e 00:00.');
    } else if (form.getFieldValue('shift_mode') === 'SIX') {
      setshiftModeHelp(
        'Trocas de turnos ocorrerão às 8:00, 14:00, 20:00 e 02:00.'
      );
    } else {
      setshiftModeHelp(null);
    }
  }

  function onSelectStartPeriod(value: Moment) {
    const initialDate = value
      .subtract(24, 'hours')
      .hours(8)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    form.setFieldsValue({
      start_of_period: initialDate,
      end_of_period: initialDate.add(24, 'hours'),
    });
    onSelectShiftMode(form.getFieldValue('shift_mode'));
  }

  function disabledDates(current: any) {
    return (
      current &&
      operationWorkPeriods?.find(
        (workPeriod) =>
          selectedWorkPeriod?.start_of_period !== workPeriod.start_of_period &&
          moment(workPeriod.start_of_period).format('YYYY-MM-DD') ===
            moment(current).format('YYYY-MM-DD')
      )
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={workPeriodToForm}
      onFinish={onSaveWorkPeriod}
      name="work_period"
    >
      <GenericDrawerHeader
        onBack={onBackForm}
        showBackButton
        title={workPeriodFormHeaderTitle()}
        formName="work_period"
        isLoadingSave={isLoadingCreatePeriod || isLoadingUpdatePeriod}
      />
      <div style={{ padding: '16px' }}>
        <Row gutter={24}>
          <FormItemDatePicker
            colSpan={12}
            name="start_of_period"
            label="Início do período"
            help="Período de 24 horas iniciando às 8:00."
            showTime={false}
            onChange={onSelectStartPeriod}
            format="DD/MM/YYYY"
            disabledDate={(current) => disabledDates(current)}
          />
          <FormItem name="end_of_period" noStyle />
          <FormItem name="operation_id" noStyle />
          <FormItemSelect
            colSpan={12}
            name="shift_mode"
            label="Modalidade dos turnos"
            dataList={shiftModeList}
            onSelect={(evt) => onSelectShiftMode(evt)}
            dependencies={['work_shifts']}
            help={shiftModeHelp}
          />
        </Row>
        <br />
        Cadastre abaixo os ternos de trabalho em seus respectivos turnos. As
        informações de ternos devem ser preenchidas somente para turnos
        utilizados.
        <WorkShiftTable
          workPeriodForm={form}
          dataSource={
            formatWorkPeriodToForm(
              !isEmpty(selectedWorkPeriod) && selectedWorkPeriod
                ? selectedWorkPeriod
                : ({} as WorkPeriodType)
            ).work_shifts
          }
          defaultWorkShifts={createWorkShifts(selectedWorkPeriod?.shift_mode)}
        />
      </div>
    </Form>
  );
}
