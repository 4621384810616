import { Col, Row } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormList from 'antd/lib/form/FormList';
import moment from 'moment';

import {
  FormItemInputNumber,
  Title,
  TableFormList,
  FormItem,
  Descriptions,
} from '../../../components';
import { TableFormListColumns } from '../../../components/common/tableFormList/TableFormList';
import {
  CargoStorageMerchantResumeType,
  OperationProductType,
  WorkPeriodType,
} from '../../../types';

type ProductOperationMovimentationProps = {
  currentOperationProduct: OperationProductType;
  selectedPeriod?: WorkPeriodType;
  selectedPeriodIndex: number;
  tableFormListColumns: TableFormListColumns[];
  form: FormInstance;
  cargoStorageMerchant: CargoStorageMerchantResumeType;
};

export function ProductOperationMovimentation(
  props: ProductOperationMovimentationProps
) {
  const {
    currentOperationProduct,
    selectedPeriod,
    tableFormListColumns,
    selectedPeriodIndex,
    cargoStorageMerchant,
    form,
  } = props;

  function ProductWorkShift(props: { index: number }) {
    const workShiftIndex = props.index;

    console.log(cargoStorageMerchant);

    return (
      <Row
        gutter={16}
        style={{
          background: '#fff',
          margin: '8px 16px 8px 16px',
          padding: '5px',
        }}
      >
        <Col span={8} style={{ color: 'var(--neutral_medium)' }}>
          {`${moment(
            selectedPeriod?.work_shifts[workShiftIndex].shift_start
          ).format('HH:mm')} - ${moment(
            selectedPeriod?.work_shifts[workShiftIndex].shift_end
          ).format('HH:mm')}`}
        </Col>
        <Col span={8} style={{ color: 'var(--neutral_medium)' }}>
          {selectedPeriod?.work_shifts[workShiftIndex].gang_amount}
        </Col>
        <Col span={8}>
          <FormList name={[workShiftIndex, 'work_shift_movimentation']}>
            {(workShiftMovimentations) => {
              console.log(workShiftMovimentations);
              console.log(
                form.getFieldValue([
                  'work_periods',
                  selectedPeriodIndex,
                  'work_shifts',
                  workShiftIndex,
                ])
              );
              return (
                <>
                  {workShiftMovimentations.map((_field, index) => {
                    if (
                      currentOperationProduct.id ===
                        form.getFieldValue([
                          'work_periods',
                          selectedPeriodIndex,
                          'work_shifts',
                          workShiftIndex,
                          'work_shift_movimentation',
                          index,
                          'operation_product_id',
                        ]) &&
                      cargoStorageMerchant.id ===
                        form.getFieldValue([
                          'work_periods',
                          selectedPeriodIndex,
                          'work_shifts',
                          workShiftIndex,
                          'work_shift_movimentation',
                          index,
                          'cargo_storage_merchant_id',
                        ])
                    ) {
                      return (
                        <>
                          <FormItemInputNumber
                            name={[index, 'total_movimented']}
                            addonAfter="ton"
                            style={{ marginBottom: '0px' }}
                          />
                          <FormItem
                            name={[index, 'operation_product_id']}
                            noStyle
                          />
                          <FormItem name={[index, 'work_period_id']} noStyle />
                          <FormItem name={[index, 'work_shift_id']} noStyle />
                          <FormItem name={[index, 'id']} noStyle />
                          <FormItem
                            name={[index, 'cargo_storage_merchant_id']}
                            noStyle
                          />
                        </>
                      );
                    }
                    return null;
                  })}
                </>
              );
            }}
          </FormList>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <Title>
        CE {cargoStorageMerchant.merchant_ce__code} - NCM{' '}
        {cargoStorageMerchant.ncm_code} {cargoStorageMerchant.ncm_description}
      </Title>
      <Descriptions column={2}>
        <Descriptions.Item label="Total manifestado">
          {cargoStorageMerchant.weight} ton
        </Descriptions.Item>
        <Descriptions.Item label="Total movimentado">
          {cargoStorageMerchant.total_movimented} ton
        </Descriptions.Item>
      </Descriptions>
      <div
        style={{
          borderBottom: '1px solid var(--neutral_lighter)',
          marginBottom: '20px',
        }}
      >
        {form
          .getFieldValue('work_periods')
          .map((_workPeriod: WorkPeriodType, index: number) => {
            return (
              <FormItem
                name="work_periods"
                hidden={selectedPeriodIndex !== index}
                key={index}
                style={{ marginBottom: '10px' }}
              >
                <TableFormList
                  formListName={[
                    'work_periods',
                    selectedPeriodIndex,
                    'work_shifts',
                  ]}
                  FormListItemRow={ProductWorkShift}
                  columns={tableFormListColumns}
                  style={{ marginTop: '0px', marginBottom: '20px' }}
                />
              </FormItem>
            );
          })}
      </div>
    </div>
  );
}
