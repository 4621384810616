import { Space, message, Row } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';

import { Button, Form, FormItemDatePicker } from '../../components';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type StartUndockingModalProps = BaseActionModalProps & {
  onStartUndocking: () => void;
};

export function StartUndockingModal(props: StartUndockingModalProps) {
  const {
    setIsVisible,
    onStartUndocking,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (currentDocking) {
      form.setFieldsValue({
        first_cable_untied_in: currentDocking?.first_cable_untied_in,
      });
    }
  }, [currentDocking]);

  function validate(
    operationRealFinish: string,
    undockingPilotOnBoard: Moment,
    firstCableUntiedIn: Moment
  ) {
    const operationRealFinishMoment = moment(operationRealFinish);
    if (undockingPilotOnBoard <= operationRealFinishMoment) {
      message.error(
        `A data de prático a bordo na manobra de desatracação não pode ser menor que data final da operação(${createDateStringPtBr(
          operationRealFinishMoment
        )})`,
        15
      );
      return false;
    }
    if (firstCableUntiedIn <= undockingPilotOnBoard) {
      message.error(
        `A data do primeiro cabo desamarrado não pode ser menor que o inicio da manobra de desatracação(${createDateStringPtBr(
          undockingPilotOnBoard
        )})`,
        15
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (updateDocking) {
        if (
          !validate(
            currentDocking?.operation_summary?.real_finish || '',
            values.undocking_manoeuvre?.pilot_on_board || '',
            values.first_cable_untied_in
          )
        )
          return;

        updateDocking(
          {
            ...values,
            first_cable_untied_in: createDateString(
              values.first_cable_untied_in
            ),
            undocking_pilot_on_board: createDateString(
              values.undocking_manoeuvre?.pilot_on_board
            ),
            id: currentDocking?.id,
          },
          onStartUndocking
        );
      }
    });
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
      <Row gutter={32}>
        <FormItemDatePicker
          name={['undocking_manoeuvre', 'pilot_on_board']}
          label="Prático a bordo em:"
          disabledDate={disabledDateAfterToday}
          colSpan={12}
          tooltip=""
          rules={[
            {
              required: true,
              message: 'Por favor, preencha a data e hora do prático a bordo',
            },
          ]}
          required
        />
        <FormItemDatePicker
          name="first_cable_untied_in"
          label="Primeiro cabo desamarrado em:"
          disabledDate={disabledDateAfterToday}
          colSpan={12}
          tooltip=""
          rules={[
            {
              required: true,
              message:
                'Por favor, preencha a data e hora do primeiro cabo desamarrado',
            },
          ]}
          required
        />
      </Row>
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          // onClick={() => onStartUndocking()}
          htmlType="submit"
          loading={isLoadingUpdateDocking}
        >
          Desatracar
        </Button>
      </Space>
    </Form>
  );
}
