import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { Button, Table } from '../../../../../components';
import { useLazyGetMaintenancesQuery } from '../../../../../services/maintenancesApi';
import {
  BerthMaintenance,
  DockingPlace,
  DockingPlaceForm,
} from '../../../../../types';
import { BerthMaintenanceDrawer } from '../berthMaintenanceForm/berthMaintenanceDrawer';
import { formatBerthMaintenanceToForm } from '../formatters';
import { columns } from './columns';
import { ExpandedRowMaintenance } from './expandedRowMaintenance';

type FormItemsBerthMaintenancesProps = {
  isEdit: boolean;
  form: FormInstance<DockingPlaceForm>;
  selectedBerth: DockingPlace;
};

export function FormItemsBerthMaintenances(
  props: FormItemsBerthMaintenancesProps
) {
  const { isEdit, form, selectedBerth } = props;

  const [isBerthMaintenanceFormVisible, setIsBerthMaintenanceFormVisible] =
    useState(false);
  const [selectedBerthMaintenance, setSelectedBerthMaintenance] =
    useState<BerthMaintenance>({} as BerthMaintenance);
  const [berthMaintenanceRowIndex, setBerthMaintenanceRowIndex] = useState(0);

  const [
    getMaintenancesByBerthTag,
    { data: berthMaintenancesData, isLoading: isLoadingBerthMaintenancesData },
  ] = useLazyGetMaintenancesQuery();

  useEffect(() => {
    if (isEdit && !isEmpty(selectedBerth)) {
      getMaintenancesByBerthTag({ berth: selectedBerth.tag });
    }
  }, [selectedBerth]);

  function onSelectMaintenance(maintenance: BerthMaintenance, index: number) {
    setBerthMaintenanceRowIndex(index);
    setIsBerthMaintenanceFormVisible(true);

    setSelectedBerthMaintenance(
      formatBerthMaintenanceToForm(filterBerths(maintenance))
    );
  }

  function onCloseForm() {
    setIsBerthMaintenanceFormVisible(false);
    setSelectedBerthMaintenance({} as BerthMaintenance);
  }

  function filterBerths(maintenance: BerthMaintenance) {
    return isEdit && !isEmpty(selectedBerth)
      ? {
          ...maintenance,
          berths: (maintenance.berths as DockingPlace[]).filter(
            (berth: DockingPlace) => berth.tag !== selectedBerth.tag
          ),
        }
      : maintenance;
  }

  return (
    <div style={{ padding: '12px' }}>
      <FormList name="berth_maintenances">
        {(
          berthMaintenances,
          { add: addBerthMaintenance, remove: removeBerthMaintenance }
        ) => {
          return (
            <>
              <BerthMaintenanceDrawer
                visibleDrawer={isBerthMaintenanceFormVisible}
                form={form}
                selectedBerth={selectedBerth}
                selectedBerthMaintenance={selectedBerthMaintenance}
                onBack={onCloseForm}
                isEdit={isEdit}
                addMaintenanceToForm={addBerthMaintenance}
                removeMaintenanceFromForm={removeBerthMaintenance}
                berthMaintenanceRowIndex={berthMaintenanceRowIndex}
              />
              <Button
                icon={<PlusOutlined />}
                type="primary"
                size="large"
                style={{ marginBottom: '12px' }}
                onClick={() => setIsBerthMaintenanceFormVisible(true)}
              >
                Nova manutenção
              </Button>
              <Table
                rowKey="description"
                columns={columns(onSelectMaintenance)}
                dataSource={
                  (berthMaintenancesData &&
                    !isEmpty(berthMaintenancesData.results) &&
                    berthMaintenancesData.results) ||
                  form.getFieldValue(['berth_maintenances'])
                }
                expandable={{
                  expandedRowClassName: () => 'berth-maintenance-row-expanded',
                  expandedRowRender: (record: any) => (
                    <ExpandedRowMaintenance record={record} />
                  ),
                  expandIcon: ({ expanded, onExpand, record }) => {
                    return expanded ? (
                      <Button
                        icon={
                          <UpOutlined
                            style={{ color: 'var(--green-sea_dark)' }}
                          />
                        }
                        type="text"
                        onClick={(e) => onExpand(record, e)}
                      />
                    ) : (
                      <Button
                        icon={
                          <DownOutlined
                            style={{ color: 'var(--green-sea_dark)' }}
                          />
                        }
                        type="text"
                        onClick={(e) => onExpand(record, e)}
                      />
                    );
                  },
                  expandRowByClick: true,
                }}
              />
            </>
          );
        }}
      </FormList>
    </div>
  );
}
