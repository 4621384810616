import { NcmItemsGroupbyStorageType, NCMItemType } from '../../../../types';

export const groupNCMItemByStorage = (input: NCMItemType[]) => {
  const groupsList: NcmItemsGroupbyStorageType[] = [];
  input.forEach((ncmItem) => {
    const groupKey = ncmItem.storage_terminal?.id || 0;
    if (!groupsList.some((item) => item.id === groupKey)) {
      const newGroup = {
        id: groupKey,
        name: ncmItem.storage_terminal?.name || 'Terminal não informado',
        items: [],
      };
      groupsList.push(newGroup);
    }
    groupsList.forEach((item) => {
      if (item.id === groupKey) {
        item.items.push(ncmItem);
      }
    });
  }, []);
  return groupsList;
};
