import styled from 'styled-components';

export const EmptyDataPlaceholderStyle = styled.div`
  width: 100%;
  height: 100%;
  background: var(--neutral_lightest);
  border-radius: 2px;
  border: 1px solid var(--neutral_lighter);
  color: var(--neutral_medium);
  padding: 9px 16px 9px 16px;
  font-size: 14px;
`;
