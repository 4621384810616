import { Modal, TabPane, Tabs } from '../../../components';
import { OperationalOperationType } from '../../../types';
import { VehiclesExportMovimentation } from './vehiclesExportMovimentation';
import { VehiclesImportMovimentation } from './vehiclesImportMovimentation';

type VehiclesOperationMovimentationProps = {
  operation?: OperationalOperationType;
  onClose: (show: boolean) => void;
  isVisible?: boolean;
};

export function VehiclesOperationMovimentation(
  props: VehiclesOperationMovimentationProps
) {
  const { operation, onClose, isVisible } = props;

  return (
    <Modal
      visible={isVisible}
      className="TOSPrimaryModal"
      title="Registro de movimentação"
      width={1100}
      onCancel={() => onClose(false)}
      footer={null}
    >
      <Tabs>
        <TabPane tab="Importação" key="import">
          <VehiclesImportMovimentation operation={operation} />
        </TabPane>
        <TabPane tab="Exportação" key="export">
          <VehiclesExportMovimentation operation={operation} />
        </TabPane>
      </Tabs>
    </Modal>
  );
}
