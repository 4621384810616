import { DownloadOutlined } from '@ant-design/icons';
import { Skeleton, Space } from 'antd';
import _ from 'lodash';
import { useMemo } from 'react';
import Flag from 'react-flagpack';
import styled from 'styled-components';

import {
  Descriptions,
  Title,
  FormatItem,
  Button,
  LightTable,
  ErrorBoundary,
} from '../../../../../../components';
import { useGetOperationCranesQuery } from '../../../../../../services/operationCranesApi';
import { useGetSystemParamByNameQuery } from '../../../../../../services/systemParamsApi';
import { StopoverGroupByOperatorType } from '../../../../../../types';
import { containerSizeList } from '../../../../../../utils/lists';
import { ContainersTable } from '../../../../../operatorArea/operationCranesData/containersCollapseData/styles';
import {
  cabotageDump,
  longHaulDump,
  totalProgressDump,
  transhipmentDump,
} from './containersDumpData';
import { cranesOperationTableColumns } from './cranesOperationTableColumns';
import { stoppagesTableColumns } from './stoppagesTableColumns';
import { totalProgress } from './totalProgressTableColumns';

type ContainersReportProps = {
  operationGroupbyOperator: StopoverGroupByOperatorType;
};
const { Item } = Descriptions;

const InfoBoardWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
`;

const directionDescriptions: { [code: string]: string } = {
  LOADING: 'Carregados',
  UNLOADING: 'Descarregados',
  REMOVAL_ON_BOARD: 'Remoção a bordo',
  DEPARTURE: 'Safamento',
};

type ProgressTotalContainerType = {
  direction_container: string;
  full: { 20: number; 40: number };
  empty: { 20: number; 40: number };
  operated: number;
  expected: number;
};

export function ContainersReport(props: ContainersReportProps) {
  const { operationGroupbyOperator } = props;

  const {
    data: operationCranesData,
    isFetching: isLoadingOperationCranesData,
  } = useGetOperationCranesQuery({
    operationId: operationGroupbyOperator.operation.id || 0,
  });

  const { data: diretionContainersData } = useGetSystemParamByNameQuery({
    name: 'DIRECTION_CONTAINER_TYPE',
  });

  function stoppagesTableFooter() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          textTransform: 'uppercase',
          fontSize: '12px',
        }}
      >
        <div>2 paralisações</div>
        <div>Total de horas paralisadas: 0h 29m</div>
      </div>
    );
  }

  const operatedContainers: ProgressTotalContainerType[] = useMemo(() => {
    const containers =
      operationCranesData && operationCranesData.length > 0
        ? operationCranesData[0].containers
        : [];

    const containersGroupedByDirection = _.groupBy(
      containers,
      (container) => container.direction_container?.code
    );

    function getFormattedContainers() {
      return (diretionContainersData?.items || []).map((direction) => {
        const containersGroupedByIsFull = _.groupBy(
          containersGroupedByDirection[direction.code],
          (container) => container.is_full
        );

        const containersGroupedByIsFullAndSize = _.groupBy(
          containersGroupedByIsFull.true,
          (container) => container.container_size
        );

        const containersGroupedByIsEmptyAndSize = _.groupBy(
          containersGroupedByIsFull.false,
          (container) => container.container_size
        );

        const operated =
          (containersGroupedByIsFullAndSize['20']?.length || 0) +
          (containersGroupedByIsFullAndSize['40']?.length || 0) +
          (containersGroupedByIsEmptyAndSize['20']?.length || 0) +
          (containersGroupedByIsEmptyAndSize['40']?.length || 0);

        return {
          direction_container: directionDescriptions[direction.code],
          full: {
            '20': containersGroupedByIsFullAndSize['20']?.length || 0,
            '40': containersGroupedByIsFullAndSize['40']?.length || 0,
          },
          empty: {
            '20': containersGroupedByIsEmptyAndSize['20']?.length || 0,
            '40': containersGroupedByIsEmptyAndSize['40']?.length || 0,
          },
          operated,
          expected: operated,
        };
      });
    }
    if (containers && containers.length > 0) {
      return getFormattedContainers();
    }
    return [];
  }, [operationCranesData, diretionContainersData]);

  return (
    <>
      <div
        style={{
          backgroundColor: '#EBF2F7',
          padding: '16px',
          marginTop: '16px',
        }}
      >
        <Title>DADOS GERAIS</Title>
        <InfoBoardWrapper>
          <div
            style={{
              display: 'flex',
              marginBottom: '10px',
              alignItems: 'center',
            }}
          >
            <ErrorBoundary replace="">
              <Flag
                code={operationGroupbyOperator.vessel_flag}
                hasBorder={false}
                size="m"
              />
            </ErrorBoundary>
            <span
              style={{
                display: 'inline-block',
                fontWeight: 'bold',
                marginLeft: '5px',
                fontSize: '12px',
              }}
            >
              {operationGroupbyOperator.vessel_name}
            </span>
          </div>
          <Descriptions column={12}>
            <Item span={2} label="Berço">
              <FormatItem>{operationGroupbyOperator.docking_place}</FormatItem>
            </Item>
            <Item span={4} label="Atracação">
              <FormatItem>{operationGroupbyOperator.docking_code}</FormatItem>
            </Item>
            <Item span={3} label="Último porto de escala">
              <FormatItem>
                {operationGroupbyOperator.last_port
                  ? `${operationGroupbyOperator.last_port.name}, ${operationGroupbyOperator.last_port.country_name}`
                  : null}
              </FormatItem>
            </Item>
            <Item span={3} label="Próximo porto de escala">
              <FormatItem>
                {operationGroupbyOperator.next_port
                  ? `${operationGroupbyOperator.next_port.name}, ${operationGroupbyOperator.next_port.country_name}`
                  : null}
              </FormatItem>
            </Item>
            <Item span={2} label="Operador">
              <FormatItem>{operationGroupbyOperator.operator}</FormatItem>
            </Item>
            <Item span={4} label="Finalidade da atracação">
              <FormatItem>{operationGroupbyOperator.docking_goal}</FormatItem>
            </Item>
            <Item span={6} label="Grupo de produto">
              <FormatItem>{operationGroupbyOperator.product_group}</FormatItem>
            </Item>
          </Descriptions>
        </InfoBoardWrapper>

        <Title style={{ marginTop: '32px' }}>RESUMO DA OPERAÇÃO</Title>
        <Skeleton active loading={isLoadingOperationCranesData}>
          <div style={{ display: 'flex', columnGap: '10px' }}>
            <InfoBoardWrapper>
              <Title>Progresso total</Title>
              <ContainersTable
                columns={totalProgress()}
                dataSource={operatedContainers}
                pagination={false}
                className="containers-reports"
              />
            </InfoBoardWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <InfoBoardWrapper style={{ height: '50%' }}>
                <Title>Produção por guindaste</Title>
                <Descriptions column={2}>
                  {operationCranesData?.map((crane) => (
                    <Item label={crane.crane_name}>
                      <FormatItem>{crane.containers?.length} un</FormatItem>
                    </Item>
                  ))}
                </Descriptions>
              </InfoBoardWrapper>
              {/* <InfoBoardWrapper style={{ height: '50%' }}>
              <Title>Totais</Title>
              <Descriptions>
                <Item label="Duração da operação">
                  <FormatItem>48 h</FormatItem>
                </Item>
                <Item label="Horas operacionais">
                  <FormatItem>45 h</FormatItem>
                </Item>
                <Item label="Paralisações gerais">
                  <FormatItem>1 (0h20m)</FormatItem>
                </Item>
                <Item label="Produtiv. líquida">
                  <FormatItem>12 un/h (24 ton/h)</FormatItem>
                </Item>
                <Item label="Produtiv. bruta">
                  <FormatItem>12 un/h (24 ton/h)</FormatItem>
                </Item>
                <Item label="Peso total">
                  <FormatItem>250.000 ton</FormatItem>
                </Item>
              </Descriptions>
            </InfoBoardWrapper> */}
            </div>
          </div>
        </Skeleton>
      </div>
      {/* <div style={{ border: '2px solid #EBF2F7', padding: '16px' }}>
        <Title>MOVIMENTAÇÃO</Title>
        <Button style={{ float: 'right' }} icon={<DownloadOutlined />}>
          Download do relatório completo
        </Button>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <ContainersTable
            columns={totalProgress(true, 'Longo Curso', 0)}
            dataSource={longHaulDump}
            pagination={false}
            className="containers-reports"
          />
          <ContainersTable
            columns={totalProgress(true, 'Cabotagem', 1)}
            dataSource={cabotageDump}
            pagination={false}
            className="containers-reports"
          />
          <ContainersTable
            columns={totalProgress(true, 'Transbordo', 2)}
            dataSource={transhipmentDump}
            pagination={false}
            className="containers-reports"
          />
        </div>
      </div>
      <div
        style={{
          border: '2px solid #EBF2F7',
          borderTop: '0px',
          padding: '16px',
        }}
      >
        <Title>TEMPOS</Title>
        <Descriptions column={3}>
          <Item label="Início da operação">
            <FormatItem>31/08/2021 02:00</FormatItem>
          </Item>
          <Item label="Fim da operação">
            <FormatItem>31/08/2021 08:00</FormatItem>
          </Item>
          <Item label="Paralisações gerais">
            <FormatItem>1 paralisação (0h20m)</FormatItem>
          </Item>
        </Descriptions>
        <LightTable columns={cranesOperationTableColumns()} />
        <Title style={{ marginTop: '15px' }}>Paralisações</Title>
        <LightTable
          columns={stoppagesTableColumns()}
          footer={() => stoppagesTableFooter()}
        />
      </div> */}
    </>
  );
}
