import { isEmpty } from 'lodash';
import {
  CargoType,
  DockingPlace,
  DockingPlaceForm,
  TreeType,
} from '../../../types';
import { cargoNatureList } from '../../../utils/lists';
import { capitalizeFirstLetter } from '../../../utils/utils';

export function formatCargoTypesGroupByCargoNature(
  cargoTypes: CargoType[]
): TreeType[] {
  const cargoTypesGroupByCargoNature = cargoNatureList.map((cargoNature) => ({
    title: cargoNature.name,
    value: cargoNature.name,
    key: cargoNature.name,
    children: cargoTypes
      .filter(
        (cargoType: CargoType) =>
          cargoType.product_group.nature_of_cargo.id === cargoNature.id
      )
      .map((filteredCargoType: CargoType) => ({
        title: capitalizeFirstLetter(filteredCargoType.name),
        value: filteredCargoType.name,
        key: filteredCargoType.name,
      })),
  }));

  return cargoTypesGroupByCargoNature.filter((item) => !isEmpty(item.children));
}

export function formatBerthToForm(berth: DockingPlace): DockingPlaceForm {
  return {
    ...berth,
    operators: berth.operators?.map((op) => op.id),
    cargo_type: berth.cargo_type?.map((cargoType) => cargoType.name),
    ship_types: berth.ship_types?.map((shipType) => shipType.name),
    sts_operations: berth.sts_operations ? 'true' : 'false',
    berth_lines: [],
    berth_maintenances: [],
  };
}

export function formatBerthToSave(berth: DockingPlace) {
  return {
    tag: berth.tag,
    name: berth.name,
    antaq_name: berth.antaq_name,
    antaq_code: berth.antaq_code,
    port_area: berth.port_area,
    depth: berth.depth,
    max_draught: berth.max_draught,
    dwt: berth.dwt,
    length: berth.length,
    loa: berth.loa,
    keel_gap: berth.keel_gap,
    sts_operations: berth.sts_operations === 'true',
    active: berth.active || true,
    operators: berth.operators || [],
    ship_types: berth.ship_types || [],
    place_type: berth.place_type || 'BERTH',
    cargo_type: berth.cargo_type || [],
  } as DockingPlace;
}
