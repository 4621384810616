import {
  GenericQueryParamsType,
  GenericResponse,
  VesselRequestUpdateType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type VesselRequestUpdateResponse = GenericResponse & {
  results: Array<VesselRequestUpdateType> | [];
};

export type VesselRequestUpdateGenericQueryParamsType =
  GenericQueryParamsType & {
    vessel?: number;
    is_confirmed?: boolean;
  };

const BASE_URL = '/maritime/vessel-requests-update';

export const vesselRequestUpdateApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getVesselRequestsUpdate: build.query<
      VesselRequestUpdateResponse,
      VesselRequestUpdateGenericQueryParamsType | void
    >({
      query: (params: VesselRequestUpdateGenericQueryParamsType) => {
        return {
          url: BASE_URL,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'VesselRequestUpdate' }],
    }),
    createVesselRequestUpdate: build.mutation<
      VesselRequestUpdateType,
      VesselRequestUpdateType
    >({
      query: (vesselRequestUpdate) => ({
        url: BASE_URL,
        method: 'POST',
        body: vesselRequestUpdate,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'VesselRequestUpdate' }]),
    }),
    patchVesselRequestUpdate: build.mutation<
      VesselRequestUpdateType,
      { id: number; is_confirmed: boolean }
    >({
      query: (vesselRequestUpdate) => ({
        url: `${BASE_URL}/${vesselRequestUpdate.id}`,
        method: 'PATCH',
        body: vesselRequestUpdate,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'VesselRequestUpdate' }]),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetVesselRequestsUpdateQuery,
  useLazyGetVesselRequestsUpdateQuery,
  useCreateVesselRequestUpdateMutation,
  usePatchVesselRequestUpdateMutation,
} = vesselRequestUpdateApi;
