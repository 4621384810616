import { EditFilled } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import Flag from 'react-flagpack';

import { MoonIcon } from '../../../assets/icons/moonIcon';
import { SunIcon } from '../../../assets/icons/sunIcon';
import { Button, ErrorBoundary, Tag } from '../../../components';
import { BreakdownOccurrenceType } from '../../../types';
import { createDateStringPtBr } from '../../../utils/utils';

const { Paragraph } = Typography;

const translateDuty = (duty: 'NIGHT' | 'DAYTIME') => {
  if (duty === 'DAYTIME') {
    return 'Plantão diurno';
  }
  return 'Plantão noturno';
};

export function breakdownsTableColumns(
  onOpenDrawer?: (breakdownOccurrence: BreakdownOccurrenceType) => void
): ColumnsType<Record<string, any>> {
  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'data/hora',
      dataIndex: 'date',
      render: (value: Moment, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {createDateStringPtBr(value) || 'Não informado'}
            <Tooltip title={translateDuty(record.duty)}>
              {record.duty === 'NIGHT' ? (
                <MoonIcon
                  style={{
                    fontSize: '20px',
                    marginLeft: '10px',
                    color: 'var(--neutral_medium)',
                  }}
                />
              ) : (
                <SunIcon
                  style={{
                    fontSize: '20px',
                    marginLeft: '10px',
                    color: 'var(--neutral_medium)',
                  }}
                />
              )}
            </Tooltip>
          </div>
        );
      },
      width: '10%',
    },
    {
      title: 'atracação',
      dataIndex: ['docking', 'code'],
      width: '10%',
      render: (value) => {
        return <div style={{ fontFamily: 'monospace' }}>{value}</div>;
      },
    },
    {
      title: 'embarcação',
      width: '20%',
      render: (_, record) => {
        return (
          <Space>
            {record.vessel && record.vessel.flag && (
              <ErrorBoundary replace="">
                <Flag code={record.vessel.flag} size="m" />
              </ErrorBoundary>
            )}
            <Paragraph
              style={{
                marginBottom: '0px',
                display: 'inline-block',
                textTransform: 'capitalize',
                fontWeight: 600,
                fontStyle: 'normal',
              }}
              ellipsis={{ rows: 1, expandable: true, symbol: 'ver mais' }}
            >
              {record.vessel?.name.toLowerCase() || ''}
            </Paragraph>
          </Space>
        );
      },
    },
    {
      title: 'avaria/ocorrência',
      render: (_, record) => {
        return (
          <>
            <Tag
              style={
                record.primary_type === 'OCCURRENCE'
                  ? {
                      background: '#C5E0E9',
                      borderColor: '#8FBFD6',
                      color: 'var(--ocean_dark)',
                    }
                  : {}
              }
            >
              {record.primary_type === 'BREAKDOWN' ? 'avaria' : 'ocorrência'}
            </Tag>
            {record.breakdown_occurrence_type?.description}
          </>
        );
      },
      width: '40%',
    },
    {
      title: 'plantonista',
      width: '10%',
      render: (_, record) => {
        return `${record.on_duty?.first_name || ''} ${
          record.on_duty?.last_name || ''
        }`;
      },
    },
    {
      title: '',
      width: '10%',
      render: (_, record) => {
        return (
          <Button
            icon={<EditFilled />}
            style={{ float: 'right' }}
            onClick={(evt) => {
              evt.stopPropagation();
              if (onOpenDrawer) {
                onOpenDrawer(record as BreakdownOccurrenceType);
              }
            }}
          />
        );
      },
    },
  ];
  return columns;
}
