import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { SearchInput } from '../../../components';
import { useChangePagination } from '../../../hooks';
import {
  useGetStopoverGroupbyOperatorQuery,
  useGetStopoversGroupbyOperatorQuery,
} from '../../../services/stopoverApi';
import {
  StopoverGroupByOperatorType,
  StopoversGroupByOperatorType,
} from '../../../types';
import { SectionHeader } from '../styles';
import { OperationReportDrawer } from './operationReportDrawer/OperationReportDrawer';
import { OperationsTable } from './operationsTable';

export function OperationsSection() {
  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();
  const PAGE_SIZE = 10;

  const [searchTerm, setSearchTerm] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [detailFilter, setDetailFilter] = useState({});

  const { data: stopoveresData, isFetching: isFetchingStopoveresData } =
    useGetStopoversGroupbyOperatorQuery({
      page: onChangePagination(),
      page_size: PAGE_SIZE,
      report_filter: searchTerm,
    });

  const { data: stopoverData, isFetching: isFetchingStopoverData } =
    useGetStopoverGroupbyOperatorQuery(detailFilter);

  useEffect(() => {
    if (stopoverData?.id) setIsVisible(true);
  }, [stopoverData]);

  function handleFilterChange(term: string) {
    setQueryPage(1);
    setSearchTerm(term);
  }

  function onSelectItem(stopover: StopoversGroupByOperatorType) {
    if (stopover?.id) {
      setDetailFilter({
        ...{ detail_filter_operation_id: stopover.id },
        ...(stopover.operator && {
          detail_filter_operator: stopover.operator,
        }),
        ...(stopover.product_group && {
          detail_filter_product_group: stopover.product_group,
        }),
      });
    }
  }

  function onCloseDrawer() {
    setDetailFilter({});
    setIsVisible(false);
  }

  return (
    <>
      <SectionHeader>
        <SearchInput
          size="large"
          allowClear
          placeholder="Pesquisar embarcação / código  da operação"
          style={{ width: '400px' }}
          onChange={debounce(
            (evt) => handleFilterChange(evt.target.value),
            500
          )}
        />
      </SectionHeader>
      <OperationsTable
        dataSource={stopoveresData?.results || []}
        onChangePagination={onChangePagination}
        total={stopoveresData?.count}
        isLoading={isFetchingStopoveresData}
        queryPage={queryPage}
        showSizeChanger={false}
        itemsPerPage={PAGE_SIZE}
        pageSize={PAGE_SIZE}
        onSelectItem={onSelectItem}
      />
      <OperationReportDrawer
        isVisible={isVisible}
        onClose={onCloseDrawer}
        stopover={stopoverData || ({} as StopoverGroupByOperatorType)}
      />
    </>
  );
}
