import { formatConsentsToDescription } from '../components/common/documentsConsentsData/formatConsentsToDescription';
import {
  ConsentsByPhaseDescriptionType,
  PspConsentsByPhaseType,
  PspStaymentType,
  PspSummaryType,
  PspVesselType,
} from '../types';
import { removeSpecialCharacters } from '../utils/utils';
import { tosApi } from './tosApi';

export const pspApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getConsents: build.query<ConsentsByPhaseDescriptionType[], string | void>({
      query: (duv: string) => {
        return {
          url: `/integration/duv/${duv}/consent`,
          method: 'GET',
        };
      },
      transformResponse: (response: PspConsentsByPhaseType) => {
        return formatConsentsToDescription(
          response || ({} as PspConsentsByPhaseType)
        );
      },
    }),
    getPspDuvSummary: build.query<PspSummaryType, string>({
      query: (duv) => {
        return {
          url: `/integration/duv/${duv}/summary`,
          method: 'GET',
        };
      },
      transformResponse: (response: PspSummaryType) => {
        response.navegation_agency.cnpj = response.navegation_agency?.cnpj
          ? removeSpecialCharacters(response.navegation_agency.cnpj)
          : response.navegation_agency.cnpj;
        return response;
      },
    }),
    getPspVessel: build.query<PspVesselType, string>({
      query: (duv) => {
        return {
          url: `/integration/duv/${duv}/vessel`,
          method: 'GET',
        };
      },
    }),
    getPspStayment: build.query<PspStaymentType, string>({
      query: (duv) => {
        return {
          url: `/integration/duv/${duv}/stayment`,
          method: 'GET',
        };
      },
      transformResponse: (response: PspStaymentType) => {
        response.last_port_stopover.bitrigram = response.last_port_stopover
          ? response.last_port_stopover?.bitrigram.substring(0, 5)
          : response.last_port_stopover;
        response.next_port_stopover.bitrigram = response.next_port_stopover
          ? response.next_port_stopover?.bitrigram.substring(0, 5)
          : response.next_port_stopover;
        return response;
      },
    }),
  }),
});

export const {
  useGetConsentsQuery,
  useLazyGetPspDuvSummaryQuery,
  useLazyGetPspVesselQuery,
  useLazyGetPspStaymentQuery,
} = pspApi;
