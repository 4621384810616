import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Flag from 'react-flagpack';

import { Vessel as VesselType, VesselTypeDescription } from '../../../types';
import { formatVesselToDescription } from '../../../utils/formatters';
import { translateCountryCode } from '../../../utils/translateCountryCode';
import { Descriptions } from '../../antd/Descriptions';
import { FormItem } from '../../antd/Form';
import { Input } from '../../antd/Input';
import ErrorBoundary from '../ErrorBoundary';
import { FormatItem } from '../FormatItem';
import { VesselInfosWrapper } from './styles';

type VesselInfosProps = {
  selectedVessel?: VesselType;
  showName?: boolean;
};

const { Item } = Descriptions;

export function VesselInfos(props: VesselInfosProps) {
  const { selectedVessel, showName } = props;

  const [vesselDescription, setVesselDescription] =
    useState<VesselTypeDescription>({} as VesselTypeDescription);

  useEffect(() => {
    setVesselDescription(
      formatVesselToDescription(selectedVessel || ({} as VesselType))
    );
    return () => {
      setVesselDescription({} as VesselTypeDescription);
    };
  }, [selectedVessel]);
  return (
    <div>
      {showName && (
        <FormItem label="Nome da embarcação">
          <span>
            <b>{vesselDescription.name}</b>
          </span>
        </FormItem>
      )}
      <VesselInfosWrapper>
        {!isEmpty(selectedVessel) && (
          <Descriptions column={2}>
            <Item label="Tipo de embarcação">
              <FormatItem>{vesselDescription.ship_type}</FormatItem>
            </Item>
            <Item label="Bandeira">
              <FormatItem>
                <div style={{ marginRight: '4px', display: 'inline-block' }}>
                  {vesselDescription.flag !== null && (
                    <ErrorBoundary replace="">
                      <Flag code={vesselDescription.flag} size="s" />
                    </ErrorBoundary>
                  )}
                </div>
                {translateCountryCode(vesselDescription.flag || '')}
              </FormatItem>
            </Item>
            <Item label="Armador proprietário">
              <FormatItem>{vesselDescription.shipowner}</FormatItem>
            </Item>
            <Item label="Calado máximo">
              <FormatItem>{vesselDescription.draught_max}</FormatItem>
            </Item>
            <Item label="MMSI">
              <FormatItem>{vesselDescription.mmsi}</FormatItem>
            </Item>
            <Item label="Calado mínimo">
              <FormatItem>{vesselDescription.draught_min}</FormatItem>
            </Item>
            <Item label="Inscrição da capitania">
              <FormatItem>
                {vesselDescription.captaincy_registration}
              </FormatItem>
            </Item>
            <Item label="LOA comprimento">
              <FormatItem>{vesselDescription.loa}</FormatItem>
            </Item>
            <Item label="Avante à meia-nau?">
              <FormatItem>{vesselDescription.forward_amidship}</FormatItem>
            </Item>
            <Item label="Boca - Largura">
              <FormatItem>{vesselDescription.width}</FormatItem>
            </Item>
            <Item label="Tipo de cabo amarração">
              <FormatItem>{vesselDescription.dock_line_type}</FormatItem>
            </Item>
            <Item label="DWT Porte">
              <FormatItem>{vesselDescription.dwt}</FormatItem>
            </Item>
          </Descriptions>
        )}
      </VesselInfosWrapper>
    </div>
  );
}
