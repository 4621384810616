import { ReactNode } from 'react';

import { TableWrapperStyles } from './styles';

type TableWrapperProps = {
  children: ReactNode;
  style?: React.CSSProperties;
};

export function TableWrapper(props: TableWrapperProps) {
  return (
    <TableWrapperStyles style={props.style}>
      {props.children}
    </TableWrapperStyles>
  );
}
