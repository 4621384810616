import styled, { ThemeProvider } from 'styled-components';

type CompanyTypeProps = {
  companyType: {
    value: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
    name?: string;
  };
};

const CompanyTypeTag = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.color};
  padding: 1px 8px 2px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 14px;
`;

export function CompanyTypeRenderer(props: CompanyTypeProps) {
  let theme = {};
  if (props.companyType.value === 1 || props.companyType.value === 7) {
    theme = {
      background: '#f5f5f5',
      color: '#595959',
    };
  } else if (props.companyType.value === 2 || props.companyType.value === 8) {
    theme = {
      background: '#e6fffb',
      color: '#13c2c2',
    };
  } else if (props.companyType.value === 3) {
    theme = {
      background: '#e6f7ff',
      color: '#1890ff',
    };
  } else if (props.companyType.value === 4) {
    theme = {
      background: '#e6f7ff',
      color: '#722ed1',
    };
  } else if (props.companyType.value === 5) {
    theme = {
      background: '#e6f7ff',
      color: '#ffb618',
    };
  } else if (props.companyType.value === 6) {
    theme = {
      background: '#fff0f6',
      color: '#eb2f96',
    };
  }

  return (
    <ThemeProvider theme={theme}>
      <CompanyTypeTag>{props.companyType.name}</CompanyTypeTag>
    </ThemeProvider>
  );
}
