import { CloseCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';

import {
  Alert,
  CargoStorageMerchantTable,
  Collapse,
  InnerCollapse,
} from '../../../components';
import { useGetStopoveresGroupyStorageMerchantQuery } from '../../../services/stopoverApi';

type StorageDataProps = {
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  stopoverId: number;
};

const { Panel } = Collapse;

const { Panel: InnerPanel } = InnerCollapse;

export function StorageData(props: StorageDataProps) {
  const { activeKey, onOpenPanel, stopoverId } = props;
  const { data } = useGetStopoveresGroupyStorageMerchantQuery(stopoverId);

  function getHeader(name: string, identifier: string) {
    return (
      <div style={{ display: 'flex', gap: '8px' }}>
        <span style={{ fontWeight: '600' }}>{name}</span>
        <span style={{ color: 'var(--neutral_medium)', fontWeight: '400' }}>
          {identifier}
        </span>
      </div>
    );
  }

  const hasRejectedCargos = useMemo(() => {
    if (data?.storage_companies) {
      return data.storage_companies.some((storageCompany) =>
        storageCompany.consignees?.some((consignee) =>
          consignee.items?.some((item) => item.status === 'REJECTED')
        )
      );
    }
  }, [data]);

  return (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel forceRender header="Locais de armazenamento" key="storages">
        {hasRejectedCargos && (
          <Alert
            type="error"
            message={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Há volumes de mercadorias rejeitados pelo terminal.</span>
                <span style={{ fontSize: '12px' }}>
                  Analise abaixo as mercadorias com armazenamento REJEITADO.
                </span>
              </div>
            }
            showIcon
            icon={<CloseCircleOutlined />}
            style={{ marginBottom: '16px' }}
          />
        )}
        {data?.storage_companies && data?.storage_companies?.length > 0 ? (
          <InnerCollapse expandIconPosition="end">
            {data?.storage_companies?.map((storageCompany) => (
              <InnerPanel
                key={storageCompany.name}
                forceRender
                header={getHeader(
                  storageCompany.name,
                  storageCompany.indetifier
                )}
              >
                {storageCompany.consignees?.map((consignee) => (
                  <InnerCollapse
                    key={consignee.id}
                    expandIconPosition="end"
                    className="white-header"
                    defaultActiveKey={consignee.id}
                  >
                    <InnerPanel
                      key={consignee.id}
                      style={{ padding: '16px' }}
                      forceRender
                      header={getHeader(consignee.name, consignee.identifier)}
                    >
                      <CargoStorageMerchantTable
                        dataSource={consignee.items}
                        isAgentAreaContext
                      />
                    </InnerPanel>
                  </InnerCollapse>
                ))}
              </InnerPanel>
            ))}
          </InnerCollapse>
        ) : (
          <Alert
            type="info"
            message="Não existem operações do tipo granel líquido com cargas atribuídas a
          locais de armazenamento nessa escala."
          />
        )}
      </Panel>
    </Collapse>
  );
}
