import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button, Drawer } from '../../../components';
import { Docking, Stopover as StopoverType, Vessel } from '../../../types';
import { DockingStaymentForm } from './dockingStaymentForm';

type DockingStaymentDrawerProps = {
  width: string | number;
  selectedDocking: Docking | undefined;
  setSelectedDocking: (value: any) => void;
  currentDockingKey: any;
  setCurrentDockingKey: (value: any) => void;
  selectedStopover?: StopoverType;
  setSelectedStopover?: (value: any) => void;
  allowedBerth: any[] | undefined;
  visible: boolean;
  setIsVisible: (value: boolean) => void;
  onBack?: () => void;
  isDockingWindowContext?: boolean;
  tugboatsData: Vessel[] | undefined;
  cargosData: any;
  operatorsData: any;
  manoeuvreBoardContext?: boolean;
};

export function DockingStaymentDrawer(props: DockingStaymentDrawerProps) {
  const {
    width,
    selectedDocking,
    setSelectedDocking,
    currentDockingKey,
    setCurrentDockingKey,
    selectedStopover,
    setSelectedStopover,
    allowedBerth,
    visible,
    setIsVisible,
    onBack,
    isDockingWindowContext,
    tugboatsData,
    cargosData,
    operatorsData,
    manoeuvreBoardContext,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  return (
    <Drawer
      visible={visible}
      width={width}
      closeIcon={
        <Button type="text" icon={<ArrowLeftOutlined />}>
          Voltar
        </Button>
      }
      onClose={!isDockingWindowContext ? () => onBack && onBack() : undefined}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <DockingStaymentForm
        name="docking_form"
        isVisibleForm={visible}
        setIsVisibleForm={setIsVisible}
        selectedDocking={selectedDocking}
        setSelectedDocking={setSelectedDocking}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        allowedBerth={allowedBerth}
        cargosData={cargosData}
        operatorsData={operatorsData}
        currentDockingKey={currentDockingKey}
        setCurrentDockingKey={setCurrentDockingKey}
        onBack={onBack}
        tugboatsData={tugboatsData}
        manoeuvreBoardContext={manoeuvreBoardContext}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
      />
    </Drawer>
  );
}
