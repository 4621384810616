import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { useState } from 'react';

import { Button } from '../../../components';
import { useChangePagination } from '../../../hooks';
import { useGetPortTugboatsQuery } from '../../../services/vesselApi';
import { Vessel } from '../../../types';
import { PanelHeader } from '../panelHeader';
import { RegistersDrawer } from '../registersDrawer';
import { RegistersTable } from '../registersTable';
import { columns } from './columns';
import { TugboatForm } from './TugboatForm';

export function Tugboat() {
  const [searchQueryTerm, setSearchQueryTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState<Vessel | null>(null);
  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();

  const {
    data: tugboatsData,
    isFetching: isFetchingTugboatData,
    refetch: refetchVesselData,
  } = useGetPortTugboatsQuery({
    imo_or_name: searchQueryTerm,
    page: onChangePagination(),
  });

  function onSaveVessel(_savedVessel?: Vessel) {
    setSelectedVessel(null);
    refetchVesselData();
  }
  function onCloseDrawer() {
    setIsFormVisible(false);
    setSelectedVessel(null);
  }

  function onSelectVessel(vessel: Vessel) {
    setSelectedVessel({
      ...vessel,
    });
    setIsFormVisible(true);
  }
  function searchData(value: any) {
    setQueryPage(1);
    setSearchQueryTerm(value);
  }
  function actionButtons() {
    return (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => setIsFormVisible(true)}
      >
        Novo rebocador
      </Button>
    );
  }
  return (
    <>
      <RegistersDrawer visible={isFormVisible} width={640}>
        <TugboatForm
          name="tugboat"
          onClose={onCloseDrawer}
          selectedTugboat={selectedVessel}
          onSaveTugboat={onSaveVessel}
          onSelectVessel={onSelectVessel}
        />
      </RegistersDrawer>
      <PanelHeader
        panelName="rebocador"
        actionButtons={actionButtons()}
        onSearch={searchData}
      />
      <Alert
        style={{ marginBottom: '20px' }}
        type="info"
        showIcon
        icon={<InfoCircleOutlined />}
        message="Devem ser cadastrados somente rebocadores do porto de Suape"
      />
      <RegistersTable
        rowKey="id"
        queryPage={queryPage}
        columns={columns}
        total={tugboatsData?.count}
        dataSource={tugboatsData?.results || []}
        onChangePagination={onChangePagination}
        isLoading={isFetchingTugboatData}
        showSizeChanger={false}
        onSelectItem={onSelectVessel}
      />
    </>
  );
}
