import styled from 'styled-components';

export const VesselInfosWrapper = styled.div`
  border: 1px solid var(--neutral_lighter);
  padding: 16px;
  & .info-row {
    margin-top: 21px;
    & .field-name,
    & .field-value {
      display: inline-block;
    }
    & .field-value {
      margin-left: 8px;
      color: var(--neutral_medium);
      font-weight: 600;
      font-size: 14px;
      line-height: 1.71;
    }
    & .field-name {
      color: var(--neutral_dark);
      font-size: 12px;
      line-height: 1.58;
      font-weight: normal;
    }
  }
`;
