import {
  OperationLineDescriptionType,
  OperationLineType,
  OperationProductType,
} from '../../types';
import { createDateStringPtBr } from '../../utils/utils';

export function formatOperationLineToDescription(
  operationLine: OperationLineType,
  operationProducts: OperationProductType[]
): OperationLineDescriptionType {
  return {
    id: operationLine.id,
    operation_product:
      operationProducts?.find(
        (item) => item.id === operationLine.operation_product_id
      )?.cargo_type?.name || null,
    movimentation: {
      // gross_flow: operationLine.movimentation?.gross_flow
      //   ? `${operationLine.movimentation.gross_flow} ton`
      //   : null,
      gross_flow: `${operationLine.movimentation?.reduce(
        (partialSum, current) => {
          return partialSum + current.gross_flow;
        },
        0
      )} ton`,
    },
    real_connection_hour: createDateStringPtBr(
      operationLine.real_connection_hour
    ),
    real_desconnection_hour: createDateStringPtBr(
      operationLine.real_desconnection_hour
    ),
    real_pump_start: createDateStringPtBr(operationLine.real_pump_start),
    real_pump_finish: createDateStringPtBr(operationLine.real_pump_finish),
    name: operationLine.name || null,
    line: operationLine.line.name,
  };
}
