import {
  CeMerchantGroupbyNcmCollapse,
  Collapse,
  InnerCollapse,
  Title,
} from '../../../../../../components';
import { useLazyGetNcmItemsGroupByOperatorQuery } from '../../../../../../services/ncmItemApi';
import {
  NcmItemGroupByConsigneeType,
  OperationProductType,
} from '../../../../../../types';
import { ResumeMovementManifest } from './resumeMovementManifest';
import { ManifestCollapseHeader, MovementDescription } from './styles';

const { Panel } = Collapse;

type MovementDescriptionSectionProps = {
  operationProduct: OperationProductType;
};

export function MovementDescriptionSection(
  props: MovementDescriptionSectionProps
) {
  const { operationProduct } = props;

  const [
    getNcmItemGroupByConsignee,
    {
      data: ncmItemGroupByOperatorData,
      isFetching: isFetchingNcmItemGroupByOperator,
    },
  ] = useLazyGetNcmItemsGroupByOperatorQuery();

  function getHeader(manifestNumber: string) {
    return (
      <ManifestCollapseHeader>
        Manifesto
        <span className="manifest-number">{manifestNumber}</span>
      </ManifestCollapseHeader>
    );
  }

  function onChangeActive(key: string | string[]) {
    if (key !== undefined) getNcmItemGroupByConsignee(Number(key));
  }

  return (
    <MovementDescription>
      <Title>MOVIMENTAÇÃO</Title>
      <InnerCollapse
        key="manifests"
        onChange={onChangeActive}
        expandIconPosition="end"
        accordion
      >
        {operationProduct.operation_manifests?.map((operationManifest) => (
          <Panel
            forceRender
            header={getHeader(operationManifest.manifest_code)}
            key={
              operationManifest.id || Number(operationManifest.manifest_code)
            }
          >
            <div style={{ padding: '16px' }}>
              <ResumeMovementManifest operationManifest={operationManifest} />

              <div>
                <Title>Embarcadores/Consignatários</Title>
                <CeMerchantGroupbyNcmCollapse
                  isFetchingNcmItemGroupByOperator={
                    isFetchingNcmItemGroupByOperator
                  }
                  ncmItemGroupByOperatorData={
                    ncmItemGroupByOperatorData ||
                    ({} as NcmItemGroupByConsigneeType)
                  }
                />
              </div>
            </div>
          </Panel>
        ))}
      </InnerCollapse>
    </MovementDescription>
  );
}
