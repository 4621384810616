import { Collapse as ANTDCollapse } from 'antd';
import styled from 'styled-components';

export const Collapse = styled(ANTDCollapse)`
  border: 0px;

  & .ant-collapse-header {
    background: var(--neutral_lightest);
    color: var(--neutral_dark) !important;

    &:hover {
      background: var(--neutral_lightest);
    }
    & .header-title-product {
      display: flex;
      align-items: center;
      > span {
        font-size: 16px;
        font-weight: 700;
        color: var(--neutral_medium);
      }
    }
    & .header-description {
      float: right;
      font-weight: normal;
      font-size: 12px;
      font-weight: 300;
      position: absolute;
      right: 44px;
    }
  }
  & .ant-collapse-item {
    border: 1px solid var(--neutral_lighter);
  }
  & button {
    margin-left: 16px;
  }
`;

export const DockingProductHeader = styled.div`
  border-top: solid 1px var(--neutral_lighter);
  padding: 16px;

  & .ant-row {
    display: flex;
    justify-content: space-between;
  }

  & .title {
    font-size: 14px;
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  & .ant-table-wrapper {
    margin-top: 16px;
  }
`;
