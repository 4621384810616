export function formatPermissionGroupToSave(permissionGroup: any) {
  return {
    ...permissionGroup,
    permissions: formatPermissionsArray(permissionGroup.permissions),
  };
}

export function formatPermissionsArray(permissions: any[]) {
  const permissionsArray: any[] = [];
  permissions.forEach((permission) => {
    if (typeof permission === 'string') {
      permission.split('-').forEach((id) => {
        if (id !== '') {
          permissionsArray.push(parseInt(id, 10));
        }
      });
    } else {
      permissionsArray.push(permission);
    }
  });
  return permissionsArray;
}
