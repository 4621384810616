import { EditFilled } from '@ant-design/icons';

import { Button } from '../../../components';
import { pilotFunctionList } from '../../../utils/lists';

export function columns(onSelectItem: (item: any) => void) {
  return [
    {
      title: <div className="show-amount">Prático</div>,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Cargo',
      key: 'function',
      dataIndex: 'function',
      render: (record: string) => {
        if (record) {
          return pilotFunctionList.find((item) => item.id === record)?.name;
        }
      },
    },
    {
      title: 'Empresa',
      key: 'company',
      dataIndex: ['company', 'name'],
    },
    {
      render: (_: any, record: any) => (
        <Button
          icon={<EditFilled />}
          style={{ float: 'right' }}
          onClick={() => onSelectItem(record)}
        />
      ),
    },
  ];
}
