import { ResourceQuantityTagWrapper } from './styles';

type ResourceQuantityTagProps = {
  resourceIcon: JSX.Element;
  quantity?: number | undefined;
  style?: any;
};

export function ResourceQuantityTag(props: ResourceQuantityTagProps) {
  const { style, resourceIcon, quantity } = props;

  return (
    <ResourceQuantityTagWrapper style={style}>
      {resourceIcon}
      <span>&nbsp;{quantity || 0}</span>
    </ResourceQuantityTagWrapper>
  );
}
