import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import { Stopover as StopoverType } from '../../../types';
import { Container } from './styles';

type GenericStopoverHeaderProps = {
  selectedStopover: StopoverType;
  tagName: ReactNode;
  style?: CSSProperties;
};

export function GenericStopoverHeader(props: GenericStopoverHeaderProps) {
  const { selectedStopover, tagName, style } = props;
  return (
    <Container style={style}>
      <span className="stopoverCode">{selectedStopover?.code}</span>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1>{selectedStopover?.vessel?.name}</h1>
        {tagName}
      </div>
    </Container>
  );
}
