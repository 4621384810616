import { Table } from 'antd';
import _ from 'lodash';
import { useMemo } from 'react';

import { Title } from '../../../../components';
import { useGetSystemParamByNameQuery } from '../../../../services/systemParamsApi';
import { OperationContainerType } from '../../../../types';
import { containerSizeList } from '../../../../utils/lists';
import { operatedContainersTableColumns } from './operatedContainersTableColumns';
import { ContainersTable } from './styles';

type OperatedContainersTableProps = {
  containers?: OperationContainerType[];
};
type OperatedContainerType = {
  direction_container: string;
  size: { [code: string]: { full: number; empty: number } };
};
export function OperatedContainersTable(props: OperatedContainersTableProps) {
  const { containers } = props;

  const directionDescriptions: { [code: string]: string } = {
    LOADING: 'Carregados',
    UNLOADING: 'Descarregados',
    REMOVAL_ON_BOARD: 'Remoção a bordo',
    DEPARTURE: 'Safamento',
  };

  const { data: diretionContainersData } = useGetSystemParamByNameQuery({
    name: 'DIRECTION_CONTAINER_TYPE',
  });

  const operatedContainers = useMemo(() => {
    function getFormattedContainers(): OperatedContainerType[] {
      const containersGroupedByDirection = _.groupBy(
        containers,
        (container) => container.direction_container?.code
      );

      return (diretionContainersData?.items || []).map((direction) => {
        const groupedBySize = _.groupBy(
          containersGroupedByDirection[direction.code],
          (container) => container.container_size
        );
        const containerSize: {
          [code: string]: { full: number; empty: number };
        } = {};
        containerSizeList.forEach((size) => {
          if (size.id in groupedBySize) {
            containerSize[size.id] = {
              full: groupedBySize[size.id].filter((g) => g.is_full).length,
              empty: groupedBySize[size.id].filter(
                (g) => !g.is_full || g === null
              ).length,
            };
          } else {
            containerSize[size.id] = { full: 0, empty: 0 };
          }
        });
        return {
          direction_container: directionDescriptions[direction.code],
          size: containerSize,
        };
      });
    }

    return getFormattedContainers();
  }, [diretionContainersData, containers]);

  function getTableSummary() {
    const totals: number[] = [];
    if (operatedContainers.length > 0) {
      containerSizeList.forEach((sizeItem) => {
        const fullTotal = operatedContainers.reduce(
          (sum, current) => sum + current.size[sizeItem.id].full,
          0
        );
        const emptyTotal = operatedContainers.reduce(
          (sum, current) => sum + current.size[sizeItem.id].empty,
          0
        );
        totals.push(fullTotal);
        totals.push(emptyTotal);
      });
    }

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>Totais:</Table.Summary.Cell>
          {totals.map((value, index) => (
            <Table.Summary.Cell key={index} index={1 + index}>
              {value}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      </Table.Summary>
    );
  }
  return (
    <div style={{ marginTop: '30px' }}>
      <Title>CONTÊINERES OPERADOS</Title>
      <ContainersTable
        rowKey="direction_container"
        columns={operatedContainersTableColumns()}
        dataSource={operatedContainers}
        className="read-only"
        pagination={false}
        summary={() => getTableSummary()}
      />
    </div>
  );
}
