import { Descriptions as DescriptionsANTD } from 'antd';
import styled from 'styled-components';

export const Descriptions = styled(DescriptionsANTD)`
  & > .ant-descriptions-view {
    & > table {
      background: transparent !important;
      & > tbody {
        & > tr {
          height: unset;
        }
      }
      & td {
        border: 0px !important;
        border-radius: 0px !important;
        top: unset !important;
      }
    }
  }
  .ant-descriptions-row {
  }
  .ant-descriptions-item-container {
  }

  .ant-descriptions-item-label {
    font-size: 12px;
    color: var(--neutral_medium) !important;
  }

  .ant-descriptions-item-content {
    font-size: 12px;
    color: var(--suape-black) !important;
  }

  .ant-descriptions-header {
    margin-bottom: 10px;
  }

  .ant-descriptions-title {
    color: var(--ocean_dark);
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .span-neutral-medium {
    span {
      color: var(--neutral_medium);
    }
  }
`;

export const DescriptionsSectionBackground = styled.div`
  background: var(--neutral_lightest);
  padding: 16px 32px;
  margin-left: -24px;
  margin-right: -24px;
  & > div {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
  }
`;
