import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { User as UserType } from '../../types';

export const slice = createSlice({
  name: 'user',
  initialState: {
    user: JSON.parse(Cookies.get('user') || '[]') as UserType,
    isLogged: false,
  },
  reducers: {
    changeUser(_state, { payload }) {
      const userData = {
        id: payload.id,
        firstName: payload.first_name,
        lastName: payload.last_name,
        email: payload.email,
        status: payload.status,
        isSuperuser: payload.is_superuser,
        groups: payload.groups.map((group: any) => ({
          id: group.id,
          name: group.name,
        })),
        company: payload.company
          ? {
              id: payload.company.id,
              name: payload.company.name,
            }
          : null,
      };

      Cookies.set('user', JSON.stringify(userData), { secure: true });

      return {
        isLogged: true,
        user: JSON.parse(Cookies.get('user') || '[]') as UserType,
      };
    },
    logout() {
      Cookies.remove('user');
      return {
        isLogged: false,
        user: {} as UserType,
      };
    },
  },
});

export const { changeUser, logout } = slice.actions;

export default slice.reducer;
