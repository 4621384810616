import { ConfigProvider, Spin } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, SetStateAction } from 'react';

import { LightTable, TableWrapper } from '../../../../../components';

type AssociateLoadingTableProps = {
  columns: (
    onSelectItem: any,
    checkedAll: boolean,
    setCheckedAll: Dispatch<SetStateAction<boolean>>,
    isVehicleManifest: boolean,
    selectedOpDirectionManifest: string
  ) => ColumnsType<Record<string, any>>;
  dataSource: Record<string, any>[];
  total?: number;
  itemsPerPage?: number;
  isLoading?: boolean;
  pageSize?: number;
  showSizeChanger?: boolean;
  rowKey?: string;
  onSelectItem?: (record: any) => void;
  onChangePagination?: (page: number) => number;
  queryPage: number;
  rowSelection?: any; // tive que manter any, O tipo correto seria TableRowSelection<object> mas o esLint reclama de usar object como type e se tentar passar Record<string, any> não funciona pq não é object
  checkedAll: boolean;
  setCheckedAll: Dispatch<SetStateAction<boolean>>;
  isVehicleManifest: boolean;
  selectedOpDirectionManifest: string;
};

export function AssociateLoadingTable(props: AssociateLoadingTableProps) {
  const {
    columns,
    dataSource,
    total,
    itemsPerPage,
    onChangePagination,
    isLoading,
    pageSize,
    showSizeChanger,
    onSelectItem,
    rowKey,
    queryPage,
    rowSelection,
    checkedAll,
    setCheckedAll,
    isVehicleManifest,
    selectedOpDirectionManifest,
  } = props;
  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }
  return (
    <Spin spinning={isLoading} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <LightTable
            rowKey={rowKey || 'key'}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: itemsPerPage,
              pageSize,
              total,
              onChange: onChangePagination,
              showSizeChanger,
              current: queryPage,
            }}
            columns={columns(
              onSelectItem,
              checkedAll,
              setCheckedAll,
              isVehicleManifest,
              selectedOpDirectionManifest
            )}
            dataSource={dataSource}
            rowSelection={rowSelection}
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
