import { EditFilled, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import { useState } from 'react';

import { Button, Collapse } from '../../../../components';
import { ProductDescription } from '../../../../components/common/ProductDescription';
import {
  CargoType,
  OperationalOperationType,
  OperationContextType,
  OperationProductAndCraneType,
  OperationProductType,
} from '../../../../types';
import { formatOperationProductToDescription } from '../../OperationsData/formatOperationToDescription';
import { OperationProductDetailsDrawer } from '../../OperationsData/operationProductDetailsDrawer/OperationProductDetailsDrawer';
import { HeaderTitle } from './styles';

type FormItemsProductProps = {
  operationForm: FormInstance;
  operation: OperationalOperationType;
  setIsProductEditDrawerVisible: (value: boolean) => void;
  setSelectedProduct?: (value: OperationProductType | undefined) => void;
};

const { Panel } = Collapse;

export function FormItemsProduct(props: FormItemsProductProps) {
  const {
    operationForm,
    operation,
    setIsProductEditDrawerVisible,
    setSelectedProduct,
  } = props;

  const [isVisibleConsignee, setIsVisibleConsignee] = useState(false);

  function getOperationType(): OperationContextType {
    if (operationForm.getFieldValue(['nature_of_cargo', 'id']) === '1') {
      return 'COMMON';
    }
    if (operationForm.getFieldValue(['nature_of_cargo', 'id']) === '2') {
      return 'LIQUID_BULK';
    }
    if (operationForm.getFieldValue(['nature_of_cargo', 'id']) === '3') {
      return 'SOLID_BULK';
    }

    return 'COMMON';
  }

  function onClickEditProduct(
    evt: React.MouseEvent<HTMLElement>,
    operationProduct: OperationProductType
  ) {
    evt.stopPropagation();
    setIsProductEditDrawerVisible(true);
    if (setSelectedProduct) {
      setSelectedProduct(operationProduct);
    }
  }

  function getHeaderTitle(cargo: CargoType) {
    return (
      <HeaderTitle>
        <span className="title">{cargo.name}</span>
        <span className="subtitle">{cargo.product_group.name}</span>
      </HeaderTitle>
    );
  }

  return (
    <Collapse expandIconPosition="end">
      <Panel header="Produtos" key="products">
        <Button
          style={{ marginTop: '8px', marginBottom: '24px' }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsProductEditDrawerVisible(true)}
        >
          Novo produto
        </Button>
        {operation?.items?.map((operationProduct, index) => (
          <Collapse
            className="inner-collapse"
            expandIconPosition="end"
            key={`collapse_product_${index}`}
          >
            <OperationProductDetailsDrawer
              product={formatOperationProductToDescription(
                operationProduct,
                getOperationType()
              )}
              isVisible={isVisibleConsignee}
              onCloseDrawer={() => setIsVisibleConsignee(false)}
            />
            <Panel
              header={
                operationProduct.cargo_type
                  ? getHeaderTitle(operationProduct.cargo_type)
                  : 'Tipo da mercadoria não informada'
              }
              key={`product_item_${index}`}
              extra={
                <Button
                  type="text"
                  icon={<EditFilled />}
                  className="edit-button"
                  onClick={(evt) => onClickEditProduct(evt, operationProduct)}
                >
                  Editar
                </Button>
              }
            >
              <Button
                icon={<EyeOutlined />}
                style={{ marginBottom: '15px' }}
                onClick={() => setIsVisibleConsignee(true)}
              >
                Ver consignatários
              </Button>
              <ProductDescription
                operationProduct={formatOperationProductToDescription(
                  operationProduct,
                  operation.type
                )}
                isVehicles={getOperationType() === 'VEHICLES'}
                isLiquidBulk={getOperationType() === 'LIQUID_BULK'}
                isSolidBulk={getOperationType() === 'SOLID_BULK'}
                unity="ton"
              />
            </Panel>
          </Collapse>
        ))}
      </Panel>
    </Collapse>
  );
}
