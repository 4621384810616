import { CheckOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { useEffect, useState, CSSProperties, ReactNode } from 'react';

type ExpireProps = {
  delay?: number;
  style?: CSSProperties;
  closable?: boolean;
  showIcon?: boolean;
  message: ReactNode;
  type: 'success' | 'error' | 'warning';
};

export function Expire(props: ExpireProps) {
  const { delay, style, closable, showIcon, message, type } = props;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, delay || 4000);
  }, [delay]);

  return visible ? (
    <div style={style || {}}>
      <Alert
        style={{ marginTop: '20px', marginBottom: '10px' }}
        type={type}
        closable={closable}
        showIcon={showIcon}
        icon={<CheckOutlined />}
        message={message}
      />
    </div>
  ) : (
    <div />
  );
}
