import { Drawer } from '../../../../components';
import {
  StoppageTypeDescription,
  WorkPeriodTypeDescription,
} from '../../../../types';
import { StoppagesByPeriodDescription } from './stoppagesByPeriodDescription';

type StoppagesByPeriodDrawerProps = {
  selectedStoppages?: StoppageTypeDescription;
  selectedWorkPeriods?: WorkPeriodTypeDescription[];
  setIsVisible: (value: boolean) => void;
  visible: boolean;
};

export function StoppagesByPeriodDrawer(props: StoppagesByPeriodDrawerProps) {
  const { selectedStoppages, selectedWorkPeriods, setIsVisible, visible } =
    props;
  return (
    <Drawer visible={visible} width={690} closable={false} maskClosable={false}>
      <StoppagesByPeriodDescription
        selectedStoppages={selectedStoppages}
        selectedWorkPeriods={selectedWorkPeriods}
        setDrawerVisible={setIsVisible}
      />
    </Drawer>
  );
}
