import { InfoCircleFilled } from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';

import {
  Descriptions,
  FormatItem,
  ProductTitle,
  Title,
} from '../../../../components';
import {
  Docking as DockingType,
  OperationalOperationTypeDescription,
  OperationProductTypeDescription,
} from '../../../../types';
import { DescriptionSection } from './styles';

const { Item } = Descriptions;

type OperationDataDescriptionProps = {
  selectedOperationProduct: OperationProductTypeDescription;
  selectedDocking: DockingType;
  selectedOperation: OperationalOperationTypeDescription;
};

export function OperationDataDescription(props: OperationDataDescriptionProps) {
  const { selectedOperationProduct, selectedDocking, selectedOperation } =
    props;
  return (
    <DescriptionSection>
      <Title>DADOS GERAIS</Title>
      <ProductTitle>
        <span className="title">{selectedDocking.docking_place?.name}</span>
      </ProductTitle>
      <Descriptions>
        <Item label="Natureza da carga">
          <FormatItem>{selectedOperation.nature_of_cargo}</FormatItem>
        </Item>
        <Item label="Sentido da operação">
          {/* <FormatItem>
            {selectedOperationProduct.operation_direction === 'UNLOADING'
              ? 'Descarga'
              : 'Carga'}
          </FormatItem> */}
        </Item>
        <Item label="Haverá inspeção">
          <FormatItem>{selectedOperation.inspection}</FormatItem>
        </Item>
      </Descriptions>
      <Descriptions column={4}>
        <Item label="Porão 01">
          <FormatItem>{null}</FormatItem>
        </Item>
        <Item label="Porão 02">
          <FormatItem>{null}</FormatItem>
        </Item>
        <Item label="Porão 03">
          <FormatItem>{null}</FormatItem>
        </Item>
        <Item label="Porão 04">
          <FormatItem>{null}</FormatItem>
        </Item>
      </Descriptions>
      <Descriptions>
        <Item
          label={
            <div>
              <span>Previsão da operação </span>
              <Tooltip title="Previsão informada pelo operador">
                <InfoCircleFilled />
              </Tooltip>
            </div>
          }
          span={3}
        >
          <div>
            <FormatItem>
              {selectedOperation?.expected_operation_start}
            </FormatItem>
            <span> até </span>
            <FormatItem>
              {selectedOperation.expected_operation_finish}
            </FormatItem>
            {/* <span style={{ color: 'var(--error-medium)' }}>
                              </span> */}
            {/* <Tooltip title="Previsão de fim da operação comprometido">
                              <WarningFilled style={{ color: '#FA8C16' }} />
                            </Tooltip> */}
          </div>
        </Item>
        <Item
          label={
            <div>
              <span>Estimativa de término da operação </span>
              <Tooltip title="Cálculo baseado na produtividade da operação">
                <InfoCircleFilled />
              </Tooltip>
            </div>
          }
          span={3}
        >
          <div>
            <FormatItem>{null}</FormatItem>
            <span> até </span>
            <FormatItem>{null}</FormatItem>
          </div>
        </Item>
      </Descriptions>
    </DescriptionSection>
  );
}
