import { Stopover } from '../../../types';
import { DocumentsConsentsDescription } from './documentsConsentsDescription';

type DocumentsConsentsDataProps = {
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  selectedStopover: Stopover;
};
export function DocumentsConsentsData(props: DocumentsConsentsDataProps) {
  const { activeKey, onOpenPanel, selectedStopover } = props;

  return (
    <DocumentsConsentsDescription
      activeKey={activeKey}
      onOpenPanel={onOpenPanel}
      selectedStopover={selectedStopover}
    />
  );
}
