import styled from 'styled-components';

export const Container = styled.div`
  width: 350px;

  .item {
    display: flex;
    flex-direction: row;
    margin: 10px;
    gap: 8px;
    align-items: center;

    &.right {
      justify-content: end;
    }

    .item-data {
      display: flex;
      flex-direction: row;
    }
  }
`;
