export function StorageTerminalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 16 16"
      fill="none"
      style={{ marginLeft: '5px' }}
      {...props}
    >
      <g id="wrapper" clipPath="url(#clip0_12004_22966)">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1657 5.94522C14.1657 5.6796 14.061 5.42803 13.8719 5.23897L7.42975 1.13324C7.33755 1.04164 7.21285 0.990234 7.08288 0.990234C6.95291 0.990234 6.82821 1.04164 6.736 1.13324L0.293815 5.23897C0.199332 5.33309 0.124661 5.44518 0.0742087 5.56862C0.0237566 5.69207 -0.00145408 5.82437 6.4763e-05 5.95772C0.00631479 6.50772 0.464127 6.94678 1.01413 6.94678H1.67819V14.3692H12.4876V6.94678H13.1657C13.4329 6.94678 13.6844 6.8421 13.8735 6.65303C13.9666 6.56024 14.0404 6.4499 14.0905 6.3284C14.1407 6.2069 14.1662 6.07666 14.1657 5.94522ZM11.3626 13.2442V5.82178H12.8641L7.08444 2.37855L1.3032 5.82178H2.8032V13.2442H11.3626ZM6.61116 9.62703H3.88894V12.3493H6.61116V9.62703ZM7.77782 9.62703H10.5V12.3493H7.77782V9.62703ZM8.5556 5.93259H5.83338V8.65481H8.5556V5.93259Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_12004_22966">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.681641)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
