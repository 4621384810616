import { Col, Row } from 'antd';
import Flag from 'react-flagpack';

import { ErrorBoundary, StatusTag } from '../../components';
import { Stopover } from '../../types';

export function warehousingCardRenderer(stopover: Stopover) {
  return (
    <>
      <Row className="header">
        <ErrorBoundary replace="">
          <Flag code={stopover.vessel__flag} hasBorder={false} />
        </ErrorBoundary>
        <span className="title">{stopover.vessel__name}</span>
        <span className="code">{stopover.code}</span>
      </Row>
      <Row>
        <Col xl={2} xxl={2} />
        <Col xl={11} xxl={11}>
          <span className="shipping_agency">
            {stopover.shipping_agency__name || (
              <span className="missing_agency">Agência Pendente</span>
            )}
          </span>
        </Col>
        <Col xl={11} xxl={11}>
          <span className="imo">
            Viagem:{' '}
            <span className="val">{stopover.shipowner_trip || 'PENDENTE'}</span>
          </span>
          <span className="imo">
            IMO: <span className="val">{stopover.vessel__imo}</span>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xl={2} xxl={2} />
        {stopover.total_chassis_to_import_on_stopover &&
        stopover.total_chassis_to_import_on_stopover > 0 ? (
          <StatusTag style={{ marginRight: '8px' }}>
            <span className="green-sea">
              Importação: {stopover.total_chassis_to_import_on_stopover}{' '}
              veiculos
            </span>
          </StatusTag>
        ) : null}
        {stopover.total_chassis_to_export_on_stopover &&
        stopover.total_chassis_to_export_on_stopover > 0 ? (
          <StatusTag>
            <span className="blue">
              Exportação: {stopover.total_chassis_to_export_on_stopover}{' '}
              veiculos
            </span>
          </StatusTag>
        ) : null}
      </Row>
    </>
  );
}
