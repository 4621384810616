import {
  GenericQueryParamsType,
  GenericResponse,
  OperationalOperationType,
  Stopover as StopoverType,
} from '../types';
import {
  ChargeToSaveType,
  ChargeType,
  PriceTableType,
} from '../typesFinancial';
import { StopoverResponse } from './stopoverApi';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type ChargeResponse = GenericResponse & {
  results: Array<StopoverType> | [];
};

export type ChargeGenericQueryParamsType = GenericQueryParamsType & {
  status?: string;
  complete_filter?: string;
};

export const ChargeApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getCharges: build.query<
      StopoverResponse,
      ChargeGenericQueryParamsType | void
    >({
      query: (params: ChargeGenericQueryParamsType) => {
        return {
          url: '/financial/charge',
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: ChargeResponse) => {
        const results: StopoverType[] = response.results.map((item) => ({
          key: item.id,
          id: item.id,
          charge: {
            charge_type: item.charge_type,
            id: item.id,
            stopover: item.stopover,
            docking: item.docking,
            vessel: item.vessel,
            company: item.company,
            fare: item.fare,
            created_at: item.created_at,
            status: item.status,
          } as ChargeType,
          operation: {} as OperationalOperationType,
        }));
        return { ...response, results };
      },
      providesTags: () => [{ type: 'Charge', code: 'get-all' }],
    }),
    getChargeById: build.query<StopoverType, number>({
      query: (id) => {
        return {
          url: `/financial/charge/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      transformResponse: (stopover: StopoverType) => {
        stopover.key = stopover.id;
        stopover.charge = {
          charge_type: stopover.charge_type,
          id: stopover.id,
          stopover: stopover.stopover,
          docking: stopover.docking,
          vessel: stopover.vessel,
          company: stopover.company,
          fare: stopover.fare,
          created_at: stopover.created_at,
        } as ChargeType;
        stopover.fare = [];
        return stopover;
      },
      providesTags: (_result, _error, code) => [{ type: 'Charge', code }],
    }),
    createCharge: build.mutation<StopoverType, ChargeToSaveType>({
      query: (charge) => ({
        url: `/financial/charge`,
        method: 'POST',
        body: charge,
      }),
      transformResponse: (stopover: StopoverType) => {
        stopover.key = stopover.id;
        stopover.charge = {
          charge_type: stopover.charge_type,
          id: stopover.id,
          stopover: stopover.stopover,
          docking: stopover.docking,
          vessel: stopover.vessel,
          company: stopover.company,
          fare: stopover.fare,
          created_at: stopover.created_at,
        } as ChargeType;
        stopover.fare = [];
        return stopover;
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Charge', code: 'get-all' }]),
    }),
    // updateCharge: build.mutation<ChargeType, ChargeToSave>({
    //   query: (charge) => ({
    //     url: `/financial/charges/${charge.id}`,
    //     method: 'PUT',
    //     body: charge,
    //   }),
    //   invalidatesTags: (_result, error) =>
    //     getInvalidatesTags(error, [{ type: 'Charge' }]),
    // }),
    deleteCharge: build.mutation<void, number>({
      query: (chargeId) => ({
        url: `/financial/charge/${chargeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Charge' }]),
    }),
    getPriceTables: build.query<PriceTableType[], void>({
      query: () => ({
        url: '/financial/price-tables',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetChargesQuery,
  useGetChargeByIdQuery,
  useLazyGetChargeByIdQuery,
  useCreateChargeMutation,
  // useUpdateChargeMutation,
  useDeleteChargeMutation,
  useGetPriceTablesQuery,
} = ChargeApi;
