import styled from 'styled-components';

export const ContainerAside = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  min-height: 600px;

  aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 220px;
    gap: 10px;

    .item-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-inline-start: 16px;
      border-left: 2px solid #fff;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: #eeeeee;
      }

      &.active {
        font-weight: 800;
        border-left: 2px solid var(--green-sea_medium);
        background-color: var(--neutral_lightest);
      }

      .item-container-data {
        display: flex;
        flex-direction: column;
        margin-inline-start: 10px;

        .item-container-data-resume {
          font-size: 12px;
          font-weight: 400;
          color: var(--neutral_medium);
        }
      }
    }
  }

  .container-data {
    display: flex;
    flex-direction: column;
    width: 607px;
    height: 100%;
    max-height: 600px;
    overflow: auto;

    .title {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;
