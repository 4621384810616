import { Typography } from 'antd';
import { useState } from 'react';

import {
  Modal,
  TabPane,
  Tabs,
  Descriptions,
  FormatItem,
} from '../../../../components';
import {
  OperationProductCeMercanteType,
  OperationProductStoragesMercanteType,
} from '../../../../types';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import { StorageMerchantTable } from './storageMerchantTable/StorageMerchantTable';
import { MerchantCEItem } from './styles';

type MerchantCEListItemProps = {
  index: number;
  merchantCE: OperationProductCeMercanteType;
  isContainers: boolean;
};
const { Paragraph } = Typography;

const { Item } = Descriptions;

export function MerchantCEListItem(props: MerchantCEListItemProps) {
  const { index, merchantCE, isContainers } = props;

  const [IsModalVisible, setIsModalVisible] = useState(false);

  function createStorageMerchantsNames(
    storageMechantsList: OperationProductStoragesMercanteType[]
  ) {
    let storageMerchantsNames = '';
    storageMechantsList.forEach((storageMerchant, index) => {
      storageMerchantsNames += capitalizeFirstLetter(
        `${storageMerchant.storage_company?.name} (${storageMerchant.cargos_storage.length})`
      );
      if (index + 1 !== storageMechantsList.length) {
        storageMerchantsNames += ', ';
      }
    });
    return storageMerchantsNames;
  }

  return (
    <>
      <Modal
        className="TOSPrimaryModal"
        width={900}
        title={`CE MERCANTE ${merchantCE.code}`}
        visible={IsModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Descriptions column={2}>
          <Item label="Porto de origem">
            <FormatItem>
              {merchantCE.origin_port
                ? `${capitalizeFirstLetter(
                    merchantCE.origin_port?.country_name
                  )}, ${capitalizeFirstLetter(merchantCE.origin_port?.name)}`
                : null}
            </FormatItem>
          </Item>
          <Item label="Porto de destino">
            <FormatItem>
              {merchantCE.destiny_port
                ? `${capitalizeFirstLetter(
                    merchantCE.destiny_port?.country_name
                  )}, ${capitalizeFirstLetter(merchantCE.destiny_port?.name)}`
                : null}
            </FormatItem>
          </Item>
        </Descriptions>
        {isContainers ? (
          <StorageMerchantTable cargosStorages={merchantCE.cargos_storages} />
        ) : (
          <Tabs>
            {merchantCE.storages.map((storage, index) => {
              return (
                <TabPane
                  tab={`${storage.storage_company?.name}`}
                  key={`storage_${index}`}
                >
                  <StorageMerchantTable storage={storage} />
                </TabPane>
              );
            })}
          </Tabs>
        )}
      </Modal>
      <MerchantCEItem key={index} onClick={() => setIsModalVisible(true)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="title">{merchantCE.code}</span>
          <span className="subtitile">
            Origem:{' '}
            {merchantCE.origin_port
              ? `${merchantCE.origin_port?.country_name}, ${merchantCE.origin_port?.name}`
              : 'Não informado'}{' '}
            • Destino:{' '}
            {merchantCE.destiny_port
              ? `${merchantCE.destiny_port?.country_name}, ${merchantCE.destiny_port?.name}`
              : 'Não informado'}
          </span>
        </div>
        <Paragraph
          ellipsis={{
            rows: 1,
            expandable: true,
            symbol: 'mais',
          }}
          className="subtitile"
        >
          {createStorageMerchantsNames(merchantCE.storages)}
        </Paragraph>
      </MerchantCEItem>
    </>
  );
}
