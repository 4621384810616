import styled from 'styled-components';

export const GenericDrawerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: #fff;
  justify-content: space-between;
  .code {
    font-family: 'Consolas';
    color: var(--neutral_light);
  }
  & .tabs-container {
    width: 100%;
    padding-top: 15px;
    &:before {
      width: 100%;
      height: 1px;
      background: var(--neutral_lighter);
      content: '';
      position: absolute;
      left: 0px;
      bottom: 0px;
    }
  }
  & .expand {
    cursor: default;
    display: flex;
    flex-direction: row;
    width: 135px;
    align-items: center;
    margin-top: 10px;
  }
  & .switchLabel {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
  }
`;
