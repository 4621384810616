import { CSSProperties, useEffect, useState } from 'react';

import { DockingLogStatusType } from '../../types';
import { dockingStatusList } from '../../utils/lists';
import { StatusTag } from './StatusTag';

type DockingLogStatusProps = {
  dockingLogStatus?: DockingLogStatusType;
  style?: CSSProperties;
};

export function DockingLogStatus(props: DockingLogStatusProps) {
  const { dockingLogStatus, style } = props;

  const [label, setLabel] = useState('');
  const [className, setClassName] = useState('');

  useEffect(() => {
    const dockingStatusSelected = dockingStatusList.find(
      (vessel) => vessel.status === dockingLogStatus
    );
    if (dockingStatusSelected) {
      setLabel(dockingStatusSelected?.label);
      setClassName(dockingStatusSelected?.className);
    } else {
      setLabel('Aguardando liberação da atracação');
      setClassName('orange');
    }
  }, [dockingLogStatus]);

  return (
    <StatusTag style={style}>
      <span className={className}>{label}</span>
    </StatusTag>
  );
}
