import { Row } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Select, FormItemSelect, FormItemInputNumber } from '../..';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import {
  CargoType as CargoTypeType,
  ProductGroup as ProductGroupType,
} from '../../../types';
import { booleanList } from '../../../utils/lists';
import {
  isNullOrUndefined,
  removeRepeatedItemsFromArray,
} from '../../../utils/utils';

type ProductFormProps = {
  product_key: number;
  readOnly: boolean;
  allowedCargo?: CargoTypeType[];
  operationTypes: any[];
  allowedOperators: any[];
  units: any[];
  formDocking?: any;
  noStyle?: boolean;
};

export const Product = styled.div`
  & .title {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    margin-bottom: 15px;
  }
`;

export function ProductForm(props: ProductFormProps) {
  const {
    product_key,
    readOnly,
    allowedCargo,
    operationTypes,
    allowedOperators,
    units,
    formDocking,
    noStyle,
  } = props;

  const [isDirectDischarge, setIsDirectDischarge] = useState(
    formDocking.getFieldValue(['products', product_key, 'direct_discharge']) ===
      'true'
  );
  const [productUnit, setProductUnit] = useState(
    formDocking.getFieldValue(['products', product_key, 'unit'])
  );
  const [cargoNatureOfCargo, setCargoNatureOfCargo] = useState('');
  const [selectedProductGroupId, setSelectedProductGroupId] = useState(0);
  const [productGroupData, setProductGroupData] = useState<ProductGroupType[]>(
    []
  );

  const { data: storages } = useGetCompaniesQuery({
    company_type: 'STORAGE',
    page_size: 100,
  });

  useEffect(() => {
    setProductGroupData(
      removeRepeatedItemsFromArray(
        allowedCargo?.flatMap((cargo) => cargo.product_group) || [],
        'id'
      )
    );

    const cargoProduct = formDocking.getFieldValue([
      'products',
      product_key,
      'cargo_type',
      'product_group',
      'nature_of_cargo',
      'name',
    ]);
    setCargoNatureOfCargo(cargoProduct);
  }, [allowedCargo]);

  function clearStorageProduct(): void {
    const allProducts = formDocking.getFieldValue('products');

    let productKey = 0; // simula a key do produto pois se o produto ainda não foi salvo, não tem como comparar pelo id
    const updatedProducts = allProducts.map((product: any) => {
      product.key = productKey++;
      if (product.key === product_key) {
        return {
          ...product,
          storage: null,
        };
      }
      return product;
    });

    formDocking.setFieldsValue({ products: updatedProducts });
  }

  function onChangeCargo(cargoName: any) {
    clearStorageProduct();
    if (isNullOrUndefined(cargoName)) {
      setCargoNatureOfCargo('');
      return;
    }

    const cargoSelected = allowedCargo?.find(
      (cargo) => cargo.name === cargoName
    );

    setCargoNatureOfCargo(
      cargoSelected?.product_group?.nature_of_cargo?.name || ''
    );
  }

  function onChangeDirectDischarge(evt: any) {
    if (evt === 'true') {
      setIsDirectDischarge(true);
    } else {
      setIsDirectDischarge(false);
    }
  }

  const unitsAddonAfter = (
    <Select
      disabled={readOnly}
      style={{ width: 140 }}
      defaultValue={productUnit}
      onChange={onChangeProductUnit}
      placeholder="unidade"
    >
      {units.map((unit) => (
        <Select.Option key={unit.value} value={unit.value}>
          {unit.name}
        </Select.Option>
      ))}
    </Select>
  );

  function onChangeProductUnit(value: any) {
    setProductUnit(value);
    const allProducts: any[] = formDocking.getFieldValue('products');

    const updatedProducts: any[] = allProducts.map((product, index): any => {
      if (index === product_key) {
        return {
          ...product,
          unit: value,
        };
      }
      return product;
    });
    formDocking.setFieldsValue({ products: updatedProducts });
  }

  function onSelectProductGroup(value: any) {
    setSelectedProductGroupId(value);
  }

  function cargoTypeOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.name} value={option.name}>
        {option.name}
      </Select.Option>
    );
  }

  return (
    <Product>
      <Row gutter={30} align="bottom">
        <FormItemSelect
          colSpan={8}
          name={[product_key, 'cargo_type', 'product_group', 'id']}
          label="Grupo do produto"
          dataList={productGroupData}
          onSelect={onSelectProductGroup}
          allowClear
        />
        <FormItemSelect
          colSpan={8}
          name={[product_key, 'cargo_type', 'name']}
          label="Tipo de mercadoria"
          allowClear
          showSearch
          notFoundContent="Não foram encontradas mercadorias de acordo com os filtros informados"
          dataList={allowedCargo?.filter(
            (cargo) => cargo.product_group.id === selectedProductGroupId
          )}
          disabled={readOnly}
          onSelect={(e) => onChangeCargo(e)}
          noStyle={noStyle}
          optionRenderer={cargoTypeOptionRenderer}
        />
        <FormItemInputNumber
          colSpan={8}
          name={[product_key, 'quantity']}
          label="Quantidade"
          readOnly={readOnly}
          addonAfter={unitsAddonAfter}
          noStyle={noStyle}
        />
      </Row>
      <Row gutter={30} align="bottom">
        <FormItemSelect
          colSpan={8}
          name={[product_key, 'operation_type']}
          label="Sentido da operação"
          notFoundContent="Não foi encontrado nenhum sentido da operação"
          dataList={operationTypes}
          disabled={readOnly}
          noStyle={noStyle}
        />
        <FormItemSelect
          colSpan={16}
          name={[product_key, 'operator', 'id']}
          label="Operador"
          notFoundContent="Não foi encontrado nenhum operador"
          dataList={allowedOperators}
          disabled={readOnly}
          noStyle={noStyle}
        />
      </Row>
      <Row gutter={30} align="bottom">
        <FormItemSelect
          colSpan={8}
          name={[product_key, 'direct_discharge']}
          label="Carga direta?"
          dataList={booleanList}
          disabled={readOnly}
          noStyle={noStyle}
          tooltip="Não será necessário armazenamento"
          onSelect={(evt) => onChangeDirectDischarge(evt)}
        />
        {!isDirectDischarge && (
          <FormItemSelect
            colSpan={8}
            name={[product_key, 'tanking_confirmed']}
            label="Armazenamento confirmado?"
            dataList={booleanList}
            disabled={readOnly}
            noStyle={noStyle}
          />
        )}
        {!isDirectDischarge && (
          <FormItemSelect
            colSpan={8}
            name={[product_key, 'storage', 'id']}
            label="Local de armazenamento"
            tooltip="Habilita local de acordo com o tipo de mercadoria selecionado"
            dataList={storages?.results}
            disabled={readOnly || isNullOrUndefined(cargoNatureOfCargo)}
            showSearch
            allowClear
            noStyle={noStyle}
          />
        )}
      </Row>
    </Product>
  );
}
