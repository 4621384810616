import {
  GenericQueryParamsType,
  GenericResponse,
  ManoeuvreBoardType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';

export type ManoeuvreResponse = GenericResponse & {
  results: Array<ManoeuvreBoardType> | [];
};

export type GetManoeuvreParamsType = GenericQueryParamsType & {
  order_by?: string;
  filter?: string;
};

export const manoeuvreApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getManoeuvresBoard: build.query<
      ManoeuvreResponse,
      GetManoeuvreParamsType | void
    >({
      query(params: GetManoeuvreParamsType) {
        return {
          url: '/maritime/manoeuvres-board',
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'Manoeuvre', code: '1' }],
    }),
  }),
  overrideExisting: true,
});

export const { useGetManoeuvresBoardQuery } = manoeuvreApi;
