// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Antd
import { message } from 'antd';

// Types
import { IAlert } from '../../types';

const initialState: IAlert = {
  message: '',
  type: 'success',
};

const createAlertFn = (_state: IAlert, action: PayloadAction<IAlert>) => {
  switch (action.payload.type) {
    case 'error':
      message.error(action.payload.message, 4);
      break;
    case 'warning':
      message.warning(action.payload.message, 4);
      break;
    case 'success':
      message.success(action.payload.message, 4);
      break;
    case 'info':
      message.info(action.payload.message, 4);
      break;
    default:
      break;
  }
};

export const AlertStore = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    createAlert: createAlertFn,
  },
});

export const { createAlert } = AlertStore.actions;

export default AlertStore.reducer;
