import {
  BaseError,
  FinancialTableTemporaryAcessType,
  FinancialTableType,
} from '../types';
import { tosApi } from './tosApi';

const BASE_URL = '/maritime/financial-tables-temporary-access';

export const FinancialTableTemporaryAcessApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getFinancialTableTemporaryAcess: build.query<
      FinancialTableTemporaryAcessType,
      { authorization: string; id: number }
    >({
      query: (params) => {
        const { authorization, id } = params;
        return {
          url: `${BASE_URL}/${id}`,
          method: 'GET',
          headers: { authorization },
        };
      },
    }),
    patchFinancialTable: build.mutation<
      FinancialTableType,
      Partial<FinancialTableType> & { authorization: string }
    >({
      query: (data) => ({
        url: BASE_URL,
        method: 'PATCH',
        body: data,
        headers: { authorization: data.authorization },
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetFinancialTableTemporaryAcessQuery,
  usePatchFinancialTableMutation,
} = FinancialTableTemporaryAcessApi;
