import { Space } from 'antd';
import { useState } from 'react';

import {
  Button,
  Form,
  FormItemDatePicker,
  FormItemRadioGroup,
  FormItemSelect,
  Select,
} from '../../../components';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetDockingsQuery } from '../../../services/dockingApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import { useGetUsersQuery } from '../../../services/usersApi';
import { useGetVesselQuery } from '../../../services/vesselApi';
import {
  breakdownOcurrenceTypeRadioList,
  onDutyRadioList,
} from '../../../utils/lists';
import { createDateString } from '../../../utils/utils';
import { BreakdownFiltersType } from './BreakdownsSection';

type BreakdownsFilterContentProps = {
  setBreakdownFilters: (filters: BreakdownFiltersType) => void;
  setIsVisibleFiltersPopover: (isVisible: boolean) => void;
};

export function BreakdownsFilterContent(props: BreakdownsFilterContentProps) {
  const { setBreakdownFilters, setIsVisibleFiltersPopover } = props;

  const [form] = Form.useForm();

  const [searchVessel, setSearchVessel] = useState({
    name: '',
  });
  const [searchUserOnDutyName, setSearchUserOnDutyName] = useState('');

  const [searchShipownerName, setSearchShipownerName] = useState('');

  const { data: breakdownTypes } = useGetSystemParamByNameQuery({
    name: 'BREAKDOWN_TYPE',
  });
  const { data: occurenceTypes } = useGetSystemParamByNameQuery({
    name: 'OCCURRENCE_TYPE',
  });
  const { data: vesselList } = useGetVesselQuery(searchVessel);
  const { data: dockingsList } = useGetDockingsQuery();

  const { data: usersOnDutyData } = useGetUsersQuery({
    role: 'ON_DUTY',
    first_name_or_last_name: searchUserOnDutyName,
  });

  const { data: shipownerData } = useGetCompaniesQuery(
    { name_or_cnpj: searchShipownerName, company_type: 'SHIPOWNER' },
    { skip: searchShipownerName === '' }
  );

  function vesselOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {`${option.imo} - ${option.name}`}
      </Select.Option>
    );
  }
  function dockingOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {`${option.code}`}
      </Select.Option>
    );
  }
  function shipownerOptionRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.key} value={option.id}>
        {`${option.cnpj && `${option.cnpj} - `} ${option.name}`}
      </Select.Option>
    );
  }

  async function handleFormSubmit() {
    form.validateFields().then(async (values: BreakdownFiltersType) => {
      Object.keys(values).forEach((key) => {
        if (values[key] === undefined) {
          delete values[key];
        }
        if (values.date) {
          values.period_start = createDateString(values.date[0]);
          values.period_end = createDateString(values.date[1]);
          delete values.date;
        }
      });
      setBreakdownFilters(values);
      setIsVisibleFiltersPopover(false);
    });
  }

  function handleFormReset() {
    setBreakdownFilters({});
    setIsVisibleFiltersPopover(false);
  }

  return (
    <div style={{ width: '400px' }}>
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="right"
        onFinish={handleFormSubmit}
        onReset={handleFormReset}
      >
        <FormItemRadioGroup
          label="Tipo"
          name="primary_type"
          options={breakdownOcurrenceTypeRadioList}
        />
        <FormItemDatePicker isRangePicker label="Período" name="date" />
        <FormItemSelect
          label="Atracação"
          name="docking_id"
          dataList={dockingsList}
          optionRenderer={dockingOptionRenderer}
          showSearch
        />
        <FormItemSelect
          label="Embarcação"
          name="vessel_id"
          dataList={vesselList?.results}
          showSearch
          onSearch={(value) => setSearchVessel({ name: value })}
          debounceDelay={300}
          notFoundContent="Digite uma busca"
          optionRenderer={vesselOptionRenderer}
        />
        <FormItemSelect
          label="Armador"
          name="shipowner"
          dataList={shipownerData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          notFoundContent="Digite uma busca"
          optionRenderer={shipownerOptionRenderer}
          showSearch
          onSearch={(val) => setSearchShipownerName(val)}
        />
        <FormItemSelect
          label="Tipo"
          name="breakdown_occurrence_type"
          dataList={
            breakdownTypes?.items.concat(occurenceTypes?.items || []) || []
          }
          allowClear
          showSearch
        />
        <FormItemSelect
          label="Plantonista"
          name="on_duty"
          dataList={usersOnDutyData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          allowClear
          showSearch
        />
        <FormItemRadioGroup
          label="Plantão"
          options={onDutyRadioList}
          name="duty"
        />
        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <Button htmlType="reset">Limpar</Button>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
        </Space>
      </Form>
    </div>
  );
}
