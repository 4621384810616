import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button } from '../../../components';
import { useChangePagination } from '../../../hooks';
import { useGetPilotsQuery } from '../../../services/pilotageApi';
import { PilotType } from '../../../types';
import { PanelHeader } from '../panelHeader';
import { RegistersDrawer } from '../registersDrawer';
import { RegistersTable } from '../registersTable';
import { columns } from './columns';
import { PilotForm } from './PilotForm';

export function Pilot() {
  const [searchQueryTerm, setSearchQueryTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedPilot, setselectedPilot] = useState<PilotType | null>(null);
  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();

  const {
    data: pilotsData,
    isFetching: isFetchingPilotData,
    refetch: refetchPilotData,
  } = useGetPilotsQuery({
    name: searchQueryTerm,
    page: onChangePagination(),
    page_size: 10,
  });

  function onSavePilot() {
    setselectedPilot(null);
    refetchPilotData();
  }
  function onCloseDrawer() {
    setIsFormVisible(false);
    setselectedPilot(null);
  }

  function onSelectPilot(pilot: PilotType) {
    setselectedPilot({
      ...pilot,
    });
    setIsFormVisible(true);
  }

  function actionButtons() {
    return (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => setIsFormVisible(true)}
      >
        Novo Prático
      </Button>
    );
  }
  function searchData(value: any) {
    setQueryPage(1);
    setSearchQueryTerm(value);
  }

  return (
    <>
      <RegistersDrawer visible={isFormVisible} width={640}>
        <PilotForm
          name="pilot"
          onClose={onCloseDrawer}
          selectedPilot={selectedPilot}
          onSavePilot={onSavePilot}
          setQueryPage={setQueryPage}
        />
      </RegistersDrawer>
      <PanelHeader
        panelName="pratico"
        actionButtons={actionButtons()}
        onSearch={searchData}
      />
      <RegistersTable
        rowKey="id"
        queryPage={queryPage}
        columns={columns}
        total={pilotsData?.count}
        dataSource={pilotsData?.results || []}
        onChangePagination={onChangePagination}
        isLoading={isFetchingPilotData}
        showSizeChanger={false}
        onSelectItem={onSelectPilot}
      />
    </>
  );
}
