import {
  Cargo,
  CargoType,
  GenericQueryParamsType,
  GenericResponse,
  ProductGroup,
} from '../types';
import { getHeaders, tosApi } from './tosApi';

type ProductGroupResponse = GenericResponse & {
  results: Array<ProductGroup> | [];
};

type CargoResponse = GenericResponse & {
  results: Array<Cargo> | [];
};
type CargoQueryParamsType = GenericQueryParamsType & {
  name?: string;
};

export const cargoApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getCargos: build.query<CargoResponse, GenericQueryParamsType | void>({
      query: (params: GenericQueryParamsType) => {
        return {
          url: `/maritime/cargos`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: CargoResponse) => {
        response.results.forEach((cargo) => {
          cargo.key = cargo.id;
        });
        return response;
      },
      providesTags: () => [{ type: 'Cargo', code: '2' }],
    }),
    createCargo: build.mutation<any, Cargo>({
      query: (cargo) => {
        return {
          url: `/maritime/cargos`,
          method: 'POST',
          body: cargo,
        };
      },
      invalidatesTags: () => [{ type: 'Cargo', code: '2' }],
    }),
    updateCargo: build.mutation<
      CargoType,
      Partial<CargoType> & Pick<CargoType, 'id'>
    >({
      query: ({ id, ...cargo }) => {
        return {
          url: `/maritime/cargos/${id}`,
          method: 'PUT',
          body: cargo,
        };
      },
      invalidatesTags: () => [{ type: 'Cargo', code: '2' }],
    }),
    deleteCargo: build.mutation<CargoType, number>({
      query: (cargoId) => {
        return {
          url: `/maritime/cargos/${cargoId}`,
          method: 'DELETE',
        };
      },
    }),
    getProductGroups: build.query<
      ProductGroupResponse,
      { nature_of_cargo?: string } | void
    >({
      query: (params: { nature_of_cargo?: string }) => {
        return {
          url: `maritime/product-groups`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
    }),
    createProductGroup: build.mutation<ProductGroup, ProductGroup>({
      query: (productGroup) => {
        return {
          url: `/maritime/product-groups`,
          method: 'POST',
          body: productGroup,
        };
      },
    }),
    createCargoType: build.mutation<CargoType, CargoType>({
      query: (cargoType) => {
        return {
          url: `/maritime/cargo-type`,
          method: 'POST',
          body: cargoType,
        };
      },
    }),
    getCargoType: build.query<
      { results: Array<any> },
      GenericQueryParamsType | void
    >({
      query: (params: GenericQueryParamsType) => {
        return {
          url: `/maritime/cargo-type`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: { results: Array<any> }) => {
        response.results.forEach((item) => {
          item.id = item.name;
          return item;
        });
        return response;
      },
    }),
  }),
});

export const {
  useGetCargosQuery,
  useCreateCargoMutation,
  useGetProductGroupsQuery,
  useCreateProductGroupMutation,
  useCreateCargoTypeMutation,
  useUpdateCargoMutation,
  useDeleteCargoMutation,
  useGetCargoTypeQuery,
} = cargoApi;
