export function OperationIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-name="operações"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#clip0_2472_27376)">
        <g clipPath="url(#clip1_2472_27376)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.553 5.65386L12.3967 5.65386V4.68164H9.73707V5.65386L10.5808 5.65386V6.71772C10.5808 6.84664 10.632 6.97029 10.7232 7.06145C10.8143 7.15262 10.938 7.20383 11.0669 7.20383C11.2338 7.20383 11.3969 7.25331 11.5357 7.34602C11.6744 7.43873 11.7825 7.5705 11.8464 7.72467C11.9103 7.87883 11.927 8.04848 11.8944 8.21214C11.8619 8.3758 11.7815 8.52614 11.6635 8.64413C11.5455 8.76213 11.3952 8.84248 11.2315 8.87504C11.0678 8.90759 10.8982 8.89089 10.744 8.82703C10.5899 8.76317 10.4581 8.65503 10.3654 8.51628C10.2727 8.37753 10.2232 8.21441 10.2232 8.04754H9.25098C9.25098 8.4067 9.35748 8.75779 9.55702 9.05642C9.56729 9.0718 9.57779 9.087 9.58851 9.10203L5.39759 12.0881C4.93487 12.1758 4.58496 12.5823 4.58496 13.0705V17.6816C4.58496 18.2339 5.03267 18.6816 5.58496 18.6816H16.3512C16.9035 18.6816 17.3512 18.2339 17.3512 17.6816V13.0705C17.3512 12.9852 17.3406 12.9024 17.3204 12.8233C17.3141 12.7016 17.2623 12.5833 17.1695 12.4956C17.0424 12.315 16.8571 12.1784 16.6408 12.1131L12.5 9.16279C12.673 8.94049 12.7926 8.6801 12.848 8.40181C12.918 8.04956 12.8821 7.68443 12.7446 7.35261C12.6072 7.0208 12.3744 6.73719 12.0758 6.53765C11.9145 6.42988 11.7379 6.34925 11.553 6.29788V5.65386ZM11.6655 9.76197L14.9056 12.0705H7.09764L10.3627 9.74421C10.3694 9.73943 10.3759 9.73452 10.3823 9.72947C10.7113 9.86342 11.0726 9.89792 11.4212 9.82858C11.5044 9.81202 11.586 9.78972 11.6655 9.76197ZM6.33496 13.6261H7.50163V17.1261H6.33496V13.6261ZM10.2238 13.6261H9.05718V17.1261H10.2238V13.6261ZM11.7794 13.6261H12.9461V17.1261H11.7794V13.6261ZM15.6683 13.6261H14.5016V17.1261H15.6683V13.6261Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2472_27376">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(4.00146 4.6814)"
          />
        </clipPath>
        <clipPath id="clip1_2472_27376">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(4.00146 4.6814)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
