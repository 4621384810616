import { Spin, message, Checkbox, Row, Col } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect, useState } from 'react';

import { Form, FormItem } from '../../../components/antd/Form';
import { Input } from '../../../components/antd/Input';
import { Select } from '../../../components/antd/Select';
import { useCreateCargoTypeMutation } from '../../../services/cargoApi';
import { useGetBerthsQuery } from '../../../services/dockingPlacesApi';
import { CargoType, ProductGroup } from '../../../types';
import { cargoNatureList } from '../../../utils/lists';
import { isNullOrUndefined } from '../../../utils/utils';
import {
  RegisterHeaderForm,
  RegisterHeaderFormProps,
} from '../registerHeaderForm';
import { FormItemsWrapper } from './cargoForm';

type CargoTypeFormProps = RegisterHeaderFormProps & {
  productGroups: Array<ProductGroup>;
  onSaveCargoType: (cargoType: CargoType) => void;
};

export function CargoTypeForm(props: CargoTypeFormProps) {
  const [cargoNatureSelected, setCargoNatureSelected] = useState(0);

  const [form] = Form.useForm();
  const [
    createCargoType,
    { isSuccess: isSuccessCreateCargoType, isError: isErrorCreateCargoType },
  ] = useCreateCargoTypeMutation();

  const {
    data: berthData,
    isLoading: isLoadingBerthData,
    isSuccess: isSuccessLoadingBerth,
  } = useGetBerthsQuery({
    shipType: '',
  });

  function getBerthList() {
    return (berthData?.results || []).reduce((filteredBerths, berth) => {
      if (berth.place_type === 'BERTH') {
        filteredBerths.push({
          label: berth.name,
          value: berth.id,
        });
      }
      return filteredBerths;
    }, []);
  }

  function onChangeDockingPlaces(dockingPlaces: CheckboxValueType[]) {
    form.setFieldsValue({ docking_places: dockingPlaces });
  }

  async function handleFormSubmit(values: any) {
    const cargoType: CargoType = {
      ...values,
    };
    const createdCargoType = await createCargoType(cargoType);
    if ('data' in createdCargoType) {
      props.onSaveCargoType(createdCargoType.data);
    }
  }

  useEffect(() => {
    if (isSuccessCreateCargoType) {
      message.success('Tipo de mercadoria criado');
      props.onClose(null);
    } else if (isErrorCreateCargoType) {
      message.error(
        'Ocorreu um erro ao salvar o tipo de mercadoria, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessCreateCargoType, isErrorCreateCargoType]);

  function onChangeCargoNature(e: any) {
    if (isNullOrUndefined(e)) {
      setCargoNatureSelected(0);
    } else {
      setCargoNatureSelected(e);
    }
    form.setFieldsValue({ product_group: null });
  }

  function onChangeProductGroup(e: any) {
    if (!isNullOrUndefined(e)) {
      const productGroupSelected = props.productGroups.find((p) => p.id === e);
      form.setFieldsValue({
        cargo_nature: productGroupSelected?.nature_of_cargo.id,
      });
      setCargoNatureSelected(productGroupSelected?.nature_of_cargo.id || 0);
    }

    form.setFieldsValue({ cargo_type: null });
  }

  return (
    <Form
      form={form}
      name="cargo_type"
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <RegisterHeaderForm onClose={props.onClose} formName="cargo_type">
        <div className="title">NOVO TIPO DE MERCADORIA</div>
      </RegisterHeaderForm>
      <FormItemsWrapper>
        <FormItem name="cargo_nature" label="Natureza da carga">
          <Select onChange={(e) => onChangeCargoNature(e)} allowClear>
            {cargoNatureList.map((currentCargoNature) => {
              return (
                <Select.Option
                  key={currentCargoNature.id}
                  value={currentCargoNature.id}
                >
                  {currentCargoNature.name}
                </Select.Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="Grupo de produto"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          name="product_group"
        >
          <Select
            notFoundContent="Selecione a natureza da carga"
            allowClear
            onChange={(e) => onChangeProductGroup(e)}
          >
            {props.productGroups
              .filter((productGroup) =>
                cargoNatureSelected === 0
                  ? productGroup
                  : productGroup.nature_of_cargo.id === cargoNatureSelected
              )
              .map((productGroup) => {
                return (
                  <Select.Option key={productGroup.id} value={productGroup.id}>
                    {productGroup.name}
                  </Select.Option>
                );
              })}
          </Select>
        </FormItem>
        <FormItem
          label="Nome do tipo de mercadoria"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
          name="name"
        >
          <Input />
        </FormItem>
        <Spin
          style={{ marginTop: '8px' }}
          spinning={isLoadingBerthData}
          tip="Carregando..."
        >
          <FormItem
            name="docking_places"
            label="Selecione os berços que aceitam o tipo de mercadoria"
          >
            <Checkbox.Group onChange={onChangeDockingPlaces}>
              <Row>
                {getBerthList().map((berth: any) => (
                  <Col style={{ marginBottom: '4px' }} span={6}>
                    <Checkbox value={berth.value}>{berth.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </FormItem>
        </Spin>
      </FormItemsWrapper>
    </Form>
  );
}
