import { TrackingLogType } from '../../../types';
import { addSuffix, createDateStringPtBr } from '../../../utils/utils';

export function formatTrackingLogToDescription(
  trackingLog: TrackingLogType,
  index: number
) {
  return {
    ...trackingLog,
    key: index,
    start_of_internal_navigation: createDateStringPtBr(
      trackingLog.start_of_internal_navigation
    ),
    departure_time_at_port_entrance: createDateStringPtBr(
      trackingLog.departure_time_at_port_entrance
    ),
    real_time_of_anchoring: createDateStringPtBr(
      trackingLog.real_time_of_anchoring
    ),
    real_time_of_unanchoring: createDateStringPtBr(
      trackingLog.real_time_of_unanchoring
    ),
    first_cable_tied_in: createDateStringPtBr(trackingLog.first_cable_tied_in),
    first_cable_untied_in: createDateStringPtBr(
      trackingLog.first_cable_untied_in
    ),
    last_cable_tied_in: createDateStringPtBr(trackingLog.last_cable_tied_in),
    last_cable_untied_in: createDateStringPtBr(
      trackingLog.last_cable_untied_in
    ),
    input_draught: addSuffix(trackingLog.input_draught, 'm'),
    arrival_draught: addSuffix(trackingLog.arrival_draught, 'm'),
  };
}
