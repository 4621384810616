import {
  GenericQueryParamsType,
  GenericResponse,
  NCMItemType,
  NcmItemToSave,
  NcmItemFilters,
  CeMerchantGroupbyNcmItemType,
  NcmItemGroupByConsigneeType,
  OperatorGroupbyManifestAndOperatorCompanyType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type NcmItemResponse = GenericResponse & {
  results: Array<NCMItemType> | [];
};

export type NcmItemGenericQueryParamsType = GenericQueryParamsType & {
  id_or_parent?: number;
  ce_merchant_code?: string;
  storage_terminal_name?: string;
  operator_id?: number;
  operator_company_id?: number;
  manifest_code?: string;
};

type CeMerchantGroupbyNcmItemResponse = GenericResponse & {
  results: CeMerchantGroupbyNcmItemType[];
};

const BASE_URL = '/maritime/ncm-item';

export const NcmItemApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getNcmItems: build.query<
      NcmItemResponse,
      NcmItemGenericQueryParamsType | void
    >({
      query: (params: NcmItemGenericQueryParamsType) => {
        return {
          url: BASE_URL,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
    }),
    getNcmItemById: build.query<NCMItemType, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    createOrUpdateNcmItems: build.mutation<NCMItemType[], NcmItemToSave[]>({
      query: (ncmItem) => ({
        url: BASE_URL,
        method: 'PUT',
        body: ncmItem,
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'NcmItem', code: '1' }]),
    }),
    patchNcmItem: build.mutation<
      NCMItemType,
      { filters?: NcmItemFilters[]; ncmItems: NcmItemToSave[] }
    >({
      query: ({ filters, ncmItems }) => ({
        url: BASE_URL,
        method: 'PATCH',
        body: { filters, ncm_items: ncmItems },
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'NcmItem', code: '1' }]),
    }),
    deleteNcmItem: build.mutation<void, number>({
      query: (ncmItemId) => ({
        url: `${BASE_URL}/${ncmItemId}`,
        method: 'DELETE',
      }),
    }),
    getMerchantsCeGroupbyNcmItems: build.query<
      CeMerchantGroupbyNcmItemResponse,
      {
        stopover_id: number;
        manifest_code?: string;
        consignee_name_or_ncm_code_or_ce_merchant?: string;
        ncm_item_assigned?: boolean | null;
        ncm_ids?: string;
        page?: number | undefined;
        page_size?: number | undefined;
        cargo_assigned?: boolean | '';
        operator_id?: number;
        operator_company_id?: number;
      }
    >({
      query: (params) => {
        return {
          url: '/maritime/ce-merchants/groupby-ncm-items',
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'NcmItem', code: '1' }],
    }),
    getOperatorsGroupbyManifestAndOperatorCompany: build.query<
      OperatorGroupbyManifestAndOperatorCompanyType[],
      {
        stopover_id?: number;
        manifest_code?: string;
        ncm_item_assigned?: boolean | null;
        cargo_assigned?: boolean | '';
      }
    >({
      query: (params) => {
        return {
          url: '/maritime/ncm-item/groupby-operator-manifest-operator-company',
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'NcmItem', code: '1' }],
    }),
    getNcmItemsGroupByOperator: build.query<
      NcmItemGroupByConsigneeType,
      number
    >({
      query: (manifestId) => {
        return {
          url: `/operational/operation-manifest/${manifestId}/group-by-operator`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetNcmItemsQuery,
  useGetNcmItemsQuery,
  useGetOperatorsGroupbyManifestAndOperatorCompanyQuery,
  useLazyGetNcmItemByIdQuery,
  useCreateOrUpdateNcmItemsMutation,
  usePatchNcmItemMutation,
  useDeleteNcmItemMutation,
  useGetMerchantsCeGroupbyNcmItemsQuery,
  useLazyGetMerchantsCeGroupbyNcmItemsQuery,
  useLazyGetNcmItemsGroupByOperatorQuery,
} = NcmItemApi;
