import styled from 'styled-components';

export const PopoverDockingContent = styled.div`
  width: 100%;
  & .title {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px;
  }
  & .name {
    font-weight: 300;
  }
  & .value {
    color: var(--neutral_medium);
    font-weight: bold;
  }
  & .code {
    font-family: 'Consolas';
    color: var(--neutral_medium);
    font-size: 12px;
    text-align: right;
    display: block;
  }
  & .dockingType,
  & .shipType {
    display: inline-block;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 10px;
    border-radius: 5px;
  }
  & .shipType {
    background: var(--marine_lighter);
    border: 1px solid var(--marine_light);
  }
  & .dockingType {
    background: var(--warning-lighter);
    border: 1px solid var(--warning-light);
    margin-left: 15px;
  }
  & .body {
    margin-top: 10px;
  }
  & .bottom {
    margin-top: 10px;
  }
`;

export const PopoverMaintenanceContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  .div-title {
    display: flex;
    justify-content: space-between;
  }
`;
