import {
  Descriptions,
  FormatItem,
  InnerCollapse,
  LightTable,
  SearchInput,
  Title,
} from '../../../components';
import { CountTag } from '../../../components/common/CountTag';
import {
  ParkingSpaceType,
  VehiclesAggregatedByBrandType,
} from '../../../typesWarehousing';
import { ResumeBackground, ResumeSection } from '../styles';
import { OverviewChassisTableColumns } from './overviewChassisTableColumns';
import { ParkingSpaceDetails } from './parkingSpaceDetails';

type OverviewDetailsProps = {
  direction: 'IMPORT' | 'EXPORT';
  storageCompanyData?: ParkingSpaceType;
  brandData?: VehiclesAggregatedByBrandType;
};

const { Item } = Descriptions;
const { Panel } = InnerCollapse;
export function OverviewDetails(props: OverviewDetailsProps) {
  const { direction, storageCompanyData, brandData } = props;

  return (
    <div>
      <ResumeBackground>
        <ResumeSection
          style={{
            width: '100%',
          }}
        >
          <Title>Modelos</Title>
          <Descriptions column={4}>
            {brandData &&
              brandData.vehicle__description &&
              Object.keys(brandData?.vehicle__description).map((brand) => {
                return (
                  <Item label={brand}>
                    <FormatItem>
                      {brandData?.vehicle__description[brand]} un
                    </FormatItem>
                  </Item>
                );
              })}
          </Descriptions>
        </ResumeSection>
      </ResumeBackground>
      <SearchInput
        placeholder={
          direction === 'IMPORT'
            ? 'Pesquisar Chassi/DI/CE/BL'
            : 'Pesquisar Chassi/DI/Chave de nota'
        }
        size="large"
      />
      <ParkingSpaceDetails
        direction={direction}
        storageCompanyData={storageCompanyData}
      />
    </div>
  );
}
