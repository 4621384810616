import { Collapse, Title } from '../../../../components';
import { OperationProductCeMercantDescriptionType } from '../../../../types';
import { MerchantCEListItem } from './merchantCEListItem';
import { CompanyCollapseTitle } from './styles';

const { Panel } = Collapse;

type MerchantCeSectionProps = {
  merchantsCe: OperationProductCeMercantDescriptionType;
  isContainers: boolean;
};

export function MerchantCeSection(props: MerchantCeSectionProps) {
  const { merchantsCe, isContainers } = props;

  function companyCollapseTitle(companyName: string, companyCode: string) {
    return (
      <CompanyCollapseTitle>
        {companyName}
        <div className="code">{companyCode}</div>
      </CompanyCollapseTitle>
    );
  }

  return (
    <Collapse expandIconPosition="end">
      {merchantsCe &&
        Object.keys(merchantsCe).map((merchant_ce_company: string, index) => {
          const company_id = parseInt(merchant_ce_company, 10);
          return (
            <Panel
              key={index}
              header={companyCollapseTitle(
                merchantsCe[company_id].company_name,
                merchantsCe[company_id].company_code
              )}
            >
              <Title style={{ marginBottom: '5px', fontSize: '14px' }}>
                CE MERCANTEs
              </Title>
              {merchantsCe[company_id].merchants_ce.map((merchantCE, index) => {
                return (
                  <MerchantCEListItem
                    key={index}
                    merchantCE={merchantCE}
                    index={index}
                    isContainers={isContainers}
                  />
                );
              })}
            </Panel>
          );
        })}
    </Collapse>
  );
}
