import { SearchOutlined } from '@ant-design/icons';

import { TOSInput } from './styles';

export function SearchInput({ ...props }) {
  return (
    <div style={{ width: props.width }}>
      <TOSInput
        {...props}
        prefix={
          <SearchOutlined style={{ color: '#09d4ab', fontSize: '20px' }} />
        }
      />
    </div>
  );
}
