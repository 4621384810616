import {
  GenericQueryParamsType,
  GenericResponse,
  OperationContainerToSave,
  OperationContainerType,
} from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export type OperationContainerResponse = GenericResponse & {
  results: Array<OperationContainerType> | [];
};

const BASE_URL = '/operational/operation-containers';

export const operationContainerApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getOperationContainers: build.query<
      OperationContainerResponse,
      (GenericQueryParamsType & { operation?: number }) | void
    >({
      query: (params: GenericQueryParamsType & { operation?: number }) => {
        return {
          url: BASE_URL,
          method: 'GET',
          params,
        };
      },
    }),
    getOperationContainer: build.query<OperationContainerType, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}`,
          method: 'GET',
        };
      },
    }),
    createOperationContainer: build.mutation<
      OperationContainerType,
      OperationContainerToSave
    >({
      query: (operationContainer) => {
        return {
          url: BASE_URL,
          method: 'POST',
          body: operationContainer,
        };
      },
    }),
    updateOperationContainer: build.mutation<
      OperationContainerType,
      OperationContainerToSave
    >({
      query: (operationContainer) => {
        return {
          url: `${BASE_URL}/${operationContainer.id}`,
          method: 'PUT',
          body: operationContainer,
        };
      },
      invalidatesTags: () => [
        { type: 'Stopover', code: '1' },
        { type: 'Stopover', code: '3' },
      ],
    }),
    deleteOperationContainer: build.mutation<void, number>({
      query: (operationContainerId) => {
        return {
          url: `${BASE_URL}/${operationContainerId}`,
          method: 'DELETE',
        };
      },
    }),
    operationContainersUpload: build.mutation<
      void,
      { files: File[]; operationId: number }
    >({
      query: (data) => {
        const { operationId } = data;
        const formData = new FormData();
        data.files.forEach((file) => formData.append('file[]', file));
        return {
          url: `/operational/operation-containers-upload/${operationId}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (_result, error, queryArg) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: queryArg.operationId },
          { type: 'OperationCrane' },
        ]),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetOperationContainerQuery,
  useLazyGetOperationContainersQuery,
  useCreateOperationContainerMutation,
  useUpdateOperationContainerMutation,
  useDeleteOperationContainerMutation,
  useOperationContainersUploadMutation,
} = operationContainerApi;
