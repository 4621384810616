import { FormInstance, Row } from 'antd';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  FormItemDatePicker,
  FormItemInputNumber,
  FormItemSelect,
  Collapse,
  Section,
  Title,
} from '../../../components';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { useGetPilotsQuery } from '../../../services/pilotageApi';
import { useGetUsersQuery } from '../../../services/usersApi';
import { useGetVesselsQuery } from '../../../services/vesselApi';
import {
  Vessel as VesselType,
  Docking as DockingType,
  Vessel,
} from '../../../types';
import { booleanList } from '../../../utils/lists';
import {
  createDateStringPtBr,
  disabledDateAfterToday,
  isNullOrUndefined,
} from '../../../utils/utils';
import { ManoeuvreStatusTag } from './ManoeuvreStatusTag';

const { Panel } = Collapse;

type FormItemsManoeuvresProps = {
  formDocking: FormInstance;
  manoeuvre: 'docking_manoeuvre' | 'undocking_manoeuvre';
  tugboatsData: VesselType[] | undefined;
  selectedDocking?: DockingType;
  openCollapsePanels: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  allowedBerth?: any[] | undefined;
};

export function FormItemsManoeuvre(props: FormItemsManoeuvresProps) {
  const {
    formDocking,
    manoeuvre,
    tugboatsData,
    selectedDocking,
    openCollapsePanels,
    onOpenPanel,
    allowedBerth,
  } = props;

  const [pilotageCompanyId, setPilotageCompanyId] = useState(
    selectedDocking?.docking_manoeuvre?.pilotage_company?.id || ''
  );

  const [isLatePilotOnBoard, setIsLatePilotOnBoard] = useState(false);
  const [isLatePilotLeaveOnBoard, setIsLatePilotLeaveOnBoard] = useState(false);

  const [companyPilotageName, setCompanyPilotageName] = useState('');
  const [companyTowingName, setCompanyTowingName] = useState('');
  const [companyMooringName, setCompanyMooringName] = useState('');

  const [practitionerName, setPractitionerName] = useState('');
  const [pilotName, setPilotName] = useState('');
  const [userOnDutyName, setUserOnDutyName] = useState('');
  const [towageCompanyId, setTowageCompanyId] = useState<number | undefined>();
  const [tugboats, setTugboats] = useState<Vessel[]>([]);
  const [pilotLeaveOnBoardDate, setPilotLeaveOnBoardDate] = useState('');

  const {
    data: companiesPilotageData,
    refetch: refetchCompaniesPilotageQuery,
    isLoading: isLoadingCompaniesPilotageData,
  } = useGetCompaniesQuery({
    page_size: 10,
    company_type: 'PILOTAGE',
    name_or_cnpj: companyPilotageName,
  });

  const {
    data: companiesTowingData,
    refetch: refetchCompaniesTowingQuery,
    isLoading: isLoadingCompaniesTowingData,
  } = useGetCompaniesQuery({
    page_size: 10,
    company_type: 'TOWING',
    name_or_cnpj: companyTowingName,
  });

  const {
    data: companiesMooringData,
    refetch: refetchCompaniesMooringQuery,
    isLoading: isLoadingCompaniesMooringData,
  } = useGetCompaniesQuery({
    page_size: 10,
    company_type: 'MOORING',
    name_or_cnpj: companyMooringName,
  });

  const { data: pilotData, isLoading: isLoadingPilotData } = useGetPilotsQuery({
    // company_id: pilotageCompanyId,
    name: pilotName,
    function: 'PILOT',
  });

  const { data: practitionerData, isLoading: isLoadingPractitionerData } =
    useGetPilotsQuery({
      // company_id: pilotageCompanyId,
      name: practitionerName,
      function: 'PRACTITIONER',
    });

  const { data: usersOnDutyData, isLoading: isLoadingUsersOnDutyData } =
    useGetUsersQuery({
      role: 'ON_DUTY',
      first_name_or_last_name: userOnDutyName,
    });

  const { data: vesselsData, isFetching: isFetchingVesselData } =
    useGetVesselsQuery({
      page_size: 100,
      ship_type: 'PORT_TUGBOAT',
    } as any);

  useEffect(() => {
    refetchCompaniesPilotageQuery();
    refetchCompaniesTowingQuery();
    refetchCompaniesMooringQuery();
  }, []);

  useEffect(() => {
    setIsLatePilotOnBoard(
      (isNullOrUndefined(selectedDocking?.docking_manoeuvre?.pilot_on_board) &&
        moment(selectedDocking?.pilot_expected_on_board) < moment()) ||
        (!isNullOrUndefined(
          selectedDocking?.docking_manoeuvre?.pilot_on_board
        ) &&
          moment(selectedDocking?.pilot_expected_on_board) <
            moment(selectedDocking?.docking_manoeuvre?.pilot_on_board))
    );
    setIsLatePilotLeaveOnBoard(
      (isNullOrUndefined(
        selectedDocking?.docking_manoeuvre?.pilot_leave_on_board
      ) &&
        moment(selectedDocking?.pilot_expected_leaving_on_board) < moment()) ||
        (!isNullOrUndefined(
          selectedDocking?.docking_manoeuvre?.pilot_leave_on_board
        ) &&
          moment(selectedDocking?.pilot_expected_leaving_on_board) <
            moment(selectedDocking?.docking_manoeuvre?.pilot_leave_on_board))
    );
    setTowageCompanyId(selectedDocking?.[manoeuvre]?.towage_company?.id);
  }, [selectedDocking]);

  useEffect(() => {
    function loadingPilotageCompanies() {
      if (!isLoadingCompaniesPilotageData) {
        const pilotageCompany = formDocking.getFieldValue([
          manoeuvre,
          'pilotage_company',
          'id',
        ]);
        if (!isNullOrUndefined(pilotageCompany)) {
          onSelectPilotageCompany(pilotageCompany, false);
        }
      }
    }

    loadingPilotageCompanies();
  }, [isLoadingCompaniesPilotageData]);

  useEffect(() => {
    if (vesselsData) {
      setTugboats(vesselsData.results);
    } else {
      setTugboats([]);
    }
  }, [vesselsData]);

  function panelHeaderTitle() {
    const manoeuvreStatus = selectedDocking?.[manoeuvre]?.status;

    const dockingManoeuvreUpdatedAt =
      selectedDocking?.docking_manoeuvre?.updated_at;

    return (
      <>
        <span style={{ paddingRight: '5px' }}>
          {manoeuvre === 'docking_manoeuvre'
            ? 'Manobra de atracação'
            : 'Manobra de desatracação'}
        </span>
        {manoeuvre === 'docking_manoeuvre' && manoeuvreStatus && (
          <ManoeuvreStatusTag manoeuvreStatus={manoeuvreStatus} />
        )}
        {!isNullOrUndefined(dockingManoeuvreUpdatedAt) && (
          <span
            className="header-description"
            style={{ float: 'right', fontWeight: 'normal', fontSize: '13px' }}
          >
            Atualizado em:{' '}
            {moment(dockingManoeuvreUpdatedAt).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </>
    );
  }

  function onChangePilotOnBoard(selectedDate: any) {
    setIsLatePilotOnBoard(true);
    if (selectedDate) {
      if (
        moment(selectedDocking?.pilot_expected_on_board) >
        moment(selectedDate).subtract(1, 'minute')
      ) {
        setIsLatePilotOnBoard(false);
      }
    }
  }

  function onChangePilotLeaveOnBoard(selectedDate: any) {
    setIsLatePilotLeaveOnBoard(true);
    if (
      selectedDate &&
      moment(selectedDocking?.pilot_expected_leaving_on_board) >
        moment(selectedDate).subtract(1, 'minute')
    ) {
      setIsLatePilotLeaveOnBoard(false);
    }
    setPilotLeaveOnBoardDate(selectedDate);
  }

  const getSubTitlePilotDate = (date: string, isLate: boolean) => {
    const expectedDate = createDateStringPtBr(date);

    return (
      <span
        style={{
          fontWeight: '400',
          fontSize: '12px',
          color: 'var(--neutral_medium)',
        }}
      >
        {expectedDate && `Previsão: ${expectedDate} `}
        {expectedDate && isLate && (
          <span style={{ color: 'var(--error-medium)' }}>(atrasado)</span>
        )}
      </span>
    );
  };

  function onSelectPilotageCompany(e: any, clearFields = true) {
    const oldPilotageCompanyId =
      selectedDocking?.docking_manoeuvre?.pilotage_company?.id;
    const companySelected = companiesPilotageData?.results.find(
      (company) => company.id === e
    );

    if (companySelected?.id) {
      setPilotageCompanyId(companySelected?.id);
      if (clearFields && companySelected?.id !== oldPilotageCompanyId) {
        formDocking.setFieldsValue({
          [manoeuvre]: {
            ...formDocking.getFieldValue(manoeuvre),
            practitioner: null,
            first_pilot: null,
            second_pilot: null,
          },
        });
      }
    }
  }

  function getMaxDraught() {
    const dockingPlaceSelected = allowedBerth?.find(
      (berth) => berth.tag === formDocking.getFieldValue('docking_place')?.tag
    );
    return dockingPlaceSelected?.max_draught;
  }

  function onSelectTowageCompany(companyId: number) {
    setTowageCompanyId(companyId);
  }

  function lastCableTiedInMax(selectedDate: any) {
    const MAX_TIME_WINDOW = 3;
    if (selectedDate && !isEmpty(pilotLeaveOnBoardDate)) {
      return (
        moment(selectedDate) >
        moment(pilotLeaveOnBoardDate).add(MAX_TIME_WINDOW, 'hour')
      );
    }
    return false;
  }

  return (
    <Section
      activeKey={openCollapsePanels}
      expandIconPosition="end"
      onChange={onOpenPanel}
    >
      <Panel forceRender header={panelHeaderTitle()} key={manoeuvre}>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={12}
            name={[manoeuvre, 'confirmed']}
            label="Manobra confirmada?"
            dataList={booleanList}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemInputNumber
            colSpan={8}
            label="Calado de proa"
            name={[manoeuvre, 'bow_draught']}
            addonAfter="m"
          />
          <FormItemInputNumber
            colSpan={8}
            label="Calado de popa"
            name={[manoeuvre, 'stern_draught']}
            addonAfter="m"
          />
          {manoeuvre === 'docking_manoeuvre' && (
            <FormItemInputNumber
              colSpan={8}
              label="Calado na entrada"
              name="input_draught"
              addonAfter="m"
              min={0}
              rules={[
                {
                  type: 'number',
                  max: getMaxDraught(),
                  message: `Valor deve ser no máximo ${getMaxDraught()}`,
                },
              ]}
              tooltip="Distância máxima da lâmina de água até a quilha do navio antes da atracação, necessária para garantir a passagem segura através de um canal de acesso, canais internos ou de aproximação, bacias de evolução e berços"
            />
          )}
          {manoeuvre === 'undocking_manoeuvre' && (
            <FormItemInputNumber
              colSpan={8}
              label="Calado na saída"
              name="output_draught"
              addonAfter="m"
              min={0}
              rules={[
                {
                  type: 'number',
                  max: getMaxDraught(),
                  message: `Valor deve ser no máximo ${getMaxDraught()}`,
                },
              ]}
              tooltip="Distância máxima da lâmina de água até a quilha do navio na desatracação, necessária para garantir a passagem segura através de um canal de acesso, canais internos ou de aproximação, bacias de evolução e berços"
            />
          )}
        </Row>
        <Row gutter={40} align="bottom">
          {manoeuvre === 'docking_manoeuvre' && (
            <FormItemInputNumber
              colSpan={12}
              label="DWT na entrada"
              name="input_dwt"
              addonAfter="ton"
            />
          )}
          {manoeuvre === 'undocking_manoeuvre' && (
            <FormItemInputNumber
              colSpan={12}
              label="DWT na saída"
              name="output_dwt"
              addonAfter="ton"
            />
          )}
          {manoeuvre === 'docking_manoeuvre' && (
            <FormItemInputNumber
              colSpan={12}
              label="Deslocamento para entrada"
              name="entrance_displacement"
              addonAfter="ton"
              tooltip="Volume imerso X Densidade da água"
            />
          )}
          {manoeuvre === 'undocking_manoeuvre' && (
            <FormItemInputNumber
              colSpan={12}
              label="Deslocamento para saída"
              name="exit_displacement"
              addonAfter="ton"
              tooltip="Volume imerso X Densidade da água"
            />
          )}
        </Row>
        <Row gutter={40} align="top">
          <FormItemSelect
            colSpan={12}
            name={[manoeuvre, 'supervised']}
            label="Manobra acompanhada?"
            dataList={booleanList}
          />
          <FormItemSelect
            colSpan={12}
            name={[manoeuvre, 'on_duty_that_accompanied', 'id']}
            label="Plantonista que acompanhou"
            allowClear
            notFoundContent="Não existem plantonistas cadastrados"
            dataList={usersOnDutyData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            showSearch
            onSearch={debounce((e: string) => setUserOnDutyName(e), 400)}
            isLoading={isLoadingUsersOnDutyData}
          />
        </Row>
        <Row>
          <Title>PRATICAGEM</Title>
        </Row>
        <Row gutter={40} align="top">
          <FormItemSelect
            label="Empresa de praticagem"
            name={[manoeuvre, 'pilotage_company', 'id']}
            colSpan={8}
            allowClear
            showSearch
            onSearch={debounce((e: string) => setCompanyPilotageName(e), 400)}
            dataList={companiesPilotageData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingCompaniesPilotageData}
            onSelect={onSelectPilotageCompany}
          />
          <FormItemDatePicker
            colSpan={8}
            label="Prático a bordo em"
            tooltip={
              manoeuvre === 'undocking_manoeuvre'
                ? 'Início da manobra de desatracação'
                : null
            }
            name={[manoeuvre, 'pilot_on_board']}
            help={
              manoeuvre === 'docking_manoeuvre' &&
              getSubTitlePilotDate(
                formDocking.getFieldValue('pilot_expected_on_board'),
                isLatePilotOnBoard
              )
            }
            disabledDate={disabledDateAfterToday}
            onChange={onChangePilotOnBoard}
            allowClear={false}
          />
          <FormItemDatePicker
            colSpan={8}
            label="Saída do prático a bordo em"
            tooltip={
              manoeuvre === 'undocking_manoeuvre'
                ? 'Fim da manobra de desatracação'
                : null
            }
            name={[manoeuvre, 'pilot_leave_on_board']}
            help={
              manoeuvre === 'docking_manoeuvre' &&
              getSubTitlePilotDate(
                formDocking.getFieldValue('pilot_expected_leaving_on_board'),
                isLatePilotLeaveOnBoard
              )
            }
            disabledDate={disabledDateAfterToday}
            onChange={onChangePilotLeaveOnBoard}
            allowClear={false}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'practitioner', 'id']}
            label="Praticante"
            allowClear
            notFoundContent="Não existem praticantes cadastrados"
            dataList={practitionerData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            showSearch
            onSearch={debounce((e: string) => setPractitionerName(e), 400)}
            isLoading={isLoadingPractitionerData}
          />
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'first_pilot', 'id']}
            label="Prático 1"
            allowClear
            notFoundContent="Não existem práticos"
            dataList={pilotData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            showSearch
            onSearch={debounce((e: string) => setPilotName(e), 400)}
            isLoading={isLoadingCompaniesPilotageData || isLoadingPilotData}
            onClick={() => setPilotName('')}
          />
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'second_pilot', 'id']}
            label="Prático 2"
            allowClear
            notFoundContent="Não existem práticos"
            dataList={pilotData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            showSearch
            onSearch={debounce((e: string) => setPilotName(e), 400)}
            isLoading={isLoadingCompaniesPilotageData || isLoadingPilotData}
            onClick={() => setPilotName('')}
          />
        </Row>
        <Row gutter={40} align="bottom">
          {manoeuvre === 'docking_manoeuvre' && (
            <>
              <FormItemSelect
                colSpan={8}
                name={[manoeuvre, 'did_internal_navigation']}
                label="Fez navegação interna?"
                dataList={booleanList}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Início da navegação interna"
                tooltip="É quando adentra no canal de navegação"
                name={['start_of_internal_navigation']}
                disabledDate={disabledDateAfterToday}
              />
            </>
          )}
          {manoeuvre === 'undocking_manoeuvre' && (
            <>
              <FormItemSelect
                colSpan={8}
                name={[manoeuvre, 'did_departure_at_port_entrance']}
                label="Navio saiu na barra?"
                dataList={booleanList}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Horário de saída na barra"
                name={['departure_time_at_port_entrance']}
                disabledDate={disabledDateAfterToday}
              />
            </>
          )}
        </Row>
        <Row>
          <Title>REBOCAGEM</Title>
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            label="Empresa de rebocagem"
            name={[manoeuvre, 'towage_company', 'id']}
            colSpan={8}
            allowClear
            showSearch
            onSearch={debounce((e: string) => setCompanyTowingName(e), 400)}
            onSelect={onSelectTowageCompany}
            dataList={companiesTowingData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingCompaniesTowingData}
          />
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'stern_tugboat', 'id']}
            label="Rebocador de popa"
            allowClear
            showSearch
            notFoundContent={
              towageCompanyId
                ? 'Não existem rebocadores cadastrados'
                : 'Selecione a empresa de rebocagem'
            }
            dataList={tugboats
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'bow_tugboat', 'id']}
            label="Rebocador de proa"
            allowClear
            showSearch
            notFoundContent={
              towageCompanyId
                ? 'Não existem rebocadores cadastrados'
                : 'Selecione a empresa de rebocagem'
            }
            dataList={tugboats
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'standby_tugboat', 'id']}
            label="Rebocador de standby"
            allowClear
            showSearch
            notFoundContent={
              towageCompanyId
                ? 'Não existem rebocadores cadastrados'
                : 'Selecione a empresa de rebocagem'
            }
            dataList={tugboats
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
          <FormItemSelect
            colSpan={8}
            name={[manoeuvre, 'amidship_tugboat', 'id']}
            label="Rebocador de meia-nau"
            allowClear
            showSearch
            notFoundContent={
              towageCompanyId
                ? 'Não existem rebocadores cadastrados'
                : 'Selecione a empresa de rebocagem'
            }
            dataList={tugboats
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        </Row>
        <Row>
          {manoeuvre === 'docking_manoeuvre' && <Title>AMARRAÇÃO</Title>}
          {manoeuvre === 'undocking_manoeuvre' && <Title>DESAMARRAÇÃO</Title>}
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            label="Empresa"
            name={[manoeuvre, 'mooring_company', 'id']}
            colSpan={8}
            allowClear
            showSearch
            onSearch={debounce((e: string) => setCompanyMooringName(e), 400)}
            dataList={companiesMooringData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingCompaniesMooringData}
          />
          {manoeuvre === 'docking_manoeuvre' && (
            <>
              <FormItemDatePicker
                colSpan={8}
                label="Primeiro cabo amarrado em"
                tooltip="Início da atracação"
                name="first_cable_tied_in"
                allowClear={false}
                disabledDate={disabledDateAfterToday}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Último cabo amarrado em"
                tooltip="Fim da manobra de atracação"
                name="last_cable_tied_in"
                allowClear={false}
                disabledDate={disabledDateAfterToday}
                // disabledDate={lastCableTiedInMax}
              />
            </>
          )}
          {manoeuvre === 'undocking_manoeuvre' && (
            <>
              <FormItemDatePicker
                colSpan={8}
                label="Primeiro cabo desamarrado em"
                name="first_cable_untied_in"
                tooltip="Início da desatracação"
                allowClear={false}
                disabledDate={disabledDateAfterToday}
              />
              <FormItemDatePicker
                colSpan={8}
                label="Último cabo desamarrado em"
                name="last_cable_untied_in"
                tooltip="Fim da desatracação"
                allowClear={false}
                disabledDate={disabledDateAfterToday}
              />
            </>
          )}
        </Row>
      </Panel>
    </Section>
  );
}
