import { BollardType, FenderType } from '../../../types';

export function formatEquipments(
  fenders: FenderType[] | [],
  bollards: BollardType[] | []
): string[] {
  return (
    fenders
      .flatMap((fender) => [fender.tag])
      .concat(bollards.flatMap((bollard) => [bollard.tag])) || []
  );
}
