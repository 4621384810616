import { Row, Space, message } from 'antd';
import moment, { Moment } from 'moment';

import { Button, Form, FormItemDatePicker } from '../../components';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type EndOperationModalProps = BaseActionModalProps & {
  onEndOperation: () => void;
};

export function EndOperationModal(props: EndOperationModalProps) {
  const {
    setIsVisible,
    onEndOperation,
    updateDocking,
    currentDocking,
    isLoadingUpdateDocking,
  } = props;
  const [form] = Form.useForm();

  function validate(operationRealStart: string, operationRealFinish: Moment) {
    const operationRealStartMoment = moment(operationRealStart);
    if (operationRealFinish <= operationRealStartMoment) {
      message.error(
        `A data do final da operação não pode ser menor que a data de início da operação(${createDateStringPtBr(
          operationRealStartMoment
        )})`
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (updateDocking) {
        if (
          !validate(
            currentDocking?.operation_summary?.real_start || '',
            values.operation_summary.real_finish
          )
        )
          return;
        updateDocking(
          {
            operation_summary: {
              real_finish: createDateString(
                values.operation_summary.real_finish
              ),
            },
            real_operation_finish_date_filled: createDateString(moment()),
            id: currentDocking?.id,
          },
          onEndOperation
        );
      }
    });
  }
  return (
    <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
      <Row gutter={32}>
        <FormItemDatePicker
          name={['operation_summary', 'real_finish']}
          label="Hora de fim da operação:"
          disabledDate={disabledDateAfterToday}
          colSpan={12}
          tooltip=""
          rules={[
            {
              required: true,
              message: 'Por favor, preencha a data e hora do fim da operação',
            },
          ]}
        />
      </Row>
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingUpdateDocking}
        >
          Finalizar operação
        </Button>
      </Space>
    </Form>
  );
}
