import { CSSProperties, useEffect, useState } from 'react';

import { isNullOrUndefined } from '../../utils/utils';
import { StatusTag } from './StatusTag';

type DockingReleaseStatusProps = {
  dockingRelease?: 'PENDING' | 'RELEASED_WITH_PENDING' | 'RELEASED';
  stopoverStatus?: string;
  style?: CSSProperties;
};

export function DockingReleaseStatus(props: DockingReleaseStatusProps) {
  const { dockingRelease, stopoverStatus, style } = props;

  const [label, setLabel] = useState('');
  const [className, setClassName] = useState('');

  useEffect(() => {
    if (!isNullOrUndefined(stopoverStatus) && stopoverStatus === 'CANCELED') {
      setClassName('red');
      setLabel('cancelada');
      return;
    }
    switch (dockingRelease) {
      case 'PENDING':
        setLabel('liberação pendente');
        setClassName('orange');
        break;
      case 'RELEASED_WITH_PENDING':
        setLabel('liberado com pendências');
        setClassName('red');
        break;
      case 'RELEASED':
        setLabel('liberado');
        setClassName('green');
        break;
      default:
        break;
    }
  }, [stopoverStatus, dockingRelease]);

  return (
    <StatusTag style={style}>
      <span className={className}>{label}</span>
    </StatusTag>
  );
}
