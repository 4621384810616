import { useEffect, useState } from 'react';

import { StatusTag } from '../../../components';
import { manoeuvreStatusList } from '../../../utils/lists';

type ManoeuvreStatusTagProps = {
  manoeuvreStatus: 'SCHEDULED' | 'IN_PROGRESS' | 'OVERDUE' | 'CONFIRMED';
};

export function ManoeuvreStatusTag(props: ManoeuvreStatusTagProps) {
  const { manoeuvreStatus } = props;

  const [label, setLabel] = useState('');
  const [className, setClassName] = useState('');

  useEffect(() => {
    const manoeuvreStatusSelected = manoeuvreStatusList.find(
      (vessel) => vessel.status === manoeuvreStatus
    );
    if (manoeuvreStatusSelected) {
      setLabel(manoeuvreStatusSelected?.label);
      setClassName(manoeuvreStatusSelected?.className);
    }
  }, [manoeuvreStatus]);

  return (
    <StatusTag>
      <span className={className}>{label}</span>
    </StatusTag>
  );
}
