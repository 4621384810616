import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import FormList from 'antd/lib/form/FormList';

import { Button } from '../../index';
import { TableFormListStyle } from './styles';

type FormListItemRowProps = {
  index: number;
  onRemove?: (index: number | number[]) => void;
  onAdd?: (defaultValue?: any, insertIndex?: number | undefined) => void;
  itemRowProps?: any; // any pois pode ser qualquer propriedade passada no componente
  format?: boolean;
};

type TableFormListProps = {
  title?: string;
  subTitle?: string;
  columns?: TableFormListColumns[];
  formListName: string | string[] | Array<string | number>;
  FormListItemRow: React.FC<FormListItemRowProps>;
  style?: React.CSSProperties;
  initialValue?: any[];
  itemToAdd?: unknown;
  addButtonText?: string;
  addButtonType?: 'text' | 'link' | 'default' | 'dashed' | 'ghost' | 'primary';
  addButtonStyle?: React.CSSProperties;
  itemRowProps?: any;
  disabledAddButton?: boolean;
};

export type TableFormListColumns = {
  title?: string;
  colSpan?: number;
  style?: React.CSSProperties;
};
export function TableFormList(props: TableFormListProps) {
  const {
    title,
    subTitle,
    columns,
    formListName,
    FormListItemRow,
    style,
    initialValue,
    itemToAdd,
    addButtonText,
    addButtonType,
    addButtonStyle,
    itemRowProps,
    disabledAddButton,
  } = props;
  return (
    <TableFormListStyle style={style}>
      {title && <h1>{title}</h1>}
      {subTitle && <h2>{subTitle}</h2>}
      <FormList name={formListName} initialValue={initialValue}>
        {(formListTableItems, { add: addItem, remove }) => {
          return (
            <>
              {itemToAdd && (
                <Button
                  type={addButtonType || 'default'}
                  icon={<PlusOutlined />}
                  onClick={() => addItem(itemToAdd)}
                  style={addButtonStyle || { margin: '20px 0' }}
                  disabled={disabledAddButton}
                >
                  {addButtonText}
                </Button>
              )}
              <div className="table">
                <Row gutter={16} className="table-head">
                  {columns?.map((col, index) => (
                    <Col key={index} span={col.colSpan} style={col.style}>
                      {col.title}
                    </Col>
                  ))}
                </Row>
                {formListTableItems.map((_field, index) => (
                  <FormListItemRow
                    index={index}
                    key={`${index}`}
                    onRemove={remove}
                    onAdd={addItem}
                    itemRowProps={itemRowProps}
                  />
                ))}
              </div>
            </>
          );
        }}
      </FormList>
    </TableFormListStyle>
  );
}
