import { Row, message } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  Collapse,
  Form,
  FormItemInput,
  FormItemInputNumber,
  FormItemSelect,
  FormItemTreeSelect,
  GenericDrawerHeader,
} from '../../../components';
import { useCollapsePanels } from '../../../hooks';
import { useGetCargoTypeQuery } from '../../../services/cargoApi';
import {
  useCreateBerthMutation,
  useUpdateBerthMutation,
} from '../../../services/dockingPlacesApi';
import { useCreateLineMutation } from '../../../services/linesApi';
import { useCreateMaintenanceMutation } from '../../../services/maintenancesApi';
import { useGetOperatorsQuery } from '../../../services/stopoverApi';
import {
  BerthMaintenance,
  DockingPlace,
  DockingPlaceForm,
  Line,
} from '../../../types';
import { booleanList, portAreaList, shipTypesList } from '../../../utils/lists';
import { formatLineToSave } from './berthLines/formatters';
import { FormItemsBerthLines } from './berthLines/formItemsBerthLines/formItemsBerthLines';
import { formatBerthMaintenanceToSave } from './berthMaintenances/formatters';
import { FormItemsBerthMaintenances } from './berthMaintenances/formItemsBerthMaintenances/formItemsBerthMaintenances';
import {
  formatBerthToSave,
  formatBerthToForm,
  formatCargoTypesGroupByCargoNature,
} from './formatters';

const { Panel } = Collapse;

type BerthFormProps = {
  onClose: () => void;
  selectedBerth: DockingPlace;
  isEdit: boolean;
};

const collapsePanels = [
  'general_data',
  'dimensions',
  'berth_maintenances',
  'operational_restrictions',
  'portuary_infrastructure',
  'operational_infrastructure',
];

export function BerthForm(props: BerthFormProps) {
  const { onClose, selectedBerth, isEdit } = props;

  const [form] = Form.useForm<DockingPlaceForm>();

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const { data: cargoTypeData, isLoading: isLoadingCargoTypeData } =
    useGetCargoTypeQuery();
  const { data: operatorsData, isLoading: isLoadingOperatorsData } =
    useGetOperatorsQuery();
  const [
    updateBerth,
    { isSuccess: isSuccessUpdateBerth, isError: isErrorUpdateBerth },
  ] = useUpdateBerthMutation();
  const [
    createBerth,
    { isSuccess: isSuccessCreateBerth, isError: isErrorCreateBerth },
  ] = useCreateBerthMutation();
  const [
    createLine,
    { isLoading: isLoadingCreateLine, isSuccess: isSuccessCreateLine },
  ] = useCreateLineMutation();
  const [
    createMaintenance,
    {
      isSuccess: isSuccessCreateMaintenance,
      isError: isErrorCreateMaintenance,
    },
  ] = useCreateMaintenanceMutation();

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if (!isEmpty(selectedBerth) && isEdit) {
      form.setFieldsValue(formatBerthToForm({ ...selectedBerth }));
    }
  }, [selectedBerth]);

  useEffect(() => {
    if (isSuccessCreateBerth) {
      message.success('Berço criado com sucesso!');
      onClose();
    }

    if (isSuccessUpdateBerth) {
      message.success('Berço atualizado com sucesso!');
      onClose();
    }
  }, [isSuccessCreateBerth, isSuccessUpdateBerth]);

  async function handleFormSubmit(values: any) {
    // edicao de berco
    if (isEdit && !isEmpty(selectedBerth)) {
      updateBerth(formatBerthToSave({ ...values, tag: selectedBerth.tag }));

      // criacao de berco
    } else {
      createBerth(formatBerthToSave({ ...values })).then(async (response) => {
        if ('data' in response) {
          const newBerth = response.data;

          if (!isEmpty(values.berth_lines)) {
            await saveLines(values.berth_lines, newBerth.tag);
          }

          if (!isEmpty(values.berth_maintenances)) {
            await saveMaintenances(values.berth_maintenances, newBerth.tag);
          }
        }
      });
    }
  }

  async function saveLines(berthLines: Line[], berthTag: string) {
    berthLines.forEach((line: Line) => {
      createLine(formatLineToSave({ ...line, berth: berthTag }));
    });
  }

  async function saveMaintenances(
    berthMaintenances: BerthMaintenance[],
    berthTag: string
  ) {
    berthMaintenances.forEach((maintenance: BerthMaintenance) => {
      createMaintenance(
        formatBerthMaintenanceToSave({
          ...maintenance,
          responsible: user,
          berths: [...(maintenance.berths || []), berthTag] as string[],
        })
      );
    });
  }

  function calculateMaxDraught() {
    const depth = form.getFieldValue('depth') || 0;
    const keelGap = form.getFieldValue('keel_gap') || 0;
    form.setFieldsValue({ max_draught: +(depth - keelGap).toFixed(2) });
  }

  return (
    <Form
      form={form}
      name="berth"
      layout="vertical"
      onFinish={handleFormSubmit}
    >
      <GenericDrawerHeader
        title={
          <span style={{ fontWeight: '700', fontSize: '16px' }}>
            {isEdit ? 'EDITAR BERÇO' : 'NOVO BERÇO'}
          </span>
        }
        onBack={onClose}
        isLoadingSave={isLoading}
        formName="berth"
        showBackButton
      />
      <Collapse
        activeKey={openCollapsePanels}
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        <Panel key="general_data" header="Informações Gerais">
          <Row gutter={24}>
            <FormItemInput
              label="Nome do berço"
              name={['name']}
              colSpan={12}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Campo obrigatório',
                },
              ]}
            />
            <FormItemSelect
              label="Área do porto"
              name={['port_area']}
              colSpan={12}
              dataList={portAreaList}
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            />
          </Row>
          <Row gutter={24}>
            <FormItemInput
              label="Berço na ANTAQ"
              name={['antaq_name']}
              colSpan={12}
            />
            <FormItemInput
              label="Código ANTAQ"
              name={['antaq_code']}
              colSpan={12}
            />
          </Row>
        </Panel>
        <Panel key="dimensions" header="Dimensões">
          <Row gutter={24}>
            <FormItemInputNumber
              label="Profundidade"
              name={['depth']}
              colSpan={8}
              addonAfter="metros"
              onChange={calculateMaxDraught}
            />
            <FormItemInputNumber
              label="Calado CMA"
              name={['max_draught']}
              colSpan={8}
              addonAfter="metros"
              disabled
              tooltip="Calculado automaticamente a partir da profundidade e folga da quilha"
              rules={[
                {
                  validator: async (_: any, value: number) =>
                    value >= 0
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'A folga da quilha não deve ser maior que a profundidade'
                          )
                        ),
                },
              ]}
            />
            <FormItemInputNumber
              label="Capacidade DWT"
              name={['dwt']}
              colSpan={8}
              addonAfter="toneladas"
            />
          </Row>
          <Row gutter={24}>
            <FormItemInputNumber
              label="Comprimento"
              name={['length']}
              colSpan={8}
              addonAfter="metros"
            />
            <FormItemInputNumber
              label="LOA"
              name={['loa']}
              colSpan={8}
              addonAfter="metros"
            />
            <FormItemInputNumber
              label="Folga da quilha"
              name={['keel_gap']}
              colSpan={8}
              addonAfter="metros"
              onChange={calculateMaxDraught}
            />
          </Row>
        </Panel>
        <Panel key="berth_maintenances" header="Paradas de manutenção">
          <FormItemsBerthMaintenances
            isEdit={isEdit}
            selectedBerth={selectedBerth}
            form={form}
          />
        </Panel>
        {/* Seção bloqueada por enquanto por questões de pendências do backend que serão
        feitas posteriormente */}
        <Panel key="operational_restrictions" header="Restrições operacionais">
          <Row>
            <FormItemSelect
              label="Ship-to-ship"
              name={['sts_operations']}
              dataList={booleanList}
            />
          </Row>
          <Row gutter={24}>
            <FormItemSelect
              tagTheme="neutral"
              label="Operadores"
              name={['operators']}
              mode="multiple"
              dataList={
                operatorsData?.results
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name)) || []
              }
              maxSelectOptions={100}
              allowClear
            />
          </Row>
          <Row gutter={24}>
            <FormItemTreeSelect
              tagTheme="neutral"
              name={['cargo_type']}
              label="Produtos"
              treeData={formatCargoTypesGroupByCargoNature(
                cargoTypeData?.results || []
              )}
              showCheckedStrategy="SHOW_CHILD"
            />
          </Row>
          <Row gutter={24}>
            <FormItemSelect
              tagTheme="neutral"
              label="Tipos de embarcações"
              name={['ship_types']}
              mode="multiple"
              maxSelectOptions={shipTypesList.length}
              dataList={shipTypesList
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))}
              allowClear
            />
          </Row>
        </Panel>
        <Panel
          key="operational_infrastructure"
          header="Infraestrutura operacional"
        >
          <FormItemsBerthLines
            selectedBerth={selectedBerth}
            form={form}
            isEdit={isEdit}
          />
        </Panel>
      </Collapse>
    </Form>
  );
}
