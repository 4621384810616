import { Dispatch, SetStateAction, useState } from 'react';

import {
  ContainerTypeDescription,
  Stopover as StopoverType,
} from '../../../../../../../../types';
import { ContainerDrawer } from './containerDrawer/ContainerDrawer';
import { ContainersByCraneDescription } from './containersByCraneDescription';

type ContainersByCraneDataProps = {
  containers: ContainerTypeDescription[];
  operationCraneId?: number;
  craneId?: string;
  selectedStopover: StopoverType;
  setSelectedStopover?: Dispatch<SetStateAction<StopoverType>>;
  pageContext?: 'OPERATOR_AREA';
};

export function ContainersByCraneData(props: ContainersByCraneDataProps) {
  const {
    containers,
    operationCraneId,
    selectedStopover,
    craneId,
    setSelectedStopover,
    pageContext,
  } = props;

  const [isContainerDrawerVisible, setIsContainerDrawerVisible] =
    useState(false);
  const [selectedContainer, setSelectedContainer] = useState(
    {} as ContainerTypeDescription
  );
  const [selectedContainers, setSelectedContainers] =
    useState<ContainerTypeDescription[]>(containers);

  function onBack() {
    setIsContainerDrawerVisible(false);
  }

  return (
    <>
      <ContainersByCraneDescription
        containers={selectedContainers}
        setIsContainerDrawerVisible={setIsContainerDrawerVisible}
        isContainerDrawerVisible={isContainerDrawerVisible}
        setSelectedContainerForDrawer={setSelectedContainer}
        pageContext={pageContext}
        operationId={selectedStopover.operation?.id}
        craneId={craneId}
      />
      <ContainerDrawer
        isVisible={isContainerDrawerVisible}
        setIsVisibleForm={setIsContainerDrawerVisible}
        selectedContainer={selectedContainer}
        setSelectedContainer={setSelectedContainer}
        onBack={onBack}
        operationCraneId={operationCraneId}
        selectedStopover={selectedStopover}
        selectedContainers={selectedContainers}
        setSelectedContainers={setSelectedContainers}
        craneId={craneId}
        setSelectedStopover={setSelectedStopover}
      />
    </>
  );
}
