import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

type TagType = {
  type:
    | 'Stopover'
    | 'StopoverLog'
    | 'Vessel'
    | 'Company'
    | 'Manoeuvre'
    | 'Docking'
    | 'BreakdownOccurrence'
    | 'NcmItem'
    | 'OperationCalculus'
    | 'Compartments'
    | 'OperationCrane'
    | 'Charge'
    | 'User'
    | 'WarehousingInvoices'
    | 'Pumping'
    | 'VesselRequestUpdate'
    | 'OperationLine'
    | 'Line'
    | 'DockingPlace'
    | 'WarehousingImporting';
  code?: string | number;
};

// função criada para não invalidar tags caso dê erro na request
export function getInvalidatesTags(
  error: FetchBaseQueryError | undefined,
  tags: TagType[]
): TagType[] | [] {
  return error === undefined ? tags : [];
}
