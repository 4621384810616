import { Row } from 'antd';
import { ReactNode } from 'react';

import { FormItemSelect, Collapse, Section, Title } from '../../../components';
import { useGetBollardsQuery } from '../../../services/bollardApi';
import { useGetFendersQuery } from '../../../services/fenderApi';

const { Panel } = Collapse;

type FormItemsBollardFenderProps = {
  panelHeaderTitle: (title: string) => ReactNode;
  openCollapsePanels: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
};

export function FormItemsBollardFender(props: FormItemsBollardFenderProps) {
  const { panelHeaderTitle, openCollapsePanels, onOpenPanel } = props;

  const { data: fendersData, isLoading: isLoadingFendersData } =
    useGetFendersQuery();
  const { data: bollardsData, isLoading: isLoadingBollardsData } =
    useGetBollardsQuery({ dockingPlace: '' });

  return (
    <Section
      activeKey={openCollapsePanels}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel
        forceRender
        header={panelHeaderTitle('Cabeços e defensas')}
        key="bollards_and_fenders"
      >
        <Row>
          <Title>PROA</Title>
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            label="Cabeço lançante de proa"
            name={['bollard_bow_launcher', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem cabeços cadastrados"
            dataList={bollardsData?.results}
            isLoading={isLoadingBollardsData}
          />
          <FormItemSelect
            colSpan={8}
            label="Cabeço spring de proa"
            name={['bollard_bow_spring', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem cabeços cadastrados"
            dataList={bollardsData?.results}
            isLoading={isLoadingBollardsData}
          />
          <FormItemSelect
            colSpan={8}
            label="Cabeço través de proa"
            name={['bollard_bow_across', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem cabeços cadastrados"
            dataList={bollardsData?.results}
            isLoading={isLoadingBollardsData}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            label="Primeira defensa de apoio proa"
            name={['first_bow_support_fender', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem defensas cadastradas"
            dataList={fendersData?.results}
            isLoading={isLoadingFendersData}
          />
        </Row>
        <Row>
          <Title>POPA</Title>
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            label="Cabeço lançante de popa"
            name={['bollard_stern_launcher', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem cabeços cadastrados"
            dataList={bollardsData?.results}
            isLoading={isLoadingBollardsData}
          />
          <FormItemSelect
            colSpan={8}
            label="Cabeço spring de popa"
            name={['bollard_stern_spring', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem cabeços cadastrados"
            dataList={bollardsData?.results}
            isLoading={isLoadingBollardsData}
          />
          <FormItemSelect
            colSpan={8}
            label="Cabeço través de popa"
            name={['bollard_stern_across', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem cabeços cadastrados"
            dataList={bollardsData?.results}
            isLoading={isLoadingBollardsData}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            label="Última defensa de apoio popa"
            name={['last_stern_support_fender', 'tag']}
            allowClear
            showSearch
            notFoundContent="Não existem defensas cadastradas"
            dataList={fendersData?.results}
            isLoading={isLoadingFendersData}
          />
        </Row>
      </Panel>
    </Section>
  );
}
