import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

export const Container = styled(Layout)`
  display: flex;
  flex-direction: row;
  height: 99.9vh;

  & .ant-layout {
    background-color: #fff;
  }
`;

export const LeftContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-left: 70px;
  padding-right: 60px;
  padding-top: 59px;
  padding-bottom: 60px;
  max-width: 800px;
  height: 100%;

  @media (max-width: 1000px) {
    padding-right: 60px;
    padding-left: 70px;
  }
`;
