import { FenderType, GenericResponse } from '../types';
import { getHeaders, tosApi } from './tosApi';

export type FenderResponse = GenericResponse & {
  results: Array<FenderType> | [];
};

export const fenderApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getFenders: build.query<FenderResponse, void>({
      query: () => {
        return {
          url: `/maritime/fenders`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      transformResponse: (response: FenderResponse) => {
        response.results.forEach((fender) => {
          fender.id = fender.tag;
        });
        return response;
      },
    }),
    getFender: build.query<FenderType, { tag: string }>({
      query: (arg) => {
        const { tag } = arg;
        return {
          url: `/maritime/fenders/${tag}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetFendersQuery, useGetFenderQuery } = fenderApi;
