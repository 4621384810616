import { useMemo } from 'react';

import { Collapse, Descriptions, FormatItem } from '../../components';
import { Stopover as StopoverType } from '../../types';
import { returnDiffDaysInHours } from '../../utils/utils';

const { Panel } = Collapse;
const { Item } = Descriptions;
type GeneralDataProps = {
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  selectedStopover: StopoverType;
};

export function GeneralData(props: GeneralDataProps) {
  const { activeKey, onOpenPanel, selectedStopover } = props;

  const dockingDuration = useMemo(() => {
    if (
      !selectedStopover.charge.docking?.first_cable_tied_in ||
      !selectedStopover.charge.docking?.last_cable_untied_in
    ) {
      return 'Atracação não finalizada';
    }
    return returnDiffDaysInHours(
      selectedStopover.charge.docking?.first_cable_tied_in || '',
      selectedStopover.charge.docking?.last_cable_untied_in || ''
    );
  }, [selectedStopover]);

  return (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel forceRender key="generalData" header="Dados gerais">
        {selectedStopover.charge.charge_type === 'DOCKING' && (
          <Descriptions column={2}>
            <Item label="Tipo de faturamento">
              <FormatItem>Atracação</FormatItem>
            </Item>
            <Item label="Atracação">
              <FormatItem>{selectedStopover.charge.docking?.code}</FormatItem>
            </Item>
            <Item label="Finalidade da atracação">
              <FormatItem>
                {selectedStopover.charge.docking?.docking_goal}
              </FormatItem>
            </Item>
            <Item label="Natureza da carga">
              <FormatItem>
                {
                  selectedStopover.charge.docking
                    ?.total_operations_product_nature_of_cargo
                }
              </FormatItem>
            </Item>
            <Item label="Tipo de navegação">
              <FormatItem>
                {selectedStopover.charge.stopover?.navigation_type}
              </FormatItem>
            </Item>
            <Item label="Grupo de produto">
              <FormatItem>
                {
                  selectedStopover.charge.docking
                    ?.total_operations_product_product_group
                }
              </FormatItem>
            </Item>
            <Item label="Duração da atracação">
              <FormatItem>{dockingDuration}</FormatItem>
            </Item>
            <Item label="DWT - Porte">
              <FormatItem>
                {selectedStopover?.docking?.input_dwt ||
                  selectedStopover.charge.vessel?.dwt}{' '}
                ton
              </FormatItem>
            </Item>
            <Item label="Berço">
              <FormatItem>
                {selectedStopover.charge.docking?.docking_place_name}
              </FormatItem>
            </Item>
            <Item label="LOA">
              <FormatItem>{selectedStopover.stopover?.vessel_loa} m</FormatItem>
            </Item>
          </Descriptions>
        )}
        {selectedStopover.charge.charge_type === 'VESSEL' && (
          <Descriptions column={2}>
            <Item label="Tipo de faturamento">
              <FormatItem>Embarcação estacionária</FormatItem>
            </Item>
            <Item label="Natureza da carga">
              <FormatItem>
                {selectedStopover.charge.nature_of_cargo?.name}
              </FormatItem>
            </Item>
            <Item label="LOA">
              <FormatItem>
                {selectedStopover.charge.vessel?.loa &&
                  `${selectedStopover.charge.vessel?.loa} m`}
              </FormatItem>
            </Item>
            <Item label="Grupo de produto">
              <FormatItem>
                {selectedStopover.charge.product_group?.name}
              </FormatItem>
            </Item>
            <Item label="Tipo de navegação">
              <FormatItem>
                {selectedStopover.charge.navigation_type?.description}
              </FormatItem>
            </Item>
            <Item label="DWT - Porte">
              <FormatItem>
                {selectedStopover.charge.vessel?.dwt &&
                  `${selectedStopover.charge.vessel?.dwt} ton`}
              </FormatItem>
            </Item>
          </Descriptions>
        )}
      </Panel>
    </Collapse>
  );
}
