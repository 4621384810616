import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';

import { OperationIcon } from '../../../../../../assets/icons/operation';
import { Button, Descriptions, FormatItem } from '../../../../../../components';
import {
  OperationCraneType,
  OperationCraneTypeDescription,
  OperationalOperationType,
  Stopover as StopoverType,
} from '../../../../../../types';
import { formatOperationCraneToDescription } from '../../../formatOperationToDescription';
import { ItemOperationCraneResume } from '../styles';
import { ContainersByCraneModal } from './containersByCraneModal/ContainersByCraneModal';

type OperationCraneResumeDescriptionProps = {
  selectedOperation: OperationalOperationType;
  selectedStopover: StopoverType;
  operationCranes?: OperationCraneType[];
  isLoadingOperationCranes?: boolean;
};

const { Item } = Descriptions;

export function OperationCraneResumeDescription(
  props: OperationCraneResumeDescriptionProps
) {
  const {
    selectedOperation,
    selectedStopover,
    operationCranes,
    isLoadingOperationCranes,
  } = props;
  const [isReadMore, setIsReadMore] = useState(false);
  const [isVisibleContainersDrawer, setIsVisibleContainersDrawer] =
    useState(false);

  const firstOperationCrane = useMemo(() => {
    if (operationCranes && operationCranes.length > 0) {
      return formatOperationCraneToDescription(operationCranes[0]);
    }
    return {} as OperationCraneTypeDescription;
  }, [operationCranes]);

  return (
    <div
      style={{
        marginBlockStart: '10px',
        marginInlineStart: '16px',
      }}
    >
      <ContainersByCraneModal
        containers={firstOperationCrane.containers || []}
        isVisible={isVisibleContainersDrawer}
        setIsVisible={setIsVisibleContainersDrawer}
        selectedStopover={selectedStopover}
        pageContext="OPERATOR_AREA"
      />
      <Button
        style={{
          display: 'flex',
          gap: '6px',
          alignItems: 'center',
          height: '37px',
          marginBottom: '8px',
        }}
        disabled={isEmpty(firstOperationCrane.containers)}
        type="primary"
        icon={<OperationIcon style={{ marginBottom: '5px' }} />}
        onClick={() => setIsVisibleContainersDrawer(true)}
        loading={isLoadingOperationCranes}
      >
        Ver contêineres
      </Button>
      <Descriptions column={1}>
        <Item label="Total manifestado">
          <ItemOperationCraneResume>
            <FormatItem>
              <span>
                {selectedStopover.total_operation_containers} container(es)
              </span>
            </FormatItem>
          </ItemOperationCraneResume>
          {/* {selectedOperation.total_containers_manifested} */}
        </Item>
        <Item label="Cód. Manifestos">
          <ItemOperationCraneResume>
            <FormatItem>
              {selectedStopover.container_manifest_numbers
                ? isReadMore ||
                  selectedStopover.container_manifest_numbers?.length < 200
                  ? selectedStopover.container_manifest_numbers
                  : selectedStopover.container_manifest_numbers
                      .slice(0, 200)
                      .concat('...')
                : null}
            </FormatItem>
            {selectedStopover.container_manifest_numbers &&
              selectedStopover.container_manifest_numbers?.length > 200 && (
                <Button
                  style={{ fontWeight: '700' }}
                  type="link"
                  onClick={() => setIsReadMore((prev) => !prev)}
                >
                  {isReadMore ? 'Ver menos' : 'Ver mais'}
                </Button>
              )}
          </ItemOperationCraneResume>
        </Item>
      </Descriptions>
    </div>
  );
}
