import { message } from 'antd';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import Flag from 'react-flagpack';

import {
  Button,
  Checkbox,
  Descriptions,
  Drawer,
  ErrorBoundary,
  FormatItem,
  GenericDrawerHeader,
  SlimTable,
} from '../../../../components';
import { usePatchFareMutation } from '../../../../services/fareApi';
import {
  ChargeType,
  DiscountItemType,
  FareType,
  TableOneItemType,
} from '../../../../typesFinancial';
import { chargeTypeList } from '../../../../utils/lists';
import {
  createDateStringPtBr,
  createDateStringPtBrSeconds,
  formatNumberToLocale,
  returnDiffDaysInHours,
} from '../../../../utils/utils';
import { Container, Title, Section, SectionCard } from './styles';

type FareStatementDrawerProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  selectedFare: FareType;
  selectedCharge: ChargeType;
};

type ServicesDataSource = {
  id: number;
  description: string;
  value?: string;
  segregated_billing?: boolean;
  raw_value?: number;
};

const { Item } = Descriptions;

export function FareStatementDrawer(props: FareStatementDrawerProps) {
  const { isVisible, setIsVisible, selectedFare, selectedCharge } = props;

  const isVesselType = selectedCharge.charge_type === 'VESSEL';

  function onClose() {
    setIsVisible(false);
  }

  const chargeType = useMemo(() => {
    return (
      chargeTypeList.find((item) => item.id === selectedCharge.charge_type) || {
        id: '',
        name: '',
      }
    );
  }, [selectedCharge]);

  const vesselInfo = useMemo(() => {
    if (chargeType.id === 'VESSEL') {
      return {
        name: selectedCharge.vessel?.name,
        flag: selectedCharge.vessel?.flag,
      };
    }
    if (chargeType.id === 'DOCKING') {
      return {
        name: selectedCharge.stopover?.vessel_name,
        flag: selectedCharge.stopover?.vessel_name,
      };
    }
    return { name: '', flag: '' };
  }, [chargeType]);

  const [
    patchFare,
    { isSuccess: isSuccessPatchFare, isLoading: isLoadingPatchFare },
  ] = usePatchFareMutation();

  const dockingDuration = useMemo(() => {
    if (
      !selectedCharge.docking?.first_cable_tied_in ||
      !selectedCharge.docking?.last_cable_untied_in
    ) {
      return '00h 00';
    }
    return returnDiffDaysInHours(
      selectedCharge.docking?.first_cable_tied_in || '',
      selectedCharge.docking?.last_cable_untied_in || ''
    );
  }, [selectedCharge]);

  function getCopyText() {
    let text = `\n\n\n\n\n\n\n\n\n\n\nARMADOR: \n${
      selectedCharge.vessel?.shipowner?.name ?? ''
    } C.N.P.J/C.P.F. ${
      selectedFare?.company?.identifier ?? ''
    } \nOPERADOR: \n Código: ${selectedFare?.company?.id ?? ''}  Nome: ${
      selectedFare?.company?.name ?? ''
    }  C.N.P.J/C.P.F.:${
      selectedFare?.company?.cnpj ?? ''
    }\nNOTAFISCAL:\nCódigo:${''}  Nome: ${
      selectedCharge.company?.name ?? ''
    } Cod.Contábil\nEndereço: ${
      selectedFare?.company?.address?.address ?? ''
    } \nBairro: ${selectedFare?.company?.address?.neighborhood ?? ''} Cidade: ${
      selectedFare?.company?.address?.city ?? ''
    } Estado: ${selectedFare?.company?.address?.state ?? ''} CEP: ${
      selectedFare?.company?.address?.postal_code ?? ''
    } \nC.N.P.J/C.P.F. ${
      selectedFare?.company?.identifier ?? ''
    } I.E.: ${''} I.M.: ${''}\nNAVIO/ATRACAÇÃO\nI.M.O: ${
      isVesselType
        ? selectedCharge.vessel?.imo
        : selectedCharge?.stopover?.vessel_imo
    } Nome: ${
      isVesselType
        ? selectedCharge?.vessel?.name
        : selectedCharge?.stopover?.vessel_name
    }\nCalado: ${''} Loa: ${selectedCharge?.vessel?.loa ?? ''} DWT: ${
      selectedCharge?.docking?.input_dwt || selectedCharge?.vessel?.dwt
    } Arqueação: ${''}\n\nViagem: ${
      selectedCharge?.stopover?.shipowner_trip ?? ''
    } Atracação: ${selectedCharge?.docking?.code ?? ''} \nBerço: ${
      selectedCharge?.docking?.docking_place_name ?? ''
    } Programação: ${''} Cesv: ${''}\nData Atracação: ${createDateStringPtBrSeconds(
      selectedCharge?.docking?.first_cable_tied_in ?? ''
    )} Data Desatracação: ${createDateStringPtBrSeconds(
      selectedCharge?.docking?.last_cable_untied_in ?? ''
    )}\n\n\n\n\n`;

    if (selectedFare.table_one && selectedFare.table_one.items) {
      selectedFare.table_one.items.forEach((table_one_item) => {
        console.log('table_one_item: ', table_one_item);
        text = text.concat(
          `\nTAB. 1 - ${table_one_item.number}: ${table_one_item.name} R$ ${
            formatNumberToLocale(table_one_item.value) || ''
          } ${
            table_one_item.minimal_value_applied ? 'Valor mínimo aplicado' : ''
          }`
        );
      });
    }

    if (selectedFare.table_two && selectedFare.table_two.items) {
      selectedFare.table_two.items.forEach((table_two_item) => {
        console.log('table_two_item: ', table_two_item);
        text = text.concat(
          `\nTAB. 2 - ${table_two_item.number}: ${table_two_item.name} R$ ${
            formatNumberToLocale(table_two_item.value) || ''
          } ${
            table_two_item.minimal_value_applied ? 'Valor mínimo aplicado' : ''
          }`
        );
      });
    }

    if (selectedFare.table_three && selectedFare.table_three.items) {
      selectedFare.table_three.items.forEach((table_three_item) => {
        console.log('table_three_item: ', table_three_item);
        text = text.concat(
          `\nTAB. 3 - ${table_three_item.number}: ${table_three_item.name} R$ ${
            formatNumberToLocale(table_three_item.value) || ''
          } ${
            table_three_item.minimal_value_applied
              ? 'Valor mínimo aplicado'
              : ''
          }`
        );
      });
    }

    if (selectedFare.table_five && selectedFare.table_five.items) {
      selectedFare.table_five.items.forEach((table_five_item) => {
        console.log('table_five_item: ', table_five_item);
        text = text.concat(
          `\nTAB. 5 - ${table_five_item.number}: ${table_five_item.name} R$ ${
            formatNumberToLocale(table_five_item.value) || ''
          } ${
            table_five_item.minimal_value_applied ? 'Valor mínimo aplicado' : ''
          }`
        );
      });
    }

    if (selectedFare.table_six && selectedFare.table_six.items) {
      selectedFare.table_six.items.forEach((table_six_item) => {
        console.log('table_six_item: ', table_six_item);
        text = text.concat(
          `\nTAB. 6 - ${table_six_item.number}: ${table_six_item.name} R$ ${
            formatNumberToLocale(table_six_item.value) || ''
          } ${
            table_six_item.minimal_value_applied ? 'Valor mínimo aplicado' : ''
          }`
        );
      });
    }

    if (selectedFare.table_seven && selectedFare.table_seven.items) {
      selectedFare.table_seven.items.forEach((table_seven_item) => {
        console.log('table_seven_item: ', table_seven_item);
        text = text.concat(
          `\nTAB. 7 - ${table_seven_item.number}: ${table_seven_item.name} R$ ${
            formatNumberToLocale(table_seven_item.value) || ''
          } ${
            table_seven_item.minimal_value_applied
              ? 'Valor mínimo aplicado'
              : ''
          }`
        );
      });
    }

    if (selectedFare.table_nine && selectedFare.table_nine.items) {
      selectedFare.table_nine.items.forEach((table_nine_item) => {
        console.log('table_nine_item: ', table_nine_item);
        text = text.concat(
          `\nTAB. 9 - ${table_nine_item.number}: ${table_nine_item.name} R$ ${
            formatNumberToLocale(table_nine_item.value) || ''
          } ${
            table_nine_item.minimal_value_applied ? 'Valor mínimo aplicado' : ''
          }`
        );
      });
    }

    if (
      selectedFare.table_one &&
      selectedFare.table_one.discount &&
      selectedFare.table_one.discount.items
    ) {
      selectedFare.table_one.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.1 referente à ${discount.name}.`
        );
      });
    }

    if (
      selectedFare.table_two &&
      selectedFare.table_two.discount &&
      selectedFare.table_two.discount.items
    ) {
      selectedFare.table_two.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.2 referente à ${discount.name}.`
        );
      });
    }

    if (
      selectedFare.table_three &&
      selectedFare.table_three.discount &&
      selectedFare.table_three.discount.items
    ) {
      selectedFare.table_three.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.3 referente à ${discount.name}.`
        );
      });
    }

    if (
      selectedFare.table_five &&
      selectedFare.table_five.discount &&
      selectedFare.table_five.discount.items
    ) {
      selectedFare.table_five.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.5 referente à ${discount.name}.`
        );
      });
    }

    if (
      selectedFare.table_six &&
      selectedFare.table_six.discount &&
      selectedFare.table_six.discount.items
    ) {
      selectedFare.table_six.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.5 referente à ${discount.name}.`
        );
      });
    }

    if (
      selectedFare.table_seven &&
      selectedFare.table_seven.discount &&
      selectedFare.table_seven.discount.items
    ) {
      selectedFare.table_seven.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.5 referente à ${discount.name}.`
        );
      });
    }

    if (
      selectedFare.table_nine &&
      selectedFare.table_nine.discount &&
      selectedFare.table_nine.discount.items
    ) {
      selectedFare.table_nine.discount.items.forEach((discount) => {
        console.log('discount: ', discount);
        text = text.concat(
          `\nDesconto: ${
            formatNumberToLocale(discount.value) || ''
          } concedido na Tab.9 referente à ${discount.name}.`
        );
      });
    }
    return text;
  }

  function copyStatement() {
    return navigator.clipboard.writeText(getCopyText());
  }

  useEffect(() => {
    if (isSuccessPatchFare)
      message.success('Demonstrativo faturado com sucesso');
  }, [isSuccessPatchFare]);

  function handleGenerateStayment() {
    if (selectedFare.id && selectedCharge.id)
      patchFare({
        action: 'generate_statement',
        id: selectedFare.id,
        chargeId: selectedCharge.id,
      });
  }

  function getTitle() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ErrorBoundary replace="">
            <Flag code={vesselInfo.flag} hasBorder={false} size="l" />
          </ErrorBoundary>
          <span
            className="title"
            style={{ paddingLeft: '8px', fontSize: '16px' }}
          >
            <strong>{vesselInfo.name}</strong>
          </span>
          {chargeType.id === 'DOCKING' && (
            <span
              style={{
                color: 'var(--neutral_light)',
                paddingLeft: '8px',
                fontFamily: 'Consolas',
              }}
            >
              ATR: {selectedCharge.docking?.code}
            </span>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '20px',
          }}
        >
          <Button
            type="primary"
            onClick={() => handleGenerateStayment()}
            loading={isLoadingPatchFare}
          >
            Faturar
          </Button>
          <Button
            type="dashed"
            onClick={() => copyStatement()}
            loading={isLoadingPatchFare}
          >
            Copiar
          </Button>
        </div>
      </div>
    );
  }

  function sumTableDiscounts(discounts?: DiscountItemType[]) {
    if (discounts) {
      return discounts.reduce((acc, obj) => acc + obj.value, 0);
    }
    return 0;
  }

  function getServicesDatasource() {
    const data: ServicesDataSource[] = [];
    let index = 1;

    if (selectedFare.table_one?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_one.discount?.items
      );
      selectedFare.table_one?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 1 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    if (selectedFare.table_two?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_two.discount?.items
      );
      selectedFare.table_two?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 2 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    if (selectedFare.table_three?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_three.discount?.items
      );
      selectedFare.table_three?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 3 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    if (selectedFare.table_five?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_five.discount?.items
      );
      selectedFare.table_five?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 5 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    if (selectedFare.table_six?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_six.discount?.items
      );
      selectedFare.table_six?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 6 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    if (selectedFare.table_seven?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_seven.discount?.items
      );
      selectedFare.table_seven?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 7 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    if (selectedFare.table_nine?.items) {
      let totalTableDiscounts = sumTableDiscounts(
        selectedFare.table_nine.discount?.items
      );
      selectedFare.table_nine?.items.map((item: TableOneItemType) => {
        let itemValue = item.value;
        if (totalTableDiscounts > 0 && itemValue && item.value) {
          itemValue -= totalTableDiscounts;
          if (itemValue < 0) {
            itemValue = 0;
            totalTableDiscounts -= item.value;
          }
        }
        data.push({
          id: index,
          description: `TAB. 9 - ${item.name}`,
          segregated_billing: item.segregated_billing,
          value: formatNumberToLocale(itemValue, true) || '',
          raw_value: itemValue,
        });

        index++;
      });
    }

    return data;
  }

  function renderSegregatedBilling(value: any) {
    if (value === true) {
      return <Checkbox checked disabled />;
    }
    return <Checkbox checked={false} disabled />;
  }

  function getFooterForServices() {
    const values = getServicesDatasource();
    const totalValue = values.reduce((acc, obj) => {
      if (obj.raw_value) {
        return acc + obj.raw_value;
      }
      return acc + 0;
    }, 0);

    return (
      <div
        style={{
          textAlign: 'right',
          color: '#2E3439',
          fontSize: '16px',
          margin: '0',
          fontFamily: 'Consolas',
        }}
      >
        <p style={{ margin: '0' }}>
          TOTAL DO DEMONSTRATIVO: {formatNumberToLocale(totalValue, true)}
          {/* {formatNumberToLocale(selectedFare.total_value, true)} */}
        </p>
      </div>
    );
  }

  return (
    <Drawer
      visible={isVisible}
      placement="right"
      width={1200}
      closable={false}
      destroyOnClose
      onClose={onClose}
    >
      <GenericDrawerHeader title={getTitle()} showBackButton onBack={onClose} />
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '18px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <Title>Tipo de faturamento: </Title>
              <span>{chargeType?.name}</span>
            </div>
            <p className="date">
              Data: {moment().format('DD/MM/YYYY - HH:mm')}
            </p>
          </div>
          <Title>Demonstrativo de cálculo</Title>
        </div>

        <Section>
          <span className="section-title">{selectedFare?.company?.name}</span>
          <SectionCard>
            <Descriptions column={5}>
              <Item label="Endereço">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.address?.address}
                </FormatItem>
              </Item>
              <Item label="Código">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {null}
                </FormatItem>
              </Item>
              <Item label="CEP">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.address?.postal_code}
                </FormatItem>
              </Item>
              <Item label="CNPJ/CPF">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.identifier}
                </FormatItem>
              </Item>
              <Item label="Cidade">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.address?.city}
                </FormatItem>
              </Item>
              <Item label="Cidade">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.address?.city}
                </FormatItem>
              </Item>
              <Item label="Bairro">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.address?.neighborhood}
                </FormatItem>
              </Item>
              <Item label="Estado">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {selectedFare?.company?.address?.state}
                </FormatItem>
              </Item>
              <Item label="I.E">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {null}
                </FormatItem>
              </Item>
              <Item label="I.M">
                <FormatItem style={{ fontFamily: 'Consolas' }}>
                  {null}
                </FormatItem>
              </Item>
            </Descriptions>
          </SectionCard>
        </Section>
        {chargeType.id !== 'SINGLE_FARE' && (
          <Section>
            <span className="section-title">EMBARCAÇÃO/ATRACAÇÃO</span>
            <SectionCard>
              <Descriptions column={5}>
                <Item label="Embarcação">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {isVesselType
                      ? selectedCharge?.vessel?.name
                      : selectedCharge?.stopover?.vessel_name}
                  </FormatItem>
                </Item>
                <Item label="Cód. Atracação">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {selectedCharge?.docking?.code}
                  </FormatItem>
                </Item>
                <Item label="Viagem">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {selectedCharge?.stopover?.shipowner_trip}
                  </FormatItem>
                </Item>
                <Item label="Berço">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {selectedCharge?.docking?.docking_place_name}
                  </FormatItem>
                </Item>
                <Item label="IMO">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {isVesselType
                      ? selectedCharge.vessel?.imo
                      : selectedCharge?.stopover?.vessel_imo}
                  </FormatItem>
                </Item>
                <Item label="Chegada">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {createDateStringPtBr(
                      selectedCharge?.stopover?.actual_time_of_arrival
                    )}
                  </FormatItem>
                </Item>
                <Item label="Atracação">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {createDateStringPtBr(
                      selectedCharge?.docking?.first_cable_tied_in
                    )}
                  </FormatItem>
                </Item>
                <Item label="Inicio operação">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {createDateStringPtBr(
                      selectedCharge?.docking?.real_operation_start
                    )}
                  </FormatItem>
                </Item>
                <Item label="LOA">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {isVesselType
                      ? selectedCharge?.vessel?.loa
                      : selectedCharge?.stopover?.vessel_loa}
                  </FormatItem>
                </Item>
                <Item label="DWT">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {selectedCharge?.docking?.input_dwt ||
                      selectedCharge?.vessel?.dwt}
                  </FormatItem>
                </Item>
                <Item label="Saída">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {createDateStringPtBr(
                      selectedCharge?.stopover?.port_stay_finished
                    )}
                  </FormatItem>
                </Item>
                <Item label="Desatracação">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {createDateStringPtBr(
                      selectedCharge?.docking?.last_cable_untied_in
                    )}
                  </FormatItem>
                </Item>
                <Item label="Fim operação">
                  <FormatItem style={{ fontFamily: 'Consolas' }}>
                    {createDateStringPtBr(
                      selectedCharge?.docking?.real_operation_finish
                    )}
                  </FormatItem>
                </Item>
              </Descriptions>
            </SectionCard>
          </Section>
        )}
        <Section
          style={{
            background: '#ffffff ',
            borderWidth: '0px 1px 1px 1px',
            borderStyle: 'solid',
            borderColor: '#CCDDE9',
          }}
        >
          <span className="section-title">Serviços</span>

          <SlimTable
            rowKey="id"
            columns={[
              {
                title: 'Itens',
                dataIndex: 'id',
                key: 'items',
                width: '5%',
              },
              {
                title: 'Descrição',
                dataIndex: 'description',
                key: 'description',
                width: '60%',
              },
              {
                title: 'Faturamento Separado',
                dataIndex: 'segregated_billing',
                key: 'segregated_billing',
                width: '15%',
                align: 'right',
                render: renderSegregatedBilling,
              },
              {
                title: 'Valor Total',
                dataIndex: 'value',
                key: 'value',
                align: 'right',
                width: '20%',
                render(value) {
                  return (
                    <span style={{ fontFamily: 'Consolas' }}>{value}</span>
                  );
                },
              },
            ]}
            dataSource={getServicesDatasource()}
            pagination={false}
            footer={() => getFooterForServices()}
          />
        </Section>
      </Container>
    </Drawer>
  );
}
