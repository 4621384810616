import { Col, Row } from 'antd';
import styled from 'styled-components';

import {
  contentOuterPadding,
  midCol,
  midLargeCol,
  midSmallCol,
  smallCol,
  statusLineWidth,
} from './manoeuvreListItem';

const BoardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${contentOuterPadding};
  padding-top: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background: rgb(223 234 241);

  & > .maneuver-date {
    width: 12%;
    padding: ${contentOuterPadding} ${contentOuterPadding} 0
      ${contentOuterPadding};
  }
  & > .maneuver-infos {
    width: calc(88% - ${statusLineWidth});
    padding: ${contentOuterPadding} ${contentOuterPadding} 0
      ${contentOuterPadding};
  }
  & .header-cell {
    color: var(--neutral_medium);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    align-items: flex-end;
    display: flex;
  }
`;

export function ManoeuvreBoardHeader() {
  return (
    <BoardHeader>
      <div className="maneuver-date header-cell"> previsão </div>
      <Row className="maneuver-infos">
        <Col className="header-cell" {...midSmallCol}>
          embarcação
        </Col>
        <Col className="header-cell" {...midSmallCol}>
          agência
        </Col>
        <Col className="header-cell" {...midCol}>
          empresa amarração{' '}
        </Col>
        <Col className="header-cell" {...midCol}>
          rebocagem
        </Col>
        <Col className="header-cell" {...smallCol}>
          origem
        </Col>
        <Col className="header-cell" {...smallCol}>
          destino
        </Col>
        <Col className="header-cell" {...midLargeCol}>
          manobra
        </Col>
        <Col className="header-cell" span={1} />
      </Row>
    </BoardHeader>
  );
}
