import { UploadOutlined } from '@ant-design/icons';
import { message, Row, Upload, Space } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';

import { Button, Form } from '../../components';
import { Modal } from '../../components/antd/Modal';
import { useUploadOperatorReportMutation } from '../../services/warehousingVehiclesApi';

type ModalOperatorReportUploadProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
};
const { confirm } = Modal;

const style = {
  btnImport: { padding: '16px 0px 0px 0px' },
  container: { padding: '12px 12px' },
  upload: { padding: '12px 12px 12px 0px' },
};

export function ModalOperatorReportUpload(
  props: ModalOperatorReportUploadProps
) {
  const [form] = Form.useForm();

  const { showModal, setShowModal } = props;

  const [filesLoading, setFilesLoading] = useState<File[]>([]);

  const [fileList, setFileList] = useState<Array<UploadFile<any>>>([]);

  const [uploadFiles, { isSuccess: isSuccessUploadFiles }] =
    useUploadOperatorReportMutation();

  useEffect(() => {
    if (isSuccessUploadFiles) {
      closeModal();
      message.success('Arquivo importado com sucesso');
    }
  }, [isSuccessUploadFiles]);

  function closeModal() {
    setFilesLoading([]);
    setFileList([]);
    setShowModal(false);
  }

  function onRemove() {
    setFilesLoading([]);
    setFileList([]);
  }

  function beforeUpload(file: File) {
    setFilesLoading([file]);
    setFileList((prev: any) => [...prev, file]);
  }

  function onChange(info: UploadChangeParam<UploadFile<any>>) {
    if (info.file.status !== 'removed') {
      setFileList((prev) => {
        return prev.map((prevItem) => {
          return {
            ...prevItem,
            ...info.file,
            status: 'done',
            url: 'tossuape',
          };
        });
      });
    }
  }

  async function uploadPredictedDue() {
    if (filesLoading.length === 1) {
      confirm({
        title: 'Tem certeza que deseja importar o relatório?',
        width: 500,
        cancelText: 'Cancelar',
        async onOk() {
          await uploadFiles({
            files: filesLoading,
          });
        },
      });
    }
  }

  return (
    <Modal
      visible={showModal}
      title={<>Importar relatório diário</>}
      className="TOSPrimaryModal"
      onCancel={closeModal}
      width={620}
      footer={null}
    >
      <Form
        form={form}
        name="operator_report_upload"
        id="operator_report_upload"
        layout="vertical"
      >
        <div style={style.container}>
          <span>
            Insira arquivo do tipo xlsx para importar as informações dos
            veículos
          </span>
          <div style={style.upload}>
            <Upload
              maxCount={1}
              accept=".xlsx"
              onRemove={onRemove}
              beforeUpload={beforeUpload}
              onChange={onChange}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />} type="primary">
                Anexar arquivo
              </Button>
            </Upload>
          </div>
          <Row justify="end" style={style.btnImport}>
            <Space size={12}>
              <Button type="text" onClick={() => closeModal()}>
                Voltar
              </Button>
              <Button
                type="primary"
                onClick={() => uploadPredictedDue()}
                disabled={filesLoading.length === 0}
              >
                Importar
              </Button>
            </Space>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
