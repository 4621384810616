import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;

  & .header {
    display: 'flex';
    gap: 8px;
    align-items: center;
    margin-bottom: 5px;

    & .title {
      text-transform: capitalize;
      font-size: 12px;
      margin-bottom: 0px !important;
    }
  }

  & .title {
    margin-bottom: 8px;

    &__berth {
      font-weight: 600;
      font-size: 14px;
    }
    &__shipping-agency {
      font-size: 12px;
      color: #5f7b91;
      margin-left: 8px;
    }
  }
`;
