import {
  FormatedStaymentLog,
  StaymentLogItemType,
  StaymentLogType,
} from '../../../types';
import { logItemsNames, staymentLogNames } from '../../../utils/lists';

export function formatStaymentLog(
  stayment: StaymentLogType
): FormatedStaymentLog[] {
  // Caso precise voltar a exibir o status da estadia só descomentar essa linha:
  // addLogToList(formatedStaymentLog, stayment);
  return stayment.childs.map((child) => {
    return addLogToList(child);
  });
}

function addLogToList(log: StaymentLogType) {
  return {
    name:
      staymentLogNames.find((logName) => logName.status === log.name)?.label ||
      '',
    created_at: log.log_date,
    log_date: log.log_date,
    id: log.id,
    log_items: formatLogItems(log.log_items),
    key: log.key,
  };
}

function formatLogItems(list: StaymentLogItemType[]): StaymentLogItemType[] {
  return list.map((item) => {
    return {
      id: item.id,
      created_at: item.created_at,
      created_by: item.created_by,
      log_date: item.log_date,
      name:
        logItemsNames.find((logItem) => logItem.status === item.name)?.label ||
        '',
    };
  });
}
