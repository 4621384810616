import { CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import moment from 'moment';

import { Line } from '../../../../../types';

type BerthLineCancelationDataProps = {
  selectedLine: Line;
};

export function BerthLineCancelationData(props: BerthLineCancelationDataProps) {
  const { selectedLine } = props;
  return (
    <div>
      <Alert
        message={
          <>
            <span>
              Linha desativada em:&nbsp;
              {moment(selectedLine.deactivated_at).format('DD/MM/YYYY HH:mm')}
              &nbsp; devido a &#034;
              <span style={{ fontWeight: 600 }}>
                {selectedLine.reason_for_deactivation}
              </span>
              &#034;
            </span>
            <br />
          </>
        }
        type="error"
        showIcon
        style={{ width: '100%', zIndex: -10, borderWidth: '0 0 1px 0' }}
        icon={<CloseCircleFilled />}
      />
    </div>
  );
}
