import {
  CeMerchantsGroupbyOperatorCompanyType,
  CEMerchantType,
  CompanyType,
} from '../types';
import { maskString } from '../utils/utils';

const getCompanyIdentifier = (operatorCompany: CompanyType) => {
  if (operatorCompany) {
    if (operatorCompany.cnpj) {
      return maskString(operatorCompany.cnpj, '##.###.###/####-##');
    }
    if (operatorCompany.tin) {
      return operatorCompany.tin;
    }
  }
};

export const groupCEMerchantByOperatorCompany = (
  input: CEMerchantType[],
  operation_direction: string
) => {
  const groupsList: CeMerchantsGroupbyOperatorCompanyType[] = [];
  input.forEach((ceMerchant) => {
    const groupKey = ceMerchant.operator_company?.id || 0;
    if (!groupsList.some((item) => item.id === groupKey)) {
      const newGroup = {
        id: groupKey,
        name:
          ceMerchant.operator_company?.name ||
          `${
            operation_direction === 'UNLOADING' ? 'Consignatário' : 'Embarcador'
          } não informado`,
        cnpj:
          getCompanyIdentifier(ceMerchant.operator_company) ||
          `${
            operation_direction === 'UNLOADING' ? 'Consignatário' : 'Embarcador'
          } não informado (CNPJ)`,
        items: [],
        all_ncm_items: [],
      };
      groupsList.push(newGroup);
    }
    groupsList.forEach((item) => {
      if (item.id === groupKey) {
        item.items.push(ceMerchant);
        item.all_ncm_items?.push(...ceMerchant.ncm_items);
      }
    });
  }, []);
  return groupsList;
};
