import { useGetOperationCalculusQuery } from '../../../../../services/operationCalculusApi';
import {
  OperationCalculusType,
  OperationProductCalculusType,
  StopoverGroupByOperatorType,
} from '../../../../../types';
import { getTypeOfOperation } from '../../../../operation/OperationsData/formatOperationToDescription';
import { ContainersReport } from './containers/ContainersReport';
import { GeneralDataDescriptionSection } from './generalDataDescriptionSection';
import { OperationResumeDescriptionSection } from './operationResumeDescriptionSection';
import { ProductDataDescriptionSection } from './productDataDescriptionSection';
import { GeneralCargoDescriptionSection } from './styles';

type GeneralCargoProps = {
  stopover: StopoverGroupByOperatorType;
};

export function GeneralCargo(props: GeneralCargoProps) {
  const { stopover } = props;
  const operationType = !stopover.operation
    ? 'COMMON'
    : getTypeOfOperation(stopover.operation);
  const isContainer = operationType === 'CONTAINER';

  const { data: operationCalculusData } = useGetOperationCalculusQuery(
    stopover?.operation?.id || 0,
    { skip: stopover?.operation?.id === undefined }
  );

  return (
    <GeneralCargoDescriptionSection>
      {!isContainer && (
        <>
          <GeneralDataDescriptionSection stopover={stopover} />
          <OperationResumeDescriptionSection
            operation={stopover.operation}
            isLiquidBulk={operationType === 'LIQUID_BULK'}
            operationCalculusData={
              operationCalculusData || ({} as OperationCalculusType)
            }
          />
          {stopover.operation?.items?.map((product, index) => {
            return (
              <ProductDataDescriptionSection
                key={index}
                product={product}
                operationType={operationType}
                currentIndex={index}
                operationCalculusData={
                  operationCalculusData || ({} as OperationCalculusType)
                }
                operationProductCalculusData={
                  operationCalculusData?.operation_products?.find(
                    (item) => item.id === product.id
                  ) || ({} as OperationProductCalculusType)
                }
              />
            );
          })}
        </>
      )}
      {isContainer && <ContainersReport operationGroupbyOperator={stopover} />}
    </GeneralCargoDescriptionSection>
  );
}
