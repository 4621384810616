import { ColumnsType } from 'antd/lib/table';

export function ncmStorageByMerchantCETableColumns(
  isVehicle: boolean
): ColumnsType<Record<string, any>> {
  return [
    { title: 'CE Mercante', dataIndex: 'ce_merchant' },
    { title: 'Armazenamento', dataIndex: 'storage_terminal' },
    {
      title: isVehicle ? 'Chassis' : 'Volume',
      dataIndex: 'volume',
      align: isVehicle ? 'left' : 'right',
      width: '25%',
      render: (value, record) => {
        if (record.chassis) {
          return record.chassis;
        }
        return value;
      },
    },
    {
      title: 'Peso total',
      dataIndex: 'weight',
      align: 'right',
      width: '25%',
      render: (value) => {
        return `${value} ton`;
      },
    },
  ];
}
