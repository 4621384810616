import { Col } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ReactNode } from 'react';

import { Checkbox } from '../Checkbox';
import { FormItem } from '../Form';

type FormItemsCheckboxProps = {
  name: string | string[];
  label?: string;
  colSpan?: number;
  noStyle?: boolean;
  style?: React.CSSProperties;
  formItemsStyle?: React.CSSProperties;
  children?: ReactNode;
  onChange?: (e: CheckboxChangeEvent) => void;
  disabled?: boolean;
};

export function FormItemsCheckbox(props: FormItemsCheckboxProps) {
  const {
    name,
    label,
    colSpan,
    noStyle,
    style,
    formItemsStyle,
    children,
    onChange,
    disabled,
  } = props;
  if (noStyle) {
    return (
      <FormItem
        name={name}
        label={label}
        noStyle={noStyle}
        valuePropName="checked"
      />
    );
  }
  return (
    <Col span={colSpan || 24} style={style}>
      <FormItem
        name={name}
        label={label}
        valuePropName="checked"
        style={formItemsStyle}
      >
        <Checkbox onChange={onChange} disabled={disabled}>
          {children}
        </Checkbox>
      </FormItem>
    </Col>
  );
}
