import { formatUserTableObj } from '../features/users/formatUserFormObj';
import { User, UserStatusType } from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const userApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<
      { results: Array<User>; next: string; count: number },
      {
        page?: number;
        page_size?: number;
        company_name?: string;
        role?: string;
        first_name_or_last_name?: string;
      }
    >({
      query: (args) => {
        const { page, company_name, role, first_name_or_last_name, page_size } =
          args;
        return {
          url: `core/users`,
          method: 'GET',
          params: page
            ? { page, company_name, role, first_name_or_last_name, page_size }
            : { company_name, role, first_name_or_last_name, page_size },
          headers: getHeaders,
        };
      },
      transformResponse: (response: any) => {
        const formatedUsers: any = [];
        const transformedResponse = {
          next: response.next,
          results: [],
          count: response.count,
        };
        response.results?.forEach((user: User) => {
          formatedUsers.push(formatUserTableObj(user));
        });
        transformedResponse.results = formatedUsers;
        return transformedResponse;
      },
      providesTags: [{ type: 'User' }],
    }),
    createUser: build.mutation<any, Partial<User>>({
      query: (user) => {
        return {
          url: `core/users`,
          method: 'POST',
          body: user,
        };
      },
      invalidatesTags: (_results, error) =>
        getInvalidatesTags(error, [{ type: 'User' }]),
    }),
    updateUser: build.mutation<any, Partial<User> & Pick<User, 'id'>>({
      query: ({ id, ...user }) => {
        return {
          url: `core/users/${id}`,
          method: 'PUT',
          body: user,
        };
      },
      invalidatesTags: (_results, error) =>
        getInvalidatesTags(error, [{ type: 'User' }]),
    }),
    updateUserStatus: build.mutation<
      any,
      { status: UserStatusType; id: number }
    >({
      query: ({ id, ...status }) => {
        return {
          url: `core/users/${id}/status`,
          method: 'PATCH',
          body: status,
        };
      },
    }),
    updateUserPermissionsGroup: build.mutation<
      any,
      { groups: Array<number>; id: number }
    >({
      query: ({ id, ...groups }) => {
        return {
          url: `core/users/${id}/groups`,
          method: 'POST',
          body: groups,
        };
      },
    }),
    disableUsers: build.mutation<any, Array<Partial<User>>>({
      query: (userStatusArray) => {
        return {
          url: `core/users/status`,
          method: 'PATCH',
          body: userStatusArray,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useLazyGetUsersQuery,
  useUpdateUserMutation,
  useUpdateUserPermissionsGroupMutation,
  useUpdateUserStatusMutation,
  useDisableUsersMutation,
} = userApi;
