import { EditFilled } from '@ant-design/icons';
import { Row, message } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import Flag from 'react-flagpack';

import {
  Form,
  Button,
  Descriptions,
  FormItemDatePicker,
  Modal,
  ErrorBoundary,
  FormatItem,
} from '../..';
import { usePatchOperationMutation } from '../../../services/operationApi';
import { OperationalOperationTypeDescription } from '../../../types';
import {
  createDate,
  createDateString,
  disabledDateAfterToday,
} from '../../../utils/utils';
import { Container } from './styles';

type OperationResumeDescriptionProps = {
  selectedOperation: OperationalOperationTypeDescription;
  shippingAgencyName?: string;
  vesselFlag?: string;
  vesselName?: string;
  dockingCode?: string;
  dockingPlace?: string;
  showRealTimeOperation?: boolean;
  expectedBerthingDates?: {
    expectedBerthing: string;
    expectedUnberthing: string;
  };
};

type FormToShowType = 'EXPECTED_TIME' | 'REAL_TIME';
export function OperationResumeDescription(
  props: OperationResumeDescriptionProps
) {
  const {
    selectedOperation,
    shippingAgencyName,
    vesselFlag,
    vesselName,
    dockingCode,
    dockingPlace,
    showRealTimeOperation,
    expectedBerthingDates,
  } = props;
  const { Item } = Descriptions;

  const [formToShow, setformToShow] = useState<FormToShowType>('EXPECTED_TIME');
  const [isVisibleForm, setIsVisibleForm] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [updateOperation, { isLoading: isLoadingUpdateOperation }] =
    usePatchOperationMutation();

  function onSubmitForm() {
    form.validateFields().then((values) => {
      Object.keys(values).forEach((key) => {
        values[key] = createDateString(values[key]);
      });
      values.id = selectedOperation.id;
      if (values.expected_operation_finish) {
        values.real_operation_finish_date_filled = createDateString(moment());
      }
      updateOperation(values).then(() => {
        message.success('Operação atualizada');
        setIsVisibleForm(false);
      });
    });
  }

  function onClickEdit(formToShow: FormToShowType) {
    setformToShow(formToShow);
    setIsVisibleForm(true);
  }

  function createInitialValues() {
    const format = 'DD/MM/YYYY HH:mm';
    return {
      expected_operation_start: createDate(
        selectedOperation?.expected_operation_start,
        format
      ),
      expected_operation_finish: createDate(
        selectedOperation?.expected_operation_finish,
        format
      ),
      real_operation_start: createDate(
        selectedOperation?.real_operation_start,
        format
      ),
      real_operation_finish: createDate(
        selectedOperation?.real_operation_finish,
        format
      ),
    };
  }
  return (
    <>
      <Modal
        title={
          formToShow === 'EXPECTED_TIME'
            ? 'Previsão da operação'
            : 'Hora real da operação'
        }
        visible={isVisibleForm}
        className="TOSPrimaryModal"
        okText="Salvar"
        cancelText="Voltar"
        okButtonProps={{
          htmlType: 'submit',
          form: 'editForm',
          loading: isLoadingUpdateOperation,
        }}
        onCancel={() => setIsVisibleForm(false)}
      >
        <Form
          form={form}
          layout="vertical"
          name="editForm"
          onFinish={onSubmitForm}
          initialValues={createInitialValues()}
        >
          {formToShow === 'EXPECTED_TIME' ? (
            <Row gutter={16}>
              <FormItemDatePicker
                // normalize={(value) => createDateString(value)}
                label="Previsão de início da operação"
                name="expected_operation_start"
                colSpan={12}
              />
              <FormItemDatePicker
                // normalize={(value) => createDateString(value)}
                label="Previsão de fim da operação"
                name="expected_operation_finish"
                colSpan={12}
              />
            </Row>
          ) : formToShow === 'REAL_TIME' ? (
            <Row gutter={16}>
              <FormItemDatePicker
                // normalize={(value) => createDateString(value)}
                label="Hora real de início da operação"
                name="real_operation_start"
                colSpan={12}
                disabledDate={disabledDateAfterToday}
              />
              <FormItemDatePicker
                // normalize={(value) => createDateString(value)}
                label="Horal real de fim da operação"
                name="real_operation_finish"
                colSpan={12}
                disabledDate={disabledDateAfterToday}
              />
            </Row>
          ) : null}
        </Form>
      </Modal>
      <Container>
        <Row className="header">
          <ErrorBoundary replace="">
            <Flag code={vesselFlag} hasBorder={false} size="s" />
          </ErrorBoundary>
          <span className="title">
            {vesselName?.toLowerCase()} • {dockingCode || 'Código não gerado'}
          </span>
        </Row>
        <div className="title">
          <span className="title__berth">
            {dockingPlace || 'Berço não informado'}
          </span>
          <span className="title__shipping-agency">
            {shippingAgencyName || 'Agência de navegação não informada'}
          </span>
        </div>
        <Descriptions>
          <Item
            label="Natureza da carga"
            span={selectedOperation?.nature_of_cargo === 'CARGA GERAL' ? 1 : 2}
          >
            <FormatItem>{selectedOperation?.nature_of_cargo}</FormatItem>
          </Item>
          {selectedOperation?.nature_of_cargo === 'CARGA GERAL' && (
            <Item label="Grupo do produto">
              <FormatItem>
                {selectedOperation?.product_group || 'COMUM'}
              </FormatItem>
            </Item>
          )}
          <Item label="Haverá inspeção?">
            <FormatItem>{selectedOperation?.inspection}</FormatItem>
            {selectedOperation?.inspection === 'Sim' &&
              selectedOperation?.inspection_type && (
                <FormatItem>, {selectedOperation?.inspection_type}</FormatItem>
              )}
          </Item>
          {selectedOperation?.nature_of_cargo === 'GRANEL LÍQUIDO' && (
            <>
              <Item label="Manifolds disponíveis">
                <FormatItem>{selectedOperation?.manifolds}</FormatItem>
              </Item>
              <Item label="Vazão da operação">
                <FormatItem>
                  {selectedOperation?.flow_rate &&
                    `${selectedOperation.flow_rate} m³/h`}
                </FormatItem>
              </Item>
              <Item label="Transbordo?">
                <FormatItem>
                  {selectedOperation?.is_cargo_transshipment}
                </FormatItem>
              </Item>
            </>
          )}
          <Item label="Previsão da operação" span={3}>
            <div>
              <FormatItem>
                {selectedOperation?.expected_operation_start}
              </FormatItem>
              <span> até </span>
              <FormatItem>
                {selectedOperation?.expected_operation_finish}
              </FormatItem>
              <Button
                style={{ marginTop: '-10px', marginLeft: '5px' }}
                type="link"
                onClick={() => onClickEdit('EXPECTED_TIME')}
                icon={<EditFilled />}
                size="small"
              />
            </div>
          </Item>
          {showRealTimeOperation && (
            <Item label="Hora real da operação" span={3}>
              <div>
                <FormatItem>
                  {selectedOperation?.real_operation_start}
                </FormatItem>
                <span> até </span>
                <FormatItem>
                  {selectedOperation?.real_operation_finish}
                </FormatItem>
                <Button
                  style={{ marginTop: '-10px', marginLeft: '5px' }}
                  type="link"
                  onClick={() => onClickEdit('REAL_TIME')}
                  icon={<EditFilled />}
                  size="small"
                />
              </div>
            </Item>
          )}
          {expectedBerthingDates && (
            <Item label="Previsão de atracação:" span={3}>
              <div>
                <FormatItem>
                  {expectedBerthingDates.expectedBerthing}
                </FormatItem>
                <span> até </span>
                <FormatItem>
                  {expectedBerthingDates.expectedUnberthing}
                </FormatItem>
              </div>
            </Item>
          )}
        </Descriptions>
      </Container>
    </>
  );
}
