import styled, { ThemeProvider } from 'styled-components';

type CargoNatureProps = {
  cargoNature: {
    value: 1 | 2 | 3;
    name: string;
  };
};

const CargoNatureTag = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.color};
  padding: 1px 8px 2px;
  border-radius: 3px;
  font-size: 14px;
`;

export function CargoNatureRenderer(props: CargoNatureProps) {
  let theme = {};
  if (props.cargoNature.value === 1) {
    theme = {
      background: '#f9f0ff',
      color: '#722ed1',
    };
  } else if (props.cargoNature.value === 2) {
    theme = {
      background: '#e6fffb',
      color: '#13c2c2',
    };
  } else if (props.cargoNature.value === 3) {
    theme = {
      background: '#e6f7ff',
      color: '#1890ff',
    };
  }
  return (
    <ThemeProvider theme={theme}>
      <CargoNatureTag>{props.cargoNature.name}</CargoNatureTag>
    </ThemeProvider>
  );
}
