import { Alert, Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';

import { Button } from '../../components';
import { CheckList } from '../../components/common/CheckList';
import { Document } from '../../types';
import { BaseActionModalProps } from './staymentActions';

type ReleaseOperationModalProps = BaseActionModalProps & {
  onRelease: () => void;
};
const releaseOperationConsents = [
  {
    checked: true,
    title: 'Adm. portuária - Meio Ambiente',
    name: 'port_admin_environment',
  },
];
export function ReleaseOperationModal(props: ReleaseOperationModalProps) {
  const {
    setIsVisible,
    onRelease,
    stopover,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
  } = props;
  const [consents, setConsents] = useState<Document[]>([]);
  const [inspections, setInspections] = useState([
    { checked: true, title: 'Anvisa' }, // TODO: pegar os valores dos campos de inspeção dos dados da atracação
    { checked: true, title: 'Inspeção da capitania' }, // TODO: pegar os valores dos campos de inspeção dos dados da atracação
  ]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    if (currentDocking?.operation_summary?.inspection) {
      setInspections([
        {
          checked: true,
          title:
            currentDocking?.operation_summary?.inspection === 'ANVISA'
              ? 'Anvisa'
              : 'Capitania',
        },
      ]);
    } else {
      setInspections([]);
    }
  }, [currentDocking]);

  useEffect(() => {
    const stopoverDocumentsConsents: Document[] = [];
    releaseOperationConsents.forEach((documentConsent) => {
      const stopoverDocument = stopover?.documents?.find(
        (stopoverDocument) => stopoverDocument.name === documentConsent.name
      );
      if (stopoverDocument) {
        stopoverDocumentsConsents.push(stopoverDocument);
      }
    });
    setConsents(stopoverDocumentsConsents);
  }, [stopover]);

  useEffect(() => {
    if (
      !consents.find((consent) => !consent.checked) &&
      !inspections.find((inspection) => !inspection.checked)
    ) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [consents, inspections, stopover]);

  async function releaseOperation() {
    const release = isAllChecked ? 'RELEASED' : 'RELEASED_WITH_PENDING';
    if (updateDocking) {
      updateDocking(
        {
          operation_summary: {
            operation_release: release,
          },
          id: currentDocking?.id,
        },
        onRelease
      );
    }
  }
  return (
    <>
      {isAllChecked && <b>Todos os requisitos foram preenchidos</b>}
      {!isAllChecked && (
        <b>Todos os requisitos abaixo devem estar preenchidos:</b>
      )}
      <Row style={{ marginTop: '10px' }}>
        <Col span={12}>
          <CheckList title="Inspeções:" items={inspections} />
        </Col>
        <Col span={12}>
          <CheckList
            title="Anuências:"
            items={consents}
            labelField="label"
            checkedField="checked"
          />
        </Col>
      </Row>
      <Alert
        message="Após liberar início de operação o status da embarcação será alterado para “Operação”."
        type="info"
      />
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={() => releaseOperation()}
          loading={isLoadingUpdateDocking}
        >
          {isAllChecked && 'Liberar'}
          {!isAllChecked && 'Liberar mesmo assim'}
        </Button>
      </Space>
    </>
  );
}
