import {
  ArrowLeftOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Switch } from 'antd';
import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import { Button, Tabs, TabPane } from '../../index';
import { GenericDrawerHeaderContainer } from './styles';

type GenericDrawerHeader = {
  title: ReactNode;
  saveButton?: ReactNode;
  style?: CSSProperties;
  showBackButton?: boolean;
  showBackButtonName?: boolean;
  onBack?: () => void;
  onDelete?: () => void;
  formName?: string;
  disabledSaveButton?: boolean;
  isLoadingSave?: boolean;
  isLoadingDelete?: boolean;
  tabs?: TabItemType[];
  onChangeActiveTab?: (activeTab: string | null) => void;
  onChangeSwitch?: (checked: boolean) => void;
  openCollapsePanels?: string | string[];
  allCollapsePanels?: string | string[];
  buttons?: () => ReactNode;
  deleteButtonType?:
    | 'link'
    | 'text'
    | 'default'
    | 'dashed'
    | 'ghost'
    | 'primary';
  saveButtonTitle?: string;
  deleteButtonTitle?: string;
};
export type TabItemType = {
  tabTitle: ReactNode;
  tabKey: string;
  tabContent?: ReactNode;
};
export function GenericDrawerHeader(props: GenericDrawerHeader) {
  const {
    title,
    showBackButton,
    showBackButtonName,
    onBack,
    style,
    onDelete,
    formName,
    disabledSaveButton,
    isLoadingSave,
    isLoadingDelete,
    tabs,
    onChangeActiveTab,
    onChangeSwitch,
    openCollapsePanels,
    allCollapsePanels,
    saveButton,
    buttons,
    deleteButtonType,
    saveButtonTitle,
    deleteButtonTitle,
  } = props;
  return (
    <GenericDrawerHeaderContainer
      style={
        tabs
          ? {
              ...style,
              flexDirection: 'column',
              alignItems: 'flex-start',
              paddingBottom: '0px',
            }
          : style
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {showBackButton && (
            <Button
              type="text"
              icon={
                <ArrowLeftOutlined style={{ color: 'var(--green-sea_dark)' }} />
              }
              onClick={() => (onBack ? onBack() : null)}
              style={{ marginRight: '10px', borderRadius: '25px' }}
              disabled={isLoadingSave || isLoadingDelete}
            >
              {showBackButtonName && 'Voltar'}
            </Button>
          )}
          {title}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            alignItems: 'end',
          }}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            {onDelete && (
              <Button
                type={deleteButtonType}
                size="large"
                danger
                icon={<DeleteOutlined />}
                onClick={(e) => onDelete()}
                disabled={isLoadingSave}
                loading={isLoadingDelete}
              >
                {deleteButtonTitle || 'Excluir'}
              </Button>
            )}
            {buttons && buttons()}
            {formName ? (
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  form={formName}
                  key={formName}
                  icon={<CheckOutlined />}
                  disabled={disabledSaveButton || isLoadingDelete}
                  loading={isLoadingSave}
                >
                  {saveButtonTitle || 'Salvar'}
                </Button>
              </div>
            ) : saveButton ? (
              <div>{saveButton}</div>
            ) : null}
          </div>
          {onChangeSwitch && (
            <div className="expand">
              <Switch
                onChange={onChangeSwitch}
                checked={
                  (openCollapsePanels || []).length ===
                  (allCollapsePanels || []).length
                }
              />
              <span className="switchLabel">Expandir tudo</span>
            </div>
          )}
        </div>
      </div>
      {tabs && (
        <div className="tabs-container">
          <Tabs
            size="middle"
            onChange={onChangeActiveTab}
            defaultActiveKey={tabs[0]?.tabKey}
            destroyInactiveTabPane={false}
          >
            {tabs.map((tab) => {
              return (
                <TabPane tab={tab.tabTitle} key={tab.tabKey} forceRender>
                  {tab.tabContent}
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      )}
    </GenericDrawerHeaderContainer>
  );
}
