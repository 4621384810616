import { message } from 'antd';
import { useEffect, useState } from 'react';

import { Form, ModalBlockNavigation } from '../../../../../components';
import { usePatchStopoverMutation } from '../../../../../services/stopoverApi';
import {
  Stopover as StopoverType,
  Vessel as VesselType,
} from '../../../../../types';
import {
  formatStopoverToForm,
  formatStopoverToSave,
} from '../../../../../utils/formatters';
import { validateHasUnallowedMaxDraught } from '../../../../../utils/validators';
import { DockingFormHeader } from '../../../../docking/dockingFormHeader/DockingFormHeader';
import { FormItemsVessel } from '../../../stopoverForm/formItemsVessel';

type VesselStopoverEditFormProps = {
  selectedStopover?: StopoverType;
  setSelectedStopover?: (value: StopoverType) => void;
  onFinishSubmit: () => void;
  onBack: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  setIsVisibleForm: (value: boolean) => void;
  blockNavigate: boolean;
  visible: boolean;
};

export function VesselStopoverEditForm(props: VesselStopoverEditFormProps) {
  const {
    selectedStopover,
    setSelectedStopover,
    onFinishSubmit,
    onBack,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    setIsVisibleForm,
    blockNavigate,
    visible,
  } = props;
  const [form] = Form.useForm();

  const [selectedVessel, setSelectedVessel] = useState<VesselType>(
    selectedStopover?.vessel || ({} as VesselType)
  );

  const [
    patchStopover,
    { isLoading: isLoadingPatchStopover, isError: isErrorPatchStopover },
  ] = usePatchStopoverMutation();

  useEffect(() => {
    setSelectedVessel(selectedStopover?.vessel || ({} as VesselType));
  }, [visible]);

  useEffect(() => {
    if (isErrorPatchStopover) {
      const messageText = 'Erro ao salvar alterações.';

      return message.error(messageText);
    }
  }, [isErrorPatchStopover]);

  // submit form after validation
  async function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        values.vessel = { id: selectedStopover?.vessel?.id };
        values.dockings = selectedStopover?.dockings;
        values.id = selectedStopover?.id;
        values.vessel = selectedVessel;
        const update = {
          ...values,
          ...formatStopoverToSave(values),
        };

        delete update[''];
        delete update.key;
        if (
          selectedStopover?.dockings &&
          selectedStopover?.dockings?.length > 0 &&
          selectedStopover.dockings[0].docking_place
        ) {
          if (
            !validateHasUnallowedMaxDraught(update, [
              selectedStopover.dockings[0].docking_place,
            ])
          )
            return;
        }
        const updatedStopover = await patchStopover({
          id: update.id,
          vessel: update.vessel,
        });
        if ('data' in updatedStopover && setSelectedStopover) {
          setSelectedStopover(formatStopoverToForm(updatedStopover.data));
        }
      })
      .finally(() => {
        onFinishSubmit();
        setBlockNavigate(false);
        setIsFormChanged(false);
      });
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      setIsVisibleForm(false);
    }
  }

  function afterCloseModal() {
    form.resetFields();
    setSelectedVessel({} as VesselType);
  }

  function onSelectVessel(vessel: any) {
    setSelectedVessel(vessel);
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name="vessel_stopover_edit_form"
      id="vessel_stopover_edit_form"
      onFinish={handleFormSubmit}
      initialValues={selectedStopover}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <DockingFormHeader
        formName="vessel_stopover_edit_form"
        isLoading={isLoadingPatchStopover}
        onBack={onClose}
        editFormTitle="Dados da escala"
        code={selectedStopover?.code}
        saveButtonTitle="Salvar"
      />
      <ModalBlockNavigation
        name="vessel_stopover_edit_form"
        key="vessel_stopover_edit_form"
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <FormItemsVessel
        form={form}
        selectedStopover={selectedStopover}
        selectedVessel={selectedVessel}
        readOnly={false}
        onSelectVessel={onSelectVessel}
        style={{ padding: '24px 16px' }}
        visible={visible}
      />
    </Form>
  );
}
