import { NotificationType } from '../../../types';
import { ContainerNotificationItem } from './styles';

type NotificationItemProps = {
  notification: NotificationType;
  keyGroup: string;
  handleNotificationRead: (
    keyGroup: string,
    id: number,
    notificationIsRead: boolean
  ) => void;
};

export default function NotificationItem(props: NotificationItemProps) {
  const { notification, keyGroup, handleNotificationRead } = props;
  return (
    <ContainerNotificationItem
      onClick={() =>
        handleNotificationRead(keyGroup, notification.id, notification.read)
      }
      isRead={notification.read}
    >
      <span className="createdAt">{notification.formatted_created_at}</span>
      <strong className="title">{notification.title}</strong>
      <div className="subtitle">
        <span style={{ paddingRight: '20px' }}>{notification.vessel_name}</span>
        <span>{notification.stopover_code}</span>
      </div>
      <span style={{ fontSize: '12px' }}>{notification.message}</span>
      {notification.expected_berthing && (
        <span style={{ fontSize: '12px' }}>
          Previsão atracação: {notification.expected_berthing}.
        </span>
      )}
    </ContainerNotificationItem>
  );
}
