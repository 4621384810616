import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Row, Tooltip, message } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import {
  StopoverCancelationData,
  Button,
  FormHeader,
  ModalBlockNavigation,
  ModalUpdateStopoverStatus,
  DocumentsConsentsData,
} from '../../../components';
// import { useGetEtaQuery } from '../../../services/etaApi';
import {} from '../../../components/common/documentsConsentsData/DocumentsConsentsData';
import { useCollapsePanels } from '../../../hooks';
import { usePatchDockingMutation } from '../../../services/dockingApi';
import { useGetBerthsQuery } from '../../../services/dockingPlacesApi';
import {
  useGetStopoverDockingChecklistQuery,
  useCancelMutation,
  useReactivateMutation,
  usePatchStopoverMutation,
} from '../../../services/stopoverApi';
import {
  DockingChecklist,
  Stopover as StopoverType,
  Vessel as VesselType,
} from '../../../types';
import {
  createDateStringPtBr,
  isNullOrUndefined,
  returnDaysForToday,
} from '../../../utils/utils';
import { canEditStopover, getAllowedBerth } from '../../../utils/validators';
import { DockingsData } from '../../docking/dockingsData/DockingsData';
import { ModalLiberateMooring } from './modalLiberateMooring';
import { ModalRegisterArrival } from './modalRegisterArrival';
import { StopoverData } from './stopoverData/StopoverData';
import { VesselData } from './vesselData/VesselData';

type StopoverDetailsProps = {
  selectedStopover: StopoverType;
  hasEdited: boolean;
  blockNavigate: boolean;
  name: string;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  setHasEdited: (hasEdited: boolean) => void;
  setSelectedStopover: (stopover: StopoverType) => void;
  onCloseDrawer?: () => void;
  hideActionButtons?: boolean;
  hideHeader?: boolean;
  hideCollapseAll?: boolean;
  showBackButton?: boolean;
};

export function StopoverDetails(props: StopoverDetailsProps) {
  const {
    selectedStopover,
    hasEdited,
    blockNavigate,
    name,
    setBlockNavigate,
    setHasEdited,
    setSelectedStopover,
    onCloseDrawer,
    hideActionButtons,
    hideHeader,
    hideCollapseAll,
    showBackButton,
  } = props;
  const collapsePanels = [
    'vessel',
    'stopover',
    'operation',
    'documentsConsents',
    'dockings',
  ];

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const [dockingChecklist, setDockingChecklist] =
    useState<DockingChecklist>(Object);

  const [showLiberateModal, setShowLiberateModal] = useState<boolean>(false);
  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [allowedBerth, setAllowedBerth] = useState([]);

  const [
    cancelStopover,
    { isSuccess: isSuccessCancelStopover, isError: isErrorCancelStopover },
  ] = useCancelMutation();

  const [reactivateStopover, { isError: isErrorReactivateStopover }] =
    useReactivateMutation();

  const Eta = {
    last_updated: selectedStopover.updated_arrival_ais,
    eta: selectedStopover.expected_arrival_ais,
  };

  const [
    patchStopover,
    {
      isLoading: isLoadingPatchStopover,
      isSuccess: isSuccessPatchStopover,
      isError: isErrorPatchStopover,
    },
  ] = usePatchStopoverMutation();

  const [patchDocking, { isLoading: isLoadingPatchDocking }] =
    usePatchDockingMutation();

  // get stopover docking checklist hook
  const { data: checklist, isLoading: isLoadingChecklist } =
    useGetStopoverDockingChecklistQuery(selectedStopover?.id || null, {
      skip: isEmpty(selectedStopover),
    });

  const { data: dockingPlacesData } = useGetBerthsQuery({});

  useEffect(() => {
    if (checklist) setDockingChecklist(checklist);
  }, [checklist]);

  useEffect(() => {
    function setBlockNavigateAndHasEditedFalse() {
      if (setBlockNavigate) {
        setBlockNavigate(false);
      }
      if (setHasEdited) {
        setHasEdited(false);
      }
    }

    if (isSuccessCancelStopover) {
      setBlockNavigateAndHasEditedFalse();
      closeModal();
    }
  }, [isSuccessCancelStopover]);

  useEffect(() => {
    setAllowedBerth(
      getAllowedBerth(props.selectedStopover?.vessel, dockingPlacesData)
    );
  }, [dockingPlacesData, props.selectedStopover]);

  useEffect(() => {
    if (isSuccessPatchStopover) {
      message.success('Alterações salvas com sucesso!');
    }

    if (
      isErrorCancelStopover ||
      isErrorReactivateStopover ||
      isErrorPatchStopover
    ) {
      message.error('Erro ao salvar alterações.');
    }
  }, [
    isSuccessPatchStopover,
    isErrorCancelStopover,
    isErrorReactivateStopover,
    isErrorPatchStopover,
  ]);

  function closeModal() {
    if (setHasEdited) {
      setHasEdited(false);
    }
  }

  useBeforeunload((event) => {
    if (hasEdited && blockNavigate) {
      event.preventDefault();
    }
  });

  function docsPanelHeader(title: string) {
    const allRequiredItems = selectedStopover?.documents?.length;
    const allCheckedDocs = selectedStopover?.documents?.filter(
      (doc: any) => doc.checked === true
    ).length;
    return (
      <div className="header-title">
        {title}
        {selectedStopover && (
          <span className="docs-count">
            ({allCheckedDocs}/{allRequiredItems})
          </span>
        )}
        {headerDate()}
      </div>
    );
  }

  function headerDate() {
    const dates = selectedStopover
      ? {
          updatedAt: selectedStopover.updated_at,
          createdAt: selectedStopover.created_at,
        }
      : null;

    if (!isEmpty(selectedStopover)) {
      return (
        <div className="header-description">
          {dates?.updatedAt
            ? `Atualizado em: ${moment(dates?.updatedAt).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : dates?.createdAt
            ? `Escala cadastrada em: ${moment(dates?.createdAt).format(
                'DD/MM/YYYY HH:mm'
              )}`
            : null}
        </div>
      );
    }
    return null;
  }

  function isDockingReleased() {
    return selectedStopover.docking_release !== 'PENDING';
  }

  function formActions() {
    return selectedStopover ? (
      <>
        <Button
          disabled={
            !canEditStopover(selectedStopover?.current_status) ||
            isDockingReleased()
          }
          type="primary"
          size="small"
          onClick={() => !isDockingReleased() && setShowLiberateModal(true)}
        >
          {isDockingReleased() ? 'Liberado' : 'Liberar'}
        </Button>
        <ModalLiberateMooring
          showModal={showLiberateModal}
          setShowModal={setShowLiberateModal}
          setHasEdited={setHasEdited}
          checklist={dockingChecklist}
          stopover={selectedStopover}
          setStopover={setSelectedStopover}
          patchStopover={patchStopover}
          isLoadingPatchStopover={isLoadingPatchStopover}
          isLoadingChecklist={isLoadingChecklist}
        />
        <Button
          disabled={!canEditStopover(selectedStopover?.current_status)}
          type="primary"
          size="small"
          onClick={() => setShowRegisterModal(true)}
        >
          Registrar chegada
        </Button>
        <ModalRegisterArrival
          showModal={showRegisterModal}
          setHasEdited={setHasEdited}
          setShowModal={setShowRegisterModal}
          stopover={selectedStopover}
          updateStopover={patchStopover}
          updateDocking={patchDocking}
          isLoading={isLoadingPatchStopover || isLoadingPatchDocking}
          setStopover={setSelectedStopover}
          selectedStopover={selectedStopover}
        />
        <Button danger size="small" onClick={() => setShowCancelModal(true)}>
          {isNullOrUndefined(selectedStopover?.cancelation_date)
            ? 'Cancelar programação'
            : 'Reativar programação'}
        </Button>
        <ModalUpdateStopoverStatus
          showModal={showCancelModal}
          setShowModal={setShowCancelModal}
          vesselName={selectedStopover.vessel?.name || ''}
          stopoverId={selectedStopover.id}
          statusToChange={
            isNullOrUndefined(selectedStopover?.cancelation_date)
              ? 'CANCELED'
              : 'WAITING'
          }
          cancelStopoverConfirm={cancelStopoverConfirm}
          reactivateStopoverConfirm={reactivateStopoverConfirm}
          title="programação da escala"
        />
      </>
    ) : null;
  }

  function subHeaderTitle() {
    let subHeaderText = `Última previsão de chegada: ${createDateStringPtBr(
      selectedStopover.expected_arrival
    )}${returnDaysForToday(selectedStopover.expected_arrival || null)}`;
    if (selectedStopover.cancelation_date) {
      subHeaderText = `Cancelada em: ${createDateStringPtBr(
        selectedStopover.cancelation_date
      )}`;
    } else if (
      selectedStopover?.dockings &&
      selectedStopover?.dockings[0]?.actual_time_of_arrival
    ) {
      subHeaderText = `Chegada em: ${createDateStringPtBr(
        selectedStopover.dockings[0].actual_time_of_arrival
      )}`;
    }

    return <strong style={{ paddingRight: '10px' }}>{subHeaderText}</strong>;
  }

  function headerTitle() {
    return (
      <Row>
        <Col span={24}>
          {!isEmpty(selectedStopover) && (
            <span className="stopoverCode">{selectedStopover.code}</span>
          )}
          <h1>
            {isEmpty(selectedStopover)
              ? 'Nova escala'
              : selectedStopover.vessel?.name || ''}
          </h1>
          {!isEmpty(selectedStopover) && (
            <div
              className="stopoverCode"
              style={hideActionButtons ? {} : { marginBottom: '1em' }}
            >
              {subHeaderTitle()}
              {!selectedStopover.cancelation_date && (
                <Tooltip
                  placement="bottom"
                  overlayStyle={{ maxWidth: '480px' }}
                  title={
                    <div>
                      <p>Previsões de chegada ETA:</p>
                      <div>
                        <span>
                          Agente de navegação:{' '}
                          <strong>
                            {' '}
                            {createDateStringPtBr(
                              selectedStopover.expected_arrival
                            )}
                          </strong>
                        </span>
                        <br />
                        <span style={{ fontSize: '12px' }}>
                          Atualizado em:{' '}
                          <strong>
                            {' '}
                            {createDateStringPtBr(
                              selectedStopover.updated_arrival
                            ) || 'Não Atualizado'}
                          </strong>
                        </span>
                      </div>
                      <br />
                      <div>
                        <span>
                          Marine Traffic:
                          {!Eta?.eta ? (
                            <strong> Não Fornecida</strong>
                          ) : (
                            <strong>
                              {' '}
                              {createDateStringPtBr(String(Eta.eta))}
                            </strong>
                          )}
                        </span>
                        <br />
                        {Eta?.eta && (
                          <span style={{ fontSize: '12px' }}>
                            Atualizado em:{' '}
                            <strong>
                              {' '}
                              {createDateStringPtBr(String(Eta.last_updated))}
                            </strong>
                          </span>
                        )}
                      </div>
                    </div>
                  }
                >
                  <InfoCircleFilled style={{ color: 'var(--neutral_light)' }} />
                </Tooltip>
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  }

  async function cancelStopoverConfirm(id: number, motivation: string) {
    const updatedStopover = await cancelStopover({ id, motivation });
    if ('data' in updatedStopover && setSelectedStopover) {
      setSelectedStopover(updatedStopover.data);
    }
  }

  async function reactivateStopoverConfirm(id: number, motivation: string) {
    const updatedStopover = await reactivateStopover({ id, motivation });
    if ('data' in updatedStopover && setSelectedStopover) {
      setSelectedStopover(updatedStopover.data);
    }
  }

  return (
    <>
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
      />

      {hideHeader ? null : (
        <FormHeader
          selectedStopover={selectedStopover}
          openCollapsePanels={openCollapsePanels}
          buttons={() => formActions()}
          onChangeSwitch={(checked: boolean) => onChangeSwitch(checked)}
          name={name}
          headerTitle={headerTitle()}
          hideEdit
          hideActionButtons={hideActionButtons}
          hideCollapseAll={hideCollapseAll}
          showBackButton={showBackButton}
          onCloseDrawer={onCloseDrawer}
        />
      )}
      {selectedStopover && selectedStopover.cancelation_date && (
        <StopoverCancelationData selectedStopover={selectedStopover} />
      )}
      <DocumentsConsentsData
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
        selectedStopover={selectedStopover}
      />
      <VesselData
        selectedVessel={selectedStopover.vessel || ({} as VesselType)}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
      />
      <StopoverData
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        activeKey={openCollapsePanels}
        onOpenPanel={onOpenPanel}
        screen="STOPOVER"
      />
      <DockingsData
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        allowedBerth={allowedBerth}
        onOpenPanel={onOpenPanel}
        activeKey={openCollapsePanels}
        screen="STOPOVER"
      />
    </>
  );
}
