import { Select } from 'antd';
import styled from 'styled-components';

export const StopoverRow = styled.div`
  width: 100%;
  border-radius: 5px;
  margin: 12px 12px 12px 0px;
  font-size: 12px;

  & .header {
    display: flex;
    align-items: center;
    width: 100%;
  }

  & .header .title {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    margin-left: 10px;
  }

  & .header .flag {
    height: 20px;
    background: var(--neutral_lighter);
  }

  & .header .imo {
    color: var(--neutral_medium);
    font-size: 14px;
    margin-left: 10px;
  }
`;
export const CustomCollapse = styled.div`
  & .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

export const DocumentCardWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
`;
export const DocumentCardContainer = styled.div`
  width: 50%;
  flex: 1 0 40%;
  & .document-card {
    border: 1px solid var(--neutral_lighter);
    border-radius: 4px;
    padding: 8px 16px;
`;

export const ResumeBackground = styled.div`
  background: #ebf2f7;
  padding: 16px;
  margin: -16px;
  margin-bottom: 16px;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;

export const ResumeSection = styled.div`
  background: #fff;
  padding: 16px 16px 0 16px;
  border-radius: 4px;
  max-height: 250px;
  overflow-y: auto;
`;

export const CustomTagSelect = styled(Select)<{ $tagStyle: any }>`
  & .ant-select-selector {
    min-width: 230px !important;
    border-radius: 4px;
    background-color: ${({ $tagStyle }) =>
      `${$tagStyle.backgroundColor} !important`};
    color: ${({ $tagStyle }) => `${$tagStyle.color} !important`};
  }
  & .ant-select-selector:hover,
  .ant-select-selector:focus-within {
    border: transparent !important;
  }
`;
