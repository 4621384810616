export function translateDockLineType(type: string | undefined) {
  switch (type) {
    case 'SYNTHETIC':
      return 'Sintético';
    case 'STEEL':
      return 'Aço';
    case 'NYLON':
      return 'Nylon';
    default:
      return null;
  }
}
