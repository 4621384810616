import { ColumnsType } from 'antd/lib/table';

import {
  capitalizeFirstLetter,
  formatNumberToLocale,
} from '../../../../../utils/utils';

export function solidBulkStorageSummaryTableColumns(): ColumnsType<
  Record<string, any>
> {
  return [
    {
      title: 'Armazenamento',
      key: 'storage_name',
      dataIndex: 'storage_name',
      render: (value: any) => {
        return capitalizeFirstLetter(value);
      },
      width: '85%',
    },
    {
      title: 'Peso',
      key: 'total_weight',
      dataIndex: 'total_weight',
      render(value) {
        return <>{formatNumberToLocale(value)} ton</>;
      },
      align: 'right' as const,
      width: '15%',
    },
  ];
}
