import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import { useEffect, useState } from 'react';

import { Button } from '../../components';
import { useGetPermissionsGroupQuery } from '../../services/groupsApi';
import { GroupPermissions } from '../../types';
import { ReportsTable } from '../reports/styles';
import { columns } from './columns';
import { formatPermissionGroupToForm } from './formatPermissionGroupToForm';
import { PermissionGroupDrawer } from './permissionGroupDrawer';

export function PermissionGroups() {
  const { data: permissionGroups, refetch: refetchPermissionGroups } =
    useGetPermissionsGroupQuery();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [allPermissionsGroup, setAllPermissionsGroup] = useState<
    GroupPermissions[]
  >([]);
  const [filteredPermissionsGroup, setFilteredPermissionsGroup] = useState<
    GroupPermissions[] | null
  >(null);
  const [selectedGroup, setSelectedGroup] = useState<null | Record<
    string,
    unknown
  >>(null);

  function onCloseDrawer() {
    setIsDrawerVisible(false);
    setSelectedGroup(null);
  }

  function onSavePermissionGroup(savedPermissionGroup: any) {
    const hasGroup = allPermissionsGroup.find(
      (permission) => permission.id === savedPermissionGroup.id
    );
    if (hasGroup) {
      setAllPermissionsGroup(
        allPermissionsGroup?.map((item) =>
          item.id === savedPermissionGroup.id
            ? { ...item, ...savedPermissionGroup }
            : item
        )
      );
    } else {
      setAllPermissionsGroup(
        allPermissionsGroup.concat([savedPermissionGroup])
      );
    }
  }

  function onDeletePermissionGroup() {
    refetchPermissionGroups();
  }

  function onSelectGroup(group: Record<string, unknown>) {
    setIsDrawerVisible(true);
    setSelectedGroup(formatPermissionGroupToForm(group));
  }
  useEffect(() => {
    if (permissionGroups?.results) {
      setAllPermissionsGroup(permissionGroups?.results);
    }
  }, [permissionGroups?.results]);

  function onSearchGroup(search: string) {
    if (
      allPermissionsGroup &&
      allPermissionsGroup.length > 0 &&
      search !== ''
    ) {
      setFilteredPermissionsGroup(
        allPermissionsGroup.filter((permissionGroup: GroupPermissions) => {
          return permissionGroup?.name
            ?.toLowerCase()
            .includes(search.toLowerCase());
        })
      );
    } else {
      setFilteredPermissionsGroup(null);
    }
  }
  return (
    <>
      <PermissionGroupDrawer
        visible={isDrawerVisible}
        width={650}
        onCloseDrawer={onCloseDrawer}
        selectedGroup={selectedGroup}
        onSavePermissionGroup={onSavePermissionGroup}
        onDeletePermissionGroup={onDeletePermissionGroup}
      />

      <Row style={{ marginTop: '5px', marginBottom: '15px' }} align="middle">
        <Col span={10}>
          <Input
            size="large"
            placeholder="Pesquisar grupo"
            type="text"
            prefix={
              <SearchOutlined style={{ color: '#09d4ab', fontSize: '20px' }} />
            }
            allowClear
            onChange={(evt) => onSearchGroup(evt.target.value)}
          />
        </Col>
        <Col span={14} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            size="large"
            onClick={() => setIsDrawerVisible(true)}
          >
            Novo grupo
          </Button>
        </Col>
      </Row>
      <ReportsTable
        columns={columns(onSelectGroup)}
        dataSource={filteredPermissionsGroup || allPermissionsGroup}
        pagination={false}
        scroll={{ y: 610, scrollToFirstRowOnChange: false }}
      />
    </>
  );
}
