import { Descriptions, FormatItem } from '../../../../../../components';
import { OperationManifestType } from '../../../../../../types';
import {
  capitalizeFirstLetter,
  formatNumberToLocale,
} from '../../../../../../utils/utils';

const { Item } = Descriptions;

type ResumeMovementManifestProps = {
  operationManifest: OperationManifestType;
};

export function ResumeMovementManifest(props: ResumeMovementManifestProps) {
  const { operationManifest } = props;
  return (
    <>
      <Descriptions column={3}>
        <Item label="Porto de carregamento">
          <FormatItem>
            {operationManifest.loading_port?.country_name
              ? `${capitalizeFirstLetter(
                  operationManifest.loading_port?.country_name
                )}, ${capitalizeFirstLetter(
                  operationManifest.loading_port?.name
                )}`
              : null}
          </FormatItem>
        </Item>
        <Item label="Porto de descarregamento">
          <FormatItem>
            {operationManifest.unloading_port?.country_name
              ? `${capitalizeFirstLetter(
                  operationManifest.unloading_port?.country_name
                )}, ${capitalizeFirstLetter(
                  operationManifest.unloading_port?.name
                )}`
              : null}
          </FormatItem>
        </Item>
        <Item label="Sentido do produto">
          <FormatItem>
            {operationManifest.operation_direction === 'UNLOADING'
              ? 'Descarregamento'
              : 'Carregamento'}
          </FormatItem>
        </Item>
      </Descriptions>
      <Descriptions column={3}>
        <Item label="Tipo de operação da carga">
          <FormatItem>
            {operationManifest.operation_type?.description}
          </FormatItem>
        </Item>
        <Item label="Total manifestado">
          <FormatItem>
            {formatNumberToLocale(operationManifest.manifest_total)} ton
          </FormatItem>
        </Item>
        <Item label="Total movimentado">
          <FormatItem>
            {formatNumberToLocale(operationManifest.total_handled)} ton
          </FormatItem>
        </Item>
      </Descriptions>
    </>
  );
}
