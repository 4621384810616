import { Dispatch, SetStateAction } from 'react';

import { Drawer } from '../../../components';
import { Stopover as StopoverType } from '../../../types';
import { AgentAreaForm } from './agentAreaForm';

type AgentAreaDrawerProps = {
  visibleDrawer: boolean;
  closeDrawer: () => void;
  hasEdited: boolean;
  setHasEdited: (value: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate: (value: boolean) => void;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  close: () => void;
  setFilteredStopoveres: Dispatch<SetStateAction<StopoverType[]>>;
};

export function AgentAreaDrawer(props: AgentAreaDrawerProps) {
  const {
    visibleDrawer,
    closeDrawer,
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
    setSelectedStopover,
    close,
    setFilteredStopoveres,
  } = props;
  return (
    <Drawer
      visible={visibleDrawer}
      placement="right"
      width={820}
      closable={false}
      destroyOnClose
      onClose={() => {
        closeDrawer();
      }}
    >
      <AgentAreaForm
        selectedStopover={{} as StopoverType}
        hasEdited={hasEdited}
        setHasEdited={(val: boolean) => setHasEdited(val)}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        onCloseDrawer={() => close()}
        setSelectedStopover={setSelectedStopover}
        setFilteredStopoveres={setFilteredStopoveres}
        visibleForm={visibleDrawer}
      />
    </Drawer>
  );
}
