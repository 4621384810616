import { Alert as ANTDAlert } from 'antd';
import styled from 'styled-components';

export const Alert = styled(ANTDAlert)`
  &.ant-alert-warning {
    background-color: var(--warning-lightest) !important;
    border: 1px solid var(--warning-lighter) !important;

    & .ant-alert-icon {
      color: var(--warning-medium);
    }
  }
`;
