import { FileExclamationOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useState } from 'react';

import { StatusTag } from '../../components';
import { TOSStopoverCard } from '../../components/common/stopoverCard/styles';
import { VehicleInvoice } from '../../typesWarehousing';

type DueIsNullCardRendererProps = {
  invoices?: VehicleInvoice[];
  totalInvoices?: number;
  onSelectCard: () => void;
  isSelectedFixedCard: boolean;
};
export function DueIsNullCardRenderer(props: DueIsNullCardRendererProps) {
  const { invoices, onSelectCard, isSelectedFixedCard, totalInvoices } = props;
  // const [isSelected, setisSelected] = useState(false);
  // function onClickCard() {
  //   onSelectCard();
  // }
  return (
    <TOSStopoverCard
      className={isSelectedFixedCard ? 'active' : ''}
      onClick={() => onSelectCard()}
    >
      <Row className="header">
        <Col xl={1} xxl={1}>
          <FileExclamationOutlined
            style={{
              color: 'var(--green-sea_dark)',
              fontSize: '16px',
              height: '20px',
            }}
          />
        </Col>
        <Col xl={23} xxl={23}>
          <span
            className="title"
            style={{
              textTransform: 'none',
            }}
          >
            Chassis não associados
          </span>
        </Col>
      </Row>
      <Row>
        <Col xl={1} xxl={1} />
        <Col xl={23} xxl={23}>
          <StatusTag style={{ marginLeft: '10px' }}>
            <span className="blue">Exportação: {totalInvoices} veiculos</span>
          </StatusTag>
        </Col>
      </Row>
    </TOSStopoverCard>
  );
}
