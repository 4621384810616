import styled, { css } from 'styled-components';

type TOSStopoverCardProps = {
  disabled?: boolean;
};

export const TOSStopoverCard = styled.div<TOSStopoverCardProps>`
  width: 100%;
  background: var(--neutral_lightest);
  border-radius: 5px;
  padding: 14px 17px 13px 20px;
  margin-inline-end: 10px;
  transition: 200ms ease-in-out;
  cursor: pointer;
  font-size: 12px;

  &.active {
    background: #fff;
    border-left: 10px solid var(--green-sea_medium);
    padding-left: 11px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    position: relative;
  }

  & .body,
  & .footer {
    /* margin-top: 10px; */
  }

  & .body .liberationStatus {
    & span {
      display: inline-block;
      padding: 1px 8px 1px;
      line-height: 15px;
      border-radius: 3px;
      border: 1px solid;
      font-size: 12px;
      &.liberate {
        background: #f6ffed;
        color: #52c41a;
        border-color: #b7eb8f;
      }
      &.pending {
        background: #fff7e6;
        color: #fa8c16;
        border-color: #ffd591;
      }
      &.unauthorized {
        background: #fff1f0;
        color: #f5222d;
        border-color: #ffa39e;
      }
    }
  }

  &:hover {
    background: #fff;
  }

  & .header {
    display: flex;
    align-items: center;
    width: 100%;
  }

  & .header .title {
    font-weight: bold;
    font-size: 17px;
    text-transform: capitalize;
    margin-left: 10px;
  }

  & .header .flag {
    height: 20px;
    background: var(--neutral_lighter);
  }

  & .footer .stopoverStatus,
  & .footer .berth {
    color: var(--neutral_medium);
    font-weight: bold;
    text-transform: uppercase;
  }
  & .footer .berth {
    color: #000;
    display: block;
    text-align: right;
  }

  & .code {
    font-family: 'Consolas';
    color: var(--neutral_medium);
    font-size: 12px;
    text-align: right;
    display: inline-block;
    flex: auto;
  }
  & .imo,
  & .duv,
  & .trip,
  & .eta,
  & .berth {
    display: block;
    font-family: 'Consolas';
    font-size: 12px;
    text-align: right;
    & .val {
      color: var(--neutral_medium);
      display: inline-block;
    }
  }
  & .eta,
  & .berth {
    text-align: left;
  }

  & .shipping_agency {
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    margin-left: 5px;
    & .missing_agency {
      color: var(--neutral_medium);
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
      cursor: wait;
    `}
`;

type StopoverCardCollapsiblePanelProps = {
  isVisible?: boolean;
};

export const StopoverCardCollapsiblePanel = styled.div<StopoverCardCollapsiblePanelProps>`
  background: var(--neutral_lightest);
  padding: 4px;
  padding-top: 9px;
  transition: 300ms;
  position: relative;
  width: 100%;
  height: ${(p) => (p.isVisible ? 'auto' : '0px')};
  opacity: ${(p) => (p.isVisible ? '1' : '0')};
  position: relative;
  margin-top: -5px;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 0 0 5px 5px;
  margin-inline-end: 10px;
`;

export const CardCollapsiblePanelItem = styled.div`
  padding: 5px 12px;
  font-size: 14px;
  color: var(--neutral_medium);
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    color: rgba(0, 0, 0, 0.85);
  }
`;

export const StopoverCardWrapper = styled.div`
  /* display: flex;
  flex-direction: column; */
  overflow-y: auto;
`;
