import styled from 'styled-components';

export const EmptyOperationPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--neutral_light);
`;

export const ProgressLegend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-block-end: 20px;

  .legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    span {
      font-size: 12px;
    }
  }

  .square {
    height: 10px;
    width: 10px;

    &.green {
      background-color: var(--green-sea_dark);
    }

    &.gray {
      background-color: var(--neutral_lighter);
    }
  }
`;
