import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useMemo } from 'react';

import { Tag } from '../../../../../components';
import { StorageGroupbyProductType } from '../../../../../types';
import { groupOperatorByManifest } from '../formatters';
import { NcmByOperatorTable } from './ncmByOperatorTable/NcmByOperatorTable';

type NcmsGroupbyManifestDataProps = {
  data: StorageGroupbyProductType[];
};

export function NcmsGroupbyManifestData(props: NcmsGroupbyManifestDataProps) {
  const { data } = props;

  const dataGroupbyManifest = useMemo(() => {
    if (data !== undefined) return groupOperatorByManifest(data);
  }, [data]);

  function getHeader(manifestCode: number, operationDirection: string) {
    return (
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'baseline',
          marginBlockEnd: '8px',
        }}
      >
        <span style={{ fontWeight: '600', color: 'var(--ocean_dark)' }}>
          Manifesto
        </span>
        <span style={{ fontFamily: 'Consolas', color: 'var(--ocean_dark)' }}>
          {manifestCode}
        </span>
        <Tag
          icon={
            operationDirection === 'UNLOADING' ? (
              <ArrowDownOutlined />
            ) : (
              <ArrowUpOutlined />
            )
          }
        >
          {operationDirection === 'UNLOADING' ? 'Descarga' : 'Carga'}
        </Tag>
      </div>
    );
  }

  return (
    <div>
      {dataGroupbyManifest?.map((groupbyManifest) => (
        <div key={groupbyManifest.id}>
          {getHeader(groupbyManifest.id, groupbyManifest.name)}
          <NcmByOperatorTable dataSource={groupbyManifest.items} />
        </div>
      ))}
    </div>
  );
}
