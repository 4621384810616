import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from 'antd';
import { useEffect, useState } from 'react';

import {
  ModalUpdateStopoverStatus,
  StopoverCancelationData,
  StopoverReactivateData,
  StaymentLog,
} from '../../components';
import { Collapse } from '../../components/antd/Collapse';
import { Drawer } from '../../components/antd/Drawer';
import { ModalBlockNavigation } from '../../components/common/ModalBlockNavigation';
import { useCollapsePanels } from '../../hooks';
import { useGetBerthsQuery } from '../../services/dockingPlacesApi';
import {
  useCancelMutation,
  useReactivateMutation,
} from '../../services/stopoverApi';
import { Stopover } from '../../types';
import { canEditStopover, getAllowedBerth } from '../../utils/validators';
import { DockingsData } from '../docking/dockingsData/DockingsData';
import { StopoverForm } from '../stopover/stopoverForm/StopoverForm';
import { BreakdownOccurrenceDrawer } from './breakdownOccurrenceDrawer/BreakdownOccurrenceDrawer';
import { FormItemsStayment } from './formItemsStayment/FormItemsStayment';
import { StaymentActions } from './staymentActions';
import { StaymentFormHeader } from './staymentFormHeader';

type StaymentDetailsProps = {
  selectedStopover: Stopover;
  setSelectedStopover: (stopover: Stopover) => void;
  name: string;
  setHasEdited: (showModal: boolean) => void;
  setBlockNavigate: (blockNavigate: boolean) => void;
  blockNavigate: boolean;
};

const collapsePanels = ['stayment_data', 'dockings', 'stayment_teste'];

export function StaymentDetails(props: StaymentDetailsProps) {
  const { onOpenPanel, openCollapsePanels } = useCollapsePanels(collapsePanels);

  const [allowedBerth, setAllowedBerth] = useState([]);

  const [isVisibleStopoverDrawer, setIsVisibleStopoverDrawer] = useState(false);
  const [
    isVisibleBreakdownOccurrenceDrawer,
    setIsVisibleBreakdownOccurrenceDrawer,
  ] = useState(false);

  const { data: berthData } = useGetBerthsQuery({});

  useEffect(
    () =>
      setAllowedBerth(
        getAllowedBerth(props.selectedStopover?.vessel, berthData)
      ),
    [berthData, props.selectedStopover]
  );

  function closeDrawer() {
    setIsVisibleStopoverDrawer(false);
  }

  return (
    <>
      <Drawer
        visible={isVisibleStopoverDrawer}
        placement="right"
        width={820}
        closable={false}
        destroyOnClose
      >
        <StopoverForm
          name="create_stopover"
          selectedStopover={props.selectedStopover}
          hasEdited={false}
          blockNavigate={false}
          hideEdit
          hideActionButtons
          hideCollapseAll={false}
          showBackButton
          onCloseDrawer={() => closeDrawer()}
        />
      </Drawer>
      <BreakdownOccurrenceDrawer
        isVisible={isVisibleBreakdownOccurrenceDrawer}
        setIsVisible={setIsVisibleBreakdownOccurrenceDrawer}
        selectedStopover={props.selectedStopover}
      />
      <ModalBlockNavigation
        name={props.name}
        key={props.name}
        setHasEdited={props.setHasEdited}
        showModal={props.blockNavigate}
        setBlockNavigate={props.setBlockNavigate}
      />
      <StaymentFormHeader
        selectedStopover={props.selectedStopover}
        buttons={() => (
          <StaymentActions
            openStopoverDrawer={setIsVisibleStopoverDrawer}
            stopover={props.selectedStopover}
            openBreakdownOccurrenceDrawer={
              setIsVisibleBreakdownOccurrenceDrawer
            }
            selectedStopover={props.selectedStopover}
            setSelectedStopover={props.setSelectedStopover}
          />
        )}
      />
      {props.selectedStopover && props.selectedStopover.cancelation_date && (
        <StopoverCancelationData selectedStopover={props.selectedStopover} />
      )}

      <StaymentLog selectedStopover={props.selectedStopover.id} />

      <FormItemsStayment
        selectedStopover={props.selectedStopover}
        setSelectedStopover={props.setSelectedStopover}
        onOpenPanel={onOpenPanel}
        activeKey={openCollapsePanels}
      />
      <Collapse
        activeKey={openCollapsePanels}
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        <DockingsData
          selectedStopover={props.selectedStopover}
          setSelectedStopover={props.setSelectedStopover}
          allowedBerth={allowedBerth}
          onOpenPanel={onOpenPanel}
          activeKey={openCollapsePanels}
          screen="STAYMENT"
        />
      </Collapse>
    </>
  );
}
