import { Col } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState } from 'react';

import { FormItem } from '../Form';
import { InputCNPJ } from '../InputCNPJ';

type FormItemCNPJProps = {
  colSpan?: number;
  required?: boolean;
  readOnly?: boolean;
  onChange?: (event: any) => void;
};

export function FormItemCNPJ(props: FormItemCNPJProps) {
  const { colSpan, required, readOnly, onChange } = props;
  const [isEmpty, setIsEmpty] = useState(false);

  function onChangeValue(e: any) {
    setIsEmpty(e.target.value === '__.___.___/____-__');
    if (onChange) {
      onChange(e);
    }
  }

  const getRules = () => {
    const rules: Rule[] = [];

    rules.push({ required, message: 'Campo obrigatório' });
    if (!isEmpty) {
      rules.push({
        pattern:
          /([\d]{2}[.]?[\d]{3}[.]?[\d]{3}[/]?[\d]{4}[-]?[\d]{2})|([\d]{3}[.]?[\d]{3}[.]?[\d]{3}[-]?[\d]{2})/,
        message: 'Informe um CNPJ válido',
      });
    }

    return rules;
  };

  return (
    <Col span={colSpan || 24}>
      <FormItem label="CNPJ" name="cnpj" rules={getRules()}>
        <InputCNPJ onChange={onChangeValue} readOnly={readOnly} />
      </FormItem>
    </Col>
  );
}
