import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import moment from 'moment';

import { Button, SlimTable } from '../../../components';
import { StoppageType } from '../../../types';
import { formatStoppagesDescription } from '../../operation/OperationsData/formatOperationToDescription';
import { ExpandedRowStoppage } from './expandedRowStoppage';
import { stoppagesTableColumns } from './stoppagesTableColumns';

type StoppagesTableProps = {
  stoppages?: StoppageType[];
  onEdit: (index: number) => void;
};

export function StoppagesTable(props: StoppagesTableProps) {
  const { stoppages, onEdit } = props;
  function getTableSummary() {
    const totalDuration = calculateTotalDuration();
    function calculateTotalDuration() {
      let sumDuration = 0;
      const stops = sortStoppages(stoppages);
      let maxEnd: moment.Moment | null = null;

      stops.map((stop) => {
        const start1 = moment(stop.start);
        const end1 = moment(stop.end);
        let min = moment(stop.start);
        let max = moment(stop.end);
        let duration = 0;

        if (maxEnd) {
          min = moment.max(start1, maxEnd);
          max = moment.max(end1, maxEnd);
          duration = moment.duration(max.diff(min)).asHours();
          if (end1 > maxEnd) {
            maxEnd = end1;
          }
        } else {
          min = moment(stop.start);
          maxEnd = moment(stop.end);
          duration = moment.duration(maxEnd.diff(min)).asHours();
        }

        if (duration > 0) {
          sumDuration += duration;
        }
      });

      return sumDuration;
    }
    function formatHoursMin(totalDuration: number) {
      const hours = Math.floor(totalDuration);
      const minutes = Math.round((totalDuration - hours) * 60);
      return `${hours}h:${minutes.toString().padStart(2, '0')}m`;
    }
    return (
      <Table.Summary fixed>
        <Table.Summary.Row key="row_0">
          <Table.Summary.Cell colSpan={6} index={1} key={1} align="right">
            TOTAL DE HORAS PARALISADAS:
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} key={2} align="right">
            {formatHoursMin(totalDuration)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  }
  function sortStoppages(stoppages: StoppageType[] | undefined) {
    if (!stoppages) {
      return [];
    }
    return stoppages.sort((a, b) => ((a.start || 0) > (b.start || 0) ? 1 : -1));
  }
  return (
    <SlimTable
      rowKey="id"
      columns={stoppagesTableColumns(onEdit)}
      dataSource={formatStoppagesDescription(sortStoppages(stoppages))}
      expandable={{
        expandedRowRender: (record: any) => (
          <ExpandedRowStoppage record={record} />
        ),
        expandedRowClassName: () => 'expanded-row-stoppage',
        expandIcon: ({ expanded, onExpand, record }) => {
          return expanded ? (
            <Button
              icon={<UpOutlined style={{ color: 'var(--green-sea_dark)' }} />}
              type="text"
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <Button
              icon={<DownOutlined style={{ color: 'var(--green-sea_dark)' }} />}
              type="text"
              onClick={(e) => onExpand(record, e)}
            />
          );
        },
        expandRowByClick: true,
      }}
      className="read-only"
      pagination={false}
      summary={() => getTableSummary()}
    />
  );
}
