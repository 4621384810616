import { useState } from 'react';

export function useCollapsePanels(collapsePanels: string | string[]) {
  const [openCollapsePanels, setOpenCollapsePanels] = useState<
    string | string[]
  >(collapsePanels);

  function onChangeSwitch(checked: boolean) {
    if (checked) {
      setOpenCollapsePanels(collapsePanels);
    } else {
      setOpenCollapsePanels([]);
    }
  }

  function onOpenPanel(panel: string | string[]) {
    setOpenCollapsePanels(panel);
  }

  return { openCollapsePanels, onChangeSwitch, onOpenPanel };
}
