import { InvoiceToReceiptType } from '../../services/warehousingVehiclesApi';
import { VehicleInvoiceReception } from '../../typesWarehousing';

export function formatInvoicesToRegisterReception(
  vehiclesInvoice: VehicleInvoiceReception[]
): InvoiceToReceiptType[] {
  const invoicesToRegister: InvoiceToReceiptType[] = vehiclesInvoice.map(
    (vehicleInvoice) => {
      if (vehicleInvoice.invoices) {
        return {
          id: vehicleInvoice.invoices[vehicleInvoice.invoices.length - 1].id,
          access_key:
            vehicleInvoice.invoices[vehicleInvoice.invoices.length - 1]
              .access_key,
          vehicle_id: vehicleInvoice.id,
        } as InvoiceToReceiptType;
      }
      return {} as InvoiceToReceiptType;
    }
  );
  return invoicesToRegister;
}
