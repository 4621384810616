import { useEffect, useState } from 'react';

import {
  Collapse,
  Descriptions,
  FormatItem,
  StopoverAgentAreaDescription,
} from '../../../../components';
import { panelHeaderTitle } from '../../../../components/common/utils';
import {
  Stopover as StopoverType,
  StopoverTypeDescription,
  Vessel as VesselType,
} from '../../../../types';
import { formatStopoverToDescription } from '../../../../utils/formatters';
import { VesselDescription } from '../vesselData/vesselDescription';

const { Item } = Descriptions;

type StopoverDescriptionProps = {
  selectedStopover: StopoverType;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  onEdit: (evt: any) => void;
  screen: 'AGENT_AREA' | 'STOPOVER';
};

const { Panel } = Collapse;

export function StopoverDescription(props: StopoverDescriptionProps) {
  const { selectedStopover, activeKey, onOpenPanel, onEdit, screen } = props;
  const [stopoverDescription, setStopoverDescription] =
    useState<StopoverTypeDescription>({} as StopoverTypeDescription);

  useEffect(() => {
    setStopoverDescription(formatStopoverToDescription(selectedStopover));
  }, [selectedStopover]);

  return (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel
        forceRender
        header="Dados da escala"
        key="stopover"
        extra={panelHeaderTitle(selectedStopover.updated_at, onEdit)}
      >
        {screen === 'STOPOVER' && (
          <Descriptions column={3}>
            <Item label="Agência de navegação" span={2}>
              <FormatItem>{stopoverDescription.shipping_agency}</FormatItem>
            </Item>
            <Item label="Nº escala mercante">
              <FormatItem>{stopoverDescription.mercante_scale}</FormatItem>
            </Item>
            <Item label="Armador afretador" span={2}>
              <FormatItem>{stopoverDescription.charterer}</FormatItem>
            </Item>
            <Item label="Nº viagem do armador">
              <FormatItem>{stopoverDescription.shipowner_trip}</FormatItem>
            </Item>
            <Item label="Agência protetora" span={2}>
              <FormatItem>{stopoverDescription.protective_agency}</FormatItem>
            </Item>
            <Item label="Calado de chegada">
              <FormatItem>{stopoverDescription.arrival_draught}</FormatItem>
            </Item>
            <Item label="País do porto de origem">
              <FormatItem>{stopoverDescription.last_port_country}</FormatItem>
            </Item>
            <Item label="Porto de origem" span={2}>
              <FormatItem>{stopoverDescription.last_port}</FormatItem>
            </Item>
            <Item label="País do porto de destino">
              <FormatItem>{stopoverDescription.next_port_country}</FormatItem>
            </Item>
            <Item label="Porto de destino">
              <FormatItem>{stopoverDescription.next_port}</FormatItem>
            </Item>
            <Item label="Tipo de navegação">
              <FormatItem>{stopoverDescription.navigation_type}</FormatItem>
            </Item>
            <Item
              label="Previsão de chegada ETA (agente de navegação)"
              span={2}
            >
              <FormatItem>{stopoverDescription.expected_arrival}</FormatItem>
            </Item>
            <Item label="DUV">
              <FormatItem>{stopoverDescription.duv}</FormatItem>
            </Item>
          </Descriptions>
        )}
        {screen === 'AGENT_AREA' && (
          <>
            <Descriptions column={2}>
              <Item label="Previsão de chegada (ETA)">
                <FormatItem>{stopoverDescription.expected_arrival}</FormatItem>
              </Item>
              <Item label="Calado de chegada">
                <FormatItem>{stopoverDescription.arrival_draught}</FormatItem>
              </Item>
            </Descriptions>
            <VesselDescription
              selectedVessel={selectedStopover.vessel || ({} as VesselType)}
              updatedAt={selectedStopover.updated_at}
              activeKey={activeKey}
              onOpenPanel={onOpenPanel}
              onEdit={onEdit}
              screen={screen}
            />
            <div
              style={{
                border: '1px solid var(--neutral_lighter)',
                borderRadius: '4px',
                padding: '16px',
                marginTop: '16px',
              }}
            >
              <h1 style={{ marginBottom: '10px' }}>
                DUV{' '}
                <span
                  style={{
                    color: 'var(--neutral_medium)',
                    fontWeight: '400',
                    marginLeft: '8px',
                  }}
                >
                  <FormatItem>{stopoverDescription.duv}</FormatItem>
                </span>
              </h1>
              {stopoverDescription.duv && (
                <StopoverAgentAreaDescription
                  selectedStopover={selectedStopover}
                />
              )}
            </div>
          </>
        )}
      </Panel>
    </Collapse>
  );
}
