import { ColumnsType } from 'antd/lib/table';

export function columns(): ColumnsType<Record<string, any>> {
  return [
    {
      title: 'Paralisações gerais',
      dataIndex: 'motive',
      width: '70%',
    },
    {
      title: 'Início',
      dataIndex: 'start',
      width: '15%',
    },
    {
      title: 'Término',
      dataIndex: 'end',
      width: '15%',
    },
  ];
}
