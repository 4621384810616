import {
  Button,
  Descriptions,
  FormatItem,
  Title,
} from '../../../../components';

type PartialTotalResumeDescriptionProps = {
  operationLength: string | null;
  netProduction: string | null;
  grossProduction: string | null;
  operatingHours: string | null;
  nonWorkingHours: string | null;
  handledTotal?: string | null;
  setIsStoppagesVisible: (value: boolean) => void;
  totalStoppages: number;
  isLiquidBulk?: boolean | false;
};

const { Item } = Descriptions;

export function PartialTotalResumeDescription(
  props: PartialTotalResumeDescriptionProps
) {
  const {
    operationLength,
    netProduction,
    grossProduction,
    operatingHours,
    nonWorkingHours,
    handledTotal,
    setIsStoppagesVisible,
    totalStoppages,
    isLiquidBulk,
  } = props;
  return (
    <div className="partial-total">
      <Title>Total parcial</Title>
      <Descriptions>
        <Item label="Duração da operação">
          <FormatItem>{operationLength}</FormatItem>
        </Item>
        <Item label={isLiquidBulk ? 'Vazão líquida' : 'Produtiv. líquida'}>
          <FormatItem>{netProduction}</FormatItem>
        </Item>
        <Item label={isLiquidBulk ? 'Vazão bruta' : 'Produtiv. bruta'}>
          <FormatItem>{grossProduction}</FormatItem>
        </Item>
        <Item label="Horas operacionais">
          <FormatItem>{operatingHours}</FormatItem>
        </Item>
        <Item label="Horas paralisadas">
          <div>
            <FormatItem>{nonWorkingHours}</FormatItem>
            <Button
              style={{ marginTop: '-8px', marginLeft: '-10px' }}
              type="link"
              onClick={() => setIsStoppagesVisible(true)}
              disabled={!(totalStoppages > 0)}
            >
              {totalStoppages === 0
                ? 'Nenhuma paralisação'
                : totalStoppages === 1
                ? '1 paralisação'
                : `(${totalStoppages} Paralisações)`}
            </Button>
          </div>
        </Item>
        {handledTotal && (
          <Item label="Total movimentado">
            <FormatItem>{handledTotal}</FormatItem>
          </Item>
        )}
      </Descriptions>
    </div>
  );
}
