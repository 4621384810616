import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 32px;
  margin-right: 32px;

  .date {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Consolas';
  }
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #1d6181;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #ebf2f7;

  .section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #1d6181;
    margin-bottom: 16px;
  }
`;

export const SectionCard = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 8px;
  height: 100%;

  .key {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    display: flex;
    align-items: center;
    letter-spacing: -0.04em;

    /* suape/neutral/medium */

    color: #5f7b91;
  }

  .value {
    /* suape/numbers/minimal */

    font-family: 'Consolas';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: flex;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on;

    /* suape/black */

    color: rgba(0, 0, 0, 0.85);
    margin-left: 4px;
  }
`;
