import { FilterOutlined } from '@ant-design/icons';
import { message, Popover } from 'antd';
import { debounce, isEmpty } from 'lodash';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';

import { Button, Drawer, SearchInput } from '../../../components';
import { useChangePagination } from '../../../hooks';
import { useGetBollardsQuery } from '../../../services/bollardApi';
import {
  useCreateBreakdownOccurrenceMutation,
  useDeleteBreakdownOccurrenceMutation,
  useGetBreakdownsOccurrencesQuery,
  useUpdateBreakdownOccurrenceMutation,
} from '../../../services/breakdownOccurrenceApi';
import { useGetFendersQuery } from '../../../services/fenderApi';
import {
  BreakdownOccurrencePrimaryType,
  BreakdownOccurrenceToSave,
  BreakdownOccurrenceType,
  DutyType,
  TreeType,
} from '../../../types';
import { formatEquipmentsToForm } from '../../stayment/breakdownOccurrenceDrawer/formatEquipmentsToForm';
import { SectionHeader } from '../styles';
import { BreakdownOccurrenceForm } from './breakdownOccurrenceForm';
import { BreakdownsFilterContent } from './breakdownsFilterContent';
import { BreakdownsTable } from './breakdownsTable';

export type BreakdownFiltersType = {
  [index: string]:
    | BreakdownOccurrencePrimaryType
    | Moment[]
    | DutyType
    | number
    | string
    | null
    | undefined;
  primary_type?: BreakdownOccurrencePrimaryType;
  date?: Moment[];
  breakdown_occurrence_type?: number;
  docking?: number;
  on_duty?: number;
  shipowner?: number;
  vessel?: number;
  duty?: DutyType;
  period_start?: string | null;
  period_end?: string | null;
  imo_or_name?: string;
  page?: number;
  page_size?: number;
};

const breakdownFiltersVerboseName = {
  primary_type: 'Tipo',
  date: 'Data',
  period_start: 'Data inicial',
  period_end: 'Data final',
  breakdown_occurrence_type: 'Tipo de avaria ou ocorrência',
  docking_id: 'Atracação',
  on_duty: 'Plantonista',
  shipowner: 'Armador',
  vessel_id: 'Embarcação',
  imo_or_name: 'Embarcação',
  duty: 'Turno',
} as Record<string, string>;

function getBreakdownFiltersVerboseName(filters: BreakdownFiltersType) {
  const filtersNamesList: string[] = [];
  Object.keys(filters).forEach((filter) => {
    filtersNamesList.push(breakdownFiltersVerboseName[filter]);
  });
  return filtersNamesList;
}

export function BreakdownsSection() {
  const { onChangePagination, queryPage } = useChangePagination();
  const PAGE_SIZE = 20;

  const [
    createBreakdown,
    {
      isSuccess: isSuccessCreateBreakdown,
      isLoading: isLoadingCreateBreakdown,
    },
  ] = useCreateBreakdownOccurrenceMutation();
  const [
    updateBreakdown,
    {
      isSuccess: isSuccessUpdateBreakdown,
      isLoading: isLoadingUpdateBreakdown,
    },
  ] = useUpdateBreakdownOccurrenceMutation();
  const [
    deleteBreakdown,
    {
      isSuccess: isSuccessDeleteBreakdown,
      isLoading: isLoadingDeleteBreakdown,
    },
  ] = useDeleteBreakdownOccurrenceMutation();

  const [breakdownFilters, setBreakdownFilters] =
    useState<BreakdownFiltersType>({
      page: queryPage,
      page_size: PAGE_SIZE,
    } as BreakdownFiltersType);

  useEffect(() => {
    setBreakdownFilters((prev) => ({ ...prev, page: queryPage }));
  }, [queryPage]);

  const { data: breakdowns, isFetching: isFetchingBreakdowns } =
    useGetBreakdownsOccurrencesQuery(breakdownFilters);

  const [SelectedBreakdownOccurrence, setSelectedBreakdownOccurrence] =
    useState<BreakdownOccurrenceType>({} as BreakdownOccurrenceType);

  const [isVisibleFiltersPopover, setIsVisibleFiltersPopover] = useState(false);
  const [isVisibleBreakdownsDrawer, setIsVisibleBreakdownsDrawer] =
    useState(false);

  const [equipments, setEquipments] = useState<TreeType[]>([]);

  const { data: fendersData, isLoading: isLoadingFendersData } =
    useGetFendersQuery();
  const { data: bollardsData, isLoading: isLoadingBollardsData } =
    useGetBollardsQuery({ dockingPlace: '' });
  useEffect(() => {
    if (!isLoadingFendersData && !isLoadingBollardsData) {
      setEquipments(
        formatEquipmentsToForm(fendersData?.results, bollardsData?.results)
      );
    }
  }, [isLoadingFendersData, isLoadingBollardsData]);

  function showFilters() {
    if (!isEmpty(breakdownFilters)) {
      return (
        <div style={{ marginLeft: '10px' }}>
          Filtrado por{' '}
          {getBreakdownFiltersVerboseName(breakdownFilters).map(
            (filterName, index) => {
              return (
                <span key={index}>
                  <b>{filterName}</b>
                  {index === Object.keys(breakdownFilters).length - 1
                    ? '.'
                    : ', '}
                </span>
              );
            }
          )}
        </div>
      );
    }
    return null;
  }

  function onSelectBreakdownOccurrence(
    breakdownOccurrence: BreakdownOccurrenceType
  ) {
    setSelectedBreakdownOccurrence(breakdownOccurrence);
    setIsVisibleBreakdownsDrawer(true);
  }

  useEffect(() => {
    if (isSuccessCreateBreakdown) {
      return message.success('Avaria ou ocorrência criada com sucesso');
    }
    if (isSuccessUpdateBreakdown) {
      return message.success('Avaria ou ocorrência atualizada com sucesso');
    }
    if (isSuccessDeleteBreakdown) {
      return message.success('Avaria ou ocorrência excluída com sucesso');
    }
  }, [
    isSuccessCreateBreakdown,
    isSuccessUpdateBreakdown,
    isSuccessDeleteBreakdown,
  ]);

  function onCreateBreakdown(breakdown: BreakdownOccurrenceToSave) {
    createBreakdown(breakdown).then(() => saveFormCallback());
  }
  function onUpdateBreakdown(breakdown: BreakdownOccurrenceToSave) {
    updateBreakdown(breakdown).then(() => saveFormCallback());
  }
  function onDeleteBreakdown(breakdown: BreakdownOccurrenceType) {
    if (breakdown.id) {
      deleteBreakdown(breakdown.id).then(() => saveFormCallback());
    }
  }
  function saveFormCallback() {
    setIsVisibleBreakdownsDrawer(false);
    setSelectedBreakdownOccurrence({} as BreakdownOccurrenceType);
  }

  function onSearch(value: string) {
    if (value !== '') {
      setBreakdownFilters({ imo_or_name: value });
    } else {
      setBreakdownFilters({ ...breakdownFilters, imo_or_name: '' });
    }
  }
  return (
    <>
      <SectionHeader>
        <Popover
          content={
            <BreakdownsFilterContent
              setBreakdownFilters={setBreakdownFilters}
              setIsVisibleFiltersPopover={setIsVisibleFiltersPopover}
            />
          }
          placement="rightTop"
          arrowPointAtCenter
          visible={isVisibleFiltersPopover}
          trigger="click"
        >
          <SearchInput
            size="large"
            allowClear
            placeholder="Pesquisar embarcação"
            style={{ width: '400px' }}
            suffix={
              <FilterOutlined
                style={{
                  color: isEmpty(breakdownFilters)
                    ? 'var(--neutral_medium)'
                    : 'var(--green-sea_medium)',
                  fontSize: '16px',
                }}
                onClick={() =>
                  setIsVisibleFiltersPopover(!isVisibleFiltersPopover)
                }
              />
            }
            onChange={debounce((evt) => onSearch(evt.target.value), 500)}
          />
        </Popover>
        {showFilters()}
        <Button
          size="large"
          style={{ marginLeft: 'auto' }}
          onClick={() => setIsVisibleBreakdownsDrawer(true)}
        >
          Registrar avarias ou ocorrências
        </Button>
      </SectionHeader>
      <BreakdownsTable
        rowKey="id"
        dataSource={breakdowns?.results || []}
        onChangePagination={onChangePagination}
        total={breakdowns?.count}
        isLoading={isFetchingBreakdowns}
        queryPage={queryPage}
        showSizeChanger={false}
        itemsPerPage={PAGE_SIZE}
        pageSize={PAGE_SIZE}
        onOpenDrawer={onSelectBreakdownOccurrence}
      />
      <Drawer
        visible={isVisibleBreakdownsDrawer}
        closable={false}
        width={640}
        destroyOnClose
      >
        <BreakdownOccurrenceForm
          setIsVisibleForm={setIsVisibleBreakdownsDrawer}
          equipments={equipments}
          breakdownSelected={SelectedBreakdownOccurrence}
          setBreakdownSelected={setSelectedBreakdownOccurrence}
          updateBreakdown={onUpdateBreakdown}
          createBreakdown={onCreateBreakdown}
          deleteBreakdown={onDeleteBreakdown}
          isLoadingSave={isLoadingCreateBreakdown || isLoadingUpdateBreakdown}
          isLoadingDelete={isLoadingDeleteBreakdown}
        />
      </Drawer>
    </>
  );
}
