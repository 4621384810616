import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button } from '../../../components/antd/Button';
import { useChangePagination } from '../../../hooks';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import { CompanyType } from '../../../types';
import { PanelHeader } from '../panelHeader';
import { RegistersDrawer } from '../registersDrawer';
import { RegistersTable } from '../registersTable';
import { columns } from './columns';
import { CompanyForm } from './companyForm';

export function Company() {
  const PAGE_SIZE = 15;
  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();
  const [searchQueryTerm, setSearchQueryTerm] = useState('');

  const {
    data: companyData,
    isFetching: isFetchingCompanyData,
    refetch: refetchCompanyData,
  } = useGetCompaniesQuery({
    page: onChangePagination(),
    name_or_cnpj: searchQueryTerm,
    page_size: PAGE_SIZE,
  });

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyType>(
    {} as CompanyType
  );

  function actionButtons() {
    return (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => setIsFormVisible(true)}
      >
        Nova Empresa
      </Button>
    );
  }
  function onSelectCompany(company: Record<string, any>) {
    setIsFormVisible(true);
    setIsEdit(true);
    setSelectedCompany({
      id: company.id,
      name: company.name,
      phone_number: company.phone_number,
      email: company.email,
      cnpj: company.cnpj,
      company_type: company.company_type,
      address: company.address,
      nature_of_cargo: company.nature_of_cargo?.id || null,
      tin: company.tin,
      activity: company.activity,
    });
  }

  function onCloseForm() {
    setIsFormVisible(false);
    setIsEdit(false);
    setSelectedCompany({} as CompanyType);
  }

  function searchData(value: any) {
    setQueryPage(1);
    setSearchQueryTerm(value);
  }

  return (
    <>
      <RegistersDrawer visible={isFormVisible} width={640}>
        <CompanyForm
          onClose={() => onCloseForm()}
          allCompaniesCount={companyData?.count}
          selectedCompany={selectedCompany}
          refetchCompanyData={refetchCompanyData}
          isEdit={isEdit}
          setQueryPage={setQueryPage}
        />
      </RegistersDrawer>
      <PanelHeader
        panelName="empresa"
        actionButtons={actionButtons()}
        onSearch={searchData}
      />
      <RegistersTable
        rowKey="id"
        columns={columns}
        onSelectItem={onSelectCompany}
        dataSource={companyData?.results || []}
        onChangePagination={onChangePagination}
        total={companyData?.count}
        isLoading={isFetchingCompanyData}
        queryPage={queryPage}
        showSizeChanger={false}
        itemsPerPage={PAGE_SIZE}
      />
    </>
  );
}
