import { EditFilled } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import Flag from 'react-flagpack';

import {
  ErrorBoundary,
  Button,
  OperationTypeIcon,
  Descriptions,
  FormatItem,
} from '../../components';
import {
  BerthMaintenance as BerthMaintenanceType,
  DockingPlace as DockingPlaceType,
} from '../../types';
import { convertProductsToString } from '../../utils/formatters';
import { operationTypes } from '../../utils/lists';
import { translateShipType } from '../../utils/translateShipType';
import { createDateStringPtBr } from '../../utils/utils';
import { ItemType } from './AnchoringWindow';
import { PopoverDockingContent, PopoverMaintenanceContent } from './styles';

const { Item } = Descriptions;

type DockingPopoverProps = {
  item: ItemType;
  openDockingDrawer: (value: boolean) => void;
  openAnchoringDrawer: (value: boolean) => void;
  openMaintenanceDrawer: (
    maintenance: BerthMaintenanceType,
    dockingPlace: DockingPlaceType
  ) => void;
};

export function DockingPopoverContent(props: DockingPopoverProps) {
  const {
    item,
    openDockingDrawer,
    openAnchoringDrawer,
    openMaintenanceDrawer,
  } = props;
  const isBerth = item.docking_place.place_type === 'BERTH';
  if (item.stopover && item.index !== undefined && item.index >= 0) {
    return (
      <PopoverDockingContent>
        <Row align="middle">
          <Col span={2} style={{ alignItems: 'center', display: 'flex' }}>
            <ErrorBoundary replace="">
              <Flag code={item.stopover.vessel?.flag} size="m" />
            </ErrorBoundary>
          </Col>
          <Col span={15}>
            <span className="title">
              {item.stopover.vessel?.name.toLocaleLowerCase()}
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={2} />
          <Col span={22}>
            <span className="name">
              AGÊNCIA DE NAVEGAÇÃO:{' '}
              <span className="value">
                {item.stopover.shipping_agency?.name}
              </span>
            </span>
          </Col>
        </Row>
        <Row>
          <Col span={2} />
          <Col span={22}>
            <span>
              {isBerth ? 'ATRACAÇÃO' : 'FUNDEIO'}{' '}
              {item.index >= 0 ? item.index + 1 : null} DA ESCALA{' '}
              {item.stopover.code}
            </span>
          </Col>
        </Row>
        {item.products[0]?.operation_type && (
          <Row>
            <Col span={2} />
            <Col span={22}>
              <div>
                <OperationTypeIcon
                  operation={item.products[0]?.operation_type}
                />
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {
                    operationTypes.find(
                      (type) => type.value === item.products[0]?.operation_type
                    )?.name
                  }
                </span>
              </div>
            </Col>
          </Row>
        )}
        <div className="body">
          {isBerth && (
            <Row>
              <Col span={2} />
              <Tooltip title={convertProductsToString(item.products)}>
                <Col span={22}>
                  <span className="name">MERCADORIA: </span>
                  <span className="value">
                    {convertProductsToString(item.products, 2)}
                  </span>
                </Col>
              </Tooltip>
            </Row>
          )}
          <Row>
            <Col span={2} />
            <Col span={22}>
              <span className="name">ESCALA INICIADA EM: </span>
              <span className="value">
                {item.stopover.actual_time_of_arrival
                  ? moment(item.stopover.actual_time_of_arrival).format(
                      'DD/MM/YYYY HH:mm'
                    )
                  : '-'}
              </span>
            </Col>
          </Row>
          {isBerth && (
            <Row>
              <Col span={2} />
              <Col span={22}>
                <span className="name">NÚMERO DA ATRACAÇÃO: </span>
                <span className="value">{item.code}</span>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={2} />
            <Col span={11}>
              <Tooltip title="Data e hora estimada para chegada">
                <span className="name">ETA: </span>
                <span className="value">
                  {moment(item.stopover.expected_arrival).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                </span>
              </Tooltip>
            </Col>
            <Col span={11}>
              <Tooltip title="Data e hora estimada para atracação">
                <span className="name">ETB: </span>
                <span className="value">
                  {moment(item.expected_start).format('DD/MM/YYYY HH:mm')}
                </span>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col span={2} />
            <Col span={22}>
              <Tooltip title="Data e hora estimada para desatracação">
                <span className="name">ETS: </span>
                <span className="value">
                  {moment(item.expected_finish).format('DD/MM/YYYY HH:mm')}
                </span>
              </Tooltip>
            </Col>
          </Row>
        </div>
        <div className="bottom">
          <Row align="bottom">
            <Col span={2} />
            <Col
              span={22}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                {item.stopover.vessel?.ship_type && (
                  <span className="shipType">
                    {translateShipType(
                      item.stopover.vessel?.ship_type
                    ).toLocaleLowerCase()}
                  </span>
                )}
                {item.docking_alongside !== 'NO' && item.docking_alongside ? (
                  <span className="dockingType">a contrabordo</span>
                ) : item.docking_in_line !== 'NO' && item.docking_in_line ? (
                  <span className="dockingType">em linha</span>
                ) : null}
              </div>
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  isBerth ? openDockingDrawer(true) : openAnchoringDrawer(true)
                }
                icon={<EditFilled />}
              >
                Editar dados
              </Button>
            </Col>
          </Row>
        </div>
      </PopoverDockingContent>
    );
  }

  // POPOVER para manutenção
  return (
    <PopoverMaintenanceContent>
      <div className="div-title">
        <span style={{ fontWeight: '700' }}>Manutenção programada</span>
        <Button
          icon={<EditFilled />}
          onClick={() =>
            openMaintenanceDrawer(
              item.maintenance || ({} as BerthMaintenanceType),
              item.docking_place as DockingPlaceType
            )
          }
        >
          Editar
        </Button>
      </div>
      <Descriptions column={3}>
        <Item label="Descrição" span={3}>
          <FormatItem>{item.subtitle}</FormatItem>
        </Item>
        <Item label="Empresa resposável" span={2}>
          <FormatItem>{item.companies}</FormatItem>
        </Item>
        <Item label="Berço">
          <FormatItem>{item.docking_place.name}</FormatItem>
        </Item>
        <Item label="Previsão" span={3}>
          <FormatItem>
            <div>
              <FormatItem>
                {createDateStringPtBr(item.expected_start)}
              </FormatItem>
              <span> até </span>
              <FormatItem>
                {createDateStringPtBr(item.expected_finish)}
              </FormatItem>
            </div>
          </FormatItem>
        </Item>
      </Descriptions>
    </PopoverMaintenanceContent>
  );
}
