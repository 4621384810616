import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '../../antd/Header';
import { Notification } from '../notification/Notification';
import { Container } from './styles';

type ScreenLayoutProps = {
  title: string;
  subtitle?: string;
  children: ReactNode;
  extra?: ReactNode;
};

export function ScreenLayout(props: ScreenLayoutProps) {
  const { title, subtitle, children, extra } = props;
  const navigate = useNavigate();
  return (
    <Container>
      <Header
        onBack={() => navigate(-1)}
        title={title}
        subTitle={subtitle}
        extra={
          <>
            {extra}
            <Notification />
          </>
        }
      />
      {children}
    </Container>
  );
}
