import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

import {
  Alert,
  FormHeader,
  ModalBlockNavigation,
  OperationResumeDescription,
} from '../../components';
import { Stopover as StopoverType } from '../../types';
import { createDateStringPtBr } from '../../utils/utils';
import { formatOperationToDescription } from '../operation/OperationsData/formatOperationToDescription';
import { ConsigneeData } from './consigneeData/ConsigneeData';

type StorageDetailsProps = {
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  blockNavigate: boolean;
  name: string;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  setHasEdited: (hasEdited: boolean) => void;
  companyName?: string;
};

export function StorageDetails(props: StorageDetailsProps) {
  const {
    selectedStopover,
    blockNavigate,
    name,
    setBlockNavigate,
    setHasEdited,
    companyName,
    setSelectedStopover,
  } = props;

  const pendingItem = selectedStopover.consignees?.some((consignee) =>
    consignee.items.some((item) => item.status === 'PENDING')
  );

  function getHeaderTitle() {
    return (
      <div>
        <div>
          <span
            style={{ color: 'var(--neutral_light)', fontFamily: 'Consolas' }}
          >
            {selectedStopover.stopover?.code} {'>'} Atracação{' '}
            {selectedStopover.docking?.code}
          </span>
        </div>
        <div>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
            {selectedStopover.operation?.products}
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setShowModal={setBlockNavigate}
      />
      <FormHeader
        selectedStopover={selectedStopover}
        title={getHeaderTitle()}
        openCollapsePanels={[]}
        name={name}
        headerTitle="operação"
        hideEdit
        hideCollapseAll
        hideActionButtons
      />
      {pendingItem && (
        <Alert
          type="warning"
          message={
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <span>Novo armazenamento</span>
              <span style={{ fontSize: '12px' }}>
                A <b>{selectedStopover.stopover?.shipping_agency_name}</b>{' '}
                atribuiu à <b>{companyName}</b> as cargas abaixo. Por favor,
                confirme o armazenamento
                {selectedStopover.docking?.expected_berthing
                  ? ` até ${createDateStringPtBr(
                      moment(
                        selectedStopover.docking?.expected_berthing
                      ).subtract(2, 'days')
                    )}, 48 horas antes da atracação prevista.`
                  : '.'}
              </span>
            </div>
          }
          showIcon
          icon={<InfoCircleOutlined />}
        />
      )}
      <OperationResumeDescription
        selectedOperation={{
          ...formatOperationToDescription(selectedStopover.operation),
          nature_of_cargo:
            selectedStopover.operation.nature_of_cargo_name || '',
          product_group: selectedStopover.operation.product_group_name || '',
        }}
        shippingAgencyName={selectedStopover.stopover?.shipping_agency_name}
        vesselFlag={selectedStopover.stopover?.vessel_flag}
        vesselName={selectedStopover.stopover?.vessel_name}
        dockingCode={selectedStopover.docking?.code}
        dockingPlace={selectedStopover.docking?.docking_place_name}
        expectedBerthingDates={{
          expectedBerthing:
            createDateStringPtBr(selectedStopover.docking?.expected_berthing) ||
            '',
          expectedUnberthing:
            createDateStringPtBr(
              selectedStopover.docking?.expected_unberthing
            ) || '',
        }}
      />
      <ConsigneeData
        consignees={selectedStopover.consignees || []}
        setSelectedStopover={setSelectedStopover}
      />
    </>
  );
}
