import { User } from '../../types';

export function formatUser(user: any): User {
  const newUser: User = {
    first_name: user.user.first_name,
    last_name: user.user.last_name,
    email: user.user.email,
    status: user.status || 'PENDING',
    phone: user.phone || null,
    username: user.user.email,
    id: user.id || null,
    company: user.company.id,
    role: user.role.id,
    name: `${user.user.first_name} ${user.user.last_name}`,
  };

  return newUser;
}

export function formatDisableUser(
  usersArray: Array<User>
): Array<Partial<User>> {
  const usersToDisableArray: Array<Partial<User>> = [];
  usersArray.forEach((user: User) => {
    usersToDisableArray.push({ id: user.id, status: 'DEACTIVATED' });
  });
  return usersToDisableArray;
}
