import { StopOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import { PumpingIcon } from '../../assets/icons/pumpingIcon';
import { OperationTypeIcon } from '../../components';
import {
  BerthMaintenance as BerthMaintenanceType,
  DockingPlace as DockingPlaceType,
} from '../../types';
import { convertProductsToString } from '../../utils/formatters';
import { berthRowHeight, itemRowHeight } from '../../utils/utils';
import { ItemType } from './AnchoringWindow';
import { DockingPopoverContent } from './dockingPopoverContent';

const listStyles = [
  { id: 'MOORED', name: ' moored-docking ' },
  { id: 'EXPECTED', name: ' expected-docking ' },
  { id: 'UNMOORED', name: ' unmoored-docking ' },
  { id: 'CARRIED_OUT', name: ' carried-out-docking ' },
  { id: 'DELAYED_UNBERTHING', name: ' delayed-undocking ' },
  { id: 'DELAYED', name: ' delayed-docking ' },
];

function getDockingWindowStatusStyle(
  dockingWindowStatus: string,
  isMaintenance = false,
  isPump = false
): string {
  if (isMaintenance) {
    return ' maintenance ';
  }
  if (isPump) {
    return ' pump ';
  }

  return (
    listStyles.find((style) => style.id === dockingWindowStatus)?.name || ''
  );
}

export function itemRenderer(
  itemProps: any,
  itemsToRender: ItemType[],
  setIsDockingDrawerVisible: (isVisible: boolean) => void,
  setIsAnchoringDrawerVisible: (isVisible: boolean) => void,
  onSelectBerthMaintenance: (
    maintenance: BerthMaintenanceType,
    dockingPlace: DockingPlaceType
  ) => void
) {
  const { left: leftResizeProps, right: rightResizeProps } =
    itemProps.getResizeProps();
  const dockingPlaceItems = itemsToRender.filter(
    (item) =>
      !['PUMPING_ORIGIN', 'PUMPING_DESTINATION', 'MAINTENANCE'].includes(
        item.type
      ) && item.group === itemProps.item.group
  );

  const isMaintenance = itemProps.item.type === 'MAINTENANCE';
  const isPump = ['PUMPING_ORIGIN', 'PUMPING_DESTINATION'].includes(
    itemProps.item.type
  );

  let berthVesselPosition = dockingPlaceItems.findIndex(
    (item) => item.id === itemProps.item.id
  );
  // caso seja paralisação sempre será no inicio da linha do berço
  let maintainceItemHeight = 0;
  if (isMaintenance || isPump) {
    berthVesselPosition = 0;
    const paralisationBerth = itemProps.item.group;
    const heightParalisedItemsDockingPlace =
      itemsToRender.filter(
        (item) =>
          !['PUMPING_ORIGIN', 'PUMPING_DESTINATION', 'MAINTENANCE'].includes(
            item.type
          ) && item.group === paralisationBerth
      ).length || 1;
    maintainceItemHeight = itemRowHeight * heightParalisedItemsDockingPlace;
  }

  const itemTop =
    parseInt(itemProps.getItemProps(itemProps.item.itemProps).style.top, 10) +
    berthVesselPosition * berthRowHeight;

  const topItem = itemProps.itemContext.dragging
    ? itemProps.getItemProps(itemProps.item.itemProps).style.top
    : itemTop;

  const heightItem = `${
    isMaintenance || isPump ? maintainceItemHeight : itemRowHeight
  }px`;

  const maxWidthContentName = `${
    parseInt(itemProps.getItemProps(itemProps.item.itemProps).style.width, 10) -
    6
  }px`;

  return (
    <div
      {...itemProps.getItemProps(itemProps.item.itemProps)}
      style={Object.assign(
        itemProps.getItemProps(itemProps.item.itemProps).style,
        { top: topItem, height: heightItem }
      )}
      className={`rct-item ${
        itemProps.itemContext.selected && ' rct-item-selected '
      }${getDockingWindowStatusStyle(
        itemProps.item.docking_window_status,
        isMaintenance,
        isPump
      )}`}
    >
      {itemProps.itemContext.useResizeHandle ? (
        <div {...leftResizeProps} />
      ) : (
        ''
      )}

      <div
        className="rct-item-content"
        style={{
          height: heightItem,
          maxHeight: `${itemProps.itemContext.dimensions.height}`,
        }}
      >
        <Popover
          overlayStyle={{ width: '500px', zIndex: 99 }}
          content={
            <DockingPopoverContent
              item={itemProps.item}
              openDockingDrawer={setIsDockingDrawerVisible}
              openAnchoringDrawer={setIsAnchoringDrawerVisible}
              openMaintenanceDrawer={onSelectBerthMaintenance}
            />
          }
          placement="bottomLeft"
          trigger={
            ['PUMPING_ORIGIN', 'PUMPING_DESTINATION'].includes(
              itemProps.item.type
            )
              ? ''
              : 'click'
          }
        >
          <div
            className="rct-item-content-name"
            style={{ maxWidth: maxWidthContentName }}
          >
            {itemProps.item.stopover?.vessel?.name ? (
              <span className="rct-item-content-vessel-name">
                {itemProps.item.stopover?.vessel?.name}
              </span>
            ) : itemProps.item.type === 'MAINTENANCE' ? (
              <div className="rct-item-content-maintenance">
                <div className="name">
                  <StopOutlined />
                  <span>{itemProps.item.name}</span>
                </div>
                <span className="subtitle">{itemProps.item.subtitle}</span>
              </div>
            ) : (
              <div className="rct-item-content-pumping">
                <div className="name">
                  <PumpingIcon />
                  <span>{itemProps.item.name}</span>
                </div>
                <span className="subtitle">{itemProps.item.subtitle}</span>
              </div>
            )}

            <div className="rct-item-content-product">
              {convertProductsToString(itemProps.item.products, 1)}
            </div>
          </div>
        </Popover>
        <div className="rct-item-operation-icon">
          <OperationTypeIcon
            operation={itemProps.item.products[0]?.operation_type}
          />
        </div>
      </div>

      {itemProps.itemContext.useResizeHandle ? (
        <div {...rightResizeProps} />
      ) : (
        ''
      )}
    </div>
  );
}
