import { Row, Skeleton } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  Descriptions,
  Form,
  FormatItem,
  FormItemSelect,
  Modal,
  Title,
} from '../../../../../components';
import { TableFormList } from '../../../../../components/common/tableFormList/TableFormList';
import { useGetCargoTypeQuery } from '../../../../../services/cargoApi';
import { useGetCompaniesQuery } from '../../../../../services/companyApi';
import { useGetMerchantsCeGroupbyNcmItemsQuery } from '../../../../../services/ncmItemApi';
import { usePatchAllOperatorManifestItemsMutation } from '../../../../../services/stopoverApi';
import { NCMItemType } from '../../../../../types';
import { removeSpecialCharacters } from '../../../../../utils/utils';
import { SelectAllItemsOperatorModalContainer } from './styles';

const { Item } = Descriptions;

function ItemLoading(props: { index: number }) {
  const { index } = props;

  const [searchStorageName, setSearchStorageName] = useState('');
  const { data: storagesData, isLoading: isLoadingStoragesData } =
    useGetCompaniesQuery({
      company_type: 'STORAGE',
      name_or_cnpj: searchStorageName,
      page_size: 15,
    });

  const [searchOperatorName, setSearchOperatorName] = useState('');
  const { data: operatorsData, isLoading: isLoadingOperatorsData } =
    useGetCompaniesQuery({
      company_type: 'OPERATOR',
      name_or_cnpj: searchOperatorName,
      page_size: 15,
    });

  const { data: cargoTypesData } = useGetCargoTypeQuery();

  function onSearchStorage(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchStorageName(search || evt);
  }

  function onSearchOperator(evt: string) {
    const search = removeSpecialCharacters(evt, /[./-]/g);
    setSearchOperatorName(search || evt);
  }

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      <FormItemSelect
        colSpan={8}
        name={[index, 'cargo_type', 'name']}
        dataList={cargoTypesData?.results
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))}
        required
        showSearch
        disabled
      />
      <FormItemSelect
        colSpan={8}
        name={[index, 'storage_terminal', 'id']}
        dataList={storagesData?.results}
        required
        showSearch
        isLoading={isLoadingStoragesData}
        onSearch={debounce(onSearchStorage, 300)}
      />
      <FormItemSelect
        colSpan={8}
        name={[index, 'operator', 'id']}
        dataList={operatorsData?.results
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))}
        required
        showSearch
        isLoading={isLoadingOperatorsData}
        onSearch={debounce(onSearchOperator, 300)}
      />
    </Row>
  );
}

type SelectAllItemsOperatorModalProps = {
  isVisible: boolean;
  handleCancelModal: () => void;
  countItems: number;
  selectedManifest: string;
  stopoverId: number;
  selectedOpDirectionManifest: string;
};

export function SelectAllItemsOperatorModal(
  props: SelectAllItemsOperatorModalProps
) {
  const {
    isVisible,
    handleCancelModal,
    countItems,
    selectedManifest,
    stopoverId,
    selectedOpDirectionManifest,
  } = props;
  const [form] = Form.useForm();

  const { data: ncmItemsData, isFetching: isFetchingNcmItemsData } =
    useGetMerchantsCeGroupbyNcmItemsQuery(
      {
        page_size: countItems,
        manifest_code: selectedManifest,
        stopover_id: stopoverId,
      },
      { skip: !isVisible }
    );

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({
        loadings: [
          {
            cargo_type: { name: 'VEÍCULOS' },
            storage: null,
            operator: null,
          },
        ],
      });
    }
  }, [isVisible]);

  const [
    patchAllOperatorManifestItems,
    { isLoading: isLoadingSelectAllOperatorManifest },
  ] = usePatchAllOperatorManifestItemsMutation();

  const allCeMerchant = useMemo(() => {
    const ceMerchantCodeList: string[] = [];
    ncmItemsData?.results.forEach((item) => {
      if (!ceMerchantCodeList.includes(item.code)) {
        ceMerchantCodeList.push(item.code);
      }
    });
    return ceMerchantCodeList.join(', ');
  }, [ncmItemsData?.results]);

  const allConsignees = useMemo(() => {
    const consigneeList: string[] = [];
    ncmItemsData?.results.forEach((item) => {
      const consignee = `${item.operator_company.cnpj} - ${item.operator_company.name}`;
      if (!consigneeList.includes(consignee)) {
        consigneeList.push(consignee);
      }
    });
    return consigneeList.join(', ');
  }, [ncmItemsData?.results]);

  const tableFormListColumns = [
    { title: 'TIPO DE MERCADORIA', colSpan: 8 },
    { title: 'LOCAL DE ARMAZENAMENTO', colSpan: 8 },
    { title: 'OPERADOR', colSpan: 8 },
  ];

  async function handleFormSubmit() {
    form.validateFields().then(async (values: { loadings: NCMItemType[] }) => {
      const item = values.loadings[0];

      const obj = {
        id: stopoverId,
        manifest_code: selectedManifest,
        operator: item.operator?.id || 0,
        storage_terminal: item.storage_terminal?.id || 0,
        cargo_type: item.cargo_type?.name || '',
      };
      const response = await patchAllOperatorManifestItems(obj);
      if ('data' in response) {
        handleCancelModalSelectOperator();
      }
    });
  }

  function handleCancelModalSelectOperator() {
    form.resetFields();
    handleCancelModal();
  }

  return (
    <Modal
      className="TOSPrimaryModal"
      width={1080}
      title="Selecionar operador(es) para todas as cargas do manifesto"
      visible={isVisible}
      destroyOnClose
      onCancel={handleCancelModalSelectOperator}
      cancelText="Voltar"
      cancelButtonProps={{ disabled: isLoadingSelectAllOperatorManifest }}
      okText="Concluir"
      onOk={handleFormSubmit}
      okButtonProps={{
        style: { marginRight: '16px' },
        loading: isLoadingSelectAllOperatorManifest,
      }}
      paddingBody="0"
    >
      <SelectAllItemsOperatorModalContainer>
        <div className="ncm-description">
          <Title>CARGA</Title>
          <Descriptions column={2}>
            <Item label="Manifesto">
              <FormatItem>{selectedManifest}</FormatItem>
            </Item>
            <Item label="Unidades totais">
              <FormatItem>{countItems}</FormatItem>
            </Item>
            <Item label="CE Mercante(s)" span={2}>
              {isFetchingNcmItemsData ? (
                <Skeleton.Input
                  active
                  style={{ width: '600px', maxWidth: '100%' }}
                />
              ) : (
                <FormatItem>{allCeMerchant}</FormatItem>
              )}
            </Item>
            <Item
              label={
                selectedOpDirectionManifest === 'LOADING'
                  ? 'Embarcador'
                  : 'Consignatário'
              }
              span={2}
            >
              {isFetchingNcmItemsData ? (
                <Skeleton.Input
                  active
                  style={{ width: '600px', maxWidth: '100%' }}
                />
              ) : (
                <FormatItem>{allConsignees}</FormatItem>
              )}
            </Item>
          </Descriptions>
        </div>
        <div className="select-operator-info">
          <span style={{ lineHeight: '28px' }}>
            Indique o local de armazenamento e o operador.
          </span>
          <Form form={form} name="selectLoading" onFinish={handleFormSubmit}>
            <TableFormList
              formListName="loadings"
              FormListItemRow={ItemLoading}
              columns={tableFormListColumns}
              style={{ marginTop: '0px', marginBottom: '20px' }}
            />
          </Form>
        </div>
      </SelectAllItemsOperatorModalContainer>
    </Modal>
  );
}
