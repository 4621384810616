import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { Input, List } from '../../../components';
import { useLazyGroupCSMByVehiclesImportingQuery } from '../../../services/cargosStorageMerchantApi';
import {
  GroupByVehiclesImportingType,
  OperationalOperationType,
} from '../../../types';
import { ContainerAside } from '../../operation/OperationsData/operationDescriptionsSections/generalCargo/containers/containersByCraneModal/containersByCraneData/styles';
import { VehiclesMovimentationDetails } from './vehiclesMovimentationDetails';

type VehiclesImportMovimentationProps = {
  operation?: OperationalOperationType;
};
export function VehiclesImportMovimentation(
  props: VehiclesImportMovimentationProps
) {
  const { operation } = props;

  const [getGroupCSMByVehiclesImporting, { data: CSMByVehiclesImport }] =
    useLazyGroupCSMByVehiclesImportingQuery();

  const [selectedCEMerchant, setselectedCEMerchant] =
    useState<GroupByVehiclesImportingType | null>(null);

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (operation && operation.id) {
      getGroupCSMByVehiclesImporting({
        operation_id: operation.id,
        merchant_ce_code_or_chassis: search,
      });
    }
  }, [operation, search]);

  return (
    <ContainerAside
      style={{ height: '500px', minHeight: '500px', marginTop: '8px' }}
    >
      <aside>
        <div
          style={{
            borderRight: '1px solid var(--neutral_lighter)',
            height: '500px',
          }}
        >
          <Input
            size="middle"
            placeholder="Pesquisar CE"
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            prefix={
              <SearchOutlined style={{ color: '#09d4ab', fontSize: '16px' }} />
            }
            allowClear
            style={{
              border: '1px solid #CCDDE9',
              width: '207px',
              marginLeft: '3px',
            }}
          />
          <List style={{ height: '500px', width: '210px', overflowX: 'auto' }}>
            {CSMByVehiclesImport?.results.map((merchantCE) => {
              return (
                <List.Item
                  key={1}
                  className={
                    selectedCEMerchant?.merchant_ce__code ===
                    merchantCE.merchant_ce__code
                      ? 'item-container active'
                      : 'item-container'
                  }
                  onClick={() => setselectedCEMerchant(merchantCE)}
                >
                  <div className="item-container-data">
                    <span>{merchantCE.merchant_ce__code}</span>
                    <span className="item-container-data-resume">
                      {merchantCE.shipped_counter}/{merchantCE.counter}{' '}
                      desembarcados
                    </span>
                  </div>
                </List.Item>
              );
            })}
          </List>
        </div>
      </aside>
      <div
        className="container-data"
        style={{ width: '100%', paddingRight: '16px' }}
      >
        <VehiclesMovimentationDetails
          direction="IMPORT"
          merchantCE={selectedCEMerchant}
          operation={operation}
        />
      </div>
    </ContainerAside>
  );
}
