import styled from 'styled-components';

type StatusButtonProps = {
  status:
    | 'moored'
    | 'expected'
    | 'unmoored'
    | 'carried-out'
    | 'dalayed-unberthing'
    | 'delayed'
    | 'foreseen'
    | 'in-progress'
    | 'realized';
};

export const StatusButton = styled.div<StatusButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 7px 8px;
  margin-left: 10px;
  border-radius: 3px;
  cursor: default;
  transition: 500ms;
  text-transform: uppercase;
  line-height: 1.67;
  color: var(--neutral_medium);
  font-size: 11px;

  &:hover {
    background: var(--neutral_lighter);
  }
  &:before {
    content: ' ';
    display: inline-block;
    margin-right: 8px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--error-light);
  }
  ${(p) =>
    p.status === 'moored'
      ? `&:before{
          background: var(--success-light)
      }`
      : p.status === 'expected'
      ? `&:before{
          background: var(--success-lighter)
      }`
      : p.status === 'unmoored'
      ? `&:before{
          background: var(--info-light)
      }`
      : p.status === 'carried-out'
      ? `&:before{
          background: var(--marine_lighter)
      }`
      : p.status === 'dalayed-unberthing'
      ? `&:before{
          background: var(--error-light)
      }`
      : p.status === 'delayed'
      ? `&:before{
          background: var(--delayed-docking)
      }`
      : p.status === 'foreseen'
      ? `&:before{
          background: #27C0D6
      }`
      : p.status === 'in-progress'
      ? `&:before{
          background: var(--green-sea_medium)
      }`
      : p.status === 'realized'
      ? `&:before{
          background: #CCDDE9
      }`
      : ``}
`;
