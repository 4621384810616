import { useState } from 'react';

import { DefaultLayout } from '../../components';
import { useChangePagination } from '../../hooks';
import { useGetStopoversResumeQuery } from '../../services/stopoverApi';
import { FilterDefaultLayoutType, Stopover as StopoverType } from '../../types';
import { StaymentDetails } from './staymentDetails';

const tabs = [
  { label: 'Todas', value: '' },
  {
    label: 'Esperadas',
    value: 'WAITING',
  },
  {
    label: 'Fundeadas',
    value:
      'WAITING_PRINCIPAL_DEFINITION,WAITING_DOCKING_PLACE_RELEASE,WAITING_FOR_ANCHORING_SERVICES_TO_END,DOCKING_PLACE_AVAILABLE,ANCHORING_SERVICES,ANCHORED,DOCKING_PLACE_AVAILABLE',
  },
  {
    label: 'Atracadas',
    value: 'DOCKING,DOCKED,IN_DOCKING_MANOEUVRE,ANCHORING_SERVICES',
  },
  { label: 'Operando', value: 'OPERATION,IN_OPERATION,OPERATION_FINISHED' },
  {
    label: 'Desatracadas',
    value: 'UNDOCKING,IN_UNDOCKING_MANOEUVRE',
  },
  { label: 'Finalizadas', value: 'DONE,UNDOCKED,END_OF_UNDOCKING_MANOEUVRE' },
  { label: 'Canceladas', value: 'CANCELED' },
];

export function Stayment() {
  const { onChangePagination, queryPage } = useChangePagination();

  const [filter, setFilter] = useState<FilterDefaultLayoutType>({
    page: queryPage,
    order_by_delay_property: 'stopover_current_docking_is_delayed',
  });

  const {
    data: stopoveresData,
    isLoading: isLoadingStopoveresData,
    isFetching: isFetchingStopoveresData,
  } = useGetStopoversResumeQuery(filter);

  const [selectedStopover, setSelectedStopover] = useState<StopoverType>(
    {} as StopoverType
  );

  const [filteredStopoveres, setFilteredStopoveres] = useState(
    [] as StopoverType[]
  );

  const [hasEdited, setHasEdited] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  return (
    <DefaultLayout
      title="Atracações"
      tabs={tabs}
      filterField="status"
      setFilter={setFilter}
      stopoveresData={stopoveresData}
      filteredStopoveres={filteredStopoveres}
      setFilteredStopoveres={setFilteredStopoveres}
      isLoadingStopoveresData={isLoadingStopoveresData}
      isFetchingStopoveresData={isFetchingStopoveresData}
      queryPage={queryPage}
      onChangePagination={onChangePagination}
      selectedStopover={selectedStopover}
      setSelectedStopover={setSelectedStopover}
      pageContext="STAYMENT"
      labelPlural="ESCALAS(S)"
    >
      <StaymentDetails
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        setHasEdited={setHasEdited}
        name={`stopover_stayment${selectedStopover?.id}`}
      />
    </DefaultLayout>
  );
}
