import { Avatar } from 'antd';
import styled from 'styled-components';

import { generateRandomIntegerInRange } from '../../utils/utils';

const NameRenderer = styled.div`
  display: flex;
  align-items: center;
  & .name {
    display: inline-block;
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
  }
`;

export function permissionNameRenderer(text: string) {
  const colorList = [
    '#69c0ff',
    '#36cfc9',
    '#ffa940',
    '#ffec3d',
    '#73d13d',
    '#bae637',
  ];

  return (
    <NameRenderer>
      <Avatar
        style={{
          background: colorList[generateRandomIntegerInRange(0, 5)],
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {text.charAt(0)}
      </Avatar>
      <span className="name">{text}</span>
    </NameRenderer>
  );
}
