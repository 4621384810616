import { Descriptions, FormatItem } from '../../../components';
import { StoppageTypeDescription } from '../../../types';
import { createTimeString } from '../../../utils/utils';
import { CompartmentList } from './styles';

type ExpandedRowStoppageProps = {
  record: StoppageTypeDescription;
};

const { Item } = Descriptions;
export function ExpandedRowStoppage(props: ExpandedRowStoppageProps) {
  const { record } = props;

  return (
    <div
      style={{
        width: 'calc(100% - 47px)',
        float: 'right',
      }}
    >
      <CompartmentList>
        {record.operation_cranes.map((oCrane) => {
          return (
            <span key={oCrane.id} className="checked">
              {oCrane.crane_name === 'Guindaste Não Definido'
                ? 'Guindaste geral'
                : oCrane.crane_name}
            </span>
          );
        })}
      </CompartmentList>
      <CompartmentList>
        {record.compartments.map((comp) => {
          return (
            <span key={comp.id} className="checked">
              {comp.name}
            </span>
          );
        })}
      </CompartmentList>
      <Descriptions>
        <Item span={3} label="Motivo da paralisação para ANTAQ ">
          <FormatItem>{record.motive}</FormatItem>
        </Item>
        <Item span={3} label="Observação">
          <FormatItem>{record.observations}</FormatItem>
        </Item>
        {record.workPeriodsInpacted && record.workPeriodsInpacted.length > 0 && (
          <Item span={3} label="Turnos impactados">
            <FormatItem>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {record.workPeriodsInpacted?.map((wpInpacted) => (
                  <div
                    key={wpInpacted.index}
                    style={{ display: 'flex', gap: '8px' }}
                  >
                    <span>Período {wpInpacted.index}:</span>
                    <strong>
                      {wpInpacted.workShiftsInpacted
                        .map((wsInpacted) => {
                          return `${createTimeString(
                            wsInpacted.shift_start
                          )}-${createTimeString(wsInpacted.shift_end)}`;
                        })
                        .join(', ')}
                      ;
                    </strong>
                  </div>
                ))}
              </div>
            </FormatItem>
          </Item>
        )}
      </Descriptions>
    </div>
  );
}
