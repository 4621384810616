import { Col, FormInstance, Row } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { Dispatch, SetStateAction } from 'react';

import {
  FormItemDatePicker,
  FormItemInputNumber,
} from '../../../../components';
import {
  CargoStorageMerchantType,
  ConsigneeWithCargosType,
} from '../../../../types';
import { csmDirectionList } from '../../../../utils/lists';
import { RemainingProductType } from './ConfirmStorageModal';

export const confirmCargoRowCollumns = [
  { title: 'Produto', colSpan: 5 },
  { title: 'Sentido', colSpan: 5 },
  { title: 'Peso', colSpan: 5 },
  { title: 'Volume', colSpan: 4 },
  { title: 'Previsão de Armazenamento', colSpan: 5 },
];

type ConfirmCargoRowProps = {
  index: number;
  itemRowProps?: {
    consignee: ConsigneeWithCargosType;
    form: FormInstance;
    setRemainingProducts: Dispatch<SetStateAction<RemainingProductType[]>>;
  };
};

export function ConfirmCargoRow(props: ConfirmCargoRowProps) {
  const { index, itemRowProps } = props;
  const { consignee, form, setRemainingProducts } =
    itemRowProps ||
    ({} as {
      consignee: ConsigneeWithCargosType;
      form: FormInstance;
      setRemainingProducts: Dispatch<SetStateAction<RemainingProductType[]>>;
    });

  function onChangeWeight(selectedVolume: valueType) {
    const weightTotal = consignee.items[index].weight;
    const volumeTotal = consignee.items[index].volume || 1;
    if (selectedVolume === volumeTotal) {
      setRemainingProducts((prev) => prev.filter((item) => item.id !== index));
    } else {
      const remainingItem = {
        id: index,
        name: `${consignee.items[index].ncm_code} - ${consignee.items[index].ncm_description}`,
        value: +(volumeTotal - Number(selectedVolume)).toFixed(3),
      };
      setRemainingProducts((prev) => {
        if (prev.find((item) => item.id === index)) {
          return prev.map((item) => {
            if (item.id === index) {
              return remainingItem;
            }
            return item;
          });
        }
        return [...prev, remainingItem];
      });
    }
    const selectedWeight =
      (Number(selectedVolume) / volumeTotal) * (weightTotal || 0);
    const items = form.getFieldValue('items');
    form.setFieldsValue({
      items: items.map((item: CargoStorageMerchantType, idx: number) => {
        if (idx === index) {
          return { ...item, weight: +selectedWeight.toFixed(2) };
        }
        return item;
      }),
    });
  }

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      <Col span={5}>
        <span style={{ fontWeight: 600 }}>
          {consignee.items[index].ncm_code} -{' '}
          {consignee.items[index].ncm_description}
        </span>
      </Col>
      <Col span={5}>
        <span style={{ fontWeight: 600 }}>
          {
            csmDirectionList.find(
              (item) => item.id === consignee.items[index].direction
            )?.name
          }
        </span>
      </Col>
      <Col span={5}>
        <span style={{ fontWeight: 600 }}>
          {form.getFieldValue(['items', index, 'weight']) || 0} ton
        </span>
      </Col>
      <FormItemInputNumber
        colSpan={4}
        name={[index, 'volume']}
        addonAfter="m³"
        max={consignee.items[index].volume}
        required
        onChange={(e) => onChangeWeight(e)}
        help={`O máximo deve ser ${consignee.items[index].volume}`}
      />
      <FormItemDatePicker
        colSpan={5}
        name={[index, 'expected_storage']}
        allowClear
      />
    </Row>
  );
}
