import _ from 'lodash';
import moment from 'moment';

import {
  OperationLineType,
  ShiftType,
  WorkPeriodType,
  OperationLineToSaveType,
  WorkPeriodFormType,
  WorkShiftFormType,
} from '../../../types';
import { createDateString } from '../../../utils/utils';

export function formatWorkPeriodToForm(
  workPeriod: WorkPeriodType
): WorkPeriodFormType {
  return {
    ...workPeriod,
    start_of_period: workPeriod.start_of_period
      ? moment(workPeriod.start_of_period)
      : null,
    end_of_period: workPeriod.end_of_period
      ? moment(workPeriod.end_of_period)
      : null,
    work_shifts: formatWorkShiftToForm(
      workPeriod?.work_shifts,
      workPeriod.shift_mode,
      workPeriod.start_of_period ? moment(workPeriod.start_of_period) : null
    ),
  };
}

export function formatWorkShiftToForm(
  workShifts: ShiftType[] | undefined,
  shiftMode: 'EIGHT' | 'SIX',
  startOfPeriod: moment.Moment | null
): WorkShiftFormType[] {
  const maxWorkShiftsAmount = shiftMode === 'EIGHT' ? 3 : 4;
  const shiftModeNumber = shiftMode === 'EIGHT' ? 8 : 6;

  const defaultWorkShifts: WorkShiftFormType[] = [];

  if (startOfPeriod) {
    for (let i = 0; i < maxWorkShiftsAmount; i++) {
      defaultWorkShifts.push({
        shift_start:
          moment(startOfPeriod).add(shiftModeNumber * i, 'hours') || null,
        shift_end:
          moment(startOfPeriod).add(shiftModeNumber * (i + 1), 'hours') || null,
        gang_amount: null,
      } as WorkShiftFormType);
    }

    const currentWorkShifts =
      workShifts?.map((workShift) => {
        return {
          ...workShift,
          shift_start: moment(workShift.shift_start),
          shift_end: moment(workShift.shift_end),
          gang_amount: workShift.gang_amount,
        } as WorkShiftFormType;
      }) || [];
    const workShiftsToForm: WorkShiftFormType[] = defaultWorkShifts.map(
      (defaultWorkShift) => {
        const currentWorkShiftIndex = currentWorkShifts.findIndex(
          (currentWorkShift) =>
            moment(currentWorkShift.shift_start).format('HH:mm') ===
            defaultWorkShift.shift_start.format('HH:mm')
        );
        if (currentWorkShiftIndex > -1) {
          return currentWorkShifts[currentWorkShiftIndex];
        }
        return defaultWorkShift;
      }
    );
    const sortedWorkShifts = _.orderBy(
      workShiftsToForm,
      (item) => {
        return moment(item.shift_start);
      },
      ['asc']
    );
    return sortedWorkShifts;
  }

  return defaultWorkShifts;
}

export function formatOperationLineToForm(
  operationLine: OperationLineType
): OperationLineType {
  return {
    ...operationLine,
    real_connection_hour: operationLine.real_connection_hour
      ? moment(operationLine.real_connection_hour)
      : null,
    real_desconnection_hour: operationLine.real_desconnection_hour
      ? moment(operationLine.real_desconnection_hour)
      : null,
    real_pump_start: operationLine.real_pump_start
      ? moment(operationLine.real_pump_start)
      : null,
    real_pump_finish: operationLine.real_pump_finish
      ? moment(operationLine.real_pump_finish)
      : null,
  };
}

export function formatWorkPeriodToSave(
  workPeriodForm: WorkPeriodType & WorkPeriodFormType
): any {
  return {
    ...workPeriodForm,
    start_of_period: createDateString(workPeriodForm.start_of_period),
    end_of_period: createDateString(workPeriodForm.end_of_period),
    work_shifts: formatWorkShiftToSave(workPeriodForm.work_shifts),
  };
}

export function formatWorkShiftToSave(work_shifts: WorkShiftFormType[]) {
  return work_shifts
    .map((workShift) => {
      return {
        ...workShift,
        shift_start: createDateString(workShift.shift_start),
        shift_end: createDateString(workShift.shift_end),
      };
    })
    .filter(
      (work_shift) =>
        work_shift.gang_amount !== null && work_shift.gang_amount !== undefined
    );
}

export function formatOperationLineToSave(
  operationLines: OperationLineType[],
  operationId: number
): OperationLineToSaveType[] {
  return operationLines.map((operationLine) => ({
    real_connection_hour: createDateString(operationLine.real_connection_hour),
    real_desconnection_hour: createDateString(
      operationLine.real_desconnection_hour
    ),
    real_pump_start: createDateString(operationLine.real_pump_start),
    real_pump_finish: createDateString(operationLine.real_pump_finish),
    line: operationLine.line.id,
    id: operationLine.id,
    name: operationLine.name || null,
    operation_product_id: operationLine.operation_product_id || null,
    operation_id: operationId,
  }));
}
