import { Space, Tooltip } from 'antd';
import Flag from 'react-flagpack';

import { VesselRequestUpdateIcon } from '../../../assets/icons/vesselRequestUpdateIcon';
import ErrorBoundary from '../../../components/common/ErrorBoundary';

export function VesselNameRenderer(record: Record<string, any>) {
  const { vessel } = record;
  return (
    <Space size={10}>
      {vessel.flag && vessel.flag !== 'ANT' && (
        <ErrorBoundary replace="">
          <Flag code={vessel.flag} size="m" />
        </ErrorBoundary>
      )}
      <span
        style={{
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: '14px',
        }}
      >
        {vessel.name.toLowerCase()}
      </span>
      {vessel.has_vessel_request_update && (
        <Tooltip title="Solicitação de alteração de dados pendetes">
          <div style={{ cursor: 'pointer' }}>
            <VesselRequestUpdateIcon />
          </div>
        </Tooltip>
      )}
    </Space>
  );
}
