import { StoppageToSave, StoppageType } from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const stoppageApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    updateStoppage: build.mutation<StoppageType, StoppageToSave>({
      query: (stoppage) => {
        return {
          url: `/operational/stoppages/${stoppage.id}`,
          method: 'PUT',
          body: stoppage,
        };
      },
      invalidatesTags: (_result, error, queryArg) =>
        getInvalidatesTags(error, [
          { type: 'OperationCalculus', code: queryArg.operation_id },
        ]),
    }),
    createStoppage: build.mutation<StoppageType, StoppageToSave>({
      query: (stoppage) => {
        return {
          url: `/operational/stoppages`,
          method: 'POST',
          body: stoppage,
        };
      },
      invalidatesTags: (_result, error, queryArg) =>
        getInvalidatesTags(error, [
          { type: 'OperationCalculus', code: queryArg.operation_id },
        ]),
    }),
    deleteStoppage: build.mutation<void, number>({
      query: (stoppageId) => {
        return {
          url: `/operational/stoppages/${stoppageId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, queryArg) =>
        getInvalidatesTags(error, [
          { type: 'OperationCalculus', code: queryArg },
        ]),
    }),
  }),
});

export const {
  useUpdateStoppageMutation,
  useCreateStoppageMutation,
  useDeleteStoppageMutation,
} = stoppageApi;
