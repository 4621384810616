export function StopoverScheduleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#clip0_2686_20425)">
        <g clipPath="url(#clip1_2686_20425)">
          <path d="M7.62549 7.58496C7.55674 7.58496 7.50049 7.64121 7.50049 7.70996V8.45996C7.50049 8.52871 7.55674 8.58496 7.62549 8.58496H13.6255C13.6942 8.58496 13.7505 8.52871 13.7505 8.45996V7.70996C13.7505 7.64121 13.6942 7.58496 13.6255 7.58496H7.62549ZM10.5005 9.83496H7.62549C7.55674 9.83496 7.50049 9.89121 7.50049 9.95996V10.71C7.50049 10.7787 7.55674 10.835 7.62549 10.835H10.5005C10.5692 10.835 10.6255 10.7787 10.6255 10.71V9.95996C10.6255 9.89121 10.5692 9.83496 10.5005 9.83496ZM9.75049 16.9912H6.25049V5.99121H15.0005V10.9912C15.0005 11.06 15.0567 11.1162 15.1255 11.1162H16.0005C16.0692 11.1162 16.1255 11.06 16.1255 10.9912V5.36621C16.1255 5.08965 15.9021 4.86621 15.6255 4.86621H5.62549C5.34893 4.86621 5.12549 5.08965 5.12549 5.36621V17.6162C5.12549 17.8928 5.34893 18.1162 5.62549 18.1162H9.75049C9.81924 18.1162 9.87549 18.06 9.87549 17.9912V17.1162C9.87549 17.0475 9.81924 16.9912 9.75049 16.9912ZM16.6255 15.6162H14.3755V15.0443C15.0989 14.8287 15.6255 14.16 15.6255 13.3662C15.6255 12.399 14.8427 11.6162 13.8755 11.6162C12.9083 11.6162 12.1255 12.399 12.1255 13.3662C12.1255 14.1584 12.6521 14.8287 13.3755 15.0443V15.6162H11.1255C10.988 15.6162 10.8755 15.7287 10.8755 15.8662V18.2412C10.8755 18.3787 10.988 18.4912 11.1255 18.4912H16.6255C16.763 18.4912 16.8755 18.3787 16.8755 18.2412V15.8662C16.8755 15.7287 16.763 15.6162 16.6255 15.6162ZM13.0942 13.3662C13.0942 12.935 13.4442 12.585 13.8755 12.585C14.3067 12.585 14.6567 12.935 14.6567 13.3662C14.6567 13.7975 14.3067 14.1475 13.8755 14.1475C13.4442 14.1475 13.0942 13.7975 13.0942 13.3662ZM15.9067 17.5225H11.8442V16.585H15.9067V17.5225Z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2686_20425">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(4 4.6814)"
          />
        </clipPath>
        <clipPath id="clip1_2686_20425">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(4 4.6814)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
