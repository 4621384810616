import { DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';

import { DatePickerStylized, RangePickerStylized } from './styles';

export interface IExtendedDatePickerProps {
  minuteStep: number;
  showTime?: boolean | { defaultValue?: moment.Moment };
  isRangePicker?: boolean;
  disabledTime?: (current: any) =>
    | {
        disabledHours: () => number[];
        disabledMinutes: () => number[];
      }
    | {
        disabledHours?: undefined;
        disabledMinutes?: undefined;
      };
  format?: string | string[];
}
export type DatePickerCustomProps = DatePickerProps &
  IExtendedDatePickerProps &
  RangePickerProps;

export function DatePicker(props: DatePickerCustomProps) {
  const { isRangePicker, format } = props;
  return isRangePicker ? (
    <RangePickerStylized
      showTime
      format={format}
      locale={locale}
      placeholder={['Data', 'Data']}
      {...props}
    />
  ) : (
    <DatePickerStylized
      showTime
      format={format}
      locale={locale}
      placeholder="Data"
      {...props}
    />
  );
}
