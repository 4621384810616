import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button, Drawer } from '../../../components';
import { Docking, Stopover as StopoverType } from '../../../types';
import { DockingPilotExpectForm } from './pilotExpectForm';

type DockingPilotExpectDrawerProps = {
  width: string | number;
  selectedDocking: Docking | undefined;
  setSelectedDocking: (value: any) => void;
  currentDockingKey: any;
  setCurrentDockingKey: (value: any) => void;
  selectedStopover?: StopoverType;
  visible: boolean;
  setIsVisible: (value: boolean) => void;
  onBack?: () => void;
  isDockingWindowContext?: boolean;
};

export function DockingPilotExpectDrawer(props: DockingPilotExpectDrawerProps) {
  const {
    width,
    selectedDocking,
    setSelectedDocking,
    currentDockingKey,
    setCurrentDockingKey,
    selectedStopover,
    visible,
    setIsVisible,
    onBack,
    isDockingWindowContext,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  return (
    <Drawer
      visible={visible}
      width={width}
      closeIcon={
        <Button type="text" icon={<ArrowLeftOutlined />}>
          Voltar
        </Button>
      }
      onClose={!isDockingWindowContext ? () => onBack && onBack() : undefined}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <DockingPilotExpectForm
        name="docking_form"
        isVisibleForm={visible}
        setIsVisibleForm={setIsVisible}
        selectedDocking={selectedDocking}
        setSelectedDocking={setSelectedDocking}
        selectedStopover={selectedStopover}
        currentDockingKey={currentDockingKey}
        setCurrentDockingKey={setCurrentDockingKey}
        onBack={onBack}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
      />
    </Drawer>
  );
}
