import { ColumnsType } from 'antd/lib/table';

import { formatNumberToLocale } from '../../../../../../utils/utils';

export function columns(
  operationDirection: string
): ColumnsType<Record<string, any>> {
  return [
    {
      title: operationDirection === 'LOADING' ? 'Embarcador' : 'Consignatário',
      dataIndex: ['operator_company', 'name'],
      width: '20%',
    },
    {
      title: 'CE Mercante',
      dataIndex: 'merchant_ce_code',
      width: '20%',
    },
    {
      title: 'NCM',
      dataIndex: ['cargo', 'name'],
      width: '20%',
      render(value, record) {
        if (!value) {
          return `${record.ncm_code} - ${record.ncm_description}`;
        }
        return value;
      },
    },
    {
      title: 'Armazenamento',
      dataIndex: ['storage_company', 'name'],
      width: '20%',
    },
    {
      title: 'Quantidade',
      dataIndex: 'amount',
      width: '10%',
      align: 'right',
      render(value) {
        return <span>{value || 0} un</span>;
      },
    },
    {
      title: 'Peso',
      dataIndex: 'weight',
      width: '10%',
      align: 'right',
      render(value) {
        return <span>{formatNumberToLocale(value)} ton</span>;
      },
    },
  ];
}
