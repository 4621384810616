import { EyeOutlined } from '@ant-design/icons';
import { Dispatch, SetStateAction, useState } from 'react';

import { Button } from '../../../../../components';
import { ProductDescription } from '../../../../../components/common/ProductDescription';
import {
  OperationProductAndCraneType,
  OperationProductTypeDescription,
  Stopover,
} from '../../../../../types';
import { OperationProductDetailsDrawer } from '../../operationProductDetailsDrawer/OperationProductDetailsDrawer';
import { DescriptionSection } from '../styles';

type ProductOperationDescriptionProps = {
  operationProduct: OperationProductTypeDescription;
  selectedOperationProduct?: OperationProductAndCraneType;
  isVehicles?: boolean;
  unity?: 'ton' | 'un' | 'm³';
  isLiquidBulk?: boolean;
  isSolidBulk?: boolean;
  setSelectedStopover?: Dispatch<SetStateAction<Stopover>>;
  selectedStopover?: Stopover;
};
export function ProductOperationDescription(
  props: ProductOperationDescriptionProps
) {
  const {
    operationProduct,
    selectedOperationProduct,
    isVehicles,
    unity,
    isLiquidBulk,
    isSolidBulk,
    setSelectedStopover,
    selectedStopover,
  } = props;
  // const [isVisibleCargoDetailsDrawer, setIsVisibleCargoDetailsDrawer] =
  //   useState(false);

  return (
    <DescriptionSection
      key="product_operation_1"
      style={{ marginBlockStart: '25px' }}
    >
      {/* <OperationProductDetailsDrawer
        product={operationProduct}
        isVisible={isVisibleCargoDetailsDrawer}
        onCloseDrawer={() => setIsVisibleCargoDetailsDrawer(false)}
        isVehicles={isVehicles}
      />
      <Button
        onClick={() => setIsVisibleCargoDetailsDrawer(true)}
        icon={<EyeOutlined />}
        style={{ marginBottom: '15px' }}
        disabled={!(Object.keys(operationProduct?.merchants_ce).length > 0)}
      >
        Ver detalhes
      </Button> */}
      <ProductDescription
        operationProduct={operationProduct}
        selectedOperationProduct={selectedOperationProduct}
        isVehicles={isVehicles || false}
        isLiquidBulk={isLiquidBulk || false}
        isSolidBulk={isSolidBulk || false}
        unity={unity || ''}
        setSelectedStopover={setSelectedStopover}
        selectedStopover={selectedStopover}
      />
    </DescriptionSection>
  );
}
