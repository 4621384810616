import styled from 'styled-components';

export const Container = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: auto;
  z-index: 1;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
  padding: 16px;
  display: flex;
  flex-direction: column;
  & .buttons {
    display: flex;
    justify-content: space-between;
    & .ant-btn-text {
      color: black !important;
    }
  }
  & .title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    & .docking-title {
      margin-right: 10px;
      display: inline-block;
    }
    & .docking-id {
      font-family: Consolas;
      font-weight: normal;
      display: inline-block;
      margin-right: 10px;
    }
  }
  & .title-stopover-code {
    color: var(--neutral_light);
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  & .switchLabel {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  & .expand {
    cursor: default;
    display: inline-block;
  }
`;
