import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import {
  CompaniesGroupByFinancialTableType,
  FinancialTableType,
} from '../../../types';
import { maskString } from '../../../utils/utils';
import { SlimTable } from '../../antd/Table';
import { Title } from '../Title';
import { groupCompaniesByTableType } from './formatters';
import { TableDescriptionWrapper } from './styles';
import { StatusTag } from '../StatusTag';
import { ResourceQuantityTagWrapper } from '../ResourceQuantityTag/styles';

const columns = [
  {
    title: 'Confirmado?',
    dataIndex: ['company_confirmed'],
    width: '10%',
    render: (value: boolean | null) =>
      value === null ? (
        <div className="confirmed-container">
          <div className="confirmed-circle" />
        </div>
      ) : value ? (
        <CheckCircleFilled
          style={{ color: 'var(--green-sea_dark)', fontSize: '16px' }}
        />
      ) : (
        <CloseCircleFilled
          style={{ color: 'var(--error-medium)', fontSize: '16px' }}
        />
      ),
    align: 'center' as const,
  },
  {
    title: 'Empresa',
    dataIndex: ['company', 'name'],
    width: '65%',
  },
  {
    title: 'CNPJ',
    dataIndex: ['company', 'cnpj'],
    width: '25%',
    render: (value: string | null) => {
      return value ? maskString(value, '##.###.###/####-##') : '';
    },
  },
];

type FinancialOfficersTableDescriptionProps = {
  financialTables: FinancialTableType[];
};

export function FinancialOfficersTableDescription(
  props: FinancialOfficersTableDescriptionProps
) {
  const { financialTables } = props;
  const [companiesByFinancialTableType, setCompaniesByFinancialTableType] =
    useState<CompaniesGroupByFinancialTableType[]>([]);

  useEffect(() => {
    setCompaniesByFinancialTableType(
      groupCompaniesByTableType(financialTables)
    );
  }, [financialTables]);

  function getTotalRejectedConfirmations(
    tableType: CompaniesGroupByFinancialTableType
  ) {
    const totalRejected = tableType.items.reduce((totalRejected, item) => {
      if (item.company_confirmed === false) {
        totalRejected += 1;
      }
      return totalRejected;
    }, 0);

    return totalRejected > 0 ? (
      <>
        &nbsp;
        <ResourceQuantityTagWrapper
          style={{ background: 'var(--error-medium)' }}
        >
          <span>{`${totalRejected} recusado${
            totalRejected > 1 ? 's' : ''
          }`}</span>
        </ResourceQuantityTagWrapper>
      </>
    ) : undefined;
  }

  return (
    <TableDescriptionWrapper>
      {companiesByFinancialTableType.map((tableType) => (
        <div className="table-container" key={tableType.id}>
          <Title>{tableType.title}</Title>
          <span className="table-description">
            &nbsp;{tableType.description}
          </span>
          {getTotalRejectedConfirmations(tableType)}
          <SlimTable
            columns={columns}
            rowKey="id"
            dataSource={tableType.items}
            pagination={false}
          />
        </div>
      ))}
    </TableDescriptionWrapper>
  );
}
