import styled from 'styled-components';

export const Container = styled.div`
  .div-border {
    border: 1px solid var(--neutral_lighter);
    border-radius: 4px;
    padding: 16px;
    margin-top: 16px;
  }

  .stoppages {
    display: flex;
    flex-direction: column;
    margin-block-start: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    display: flex;
    flex-direction: row;
    margin-block-end: 8px;
    gap: 8px;
    align-items: baseline;
  }

  .subtitle {
    display: flex;
    margin-block-end: 16px;
    color: var(--neutral_medium);
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .expand {
      cursor: default;
      display: inline-block;
    }

    .switchLabel {
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
    }
  }
`;
