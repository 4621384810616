import { Skeleton } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

import {
  Button,
  Descriptions,
  FormatItem,
  Progress,
  Title,
} from '../../components';
import { OperationCalculusType, StoppageType } from '../../types';
import { convertMinutesToStringHours } from '../../utils/utils';
import { ProgressLegend } from '../operation/OperationsData/styles';

const { Item } = Descriptions;

type OperationResumeProps = {
  operationCalculusData: OperationCalculusType;
  setIsVisibleStoppagesDrawer: (value: boolean) => void;
  stoppages: StoppageType[];
  isLoadingOperationCalculus: boolean;
  operationType: 'COMMON' | 'VEHICLES' | 'LIQUID_BULK' | 'SOLID_BULK';
  operationStart?: string | Moment | null;
  operationFinish?: string | Moment | null;
};

export function OperationResume(props: OperationResumeProps) {
  const {
    operationCalculusData,
    setIsVisibleStoppagesDrawer,
    stoppages,
    isLoadingOperationCalculus,
    operationType,
    operationStart,
    operationFinish,
  } = props;

  const formatProgressText = (percent?: number) => {
    return `${percent}% operado`;
  };

  return isLoadingOperationCalculus ? (
    <Skeleton active />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        backgroundColor: '#EBF2F7',
        padding: '16px',
      }}
    >
      <Title>RESUMO DA OPERAÇÃO</Title>
      <Progress
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '5px',
        }}
        percent={operationCalculusData?.operation_progress || 0}
        status="active"
        format={formatProgressText}
      />
      <ProgressLegend>
        <div className="legend-item">
          <div className="square green" />
          <span>
            Total operado:
            <strong>
              {`${operationCalculusData?.total_movimented} ton` || '0 ton'}
            </strong>
          </span>
        </div>
        <div className="legend-item">
          <div className="square gray" />
          <span>
            Total manifestado:
            <strong>
              {`${operationCalculusData?.total_weight} ton` || '0 ton'}
            </strong>
          </span>
        </div>
      </ProgressLegend>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          padding: '4px 8px',
          marginBlockEnd: '4px',
          borderRadius: '4px',
          paddingLeft: '8px',
        }}
      >
        <Title>Progresso por produto</Title>
        {operationCalculusData?.operation_products &&
        operationCalculusData?.operation_products.length > 0 ? (
          <Descriptions>
            {operationCalculusData?.operation_products?.map((item) => (
              <Item key={item.id} label={item.cargo_type}>
                <FormatItem>{item.product_progress}% operado</FormatItem>
              </Item>
            ))}
          </Descriptions>
        ) : (
          <span>Não existem produtos cadastrados</span>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          width: '100%',
        }}
      >
        {operationType !== 'LIQUID_BULK' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#fff',
              paddingBlockStart: '4px',
              paddingInlineStart: '8px',
            }}
          >
            <Title>Produção por período</Title>
            <Descriptions column={2}>
              {operationCalculusData?.work_periods &&
              operationCalculusData?.work_periods.length > 0 ? (
                operationCalculusData?.work_periods?.map((item, index) => (
                  <Item key={item.id} label={`Período ${index + 1}`}>
                    <FormatItem>{item.total_movimented} ton</FormatItem>
                  </Item>
                ))
              ) : (
                <span>Não existem períodos com movimentações cadastradas</span>
              )}
            </Descriptions>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            paddingBlockStart: '4px',
            paddingInlineStart: '8px',
          }}
        >
          <Title>Produtividade</Title>
          <Descriptions column={1}>
            <Item label="Produtividade liquida">
              <FormatItem>
                {operationCalculusData?.total_net_production &&
                  `${operationCalculusData?.total_net_production} ton/h`}
              </FormatItem>
            </Item>
            <Item label="Produtividade bruta">
              <FormatItem>
                {operationCalculusData?.total_gross_production &&
                  `${operationCalculusData?.total_gross_production} ton/h`}
              </FormatItem>
            </Item>
          </Descriptions>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          paddingBlockStart: '4px',
          paddingInlineStart: '8px',
        }}
      >
        <Title>Tempos</Title>
        <Descriptions column={3}>
          <Item label="Duração da operação">
            <FormatItem>
              {convertMinutesToStringHours(operationCalculusData.total_time)}
            </FormatItem>

            {/* <FormatItem>
              {operationCalculusData?.total_time &&
                `${operationCalculusData?.total_time}h`}
            </FormatItem> */}
          </Item>
          <Item label="Horas paralisadas">
            <div>
              <FormatItem>
                {convertMinutesToStringHours(
                  operationCalculusData.total_stoppage_time
                )}
              </FormatItem>
              <Button
                style={{ marginTop: '-8px', marginLeft: '-10px' }}
                type="link"
                onClick={() => setIsVisibleStoppagesDrawer(true)}
                disabled={stoppages.length === 0}
              >
                {stoppages.length === 0
                  ? 'Nenhuma paralisação'
                  : stoppages.length === 1
                  ? '1 paralisação'
                  : `(${stoppages.length} Paralisações)`}
              </Button>
            </div>
          </Item>
          <Item label="Horas operacionais">
            <FormatItem>
              {operationCalculusData?.total_time &&
                convertMinutesToStringHours(
                  (operationCalculusData?.total_time || 0) -
                    (operationCalculusData?.total_stoppage_time || 0)
                )}
            </FormatItem>
          </Item>
        </Descriptions>
      </div>
    </div>
  );
}
