import {
  GenericQueryParamsType,
  GenericResponse,
  ShiftImpactedType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';

type WorkShiftParamsType = GenericQueryParamsType & {
  start_of_period?: string;
  end_of_period?: string;
  operation_id?: number;
};

export const workShiftApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getWorkShiftsImpacted: build.query<
      GenericResponse & { results: Array<ShiftImpactedType> },
      WorkShiftParamsType | void
    >({
      query: (params: WorkShiftParamsType) => {
        return {
          url: `/operational/work-shifts-impacted`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
    }),
  }),
});

export const { useGetWorkShiftsImpactedQuery } = workShiftApi;
