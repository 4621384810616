import { PlusOutlined } from '@ant-design/icons';
import { Row, message } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Collapse,
  Form,
  FormItemDatePicker,
  FormItemInput,
  FormItemRadioGroup,
  FormItemSelect,
  GenericDrawerHeader,
  Title,
} from '../../../components';
import { useCollapsePanels } from '../../../hooks';
import {
  useGetLinesToRadioGroupQuery,
  useUpdateOperationLineMutation,
} from '../../../services/operationLinesApi';
import { OperationLineType, OperationalOperationType } from '../../../types';
import {
  formatOperationLineToSave,
  formatOperationLineToForm,
} from './formatters';
import { disabledDateAfterToday } from '../../../utils/utils';

type OperationLineFormProps = {
  onCloseDrawer: () => void;
  operation: OperationalOperationType;
  selectedDockingPlaceTag: string;
};

type TitleType = {
  index: number;
  title?: string;
  subtitle?: string;
};

const { Panel } = Collapse;

export function OperationLineForm(props: OperationLineFormProps) {
  const { onCloseDrawer, operation, selectedDockingPlaceTag } = props;
  const [form] = Form.useForm();

  const [titles, setTitles] = useState<TitleType[]>([]);

  const { data: linesData } = useGetLinesToRadioGroupQuery({
    berth: selectedDockingPlaceTag,
  });

  const [
    updateOperationLine,
    {
      isLoading: isLoadingUpdateOperationLine,
      isSuccess: isSuccessUpdateOperationLine,
      isError: isErrorUpdateOperationLine,
    },
  ] = useUpdateOperationLineMutation();

  useEffect(() => {
    if (isSuccessUpdateOperationLine) {
      message.success('Linha salva com sucesso!');
    }
  }, [isSuccessUpdateOperationLine]);

  useEffect(() => {
    if (isErrorUpdateOperationLine) {
      message.error('Ocorreu um erro ao salvar a linha');
    }
  }, [isErrorUpdateOperationLine]);

  useEffect(() => {
    setTitles(
      operationLinesToForm.operationLines.map((operationLine, index) => ({
        index,
        title: operationLine.line.name,
        subtitle: operationLine.name || '',
      }))
    );
  }, [operation]);

  const operationLinesToForm = useMemo(() => {
    return {
      operationLines:
        operation.operation_lines?.map((operationLine) =>
          formatOperationLineToForm(
            !isEmpty(operationLine) && operationLine
              ? operationLine
              : ({} as OperationLineType)
          )
        ) || [],
    };
  }, [operation]);

  const operationProducts = useMemo(() => {
    return (
      operation.items?.map((item) => ({
        id: item.id,
        name: item.cargo_type?.name,
      })) || []
    );
  }, [operation]);

  function onBackForm() {
    onCloseDrawer();
    form.resetFields();
  }

  function handleAddNewLine() {
    form.setFieldsValue({
      operationLines: [...form.getFieldValue('operationLines'), {}],
    });
    setTitles((prev) => [...prev, { index: prev.length, title: 'Nova linha' }]);
  }

  function getHeader() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <b style={{ fontSize: '16px' }}>Linhas</b>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => handleAddNewLine()}
        >
          Adicionar linha
        </Button>
      </div>
    );
  }

  function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        updateOperationLine(
          formatOperationLineToSave(values.operationLines, operation.id || 0)
        );
      })
      .finally(() => {
        form.resetFields();
        onCloseDrawer();
      });
  }

  function getHeaderOperationLine(title: TitleType) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'baseline',
        }}
      >
        <b>{title.title}</b>
        <span
          style={{
            color: 'var(--neutral_medium)',
            fontWeight: '400',
            fontSize: '14px',
          }}
        >
          {title.subtitle}
        </span>
      </div>
    );
  }

  function onSetTitles(index: number, lineId = 0, name = '') {
    let obj: TitleType = { index };
    if (lineId > 0) {
      const line = linesData?.find((item) => item.value === lineId);
      obj = { ...obj, title: line?.label || '' };
    }
    if (name) {
      obj = { ...obj, subtitle: name };
    }
    setTitles((prev) =>
      prev.map((title) => {
        if (obj.index === title.index) {
          return { ...title, ...obj };
        }
        return title;
      })
    );
  }

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } = useCollapsePanels(
    titles.map((title) => String(title.index))
  );

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={operationLinesToForm}
      onFinish={handleFormSubmit}
      name="line"
    >
      <GenericDrawerHeader
        onBack={onBackForm}
        showBackButton
        title={getHeader()}
        formName="line"
        isLoadingSave={isLoadingUpdateOperationLine}
        onChangeSwitch={onChangeSwitch}
        openCollapsePanels={openCollapsePanels}
        allCollapsePanels={titles.map((title) => String(title.index))}
      />
      <FormList name="operationLines">
        {(fields) => (
          <Collapse
            key="operation_lines"
            activeKey={openCollapsePanels}
            onChange={onOpenPanel}
            expandIconPosition="end"
          >
            {fields.map((field) => {
              const { key } = field;
              const keyStr = String(key);
              return (
                <Panel
                  header={getHeaderOperationLine(
                    titles.find((item) => item.index === key) ||
                      ({} as TitleType)
                  )}
                  key={key}
                >
                  <>
                    <Row gutter={16}>
                      {linesData && linesData.length === 0 && (
                        <span>
                          É necessário ter alguma linha cadastrada ao berço da
                          atracação({selectedDockingPlaceTag})
                        </span>
                      )}
                      <FormItemRadioGroup
                        label="Selecione a linha"
                        name={[keyStr, 'line', 'id']}
                        options={linesData}
                        required
                        onChange={(e) => onSetTitles(key, e.target.value)}
                      />
                    </Row>
                    <Row gutter={16}>
                      <FormItemInput
                        colSpan={12}
                        label="Identificação da linha"
                        name={[keyStr, 'name']}
                        onChange={(e) => onSetTitles(key, 0, e.target.value)}
                        maxLength={100}
                      />
                      <FormItemSelect
                        colSpan={12}
                        name={[keyStr, 'operation_product_id']}
                        label="Selecione o produto"
                        dataList={operationProducts}
                      />
                    </Row>
                    <Title>CONEXÃO</Title>
                    <Row gutter={16}>
                      <FormItemDatePicker
                        label="Hora real de conexão"
                        disabledDate={disabledDateAfterToday}
                        name={[keyStr, 'real_connection_hour']}
                        colSpan={12}
                      />
                      <FormItemDatePicker
                        label="Hora real de desconexão"
                        disabledDate={disabledDateAfterToday}
                        name={[keyStr, 'real_desconnection_hour']}
                        colSpan={12}
                      />
                    </Row>
                    <Title>BOMBEIO</Title>
                    <Row gutter={16}>
                      <FormItemDatePicker
                        label="Início de bombeio"
                        name={[keyStr, 'real_pump_start']}
                        colSpan={12}
                      />
                      <FormItemDatePicker
                        label="Término de bombeio"
                        name={[keyStr, 'real_pump_finish']}
                        colSpan={12}
                      />
                    </Row>
                  </>
                </Panel>
              );
            })}
          </Collapse>
        )}
      </FormList>
    </Form>
  );
}
