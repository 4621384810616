import { maskString } from '../../../utils/utils';
import { ConsigneeListItemTitle } from './styles';

type ConsigneeListItemProps = {
  companyName: string;
  companyCode: string;
  merchantCECodes: string[];
  button?: React.ReactNode;
};

export function ConsigneeListItem(props: ConsigneeListItemProps) {
  const { companyName, companyCode, merchantCECodes, button } = props;

  return (
    <ConsigneeListItemTitle>
      <div>
        <div>
          <span className="name">{companyName}</span>
          <span className="code">
            {maskString(companyCode, '##.###.###/####-##')}
          </span>
          <div className="items">{merchantCECodes.join(', ')}</div>
        </div>
      </div>
      <div>{button}</div>
    </ConsigneeListItemTitle>
  );
}
