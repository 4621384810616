import { FileOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';

import { Collapse } from '../../../components';
import { OperationProductHeader } from '../../../components/common/cargoOperationHeader/styles';
import {
  OperationalOperationType,
  OperationCalculusType,
  OperationContextType,
  OperationProductAndCraneType,
  OperationProductCalculusType,
  Stopover as StopoverType,
} from '../../../types';
import { ContainersByCraneModal } from '../../operation/OperationsData/operationDescriptionsSections/generalCargo/containers/containersByCraneModal/ContainersByCraneModal';
import { OperationCraneResumeDescription } from '../../operation/OperationsData/operationDescriptionsSections/generalCargo/containers/operationCraneResumeDescription';
import { EmptyOperationPlaceholder } from '../../operation/OperationsData/styles';
import { GeneralCargoOperationProductsDescription } from './generalCargoOperationProductDescription';
import { LiquidBulkOperationProductDescription } from './liquidBulkOperationProductDescription';

type OperationProductsDescriptionProps = {
  selectedOperationProducts: OperationProductAndCraneType[] | [];
  operation: OperationalOperationType;
  operationType: OperationContextType;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  onEdit: (evt: any, index: number) => void;
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  operationCalculusData: OperationCalculusType;
  isProvisioned?: boolean;
};

const { Panel } = Collapse;

export function OperationProductsDescription(
  props: OperationProductsDescriptionProps
) {
  const {
    selectedOperationProducts,
    operation,
    operationType,
    activeKey,
    onOpenPanel,
    onEdit,
    selectedStopover,
    setSelectedStopover,
    operationCalculusData,
    isProvisioned,
  } = props;

  const [isVisibleContainersDrawer, setIsVisibleContainersDrawer] =
    useState(false);

  const isContainers = operationType === 'CONTAINER';
  const isLiquidBulk = operationType === 'LIQUID_BULK';

  function getHeader(
    cargoType: string,
    productGroup: string,
    operatorCompany: string
  ) {
    return (
      <OperationProductHeader>
        <span className="cargo-type">{cargoType}</span>
        <span className="product-group">{productGroup}</span>
        <span className="operator-company">{operatorCompany}</span>
      </OperationProductHeader>
    );
  }

  function onEditContainers(evt: any, index: number) {
    setIsVisibleContainersDrawer(true);
  }

  return isEmpty(selectedOperationProducts) ? (
    <EmptyOperationPlaceholder>
      <FileOutlined style={{ fontSize: '30px', marginBottom: '10px' }} />
      <div>Sem produtos cadastrados</div>
    </EmptyOperationPlaceholder>
  ) : isContainers ? (
    <>
      <ContainersByCraneModal
        containers={[]}
        isVisible={isVisibleContainersDrawer}
        setIsVisible={setIsVisibleContainersDrawer}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        pageContext="OPERATOR_AREA"
      />
      <Collapse
        activeKey={activeKey}
        key="collapse_containers"
        onChange={onOpenPanel}
        expandIconPosition="end"
      >
        <Panel forceRender header="Contêineres" key="containers">
          <OperationCraneResumeDescription
            selectedOperation={operation || ({} as OperationalOperationType)}
            selectedStopover={selectedStopover}
          />
          <GeneralCargoOperationProductsDescription
            operationType={operationType}
            operation={operation}
            selectedStopover={selectedStopover}
            setSelectedStopover={setSelectedStopover}
            operationProductCalculusData={{} as OperationProductCalculusType}
            operationCalculusData={{} as OperationCalculusType}
          />
        </Panel>
      </Collapse>
    </>
  ) : (
    <>
      {selectedOperationProducts.map((product, productIndex) => {
        if (
          product.operation_release !== 'PENDING' &&
          product.operation_release !== 'NOT_ACCEPTED'
        ) {
          return (
            <Collapse
              activeKey={activeKey}
              key={`collapse_${productIndex}`}
              onChange={onOpenPanel}
              expandIconPosition="end"
            >
              <Panel
                forceRender
                header={getHeader(
                  product.cargo_type?.name || '',
                  product.cargo_type?.product_group?.name || '',
                  product.operator.name || ''
                )}
                key={`product_${productIndex}`}
              >
                {isLiquidBulk ? (
                  <LiquidBulkOperationProductDescription
                    operationProduct={product}
                    operationType={operationType}
                    selectedStopover={selectedStopover}
                    setSelectedStopover={setSelectedStopover}
                    operationProductCalculusData={
                      operationCalculusData?.operation_products?.find(
                        (item) => item.id === product.id
                      ) || ({} as OperationProductCalculusType)
                    }
                    isProvisioned={isProvisioned}
                  />
                ) : (
                  !isContainers && (
                    <GeneralCargoOperationProductsDescription
                      operationProduct={product}
                      operationType={operationType}
                      operation={operation}
                      selectedStopover={selectedStopover}
                      setSelectedStopover={setSelectedStopover}
                      operationProductCalculusData={
                        operationCalculusData?.operation_products?.find(
                          (item) => item.id === product.id
                        ) || ({} as OperationProductCalculusType)
                      }
                      operationCalculusData={operationCalculusData}
                      isProvisioned={isProvisioned}
                    />
                  )
                )}
              </Panel>
            </Collapse>
          );
        }
      })}
    </>
  );
}
