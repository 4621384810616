import { Descriptions, FormatItem, Title } from '../../../../../components';
import { DescriptionSection } from '../styles';

type LiquidBulkProductivityDescriptionProps = {
  connectionTimeStart: string | null;
  connectionTimeEnd: string | null;
  pumpTimeStart: string | null;
  pumpTimeEnd: string | null;
};
export function LiquidBulkProductivityDescription(
  props: LiquidBulkProductivityDescriptionProps
) {
  const { connectionTimeStart, connectionTimeEnd, pumpTimeStart, pumpTimeEnd } =
    props;

  const { Item } = Descriptions;

  return (
    <DescriptionSection>
      <div>
        <Title>TEMPOS</Title>
      </div>
      <Descriptions column={2}>
        <Item label="Conexão">
          <div>
            <FormatItem>{connectionTimeStart}</FormatItem>
            <span> até </span>
            <FormatItem>{connectionTimeEnd}</FormatItem>
          </div>
        </Item>
        <Item label="Bombeio">
          <div>
            <FormatItem>{pumpTimeStart}</FormatItem>
            <span> até </span>
            <FormatItem>{pumpTimeEnd}</FormatItem>
          </div>
        </Item>
      </Descriptions>
    </DescriptionSection>
  );
}
