import {
  DollarCircleOutlined,
  EyeFilled,
  FileTextOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Flag from 'react-flagpack';

import { Button, Checkbox, ErrorBoundary } from '../../../../components';
import { StopoverGroupbyDockingStatisticsType } from '../../../../types';
import { createDateStringPtBr } from '../../../../utils/utils';
import { InvoicingStatusTag } from '../InvoicingStatusTag';

export function columns(
  dockingsIdToEdit: number[],
  onSelectItem: (e: boolean, record: number) => void,
  onOpenDrawer: (docking: StopoverGroupbyDockingStatisticsType) => void,
  checkedAll: boolean,
  onCheckedAll: (value: boolean) => void,
  onCreateCharge: (docking_id: number) => void
): ColumnsType<Record<string, any>> {
  return [
    {
      title() {
        return (
          <Checkbox
            defaultChecked={checkedAll}
            checked={checkedAll}
            onChange={(e) => onCheckedAll(e.target.checked)}
          />
        );
      },
      width: '5%',
      dataIndex: 'is_selected',
      render(value, record) {
        return (
          <Checkbox
            defaultChecked={value}
            disabled={record.docking_place.antaq_code === null}
            checked={
              dockingsIdToEdit.find((id) => id === record.docking__id) !==
              undefined
            }
            onChange={(e) => onSelectItem(e.target.checked, record.docking__id)}
          />
        );
      },
    },
    {
      title: 'atracação',

      dataIndex: 'code',
      width: '10%',
      sorter: {
        compare: (a: any, b: any) => a.code?.localeCompare(b.code),
        multiple: 1,
      },
    },
    {
      title: 'embarcação',
      dataIndex: 'vessel__flag',
      width: '15%',
      sorter: {
        compare: (a: any, b: any) =>
          a.vessel__name?.localeCompare(b.vessel__name),
        multiple: 1,
      },
      render: (value, record) => {
        return (
          <div style={{ display: 'flex', gap: '4px' }}>
            <div style={{ width: '20px' }}>
              {value && (
                <ErrorBoundary replace="">
                  <Flag code={value} size="m" />
                </ErrorBoundary>
              )}
            </div>
            <span
              style={{
                textTransform: 'capitalize',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {record.vessel__name?.toLowerCase() || ''}
            </span>
          </div>
        );
      },
    },
    {
      title: 'finalidade',
      dataIndex: ['docking_goal', 'description'],
      width: '15%',
      sorter: {
        compare: (a: any, b: any) =>
          a.docking_goal?.description?.localeCompare(
            b.docking_goal?.description
          ),
        multiple: 1,
      },
    },
    {
      title: 'berço',
      dataIndex: ['docking_place', 'name'],
      width: '10%',
      sorter: {
        compare: (a: any, b: any) =>
          a.docking_place?.name?.localeCompare(b.docking_place?.name),
        multiple: 1,
      },
    },
    {
      title: 'desatracação',
      dataIndex: 'last_cable_untied_in',
      width: '13%',
      sorter: {
        compare: (a: any, b: any) =>
          a.last_cable_untied_in?.localeCompare(b.last_cable_untied_in),
        multiple: 1,
      },
      render: (value) => createDateStringPtBr(value),
    },
    {
      title: 'faturamento',
      dataIndex: 'invoicing_status',
      width: '13%',
      render: (value) => (
        <InvoicingStatusTag status={value?.invoicing_status || ''} />
      ),
    },
    {
      title: 'status xml',
      dataIndex: 'last_date_xml',
      width: '11%',
      sorter: {
        compare: (a: any, b: any) =>
          a.last_date_xml?.localeCompare(b.last_date_xml),
        multiple: 1,
      },
      render: (value) =>
        value ? (
          createDateStringPtBr(value)
        ) : (
          <div>
            <FileTextOutlined style={{ color: 'var(--neutral_medium)' }} />
            <i style={{ color: 'var(--neutral_medium)', marginLeft: '5px' }}>
              Ainda não gerado
            </i>
          </div>
        ),
    },
    {
      title: '',
      width: '9%',
      align: 'right' as const,
      render: (_, record) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'right',
            }}
          >
            <Button
              icon={<EyeFilled />}
              onClick={() =>
                onOpenDrawer(record as StopoverGroupbyDockingStatisticsType)
              }
            />
            <Tooltip title="Gerar faturamento">
              <Button
                icon={<DollarCircleOutlined />}
                onClick={() => onCreateCharge(record.docking__id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
}
