import { EditFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import { GeneralCargoStorageSummaryTable } from '../../features/operation/OperationsData/operationDescriptionsSections/generalCargo/storageSummaryTable';
import { VehiclesStorageSummaryTable } from '../../features/operation/OperationsData/operationDescriptionsSections/generalCargo/vehicles/vehiclesStorageSummaryTable';
import { LiquidBulkStorageSummaryTable } from '../../features/operation/OperationsData/operationDescriptionsSections/liquidBulk/liquidBulkStorageSummaryTable';
import { OperationProductManifests } from '../../features/operation/OperationsData/operationDescriptionsSections/operationProductsManifests';
import { SolidBulkStorageSummaryTable } from '../../features/operation/OperationsData/operationDescriptionsSections/solidBulk/solidBulkStorageSummaryTable';
import { CompartmentEditDrawer } from '../../features/operatorArea/operationProductsData/compartmentEditDrawer/CompartmentEditDrawer';
import {
  OperationProductAndCraneType,
  OperationProductCompartmentType,
  OperationProductTypeDescription,
  Stopover as StopoverType,
} from '../../types';
import { formatNumberToLocale } from '../../utils/utils';
import { Button } from '../antd/Button';
import { Descriptions } from '../antd/Descriptions';
import { FormatItem } from './FormatItem';
import { Title } from './Title';

const { Item } = Descriptions;

const compartmentTypes = {
  HOLD: 'Porão',
  DECK: 'Deck',
  TANK: 'Tanque',
};

const unitLabels = {
  UNITS: 'Un',
  TONNES: 'Ton',
  CUBIC_METERS: 'm³',
};
const ItalicItem = styled.span`
  font-style: italic;
`;

type ProductDescriptionProps = {
  operationProduct: OperationProductTypeDescription;
  selectedOperationProduct?: OperationProductAndCraneType;
  isVehicles: boolean;
  isLiquidBulk: boolean;
  isSolidBulk: boolean;
  unity: string;
  setSelectedStopover?: Dispatch<SetStateAction<StopoverType>>;
  selectedStopover?: StopoverType;
};

export function ProductDescription(props: ProductDescriptionProps) {
  const {
    operationProduct,
    selectedOperationProduct,
    isVehicles,
    isLiquidBulk,
    isSolidBulk,
    unity,
    setSelectedStopover,
    selectedStopover,
  } = props;

  const [isVisibleCompartmentsDrawer, setIsVisibleCompartmentsDrawer] =
    useState(false);

  function totalWeightTooltip() {
    const totalSum = operationProduct.all_merchants_ce
      .map((merchantCE) =>
        merchantCE.storages
          .map((storage) => storage.total_weight)
          .reduce((prev, next) => (prev || 0) + (next || 0), 0)
      )
      .reduce((prev, next) => (prev || 0) + (next || 0), 0);

    return `${formatNumberToLocale(totalSum)} ton`;
  }

  return (
    <>
      <CompartmentEditDrawer
        isVisibleForm={isVisibleCompartmentsDrawer}
        setIsVisibleForm={setIsVisibleCompartmentsDrawer}
        selectedProduct={
          selectedOperationProduct || ({} as OperationProductAndCraneType)
        }
        setSelectedStopover={setSelectedStopover}
        selectedStopover={selectedStopover || ({} as StopoverType)}
      />

      {selectedStopover && selectedStopover.id && (
        <OperationProductManifests
          operationProduct={selectedOperationProduct}
        />
      )}
      <Divider />
      <Title>CARGA</Title>
      <Descriptions column={2}>
        <Item label="Compartimentos">
          <FormatItem>
            {operationProduct.compartments
              ?.map((compartment: OperationProductCompartmentType) => {
                return {
                  name: `${compartmentTypes[compartment.compartment_type]} ${
                    compartment.name
                  }`,
                  quantity: compartment.quantity
                    ? `(${formatNumberToLocale(compartment.quantity)} ${
                        unitLabels[compartment.unit]
                      })`
                    : '',
                };
              })
              .flatMap(
                (compartment: { name: string; quantity: string }, index) => (
                  <span key={index}>
                    {compartment.name}{' '}
                    <ItalicItem>{compartment.quantity}</ItalicItem>
                  </span>
                )
              )}
            <Button
              style={{ marginTop: '-8px', marginLeft: '0px' }}
              type="link"
              onClick={() => setIsVisibleCompartmentsDrawer(true)}
              // disabled={!(totalStoppages > 0)}
              icon={<EditFilled />}
            />
          </FormatItem>
        </Item>
      </Descriptions>
      {isVehicles ? (
        <VehiclesStorageSummaryTable product={operationProduct} />
      ) : isLiquidBulk ? (
        <LiquidBulkStorageSummaryTable product={operationProduct} />
      ) : isSolidBulk ? (
        <SolidBulkStorageSummaryTable product={operationProduct} />
      ) : (
        <GeneralCargoStorageSummaryTable product={operationProduct} />
      )}
    </>
  );
}
