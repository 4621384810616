import { Descriptions, FormatItem } from '../../../../../components';
import { BerthMaintenance, DockingPlace } from '../../../../../types';
import { ExpandedRowMaintenanceWrapper } from './styles';

const { Item } = Descriptions;

type ExpandedRowMaintenanceProps = {
  record: BerthMaintenance;
};

export function ExpandedRowMaintenance(props: ExpandedRowMaintenanceProps) {
  const { record } = props;
  return (
    <ExpandedRowMaintenanceWrapper>
      <Descriptions column={2}>
        <Item label="Berços impactados">
          <FormatItem>
            {record.berths.map(
              (berth: DockingPlace | string, index: number) => (
                <span key={index}>{`${
                  record.id ? (berth as DockingPlace).name : (berth as string)
                }${index !== record.berths.length - 1 ? ', ' : ''}`}</span>
              )
            )}
          </FormatItem>
        </Item>
        <Item label="Responsável">
          <FormatItem>
            {record.responsible?.first_name} {record.responsible?.last_name}
          </FormatItem>
        </Item>
      </Descriptions>
      <Descriptions column={2}>
        <Item label="Permitirá bombeios (das 18:00 às 06:00)?">
          <FormatItem>{record.allow_night_pump ? 'Sim' : 'Não'}</FormatItem>
        </Item>
        <Item label="Permitirá bombeios e navios nos fins de semana?">
          <FormatItem>
            {record.allow_weekend_pump_and_vessel ? 'Sim' : 'Não'}
          </FormatItem>
        </Item>
      </Descriptions>
    </ExpandedRowMaintenanceWrapper>
  );
}
