export function hourRenderer(hourProps: any) {
  const hour = parseInt(
    hourProps.intervalContext.intervalText.split(':')[0],
    10
  );
  const hourClassName =
    hour >= 20 || hour <= 2
      ? 'night'
      : hour === 3 || hour === 19
      ? 'mid-night'
      : hour === 4 || hour === 18
      ? 'earlier-day'
      : hour === 5 || hour === 17
      ? 'early-day'
      : hour >= 6 || hour <= 16
      ? 'day-light'
      : null;
  return (
    <div
      {...hourProps.getIntervalProps()}
      className={`rct-dateHeader hour ${hourClassName}`}
    >
      {hourProps.intervalContext.intervalText.split(':')[0]}
    </div>
  );
}
