export function LogoSuape() {
  return (
    <svg
      id="LogoSuape"
      xmlns="http://www.w3.org/2000/svg"
      width="115.495"
      height="22.613"
      viewBox="0 0 115.495 22.613"
    >
      <g data-name="Logo suape">
        <path
          d="M.029 12.539c-.02-.438-.042-.877-.02-1.315h4.266a5.778 5.778 0 0 0 .9 2.845 4.261 4.261 0 0 0 3.238 1.941c2.44.222 5.123-1.307 5.48-3.842a4.64 4.64 0 0 1 1.72-4.348 4.352 4.352 0 0 1 6.759 4.152c.269 1.092-1.886 9.807-10.347 10.6a8.5 8.5 0 0 1-.877.045C6.211 22.612.422 18.536.029 12.539zm5.769 1.4a4.527 4.527 0 0 1-1-3.3C4.761 9 6.748.15 15.147.042a11.494 11.494 0 0 1 8.128 2.669 10.8 10.8 0 0 1 2.568 3.006 9.764 9.764 0 0 1 1.323 5.507h-4.275c-.2-2.635-1.488-4.057-3.032-4.583a5.564 5.564 0 0 0-4.975.916 3.841 3.841 0 0 0-1.605 2.885 4.737 4.737 0 0 1-1.1 3.666A4.208 4.208 0 0 1 9.066 15.4 4.353 4.353 0 0 1 5.8 13.935z"
          style={{ fill: '#003804' }}
        />
        <path
          d="M361.357-387.827v4.922h-14.01v-4.922zm0 8.141v4.922h-8.33v2.84h8.33V-367h-14.01v-12.685zm-30.67-8.141h7.123a12.942 12.942 0 0 1 3.357.357 5.7 5.7 0 0 1 2.265 1.221 6.131 6.131 0 0 1 1.632 2.243 6.718 6.718 0 0 1 .594 2.746 6.441 6.441 0 0 1-1.027 3.524 5.821 5.821 0 0 1-2.486 2.281 9.257 9.257 0 0 1-3.87.692h-1.908V-367h-5.68v-12.685h6.536a3.671 3.671 0 0 0 1.927-.336 1.4 1.4 0 0 0 .586-1.2 1.238 1.238 0 0 0-.57-1.145 3.8 3.8 0 0 0-1.894-.348h-6.585zm-7.325 0L329.877-367h-19.525l4.177-12.685h5.191l-2.215 7.762h5.355l-5.673-15.9zm-14.258 0V-367h-9.651a7.589 7.589 0 0 1-4.089-.883 5.009 5.009 0 0 1-1.943-2.3 10.122 10.122 0 0 1-.6-3.8v-13.837h5.68v13.109a3.3 3.3 0 0 0 .458 2.107 2.626 2.626 0 0 0 1.913.5h2.552v-15.714zm-24.683 0a8 8 0 0 0-.151 1.324 2.8 2.8 0 0 0 .393 1.378 11.452 11.452 0 0 0 1.395 1.841c1.357 1.556 2.3 2.66 2.81 3.316a12.932 12.932 0 0 1 1.244 1.874 7.808 7.808 0 0 1 1.088 3.871 7.421 7.421 0 0 1-1.007 3.758q-1.987 3.457-7.129 3.462H277.3v-5.112h5.076a2.841 2.841 0 0 0 2.273-.8 2.159 2.159 0 0 0 .562-1.412 3.325 3.325 0 0 0-.62-1.84c-.417-.62-1.455-1.888-3.129-3.808a15.743 15.743 0 0 1-2.647-3.728 5.766 5.766 0 0 1-.487-2.187 12.277 12.277 0 0 1 .214-1.936h5.882"
          transform="translate(-245.862 388.64)"
          style={{ fill: '#003804' }}
        />
      </g>
    </svg>
  );
}
