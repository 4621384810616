import { useEffect, useState } from 'react';

import { Modal } from '../../components';
import { EndOperationModal } from './endOperationModal';
import { ReleaseOperationModal } from './releaseOperationModal';
import { StartOperationModal } from './startOperationModal';
import { BaseActionModalProps } from './staymentActions';

type OperationActionsModalProps = BaseActionModalProps & {
  releaseOperation: () => void;
  startOperation: () => void;
  endOperation: () => void;
};

export function OperationActionsModal(props: OperationActionsModalProps) {
  const [modalTitle, setModalTitle] = useState('');
  const {
    isVisible,
    setIsVisible,
    stopover,
    currentDocking,
    releaseOperation,
    startOperation,
    endOperation,
    updateDocking,
    status,
    isLoadingUpdateDocking,
  } = props;

  useEffect(() => {
    if (status === 'RELEASE_OPERATION') {
      setModalTitle('Liberar operação');
    } else if (status === 'START_OPERATION') {
      setModalTitle('Iniciar operação');
    } else if (status === 'END_OPERATION') {
      setModalTitle('Finalizar operação');
    }
  });
  return (
    <Modal
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      title={modalTitle}
      className="TOSPrimaryModal"
      width={624}
      footer={null}
    >
      {status === 'RELEASE_OPERATION' ? (
        <ReleaseOperationModal
          setIsVisible={setIsVisible}
          onRelease={releaseOperation}
          stopover={stopover}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : status === 'START_OPERATION' ? (
        <StartOperationModal
          setIsVisible={setIsVisible}
          onStartOperation={startOperation}
          updateDocking={updateDocking}
          currentDocking={currentDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : status === 'END_OPERATION' ? (
        <EndOperationModal
          setIsVisible={setIsVisible}
          onEndOperation={endOperation}
          updateDocking={updateDocking}
          currentDocking={currentDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : null}
    </Modal>
  );
}
