import 'react-calendar-timeline/lib/Timeline.css';
import 'moment/locale/pt-br';
import { EyeFilled, FilterFilled } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { Dispatch, ElementType, ReactNode, SetStateAction } from 'react';
import {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
  ReactCalendarGroupRendererProps,
  TimelineGroupBase,
} from 'react-calendar-timeline';

import { ScreenLayout, Button, Wrapper } from '../../components';
import {
  BerthMaintenance as BerthMaintenanceType,
  Product as ProductType,
  ShipType,
  Stopover as StopoverType,
} from '../../types';
import { berthRowHeight } from '../../utils/utils';
import { AnchoringWindowTimeLine } from './anchoringWindowTimeLine';
import { dayRenderer } from './dayRenderer';
import { hourRenderer } from './hourRenderer';

moment.locale('pt-br');

type GroupType = {
  id: string;
  height: number;
  title: ReactNode;
  type: string;
  name: string;
  dwt?: number;
  docking_place_type?: string;
  ship_types?: ShipType[];
};

export type ItemType = {
  id: number;
  group: string;
  title: string;
  start_time: number;
  end_time: number;
  type: 'DOCKING' | 'MAINTENANCE' | 'PUMPING_ORIGIN' | 'PUMPING_DESTINATION';
  canMove?: boolean;
  canResize?: boolean;
  index?: number;
  code?: string;
  expected_start?: string | null | Moment;
  expected_finish?: string | null | Moment;
  name?: string;
  subtitle?: string;
  hover?: string;
  docking_place?: { tag: string; name: string; place_type: string };
  stopover?: StopoverType;
  products?: ProductType[];
  input_dwt?: number;
  docking_in_line?: string;
  docking_alongside?: string;
  // campos de manutenção
  companies?: string;
  maintenanceId?: number;
  maintenance?: BerthMaintenanceType;
};

type TimelineWindowProps = {
  isLoadingData?: boolean;
  isSuccessData?: boolean;
  onItemSelect: (itemId: number) => void;
  onItemDeselect: () => void;
  ItemRenderer: ElementType; // função q retorna renderização do item
  sidebar: any; // função para abrir nova gaveta
  groupsToRender: GroupType[];
  itemsToRender: ItemType[];
  itemMove: any;
  itemResize: (itemId: number, time: number, edge: string) => void;
  selectedItems: number[];
  updateButton: ReactNode; // botão para atualizar dados da janela
  onBoundsChange: (canvasTimeStart: number, canvasTimeEnd: number) => void; // called when callendar bounds change
  groupRenderer: (
    groupProps: ReactCalendarGroupRendererProps<TimelineGroupBase>,
    itemsToRender: ItemType[],
    onClickGroupItem: (item: ItemType) => void
  ) => JSX.Element;
  onClickGroupItem: (item: ItemType) => void;
  title: string;
  StatusButtonsCol: (props: any) => JSX.Element;
  rangeHour?: number;
  timeStartEnd: { start: Moment; end: Moment };
  setTimeStartEnd: Dispatch<SetStateAction<{ start: Moment; end: Moment }>>;
  setQueryStartEndTime: Dispatch<
    SetStateAction<{ start: Moment; end: Moment }>
  >;
  timeRendering: Date;
  setTimeRendering: Dispatch<SetStateAction<Date>>;
  queryStartEndTime: { start: Moment; end: Moment };
  propsItemRender: { [key: string]: (...args: any[]) => void };
  subtitle?: string;
};

export function TimelineWindow(props: TimelineWindowProps) {
  const {
    isLoadingData,
    isSuccessData,
    onItemSelect,
    onItemDeselect,
    ItemRenderer,
    sidebar,
    groupsToRender,
    itemsToRender,
    itemMove,
    itemResize,
    selectedItems,
    updateButton,
    onBoundsChange,
    groupRenderer,
    onClickGroupItem,
    title,
    StatusButtonsCol,
    rangeHour,
    timeStartEnd,
    setTimeStartEnd,
    timeRendering,
    setTimeRendering,
    setQueryStartEndTime,
    queryStartEndTime,
    propsItemRender,
    subtitle,
  } = props;

  // called everytime time rendered changes
  function onTimeChange(
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: any
  ) {
    setTimeRendering(new Date(visibleTimeStart));
    setTimeStartEnd({
      start: moment(visibleTimeStart),
      end: moment(visibleTimeEnd),
    });
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  }

  function setToday() {
    setTimeStartEnd({
      start: moment().set({ hour: 0, minute: 0, second: 0 }),
      end: moment().set({ hour: 0, minute: 0, second: 0 }).add(36, 'hour'),
    });
    setTimeRendering(new Date());
  }

  return (
    <ScreenLayout title={title} subtitle={subtitle}>
      <Row align="middle" style={{ padding: '10px' }}>
        <Col span={6}>
          <Button icon={<FilterFilled />} size="large">
            Filtros
          </Button>
          {updateButton}
        </Col>
        <Col span={6}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
              {moment(timeRendering).format('MMM')}
            </span>
            <span
              style={{
                fontSize: '24px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: 'var(--neutral_medium)',
              }}
            >
              {` ${moment(timeRendering).format('YYYY')}`}
            </span>
            <Button
              size="small"
              style={{ marginLeft: '20px' }}
              icon={<EyeFilled />}
              onClick={() => setToday()}
            >
              Ver hoje
            </Button>
          </div>
        </Col>
        <Col span={12}>
          <StatusButtonsCol itemsToRender={itemsToRender} />
        </Col>
      </Row>
      <Spin spinning={isLoadingData} tip="Carregando...">
        <Wrapper
          style={{
            padding: '15px',
            maxHeight: '80vh',
          }}
        >
          <div
            style={{
              height: 'auto',
              overflowY: 'auto',
            }}
          >
            {isSuccessData && (
              <AnchoringWindowTimeLine
                groups={groupsToRender}
                items={itemsToRender}
                defaultTimeStart={timeStartEnd.start}
                defaultTimeEnd={timeStartEnd.end}
                visibleTimeStart={Number(timeStartEnd.start.format('x'))}
                visibleTimeEnd={Number(timeStartEnd.end.format('x'))}
                canMove
                canResize="both"
                itemHeightRatio={0.75}
                itemTouchSendsClick
                stackItems={false}
                itemRenderer={(itemProps) => (
                  <ItemRenderer
                    itemProps={itemProps}
                    itemsToRender={itemsToRender}
                    entries={propsItemRender}
                  />
                )}
                groupRenderer={(groupProps) =>
                  groupRenderer(groupProps, itemsToRender, onClickGroupItem)
                }
                sidebarWidth={400}
                onItemResize={itemResize}
                onItemMove={itemMove}
                onTimeChange={onTimeChange}
                onBoundsChange={debounce(onBoundsChange, 1500)}
                selected={selectedItems}
                onItemSelect={onItemSelect}
                onItemDeselect={onItemDeselect}
                lineHeight={berthRowHeight}
                timeSteps={{
                  second: 1,
                  minute: 1,
                  hour: rangeHour || 2,
                  day: 1,
                  month: 1,
                  year: 1,
                }}
              >
                <TimelineHeaders
                  style={{ position: 'sticky', top: '0', zIndex: 91 }}
                >
                  <SidebarHeader>{() => sidebar()}</SidebarHeader>
                  <DateHeader
                    unit="day"
                    intervalRenderer={(dayProps) => dayRenderer(dayProps)}
                    height={62}
                  />
                  <DateHeader
                    unit="hour"
                    labelFormat="HH"
                    intervalRenderer={(hourProps) => hourRenderer(hourProps)}
                  />
                </TimelineHeaders>
                <TimelineMarkers>
                  <TodayMarker date={moment().toDate()}>
                    {({ styles }) => (
                      <div style={styles} className="tos-today-marker" />
                    )}
                  </TodayMarker>
                </TimelineMarkers>
              </AnchoringWindowTimeLine>
            )}
          </div>
        </Wrapper>
      </Spin>
    </ScreenLayout>
  );
}
