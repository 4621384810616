import {
  FareFormType,
  FareType,
  TableOneItemType,
  TableThreeItemType,
} from '../../../../typesFinancial';

export function formatApportmentTableOneToSave(
  values: FareFormType,
  index: number,
  selectedFare: FareType,
  item: TableOneItemType
) {
  if (values.tables) {
    return (
      values.tables[0].items[index].childs
        ?.filter((childItem) => childItem.id === undefined) // apenas processa os novos itens de rateio
        .map((childItem) => {
          let proratedValue = 0;
          if (item.value && item.dwt) {
            proratedValue =
              (item.value / item.dwt) * childItem.prorated_amount || 0;
          }
          return {
            id: selectedFare.id,
            company: childItem.fare_company_id, // empresa selecionada no rateio
            table_one: {
              // pode ser one ou three
              items: [
                {
                  parent_id: item.id, // id do item rateado
                  code: item.code,
                  fee: item.fee,
                  segregated_billing: item.segregated_billing,
                  number: item.number,
                  name: item.name,
                  product_group:
                    item.product_group?.map(
                      (productGroup) => productGroup.id || 0
                    ) || [],
                  value: +proratedValue.toFixed(2),
                  navigation_type: item.navigation_type?.id,
                  operation_type: item.operation_type?.id,
                  observations: item.observations,
                  dwt: childItem.prorated_amount,
                },
              ],
            },
          };
        }) || []
    );
  }
  return [];
}

export function formatApportmentTableThreeToSave(
  values: FareFormType,
  index: number,
  selectedFare: FareType,
  item: TableThreeItemType
) {
  if (values.tables) {
    return (
      values.tables[0].items[index].childs?.map((childItem) => {
        let proratedValue = 0;
        if (item.value && item.movimented_cargo) {
          proratedValue =
            (item.value / item.movimented_cargo) * childItem.prorated_amount ||
            0;
        }
        return {
          id: selectedFare.id,
          company: childItem.fare_company_id, // empresa selecionada no rateio
          table_three: {
            // pode ser one ou three
            items: [
              {
                parent_id: item.id, // id do item rateado
                code: item.code,
                fee: item.fee,
                segregated_billing: item.segregated_billing,
                number: item.number,
                name: item.name,
                value: +proratedValue.toFixed(2),
                cargo_type: item.cargo_type?.name,
                observations: item.observations,
                movimented_cargo: childItem.prorated_amount,
              },
            ],
          },
        };
      }) || []
    );
  }
  return [];
}
