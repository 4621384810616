import { BerthMaintenance, GenericResponse } from '../types';
import { tosApi } from './tosApi';

const BASE_URL = '/maritime/berth-maintenance';

export const maintenancesApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getMaintenances: build.query<
      GenericResponse & { results: BerthMaintenance[] },
      {
        berth?: string;
        initial_expected_start?: string;
        final_expected_start?: string;
      }
    >({
      query: (params) => {
        return {
          url: BASE_URL,
          method: 'GET',
          params,
        };
      },
      providesTags: () => [{ type: 'BerthMaintenance', code: '1' }],
    }),
    getMaintenance: build.query<BerthMaintenance, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}`,
          method: 'GET',
        };
      },
    }),
    createMaintenance: build.mutation<BerthMaintenance, BerthMaintenance>({
      query: (maintenance) => {
        return {
          url: BASE_URL,
          method: 'POST',
          body: maintenance,
        };
      },
      invalidatesTags: () => [
        { type: 'BerthMaintenance', code: '1' },
        { type: 'DockingPlace', code: '1' },
      ],
    }),
    updateMaintenance: build.mutation<BerthMaintenance, BerthMaintenance>({
      query: (maintenance) => {
        return {
          url: `${BASE_URL}/${maintenance.id}`,
          method: 'PUT',
          body: maintenance,
        };
      },
      invalidatesTags: () => [
        { type: 'BerthMaintenance', code: '1' },
        { type: 'DockingPlace', code: '1' },
      ],
    }),
    deleteMaintenance: build.mutation<void, number>({
      query: (id) => {
        return {
          url: `${BASE_URL}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => [{ type: 'BerthMaintenance', code: '1' }],
    }),
  }),
});

export const {
  useGetMaintenancesQuery,
  useLazyGetMaintenancesQuery,
  useGetMaintenanceQuery,
  useCreateMaintenanceMutation,
  useUpdateMaintenanceMutation,
  useDeleteMaintenanceMutation,
} = maintenancesApi;
