import {
  Descriptions,
  FormatItem,
  Progress,
  Title,
} from '../../../../../components';
import {
  OperationProductCalculusType,
  OperationProductTypeDescription,
} from '../../../../../types';
import { ProgressLegend } from '../../styles';
import { OperationResumeDescriptionContainer } from '../styles';

type LiquidBulkResumeDescriptionProps = {
  operationProduct: OperationProductTypeDescription;
  operationProductCalculusData: OperationProductCalculusType;
  isProvisioned?: boolean;
};

export function LiquidBulkResumeDescription(
  props: LiquidBulkResumeDescriptionProps
) {
  const { operationProduct, operationProductCalculusData, isProvisioned } =
    props;

  const formatProgressText = (percent?: number) => {
    return `${percent}% operado`;
  };

  return (
    <OperationResumeDescriptionContainer>
      {/* <StoppagesByPeriodDrawer
        selectedWorkPeriods={operationProduct.work_periods}
        visible={isStoppagesByPeriodDrawerVisible}
        setIsVisible={setIsStoppagesByPeriodDrawerVisible}
        operationCode={operationCode}
      /> */}
      <div className="infos">
        <Title>RESUMO DO PRODUTO</Title>
        <Progress
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
          }}
          percent={operationProductCalculusData.product_progress || 0}
          status="active"
          format={formatProgressText}
        />
        <ProgressLegend>
          <div className="legend-item">
            <div className="square green" />
            <span>
              Total operado:
              <strong>
                {`${operationProductCalculusData?.total_movimented} ton` ||
                  '0 ton'}
              </strong>
            </span>
          </div>
          <div className="legend-item">
            <div className="square gray" />
            <span>
              Total manifestado:
              <strong>
                {`${operationProductCalculusData?.total_weight} ton` || '0 ton'}
              </strong>
            </span>
          </div>
        </ProgressLegend>
        {!isProvisioned && (
          <div className="production">
            <div className="production-item">
              <Title>Produtividade</Title>
              <Descriptions column={1}>
                <Descriptions.Item label="Produtividade bruta">
                  <FormatItem>
                    {operationProductCalculusData.gross_production &&
                      `${operationProductCalculusData.gross_production} ton/h`}
                  </FormatItem>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        )}
        {/* <PartialTotalResumeDescription
          operationLength={operationProduct.operation_length}
          netProduction={operationProduct.liquid_flow}
          grossProduction={operationProduct.raw_flow}
          operatingHours={operationProduct.operating_hours}
          nonWorkingHours={operationProduct.non_working_hours}
          // handledTotal={operationProduct.total_handled_str || null}
          totalStoppages={0}
          setIsStoppagesVisible={setIsStoppagesByPeriodDrawerVisible}
          isLiquidBulk
        /> */}
      </div>
    </OperationResumeDescriptionContainer>
  );
}
