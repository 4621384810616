import { Col, Row, Skeleton, Spin, message } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  Form,
  FormItemSelect,
  FormDrawer,
  Title,
  Descriptions,
  FormatItem,
  Select,
  Alert,
  FormItemsCheckbox,
  Modal,
  FormItemRadioGroup,
} from '../../components';
import { useGetLinesQuery } from '../../services/linesApi';
import {
  useGetPumpingByIdQuery,
  usePatchPumpingMutation,
} from '../../services/pumpingApi';
import { Line as LineType, PumpingType } from '../../types';
import {
  createDateStringPtBr,
  formatNumberToLocale,
  isNullOrUndefined,
  returnDiffDaysInHours,
} from '../../utils/utils';

const { Item } = Descriptions;

type ConfirmPumpingFormDrawerProps = {
  pumpingId: number;
};

export function ConfirmPumpingFormDrawer(props: ConfirmPumpingFormDrawerProps) {
  const { pumpingId } = props;
  const [form] = Form.useForm();

  const {
    data: pumpingData,
    isError: isErrorPumpingData,
    isLoading: isLoadingPumpingData,
  } = useGetPumpingByIdQuery(pumpingId || 0, {
    skip: pumpingId === 0,
  });

  const [destinationLine, setDestinationLine] = useState({} as LineType);
  const [isVisible, setIsVisible] = useState(pumpingId > 0);
  const [showModalConfirmLoop10, setShowModalConfirmLoop10] = useState(false);

  const { data: linesData } = useGetLinesQuery({
    storage_company_terminal: pumpingData?.destination_terminal.id,
  });

  useEffect(() => {
    if (pumpingData) {
      form.setFieldsValue(pumpingData);
      setDestinationLine(
        linesData?.find((l) => l.id === pumpingData.destination_line?.id) ||
          ({} as LineType)
      );
    }
  }, [pumpingData, linesData]);

  useEffect(() => {
    if (isErrorPumpingData) {
      setIsVisible(false);
      form.resetFields();
    }
  }, [isErrorPumpingData]);

  function handleSelectDestinationLine(id: number) {
    setDestinationLine(linesData?.find((l) => l.id === id) || ({} as LineType));
  }

  const isDiffBerth = useMemo(() => {
    const isDiffBerth =
      pumpingData?.origin_line.berth &&
      destinationLine.berth &&
      pumpingData?.origin_line.berth !== destinationLine.berth;
    if (isDiffBerth) {
      form.setFieldsValue({ uses_loop10: true });
    }
    return isDiffBerth || false;
  }, [destinationLine]);

  const [patchPumping, { isLoading: isLoadingPatchPumping }] =
    usePatchPumpingMutation();

  function handleFormSubmit(isRefused = false) {
    form.validateFields().then(async (values) => {
      if (isRefused !== true && isNullOrUndefined(values.destination_line.id)) {
        message.error('Para confirmar é necessário informar uma linha');
        return;
      }
      if (
        !values.confirm_loop10 &&
        !values.uses_loop10 &&
        destinationLine?.berth !== pumpingData?.origin_line.berth
      ) {
        setShowModalConfirmLoop10(true);
        return;
      }
      const isRequiredAswerLoop10 =
        destinationLine?.berth !== pumpingData?.origin_line.berth;
      let usesLoop10 = isRequiredAswerLoop10 && values.uses_loop10;
      if (isRequiredAswerLoop10) {
        if (!values.uses_loop10 && values.confirm_loop10) {
          usesLoop10 = values.confirm_loop10 === 'use_loop';
        }
      } else {
        usesLoop10 = values.uses_loop10;
      }

      const pumpingToSave: Partial<PumpingType> = {
        id: Math.abs(pumpingData?.id || 0),
        is_confirmed: isRefused !== true,
        destination_line:
          isRefused === true ? null : values.destination_line.id,
        uses_loop10: usesLoop10,
      };
      const response = await patchPumping(pumpingToSave);
      if ('data' in response) {
        setIsVisible(false);
        form.resetFields();
        message.success(
          `Bombeio ${
            isRefused === true ? 'recusado' : 'confirmado'
          } com sucesso`
        );
      }
    });
  }

  const pumpingDuration = () => {
    if (!pumpingData?.expected_start || !pumpingData?.expected_finish) {
      return 'Bombeio não finalizado';
    }
    return returnDiffDaysInHours(
      pumpingData?.expected_start as string,
      pumpingData?.expected_finish as string
    );
  };

  function lineRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {`${option.name} ${option.berth && `(${option.berth.substring(3)})`}`}
      </Select.Option>
    );
  }

  return (
    <FormDrawer
      isVisible={isVisible}
      title={<strong>Confirmação de bombeio</strong>}
      onClose={() => {
        setIsVisible(false);
        form.resetFields();
      }}
      form={form}
      formName="confirm_pumping"
      handleFormSubmit={handleFormSubmit}
      disabledSaveButton={isLoadingPatchPumping}
      containerStyle={{}}
      saveButtonTitle="Confirmar"
      deleteButtonTitle="Recusar"
      onDelete={() => {
        handleFormSubmit(true);
      }}
    >
      <Modal
        visible={showModalConfirmLoop10}
        title="Confirmação de bombeio Loop10"
        className="TOSPrimaryModal"
        onCancel={() => setShowModalConfirmLoop10(false)}
        width={800}
        okText="Confirmar"
        onOk={() => handleFormSubmit()}
      >
        <div style={{ display: 'flex' }}>
          <Row gutter={50}>
            <FormItemRadioGroup
              label={`Esse bombeio utiliza linhas de berços diferentes (
            ${pumpingData?.origin_line.berth} e ${destinationLine.berth}). Por
            favor, informe se o Loop10 será utilizado
          `}
              name="confirm_loop10"
              options={[
                { label: 'Utilizar Loop10 neste bombeio', value: 'use_loop' },
                { label: 'Bombeio SEM Loop10', value: 'not_use_loop' },
              ]}
              isOptionTypeDefault
            />
          </Row>
        </div>
      </Modal>
      <Skeleton active loading={isLoadingPumpingData}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            border: '16px solid var(--neutral_lightest)',
            padding: '16px',
          }}
        >
          <div style={{ display: 'flex', gap: '8px' }}>
            <Title style={{ fontSize: '16px' }}>
              {pumpingData?.product.name}
            </Title>
            <span style={{ color: 'var(--neutral_medium)' }}>
              {formatNumberToLocale(pumpingData?.volume)} m³
            </span>
          </div>
          <Descriptions column={2}>
            <Item label="Terminal de origem">
              <FormatItem>{pumpingData?.origin_terminal.name}</FormatItem>
            </Item>
            <Item label="Linha de origem">
              <FormatItem>
                {pumpingData?.origin_line.name} (
                <i>{pumpingData?.origin_line.berth?.substring(3)}</i>)
              </FormatItem>
            </Item>
            <Item span={2} label="Previsão de bombeio">
              <FormatItem>
                <div>
                  <FormatItem>
                    {createDateStringPtBr(pumpingData?.expected_start)}
                  </FormatItem>
                  <span> até </span>
                  <FormatItem>
                    {createDateStringPtBr(pumpingData?.expected_finish)}
                  </FormatItem>
                  <i>({pumpingDuration()} de duração)</i>
                </div>
              </FormatItem>
            </Item>
          </Descriptions>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', padding: '16px' }}
        >
          <Row gutter={40}>
            <FormItemSelect
              colSpan={12}
              name={['destination_line', 'id']}
              label={`Linha da ${pumpingData?.destination_terminal.name}`}
              dataList={linesData}
              onSelect={handleSelectDestinationLine}
              notFoundContent={`Nenhuma linha encontrada para o terminal ${pumpingData?.destination_terminal.name}`}
              optionRenderer={lineRenderer}
              help={
                isEmpty(destinationLine) ? (
                  <span>Selecione uma linha</span>
                ) : (
                  <span>
                    Diâmetro: {destinationLine.diameter}
                    &quot;, Vazão máxima: {destinationLine.max_flow_rate} m³/h
                  </span>
                )
              }
            />
            {isDiffBerth && (
              <Col span={12}>
                <Alert
                  showIcon
                  type="info"
                  message={
                    <span>
                      Devido ao uso de linhas presentes em berços diferentes, a
                      operação será realizada através do <strong>Loop10</strong>
                      .
                    </span>
                  }
                />
              </Col>
            )}
          </Row>
          <Row gutter={40}>
            <FormItemsCheckbox name="uses_loop10" disabled={isDiffBerth}>
              <span>
                Utilizar <strong>Loop10</strong> para realização do bombeio. O
                bombeio será acompanhado pela{' '}
                <strong>{pumpingData?.destination_terminal.name}</strong>
              </span>
            </FormItemsCheckbox>
          </Row>
        </div>
      </Skeleton>
    </FormDrawer>
  );
}
