import { Input as ANTDInput } from 'antd';
import { MaskedInput as ANTDMaskedInput } from 'antd-mask-input';
import styled, { css } from 'styled-components';

export const baseInputStyles = css`
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid var(--neutral_light);
  border-radius: 0px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  padding-bottom: 6px;

  &[readonly] {
    border: 0px;
    border-bottom: 0px solid transparent;
    color: #000;
    padding-left: 2px;
    padding-top: 0px;
  }

  &:focus,
  & .ant-input-focused,
  &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 transparent;
  }
`;

export const Input = styled(ANTDInput)`
  ${baseInputStyles}
  &.code_input {
    font-family: Consolas;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    font-size: 14px;
  }
`;

export const MaskedInput = styled(ANTDMaskedInput)`
  ${baseInputStyles}
`;
