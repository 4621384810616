import { InfoCircleFilled } from '@ant-design/icons';
import { Alert, RadioChangeEvent } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Form,
  FormItemRadioGroup,
  GenericDrawerHeader,
  Modal,
} from '../../../components';
import { useLazyGetStopoverQuery } from '../../../services/stopoverApi';
import {
  BreakdownOccurrencePrimaryType,
  BreakdownOccurrenceToSave,
  BreakdownOccurrenceType,
  TreeType,
} from '../../../types';
import { breakdownOcurrenceTypeRadioList } from '../../../utils/lists';
import { BreakdownFormItems } from '../../stayment/breakdownOccurrenceDrawer/breakdownFormItems';
import { formatBreakdownOccurrenceToSave } from '../../stayment/breakdownOccurrenceDrawer/formatToSave';
import { OccurrenceFormItems } from '../../stayment/breakdownOccurrenceDrawer/occurrenceFormItems';

const { confirm } = Modal;

type BreakdownOccurrenceFormProps = {
  setIsVisibleForm: (visible: boolean) => void;
  equipments: TreeType[];
  breakdownSelected: BreakdownOccurrenceType;
  setBreakdownSelected: (breakdownOccurrence: BreakdownOccurrenceType) => void;
  createBreakdown: (breakdown: BreakdownOccurrenceToSave) => void;
  updateBreakdown: (breakdown: BreakdownOccurrenceToSave) => void;
  deleteBreakdown: (breakdown: BreakdownOccurrenceType) => void;
  isLoadingSave?: boolean;
  isLoadingDelete?: boolean;
};
export function BreakdownOccurrenceForm(props: BreakdownOccurrenceFormProps) {
  const {
    setIsVisibleForm,
    equipments,
    breakdownSelected,
    setBreakdownSelected,
    createBreakdown,
    updateBreakdown,
    deleteBreakdown,
    isLoadingSave,
    isLoadingDelete,
  } = props;
  const [primaryType, setPrimaryType] =
    useState<BreakdownOccurrencePrimaryType | null>(null);

  const [getStopoverDataById, { data: stopoverDataById }] =
    useLazyGetStopoverQuery();

  const [form] = Form.useForm();

  function onChangePrimaryType(ev: RadioChangeEvent) {
    setPrimaryType(ev.target.value);
    form.setFieldsValue({
      breakdown_occurrence_type: { id: null },
      equipments: [],
    });
  }

  async function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      const saveData = formatBreakdownOccurrenceToSave(
        { ...breakdownSelected, ...values },
        equipments
      );
      if (isEmpty(breakdownSelected)) {
        createBreakdown(saveData);
      } else {
        updateBreakdown(saveData);
      }
    });
  }

  useEffect(() => {
    if (!isEmpty(breakdownSelected) && breakdownSelected.primary_type) {
      setPrimaryType(breakdownSelected.primary_type);
    }
    if (!isEmpty(breakdownSelected) && breakdownSelected.stopover_id) {
      getStopoverDataById(breakdownSelected.stopover_id);
    }
  }, [breakdownSelected]);

  function onCloseDrawer() {
    setIsVisibleForm(false);
    setBreakdownSelected({} as BreakdownOccurrenceType);
  }

  function onClickDelete() {
    if (!isEmpty(breakdownSelected) && breakdownSelected.id) {
      confirm({
        title: 'Tem certeza?',
        content:
          'Tem certeza que deseja excluir essa avaria ou ocorrência, essa ação não poderá ser revertida',
        onOk() {
          deleteBreakdown(breakdownSelected);
        },
      });
    }
  }

  const initialValues = {
    ...breakdownSelected,
    date: breakdownSelected.date ? moment(breakdownSelected.date) : '',
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleFormSubmit}
      initialValues={initialValues}
      name="breakdown_occurence"
    >
      <GenericDrawerHeader
        title={
          <span style={{ fontSize: '16px', fontWeight: 700 }}>
            {!isEmpty(breakdownSelected)
              ? 'Editar avaria ou ocorrência'
              : 'Registrar avaria ou ocorrência'}
          </span>
        }
        showBackButton
        onBack={() => onCloseDrawer()}
        formName="breakdown_occurence"
        onDelete={!isEmpty(breakdownSelected) ? onClickDelete : undefined}
        isLoadingSave={isLoadingSave}
        isLoadingDelete={isLoadingDelete}
      />
      <div style={{ padding: '24px 16px' }}>
        <FormItem noStyle name="id" />

        {isEmpty(breakdownSelected) && (
          <Alert
            showIcon
            icon={<InfoCircleFilled />}
            type="info"
            style={{ marginBottom: '24px' }}
            description="Este formulário destina-se ao registro de avarias ou ocorrências que não estão relacionadas a atracações. Avarias ou ocorrências referentes a atracações devem ser registradas nas respectivas telas de Atracações."
          />
        )}
        <FormItemRadioGroup
          label="Tipo"
          name="primary_type"
          options={breakdownOcurrenceTypeRadioList}
          onChange={onChangePrimaryType}
          required
        />
        {primaryType === 'BREAKDOWN' ? (
          <BreakdownFormItems
            breakdownSelected={{} as BreakdownOccurrenceType}
            dockings={
              !isEmpty(breakdownSelected) && stopoverDataById
                ? stopoverDataById.dockings
                : undefined
            }
            hideDocking={!(breakdownSelected && breakdownSelected.stopover_id)}
            equipments={equipments}
          />
        ) : primaryType === 'OCCURRENCE' ? (
          <OccurrenceFormItems
            dockings={
              !isEmpty(breakdownSelected) && stopoverDataById
                ? stopoverDataById.dockings
                : undefined
            }
            hideDockings={!(breakdownSelected && breakdownSelected.stopover_id)}
          />
        ) : null}
      </div>
    </Form>
  );
}
