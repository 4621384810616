import styled from 'styled-components';

import { User } from '../../types';

const UsersWrapper = styled.div``;

type UsersRendererProps = {
  user?: User;
};

export function UsersRenderer(props: UsersRendererProps) {
  return (
    <UsersWrapper>
      <div>{`${props.user?.first_name} ${props.user?.last_name || ' '}`}</div>
      <div>{props.user?.email}</div>
    </UsersWrapper>
  );
}
