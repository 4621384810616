import { Steps as AntSteps } from 'antd';
import styled from 'styled-components';

export const Steps = styled(AntSteps)`
  & .ant-steps-item-container {
    display: flex;
    flex-direction: column-reverse;
  }
  & .ant-steps-item-tail {
    position: relative !important;
    &::after {
      background: var(--neutral_medium) !important;
    }
  }

  & .ant-steps-icon-dot,
  & .ant-steps-item-icon {
    width: 14px !important;
    height: 14px !important;
    background: #fff !important;
    border: 2px solid var(--neutral_medium);
  }
  & .ant-steps-icon {
    top: 0px !important;
  }

  & .ant-steps-item-active {
    & .ant-steps-icon-dot {
      background: var(--green-sea_medium) !important;
      border: 2px solid var(--green-sea_dark);
    }
    & .status-title,
    & .status-subtitle {
      color: var(--neutral_medium) !important;
    }
  }

  & .ant-steps-item-tail {
    top: -9px !important;
    margin: 0 0 0 72px !important;
  }

  & .status-title-container {
    margin-bottom: 10px;
    & .status-title,
    & .status-subtitle {
      display: block;
      color: var(--neutral_light);
    }
    & .status-title {
      font-weight: 500;
    }
  }
`;

export const SubStatusWrapper = styled.div`
  background: #fff;
  & .sub-status-title {
    text-align: center;
    padding-bottom: 15px;
    & .title {
      font-weight: 600;
      font-size: 16px;
    }
    & .id {
      color: #a0bace;
      display: inline-block;
      margin-left: 8px;
    }
  }
`;

export const StaymentLogWrapper = styled.div`
  padding: 20px;
  padding-top: 40px;
  background: #fff;
  /* overflow-x: auto; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
