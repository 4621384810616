import styled from 'styled-components';

export const ResourceQuantityTagWrapper = styled.div`
  border-radius: 10px;
  display: inline-block;
  padding: 2px 8px 2px;
  line-height: 15px;
  text-align: center;
  color: white;
`;
