import { Col } from 'antd';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import { ReactNode } from 'react';

import { DatePicker } from '../../common/datepicker/DatePicker';
import { FormItem } from '../Form';

type FormItemDatePickerProps = {
  label?: string;
  name: NamePath;
  required?: boolean;
  colSpan?: number;
  tooltip?: ReactNode;
  disabledDate?: (current: any) => boolean;
  disabledTime?: (current: any) =>
    | {
        disabledHours: () => number[];
        disabledMinutes: () => number[];
      }
    | {
        disabledHours?: undefined;
        disabledMinutes?: undefined;
      };
  help?: ReactNode;
  onChange?: (value: any) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  rules?: Rule[];
  validateTrigger?: string | string[] | false;
  minuteStep?: number;
  dependencies?: NamePath[];
  isRangePicker?: boolean;
  showTime?: boolean;
  normalize?: (value: moment.Moment) => string | null;
  allowClear?: boolean;
  format?: string | string[];
};

export function FormItemDatePicker(props: FormItemDatePickerProps) {
  const {
    label,
    name,
    required,
    colSpan,
    tooltip,
    disabledDate,
    disabledTime,
    help,
    onChange,
    disabled,
    style,
    rules,
    validateTrigger,
    minuteStep,
    dependencies,
    isRangePicker,
    showTime,
    normalize,
    allowClear,
    format,
  } = props;
  return (
    <Col span={colSpan || 24}>
      <FormItem
        label={label}
        name={name}
        tooltip={tooltip}
        required={required}
        help={help}
        style={style}
        rules={rules}
        validateTrigger={validateTrigger}
        dependencies={dependencies}
        normalize={normalize}
      >
        <DatePicker
          disabled={disabled}
          disabledDate={disabledDate}
          allowClear={allowClear !== undefined ? allowClear : false}
          onChange={onChange}
          disabledTime={disabledTime}
          minuteStep={minuteStep || 1}
          isRangePicker={isRangePicker}
          showTime={showTime !== undefined ? showTime : true}
          format={
            format ||
            (isRangePicker ? ['DD/MM/YYYY HH:mm'] : 'DD/MM/YYYY HH:mm')
          }
        />
      </FormItem>
    </Col>
  );
}
