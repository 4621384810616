import styled from 'styled-components';

export const WorkShiftStoppagesDrawerHeader = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;

  & h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 0px !important;
    margin-block-end: 0px !important;
    margin-block-start: 0px !important;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  & span {
    margin-left: 10px;
    color: var(--neutral_medium);
  }
`;
