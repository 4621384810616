import {
  AddressType,
  CargoStorageMerchantType,
  CargoType,
  CeMerchantGroupbyNcmItemType,
  CompanyType,
  OperationManifestType,
  OperationProductCeMercanteType,
  OperationProductStoragesMercanteType,
  OperationProductType,
  OperationTypeType,
  ProvisionedCargoType,
} from '../../types';

function getNewCargoStorageMerchant(
  merchantCe: CeMerchantGroupbyNcmItemType
): CargoStorageMerchantType {
  return {
    ncm_code: merchantCe.ncm_code,
    ncm_description: merchantCe.description || '',
    weight: merchantCe.weight,
    volume: merchantCe.volume,
    status: 'PENDING',
    direction:
      merchantCe.operation_direction === 'LOADING' ? 'DISPATCH' : 'RECEIPT',
    amount: merchantCe.amount,
    chassis: merchantCe.chassis,
    imdg_code: merchantCe.imdg_code?.id,
  };
}

function getNewStorage(
  merchantCe: CeMerchantGroupbyNcmItemType
): OperationProductStoragesMercanteType {
  return {
    storage_company: merchantCe.storage_terminal || ({} as CompanyType),
    cargos_storage: [getNewCargoStorageMerchant(merchantCe)],
  };
}

function getNewMerchantCe(
  merchantCe: CeMerchantGroupbyNcmItemType
): OperationProductCeMercanteType {
  return {
    operator_company: merchantCe.operator_company,
    origin_port: merchantCe.loading_port,
    destiny_port: merchantCe.unloading_port,
    code: merchantCe.code,
    storages: [getNewStorage(merchantCe)],
  };
}

function getNewManifest(
  merchantCe: CeMerchantGroupbyNcmItemType
): OperationManifestType {
  return {
    operation_type: merchantCe.operation_type || ({} as OperationTypeType),
    loading_port: merchantCe.loading_port,
    unloading_port: merchantCe.unloading_port,
    loading_terminal: merchantCe.loading_terminal,
    unloading_terminal: merchantCe.unloading_terminal,
    operation_direction: merchantCe.operation_direction,
    manifest_total: merchantCe.total_manifested,
    manifest_code: merchantCe.manifest_code,
    cargo_navigation_type: merchantCe.cargo_navigation_type,
    merchants_ce: [getNewMerchantCe(merchantCe)],
  };
}

function getNewOperationProduct(
  merchantCe: CeMerchantGroupbyNcmItemType,
  operationId: number
): NcmItemsGroupbyOpProductType {
  return {
    key: merchantCe.cargo_type?.name || '',
    operation_id: operationId,
    cargo_type: merchantCe.cargo_type || ({} as CargoType),
    operator: merchantCe.operator || ({} as CompanyType),
    operation_release: 'PENDING',
    work_shift_movimentation: [],
    operation_manifests: [getNewManifest(merchantCe)],
  };
}

type NcmItemsGroupbyOpProductType = OperationProductType & { key: string };

function updateCurrentCargosStorages(
  cargosStorages: CargoStorageMerchantType[]
) {
  return cargosStorages.map((cargoStorage) => {
    return {
      ...cargoStorage,
      imdg_code:
        typeof cargoStorage.imdg_code !== 'number'
          ? cargoStorage.imdg_code?.id
          : null,
    };
  });
}

function updateCargoStorageMerchant(
  storage: OperationProductStoragesMercanteType,
  ncmItem: CeMerchantGroupbyNcmItemType
): OperationProductStoragesMercanteType {
  return {
    ...storage,
    cargos_storage: [
      ...updateCurrentCargosStorages(storage.cargos_storage),
      getNewCargoStorageMerchant(ncmItem),
    ],
  };
}

function updateStorages(
  merchantCe: OperationProductCeMercanteType,
  ncmItem: CeMerchantGroupbyNcmItemType
): OperationProductCeMercanteType {
  const selectedStorage = merchantCe.storages.find(
    (storage) => storage.storage_company?.id === ncmItem.storage_terminal?.id
  );
  if (selectedStorage) {
    // já existe o storage, adicionar o cargo_storage_merchant
    return {
      ...merchantCe,
      storages: merchantCe.storages?.map((storage) => {
        if (
          storage.storage_company?.id === selectedStorage.storage_company?.id
        ) {
          return updateCargoStorageMerchant(selectedStorage, ncmItem);
        }
        return storage;
      }),
    };
  }
  // nao existe storage, adiciona um novo com cargo_storage_merchant
  return {
    ...merchantCe,
    storages: [...merchantCe.storages, getNewStorage(ncmItem)],
  };
}

function updateMerchantsCe(
  manifest: OperationManifestType,
  ncmItem: CeMerchantGroupbyNcmItemType
): OperationManifestType {
  const selectedMerchantCe = manifest.merchants_ce.find(
    (merchantCe) => merchantCe.code === ncmItem.code
  );
  if (selectedMerchantCe) {
    // já existe o merchantCe, checar se existe storage
    return {
      ...manifest,
      merchants_ce: manifest.merchants_ce?.map((merchantCe) => {
        if (merchantCe.code === selectedMerchantCe.code) {
          return updateStorages(selectedMerchantCe, ncmItem);
        }
        return merchantCe;
      }),
    };
  }
  // nao existe cemercante, adiciona um novo
  return {
    ...manifest,
    merchants_ce: [...manifest.merchants_ce, getNewMerchantCe(ncmItem)],
  };
}

function updateManifests(
  opProduct: NcmItemsGroupbyOpProductType,
  ncmItem: CeMerchantGroupbyNcmItemType
): NcmItemsGroupbyOpProductType {
  const selectedManifest = opProduct.operation_manifests?.find(
    (manifest) => manifest.manifest_code === ncmItem.manifest_code
  );
  if (selectedManifest) {
    // já existe o manifesto, checar se existe cemercante e storage
    return {
      ...opProduct,
      operation_manifests: opProduct.operation_manifests?.map((opManifest) => {
        if (opManifest.manifest_code === selectedManifest.manifest_code) {
          return updateMerchantsCe(selectedManifest, ncmItem);
        }
        return opManifest;
      }),
    };
  }
  // nao existe manifesto, adiciona um novo
  return {
    ...opProduct,
    operation_manifests: [
      ...(opProduct.operation_manifests || []),
      getNewManifest(ncmItem),
    ],
  };
}

export const groupNCMItemByOpProduct = (
  input: CeMerchantGroupbyNcmItemType[],
  operationId: number
) => {
  let groupsList: NcmItemsGroupbyOpProductType[] = [];
  console.log(input);
  input.forEach((ncmItem) => {
    const groupKey = ncmItem.cargo_type?.name || '';
    if (!groupsList.some((item) => item.key === groupKey)) {
      const newOpProduct = getNewOperationProduct(ncmItem, operationId);
      groupsList.push(newOpProduct);
    } else {
      // já existe o operation_product, checar se existe manifesto, cemercante e storage
      groupsList = groupsList.map((item) => {
        return updateManifests(item, ncmItem);
      });
    }
  }, []);
  return groupsList;
};

export const convertToCeMerchantGroupbyNcmItemType = (
  input: ProvisionedCargoType[]
): CeMerchantGroupbyNcmItemType[] => {
  return input?.map((item) => {
    return {
      weight: item.total_weight,
      operator: item.supplier_company,
      operator_company: {
        id: 2881,
        name: '',
        cnpj: '',
        phone_number: '',
        email: '',
        company_type: [0],
        address: {} as AddressType,
      },
      cargo_type: item.supply_type,
      amount: item.amount,
      ncm_code: item.ncm_code,
      description: 'PROVISIONADA',
      loading_port: item.loading_port,
      unloading_port: item.unloading_port,
      // loading_terminal: item.loading_port,
      // unloading_terminal: item.unloading_port,
      operation_type: item.operation_type,
      operation_direction: item.operation_direction,
      code: '000000000000001',
      manifest_code: '01',
      total_manifested: item.total_weight,
      ncm_item_id: 0,
      storage_terminal: {
        id: 19,
        name: '',
        cnpj: '',
        phone_number: '',
        email: '',
        company_type: [0],
        address: {} as AddressType,
      },
    };
  });
};
