import { EditFilled, EyeFilled } from '@ant-design/icons';
import { Space } from 'antd';

import { Button } from '../../../components';

type VesselEditRendererProps = {
  vessel: Record<string, any>;
  onSelectItem: (vessel: any) => void;
};

export function VesselEditRenderer(props: VesselEditRendererProps) {
  const { vessel, onSelectItem } = props;
  return (
    <Space size={10} style={{ float: 'right' }}>
      {/* <Button icon={<EyeFilled />}>Ver histórico de nomes</Button> */}
      <Button icon={<EditFilled />} onClick={() => onSelectItem(vessel)} />
    </Space>
  );
}
