import { useEffect, useState } from 'react';

import { Descriptions, FormatItem } from '..';

import { Stopover as StopoverType, StopoverTypeDescription } from '../../types';
import { formatStopoverToDescription } from '../../utils/formatters';

type StopoverAgentAreaDescriptionProps = {
  selectedStopover: StopoverType;
};

const { Item } = Descriptions;

export function StopoverAgentAreaDescription(
  props: StopoverAgentAreaDescriptionProps
) {
  const { selectedStopover } = props;

  useEffect(() => {
    setStopoverDescription(formatStopoverToDescription(selectedStopover));
  }, [selectedStopover]);

  const [stopoverDescription, setStopoverDescription] = useState(
    {} as StopoverTypeDescription
  );

  return (
    <Descriptions column={2}>
      <Item label="Agência de navegação">
        <FormatItem>{stopoverDescription.shipping_agency}</FormatItem>
      </Item>
      <Item label="Nº escala mercante">
        <FormatItem>{stopoverDescription.mercante_scale}</FormatItem>
      </Item>
      <Item label="Armador afretador">
        <FormatItem>{stopoverDescription.charterer}</FormatItem>
      </Item>
      <Item label="Nº viagem do armador">
        <FormatItem>{stopoverDescription.shipowner_trip}</FormatItem>
      </Item>
      <Item label="Agência protetora">
        <FormatItem>{stopoverDescription.protective_agency}</FormatItem>
      </Item>
      <Item label="Tipo de navegação">
        <FormatItem>{stopoverDescription.navigation_type}</FormatItem>
      </Item>
      <Item label="Porto de origem">
        <FormatItem>
          {stopoverDescription.last_port
            ? `${stopoverDescription.last_port}, 
          ${stopoverDescription.last_port_country}`
            : null}
        </FormatItem>
      </Item>
      <Item label="Porto de destino">
        <FormatItem>
          {stopoverDescription.next_port
            ? `${stopoverDescription.next_port}, 
          ${stopoverDescription.next_port_country}`
            : null}
        </FormatItem>
      </Item>
      <Item label="Embarcação">
        <FormatItem>{stopoverDescription.vessel}</FormatItem>
      </Item>
      <Item label="Natureza da estadia">
        <FormatItem>{stopoverDescription.nature_of_stayment}</FormatItem>
      </Item>
      <Item label="Previsão de chegada (ETA)">
        <FormatItem>{stopoverDescription.expected_arrival_ais}</FormatItem>
      </Item>
      <Item label="Previsão de saída(ETD)">
        <FormatItem>{stopoverDescription.expected_departure}</FormatItem>
      </Item>
    </Descriptions>
  );
}
