import { Tag as ANTDTag } from 'antd';
import styled from 'styled-components';

export const Tag = styled(ANTDTag)`
  background: var(--neutral_lightest);
  border: 1px solid var(--neutral_lighter);
  color: var(--neutral_medium);
  font-weight: normal;
  border-radius: 2px;
  &.rounded {
    border-radius: 10px;
  }
  &.error {
    border: 1px solid var(--error-light);
    color: var(--error-medium);
  }
  &.due-status {
    &.released {
      background: var(--green-sea_lighter);
      color: var(--green-sea_dark);
      border-color: var(--green-sea_light);
    }
    &.delivered {
      background: var(--success-lightest);
      color: var(--success-dark);
      border-color: var(--success-light);
    }
    &.outdated {
      background: var(--error-lightest);
      color: var(--error-medium);
      border-color: var(--error-light);
    }
  }
  &.invoicing-status {
    &.unbilled {
      background: var(--warning-lightest);
      color: var(--warning-dark);
      border-color: var(--warning-dark);
    }
    &.billed {
      border-color: var(--neutral_medium);
    }
    &.rebilled {
      background: var(--ocean_lighter);
      color: var(--ocean_dark);
      border-color: var(--ocean_dark);
    }
  }
  &.charge-status {
    &.invoicing {
      background: var(--warning-lightest);
      color: var(--warning-dark);
      border-color: var(--warning-light);
    }
    &.billing {
      background: var(--warning-lightest);
      color: var(--warning-dark);
      border-color: var(--warning-light);
    }
    &.billed {
      border-color: var(--ocean_lighter);
    }
    &.rebilled {
      border-color: var(--ocean_lighter);
    }
    &.reversed {
      background: var(--error-lightest);
      color: var(--error-medium);
      border-color: var(--error-light);
    }
  }
  &.storage-status {
    &.confirmed {
      background: var(--success-lightest);
      color: var(--success-dark);
      border-color: var(--success-light);
    }
    &.rejected {
      background: var(--error-lightest);
      color: var(--error-medium);
      border-color: var(--error-light);
    }
    &.pending {
      background: var(--warning-lightest);
      color: var(--warning-dark);
      border-color: var(--warning-light);
    }
    &.new-schedule {
      background: var(--info-lighter);
      color: var(--info-dark);
      border-color: var(--info-light);
    }
  }
  &.pumping-berth {
    height: 22px;
    &.pgl-1 {
      background: var(--neutral_lightest);
      color: var(--neutral_medium);
      border-color: var(--neutral_lighter);
    }
    &.pgl-2 {
      background: var(--ocean_lighter);
      color: var(--ocean_dark);
      border-color: var(--ocean_light);
    }
    &.pgl-3a {
      background: var(--neutral_lighter);
      color: var(--neutral_medium);
      border-color: var(--neutral_light);
    }
    &.pgl-3b {
      background: var(--neutral_medium);
      color: var(--neutral_lightest);
      border-color: var(--neutral_light);
    }
    &.berth-1 {
      background: var(--ocean_light);
      color: #fff;
      border-color: var(--ocean_lighter);
    }
    &.berth-2 {
      background: var(--ocean_lighter);
      color: var(--ocean_dark);
      border-color: var(--ocean_light);
    }
    &.berth-3 {
      background: #f8fafb;
      color: #65737e;
      border-color: #dfe3e6;
    }
    &.berth-4 {
      background: #b2bec3;
      color: #fff;
      border-color: #c0c5ce;
    }
    &.berth-5 {
      background: #dfe6e9;
      color: #636e72;
      border-color: #b2bec3;
    }
    &.berth-6 {
      background: #c0c5ce;
      color: #65737e;
      border-color: #a7adba;
    }
    &.berth-7 {
      background: #65737e;
      color: #fff;
      border-color: #c0c5ce;
    }
  }
`;
