// Redux
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

// Services
import { tosApi } from '../../services/tosApi';

// Middlewares
import { ErrorMiddleware } from '../middlewares/ErrorMiddleware';

// Store
import alertReducer from './alertStore';
import authReducer from './authStore';
import userReducer from './userStore';

export const store = configureStore({
  reducer: {
    [tosApi.reducerPath]: tosApi.reducer,
    alert: alertReducer,
    user: userReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tosApi.middleware).concat(ErrorMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
