import { ColumnsType } from 'antd/lib/table';

interface IData {
  direction_container?: string;
  // size?: {
  full?: {
    '20'?: string;
    '40'?: string;
  };
  empty?: {
    '20'?: string;
    '40'?: string;
  };
  // };
  operated?: string;
  expected?: string;
}

export function totalProgress(
  isMovimentation?: boolean,
  movimentationName?: string,
  movimentationIndex?: number
): ColumnsType<Record<string, any>> {
  const fullCol = {
    title: 'Cheio',
    className: 'group',
    align: 'center' as const,
    children: [
      {
        title: '20"',
        key: '20',
        dataIndex: '20',
        className: 'group-item',
        align: 'center' as const,
        render: (_: any, record: IData) => {
          return record.full && record.full['20'];
        },
        onCell: (_: any, index: number) => {
          const extraProps: any = {};
          if (index === 5) {
            extraProps.colSpan = 2;
          }
          if (index === 4 || index === 5) {
            extraProps.className = 'total';
          }
          return extraProps;
        },
      },
      {
        title: '40"',
        key: '40',
        dataIndex: '40',
        className: 'group-item',
        align: 'center' as const,
        render: (_: any, record: IData) => {
          return record.full && record.full['40'];
        },
        onCell: (_: any, index: number) => {
          const extraProps: any = {};
          if (index === 5) {
            extraProps.colSpan = 0;
          }
          if (index === 4 || index === 5) {
            extraProps.className = 'total';
          }
          return extraProps;
        },
      },
    ],
  };
  const emptyCol = {
    title: 'Vazio',
    className: 'group',
    align: 'center' as const,
    children: [
      {
        title: '20"',
        key: '20',
        dataIndex: '20',
        className: 'group-item',
        align: 'center' as const,
        render: (_: any, record: IData) => {
          return record.empty && record.empty['20'];
        },
        onCell: (_: any, index: number) => {
          const extraProps: any = {};
          if (index === 5) {
            extraProps.colSpan = 2;
          }
          if (index === 4 || index === 5) {
            extraProps.className = 'total';
          }
          return extraProps;
        },
      },
      {
        title: '40"',
        key: '40',
        dataIndex: '40',
        className: 'group-item',
        align: 'center' as const,
        render: (_: any, record: IData) => {
          return record.empty && record.empty['40'];
        },
        onCell: (_: any, index: number) => {
          const extraProps: any = {};
          if (index === 5) {
            extraProps.colSpan = 0;
          }
          if (index === 4 || index === 5) {
            extraProps.className = 'total';
          }
          return extraProps;
        },
      },
    ],
  };
  const movimentationCol = {
    title: movimentationName,
    className: 'group',
    align: 'center' as const,
    children: [fullCol, emptyCol],
  };
  const baseColumns: ColumnsType<Record<string, any>> = [
    {
      title: '',
      key: 'direction_container',
      dataIndex: 'direction_container',
      className: 'direction-container',
      align: 'right' as const,
      onCell: (_, index) => {
        if (index === 4) {
          return { className: 'total-row' };
        }
        return {};
      },
    },
    {
      title: 'Operados',
      key: 'operated',
      dataIndex: 'operated',
      align: 'center' as const,
      className: 'expected-operated',
      onCell: (record, index) => {
        const extraProps: any = {};
        if (index === 4) {
          extraProps.rowSpan = 2;
          extraProps.className = 'total operated';
          if (record.expected > record.operated) {
            extraProps.className = 'total operated incomplete';
          } else {
            extraProps.className = 'total operated complete';
          }
        }
        if (index === 5) {
          extraProps.rowSpan = 0;
        }

        return extraProps;
      },
    },
    {
      title: 'Esperados',
      key: 'expected',
      dataIndex: 'expected',
      align: 'center' as const,
      className: 'expected-operated',
      onCell: (_, index) => {
        const extraProps: any = {};
        if (index === 4) {
          extraProps.rowSpan = 2;
          extraProps.className = 'total expected';
        }
        if (index === 5) {
          extraProps.rowSpan = 0;
        }

        return extraProps;
      },
    },
  ];
  if (!isMovimentation) {
    baseColumns.splice(1, 0, fullCol);
    baseColumns.splice(2, 0, emptyCol);
  } else {
    baseColumns.splice(1, 0, movimentationCol);
    if (movimentationIndex && movimentationIndex > 0) {
      baseColumns.splice(0, 1);
    }
  }
  return baseColumns;
}
