import moment from 'moment';

import { Expire } from './Expire';

type StopoverReactivateDataProps = {
  motivationCancel: string;
};

export function StopoverReactivateData(props: StopoverReactivateDataProps) {
  const { motivationCancel } = props;
  return (
    <Expire
      type="success"
      closable
      showIcon
      message={
        <>
          <span>
            Escala reativada em: {moment().format('DD/MM/YYYY HH:mm')}
          </span>
          <br />
          <span>Motivo: {motivationCancel}</span>
        </>
      }
    />
  );
}
