import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditFilled,
  EyeFilled,
} from '@ant-design/icons';
import { Dispatch, SetStateAction, useState } from 'react';

import {
  Button,
  Descriptions,
  FormatItem,
  InnerCollapse,
  Tag,
  Title,
} from '../../../../components';
import {
  CargoManifestType,
  CeMerchantsGroupbyOperatorCompanyType,
} from '../../../../types';
import {
  capitalizeFirstLetter,
  formatNumberToLocale,
} from '../../../../utils/utils';
import { CeMerchantsDrawer } from './ceMerchantsDrawer/CeMerchantsDrawer';
import { EditCargoNavigationTypeModal } from './editCargoNavigationTypeModal';
import { groupNCMItemByStorage } from './formatters';

const { Panel } = InnerCollapse;
const { Item } = Descriptions;

type ManifestsImportedCollapseProps = {
  cargoManifest: CargoManifestType;
  setUploadManifestsData: Dispatch<SetStateAction<CargoManifestType[]>>;
};

export function ManifestsImportedCollapse(
  props: ManifestsImportedCollapseProps
) {
  const { cargoManifest, setUploadManifestsData } = props;
  const [isVisibleCeMerchantDrawer, setIsVisibleCeMerchantDrawer] =
    useState(false);
  const [
    isModalEditCargoNavigationTypeVisible,
    setIsModalEditCargoNavigationTypeVisible,
  ] = useState(false);

  const [operatorCompanySelected, setOperatorCompanySelected] = useState(
    {} as CeMerchantsGroupbyOperatorCompanyType
  );

  function getHeader() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          gap: '8px',
        }}
      >
        <span>Manifesto </span>
        <span>{cargoManifest.code}</span>
        <Tag
          icon={
            cargoManifest.operation_direction === 'UNLOADING' ? (
              <ArrowDownOutlined />
            ) : (
              <ArrowUpOutlined />
            )
          }
        >
          {cargoManifest.operation_direction === 'UNLOADING'
            ? 'Descarga'
            : 'Carga'}
        </Tag>
      </div>
    );
  }

  function handleSelectOperatorCompany(
    operatorCompany: CeMerchantsGroupbyOperatorCompanyType
  ) {
    setIsVisibleCeMerchantDrawer(true);
    setOperatorCompanySelected({
      ...operatorCompany,
      all_ncm_items_groupby_storage: groupNCMItemByStorage(
        operatorCompany.all_ncm_items
      ),
    });
  }

  function handleCancelEditCargoNavigationType() {
    setIsModalEditCargoNavigationTypeVisible(false);
  }

  return (
    <>
      <EditCargoNavigationTypeModal
        isVisible={isModalEditCargoNavigationTypeVisible}
        setIsVisible={setIsModalEditCargoNavigationTypeVisible}
        handleCancel={handleCancelEditCargoNavigationType}
        selectedCargoManifest={cargoManifest}
        setUploadManifestsData={setUploadManifestsData}
      />
      <CeMerchantsDrawer
        isVisible={isVisibleCeMerchantDrawer}
        setIsVisible={setIsVisibleCeMerchantDrawer}
        cargoManifest={cargoManifest}
        operatorCompanySelected={operatorCompanySelected}
        setOperatorCompanySelected={setOperatorCompanySelected}
      />
      <InnerCollapse expandIconPosition="end">
        <Panel header={getHeader()} key="manifest-imported">
          <div style={{ padding: '16px' }}>
            <Descriptions column={2}>
              <Item label="Porto de carregamento">
                <FormatItem>
                  {capitalizeFirstLetter(
                    cargoManifest.loading_port?.country_name
                  )}
                  , {capitalizeFirstLetter(cargoManifest.loading_port?.name)}
                </FormatItem>
              </Item>
              <Item label="Porto de descarregamento">
                <FormatItem>
                  {capitalizeFirstLetter(
                    cargoManifest.unloading_port?.country_name
                  )}
                  , {capitalizeFirstLetter(cargoManifest.unloading_port?.name)}
                </FormatItem>
              </Item>
              <Item label="Navegação da carga">
                <div>
                  <FormatItem>
                    {cargoManifest?.cargo_navigation_type?.description}
                  </FormatItem>
                  <Button
                    style={{ marginTop: '-10px', marginLeft: '5px' }}
                    type="link"
                    onClick={() =>
                      setIsModalEditCargoNavigationTypeVisible(true)
                    }
                    icon={<EditFilled />}
                    size="small"
                  />
                </div>
              </Item>
              <Item label="Tipo de operação da carga">
                <FormatItem>
                  {cargoManifest.operation_type?.description}
                </FormatItem>
              </Item>
              <Item label="Total manifestado">
                <FormatItem>
                  {formatNumberToLocale(cargoManifest.total_manifested)} ton
                </FormatItem>
              </Item>
              <Item label="Total atribuído">
                <FormatItem>(XX ton)</FormatItem>
              </Item>
              <Item label="Sentido do produto" span={2}>
                <FormatItem>
                  {cargoManifest.operation_direction === 'UNLOADING'
                    ? 'Descarregamento'
                    : 'Carregamento'}
                </FormatItem>
              </Item>
            </Descriptions>
            <Title>
              {cargoManifest.operation_direction === 'UNLOADING'
                ? 'CONSIGNATÁRIOS'
                : 'EMBARCADORES'}
            </Title>
            {cargoManifest.ce_merchants_groupby_operator_company?.map(
              (operatorCompany, index) => (
                <div
                  key={operatorCompany.id}
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    borderTop: index > 0 ? '1px solid #F0F0F0' : 'none',
                    padding: '16px 0',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 600, fontSize: '12px' }}>
                      {operatorCompany.name}
                    </span>
                    <span
                      style={{
                        fontSize: '12px',
                        color: 'var(--neutral_light)',
                      }}
                    >
                      {operatorCompany.cnpj}
                    </span>
                  </div>
                  <Button
                    icon={<EyeFilled />}
                    style={{ fontSize: '12px' }}
                    onClick={() => handleSelectOperatorCompany(operatorCompany)}
                  >
                    Ver CE Mercantes
                  </Button>
                </div>
              )
            ) || (
              <>
                <br />
                <span>{`Nenhum ${
                  cargoManifest.operation_direction === 'UNLOADING'
                    ? 'Consignatário'
                    : 'Embarcador'
                } encontrado`}</span>
              </>
            )}
          </div>
        </Panel>
      </InnerCollapse>
    </>
  );
}
