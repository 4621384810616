import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { Input, List } from '../../../components';
import { useLazyGroupCSMByVehiclesExportingQuery } from '../../../services/cargosStorageMerchantApi';
import { OperationalOperationType, VehicleBrandByPort } from '../../../types';
import { ContainerAside } from '../../operation/OperationsData/operationDescriptionsSections/generalCargo/containers/containersByCraneModal/containersByCraneData/styles';
import { VehiclesMovimentationDetails } from './vehiclesMovimentationDetails';

type VehiclesExportMovimentationProps = {
  operation?: OperationalOperationType;
};
export function VehiclesExportMovimentation(
  props: VehiclesExportMovimentationProps
) {
  const { operation } = props;

  const [getGroupCSMByVehiclesExporting, { data: CSMByVehiclesExporting }] =
    useLazyGroupCSMByVehiclesExportingQuery();

  const [selectedVehicleBrand, setselectedVehicleBrand] =
    useState<VehicleBrandByPort | null>(null);
  const [search, setSearch] = useState('');

  const [selectedPort, setselectedPort] = useState<{
    country: string;
    name: string;
  } | null>(null);
  const [selectedOperationProductId, setselectedOperationProductId] = useState<
    number | null
  >(null);
  useEffect(() => {
    if (operation && operation.id) {
      getGroupCSMByVehiclesExporting({
        operation_id: operation.id,
        due_number_or_chassis: search,
      });
    }
  }, [operation, search]);

  function refetchGroupCSMByVehiclesExporting() {
    if (operation && operation.id) {
      getGroupCSMByVehiclesExporting({
        operation_id: operation.id,
        due_number_or_chassis: search,
      });
      setselectedVehicleBrand(null);
    }
  }

  return (
    <ContainerAside
      style={{ height: '500px', minHeight: '500px', marginTop: '8px' }}
    >
      <aside>
        <div>
          <Input
            size="middle"
            placeholder="Pesquisar CE ou chassi"
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            prefix={
              <SearchOutlined style={{ color: '#09d4ab', fontSize: '16px' }} />
            }
            allowClear
            style={{ border: '1px solid #CCDDE9' }}
          />
          <List style={{ height: '480px', width: '210px', overflowX: 'auto' }}>
            {CSMByVehiclesExporting?.results.map((port) => {
              return port.vehicle_brands.map(
                (vehicleBrand, vehicleBrandKey) => {
                  return (
                    <List.Item
                      key={vehicleBrandKey}
                      className={
                        selectedVehicleBrand === vehicleBrand
                          ? 'item-container active'
                          : 'item-container'
                      }
                      onClick={() => {
                        setselectedVehicleBrand(vehicleBrand);
                        setselectedPort({
                          country: port.country,
                          name: port.name,
                        });
                        setselectedOperationProductId(
                          port.operation_product__id
                        );
                      }}
                    >
                      <div className="item-container-data">
                        <span>{vehicleBrand.description}</span>
                        <span className="item-container-data-resume">
                          {port.country}, {port.name}
                        </span>
                      </div>
                    </List.Item>
                  );
                }
              );
            })}
          </List>
        </div>
      </aside>
      <div
        className="container-data"
        style={{ width: '100%', paddingRight: '16px' }}
      >
        <VehiclesMovimentationDetails
          direction="EXPORT"
          exportingByBrands={selectedVehicleBrand}
          selectedPort={selectedPort}
          selectedOperationProductId={selectedOperationProductId}
          operation={operation}
          refetchGroupCSMByVehiclesExporting={
            refetchGroupCSMByVehiclesExporting
          }
        />
      </div>
    </ContainerAside>
  );
}
