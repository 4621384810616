import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { useMemo, useState } from 'react';

import { Alert, Button, Modal, Tag } from '../../../../components';
import { useChangePagination } from '../../../../hooks';
import {
  useGetMerchantsCeGroupbyNcmItemsQuery,
  useLazyGetNcmItemByIdQuery,
} from '../../../../services/ncmItemApi';
import { CeMerchantGroupbyNcmItemType, NCMItemType } from '../../../../types';
import { isNullOrUndefined } from '../../../../utils/utils';
import { PanelHeader } from '../../../registers/panelHeader';
import { AssociateLoadingTable } from './associateLoadingTable/AssociateLoadingTable';
import { columns } from './associateLoadingTable/columns';
import { SelectAllItemsOperatorModal } from './selectAllItemsOperatorModal/SelectAllItemsOperatorModal';
import { SelectOperatorModal } from './selectOperatorModal/SelectOperatorModal';

type AssociateLoadingModalProps = {
  isVisible: boolean;
  handleCancelModal: () => void;
  selectedManifest: string;
  stopoverId: number;
  selectedOpDirectionManifest: string;
};

export function AssociateLoadingModal(props: AssociateLoadingModalProps) {
  const {
    isVisible,
    handleCancelModal,
    selectedManifest,
    stopoverId,
    selectedOpDirectionManifest,
  } = props;
  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();
  const [searchQueryTerm, setSearchQueryTerm] = useState('');
  const [isVisibleOperatorModal, setIsVisibleOperatorModal] = useState(false);
  const [selectedLoading, setSelectedLoading] = useState(
    {} as CeMerchantGroupbyNcmItemType
  );
  const [checkedAll, setCheckedAll] = useState(false);
  const [isVisibleAllItemsOperatorModal, setIsVisibleAllItemsOperatorModal] =
    useState(false);

  const { data: ncmItemsData, isFetching: isFetchingNcmItemsData } =
    useGetMerchantsCeGroupbyNcmItemsQuery(
      {
        page: onChangePagination(),
        manifest_code: selectedManifest,
        stopover_id: stopoverId,
        consignee_name_or_ncm_code_or_ce_merchant: searchQueryTerm,
      },
      { skip: isNullOrUndefined(selectedManifest) }
    );

  // adicionado apenas para pegar o total de cargas associadas
  const { data: totalNcmItems, isFetching: isFetchingTotalNcmItems } =
    useGetMerchantsCeGroupbyNcmItemsQuery(
      {
        manifest_code: selectedManifest,
        ncm_item_assigned: true,
        stopover_id: stopoverId,
      },
      {
        skip:
          isNullOrUndefined(selectedManifest) ||
          !ncmItemsData?.results ||
          ncmItemsData?.results.length === 0,
      }
    );

  const isVehicleManifest = useMemo(() => {
    if (ncmItemsData?.results && ncmItemsData?.results.length > 0) {
      return !isNullOrUndefined(ncmItemsData.results[0].chassis);
    }
    return false;
  }, [ncmItemsData]);

  const [getNcmItemById, { data: ncmItemByIdData }] =
    useLazyGetNcmItemByIdQuery();

  function getTitle() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <span style={{ color: 'var(--neutral_light)' }}>
              Manifesto {selectedManifest}
            </span>
            <Tag
              icon={
                selectedOpDirectionManifest === 'UNLOADING' ? (
                  <ArrowDownOutlined
                    style={{ color: 'var(--neutral_light)' }}
                  />
                ) : (
                  <ArrowUpOutlined style={{ color: 'var(--neutral_light)' }} />
                )
              }
            >
              {selectedOpDirectionManifest === 'UNLOADING'
                ? 'Descarga'
                : 'Carga'}
            </Tag>
          </div>
          <span
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: 'var(--suape-black)',
            }}
          >
            Atribuir cargas
          </span>
        </div>
        <div>
          <Button
            type="link"
            style={{ color: 'var(--suape-black)' }}
            onClick={() => {
              setCheckedAll(false);
              handleCancelModal();
            }}
          >
            Voltar
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setCheckedAll(false);
              handleCancelModal();
            }}
          >
            Concluir
          </Button>
        </div>
      </div>
    );
  }

  function searchData(value: string) {
    setQueryPage(1);
    setSearchQueryTerm(value);
  }

  function onSelectOperator(ncmItem: CeMerchantGroupbyNcmItemType) {
    setSelectedLoading(ncmItem);
    if (ncmItem.ncm_item_id) getNcmItemById(ncmItem.ncm_item_id);
    setIsVisibleOperatorModal(true);
  }

  function handleCancelSelectOperatorModal() {
    setIsVisibleOperatorModal(false);
    setCheckedAll(false);
    setSelectedLoading({} as CeMerchantGroupbyNcmItemType);
  }

  function handleCancelSelectAllItemsOperatorModal() {
    setIsVisibleAllItemsOperatorModal(false);
    setCheckedAll(false);
    setSelectedLoading({} as CeMerchantGroupbyNcmItemType);
  }

  const totalAssociated = totalNcmItems?.count || 0;

  return (
    <Modal
      className="TOSPrimaryModal"
      width={1300}
      title={getTitle()}
      visible={isVisible}
      destroyOnClose
      onCancel={() => handleCancelModal()}
      footer={null}
      closable={false}
    >
      <SelectOperatorModal
        isVisible={isVisibleOperatorModal}
        handleCancelModal={handleCancelSelectOperatorModal}
        selectedLoading={selectedLoading}
        ncmItem={ncmItemByIdData || ({} as NCMItemType)}
        selectedOpDirectionManifest={selectedOpDirectionManifest}
        isVehicleManifest={isVehicleManifest}
      />
      <SelectAllItemsOperatorModal
        isVisible={isVisibleAllItemsOperatorModal}
        handleCancelModal={handleCancelSelectAllItemsOperatorModal}
        selectedManifest={selectedManifest}
        countItems={ncmItemsData?.count || 0}
        stopoverId={stopoverId}
        selectedOpDirectionManifest={selectedOpDirectionManifest}
      />
      <PanelHeader
        panelName="por consignatário, CE Mercante ou NCM"
        onSearch={searchData}
        inputWidth="430px"
        actionButtons={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginInlineStart: '15px',
            }}
          >
            {isFetchingTotalNcmItems || isFetchingNcmItemsData ? (
              <Skeleton.Input active />
            ) : (
              <>
                <span>
                  <strong>{totalAssociated}</strong> carga(s) atribuídas(s)
                </span>
                {totalAssociated === (ncmItemsData?.count || 0) && (
                  <Alert
                    type="warning"
                    message="Todas as cargas já estão atribuídas"
                  />
                )}
              </>
            )}
            {isVehicleManifest && (
              <Button
                disabled={
                  !checkedAll || totalAssociated === (ncmItemsData?.count || 0)
                }
                onClick={() => setIsVisibleAllItemsOperatorModal(true)}
              >
                Selecionar operador para todos
              </Button>
            )}
          </div>
        }
      />

      <AssociateLoadingTable
        rowKey="ncm_item_id"
        columns={columns}
        checkedAll={checkedAll}
        setCheckedAll={setCheckedAll}
        onSelectItem={onSelectOperator}
        dataSource={ncmItemsData?.results || []}
        onChangePagination={onChangePagination}
        total={ncmItemsData?.count}
        isLoading={isFetchingNcmItemsData}
        queryPage={queryPage}
        showSizeChanger={false}
        isVehicleManifest={isVehicleManifest}
        selectedOpDirectionManifest={selectedOpDirectionManifest}
      />
    </Modal>
  );
}
