import { Radio } from 'antd';

import { Button, InputNumber } from '../../../../../../../../../components';
import { FilterContaninerType } from '../../../../../../../../../types';
import { Container } from './styles';

type FilterContentProps = {
  filters: FilterContaninerType;
  setFilters: (value: FilterContaninerType) => void;
};

export function FilterContent(props: FilterContentProps) {
  const { filters, setFilters } = props;
  return (
    <Container>
      <div className="item">
        <span>Tamanho:</span>
        <Radio.Group
          value={filters.size}
          onChange={(e) => setFilters({ ...filters, size: e.target.value })}
        >
          <Radio.Button value={20}>20</Radio.Button>
          <Radio.Button value={40}>40</Radio.Button>
        </Radio.Group>
      </div>
      <div className="item">
        <span>Operado:</span>
        <Radio.Group
          value={filters.isOperated}
          onChange={(e) =>
            setFilters({ ...filters, isOperated: e.target.value })
          }
        >
          <Radio.Button value>Sim</Radio.Button>
          <Radio.Button value={false}>Não</Radio.Button>
        </Radio.Group>
      </div>
      <div className="item">
        <span>Tipo:</span>
        <Radio.Group
          value={filters.type}
          onChange={(e) => setFilters({ ...filters, type: e.target.value })}
        >
          <Radio.Button value="RH">RH</Radio.Button>
          <Radio.Button value="HC">HC</Radio.Button>
          <Radio.Button value="DC">DC</Radio.Button>
          <Radio.Button value="FR">FR</Radio.Button>
          <Radio.Button value="OT">OT</Radio.Button>
          <Radio.Button value="RF">RF</Radio.Button>
          <Radio.Button value="TK">TK</Radio.Button>
          <Radio.Button value="R1">R1</Radio.Button>
          <Radio.Button value="TN">TN</Radio.Button>
          <Radio.Button value="FH">FH</Radio.Button>
          <Radio.Button value="OH">OH</Radio.Button>
          <Radio.Button value="GS">GS</Radio.Button>
          <Radio.Button value="U1">U1</Radio.Button>
          <Radio.Button value="DV">DV</Radio.Button>
          <Radio.Button value="GP">GP</Radio.Button>
          <Radio.Button value="PL">PL</Radio.Button>
          <Radio.Button value="PC">PC</Radio.Button>
        </Radio.Group>
      </div>
      <div className="item">
        <span>Cheio:</span>
        <Radio.Group
          value={filters.isFull}
          onChange={(e) => setFilters({ ...filters, isFull: e.target.value })}
        >
          <Radio.Button value>Sim</Radio.Button>
          <Radio.Button value={false}>Não</Radio.Button>
        </Radio.Group>
      </div>
      <div className="item">
        <span>Peso:</span>
        <div className="item-data">
          <InputNumber
            placeholder="Mínimo"
            addonAfter="ton"
            value={filters.minWeight}
            onChange={(e) => setFilters({ ...filters, minWeight: Number(e) })}
          />
          <span> - </span>
          <InputNumber
            placeholder="Máximo"
            addonAfter="ton"
            value={filters.maxWeight}
            onChange={(e) => setFilters({ ...filters, maxWeight: Number(e) })}
          />
        </div>
      </div>
      <div className="item">
        <span>Tara:</span>
        <div className="item-data">
          <InputNumber
            placeholder="Mínimo"
            addonAfter="ton"
            value={filters.minTara}
            onChange={(e) => setFilters({ ...filters, minTara: Number(e) })}
          />
          <span> - </span>
          <InputNumber
            placeholder="Máximo"
            addonAfter="ton"
            value={filters.maxTara}
            onChange={(e) => setFilters({ ...filters, maxTara: Number(e) })}
          />
        </div>
      </div>
      <div className="item right">
        <Button onClick={() => setFilters({} as FilterContaninerType)}>
          Limpar
        </Button>
      </div>
    </Container>
  );
}
