import { BollardType, GenericResponse } from '../types';
import { getHeaders, tosApi } from './tosApi';

export type BollardResponse = GenericResponse & {
  results: Array<BollardType> | [];
};

export const bollardApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getBollards: build.query<BollardResponse, { dockingPlace?: string }>({
      query: ({ dockingPlace }) => {
        return {
          url: `/maritime/bollards`,
          method: 'GET',
          headers: getHeaders,
          params: {
            docking_place: dockingPlace,
          },
        };
      },
      transformResponse: (response: BollardResponse) => {
        response.results.forEach((bollard) => {
          bollard.id = bollard.tag;
        });
        return response;
      },
    }),
    getBollard: build.query<BollardType, { tag: string }>({
      query: (arg) => {
        const { tag } = arg;
        return {
          url: `/maritime/bollards/${tag}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetBollardsQuery, useGetBollardQuery } = bollardApi;
