import styled from 'styled-components';

import { Title } from '../../../components';
import { Number } from '../../../components/common/Number';
import { Subtitle } from '../../../components/common/Title';
import { ContainersTable } from '../operationCranesData/containersCollapseData/styles';

export const MovimentationsTable = styled(ContainersTable)`
  & table {
    background: #fff !important;
    & thead > tr > .ant-table-cell {
      color: var(--neutral_medium);
      font-size: 12px;
      padding: 0px;
      &:first-child {
        background: #fff !important;
      }
      &:last-child {
        background: #fff !important;
      }
      & > .work-shift {
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        & > .work-shift-name,
        & > .work-shift-gang-amount {
          background: rgba(204, 221, 233, 0.5);
          padding: 5px;
        }
        & > .work-shift-name {
          margin-bottom: 2px;
          border-radius: 5px 5px 0 0;
        }
        & > .work-shift-gang-amount {
          border-radius: 0 0 5px 5px;
          text-transform: lowercase;
        }
      }
      &:last-child {
        color: var(--neutral_dark);
        text-align: center;
        vertical-align: bottom;
      }
    }
    & tbody > tr > td {
      background: var(--neutral_lightest) !important;
      border-radius: 5px;
      padding: 4px 16px 4px 16px;
      text-align: center;
      &:first-child {
        color: var(--neutral_medium);
        font-weight: 700;
        text-align: right;
        background: #fff !important;
      }
      &:last-child {
        background: #dbe7f0 !important;
      }
    }
    & tbody > tr {
      &:last-child {
        td {
          background: #dbe7f0 !important;
          &:first-child {
            background: #fff !important;
            color: var(--neutral_dark);
            font-weight: normal;
          }
          &:last-child {
            background: var(--neutral_medium) !important;
            color: #fff;
          }
        }
      }
    }
  }
`;
export const VehiclesMovimentations = styled.div`
  padding-top: 8px;
  & ${Title} {
    color: #000;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 0px;
    margin-right: 10px;
    & ${Number} {
      font-weight: 700;
    }
  }
  & ${Subtitle} {
    font-size: 16px;
    font-weight: normal;
    & ${Number} {
      font-weight: 700;
    }
  }
`;
