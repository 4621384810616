import { EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import { Button, FormatItem } from '../../../components';
import { createDateStringPtBr } from '../../../utils/utils';

export function warehousingVehiclesTableColumns(
  setSelectedChassis: (chassis: any) => void
): ColumnsType<Record<string, any>> {
  return [
    { title: 'Chassi', dataIndex: 'chassis' },
    {
      title: 'Chegada',
      dataIndex: 'posting_date',
      render: (value) => {
        return <FormatItem>{createDateStringPtBr(value)}</FormatItem>;
      },
    },
    {
      title: 'Saída',
      dataIndex: 'departure_date',
      render: (value) => {
        return <FormatItem>{createDateStringPtBr(value)}</FormatItem>;
      },
    },
    {
      title: 'Faixa de tempo',
      dataIndex: 'storage_days',
      render: (value) => {
        if (value >= 0 && value <= 7) {
          return '0 a 7 dias';
        }
        if (value >= 8 && value <= 14) {
          return '8 a 14 dias';
        }
        if (value >= 15 && value <= 21) {
          return '8 a 14 dias';
        }
        return '> 21';
      },
    },
    {
      title: 'Área',
      dataIndex: 'parking_space__storage_company__name',
      render: (value) => <FormatItem>{value}</FormatItem>,
    },
    {
      title: 'Navegação de carga',
      dataIndex: 'cargo_navigation_type',
      render: (value) => <FormatItem>{value}</FormatItem>,
    },
    {
      title: 'Sentido',
      dataIndex: 'operation_direction',
      render: (value) => <FormatItem>{value}</FormatItem>,
    },
    {
      title: 'Status XML',
      dataIndex: 'last_date_xml',
      render: (value) => <FormatItem>{createDateStringPtBr(value)}</FormatItem>,
    },
    {
      title: '',
      render: (record) => (
        <Button
          style={{ float: 'right' }}
          icon={<EyeOutlined />}
          onClick={() => setSelectedChassis(record)}
        />
      ),
    },
  ];
}
