import { Col } from 'antd';

import { FormItem } from '../Form';
import { Input } from '../Input';

type FormItemTextAreaProps = {
  label: string;
  name: string | (string | number)[];
  placeholder?: string;
  maxLength?: number;
  colSpan?: number;
  rows?: number;
  hideCount?: boolean;
  required?: boolean;
  help?: string;
};

const { TextArea } = Input;

export function FormItemTextArea(props: FormItemTextAreaProps) {
  const {
    label,
    name,
    placeholder,
    maxLength,
    colSpan,
    rows,
    hideCount,
    required,
    help,
  } = props;

  return (
    <Col span={colSpan || 24}>
      <FormItem label={label} name={name} required={required} help={help}>
        <TextArea
          placeholder={placeholder}
          maxLength={maxLength || 100}
          rows={rows || 5}
          showCount={!hideCount}
          required={required}
        />
      </FormItem>
    </Col>
  );
}
