import { UploadOutlined } from '@ant-design/icons';
import { Alert, message, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Button, Collapse, Modal, Progress } from '../../components';
import { useOperationContainersUploadMutation } from '../../services/operationContainersApi';

type ContainersImportModalProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  operationId: number;
};

const { Panel } = Collapse;
const { confirm } = Modal;

const boardingData = {
  uid: '1',
  name: 'Embarque',
};

const landingData = {
  uid: '1',
  name: 'Desembarque',
};

const removalData = {
  uid: '1',
  name: 'Remocao',
};

export function ContainersImportModal(props: ContainersImportModalProps) {
  const { isVisible, setIsVisible, operationId } = props;

  const [fileBoarding, setFileBoarding] = useState<File>({} as File);
  const [fileLanding, setFileLanding] = useState<File>({} as File);
  const [fileRemoval, setFileRemoval] = useState<File>({} as File);

  const [upFileBoarding, setUpFileBoarding] =
    useState<UploadFile<any>>(boardingData);
  const [upFileLanding, setUpFileLanding] =
    useState<UploadFile<any>>(landingData);
  const [upFileRemoval, setUpFileRemoval] =
    useState<UploadFile<any>>(removalData);

  const [showAlertNameBoarding, setShowAlertNameBoarding] = useState(false);
  const [showAlertNameLanding, setShowAlertNameLanding] = useState(false);
  const [showAlertNameRemoval, setShowAlertNameRemoval] = useState(false);

  const [operationContainersUpload, { isSuccess, isLoading }] =
    useOperationContainersUploadMutation();

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress) {
      setTimeout(() => {
        if (progress < 97) {
          setProgress((prev) => prev + 2);
        }
      }, 1000);
    }
  }, [progress]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      message.success('Arquivos importados com sucesso');
      setProgress(0);
    }
  }, [isSuccess]);

  function beforeUploadBoarding(file: File) {
    if (!file.name.startsWith('Embarque')) {
      message.error(
        'O nome do arquivo não corresponde. O nome do arquivo deve começar com "Embarque"'
      );
      setShowAlertNameBoarding(true);
      return false;
    }
    setFileBoarding(file);
    return false;
  }

  function beforeUploadLanding(file: File) {
    if (!file.name.startsWith('Desembarque')) {
      message.error(
        'O nome do arquivo não corresponde. O nome do arquivo deve começar com "Desembarque"'
      );
      setShowAlertNameLanding(true);
      return false;
    }
    setFileLanding(file);
    return false;
  }

  function beforeUploadRemoval(file: File) {
    if (!file.name.startsWith('Remocao')) {
      message.error(
        'O nome do arquivo não corresponde. O nome do arquivo deve começar com "Remocao"'
      );
      setShowAlertNameRemoval(true);
      return false;
    }
    setFileRemoval(file);
    return false;
  }

  function handleImportManifests() {
    const content = (
      <ul>
        {!isEmpty(fileBoarding) && (
          <li>Será feito upload do arquivo de embarque.</li>
        )}
        {!isEmpty(fileLanding) && (
          <li>Será feito upload do arquivo de desembarque.</li>
        )}
        {!isEmpty(fileRemoval) && (
          <li>Será feito upload do arquivo de remoção.</li>
        )}
      </ul>
    );

    confirm({
      title: 'Tem certeza que deseja importar os arquivos?',
      width: 500,
      content,
      cancelText: 'Cancelar',
      onOk() {
        const files = [fileBoarding, fileLanding, fileRemoval].filter(
          (file) => !isEmpty(file)
        );
        operationContainersUpload({ operationId, files });
        setProgress(10);
      },
    });
  }

  function handleClose() {
    setFileBoarding({} as File);
    setFileLanding({} as File);
    setFileRemoval({} as File);
    setIsVisible(false);
    setUpFileBoarding(boardingData);
    setUpFileLanding(landingData);
    setUpFileRemoval(removalData);
    setShowAlertNameBoarding(false);
    setShowAlertNameLanding(false);
    setShowAlertNameRemoval(false);
  }

  function onChangeUpFileBoarding(info: UploadChangeParam<UploadFile<any>>) {
    if (!info.file.name.startsWith('Embarque')) {
      return false;
    }
    if (info.file.status === 'removed') {
      if (upFileBoarding.uid === info.file.uid) {
        setUpFileBoarding((prev) => ({ ...prev, url: '', name: 'Embarque' }));
      }
      setFileBoarding({} as File);
      return;
    }

    setUpFileBoarding((prev) => ({
      ...prev,
      ...info.file,
      status: 'done',
      url: 'tossuape', // apenas para renderizar o status done
      name: info.file.name,
    }));
    setShowAlertNameBoarding(false);
  }

  function onChangeUpFileLanding(info: UploadChangeParam<UploadFile<any>>) {
    if (!info.file.name.startsWith('Desembarque')) {
      return false;
    }
    if (info.file.status === 'removed') {
      if (upFileLanding.uid === info.file.uid) {
        setUpFileLanding((prev) => ({ ...prev, url: '', name: 'Desembarque' }));
      }
      setFileLanding({} as File);
      return;
    }

    setUpFileLanding((prev) => ({
      ...prev,
      ...info.file,
      status: 'done',
      url: 'tossuape', // apenas para renderizar o status done
      name: info.file.name,
    }));
    setShowAlertNameLanding(false);
  }

  function onChangeUpFileRemoval(info: UploadChangeParam<UploadFile<any>>) {
    if (!info.file.name.startsWith('Remocao')) {
      return false;
    }
    if (info.file.status === 'removed') {
      if (upFileRemoval.uid === info.file.uid) {
        setUpFileRemoval((prev) => ({ ...prev, url: '', name: 'Remocao' }));
      }
      setFileRemoval({} as File);
      return;
    }

    setUpFileRemoval((prev) => ({
      ...prev,
      ...info.file,
      status: 'done',
      url: 'tossuape', // apenas para renderizar o status done
      name: info.file.name,
    }));
    setShowAlertNameRemoval(false);
  }

  return (
    <Modal
      className="TOSPrimaryModal"
      title="Importar arquivos de movimentação de carga"
      visible={isVisible}
      cancelText="Voltar"
      onCancel={() => !isLoading && handleClose()}
      okText="Importar arquivos"
      onOk={handleImportManifests}
      okButtonProps={{
        disabled:
          isEmpty(fileBoarding) && isEmpty(fileLanding) && isEmpty(fileRemoval),
        loading: isLoading,
      }}
      cancelButtonProps={{
        disabled: isLoading,
      }}
      closable={isLoading}
      bodyStyle={{ padding: '10px 0px' }}
      width={785}
    >
      <div style={{ padding: '10px' }}>
        <span>
          Caso deseje fazer alterações nas informações após a importação, será
          necessário anexar um novo arquivo.
        </span>
      </div>
      <Collapse
        activeKey={['loading', 'unloading', 'removal']}
        expandIconPosition="end"
      >
        <Panel key="loading" header="Carregamento">
          <span>
            Anexe abaixo o arquivo .txt com os dados de movimentação de carga de
            <b> carregamento</b> para o Porto de Suape:
          </span>
          <Upload
            maxCount={2}
            accept=".txt"
            beforeUpload={beforeUploadBoarding}
            fileList={[upFileBoarding]}
            onChange={onChangeUpFileBoarding}
          >
            <Button icon={<UploadOutlined />} type="primary">
              Anexar arquivos
            </Button>
          </Upload>
          {showAlertNameBoarding && (
            <Alert
              style={{ marginTop: '16px' }}
              type="error"
              message="O arquivo anexado deve começar com 'Embarque' e ser um .txt"
            />
          )}
        </Panel>
        <Panel key="unloading" header="Descarregamento">
          <span>
            Anexe abaixo o arquivo .txt com os dados de movimentação de carga de
            <b> descarregamento</b> para o Porto de Suape:
          </span>
          <Upload
            maxCount={2}
            accept=".txt"
            beforeUpload={beforeUploadLanding}
            fileList={[upFileLanding]}
            onChange={onChangeUpFileLanding}
          >
            <Button icon={<UploadOutlined />} type="primary">
              Anexar arquivos
            </Button>
          </Upload>
          {showAlertNameLanding && (
            <Alert
              style={{ marginTop: '16px' }}
              type="error"
              message="O arquivo anexado deve começar com 'Desembarque' e ser um .txt"
            />
          )}
        </Panel>
        <Panel key="removal" header="Remoção">
          <span>
            Anexe abaixo o arquivo .txt com os dados de movimentação de carga de
            <b> remoção</b> para o Porto de Suape:
          </span>
          <Upload
            maxCount={2}
            accept=".txt"
            beforeUpload={beforeUploadRemoval}
            fileList={[upFileRemoval]}
            onChange={onChangeUpFileRemoval}
          >
            <Button icon={<UploadOutlined />} type="primary">
              Anexar arquivos
            </Button>
          </Upload>
          {showAlertNameRemoval && (
            <Alert
              style={{ marginTop: '16px' }}
              type="error"
              message="O arquivo anexado deve começar com 'Remocao' e ser um .txt"
            />
          )}
        </Panel>
      </Collapse>
      <Alert
        style={{ margin: '10px' }}
        type="warning"
        message="Ao importar um novo .txt, ocorrerá a substituição de todas as informações."
      />
      {isLoading && (
        <div style={{ margin: '0 16px', textAlign: 'center' }}>
          <b>
            A importação pode levar até 1 minuto. Aguarde a conclusão e não saia
            da tela.
          </b>
          <Progress percent={progress} status="active" />
        </div>
      )}
    </Modal>
  );
}
