import { Stopover } from '../../types';
import { isNullOrUndefined } from '../../utils/utils';

export function getCurrentDocking(stopover?: Stopover) {
  if (isNullOrUndefined(stopover?.current_docking_id) && stopover?.dockings) {
    return stopover?.dockings[0];
  }
  return stopover?.dockings?.filter(
    (docking) => docking.id === stopover.current_docking_id
  )[0];
}
