import Flag from 'react-flagpack';

import {
  Descriptions,
  ErrorBoundary,
  FormatItem,
  Table,
  Title,
} from '../../components';
import { VehicleType } from '../../typesWarehousing';
import { createDateStringPtBr } from '../../utils/utils';
import { invoicingTableColumns } from './invoicingTableColumns';

type ImportChassisDetailsDrawerProps = {
  chassis?: VehicleType;
};

const { Item } = Descriptions;
export function ImportChassisDetailsDrawer(
  props: ImportChassisDetailsDrawerProps
) {
  const { chassis } = props;
  return (
    <div style={{ padding: '24px 16px' }}>
      <Title>DOCUMENTAÇÃO</Title>
      <Descriptions column={3}>
        <Item label="DI">
          <FormatItem>{chassis?.di_number}</FormatItem>
        </Item>
        <Item label="BL">
          <FormatItem>{chassis?.bl_number}</FormatItem>
        </Item>
        <Item label="Manifesto">
          <FormatItem>{chassis?.operation_manifest__manifest_code}</FormatItem>
        </Item>
        <Item label="CE Mercante">
          <FormatItem>{chassis?.merchant_ce__code}</FormatItem>
        </Item>
        <Item label="Consignatário">
          <FormatItem>{chassis?.operator_company__name}</FormatItem>
        </Item>
      </Descriptions>
      <Title>ARMAZENAMENTO</Title>
      <Descriptions column={2}>
        <Item label="Local de armazenamento">
          <FormatItem>{chassis?.storage_company__name}</FormatItem>
        </Item>
        <Item label="Vaga">
          <FormatItem>
            {chassis?.parking_space__block}.{chassis?.parking_space__row}.
            {chassis?.parking_space__column}
          </FormatItem>
        </Item>
      </Descriptions>
      <Descriptions column={3}>
        <Item label="Chegada">
          <FormatItem>{createDateStringPtBr(chassis?.posting_date)}</FormatItem>
        </Item>
        <Item label="Saída">
          <FormatItem>
            {createDateStringPtBr(chassis?.departure_date)}
          </FormatItem>
        </Item>
        <Item label="Dias armazenados">
          <FormatItem>{chassis?.storage_duration}</FormatItem>
        </Item>
      </Descriptions>
      <Title>DESCRIÇÃO DA CARGA</Title>
      <Descriptions column={1}>
        <Item label="NCM">
          <FormatItem>
            {chassis?.ncm_code} - {chassis?.ncm_description}
          </FormatItem>
        </Item>
      </Descriptions>
      <Descriptions column={2}>
        <Item label="Peso">
          <FormatItem>{chassis?.weight}</FormatItem>
        </Item>
      </Descriptions>
      <Title>OPERAÇÃO DE TRANSPORTE</Title>
      <br />
      <Title>Marítimo</Title>
      <Descriptions column={2}>
        <Item label="Embarcação">
          <FormatItem>
            {chassis?.vessel__flag && (
              <ErrorBoundary replace="">
                <Flag code={chassis?.vessel__flag} hasBorder={false} size="s" />
              </ErrorBoundary>
            )}{' '}
            <FormatItem>{chassis?.vessel__name}</FormatItem>
          </FormatItem>
        </Item>
        <Item label="IMO">
          <FormatItem>{chassis?.vessel__imo}</FormatItem>
        </Item>
        <Item label="Escala">
          <FormatItem>{chassis?.stopover__code}</FormatItem>
        </Item>
        <Item label="Viagem">
          <FormatItem>
            {chassis?.loading_port__name}, {chassis?.loading_port__country_name}
          </FormatItem>
        </Item>
        <Item label="Porto de carregamento">
          <FormatItem>
            {chassis?.loading_port__name}, {chassis?.loading_port__country_name}
          </FormatItem>
        </Item>
        <Item label="Porto de descarregamento">
          <FormatItem>
            {chassis?.unloading_port__name},{' '}
            {chassis?.unloading_port__country_name}
          </FormatItem>
        </Item>
        <Item label="Tipo de operação da carga">
          <FormatItem>{chassis?.operation_type__description}</FormatItem>
        </Item>
      </Descriptions>
      <Title>Terrestre</Title>
      <Descriptions column={2}>
        <Item label="Transportadora">
          <FormatItem>{chassis?.carrier__name}</FormatItem>
        </Item>
        <Item label="Frota">
          <FormatItem>{chassis?.fleet}</FormatItem>
        </Item>
      </Descriptions>
      {/* <Title>FATURAMENTO</Title>
      <Table columns={invoicingTableColumns()} size="small" /> */}
    </div>
  );
}
