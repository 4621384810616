import { Dispatch, SetStateAction, useEffect } from 'react';

import {
  Descriptions,
  FormatItem,
  Modal,
  Tabs,
} from '../../../../../components';
import {
  CargoManifestType,
  CeMerchantsGroupbyOperatorCompanyType,
  CEMerchantType,
} from '../../../../../types';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import { StorageMerchantManifestTable } from './storageMerchantTable/StorageMerchantManifestTable';
import { ModalTitle } from './styles';
import { useLazyGetMerchantsCeGroupbyStorageTerminalQuery } from '../../../../../services/ceMechantApi';

const { TabPane } = Tabs;

type NcmItemsModalProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  cargoManifest: CargoManifestType;
  ceMerchantSelected: CEMerchantType;
  setCeMerchantSelected: Dispatch<SetStateAction<CEMerchantType>>;
  operatorCompanySelected: CeMerchantsGroupbyOperatorCompanyType;
};

const { Item } = Descriptions;

export function NcmItemsModal(props: NcmItemsModalProps) {
  const {
    isVisible,
    setIsVisible,
    ceMerchantSelected,
    cargoManifest,
    setCeMerchantSelected,
    operatorCompanySelected,
  } = props;

  function handleCancelModal() {
    setIsVisible(false);
    setCeMerchantSelected({} as CEMerchantType);
  }
  const [
    getStorageTerminals,
    {
      data: ceMerchantGroupByStorageTerminals,
      isFetching: isFechtingStorageNames,
    },
  ] = useLazyGetMerchantsCeGroupbyStorageTerminalQuery();

  useEffect(() => {
    if (isVisible) {
      getStorageTerminals({ code: ceMerchantSelected.code });
    }
  }, [isVisible]);

  function getTitle() {
    return (
      <ModalTitle>
        <div>
          <span className="consignee-name">{operatorCompanySelected.name}</span>
          <span className="consignee-cnpj">{operatorCompanySelected.cnpj}</span>
        </div>
        <span className="ceMerchant-title">
          CE MERCANTE {ceMerchantSelected.code}
        </span>
      </ModalTitle>
    );
  }

  return (
    <Modal
      className="TOSPrimaryModal"
      width={900}
      title={getTitle()}
      visible={isVisible}
      destroyOnClose
      onCancel={() => handleCancelModal()}
      footer={null}
    >
      <Descriptions column={2}>
        <Item label="Porto de origem">
          <FormatItem>
            {capitalizeFirstLetter(cargoManifest.loading_port?.country_name)},{' '}
            {capitalizeFirstLetter(cargoManifest.loading_port?.name)}
          </FormatItem>
        </Item>
        <Item label="Porto de destino">
          <FormatItem>
            {capitalizeFirstLetter(cargoManifest.unloading_port?.country_name)},{' '}
            {capitalizeFirstLetter(cargoManifest.unloading_port?.name)}
          </FormatItem>
        </Item>
      </Descriptions>

      <Tabs>
        {ceMerchantGroupByStorageTerminals?.map((storageTerminal, index) => {
          return (
            <TabPane
              tab={`${
                storageTerminal.storage_name || 'Terminal não informado'
              }`}
              key={`storage_${index}`}
            >
              <StorageMerchantManifestTable
                ceMerchantCode={ceMerchantSelected.code}
                storageTerminal={storageTerminal}
              />
            </TabPane>
          );
        })}
      </Tabs>
    </Modal>
  );
}
