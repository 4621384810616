import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FormItemInput } from '../../components';
import { Button } from '../../components/antd/Button';
import { LayoutLogin } from '../../components/common/layoutLogin/LayoutLogin';
import { useUpdatePasswordMutation } from '../../services/authApi';
import { Form, Title, Footer, ButtonBackLogin } from './styles';

export function ChangeYourPassword() {
  const [form] = Form.useForm();
  const [send, setSend] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  const isLoading = false;

  const [updatePassword, { isError, isSuccess }] = useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      setSend(true);
    }
    if (isError) {
      message.error('Erro ao alterar a senha');
    }
  }, [isError, isSuccess]);

  async function handleFormSubmit() {
    let isValid = true;
    form.validateFields().then(async (values) => {
      if (values.password !== values.passwordConfirm) {
        message.error('Senhas não são iguais');
        isValid = false;
      }
      if (isValid) {
        await updatePassword({ password: values.password, token: token || '' });
      }
    });
  }

  return (
    <LayoutLogin>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        layout="vertical"
        requiredMark="optional"
      >
        <Title>{send ? 'Faça seu login' : 'Altere sua senha'}</Title>
        <div>
          {send ? (
            <span>Senha alterada com sucesso.</span>
          ) : (
            <>
              <FormItemInput
                label="Nova senha"
                name="password"
                type="password"
                required
                requiredMessage="Informe sua nova senha"
                disabled={isLoading}
                placeholder="insira sua nova senha"
              />
              <FormItemInput
                label="Confirme sua nova senha"
                name="passwordConfirm"
                type="password"
                required
                requiredMessage="Confirme sua nova senha"
                disabled={isLoading}
                placeholder="confirme sua nova senha"
              />
            </>
          )}
          <Footer>
            <ButtonBackLogin onClick={() => navigate('/login')}>
              <ArrowLeftOutlined /> voltar para o login
            </ButtonBackLogin>
            <Form.Item>
              {!send && (
                <Button
                  style={{ width: '106px', height: '40px' }}
                  type="primary"
                  htmlType="submit"
                  block
                  icon={!isLoading && <ArrowRightOutlined />}
                >
                  {isLoading ? <LoadingOutlined /> : 'Enviar'}
                </Button>
              )}
            </Form.Item>
          </Footer>
        </div>
      </Form>
    </LayoutLogin>
  );
}
