import { useMemo } from 'react';

import { Tag } from '../../../components';
import { listInvoicingStatus } from '../../../utils/lists';

export function InvoicingStatusTag(props: { status: string }) {
  const { status } = props;

  const selectedStatus = useMemo(() => {
    return (
      listInvoicingStatus.find((item) => item.id === status) ||
      listInvoicingStatus[0]
    );
  }, [status]);

  return (
    <Tag className={`invoicing-status ${selectedStatus?.className}`}>
      {selectedStatus.name}
    </Tag>
  );
}
