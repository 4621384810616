import {
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from '@ant-design/icons';
import { Collapse } from 'antd';
import styled from 'styled-components';

import { CheckListItem } from './CheckListItem';

const { Panel } = Collapse;

type CheckListProps = {
  title?: string;
  items?: any[];
  labelField?: string;
  checkedField?: string;
  isCollapsable?: boolean;
};

export const CheckListStyle = styled.div`
  & .ant-collapse-header {
    padding: 0px !important;
    margin-bottom: 10px !important;
    background: #fff;
    font-size: unset;
    font-weight: normal;
    &:hover {
      background: #fff;
    }
    & .ant-collapse-header-text li {
      margin-bottom: 0px !important;
    }
  }
  & .ant-collapse-content-box {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 25px !important;
  }
`;

export function CheckList(props: CheckListProps) {
  const { title, items, labelField, checkedField, isCollapsable } = props;
  function createPanelHeaderTitle(item: any) {
    const isChecked = item.subItens.every(
      (subItem: any) => subItem.checked === true
    )
      ? 'ALL'
      : item.subItens.every((subItem: any) => subItem.checked === false)
      ? 'NONE'
      : item.subItens.some((subItem: any) => subItem.checked === true)
      ? 'SOME'
      : null;
    return (
      <CheckListItem
        checked={checkedField ? item[checkedField] : item.checked}
        title={labelField ? item[labelField] : item.title}
        checkedIcon={
          isChecked === 'ALL' ? (
            <CheckCircleFilled
              style={{ color: 'var(--success-dark)', marginRight: '10px' }}
            />
          ) : isChecked === 'NONE' ? (
            <CloseCircleFilled
              style={{ color: 'var(--error-medium)', marginRight: '10px' }}
            />
          ) : isChecked === 'SOME' ? (
            <MinusCircleFilled
              style={{ color: 'var(--warning-medium)', marginRight: '10px' }}
            />
          ) : null
        }
      />
    );
  }
  return isCollapsable ? (
    <CheckListStyle>
      <div style={{ marginBottom: '10px' }}>{title}</div>
      <Collapse ghost expandIconPosition="end">
        {items?.map((item, index) => {
          if (item.subItens) {
            return (
              <Panel
                header={createPanelHeaderTitle(item)}
                key={`item-${index}`}
                style={{ padding: '0px' }}
              >
                <ul
                  style={{
                    listStyle: 'none',
                    paddingLeft: '0px',
                  }}
                >
                  {item.subItens.map((subitem: any, subitemIndex: number) => {
                    return (
                      <CheckListItem
                        key={`subitem-${index}-${subitemIndex}`}
                        checked={
                          checkedField ? subitem[checkedField] : subitem.checked
                        }
                        title={subitem.title}
                      />
                    );
                  })}
                </ul>
              </Panel>
            );
          }
          return (
            <CheckListItem
              key={`item-${index}`}
              checked={checkedField ? item[checkedField] : item.checked}
              title={labelField ? item[labelField] : item.title}
            />
          );
        })}
      </Collapse>
    </CheckListStyle>
  ) : (
    <>
      <div style={{ marginBottom: '10px' }}>{title}</div>
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '0px',
        }}
      >
        {items?.map((consent, index) => {
          return (
            <CheckListItem
              key={index}
              checked={checkedField ? consent[checkedField] : consent.checked}
              title={labelField ? consent[labelField] : consent.title}
            />
          );
        })}
      </ul>
    </>
  );
}
