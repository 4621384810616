import { Alert, Row, Space, message } from 'antd';
import moment, { Moment } from 'moment';

import { Button, Form, FormItemDatePicker, Modal } from '../../components';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type EndStaymentModalProps = BaseActionModalProps & {
  onEndStayment: () => void;
};
export function EndStaymentModal(props: EndStaymentModalProps) {
  const {
    setIsVisible,
    stopover,
    currentDocking,
    updateStopover,
    onEndStayment,
  } = props;
  const [form] = Form.useForm();

  function validate(lastCableUntiedIn: string, portStayFinished: Moment) {
    const lastCableUntiedInMoment = moment(lastCableUntiedIn);
    if (portStayFinished <= lastCableUntiedInMoment) {
      message.error(
        `A data finalização da estadia não pode ser menor que a data do ultimo cabo amarrado da desatracação(${createDateStringPtBr(
          lastCableUntiedInMoment
        )})`
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (
        !validate(
          currentDocking?.last_cable_untied_in || '',
          values.port_stay_finished
        )
      )
        return;

      onEndStayment();
      if (updateStopover) {
        updateStopover({
          port_stay_finished: createDateString(values.port_stay_finished),
          id: stopover?.id,
        });
      }
    });
  }
  return (
    <>
      A embarcação <b>{stopover?.vessel?.name}</b>{' '}
      {currentDocking?.docking_manoeuvre?.destiny &&
        `que está em ${currentDocking?.docking_manoeuvre?.destiny?.name}`}
      , não possui novas atracações. <br />
      Deseja finalizar a estadia?
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <Row gutter={32} style={{ marginTop: '24px' }}>
          <FormItemDatePicker
            name="port_stay_finished"
            label="Estadia no porto finalizada em:"
            disabledDate={disabledDateAfterToday}
            tooltip=""
            colSpan={12}
            required
            rules={[
              {
                required: true,
                message: 'Por favor, preencha a data de finalização da estadia',
              },
            ]}
          />
        </Row>
        <Alert
          type="info"
          message="Ao registrar a desatracação da embarcação o status será alterado para “Finalizada”."
        />
        <Space
          size={12}
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            marginTop: '20px',
          }}
        >
          <Button type="text" onClick={() => setIsVisible(false)}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit">
            Finalizar estadia
          </Button>
        </Space>
      </Form>
    </>
  );
}
