import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Button, Input, Tag } from '../../../components';
import { VehicleType } from '../../../typesWarehousing';
import { chassisStatus } from '../../../utils/lists';

export function vehiclesTableColumns(
  direction: 'IMPORT' | 'EXPORT',
  onOpenChassisDetailsDrawer: (chassi: VehicleType) => void
): ColumnsType<Record<string, any>> {
  if (direction === 'IMPORT') {
    return [
      {
        title: 'Chassi',
        dataIndex: 'chassis',
      },
      {
        title: 'Status do chassi',
        dataIndex: 'chassis_status',
        render: (value: any) => {
          return (
            <Tag>
              {
                chassisStatus.find(
                  (chassiStatus) => chassiStatus.value === value
                )?.label
              }
            </Tag>
          );
        },
      },
      { title: 'Tipo de operação da carga', dataIndex: 'operation_type' },
      {
        render: (_, record) => {
          return (
            <Button
              style={{ float: 'right' }}
              icon={<EyeOutlined />}
              onClick={() => onOpenChassisDetailsDrawer(record as VehicleType)}
            />
          );
        },
      },
    ];
  }
  return [
    { title: 'Chassi', dataIndex: 'chassis' },
    { title: 'DU-E', dataIndex: 'due__number' },
    {
      title: 'Status do chassi',
      dataIndex: 'chassis_status',
      render: (value: any) => {
        return (
          <Tag>
            {
              chassisStatus.find((chassiStatus) => chassiStatus.value === value)
                ?.label
            }
          </Tag>
        );
      },
    },
    { title: 'Tipo de operação da carga', dataIndex: 'operation_type' },
    { title: 'Vaga', dataIndex: 'parking_space' },
    {
      render: (_, record) => {
        return (
          <Button
            style={{ float: 'right' }}
            icon={<EyeOutlined />}
            onClick={() => onOpenChassisDetailsDrawer(record as VehicleType)}
          />
        );
      },
    },
  ];
}
