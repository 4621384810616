import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { Col, Space, Select } from 'antd';
import { BaseOptionType } from 'antd/lib/select';
import { debounce, isEmpty } from 'lodash';
import { useState } from 'react';

import {
  useGetCompaniesQuery,
  useGetCompanyQuery,
} from '../../../services/companyApi';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import { Docking } from '../../../types';
import { removeSpecialCharacters } from '../../../utils/utils';
import { Button, FormItemSelect, TableFormList, FormItem } from '../../index';
import { FormListItemRowStyle } from '../tableFormList/styles';

type FinancialOfficersTableProps = {
  docking?: Docking | null;
};

export function FinancialOfficersTable(props: FinancialOfficersTableProps) {
  const { docking } = props;

  function FinancialTableRow(props: {
    index: number;
    onRemove?: (index: number | number[]) => void;
  }) {
    const { index, onRemove } = props;
    const { data: company, isFetching: isLoadingCurrentCompany } =
      useGetCompanyQuery(
        {
          tag:
            docking &&
            !isEmpty(docking) &&
            docking?.financial_tables?.length > 0
              ? `${docking?.financial_tables[index]?.company?.id}`
              : '',
        },
        {
          skip:
            docking &&
            !isEmpty(docking) &&
            docking?.financial_tables?.length > 0
              ? !docking?.financial_tables[index]?.company?.id
              : true,
        }
      );
    const [searchCompanyName, setSearchCompanyName] = useState('');
    const { data: filteredCompanies, isFetching: isLoadingCompanies } =
      useGetCompaniesQuery({
        page_size: 100,
        name_or_cnpj: searchCompanyName,
      });
    const {
      data: financialTableTypes,
      isFetching: isLoadingFinancialTableTypes,
    } = useGetSystemParamByNameQuery({
      name: 'FINANCIAL_TABLE',
    });
    const [showRowButtons, setshowRowButtons] = useState(
      !!(
        !isEmpty(docking) &&
        docking?.financial_tables[index] &&
        docking?.financial_tables[index].company
      )
    );
    function onSearch(evt: string) {
      const search = removeSpecialCharacters(evt, /[./-]/g);
      setSearchCompanyName(search || evt);
    }
    function onSelectCompany() {
      setshowRowButtons(true);
    }
    function companyOptionRenderer(option: Record<string, any>) {
      const optionName = `${option.cnpj ? option.cnpj.concat(' - ') : ''}${
        option.name
      }`;
      return (
        <Select.Option key={option.id} value={option.id}>
          {optionName}
        </Select.Option>
      );
    }
    function filterOption(input: string, option: BaseOptionType) {
      const cleanLabel =
        removeSpecialCharacters(
          option?.props.children.toLowerCase(),
          /[./-]/g
        ) || '';
      const cleanInput =
        removeSpecialCharacters(input.toLowerCase(), /[./-]/g) || '';
      return cleanLabel.indexOf(cleanInput) >= 0;
    }
    return (
      <FormListItemRowStyle gutter={8}>
        <Col span={10}>
          <FormItemSelect
            name={[index, 'financial_table_type', 'id']}
            style={{ marginBottom: '0px' }}
            placeholder="Tabela"
            isLoading={isLoadingFinancialTableTypes}
            dataList={financialTableTypes?.items}
            showSearch
            required
          />
        </Col>
        <Col span={11}>
          <FormItemSelect
            name={[index, 'company', 'id']}
            style={{ marginBottom: '0px' }}
            placeholder="Empresa"
            onSearch={debounce(onSearch, 300)}
            isLoading={isLoadingCurrentCompany || isLoadingCompanies}
            dataList={
              company
                ? [company].concat(filteredCompanies?.results || [])
                : filteredCompanies?.results
            }
            showSearch
            onSelect={onSelectCompany}
            optionRenderer={companyOptionRenderer}
            filterOption={filterOption}
            optionFilterProp="label"
            required
          />
          <FormItem noStyle name={[index, 'company', 'id']} />
        </Col>
        <Col span={3}>
          <Space size={8}>
            {/* {showRowButtons && (
              <Button type="default" icon={<MailOutlined />} />
            )} */}
            <Button
              type="default"
              danger
              icon={<DeleteOutlined />}
              onClick={onRemove ? () => onRemove(index) : () => {}}
            />
          </Space>
        </Col>
      </FormListItemRowStyle>
    );
  }

  return (
    <TableFormList
      FormListItemRow={FinancialTableRow}
      formListName={['financial_tables']}
      columns={[
        { title: 'TABELA', colSpan: 10 },
        { title: 'EMPRESA', colSpan: 11 },
        { title: '', colSpan: 3 },
      ]}
      itemToAdd={{ financial_table_type: {}, company: {} }}
      addButtonText="Novo responsável"
      addButtonType="primary"
      addButtonStyle={{ margin: '0px 0px 8px' }}
      style={{ marginTop: '0px' }}
    />
  );
}
