import { Checkbox } from 'antd';
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group';

import { StyledCheckBoxButtonGroup } from './styles';

type CheckBoxButtonGroupProps = {
  options: CheckboxOptionType[];
  value?: CheckboxValueType[];
  onChange?: (values: CheckboxValueType[]) => void;
  disabled?: boolean;
};

export function CheckBoxButtonGroup(props: CheckBoxButtonGroupProps) {
  const { options, value, onChange, disabled } = props;
  return (
    <StyledCheckBoxButtonGroup>
      <Checkbox.Group
        disabled={disabled}
        options={options}
        value={value}
        onChange={onChange}
      />
    </StyledCheckBoxButtonGroup>
  );
}
