import { Progress as ProgresANTD } from 'antd';
import styled from 'styled-components';

export const Progress = styled(ProgresANTD)`
  margin-block-end: 15px;
  & .ant-progress-inner {
    height: 20px;
  }

  & .ant-progress-bg {
    height: 20px !important;
  }
  &.ant-progress-small .ant-progress-inner,
  &.ant-progress-small .ant-progress-bg {
    height: 8px !important;
  }
  & .ant-progress-outer {
    margin-right: calc(-2em - 90px) !important;
    padding-right: calc(2em + 75px) !important;
  }
  & .ant-progress-text {
    width: 10% !important;
    text-align: right !important;
  }
`;
