import { useMemo } from 'react';

import { Descriptions, FormatItem, Title } from '../../components';
import { OperationLineType, OperationProductType } from '../../types';
import { formatOperationLineToDescription } from './formatters';

type OperationLineDescriptionProps = {
  operationLine: OperationLineType;
  hideProductAndMovimentation?: boolean;
  operationProducts?: OperationProductType[];
};
export function OperationLineDescription(props: OperationLineDescriptionProps) {
  const { operationLine, hideProductAndMovimentation, operationProducts } =
    props;

  const operationDescription = useMemo(() => {
    return formatOperationLineToDescription(
      operationLine,
      operationProducts || []
    );
  }, [operationLine]);

  return (
    <div className="panel-content">
      {!hideProductAndMovimentation && (
        <Descriptions column={2}>
          <Descriptions.Item label="Produto">
            <FormatItem>{operationDescription.operation_product}</FormatItem>
          </Descriptions.Item>
          <Descriptions.Item label="Peso movimentado">
            <FormatItem>
              {operationDescription.movimentation.gross_flow}
            </FormatItem>
          </Descriptions.Item>
        </Descriptions>
      )}
      <Title>Conexão</Title>
      <Descriptions column={1}>
        <Descriptions.Item label="Hora real de conexão">
          <FormatItem>{operationDescription.real_connection_hour}</FormatItem>
          <span style={{ display: 'inline-block', margin: '0 5px 0 5px' }}>
            até
          </span>
          <FormatItem>
            {operationDescription.real_desconnection_hour}
          </FormatItem>
        </Descriptions.Item>
      </Descriptions>

      <Title>Bombeio</Title>
      <Descriptions column={1}>
        <Descriptions.Item label="Hora real de bombeio">
          <FormatItem>{operationDescription.real_pump_start}</FormatItem>
          <span style={{ display: 'inline-block', margin: '0 5px 0 5px' }}>
            até
          </span>
          <FormatItem>{operationDescription.real_pump_finish}</FormatItem>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
