import { Row, Space, message } from 'antd';
import moment, { Moment } from 'moment';

import { Button, Form, FormItemDatePicker } from '../../components';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type StartOperationModalProps = BaseActionModalProps & {
  onStartOperation: () => void;
};

export function StartOperationModal(props: StartOperationModalProps) {
  const {
    setIsVisible,
    onStartOperation,
    updateDocking,
    currentDocking,
    isLoadingUpdateDocking,
  } = props;
  const [form] = Form.useForm();

  function validate(lastCableTiedIn: string, operationRealStart: Moment) {
    const lastCableTiedInMoment = moment(lastCableTiedIn);
    if (operationRealStart <= lastCableTiedInMoment) {
      message.error(
        `A data do início da operação não pode ser menor que a data de último cabo amarrado(${createDateStringPtBr(
          lastCableTiedInMoment
        )})`
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (updateDocking) {
        if (
          !validate(
            currentDocking?.last_cable_tied_in || '',
            values.operation_summary.real_start
          )
        )
          return;

        updateDocking(
          {
            operation_summary: {
              real_start: createDateString(values.operation_summary.real_start),
              expected_start: createDateString(
                values.operation_summary.expected_start
              ),
            },
            id: currentDocking?.id,
          },
          onStartOperation
        );
      }
    });
  }
  return (
    <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
      <Row gutter={32}>
        <FormItemDatePicker
          name={['operation_summary', 'real_start']}
          label="Hora real de início da operação:"
          disabledDate={disabledDateAfterToday}
          colSpan={12}
          tooltip=""
          rules={[
            {
              required: true,
              message:
                'Por favor, preencha a data e hora do início da operação',
            },
          ]}
          required
        />
        <FormItemDatePicker
          name={['operation_summary', 'expected_start']}
          label="Previsão de início da operação:"
          colSpan={12}
          tooltip=""
          minuteStep={15}
        />
      </Row>
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          loading={isLoadingUpdateDocking}
          htmlType="submit"
        >
          Iniciar operação
        </Button>
      </Space>
    </Form>
  );
}
