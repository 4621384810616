import { tosApi } from './tosApi';

export const DueUploadApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    uploadPredictedDue: build.mutation<
      void,
      {
        files: File[];
        stopover_id: number;
        automobile_manufacturer_id: number;
        financial_responsible_id: number;
      }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('file[]', data.files[0]);
        formData.append('stopover_id', data.stopover_id.toString());
        formData.append(
          'automobile_manufacturer_id',
          data.automobile_manufacturer_id.toString()
        );
        formData.append(
          'financial_responsible_id',
          data.financial_responsible_id.toString()
        );
        return {
          url: `warehousing/dues/upload-predicted-due`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    uploadPredictedCin: build.mutation<
      void,
      {
        files: File[];
        stopover_id: number;
        automobile_manufacturer_id: number;
        financial_responsible_id: number;
      }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('file[]', data.files[0]);
        formData.append(
          'automobile_manufacturer_id',
          data.automobile_manufacturer_id.toString()
        );
        formData.append(
          'financial_responsible_id',
          data.financial_responsible_id.toString()
        );
        return {
          url: `warehousing/importing-vehicles-group/upload-predicted-cin/${data.stopover_id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    receiptStopoverDues: build.mutation<any, number | undefined>({
      query: (stopoverId) => {
        return {
          url: `warehousing/dues/receipt-dues/${stopoverId}`,
          method: 'PATCH',
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useUploadPredictedDueMutation,
  useUploadPredictedCinMutation,
  useReceiptStopoverDuesMutation,
} = DueUploadApi;
