import { isEmpty } from 'lodash';
import { useState } from 'react';
import Flag from 'react-flagpack';

import { DefaultLayout, ErrorBoundary } from '../../components';
import { useChangePagination } from '../../hooks';
import { useGetStopoversGroupbyOperationQuery } from '../../services/stopoverApi';
import { FilterDefaultLayoutType, Stopover as StopoverType } from '../../types';
import { OperationStatusTag } from './operationStatusTag';
import { OperatorAreaDetails } from './operatorAreaDetails';
import { OperatorAreaDrawer } from './operatorAreaDrawer/OperatorAreaDrawer';
import { CardRenderer } from './styles';

const tabs = [
  { label: 'Todas', value: '' },
  { label: 'Aguardando operação', value: 'WAITING_OPERATION' },
  { label: 'Operando', value: 'IN_OPERATION' },
  { label: 'Operação Finalizada', value: 'OPERATION_FINISHED' },
  { label: 'Operação Cancelada', value: 'CANCELED' },
];

const GENERAL_CARGO = 'CARGA GERAL';
const CONTAINER = 'CONTÊINER';

export function OperatiorArea() {
  const { onChangePagination, queryPage } = useChangePagination();
  const [filter, setFilter] = useState<FilterDefaultLayoutType>({
    page: queryPage,
  });

  const { data: stopoveresData, isLoading: isLoadingStopoveresData } =
    useGetStopoversGroupbyOperationQuery(filter);

  const [isVisibleAddOperationDrawer, setisVisibleAddOperationDrawer] =
    useState(false);

  const [selectedStopover, setSelectedStopover] = useState<StopoverType>(
    {} as StopoverType
  );

  const [filteredStopoveres, setFilteredStopoveres] = useState(
    [] as StopoverType[]
  );

  const [hasEdited, setHasEdited] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  function getCardRenderer(stopover: StopoverType) {
    const isContainer =
      stopover.nature_of_cargo_name === GENERAL_CARGO &&
      stopover.product_group_name === CONTAINER;
    return (
      <CardRenderer>
        <ErrorBoundary replace="">
          <Flag code={stopover.vessel_flag} hasBorder={false} size="m" />
        </ErrorBoundary>
        <div className="item-data">
          <div className="space-between">
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <span>{stopover.vessel_name}</span>
            </div>
            <span>
              Atracação:{' '}
              <span className="color-neutral-medium">
                {stopover.docking_code || 'Código não gerado'}
              </span>
            </span>
          </div>
          <div className="space-between" style={{ fontSize: '14px' }}>
            {isContainer ? (
              <div>
                <span style={{ fontWeight: '700' }}>Contêineres </span>
                <span style={{ fontWeight: '500' }}>
                  ({stopover.total_operation_containers} unidade(s))
                </span>
              </div>
            ) : (
              <span>{stopover.total_operation_products}</span>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            <span>
              Berço:{' '}
              <span className="color-neutral-medium">
                {stopover.docking_place_name}
              </span>
            </span>
          </div>
          <div style={{ display: 'flex' }}>
            <span style={{ textOverflow: 'ellipsis' }}>Manifestos: </span>
            <span className="color-neutral-medium text-hide">
              {isContainer
                ? stopover.container_manifest_numbers
                : stopover.operation_product_manifest_numbers}
            </span>
          </div>
          <div>
            {stopover.has_operation_product_pending &&
            stopover.operation_status !== 'CANCELED' ? (
              <OperationStatusTag operationStatus="PENDING" />
            ) : (
              <OperationStatusTag operationStatus={stopover.operation_status} />
            )}
          </div>
        </div>
      </CardRenderer>
    );
  }

  function onCloseDrawer() {
    setisVisibleAddOperationDrawer(false);
    setSelectedStopover({} as StopoverType);
  }

  return (
    <>
      <DefaultLayout
        title="Operações"
        tabs={tabs}
        filterField="status"
        setFilter={setFilter}
        stopoveresData={stopoveresData}
        filteredStopoveres={filteredStopoveres}
        setFilteredStopoveres={setFilteredStopoveres}
        isLoadingStopoveresData={isLoadingStopoveresData}
        queryPage={queryPage}
        onChangePagination={onChangePagination}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        cardRenderer={getCardRenderer}
        labelPlural="OPERAÇÃO(ES)"
        pageContext="OPERATOR_AREA"
      >
        <OperatorAreaDetails
          name="stopover_operation_form"
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
          blockNavigate={blockNavigate}
          setBlockNavigate={setBlockNavigate}
          hasEdited={hasEdited}
          setHasEdited={setHasEdited}
          setIsVisibleEditOperationDrawer={setisVisibleAddOperationDrawer}
        />
      </DefaultLayout>
      <OperatorAreaDrawer
        isVisible={isVisibleAddOperationDrawer}
        setIsVisible={setisVisibleAddOperationDrawer}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        hasEdited={hasEdited}
        setHasEdited={setHasEdited}
        onCloseDrawer={onCloseDrawer}
        isEditMode={!isEmpty(selectedStopover)}
      />
    </>
  );
}
