import { ColumnsType } from 'antd/lib/table';

import { formatNumberToLocale } from '../../../../../utils/utils';

export function columns(
  isContainers?: boolean
): ColumnsType<Record<string, any>> {
  const items: ColumnsType<Record<string, any>> = [];
  items.push({
    title: 'NCM',
    key: 'ncm',
    width: '70%',
    render(_value: any, record) {
      return record.cargo
        ? record.cargo.name
        : `${record.ncm_code ? record.ncm_code : 'Código não informado'} - ${
            record.ncm_description
              ? record.ncm_description
              : 'Descrição não informada'
          }`;
    },
  });
  if (!isContainers) {
    items.push(
      {
        title: 'Unidades',
        key: 'amount',
        dataIndex: 'amount',
        width: '10%',
        align: 'right' as const,
      },
      {
        title: 'Peso total',
        key: 'total_weight',
        dataIndex: 'weight',
        width: '20%',
        align: 'right' as const,
        render(value) {
          return <>{formatNumberToLocale(value)} ton</>;
        },
      }
    );
  }
  return items;
}
