import { WarningFilled } from '@ant-design/icons';
import { Row, Space, Col, notification } from 'antd';
import { useState } from 'react';

import { Button, Input, Modal } from '..';

type ModalUpdateStopoverStatusProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  vesselName: string;
  stopoverId: number | undefined;
  statusToChange: string;
  cancelStopoverConfirm: (id: number, motivation: string) => void;
  reactivateStopoverConfirm: (id: number, motivation: string) => void;
  title: 'estadia' | 'programação da escala';
};

export function ModalUpdateStopoverStatus(
  props: ModalUpdateStopoverStatusProps
) {
  const {
    title,
    showModal,
    setShowModal,
    vesselName,
    stopoverId,
    statusToChange,
    cancelStopoverConfirm,
    reactivateStopoverConfirm,
  } = props;
  const [motivation, setMotivation] = useState('');
  const isCancelation = statusToChange === 'CANCELED' && vesselName !== null;

  function modalMessage() {
    if (isCancelation)
      return `Tem certeza de que deseja cancelar a ${title} de ${vesselName}?`;

    return `Tem certeza de que deseja reativar a ${title} de ${vesselName}?`;
  }

  function buttonText() {
    if (isCancelation) return `Cancelar ${title}`;

    return `Reativar ${title}`;
  }

  function handleUpdateStatusStopover() {
    if (!motivation) {
      notification.warning({
        message: 'Informe o Motivo',
        placement: 'top',
        duration: 3,
      });
      return;
    }
    if (stopoverId) {
      if (isCancelation) {
        cancelStopoverConfirm(stopoverId, motivation);
      } else {
        reactivateStopoverConfirm(stopoverId, motivation);
      }
    }
    handleCloseModal();
  }

  function handleCloseModal() {
    setMotivation('');
    setShowModal(false);
  }

  return (
    <Modal
      visible={showModal}
      className="TOSWarningModal"
      width={520}
      title={
        <>
          <WarningFilled /> Atenção
        </>
      }
      onCancel={() => setShowModal(false)}
      footer={null}
    >
      <>
        <p>{modalMessage()}</p>
        <Row gutter={24} align="bottom">
          <Col span={24}>
            <Input.TextArea
              value={motivation}
              onChange={(e) => setMotivation(e.target.value)}
              maxLength={200}
              placeholder={
                isCancelation
                  ? 'Motivo do cancelamento'
                  : 'Motivo da reativação'
              }
            />
          </Col>
        </Row>
        <br />
        <Row justify="end">
          <Space size={12}>
            <Button onClick={() => handleCloseModal()}>Voltar</Button>
            <Button danger onClick={() => handleUpdateStatusStopover()}>
              {buttonText()}
            </Button>
          </Space>
        </Row>
      </>
    </Modal>
  );
}
