import { Col, message, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { useCreateVesselRequestUpdateMutation } from '../../services/vesselRequestUpdateApi';
import { Button } from '../antd/Button';
import { Modal } from '../antd/Modal';

type VesselRequestUpdateModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  vesselId: number;
};

export function VesselRequestUpdateModal(props: VesselRequestUpdateModalProps) {
  const { showModal, setShowModal, vesselId } = props;
  const { company: companyUser } = useAppSelector(
    (state: RootState) => state.user.user
  );

  const [hasEdited, setHasEdited] = useState(false);
  const [motive, setMotive] = useState('');

  const [createVesselRequestUpdate] = useCreateVesselRequestUpdateMutation();

  function handleCloseModal() {
    if (setHasEdited) setHasEdited(false);
    setMotive('');
    setShowModal(false);
  }

  async function handleFormSubmit() {
    const response = await createVesselRequestUpdate({
      company_agency: companyUser?.id || 0,
      vessel: vesselId,
      motive,
      is_confirmed: false,
    });
    if ('data' in response) {
      message.success(
        'Solicitação enviada para SUAPE, favor aguardar aprovação'
      );
      setHasEdited(false);
      handleCloseModal();
    }
  }
  return (
    <Modal
      visible={showModal}
      title="Solicitar alteração de dados"
      className="TOSPrimaryModal"
      onCancel={handleCloseModal}
      width={600}
      footer={null}
    >
      <Row gutter={24}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 15px',
          }}
        >
          <p>
            Ao clicar em &quot;Solicitar&quot;, o porto de Suape receberá uma
            notificação para aprovar sua solicitação.
          </p>
          <p>
            Uma vez aprovada, os dados da embarcação serão sincronizados com o
            Marine Traffic, portanto certifique-se de que os dados estão
            atualizados no Marine Traffic antes de fazer a solicitação.
          </p>
          <span>Preencha o motivo da solicitação abaixo:</span>
        </div>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <TextArea
            maxLength={500}
            rows={2}
            showCount
            required
            value={motive}
            onChange={(e) => setMotive(e.target.value)}
          />
        </Col>
      </Row>

      <Row justify="end">
        <Space size={12}>
          <Button type="text" onClick={() => handleCloseModal()}>
            Cancelar
          </Button>
          <Button type="primary" disabled={!motive} onClick={handleFormSubmit}>
            Solicitar
          </Button>
        </Space>
      </Row>
    </Modal>
  );
}
