import styled from 'styled-components';

import { Button } from '../../../../../components';

export const DrawerTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;

  .consignee-name {
    font-size: 16px;
    font-weight: 700;
    color: var(--suape-black);
  }

  .consignee-cnpj {
    font-size: 14px;
    font-weight: 400;
    color: var(--neutral_medium);
  }
`;

export const ButtonOperatorCompany = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  padding-top: 10px;
  transition: 0.3s;

  &:hover {
    background-color: var(--neutral_lightest);
  }

  .cemerchant-code {
    font-weight: 600;
    font-size: 16px;
    color: var(--marine_dark);
  }

  .storages-by-amount {
    font-weight: 400;
    font-size: 14px;
    color: var(--neutral_light);
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;

  .consignee-name {
    font-size: 12px;
    font-weight: 600;
    color: var(--neutral_medium);
    margin-right: 8px;
  }

  .consignee-cnpj {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral_medium);
  }

  .ceMerchant-title {
    font-size: 16px;
    font-weight: 700;
    color: var(--suape-black);
  }
`;
