import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Button,
  Collapse,
  InnerCollapse,
  Table,
} from '../../../../../components';
import { useLazyGetLinesQuery } from '../../../../../services/linesApi';
import { DockingPlace, DockingPlaceForm, Line } from '../../../../../types';
import { BerthLineDrawer } from '../berthLineForm/berthLineDrawer';
import { formatLineToForm, formatLinesToForm } from '../formatters';
import { columns } from './columns';
import { ExpandedRowLine } from './expandedRowLine/expandedRowLine';

const { Panel } = Collapse;

type FormItemsBerthLinesProps = {
  selectedBerth: DockingPlace;
  form: FormInstance<DockingPlaceForm>;
  isEdit: boolean;
};

export function FormItemsBerthLines(props: FormItemsBerthLinesProps) {
  const { selectedBerth, form, isEdit } = props;
  const [selectedLine, setSelectedLine] = useState({});
  const [isLineFormVisible, setIsLineFormVisible] = useState(false);
  const [berthLineRowIndex, setBerthLineRowIndex] = useState(0);

  const [
    getLinesByBerth,
    { data: berthLinesData, isLoading: isLoadingBerthLinesData },
  ] = useLazyGetLinesQuery();

  useEffect(() => {
    if (isEdit && selectedBerth.tag) {
      getLinesByBerth({ berth: selectedBerth.tag }).then((berthLines) => {
        if (!isEmpty(berthLines.data) && berthLines.data) {
          form.setFieldsValue({
            berth_lines: formatLinesToForm(berthLines.data),
          });
        }
      });
    }
  }, [selectedBerth]);

  function onSelectLine(selectedLine: any, index: number) {
    setBerthLineRowIndex(index);
    setSelectedLine(formatLineToForm({ ...selectedLine }));
    setIsLineFormVisible(true);
  }

  function onCloseForm() {
    setIsLineFormVisible(false);
    setSelectedLine({});
  }

  return (
    <InnerCollapse expandIconPosition="end">
      <Panel key="operational_infrastructure_lines" header="Linhas">
        <FormList name="berth_lines">
          {(berthLines, { add: addBerthLine, remove: removeBerthLine }) => {
            return (
              <>
                <BerthLineDrawer
                  selectedBerth={selectedBerth}
                  selectedLine={selectedLine}
                  berthLineRowIndex={berthLineRowIndex}
                  visibleDrawer={isLineFormVisible}
                  onBack={onCloseForm}
                  form={form}
                  isEdit={isEdit}
                  addBerthLineToForm={addBerthLine}
                  removeBerthLineFromForm={removeBerthLine}
                />
                <div style={{ padding: '12px' }}>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    size="large"
                    onClick={() => setIsLineFormVisible(true)}
                    style={{ marginBottom: '12px' }}
                  >
                    Nova Linha
                  </Button>
                  <Table
                    rowKey="name"
                    columns={columns(onSelectLine)}
                    dataSource={
                      (berthLinesData && berthLinesData) ||
                      form.getFieldValue(['berth_lines'])
                    }
                    expandable={{
                      expandedRowClassName: () => 'berth-line-row-expanded',
                      expandedRowRender: (record: any) => (
                        <ExpandedRowLine record={record} />
                      ),
                      expandIcon: ({ expanded, onExpand, record }) => {
                        return expanded ? (
                          <Button
                            icon={
                              <UpOutlined
                                style={{ color: 'var(--green-sea_dark)' }}
                              />
                            }
                            type="text"
                            onClick={(e) => onExpand(record, e)}
                          />
                        ) : (
                          <Button
                            icon={
                              <DownOutlined
                                style={{ color: 'var(--green-sea_dark)' }}
                              />
                            }
                            type="text"
                            onClick={(e) => onExpand(record, e)}
                          />
                        );
                      },
                      expandRowByClick: true,
                    }}
                  />
                </div>
              </>
            );
          }}
        </FormList>
      </Panel>
    </InnerCollapse>
  );
}
