import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { Collapse, Tag } from '../../../../../components';
import { OperationProductHeader } from '../../../../../components/common/cargoOperationHeader/styles';
import {
  OperationCalculusType,
  OperationContextType,
  OperationProductCalculusType,
  OperationProductType,
  OperationProductTypeDescription,
} from '../../../../../types';
import { operationDirectionList } from '../../../../../utils/lists';
import { formatOperationProductToDescription } from '../../../../operation/OperationsData/formatOperationToDescription';
import { MovementDescriptionSection } from './movementDescriptionSection/MovementDescriptionSection';
import { ProductResumeDescriptionSection } from './productResumeDescriptionSection';

const { Panel } = Collapse;

type ProductDataDescriptionSectionProps = {
  product: OperationProductType;
  operationType: OperationContextType;
  currentIndex: number;
  operationProductCalculusData: OperationProductCalculusType;
  operationCalculusData: OperationCalculusType;
};

export function ProductDataDescriptionSection(
  props: ProductDataDescriptionSectionProps
) {
  const {
    product,
    operationType,
    currentIndex,
    operationProductCalculusData,
    operationCalculusData,
  } = props;
  const [openCollapsePanels, setOpenCollapsePanels] = useState<
    string | string[]
  >(['products']);

  const isLiquidBulk = operationType === 'LIQUID_BULK';

  const [productDescription, setProductDescription] =
    useState<OperationProductTypeDescription>(Object);

  useEffect(() => {
    if (product && operationType) {
      setProductDescription(
        formatOperationProductToDescription(product, operationType)
      );
    }
  }, [product]);

  function getHeader(cargoType: string, productGroup: string) {
    return (
      <OperationProductHeader>
        <span className="cargo-type">{cargoType}</span>
        <span className="product-group">{productGroup}</span>
      </OperationProductHeader>
    );
  }
  return (
    <Collapse
      activeKey={openCollapsePanels}
      key={`collapse_${currentIndex}`}
      onChange={(panel) => setOpenCollapsePanels(panel)}
      expandIconPosition="end"
    >
      <Panel
        forceRender
        header={getHeader(
          productDescription.cargo_type,
          productDescription.product_group
        )}
        key={`product_${currentIndex}`}
      >
        <ProductResumeDescriptionSection
          product={productDescription}
          isLiquidBulk={isLiquidBulk}
          operationProductCalculusData={operationProductCalculusData}
          operationCalculusData={operationCalculusData}
        />
        <MovementDescriptionSection operationProduct={product} />
        {/* {productDescription.work_periods &&
          productDescription.work_periods.length > 0 && (
            <PeriodsDescriptionSection
              workPeriods={productDescription.work_periods}
            />
          )} */}
      </Panel>
    </Collapse>
  );
}
