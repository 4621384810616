import { PlusOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useState } from 'react';

import { Button } from '../../../components';
import { useChangePagination } from '../../../hooks';
import { useGetBerthsQuery } from '../../../services/dockingPlacesApi';
import { DockingPlace } from '../../../types';
import { PanelHeader } from '../panelHeader';
import { RegistersDrawer } from '../registersDrawer';
import { RegistersTable } from '../registersTable';
import { BerthForm } from './berthForm';
import { columns } from './columns';

export function Berth() {
  const [selectedBerth, setSelectedBerth] = useState({} as DockingPlace);
  const [searchQueryTerm, setSearchQueryTerm] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();
  const { data: berthData, isLoading: isLoadingBerthData } = useGetBerthsQuery({
    placeType: 'BERTH',
    berthName: searchQueryTerm,
    page_size: 10,
    page: onChangePagination(),
  });

  function actionButtons() {
    return (
      <Button
        icon={<PlusOutlined />}
        type="primary"
        size="large"
        onClick={() => setIsFormVisible(true)}
      >
        Novo berço
      </Button>
    );
  }

  function onSelectBerth(berth: DockingPlace) {
    setSelectedBerth({ ...berth });
    setIsEdit(true);
    setIsFormVisible(true);
  }

  function onCloseForm() {
    setIsFormVisible(false);
    setSelectedBerth({} as DockingPlace);
    setIsEdit(false);
  }

  function searchData(value: any) {
    setQueryPage(1);
    setSearchQueryTerm(value);
  }

  return (
    <>
      <RegistersDrawer visible={isFormVisible} width={840}>
        <BerthForm
          onClose={onCloseForm}
          isEdit={isEdit}
          selectedBerth={selectedBerth}
        />
      </RegistersDrawer>
      <Spin spinning={isLoadingBerthData} tip="Carregando...">
        <PanelHeader
          panelName="berços"
          onSearch={searchData}
          actionButtons={actionButtons()}
        />
        <RegistersTable
          rowKey="id"
          columns={columns}
          onSelectItem={onSelectBerth}
          dataSource={berthData?.results || []}
          onChangePagination={onChangePagination}
          total={berthData?.count}
          isLoading={isLoadingBerthData}
          queryPage={queryPage}
          showSizeChanger={false}
        />
      </Spin>
    </>
  );
}
