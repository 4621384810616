import { Select } from '../../../components';
import { isNullOrUndefined } from '../../../utils/utils';

export function dockingOptionRenderer(option: Record<string, any>) {
  return (
    <Select.Option key={option.id} value={option.id}>
      {`${option.index + 1} - `}
      {isNullOrUndefined(option.code)
        ? 'Código não gerado - '
        : `${option.code} - `}
      {!isNullOrUndefined(option.docking_place) &&
        `${option.docking_place?.name}`}
    </Select.Option>
  );
}

export function shipownerOptionRenderer(option: Record<string, any>) {
  return (
    <Select.Option key={option.id} value={option.id}>
      {`${option.cnpj} - ${option.name}`}
    </Select.Option>
  );
}
