import { EditFilled } from '@ant-design/icons';
import moment from 'moment';

import { Button } from '..';

export function panelHeaderTitle(
  updatedAt: string | undefined,
  onClickEdit?: (evt: any, indexToEdit: number) => void,
  index?: number
) {
  const date = moment(updatedAt).format('DD/MM/YYYY HH:mm');
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontWeight: '300', fontSize: '12px' }}>
        {updatedAt && `Atualizado em: ${date}`}
      </span>
      {onClickEdit ? (
        <Button
          style={{
            fontSize: '12px',
            marginInlineEnd: '12px',
            paddingRight: '5px',
            paddingLeft: '5px',
          }}
          icon={<EditFilled />}
          htmlType="button"
          type="text"
          onClick={(e) => onClickEdit && onClickEdit(e, index || 0)}
        >
          Editar
        </Button>
      ) : (
        <div style={{ marginLeft: '17px' }} />
      )}
    </div>
  );
}
