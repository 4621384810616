import { ReactNode } from 'react';

import { Drawer } from '../../components/antd/Drawer';

type RegistersDrawerProps = {
  visible?: boolean;
  children?: ReactNode;
  width?: number;
};

export function RegistersDrawer(props: RegistersDrawerProps) {
  return (
    <Drawer
      visible={props.visible}
      width={props.width}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      {props.children}
    </Drawer>
  );
}
