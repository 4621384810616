import { useState } from 'react';

export function useChangePagination() {
  const [queryPage, setQueryPage] = useState(1);

  function onChangePagination(newPage?: number) {
    // console.log(newPage);
    if (newPage) {
      setQueryPage(newPage);
    }
    return queryPage;
  }

  return { onChangePagination, queryPage, setQueryPage };
}
