import { Col, Row } from 'antd';

import { StatusButton } from './statusButton';

export function StatusButtonsCol(props: any) {
  const { itemsToRender } = props;
  return (
    <Col span={12}>
      <Row align="middle">
        <Col span={6}>
          <StatusButton status="moored">
            Atracadas (
            {
              itemsToRender.filter(
                (item: any) => item.docking_window_status === 'MOORED'
              ).length
            }
            )
          </StatusButton>
          <StatusButton status="expected">
            Previstas (
            {
              itemsToRender.filter(
                (item: any) => item.docking_window_status === 'EXPECTED'
              ).length
            }
            )
          </StatusButton>
        </Col>
        <Col span={8}>
          <StatusButton status="unmoored">
            Desatracadas (
            {
              itemsToRender.filter(
                (item: any) => item.docking_window_status === 'UNMOORED'
              ).length
            }
            )
          </StatusButton>
          <StatusButton status="carried-out">
            Estadias Finalizadas (
            {
              itemsToRender.filter(
                (item: any) => item.docking_window_status === 'CARRIED_OUT'
              ).length
            }
            )
          </StatusButton>
        </Col>
        <Col span={10}>
          <StatusButton status="dalayed-unberthing">
            Desatracações Atrasadas (
            {
              itemsToRender.filter(
                (item: any) =>
                  item.docking_window_status === 'DELAYED_UNBERTHING'
              ).length
            }
            )
          </StatusButton>
          <StatusButton status="delayed">
            Atracações Atrasadas (
            {
              itemsToRender.filter(
                (item: any) => item.docking_window_status === 'DELAYED'
              ).length
            }
            )
          </StatusButton>
        </Col>
      </Row>
    </Col>
  );
}
