import { ArrowLeftOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';

import { Button, Drawer } from '../../../../components';
import { StopoverGroupByOperatorType } from '../../../../types';
import { OperationReportDrawerHeader } from '../../styles';
import { GeneralCargo } from './generalCargo/GeneralCargo';

type OperationReportDrawerProps = {
  isVisible: boolean;
  onClose: () => void;
  stopover: StopoverGroupByOperatorType;
  isLoading?: boolean;
};

export function OperationReportDrawer(props: OperationReportDrawerProps) {
  const { isVisible, onClose, stopover, isLoading } = props;

  return (
    <Drawer
      visible={isVisible}
      width={1250}
      closeIcon={<Button type="text">Voltar</Button>}
      onClose={onClose}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <OperationReportDrawerHeader>
        <Button
          type="text"
          className="button-back"
          onClick={onClose}
          icon={
            <ArrowLeftOutlined
              style={{ color: 'var(--green-sea_dark)', fontSize: '20px' }}
            />
          }
        />
        {isLoading ? (
          <Skeleton active />
        ) : (
          <div className="title">
            <span>{stopover.operator}</span>
            <span className="subtitle">
              {stopover.docking_code ? `#${stopover.docking_code}` : ''}
            </span>
          </div>
        )}
      </OperationReportDrawerHeader>
      <GeneralCargo stopover={stopover} />
    </Drawer>
  );
}
