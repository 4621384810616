import { BreakdownFiltersType } from '../features/reports/breakdownsSection/BreakdownsSection';
import { formatEquipments } from '../features/stayment/breakdownOccurrenceDrawer/formatEquipments';
import {
  GenericQueryParamsType,
  GenericResponse,
  BreakdownOccurrenceType,
  BreakdownOccurrenceToSave,
} from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type BreakdownOccurrenceResponse = GenericResponse & {
  results: Array<BreakdownOccurrenceType> | [];
};

export type BreakdownOccurrenceGenericQueryParamsType = GenericQueryParamsType &
  BreakdownFiltersType & {
    stopover_id?: number;
  };

export const breakdownOccurrenceApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getBreakdownsOccurrences: build.query<
      BreakdownOccurrenceResponse,
      BreakdownOccurrenceGenericQueryParamsType | void
    >({
      query: (params: BreakdownOccurrenceGenericQueryParamsType) => {
        return {
          url: `/maritime/breakdowns`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: BreakdownOccurrenceResponse) => {
        response.results.forEach((result) => {
          result.equipments = formatEquipments(
            result.fenders || [],
            result.bollards || []
          );
        });
        response.results.sort((a, b) => (a.id && b.id ? a.id - b.id : -1)); // order by asc, change to back default order
        return response;
      },
      providesTags: () => [{ type: 'BreakdownOccurrence' }],
    }),
    createBreakdownOccurrence: build.mutation<
      BreakdownOccurrenceType,
      BreakdownOccurrenceToSave
    >({
      query: (breakdown) => ({
        url: `/maritime/breakdowns`,
        method: 'POST',
        body: breakdown,
      }),
      transformResponse: (response: BreakdownOccurrenceType) => {
        response.equipments = formatEquipments(
          response.fenders || [],
          response.bollards || []
        );
        return response;
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'BreakdownOccurrence' }]),
    }),
    updateBreakdownOccurrence: build.mutation<
      BreakdownOccurrenceType,
      BreakdownOccurrenceToSave
    >({
      query: (breakdown) => ({
        url: `/maritime/breakdowns/${breakdown.id}`,
        method: 'PUT',
        body: breakdown,
      }),
      transformResponse: (response: BreakdownOccurrenceType) => {
        response.equipments = formatEquipments(
          response.fenders || [],
          response.bollards || []
        );
        return response;
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'BreakdownOccurrence' }]),
    }),
    deleteBreakdownOccurrence: build.mutation<void, number>({
      query: (breakdownId) => ({
        url: `/maritime/breakdowns/${breakdownId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'BreakdownOccurrence' }]),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetBreakdownsOccurrencesQuery,
  useCreateBreakdownOccurrenceMutation,
  useUpdateBreakdownOccurrenceMutation,
  useDeleteBreakdownOccurrenceMutation,
} = breakdownOccurrenceApi;
