import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';

import { Collapse } from '../../components';
import { FinancialOfficersTable } from '../../components/common/financialOfficersTable/financialOfficersTable';
import { useGetSystemParamByNameQuery } from '../../services/systemParamsApi';
import { Docking, FinancialTableTypeType, Stopover } from '../../types';

const { Panel } = Collapse;
type FormItemsFinancialOfficersProps = {
  form?: FormInstance;
  docking?: Docking;
  selectedStopover?: Stopover;
  openCollapsePanels: string | string[];
  onOpenPanel?: (panel: string | string[]) => void;
};
export function FormItemsFinancialOfficers(
  props: FormItemsFinancialOfficersProps
) {
  const { form, docking, selectedStopover, onOpenPanel, openCollapsePanels } =
    props;

  const [isCreatingDocking, setIsCreatingDocking] = useState(true);

  const [tableType1, setTableType1] = useState(Object);
  const [tableType2, setTableType2] = useState(Object);
  const [tableType3, setTableType3] = useState(Object);

  const { data: tableTypes } = useGetSystemParamByNameQuery({
    name: 'FINANCIAL_TABLE',
  });

  useEffect(() => {
    if (tableTypes && tableTypes?.items) {
      setTableType1(
        tableTypes.items.find(
          (item) => item.code === 'USE_OF_MARINE_INFRASTRUCTURE'
        ) || ({} as FinancialTableTypeType)
      );
      setTableType2(
        tableTypes.items.find(
          (item) => item.code === 'OCCUPATION_OF_DOCKING_FACILITIES'
        ) || ({} as FinancialTableTypeType)
      );
      setTableType3(
        tableTypes.items.find(
          (item) => item.code === 'USE_OF_LAND_INFRASTRUCTURE'
        ) || ({} as FinancialTableTypeType)
      );
    }
  }, [tableTypes]);

  function tableTitle(tableType: number) {
    if (tableType === 16) {
      return `tabela 1`;
    }
    if (tableType === 17) {
      return `tabela 2`;
    }
    if (tableType === 18) {
      return `tabela 3`;
    }
    return '';
  }

  useEffect(() => {
    if (docking && docking?.financial_tables?.length > 0) {
      setIsCreatingDocking(false);
    } else {
      form?.setFieldsValue({
        financial_tables: [
          // {
          //   items: [] as FinancialTableItemType[],
          //   financial_table_type: tableType1,
          // } as FinancialTableType,
          // {
          //   items: [] as FinancialTableItemType[],
          //   financial_table_type: tableType2,
          // } as FinancialTableType,
          // {
          //   items: [] as FinancialTableItemType[],
          //   financial_table_type: tableType3,
          // } as FinancialTableType,
        ],
      });
      setIsCreatingDocking(true);
    }
  }, [docking, tableType1, tableType2, tableType3]);

  return (
    <Collapse
      expandIconPosition="end"
      activeKey={openCollapsePanels}
      onChange={onOpenPanel}
    >
      <Panel
        forceRender
        header="Responsáveis financeiros"
        key="financialOfficers"
      >
        <FinancialOfficersTable docking={docking} />
      </Panel>
    </Collapse>
  );
}
