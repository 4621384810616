import { useState } from 'react';

import { Drawer } from '../../../../../components';
import { Stopover as StopoverType } from '../../../../../types';
import { VesselStopoverEditForm } from './vesselStopoverEditForm';

type VesselStopoverEditDrawerProps = {
  visible: boolean;
  width: number;
  onBack: () => void;
  selectedStopover?: StopoverType;
  setSelectedStopover?: (value: StopoverType) => void;
  setIsVisibleForm: (value: boolean) => void;
};

export function VesselStopoverEditDrawer(props: VesselStopoverEditDrawerProps) {
  const {
    visible,
    width,
    onBack,
    selectedStopover,
    setSelectedStopover,
    setIsVisibleForm,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  return (
    <Drawer
      visible={visible}
      width={width}
      onClose={() => onBack()}
      closable={false}
      maskClosable={false}
    >
      <VesselStopoverEditForm
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        onFinishSubmit={onBack}
        onBack={onBack}
        setIsVisibleForm={setIsVisibleForm}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
        visible={visible}
      />
    </Drawer>
  );
}
