import styled from 'styled-components';

export const ConsigneeListItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed var(--neutral_lighter);
  padding-bottom: 10px;
  padding-top: 16px;
  .name {
    color: var(--suape-black);
    margin-right: 8px;
    font-weight: 600;
    font-size: 12px;
  }

  .code {
    font-size: 12px;
    color: var(--neutral_medium);
  }

  .items {
    font-size: 12px;
    color: var(--neutral_light);
  }
`;

export const ConsigneeDrawerHeader = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  & .company-name {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    &.truncate {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100px;
    }
  }
  & .company-code {
    font-size: 14px;
    color: var(--neutral_medium);
    margin-right: 16px;
  }
  & button {
    & .ant-btn-text {
      color: black !important;
    }
  }
`;

export const MerchantCEListItemHeader = styled.div`
  border-top: solid 1px var(--neutral_lighter);
  padding: 16px;
  margin-bottom: 18px;

  & .ant-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  & .title {
    font-size: 14px;
    margin-bottom: 0px;
  }

  & .ant-table-wrapper {
    margin-top: 16px;
  }
`;
