import { useEffect, useState } from 'react';

import { LightTable } from '../../../../../components';
import {
  OperationProductTypeDescription,
  StorageSumaryItemType,
} from '../../../../../types';
import { liquidBulkStorageSumaryTableColumns } from './liquidBulkStorageSummatyTableColumns';

type LiquidBulkStorageSummaryTableProps = {
  product: OperationProductTypeDescription;
};
export function LiquidBulkStorageSummaryTable(
  props: LiquidBulkStorageSummaryTableProps
) {
  const { product } = props;
  const [
    operationProductStorageSummaryData,
    setOperationProductStorageSummaryData,
  ] = useState<StorageSumaryItemType[]>([]);
  useEffect(() => {
    if (product && product.storage_summary) {
      setOperationProductStorageSummaryData(
        Object.keys(product.storage_summary).map(
          (storageSummaryId: string, index: number) => {
            return {
              ...product.storage_summary[parseInt(storageSummaryId, 10)],
              key: index,
            };
          }
        )
      );
    }
  }, [product]);
  return (
    <LightTable
      columns={liquidBulkStorageSumaryTableColumns()}
      dataSource={operationProductStorageSummaryData}
      className="read-only"
      pagination={false}
    />
  );
}
