import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import { useState } from 'react';

import {
  Alert,
  Button,
  Drawer,
  Form,
  FormItemInput,
  GenericDrawerHeader,
  Modal,
  TableFormList,
} from '../../components';
import { FormListItemRowStyle } from '../../components/common/tableFormList/styles';
import { useCreateInvoiceMutation } from '../../services/warehousingVehiclesApi';
import { VehicleInvoice } from '../../typesWarehousing';

type ReadingInvoicesDrawerProps = {
  visible?: boolean;
  setIsVisible: (visible: boolean) => void;
  width?: number;
};

const { confirm } = Modal;

export function ReadingInvoicesDrawer(props: ReadingInvoicesDrawerProps) {
  const { visible, setIsVisible, width } = props;
  const [form] = Form.useForm();

  const [ReadInvoicesAmount, setReadInvoicesAmount] = useState(0);
  const [createdKeys, setCreatedKeys] = useState(new Set<string>());

  function InvoiceRow(props: {
    index: number;
    onRemove?: (index: number | number[]) => void;
    onAdd?: (
      defaultValue?: { access_key: string; date_of_access_key_read: string },
      insertIndex?: number | undefined
    ) => void;
  }) {
    const { index, onRemove, onAdd } = props;
    const [createInvoice] = useCreateInvoiceMutation();

    function focusRow(row: number) {
      window.setTimeout(() => {
        const nextSibling = document.querySelector<HTMLInputElement>(
          `input[id=invoices_${row}_access_key]`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }, 200);
    }

    function removeCreatedRow() {
      const currentInvoices = [...form.getFieldValue('invoices')];
      const removedAccessKey = currentInvoices[index].access_key;
      setCreatedKeys((prevKeys) => {
        const newKeys = new Set<string>(prevKeys);
        newKeys.delete(removedAccessKey);
        return newKeys;
      });
    }
    async function duplicateInvoiceKey(value: string) {
      if (value.length === 44 && value !== '') {
        if (onAdd) {
          const currentInvoices = [...form.getFieldValue('invoices')];
          currentInvoices[index] = {
            ...currentInvoices[index],
            date_of_access_key_read: moment().format('YYYY-MM-DD HH:mm'),
          };
          const invoiceToCreate: VehicleInvoice = currentInvoices[index];

          if (!createdKeys.has(invoiceToCreate.access_key)) {
            setCreatedKeys(
              new Set<string>(createdKeys).add(invoiceToCreate.access_key)
            );
            const createdInvoice = await createInvoice(invoiceToCreate);
            if ('data' in createdInvoice) {
              form.setFieldsValue({
                invoices: currentInvoices,
              });
              onAdd({ access_key: '', date_of_access_key_read: '' });
              setReadInvoicesAmount(
                currentInvoices.filter(
                  (invoice: any) => invoice.date_of_access_key_read !== ''
                ).length
              );
              focusRow(index + 1);

              return Promise.resolve();
            }
            return Promise.reject(new Error('Falha ao criar a nota'));
          }
          return Promise.reject(
            new Error('Nota já pistolada, a duplicidade será desconsiderada ')
          );
        }
      }
      return Promise.reject(new Error('A nota deve possuir 44 caracteres'));
    }

    focusRow(index);

    return (
      <FormListItemRowStyle gutter={8}>
        <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{ textAlign: 'center', width: '100%', marginLeft: '-5px' }}
          >
            {index + 1}
          </div>
        </Col>
        <Col span={14}>
          <FormItemInput
            maxLength={44}
            name={[index, 'access_key']}
            style={{ marginBottom: '0px' }}
            className="code_input"
            rules={[
              {
                validator: (_, value) => duplicateInvoiceKey(value),
                max: 44,
              },
            ]}
          />
        </Col>
        <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ paddingLeft: '5px' }}>
            {form.getFieldValue('invoices')[index].date_of_access_key_read !==
            ''
              ? moment(
                  form.getFieldValue('invoices')[index].date_of_access_key_read
                ).format('DD/MM/yyyy HH:mm')
              : ''}
          </div>
        </Col>
        <Col
          span={2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {form.getFieldValue('invoices')[index].date_of_access_key_read !==
            '' && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                if (form.getFieldValue('invoices')[index].access_key !== '') {
                  confirm({
                    title: 'Tem certeza que deseja apagar essa chave de nota?',
                    content: 'Não será possível recuperar a nota apagada',
                    onOk() {
                      if (onRemove) {
                        onRemove(index);
                        removeCreatedRow();
                        setReadInvoicesAmount(ReadInvoicesAmount - 1);
                      }
                    },
                    okText: 'Sim',
                    okButtonProps: { danger: true },
                    cancelText: 'Não',
                  });
                } else if (onRemove) {
                  onRemove(index);
                }
              }}
            />
          )}
        </Col>
      </FormListItemRowStyle>
    );
  }
  return (
    <Drawer
      visible={visible}
      width={width}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <GenericDrawerHeader
        title={<b>Nova leitura</b>}
        showBackButton
        onBack={() => setIsVisible(false)}
        saveButton={
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => setIsVisible(false)}
          >
            Concluir
          </Button>
        }
      />
      <Content style={{ margin: '24px 16px 0' }}>
        <Alert
          showIcon
          message={
            <div>
              Caso alguma informação esteja incorreta, é possível edita-la
              clicando em cima. <br /> O sistema salva automaticamente sempre
              que uma nota é lida.
            </div>
          }
        />
        <Form
          form={form}
          initialValues={{
            invoices: [
              {
                access_key: '',
                date_of_access_key_read: '',
              },
            ],
          }}
        >
          <div style={{ marginTop: '15px' }}>
            {ReadInvoicesAmount} notas lidas
          </div>
          <TableFormList
            FormListItemRow={InvoiceRow}
            formListName="invoices"
            columns={[
              { title: 'Nº', colSpan: 2, style: { textAlign: 'center' } },
              { title: 'CHAVE DE NOTA', colSpan: 14 },
              { title: 'HORÁRIO DE LEITURA', colSpan: 6 },
              { title: '', colSpan: 2 },
            ]}
            style={{ marginTop: '20px' }}
          />
        </Form>
      </Content>
    </Drawer>
  );
}
