import { CheckboxOptionType, Col, Radio, RadioChangeEvent } from 'antd';
import { Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';

import { FormItem } from '../Form';

type FormItemRadioButtonProps = {
  label?: string;
  name?: NamePath;
  colSpan?: number;
  options?: (string | number | CheckboxOptionType)[];
  onChange?: (e: RadioChangeEvent) => void;
  required?: boolean;
  rules?: Rule[];
  isOptionTypeDefault?: boolean;
};
export function FormItemRadioGroup(props: FormItemRadioButtonProps) {
  const {
    label,
    name,
    colSpan,
    options,
    onChange,
    required,
    rules,
    isOptionTypeDefault,
  } = props;
  return (
    <Col span={colSpan || 24}>
      <FormItem
        name={name}
        label={label}
        rules={rules || [{ required, message: 'Campo obrigatório' }]}
      >
        <Radio.Group
          options={options}
          optionType={isOptionTypeDefault ? 'default' : 'button'}
          buttonStyle={isOptionTypeDefault ? 'outline' : 'solid'}
          onChange={(e) => onChange && onChange(e)}
        />
      </FormItem>
    </Col>
  );
}
