import { message } from 'antd';
import { Rule } from 'antd/lib/form';
import moment from 'moment';

import { Docking, DockingPlace, FinancialTableType, ShipType } from '../types';
import { isNullOrUndefined } from './utils';

export const askForDwt = (vesselDwt?: number, berthDwt?: number): boolean => {
  if (vesselDwt === undefined || berthDwt === undefined) return true;

  return vesselDwt > berthDwt;
};

export function hasUnallowedCargo(
  allowedBerths: any[] | undefined,
  docking: Docking
) {
  const unallowedCargo: any[] = [];

  const berthSelected = allowedBerths?.find(
    (berth: DockingPlace) => berth.tag === docking.docking_place?.tag
  );

  if (berthSelected) {
    const berthCargo = berthSelected.cargo_type.flatMap(
      (cargo_type: any) => [cargo_type.name] || []
    );
    const dockingCargos = docking.products?.flatMap(
      (product: any) => [product.cargo_type] || []
    );

    dockingCargos?.forEach((dockingCargo: any) => {
      if (!berthCargo.includes(dockingCargo.name)) {
        unallowedCargo.push(dockingCargo);
      }

      return unallowedCargo;
    });
  }

  return unallowedCargo;
}

export function minAndMaxDateRules(
  minDate: string | moment.Moment | null,
  maxDate: string | moment.Moment | null,
  minLabel: string,
  maxLabel: string,
  isDatepicker?: boolean
) {
  const min = minDate && moment(minDate);
  const max = maxDate && moment(maxDate);
  const rules = [];

  let valueToValidate = null;

  if (min && min.isValid()) {
    rules.push({
      transform: (value: any) => {
        // É necessário pegar o valor do campo em milissegundos antes da validação
        valueToValidate = isDatepicker
          ? value
          : value && value[0]
          ? value[0]
          : null;
        if (valueToValidate) {
          // Verifica se tem valor preenchido para não da erro na validação
          return moment(valueToValidate).valueOf(); // Data em milissegundos
        }
        return min.valueOf(); // Caso não tenha valor preenchido retorna o valor mínimo para passar na validação
      },
      min: min.valueOf(),
      message: `A data inicial deve ser maior que a ${minLabel}`,
      type: 'number' as const, // Necessário informar o type quando for uma regra de min ou max
    });
  }

  if (max && max.isValid()) {
    rules.push({
      transform: (value: any) => {
        valueToValidate = isDatepicker
          ? value
          : value && value[1]
          ? value[1]
          : null;

        if (valueToValidate) {
          return moment(valueToValidate).valueOf();
        }
        return max.valueOf();
      },
      max: max.valueOf(),
      message: `A data final deve ser menor que a ${maxLabel}`,
      type: 'number' as const,
    });
  }
  return rules;
}

export const getAllowedBerth = (vessel: any, berthData: any) => {
  const berths: any = [];

  if (vessel === undefined) {
    return berths;
  }

  berthData?.results.forEach((item: any) => {
    const ship_types = item.ship_types.map((type: any) => type.name);

    if (ship_types.indexOf(vessel.ship_type?.name) !== -1) {
      berths.push(item);
    }

    if (item.place_type === 'ANCHORING') {
      berths.push(item);
    }
  });

  return berths;
};

export function isAllowedInBerth(
  berthShipTypes: Array<ShipType>,
  shipType: ShipType
): boolean {
  const hasShipType: ShipType | undefined = berthShipTypes.find(
    (berthShipType) => berthShipType.name === shipType.name
  );
  return !!hasShipType;
}

export function validateIMO(imo: string) {
  if (isNullOrUndefined(imo)) {
    return Promise.resolve();
  }
  // Precisa corrigir se o imo deixar de ser obrigatório
  let sum = 0;
  const factor = [7, 6, 5, 4, 3, 2];
  for (let i = 6; i >= 1; i--) {
    sum += parseInt(imo[i - 1], 10) * factor[i - 1];
  }
  if (imo.length !== 7) {
    return Promise.reject(new Error('IMO inválido'));
  }
  if (
    imo.substring(imo.length - 1) === `${sum}`.substring(`${sum}`.length - 1)
  ) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('IMO inválido'));
}

export const canEditStopover = (
  stopoverStatus: 'WAITING' | 'IN_PROGRESS' | 'DONE' | 'CANCELED' | undefined
): boolean | undefined => {
  if (stopoverStatus === undefined) return true;

  const notEditableStatus: string[] = ['CANCELED'];

  return !notEditableStatus.includes(stopoverStatus);
};

function hasUnallowedMaxDraught(stopover: any, allowedBerth: any) {
  const firstDockingBerthObj = allowedBerth.find(
    (berth: any) => berth.tag === stopover.dockings[0]?.docking_place
  );
  let hasUnallowed = false;

  if (
    firstDockingBerthObj &&
    firstDockingBerthObj.place_type === 'BERTH' &&
    stopover.arrival_draught
  ) {
    if (stopover.arrival_draught > firstDockingBerthObj.max_draught) {
      hasUnallowed = true;
    }
  }
  return {
    hasUnallowed,
    berth: firstDockingBerthObj,
  };
}

// validate unallowed draught on berth
export function validateHasUnallowedMaxDraught(
  stopover: any,
  allowedBerth: any
) {
  const checkMaxDraught = hasUnallowedMaxDraught(stopover, allowedBerth);
  if (checkMaxDraught.hasUnallowed) {
    message.error(
      `O calado de chegada inserido é maior do que o esperado para o berço ${checkMaxDraught.berth.name}(${checkMaxDraught.berth.max_draught}m). Por favor, revise as informações.`
    );
    return false;
  }
  return true;
}

export function getFinancialTableItems(
  financial_table: Array<FinancialTableType>,
  index: number
) {
  if (!isNullOrUndefined(financial_table)) {
    return financial_table;
  }
  return [];
}

export function validateStopover(values: any) {
  const minDate = moment().subtract(24, 'hour').startOf('day');

  if (!values.expected_arrival) {
    message.error('O campo previsão de chegada ETA deve ser informado');
    return false;
  }

  if (values.expected_arrival < minDate) {
    message.error(
      'A previsão de chegada deve ser no máximo 24 horas antes da data/hora atual'
    );
    return false;
  }
  return true;
}

export const mmsiRules: Rule[] = [
  {
    pattern: /^\d*$/,
    message: 'O MMSI deve conter apenas números',
  },
];

export const captaincyRegistrationRules: Rule[] = [
  {
    pattern: /^\d*$/,
    message: 'O Número de Inscrição da Capitania deve conter apenas números',
  },
];
