import { useMemo, CSSProperties } from 'react';

import { Tag } from '../../components';
import { chargeStatusList } from '../../utils/lists';

export function ChargeStatusTag(props: {
  status: string;
  style?: CSSProperties;
}) {
  const { status, style } = props;

  const selectedStatus = useMemo(() => {
    return (
      chargeStatusList.find((item) => item.id === status) || chargeStatusList[0]
    );
  }, [status]);

  return (
    <Tag style={style} className={`charge-status ${selectedStatus?.className}`}>
      {selectedStatus.name}
    </Tag>
  );
}
