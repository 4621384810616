import {
  CheckCircleOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Descriptions,
  FormatItem,
  Input,
  List,
  PageContentPlaceholder,
} from '../../../../../../../../components';
import {
  ContainerTypeDescription,
  FilterContaninerType,
} from '../../../../../../../../types';
import { isNullOrUndefined } from '../../../../../../../../utils/utils';
import { MerchantCeSection } from '../../../../../merchantCeSection/MerchantCeSection';
import { FilterContent } from './filterContent/FilterContent';
import { ContainerAside } from './styles';

type ContainersByCraneDescriptionProps = {
  containers: ContainerTypeDescription[];
  setIsContainerDrawerVisible?: (value: boolean) => void;
  setSelectedContainerForDrawer?: (value: ContainerTypeDescription) => void;
  isContainerDrawerVisible: boolean;
  pageContext?: 'OPERATOR_AREA';
  operationId?: number;
  craneId?: string;
};

const { Item } = Descriptions;

export function ContainersByCraneDescription(
  props: ContainersByCraneDescriptionProps
) {
  const {
    containers,
    setIsContainerDrawerVisible,
    setSelectedContainerForDrawer,
    isContainerDrawerVisible,
    pageContext,
    operationId,
    craneId,
  } = props;
  const [search, setSearch] = useState('');
  const [selectedContainer, setSelectedContainer] =
    useState<ContainerTypeDescription>({} as ContainerTypeDescription);
  const [filters, setFilters] = useState({} as FilterContaninerType);

  useEffect(() => {
    if (!isEmpty(selectedContainer)) {
      setSelectedContainer(
        containers.find((container) => container.id === selectedContainer.id) ||
          ({} as ContainerTypeDescription)
      );
    } else if (containers.length > 0) {
      setSelectedContainer(containers[0]);
    }
  }, [containers]);

  // useEffect(() => {
  //   if (operationId && !craneId)
  //     getOperationContainersData({ operation: operationId });
  // }, [operationId, containers]);

  // const [getOperationContainersData, { data: operationContainersData }] =
  //   useLazyGetOperationContainersQuery();

  // const containersToShow = useMemo(() => {
  //   if (operationContainersData && !craneId) {
  //     return formatContainersToDescription(operationContainersData.results);
  //   }
  //   return containers;
  // }, [craneId, operationContainersData, containers]);

  // Carrega os dados do infinit scroll ou caso mude a aba de status
  // useEffect(() => {
  //   if (isLoadMoreData && operationContainersData?.results) {
  //     setFilteredOperationContainers((prev) => [
  //       ...prev,
  //       ...operationContainersData?.results,
  //     ]);
  //     setIsLoadMoreData(false);
  //   } else {
  //     setFilteredOperationContainers(operationContainersData?.results || []);
  //   }
  // }, [operationContainersData?.results]);

  const filteredContainers = containers
    .filter((container) =>
      search.length > 0
        ? container.manifest_number?.includes(search) ||
          container.code?.includes(search.toUpperCase())
        : container
    )
    .filter((container) =>
      isNullOrUndefined(filters.size)
        ? container
        : filters.size === container.container_size
    )
    .filter((container) =>
      isNullOrUndefined(filters.isOperated)
        ? container
        : filters.isOperated === container.is_finished
    )
    .filter((container) =>
      isNullOrUndefined(filters.type)
        ? container
        : filters.type === container.container_type
    )
    .filter((container) =>
      isNullOrUndefined(filters.isFull)
        ? container
        : filters.isFull === container.is_full
    )
    .filter((container) =>
      isNullOrUndefined(filters.minWeight) || filters.minWeight === 0
        ? container
        : filters.minWeight < container.container_weight
    )
    .filter((container) =>
      isNullOrUndefined(filters.maxWeight) || filters.maxWeight === 0
        ? container
        : filters.maxWeight > container.container_weight
    )
    .filter((container) =>
      isNullOrUndefined(filters.minTara) || filters.minTara === 0
        ? container
        : filters.minTara < container.tara
    )
    .filter((container) =>
      isNullOrUndefined(filters.maxTara) || filters.maxTara === 0
        ? container
        : filters.maxTara > container.tara
    );

  function searchContainer() {
    return (
      <Input
        size="middle"
        placeholder="Pesquisar container"
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        prefix={
          <SearchOutlined style={{ color: '#09d4ab', fontSize: '16px' }} />
        }
        suffix={
          <Popover
            trigger="click"
            content={
              <FilterContent filters={filters} setFilters={setFilters} />
            }
            placement="right"
          >
            <FilterOutlined
              style={{ color: 'var(--neutral_medium)', fontSize: '16px' }}
            />
          </Popover>
        }
        allowClear
        style={{ border: '1px solid #CCDDE9' }}
      />
    );
  }

  function openEditContainerDrawer() {
    if (setIsContainerDrawerVisible) setIsContainerDrawerVisible(true);
    if (setSelectedContainerForDrawer)
      setSelectedContainerForDrawer(selectedContainer);
  }

  function openNewContainerDrawer() {
    if (setIsContainerDrawerVisible) setIsContainerDrawerVisible(true);
    if (setSelectedContainerForDrawer)
      setSelectedContainerForDrawer({} as ContainerTypeDescription);
  }

  return (
    <ContainerAside>
      <aside>
        <div>
          {searchContainer()}
          <List style={{ height: '530px', width: '210px', overflowX: 'auto' }}>
            {filteredContainers.length > 0 ? (
              filteredContainers.map((container, index) => (
                <List.Item
                  key={index}
                  className={
                    selectedContainer.id === container.id
                      ? 'item-container active'
                      : 'item-container'
                  }
                  onClick={() => setSelectedContainer(container)}
                >
                  {container.is_finished ? (
                    <CheckCircleOutlined
                      style={{ color: '#09d4ab', fontSize: '16px' }}
                    />
                  ) : (
                    <div style={{ padding: '8px' }} />
                  )}
                  <div className="item-container-data">
                    <span>{container.code}</span>
                    <span className="item-container-data-resume">
                      {container.is_full && 'Cheio • '}
                      {container.container_type} •{' '}
                      {container.container_size
                        ? `${container.container_size}"`
                        : ''}
                    </span>
                  </div>
                </List.Item>
              ))
            ) : (
              <div>
                <span>
                  {containers.length === 0
                    ? 'Nenhum container encontrado'
                    : 'Nenhum registro encontrado com os filtros informados'}
                </span>
              </div>
            )}
          </List>
        </div>
        {/* {pageContext === 'OPERATOR_AREA' && setIsContainerDrawerVisible && (
          <Button
            icon={<PlusOutlined />}
            style={{ width: '170px', alignSelf: 'center' }}
            onClick={() => openNewContainerDrawer()}
          >
            Novo container
          </Button>
        )} */}
      </aside>
      {isEmpty(selectedContainer) ? (
        <div style={{ display: 'flex', width: '100%' }}>
          <PageContentPlaceholder message="Selecione algum container" />
        </div>
      ) : (
        <div className="container-data">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBlockEnd: '25px',
            }}
          >
            <span className="title">{selectedContainer.code}</span>
            {/* {pageContext === 'OPERATOR_AREA' && setIsContainerDrawerVisible && (
              <Button
                icon={<EditFilled />}
                onClick={() => openEditContainerDrawer()}
              >
                Editar
              </Button>
            )} */}
          </div>
          <Descriptions column={2}>
            <Item label="Código Manifesto">
              <FormatItem>{selectedContainer.manifest_number}</FormatItem>
            </Item>
            <Item label="Local de armazenamento">
              <FormatItem>{selectedContainer.storage_company}</FormatItem>
            </Item>
          </Descriptions>
          <Descriptions column={2}>
            {/* <Item label="Guindaste">
              <FormatItem>{craneName}</FormatItem>
            </Item> */}
            <Item label="Sentido do produto" span={2}>
              <FormatItem>{selectedContainer.direction_container}</FormatItem>
            </Item>
          </Descriptions>
          <Descriptions>
            <Item label="ISO">
              <FormatItem>{selectedContainer.iso}</FormatItem>
            </Item>
            <Item label="Tipo">
              <FormatItem>{selectedContainer.container_type}</FormatItem>
            </Item>
            <Item label="Cheio">
              <FormatItem>
                {selectedContainer.is_full ? 'Sim' : 'Não'}
              </FormatItem>
            </Item>
            <Item label="Tamanho">
              <FormatItem>{selectedContainer.container_size_str}</FormatItem>
            </Item>
            <Item label="Peso">
              <FormatItem>{selectedContainer.container_weight_str}</FormatItem>
            </Item>
            <Item label="Tara">
              <FormatItem>{selectedContainer.tara_str}</FormatItem>
            </Item>
            <Item label="Linha">
              <FormatItem>{selectedContainer.line}</FormatItem>
            </Item>
            <Item label="IMO">
              <FormatItem>{selectedContainer.imo}</FormatItem>
            </Item>
            <Item label="Booking">
              <FormatItem>{selectedContainer.booking_code}</FormatItem>
            </Item>
            <Item label="Código ANTAQ">
              <FormatItem>{selectedContainer.codantaq}</FormatItem>
            </Item>
            <Item label="Tipo de operação da carga">
              <FormatItem>{selectedContainer.operation_type}</FormatItem>
            </Item>
          </Descriptions>
          <Descriptions column={2}>
            <Item label="Porto de carregamento">
              <FormatItem>{selectedContainer.port_boarding}</FormatItem>
            </Item>
            <Item label="Porto de descarregamento">
              <FormatItem>{selectedContainer.port_unboarding}</FormatItem>
            </Item>
          </Descriptions>
          <MerchantCeSection
            merchantsCe={selectedContainer.merchants_ce}
            isContainers
          />
        </div>
      )}
    </ContainerAside>
  );
}
