import { useMemo } from 'react';

import { Tag } from '../antd/Tag';

export function CustomStatusTag(props: {
  list: { id: string; className: string; label: string }[];
  status: string;
}) {
  const { list, status } = props;

  const selectedStatus = useMemo(() => {
    return list.find((item) => item.id === status) || list[0];
  }, [status]);

  return (
    <Tag className={`${selectedStatus?.className}`}>{selectedStatus.label}</Tag>
  );
}
