import styled from 'styled-components';

export const ProductOperationDetailsDrawerHeader = styled.div`
  margin-left: 10px;
  & h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    margin-bottom: 0px !important;
    margin-block-end: 0px !important;
    margin-block-start: 0px !important;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  & h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    color: var(--neutral_medium);
    margin-left: 10px;
    margin-bottom: 0px;
    text-transform: lowercase;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  & .manifest-code {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--neutral_medium);
    & .code {
      font-family: 'Consolas';
      color: #000;
      margin-left: 10px;
    }
  }

  & .ant-tag {
    font-family: 'Consolas';
    margin-left: 10px;
  }
`;

export const StorageMerchantTableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  & .amount,
  & .weight {
    width: 20%;
    padding-right: 10px;
    text-align: right;
  }
  & .amount {
    width: 10%;
  }
`;
