import { SlimTable } from '../..';
import { CargoStorageMerchantType } from '../../../types';
import { formatNumberToLocale } from '../../../utils/utils';
import { columns } from './columns';

export function CargoStorageMerchantTable(props: {
  dataSource: CargoStorageMerchantType[];
  isAgentAreaContext?: boolean;
}) {
  const { dataSource, isAgentAreaContext } = props;
  function getTableSummary() {
    const totalWeight = (dataSource || []).reduce(
      (sum, current) => sum + (current.volume || 0),
      0
    );

    const totalConfirmed =
      dataSource.filter((item) => item.status === 'CONFIRMED').length || 0;

    return (
      <SlimTable.Summary fixed>
        <SlimTable.Summary.Row key="row_0">
          <SlimTable.Summary.Cell index={0} colSpan={1} key={0} align="left">
            TOTAIS:{' '}
            {totalConfirmed > 0
              ? `${totalConfirmed} CONFIRMADOS`
              : dataSource.length || 0}
          </SlimTable.Summary.Cell>
          <SlimTable.Summary.Cell colSpan={2} index={1} key={1} align="right">
            {formatNumberToLocale(totalWeight)} m³
          </SlimTable.Summary.Cell>
        </SlimTable.Summary.Row>
      </SlimTable.Summary>
    );
  }

  return (
    <SlimTable
      rowKey="id"
      columns={columns(isAgentAreaContext)}
      dataSource={dataSource}
      className="read-only"
      pagination={false}
      summary={() => getTableSummary()}
    />
  );
}
