import { Collapse } from 'antd';
import styled from 'styled-components';

export const Section = styled(Collapse)`
  background: #fff;
  border-radius: 5px;
  border: none;

  & .title {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 18px;
    display: block;
  }
  & .ant-collapse {
    border-radius: 5px;
    border: 1px solid var(--neutral_lighter);
    overflow: hidden;
    margin-top: 16px;
    & .ant-collapse-header {
      background: #fff;
      font-size: 16px;
      color: var(--neutral_medium) !important;
      padding-left: 16px !important;
      & .ant-collapse-header-text {
        width: 100%;
      }
      & .docking-header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        & .docking-title {
          margin-right: 10px;
          display: inline-block;
        }
        & .docking-id {
          font-family: Consolas;
          font-weight: normal;
          display: inline-block;
          margin-right: 10px;
        }
        & .icons {
          & div {
            padding: 5px 15px;
            display: inline-block;
            opacity: 0.6;
            transition: 300ms;
            &:hover {
              opacity: 1;
              color: var(--neutral_dark);
            }
          }
        }
      }
    }
    & .ant-collapse-content-box {
      padding: 16px !important;
      & .docking-content-form {
        padding-left: 40px;
      }
      & .docking-product {
        border-top: 1px solid var(--neutral_lighter);
        padding-top: 16px;
        & .docking-product-title {
          font-size: 14px;
          color: var(--neutral_medium);
          font-weight: bold;
          margin-bottom: 16px;
        }
      }
    }
  }
`;
