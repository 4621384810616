import { EditFilled } from '@ant-design/icons';
import { Row } from 'antd';

import {
  Button,
  Descriptions,
  FormatItem,
  TabPane,
  Tabs,
  Title,
} from '../../../components';
import {
  OperationProductCeMercanteType,
  OperationProductStoragesMercanteType,
} from '../../../types';
import { capitalizeFirstLetter } from '../../../utils/utils';
import { StorageMerchantTable } from '../../operation/OperationsData/merchantCeSection/storageMerchantTable/StorageMerchantTable';
import { MerchantCEListItemHeader } from './styles';

type MerchantCEListItemProps = {
  merchantCE: OperationProductCeMercanteType;
  onEdit: () => void;
  isContainer?: boolean;
};

export function MerchantCEListItem(props: MerchantCEListItemProps) {
  const { merchantCE, isContainer } = props;
  const { Item } = Descriptions;

  function formatStorage(storage: OperationProductStoragesMercanteType) {
    return {
      ...storage,
      total_amount: storage.cargos_storage?.reduce(
        (sum: number, current: any) => sum + current.amount,
        0
      ),
      total_weight: storage.cargos_storage?.reduce(
        (sum: number, current: any) => sum + current.weight,
        0
      ),
    };
  }

  return (
    <MerchantCEListItemHeader>
      <Row>
        <Title className="title">{merchantCE.code}</Title>
        <Button onClick={() => props.onEdit()} icon={<EditFilled />}>
          Editar
        </Button>
      </Row>
      <Descriptions column={2}>
        <Item label="Porto de origem">
          <FormatItem>
            {capitalizeFirstLetter(merchantCE.origin_port?.country_name)},{' '}
            {capitalizeFirstLetter(merchantCE.origin_port?.name)}
          </FormatItem>
        </Item>
        <Item label="Porto de destino">
          <FormatItem>
            {capitalizeFirstLetter(merchantCE.destiny_port?.country_name)},{' '}
            {capitalizeFirstLetter(merchantCE.destiny_port?.name)}
          </FormatItem>
        </Item>
      </Descriptions>
      {!isContainer && (
        <Tabs>
          {merchantCE.storages?.map((storage, index) => {
            return (
              <TabPane
                tab={`${storage.storage_company?.name}`}
                key={`storage_${index}`}
              >
                <StorageMerchantTable storage={formatStorage(storage)} />
              </TabPane>
            );
          })}
        </Tabs>
      )}
      {isContainer &&
        merchantCE.storages?.map((storage) => {
          return <StorageMerchantTable storage={formatStorage(storage)} />;
        })}
    </MerchantCEListItemHeader>
  );
}
