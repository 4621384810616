import { Line, OperationLineToSaveType, OperationLineType } from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const operationLinesApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getLinesToRadioGroup: build.query<
      Array<{ label: string; value: number }>,
      { berth?: string }
    >({
      query: (params) => {
        return {
          url: '/operational/lines',
          method: 'GET',
          params,
        };
      },
      transformResponse: (response: Array<Line>) => {
        return response.map((line) => {
          return {
            label: line.name,
            value: line.id,
          };
        });
      },
      providesTags: () => [{ type: 'OperationLine', code: '1' }],
    }),
    createOperationLine: build.mutation<
      OperationLineType,
      OperationLineToSaveType
    >({
      query: (operationLine) => {
        return {
          url: '/operational/operation-lines',
          method: 'POST',
          body: operationLine,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: result?.operation_id },
        ]),
    }),
    updateOperationLine: build.mutation<
      OperationLineType[],
      OperationLineToSaveType[]
    >({
      query: (operationLine) => {
        return {
          url: `/operational/operation-lines`,
          method: 'PUT',
          body: operationLine,
        };
      },
      invalidatesTags: (result, error, queryArg) =>
        getInvalidatesTags(error, [
          {
            type: 'Stopover',
            code: result && result.length ? result[0].operation_id : undefined,
          },
          { type: 'OperationCalculus', code: queryArg[0].operation_id },
        ]),
    }),
  }),
});

export const {
  useGetLinesToRadioGroupQuery,
  useCreateOperationLineMutation,
  useUpdateOperationLineMutation,
} = operationLinesApi;
