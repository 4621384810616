import styled from 'styled-components';

export const TableWrapperStyles = styled.div`
  background: var(--neutral_lighter);
  border-radius: 5px;

  & .ant-pagination-item-active {
    background: var(--green-sea_medium);
    & a {
      color: #000;
    }
  }
`;
