import { EditFilled, FileAddOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button, Collapse, InnerCollapse } from '../../../components';
import {
  AllTableItemsType,
  AllTableType,
  ChargeType,
  FareType,
  IndexTablesType,
  TableFiveType,
} from '../../../typesFinancial';
import {
  createDate,
  formatNumberToLocale,
  maskString,
} from '../../../utils/utils';
import { ChargeStatusTag } from '../ChargeStatusTag';
import { FareDrawer } from './fareDrawer/FareDrawer';
import { FaresTablesData } from './FaresTablesData';
import { FareStatementDrawer } from './fareStatementDrawer/FareStatementDrawer';
import { ItemTableViewDrawer } from './itemTableViewDrawer/ItemTableViewDrawer';
import { Container } from './styles';

type FaresDataProps = {
  fares: FareType[];
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  selectedCharge: ChargeType;
  hasEdited: boolean;
  setHasEdited: (value: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate: (value: boolean) => void;
};

const { Panel } = Collapse;
const { Panel: InnerPanel } = InnerCollapse;

export function FaresData(props: FaresDataProps) {
  const {
    fares,
    activeKey,
    onOpenPanel,
    selectedCharge,
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
  } = props;
  const [isNewFareDrawerVisible, setIsNewFareDrawerVisible] = useState(false);
  const [isFareStatementDrawerVisible, setIsFareStatementDrawerVisible] =
    useState(false);

  const [isItemTableViewDrawerVisible, setItemTableViewDrawerVisible] =
    useState(false);
  const [selectedItemTable, setSelectedItemTable] = useState(
    {} as AllTableItemsType
  );
  const [selectedTable, setSelectedTable] = useState(0);

  const [selectedFare, setSelectedFare] = useState({} as FareType);
  const [isAddNewTable, setIsAddNewTable] = useState(false);

  // necessario criar 2 estados, 1 para saber qual a tabela e outro para retornar os dados da tabela
  const [isEditTable, setIsEditTable] = useState<0 | IndexTablesType>(0);
  const [editTable, setEditTable] = useState({} as AllTableType);

  const [isComplementary, setIsComplementary] = useState(false);

  function onClickEdit(
    e: React.MouseEvent<HTMLElement>,
    fare: FareType,
    isAddNewTableProp?: boolean,
    indexTable?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
    editTableProp = {} as AllTableType
  ) {
    e.stopPropagation();

    if (isAddNewTableProp) setIsAddNewTable(true);
    if (indexTable) setIsEditTable(indexTable);
    if (indexTable && editTableProp && indexTable === 5) {
      setEditTable({
        ...editTableProp,
        items:
          editTableProp?.items?.map((item) => ({
            ...item,
            cargo_arrival_date: item.cargo_arrival_date
              ? createDate(item.cargo_arrival_date)
              : null,
            cargo_departure_date: item.cargo_departure_date
              ? createDate(item.cargo_departure_date)
              : null,
          })) || [],
      });
    } else if (editTableProp) setEditTable(editTableProp);

    const formattedFare: FareType = {
      ...fare,
      table_five: {
        ...(fare.table_five || ({} as TableFiveType)),
        items:
          fare.table_five?.items.map((item) => ({
            ...item,
            cargo_arrival_date: item.cargo_arrival_date
              ? createDate(item.cargo_arrival_date)
              : null,
            cargo_departure_date: item.cargo_departure_date
              ? createDate(item.cargo_departure_date)
              : null,
          })) || [],
      },
    };
    setSelectedFare(formattedFare);
    setIsNewFareDrawerVisible(true);
  }

  function getHeader(fare: FareType) {
    return (
      <div className="header-fare-company">
        <span className="strong">{fare.company.name}</span>
        <span className="thin">
          {fare.company.cnpj
            ? maskString(fare.company.cnpj, '##.###.###/####-##')
            : fare.company.tin}
        </span>
        <ChargeStatusTag
          style={{ fontSize: '12px' }}
          status={fare.status || 'BILLING'}
        />
      </div>
    );
  }

  function extraHeaderTitle(fare: FareType) {
    return (
      <div className="extra-header-fare-company">
        <span className="fare-total">
          {formatNumberToLocale(fare.total_value || 0, true)}
        </span>
        <Button
          className="button-edit-fare"
          icon={<EditFilled />}
          htmlType="button"
          type="text"
          onClick={(e) => onClickEdit(e, fare)}
          disabled={fare.status === 'BILLED' || fare.status === 'REBILLED'}
        >
          Editar
        </Button>
      </div>
    );
  }

  function handleCloseItem() {
    setItemTableViewDrawerVisible(false);
    setSelectedItemTable({} as AllTableItemsType);
    setSelectedTable(0);
  }

  function openStatementDrawer(fare: FareType) {
    setSelectedFare(fare);
    setIsFareStatementDrawerVisible(true);
  }

  function onCloseDrawer() {
    setSelectedFare({} as FareType);
    setIsAddNewTable(false);
    setIsEditTable(0);
    setEditTable({} as AllTableType);
    setHasEdited(false);
    setBlockNavigate(false);
    setIsComplementary(false);
  }

  return (
    <>
      <ItemTableViewDrawer
        isVisible={isItemTableViewDrawerVisible}
        selectedItem={selectedItemTable}
        selectedTable={selectedTable}
        selectedCharge={selectedCharge}
        onClose={handleCloseItem}
      />
      <FareStatementDrawer
        isVisible={isFareStatementDrawerVisible}
        setIsVisible={setIsFareStatementDrawerVisible}
        selectedFare={selectedFare}
        selectedCharge={selectedCharge}
      />
      <FareDrawer
        isVisible={isNewFareDrawerVisible}
        setIsVisible={setIsNewFareDrawerVisible}
        selectedCharge={selectedCharge}
        hasEdited={hasEdited}
        setHasEdited={setHasEdited}
        blockNavigate={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        selectedFare={selectedFare}
        isAddNewTable={isAddNewTable}
        isEditTable={isEditTable}
        editTable={editTable}
        onCloseDrawer={onCloseDrawer}
        isComplementary={isComplementary}
        setSelectedFare={setSelectedFare}
      />
      <Container>
        <Collapse
          activeKey={activeKey}
          key="fares"
          onChange={onOpenPanel}
          expandIconPosition="end"
        >
          <Panel forceRender header="Tarifas" key="fares">
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={{ marginBottom: '16px' }}
                onClick={() => setIsNewFareDrawerVisible(true)}
              >
                Novo responsável
              </Button>
              <Button
                icon={<FileAddOutlined />}
                style={{ marginBottom: '16px' }}
                onClick={() => {
                  setIsNewFareDrawerVisible(true);
                  setIsComplementary(true);
                }}
              >
                Complementar fatura
              </Button>
            </div>
            {fares.map((fare) => (
              <InnerCollapse
                key={fare.id}
                expandIconPosition="end"
                defaultActiveKey={fares.map((fare) => `fare_${fare.id}`)}
              >
                <InnerPanel
                  key={`fare_${fare.id}`}
                  header={getHeader(fare)}
                  extra={extraHeaderTitle(fare)}
                >
                  {fare.complementaries && fare.complementaries.length > 0 && (
                    <>
                      <hr className="division-line" />
                      <FaresTablesData
                        fare={fare.complementaries[0]}
                        setSelectedItemTable={setSelectedItemTable}
                        setItemTableViewDrawerVisible={
                          setItemTableViewDrawerVisible
                        }
                        onClickEdit={onClickEdit}
                        setSelectedTable={setSelectedTable}
                        title="FATURA COMPLEMENTAR"
                        openStatementDrawer={openStatementDrawer}
                        chargeId={selectedCharge.id}
                      />
                    </>
                  )}
                  <hr className="division-line" />
                  <FaresTablesData
                    fare={fare}
                    setSelectedItemTable={setSelectedItemTable}
                    setItemTableViewDrawerVisible={
                      setItemTableViewDrawerVisible
                    }
                    onClickEdit={onClickEdit}
                    setSelectedTable={setSelectedTable}
                    openStatementDrawer={openStatementDrawer}
                    chargeId={selectedCharge.id}
                  />
                </InnerPanel>
              </InnerCollapse>
            ))}
          </Panel>
        </Collapse>
      </Container>
    </>
  );
}
