import { useMemo } from 'react';

import { MaskedInputProps } from '../../types';
import { MaskedInput } from './Input';

export function InputDynamicPhone(props: MaskedInputProps) {
  const cellphoneMask = '+ 00 (00) 0 0000-0000';
  const phoneMask = '+ 00 (00) 0000-0000';

  // always memoize dynamic masks
  const mask = useMemo(
    () => [
      {
        mask: cellphoneMask,
        lazy: false,
      },
      {
        mask: phoneMask,
        lazy: false,
      },
    ],
    []
  );

  return (
    <MaskedInput
      {...props}
      mask={mask}
      maskOptions={{
        dispatch(_appended: any, dynamicMasked: any) {
          const isCellPhone = dynamicMasked.unmaskedValue[4] === '9';
          return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
        },
      }}
    />
  );
}
