import { Col, Row } from 'antd';
import { useMemo } from 'react';

import { Descriptions, FormatItem, Title } from '../../../../../components';
import {
  OperationalOperationType,
  OperationCalculusType,
} from '../../../../../types';
import {
  convertMinutesToStringHours,
  createDateStringPtBr,
} from '../../../../../utils/utils';
import { DescriptionCard, DescriptionSection } from './styles';

const { Item } = Descriptions;

type OperationResumeDescriptionSectionProps = {
  operation: OperationalOperationType;
  isLiquidBulk?: boolean;
  operationCalculusData: OperationCalculusType;
};

export function OperationResumeDescriptionSection(
  props: OperationResumeDescriptionSectionProps
) {
  const { operation, isLiquidBulk, operationCalculusData } = props;

  const first = useMemo(() => {
    if (
      operation &&
      operation.work_periods &&
      operation.work_periods.length > 0 &&
      operation.work_periods[0].work_shifts &&
      operation.work_periods[0].work_shifts.length > 0
    ) {
      return operation.work_periods[0].work_shifts[0].shift_start;
    }
    return null;
  }, [operation]);

  const last = useMemo(() => {
    if (
      operation &&
      operation.work_periods &&
      operation.work_periods.length > 0 &&
      operation.work_periods[0].work_shifts &&
      operation.work_periods[0].work_shifts.length > 0
    ) {
      return operation.work_periods[operation.work_periods.length - 1]
        .work_shifts[
        operation.work_periods[operation.work_periods.length - 1].work_shifts
          .length - 1
      ].shift_end;
    }
    return null;
  }, [operation]);

  return (
    <DescriptionSection>
      <Title>RESUMO DA OPERAÇÃO</Title>
      <Row>
        <Col span={8}>
          <DescriptionCard>
            <Title>PROGRESSO TOTAL</Title>
            <Descriptions column={1}>
              <Item label="Total manifestado">
                <FormatItem>
                  {operationCalculusData.total_weight || 0} ton
                </FormatItem>
              </Item>
              <Item label="Total movimentado">
                <FormatItem>
                  {operationCalculusData.total_movimented || 0} ton
                </FormatItem>
              </Item>
            </Descriptions>
          </DescriptionCard>
        </Col>
        {!isLiquidBulk && (
          <>
            <Col span={8}>
              <DescriptionCard>
                <Title>PRODUÇÃO POR PERÍODO</Title>
                <Descriptions column={1}>
                  {operationCalculusData?.work_periods?.map((item, index) => {
                    return (
                      <Item key={item.id} label={`Período ${index + 1}`}>
                        <FormatItem>{item.total_movimented} ton</FormatItem>
                      </Item>
                    );
                  })}
                </Descriptions>
              </DescriptionCard>
            </Col>
            <Col span={8}>
              <DescriptionCard>
                <Title>TEMPOS</Title>
                <Descriptions column={1}>
                  <Item label="Início do primeiro terno">
                    <FormatItem>{createDateStringPtBr(first)}</FormatItem>
                  </Item>
                  <Item label="Fim do último terno">
                    <FormatItem>{createDateStringPtBr(last)}</FormatItem>
                  </Item>
                </Descriptions>
              </DescriptionCard>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <DescriptionCard>
          <Title>TOTAIS</Title>
          <Descriptions column={5}>
            <Item label="Duração da operação">
              <FormatItem>
                {convertMinutesToStringHours(operationCalculusData.total_time)}
              </FormatItem>
            </Item>
            <Item label="Horas operacionais">
              <FormatItem>
                {operationCalculusData?.total_time &&
                  convertMinutesToStringHours(
                    (operationCalculusData?.total_time || 0) -
                      (operationCalculusData?.total_stoppage_time || 0)
                  )}
              </FormatItem>
            </Item>
            <Item label="Horas paralisadas">
              <FormatItem>
                {convertMinutesToStringHours(
                  operationCalculusData.total_stoppage_time
                )}
              </FormatItem>
            </Item>
            <Item label="Produtiv. líquida">
              <FormatItem>
                {operationCalculusData?.total_gross_production &&
                  `${operationCalculusData?.total_gross_production} ton/h`}
              </FormatItem>
            </Item>
            <Item label="Produtiv. bruta">
              <FormatItem>
                {operationCalculusData?.total_gross_production &&
                  `${operationCalculusData?.total_gross_production} ton/h`}
              </FormatItem>
            </Item>
          </Descriptions>
        </DescriptionCard>
      </Row>
    </DescriptionSection>
  );
}
