import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Col, message, Row } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import {
  Form,
  GenericDrawerHeader,
  ModalBlockNavigation,
  FormItemDatePicker,
  FormItemInputNumber,
  VesselSelectData,
  FormItemDUV,
  StopoverAgentAreaDescription,
  PageContentPlaceholder,
} from '../../../components';
import { useLazyGetCompaniesQuery } from '../../../services/companyApi';
import {
  useLazyGetPspDuvSummaryQuery,
  useLazyGetPspStaymentQuery,
  useLazyGetPspVesselQuery,
} from '../../../services/pspApi';
import { useAddStopoverMutation } from '../../../services/stopoverApi';
import {
  useLazyGetPortQuery,
  useLazyGetVesselsQuery,
} from '../../../services/vesselApi';
import { Stopover as StopoverType, Vessel as VesselType } from '../../../types';
import { formatStopoverToForm } from '../../../utils/formatters';
import {
  createDateStringPtBr,
  disableDateTimeBeforeAndAfterDay,
  enableDatesBetween,
  removeSpecialCharacters,
} from '../../../utils/utils';
import { validateStopover } from '../../../utils/validators';
import { formatNewStopover } from '../../stopover/stopoverForm/formatStopover';
import { ModalExpectedArrivalPspDifferent } from './modalExpectedArrivalPspDifferent';
import { ModalVesselPspDifferent } from './modalVesselPspDifferent';

type AgentAreaFormProps = {
  selectedStopover: StopoverType;
  hasEdited: boolean;
  setHasEdited?: (hasEdited: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  onCloseDrawer?: () => void;
  setSelectedStopover?: Dispatch<SetStateAction<StopoverType>>;
  setFilteredStopoveres?: Dispatch<SetStateAction<StopoverType[]>>;
  visibleForm?: boolean;
};

export function AgentAreaForm(props: AgentAreaFormProps) {
  const {
    selectedStopover,
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
    onCloseDrawer,
    setSelectedStopover,
    setFilteredStopoveres,
    visibleForm,
  } = props;

  const [form] = Form.useForm();
  const [selectedVessel, setSelectedVessel] = useState<VesselType>(
    props.selectedStopover?.vessel || ({} as VesselType)
  );

  const [searchVessel, setSearchVessel] = useState({
    name: selectedVessel?.name || '',
  });

  const [stopoverByPsp, setStopoverByPsp] = useState({} as StopoverType);
  const [showModalVesselPspDifferent, setShowModalVesselPspDifferent] =
    useState(false);
  const [
    showModalExpectedArrivalPspDifferent,
    setShowModalExpectedArrivalPspDifferent,
  ] = useState(false);

  const [isDuvValid, setIsDuvValid] = useState(false);
  // Dados do psp são retornados de 3 endpoints diferentes
  const [
    getPspDuvSummary,
    {
      data: pspDuvSummaryData,
      isLoading: isLoadingPspDuvSummary,
      isSuccess: isSuccessPspDuvSummary,
    },
  ] = useLazyGetPspDuvSummaryQuery();
  const [getPspVessel, { data: pspVesselData, isSuccess: isSuccessPspVessel }] =
    useLazyGetPspVesselQuery();
  const [getPspStaymentSummary, { data: pspStaymentSummaryData }] =
    useLazyGetPspStaymentQuery();

  // Consultas feitas a partir de dados retornados do psp
  const [getAgencyCompanies, { data: agencyCompaniesData }] =
    useLazyGetCompaniesQuery();
  const [getChartererCompanies, { data: chartererCompaniesData }] =
    useLazyGetCompaniesQuery();
  const [getLastPort, { data: lastPortData }] = useLazyGetPortQuery();
  const [getNextPort, { data: nextPortData }] = useLazyGetPortQuery();
  const [getVessels, { data: vesselsData, isFetching: isFetchingVesselsData }] =
    useLazyGetVesselsQuery();

  const [
    addStopover,
    { isLoading: isLoadingAddStopover, isSuccess: isSuccessAddStopover },
  ] = useAddStopoverMutation();

  useEffect(() => {
    if (pspDuvSummaryData) {
      if (pspDuvSummaryData.navegation_agency.cnpj) {
        getAgencyCompanies({
          name_or_cnpj: pspDuvSummaryData.navegation_agency.cnpj,
        });
      }
      setStopoverByPsp((prev) => ({
        ...prev,
        expected_arrival_ais: pspDuvSummaryData?.expected_arrival
          ? moment(pspDuvSummaryData?.expected_arrival)
          : null,
        expected_departure: pspDuvSummaryData?.expected_port_departure
          ? moment(pspDuvSummaryData?.expected_port_departure)
          : null,
        // atualizar apos criar o campo
        nature_of_stayment: pspDuvSummaryData?.stayment_nature,
      }));
      const pspExpecArrival = createDateStringPtBr(
        pspDuvSummaryData.expected_arrival
      );
      const tosExpecArrival = createDateStringPtBr(
        form.getFieldValue('expected_arrival')
      );
      if (
        pspDuvSummaryData.expected_arrival &&
        pspExpecArrival !== tosExpecArrival
      ) {
        setShowModalExpectedArrivalPspDifferent(true);
      }
    }
  }, [pspDuvSummaryData]);

  useEffect(() => {
    if (pspStaymentSummaryData) {
      if (
        pspStaymentSummaryData.last_port_stopover &&
        pspStaymentSummaryData.last_port_stopover.bitrigram !== ''
      ) {
        getLastPort(pspStaymentSummaryData.last_port_stopover.bitrigram);
      }
      if (
        pspStaymentSummaryData.next_port_stopover &&
        pspStaymentSummaryData.next_port_stopover.bitrigram !== ''
      ) {
        getNextPort(pspStaymentSummaryData.next_port_stopover.bitrigram);
      }
      setStopoverByPsp((prev) => ({
        ...prev,
        shipowner_trip:
          pspStaymentSummaryData.general_information?.owner_travel_number,
        navigation_type: pspStaymentSummaryData.general_information
          ?.travel_arrival_type
          ? {
              id: 0,
              code: '0',
              name: pspStaymentSummaryData?.general_information
                ?.travel_arrival_type,
              description:
                pspStaymentSummaryData?.general_information
                  ?.travel_arrival_type,
            }
          : null,
      }));
    }
  }, [pspStaymentSummaryData]);

  useEffect(() => {
    if (pspVesselData?.charterer?.cnpj) {
      getChartererCompanies({ name_or_cnpj: pspVesselData?.charterer.cnpj });
    }
    if (pspVesselData?.imo) {
      getVessels({ imo_or_name: pspVesselData?.imo });
    }
  }, [pspVesselData, isSuccessPspVessel]);

  useEffect(() => {
    if (agencyCompaniesData?.results) {
      setStopoverByPsp((prev) => ({
        ...prev,
        shipping_agency: agencyCompaniesData.results[0],
      }));
    }
  }, [agencyCompaniesData?.results]);

  useEffect(() => {
    if (chartererCompaniesData?.results) {
      setStopoverByPsp((prev) => ({
        ...prev,
        charterer: chartererCompaniesData.results[0],
      }));
    }
  }, [chartererCompaniesData?.results]);

  useEffect(() => {
    if (lastPortData) {
      setStopoverByPsp((prev) => ({
        ...prev,
        last_port: lastPortData,
      }));
    }
  }, [lastPortData]);

  useEffect(() => {
    if (nextPortData) {
      setStopoverByPsp((prev) => ({
        ...prev,
        next_port: nextPortData,
      }));
    }
  }, [nextPortData]);

  useEffect(() => {
    if (vesselsData?.results && vesselsData?.results.length > 0) {
      setStopoverByPsp((prev) => ({
        ...prev,
        vessel: vesselsData.results[0],
      }));
      if (selectedVessel?.imo !== vesselsData.results[0].imo) {
        setShowModalVesselPspDifferent(true);
      }
    } else if (
      !isFetchingVesselsData &&
      !isEmpty(pspVesselData?.imo) &&
      pspVesselData?.imo !== ''
    ) {
      message.warning(
        `A embarcação ${pspVesselData?.name}(IMO:${pspVesselData?.imo}) encontrada no PSP não existe no TOS.`,
        15
      );
    }
  }, [isFetchingVesselsData]);

  useEffect(() => {
    function setBlockNavigateAndHasEditedFalse() {
      if (props.setBlockNavigate) {
        props.setBlockNavigate(false);
      }
      if (props.setHasEdited) {
        props.setHasEdited(false);
      }
    }

    if (isSuccessAddStopover) {
      setBlockNavigateAndHasEditedFalse();
      setSelectedVessel({} as VesselType);
      form.resetFields();
      if (setHasEdited) setHasEdited(false);
      if (onCloseDrawer) {
        onCloseDrawer();
      }
    }
  }, [isSuccessAddStopover]);

  const { company: userCompany } = useAppSelector(
    (state: RootState) => state.user.user
  );

  async function handleFormSubmit(values: any) {
    if (
      !validateStopover({
        expected_arrival: form.getFieldValue('expected_arrival'),
      })
    )
      return;

    values.shipping_agency = !isEmpty(userCompany)
      ? userCompany
      : agencyCompaniesData?.results[0];

    values.duv = form.getFieldValue('duv');
    values.expected_arrival = form.getFieldValue('expected_arrival');
    values.arrival_draught = form.getFieldValue('arrival_draught');
    const newStopover = formatNewStopover({ ...stopoverByPsp, ...values });
    const savedStopover = await addStopover(newStopover);
    if ('data' in savedStopover && setSelectedStopover) {
      const formattedStopover = formatStopoverToForm(savedStopover.data);
      setSelectedStopover(formattedStopover);
      if (setFilteredStopoveres) {
        setFilteredStopoveres((prev) => {
          const prevList = prev ? [...prev] : [];
          // Ao adicionar, remove o ultimo item para ele não se repetir na paginação
          if (prevList) prevList.pop();
          return [formattedStopover, ...prevList];
        });
      }
    }
  }

  const handleFormSubmitError = (errorInfo: any) => {
    console.error(errorInfo);
  };

  function onSelectVessel(vessel: any) {
    setSelectedVessel(vessel);
    setStopoverByPsp((prev) => ({ ...prev, vessel }));
  }

  function loadDataDuv(duv: string) {
    if (duv.length !== 10) {
      setIsDuvValid(false);
      setStopoverByPsp({} as StopoverType);
      form.setFieldsValue({});
      return;
    }
    setIsDuvValid(true);
    getPspDuvSummary(duv);
    getPspVessel(duv);
    getPspStaymentSummary(duv);
  }

  function setPspVesselInStopoverForm() {
    if (vesselsData !== undefined && vesselsData.results.length > 0) {
      setSearchVessel({ name: vesselsData.results[0].imo || '' });
      setSelectedVessel(vesselsData.results[0]);
      form.setFieldsValue({ vessel: { id: vesselsData.results[0].id } });
    }
    setShowModalVesselPspDifferent(false);
  }

  function setPspExpectedArrivalInStopoverForm() {
    if (
      pspDuvSummaryData !== undefined &&
      pspDuvSummaryData.expected_arrival !== ''
    ) {
      form.setFieldsValue({
        expected_arrival: moment(pspDuvSummaryData.expected_arrival),
      });
    }
    setShowModalExpectedArrivalPspDifferent(false);
  }

  return (
    <>
      <ModalVesselPspDifferent
        showModalVesselPspDifferent={showModalVesselPspDifferent}
        setShowModalVesselPspDifferent={setShowModalVesselPspDifferent}
        setPspVesselInStopoverForm={setPspVesselInStopoverForm}
        vesselsDataResults={vesselsData?.results}
      />
      <ModalExpectedArrivalPspDifferent
        showModalExpectedArrivalPspDifferent={
          showModalExpectedArrivalPspDifferent
        }
        setShowModalExpectedArrivalPspDifferent={
          setShowModalExpectedArrivalPspDifferent
        }
        setPspExpectedArrivalInStopoverForm={
          setPspExpectedArrivalInStopoverForm
        }
        pspExpectedArrival={pspDuvSummaryData?.expected_arrival}
        tosExpectedArrival={form.getFieldValue('expected_arrival')}
      />
      <Form
        form={form}
        autoComplete="off"
        initialValues={selectedStopover}
        layout="vertical"
        name="create_stopover_by_agent"
        id="create_stopover_by_agent"
        onFinish={handleFormSubmit}
        onValuesChange={() => {
          if (setHasEdited) {
            setHasEdited(true);
          }
        }}
        onFinishFailed={handleFormSubmitError}
      >
        <ModalBlockNavigation
          name="create_stopover_by_agent"
          setHasEdited={props.setHasEdited}
          showModal={props.blockNavigate}
          setBlockNavigate={props.setBlockNavigate}
        />
        <GenericDrawerHeader
          onBack={() => onCloseDrawer && onCloseDrawer()}
          title={
            <span style={{ fontWeight: '700', fontSize: '16px' }}>
              Nova escala
            </span>
          }
          showBackButton
          formName="create_stopover_by_agent"
          disabledSaveButton={isLoadingAddStopover}
        />
        <div style={{ margin: '24px 16px' }}>
          <Row gutter={40}>
            <FormItemDatePicker
              colSpan={12}
              label="Previsão de chegada ETA (agente de navegação)"
              name="expected_arrival"
              required
              minuteStep={15}
              disabledDate={(e) =>
                enableDatesBetween(
                  e,
                  moment().subtract(24, 'hour').startOf('day'),
                  null
                )
              }
              disabledTime={(e) =>
                disableDateTimeBeforeAndAfterDay(
                  e,
                  moment().subtract(24, 'hour'),
                  null
                )
              }
            />
            <FormItemInputNumber
              colSpan={12}
              name="arrival_draught"
              label="Calado de chegada"
              addonAfter="m"
              required
              maxLength={8}
            />
          </Row>
          <VesselSelectData
            selectedVessel={selectedVessel}
            visibleForm={visibleForm}
            onSelectVessel={onSelectVessel}
            form={form}
            searchVessel={searchVessel}
            setSearchVessel={setSearchVessel}
          />
          <Row gutter={20} align="middle" style={{ marginBlockStart: '24px' }}>
            <FormItemDUV
              colSpan={12}
              name="duv"
              onBlur={(e) => loadDataDuv(e.unmaskedValue)}
              onChange={(e) => loadDataDuv(e.unmaskedValue)}
            />
            <Col span={12}>
              <Alert
                message={
                  isLoadingPspDuvSummary
                    ? 'Carregando...'
                    : !pspDuvSummaryData?.expected_arrival &&
                      isSuccessPspDuvSummary
                    ? 'Não foram encontrados dados para o DUV informado'
                    : pspDuvSummaryData?.expected_arrival &&
                      isSuccessPspDuvSummary &&
                      isDuvValid
                    ? 'DUV encontrado no PSP'
                    : 'Informe um DUV válido para consultar no PSP'
                }
                type={
                  isLoadingPspDuvSummary
                    ? 'info'
                    : !pspDuvSummaryData?.expected_arrival &&
                      isSuccessPspDuvSummary
                    ? 'error'
                    : pspDuvSummaryData?.expected_arrival &&
                      isSuccessPspDuvSummary
                    ? 'success'
                    : 'info'
                }
                showIcon
                icon={<InfoCircleOutlined />}
              />
            </Col>
          </Row>
          {isDuvValid ? (
            <StopoverAgentAreaDescription selectedStopover={stopoverByPsp} />
          ) : (
            <div style={{ height: '230px' }}>
              <PageContentPlaceholder message="Preencha o DUV" />
            </div>
          )}
        </div>
      </Form>
    </>
  );
}
