import { FormInstance } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

import { Drawer, Form, GenericDrawerHeader } from '..';

type FormDrawerProps = {
  children: ReactNode;
  isVisible: boolean;
  title: ReactNode;
  onClose: () => void;
  form: FormInstance;
  formName: string;
  handleFormSubmit: () => void;
  width?: number;
  onValuesChange?: () => void;
  disabledSaveButton?: boolean;
  containerStyle?: CSSProperties;
  initialValues?: Store;
  saveButtonTitle?: string;
  deleteButtonTitle?: string;
  onDelete?: () => void;
};

export function FormDrawer(props: FormDrawerProps) {
  const {
    isVisible,
    width,
    onClose,
    form,
    formName,
    handleFormSubmit,
    onValuesChange,
    title,
    disabledSaveButton,
    containerStyle,
    children,
    initialValues,
    saveButtonTitle,
    deleteButtonTitle,
    onDelete,
  } = props;
  return (
    <Drawer
      visible={isVisible}
      placement="right"
      width={width || 750}
      closable={false}
      destroyOnClose
      onClose={() => onClose()}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        name={formName}
        id={formName}
        onFinish={handleFormSubmit}
        onValuesChange={() => onValuesChange && onValuesChange()}
        initialValues={initialValues}
      >
        <GenericDrawerHeader
          onBack={() => onClose()}
          title={title}
          showBackButton
          formName={formName}
          disabledSaveButton={disabledSaveButton}
          isLoadingSave={disabledSaveButton}
          saveButtonTitle={saveButtonTitle}
          deleteButtonTitle={deleteButtonTitle}
          onDelete={onDelete}
        />
        <div style={containerStyle || { padding: '24px 16px' }}>{children}</div>
      </Form>
    </Drawer>
  );
}
