import { CheckOutlined } from '@ant-design/icons';
import { FormInstance, message } from 'antd';
import { useEffect } from 'react';

import {
  Button,
  Form,
  GenericDrawerHeader,
  ModalBlockNavigation,
} from '../../../../components';
import {
  useCreateOperationProductMutation,
  useUpdateOperationProductMutation,
} from '../../../../services/operationProductApi';
import {
  OperationProductAndCraneType,
  OperationProductType,
  Stopover as StopoverType,
} from '../../../../types';
import { formatOperationProductToSave } from '../../../../utils/formatters';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import { OperationPeriodsData } from '../../../operatorArea/operationPeriodsData/OperationPeriodsData';
import { OperationProductConsigneeData } from '../../../operatorArea/operationProductConsigneeData/OperationProductConsigneeData';
import { formatProductMerchantCeToDescription } from '../../OperationsData/formatOperationToDescription';
import { FormItemsOperationProduct } from './formItemsOperationProduct';
import { ProductOperationDetailsDrawerHeader } from './styles';

type OperationProductsEditFormProps = {
  selectedProduct?: OperationProductType | undefined;
  setSelectedProduct?: (value: OperationProductType | undefined) => void;
  selectedStopover: StopoverType;
  setSelectedStopover: (stopover: StopoverType) => void;
  onFinishSubmit: () => void;
  onBack: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  setIsVisibleForm: (value: boolean) => void;
  blockNavigate: boolean;
  operationForm?: FormInstance | undefined;
};

export function OperationProductsEditForm(
  props: OperationProductsEditFormProps
) {
  const {
    selectedProduct,
    setSelectedProduct,
    selectedStopover,
    setSelectedStopover,
    onFinishSubmit,
    onBack,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    setIsVisibleForm,
    blockNavigate,
    operationForm,
  } = props;
  const [form] = Form.useForm();

  // update operation product hook
  const [
    updateOperationProduct,
    {
      isLoading: isLoadingUpdateProduct,
      isError: isErrorUpdateProduct,
      error: errorUpdateProduct,
    },
  ] = useUpdateOperationProductMutation();
  const [
    createOperationProduct,
    {
      isLoading: isLoadingCreateProduct,
      isError: isErrorCreateProduct,
      error: errorCreateProduct,
    },
  ] = useCreateOperationProductMutation();

  useEffect(() => {
    form.setFieldsValue(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    if (isErrorUpdateProduct) {
      return message.error('Erro ao salvar alterações.');
    }
  }, [isErrorUpdateProduct]);

  // submit form after validation
  async function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        const product: OperationProductAndCraneType = {
          ...selectedProduct,
          ...values,
        };

        if (selectedProduct?.id) {
          const update = formatOperationProductToSave(product);
          const updatedProduct = await updateOperationProduct(update);
          if ('data' in updatedProduct && setSelectedProduct) {
            setSelectedProduct(updatedProduct.data);
            const items = selectedStopover.operation.items?.map(
              (item: OperationProductType) => {
                if (item.id === updatedProduct.data.id) {
                  return updatedProduct.data;
                }
                return item;
              }
            );
            setSelectedStopover({
              ...selectedStopover,
              operation: { ...selectedStopover.operation, items },
            });
          }
          message.success('Alterações salvas com sucesso!', 5);
        } else {
          product.operation_id = selectedStopover?.operation?.id;
          const create = formatOperationProductToSave(product);
          const createdProduct = await createOperationProduct(create);
          if ('data' in createdProduct && setSelectedStopover) {
            const oldItems = selectedStopover?.operation?.items || [];
            setSelectedStopover({
              ...selectedStopover,
              operation: {
                ...selectedStopover.operation,
                items: [...oldItems, createdProduct.data],
              },
            });
          }
        }
      })
      .finally(() => {
        onFinishSubmit();
        if (setSelectedProduct) setSelectedProduct(undefined);
        setBlockNavigate(false);
        setIsFormChanged(false);
        setIsVisibleForm(false);
      });
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      setIsVisibleForm(false);
    }
  }

  function afterCloseModal() {
    form.resetFields();
  }

  function drawerHeaderTitle(product?: OperationProductType) {
    return (
      <ProductOperationDetailsDrawerHeader>
        <div className="title">
          {product?.id ? (
            <>
              <h1>{product?.cargo_type?.name}</h1>
              <span>
                {capitalizeFirstLetter(
                  product?.cargo_type?.product_group?.name
                )}
              </span>
            </>
          ) : (
            <h1>Novo produto</h1>
          )}
        </div>
      </ProductOperationDetailsDrawerHeader>
    );
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name="product_edit_form"
      id="product_edit_form"
      onFinish={handleFormSubmit}
      initialValues={selectedProduct}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <GenericDrawerHeader
        title={drawerHeaderTitle(selectedProduct)}
        showBackButton
        onBack={onBack}
        formName="product_edit_form"
      />
      <ModalBlockNavigation
        name="product_edit_form"
        key="product_edit_form"
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <FormItemsOperationProduct
        form={form}
        selectedProduct={selectedProduct || ({} as OperationProductType)}
        style={{ padding: '24px 16px' }}
      />
      {/* <OperationProductConsigneeData
        consigneeCompanies={formatProductMerchantCeToDescription(
          selectedProduct?.merchants_ce || []
        )}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
      /> */}
      {/* {selectedProduct?.work_periods !== undefined && (
        <OperationPeriodsData
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          workPeriods={selectedProduct?.work_periods}
        />
      )} */}
    </Form>
  );
}
