// Redux
import {
  isRejected,
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from '@reduxjs/toolkit';

// Types
import { ApiResponseError } from '../../types';
// Store
import { handleErrors } from '../../utils/handleErrors';
import { createAlert } from '../store/alertStore';

// Utils

export const ErrorMiddleware: Middleware =
  (api: MiddlewareAPI) =>
  (next) =>
  (action: PayloadAction<ApiResponseError>) => {
    if (isRejected(action) && action.payload && action.payload.data) {
      const error = action.payload.data;
      const tokenErrorMsg = 'Given token not valid for any token type';
      if (typeof error === 'string' || !error.message.includes(tokenErrorMsg)) {
        api.dispatch(
          createAlert({
            message: handleErrors(error),
            type: 'error',
          })
        );
      }
    }

    return next(action);
  };
