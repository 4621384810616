import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import { debounce } from 'lodash';

import { Button, Form, FormItem } from '../../components';

export function sidebar(
  onOpenNewDocking: () => void,
  form: any,
  onSearchDockingByName: (evt: any) => void
) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '400px',
        background: 'rgb(223 234 241)',
      }}
    >
      <Row align="middle">
        <Col span={12} style={{ bottom: '12px' }}>
          <Button
            type="primary"
            block
            size="large"
            onClick={() => onOpenNewDocking()}
          >
            Nova atracação
          </Button>
        </Col>
        <Col span={12} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
          <Form form={form}>
            <FormItem name="search">
              <Input
                size="large"
                placeholder="Pesquisar"
                onChange={debounce((e) => onSearchDockingByName(e), 1000)}
                onPressEnter={onSearchDockingByName}
                type="text"
                prefix={
                  <SearchOutlined
                    style={{ color: '#09d4ab', fontSize: '20px' }}
                  />
                }
                allowClear
              />
            </FormItem>
          </Form>
        </Col>
      </Row>
      <div className="sidebar-content">
        <span className="sidebar-column">LOCAL</span>
        <span className="sidebar-column">EMBARCAÇÃO</span>
      </div>
    </div>
  );
}
