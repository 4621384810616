import {
  ArrowLeftOutlined,
  CheckOutlined,
  EditFilled,
} from '@ant-design/icons';
import { Col, Row, Space, Switch } from 'antd';
import { ReactNode } from 'react';

import { Stopover as StopoverType } from '../../../types';
import { Button } from '../../antd/Button';
import { StopoverHeader } from './styles';

type FormHeaderProps = {
  selectedStopover: StopoverType;
  openCollapsePanels: string | string[];
  buttons?: () => ReactNode;
  titleButtons?: () => ReactNode;
  editButtonTitle?: string | ReactNode;
  onClickEdit?: any;
  readOnly?: boolean;
  onChangeSwitch?: any;
  alert?: () => ReactNode;
  name: string;
  headerTitle: ReactNode;
  hideExpand?: boolean;
  secondButton?: ReactNode;
  hideEdit?: boolean;
  hideActionButtons?: boolean;
  hideCollapseAll?: boolean;
  showBackButton?: boolean;
  onCloseDrawer?: any;
  title?: ReactNode;
};

export function FormHeader(props: FormHeaderProps) {
  const {
    selectedStopover,
    openCollapsePanels,
    buttons,
    editButtonTitle,
    onClickEdit,
    readOnly,
    onChangeSwitch,
    alert,
    name,
    headerTitle,
    hideExpand,
    secondButton,
    hideEdit,
    hideActionButtons,
    hideCollapseAll,
    showBackButton,
    onCloseDrawer,
    title,
    titleButtons,
  } = props;
  return (
    <StopoverHeader>
      <Row align={hideActionButtons && !hideCollapseAll ? 'bottom' : 'middle'}>
        {showBackButton && onCloseDrawer && (
          <Col span={24}>
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              style={{ marginLeft: '-10px', marginBottom: '10px' }}
              onClick={() => (onCloseDrawer ? onCloseDrawer() : null)}
            >
              Voltar
            </Button>
          </Col>
        )}
        <Col span={18}>
          {title || <div style={{ height: '100%' }}>{headerTitle}</div>}
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          {titleButtons && titleButtons()}
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {hideEdit ? (
              hideActionButtons &&
              !hideCollapseAll && (
                <div className="expand">
                  <Switch
                    onChange={onChangeSwitch}
                    checked={openCollapsePanels.length > 0}
                  />
                  <span className="switchLabel">Expandir tudo</span>
                </div>
              )
            ) : (
              <div>
                {selectedStopover && readOnly === true ? (
                  <Button
                    type="primary"
                    icon={<EditFilled />}
                    htmlType="button"
                    onClick={() => onClickEdit()}
                  >
                    {editButtonTitle}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    form={name}
                    key={name}
                    icon={<CheckOutlined />}
                  >
                    Salvar cadastro
                  </Button>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {hideActionButtons ? null : (
        <Row align="middle">
          <Col span={18}>
            {buttons && (
              <Row>
                <Col span={24}>
                  <Space size={10}>{buttons()}</Space>
                </Col>
              </Row>
            )}
          </Col>
          <Col span={6}>
            {hideExpand && secondButton}
            {!hideExpand && (
              <div className="expand">
                <Switch
                  onChange={onChangeSwitch}
                  checked={openCollapsePanels.length > 0}
                />
                <span className="switchLabel">Expandir tudo</span>
              </div>
            )}
          </Col>
        </Row>
      )}

      {alert && alert()}
    </StopoverHeader>
  );
}
