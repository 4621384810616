import { Col } from 'antd';
import { Rule } from 'antd/lib/form';
import { useState } from 'react';

import { FormItem } from '../Form';
import { InputCEP } from '../InputCEP';

type FormItemCEPProps = {
  name: string | string[];
  colSpan?: number;
  required?: boolean;
};

export function FormItemCEP(props: FormItemCEPProps) {
  const { name, colSpan, required } = props;
  const [isEmpty, setIsEmpty] = useState(false);

  function onChangeValue(e: any) {
    setIsEmpty(e.target.value === '_____-___');
  }

  const getRules = () => {
    const rules: Rule[] = [];

    rules.push({ required, message: 'Campo obrigatório' });
    if (!isEmpty) {
      rules.push({
        pattern: /([\d]{5}[-][\d]{3})/,
        message: 'Informe um CEP válido',
      });
    }

    return rules;
  };

  return (
    <Col span={colSpan || 24}>
      <FormItem label="CEP" name={name} rules={getRules()}>
        <InputCEP onChange={onChangeValue} />
      </FormItem>
    </Col>
  );
}
