import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { SearchInput, Select } from '../../../components';
import { RangePickerStylized } from '../../../components/common/datepicker/styles';
import {
  TrackingLogParamsType,
  useTrackingLogQuery,
} from '../../../services/dockingApi';
import { createDateString } from '../../../utils/utils';
import { SectionHeader } from '../styles';
import { VesselsTable } from './vesselsTable';
import { dockingStatusList } from './vesselsTableColumns';

export function VesselsSection() {
  const [QueryPage, setQueryPage] = useState(1);
  const [Filters, setFilters] = useState<TrackingLogParamsType>({
    page: QueryPage,
  });

  const { data: vesselsData, isLoading: isLoadingVesselsData } =
    useTrackingLogQuery(Filters);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, page: QueryPage }));
  }, [QueryPage]);

  function onChangeDatePicker(dates: any) {
    if (dates === null) {
      setFilters((prev) => ({
        vessel_name_or_docking_code: prev.vessel_name_or_docking_code,
      }));
      return;
    }
    let filteredDates = {};
    if (dates && dates.length > 0 && dates[0]) {
      filteredDates = {
        ...filteredDates,
        initial_date_report: createDateString(dates[0], true),
      };
    }
    if (dates && dates.length > 1 && dates[1]) {
      filteredDates = {
        ...filteredDates,
        final_date_report: createDateString(dates[1], true),
      };
    }
    setFilters((prev) => ({ ...prev, ...filteredDates }));
  }

  function onSearch(value: string) {
    if (value !== '') {
      setFilters({ vessel_name_or_docking_code: value });
    } else {
      setFilters((prev) => ({ ...prev, vessel_name_or_docking_code: '' }));
    }
  }

  function onSelectStatus(value: unknown) {
    if (value) {
      setFilters((prev) => ({ ...prev, status: `${value}` }));
    } else {
      setFilters((prev) => ({ ...prev, status: `` }));
    }
  }

  function onSelectDuty(value: unknown) {
    if (value) {
      setFilters((prev) => ({ ...prev, duty: value }));
    } else {
      setFilters((prev) => ({ ...prev, duty: `` }));
    }
  }

  return (
    <>
      <SectionHeader style={{ flexDirection: 'column', gap: '15px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
              marginInlineEnd: '10px',
            }}
          >
            <SearchInput
              size="large"
              allowClear
              placeholder="Pesquisar embarcação/atracação"
              style={{ width: '400px' }}
              onChange={debounce((evt) => onSearch(evt.target.value), 500)}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            marginRight: 'auto',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <span style={{ width: '140px' }}>Filtro por período:{'   '}</span>
            <RangePickerStylized
              allowClear
              onChange={onChangeDatePicker}
              minuteStep={1}
              showTime={false}
              format={['DD/MM/YYYY', 'DD/MM/YYYY']}
              style={{ width: '240px', background: 'var(--neutral_lightest)' }}
            />
          </div>
          <div>
            <Select
              placeholder="status"
              style={{ width: '115px' }}
              $selectorStyle={{
                background: 'var(--neutral_lightest) !important',
              }}
              options={dockingStatusList}
              allowClear
              onChange={(value: unknown) => onSelectStatus(value)}
            />
            <Select
              placeholder="turno"
              style={{ width: '150px', marginLeft: '10px' }}
              $selectorStyle={{
                background: 'var(--neutral_lightest) !important',
              }}
              options={[
                { value: '07:00-19:00', label: '07h às 09h - Diurno' },
                { value: '19:00-07:00', label: '19h às 07h - Noturno' },
              ]}
              allowClear
              onChange={(value: unknown) => onSelectDuty(value)}
            />
          </div>
        </div>
      </SectionHeader>
      <VesselsTable
        dataSource={vesselsData?.results}
        totalItems={vesselsData?.count}
        isLoadingVesselsData={isLoadingVesselsData}
        onChangePagination={setQueryPage}
        queryPage={QueryPage}
      />
    </>
  );
}
