import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Drawer } from '../../../components';
import {
  ShiftType,
  Stopover,
  StoppageType,
  WorkPeriodInpactedType,
} from '../../../types';
import { createDate } from '../../../utils/utils';
import { WorkShiftStoppagesEditDrawer } from '../workShiftStoppagesEditDrawer/WorkShiftStoppagesEditDrawer';
import { StoppagesDrawerHeader } from './stoppagesDrawerHeader';
import { StoppagesTable } from './stoppagesTable';

type StoppagesDrawerProps = {
  visible: boolean;
  onBack: () => void;
  selectedStopover: Stopover;
  setSelectedStopover: Dispatch<SetStateAction<Stopover>>;
};

export function StoppagesDrawer(props: StoppagesDrawerProps) {
  const { visible, onBack, selectedStopover, setSelectedStopover } = props;

  const [isVisibleAddStoppagesDrawer, setIsVisibleAddStoppagesDrawer] =
    useState(false);

  const [selectedStoppage, setSelectedStoppage] = useState<StoppageType>(
    {} as StoppageType
  );

  function onEditStoppage(indexToEdit: number) {
    if (
      selectedStopover.operation.stoppages &&
      selectedStopover.operation.stoppages.length > 0 &&
      indexToEdit >= 0
    ) {
      const stoppage = selectedStopover.operation.stoppages.find(
        ({ id }) => id === indexToEdit
      );
      setSelectedStoppage(stoppage || ({} as StoppageType));
      setIsVisibleAddStoppagesDrawer(true);
    }
  }

  function onAddStoppage() {
    setSelectedStoppage({} as StoppageType);
    setIsVisibleAddStoppagesDrawer(true);
  }

  function onCloseForm() {
    setSelectedStoppage({} as StoppageType);
    setIsVisibleAddStoppagesDrawer(false);
  }

  const stoppagesWithPeriods = useMemo(() => {
    return selectedStopover.operation.stoppages?.map((stoppage) => {
      const workPeriodsInpacted: WorkPeriodInpactedType[] = [];
      for (let i = 0; i < selectedStopover.operation.work_periods.length; i++) {
        const period = selectedStopover.operation.work_periods[i];
        const workShiftsInpacted: ShiftType[] = [];
        for (let j = 0; j < period.work_shifts.length; j++) {
          const shift = period.work_shifts[j];
          const shift_start = createDate(shift.shift_start);
          const shift_end = createDate(shift.shift_end);

          if (shift_start !== null && shift_end !== null) {
            const stoppage_start = createDate(stoppage.start);
            const stoppage_end = createDate(stoppage.end);

            if (stoppage_start !== null && stoppage_end !== null) {
              if (
                (shift_start <= stoppage_start &&
                  shift_end >= stoppage_start) ||
                (shift_start <= stoppage_end && shift_end >= stoppage_end) ||
                (shift_start >= stoppage_start && shift_end <= stoppage_end)
              ) {
                workShiftsInpacted.push(period.work_shifts[j]);
              }
            }
          }
        }
        if (workShiftsInpacted.length > 0) {
          workPeriodsInpacted.push({ index: i + 1, workShiftsInpacted });
        }
      }
      return {
        ...stoppage,
        workPeriodsInpacted,
        workPeriodsIndex: workPeriodsInpacted
          .map((wp) => String(wp.index).padStart(2, '0'))
          .join(', '),
      };
    });
  }, [selectedStopover]);

  return (
    <>
      <Drawer
        visible={visible}
        width={900}
        onClose={() => onBack()}
        closable={false}
        maskClosable={false}
      >
        <StoppagesDrawerHeader onBack={onBack} onAdd={onAddStoppage} />
        <div style={{ padding: '24px 16px' }}>
          <StoppagesTable
            stoppages={stoppagesWithPeriods}
            onEdit={onEditStoppage}
          />
        </div>
      </Drawer>
      <WorkShiftStoppagesEditDrawer
        visible={isVisibleAddStoppagesDrawer}
        setIsVisibleForm={setIsVisibleAddStoppagesDrawer}
        onBack={onCloseForm}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        selectedStoppage={selectedStoppage}
        setSelectedStoppage={setSelectedStoppage}
      />
    </>
  );
}
