import { BarcodeOutlined } from '@ant-design/icons';
import { message } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { debounce } from 'lodash';
import moment from 'moment';
import { useState } from 'react';

import { Button, Modal, ScreenLayout, SearchInput } from '../../components';
import { RangePickerStylized } from '../../components/common/datepicker/styles';
import { useChangePagination } from '../../hooks';
import {
  FilterInvoicesType,
  useDeleteInvoiceMutation,
  useGetInvoicesQuery,
} from '../../services/warehousingVehiclesApi';
import { createDateString } from '../../utils/utils';
import { RegistersTable } from '../registers/registersTable';
import { ReadingInvoicesDrawer } from './readingInvoicesDrawer';
import { columns } from './tableColumns';

const { confirm } = Modal;

export function ReadingInvoices() {
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);

  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();

  const [InvoicesFilters, setInvoicesFilters] = useState<FilterInvoicesType>({
    initial_date_of_read: createDateString(moment(), true),
    final_date_of_read: createDateString(moment(), true),
    page: onChangePagination(),
  } as FilterInvoicesType);

  const { data: invoices, isLoading: isLoadingInvoices } =
    useGetInvoicesQuery(InvoicesFilters);

  const [deleteInvoice] = useDeleteInvoiceMutation();

  function onSearch(value: string) {
    if (value !== '') {
      setInvoicesFilters({ access_key_or_chassis: value });
    } else {
      const currentFilters = {
        ...InvoicesFilters,
        initial_date_of_read: createDateString(moment(), true),
        final_date_of_read: createDateString(moment(), true),
      };
      delete currentFilters.access_key_or_chassis;
      setInvoicesFilters(currentFilters);
    }
  }

  function onChangeDatePicker(dates: any) {
    if (dates === null) {
      setInvoicesFilters((prev) => ({
        access_key_or_chassis: prev.access_key_or_chassis,
      }));
      return;
    }
    let filteredDates = {};
    if (dates && dates.length > 0 && dates[0]) {
      filteredDates = {
        ...filteredDates,
        initial_date_of_read: createDateString(dates[0], true),
      };
    }
    if (dates && dates.length > 1 && dates[1]) {
      filteredDates = {
        ...filteredDates,
        final_date_of_read: createDateString(dates[1], true),
      };
    }
    setInvoicesFilters((prev) => ({ ...prev, ...filteredDates }));
  }

  function onDeleteItem(id: number) {
    confirm({
      title: `Tem certeza que deseja excluir esta nota?`,
      content: 'A nota excluída não poderá ser recuperada',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        deleteInvoice(id).then(() => {
          message.success('Nota excluída');
        });
      },
    });
  }

  return (
    <ScreenLayout title="Armazenamento" subtitle="LEITURA DE NOTAS FISCAIS">
      <ReadingInvoicesDrawer
        visible={isVisibleDrawer}
        setIsVisible={setIsVisibleDrawer}
        width={850}
      />
      <div style={{ background: 'var(--neutral_lightest)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <SearchInput
            size="large"
            allowClear
            placeholder="Pesquisar chave de nota"
            style={{ width: '400px' }}
            onChange={debounce((evt) => onSearch(evt.target.value), 500)}
          />
          <Button
            icon={<BarcodeOutlined />}
            type="primary"
            size="large"
            onClick={() => setIsVisibleDrawer(true)}
          >
            Nova leitura
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: 'auto',
            marginBottom: '20px',
          }}
        >
          <span style={{ marginRight: '10px' }}>
            Filtro por período:{'   '}
          </span>
          <RangePickerStylized
            showTime={false}
            style={{ width: '240px' }}
            format={['DD/MM/YYYY']}
            locale={locale}
            placeholder={['Data', 'Data']}
            allowEmpty={[true, true]}
            value={[
              moment(InvoicesFilters.initial_date_of_read),
              moment(InvoicesFilters.final_date_of_read),
            ]}
            onChange={onChangeDatePicker}
          />
          {(invoices?.count || 0) > 0 && (
            <span style={{ marginLeft: '15px' }}>
              {`${invoices?.count} `} chaves de notas
            </span>
          )}
        </div>
        <RegistersTable
          columns={columns}
          total={invoices?.count}
          itemsPerPage={20}
          onDeleteItem={onDeleteItem}
          dataSource={invoices?.results || []}
          onChangePagination={onChangePagination}
          isLoading={isLoadingInvoices}
          queryPage={queryPage}
        />
      </div>
    </ScreenLayout>
  );
}
