import { Col, Row } from 'antd';
import { useMemo } from 'react';

import { Descriptions, FormatItem, Title } from '../../../../../components';
import {
  OperationCalculusType,
  OperationProductCalculusType,
  OperationProductTypeDescription,
} from '../../../../../types';
import {
  addSuffix,
  convertMinutesToStringHours,
} from '../../../../../utils/utils';
import { DescriptionCard, DescriptionSection } from './styles';

const { Item } = Descriptions;

type ProductResumeDescriptionSectionProps = {
  isLiquidBulk?: boolean;
  product: OperationProductTypeDescription;
  operationProductCalculusData: OperationProductCalculusType;
  operationCalculusData: OperationCalculusType;
};

export function ProductResumeDescriptionSection(
  props: ProductResumeDescriptionSectionProps
) {
  const {
    isLiquidBulk,
    product,
    operationProductCalculusData,
    operationCalculusData,
  } = props;

  const netProduction = useMemo(() => {
    if (
      operationProductCalculusData.total_movimented &&
      operationCalculusData.total_time
    ) {
      return addSuffix(
        (
          (operationProductCalculusData.total_movimented || 0) /
          (operationCalculusData.total_time || 0)
        ).toFixed(2),
        'ton/h'
      );
    }
  }, [operationCalculusData]);

  const grossProduction = useMemo(() => {
    if (
      operationProductCalculusData.total_movimented &&
      operationCalculusData.total_time
    ) {
      const totalTime =
        operationCalculusData.total_time -
        operationCalculusData.total_stoppage_time;
      return addSuffix(
        (
          (operationProductCalculusData.total_movimented || 0) / totalTime
        ).toFixed(2),
        'ton/h'
      );
    }
  }, [operationCalculusData]);

  return (
    <DescriptionSection>
      <Title>RESUMO DO PRODUTO</Title>
      <Row>
        <Col span={8}>
          <DescriptionCard>
            <Title>PROGRESSO TOTAL</Title>
            <Descriptions column={1}>
              <Item label="Total manifestado">
                <FormatItem>
                  {operationProductCalculusData.total_weight} ton
                </FormatItem>
              </Item>
              <Item label="Total movimentado">
                <FormatItem>
                  {operationProductCalculusData.total_movimented} ton
                </FormatItem>
              </Item>
            </Descriptions>
          </DescriptionCard>
        </Col>
        <Col span={8}>
          <DescriptionCard>
            <Title>PRODUÇÃO POR PERÍODO</Title>
            <Descriptions column={1}>
              {product.periodProduction?.map((workPeriod, index) => (
                <Item key={index} label={`Período ${index + 1}`}>
                  <FormatItem>{workPeriod.sum} ton</FormatItem>
                </Item>
              ))}
            </Descriptions>
          </DescriptionCard>
        </Col>
        {/* <Col span={8}>
          <DescriptionCard>
            <Title>TEMPOS</Title>
            <Descriptions column={1}>
              <Item label="Início do primeiro terno">
                <FormatItem>{product.first_gang_start}</FormatItem>
              </Item>
              <Item label="Fim do último terno">
                <FormatItem>{product.last_gang_finish}</FormatItem>
              </Item>
            </Descriptions>
          </DescriptionCard>
        </Col> */}
      </Row>
      <Row>
        <DescriptionCard>
          <Title>TOTAIS</Title>
          <Descriptions column={5}>
            <Item label="Duração da operação">
              <FormatItem>
                {convertMinutesToStringHours(operationCalculusData.total_time)}
              </FormatItem>
            </Item>
            <Item label="Horas operacionais">
              <FormatItem>
                {operationCalculusData?.total_time &&
                  convertMinutesToStringHours(
                    (operationCalculusData?.total_time || 0) -
                      (operationCalculusData?.total_stoppage_time || 0)
                  )}
              </FormatItem>
            </Item>
            {/* <Item label="Horas paralisadas">
              <FormatItem>
                {operationProductCalculusData?.non_working_hours} ({product.total_stopagges}{' '}
                Paralisações)
              </FormatItem>
            </Item> */}
            <Item label="Produtiv. líquida">
              <FormatItem>{netProduction}</FormatItem>
            </Item>
            <Item label="Produtiv. bruta">
              <FormatItem>{grossProduction}</FormatItem>
            </Item>
          </Descriptions>
        </DescriptionCard>
      </Row>
    </DescriptionSection>
  );
}
