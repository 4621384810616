import { Duration } from 'moment';

export function berthingLabel(
  berthingDuration: Duration | null,
  purpose: string
) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div style={{ display: 'inline-block' }}>
        {purpose === 'DOCK_IN_BERTH'
          ? 'Previsão de estadia em berço'
          : 'Previsão de estadia no fundeio'}
      </div>
      {berthingDuration && (
        <div
          style={{
            display: 'inline-block',
            color: 'var(--green-sea_medium)',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          {berthingDuration.months() > 0 && (
            <>
              {berthingDuration.months()}{' '}
              {berthingDuration.months() > 1 ? 'meses' : 'mês'}{' '}
              {berthingDuration.days() > 0 && ', '}
            </>
          )}
          {berthingDuration.days() > 0 && (
            <>
              {berthingDuration.days()} dia(s){' '}
              {berthingDuration.hours() > 0 && ', '}
            </>
          )}
          {berthingDuration.hours() > 0 && (
            <>
              {' '}
              {berthingDuration.hours()}h{' '}
              {berthingDuration.minutes() > 0 && ' e '}
            </>
          )}
          {berthingDuration.minutes() > 0 && (
            <> {berthingDuration.minutes()}m</>
          )}
        </div>
      )}
    </div>
  );
}
