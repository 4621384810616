import { formatTrackingLogToDescription } from '../features/reports/vesselsSection/formatTrackingLogToDescription';
import {
  Docking,
  Docking as DockingType,
  GenericResponse,
  TrackingLogType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type DockingExportXmlParamsType = {
  initial_date_report?: string;
  final_date_report?: string;
  list_of_docking_ids?: number[];
};

export type TrackingLogParamsType = {
  initial_date_report?: string;
  final_date_report?: string;
  status?: string;
  vessel_name_or_docking_code?: string;
  page?: number;
};

export const dockingApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getDockings: build.query<Array<DockingType>, void>({
      query: () => {
        return {
          url: `/maritime/dockings`,
          method: 'GET',
          headers: getHeaders,
        };
      },
      transformResponse: (response: { results: Array<DockingType> }) => {
        response.results.forEach((docking: any) => {
          docking.key = docking.id;
        });
        return response.results;
      },
    }),
    getDockingAlerts: build.query<
      Array<any>,
      { id?: number; berthId?: string }
    >({
      query: (arg) => {
        const { id, berthId } = arg;
        return {
          url: `/maritime/dockings/${id}/check-alerts/${berthId}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    getDockingsByDate: build.query<
      { results: Array<any> },
      {
        initial_expected_berthing?: string;
        final_expected_berthing?: string;
        name_or_imo?: string;
        order_by?: string;
      }
    >({
      query: (arg) => {
        return {
          url: `/maritime/dockings-window`,
          method: 'GET',
          headers: getHeaders,
          params: arg,
        };
      },
      transformResponse: (response: { results: Array<any> }) => {
        const transformedResponse = { ...response };
        transformedResponse.results.forEach((element) => {
          if (element.stopover.port_stay_finished !== null) {
            element.docking_window_status = 'CARRIED_OUT';
          }
        });
        return response;
      },
      providesTags: () => [{ type: 'Docking', code: 'docking-window' }],
    }),
    updateDockingWindow: build.mutation<
      any,
      {
        id: number;
        expected_berthing?: string;
        expected_unberthing?: string;
        expected_anchoring?: string;
        expected_unanchoring?: string;
        docking_place?: string;
      }
    >({
      query: ({ id, ...update }) => {
        return {
          url: `/maritime/dockings-window/${id}`,
          method: 'PATCH',
          body: update,
        };
      },
    }),
    updateDocking: build.mutation<any, any>({
      query: (update) => {
        return {
          url: `/maritime/dockings/${update.id}`,
          method: 'PUT',
          body: update,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '1' },
          { type: 'Stopover', code: '3' },
          { type: 'Manoeuvre', code: '1' },
          { type: 'StopoverLog', code: '1' },
          { type: 'Docking', code: 'docking-window' },
        ]),
    }),
    createDocking: build.mutation<any, any>({
      query: (docking) => {
        return {
          url: '/maritime/dockings',
          method: 'POST',
          body: docking,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '1' },
          { type: 'Stopover', code: '3' },
        ]),
    }),
    patchDocking: build.mutation<any, any>({
      query: (docking) => {
        const dockingToSave = { ...docking };
        delete dockingToSave.id;
        return {
          url: `/maritime/dockings/${docking.id}`,
          method: 'PATCH',
          body: dockingToSave,
        };
      },
      invalidatesTags: (_result, error, queryArgs) =>
        queryArgs.notInvalidateTags
          ? []
          : getInvalidatesTags(error, [
              { type: 'StopoverLog', code: '1' },
              { type: 'Stopover', code: '1' },
              { type: 'Stopover', code: '3' },
            ]),
    }),
    cancelDocking: build.mutation<DockingType, { id: number }>({
      query: (data) => {
        const { id } = data;
        return {
          url: `/maritime/dockings/${id}/cancel`,
          method: 'PATCH',
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'StopoverLog', code: '1' },
          { type: 'Stopover', code: result?.stopover_id },
          { type: 'Docking', code: 'docking-window' },
        ]),
    }),
    checkBerthAvailable: build.mutation<
      Docking[],
      { tag: string; date?: string; id?: number }
    >({
      query: (data) => {
        const dataToQuery = { ...data };
        delete dataToQuery.id;
        return {
          url: `/maritime/dockings/${data.id}/check-available`,
          method: 'POST',
          body: dataToQuery,
        };
      },
    }),
    exportXml: build.mutation<string, DockingExportXmlParamsType>({
      query: (body) => {
        return {
          url: '/maritime/dockings/export-xml',
          method: 'PUT',
          body,
          responseHandler: (response) => response.text(),
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'Stopover', code: '5' }]),
    }),
    trackingLog: build.query<
      GenericResponse & { results: TrackingLogType[] },
      void | TrackingLogParamsType
    >({
      query: (params) => {
        return {
          url: '/maritime/dockings/tracking-log',
          method: 'GET',
          params: params || {},
        };
      },
      transformResponse: (
        baseResponse: GenericResponse & { results: TrackingLogType[] }
      ) => {
        return {
          ...baseResponse,
          results: baseResponse.results.map((item, index) => {
            return formatTrackingLogToDescription(item, index);
          }),
        };
      },
    }),
    createDockingCharge: build.mutation<any, number>({
      query: (docking_id) => {
        return {
          url: `/maritime/dockings/${docking_id}/create-charge`,
          method: 'POST',
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetDockingsQuery,
  useGetDockingAlertsQuery,
  useGetDockingsByDateQuery,
  useUpdateDockingWindowMutation,
  useCreateDockingMutation,
  useUpdateDockingMutation,
  usePatchDockingMutation,
  useCancelDockingMutation,
  useCheckBerthAvailableMutation,
  useExportXmlMutation,
  useTrackingLogQuery,
  useCreateDockingChargeMutation,
} = dockingApi;
