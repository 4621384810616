import moment from 'moment';

import { User } from '../../types';
import { formatUserName } from '../../utils/utils';

export function formatUserFormObj(user: User) {
  return {
    ...user,
    groups: user?.groups?.flatMap((group: any) => [group.id]) || [],
    // company: user?.company?.name,
    // role: user?.role?.name,
  };
}

export function formatUserTableObj(user: User) {
  return {
    user: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    },
    groups: user.groups,
    company: user.company,
    role: user.role,
    created_at: moment(user.created_at).format('DD/MM/YYYY'),
    status: user.status,
    key: user.id,
    id: user.id,
    name: formatUserName(user),
  };
}
