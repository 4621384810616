import { Drawer } from '../../components';
import { User } from '../../types';
import { UserForm } from './userForm';

type UserDrawerProps = {
  visible: boolean;
  width: string | number;
  selectedUser?: User | null;
  onClose: () => void;
  onSaveUser: (value: any) => void;
};

export function UserDrawer(props: UserDrawerProps) {
  return (
    <Drawer
      visible={props.visible}
      width={props.width}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <UserForm
        name={
          props.selectedUser
            ? `userForm_${props.selectedUser?.id}`
            : 'newUserForm'
        }
        selectedUser={props.selectedUser}
        readOnly={
          props.selectedUser?.status !== 'DEACTIVATED'
            ? false
            : !!props.selectedUser
        }
        onCloseDrawer={props.onClose}
        onSaveUser={props.onSaveUser}
      />
    </Drawer>
  );
}
