import { Dropdown as DropdownANTD } from 'antd';
import styled from 'styled-components';

export const Dropdown = styled(DropdownANTD)`
  background-color: var(--green-sea_medium);
  padding: 10px;
  color: var(--suape-black);
  font-weight: 700;
  cursor: pointer;

  .ant-dropdown-menu-title-content {
    color: black;
  }
  .ant-dropdown-menu-item {
    color: black;
  }
`;
