import {
  ArrowLeftOutlined,
  CheckOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Switch, Typography } from 'antd';

import {
  Button,
  DockingLogStatus,
  DockingReleaseStatus,
} from '../../../components';
import { DockingLogStatusType, Vessel } from '../../../types';
import { Container } from './styles';

type DockingFormHeaderProps = {
  code?: string;
  selectedVessel?: Vessel;
  showExpandAll?: boolean;
  createFormTitle?: string;
  saveButtonTitle?: string;
  cancelButtonTitle?: string;
  showCancelButton?: boolean;
  formName?: string;
  openCollapsePanels?: string | string[];
  onBack?: () => void;
  onChangeSwitch?: (value: boolean) => void;
  isCreatingNewDocking?: boolean;
  isLoading?: boolean;
  dockingRelease?: 'PENDING' | 'RELEASED_WITH_PENDING' | 'RELEASED';
  dockingStatus?: DockingLogStatusType;
  dockingCode?: string;
  editFormTitle: string;
  onCancelDocking?: (evt: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const { Paragraph } = Typography;

export function DockingFormHeader(props: DockingFormHeaderProps) {
  const {
    code,
    selectedVessel,
    showExpandAll,
    createFormTitle,
    editFormTitle,
    saveButtonTitle,
    cancelButtonTitle,
    showCancelButton,
    formName,
    openCollapsePanels,
    onCancelDocking,
    onBack,
    onChangeSwitch,
    isCreatingNewDocking,
    isLoading,
    dockingStatus,
    dockingCode,
    dockingRelease,
  } = props;
  return (
    <Container>
      <div className="buttons">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '-15px',
          }}
        >
          <div style={{ margin: '10px' }}>
            {onBack && (
              <Button
                type="text"
                disabled={isLoading}
                icon={
                  <ArrowLeftOutlined
                    style={{ color: 'var(--green-sea_dark)', fontSize: '20px' }}
                  />
                }
                onClick={() => onBack()}
              />
            )}
          </div>
          <div>
            <span className="title-stopover-code">{code} </span>
            <div className="title">
              <div className="docking-title">
                {isCreatingNewDocking ? createFormTitle : editFormTitle}
              </div>
              {dockingCode && <div className="docking-id">#{dockingCode}</div>}
              <Paragraph
                ellipsis={{
                  rows: 1,
                  tooltip: `${selectedVessel?.imo} - ${selectedVessel?.name}`,
                }}
                style={{ display: 'inline-block', maxWidth: '200px' }}
              >
                {selectedVessel &&
                  selectedVessel.imo &&
                  selectedVessel.name &&
                  `- ${selectedVessel?.imo} ${selectedVessel?.name}`}
              </Paragraph>
            </div>
          </div>
        </div>
        <div>
          {showCancelButton && onCancelDocking && (
            <Button
              danger
              disabled={isLoading}
              style={{ marginRight: '15px' }}
              size="large"
              onClick={(evt) => onCancelDocking(evt)}
            >
              Cancelar atracação
            </Button>
          )}

          <Button
            type="primary"
            disabled={isLoading}
            icon={isLoading ? <LoadingOutlined /> : <CheckOutlined />}
            htmlType="submit"
            size="large"
            form={formName}
            key={formName}
          >
            {saveButtonTitle}
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginLeft: '35px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gridGap: '10px' }}>
          {!isCreatingNewDocking && (
            <DockingLogStatus dockingLogStatus={dockingStatus} />
          )}
          {dockingRelease && (
            <DockingReleaseStatus dockingRelease={dockingRelease} />
          )}
        </div>
        {showExpandAll && (
          <div className="expand">
            <Switch
              defaultChecked
              onChange={onChangeSwitch}
              checked={(openCollapsePanels || []).length > 0}
            />
            <span className="switchLabel">Exibir tudo</span>
          </div>
        )}
      </div>
    </Container>
  );
}
