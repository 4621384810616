import { Modal as ANTDModal } from 'antd';
import styled from 'styled-components';

type ModalProps = {
  paddingBody?: string;
};

export const Modal = styled(ANTDModal)<ModalProps>`
  & .ant-modal-content {
    padding-top: 15px;
  }
  & .ant-modal-body {
    padding: ${(props) =>
      props.paddingBody ? `${props.paddingBody}px` : '24px'};
  }
  & .ant-modal-header {
    border-bottom: 0px;
    & .ant-modal-title {
      font-size: 20px;
      font-weight: bold;
      color: #262626;
    }
  }
  &.TOSDangerModal .ant-modal-header {
    border-left: 10px solid var(--error-medium);
    & .ant-modal-title {
      text-transform: uppercase;
    }
    & span.anticon {
      color: var(--error-medium);
    }
  }
  &.TOSDangerModal .ant-modal-footer {
    border-top: 0px;
    & .ant-btn-primary {
      font-weight: bold;
      border: 1px solid var(--error-medium) !important;
      color: red !important;
      background-color: #fff;
      transition: 0.3s;
      & span {
        color: var(--error-medium);
      }
      &[disabled] {
        background: var(--neutral_lighter);
        border: 1px solid var(--neutral_lighter) !important;
        cursor: default;
        &:hover {
          background: var(--neutral_lighter);
        }
        & span {
          color: var(--neutral_light);
        }
      }
    }
  }
  &.TOSWarningModal .ant-modal-header {
    border-left: 10px solid var(--warning-medium);
    & .ant-modal-title {
      text-transform: uppercase;
    }
    & span.anticon {
      color: var(--warning-medium);
    }
  }
  &.TOSPrimaryModal .ant-modal-header {
    border-left: 10px solid var(--green-sea_medium);
    & span.anticon {
      color: var(--green-sea_medium);
    }
  }
  & .ant-modal-footer {
    border-top: 0px;
    & .ant-btn-primary {
      font-weight: bold;
      border: 0px !important;
      border: 2px solid var(--green-sea_medium);
      & span {
        color: #000;
      }
      &[disabled] {
        background: var(--neutral_lighter);
        border: 0px;
        cursor: default;
        &:hover {
          background: var(--neutral_lighter);
        }
        & span {
          color: var(--neutral_light);
        }
      }
    }
    & .ant-btn-default {
      color: var(--ant-primary-color);
      border-color: transparent;
      background: transparent;
      box-shadow: none;
      font-weight: 400;
      font-weight: bold;
      & span {
        color: #000;
      }
    }
    &[disabled] {
      background: transparent;
    }
  }
`;

const { confirm } = Modal;

export function showConfirm(props: any) {
  confirm({
    title: props.title,
    content: props.content,
    onOk() {
      console.log('OK');
    },
    onCancel() {
      console.log('Cancel');
    },
    icon: props.icon || '',
  });
}
