import { GenericQueryParamsType, GenericResponse, PilotType } from '../types';
import { getHeaders, tosApi } from './tosApi';

type PilotQueryParamsType = GenericQueryParamsType & {
  company_id?: number | string | undefined;
  function?: string;
};

export const pilotageApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getPilots: build.query<
      GenericResponse & { results: Array<PilotType> },
      PilotQueryParamsType | void
    >({
      query: (params: GenericQueryParamsType) => {
        return {
          url: `/maritime/pilotage`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'Pilot', code: '2' }],
    }),
    getPilot: build.query<{ results: Array<PilotType> }, { name?: string }>({
      query: (arg) => {
        const { name } = arg;
        return {
          url: '/maritime/pilotage',
          method: 'GET',
          headers: getHeaders,
          params: { name },
        };
      },
    }),
    createPilot: build.mutation<PilotType, PilotType>({
      query: (pilot) => {
        return {
          url: `/maritime/pilotage`,
          method: 'POST',
          body: pilot,
        };
      },
      invalidatesTags: () => [{ type: 'Pilot', code: '2' }],
    }),
    updatePilot: build.mutation<PilotType, PilotType>({
      query: (pilot) => {
        return {
          url: `/maritime/pilotage/${pilot.id}`,
          method: 'PUT',
          body: pilot,
        };
      },
      invalidatesTags: () => [{ type: 'Pilot', code: '2' }],
    }),
    deletePilot: build.mutation<PilotType, number>({
      query: (pilotId) => {
        return {
          url: `/maritime/pilotage/${pilotId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetPilotsQuery,
  useLazyGetPilotsQuery,
  useGetPilotQuery,
  useCreatePilotMutation,
  useUpdatePilotMutation,
  useDeletePilotMutation,
} = pilotageApi;
