import { Row, message } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { useMemo } from 'react';

import {
  Collapse,
  Form,
  FormItemInputNumber,
  GenericDrawerHeader,
  Title,
} from '../../../components';
import {
  useUpdateCargoStorageMerchantTotalMovimentedMutation,
  useUpdateLinesMovimentationMutation,
} from '../../../services/movimentationApi';
import { OperationalOperationType, Stopover } from '../../../types';
import { OperationLineDescription } from '../operationLineDescripion';
import {
  formatCargoStorageMerchantLineMovimentationToSave,
  formatOperationLineMovimentationToSave,
} from './formatters';

type LiquidBulkOperationMovimentationProps = {
  operation?: OperationalOperationType;
  onCloseDrawer?: (show: boolean) => void;
  selectedStopover?: Stopover;
};

const { Panel } = Collapse;

export function LiquidBulkOperationMovimentation(
  props: LiquidBulkOperationMovimentationProps
) {
  const { operation, onCloseDrawer, selectedStopover } = props;

  const [form] = Form.useForm();

  const [
    updateLinesMovimentations,
    { isLoading: isLoadingUpdateMovimentations },
  ] = useUpdateLinesMovimentationMutation();
  const [updateCargoStorageMerchantTotalMovimented] =
    useUpdateCargoStorageMerchantTotalMovimentedMutation();

  const totalOperated = useMemo(() => {
    const total2 = operation?.operation_lines?.flatMap(
      (operationLine) => operationLine.movimentation
    );
    const total = total2?.flat(1).reduce((previous, current) => {
      if (current) {
        previous += current.gross_flow || 0;
      }
      return previous;
    }, 0);
    if (total && total > 0) {
      return `Total operado: ${total} ton`;
    }
    return 'Não operado';
  }, [operation]);

  function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        updateLinesMovimentations({
          movimentations: formatOperationLineMovimentationToSave(
            values.operationLines
          ),
          operation_id: operation?.id,
        }).then(() => {
          updateCargoStorageMerchantTotalMovimented(
            formatCargoStorageMerchantLineMovimentationToSave(
              values.operationLines
            )
          );
        });
        // console.log('form', values.operationLines);
        // console.log(
        //   formatOperationLineMovimentationToSave(values.operationLines)
        // );
        // console.log(
        //   formatCargoStorageMerchantLineMovimentationToSave(
        //     values.operationLines
        //   )
        // );
      })
      .finally(() => {
        message.success('Movimentação atualizada com sucesso!', 5);
        // closeDrawer();
      });
  }

  function closeDrawer() {
    if (onCloseDrawer) {
      onCloseDrawer(false);
    }
  }

  function getHeader() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <strong style={{ fontSize: '16px' }}>Registrar movimentação</strong>
        <span style={{ fontWeight: '400', color: 'var(--neutral_medium)' }}>
          {totalOperated}
        </span>
      </div>
    );
  }

  function getHeaderOperationLine(
    lineName: string,
    operationProductId: number
  ) {
    const operationProductCargoTypeName =
      operation?.items?.find((item) => item.id === operationProductId)
        ?.cargo_type?.name || 'Produto não informado';
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'baseline',
        }}
      >
        <b>{lineName}</b>
        <span
          style={{
            color: 'var(--neutral_medium)',
            fontWeight: '400',
            fontSize: '14px',
          }}
        >
          {operationProductCargoTypeName}
        </span>
      </div>
    );
  }

  // console.log('initial values', { operationLines: operation?.operation_lines });
  console.log(selectedStopover);

  return (
    <Form
      form={form}
      name="movimentation"
      initialValues={{ operationLines: operation?.operation_lines }}
      onFinish={handleFormSubmit}
      layout="vertical"
    >
      <GenericDrawerHeader
        title={getHeader()}
        formName="movimentation"
        showBackButton
        onBack={closeDrawer}
        isLoadingSave={isLoadingUpdateMovimentations}
      />
      <FormList name="operationLines">
        {(fields) => (
          <Collapse expandIconPosition="end" destroyInactivePanel={false}>
            {fields.map((field) => {
              const { key } = field;
              const keyStr = String(key);
              return (
                <Panel
                  header={getHeaderOperationLine(
                    form.getFieldValue([
                      'operationLines',
                      keyStr,
                      'line',
                      'name',
                    ]),
                    form.getFieldValue([
                      'operationLines',
                      keyStr,
                      'operation_product_id',
                    ])
                  )}
                  key={`operation_line_${keyStr}`}
                  forceRender
                >
                  <OperationLineDescription
                    operationLine={form.getFieldValue([
                      'operationLines',
                      keyStr,
                    ])}
                    hideProductAndMovimentation
                  />
                  {selectedStopover?.cargos_storage_merchant?.map(
                    (cargoStorageMerchant, index) => {
                      return (
                        <Row gutter={16}>
                          <Title>
                            CE {cargoStorageMerchant.merchant_ce__code} - NCM{' '}
                            {cargoStorageMerchant.ncm_code}{' '}
                            {cargoStorageMerchant.ncm_description}
                          </Title>
                          <FormItemInputNumber
                            colSpan={12}
                            name={[
                              keyStr,
                              'movimentation',
                              index,
                              'gross_flow',
                            ]}
                            label="Peso movimentado"
                            addonAfter="ton"
                          />
                          <Form.Item
                            name={[keyStr, 'movimentation', index, 'id']}
                            noStyle
                          />
                          {/* <Form.Item name={[keyStr, 'id']} noStyle /> */}
                          {/* <Form.Item
                            name={[keyStr, 'movimentation', 'id']}
                            noStyle
                          /> */}
                          <Form.Item
                            name={[
                              keyStr,
                              'movimentation',
                              index,
                              'cargo_storage_merchant_id',
                            ]}
                            initialValue={cargoStorageMerchant.id}
                            noStyle
                          />
                        </Row>
                      );
                    }
                  )}
                </Panel>
              );
            })}
          </Collapse>
        )}
      </FormList>
    </Form>
  );
}
