import { EditFilled } from '@ant-design/icons';

import { Button } from '../../components';
import { membersCountRenderer } from './membersCountRenderer';
import { permissionNameRenderer } from './permissionNameRenderer';

export function columns(onSelectGroup: (record: any) => void) {
  return [
    {
      title: <div className="show-amount">Grupos</div>,
      dataIndex: 'name',
      key: 'title',
      render: (text: string) => permissionNameRenderer(text),
      sorter: {
        compare: (a: any, b: any) => a.name.localeCompare(b.name),
        multiple: 1,
      },
      showSorterTooltip: false,
    },
    {
      title: 'Membros deste grupo podem:',
      dataIndex: 'description',
      key: 'description',
      render: (description: 'string') => <span>{description}</span>,
    },
    {
      title: 'Membros',
      dataIndex: 'users_count',
      key: 'users_count',
      render: (text: string) => membersCountRenderer(text),
      sorter: (a: any, b: any) => a.users_count - b.users_count,
      showSorterTooltip: false,
    },
    {
      width: '10%',
      render: (_text: any, record: any) => {
        return (
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => onSelectGroup(record)}
            style={{ float: 'right' }}
          />
        );
      },
    },
  ];
}
