import { Drawer } from '../../../components';
import { Stopover as StopoverType } from '../../../types';
import { BreakdownOccurenceForm } from './breakdownOccurenceForm';

type BreakdownOccurrenceDrawerProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  selectedStopover: StopoverType;
};

export function BreakdownOccurrenceDrawer(
  props: BreakdownOccurrenceDrawerProps
) {
  const { isVisible, setIsVisible, selectedStopover } = props;

  return (
    <Drawer
      closable={false}
      maskClosable={false}
      destroyOnClose
      width={800}
      visible={isVisible}
    >
      <BreakdownOccurenceForm
        setIsVisible={setIsVisible}
        selectedStopover={selectedStopover}
      />
    </Drawer>
  );
}
