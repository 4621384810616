import { WarningFilled } from '@ant-design/icons';
import { SetStateAction } from 'react';

import { Button, Modal } from '../../../components';
import { createDateStringPtBr } from '../../../utils/utils';

type ModalExpectedArrivalPspDifferent = {
  showModalExpectedArrivalPspDifferent: boolean;
  setShowModalExpectedArrivalPspDifferent: (
    value: SetStateAction<boolean>
  ) => void;
  setPspExpectedArrivalInStopoverForm: () => void;
  pspExpectedArrival: string | undefined;
  tosExpectedArrival: string | undefined;
};

export function ModalExpectedArrivalPspDifferent(
  props: ModalExpectedArrivalPspDifferent
) {
  const {
    showModalExpectedArrivalPspDifferent,
    setShowModalExpectedArrivalPspDifferent,
    setPspExpectedArrivalInStopoverForm,
    pspExpectedArrival,
    tosExpectedArrival,
  } = props;
  return (
    <Modal
      visible={showModalExpectedArrivalPspDifferent}
      className="TOSWarningModal"
      closable
      onCancel={() => setShowModalExpectedArrivalPspDifferent(false)}
      destroyOnClose
      title={
        <>
          <WarningFilled /> Atenção
        </>
      }
      footer={
        <>
          <Button
            danger
            onClick={() => setShowModalExpectedArrivalPspDifferent(false)}
          >
            Manter assim mesmo
          </Button>
          <Button type="primary" onClick={setPspExpectedArrivalInStopoverForm}>
            Sobrescrever
          </Button>
        </>
      }
    >
      {pspExpectedArrival !== '' && (
        <div>
          <span>
            A Previsão de chegada (ETA) registrada diverge da que está
            cadastrada para o DUV informado.
          </span>
          <br />
          <span>
            TOS: Previsão de chegada (ETA):
            {createDateStringPtBr(tosExpectedArrival)}
          </span>
          <br />
          <span>
            DUV: Previsão de chegada (ETA):
            {createDateStringPtBr(pspExpectedArrival)}
          </span>
          <br />
          <span>Deseja sobrescrever?</span>
        </div>
      )}
    </Modal>
  );
}
