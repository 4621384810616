import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Alert, Divider } from 'antd';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Button,
  Collapse,
  Descriptions,
  DockingLogStatus,
  FinancialOfficersTableDescription,
  FormatItem,
  InnerCollapse,
  Title,
} from '../../../components';
import { panelHeaderTitle } from '../../../components/common/utils';
import { Docking as DockingType, DockingTypeDescription } from '../../../types';
import { formatToDescription } from './formatDockingToDescription';
import { ProductDescription } from './productDescription';
import { TitleEdit } from './styles';

const { Item } = Descriptions;
const { Panel } = Collapse;

type DockingDescriptionProps = {
  selectedDockings?: DockingType[] | null;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  onClickEdit: (evt: any, indexToEdit: number) => void;
  setIsVisibleAnchoringDrawer: (value: boolean) => void;
  setIsDockingDrawerVisible: (value: boolean) => void;
  screen: 'STOPOVER' | 'STAYMENT' | 'AGENT_AREA';
  isDockingDrawerVisible: boolean;
  blockAddDocking?: boolean;
  setOpenCollapsePanels: Dispatch<SetStateAction<string | string[]>>;
};

export function DockingDescription(props: DockingDescriptionProps) {
  const {
    activeKey,
    onOpenPanel,
    selectedDockings,
    onClickEdit,
    setIsVisibleAnchoringDrawer,
    setIsDockingDrawerVisible,
    screen,
    isDockingDrawerVisible,
    blockAddDocking,
    setOpenCollapsePanels,
  } = props;
  const [dockingsDescription, setDockingsDescription] = useState<
    DockingTypeDescription[]
  >([]);

  useEffect(() => {
    setDockingsDescription(formatToDescription(selectedDockings));
  }, [selectedDockings, isDockingDrawerVisible]);

  function dockingCollapseHeader(index: number) {
    if (selectedDockings === null || selectedDockings === undefined) {
      return;
    }
    const headerTitle =
      !isEmpty(selectedDockings) &&
      (selectedDockings[index].purpose === 'ANCHORING_SERVICES' ||
        selectedDockings[index].purpose === 'ANCHORING_WAIT')
        ? 'Fundeio'
        : `Atracação ${index + 1}`;
    return (
      <div className="docking-header-title">
        <span className="docking-title">{`${headerTitle}`}</span>
        <span className="docking-code">
          {!isEmpty(selectedDockings) &&
            selectedDockings[index].code &&
            `#${selectedDockings[index].code}`}
        </span>
        <DockingLogStatus dockingLogStatus={selectedDockings[index].status} />
      </div>
    );
  }

  function getPanelHeader() {
    if (screen === 'STAYMENT') {
      return `Atracações e fundeios 0/${dockingsDescription.length}`;
    }
    return 'Atracações e fundeios previstos';
  }

  return (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel forceRender header={getPanelHeader()} key="dockings">
        {!blockAddDocking && (
          <>
            <Button
              style={{ marginInlineEnd: '10px' }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsDockingDrawerVisible(true)}
            >
              Programar atracação
            </Button>
            <Button
              icon={<PlusOutlined />}
              onClick={() => setIsVisibleAnchoringDrawer(true)}
            >
              Programar fundeio
            </Button>
          </>
        )}
        <InnerCollapse expandIconPosition="end" style={{ marginTop: '16px' }}>
          {dockingsDescription?.map((docking, index) => {
            return (
              <Panel
                forceRender
                header={dockingCollapseHeader(index)}
                extra={
                  selectedDockings &&
                  selectedDockings[index].status !== 'CANCELED' &&
                  panelHeaderTitle(docking.updated_at, onClickEdit, index)
                }
                key={`docking${index}`}
              >
                <div style={{ padding: '16px' }}>
                  {(docking.purpose_id === 'ANCHORING_SERVICES' ||
                    docking.purpose_id === 'ANCHORING_WAIT') && (
                    <Descriptions column={3}>
                      <Item label="Fundeadouro">
                        <FormatItem>{docking.docking_place}</FormatItem>
                      </Item>
                      <Item label="Finalidade" span={2}>
                        <FormatItem>{docking.purpose}</FormatItem>
                      </Item>
                      {screen === 'STAYMENT' && (
                        <Item label="Período em fundeio" span={3}>
                          <div>
                            <FormatItem>{docking.start_anchoring}</FormatItem>
                            <span> até </span>
                            <FormatItem>{docking.end_anchoring}</FormatItem>
                          </div>
                        </Item>
                      )}
                      {screen === 'STOPOVER' && (
                        <Item label="Previsão de estadia no fundeio" span={3}>
                          <div>
                            <FormatItem>
                              {docking.expected_anchoring}
                            </FormatItem>
                            <span> até </span>
                            <FormatItem>
                              {docking.expected_unanchoring}
                            </FormatItem>
                          </div>
                        </Item>
                      )}
                    </Descriptions>
                  )}
                  {docking.purpose_id === 'DOCK_IN_BERTH' && (
                    <>
                      {screen !== 'AGENT_AREA' && (
                        <>
                          <Descriptions
                            style={{ display: 'flex', marginBlockEnd: '-5px' }}
                          >
                            <Item label="Finalidade da atracação" span={2}>
                              <FormatItem>{docking.docking_goal}</FormatItem>
                            </Item>
                          </Descriptions>
                          <Divider />
                          <Title>OPERAÇÕES</Title>
                          <Descriptions column={3}>
                            <Item label="Natureza da carga" span={2}>
                              <FormatItem>
                                {docking.operation_summary?.nature_of_cargo}
                              </FormatItem>
                            </Item>
                            <Item label="Haverá inspeção?" span={1}>
                              <FormatItem>
                                {docking.operation_summary?.inspection}
                              </FormatItem>
                            </Item>
                            {screen === 'STAYMENT' && (
                              <>
                                <Item
                                  label="Previsão de todas operações"
                                  span={3}
                                >
                                  <div>
                                    <FormatItem>
                                      {
                                        docking.operation_summary
                                          ?.start_expected_operation
                                      }
                                    </FormatItem>
                                    <span> até </span>
                                    <FormatItem>
                                      {
                                        docking.operation_summary
                                          ?.end_expected_operation
                                      }
                                    </FormatItem>
                                  </div>
                                </Item>
                                <Item
                                  label="Período real de todas operações"
                                  span={3}
                                >
                                  <div>
                                    <FormatItem>
                                      {
                                        docking.operation_summary
                                          ?.start_real_time_of_operation
                                      }
                                    </FormatItem>
                                    <span> até </span>
                                    <FormatItem>
                                      {
                                        docking.operation_summary
                                          ?.end_real_time_of_operation
                                      }
                                    </FormatItem>
                                  </div>
                                </Item>
                              </>
                            )}
                          </Descriptions>
                        </>
                      )}
                      {screen !== 'AGENT_AREA' &&
                        docking.products &&
                        docking.products.length > 0 && (
                          <ProductDescription
                            products={docking.products}
                            screen={screen}
                          />
                        )}
                      {screen === 'AGENT_AREA' && (
                        <Alert
                          message={
                            <Descriptions column={2}>
                              <Item label="Berço" span={2}>
                                <FormatItem>{docking.docking_place}</FormatItem>
                              </Item>
                              <Item label="Previsão de atracação">
                                <FormatItem>
                                  {docking.expected_berthing}
                                </FormatItem>
                              </Item>
                              <Item label="Previsão de desatracação">
                                <FormatItem>
                                  {docking.expected_unberthing}
                                </FormatItem>
                              </Item>
                            </Descriptions>
                          }
                          type="info"
                        />
                      )}
                      <Divider />
                      <TitleEdit>
                        <Title>DADOS GERAIS</Title>
                        {screen === 'AGENT_AREA' && (
                          <Button
                            type="primary"
                            icon={<EditFilled />}
                            onClick={(e) => {
                              setOpenCollapsePanels('generalData');
                              onClickEdit(e, index);
                            }}
                          >
                            Editar
                          </Button>
                        )}
                      </TitleEdit>
                      {screen === 'AGENT_AREA' && (
                        <Descriptions column={2}>
                          <Item label="Finalidade da atracação" span={2}>
                            <FormatItem>{docking.docking_goal}</FormatItem>
                          </Item>
                          <Item label="Posição da atracação">
                            <FormatItem>{docking.position}</FormatItem>
                          </Item>
                          <Item label="Embarcação de apoio?">
                            <FormatItem>{docking.is_support_vessel}</FormatItem>
                          </Item>
                          <Item label="Atracação à contrabordo?">
                            <FormatItem>{docking.docking_alongside}</FormatItem>
                          </Item>
                          <Item label="Atracação em linha?">
                            <FormatItem>{docking.docking_in_line}</FormatItem>
                          </Item>
                        </Descriptions>
                      )}
                      <Descriptions column={3}>
                        {screen !== 'AGENT_AREA' && (
                          <>
                            <Item label="Berço" span={1}>
                              <FormatItem>{docking.docking_place}</FormatItem>
                            </Item>
                            <Item label="Atracação em linha?" span={2}>
                              <FormatItem>{docking.docking_in_line}</FormatItem>
                            </Item>
                            <Item label="Posição da atracação" span={1}>
                              <FormatItem>{docking.position}</FormatItem>
                            </Item>
                            <Item label="Atracação à contrabordo?" span={1}>
                              <FormatItem>
                                {docking.docking_alongside}
                              </FormatItem>
                            </Item>
                            <Item label="É embarcação de apoio?" span={1}>
                              <FormatItem>
                                {docking.is_support_vessel}
                              </FormatItem>
                            </Item>
                          </>
                        )}
                        {screen === 'AGENT_AREA' && (
                          <Item label="Duração da atracação" span={3}>
                            <div>
                              <FormatItem>{docking.start_tied}</FormatItem>
                              <span> até </span>
                              <FormatItem>{docking.end_untied}</FormatItem>
                            </div>
                          </Item>
                        )}
                        {['STOPOVER', 'AGENT_AREA'].includes(screen) && (
                          <>
                            <Item
                              label="Previsão de prático na atracação"
                              span={3}
                            >
                              <div>
                                <FormatItem>
                                  {docking.pilot_expected_on_board}
                                </FormatItem>
                                <span> até </span>
                                <FormatItem>
                                  {docking.pilot_expected_leaving_on_board}
                                </FormatItem>
                              </div>
                            </Item>
                            <Item
                              label="Previsão de prático na desatracação"
                              span={3}
                            >
                              <div>
                                <FormatItem>
                                  {docking.pilot_expected_leaving_on_board}
                                </FormatItem>
                                <span> até </span>
                                <FormatItem>
                                  {
                                    docking.pilot_expected_leaving_on_board_undocking
                                  }
                                </FormatItem>
                              </div>
                            </Item>
                            <Item label="Previsão de atracação" span={3}>
                              <div>
                                <FormatItem>
                                  {docking.expected_berthing}
                                </FormatItem>
                                <span> até </span>
                                <FormatItem>
                                  {docking.expected_unberthing}
                                </FormatItem>
                              </div>
                            </Item>
                            <Divider />
                          </>
                        )}
                      </Descriptions>

                      {screen === 'STAYMENT' && (
                        <>
                          <Title>MANOBRA DE ATRACAÇÃO</Title>
                          <Descriptions column={3}>
                            <Item label="Manobra confirmada?" span={3}>
                              {docking.docking_manoeuvre?.confirmed}
                            </Item>
                            <Item label="Calado de proa">
                              <FormatItem>
                                {docking.docking_manoeuvre?.bow_draught}
                              </FormatItem>
                            </Item>
                            <Item label="Calado de popa">
                              <FormatItem>
                                {docking.docking_manoeuvre?.stern_draught}
                              </FormatItem>
                            </Item>
                            <Item label="Calado na entrada">
                              <FormatItem>{docking.input_draught}</FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3}>
                            <Item label="DWT na entrada">
                              <FormatItem>{docking.input_dwt}</FormatItem>
                            </Item>
                            <Item label="Manobra acompanhada?">
                              {docking.docking_manoeuvre?.supervised}
                            </Item>
                            <Item label="Plantonista que acomp.">
                              <FormatItem>
                                {
                                  docking.docking_manoeuvre
                                    ?.on_duty_that_accompanied
                                }
                              </FormatItem>
                            </Item>
                            <Item label="Deslocamento na entrada">
                              <FormatItem>
                                {docking.entrance_displacement}
                              </FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Praticagem">
                            <Item label="Empresa" span={1}>
                              <FormatItem>
                                {docking.docking_manoeuvre?.pilotage_company}
                              </FormatItem>
                            </Item>
                            <Item label="Período a bordo" span={2}>
                              <div>
                                <FormatItem>
                                  {
                                    docking.docking_manoeuvre
                                      ?.start_period_on_board
                                  }
                                </FormatItem>
                                <span> até </span>
                                <FormatItem>
                                  {
                                    docking.docking_manoeuvre
                                      ?.end_period_on_board
                                  }
                                </FormatItem>
                              </div>
                            </Item>
                            <Item label="Práticos" span={2}>
                              <FormatItem>
                                {docking.docking_manoeuvre?.pilots}
                              </FormatItem>
                            </Item>
                            <Item label="Praticante" span={1}>
                              <FormatItem>
                                {docking.docking_manoeuvre?.practitioner}
                              </FormatItem>
                            </Item>
                            <Item label="Fez navegação interna?" span={1}>
                              {
                                docking.docking_manoeuvre
                                  ?.did_internal_navigation
                              }
                            </Item>
                            <Item label="Início da navegação interna" span={2}>
                              <FormatItem>
                                {docking.start_of_internal_navigation}
                              </FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Rebocagem">
                            <Item label="Empresa">
                              <FormatItem>
                                {docking.docking_manoeuvre?.towage_company}
                              </FormatItem>
                            </Item>
                            <Item label="Rebocadores" span={2}>
                              <div className="span-neutral-medium">
                                <FormatItem>
                                  {docking.docking_manoeuvre?.bow_tugboat}
                                </FormatItem>
                                <span>(proa), </span>
                                <FormatItem>
                                  {docking.docking_manoeuvre?.stern_tugboat}
                                </FormatItem>
                                <span>(popa), </span>
                                <FormatItem>
                                  {docking.docking_manoeuvre?.standby_tugboat}
                                </FormatItem>
                                <span>(stand by), </span>
                                <FormatItem>
                                  {docking.docking_manoeuvre?.amidship_tugboat}
                                </FormatItem>
                                <span>(meia-nau)</span>
                              </div>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Amarração">
                            <Item label="Empresa">
                              <FormatItem>
                                {docking.docking_manoeuvre?.mooring_company}
                              </FormatItem>
                            </Item>
                            <Item label="Amarrado" span={2}>
                              <div>
                                <FormatItem>{docking.start_tied}</FormatItem>
                                <span> até </span>
                                <FormatItem>{docking.end_tied}</FormatItem>
                              </div>
                            </Item>
                          </Descriptions>
                          <Divider />
                          <Title>CABEÇOS E DEFENSAS</Title>
                          <Descriptions column={3} title="Proa">
                            <Item label="Cabeços" span={2}>
                              <div className="span-neutral-medium">
                                <FormatItem>
                                  {docking.bollard_bow_launcher}
                                </FormatItem>
                                <span>(lançante), </span>
                                <FormatItem>
                                  {docking.bollard_bow_spring}
                                </FormatItem>
                                <span>(spring), </span>
                                <FormatItem>
                                  {docking.bollard_bow_across}
                                </FormatItem>
                                <span>(través)</span>
                              </div>
                            </Item>
                            <Item label="Primeira defensa de apoio">
                              <FormatItem>
                                {docking.first_bow_support_fender}
                              </FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Popa">
                            <Item label="Cabeços" span={2}>
                              <div className="span-neutral-medium">
                                <FormatItem>
                                  {docking.bollard_stern_launcher}
                                </FormatItem>
                                <span>(lançante), </span>
                                <FormatItem>
                                  {docking.bollard_stern_spring}
                                </FormatItem>
                                <span>(spring), </span>
                                <FormatItem>
                                  {docking.bollard_stern_across}
                                </FormatItem>
                                <span>(través)</span>
                              </div>
                            </Item>
                            <Item label="Última defensa de apoio">
                              <FormatItem>
                                {docking.last_stern_support_fender}
                              </FormatItem>
                            </Item>
                          </Descriptions>

                          <Divider />
                          <Title>MANOBRA DE DESATRACAÇÃO</Title>
                          <Descriptions column={3}>
                            <Item label="Manobra confirmada?" span={3}>
                              {docking.undocking_manoeuvre?.confirmed}
                            </Item>
                            <Item label="Calado de proa">
                              <FormatItem>
                                {docking.undocking_manoeuvre?.bow_draught}
                              </FormatItem>
                            </Item>
                            <Item label="Calado de popa">
                              <FormatItem>
                                {docking.undocking_manoeuvre?.stern_draught}
                              </FormatItem>
                            </Item>
                            <Item label="Calado na saída">
                              <FormatItem>{docking.output_draught}</FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3}>
                            <Item label="DWT na saída">
                              <FormatItem>{docking.output_dwt}</FormatItem>
                            </Item>
                            <Item label="Manobra acompanhada?">
                              {docking.undocking_manoeuvre?.supervised}
                            </Item>
                            <Item label="Plantonista que acomp.">
                              <FormatItem>
                                {
                                  docking.undocking_manoeuvre
                                    ?.on_duty_that_accompanied
                                }
                              </FormatItem>
                            </Item>
                            <Item label="Deslocamento na saída">
                              <FormatItem>
                                {docking.exit_displacement}
                              </FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Praticagem">
                            <Item label="Empresa" span={1}>
                              <FormatItem>
                                {docking.undocking_manoeuvre?.pilotage_company}
                              </FormatItem>
                            </Item>
                            <Item label="Período a bordo" span={2}>
                              <div>
                                <FormatItem>
                                  {
                                    docking.undocking_manoeuvre
                                      ?.start_period_on_board
                                  }
                                </FormatItem>
                                <span> até </span>
                                <FormatItem>
                                  {
                                    docking.undocking_manoeuvre
                                      ?.end_period_on_board
                                  }
                                </FormatItem>
                              </div>
                            </Item>
                            <Item label="Práticos" span={2}>
                              <FormatItem>
                                {docking.undocking_manoeuvre?.pilots}
                              </FormatItem>
                            </Item>
                            <Item label="Praticante" span={1}>
                              <FormatItem>
                                {docking.undocking_manoeuvre?.practitioner}
                              </FormatItem>
                            </Item>
                            <Item label="Navio saiu na barra?" span={1}>
                              {
                                docking.undocking_manoeuvre
                                  ?.did_departure_at_port_entrance
                              }
                            </Item>
                            <Item label="Horário de saída na barra" span={2}>
                              <FormatItem>
                                {docking.departure_time_at_port_entrance}
                              </FormatItem>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Rebocagem">
                            <Item label="Empresa" span={1}>
                              <FormatItem>
                                {docking.undocking_manoeuvre?.towage_company}
                              </FormatItem>
                            </Item>
                            <Item label="Rebocadores" span={2}>
                              <div className="span-neutral-medium">
                                <FormatItem>
                                  {docking.undocking_manoeuvre?.bow_tugboat}
                                </FormatItem>
                                <span>(proa), </span>
                                <FormatItem>
                                  {docking.undocking_manoeuvre?.stern_tugboat}
                                </FormatItem>
                                <span>(popa), </span>
                                <FormatItem>
                                  {docking.undocking_manoeuvre?.standby_tugboat}
                                </FormatItem>
                                <span>(stand by), </span>
                                <FormatItem>
                                  {
                                    docking.undocking_manoeuvre
                                      ?.amidship_tugboat
                                  }
                                </FormatItem>
                                <span>(meia-nau)</span>
                              </div>
                            </Item>
                          </Descriptions>
                          <Descriptions column={3} title="Desamarração">
                            <Item label="Empresa" span={1}>
                              <FormatItem>
                                {docking.undocking_manoeuvre?.mooring_company}
                              </FormatItem>
                            </Item>
                            <Item label="Desamarração" span={2}>
                              <div>
                                <FormatItem>{docking.start_untied}</FormatItem>
                                <span> até </span>
                                <FormatItem>{docking.end_untied}</FormatItem>
                              </div>
                            </Item>
                          </Descriptions>
                          <Divider />
                        </>
                      )}
                      <TitleEdit>
                        <Title>RESPONSÁVEIS FINANCEIROS</Title>
                        {screen === 'AGENT_AREA' && (
                          <Button
                            type="primary"
                            icon={<EditFilled />}
                            onClick={(e) => {
                              setOpenCollapsePanels('financialOfficers');
                              onClickEdit(e, index);
                            }}
                          >
                            Editar
                          </Button>
                        )}
                      </TitleEdit>
                      <FinancialOfficersTableDescription
                        financialTables={docking.financial_tables}
                      />
                    </>
                  )}
                </div>
              </Panel>
            );
          })}
        </InnerCollapse>
      </Panel>
    </Collapse>
  );
}
