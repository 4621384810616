import { Stopover } from '../../../types';
import {
  formatDockingsToSave,
  handleDateAndTimeStopover,
} from '../../../utils/formatters';
import { removeSpecialCharacters } from '../../../utils/utils';

export const formatNewStopover = (values: any): Stopover => ({
  ...values,
  last_port: values.last_port?.bi_trigram || null,
  next_port: values.next_port?.bi_trigram || null,
  charterer: values.charterer?.id || null,
  vessel: values.vessel.id,
  shipping_agent: values.shipping_agent?.id || null,
  shipping_agency: values.shipping_agency?.id || null,
  protective_agency: values.protective_agency?.id || null,
  ...handleDateAndTimeStopover(values),
  dockings: formatDockingsToSave(values.dockings || []),
  mercante_scale: values.mercante_scale || null,
  navigation_type: values.navigation_type?.id || null,
  duv: removeSpecialCharacters(values.duv),
});
