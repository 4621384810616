import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip, Layout } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { ReactNode } from 'react';

import { LogoPmsi } from '../../../assets/logoPmis/logoPmis';
import { LogoSuape } from '../../../assets/logoSuape/logoSuape';
import shipIsometric from '../../../assets/shipIsometric/ShipIsometric@3x.png';
import { LeftContent, Container } from './styles';

type LayoutLoginProps = {
  children: ReactNode;
};

export function LayoutLogin(props: LayoutLoginProps) {
  return (
    <Container>
      <Layout>
        <LeftContent>
          <div>
            <LogoPmsi />
            {props.children}
          </div>
          <LogoSuape />
        </LeftContent>
      </Layout>
      <Sider width="50%" style={{ backgroundColor: '#fff' }}>
        <img
          width="100%"
          height="100%"
          src={shipIsometric}
          alt="Embarcações no porto"
        />
        <div style={{ position: 'absolute', right: '30px', bottom: '30px' }}>
          <Tooltip
            placement="left"
            title="Infographic vector created by Upklyak"
            overlayStyle={{ maxWidth: '500px' }}
          >
            <InfoCircleFilled
              style={{ color: 'var(--neutral_light)', marginLeft: '10px' }}
            />
          </Tooltip>
        </div>
      </Sider>
    </Container>
  );
}
