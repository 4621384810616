import { UploadOutlined } from '@ant-design/icons';
import { Alert, message, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Button, Collapse, Modal } from '../../../../components';
import { useUploadManifestMutation } from '../../../../services/stopoverApi';

type ModalImportManifestsProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  stopoverId: number;
};

const { Panel } = Collapse;
const { confirm } = Modal;

const defaultList = [
  {
    uid: '1',
    name: 'csv-conhecimentos-embarque.zip',
  },
  {
    uid: '2',
    name: 'csv-itens-carga.zip',
  },
];

export function ModalImportManifests(props: ModalImportManifestsProps) {
  const { isVisible, setIsVisible, stopoverId } = props;

  const [filesLoading, setFilesLoading] = useState<File[]>([]);
  const [filesUnloading, setFilesUnloading] = useState<File[]>([]);

  const [fileListLoading, setFileListLoading] =
    useState<UploadFile<any>[]>(defaultList);
  const [fileListUnloading, setFileListUnloading] =
    useState<UploadFile<any>[]>(defaultList);

  const [showAlertNameLoading, setShowAlertNameLoading] = useState(false);
  const [showAlertNameUnloading, setShowAlertNameUnloading] = useState(false);

  const [uploadFiles, { isSuccess: isSuccessUploadFiles }] =
    useUploadManifestMutation();

  useEffect(() => {
    if (isSuccessUploadFiles) {
      handleClose();
      message.success('Arquivos importados com sucesso');
    }
  }, [isSuccessUploadFiles]);

  function beforeUploadLoading(file: File) {
    if (
      file.name !== 'csv-conhecimentos-embarque.zip' &&
      file.name !== 'csv-itens-carga.zip'
    ) {
      message.error('O nome do arquivo não corresponde');
      setShowAlertNameLoading(true);
      return false;
    }
    setFilesLoading((prev) => [...prev, file]);
    return false;
  }

  function beforeUploadUnloading(file: File) {
    if (
      file.name !== 'csv-conhecimentos-embarque.zip' &&
      file.name !== 'csv-itens-carga.zip'
    ) {
      message.error('O nome do arquivo não corresponde');
      setShowAlertNameUnloading(true);
      return false;
    }
    setFilesUnloading((prev) => [...prev, file]);
    return false;
  }

  function handleImportManifests() {
    const content = (
      <ul>
        {filesLoading.length === 2 && (
          <li>Será feito upload dos arquivos de carregamento.</li>
        )}
        {filesUnloading.length === 2 && (
          <li>Será feito upload dos arquivos de descarregamento.</li>
        )}
      </ul>
    );

    confirm({
      title: 'Tem certeza que deseja importar os manifestos?',
      width: 500,
      content,
      cancelText: 'Cancelar',
      async onOk() {
        if (filesLoading.length === 2) {
          await uploadFiles({
            files: filesLoading,
            direction: 'LOADING',
            id: stopoverId,
          });
        }
        if (filesUnloading.length === 2) {
          await uploadFiles({
            files: filesUnloading,
            direction: 'UNLOADING',
            id: stopoverId,
          });
        }
      },
    });
  }

  function handleClose() {
    setFilesLoading([]);
    setFilesUnloading([]);
    setIsVisible(false);
    setFileListLoading(defaultList);
    setFileListUnloading(defaultList);
    setShowAlertNameLoading(false);
    setShowAlertNameUnloading(false);
  }

  function onChangeFileListLoading(info: UploadChangeParam<UploadFile<any>>) {
    if (
      info.file.name !== 'csv-conhecimentos-embarque.zip' &&
      info.file.name !== 'csv-itens-carga.zip'
    ) {
      return false;
    }
    if (info.file.status === 'removed') {
      setFileListLoading((prev) =>
        prev.map((item) => {
          if (item.uid === info.file.uid) {
            return { ...item, url: '' };
          }
          return item;
        })
      );
      setFilesLoading((prev) =>
        prev.filter(
          (item: File & { uid?: string }) => item.uid !== info.file.uid
        )
      );
      return;
    }

    setFileListLoading((prev) => {
      return prev.map((prevItem) => {
        if (prevItem.name === info.file.name) {
          return {
            ...prevItem,
            ...info.file,
            status: 'done',
            url: 'tossuape', // apenas para renderizar o status done
          };
        }
        return prevItem;
      });
    });

    if (fileListLoading.every((item) => item.status === 'done')) {
      setShowAlertNameLoading(false);
    }
  }

  function onChangeFileListUnloading(info: UploadChangeParam<UploadFile<any>>) {
    if (
      info.file.name !== 'csv-conhecimentos-embarque.zip' &&
      info.file.name !== 'csv-itens-carga.zip'
    ) {
      return false;
    }

    if (info.file.status === 'removed') {
      setFileListUnloading((prev) =>
        prev.map((item) => {
          if (item.uid === info.file.uid) {
            return { ...item, url: '' };
          }
          return item;
        })
      );
      setFilesUnloading((prev) =>
        prev.filter(
          (item: File & { uid?: string }) => item.uid !== info.file.uid
        )
      );
      return;
    }

    setFileListUnloading((prev) => {
      return prev.map((prevItem) => {
        if (prevItem.name === info.file.name) {
          return {
            ...prevItem,
            ...info.file,
            status: 'done',
            url: 'tossuape', // apenas para renderizar o status done
          };
        }
        return prevItem;
      });
    });

    if (fileListUnloading.every((item) => item.status === 'done')) {
      setShowAlertNameUnloading(false);
    }
  }

  return (
    <Modal
      className="TOSPrimaryModal"
      title="Importar manifestos"
      visible={isVisible}
      cancelText="Voltar"
      onCancel={handleClose}
      okText="Importar manifestos"
      onOk={handleImportManifests}
      okButtonProps={{
        disabled: filesLoading.length !== 2 && filesUnloading.length !== 2,
      }}
      bodyStyle={{ padding: '10px 0px' }}
      width={785}
    >
      <div style={{ padding: '10px' }}>
        <span>
          Caso deseje fazer alterações nos manifestos após a importação, é
          necessário anexar um novo arquivo ou alterar os dados manualmente no
          sistema.
        </span>
      </div>
      <Collapse activeKey={['loading', 'unloading']} expandIconPosition="end">
        <Panel key="loading" header="Carregamento">
          <span>
            Baixe do PSP os manifestos de carga com carregamento para o Porto de
            Suape e anexe aqui os arquivos abaixo:
          </span>
          <Upload
            maxCount={2}
            accept=".zip"
            beforeUpload={beforeUploadLoading}
            fileList={fileListLoading}
            onChange={onChangeFileListLoading}
          >
            <Button icon={<UploadOutlined />} type="primary">
              Anexar arquivos
            </Button>
          </Upload>
          {showAlertNameLoading && (
            <Alert
              style={{ marginTop: '16px' }}
              type="error"
              message="Os arquivos que devem ser anexados são “csv.conhecimentos-embarque.zip” e o ”csv-itens-carga.zip”. Anexe-os."
            />
          )}
        </Panel>
        <Panel key="unloading" header="Descarregamento">
          <span>
            Baixe do PSP os manifestos de carga com descarregamento para o Porto
            de Suape e anexe aqui os arquivos abaixo:
          </span>
          <Upload
            maxCount={2}
            accept=".zip"
            beforeUpload={beforeUploadUnloading}
            fileList={fileListUnloading}
            onChange={onChangeFileListUnloading}
            showUploadList={{
              showDownloadIcon: false,
              showRemoveIcon: true,
              showPreviewIcon: false,
            }}
          >
            <Button icon={<UploadOutlined />} type="primary">
              Anexar arquivos
            </Button>
          </Upload>
          {showAlertNameUnloading && (
            <Alert
              style={{ marginTop: '16px' }}
              type="error"
              message="Os arquivos que devem ser anexados são “csv.conhecimentos-embarque.zip” e o ”csv-itens-carga.zip”. Anexe-os."
            />
          )}
          <Alert
            style={{ marginTop: '16px' }}
            type="warning"
            message="Ao importar um novo manifesto, ocorrerá a substituição de todas as informações de mercadoria e, consequente, perda de qualquer edição feita anteriormente."
          />
        </Panel>
      </Collapse>
    </Modal>
  );
}
