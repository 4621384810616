import { Alert, Col, Row, Space } from 'antd';
import { useEffect, useState } from 'react';

import { Button } from '../../components';
import { CheckList } from '../../components/common/CheckList';
import { useGetStopoverDockingChecklistQuery } from '../../services/stopoverApi';
import { CheckListItem, Document } from '../../types';
import { BaseActionModalProps } from './staymentActions';

type ReleaseDockingModalProps = BaseActionModalProps & {
  onRelease: (releasedWithPendings?: boolean) => void;
  readOnly?: boolean;
};

const releaseDockingConsents = [
  {
    checked: true,
    title: 'Autoridade sanitária',
    name: 'health_authority',
    subItens: [
      {
        title: 'CLP certificado de livre prática',
        name: 'clp',
        checked: false,
      },
    ],
  },
  {
    checked: true,
    title: 'Adm. portuária - Meio Ambiente',
    name: 'port_admin_environment',
  },
  {
    checked: true,
    title: 'Adm. portuária - Provisão',
    name: 'port_admin_provision',
    subItens: [
      {
        title: 'CAA',
        name: 'caa',
        checked: false,
      },
      {
        title: 'Confirmação da fonte pagadora',
        name: 'confirm_payment', // TODO: mudar
        checked: false,
      },
      {
        title: 'Associação à escala mercante',
        name: 'confirm_payment', // TODO: mudar
        checked: true,
      },
      {
        title: 'Manifesto de carga',
        name: 'confirm_payment', // TODO: mudar
        checked: false,
      },
      {
        title: 'Confirmação de DWT de entrada',
        name: 'confirm_payment', // TODO: mudar
        checked: true,
      },
    ],
  },
  {
    checked: true,
    title: 'Adm. portuária - Risco',
    name: 'port_admin_risk',
    subItens: [
      {
        title: 'Declaração de carga perigosa',
        name: 'dangerous', // TODO: mudar
        checked: true,
      },
    ],
  },
  {
    checked: true,
    title: 'Adm. portuária - Segurança',
    name: 'port_admin_safety',
    subItens: [
      {
        title: 'Nível de segurança em que o navio está operando',
        name: 'dangerous', // TODO: mudar
        checked: false,
      },
      {
        title: 'ISSC International Ship Security Certificate',
        name: 'dangerous', // TODO: mudar
        checked: true,
      },
    ],
  },
];

export function ReleaseDockingModal(props: ReleaseDockingModalProps) {
  const {
    setIsVisible,
    onRelease,
    stopover,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
    readOnly,
  } = props;
  const { data: checklist, isLoading } = useGetStopoverDockingChecklistQuery(
    stopover?.id || null
  );
  const [consents, setConsents] = useState<Document[]>([]);
  const [data, setData] = useState<CheckListItem[]>([
    {
      checked: false,
      title: 'Calado de entrada',
      name: 'input_draught',
    },
    {
      checked: false,
      title: 'Confirmação de armazenamento',
      name: 'storage_confirmed',
    },
    { checked: false, title: 'DUV - Documento Único Virtual', name: 'duv' },
    {
      checked: false,
      title: 'Comprimento (LOA)',
      name: 'length',
    },
    { checked: false, title: 'DWT de entrada', name: 'input_dwt' },
    {
      checked: false,
      title: 'Tipo de cabo',
      name: 'dock_line_type',
    },
    {
      checked: false,
      title: 'Deslocamento para entrada',
      name: 'entrance_displacement',
    },
    {
      checked: false,
      title: 'Confirmação dos resp. financeiros',
      name: 'financial_tables_confirmed',
    },
    {
      checked: false,
      title: 'Manifesto de carga',
      name: 'cargo_manifest_data',
    },
  ]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  useEffect(() => {
    const stopoverDocumentsConsents: Document[] = [];
    releaseDockingConsents.forEach((documentConsent) => {
      const stopoverDocument = stopover?.documents?.find(
        (stopoverDocument) => stopoverDocument.name === documentConsent.name
      );
      if (stopoverDocument) {
        stopoverDocumentsConsents.push({
          ...stopoverDocument,
          subItens: documentConsent.subItens,
        });
      }
    });
    setConsents(stopoverDocumentsConsents);
  }, [stopover]);
  useEffect(() => {
    if (!isLoading) {
      const newData: CheckListItem[] = [];
      Object.keys(data).forEach((_item, index) => {
        if (checklist !== undefined) {
          data[index].checked =
            Object.entries(checklist).find(
              (item) => item[0] === data[index].name
            )?.[1] || false;
          newData.push({
            checked: data[index].checked,
            name: data[index].name,
            title: data[index].title,
          });
        }
      });
      setData(newData);
    }
  }, [checklist]);
  useEffect(() => {
    if (
      !consents.find((consent) => !consent.checked) &&
      !data.find((dataItem) => !dataItem.checked)
    ) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  });

  async function releaseDocking() {
    const release = isAllChecked ? 'RELEASED' : 'RELEASED_WITH_PENDING';
    if (updateDocking) {
      updateDocking(
        {
          docking_release: release,
          id: currentDocking?.id,
        },
        onRelease,
        release === 'RELEASED_WITH_PENDING'
      );
    }
  }
  return (
    <>
      {isAllChecked && <b>Todos os requisitos foram preenchidos</b>}
      {!isAllChecked && (
        <b>Todos os requisitos abaixo devem estar preenchidos:</b>
      )}
      <Row style={{ marginTop: '10px' }}>
        <Col span={12}>
          <CheckList
            title="Anuências:"
            items={consents}
            labelField="label"
            isCollapsable
          />
        </Col>
        <Col span={12}>
          <CheckList title="Dados:" items={data} />
        </Col>
      </Row>
      {readOnly && (
        <Alert
          type="error"
          message="Para a liberação da manobra é necessário que o campo “Previsão de prático” seja preenchido."
        />
      )}
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={() => releaseDocking()}
          disabled={readOnly}
          loading={isLoadingUpdateDocking}
        >
          {isAllChecked && 'Liberar'}
          {!isAllChecked && 'Liberar mesmo assim'}
        </Button>
      </Space>
    </>
  );
}
