import { Tabs as ANTDTabs } from 'antd';
import styled from 'styled-components';

export const ModalOperationMerchantCEHeader = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  & .title {
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }
  & button {
    & .ant-btn-text,
    .anticon-check {
      color: black !important;
    }
    & .anticon-delete {
      color: var(--error-medium) !important;
    }
  }
`;

export const StorageCompanyTabs = styled(ANTDTabs)`
  &.ant-tabs-left {
    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--ant-primary-color) !important;
    }
    & .ant-tabs-ink-bar {
      height: 41px !important;
    }
  }
  & .ant-tabs-nav {
    min-width: 250px;
  }
  & .ant-tabs-tab-btn {
    width: 100%;

    & .ant-btn {
      margin-left: 10px;

      & .anticon {
        margin-right: 0px !important;
      }
    }
  }
`;
