import { Form as ANTDForm } from 'antd';
import styled from 'styled-components';

export const Form = styled(ANTDForm)`
  & .ant-form-item-label {
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333337;
    & label {
      font-size: 12px;
      line-height: 1.58;
      cursor: pointer !important;
    }
  }

  & .ant-input-password {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--neutral_light);
    border-radius: 0px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    padding-bottom: 6px;
  }

  &:focus,
  &
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    box-shadow: 0 0 0 transparent;
  }

  &:focus,
  &
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    box-shadow: 0 0 0 transparent;
  }
`;

export const Title = styled.h1`
  padding-top: 90px;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #262626;
`;

export const ButtonForgotPassword = styled.a`
  font-size: 12px;
  border: none;
  background: none;
  font-weight: bold;
  font-stretch: normal;
  color: #000;
  line-height: 2;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: normal;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 36px;
  padding-bottom: 70px;
  height: 40px;
`;
