import { useEffect } from 'react';

import { FormatedStaymentLog } from '../../../types';
import { StaymentLogWrapper, Steps } from './styles';

type StaymentStatusProps = {
  selectedStatus: number;
  setSelectedStatus: (current: number) => void;
  statusList?: FormatedStaymentLog[];
};

export function StaymentStatus(props: StaymentStatusProps) {
  const { selectedStatus, setSelectedStatus, statusList } = props;

  // useEffect(() => {
  //   document
  //     .getElementsByClassName('ant-steps-item-active')[0]
  //     ?.scrollIntoView({
  //       behavior: 'smooth',
  //       inline: 'center',
  //     }); // centraliza o elemento selecionado na div
  // });

  function renderTitle(status: any) {
    return (
      <div className="status-title-container">
        <span className="status-title">{status.name}</span>
        <span className="status-subtitle">#{status.key}</span>
      </div>
    );
  }
  return (
    <StaymentLogWrapper>
      <Steps
        size="small"
        current={selectedStatus}
        onChange={setSelectedStatus}
        progressDot
      >
        {statusList?.map((status) => {
          return <Steps.Step key={status.key} title={renderTitle(status)} />;
        })}
      </Steps>
    </StaymentLogWrapper>
  );
}
