import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Form, Collapse } from '../../../components';
import { useGetBollardsQuery } from '../../../services/bollardApi';
import {
  useCreateBreakdownOccurrenceMutation,
  useDeleteBreakdownOccurrenceMutation,
  useUpdateBreakdownOccurrenceMutation,
} from '../../../services/breakdownOccurrenceApi';
import { useGetFendersQuery } from '../../../services/fenderApi';
import {
  TreeType,
  Docking as DockingType,
  BreakdownOccurrenceType,
} from '../../../types';
import { BreakdownFormItems } from './breakdownFormItems';
import { formatEquipmentsToForm } from './formatEquipmentsToForm';
import { formatBreakdownOccurrenceToSave } from './formatToSave';
import { PanelHeaderTitle } from './panelHeaderTitle';
import { BreakdownOccurenceCollapse } from './styles';

type BreakdownFormProps = {
  index: number;
  dockings: DockingType[] | null | undefined;
  equipments: TreeType[];
  setEquipments: (data: TreeType[]) => void;
  breakdownSelected: BreakdownOccurrenceType;
  setBreakdowns: (value: any) => void;
  stopoverId?: number;
};

const { Panel } = Collapse;

export function BreakdownForm(props: BreakdownFormProps) {
  const {
    index,
    dockings,
    equipments,
    setEquipments,
    breakdownSelected,
    setBreakdowns,
    stopoverId,
  } = props;

  const [form] = Form.useForm();

  const [isChanged, setIsChanged] = useState(false);
  const [updatedAt, setUpdatedAt] = useState<string | null | undefined>(
    breakdownSelected?.updated_at
  );

  const [
    createBreakdown,
    { isSuccess: isSuccessCreateBreakdown, data: createBreakdownData },
  ] = useCreateBreakdownOccurrenceMutation();
  const [
    updateBreakdown,
    { isSuccess: isSuccessUpdateBreakdown, data: updateBreakdownData },
  ] = useUpdateBreakdownOccurrenceMutation();

  const [deleteBreakdown, { isSuccess: isSuccessDeleteBreakdown }] =
    useDeleteBreakdownOccurrenceMutation();

  const { data: fendersData, isLoading: isLoadingFendersData } =
    useGetFendersQuery();
  const { data: bollardsData, isLoading: isLoadingBollardsData } =
    useGetBollardsQuery({ dockingPlace: '' });

  useEffect(() => {
    // spread operator doesnt work to set fields I don't know why
    form.setFieldsValue({
      description: breakdownSelected.description,
      date: breakdownSelected.date,
      id: breakdownSelected.id,
      primary_type: breakdownSelected.primary_type,
      duty: breakdownSelected.duty,
      on_duty: breakdownSelected.on_duty,
      docking: breakdownSelected.docking,
      breakdown_occurrence_type: breakdownSelected.breakdown_occurrence_type,
      shipowner: breakdownSelected.shipowner,
      equipments: breakdownSelected.equipments,
      fenders: breakdownSelected.fenders,
      bollards: breakdownSelected.bollards,
    });
  }, [breakdownSelected]);

  useEffect(() => {
    if (!isLoadingFendersData && !isLoadingBollardsData) {
      setEquipments(
        formatEquipmentsToForm(fendersData?.results, bollardsData?.results)
      );
    }
  }, [isLoadingFendersData, isLoadingBollardsData]);

  useEffect(() => {
    if (isSuccessCreateBreakdown && createBreakdownData) {
      message.success('Avaria criada com sucesso');
      setUpdatedAt(
        moment(createBreakdownData?.updated_at).format('DD/MM/YYYY HH:mm')
      );
    }
  }, [isSuccessCreateBreakdown]);

  useEffect(() => {
    if (isSuccessUpdateBreakdown && updateBreakdownData) {
      message.success('Avaria atualizada com sucesso');
      setUpdatedAt(
        moment(updateBreakdownData?.updated_at).format('DD/MM/YYYY HH:mm')
      );
    }
  }, [isSuccessUpdateBreakdown]);

  useEffect(() => {
    if (isSuccessDeleteBreakdown) {
      message.success('Avaria removida com sucesso');
    }
  }, [isSuccessDeleteBreakdown]);

  function removeItemIndex(selectedIndex: number) {
    setBreakdowns((prevBreakdown: BreakdownOccurrenceType[]) =>
      prevBreakdown.filter((_, i) => i !== selectedIndex)
    );
  }

  function onSaveBreakdown(values: any) {
    values.stopover_id = stopoverId;
    const saveData = formatBreakdownOccurrenceToSave(
      { ...values, primary_type: 'BREAKDOWN' },
      equipments
    );
    setIsChanged(false);

    if (breakdownSelected.id) {
      saveData.id = breakdownSelected.id;
      updateBreakdown(saveData);
    } else {
      createBreakdown(saveData);
      removeItemIndex(index);
    }
  }

  function onRemoveBreakdown(e: any) {
    e.stopPropagation();
    confirm({
      title: 'Deseja realmente remover esta avaria?',
      icon: <ExclamationCircleOutlined />,
      content: 'A avaria removida não poderá ser recuperada',
      onOk() {
        if (breakdownSelected?.id) {
          deleteBreakdown(breakdownSelected.id);
        } else {
          removeItemIndex(index);
        }
      },
    });
  }

  return (
    <Form
      form={form}
      initialValues={breakdownSelected}
      layout="vertical"
      autoComplete="off"
      name={`breakdown_${index}`}
      id={`breakdown_${index}`}
      onValuesChange={() => setIsChanged(true)}
      onFinish={onSaveBreakdown}
    >
      <BreakdownOccurenceCollapse expandIconPosition="end">
        <Panel
          key={index}
          header={
            <PanelHeaderTitle
              title={`Avaria ${index + 1}`}
              isChanged={isChanged}
              onRemove={onRemoveBreakdown}
              updatedAt={updatedAt}
            />
          }
        >
          <BreakdownFormItems
            breakdownSelected={breakdownSelected}
            dockings={dockings}
            equipments={equipments}
          />
        </Panel>
      </BreakdownOccurenceCollapse>
    </Form>
  );
}
