import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Row, message } from 'antd';
import { useState } from 'react';

import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  Button,
  Alert,
  Modal,
  Input,
  Form,
  FormItemDatePicker,
} from '../../components';
import {
  useUpdateLinesMovimentationMutation,
  useUpdateMovimentationMutation,
} from '../../services/movimentationApi';
import { usePatchOperationMutation } from '../../services/operationApi';
import { useCreateOperationLineMutation } from '../../services/operationLinesApi';
import { usePatchOperationProductMutation } from '../../services/operationProductApi';
import { useCreateWorkPeriodMutation } from '../../services/workPeriodApi';
import { NatureOfCargoType, WorkShiftMovimentationType } from '../../types';
import { createDateString, disabledDateAfterToday } from '../../utils/utils';

const { TextArea } = Input;

type ConfirmNewOperationAlertProps = {
  operatorName: string;
  operationProductId: number;
  operationCargoTypeName: string;
  isProvisioned?: boolean;
  operationId: number;
  opProductTotalWeight: number;
  opNatureOfCargo: NatureOfCargoType;
};

const LIQUID_BULK = 2;

export function ConfirmNewOperationAlert(props: ConfirmNewOperationAlertProps) {
  const {
    operatorName,
    operationProductId,
    operationCargoTypeName,
    isProvisioned,
    operationId,
    opProductTotalWeight,
    opNatureOfCargo,
  } = props;

  const [form] = Form.useForm();

  const { id: userId } = useAppSelector((state: RootState) => state.user.user);

  const [isVisibleConfirmOperationModal, setIsVisibleConfirmOperationModal] =
    useState(false);
  const [isVisibleRejectOperationModal, setIsVisibleRejectOperationModal] =
    useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const [patchOperation, { isLoading: isLoadingPatchOperation }] =
    usePatchOperationMutation();

  const [patchOperationProduct, { isLoading: isLoadingPatchOperationProduct }] =
    usePatchOperationProductMutation();

  const [createWorkPeriod, { isLoading: isLoadingCreateWorkPeriod }] =
    useCreateWorkPeriodMutation();
  const [updateMovimentations, { isLoading: isLoadingUpdateMovimentations }] =
    useUpdateMovimentationMutation();

  const [createOperationLine, { isLoading: isLoadingCreateOperationLine }] =
    useCreateOperationLineMutation();
  const [
    updateLinesMovimentations,
    { isLoading: isLoadingUpdateLinesMovimentations },
  ] = useUpdateLinesMovimentationMutation();

  const isLoading =
    isLoadingPatchOperation ||
    isLoadingPatchOperationProduct ||
    isLoadingCreateWorkPeriod ||
    isLoadingUpdateMovimentations ||
    isLoadingCreateOperationLine ||
    isLoadingUpdateLinesMovimentations;

  function handleConfirmOperation() {
    if (isProvisioned) {
      form.validateFields().then(async (values) => {
        if (!values.real_operation_start) {
          message.error('Informe a data de ínicio da operação');
          return;
        }
        Object.keys(values).forEach((key) => {
          values[key] = createDateString(values[key]);
        });
        values.id = operationId;
        const response = await patchOperation(values);
        if ('data' in response) {
          patchOperationProduct({
            id: operationProductId,
            operation_release: 'CONFIRMED',
            user_release: userId,
          }).then(() => {
            // para granel liquido cria linha e movimentação da linha
            // para outras cargas cria periodo
            if (opNatureOfCargo.id === LIQUID_BULK) {
              createOperationLine({
                name: 'PROVISIONADA',
                operation_id: operationId,
                real_pump_start: values.real_operation_start,
                real_pump_finish:
                  values.real_operation_finish || values.real_operation_start,
                real_connection_hour: values.real_operation_start,
                real_desconnection_hour:
                  values.real_operation_finish || values.real_operation_start,
                operation_product_id: operationProductId,
                line: 1,
              }).then(async (response) => {
                if ('data' in response) {
                  const selectedOperationLine = response.data;
                  updateLinesMovimentations({
                    movimentations: [
                      {
                        operation_line_id: selectedOperationLine.id,
                        gross_flow: opProductTotalWeight,
                      },
                    ],
                    operation_id: operationId,
                  });
                }
              });
            } else {
              createWorkPeriod({
                operation_id: operationId,
                start_of_period: values.real_operation_start,
                end_of_period:
                  values.real_operation_finish || values.real_operation_start,
                shift_mode: 'SIX',
                total_stoppages: 0,
                total_cargo_operated: 0,
                worked_time: 0,
                work_shifts: [
                  {
                    shift_start: values.real_operation_start,
                    shift_end:
                      values.real_operation_finish ||
                      values.real_operation_start,
                    gang_amount: '1',
                    hour_productivity: 0,
                    operated_cargo_amount: opProductTotalWeight,
                    inoperative_time: 0,
                  },
                ],
              }).then(async (response) => {
                if ('data' in response) {
                  const selectedShiftPeriod = response.data.work_shifts[0];
                  const movimentation = [
                    {
                      id: null,
                      total_movimented: opProductTotalWeight,
                      work_shift_id: selectedShiftPeriod.id,
                      work_period_id: response.data.id,
                      operation_product_id: operationProductId,
                      name: 'IMPORTING',
                    },
                  ];
                  const movimentationsToSave = {
                    movimentations:
                      movimentation as WorkShiftMovimentationType[],
                    operation_id: operationId,
                  };
                  await updateMovimentations(movimentationsToSave).then(() => {
                    message.success('Operação confirmada com sucesso');
                    setIsVisibleConfirmOperationModal(false);
                  });
                }
              });
            }
          });
        }
      });
    } else {
      patchOperationProduct({
        id: operationProductId,
        operation_release: 'CONFIRMED',
        user_release: userId,
      }).then(() => {
        message.success('Operação confirmada com sucesso');
        setIsVisibleConfirmOperationModal(false);
      });
    }
  }

  function handleRejectOperation() {
    patchOperationProduct({
      id: operationProductId,
      operation_release: 'NOT_ACCEPTED',
      user_release: userId,
      rejection_reason: rejectionReason,
    }).then(() => {
      message.success('Operação rejeitada com sucesso');
      setIsVisibleRejectOperationModal(false);
    });
  }

  function handleCancel() {
    setIsVisibleConfirmOperationModal(false);
    setIsVisibleRejectOperationModal(false);
    setRejectionReason('');
  }

  return (
    <>
      <Modal
        className="TOSPrimaryModal"
        title="Aceite de operação"
        visible={isVisibleConfirmOperationModal}
        cancelText="Voltar"
        onCancel={handleCancel}
        okText="Confirmar"
        okButtonProps={{ loading: isLoading }}
        onOk={handleConfirmOperation}
        width={535}
        destroyOnClose
      >
        {isProvisioned && (
          <Form
            form={form}
            layout="vertical"
            name="editForm"
            onFinish={handleConfirmOperation}
            // initialValues={createInitialValues()}
          >
            <Row gutter={16}>
              <FormItemDatePicker
                label="Hora real de início da operação"
                name="real_operation_start"
                disabledDate={disabledDateAfterToday}
                colSpan={12}
                required
              />
              <FormItemDatePicker
                label="Horal real de fim da operação"
                name="real_operation_finish"
                disabledDate={disabledDateAfterToday}
                colSpan={12}
              />
            </Row>
            <Row>
              <Alert
                type="info"
                message="Para carga provisionada é necessário informar o inicio da
                  operação"
              />
            </Row>
          </Form>
        )}
        <span>
          Ao confirmar a(o) {operatorName} será responsável pela operação em
          questão.
        </span>
      </Modal>
      <Modal
        className="TOSDangerModal"
        title="Rejeição de operação"
        visible={isVisibleRejectOperationModal}
        cancelText="Voltar"
        onCancel={handleCancel}
        okText="Rejeitar"
        onOk={handleRejectOperation}
        okButtonProps={{
          disabled: rejectionReason === '',
        }}
        // bodyStyle={{ padding: '10px 0px' }}
        width={535}
        destroyOnClose
      >
        <span>Para efetuar a rejeição desta operação, preencha o motivo.</span>
        <TextArea
          style={{ marginTop: '16px' }}
          placeholder="Motivo da rejeição"
          maxLength={2000}
          rows={4}
          showCount={false}
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
      </Modal>
      <Alert
        type="warning"
        style={{
          boxShadow: '0 3px 6px 0 rgba(95, 123, 145, 0.2)',
        }}
        message={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <ExclamationCircleTwoTone
                  twoToneColor="#FA8C16"
                  style={{ fontSize: '21px', marginRight: '12px' }}
                />
                <span style={{ fontSize: '16px' }}>Nova operação</span>
                {isProvisioned && <span>(Provisionada)</span>}
              </div>
              <div>
                <Button
                  type="ghost"
                  danger
                  style={{ color: 'var(--error-medium)', marginRight: '10px' }}
                  onClick={() => setIsVisibleRejectOperationModal(true)}
                >
                  Rejeitar
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsVisibleConfirmOperationModal(true)}
                  loading={isLoading}
                >
                  Confirmar
                </Button>
              </div>
            </div>

            <span style={{ marginLeft: '33px' }}>
              Esse produto da operação({operationCargoTypeName}) foi atribuído
              para {operatorName}.<br /> Deseja confirmar?
            </span>
          </div>
        }
      />
    </>
  );
}
