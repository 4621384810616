import { groupBy } from 'lodash';

import { Permission } from '../../types';
import { translatePermission } from '../../utils/utils';

type PermissionGroupArrayItemType = {
  title: string;
  key: string;
  value: string;
  children: PermissionArrayItemType[];
};

type PermissionArrayItemType = {
  title: string;
  value: number;
  key: number;
};

export function formatPermissionsArray(permissions: Permission[]) {
  const formatedPermissions: PermissionGroupArrayItemType[] = [];
  const permissionsByModel = groupBy(permissions, 'content_type.model');

  Object.keys(permissionsByModel).forEach((model) => {
    formatedPermissions.push(
      formatModelPermission(permissionsByModel[model], model)
    );
  });
  return formatedPermissions;
}

function formatModelPermission(modelPermission: any, modelName: string) {
  return {
    title: modelPermission[0].name.split('add ')[1],
    key: modelName,
    value: formatModelPermissionValue(modelPermission),
    children: formatPermissions(modelPermission),
  };
}

function formatPermissions(permissions: Permission[]) {
  const permissionArray: any[] = [];
  permissions.forEach((permission: any) => {
    permissionArray.push({
      title: translatePermission(permission.name),
      value: permission.id,
      key: permission.id,
    });
  });
  return permissionArray;
}

function formatModelPermissionValue(permissions: any) {
  let modelPermissionValue = '';
  permissions.forEach((permission: any) => {
    modelPermissionValue += `${permission.id}-`;
  });
  return modelPermissionValue;
}
