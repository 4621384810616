import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Col, Row, Space } from 'antd';
import FormList from 'antd/lib/form/FormList';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';

import { FormItemInputNumber } from '../../../components';
import { Button } from '../../../components/antd/Button';
import { Form, FormItem } from '../../../components/antd/Form';
import { Modal } from '../../../components/antd/Modal';
import { DatePicker } from '../../../components/common/datepicker/DatePicker';
import { Stopover as StopoverType } from '../../../types';
import {
  formatStopoverToForm,
  formatStopoverToSave,
  formatDockingToSave,
} from '../../../utils/formatters';
import { disabledDateAfterToday } from '../../../utils/utils';
import { askForDwt } from '../../../utils/validators';

type ModalRegisterArrivalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setHasEdited?: (hasEdited: boolean) => void;
  stopover: StopoverType;
  updateStopover: any;
  updateDocking: any;
  setStopover: (stopover: StopoverType) => void;
  selectedStopover: StopoverType;
  isLoading?: boolean;
};

export function ModalRegisterArrival(props: ModalRegisterArrivalProps) {
  const {
    showModal,
    setShowModal,
    setHasEdited,
    stopover,
    updateStopover,
    updateDocking,
    setStopover,
    selectedStopover,
    isLoading,
  } = props;

  const [form] = Form.useForm();

  const [isDateEmpty, setIsDateEmpty] = useState<boolean>(true);

  const berthDWT =
    stopover.dockings && stopover.dockings.length > 0
      ? stopover.dockings[0].docking_place?.dwt
      : 0;

  function handleCloseModal() {
    if (setHasEdited) setHasEdited(false);
    setShowModal(false);
  }

  function saveWithoutRegistration() {
    const actualTimeOfArrival = form.getFieldValue('actual_time_of_arrival');
    const updatedStopover: StopoverType = {
      ...stopover,
      ...formatStopoverToSave({
        ...stopover,
      }),
      actual_time_of_arrival: actualTimeOfArrival
        ? moment(actualTimeOfArrival).format('YYYY-MM-DD HH:mm')
        : null,
    };

    if (form.getFieldValue(['dockings', '0'])) {
      const updatedDocking: any = {
        ...formatDockingToSave({
          ...form.getFieldValue(['dockings', '0']),
        }),
      };
      save(updatedStopover, updatedDocking);
    } else {
      save(updatedStopover);
    }
  }

  function saveWithRegistration() {
    const actualTimeOfArrival = form.getFieldValue('actual_time_of_arrival');
    const updatedStopover: StopoverType = {
      ...stopover,
      ...formatStopoverToSave({
        ...stopover,
      }),
      actual_time_of_arrival: actualTimeOfArrival
        ? moment(actualTimeOfArrival).format('YYYY-MM-DD HH:mm')
        : null,
      status: 'IN_PROGRESS',
    };

    if (form.getFieldValue(['dockings', '0'])) {
      const updatedDocking: any = {
        ...formatDockingToSave({
          ...form.getFieldValue(['dockings', '0']),
        }),
      };
      save(updatedStopover, updatedDocking);
    } else {
      save(updatedStopover);
    }
  }

  async function save(update: StopoverType, dockingUpdate?: any) {
    const updatedStopover = await updateStopover(update);
    if (dockingUpdate) {
      await updateDocking(dockingUpdate);
    }

    if ('data' in updatedStopover) {
      setStopover(formatStopoverToForm(updatedStopover.data));
    }
    handleCloseModal();
  }

  return (
    <Modal
      visible={showModal}
      title={<>Registrar chegada e/ou atracação</>}
      className="TOSPrimaryModal"
      onCancel={handleCloseModal}
      width={800}
      footer={null}
    >
      <Form initialValues={selectedStopover} form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              label="Hora real de chegada (ATA)"
              tooltip="Quando a embarcação adentra à área delimitada para fundeio"
              name="actual_time_of_arrival"
            >
              <DatePicker
                disabledDate={disabledDateAfterToday}
                style={{ width: '100%' }}
                minuteStep={1}
                onChange={(value) => setIsDateEmpty(isEmpty(value))}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            {!isEmpty(stopover.dockings) && (
              <FormList name="dockings">
                {(_fields) => {
                  const iterate = [{ key: 0, name: 0 }];
                  return iterate.map(
                    ({ name }) =>
                      askForDwt(stopover.vessel?.dwt, berthDWT) && (
                        <FormItemInputNumber
                          label="DWT na entrada"
                          name={[name, 'input_dwt']}
                          addonAfter="ton"
                        />
                      )
                  );
                }}
              </FormList>
            )}
          </Col>
        </Row>
        {stopover.docking_release === 'PENDING' && (
          <>
            <Alert
              message="Embarcação ainda não foi liberada para atracação. Verifique as exigências afim de liberar a atracação."
              type="warning"
              showIcon
              icon={<InfoCircleOutlined />}
            />
            <br />
          </>
        )}
        {stopover.current_status === 'WAITING' ? (
          <>
            <Alert
              message='Ao registrar a chegada da embarcação o status será alterado para "Escala em andamento".'
              type="info"
              showIcon
              icon={<InfoCircleOutlined />}
            />
            <br />
          </>
        ) : null}
        <Row justify="end">
          <Space size={12}>
            <Button onClick={() => handleCloseModal()} disabled={isLoading}>
              Sair sem salvar
            </Button>
            <Button
              type="primary"
              onClick={() => saveWithoutRegistration()}
              loading={isLoading}
            >
              Salvar informações sem registrar
            </Button>
            <Button
              type="primary"
              onClick={() => saveWithRegistration()}
              disabled={stopover.current_status !== 'WAITING' || isDateEmpty}
              loading={isLoading}
            >
              Salvar e registrar chegada
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
}
