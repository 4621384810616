import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, message, Row } from 'antd';
import { useState } from 'react';

import {
  Drawer,
  Form,
  FormItemDatePicker,
  ModalBlockNavigation,
} from '../../../components';
import { usePatchStopoverMutation } from '../../../services/stopoverApi';
import { Stopover as StopoverType } from '../../../types';
import { formatStopoverToForm } from '../../../utils/formatters';
import { createDateString, disabledDateAfterToday } from '../../../utils/utils';
import { DockingFormHeader } from '../../docking/dockingFormHeader/DockingFormHeader';

type StaymentDrawerProps = {
  visible: boolean;
  setIsDrawerVisible: (value: boolean) => void;
  selectedStopover: StopoverType;
  setSelectedStopover: (value: any) => void;
};

export function StaymentDrawer(props: StaymentDrawerProps) {
  const { visible, setIsDrawerVisible, selectedStopover, setSelectedStopover } =
    props;
  const [form] = Form.useForm();

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);

  const [patchStopover, { isLoading: isLoadingPatchStopover }] =
    usePatchStopoverMutation();

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      const partialStopover = {
        id: selectedStopover.id,
        actual_time_of_arrival: createDateString(values.actual_time_of_arrival),
        port_stay_finished: createDateString(values.port_stay_finished),
      };

      const updatedStopover = await patchStopover(partialStopover);
      if ('data' in updatedStopover) {
        const { data } = updatedStopover;
        setSelectedStopover(formatStopoverToForm(data));
        message.success('Alterações salvas com sucesso!', 5);
        setIsDrawerVisible(false);
        setBlockNavigate(false);
        setIsFormChanged(false);
      }
    });
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setIsDrawerVisible(false);
    }
  }

  function afterCloseModal() {
    form.resetFields();
  }

  return (
    <Drawer
      visible={visible}
      width={820}
      closeIcon={
        <Button type="text" icon={<ArrowLeftOutlined />}>
          Voltar
        </Button>
      }
      onClose={() => onClose()}
      closable={false}
      maskClosable={false}
      destroyOnClose
    >
      <Form
        form={form}
        id="stayment_form"
        name="stayment_form"
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={selectedStopover}
        onValuesChange={() => setIsFormChanged(true)}
      >
        <ModalBlockNavigation
          name="stayment_form"
          key="stayment_form"
          setHasEdited={setIsFormChanged}
          setShowModal={setIsDrawerVisible}
          showModal={blockNavigate}
          setBlockNavigate={setBlockNavigate}
          afterCloseModal={afterCloseModal}
        />
        <DockingFormHeader
          formName="stayment_form"
          onBack={onClose}
          showExpandAll={false}
          editFormTitle="Dados da estadia"
          code={selectedStopover.code}
          saveButtonTitle="Salvar Estadia"
          isLoading={isLoadingPatchStopover}
        />
        <Row
          gutter={40}
          align="bottom"
          style={{
            marginBlockStart: '24px',
            marginLeft: '19px',
            marginRight: '19px',
          }}
        >
          <FormItemDatePicker
            colSpan={12}
            disabledDate={disabledDateAfterToday}
            label="Hora real da chegada (ATA)"
            name="actual_time_of_arrival"
            allowClear={false}
          />
          <FormItemDatePicker
            colSpan={12}
            disabledDate={disabledDateAfterToday}
            label="Estadia no porto finalizada em"
            name="port_stay_finished"
            allowClear={false}
          />
        </Row>
      </Form>
    </Drawer>
  );
}
