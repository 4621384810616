import { ColumnsType } from 'antd/lib/table';

import { Button, CustomStatusTag } from '../..';
import { csmDirectionList, statusStorageList } from '../../../utils/lists';
import { createDateStringPtBr } from '../../../utils/utils';

export function columns(
  isAgentAreaContext?: boolean
): ColumnsType<Record<string, any>> {
  return [
    {
      title: 'Produto',
      key: 'ncm_code',
      dataIndex: 'ncm_code',
      align: 'left' as const,
      width: '40%',
      render: (value: string, record: any) => {
        return (
          <div style={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}>
            <span>
              {value} - {record.ncm_description}
            </span>
            <CustomStatusTag list={statusStorageList} status={record.status} />
          </div>
        );
      },
    },
    {
      title: 'Sentido',
      key: 'direction',
      dataIndex: 'direction',
      align: 'left' as const,
      width: '20%',
      render: (value: string) => {
        return (
          <div>
            <span>
              {csmDirectionList.find((item) => item.id === value)?.name}
            </span>
            {/* <span>STATUS</span> */}
          </div>
        );
      },
    },
    {
      title: 'Volume',
      key: 'volume',
      dataIndex: 'volume',
      align: 'right' as const,
      width: '20%',
      render: (value: number) => <span>{value} m³</span>,
    },
    {
      title: 'Prev. armazenamento',
      key: 'expected_storage',
      dataIndex: 'expected_storage',
      align: 'left' as const,
      width: '20%',
      render: (value: string, record: any) =>
        isAgentAreaContext && record.status === 'REJECTED' ? (
          <Button type="primary">Reatribuir</Button>
        ) : (
          <span>
            {value ? (
              createDateStringPtBr(value)
            ) : (
              <i style={{ color: 'var(--neutral_medium)' }}>Não informado</i>
            )}
          </span>
        ),
    },
  ];
}
