import { EditFilled, EyeFilled } from '@ant-design/icons';
import styled from 'styled-components';

import { Tag } from '../../components/antd/Tag';
import { UsersGroup } from '../../types';

const GroupsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
type GroupsRendererProps = {
  groups: Array<UsersGroup>;
  record: any;
  selectUser: (user: any) => void;
};

export function GroupsRenderer(props: GroupsRendererProps) {
  return (
    <GroupsWrapper>
      <div>
        {props.groups.map((permission, index) => {
          return <Tag key={`permission_${index}`}>{permission.name}</Tag>;
        })}
      </div>
      <div
        style={{ cursor: 'pointer', padding: '5px' }}
        onClick={() => props.selectUser(props.record)}
      >
        {props.record.status !== 'DEACTIVATED' ? <EditFilled /> : <EyeFilled />}
      </div>
    </GroupsWrapper>
  );
}
