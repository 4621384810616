// TODO: Criar uma lista de nomes e codigos e fazer um find nela ou consumir da api de lista de dominios
export function translateCountryCode(code: string) {
  switch (code) {
    case 'NO':
      return 'No Flag';
    case 'ABW':
      return 'Aruba';
    case 'AFG':
      return 'Afghanistan';
    case 'AFI':
      return 'French Territory of the Afars and the Issas';
    case 'AGO':
      return 'Angola';
    case 'AIA':
      return 'Anguilla';
    case 'ALA':
      return 'Åland';
    case 'ALB':
      return 'Albania';
    case 'AND':
      return 'Andorra';
    case 'ANT':
      return 'Netherlands Antilles';
    case 'ARE':
      return 'United Arab Emirates';
    case 'ARG':
      return 'Argentina';
    case 'ARM':
      return 'Armenia';
    case 'ASM':
      return 'American Samoa';
    case 'ATA':
      return 'Antarctic Treaty area';
    case 'ATB':
      return 'British Antarctic Territory';
    case 'ATF':
      return 'French Southern and Antarctic Lands';
    case 'ATG':
      return 'Antigua and Barbuda';
    case 'ATN':
      return 'Queen Maud Land';
    case 'AUS':
      return 'Australia';
    case 'AUT':
      return 'Austria';
    case 'AZE':
      return 'Azerbaijan';
    case 'BDI':
      return 'Burundi';
    case 'BEL':
      return 'Belgium';
    case 'BEN':
      return 'Benin';
    case 'BES':
      return 'Caribbean Netherlands';
    case 'BFA':
      return 'Burkina Faso';
    case 'BGD':
      return 'Bangladesh';
    case 'BGR':
      return 'Bulgaria';
    case 'BHR':
      return 'Bahrain';
    case 'BHS':
      return 'The Bahamas';
    case 'BIH':
      return 'Bosnia and Herzegovina';
    case 'BLM':
      return 'Saint Barthélemy';
    case 'BLR':
      return 'Belarus';
    case 'BLZ':
      return 'Belize';
    case 'BMU':
      return 'Bermuda';
    case 'BOL':
      return 'Bolivia';
    case 'BRA':
      return 'Brazil';
    case 'BRB':
      return 'Barbados';
    case 'BRN':
      return 'Brunei';
    case 'BTN':
      return 'Bhutan';
    case 'BVT':
      return 'Bouvet Island';
    case 'BWA':
      return 'Botswana';
    case 'BYS':
      return 'Byelorussian Soviet Socialist Republic';
    case 'CAF':
      return 'Central African Republic';
    case 'CAN':
      return 'Canada';
    case 'CCK':
      return 'Cocos (Keeling) Islands';
    case 'CHE':
      return 'Switzerland';
    case 'CHL':
      return 'Chile';
    case 'CHN':
      return "People's Republic of China";
    case 'CIV':
      return "Côte d'Ivoire";
    case 'CMR':
      return 'Cameroon';
    case 'COD':
      return 'Democratic Republic of the Congo';
    case 'COG':
      return 'Republic of the Congo';
    case 'COK':
      return 'Cook Islands';
    case 'COL':
      return 'Colombia';
    case 'COM':
      return 'Comoros';
    case 'CPV':
      return 'Cape Verde';
    case 'CRI':
      return 'Costa Rica';
    case 'CSK':
      return 'Czechoslovakia';
    case 'CTE':
      return 'Canton and Enderbury Islands';
    case 'CUB':
      return 'Cuba';
    case 'CUW':
      return 'Curaçao';
    case 'CXR':
      return 'Christmas Island';
    case 'CYM':
      return 'Cayman Islands';
    case 'CYP':
      return 'Cyprus';
    case 'CZE':
      return 'Czech Republic';
    case 'DDR':
      return 'German Democratic Republic';
    case 'DEU':
      return 'Germany';
    case 'DGA':
      return 'Diego Garcia';
    case 'DHY':
      return 'Republic of Dahomey';
    case 'DJI':
      return 'Djibouti';
    case 'DMA':
      return 'Dominica';
    case 'DNK':
      return 'Denmark';
    case 'DOM':
      return 'Dominican Republic';
    case 'DZA':
      return 'Algeria';
    case 'ECU':
      return 'Ecuador';
    case 'EGY':
      return 'Egypt';
    case 'ERI':
      return 'Eritrea';
    case 'ESH':
      return 'Western Sahara';
    case 'ESP':
      return 'Spain';
    case 'EST':
      return 'Estonia';
    case 'ETH':
      return 'Ethiopia';
    case 'FIN':
      return 'Finland';
    case 'FJI':
      return 'Fiji';
    case 'FLK':
      return 'Falkland Islands';
    case 'FRA':
      return 'France';
    case 'FRO':
      return 'Faroe Islands';
    case 'FSM':
      return 'Federated States of Micronesia';
    case 'FXX':
      return 'Metropolitan France';
    case 'GAB':
      return 'Gabon';
    case 'GBR':
      return 'United Kingdom';
    case 'GEL':
      return 'Gilbert and Ellice Islands';
    case 'GEO':
      return 'Georgia';
    case 'GGY':
      return 'Guernsey';
    case 'GHA':
      return 'Ghana';
    case 'GIB':
      return 'Gibraltar';
    case 'GIN':
      return 'Guinea';
    case 'GLP':
      return 'Guadeloupe';
    case 'GMB':
      return 'The Gambia';
    case 'GNB':
      return 'Guinea-Bissau';
    case 'GNQ':
      return 'Equatorial Guinea';
    case 'GRC':
      return 'Greece';
    case 'GRD':
      return 'Grenada';
    case 'GRL':
      return 'Greenland';
    case 'GTM':
      return 'Guatemala';
    case 'GUF':
      return 'French Guiana';
    case 'GUM':
      return 'Guam';
    case 'GUY':
      return 'Guyana';
    case 'HKG':
      return 'Hong Kong';
    case 'HMD':
      return 'Heard Island and McDonald Islands';
    case 'HND':
      return 'Honduras';
    case 'HRV':
      return 'Croatia';
    case 'HTI':
      return 'Haiti';
    case 'HUN':
      return 'Hungary';
    case 'HVO':
      return 'Republic of Upper Volta';
    case 'IDN':
      return 'Indonesia';
    case 'IMN':
      return 'Isle of Man';
    case 'IND':
      return 'India';
    case 'IOT':
      return 'British Indian Ocean Territory';
    case 'IRL':
      return 'Ireland';
    case 'IRN':
      return 'Iran';
    case 'IRQ':
      return 'Iraq';
    case 'ISL':
      return 'Iceland';
    case 'ISR':
      return 'Israel';
    case 'ITA':
      return 'Italy';
    case 'JAM':
      return 'Jamaica';
    case 'JEY':
      return 'Jersey';
    case 'JOR':
      return 'Jordan';
    case 'JPN':
      return 'Japan';
    case 'JTN':
      return 'Johnston Atoll';
    case 'KAZ':
      return 'Kazakhstan';
    case 'KEN':
      return 'Kenya';
    case 'KGZ':
      return 'Kyrgyzstan';
    case 'KHM':
      return 'Cambodia';
    case 'KIR':
      return 'Kiribati';
    case 'KNA':
      return 'Saint Kitts and Nevis';
    case 'KOR':
      return 'South Korea';
    case 'KWT':
      return 'Kuwait';
    case 'LAO':
      return 'Laos';
    case 'LBN':
      return 'Lebanon';
    case 'LBR':
      return 'Liberia';
    case 'LBY':
      return 'Libya';
    case 'LCA':
      return 'Saint Lucia';
    case 'LIE':
      return 'Liechtenstein';
    case 'LKA':
      return 'Sri Lanka';
    case 'LSO':
      return 'Lesotho';
    case 'LTU':
      return 'Lithuania';
    case 'LUX':
      return 'Luxembourg';
    case 'LVA':
      return 'Latvia';
    case 'MAC':
      return 'Macau';
    case 'MAF':
      return 'Saint Martin';
    case 'MAR':
      return 'Morocco';
    case 'MCO':
      return 'Monaco';
    case 'MDA':
      return 'Moldova';
    case 'MDG':
      return 'Madagascar';
    case 'MDV':
      return 'Maldives';
    case 'MEX':
      return 'Mexico';
    case 'MHL':
      return 'Marshall Islands';
    case 'MID':
      return 'Midway Atoll';
    case 'MKD':
      return 'North Macedonia';
    case 'MLI':
      return 'Mali';
    case 'MLT':
      return 'Malta';
    case 'MMR':
      return 'Myanmar';
    case 'MNE':
      return 'Montenegro';
    case 'MNG':
      return 'Mongolia';
    case 'MNP':
      return 'Northern Mariana Islands';
    case 'MOZ':
      return 'Mozambique';
    case 'MRT':
      return 'Mauritania';
    case 'MSR':
      return 'Montserrat';
    case 'MTQ':
      return 'Martinique';
    case 'MUS':
      return 'Mauritius';
    case 'MWI':
      return 'Malawi';
    case 'MYS':
      return 'Malaysia';
    case 'MYT':
      return 'Mayotte';
    case 'NAM':
      return 'Namibia';
    case 'NCL':
      return 'New Caledonia';
    case 'NER':
      return 'Niger';
    case 'NFK':
      return 'Norfolk Island';
    case 'NGA':
      return 'Nigeria';
    case 'NHB':
      return 'New Hebrides';
    case 'NIC':
      return 'Nicaragua';
    case 'NIU':
      return 'Niue';
    case 'NLD':
      return 'Netherlands';
    case 'NOR':
      return 'Norway';
    case 'NPL':
      return 'Nepal';
    case 'NRU':
      return 'Nauru';
    case 'NZL':
      return 'New Zealand';
    case 'OMN':
      return 'Oman';
    case 'PAK':
      return 'Pakistan';
    case 'PAN':
      return 'Panama';
    case 'PCN':
      return 'Pitcairn Islands';
    case 'PER':
      return 'Peru';
    case 'PHL':
      return 'Philippines';
    case 'PLW':
      return 'Palau';
    case 'PNG':
      return 'Papua New Guinea';
    case 'POL':
      return 'Poland';
    case 'PRI':
      return 'Puerto Rico';
    case 'PRK':
      return 'North Korea';
    case 'PRT':
      return 'Portugal';
    case 'PRY':
      return 'Paraguay';
    case 'PSE':
      return 'State of Palestine';
    case 'PYF':
      return 'French Polynesia';
    case 'QAT':
      return 'Qatar';
    case 'REU':
      return 'Réunion';
    case 'ROU':
      return 'Romania';
    case 'RUS':
      return 'Russia';
    case 'RWA':
      return 'Rwanda';
    case 'SAU':
      return 'Saudi Arabia';
    case 'SCG':
      return 'Serbia and Montenegro';
    case 'SDN':
      return 'Sudan';
    case 'SEN':
      return 'Senegal';
    case 'SGP':
      return 'Singapore';
    case 'SGS':
      return 'South Georgia and the South Sandwich Islands';
    case 'SHN':
      return 'Saint Helena, Ascension and Tristan da Cunha';
    case 'SJM':
      return 'Svalbard and Jan Mayen';
    case 'SLB':
      return 'Solomon Islands';
    case 'SLE':
      return 'Sierra Leone';
    case 'SLV':
      return 'El Salvador';
    case 'SMR':
      return 'San Marino';
    case 'SOM':
      return 'Somalia';
    case 'SPM':
      return 'Saint Pierre and Miquelon';
    case 'SRB':
      return 'Serbia';
    case 'SSD':
      return 'South Sudan';
    case 'STP':
      return 'São Tomé and Príncipe';
    case 'SUN':
      return 'Soviet Union';
    case 'SUR':
      return 'Suriname';
    case 'SVK':
      return 'Slovakia';
    case 'SVN':
      return 'Slovenia';
    case 'SWE':
      return 'Sweden';
    case 'SWZ':
      return 'Eswatini';
    case 'SXM':
      return 'Sint Maarten';
    case 'SYC':
      return 'Seychelles';
    case 'SYR':
      return 'Syria';
    case 'TCA':
      return 'Turks and Caicos Islands';
    case 'TCD':
      return 'Chad';
    case 'TGO':
      return 'Togo';
    case 'THA':
      return 'Thailand';
    case 'TJK':
      return 'Tajikistan';
    case 'TKL':
      return 'Tokelau';
    case 'TKM':
      return 'Turkmenistan';
    case 'TLS':
      return 'Timor-Leste';
    case 'TON':
      return 'Tonga';
    case 'TTO':
      return 'Trinidad and Tobago';
    case 'TUN':
      return 'Tunisia';
    case 'TUR':
      return 'Turkey';
    case 'TUV':
      return 'Tuvalu';
    case 'TWN':
      return 'Taiwan';
    case 'TZA':
      return 'Tanzania';
    case 'UGA':
      return 'Uganda';
    case 'UKR':
      return 'Ukraine';
    case 'UMI':
      return 'United States Minor Outlying Islands';
    case 'URY':
      return 'Uruguay';
    case 'USA':
      return 'United States of America';
    case 'UZB':
      return 'Uzbekistan';
    case 'VAT':
      return 'Vatican City';
    case 'VCT':
      return 'Saint Vincent and the Grenadines';
    case 'VEN':
      return 'Venezuela';
    case 'VGB':
      return 'British Virgin Islands';
    case 'VIR':
      return 'United States Virgin Islands';
    case 'VNM':
      return 'Vietnam';
    case 'VUT':
      return 'Vanuatu';
    case 'WLF':
      return 'Wallis and Futuna';
    case 'WSM':
      return 'Samoa';
    case 'XKS':
      return 'Kosovo';
    case 'YEM':
      return 'Yemen';
    case 'YUG':
      return 'Federal Republic of Yugoslavia';
    case 'ZAF':
      return 'South Africa';
    case 'ZMB':
      return 'Zambia';
    case 'ZWE':
      return 'Zimbabwe';
    case 'XZ':
      return 'Internacional Zone';
    default:
      return '';
  }
}
