import {
  BreakdownOccurrenceToSave,
  BreakdownOccurrenceType,
  TreeType,
} from '../../../types';
import { createDateString } from '../../../utils/utils';

const FENDER_TREE = 0;
const BOLLARD_TREE = 1;

export function formatBreakdownOccurrenceToSave(
  breakdown: BreakdownOccurrenceType,
  equipments: TreeType[]
): BreakdownOccurrenceToSave {
  const breakdownToSave: BreakdownOccurrenceToSave = {
    id: breakdown.id,
    primary_type: breakdown.primary_type,
    stopover_id: breakdown.stopover_id,
    date: createDateString(breakdown.date || null),
    breakdown_occurrence_type: breakdown.breakdown_occurrence_type?.id || null,
    duty: breakdown.duty,
    shipowner: breakdown.shipowner?.id || null,
    on_duty: breakdown.on_duty?.id || null,
    docking: breakdown.docking?.id || null,
    description: breakdown.description,
  };

  // checa se foram selecionados todos, senao so inclui os selecionados
  if (breakdown.equipments?.includes('FENDER')) {
    breakdownToSave.fenders =
      equipments[FENDER_TREE].children?.flatMap((fender) => [fender.key]) || [];
  } else {
    breakdownToSave.fenders = breakdown.equipments?.filter(
      (equipment: string) => {
        if (equipment.startsWith('DE')) {
          return equipment;
        }
      }
    );
  }

  // checa se foram selecionados todos, senao so inclui os selecionados
  if (breakdown.equipments?.includes('BOLLARD')) {
    breakdownToSave.bollards =
      equipments[BOLLARD_TREE].children?.flatMap((bollard) => [bollard.key]) ||
      [];
  } else {
    breakdownToSave.bollards = breakdown.equipments?.filter(
      (equipment: string) => {
        if (equipment.startsWith('CA')) {
          return equipment;
        }
      }
    );
  }

  return breakdownToSave;
}
