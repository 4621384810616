import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

import { useGetStopoverLogQuery } from '../../../services/stopoverLogApi';
import { StaymentStatus } from './staymentStatus';
import { StaymentSubStatus } from './staymentSubStatus';

type StaymentLogProps = {
  selectedStopover?: number;
  selectedDocking?: number;
};

export function StaymentLog(props: StaymentLogProps) {
  const { selectedStopover, selectedDocking } = props;

  const { data: stopoverLogData, isFetching: isFetchingStopoverLog } =
    useGetStopoverLogQuery(
      {
        stopover_id: selectedStopover,
        docking_id: selectedDocking,
      },
      { skip: selectedStopover === undefined && selectedDocking === undefined }
    );
  const [selectedStatus, setSelectedStatus] = useState(0); // Deve selecionar o ultimo item por padrão

  useEffect(() => {
    if (stopoverLogData?.length) {
      setSelectedStatus(stopoverLogData.length - 1);
    }
  }, [stopoverLogData]);

  return (
    <Skeleton loading={isFetchingStopoverLog}>
      {stopoverLogData && stopoverLogData?.length > 0 && (
        <StaymentStatus
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          statusList={stopoverLogData}
        />
      )}
      <StaymentSubStatus
        selectedStatus={
          stopoverLogData ? stopoverLogData[selectedStatus] : undefined
        }
      />
    </Skeleton>
  );
}
