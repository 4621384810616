import { Descriptions, FormatItem, ProductTitle } from '../../../components';
import { ProductTypeDescription } from '../../../types';
import { ContainerProductDescription } from './styles';

type ProductDescriptionProps = {
  products: ProductTypeDescription[] | null | undefined;
  screen: 'STOPOVER' | 'STAYMENT';
};

const { Item } = Descriptions;

export function ProductDescription(props: ProductDescriptionProps) {
  const { products, screen } = props;
  return (
    <ContainerProductDescription>
      <span
        style={{
          fontWeight: '600',
          fontSize: '12px',
          color: 'var(--ocean_dark)',
          marginBlockEnd: '16px',
        }}
      >
        Mercadorias
      </span>
      {products?.map((product, index) => {
        return (
          <div key={product.id}>
            {index > 0 && <hr />}
            <ProductTitle>
              <span className="title">{product.cargo_type}</span>
              <span className="subtitle">{product.product_group}</span>
              <div className="product-unit">
                <span>{product.quantity} </span>
                <span>{product.unit}</span>
              </div>
            </ProductTitle>
            <Descriptions column={3}>
              <Item label="Sentido da operação">
                <FormatItem>{product.operation_type}</FormatItem>
              </Item>
              <Item label="Operador" span={2}>
                <FormatItem>{product.operator}</FormatItem>
              </Item>
              <Item label="Carga direta?">
                <FormatItem>{product.direct_discharge}</FormatItem>
              </Item>
              {screen === 'STAYMENT' && (
                <Item label="Tipo de tráfego" span={2}>
                  <FormatItem>{product.traffic_type}</FormatItem>
                </Item>
              )}
              <Item label="Armazen. confirmado?">
                <FormatItem>{product.tanking_confirmed}</FormatItem>
              </Item>
              <Item label="Local de armaz" span={1}>
                <FormatItem>{product.storage}</FormatItem>
              </Item>
            </Descriptions>
          </div>
        );
      })}
    </ContainerProductDescription>
  );
}
