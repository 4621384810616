import styled from 'styled-components';

export const ProductTitle = styled.div`
  display: flex;
  margin-block-end: 8px;
  align-items: flex-end;
  .title {
    color: var(--suape-black);
    font-weight: 600;

    margin-inline-end: 8px;
  }
  .subtitle {
    color: var(--neutral_medium);
    font-size: 12px;
  }
  .product-unit {
    background-color: var(--neutral_lightest);
    justify-content: center;
    padding: 0px 6px;
    margin-inline-start: 8px;
    border-radius: 100px;
    span {
      font-family: 'Consolas';
      font-size: 12px;
      font-weight: 400;
      color: var(--neutral_medium);
    }
  }
`;
