import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';
import FormList from 'antd/lib/form/FormList';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';

import { Button, Collapse } from '../../components';
import { NcmItemFilters, OperationalOperationType } from '../../types';
import { formatOperationToForm } from '../../utils/formatters';
import { isNullOrUndefined } from '../../utils/utils';
import { OperationalOperationDrawer } from './operationalOperationDrawer';

type FormItemsOperationalOperationProps = {
  updatedAt?: string;
  openCollapsePanels: string | string[];
  setOpenCollapsePanels: Dispatch<SetStateAction<string | string[]>>;
  dockingId?: number;
  dockingForm: FormInstance;
  stopoverId: number;
  ncmIds: number[];
  setNcmIds: Dispatch<SetStateAction<number[]>>;
  ncmItemFilters: NcmItemFilters[];
  setNcmItemFilters: Dispatch<SetStateAction<NcmItemFilters[]>>;
};

const { Panel } = Collapse;

export function FormItemsOperationalOperation(
  props: FormItemsOperationalOperationProps
) {
  const {
    updatedAt,
    openCollapsePanels,
    dockingId,
    dockingForm,
    setOpenCollapsePanels,
    stopoverId,
    ncmIds,
    setNcmIds,
    ncmItemFilters,
    setNcmItemFilters,
  } = props;
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [selectedOperationToEdit, setSelectedOperationToEdit] = useState(
    {} as OperationalOperationType
  );
  const [operationIndexToEdit, setOperationIndexToEdit] = useState(0);

  function panelHeaderTitle(title: string) {
    return (
      <>
        {title}
        {!isNullOrUndefined(updatedAt) && (
          <span
            className="header-description"
            style={{ float: 'right', fontWeight: 'normal', fontSize: '13px' }}
          >
            Atualizado em: {moment(updatedAt).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </>
    );
  }

  function handleAddOperation() {
    setIsVisibleDrawer(true);
  }

  function handleEditOperation(index: number) {
    const operation = dockingForm.getFieldValue(['operations', index]);
    setSelectedOperationToEdit(formatOperationToForm(operation));
    setOperationIndexToEdit(index);
    setIsVisibleDrawer(true);
  }

  return (
    <Collapse
      expandIconPosition="end"
      activeKey={openCollapsePanels}
      onChange={setOpenCollapsePanels}
    >
      <Panel
        forceRender
        header={panelHeaderTitle('Operações')}
        key="operationalOperations"
      >
        <FormList name="operations">
          {(operations, { add: addOperation, remove: removeOperation }) => {
            return (
              <>
                <OperationalOperationDrawer
                  dockingId={dockingId}
                  addOperationToForm={addOperation}
                  removeOperationFromForm={removeOperation}
                  isVisibleDrawer={isVisibleDrawer}
                  setIsVisibleDrawer={setIsVisibleDrawer}
                  selectedOperation={selectedOperationToEdit}
                  selectedOperationIndex={operationIndexToEdit}
                  setSelectedOperation={setSelectedOperationToEdit}
                  dockingForm={dockingForm}
                  stopoverId={stopoverId}
                  ncmIds={ncmIds}
                  setNcmIds={setNcmIds}
                  ncmItemFilters={ncmItemFilters}
                  setNcmItemFilters={setNcmItemFilters}
                />
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => handleAddOperation()}
                  style={{ marginBlockEnd: '10px' }}
                >
                  Nova operação
                </Button>
                {operations.map((fields, index) => (
                  <div key={index} style={{ display: 'flex', width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10px',
                        width: '100%',
                      }}
                    >
                      {index !== 0 && (
                        <hr
                          style={{
                            borderTop: '1px dashed #ccc',
                            width: '100%',
                          }}
                        />
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                          alignItems: 'baseline',
                          justifyContent: 'space-between',
                        }}
                      >
                        {dockingForm.getFieldValue([
                          'operations',
                          index,
                          'provisioned_cargos',
                        ])?.length > 0 ? (
                          <span
                            style={{
                              fontWeight: '700',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Carga provisionada (
                            {dockingForm.getFieldValue([
                              'operations',
                              index,
                              'total_provisioned_cargo_itens',
                            ])}
                            )
                          </span>
                        ) : (
                          <div>
                            {dockingForm.getFieldValue([
                              'operations',
                              index,
                              'items',
                            ])?.length > 0 &&
                            dockingForm.getFieldValue([
                              'operations',
                              index,
                              'total_products_handled_description',
                            ]) ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <span style={{ fontWeight: '700' }}>
                                  {dockingForm.getFieldValue([
                                    'operations',
                                    index,
                                    'total_products_handled_description',
                                  ])}
                                </span>
                                <span style={{ color: 'var(--neutral_light)' }}>
                                  {dockingForm.getFieldValue([
                                    'operations',
                                    index,
                                    'total_products_operator_handled_description',
                                  ])}
                                </span>
                              </div>
                            ) : (
                              <>
                                <span style={{ fontWeight: '700' }}>
                                  {dockingForm.getFieldValue([
                                    'operations',
                                    index,
                                    'nature_of_cargo',
                                    'name',
                                  ])}
                                </span>
                                <span>
                                  (
                                  {dockingForm.getFieldValue([
                                    'operations',
                                    index,
                                    'product_group',
                                    'name',
                                  ]) || 'Comum'}
                                  )
                                </span>
                              </>
                            )}
                          </div>
                        )}
                        <Button
                          icon={<EditFilled />}
                          onClick={() => handleEditOperation(index)}
                        >
                          Editar
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            );
          }}
        </FormList>
      </Panel>
    </Collapse>
  );
}
