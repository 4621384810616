import styled, { css } from 'styled-components';

type ButtonNotificationProps = {
  hasNewNotification: boolean;
};

type NotificationItemProps = {
  isRead: boolean;
};

export const ButtonNotification = styled.button<ButtonNotificationProps>`
  padding-right: 15px;
  background: none;
  border: 0;
  cursor: pointer;

  /* ${(props) =>
    props.hasNewNotification &&
    css`
      &::after {
        position: absolute;
        width: 8px;
        height: 8px;
        right: 13px;
        background: var(--green-sea_dark) !important;
        content: '';
        border-radius: 50%;
      }
    `} */
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: normal;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 290px;
  overflow: auto;
  max-height: 70vh;
  background-color: var(--neutral_lightest);
`;

export const GroupNotification = styled.div`
  background-color: #fff;
  padding: 5px;
`;

export const ContainerNotificationItem = styled.div<NotificationItemProps>`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-bottom: 15px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 10px;

  &:hover {
    background: var(--neutral_lightest);
  }

  div {
    padding: 3px 0;
    color: gray;
  }

  ${(props: NotificationItemProps) =>
    css`
      &::after {
        position: absolute;
        width: 7px;
        height: 7px;
        top: 23px;
        left: 3px;
        background: ${props.isRead ? '#fff' : 'var(--ocean_dark) !important'};
        content: '';
        border-radius: 50%;
        border: solid 1px var(--neutral_lighter);
      }
    `}

  .createdAt {
    font-size: 10px;
    color: var(--ocean_dark);
  }

  .title {
    font-size: 14px;
    line-height: 20px;
  }

  .subtitle {
    color: var(--ocean_dark);
    font-size: 12px;
    line-height: 20px;
  }
`;
