import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Skeleton, Switch, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Button, Collapse } from '../../../components';
import { useCollapsePanels } from '../../../hooks';
import { useGetBreakdownsOccurrencesQuery } from '../../../services/breakdownOccurrenceApi';
import {
  BreakdownOccurrenceType,
  TreeType,
  Stopover as StopoverType,
} from '../../../types';
import { BreakdownForm } from './breakdownForm';
import { OccurenceForm } from './occurenceForm';
import { BreakdownOccurenceFormHeader } from './styles';

type BreakdownOccurenceFormProps = {
  setIsVisible: (value: boolean) => void;
  selectedStopover: StopoverType;
};

const { Panel } = Collapse;
const collapsePanels = ['breakdowns', 'occurences'];

export function BreakdownOccurenceForm(props: BreakdownOccurenceFormProps) {
  const { selectedStopover, setIsVisible } = props;

  const {
    data: occurrencesData,
    isSuccess: isSucessGetOccurrences,
    isFetching: isFetchingOccurrenceData,
  } = useGetBreakdownsOccurrencesQuery({
    stopover_id: selectedStopover.id,
    primary_type: 'OCCURRENCE',
  });

  const {
    data: breakdownsData,
    isSuccess: isSucessGetBreakdowns,
    isFetching: isFetchingBreakdownData,
  } = useGetBreakdownsOccurrencesQuery({
    stopover_id: selectedStopover.id,
    primary_type: 'BREAKDOWN',
  });

  const [equipments, setEquipments] = useState<TreeType[]>([]);
  const [breakdowns, setBreakdowns] = useState<BreakdownOccurrenceType[]>();
  const [occurences, setOccurences] = useState<BreakdownOccurrenceType[]>();

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  useEffect(() => {
    if (isSucessGetOccurrences && !isFetchingOccurrenceData) {
      setOccurences((prev) => [
        ...occurrencesData.results.map((occurrence) => {
          return {
            ...occurrence,
            date: occurrence.date ? moment(occurrence.date) : '',
            updated_at: moment(occurrence?.updated_at).format(
              'DD/MM/YYYY HH:mm'
            ),
          };
        }),
        ...(prev?.filter((item) => item && item.id === undefined) || []),
      ]);
    }
  }, [isSucessGetOccurrences, isFetchingOccurrenceData]);

  useEffect(() => {
    if (isSucessGetBreakdowns && !isFetchingBreakdownData) {
      setBreakdowns((prev) => [
        ...breakdownsData.results.map((breakdown) => {
          return {
            ...breakdown,
            date: breakdown.date ? moment(breakdown.date) : '',
            updated_at: moment(breakdown?.updated_at).format(
              'DD/MM/YYYY HH:mm'
            ),
          };
        }),
        ...(prev?.filter((item) => item && item.id === undefined) || []),
      ]);
    }
  }, [isSucessGetBreakdowns, isFetchingBreakdownData]);

  function onBack() {
    setIsVisible(false);
  }

  return (
    <>
      <BreakdownOccurenceFormHeader>
        <div className="container-title">
          <Button
            type="text"
            className="button-back"
            icon={
              <ArrowLeftOutlined
                style={{ color: 'var(--green-sea_dark)', fontSize: '20px' }}
              />
            }
            onClick={() => onBack()}
          />
          <div className="titles">
            <span className="title-stopover-code">{selectedStopover.code}</span>
            <span className="title">Avarias e Ocorrências</span>
          </div>
        </div>
        <div className="expand">
          <Switch
            onChange={onChangeSwitch}
            checked={openCollapsePanels.length > 0}
          />
          <span className="switchLabel">Expandir tudo</span>
        </div>
      </BreakdownOccurenceFormHeader>
      <Collapse activeKey={openCollapsePanels} onChange={onOpenPanel}>
        <Panel key="breakdowns" header={`Avarias (${breakdowns?.length || 0})`}>
          <Skeleton loading={isFetchingBreakdownData}>
            <div style={{ paddingBlockEnd: '24px' }}>
              <Tooltip
                title={
                  breakdowns?.some((item) => item.id === undefined)
                    ? 'É necessário salvar o item adicionado para criar um novo'
                    : ''
                }
                placement="right"
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    setBreakdowns((prevBreakdowns) =>
                      prevBreakdowns
                        ? [
                            ...prevBreakdowns,
                            {
                              primary_type: 'BREAKDOWN',
                              updated_at: moment().format('DD/MM/YYYY HH:mm'),
                            },
                          ]
                        : [{} as BreakdownOccurrenceType]
                    );
                  }}
                  icon={<PlusOutlined />}
                  disabled={breakdowns?.some((item) => item.id === undefined)}
                >
                  Nova avaria
                </Button>
              </Tooltip>
            </div>
            {breakdowns?.map((breakdown, index) => (
              <BreakdownForm
                key={index}
                index={index}
                breakdownSelected={breakdown}
                dockings={selectedStopover.dockings}
                equipments={equipments}
                setEquipments={setEquipments}
                setBreakdowns={setBreakdowns}
                stopoverId={selectedStopover.id}
              />
            ))}
          </Skeleton>
        </Panel>
        <Panel
          key="occurences"
          header={`Ocorrências (${occurences?.length || 0})`}
        >
          <Skeleton loading={isFetchingOccurrenceData}>
            <div style={{ paddingBlockEnd: '24px' }}>
              <Tooltip
                title={
                  occurences?.some((item) => item.id === undefined)
                    ? 'É necessário salvar o item adicionado para criar um novo'
                    : ''
                }
                placement="right"
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    setOccurences((prevOccurence) =>
                      prevOccurence
                        ? [
                            ...prevOccurence,
                            {
                              primary_type: 'OCCURRENCE',
                              updated_at: moment().format('DD/MM/YYYY HH:mm'),
                            },
                          ]
                        : [{} as BreakdownOccurrenceType]
                    );
                  }}
                  icon={<PlusOutlined />}
                  disabled={occurences?.some((item) => item.id === undefined)}
                >
                  Nova ocorrência
                </Button>
              </Tooltip>
            </div>
            {occurences?.map((occurence, index) => (
              <OccurenceForm
                key={index}
                index={index}
                occurenceSelected={occurence}
                dockings={selectedStopover.dockings}
                setOccurences={setOccurences}
                stopoverId={selectedStopover.id}
              />
            ))}
          </Skeleton>
        </Panel>
      </Collapse>
    </>
  );
}
