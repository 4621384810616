import { FormInstance } from 'antd';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { Drawer } from '../../../../components';
import {
  OperationProductAndCraneType,
  OperationProductType,
  Stopover as StopoverType,
} from '../../../../types';
import { OperationProductsEditForm } from './operationProductsEditForm';

type OperationProductsEditDrawerProps = {
  visible: boolean;
  width: number;
  onBack: () => void;
  selectedProduct?: OperationProductType | undefined;
  setSelectedProduct?: (value: OperationProductType | undefined) => void;
  selectedStopover: StopoverType;
  setSelectedStopover: (stopover: StopoverType) => void;
  setIsVisibleForm: (value: boolean) => void;
  operationForm?: FormInstance | undefined;
};

export function OperationProductsEditDrawer(
  props: OperationProductsEditDrawerProps
) {
  const {
    visible,
    width,
    onBack,
    selectedProduct,
    setSelectedProduct,
    selectedStopover,
    setSelectedStopover,
    setIsVisibleForm,
    operationForm,
  } = props;

  const [formIsChanged, setIsFormChanged] = useState(false);
  const [blockNavigate, setBlockNavigate] = useState(false);
  const [selectedOperationProduct, setSelectedOperationProduct] = useState<
    OperationProductType | undefined
  >({} as OperationProductType);

  function getSelectedProduct() {
    return isEmpty(selectedProduct)
      ? selectedOperationProduct
      : selectedProduct;
  }

  return (
    <Drawer
      visible={visible}
      width={width}
      onClose={() => onBack()}
      closable={false}
      maskClosable={false}
    >
      <OperationProductsEditForm
        selectedProduct={getSelectedProduct()}
        setSelectedProduct={setSelectedProduct || setSelectedOperationProduct}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        onFinishSubmit={onBack}
        onBack={onBack}
        setIsVisibleForm={setIsVisibleForm}
        formIsChanged={formIsChanged}
        setIsFormChanged={setIsFormChanged}
        setBlockNavigate={setBlockNavigate}
        blockNavigate={blockNavigate}
        operationForm={operationForm}
      />
    </Drawer>
  );
}
