import { message, Row } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Collapse,
  Form,
  FormItem,
  FormItemCEP,
  FormItemCNPJ,
  FormItemDynamicPhone,
  FormItemInput,
  FormItemSelect,
} from '../../../components';
import {
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
  useUpdateCompanyMutation,
} from '../../../services/companyApi';
import { useGetCompanyTypesQuery } from '../../../services/companyTypeApi';
import { CompanyType } from '../../../types';
import { cargoNatureList } from '../../../utils/lists';
import {
  isNullOrUndefined,
  removeSpecialCharacters,
} from '../../../utils/utils';
import { RegisterHeaderForm } from '../registerHeaderForm';

const { Panel } = Collapse;

export const FormItemsWrapper = styled.div`
  padding: 24px 16px;
`;

type CompanyFormProps = {
  allCompaniesCount: number | undefined;
  onClose: (show: null) => void;
  setQueryPage: (page: number) => void;
  selectedCompany: CompanyType | undefined;
  refetchCompanyData: () => void;
  isEdit: boolean;
};

const STORAGE = 8;

export function CompanyForm(props: CompanyFormProps) {
  const { allCompaniesCount, onClose, setQueryPage, selectedCompany, isEdit } =
    props;
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isStorage, setIsStorage] = useState(false);

  const [
    createCompany,
    { isSuccess: isSuccessCreateCompany, isError: isErrorCreateCompany },
  ] = useCreateCompanyMutation();

  const [
    updateCompany,
    { isSuccess: isSuccessUpdateCompany, isError: isErrorUpdateCompany },
  ] = useUpdateCompanyMutation();

  const [
    deleteCompany,
    { isSuccess: isSuccessDeleteCompany, isError: isErrorDeleteCompany },
  ] = useDeleteCompanyMutation();

  const { data: allCompaniesData } = useGetCompaniesQuery({
    page_size: allCompaniesCount,
  });

  const { data: allCompanyTypesData } = useGetCompanyTypesQuery({
    page_size: 100,
  });

  useEffect(() => {
    if (isEdit) {
      setIsStorage(
        form
          .getFieldValue('company_type')
          .some((companyType: number) => companyType === STORAGE)
      );
    }

    if (isSuccessUpdateCompany) {
      message.success('Empresa atualizada');
      onClose(null);
    }
  }, [isSuccessUpdateCompany]);

  useEffect(() => {
    if (isSuccessCreateCompany) {
      message.success('Empresa criada');
      onClose(null);
    } else if (isErrorCreateCompany) {
      message.error(
        'Ocorreu um erro ao salvar a empresa, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessCreateCompany, isErrorCreateCompany]);

  useEffect(() => {
    if (isSuccessDeleteCompany) {
      message.success('Empresa excluída');
      onClose(null);
    } else if (isErrorDeleteCompany) {
      message.error(
        'Ocorreu um erro ao excluir a Empresa, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessDeleteCompany, isErrorDeleteCompany]);

  async function onDeleteCompany() {
    if (selectedCompany && selectedCompany.id) {
      setQueryPage(1);
      await deleteCompany(selectedCompany.id);
      props.refetchCompanyData();
    }
  }

  async function handleFormSubmit(values: any) {
    setIsLoading(true);
    if (!validateCompany(values)) return;

    const company: CompanyType = {
      ...values,
      phone_number: removeSpecialCharacters(values.phone_number),
      cnpj: removeSpecialCharacters(values.cnpj),
      address: {
        ...values.address,
        postal_code: removeSpecialCharacters(values.address?.postal_code),
      },
    };

    if (isEdit) {
      await updateCompany(company);
    } else {
      await createCompany(company);
    }
    setIsLoading(false);
  }

  function validateCompany(companySaved: CompanyType) {
    let existError = false;
    const existCompanyWithSameName = allCompaniesData?.results.some(
      (company) =>
        company.id !== companySaved.id &&
        company.name.toLowerCase() === companySaved.name.toLowerCase()
    );

    if (existCompanyWithSameName) {
      message.error('Já existe uma empresa cadastrada com o Nome informado');
      existError = true;
    }

    const existCompanyWithSameCnpj = allCompaniesData?.results.some(
      (company) =>
        company.id !== companySaved.id &&
        !isNullOrUndefined(company.cnpj) &&
        company.cnpj?.toLowerCase() === companySaved.cnpj?.toLowerCase()
    );

    if (existCompanyWithSameCnpj) {
      message.error('Já existe uma empresa cadastrada com o CNPJ informado');
      existError = true;
    }

    if (
      isNullOrUndefined(companySaved.tin) &&
      (isNullOrUndefined(companySaved.cnpj) ||
        companySaved.cnpj === '__.___.___/____-__')
    ) {
      message.error(
        'É necessário informar o CNPJ ou o TIN para cadastrar uma empresa'
      );
      existError = true;
    }

    if (
      !isNullOrUndefined(companySaved.tin) &&
      !isNullOrUndefined(companySaved.cnpj) &&
      companySaved.cnpj !== '__.___.___/____-__'
    ) {
      message.error('Não é possível informar o CNPJ e o TIN para uma empresa');
      existError = true;
    }

    const existCompanyWithSameTin = allCompaniesData?.results.some(
      (company) =>
        company.id !== companySaved.id &&
        !isNullOrUndefined(company.tin) &&
        company.tin?.toLowerCase() === companySaved.tin?.toLowerCase()
    );

    if (existCompanyWithSameTin) {
      message.error('Já existe uma empresa cadastrada com o TIN informado');
      existError = true;
    }

    const existCompanyWithSameEmail = allCompaniesData?.results.some(
      (company) =>
        company.id !== companySaved.id &&
        !isNullOrUndefined(company.email) &&
        company.email?.toLowerCase() === companySaved.email?.toLowerCase()
    );

    if (existCompanyWithSameEmail) {
      message.error('Já existe uma empresa cadastrada com o Email informado');
      existError = true;
    }

    if (existError) {
      setIsLoading(false);
      return false;
    }
    return true;
  }

  function onSelectCompanyType() {
    setIsStorage(false);
    if (form.getFieldValue('company_type').includes(STORAGE)) {
      setIsStorage(true);
    }
  }

  return (
    <Form
      form={form}
      name="company"
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={selectedCompany}
    >
      <RegisterHeaderForm
        onClose={onClose}
        isLoading={isLoading}
        formName="company"
        allowDelete={!!selectedCompany?.id}
        onDelete={onDeleteCompany}
      >
        <div className="title">
          {isEdit ? 'EDITAR EMPRESA' : 'NOVA EMPRESA'}
        </div>
      </RegisterHeaderForm>
      <FormItemsWrapper>
        <FormItem noStyle name="id" />
        <Row>
          <FormItemInput label="Nome" name="name" maxLength={50} required />
        </Row>
        <Row>
          <FormItemInput label="Atividade" name="activity" maxLength={50} />
        </Row>
        <Row gutter={20}>
          <FormItemInput
            label="E-mail"
            name="email"
            type="email"
            placeholder="empresa@email.com"
            maxLength={50}
          />
        </Row>
        <Row>
          <FormItemDynamicPhone />
        </Row>
        <Row>
          <FormItemCNPJ readOnly={isEdit} />
        </Row>
        <Row>
          <FormItemInput
            label="Código Internacional"
            name="tin"
            maxLength={50}
            readOnly={isEdit}
          />
        </Row>
        <Row>
          <FormItemSelect
            name="company_type"
            label="Tipo da Empresa"
            dataList={allCompanyTypesData?.results}
            allowClear
            required
            mode="multiple"
            maxSelectOptions={5}
            initialSelectedOptions={form.getFieldValue('company_type')}
            onSelect={() => onSelectCompanyType()}
            onClear={() => onSelectCompanyType()}
          />
        </Row>
        {isStorage && (
          <Row>
            <FormItemSelect
              name="nature_of_cargo"
              label="Natureza da Carga"
              dataList={cargoNatureList}
              allowClear
              required={isStorage}
            />
          </Row>
        )}
      </FormItemsWrapper>
      <Collapse defaultActiveKey={['address_data']}>
        <Panel forceRender header="Endereço" key="address_data">
          <Row>
            <FormItemInput
              label="Endereço"
              name={['address', 'address']}
              maxLength={70}
            />
          </Row>
          <Row>
            <FormItemInput
              label="Complemento"
              name={['address', 'complement']}
              maxLength={70}
            />
          </Row>
          <Row gutter={20}>
            <FormItemInput
              label="Bairro"
              name={['address', 'neighborhood']}
              maxLength={70}
              colSpan={12}
            />
            <FormItemCEP
              name={['address', 'postal_code']}
              required={false}
              colSpan={12}
            />
          </Row>
          <Row gutter={20}>
            <FormItemInput
              label="Estado"
              name={['address', 'state']}
              colSpan={12}
              maxLength={70}
            />
            <FormItemInput
              label="Cidade"
              name={['address', 'city']}
              maxLength={70}
              colSpan={12}
            />
          </Row>
          <Row gutter={20}>
            <FormItemInput
              label="País"
              name={['address', 'country']}
              maxLength={70}
            />
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
}
