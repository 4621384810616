import {
  formatStopoverOperations,
  transformResponseOperation,
} from '../features/operation/OperationsData/formatOperationToDescription';
import {
  OperationalOperationType,
  GenericResponse,
  OperationalOperationToSave,
  StorageGroupbyProductType,
} from '../types';
import { getHeaders, tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export type OperationsResponse = GenericResponse & {
  results: OperationalOperationType[] | [];
};

export const operationApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getOperation: build.query<
      OperationalOperationType,
      { operationId?: number; stopoverId?: number }
    >({
      query: (params) => {
        return {
          url: `/operational/operations/${params.operationId}`,
          method: 'GET',
          headers: getHeaders,
          params: { stopover: params.stopoverId },
        };
      },
      transformResponse: (response: OperationalOperationType) =>
        transformResponseOperation(response),
    }),
    getOperationByStopvoer: build.query<
      OperationalOperationType[],
      { stopoverId?: number }
    >({
      query: (params) => {
        return {
          url: `/operational/operations`,
          method: 'GET',
          headers: getHeaders,
          params: { stopover: params.stopoverId },
        };
      },
      transformResponse: (response: OperationsResponse) =>
        formatStopoverOperations(response.results),
    }),
    createOperation: build.mutation<
      OperationalOperationType,
      OperationalOperationToSave
    >({
      query: (operation) => {
        return {
          url: '/operational/operations',
          method: 'POST',
          body: operation,
        };
      },
      // comentado pois ao criar operação pela tela do agente as requests são refeitas e fecha o modal
      // invalidatesTags: () => [
      //   { type: 'Stopover', code: '1' },
      //   { type: 'Stopover', code: '3' },
      // ],
    }),
    updateOperation: build.mutation<
      OperationalOperationType,
      OperationalOperationToSave
    >({
      query: (operation) => {
        return {
          url: `/operational/operations/${operation.id}`,
          method: 'PUT',
          body: operation,
        };
      },
      // comentado pois ao editar operação pela tela do agente as requests são refeitas e fecha o modal
      // invalidatesTags: () => [
      //   { type: 'Stopover', code: '1' },
      //   { type: 'Stopover', code: '3' },
      // ],
    }),
    patchOperation: build.mutation<
      OperationalOperationType,
      Partial<OperationalOperationToSave>
    >({
      query: (operation) => {
        return {
          url: `/operational/operations/${operation.id}`,
          method: 'PATCH',
          body: operation,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: result?.id },
          { type: 'OperationCalculus', code: result?.id },
        ]),
    }),
    deleteOperation: build.mutation<void, number>({
      query: (operationId) => {
        return {
          url: `operational/operations/${operationId}`,
          method: 'DELETE',
        };
      },
    }),
    getStorageGroupbyProduct: build.query<StorageGroupbyProductType[], number>({
      query: (operationId) => {
        return {
          url: `/operational/cargos-storage-merchant/group-by-product/${operationId}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
  }),
});

export const {
  useGetOperationQuery,
  useGetOperationByStopvoerQuery,
  useCreateOperationMutation,
  useUpdateOperationMutation,
  usePatchOperationMutation,
  useDeleteOperationMutation,
  useGetStorageGroupbyProductQuery,
} = operationApi;
