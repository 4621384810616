import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Skeleton, Space } from 'antd';

import { Button } from '../../../components/antd/Button';
import { Modal } from '../../../components/antd/Modal';
import { DockingChecklist, Stopover } from '../../../types';

type LiberateMooringProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setHasEdited?: (hasEdited: boolean) => void;
  checklist: DockingChecklist;
  stopover: Stopover;
  setStopover: any;
  patchStopover?: any;
  isLoadingChecklist?: boolean;
  isLoadingPatchStopover?: boolean;
};

export function ModalLiberateMooring(props: LiberateMooringProps) {
  const {
    showModal,
    setShowModal,
    setHasEdited,
    stopover,
    setStopover,
    patchStopover,
    isLoadingChecklist,
    isLoadingPatchStopover,
  } = props;

  const {
    necessary_consents,
    necessary_documentation,
    has_registered_products,
    expected_arrival,
    storage_confirmed,
    input_dwt,
  } = props.checklist;

  const requirements = [
    {
      name: 'Anuências necessárias',
      checked: necessary_consents || false,
    },
    {
      name: 'Documentações necessárias',
      checked: necessary_documentation || false,
    },
    {
      name: 'Informações de produtos',
      checked: has_registered_products || false,
    },
    {
      name: 'Previsão de chegada da embarcação',
      checked: expected_arrival || false,
    },
    {
      name: 'Armazenamento disponível confirmado',
      checked: storage_confirmed,
    },
    { name: 'DWT de entrada', checked: input_dwt || false },
  ];

  function closeModal() {
    if (setHasEdited) setHasEdited(false);
    setShowModal(false);
  }

  async function releaseDocking(
    dockingRelease: 'RELEASED_WITH_PENDING' | 'RELEASED'
  ) {
    const updatedStopover = await patchStopover({
      id: stopover.id,
      docking_release: dockingRelease,
    });
    if ('data' in updatedStopover) {
      setStopover(updatedStopover.data);
    }
    closeModal();
  }

  return (
    <Modal
      visible={showModal}
      title={<>Liberar para atracação</>}
      className="TOSPrimaryModal"
      onCancel={closeModal}
      width={620}
      footer={null}
    >
      <>
        {requirements.find((r) => r.checked === false) ? (
          <p>
            Existem requisitos que ainda não foram preenchidos para a liberação
            de atracação:
          </p>
        ) : null}
        <Skeleton loading={isLoadingChecklist}>
          <ul style={{ listStyle: 'none', paddingLeft: '10px' }}>
            {requirements.map((requirement) => (
              <li key={requirement.name} style={{ marginBottom: '10px' }}>
                {requirement.checked ? (
                  <CheckCircleFilled
                    style={{
                      color: 'var(--success-medium)',
                      marginRight: '10px',
                    }}
                  />
                ) : (
                  <CloseCircleFilled
                    style={{
                      color: 'var(--error-medium)',
                      marginRight: '10px',
                    }}
                  />
                )}
                {requirement.name}
              </li>
            ))}
          </ul>
        </Skeleton>
        <Row justify="end">
          <Space size={12}>
            <Button
              onClick={() => closeModal()}
              disabled={isLoadingPatchStopover}
            >
              Cancelar
            </Button>
            {requirements.find((r) => r.checked === false) ? (
              <Button
                type="primary"
                onClick={() => releaseDocking('RELEASED_WITH_PENDING')}
                loading={isLoadingPatchStopover}
              >
                Liberar assim mesmo
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => releaseDocking('RELEASED')}
                loading={isLoadingPatchStopover}
              >
                Liberar
              </Button>
            )}
          </Space>
        </Row>
      </>
    </Modal>
  );
}
