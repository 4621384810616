import { useState } from 'react';

import { Modal } from '../../components';
import { Stopover } from '../../types';
import { EndStaymentModal } from './endStaymentModal';
import { BaseActionModalProps } from './staymentActions';

type StaymentActionsModalProps = BaseActionModalProps & {
  stopover?: Stopover;
  endStayment: () => void;
};
export function StaymentActionsModal(props: StaymentActionsModalProps) {
  const {
    isVisible,
    setIsVisible,
    stopover,
    currentDocking,
    updateStopover,
    endStayment,
    // isEndedStayment,
    // isReleasedEndStayment,
    status,
  } = props;
  const [modalTitle, setModalTitle] = useState('Finalizar estadia');

  return (
    <Modal
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      title={modalTitle}
      className="TOSPrimaryModal"
      width={624}
      footer={null}
    >
      {/* {!isReleasedEndStayment && (
        <ReleaseEndStaymentModal setIsVisible={setIsVisible} onEnd={() => {}} />
      )} */}
      {status === 'END_STAYMENT' && (
        <EndStaymentModal
          setIsVisible={setIsVisible}
          stopover={stopover}
          currentDocking={currentDocking}
          updateStopover={updateStopover}
          onEndStayment={endStayment}
        />
      )}
    </Modal>
  );
}
