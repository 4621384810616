import { PageHeader } from 'antd';
import styled from 'styled-components';

export const Header = styled(PageHeader)`
  padding-left: 0px;
  padding-right: 0px;
  background-color: var(--neutral_lightest);
  & .ant-page-header-heading-left {
    width: 100%;
    position: relative;
  }
  & .ant-page-header-heading-sub-title {
    margin-left: 20px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: normal;
    color: #5f7b91;
  }
  & .ant-page-header-heading-title {
    font-weight: bold;
    font-size: 24px;
    color: #262626;
  }
`;
