import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Form,
  GenericDrawerHeader,
  ModalBlockNavigation,
} from '../../../components';
import { Alert } from '../../../components/antd/Alert';
import {
  useCreateStoppageMutation,
  useDeleteStoppageMutation,
  useUpdateStoppageMutation,
} from '../../../services/stoppageApi';
import { useGetWorkShiftsImpactedQuery } from '../../../services/workShiftApi';
import { Stopover, StoppageType } from '../../../types';
import { formatStoppageToSave } from '../../../utils/formatters';
import { createTimeString } from '../../../utils/utils';
import { FormItemsWorkShifStoppages } from './formItemsWofkShiftStoppages';
import { WorkShiftStoppagesDrawerHeader } from './styles';

type WorkShiftStoppagesEditFormProps = {
  onBack: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  setIsVisibleForm: (value: boolean) => void;
  blockNavigate: boolean;
  selectedStopover: Stopover;
  setSelectedStopover: Dispatch<SetStateAction<Stopover>>;
  selectedStoppage?: StoppageType;
  setSelectedStoppage: Dispatch<SetStateAction<StoppageType>>;
};

export function WorkShiftStoppagesEditForm(
  props: WorkShiftStoppagesEditFormProps
) {
  const {
    onBack,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    setIsVisibleForm,
    blockNavigate,
    selectedStopover,
    setSelectedStopover,
    selectedStoppage,
    setSelectedStoppage,
  } = props;
  const [form] = Form.useForm();
  const isEdit = !!selectedStoppage && !isEmpty(selectedStoppage);
  const [shiftImpactedFilter, setShiftImpactedFilter] = useState({});

  const [
    createStoppage,
    { isSuccess: isSuccessCreateStoppage, isLoading: isLoadingCreateStoppage },
  ] = useCreateStoppageMutation();
  const [
    updateStoppage,
    { isSuccess: isSuccessUpdateStoppage, isLoading: isLoadingUpdateStoppage },
  ] = useUpdateStoppageMutation();
  const [deleteStoppage, { isLoading: isLoadingDeleteStoppage }] =
    useDeleteStoppageMutation();

  const { data: workShiftsImpacted } =
    useGetWorkShiftsImpactedQuery(shiftImpactedFilter);

  useEffect(() => {
    if (isEmpty(selectedStoppage)) {
      form.setFieldsValue({});
      form.resetFields();
    } else {
      form.setFieldsValue(formatStoppageToForm(selectedStoppage));
    }
  }, [selectedStoppage]);

  useEffect(() => {
    if (isSuccessCreateStoppage || isSuccessUpdateStoppage) {
      setBlockNavigate(false);
      setIsFormChanged(false);
      setIsVisibleForm(false);
      afterCloseModal();
    }
  }, [isSuccessCreateStoppage, isSuccessUpdateStoppage]);

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      } else {
        setIsVisibleForm(false);
      }
    }
  }

  function afterCloseModal() {
    form.resetFields();
    setSelectedStoppage({} as StoppageType);
    setShiftImpactedFilter({});
  }

  function drawerHeaderTitle() {
    return (
      <WorkShiftStoppagesDrawerHeader>
        <h1>
          {!isEdit ? 'Nova paralisação' : selectedStoppage.motive?.description}
        </h1>
      </WorkShiftStoppagesDrawerHeader>
    );
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      const stoppage: StoppageType = {
        ...selectedStoppage,
        ...values,
        operation_id: selectedStopover.operation?.id,
      };
      const stoppageToSave = formatStoppageToSave(stoppage);
      if (isEdit) {
        const updatedStoppage = await updateStoppage(stoppageToSave);
        if ('data' in updatedStoppage && setSelectedStopover) {
          const { data } = updatedStoppage;
          const stoppages = selectedStopover.operation.stoppages?.map(
            (stoppage: StoppageType) => {
              if (stoppage.id === data.id) {
                return data;
              }
              return stoppage;
            }
          );
          setSelectedStopover({
            ...selectedStopover,
            operation: { ...selectedStopover.operation, stoppages },
          });
          message.success('Paralisação atualizada com sucesso!', 5);
        }
      } else {
        const createdStoppage = await createStoppage(stoppageToSave);
        if ('data' in createdStoppage && setSelectedStopover) {
          const oldStoppages = selectedStopover?.operation?.stoppages || [];
          setSelectedStopover({
            ...selectedStopover,
            operation: {
              ...selectedStopover.operation,
              stoppages: [...oldStoppages, createdStoppage.data],
            },
          });
          message.success('Paralisação criada com sucesso!', 5);
        }
      }
    });
  }

  function getStoppageAlertDescription() {
    const periods = workShiftsImpacted?.results.reduce((groups: any, item) => {
      return {
        ...groups,
        [item.work_period_index]: {
          index: item.work_period_index,
          work_shifts: [
            ...(groups[item.work_period_index]?.work_shifts || []),
            {
              shift_start: item.shift_start,
              shift_end: item.shift_end,
              index: item.index,
            },
          ],
        },
      };
    }, {});

    return Object.keys(periods).map((key: string, index: number) => {
      const periodNumber = String(periods[key].index + 1).padStart(2, '0');

      return (
        <div key={index}>
          <span>Período {periodNumber}: </span>
          <ul style={{ marginTop: '5px' }}>
            {periods[key].work_shifts.map((shift: any, shiftIndex: number) => {
              return (
                <li key={shiftIndex} style={{ fontWeight: '700' }}>
                  {createTimeString(shift.shift_start)} -{' '}
                  {createTimeString(shift.shift_end)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  }

  async function handleDelete() {
    if (isEdit && selectedStoppage?.id) {
      await deleteStoppage(selectedStoppage?.id);
      setSelectedStopover({
        ...selectedStopover,
        operation: {
          ...selectedStopover.operation,
          stoppages: selectedStopover.operation.stoppages?.filter(
            (stoppage) => stoppage.id !== selectedStoppage?.id
          ),
        },
      });

      setBlockNavigate(false);
      setIsFormChanged(false);
      setIsVisibleForm(false);
      afterCloseModal();
      message.success('Paralisação removida com sucesso');
    }
  }

  async function onTryDelete() {
    confirm({
      title: 'Deseja realmente remover esta paralisação?',
      icon: <ExclamationCircleOutlined />,
      content: 'A paralisação removida não poderá ser recuperada',
      onOk() {
        handleDelete();
      },
    });
  }

  function formatStoppageToForm(stoppage: StoppageType | undefined) {
    return !stoppage || isEmpty(stoppage)
      ? { is_total: false }
      : {
          ...stoppage,
          start: moment(stoppage.start),
          end: moment(stoppage.end),
          motive: stoppage.motive?.id,
          compartments: stoppage.compartments?.map((comp) => comp.id),
          is_total: stoppage.is_total,
          operation_cranes: stoppage.operation_cranes.map(
            (opCrane) => opCrane.id
          ),
        };
  }
  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name="stoppages_edit_form"
      id="stoppages_edit_form"
      onFinish={handleFormSubmit}
      initialValues={formatStoppageToForm(selectedStoppage)}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <GenericDrawerHeader
        title={drawerHeaderTitle()}
        showBackButton
        onBack={onClose}
        formName="stoppages_edit_form"
        disabledSaveButton={!formIsChanged}
        isLoadingDelete={isLoadingDeleteStoppage}
        isLoadingSave={isLoadingCreateStoppage || isLoadingUpdateStoppage}
        onDelete={isEdit ? onTryDelete : undefined}
      />
      <ModalBlockNavigation
        name="stoppages_edit_form"
        key="stoppages_edit_form"
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <FormItemsWorkShifStoppages
        style={{ padding: '24px 16px' }}
        form={form}
        operationId={selectedStopover.operation?.id}
        operationCranes={selectedStopover.operation.operation_cranes}
        setIsFormChanged={setIsFormChanged}
        setShiftImpactedFilter={setShiftImpactedFilter}
        selectedStoppage={selectedStoppage || ({} as StoppageType)}
      />
      {workShiftsImpacted?.count && workShiftsImpacted?.count > 0 ? (
        <Alert
          style={{ margin: '16px' }}
          type="warning"
          showIcon
          message="Esta paralisação impacta os seguintes turnos:"
          description={getStoppageAlertDescription()}
        />
      ) : null}
    </Form>
  );
}
