import { Dispatch, SetStateAction } from 'react';

import { Modal } from '../../../../../../../components';
import {
  ContainerTypeDescription,
  Stopover as StopoverType,
} from '../../../../../../../types';
import { ContainersByCraneData } from './containersByCraneData/ContainersByCraneData';

type ContainersByCraneModalProps = {
  containers: ContainerTypeDescription[];
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  craneName?: string;
  operationCraneId?: number;
  craneId?: string;
  selectedStopover: StopoverType;
  setSelectedStopover?: Dispatch<SetStateAction<StopoverType>>;
  pageContext?: 'OPERATOR_AREA';
};

export function ContainersByCraneModal(props: ContainersByCraneModalProps) {
  const {
    containers,
    isVisible,
    setIsVisible,
    craneName,
    operationCraneId,
    craneId,
    selectedStopover,
    setSelectedStopover,
    pageContext,
  } = props;
  return (
    <Modal
      className="TOSPrimaryModal"
      // title={craneName || 'Contêineres'}
      title="Contêineres"
      visible={isVisible}
      width={905}
      footer={false}
      onCancel={() => setIsVisible(false)}
    >
      <ContainersByCraneData
        containers={containers}
        operationCraneId={operationCraneId}
        selectedStopover={selectedStopover}
        setSelectedStopover={setSelectedStopover}
        craneId={craneId}
        pageContext={pageContext}
      />
    </Modal>
  );
}
