import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { isNullOrUndefined } from '../../../utils/utils';

type PanelHeaderTitleProps = {
  title: string;
  updatedAt: string | null | undefined;
  isChanged: boolean;
  onRemove: (e: any) => void;
};

export function PanelHeaderTitle(props: PanelHeaderTitleProps) {
  const { title, updatedAt, isChanged, onRemove } = props;

  return (
    <div className="header-title">
      <span>{title}</span>
      <div className="header-description">
        {!isNullOrUndefined(updatedAt) && (
          <span>Atualizado em: {updatedAt}</span>
        )}
        <Button
          style={{
            fontWeight: '700',
          }}
          type="text"
          htmlType="submit"
          disabled={!isChanged}
          onClick={(e) => e.stopPropagation()}
          icon={
            <CheckOutlined
              style={{
                color: isChanged
                  ? 'var(--suape-black)'
                  : 'var(--neutral_light)',
              }}
            />
          }
        >
          Salvar
        </Button>
        <Button
          style={{
            fontWeight: '700',
          }}
          type="text"
          icon={<DeleteOutlined style={{ color: 'var(--suape-black)' }} />}
          onClick={(e) => onRemove(e)}
        >
          Excluir
        </Button>
      </div>
    </div>
  );
}
