import { useEffect, useState } from 'react';

import { Modal } from '../../components';
import { EndUndockingModal } from './endUndockingModal';
import { PilotExpectedOnBoardUndocking } from './pilotExpectedOnBoardUndocking';
import { PilotLeaveOnBoardUndocking } from './pilotLeaveOnBoardUndocking';
import { ReleaseUndockingModal } from './releaseUndockingModal';
import { StartUndockingModal } from './startUndockingModal';
import { BaseActionModalProps } from './staymentActions';

type UndockingActionsModalProps = BaseActionModalProps & {
  releaseUndocking: () => void;
  startUndocking: () => void;
  endUndocking: () => void;
  pilotExpectedOnBoard: () => void;
  pilotLeaveOnBoardUndocking: () => void;
};
export function UndockingActionsModal(props: UndockingActionsModalProps) {
  const [modalTitle, setModalTitle] = useState('');

  const {
    isVisible,
    setIsVisible,
    stopover,
    currentDocking,
    releaseUndocking,
    startUndocking,
    endUndocking,
    pilotExpectedOnBoard,
    updateDocking,
    pilotLeaveOnBoardUndocking,
    isLoadingUpdateDocking,
    status,
  } = props;
  useEffect(() => {
    if (
      status === 'RELEASE_UNDOCKING' ||
      status === 'PILOT_EXPECTED_ON_BOARD_UNDOCKING'
    ) {
      setModalTitle('Liberar desatracação');
    } else if (status === 'UNDOCK') {
      setModalTitle('Desatracar');
    } else if (status === 'END_UNDOCKING') {
      setModalTitle('Finalizar desatracação');
    } else if (status === 'END_OF_UNDOCKING_MANOEUVRE') {
      setModalTitle('Fim da manobra de desatracação');
    }
  });
  return (
    <Modal
      visible={isVisible}
      onCancel={() => setIsVisible(false)}
      title={modalTitle}
      className="TOSPrimaryModal"
      width={624}
      footer={null}
    >
      {status === 'PILOT_EXPECTED_ON_BOARD_UNDOCKING' ? (
        <PilotExpectedOnBoardUndocking
          setIsVisible={setIsVisible}
          onPilotExpectedOnBoard={pilotExpectedOnBoard}
          updateDocking={updateDocking}
          currentDocking={currentDocking}
        />
      ) : status === 'RELEASE_UNDOCKING' ? (
        <ReleaseUndockingModal
          setIsVisible={setIsVisible}
          onRelease={releaseUndocking}
          stopover={stopover}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : status === 'UNDOCK' ? (
        <StartUndockingModal
          setIsVisible={setIsVisible}
          onStartUndocking={startUndocking}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : status === 'END_UNDOCKING' ? (
        <EndUndockingModal
          setIsVisible={setIsVisible}
          onEndUndocking={endUndocking}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : status === 'END_OF_UNDOCKING_MANOEUVRE' ? (
        <PilotLeaveOnBoardUndocking
          setIsVisible={setIsVisible}
          onPilotLeaveOnBoard={pilotLeaveOnBoardUndocking}
          currentDocking={currentDocking}
          updateDocking={updateDocking}
          isLoadingUpdateDocking={isLoadingUpdateDocking}
        />
      ) : null}
    </Modal>
  );
}
