import moment from 'moment';

import { Descriptions, FormatItem } from '../../components';
import { TotalMovimentationByPeriodIdType, WorkPeriodType } from '../../types';
import { shiftModeList } from '../../utils/lists';
import { MovimentationByPeriod } from './operationMovimentation/movimentationByPeriodTable';

type OperationPeriodDescriptionProps = {
  workPeriod: WorkPeriodType;
  movimentationProductsByPeriod: TotalMovimentationByPeriodIdType;
  operationType: string;
};
export function OperationPeriodDescription(
  props: OperationPeriodDescriptionProps
) {
  const { workPeriod, movimentationProductsByPeriod, operationType } = props;
  return (
    <div className="panel-content">
      <Descriptions column={2}>
        <Descriptions.Item label="Início do período">
          <FormatItem>
            {moment(workPeriod.start_of_period).format('DD/MM/yyyy')}
          </FormatItem>
        </Descriptions.Item>
        <Descriptions.Item label="Modalidade dos turnos">
          <FormatItem>
            {
              shiftModeList.find((mode) => mode.id === workPeriod?.shift_mode)
                ?.name
            }
          </FormatItem>
        </Descriptions.Item>
      </Descriptions>
      {workPeriod.id &&
        movimentationProductsByPeriod[workPeriod.id] &&
        movimentationProductsByPeriod[workPeriod.id].length > 0 && (
          <MovimentationByPeriod
            movimentationsByPeriod={
              movimentationProductsByPeriod[workPeriod.id]
            }
            operationType={operationType}
          />
        )}
    </div>
  );
}
