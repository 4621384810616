import { isBoolean } from 'lodash';
import { CSSProperties, ReactNode } from 'react';

import { isNullOrUndefined } from '../../utils/utils';

type FormatItemProps = {
  children: ReactNode;
  style?: CSSProperties;
  suffix?: string;
};

// Formata item do Description
export function FormatItem(props: FormatItemProps) {
  const { children, style, suffix } = props;
  return isNullOrUndefined(children) ? (
    <span style={{ fontStyle: 'italic', color: 'var(--neutral_medium)' }}>
      Não informado
    </span>
  ) : isBoolean(children) ? (
    <span>{children === true ? 'Sim' : 'Não'}</span>
  ) : (
    <span style={style}>
      {children} {suffix}
    </span>
  );
}
