import 'react-calendar-timeline/lib/Timeline.css';

import {
  EyeFilled,
  DownOutlined,
  FilterFilled,
  ReloadOutlined,
} from '@ant-design/icons';
import { Col, message, Row, Spin } from 'antd';
import { debounce } from 'lodash';
import moment, { Moment } from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline';

import 'moment/locale/pt-br';

import {
  Form,
  ScreenLayout,
  Button,
  Wrapper,
  Drawer,
  Select,
} from '../../components';
import {
  useGetDockingsByDateQuery,
  useUpdateDockingWindowMutation,
} from '../../services/dockingApi';
import { useGetBerthsQuery } from '../../services/dockingPlacesApi';
import { useGetMaintenancesQuery } from '../../services/maintenancesApi';
import { useGetPumpingsQuery } from '../../services/pumpingApi';
import { useLazyGetStopoverQuery } from '../../services/stopoverApi';
import {
  BerthMaintenance as BerthMaintenanceType,
  CompanyType,
  DockingPlace as DockingPlaceType,
  Docking as DockingType,
  Product as ProductType,
  PumpingType,
  ShipType,
  Stopover as StopoverType,
} from '../../types';
import { collapsePanelsListDocking } from '../../utils/lists';
import {
  berthRowHeight,
  createDate,
  generateRandomIntegerInRange,
  isNullOrUndefined,
} from '../../utils/utils';
import { isAllowedInBerth } from '../../utils/validators';
import { AnchoringForm } from '../docking/anchoringForm';
import { DockingDrawer } from '../docking/DockingDrawer';
import { BerthMaintenanceDrawer } from '../registers/berth/berthMaintenances/berthMaintenanceForm/berthMaintenanceDrawer';
import { formatBerthMaintenanceToForm } from '../registers/berth/berthMaintenances/formatters';
import { AnchoringWindowTimeLine } from './anchoringWindowTimeLine';
import { dayRenderer } from './dayRenderer';
import { dockingPlaceRenderer } from './dockingPlaceRenderer';
import { hourRenderer } from './hourRenderer';
import { itemRenderer } from './itemRenderer';
import { sidebar } from './sidebarRenderer';
import { StatusButtonsCol } from './statusButtonsCol';

moment.locale('pt-br');

const MAX_TIME_WINDOW_DAYS = 20;

const dockingPlaceDatesList: {
  placeType: string;
  dates: Array<
    | 'expected_berthing'
    | 'expected_unberthing'
    | 'expected_anchoring'
    | 'expected_unanchoring'
  >;
}[] = [
  {
    placeType: 'BERTH',
    dates: ['expected_berthing', 'expected_unberthing'],
  },
  {
    placeType: 'ANCHORING',
    dates: ['expected_anchoring', 'expected_unanchoring'],
  },
];

type GroupType = {
  id: string;
  height: number;
  title: ReactNode;
  type: string;
  name: string;
  dwt: number;
  docking_place_type: string;
  ship_types: ShipType[];
};

export type ItemType = {
  canMove?: boolean;
  canResize?: boolean;
  index?: number;
  code?: string;
  expected_start?: string | null | Moment;
  expected_finish?: string | null | Moment;
  group: string;
  name?: string;
  title: string;
  subtitle?: string;
  hover?: string;
  start_time: number;
  end_time: number;
  docking_place: { tag: string; name: string; place_type: string };
  stopover?: StopoverType;
  id: number;
  products: ProductType[];
  type:
    | 'DOCKING'
    | 'MAINTENANCE'
    | 'PUMP'
    | 'PUMPING_ORIGIN'
    | 'PUMPING_DESTINATION';
  input_dwt?: number;
  docking_in_line?: string;
  docking_alongside?: string;
  // campos de manutenção
  companies?: string;
  maintenanceId?: number;
  maintenance?: BerthMaintenanceType;
  // campo de bombeio
  pumping?: PumpingType;
  docking_window_status?: string;
};

export function AnchoringWindow() {
  // used to show or hide docking drawer
  const [isDockingDrawerVisible, setIsDockingDrawerVisible] = useState(false);
  const [isAnchoringDrawerVisible, setIsAnchoringDrawerVisible] =
    useState(false);
  const [isBerthMaintenanceDrawerVisible, setIsBerthMaintenanceDrawerVisible] =
    useState(false);
  // initial and final time used to render calendar
  const [timeStart, setTimeStart] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 })
  );
  const [timeEnd, setTimeEnd] = useState(moment(timeStart).add(48, 'hour'));
  // time used to render month and year
  const [timeRendering, setTimeRendering] = useState(moment().toDate());
  // initial and final time used to query
  const [queryStartTime, setQueryStartTime] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 }).subtract(2, 'day')
  );
  const [queryEndTime, setQueryEndTime] = useState(
    moment().set({ hour: 0, minute: 0, second: 0 }).add(7, 'day')
  );
  // array with selected docking id, used to know where to render the popover, will always have a single item
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);

  const [selectedDocking, setSelectedDocking] = useState<DockingType>(
    {} as DockingType
  );
  const [selectedBerth, setSelectedBerth] = useState({} as DockingPlaceType);
  const [selectedBerthMaintenance, setSelectedBerthMaintenance] = useState(
    {} as BerthMaintenanceType
  );
  const [groupHour, setGroupHour] = useState<number>(2);

  const [currentStopover, setCurrentStopover] = useState<StopoverType>(
    {} as StopoverType
  );
  const [getStopoverSelected] = useLazyGetStopoverQuery();

  const [filterNameOrImo, setFilterNameOrImo] = useState('');

  const [form] = Form.useForm();

  // query dockings
  const {
    data: dockingsData,
    isSuccess: isSuccessLoadingDocking,
    isFetching: isFetchingDockings,
    refetch: refetchDockings,
  } = useGetDockingsByDateQuery({
    initial_expected_berthing: queryStartTime.format('YYYY-MM-DD'),
    final_expected_berthing: queryEndTime.format('YYYY-MM-DD'),
    name_or_imo: filterNameOrImo,
    order_by: 'expected_berthing',
  });

  const { data: maintenanceData } = useGetMaintenancesQuery({
    initial_expected_start: queryStartTime.format('YYYY-MM-DD'),
    final_expected_start: queryEndTime.format('YYYY-MM-DD'),
  });

  const { data: pumpingsData } = useGetPumpingsQuery({
    initial_expected_start: queryStartTime.format('YYYY-MM-DD'),
    final_expected_start: queryEndTime.format('YYYY-MM-DD'),
  });

  // query berths
  const { data: berthData, isSuccess: isSuccessLoadingBerth } =
    useGetBerthsQuery({ shipType: '' });
  // update docking mutation
  const [updateDocking, { isLoading: isLoadingUpdateDocking }] =
    useUpdateDockingWindowMutation();
  // states with items and groups to be rendered
  const [itemsToRender, setItemsToRender] = useState<ItemType[]>(
    formatDockingItems(dockingsData?.results || [])
  );
  const [groupsToRender, setGroupsToRender] = useState<GroupType[]>(
    formatGroup(berthData?.results || [])
  );

  const [openCollapsePanelsDocking, setOpenCollapsePanelsDocking] = useState<
    string | string[]
  >(collapsePanelsListDocking);

  function formatPumpingItems(pumpings: PumpingType[]): ItemType[] {
    const items: ItemType[] = [];
    pumpings.forEach((pumping) => {
      // adicionar só se tiver confirmado
      const id = Number(
        `${pumping.id}${moment(pumping.expected_start).valueOf()}${moment(
          pumping.expected_finish
        ).valueOf()}`
      );
      const item = {
        id,
        name: 'Bombeio entre terminais',
        canMove: false,
        canResize: false,
        subtitle: `De: ${pumping.origin_terminal.name} (${
          pumping.origin_line.name
        })  Para: ${pumping.destination_terminal?.name} (${
          pumping.destination_line?.name || 'Não informado'
        })`,
        title: 'Bombeio entre terminais',
        start_time: moment(pumping.expected_start).valueOf(),
        end_time: moment(pumping.expected_finish).valueOf(),
        group: String(pumping.origin_line.berth),
        products: [],
        pumping,
        docking_place: {
          tag: String(pumping.origin_line.berth),
          name: String(pumping.origin_line.berth),
        } as DockingPlaceType,
      };
      items.push({
        ...item,
        type: 'PUMPING_ORIGIN',
        group: String(pumping.origin_line.berth),
      });
      if (pumping.destination_line && pumping.is_confirmed) {
        items.push({
          ...item,
          type: 'PUMPING_DESTINATION',
          group: String(pumping.destination_line.berth),
          id: -item.id,
        });
      }
    });
    return items;
  }

  function formatMaintenanceItems(
    maintenances: BerthMaintenanceType[]
  ): ItemType[] {
    const items: ItemType[] = [];
    maintenances.forEach((maintenance) => {
      if (
        maintenance.expected_start &&
        maintenance.expected_finish &&
        maintenance.berths
      ) {
        maintenance.berths.forEach((berth) => {
          items.push({
            id: generateRandomIntegerInRange(-1000, 0),
            maintenanceId: maintenance.id || 0,
            group: (berth as DockingPlaceType).tag || '',
            type: 'MAINTENANCE',
            name: maintenance.description,
            canMove: false,
            canResize: false,
            subtitle: maintenance.description,
            title: maintenance.description || 'Manutenção',
            start_time: moment(maintenance.expected_start).valueOf(),
            end_time: moment(maintenance.expected_finish).valueOf(),
            products: [],
            docking_place: berth as DockingPlaceType,
            companies: (maintenance.companies as CompanyType[])
              .map((company) => company.name)
              .join(', '),
            expected_start: maintenance.expected_start,
            expected_finish: maintenance.expected_finish,
            maintenance,
          });
        });
      }
    });
    return items;
  }

  // format items and groups to be rendered when these data change
  useEffect(() => {
    setItemsToRender([
      ...formatPumpingItems(pumpingsData?.results || []),
      ...formatMaintenanceItems(maintenanceData?.results || []),
      ...formatDockingItems(dockingsData?.results || []),
    ]);
    setGroupsToRender(formatGroup(berthData?.results || []));
    checkFilterByNameOrImo();
  }, [dockingsData?.results, berthData?.results, maintenanceData?.results]);

  // needs to call format groups when itemsToRender changes so it can resize group's height
  useEffect(() => {
    setGroupsToRender(formatGroup(berthData?.results || []));
  }, [itemsToRender]);

  // format groups
  function formatGroup(dockingPlaces: DockingPlaceType[]): GroupType[] {
    const groups: GroupType[] = [];
    const anchoringDockingPlaces: GroupType[] = [];
    let arrayToPush: GroupType[];
    dockingPlaces.forEach((dockingPlace) => {
      if (dockingPlace.place_type === 'ANCHORING') {
        arrayToPush = anchoringDockingPlaces;
      } else {
        arrayToPush = groups;
      }
      arrayToPush.push({
        // ...dockingPlace,
        type: dockingPlace.place_type,
        id: dockingPlace.tag,
        name: dockingPlace.name,
        dwt: dockingPlace.dwt,
        ship_types: dockingPlace.ship_types,
        docking_place_type: dockingPlace.place_type,
        height:
          itemsToRender.filter(
            (item) =>
              ![
                'PUMPING_ORIGIN',
                'PUMPING_DESTINATION',
                'MAINTENANCE',
              ].includes(item.type) &&
              item.docking_place.tag === dockingPlace.tag
          ).length * berthRowHeight,
        title: <div>{dockingPlace.name}</div>,
      });
    });
    return anchoringDockingPlaces.concat(groups);
  }
  // format items
  function formatDockingItems(dockings: DockingType[]): ItemType[] {
    const items: ItemType[] = [];
    dockings.forEach((item: DockingType) => {
      if (
        item.docking_place &&
        item.docking_place.tag &&
        ((item.expected_berthing && item.expected_unberthing) ||
          (item.expected_anchoring && item.expected_unanchoring))
      ) {
        const typeDocking =
          dockingPlaceDatesList.find(
            (dpdates) => dpdates.placeType === item.docking_place?.place_type
          ) || dockingPlaceDatesList[0];
        let start_time = item.expected_berthing || item.expected_anchoring;
        let end_time = item.expected_unberthing || item.expected_unanchoring;

        if (item.first_cable_tied_in) {
          start_time = item.first_cable_tied_in;
        }

        if (item.last_cable_untied_in) {
          end_time = item.last_cable_untied_in;
        }

        if (item.real_time_of_anchoring) {
          start_time = item.real_time_of_anchoring;
        }
        if (item.real_time_of_unanchoring) {
          end_time = item.real_time_of_unanchoring;
        }
        items.push({
          group: item.docking_place.tag,
          start_time: moment(start_time).valueOf(),
          end_time: moment(end_time).valueOf(),
          docking_place: item.docking_place,
          stopover: item.stopover,
          id: item.id || 0,
          products: item.products || [],
          type: 'DOCKING',
          title: item.stopover?.vessel?.name || '',
          index: item.index,
          code: item.code,
          expected_start: item?.[typeDocking.dates[0]],
          expected_finish: item?.[typeDocking.dates[1]],
          docking_window_status: item.docking_window_status,
        });
      }
    });
    return items;
  }
  function onClickVesselNameSideColumn(item: ItemType) {
    setTimeStart(moment(item.start_time));
    setTimeEnd(moment(item.start_time).add(36, 'hour'));
    setTimeRendering(new Date(item.start_time));
  }

  function checkFilterByNameOrImo() {
    if (
      !isNullOrUndefined(filterNameOrImo) &&
      dockingsData &&
      dockingsData.results.length > 0
    ) {
      setStartTimeWithFirstDocking(dockingsData.results[0]);
    }
  }

  function setStartTimeWithFirstDocking(docking: DockingType) {
    if (docking.expected_berthing) {
      setTimeStart(moment(docking.expected_berthing, 'YYYY-MM-DD HH:mm:ss'));
      setTimeEnd(
        moment(docking.expected_berthing, 'YYYY-MM-DD HH:mm:ss').add(36, 'hour')
      );
      setTimeRendering(new Date(docking.expected_berthing));
    }
  }

  function onSearchDockingByName(e: any) {
    setFilterNameOrImo(e.target.value);
  }

  function onOpenNewDocking() {
    setCurrentStopover({} as StopoverType);
    setSelectedDocking({} as DockingType);
    onItemDeselect();
    setIsDockingDrawerVisible(true);
  }

  // error message to be shown when berth is ocuppied
  const messageString = (
    berth: string,
    dateStart: string,
    dateEnd: string,
    vesselName: string
  ) => (
    <>
      O local <b>{berth}</b> está ocupado entre as horas <b>{dateStart}</b> e{' '}
      <b>{dateEnd}</b>, pela embarcação <b>{vesselName}</b>
    </>
  );

  // called when tries to resize an item, validate if berth is occupied in the selected hour
  function itemResize(itemId: number, time: number, edge: string) {
    const dateToChange =
      edge === 'left' ? 'start_time' : edge === 'right' ? 'end_time' : '';

    const vessel = itemsToRender.find((item) => item.id === itemId);

    const currentTimeStart = moment(vessel?.start_time);
    const currentTimeEnd = moment(vessel?.end_time);

    const newTime = moment(time);

    const vesselBerth = vessel?.group;

    const berth =
      groupsToRender.find((berthGroup) => berthGroup.id === vesselBerth) ||
      ({} as GroupType);

    const typeDocking =
      dockingPlaceDatesList.find(
        (item) => item.placeType === berth.docking_place_type
      ) || dockingPlaceDatesList[0];

    const dataToChange =
      edge === 'left'
        ? typeDocking.dates[0]
        : edge === 'right'
        ? typeDocking.dates[1]
        : '';

    const berthVessels = itemsToRender.filter(
      (item) => item.group === vesselBerth && item.id !== itemId
    );

    const canResize = [];

    if (berthVessels.length > 0) {
      berthVessels.forEach((item) => {
        const vesselStartTime = moment(item.start_time);
        const vesselEndTime = moment(item.end_time);
        const occupiedBerthString = messageString(
          berth.name,
          vesselStartTime.format('HH:mm'),
          vesselEndTime.format('HH:mm'),
          item.stopover?.vessel?.name || ''
        );
        if (newTime.isBetween(vesselStartTime, vesselEndTime)) {
          canResize.push(false);
          return message.error(occupiedBerthString, 5);
        }
        if (
          edge === 'right' &&
          newTime >= vesselStartTime &&
          newTime >= vesselEndTime &&
          currentTimeStart <= vesselStartTime
        ) {
          canResize.push(false);
          return message.error(occupiedBerthString, 5);
        }
        if (
          edge === 'left' &&
          newTime <= vesselStartTime &&
          newTime <= vesselEndTime &&
          currentTimeEnd >= vesselEndTime
        ) {
          canResize.push(false);
          return message.error(occupiedBerthString, 5);
        }
        canResize.push(true);
      });
    } else {
      canResize.push(true);
    }

    if (!canResize.includes(false)) {
      resizeItem(dataToChange, dateToChange, time, itemId);
      onItemDeselect();
    }
  }
  // actually resize an item
  async function resizeItem(
    dataToChange:
      | 'expected_berthing'
      | 'expected_unberthing'
      | 'expected_anchoring'
      | 'expected_unanchoring'
      | '',
    dateToChange: 'start_time' | 'end_time' | '',
    time: number,
    dockingId: number
  ) {
    // used to update docking data
    const dataUpdateObj = {
      [dataToChange]: moment(time).utc().format(),
      id: dockingId,
    };
    // used to update local docking data
    const updateObj = {
      [dateToChange]: moment(time).valueOf(),
      [dataToChange]: moment(time).valueOf(),
    };
    const updatedDocking = await updateDocking(dataUpdateObj);
    if ('data' in updatedDocking) {
      setItemsToRender(
        itemsToRender.map((item) =>
          item.id === dockingId ? { ...item, ...updateObj } : item
        )
      );
    }
  }

  // called when a docking update is saved
  function onSaveUpdate(dockingId: number, updateObj: any) {
    const dateObj = {
      start_time: moment(updateObj.expected_berthing).valueOf(),
      end_time: moment(updateObj.expected_unberthing).valueOf(),
      group: updateObj.docking_place?.tag,
    };
    const hasItem = itemsToRender.find((item) => item.id === dockingId);
    if (hasItem) {
      setItemsToRender(
        itemsToRender.map((item) =>
          item.id === dockingId ? { ...item, ...dateObj, ...updateObj } : item
        )
      );
    } else {
      refetchDockings();
    }
  }

  // called when callendar bounds change
  function onBoundsChange(canvasTimeStart: number, canvasTimeEnd: number) {
    const canvasStartTime = moment(canvasTimeStart);
    const canvasEndTime = moment(canvasTimeEnd);

    if (isNullOrUndefined(form.getFieldValue('search'))) {
      setFilterNameOrImo('');

      // this limits the query days range
      if (canvasStartTime.isBefore(queryStartTime)) {
        setQueryStartTime(canvasStartTime);
        const diffTimeDays = moment
          .duration(queryEndTime.diff(canvasStartTime))
          .asDays();
        if (diffTimeDays > MAX_TIME_WINDOW_DAYS) {
          const exceededDays = diffTimeDays - MAX_TIME_WINDOW_DAYS;
          setQueryEndTime(queryEndTime.subtract(exceededDays, 'days'));
        }
      }

      if (canvasEndTime.isAfter(queryEndTime)) {
        setQueryEndTime(canvasEndTime);
        const diffTimeDays = moment
          .duration(canvasEndTime.diff(queryStartTime))
          .asDays();
        if (diffTimeDays > MAX_TIME_WINDOW_DAYS) {
          const exceededDays = diffTimeDays - MAX_TIME_WINDOW_DAYS;
          setQueryStartTime(queryStartTime.add(exceededDays, 'days'));
        }
      }
    }
  }

  // called everytime time rendered changes
  function onTimeChange(
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: any
  ) {
    setTimeRendering(new Date(visibleTimeStart));
    setTimeStart(moment(visibleTimeStart));
    setTimeEnd(moment(visibleTimeEnd));
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
  }

  // called when tries to move an item, validate if berth is occupied in the move hour
  function itemMove(itemId: any, dragTime: number, groupOrder: number) {
    const itemToMove: ItemType =
      itemsToRender.find((item) => item.id === itemId) || ({} as ItemType);

    const currentStartTime = moment(itemToMove?.start_time);
    const currentEndTime = moment(itemToMove?.end_time);

    const duration = moment.duration(currentEndTime.diff(currentStartTime));

    const newTimeStart = moment(dragTime);
    const newTimeEnd = moment(dragTime).add(duration);

    const newGroup = groupsToRender[groupOrder]; // New group refers to selected docking_place
    const berthVessels = itemsToRender.filter(
      (item) => item.group === newGroup.id && item.id !== itemId
    );

    const berthName = newGroup.name;

    if (itemToMove.docking_place.place_type !== newGroup.docking_place_type) {
      return message.error(
        'Não é possível alterar a embarcação para um tipo de local de atracação diferente.',
        5
      );
    }

    // Realiza validacoes apenas se for berço
    if (newGroup.type === 'BERTH') {
      if (
        itemToMove?.stopover?.vessel?.dwt &&
        itemToMove.stopover.vessel.dwt > newGroup.dwt
      ) {
        if (!itemToMove?.input_dwt) {
          return message.error(
            'O DWT máximo dessa embarcação é maior que o suportado pelo berço, por favor informe o DWT de entrada.',
            5
          );
        }
        if (itemToMove?.input_dwt > newGroup.dwt) {
          return message.error(
            'O DWT de entrada para essa atracação é maior que o suportado pelo berço.',
            5
          );
        }
      }

      if (
        !isAllowedInBerth(
          newGroup.ship_types,
          itemToMove?.stopover?.vessel?.ship_type || ({} as ShipType)
        )
      ) {
        return message.error(
          'Esse local de ancoragem não pode receber essa embarcação.',
          5
        );
      }
    }

    const canMove = [];

    if (berthVessels.length > 0) {
      berthVessels.forEach((vessel) => {
        const vesselStartTime = moment(vessel.start_time);
        const vesselEndTime = moment(vessel.end_time);
        const occupiedBerthString = messageString(
          berthName,
          vesselStartTime.format('HH:mm'),
          vesselEndTime.format('HH:mm'),
          vessel.stopover?.vessel?.name || ''
        );
        let shouldCheckDocking = false;
        let isDockingInline = false;
        let isDockingAlongside = false;
        if (
          itemToMove.docking_in_line !== null &&
          itemToMove.docking_in_line !== 'NO' &&
          vessel.docking_in_line !== null &&
          vessel.docking_in_line !== 'NO'
        ) {
          isDockingInline = true;
        }

        if (
          itemToMove.docking_alongside !== null &&
          itemToMove.docking_alongside !== 'NO' &&
          vessel.docking_alongside !== null &&
          vessel.docking_alongside !== 'NO'
        ) {
          isDockingAlongside = true;
        }

        if (!isDockingInline && !isDockingAlongside) {
          shouldCheckDocking = true;
        }

        if (shouldCheckDocking) {
          if (
            newTimeStart >= vesselStartTime &&
            newTimeStart <= vesselEndTime
          ) {
            canMove.push(false);
            return message.error(occupiedBerthString, 5);
          }
          if (newTimeEnd <= vesselEndTime && newTimeEnd >= vesselStartTime) {
            canMove.push(false);
            return message.error(occupiedBerthString, 5);
          }
          if (newTimeStart <= vesselStartTime && newTimeEnd >= vesselEndTime) {
            canMove.push(false);
            return message.error(occupiedBerthString, 5);
          }
          if (newTimeStart >= vesselStartTime && newTimeEnd <= vesselEndTime) {
            canMove.push(false);
            return message.error(occupiedBerthString, 5);
          }
        }
        canMove.push(true);
      });
    } else {
      canMove.push(true);
    }

    if (!canMove.includes(false)) {
      moveItem(
        itemId,
        newTimeStart,
        newTimeEnd,
        newGroup,
        itemToMove.docking_place.place_type
      );
      onItemDeselect();
    }
  }
  // actually move an item
  async function moveItem(
    itemId: number,
    newTimeStart: Moment,
    newTimeEnd: Moment,
    newGroup: GroupType,
    placeType: string
  ) {
    const typeDocking =
      dockingPlaceDatesList.find((item) => item.placeType === placeType) ||
      dockingPlaceDatesList[0];

    const datesObj = {
      [typeDocking?.dates[0]]: newTimeStart.utc().format(),
      [typeDocking?.dates[1]]: newTimeEnd.utc().format(),
    };

    const updatedDocking = await updateDocking({
      ...datesObj,
      docking_place: newGroup.id,
      id: itemId,
    });
    if ('data' in updatedDocking) {
      const docking = updatedDocking.data;
      setItemsToRender(
        itemsToRender.map((item) => {
          if (item.id === itemId) {
            return {
              ...item,
              start_time: newTimeStart.valueOf(),
              end_time: newTimeEnd.valueOf(),
              group: newGroup.id,
              docking_place: docking.docking_place,
              expected_start: docking?.[typeDocking?.dates[0]],
              expected_finish: docking?.[typeDocking?.dates[0]],
              docking_window_status: docking?.docking_window_status,
            };
          }

          return item;
        })
      );
    }
  }
  // update selected items array
  function onItemSelect(itemId: number) {
    setSelectedItems([itemId]);
    if (itemId && itemId > 0) {
      const selectedItemObject =
        itemsToRender.find((docking) => docking.id === itemId) ||
        ({} as ItemType);

      if (selectedItemObject.stopover?.id) {
        getStopoverSelected(selectedItemObject.stopover.id).then((stopover) => {
          if (stopover && 'data' in stopover) {
            setCurrentStopover(stopover.data || ({} as StopoverType));
            const currentDocking = stopover.data?.dockings?.find(
              (docking) => docking.id === selectedItemObject?.id
            );

            if (currentDocking) {
              setSelectedDocking({
                ...currentDocking,
                expected_anchoring: createDate(
                  currentDocking.expected_anchoring || ''
                ),
                expected_unanchoring: createDate(
                  currentDocking.expected_unanchoring || ''
                ),
              });
            } else {
              setSelectedDocking({} as DockingType);
            }
          }
        });
      }
    }
  }
  function onItemDeselect() {
    setSelectedItems([]);
  }
  function onBack() {
    onItemDeselect();
  }

  function setToday() {
    setTimeStart(moment().set({ hour: 0, minute: 0, second: 0 }));
    setTimeEnd(
      moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .add(groupHour * 24, 'hour')
    );
    setTimeRendering(new Date());
  }

  function onSelectBerthMaintenance(
    maintenance: BerthMaintenanceType,
    dockingPlace: DockingPlaceType
  ) {
    setIsBerthMaintenanceDrawerVisible(true);
    setSelectedBerth(dockingPlace);
    setSelectedBerthMaintenance(
      formatBerthMaintenanceToForm({
        ...maintenance,
        berths: (maintenance.berths as DockingPlaceType[]).filter(
          (berth: DockingPlaceType) => berth.tag !== selectedBerth.tag
        ),
      })
    );
  }

  function onGroupHourSelected(value: any) {
    const selectedGroupHour = parseInt(value, 10);
    setGroupHour(selectedGroupHour);
    setTimeEnd(moment(timeStart).add(selectedGroupHour * 24, 'hour'));
  }

  return (
    <ScreenLayout title="Janela de atracação e ancoragem">
      <Row align="middle" style={{ padding: '10px' }}>
        <Col span={6} style={{ maxWidth: '400px' }}>
          <Button icon={<FilterFilled />} size="large">
            Filtros
          </Button>
          <Button
            icon={<ReloadOutlined />}
            type="text"
            style={{ color: 'black', marginLeft: '10px' }}
            size="large"
            onClick={debounce(refetchDockings, 400)}
          >
            Atualizar
          </Button>
        </Col>
        <Col span={6}>
          <Row>
            <span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
              {moment(timeRendering).format('MMM')}
            </span>
            <span
              style={{
                fontSize: '24px',
                marginLeft: '5px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: 'var(--neutral_medium)',
              }}
            >
              {moment(timeRendering).format('YYYY')}
            </span>
          </Row>
          <Row
            style={{
              margin: '8px 0',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Button
              size="small"
              style={{ height: 28 }}
              icon={<EyeFilled />}
              onClick={() => setToday()}
            >
              Hoje
            </Button>
            <Select
              defaultValue="2 horas"
              optionLabelProp="label"
              onChange={(v) => onGroupHourSelected(v)}
              dropdownMatchSelectWidth={120}
              suffixIcon={<DownOutlined style={{ color: '#1AAA8C' }} />}
              size="small"
              style={{
                width: 90,
                marginLeft: 15,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: '#1AAA8C',
                color: '#1AAA8C',
                fontSize: 12,
              }}
            >
              <Select.OptGroup label="Agrupar a cada">
                <Select.Option value="1" label="1 hora">
                  1 hora
                </Select.Option>
                <Select.Option value="2" label="2 horas">
                  2 horas
                </Select.Option>
                <Select.Option value="6" label="6 horas">
                  6 horas
                </Select.Option>
              </Select.OptGroup>
            </Select>
          </Row>
        </Col>
        <StatusButtonsCol itemsToRender={itemsToRender} />
      </Row>
      <DockingDrawer
        visible={isDockingDrawerVisible}
        setIsVisible={setIsDockingDrawerVisible}
        selectedStopover={currentStopover}
        currentDockingKey={selectedDocking?.index}
        setCurrentDockingKey={setSelectedItems}
        selectedDocking={selectedDocking}
        selectedVessel={currentStopover?.vessel}
        width={800}
        allowedBerth={[selectedDocking?.docking_place]}
        onBack={onBack}
        isDockingWindowContext
        onSaveUpdate={onSaveUpdate}
        screen="STOPOVER"
        openCollapsePanels={openCollapsePanelsDocking}
        setOpenCollapsePanels={setOpenCollapsePanelsDocking}
      />
      <Drawer closable={false} visible={isAnchoringDrawerVisible} width={820}>
        <AnchoringForm
          selectedStopover={currentStopover}
          setSelectedStopover={setCurrentStopover}
          selectedDocking={selectedDocking}
          setSelectedDocking={setSelectedDocking}
          onClose={() => {
            setIsAnchoringDrawerVisible(false);
            setSelectedDocking({} as DockingType);
            onItemDeselect();
          }}
          screen="STOPOVER"
        />
      </Drawer>
      <BerthMaintenanceDrawer
        visibleDrawer={isBerthMaintenanceDrawerVisible}
        form={form}
        selectedBerth={selectedBerth}
        selectedBerthMaintenance={selectedBerthMaintenance}
        onBack={() => {
          setIsBerthMaintenanceDrawerVisible(false);
          setSelectedBerthMaintenance({} as BerthMaintenanceType);
          setSelectedBerth({} as DockingPlaceType);
          onItemDeselect();
        }}
        isEdit
        isDockingWindowContext
      />
      <Spin
        spinning={
          !isSuccessLoadingDocking ||
          !isSuccessLoadingBerth ||
          isFetchingDockings ||
          isLoadingUpdateDocking
        }
        tip="Carregando..."
      >
        <Wrapper
          style={{
            padding: '15px',
            maxHeight: '80vh',
          }}
        >
          <div
            style={{
              height: 'auto',
              overflowY: 'auto',
            }}
          >
            {isSuccessLoadingDocking && isSuccessLoadingBerth && (
              <AnchoringWindowTimeLine
                buffer={4}
                groups={groupsToRender}
                items={itemsToRender}
                defaultTimeStart={timeStart}
                defaultTimeEnd={timeEnd}
                visibleTimeEnd={Number(timeEnd.format('x'))}
                visibleTimeStart={Number(timeStart.format('x'))}
                minZoom={24 * 60 * 60 * 1000}
                maxZoom={7 * 24 * 60 * 60 * 1000}
                canMove
                canResize="both"
                itemHeightRatio={0.75}
                itemTouchSendsClick
                stackItems={false}
                itemRenderer={(itemProps) =>
                  itemRenderer(
                    itemProps,
                    itemsToRender,
                    setIsDockingDrawerVisible,
                    setIsAnchoringDrawerVisible,
                    onSelectBerthMaintenance
                  )
                }
                groupRenderer={(groupProps) =>
                  dockingPlaceRenderer(
                    groupProps,
                    itemsToRender,
                    onClickVesselNameSideColumn
                  )
                }
                sidebarWidth={400}
                onItemResize={itemResize}
                onItemMove={itemMove}
                onTimeChange={onTimeChange}
                onBoundsChange={onBoundsChange}
                selected={selectedItems}
                onItemSelect={onItemSelect}
                onItemDeselect={onItemDeselect}
                lineHeight={berthRowHeight}
                timeSteps={{
                  second: 1,
                  minute: 1,
                  hour: groupHour,
                  day: 1,
                  month: 1,
                  year: 1,
                }}
              >
                <TimelineHeaders
                  style={{ position: 'sticky', top: '0', zIndex: 91 }}
                >
                  <SidebarHeader>
                    {() =>
                      sidebar(onOpenNewDocking, form, onSearchDockingByName)
                    }
                  </SidebarHeader>
                  <DateHeader
                    unit="day"
                    intervalRenderer={(dayProps) => dayRenderer(dayProps)}
                    height={62}
                  />
                  <DateHeader
                    unit="hour"
                    labelFormat="HH"
                    intervalRenderer={(hourProps) => hourRenderer(hourProps)}
                  />
                </TimelineHeaders>
                <TimelineMarkers>
                  <TodayMarker date={moment().toDate()}>
                    {({ styles }) => (
                      <div style={styles} className="tos-today-marker" />
                    )}
                  </TodayMarker>
                </TimelineMarkers>
              </AnchoringWindowTimeLine>
            )}
          </div>
        </Wrapper>
      </Spin>
    </ScreenLayout>
  );
}
