import { ArrowLeftOutlined } from '@ant-design/icons';
import { message, Row, Space } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { debounce, filter } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  Button,
  Drawer,
  Form,
  FormItemInput,
  FormItemSelect,
  Modal,
  SearchInput,
  Select,
  SlimTable,
  Tag,
  Title,
} from '../../../components';
import { EmptyDataPlaceholder } from '../../../components/common/emptyDataPlaceholder/EmptyDataPlaceholder';
import { Number } from '../../../components/common/Number';
import { Subtitle } from '../../../components/common/Title';
import { useStateCallback } from '../../../hooks';
import {
  usePatchCSMByVehiclesExportingMutation,
  usePatchCSMByVehiclesImportingMutation,
} from '../../../services/cargosStorageMerchantApi';
import {
  usePatchCargoStorageMerchantVehicleMovimentationMutation,
  useUpdateMovimentationMutation,
} from '../../../services/movimentationApi';
import {
  useGetVehicleDetailsQuery,
  useSegregateChassiMutation,
} from '../../../services/warehousingVehiclesApi';
import {
  GroupByBrandByPortVehicle,
  GroupByImportingVehicle,
  GroupByVehiclesImportingType,
  OperationalOperationType,
  ShiftType,
  VehicleBrandByPort,
  WorkShiftMovimentationType,
} from '../../../types';
import { VehicleType } from '../../../typesWarehousing';
import { chassisStatus } from '../../../utils/lists';
import { ImportChassisDetailsDrawer } from '../../warehousingVehicles/importChassisDetailsDrawer';
import { VehiclesMovimentations } from './styles';
import { vehiclesTableColumns } from './vehiclesTableColumns';

type VehiclesMovimentationDetailsProps = {
  direction: 'IMPORT' | 'EXPORT';
  merchantCE?: GroupByVehiclesImportingType | null;
  exportingByBrands?: VehicleBrandByPort | null;
  operation?: OperationalOperationType;
  selectedPort?: {
    country: string;
    name: string;
  } | null;
  selectedOperationProductId?: number | null;
  refetchGroupCSMByVehiclesExporting?: () => void;
};
export function VehiclesMovimentationDetails(
  props: VehiclesMovimentationDetailsProps
) {
  const {
    direction,
    merchantCE,
    exportingByBrands,
    operation,
    selectedPort,
    selectedOperationProductId,
    refetchGroupCSMByVehiclesExporting,
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useStateCallback<React.Key[]>(
    []
  );
  const [selectedRows, setSelectedRows] = useStateCallback<any[]>([]);
  const [isVisibleVehiclesDetailsDrawer, setIsVisibleVehiclesDetailsDrawer] =
    useState(false);

  const [workPeriodsShiftsArray, setWorkPeriodsShiftsArray] = useState<any[]>(
    []
  );

  const [showSelectShiftModal, setShowSelectShiftModal] = useState(false);

  const [form] = Form.useForm();
  const [segregateForm] = Form.useForm();

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    newSelectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };
  const onSelect = (
    record: any,
    selected: boolean,
    newSelectedRows: Array<any>
  ) => {
    const key = direction === 'IMPORT' ? 'id' : 'warehousing__id';
    if (selected === false) {
      if (filteredVehicles) {
        const newSelected = selectedRows.filter(
          (row) => row[key] !== record[key]
        );
        setSelectedRowKeys(newSelected.flatMap((selected) => selected[key]));
        setSelectedRows(newSelected);
      } else {
        setSelectedRowKeys(
          newSelectedRows.flatMap((selected) => selected[key])
        );
        setSelectedRows(newSelectedRows);
      }
    } else {
      setSelectedRowKeys((prev) => prev.concat(record[key]));
      setSelectedRows((prev) => prev.concat(record));
    }
  };
  const rowSelection: TableRowSelection<Record<string, any>> = {
    selectedRowKeys,
    // onChange: onSelectChange,
    onSelect,
    hideSelectAll: true,
    getCheckboxProps: (record) => ({
      disabled:
        record.chassis_status === 'UNSHIPPED' ||
        record.chassis_status === 'SHIPPED',
    }),
  };

  const [updateMovimentations, { isLoading: isLoadingUpdateMovimentations }] =
    useUpdateMovimentationMutation();
  const [
    patchCSMVehiclesImporting,
    { isLoading: isLoadingPatchCSMVehiclesImporting },
  ] = usePatchCSMByVehiclesImportingMutation();
  const [
    patchCSMVehiclesExporting,
    { isLoading: isLoadingPatchCSMVehiclesExporting },
  ] = usePatchCSMByVehiclesExportingMutation();
  const [patchCSMVehiclesMovimentatinoExporting] =
    usePatchCargoStorageMerchantVehicleMovimentationMutation();

  function onLandChassis() {
    // setShowSelectShiftModal(true);
    onFinishMovimentation();
  }
  const [filteredVehicles, setFilteredVehicles] = useState<Array<
    GroupByBrandByPortVehicle | GroupByImportingVehicle
  > | null>(null);

  function onLandCE() {
    if (merchantCE) {
      setSelectedRows(merchantCE.vehicles, () => {
        setSelectedRowKeys(
          merchantCE.vehicles.flatMap((vehicle) => vehicle.id),
          () => {
            onFinishMovimentation(merchantCE.vehicles.length);
          }
        );
      });
      // setShowSelectShiftModal(true);
      // window.setTimeout(() => {
      // }, 1000);
    }
  }

  function onShipChassis() {
    // setShowSelectShiftModal(true);
    onFinishMovimentation();
  }

  function onShipAllChassis() {
    if (exportingByBrands && exportingByBrands.vehicles) {
      setSelectedRows(exportingByBrands.vehicles, () => {
        setSelectedRowKeys(
          exportingByBrands.vehicles.flatMap(
            (vehicle) => vehicle.warehousing__id
          ),
          () => {
            onFinishMovimentation(exportingByBrands.vehicles.length);
          }
        );
      });
      // setShowSelectShiftModal(true);
      // window.setTimeout(() => {
      // }, 1000);
    }
  }

  useEffect(() => {
    if (direction === 'IMPORT' && merchantCE) {
      setSelectedRowKeys(
        merchantCE.vehicles
          .filter((vehicle) => vehicle.chassis_status !== 'UNSHIPPED')
          .flatMap((vehicle) => vehicle.id)
      );
      setSelectedRows(merchantCE?.vehicles);
    } else if (direction === 'EXPORT' && exportingByBrands) {
      setSelectedRowKeys(
        exportingByBrands.vehicles
          .filter((vehicle) => vehicle.chassis_status !== 'SHIPPED')
          .flatMap((vehicle) => vehicle.warehousing__id)
      );
      setSelectedRows(exportingByBrands.vehicles);
    }
  }, [merchantCE?.vehicles, exportingByBrands?.vehicles]);

  useEffect(() => {
    if (operation && operation.work_periods) {
      setWorkPeriodsShiftsArray(
        operation.work_periods.flatMap((workPeriod, index) =>
          workPeriod.work_shifts.map((workShift) => {
            return { ...workShift, work_period_index: index };
          })
        )
      );
    }
  }, [operation]);

  async function onFinishMovimentation(total_movimented?: number) {
    // form.validateFields().then(async (values) => {
    // const selectedPeriod =
    const selectedShiftPeriod =
      operation?.work_periods[0].work_shifts[0] || ({} as ShiftType);
    if (direction === 'IMPORT') {
      const movimentation = [
        {
          id: null,
          total_movimented: total_movimented || selectedRows.length,
          work_shift_id: selectedShiftPeriod.id,
          work_period_id: selectedShiftPeriod.work_period_id,
          operation_product_id: merchantCE?.operation_product__id,
          name: 'IMPORTING',
        },
      ];
      const movimentationsToSave = {
        movimentations: movimentation as WorkShiftMovimentationType[],
        operation_id: operation?.id,
      };
      await patchCSMVehiclesImporting(
        selectedRows.flatMap((row) => {
          return { id: row.id };
        })
      ).then(async () => {
        await updateMovimentations(movimentationsToSave);
      });
    } else {
      const movimentation = [
        {
          id: null,
          total_movimented: total_movimented || selectedRows.length,
          work_shift_id: selectedShiftPeriod.id,
          work_period_id: selectedShiftPeriod.work_period_id,
          operation_product_id: selectedOperationProductId,
          name: 'EXPORTING',
        },
      ];
      const movimentationsToSave = {
        movimentations: movimentation as WorkShiftMovimentationType[],
        operation_id: operation?.id,
      };
      await patchCSMVehiclesExporting(
        selectedRows.flatMap((row) => {
          return { warehousing__id: row.warehousing__id };
        })
      ).then(async () => {
        await patchCSMVehiclesMovimentatinoExporting(
          selectedRows.flatMap((row) => {
            return { id: row.id };
          })
        ).then(async () => {
          await updateMovimentations(movimentationsToSave);
        });
      });
    }
    // });
  }

  const [SelectedChassis, setSelectedChassis] = useState<VehicleType>(
    {} as VehicleType
  );

  const { data: vehicleDetailsData } = useGetVehicleDetailsQuery(
    SelectedChassis.chassis
  );

  const [segregateChassis, { isLoading: isLoadingSegregate }] =
    useSegregateChassiMutation();

  const [isVisibleSegregateChassis, setIsVisibleSegregateChassis] =
    useState(false);

  function chassiDetailsDrawerHeader() {
    return (
      <Row justify="space-between">
        <div>
          <div style={{ marginBottom: '5px' }}>
            {SelectedChassis.chassis}
            <Tag style={{ marginLeft: '10px' }}>
              {
                chassisStatus.find(
                  (chassiStatus) =>
                    chassiStatus.value === vehicleDetailsData?.status
                )?.label
              }
            </Tag>
          </div>
          <span
            style={{
              fontSize: '14px',
              display: 'block',
              color: 'var(--neutral_medium)',
              fontWeight: 400,
            }}
          >
            {vehicleDetailsData?.description}
          </span>
        </div>

        <Button danger onClick={() => setIsVisibleSegregateChassis(true)}>
          Marcar como segregado
        </Button>
      </Row>
    );
  }

  function onOpenChassisDetailsDrawer(chassis: VehicleType) {
    setIsVisibleVehiclesDetailsDrawer(true);
    setSelectedChassis(chassis);
  }

  function onSegregateChassis() {
    segregateForm.validateFields().then(async (values) => {
      if (vehicleDetailsData && vehicleDetailsData.id) {
        await segregateChassis({
          segregation_reason: values.segregation_reason,
          chassi_id: vehicleDetailsData.id,
        }).then(() => {
          message.success('Chassi segregado');
          setIsVisibleSegregateChassis(false);
          if (refetchGroupCSMByVehiclesExporting) {
            refetchGroupCSMByVehiclesExporting();
            setIsVisibleVehiclesDetailsDrawer(false);
          }
        });
      }
    });
  }

  function onSearch(value: string) {
    if (value !== '') {
      if (direction === 'IMPORT' && merchantCE) {
        setFilteredVehicles(
          merchantCE.vehicles.filter((vehicle) =>
            vehicle.chassis
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          )
        );
      }
      if (direction === 'EXPORT' && exportingByBrands) {
        setFilteredVehicles(
          exportingByBrands.vehicles.filter((vehicle) =>
            vehicle.chassis
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase())
          )
        );
      }
    } else {
      setFilteredVehicles(null);
    }
  }
  return (
    <>
      <Modal
        title="Marcar como segregado"
        visible={isVisibleSegregateChassis}
        okText="Confirmar"
        cancelText="Voltar"
        okButtonProps={{
          htmlType: 'submit',
          form: 'segregate',
          loading: isLoadingSegregate,
        }}
        onCancel={() => setIsVisibleSegregateChassis(false)}
      >
        <Form
          layout="vertical"
          name="segregate"
          onFinish={onSegregateChassis}
          form={segregateForm}
        >
          <FormItemInput
            name="segregation_reason"
            label="Descrição da avaria"
          />
        </Form>
      </Modal>
      <Drawer
        visible={isVisibleVehiclesDetailsDrawer}
        headerStyle={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
        onClose={() => setIsVisibleVehiclesDetailsDrawer(false)}
        width={590}
        title={chassiDetailsDrawerHeader()}
        closeIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ borderRadius: '25px' }}
          />
        }
      >
        <ImportChassisDetailsDrawer chassis={vehicleDetailsData} />
      </Drawer>
      {(direction === 'IMPORT' && merchantCE) ||
      (direction === 'EXPORT' && exportingByBrands) ? (
        <VehiclesMovimentations>
          <div style={{ marginBottom: '10px' }}>
            <Row justify="space-between" align="middle">
              {direction === 'IMPORT' ? (
                <>
                  <div>
                    <Title>
                      CE <Number> {merchantCE?.merchant_ce__code}</Number>
                    </Title>
                    <Subtitle>
                      Manifesto <Number> {merchantCE?.manifest__code}</Number>
                    </Subtitle>
                  </div>
                  <Space size={8}>
                    <SearchInput
                      allowClear
                      placeholder="Pesquisar chassi"
                      style={{ width: '200px' }}
                      onChange={debounce(
                        (evt) => onSearch(evt.target.value),
                        500
                      )}
                    />
                    <Button
                      disabled={selectedRowKeys.length === 0}
                      size="middle"
                      type="primary"
                      onClick={() => onLandChassis()}
                    >
                      Desembarcar Chassi(s)
                    </Button>
                  </Space>
                </>
              ) : (
                <>
                  <div>
                    <Title>{exportingByBrands?.description}</Title>
                    <Subtitle>
                      {selectedPort?.country}, {selectedPort?.name}
                    </Subtitle>
                  </div>
                  <Space size={8}>
                    <SearchInput
                      allowClear
                      placeholder="Pesquisar chassi"
                      style={{ width: '200px' }}
                      onChange={debounce(
                        (evt) => onSearch(evt.target.value),
                        500
                      )}
                    />
                    <Button
                      disabled={selectedRowKeys.length === 0}
                      size="middle"
                      type="primary"
                      onClick={() => onShipChassis()}
                    >
                      Embarcar Chassi(s)
                    </Button>
                  </Space>
                </>
              )}
            </Row>
          </div>
          <SlimTable
            size="small"
            columns={vehiclesTableColumns(
              direction,
              onOpenChassisDetailsDrawer
            )}
            dataSource={
              filteredVehicles !== null
                ? filteredVehicles
                : direction === 'IMPORT'
                ? merchantCE?.vehicles
                : exportingByBrands?.vehicles
            }
            pagination={{
              pageSize: 8,
            }}
            rowSelection={rowSelection}
            rowKey={direction === 'IMPORT' ? 'id' : 'warehousing__id'}
          />
        </VehiclesMovimentations>
      ) : (
        <EmptyDataPlaceholder
          text={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              Selecione um item
            </div>
          }
        />
      )}
    </>
  );
}
