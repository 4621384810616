import { ArrowLeftOutlined } from '@ant-design/icons';
import { ConfigProvider, Spin } from 'antd';
import { useState } from 'react';

import { Button, Drawer, TableWrapper, Tag } from '../../../components';
import { useGetVehicleDetailsQuery } from '../../../services/warehousingVehiclesApi';
import {
  VehicleType,
  VehiclesGroupByStatisticsDetailsType,
} from '../../../typesWarehousing';
import { chassisStatus } from '../../../utils/lists';
import { ImportChassisDetailsDrawer } from '../../warehousingVehicles/importChassisDetailsDrawer';
import { ReportsTable } from '../styles';
import { warehousingVehiclesTableColumns } from './warehousingVehiclesTableColumns';

type WarehousingVehiclesTableProps = {
  dataSource?: VehiclesGroupByStatisticsDetailsType[];
  total?: number;
};

export function WarehousingVehiclesTable(props: WarehousingVehiclesTableProps) {
  const { dataSource, total } = props;
  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  const [IsVisibleDetailsChassisDrawer, setIsVisibleDetailsChassisDrawer] =
    useState(false);
  const data = [
    {
      chassis: '44P4SE1G5ALKF0214',
      arrival_date: '11/08/2023',
      leaving_date: '22/09/2023',
      time_range: '15 a 21 dias',
      warehousing: 'PPV 1',
      navigation_type: 'Longo curso importação',
      direction: 'Desembarque',
      xml_status: '13/08/2023 12:14',
    },
  ];

  const [SelectedChassis, setSelectedChassis] = useState<VehicleType>(
    {} as VehicleType
  );

  const { data: vehicleDetailsData } = useGetVehicleDetailsQuery(
    SelectedChassis.chassis
  );
  function chassiDetailsDrawerHeader() {
    return (
      <>
        <div style={{ marginBottom: '5px' }}>
          {SelectedChassis.chassis}
          <Tag style={{ marginLeft: '10px' }}>
            {
              chassisStatus.find(
                (chassiStatus) =>
                  chassiStatus.value === vehicleDetailsData?.status
              )?.label
            }
          </Tag>
        </div>
        <span
          style={{
            fontSize: '14px',
            display: 'block',
            color: 'var(--neutral_medium)',
            fontWeight: 400,
          }}
        >
          {vehicleDetailsData?.description}
        </span>
      </>
    );
  }

  function onOpenChassisDrawer(chassis: VehicleType) {
    setIsVisibleDetailsChassisDrawer(true);
    setSelectedChassis(chassis);
  }
  return (
    <>
      <Drawer
        visible={IsVisibleDetailsChassisDrawer}
        headerStyle={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
        onClose={() => setIsVisibleDetailsChassisDrawer(false)}
        width={590}
        title={chassiDetailsDrawerHeader()}
        closeIcon={
          <Button
            icon={<ArrowLeftOutlined />}
            type="text"
            style={{ borderRadius: '25px' }}
          />
        }
      >
        <ImportChassisDetailsDrawer chassis={vehicleDetailsData} />
      </Drawer>
      <Spin spinning={false} tip="Carregando...">
        <TableWrapper>
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <ReportsTable
              rowKey="key"
              pagination={{
                position: ['bottomCenter'],
                defaultPageSize: 100,
                total,
                showSizeChanger: false,
                //   onChange: (page) => onChangePagination(page),
                //   current: queryPage,
              }}
              columns={warehousingVehiclesTableColumns(onOpenChassisDrawer)}
              dataSource={dataSource}
              scroll={{ y: '60vh', scrollToFirstRowOnChange: false }}
            />
          </ConfigProvider>
        </TableWrapper>
      </Spin>
    </>
  );
}
