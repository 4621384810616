import styled from 'styled-components';

import { LightTable } from '../../../../components';

export const Header = styled.div`
  display: flex;
  gap: 8px;

  .containers {
    background-color: var(--neutral_lighter);
    color: var(--neutral_medium);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 25px;
    font-family: Consolas;
    font-size: 12px;
    align-self: center;
  }

  .container-without-name {
    color: var(--neutral_medium);
    font-weight: 700;
    font-size: 16px;
    align-self: center;
  }
`;

export const ContainersTable = styled(LightTable)`
  table {
    border-spacing: 5px !important;
    border-collapse: separate !important;

    .direction-container {
      background: #ebf2f7 !important;
      text-transform: uppercase;
      color: var(--ocean_dark);
      font-weight: 500;
    }

    th {
      &.ant-table-cell:not(:first-child) {
        &.group {
          background-color: rgba(204, 221, 233, 0.5);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-spacing: 5px;
          border-collapse: separate;
        }
      }
      &.ant-table-cell {
        &.group-item {
          background-color: rgba(204, 221, 233, 0.5);

          &:nth-child(odd) {
            border-bottom-left-radius: 5px;
          }
          &:nth-child(even) {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
    td {
      &.ant-table-cell {
        &.group-item {
          &:nth-child(even) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          &:nth-child(odd) {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }

    & .ant-table-summary {
      background: #ebf2f7 !important;

      td {
        padding: 4px 16px 4px 16px;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        color: var(--neutral_dark);
        border-bottom: none;

        &:first-child {
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }

  &.containers-reports {
    & table {
      background: transparent !important;
      width: 100%;
    }
    table {
      font-size: 12px;
      .direction-container {
        background: #fff !important;
        text-transform: none;
        white-space: nowrap;
        &.total-row {
          text-transform: uppercase;
          color: #000;
          font-weight: bold;
        }
      }
      & th {
        padding: 4px;
        &.ant-table-cell {
          &.group {
            background-color: rgba(204, 221, 233, 0.5);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-spacing: 5px;
            border-collapse: separate;
          }
        }
        &.expected-operated {
          vertical-align: bottom;
        }
      }
      & td.ant-table-cell {
        padding: 2px;
        &.group-item {
          background: var(--neutral_lightest);
          &.total {
            background: #dbe7f0;
          }
        }
        &.expected-operated {
          background: #dbe7f0;
          border-radius: 5px;
          &.total {
            color: #fff;
            &.operated {
              &.incomplete {
                background: var(--error-medium);
              }
              &.complete {
                background: var(--success-dark);
              }
            }
            &.expected {
              background: var(--neutral_medium);
            }
          }
        }
      }
    }
  }
`;

export const PeriodsTable = styled(LightTable)`
  table {
    margin-top: 15px;

    & .ant-table-summary {
      background: #ebf2f7 !important;

      td {
        padding: 4px 16px 4px 16px;
        font-weight: 600;
        font-size: 12px;
        color: var(--neutral_dark);
        border-bottom: none;

        &:first-child {
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }
`;
