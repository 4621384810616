import { Space, message } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';

import { Button, Form, FormItemDatePicker } from '../../components';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type EndDockingModalProps = BaseActionModalProps & {
  onEndDocking: () => void;
};

export function EndDockingModal(props: EndDockingModalProps) {
  const {
    setIsVisible,
    onEndDocking,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
  } = props;
  const [form] = Form.useForm();

  function validate(firstCableTiedIn: string, lastCableTiedIn: Moment) {
    const firstCableTiedInMoment = moment(firstCableTiedIn);
    if (lastCableTiedIn <= firstCableTiedInMoment) {
      message.error(
        `A data do último cabo amarrado não pode ser menor que a data de primeiro cabo amarrado(${createDateStringPtBr(
          firstCableTiedInMoment
        )})`
      );
      return false;
    }
    return true;
  }

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      if (updateDocking) {
        if (
          !validate(
            currentDocking?.first_cable_tied_in || '',
            values.last_cable_tied_in
          )
        )
          return;

        updateDocking(
          {
            ...values,
            last_cable_tied_in: createDateString(values.last_cable_tied_in),
            id: currentDocking?.id,
          },
          onEndDocking
        );
      }
    });
  }
  useEffect(() => {
    if (currentDocking) {
      form.setFieldsValue({
        last_cable_tied_in: currentDocking?.last_cable_tied_in,
      });
    }
  }, [currentDocking]);
  return (
    <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
      <FormItemDatePicker
        name="last_cable_tied_in"
        label="Último cabo amarrado em:"
        disabledDate={disabledDateAfterToday}
        tooltip="Data e horário do último cabo amarrado"
        colSpan={12}
        rules={[
          {
            required: true,
            message:
              'Por favor, preencha a data e hora do último cabo amarrado',
          },
        ]}
      />
      <Space
        size={12}
        style={{
          justifyContent: 'flex-end',
          display: 'flex',
          marginTop: '20px',
        }}
      >
        <Button type="text" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoadingUpdateDocking}
        >
          Finalizar
        </Button>
      </Space>
    </Form>
  );
}
