import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Empty, Row, message } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import confirm from 'antd/lib/modal/confirm';
import { Dispatch, SetStateAction, useEffect } from 'react';

import {
  Button,
  FormItemSelect,
  FormItemInputNumber,
  Select,
  FormItem,
} from '../../../../../components';
import { useDeleteCompartmentMutation } from '../../../../../services/compartmentsApi';
import { OperationProductType, Stopover } from '../../../../../types';
import { compartmentTypeList, unitsList } from '../../../../../utils/lists';
import { CompartmentsTableStyle } from './styles';

type CompartmentsTableProps = {
  compartments: FormListFieldData[];
  onRemoveItem: (itemIndex: number) => void;
  form: FormInstance;
  setIsFormChanged: (value: boolean) => void;
  selectedStopover?: Stopover;
  setSelectedStopover?: Dispatch<SetStateAction<Stopover>>;
  selectedProduct?: OperationProductType;
};
export function CompartmentsTable(props: CompartmentsTableProps) {
  const {
    compartments,
    onRemoveItem,
    form,
    setIsFormChanged,
    selectedStopover,
    setSelectedStopover,
    selectedProduct,
  } = props;

  return (
    <CompartmentsTableStyle>
      <div className="table">
        <Row gutter={16} className="table-head">
          <Col span={6}>COMPARTIMENTO</Col>
          <Col span={7}>IDENTIFICADOR</Col>
          <Col span={9}>QUANTIDADE DE CARGA</Col>
        </Row>
        {compartments.map((_field, index) => {
          return (
            <ItemRow
              key={index}
              rowIndex={index}
              onRemove={onRemoveItem}
              form={form}
              setIsFormChanged={setIsFormChanged}
              selectedStopover={selectedStopover}
              setSelectedStopover={setSelectedStopover}
              selectedProduct={selectedProduct}
            />
          );
        })}
        {compartments.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </CompartmentsTableStyle>
  );
}

type ItemRowProps = {
  rowIndex: number;
  onRemove: (itemIndex: number) => void;
  form: FormInstance;
  setIsFormChanged: (value: boolean) => void;
  selectedStopover?: Stopover;
  setSelectedStopover?: Dispatch<SetStateAction<Stopover>>;
  selectedProduct?: OperationProductType;
};
function ItemRow(props: ItemRowProps) {
  const {
    rowIndex,
    onRemove,
    form,
    setIsFormChanged,
    selectedStopover,
    setSelectedStopover,
    selectedProduct,
  } = props;

  const [
    deleteCompartment,
    {
      isSuccess: isSuccessDeleteCompartment,
      isError: isErrorDeleteCompartment,
    },
  ] = useDeleteCompartmentMutation();

  function TypeOptionRenderer(type: any) {
    return (
      <Select.Option key={type.id} value={type.id}>
        {type.name}
      </Select.Option>
    );
  }

  async function handleRemove(rowIndex: number, id: number | undefined) {
    confirm({
      title: 'Deseja realmente remover esse compartimento?',
      icon: <ExclamationCircleOutlined />,
      content: 'O compartimento removido não poderá ser recuperado',
      async onOk() {
        if (id) await deleteCompartment(id);
        setIsFormChanged(false);
      },
    });
  }

  useEffect(() => {
    if (isSuccessDeleteCompartment) {
      message.success('Compartimento excluído');
      onRemove(rowIndex);
      if (
        selectedStopover &&
        selectedStopover.operation.items &&
        selectedProduct &&
        setSelectedStopover
      ) {
        const updatedCompartments = form.getFieldValue(['compartments']);

        const items = selectedStopover.operation.items.map(
          (item: OperationProductType) => {
            if (item.id === selectedProduct.id) {
              return {
                ...item,
                compartments: updatedCompartments,
              };
            }
            return item;
          }
        );
        setSelectedStopover((prev) => ({
          ...prev,
          operation: { ...selectedStopover.operation, items },
        }));
      }
    }
  }, [isSuccessDeleteCompartment]);

  const unitsAddonAfter = (
    <FormItem name={[`${rowIndex}`, 'unit']} noStyle>
      <Select>
        {unitsList.map((unit) => (
          <Select.Option key={unit.value} value={unit.value}>
            {unit.abbreviation}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );

  return (
    <Row
      gutter={16}
      style={{
        background: '#fff',
        margin: '8px 16px 8px 16px',
        padding: '5px',
      }}
    >
      <FormItemSelect
        colSpan={6}
        placeholder="Tipo"
        name={[`${rowIndex}`, 'compartment_type']}
        showSearch
        style={{ margin: 0 }}
        dataList={compartmentTypeList
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))}
        optionRenderer={TypeOptionRenderer}
      />
      <FormItemInputNumber
        colSpan={7}
        placeholder="Identificador"
        name={[`${rowIndex}`, 'name']}
        required
      />
      <FormItemInputNumber
        colSpan={9}
        placeholder="Quantidade"
        name={[`${rowIndex}`, 'quantity']}
        addonAfter={unitsAddonAfter}
        maxLength={9}
      />
      <Col span={2} style={{ textAlign: 'right' }}>
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={() =>
            handleRemove(
              rowIndex,
              form.getFieldValue(['compartments', `${rowIndex}`, 'id'])
            )
          }
        />
      </Col>
    </Row>
  );
}
