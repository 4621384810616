import { message, Row } from 'antd';
import { debounce, isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Form,
  FormItem,
  FormItemInput,
  FormItemInputNumber,
  FormItemSelect,
  GenericDrawerHeader,
  ModalBlockNavigation,
} from '../../../../../../../../../components';
import { useGetCompaniesQuery } from '../../../../../../../../../services/companyApi';
import {
  useCreateOperationContainerMutation,
  useDeleteOperationContainerMutation,
  useLazyGetOperationContainerQuery,
  useUpdateOperationContainerMutation,
} from '../../../../../../../../../services/operationContainersApi';
import { useGetSystemParamByNameQuery } from '../../../../../../../../../services/systemParamsApi';
import {
  useGetPortsCountriesQuery,
  useGetPortsQuery,
} from '../../../../../../../../../services/vesselApi';
import {
  OperationContainerType,
  ContainerTypeDescription,
  OperationContainerToSave,
  Stopover as StopoverType,
} from '../../../../../../../../../types';
import { formatOperationMerchantCEToSave } from '../../../../../../../../../utils/formatters';
import {
  booleanList,
  containerSizeList,
} from '../../../../../../../../../utils/lists';
import { OperationProductConsigneeData } from '../../../../../../../../operatorArea/operationProductConsigneeData/OperationProductConsigneeData';
import { formatContainerToDescription } from '../../../../../../formatOperationToDescription';

type ContainerFormProps = {
  selectedContainer: ContainerTypeDescription;
  setSelectedContainer: (value: ContainerTypeDescription) => void;
  onFinishSubmit: () => void;
  onBack: () => void;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  setIsVisibleForm: (value: boolean) => void;
  blockNavigate: boolean;
  operationCraneId?: number;
  craneId?: string;
  selectedStopover: StopoverType;
  selectedContainers: ContainerTypeDescription[];
  setSelectedContainers: Dispatch<SetStateAction<ContainerTypeDescription[]>>;
  isVisible: boolean;
};

export function ContainerForm(props: ContainerFormProps) {
  const {
    selectedContainer,
    formIsChanged,
    setIsFormChanged,
    onBack,
    setIsVisibleForm,
    blockNavigate,
    setBlockNavigate,
    setSelectedContainer,
    onFinishSubmit,
    operationCraneId,
    selectedStopover,
    selectedContainers,
    setSelectedContainers,
    isVisible,
    craneId,
  } = props;
  const [form] = Form.useForm();

  const [searchPortBoardingCountry, setSearchPortBoardingCountry] = useState(
    {}
  );
  const [searchPortUnboardingCountry, setSearchPortUnboardingCountry] =
    useState({});

  const [searchPortBoarding, setSearchPortBoarding] = useState({});
  const [searchPortUnboarding, setSearchPortUnboarding] = useState({});

  const [selectedPortBoardingCountry, setSelectedPortBoardingCountry] =
    useState<string>();

  const [selectedPortUnboardingCountry, setSelectedPortUnboardingCountry] =
    useState<string>();

  const [selectedMerchantsCE, setSelectedMerchantsCE] = useState([]);

  const { data: storages } = useGetCompaniesQuery({ company_type: 'STORAGE' });

  const { data: containerTypesData } = useGetSystemParamByNameQuery({
    name: 'CONTAINER_TYPE',
  });

  const { data: operationTypesData } = useGetSystemParamByNameQuery({
    name: 'OPERATION_TYPE',
  });

  const { data: diretionContainersData } = useGetSystemParamByNameQuery({
    name: 'DIRECTION_CONTAINER_TYPE',
  });

  const cranesData = selectedStopover?.operation?.operation_cranes;

  const isEdit = selectedContainer?.id > 0;

  const [
    loadOperationContainer,
    {
      data: operationContainerData,
      currentData: operationContainerCurrentData,
    },
  ] = useLazyGetOperationContainerQuery();

  const { data: portsBoardingData, isLoading: isLoadingPortBoardingData } =
    useGetPortsQuery(searchPortBoarding);

  const {
    data: countriesDataPortBoarding,
    isLoading: isLoadingCountriesDataPortBoarding,
  } = useGetPortsCountriesQuery(searchPortBoardingCountry);

  const { data: portsUnboardingData, isLoading: isLoadingPortUnboardingData } =
    useGetPortsQuery(searchPortUnboarding);

  const {
    data: countriesDataPortUnboarding,
    isLoading: isLoadingCountriesDataPortUnboarding,
  } = useGetPortsCountriesQuery(searchPortUnboardingCountry);

  useEffect(() => {
    if (isVisible && isEdit) loadOperationContainer(selectedContainer?.id || 0);

    form.setFieldsValue({ operation_crane_id: operationCraneId });
  }, [isVisible]);

  useEffect(() => {
    form.setFieldsValue(operationContainerData);
    form.setFieldsValue({
      port_boarding: {
        ...operationContainerData?.port_boarding,
        id: operationContainerData?.port_boarding?.bi_trigram,
      },
      port_unboarding: {
        ...operationContainerData?.port_unboarding,
        id: operationContainerData?.port_unboarding?.bi_trigram,
      },
    });
    form.setFieldsValue({ crane: { id: craneId } });
    setSearchPortBoardingCountry({
      countryName: operationContainerData?.port_boarding?.country_name,
    });
    setSearchPortBoarding({
      countryCode: operationContainerData?.port_boarding?.country_code,
    });
    setSearchPortUnboardingCountry({
      countryName: operationContainerData?.port_unboarding?.country_name,
    });
    setSearchPortUnboarding({
      countryCode: operationContainerData?.port_unboarding?.country_code,
    });
  }, [operationContainerData]);

  const [createOperationContainer] = useCreateOperationContainerMutation();
  const [updateOperationContainer] = useUpdateOperationContainerMutation();
  const [deleteOperationContainer] = useDeleteOperationContainerMutation();

  function formatOperationContainerToSave(
    operationContainer: OperationContainerType
  ): OperationContainerToSave {
    return {
      ...operationContainer,
      storage_company: operationContainer.storage_company?.id,
      port_unboarding: operationContainer.port_unboarding?.bi_trigram,
      port_boarding: operationContainer.port_boarding?.bi_trigram,
      crane_name: operationContainer.crane_name,
      operation_type: operationContainer.operation_type?.id,
      container_type: operationContainer.container_type?.id,
      direction_container: operationContainer.direction_container?.id,
      merchants_ce: (operationContainer.merchants_ce || []).map((ce: any) =>
        formatOperationMerchantCEToSave(ce)
      ),
      operation_crane_id: operationContainer.operation_crane_id,
    };
  }

  async function handleDeleteItem() {
    if (isEdit) {
      await deleteOperationContainer(selectedContainer.id);
      selectedStopover.operation.operation_cranes?.forEach((operationCrane) => {
        if (operationCrane.id === selectedContainer.operation_crane_id) {
          setSelectedContainers((prev) =>
            prev.filter(
              (prevContainer) => prevContainer.id !== selectedContainer.id
            )
          );
        }
      });
    }

    setSelectedContainer({} as ContainerTypeDescription);
    message.success('Container removido com sucesso');
    onFinishSubmit();
  }

  // submit form after validation
  async function handleFormSubmit() {
    form
      .validateFields()
      .then(async (values) => {
        values.merchants_ce = selectedMerchantsCE?.map((ce: any) => {
          return {
            ...ce,
            storages: ce.storages.map((storage: any) => {
              return {
                ...storage,
                storage_company: values.storage_company,
              };
            }),
          };
        });

        const containerToSave = formatOperationContainerToSave(values);
        containerToSave.id = operationContainerData?.id;
        if (!selectedContainer?.id) {
          const createdContainer = await createOperationContainer(
            containerToSave
          );
          if ('data' in createdContainer) {
            const { data } = createdContainer;
            selectedStopover.operation.operation_cranes?.forEach(
              (operationCrane) => {
                if (
                  operationCrane.id === createdContainer.data.operation_crane_id
                ) {
                  setSelectedContainers((prev) => [
                    ...prev,
                    formatContainerToDescription(data),
                  ]);
                }
              }
            );
            message.success('Container criado com sucesso');
          }
        } else {
          const updatedContainer = await updateOperationContainer(
            containerToSave
          );
          if ('data' in updatedContainer) {
            const { data } = updatedContainer;
            selectedStopover.operation.operation_cranes?.forEach(
              (operationCrane) => {
                if (
                  operationCrane.id === updatedContainer.data.operation_crane_id
                ) {
                  setSelectedContainers((prev) =>
                    prev.map((container) => {
                      if (container.id === operationContainerData?.id) {
                        return formatContainerToDescription(data);
                      }
                      return container;
                    })
                  );
                }
              }
            );
            message.success('Container atualizado com sucesso');
          }
        }
      })
      .finally(() => {
        onFinishSubmit();
      });
  }

  function afterCloseModal() {
    form.resetFields();
    setSelectedContainer({} as ContainerTypeDescription);
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      setIsVisibleForm(false);
    }
  }

  function getHeader() {
    return (
      <div>
        <span
          style={{ fontWeight: 'bold', fontSize: '16px', marginRight: '5px' }}
        >
          {selectedContainer?.code}
        </span>
        <span style={{ color: 'var(--neutral_medium)' }}>Container</span>
      </div>
    );
  }

  function onSelectPortBoarding(option: string) {
    const portObject = portsBoardingData?.results.find((port) => {
      return port.id === option;
    });
    form.setFieldsValue({
      port_boarding: {
        ...portObject,
      },
    });
  }

  function onSelectPortUnboarding(option: string) {
    const portObject = portsUnboardingData?.results.find((port) => {
      return port.id === option;
    });
    form.setFieldsValue({
      port_unboarding: {
        ...portObject,
      },
    });
  }

  function onSearchCountryPortBoardingByName(val: string) {
    setSearchPortBoardingCountry({ countryName: val });
  }

  function onSearchCountryPortUnboardingByName(val: string) {
    setSearchPortUnboardingCountry({ countryName: val });
  }

  function onSearchPortBoardingByName(val: string) {
    setSearchPortBoarding({
      name: val,
      countryCode: selectedPortBoardingCountry,
    });
  }

  function onSearchPortUnboardingByName(val: string) {
    setSearchPortUnboarding({
      name: val,
      countryCode: selectedPortUnboardingCountry,
    });
  }

  function onSelectPortBoardingCountry(val: any) {
    setSelectedPortBoardingCountry(val);
    setSearchPortBoarding({ countryCode: val });
    form.setFieldsValue({
      port_boarding: { countryCode: val, name: null, id: null },
    });
  }

  function onSelectPortUnboardingCountry(val: any) {
    setSelectedPortUnboardingCountry(val);
    setSearchPortUnboarding({ countryCode: val });
    form.setFieldsValue({
      port_unboarding: { countryCode: val, name: null, id: null },
    });
  }

  function onClearSelectedPortBoardingCountry() {
    setSelectedPortBoardingCountry('');
    form.setFieldsValue({
      port_boarding: { countryCode: null, name: null, id: null },
    });
  }

  function onClearSelectedPortUnboardingCountry() {
    setSelectedPortUnboardingCountry('');
    form.setFieldsValue({
      port_unboarding: { countryCode: null, name: null, id: null },
    });
  }

  function onClearSelectedPortBoarding() {
    form.setFieldsValue({
      port_boarding: { countryCode: null, name: null, id: null },
    });
  }

  function onClearSelectedPortUnboarding() {
    form.setFieldsValue({
      port_unboarding: { countryCode: null, name: null, id: null },
    });
  }

  function setSelectedProduct(value: any) {
    setSelectedContainer(formatContainerToDescription(value));
    setSelectedMerchantsCE(value.merchants_ce);
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      initialValues={operationContainerCurrentData}
      layout="vertical"
      name="container_form"
      id="container_form"
      onFinish={handleFormSubmit}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <ModalBlockNavigation
        name="container_form"
        key="container_form"
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <GenericDrawerHeader
        onBack={() => setIsVisibleForm(false)}
        title={getHeader()}
        showBackButton
        formName="container_form"
        onDelete={isEdit ? handleDeleteItem : undefined}
      />
      <div
        style={{
          marginBlockStart: '24px',
          marginInline: '16px',
        }}
      >
        <Row gutter={40} align="bottom">
          <FormItemInput
            label="Código do container"
            name="code"
            required
            maxLength={30}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemInput
            colSpan={8}
            label="Código do manifesto"
            name="manifest_number"
            required
          />
          <FormItemSelect
            colSpan={16}
            name={['storage_company', 'id']}
            label="Local de armazenamento"
            dataList={storages?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            required
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemInput
            colSpan={8}
            name="crane_name"
            label="Guindaste"
            required
            disabled={craneId !== undefined}
          />
          <FormItemSelect
            colSpan={16}
            name={['direction_container', 'id']}
            label="Sentido do produto"
            dataList={diretionContainersData?.items
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            required
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemInput colSpan={8} name="iso" label="ISO" />
          <FormItemSelect
            colSpan={8}
            name={['container_type', 'id']}
            label="Tipo"
            dataList={containerTypesData?.items
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            required
          />
          <FormItemSelect
            colSpan={8}
            name="is_full"
            label="Cheio"
            dataList={booleanList}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={8}
            name="container_size"
            label="Tamanho"
            dataList={containerSizeList}
          />
          <FormItemInputNumber
            colSpan={8}
            name="container_weight"
            label="Peso"
            addonAfter="ton"
          />
          <FormItemInputNumber
            colSpan={8}
            name="tara"
            label="Tara"
            addonAfter="ton"
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemInput colSpan={8} name="line" label="Linha" maxLength={10} />
          <FormItemInput colSpan={8} name="imo" label="IMO" maxLength={30} />
          <FormItemInput
            colSpan={8}
            name="booking_code"
            label="Booking"
            maxLength={20}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemInput
            colSpan={8}
            name="codantaq"
            label="Código ANTAQ"
            maxLength={20}
          />
          <FormItemSelect
            colSpan={16}
            required
            name={['operation_type', 'id']}
            label="Tipo de operação da carga"
            dataList={operationTypesData?.items
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={12}
            name={['port_boarding', 'country_name']}
            label="País de carregamento"
            allowClear
            showSearch
            onSearch={debounce(onSearchCountryPortBoardingByName, 400)}
            onSelect={onSelectPortBoardingCountry}
            onClear={onClearSelectedPortBoardingCountry}
            dataList={countriesDataPortBoarding?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingCountriesDataPortBoarding}
            notFoundContent="Nenhum país encontrado para o filtro informado"
          />
          <FormItem name={['port_boarding', 'bi_trigram']} noStyle />
          <FormItemSelect
            colSpan={12}
            name={['port_boarding', 'name']}
            label="Porto de carregamento"
            allowClear
            showSearch
            onSearch={debounce((e) => onSearchPortBoardingByName(e), 400)}
            onSelect={onSelectPortBoarding}
            onClear={onClearSelectedPortBoarding}
            dataList={portsBoardingData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingPortBoardingData}
            notFoundContent="Nenhum porto encontrado para o filtro informado"
          />
        </Row>
        <Row gutter={40} align="bottom">
          <FormItemSelect
            colSpan={12}
            name={['port_unboarding', 'country_name']}
            label="País de descarregamento"
            allowClear
            showSearch
            onSearch={debounce(onSearchCountryPortUnboardingByName, 400)}
            onSelect={onSelectPortUnboardingCountry}
            onClear={onClearSelectedPortUnboardingCountry}
            dataList={countriesDataPortUnboarding?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingCountriesDataPortUnboarding}
            notFoundContent="Nenhum país encontrado para o filtro informado"
          />
          <FormItem name={['port_unboarding', 'bi_trigram']} noStyle />
          <FormItemSelect
            colSpan={12}
            name={['port_unboarding', 'name']}
            label="Porto de descarregamento"
            allowClear
            showSearch
            onSearch={debounce((e) => onSearchPortUnboardingByName(e), 400)}
            onSelect={onSelectPortUnboarding}
            onClear={onClearSelectedPortUnboarding}
            dataList={portsUnboardingData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
            isLoading={isLoadingPortUnboardingData}
            notFoundContent="Nenhum porto encontrado para o filtro informado"
          />
        </Row>
      </div>
      <OperationProductConsigneeData
        consigneeCompanies={selectedContainer.merchants_ce || {}}
        selectedProduct={
          operationContainerCurrentData || ({} as OperationContainerType)
        }
        setSelectedProduct={setSelectedProduct}
        isContainer
      />
    </Form>
  );
}
