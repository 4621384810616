import { EditOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, SetStateAction } from 'react';

import { Button } from '../../../../../components';
import {
  formatNumberToLocale,
  isNullOrUndefined,
} from '../../../../../utils/utils';

export function columns(
  onSelectItem: (record: any) => void,
  checkedAll: boolean,
  setCheckedAll: Dispatch<SetStateAction<boolean>>,
  isVehicleManifest: boolean,
  selectedOpDirectionManifest: string
): ColumnsType<Record<string, any>> {
  const columns: any[] = [
    {
      title: () => {
        if (isVehicleManifest) {
          return (
            <Checkbox
              checked={checkedAll}
              onChange={() => setCheckedAll((prev) => !prev)}
            />
          );
        }
        return <div />;
      },
      width: '5%',
      dataIndex: 'check_all',
      render() {
        if (checkedAll) {
          return <Checkbox checked disabled />;
        }
        return <span />;
      },
    },
    {
      title:
        selectedOpDirectionManifest === 'LOADING'
          ? 'EMBARCADOR'
          : 'CONSIGNATÁRIO',
      dataIndex: ['operator_company', 'name'],
      defaultSortOrder: 'ascend' as const,
      width: '15%',
    },
  ];
  if (isVehicleManifest) {
    columns.push({
      title: 'CHASSIS',
      dataIndex: 'chassis',
      defaultSortOrder: 'ascend' as const,
      width: '10%',
    });
  }
  columns.push(
    {
      title: 'CE MERCANTE',
      dataIndex: 'code',
      width: '15%',
    },
    {
      title: 'NCM',
      dataIndex: 'ncm_code',
      width: '10%',
    }
  );
  if (!isVehicleManifest) {
    columns.push({
      title: 'PESO TOTAL',
      dataIndex: 'weight',
      width: '15%',
      render(value: number) {
        return <>{formatNumberToLocale(value)} ton</>;
      },
    });
  }
  columns.push({
    title: 'ARMAZENAMENTO',
    dataIndex: ['storage_terminal', 'name'],
    width: '15%',
  });
  if (!isVehicleManifest) {
    columns.push({
      title: 'Volume',
      dataIndex: 'volume',
      width: '10%',
      render(value: number) {
        return (
          <span>{value ? `${formatNumberToLocale(value)} m³` : null}</span>
        );
      },
    });
  }
  columns.push({
    title: 'OPERADOR',
    dataIndex: 'edit',
    width: '15%',
    render: (_: any, record: any) => {
      if (isNullOrUndefined(record.operator)) {
        return (
          <Button disabled={checkedAll} onClick={() => onSelectItem(record)}>
            Selecionar
          </Button>
        );
      }
      return (
        <div>
          <span>{record.operator.name}</span>
          <Tooltip
            placement="top"
            title={record.cargo_assigned ? 'Carga já associada' : ''}
          >
            <Button
              type="link"
              disabled={record.cargo_assigned}
              icon={<EditOutlined />}
              onClick={() => onSelectItem(record)}
            />
          </Tooltip>
        </div>
      );
    },
  });
  return columns;
}
