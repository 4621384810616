export function SettingsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g data-name="icon/outlined/application/setting">
        <path
          data-name="icon/outlined/application/setting (clip mask)"
          style={{ fill: 'none' }}
          d="M0 0h24v24H0z"
        />
        <path d="M12 22.125a10.068 10.068 0 0 1-1.781-.159l-.06-.011a.717.717 0 0 1-.578-.577l-.354-1.918a7.829 7.829 0 0 1-2.217-1.29l-1.835.654a.711.711 0 0 1-.237.041.72.72 0 0 1-.549-.254l-.04-.047a10 10 0 0 1-1.785-3.1l-.021-.059a.723.723 0 0 1 .209-.791l1.485-1.271a8.021 8.021 0 0 1 0-2.565l-1.486-1.27a.726.726 0 0 1-.209-.79l.021-.059a10.007 10.007 0 0 1 1.785-3.1l.04-.047a.718.718 0 0 1 .548-.254.7.7 0 0 1 .238.041l1.835.654a7.85 7.85 0 0 1 2.217-1.29l.355-1.916a.717.717 0 0 1 .578-.577l.06-.011a10.085 10.085 0 0 1 3.562 0l.06.011a.72.72 0 0 1 .578.577l.352 1.909A7.924 7.924 0 0 1 17 5.949l1.825-.649a.716.716 0 0 1 .238-.041.727.727 0 0 1 .189.025.71.71 0 0 1 .36.229l.04.047a9.946 9.946 0 0 1 1.786 3.1l.02.059a.719.719 0 0 1-.208.79l-1.468 1.257a8.017 8.017 0 0 1 0 2.6l1.467 1.258a.719.719 0 0 1 .208.786l-.02.059a10.042 10.042 0 0 1-1.786 3.1l-.04.047a.716.716 0 0 1-.786.213l-1.821-.65a7.818 7.818 0 0 1-2.233 1.294l-.352 1.91a.72.72 0 0 1-.578.577l-.06.011a10.079 10.079 0 0 1-1.781.154zm-4.668-5.782.7.581A6.248 6.248 0 0 0 9.8 17.951l.847.326.4 2.183a8.362 8.362 0 0 0 1.9 0l.4-2.179.853-.321a6.256 6.256 0 0 0 1.776-1.029l.7-.58 2.078.739a8.332 8.332 0 0 0 .955-1.654L18.043 14l.148-.9a6.339 6.339 0 0 0 0-2.071l-.145-.9L19.719 8.7a8.532 8.532 0 0 0-.954-1.654l-2.079.739-.7-.58a6.3 6.3 0 0 0-1.776-1.029l-.853-.321-.4-2.179a8.461 8.461 0 0 0-1.9 0l-.4 2.183-.857.322a6.248 6.248 0 0 0-1.765 1.027l-.7.581-2.095-.745A8.318 8.318 0 0 0 4.286 8.7l1.691 1.446-.146.9a6.224 6.224 0 0 0-.083 1.022 6.513 6.513 0 0 0 .083 1.021l.146.9-1.691 1.447a8.45 8.45 0 0 0 .954 1.653l2.091-.745zM12 15.795a3.953 3.953 0 1 1 3.942-3.953A3.952 3.952 0 0 1 12 15.795zm0-6.469a2.513 2.513 0 0 0-2.507 2.516 2.51 2.51 0 0 0 3.469 2.325A2.517 2.517 0 0 0 12 9.326z" />
      </g>
    </svg>
  );
}
