import { Checkbox as ANTCheckbox } from 'antd';
import styled from 'styled-components';

export const Checkbox = styled(ANTCheckbox)`
  & .ant-checkbox-disabled .ant-checkbox-inner,
  & .ant-checkbox-checked .ant-checkbox-inner {
    background: #fff;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--green-sea_medium);
    &::after {
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
    }
  }
  & .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    border-color: var(--green-sea_medium) !important;
    background: #fff;
    &::after {
      border: 2px solid var(--green-sea_medium);
      border-top: 0;
      border-left: 0;
    }
  }
`;
