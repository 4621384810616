import { Collapse } from '../../components';
import { StopoverVehiclesImportType } from '../../typesWarehousing';
import { CECards } from '../../utils/lists';
import { DocumentCard } from './DocumentCard';
import { DocumentCardWrapper } from './styles';

const { Panel } = Collapse;

type CEDocsDrawerProps = {
  CEList?: StopoverVehiclesImportType[];
};
export function CEDocsDrawer(props: CEDocsDrawerProps) {
  const { CEList } = props;
  return (
    <div>
      <Collapse expandIconPosition="right">
        {CEList?.map((CEItem, index) => {
          return (
            <Panel header={`CE ${CEItem.merchant_ce}`} key={index}>
              <DocumentCardWrapper>
                {CECards.map((doc, key) => {
                  return (
                    <DocumentCard
                      title={doc.title}
                      folderName={doc.folderName}
                      itemGroupId={CEItem.id}
                      key={key}
                    />
                  );
                })}
              </DocumentCardWrapper>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
