import { CarOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { Descriptions } from '../../../components';
import { TOSStopoverCard } from '../../../components/common/stopoverCard/styles';
import { VehiclesGroupByParkingSpaceType } from '../../../typesWarehousing';

type WarehousingOverviewCardRendererProps = {
  onSelectCard: () => void;
  isSelectedFixedCard: boolean;
  vehiclesByStorageCompany?: VehiclesGroupByParkingSpaceType;
};
export function WarehousingOverviewCardRenderer(
  props: WarehousingOverviewCardRendererProps
) {
  const { onSelectCard, isSelectedFixedCard, vehiclesByStorageCompany } = props;

  return (
    <TOSStopoverCard
      className={isSelectedFixedCard ? 'active' : ''}
      onClick={() => onSelectCard()}
      style={{ marginBottom: '16px' }}
    >
      <Row className="header">
        <Col xl={1} xxl={1}>
          <CarOutlined
            style={{
              color: 'var(--green-sea_dark)',
              fontSize: '16px',
              height: '20px',
            }}
          />
        </Col>
        <Col xl={23} xxl={23}>
          <span
            className="title"
            style={{
              textTransform: 'none',
            }}
          >
            Visão geral de ocupação
          </span>
        </Col>
      </Row>
      <Row className="body">
        <Col xl={1} xxl={1} />
        <Col xl={23} xxl={23}>
          <Descriptions column={1}>
            {vehiclesByStorageCompany && vehiclesByStorageCompany['PPV 1'] && (
              <Descriptions.Item
                label="PPV-1 (Importação)"
                style={{ paddingBottom: '5px', paddingTop: '5px' }}
              >
                {vehiclesByStorageCompany['PPV 1'].counter} veículos (% ocupado)
              </Descriptions.Item>
            )}
            {vehiclesByStorageCompany && vehiclesByStorageCompany['PPV 2'] && (
              <Descriptions.Item
                label="PPV-2 (Exportação)"
                style={{ paddingBottom: '5px' }}
              >
                {vehiclesByStorageCompany['PPV 2'].counter} veículos (% ocupado)
              </Descriptions.Item>
            )}
          </Descriptions>
        </Col>
      </Row>
    </TOSStopoverCard>
  );
}
