import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { changeToken } from '../../app/store/authStore';
import { changeUser } from '../../app/store/userStore';
import { FormItemInput, Button } from '../../components';
import { LayoutLogin } from '../../components/common/layoutLogin/LayoutLogin';
import { useLoginMutation } from '../../services/authApi';
import { LoginType } from '../../types';
import { Form, Title, Footer, ButtonForgotPassword } from './styles';

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();

  async function onFinish(values: any) {
    const userData: LoginType = {
      email: values.email,
      password: values.password,
    };

    const response = await login(userData);

    if ('data' in response && 'user' in response.data) {
      dispatch(changeUser(response.data.user));
      dispatch(changeToken(response.data));
      navigate('/stopover-management');
    }
  }

  return (
    <LayoutLogin>
      <Form onFinish={onFinish} layout="vertical" requiredMark="optional">
        <Title> Bem vindo</Title>
        <div>
          <FormItemInput
            label="Nome do Usuário"
            name="email"
            type="email"
            required
            requiredMessage="Informe seu Usuário"
            disabled={isLoadingLogin}
            placeholder="usuario@email.com"
          />
          <FormItemInput
            label="Senha"
            name="password"
            type="password"
            required
            requiredMessage="Informe seu senha"
            disabled={isLoadingLogin}
            placeholder="******"
          />
          <Footer>
            <ButtonForgotPassword
              onClick={() => navigate('/forgot-my-password')}
            >
              Esqueceu a senha?
            </ButtonForgotPassword>
            <Form.Item>
              <Button
                style={{ width: '106px', height: '40px' }}
                type="primary"
                htmlType="submit"
                block
                icon={!isLoadingLogin && <ArrowRightOutlined />}
              >
                {isLoadingLogin ? <LoadingOutlined /> : 'Entrar'}
              </Button>
            </Form.Item>
          </Footer>
        </div>
      </Form>
    </LayoutLogin>
  );
}
