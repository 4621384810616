import { Collapse as ANTDCollapse } from 'antd';
import styled from 'styled-components';

export const Collapse = styled(ANTDCollapse)`
  border: 0px;
  background: #fff;
  &.inner-collapse {
    border: 1px solid var(--neutral_lighter);
    /* padding: 0px; */
    & .ant-collapse-header {
      background: var(--neutral_lightest);
      color: var(--neutral_medium) !important;
      &:hover {
        background: var(--neutral_lighter);
      }
    }
  }
  & .ant-collapse-item {
    & .ant-collapse-header {
      display: flex;
      align-items: center;
    }
  }
  & .ant-collapse-header {
    border: 0px;
    background: var(--neutral_lighter);
    font-weight: bold;
    font-size: 1rem;
    color: var(--neutral_dark) !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-right: 40px !important;
    min-height: 55px;
    &:hover {
      background: var(--neutral_light);
    }
    & .header-title {
      display: flex;
      align-items: center;
      & .code {
        font-family: 'Courier New';
        color: #262626;
        display: inline-block;
        margin-left: 10px;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }
      & .docs-count {
        display: inline-block;
        margin-left: 10px;
      }
    }
    & .header-description {
      font-size: 13px;
      float: right;
      text-align: right;
      font-weight: normal;
      position: absolute;
      right: 90px;
    }
  }
  & .ant-collapse-item {
    border: 0px;
  }
  & .ant-collapse-content {
    border: 0px;
  }

  &.small-collapse .ant-collapse-content-box {
    padding-left: 20px;
    padding-right: 20px;
  }
  &.small-collapse .ant-collapse-header {
    padding-left: 20px !important;
    padding-right: 40px !important;
  }

  &.bordered-collapse {
    border: solid 2px var(--neutral_lightest);
  }
  @media only screen and (max-width: 1600px) {
    & .ant-collapse-header {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
    & .ant-collapse-content > .ant-collapse-content-box {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  @media only screen and (max-width: 1280px) {
    &.small-collapse .ant-collapse-header {
      font-size: 0.8rem;
      padding-left: 10px !important;
    }
    &.small-collapse .ant-collapse-header .header-description {
      font-size: 10px !important;
    }
  }
`;

export const InnerCollapse = styled(Collapse)`
  border: 1px solid var(--neutral_lighter);
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  & .ant-collapse-item {
    /* margin-block-start: 24px; */
    border-bottom: 1px solid var(--neutral_lighter);
    /* &:last-child {
      border-bottom: 0;
    } */

    & .ant-collapse-content > .ant-collapse-content-box {
      & .panel-content {
        padding: 16px;
      }
    }

    & .ant-collapse-header {
      display: flex;
      flex-direction: row;
      background: var(--neutral_lightest);
      font-size: 16px;
      color: var(--neutral_medium) !important;
      padding: 8px 16px !important;
      font-weight: 700;
      min-height: 40px;
      &:hover {
        background: var(--neutral_lighter);
      }
      /* & .ant-collapse-header-text {
      } */
      & .docking-header-title {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: end;

        & .docking-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }

        & .docking-code {
          font-family: Consolas;
          font-weight: normal;
          font-size: 14px;
          display: inline-block;
        }
      }
    }
  }
  .white-header {
    border: none;
    & .ant-collapse-header {
      display: flex;
      flex-direction: row;
      background: #fff;
      font-size: 16px;
      color: var(--neutral_medium) !important;
      padding: 8px 0 !important;
      font-weight: 700;
      font-size: 14px;
      & .count-tag {
        background: var(--neutral_medium);
        border-radius: 100px;
        color: #fff;
        margin-left: 10px;
        padding: 2px 10px;
        font-weight: normal;
        transition: 150ms;
      }
      & .code {
        font-weight: normal;
        margin-left: 5px;
      }
      &:hover {
        background: #fff;
        color: #3b4f5f !important;
        & .count-tag {
          background: #3b4f5f;
        }
      }
    }
  }
`;
