import { Row, Col, FormInstance } from 'antd';
import { isEmpty } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';

import { VesselSelectData } from '../../../components';
import { Button } from '../../../components/antd/Button';
import { Modal } from '../../../components/antd/Modal';
import { Stopover as StopoverType, Vessel as VesselType } from '../../../types';

type FormItemsVesselProps = {
  readOnly: boolean;
  form: FormInstance;
  onSelectVessel: (value: any) => void;
  selectedVessel?: VesselType;
  selectedStopover?: StopoverType;
  style?: CSSProperties;
  visible?: boolean;
};

export function FormItemsVessel(props: FormItemsVesselProps) {
  const {
    readOnly,
    form,
    selectedVessel,
    selectedStopover,
    style,
    visible,
    onSelectVessel,
  } = props;

  const [showVesselNamesHistoryModal, setShowVesselNamesHistoryModal] =
    useState(false);

  const [searchVessel, setSearchVessel] = useState({
    name: selectedVessel?.name || '',
  });

  useEffect(() => {
    if (
      selectedVessel !== undefined &&
      Object.keys(selectedVessel).length > 0
    ) {
      form.setFieldsValue({
        vessel: {
          ...selectedVessel,
        },
      });
      props.onSelectVessel(selectedVessel);
    }
  }, [selectedVessel]);

  return (
    <>
      {!isEmpty(selectedStopover) && (
        <Modal
          footer={null}
          title="Histórico de nomes"
          width={600}
          className="TOSPrimaryModal"
          visible={showVesselNamesHistoryModal}
          onCancel={() => setShowVesselNamesHistoryModal(false)}
        >
          <p>
            <b>
              Nomes anteriores da embarcação {selectedStopover?.vessel?.name}
            </b>
          </p>
          <br />
          <Row gutter={24} style={{ fontWeight: 600, marginBottom: '15px' }}>
            <Col span={6}>Data da alteração</Col>
            <Col span={9}>Nome da embarcação</Col>
            <Col span={9}>Armador</Col>
          </Row>
          <Row
            gutter={24}
            style={{
              borderBottom: 'solid 1px var(--neutral_lighter)',
              paddingBottom: '10px',
            }}
          >
            <Col span={6}>02/10/2020</Col>
            <Col span={9}>Nome</Col>
            <Col span={9}>Columbia</Col>
          </Row>
          <Row justify="end" style={{ marginTop: '15px' }}>
            <Button
              type="primary"
              onClick={() => setShowVesselNamesHistoryModal(false)}
            >
              OK
            </Button>
          </Row>
        </Modal>
      )}
      <VesselSelectData
        selectedVessel={selectedVessel}
        style={style}
        readOnly={readOnly}
        onSelectVessel={onSelectVessel}
        visibleForm={visible}
        form={form}
        searchVessel={searchVessel}
        setSearchVessel={setSearchVessel}
      />
    </>
  );
}
