import {
  Docking as DockingType,
  DockingTypeDescription,
  ManoeuvreType,
  Product as ProductType,
  ProductTypeDescription,
} from '../../../types';
import {
  booleanList,
  dockingAlongsideList,
  dockingInLineList,
  inspectionOperationList,
  operationTypes,
  positionDockingList,
  purposeList,
  unitsList,
} from '../../../utils/lists';
import {
  addSuffix,
  formatNumberToLocale,
  isNullOrUndefined,
  createDateStringPtBr,
  formatUserName,
} from '../../../utils/utils';
import { formatOperationToDescription } from '../../operation/OperationsData/formatOperationToDescription';

export function formatDockingToDescription(
  docking: DockingType
): DockingTypeDescription {
  return {
    purpose: purposeList.find((item) => item.id === docking.purpose)?.name,
    purpose_id: docking.purpose,
    docking_goal: docking.docking_goal?.description,
    docking_goal_id: docking.docking_goal?.id,
    start_anchoring: createDateStringPtBr(docking.real_time_of_anchoring),
    end_anchoring: createDateStringPtBr(docking.real_time_of_unanchoring),
    expected_anchoring: createDateStringPtBr(docking.expected_anchoring),
    expected_unanchoring: createDateStringPtBr(docking.expected_unanchoring),
    docking_place: docking.docking_place?.name,
    docking_in_line: dockingInLineList.find(
      (item) => item.id === docking.docking_in_line
    )?.name,
    docking_alongside: dockingAlongsideList.find(
      (item) => item.id === docking.docking_alongside
    )?.name,
    position: positionDockingList.find((item) => item.id === docking.position)
      ?.name,
    input_draught: addSuffix(formatNumberToLocale(docking.input_draught), 'm'),
    input_dwt: addSuffix(formatNumberToLocale(docking.input_dwt), 'ton'),
    entrance_displacement: addSuffix(
      formatNumberToLocale(docking.entrance_displacement),
      'ton'
    ),
    start_of_internal_navigation: createDateStringPtBr(
      docking.start_of_internal_navigation
    ),
    end_of_internal_navigation: createDateStringPtBr(
      docking.end_of_internal_navigation
    ),
    departure_time_at_port_entrance: createDateStringPtBr(
      docking.departure_time_at_port_entrance
    ),
    is_support_vessel:
      booleanList.find((item) => item.id === String(docking.is_support_vessel))
        ?.name || 'Não',
    // Manobra de atracação
    pilot_expected_on_board: createDateStringPtBr(
      docking.pilot_expected_on_board
    ),
    pilot_expected_leaving_on_board: createDateStringPtBr(
      docking.pilot_expected_leaving_on_board
    ),
    pilot_expected_leaving_on_board_undocking: createDateStringPtBr(
      docking.pilot_expected_leaving_on_board_undocking
    ),
    expected_berthing: createDateStringPtBr(docking.expected_berthing),
    expected_unberthing: createDateStringPtBr(docking.expected_unberthing),
    start_tied: createDateStringPtBr(docking.first_cable_tied_in),
    end_tied: createDateStringPtBr(docking.last_cable_tied_in),
    bollard_stern_launcher: docking.bollard_stern_launcher?.tag,
    bollard_stern_spring: docking.bollard_stern_spring?.tag,
    bollard_stern_across: docking.bollard_stern_across?.tag,
    bollard_bow_launcher: docking.bollard_bow_launcher?.tag,
    bollard_bow_spring: docking.bollard_bow_spring?.tag,
    bollard_bow_across: docking.bollard_bow_across?.tag,
    first_bow_support_fender: docking.first_bow_support_fender?.tag,
    last_stern_support_fender: docking.last_stern_support_fender?.tag,
    docking_manoeuvre: formatManoeuvreToDescription(docking.docking_manoeuvre),
    // Manobra de desatracação
    output_draught: addSuffix(
      formatNumberToLocale(docking.output_draught),
      'm'
    ),
    output_dwt: addSuffix(formatNumberToLocale(docking.output_dwt), 'ton'),
    exit_displacement: addSuffix(
      formatNumberToLocale(docking.exit_displacement),
      'ton'
    ),
    start_untied: createDateStringPtBr(docking.first_cable_untied_in),
    end_untied: createDateStringPtBr(docking.last_cable_untied_in),
    undocking_manoeuvre: formatManoeuvreToDescription(
      docking.undocking_manoeuvre
    ),
    operation_summary: {
      start_expected_operation: createDateStringPtBr(
        docking.operation_summary?.expected_start
      ),
      end_expected_operation: createDateStringPtBr(
        docking.operation_summary?.expected_finish
      ),
      inspection:
        inspectionOperationList.find(
          (item) => item.id === docking.operation_summary?.inspection
        )?.name || 'Não',
      start_real_time_of_operation: createDateStringPtBr(
        docking.operation_summary?.real_start
      ),
      end_real_time_of_operation: createDateStringPtBr(
        docking.operation_summary?.real_finish
      ),
      nature_of_cargo: docking.operation_summary?.nature_of_cargo?.name,
    },
    products: formatProductsToDescription(docking.products),
    updated_at: docking.updated_at || '',
    financial_tables: docking.financial_tables,
    operations: docking.operations?.map((operation) =>
      formatOperationToDescription(operation)
    ),
  };
}

export function formatToDescription(
  dockings: DockingType[] | null | undefined
): DockingTypeDescription[] {
  return dockings?.map((docking) => formatDockingToDescription(docking)) || [];
}

function formatProductsToDescription(
  products: ProductType[] | null | undefined
): ProductTypeDescription[] | null {
  if (products == null || products === undefined) {
    return null;
  }
  return products?.map((product) => {
    return {
      id: product.id,
      cargo_type: product.cargo_type?.name,
      product_group: product.cargo_type?.product_group.name,
      nature_of_cargo: product.cargo_type?.product_group.nature_of_cargo.name,
      operation_type: operationTypes.find(
        (item) => item.value === product.operation_type
      )?.name,
      direct_discharge:
        product.direct_discharge === true || product.direct_discharge === 'true'
          ? 'Sim'
          : 'Não',
      operator: product.operator?.name,
      traffic_type: product.traffic_type?.description,
      quantity: String(product.quantity),
      storage: product.storage?.name,
      tanking_confirmed:
        product.tanking_confirmed === true ||
        product.tanking_confirmed === 'true'
          ? 'Sim'
          : 'Não',
      unit: unitsList
        .find((item) => item.value === product.unit)
        ?.name.split(',')[1],
    };
  });
}

function formatManoeuvreToDescription(
  manoeuvre: Partial<ManoeuvreType> | undefined
) {
  if (isNullOrUndefined(manoeuvre)) {
    return null;
  }
  return {
    bow_draught: addSuffix(formatNumberToLocale(manoeuvre?.bow_draught), 'm'),
    stern_draught: addSuffix(
      formatNumberToLocale(manoeuvre?.stern_draught),
      'm'
    ),
    supervised:
      booleanList.find((item) => item.id === manoeuvre?.supervised)?.name ||
      'Não',
    on_duty_that_accompanied: formatUserName(
      manoeuvre?.on_duty_that_accompanied
    ),
    pilotage_company: manoeuvre?.pilotage_company?.name,
    start_period_on_board: createDateStringPtBr(manoeuvre?.pilot_on_board),
    end_period_on_board: createDateStringPtBr(manoeuvre?.pilot_leave_on_board),
    pilots: createStringWithPilots(
      formatUserName(manoeuvre?.first_pilot),
      formatUserName(manoeuvre?.second_pilot)
    ),
    practitioner: formatUserName(manoeuvre?.practitioner),
    towage_company: manoeuvre?.towage_company?.name,
    bow_tugboat: manoeuvre?.bow_tugboat?.name,
    stern_tugboat: manoeuvre?.stern_tugboat?.name,
    standby_tugboat: manoeuvre?.standby_tugboat?.name,
    amidship_tugboat: manoeuvre?.amidship_tugboat?.name,
    mooring_company: manoeuvre?.mooring_company?.name,
    confirmed:
      booleanList.find((item) => item.id === manoeuvre?.confirmed)?.name ||
      'Não',
    did_internal_navigation:
      booleanList.find(
        (item) => item.id === String(manoeuvre?.did_internal_navigation)
      )?.name || 'Não',
    did_departure_at_port_entrance:
      booleanList.find(
        (item) => item.id === String(manoeuvre?.did_departure_at_port_entrance)
      )?.name || 'Não',
  };
}

function createStringWithPilots(
  firstPilot: string | null | undefined,
  secondPilot: string | null | undefined
) {
  if (!firstPilot && !secondPilot) {
    return null;
  }

  if (firstPilot && secondPilot) {
    return firstPilot.concat(', ').concat(secondPilot);
  }
  if (firstPilot) {
    return firstPilot;
  }
  if (secondPilot) {
    return secondPilot;
  }
}
