import { Layout } from 'antd';
import styled from 'styled-components';

const Header = styled(Layout.Header)`
  background: #fff;
  padding: 0px;
`;

export function Navbar() {
  return <Header />;
}
