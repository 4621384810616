import { Collapse as ANTDCollapse } from 'antd';
import styled from 'styled-components';

export const BreakdownOccurenceFormHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  height: auto;
  z-index: 1;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(95, 123, 145, 0.2);
  padding: 16px 1px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  & .container-title {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
  }
  * .button-back {
    margin-right: 13px;
    margin-top: 7px;
  }
  & .titles {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  & .title {
    color: var(--suape-black);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  & .title-stopover-code {
    color: var(--neutral_light);
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  & .expand {
    cursor: default;
    display: inline-block;
    margin-right: 15px;
  }
  & .switchLabel {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }
`;

export const BreakdownOccurenceCollapse = styled(ANTDCollapse)`
  border: 0px;
  margin-block-end: 16px;

  & .ant-collapse-header {
    background: var(--neutral_lightest);
    color: var(--neutral_dark) !important;

    &:hover {
      background: var(--neutral_lightest);
    }
    & .header-title {
      display: flex;
      align-items: center;
      > span {
        font-size: 16px;
        font-weight: 700;
        color: var(--neutral_medium);
      }
    }
    & .header-description {
      float: right;
      font-weight: normal;
      font-size: 12px;
      font-weight: 300;
      position: absolute;
      right: 44px;
    }
  }
  & .ant-collapse-item {
    border: 1px solid var(--neutral_lighter);
  }
`;

export const Breakdown = styled.div`
  & .title {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    margin-bottom: 15px;
  }
`;

export const Occurence = styled.div`
  & .title {
    color: var(--neutral_medium);
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    margin-bottom: 15px;
  }
`;
