import styled from 'styled-components';

export const WorkPeriodTitle = styled.div`
  display: flex;
  justify-content: space-between;

  .title {
    color: var(--suape-black);
    margin-right: 8px;
    font-weight: 600;
    font-size: 12px;
  }

  .period-hours {
    font-size: 12px;
    color: var(--neutral_medium);
  }

  .resume {
    font-size: 12px;
    color: var(--neutral_light);
  }
`;

export const ItemOperationCraneResume = styled.div`
  display: flex;
  flex-direction: 'row';
  width: 100%;
  justify-content: space-between;
  padding-right: 10px;
`;
