import { Line, LineToSave } from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

type PatchLineRequestBody = {
  id: number;
  active: boolean;
  reason_for_deactivation?: string;
};

const BASE_URL = '/operational/lines';

export const linesApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getLines: build.query<
      Array<Line>,
      { berth?: string; storage_company_terminal?: number }
    >({
      query: ({ berth, storage_company_terminal }) => {
        return {
          url: BASE_URL,
          method: 'GET',
          params: { berth, storage_company_terminal },
        };
      },
      providesTags: () => [{ type: 'Line', code: '1' }],
    }),
    createLine: build.mutation<Line, LineToSave>({
      query: (line) => {
        return {
          url: BASE_URL,
          method: 'POST',
          body: line,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Line', code: '1' },
          { type: 'DockingPlace', code: '1' },
          { type: 'OperationLine', code: '1' },
        ]),
    }),
    updateLine: build.mutation<Line, LineToSave>({
      query: (line) => {
        return {
          url: `${BASE_URL}/${line.id}`,
          method: 'PUT',
          body: line,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Line', code: '1' },
          { type: 'OperationLine', code: '1' },
        ]),
    }),
    patchLine: build.mutation<Line, PatchLineRequestBody>({
      query: (body) => {
        return {
          url: `${BASE_URL}/${body.id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Line', code: '1' },
          { type: 'OperationLine', code: '1' },
        ]),
    }),
    deleteLine: build.mutation<void, number>({
      query: (lineId) => {
        return {
          url: `${BASE_URL}/${lineId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Line', code: '1' },
          { type: 'OperationLine', code: '1' },
        ]),
    }),
  }),
});

export const {
  useGetLinesQuery,
  useLazyGetLinesQuery,
  useCreateLineMutation,
  useUpdateLineMutation,
  usePatchLineMutation,
  useDeleteLineMutation,
} = linesApi;
