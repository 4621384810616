import styled from 'styled-components';

export const LoadingDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .dropdown-manifests {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: baseline;
  }
`;
