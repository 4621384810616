import moment from 'moment';

import {
  OperationProductCeMercanteType,
  OperationProductStoragesMercanteType,
  OperationProductType,
  OperationProductTypeDescription,
  OperationalOperationType,
  ShiftType,
  ShiftTypeDescription,
  StoppageType,
  StoppageTypeDescription,
  StorageSumaryType,
  WorkPeriodType,
  WorkPeriodTypeDescription,
  OperationalOperationTypeDescription,
  OperationContextType,
  OperationCraneType,
  OperationCraneTypeDescription,
  OperationContainerType,
  ContainerTypeDescription,
  CraneStoragesSummaryByStorageIdType,
  CargosStoragesSummaryType,
  CargosStoragesSummary,
  OperationStatusType,
  CraneWorkPeriodTypeDescription,
  OperationProductCompartmentType,
  OperationProductTotalMovimentedByShiftType,
  TotalMovimentationByPeriodIdType,
  OperationManifestType,
  OperationManifestDescription,
  WorkShiftMovimentationType,
  OperatorsOperationDescription,
} from '../../../types';
import {
  booleanList,
  compartmentTypeList,
  shiftModeList,
  unitsList,
} from '../../../utils/lists';
import {
  addSuffix,
  capitalizeFirstLetter,
  convertMinutesToStringHours,
  convertTonToM3,
  createDate,
  createDateStringPtBr,
  formatNumberToLocale,
  getDurationBetweenDates,
  formatHoursMinutes,
} from '../../../utils/utils';

export function formatStopoverOperations(
  operations: OperationalOperationType[]
) {
  return operations.map((operation) => {
    return {
      ...transformResponseOperation(operation),
    };
  });
}

export function getTypeOfOperation(
  operation: OperationalOperationType
): OperationContextType {
  const GENERAL_CARGO = { id: 1, VEHICLES: [2, 71, 74], CONTAINER: 5 };
  const LIQUID_BULK = 2;
  const SOLID_BULK = 3;

  if (operation.nature_of_cargo?.id === LIQUID_BULK) {
    return 'LIQUID_BULK';
  }
  if (operation.nature_of_cargo?.id === SOLID_BULK) {
    return 'SOLID_BULK';
  }
  if (operation.nature_of_cargo?.id === GENERAL_CARGO.id) {
    if (
      operation.product_group?.id &&
      GENERAL_CARGO.VEHICLES.includes(operation.product_group?.id)
    ) {
      return 'VEHICLES';
    }
    if (operation.product_group?.id === GENERAL_CARGO.CONTAINER) {
      return 'CONTAINER';
    }
  }
  return 'COMMON';
}

export function transformResponseOperation(
  operation: OperationalOperationType
): OperationalOperationType {
  const type = getTypeOfOperation(operation);
  return {
    ...operation,
    type,
    inspection: String(operation.inspection),
    operators: formatOperatorsOperationsToDescription(
      operation.items || [],
      operation.operation_cranes || [],
      type
    ),
    is_cargo_transshipment: String(operation.is_cargo_transshipment),
  } as OperationalOperationType;
}

export function formatOperatorsOperationsToDescription(
  operationProducts: OperationProductType[],
  operationCranes: OperationCraneType[],
  operationType: OperationContextType
) {
  if (operationType === 'CONTAINER') {
    const operators = operationCranes.reduce((groups: any, item) => {
      return {
        ...groups,
        [item.operator.id]: {
          operator_name: item.operator.name,
          operator_id: item.operator.id,
          items: [
            ...(groups[item.operator.id]?.items || []),
            formatOperationCraneToDescription(item),
          ],
        },
      };
    }, {});
    return operators;
  }

  const operators = operationProducts.reduce((groups: any, item) => {
    if (item.operator && item.operator.id !== null) {
      return {
        ...groups,
        [item.operator.id]: {
          operator_name: item.operator.name,
          operator_id: item.operator.id,
          items: [
            ...(groups[item.operator.id]?.items || []),
            formatOperationProductToDescription(item, operationType, groups),
          ],
        },
      };
    }
  }, {});
  return operators;
}

export function formatOperationToDescription(
  operation: OperationalOperationType
): OperationalOperationTypeDescription {
  const type = getTypeOfOperation(operation);
  const suffix =
    type === 'VEHICLES' ? 'un' : type === 'LIQUID_BULK' ? 'm³' : 'ton';
  return {
    id: operation.id || null,
    nature_of_cargo: operation.nature_of_cargo?.name || null,
    product_group: operation.product_group?.name || null,
    inspection:
      booleanList.find((item) => item.id === operation.inspection)?.name ||
      'Não',
    inspection_type: operation.inspection_type?.description,
    real_operation_start: createDateStringPtBr(operation.real_operation_start),
    real_operation_finish: createDateStringPtBr(
      operation.real_operation_finish
    ),
    expected_operation_start: createDateStringPtBr(
      operation.expected_operation_start
    ),
    expected_operation_finish: createDateStringPtBr(
      operation.expected_operation_finish
    ),
    items:
      operation?.items?.map((item) =>
        formatOperationProductToDescription(item, type, operation)
      ) || [],
    work_periods: formatWorkPeriodsDescription(
      operation.work_periods || [],
      type
    ),
    stoppages: formatStoppagesDescription(operation.stoppages || []),
    cargos_storages_summary:
      type !== 'CONTAINER'
        ? formatCargosStoragesSummary(operation.items || [])
        : {},
    operators: {} as OperatorsOperationDescription,
    // formatOperatorsOperationsToDescription(
    //   operation.items || [],
    //   operation.operation_cranes || [],
    //   type
    // ),
    operation_cranes:
      operation.operation_cranes?.map((crane) =>
        formatOperationCraneToDescription(crane)
      ) || [],
    total_operation_lenght: addSuffix(
      operation.total_operation_lenght || 0,
      'horas'
    ),
    total_net_production: addSuffix(
      operation.total_net_production || 0,
      `${suffix}/h`
    ),
    total_gross_production: addSuffix(
      operation.total_gross_production || 0,
      `${suffix}/h`
    ),
    total_operating_hours: addSuffix(
      operation.total_operating_hours || 0,
      'horas'
    ),
    total_non_working_hours: addSuffix(
      operation.total_non_working_hours || 0,
      'horas'
    ),
    total_handled_percent: +(
      ((operation.total_handled || 0) / (operation.total_manifested || 0)) *
      100
    ).toFixed(2),
    total_handled:
      type === 'LIQUID_BULK'
        ? addSuffix(convertTonToM3(operation.total_handled || 0), suffix)
        : addSuffix(operation.total_handled || 0, suffix),
    total_manifested:
      type === 'LIQUID_BULK'
        ? addSuffix(convertTonToM3(operation.total_manifested || 0), suffix)
        : addSuffix(operation.total_manifested || 0, suffix),
    total_handled_cranes: addSuffix(
      operation.total_handled_cranes || 0,
      suffix
    ),
    total_manifested_cranes: addSuffix(
      operation.total_manifested_cranes || 0,
      suffix
    ),
    total_handled_percent_cranes:
      ((operation.total_handled_cranes || 0) /
        (operation.total_manifested_cranes || 0)) *
      100,
    type,
    total_liquid_flow: addSuffix(
      convertTonToM3(operation.total_liquid_flow || 0),
      'm³/h'
    ),
    total_raw_flow: addSuffix(convertTonToM3(operation.total_raw_flow), 'm³/h'),
    total_containers: addSuffix(operation.total_containers, 'container(es)'),
    total_manifest_number_container_description:
      operation.total_manifest_number_container_description,
    total_amount_handled: addSuffix(operation.total_amount_handled, suffix),
    total_weight_handled: addSuffix(operation.total_weight_handled, 'ton'),
    total_products_handled_description:
      operation.total_products_handled_description,
    flow_rate: operation.flow_rate,
    manifolds: operation.manifolds,
    is_cargo_transshipment: !!operation.is_cargo_transshipment,
  };
}

export function formatCargosStoragesSummary(
  products: OperationProductType[]
): CargosStoragesSummaryType {
  const productsByCargoTypeName = products.reduce((prevPro: any, currPro) => {
    if (currPro.cargo_type) {
      return {
        ...prevPro,
        [currPro.cargo_type.name]: formatStorageSumary(
          currPro.operation_manifests,
          currPro.operator?.name,
          currPro.log_status
        ),
      };
    }
  }, {});

  const productsByCargoTypeNameList: CargosStoragesSummaryType[] = Object.keys(
    productsByCargoTypeName
  ).map((cargoType) => {
    return {
      [cargoType]: Object.values(productsByCargoTypeName[cargoType]),
    };
  });
  const cargoStoragesSummaryObj: CargosStoragesSummaryType =
    productsByCargoTypeNameList.reduce((group: any, item: any) => {
      return {
        ...group,
        [item]: item as CargosStoragesSummary,
      };
    }, {});
  return cargoStoragesSummaryObj;
}

export const groupWorkShiftSumByPeriod = (
  input: WorkShiftMovimentationType[]
): { id: number; sum: number }[] => {
  const groupsList: { id: number; sum: number }[] = [];
  input.forEach((workShiftMovimentation) => {
    const groupKey = workShiftMovimentation.work_period_id || 0;
    if (!groupsList.some((item) => item.id === groupKey)) {
      const newGroup = {
        id: groupKey,
        sum: workShiftMovimentation.total_movimented || 0,
      };
      groupsList.push(newGroup);
    } else {
      groupsList.forEach((item) => {
        if (item.id === groupKey) {
          item.sum += workShiftMovimentation.total_movimented || 0;
        }
      });
    }
  }, []);
  return groupsList;
};

export function formatOperationProductToDescription(
  product: OperationProductType,
  operationType: OperationContextType,
  operation?: OperationalOperationType
): OperationProductTypeDescription {
  const suffix =
    operationType === 'VEHICLES'
      ? 'un'
      : operationType === 'LIQUID_BULK'
      ? 'm³'
      : 'ton';
  const periodProduction = groupWorkShiftSumByPeriod(
    product.work_shift_movimentation || []
  );
  periodProduction.sort((a, b) => (a.id > b.id ? 1 : -1));

  return {
    ...product,
    cargo_type: capitalizeFirstLetter(product.cargo_type?.name),
    product_group: capitalizeFirstLetter(
      product.cargo_type?.product_group?.name
    ),
    storage_summary:
      operationType === 'LIQUID_BULK'
        ? formatLiquidBulkStorageSumary(product.operation_manifests)
        : formatStorageSumary(product.operation_manifests),
    all_merchants_ce:
      product.operation_manifests
        ?.map((operationManifest) => operationManifest.merchants_ce)
        .flat() || [],
    connection_time_start: createDateStringPtBr(product.connection_time_start),
    connection_time_end: createDateStringPtBr(product.connection_time_end),
    pump_time_start: createDateStringPtBr(product.pump_time_start),
    pump_time_end: createDateStringPtBr(product.pump_time_end),
    liquid_flow: product.liquid_flow
      ? addSuffix(convertTonToM3(product.liquid_flow), 'm³/h')
      : null,
    raw_flow: product.raw_flow
      ? addSuffix(convertTonToM3(product.raw_flow), 'm³/h')
      : null,
    liquid_flow_ton: product.liquid_flow
      ? addSuffix(product.liquid_flow, 'ton/h')
      : null,
    raw_flow_ton: product.raw_flow
      ? addSuffix(product.raw_flow, 'ton/h')
      : null,
    first_gang_start: createDateStringPtBr(product.first_gang_start),
    last_gang_finish: createDateStringPtBr(product.last_gang_finish),
    code: '',
    operation_length: addSuffix(
      formatNumberToLocale(product.operating_hours),
      'horas'
    ),
    operating_hours: addSuffix(
      formatNumberToLocale(product.operating_hours),
      'horas'
    ),
    non_working_hours: addSuffix(
      formatNumberToLocale(product.non_working_hours),
      'horas'
    ),
    net_production: addSuffix(
      formatNumberToLocale(product.net_production),
      `${suffix}/h`
    ),
    gross_production: addSuffix(
      formatNumberToLocale(product.gross_production),
      `${suffix}/h`
    ),
    total_stopagges:
      operation?.work_periods?.reduce(
        (sum, current) =>
          sum +
          current.work_shifts.reduce(
            (shiftSum, shift) =>
              shiftSum + (operation.stoppages ? operation.stoppages.length : 0),
            0
          ),
        0
      ) || 0,
    total_amount_handled: product.total_amount_handled
      ? addSuffix(product.total_amount_handled, suffix)
      : null,
    total_weight_handled: product.total_weight_handled
      ? addSuffix(product.total_weight_handled, 'ton')
      : null,
    total_manifest_total: product.total_manifest_total
      ? addSuffix(product.total_manifest_total, 'ton')
      : null,
    periodProduction,
    compartments: product.compartments,
    operation_manifests: formatOperationProductManifestsToDescription(
      product.operation_manifests
    ),
  };
}

export function formatOperationProductManifestsToDescription(
  operation_manifests?: OperationManifestType[]
): OperationManifestDescription[] {
  return (
    operation_manifests?.map((operationManifest) => {
      return {
        ...operationManifest,
        all_operator_companies: [],
      };
    }) || []
  );
}

export function formatOperationCraneToDescription(
  operationCrane: OperationCraneType
): OperationCraneTypeDescription {
  return {
    id: operationCrane.id,
    crane_id: operationCrane.crane_name,
    crane: operationCrane.crane_name,
    operator: operationCrane.operator,
    containers: formatContainersToDescription(operationCrane.containers || []),
    operation_length: addSuffix(
      formatNumberToLocale(operationCrane.operating_hours),
      'horas'
    ),
    operating_hours: addSuffix(
      formatNumberToLocale(operationCrane.operating_hours),
      'horas'
    ),
    non_working_hours: addSuffix(
      formatNumberToLocale(operationCrane.non_working_hours),
      'horas'
    ),
    net_production: addSuffix(
      formatNumberToLocale(operationCrane.net_production),
      'un/h'
    ),
    gross_production: addSuffix(
      formatNumberToLocale(operationCrane.gross_production),
      'un/h'
    ),
    total_handled: operationCrane.total_handled,
    total_handled_str: addSuffix(
      formatNumberToLocale(operationCrane.total_handled),
      'un'
    ),
    manifest_total: operationCrane.manifest_total,
    containers_is_finished: operationCrane.containers_is_finished,
    crane_storages_summary: formatCranesStorageSummary(operationCrane),
    total_manifest_number_description:
      operationCrane.total_manifest_number_description,
    work_periods: formatCraneWorkPeriodsDescription(
      operationCrane.work_periods || []
    ),
    total_periods_duration: convertMinutesToStringHours(
      operationCrane.work_periods?.reduce(
        (sum, current) => sum + (current.worked_time || 0),
        0
      ) || 0
    ),
  };
}

function formatCraneWorkPeriodsDescription(
  work_periods: WorkPeriodType[]
): CraneWorkPeriodTypeDescription[] {
  return work_periods.map((workPeriod) => {
    const total_stopagges = workPeriod.total_stoppages * 60;
    return {
      id: workPeriod.id,
      start_of_period: createDateStringPtBr(workPeriod.start_of_period),
      end_of_period: createDateStringPtBr(workPeriod.end_of_period),
      non_working_hours: convertMinutesToStringHours(total_stopagges || 0),
      operating_hours: convertMinutesToStringHours(
        (workPeriod.worked_time || 0) - total_stopagges || 0
      ),
      duration: convertMinutesToStringHours(workPeriod.worked_time || 0),
    };
  });
}

function formatCranesStorageSummary(operationCrane: OperationCraneType) {
  if (operationCrane.containers) {
    const containersByStorage: CraneStoragesSummaryByStorageIdType =
      operationCrane.containers.reduce(
        (
          previousContainer: CraneStoragesSummaryByStorageIdType,
          currentContainer
        ) => {
          return {
            ...previousContainer,
            ...currentContainer.merchants_ce?.reduce((prevMer, currMer) => {
              return {
                ...prevMer,
                ...currMer.storages.reduce((prevSto, currSto) => {
                  if (currSto.storage_company) {
                    return {
                      ...prevSto,
                      [currSto.storage_company.id]: {
                        storage_name: currSto.storage_company.name,
                        storage_id: currSto.storage_company.id,
                        amount: [
                          ...(previousContainer[currSto.storage_company.id]
                            ?.containers || []),
                          currentContainer,
                        ].length,
                        operator: operationCrane.operator.name,
                      },
                    };
                  }
                  return { ...prevSto };
                }, {}),
              };
            }, {}),
          };
        },
        {}
      );
    return Object.keys(containersByStorage).map(
      (key) => containersByStorage[parseInt(key, 10)]
    );
  }
  return [];
}

export function formatContainerToDescription(
  container: OperationContainerType
): ContainerTypeDescription {
  return {
    ...container,
    storage_company: container.storage_company?.name || null,
    container_type: container.container_type?.code || null,
    container_size_str: addSuffix(container.container_size, 'pés'),
    container_weight_str: addSuffix(
      formatNumberToLocale(container.container_weight),
      'ton'
    ),
    tara_str: addSuffix(formatNumberToLocale(container.tara), 'ton'),
    operation_type: container.operation_type?.description || null,
    port_unboarding: container.port_unboarding
      ? `${container.port_unboarding?.country_name}, ${container.port_unboarding?.name}`
      : null,
    port_boarding: container.port_boarding
      ? `${container.port_boarding?.country_name}, ${container.port_boarding?.name}`
      : null,
    crane: container.crane_name,
    merchants_ce: formatProductMerchantCeToDescription(
      container.merchants_ce || []
    ),
    direction_container: container.direction_container?.description || null,
  };
}

export function formatContainersToDescription(
  containers: OperationContainerType[]
): ContainerTypeDescription[] {
  return (
    containers.map((container) => formatContainerToDescription(container)) || []
  );
}

export function formatProductMerchantCeToDescription(
  merchants_ce: OperationProductCeMercanteType[]
) {
  return merchants_ce.reduce((groups: any, item) => {
    return {
      ...groups,
      [item.operator_company.id]: {
        company_name: item.operator_company.name,
        company_code: item.operator_company.cnpj,
        merchants_ce: [
          ...(groups[item.operator_company.id]?.merchants_ce || []),
          formatProductMerchantCeItem(item),
        ],
      },
    };
  }, {});
}

export function formatProductMerchantCeItem(
  item: OperationProductCeMercanteType
) {
  return {
    ...item,
    storages: item.storages.map((storage) => ({
      ...storage,
      cargos_storage: storage.cargos_storage.map((cargo_storage, index) => ({
        ...cargo_storage,
        key: index,
      })),
    })),
  };
}

export function formatWorkPeriodsDescription(
  work_periods: WorkPeriodType[],
  operationType: OperationContextType
): WorkPeriodTypeDescription[] {
  const suffix = operationType === 'VEHICLES' ? 'un' : 'ton';
  return work_periods.map((workPeriod) => ({
    id: workPeriod.id,
    start_of_period: createDateStringPtBr(workPeriod.start_of_period),
    end_of_period: createDateStringPtBr(workPeriod.end_of_period),
    shift_mode:
      shiftModeList.find((item) => item.id === workPeriod.shift_mode)?.name ||
      null,
    period_productivity: addSuffix(
      workPeriod.total_cargo_operated <= 0
        ? 0
        : Math.ceil(
            workPeriod.total_cargo_operated / (workPeriod.worked_time / 60)
          ),
      `${suffix}/h`
    ),
    total_stoppages: addSuffix(Math.ceil(workPeriod.total_stoppages / 60), 'h'),
    worked_time: addSuffix(Math.ceil(workPeriod.worked_time / 60), 'hora(s)'),
    work_shifts: formatShiftDescription(
      workPeriod.work_shifts || [],
      operationType
    ),
    total_cargo_operated: addSuffix(workPeriod.total_cargo_operated, suffix),
    worked_productivity: addSuffix(
      workPeriod.total_cargo_operated <= 0
        ? 0
        : Math.ceil(
            workPeriod.total_cargo_operated /
              ((workPeriod.worked_time - workPeriod.total_stoppages) / 60)
          ),
      `${suffix}/h`
    ),
  }));
}

export function formatShiftDescription(
  shifts: ShiftType[],
  operationType: OperationContextType
  // operation: OperationalOperationType
): ShiftTypeDescription[] {
  const suffix = operationType === 'VEHICLES' ? 'un' : 'ton';
  return shifts.map((shift) => {
    // const totalDurationStoppages =
    //   operation.stoppages && operation.stoppages.length > 0
    //     ? operation.stoppages
    //         .map((stoppage) => stoppage.duration)
    //         .reduce((prev, next) => (prev || 0) + (next || 0))
    //     : 0;
    const workedTime = moment
      .duration(
        createDate(shift.shift_end)?.diff(createDate(shift.shift_start))
      )
      .asHours();
    return {
      id: shift.id,
      shift_start: createDateStringPtBr(shift.shift_start),
      shift_end: createDateStringPtBr(shift.shift_end),
      // stoppages: formatStoppagesDescription(shift.stoppages || []),
      gang_amount: shift.gang_amount,
      hour_productivity: addSuffix(shift.hour_productivity, `${suffix}/h`),
      inoperative_time: addSuffix(shift.inoperative_time, 'h'),
      operated_cargo_amount: addSuffix(shift.operated_cargo_amount, suffix),
      // total_duration_stoppages: convertMinutesToStringHours(
      //   totalDurationStoppages || 0
      // ),
      workedTime: addSuffix(Math.ceil(workedTime || 0), 'h'),
      gross_productivity: addSuffix(
        workedTime === 0
          ? 0
          : Math.ceil(shift.operated_cargo_amount / workedTime),
        `${suffix}/h`
      ),
    };
  });
}

export function formatStoppagesDescription(
  stoppages: StoppageType[]
): StoppageTypeDescription[] {
  return stoppages.map((stoppage) => ({
    id: String(stoppage.id),
    motive: stoppage.motive.description,
    start: createDateStringPtBr(stoppage.start),
    end: createDateStringPtBr(stoppage.end),
    duration: formatHoursMinutes(
      getDurationBetweenDates(stoppage.start, stoppage.end, 'ms')
    ),
    workPeriodsIndex: stoppage.workPeriodsIndex,
    workPeriodsInpacted: stoppage.workPeriodsInpacted,
    observations: stoppage.observations,
    compartments: stoppage.compartments.map((compartment) => {
      return formatCompartmentDescription(compartment);
    }),
    is_total: stoppage.is_total,
    operation_cranes: stoppage.operation_cranes,
  }));
}

export function formatCompartmentDescription(
  compartment: OperationProductCompartmentType
) {
  const type = compartmentTypeList.find(
    (type) => type.id === compartment.compartment_type
  );
  const unit = unitsList.find((type) => type.value === compartment.unit);
  return {
    id: compartment.id,
    name: `${type?.name} ${compartment.name}`,
    quantity: `${compartment.quantity} ${unit?.abbreviation}`,
  };
}

export function formatStorageSumary(
  operationManifestList?: OperationManifestType[],
  operator?: string,
  operationItemStatus?: OperationStatusType
): StorageSumaryType {
  let allStorages: OperationProductStoragesMercanteType[] = [];
  operationManifestList?.forEach((operationManifest) => {
    operationManifest.merchants_ce.forEach((merchantCE) => {
      allStorages = allStorages.concat(merchantCE.storages);
    });
  });
  const storagesSummary = allStorages.reduce((groups: any, item) => {
    if (item.storage_company) {
      return {
        ...groups,
        [item.storage_company.id]: {
          total_amount:
            (groups[item.storage_company.id]?.total_amount || 0) +
            (item?.total_amount || 0),
          total_weight:
            (groups[item.storage_company.id]?.total_weight || 0) +
            (item?.total_weight || 0),
          storage_name: item.storage_company?.name || null,
          operator,
          status: operationItemStatus,
        },
      };
    }
    return { ...groups };
  }, {});
  return storagesSummary;
}

export function formatLiquidBulkStorageSumary(
  operationManifestsList?: OperationManifestType[]
): StorageSumaryType {
  let allStorages: OperationProductStoragesMercanteType[] = [];
  let allMerchantsCE: OperationProductCeMercanteType[] = [];

  operationManifestsList?.forEach((operationManifest) => {
    allMerchantsCE = allMerchantsCE.concat(operationManifest.merchants_ce);
    operationManifest.merchants_ce.forEach((merchantCE) => {
      allStorages = allStorages.concat(merchantCE.storages);
    });
  });

  const storagesSummary = allStorages.reduce((groups: any, item) => {
    const merchantCE = allMerchantsCE.find(
      (ce) => ce.id === item.merchant_ce_id
    );
    if (item.storage_company) {
      return {
        ...groups,
        [item.storage_company.id]: {
          total_amount:
            (groups[item.storage_company.id]?.total_amount || 0) +
            (item?.total_amount || 0),
          total_weight:
            (groups[item.storage_company.id]?.total_weight || 0) +
            (item?.total_weight || 0),
          total_density:
            (groups[item.storage_company.id]?.total_density || 0) +
            (item?.total_density || 0),
          storage_name: item.storage_company?.name || null,
          distributor: merchantCE?.operator_company?.name || null,
        },
      };
    }
    return { ...groups };
  }, {});
  return storagesSummary;
}

export function formatOperationProductTotalMovimentedByShift(
  operation: OperationalOperationType
): TotalMovimentationByPeriodIdType {
  if (operation.items !== undefined) {
    const totalMovimentedByProductMatrix: OperationProductTotalMovimentedByShiftType[][] =
      operation.items.map((operationProduct) =>
        operationProduct.work_shift_movimentation.map(
          (operationProductMovimentation) => {
            const shift = operation.work_periods
              .find(
                (workPeriod) =>
                  workPeriod.id === operationProductMovimentation.work_period_id
              )
              ?.work_shifts.find(
                (workShift) =>
                  workShift.id === operationProductMovimentation.work_shift_id
              );
            // const workPeriodMovimentations =
            //   operationProduct.work_shift_movimentation.filter(
            //     (workShiftMovimentation) =>
            //       workShiftMovimentation.work_period_id ===
            //       shift?.work_period_id
            //   );

            return {
              name: operationProductMovimentation.name,
              work_period_id: shift?.work_period_id,
              work_shift_id: shift?.id,
              operation_product_id: operationProduct.id,
              operation_product_name: operationProduct.cargo_type?.name,
              work_shift_period_time: `${moment(shift?.shift_start).format(
                'HH:mm'
              )} - ${moment(shift?.shift_end).format('HH:mm')}`,
              work_shift_gang_amount: shift?.gang_amount,
              total_movimented: operationProductMovimentation.total_movimented,
              total_by_product:
                operationProduct.work_shift_movimentation.reduce(
                  (previous, current) => {
                    previous += current.total_movimented || 0;
                    return previous;
                  },
                  0
                ),
              total_by_product_by_period:
                operationProduct.work_shift_movimentation
                  .filter(
                    (workShiftMovimentation) =>
                      workShiftMovimentation.work_period_id ===
                      shift?.work_period_id
                  )
                  .reduce((previous, current) => {
                    previous += current.total_movimented || 0;
                    return previous;
                  }, 0) || 0,
              total_by_shift: shift?.work_shift_movimentation
                ?.filter(
                  (wsm) =>
                    wsm.operation_product_id !== null &&
                    wsm.operation_product_id !== undefined
                )
                .reduce((previous, current) => {
                  previous += current.total_movimented || 0;
                  return previous;
                }, 0),
              cargo_storage_merchant_id:
                operationProductMovimentation.cargo_storage_merchant_id,
              // total_by_period: workPeriodMovimentations.reduce(
              //   (previous, current) => {
              //     previous += current.total_movimented || 0;
              //     return previous;
              //   },
              //   0
              // ),
            } as OperationProductTotalMovimentedByShiftType;
          }
        )
      );
    const allMovimentations: OperationProductTotalMovimentedByShiftType[] =
      totalMovimentedByProductMatrix.flat(2);

    const totalMovimentationsByPeriodId = allMovimentations.reduce(
      (groups: TotalMovimentationByPeriodIdType, item) => {
        return {
          ...groups,
          [item.work_period_id]: [
            ...(groups[item.work_period_id] || []),
          ].concat(item),
        };
      },
      {}
    );
    return totalMovimentationsByPeriodId;
  }
  return {};
}
