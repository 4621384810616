import { isObject } from 'lodash';

import { Descriptions, FormatItem } from '../../../../../../components';
import { CargoType } from '../../../../../../types';
import {
  capitalizeFirstLetter,
  createDateStringPtBr,
} from '../../../../../../utils/utils';
import { ExpandedRowLineWrapper } from './styles';

const { Item } = Descriptions;

type ExpandedRowLineProps = {
  record: any;
};
export function ExpandedRowLine(props: ExpandedRowLineProps) {
  const { record } = props;

  return (
    <ExpandedRowLineWrapper>
      <Descriptions column={1}>
        <Item label="Produtos">
          <FormatItem>
            {record.cargo_types?.map(
              (cargo_type: CargoType | string, index: number) => (
                <span key={index}>{`${capitalizeFirstLetter(
                  isObject(cargo_type) ? cargo_type.name : cargo_type
                )}${
                  index !== record.cargo_types.length - 1 ? ', ' : ''
                }`}</span>
              )
            )}
          </FormatItem>
        </Item>
      </Descriptions>
      <Descriptions column={1}>
        <Item label="Última desativação">
          <FormatItem>
            {record.deactivated_at
              ? `${createDateStringPtBr(record.deactivated_at)} - ${
                  record.reason_for_deactivation
                }`
              : 'Não informada'}
          </FormatItem>
        </Item>
      </Descriptions>
    </ExpandedRowLineWrapper>
  );
}
