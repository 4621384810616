import styled from 'styled-components';

export const SelectOperatorModalContainer = styled.div`
  padding-top: 8px;

  .ncm-description {
    border: 16px solid #ebf2f7;
    border-left: 32px solid #ebf2f7;
    border-right: 32px solid #ebf2f7;
    border-radius: 4px;
    padding: 16px;
  }

  .select-operator-info {
    display: flex;
    flex-direction: column;
    margin: 16px 32px;
  }
`;
