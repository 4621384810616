import { ConfigProvider } from 'antd';

import { SlimTable, TableWrapper } from '../../../../../components';
import {
  AllTableItemsType,
  ChildProratedType,
  DiscountItemType,
} from '../../../../../typesFinancial';
import { formatNumberToLocale } from '../../../../../utils/utils';

type DiscountItemsTableProps = {
  dataSource: DiscountItemType[];
  rowKey?: string;
  totalValue: number;
  // totalAmount: number;
};

export function DiscountItemsTable(props: DiscountItemsTableProps) {
  const { dataSource, rowKey, totalValue } = props;

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }

  function getTableSummary() {
    const totalPercentual = (dataSource || []).reduce(
      (sum, current) => sum + (current.percentual || 0),
      0
    );

    return (
      <SlimTable.Summary fixed>
        <SlimTable.Summary.Row key="row_0">
          <SlimTable.Summary.Cell index={0} colSpan={1} key={0} align="left">
            Totais:
          </SlimTable.Summary.Cell>
          <SlimTable.Summary.Cell index={1} colSpan={1} key={1} align="right">
            {totalPercentual}%
          </SlimTable.Summary.Cell>
          <SlimTable.Summary.Cell index={2} colSpan={1} key={2} align="right">
            {formatNumberToLocale(totalValue, true)}
          </SlimTable.Summary.Cell>
        </SlimTable.Summary.Row>
      </SlimTable.Summary>
    );
  }

  return (
    <TableWrapper>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <SlimTable
          rowKey={rowKey || 'key'}
          columns={[
            {
              title: 'Item',
              dataIndex: 'id',
              key: 'items',
              width: '70%',
              render(_value, record: any) {
                return <span>{record.name}</span>;
              },
            },
            {
              title: '%',
              dataIndex: 'percentual',
              key: 'percentual',
              width: '10%',
              align: 'right',
              render(value) {
                return (
                  <span style={{ fontFamily: 'Consolas' }}>
                    {formatNumberToLocale(value)}%
                  </span>
                );
              },
            },
            {
              title: 'Valor',
              dataIndex: 'value',
              key: 'value',
              align: 'right',
              width: '20%',
              render(value) {
                return (
                  <span style={{ fontFamily: 'Consolas' }}>
                    {formatNumberToLocale(value, true)}
                  </span>
                );
              },
            },
          ]}
          dataSource={dataSource}
          className="read-only"
          pagination={false}
          summary={() => getTableSummary()}
        />
      </ConfigProvider>
    </TableWrapper>
  );
}
