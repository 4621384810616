import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';

// stylesheet
import './styles/App.css';

import { useAppSelector } from './app/hooks';
import { RootState } from './app/store';
import { SideNavbar } from './components/common/sideNavbar/SideNavbar';
// routes
import { sideNavbarItens } from './menuItens';
import { Routes } from './routes';
import GlobalStyle from './styles/globalStyles';

const Content = styled(Layout.Content)`
  background-color: var(--neutral_lightest);
`;

function App() {
  const { token } = useAppSelector((state: RootState) => state.auth);

  return (
    <div>
      <GlobalStyle />
      <Layout style={{ minHeight: '100vh' }} hasSider>
        <Router>
          {token && <SideNavbar navbarItens={sideNavbarItens} />}
          <Content>
            <Routes />
          </Content>
        </Router>
      </Layout>
    </div>
  );
}

export default App;
