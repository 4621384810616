import { ConfigProvider, Spin } from 'antd';

import { TableWrapper } from '../../../components';
import { ReportsTable } from '../styles';
import { operationsTableColumns } from './operationsTableColumns';

type OperationsTableProps = {
  dataSource: Record<string, any>[];
  scrollOptions?: Record<string, any>;
  total?: number;
  itemsPerPage?: number;
  isLoading?: boolean;
  pageSize?: number;
  showSizeChanger?: boolean;
  rowKey?: string;
  onChangePagination?: (page: number) => number;
  queryPage: number;
  onSelectItem: (record: any) => void;
};

export function OperationsTable(props: OperationsTableProps) {
  const {
    dataSource,
    scrollOptions,
    total,
    itemsPerPage,
    onChangePagination,
    isLoading,
    pageSize,
    showSizeChanger,
    rowKey,
    queryPage,
    onSelectItem,
  } = props;

  function customizeRenderEmpty() {
    return <>Não há dados cadastrados.</>;
  }
  return (
    <Spin spinning={isLoading} tip="Carregando...">
      <TableWrapper>
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <ReportsTable
            rowKey={rowKey || 'key'}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: itemsPerPage,
              pageSize,
              total,
              onChange: onChangePagination,
              showSizeChanger,
              current: queryPage,
            }}
            columns={operationsTableColumns(onSelectItem)}
            dataSource={dataSource}
            scroll={
              scrollOptions || { y: '60vh', scrollToFirstRowOnChange: false }
            }
          />
        </ConfigProvider>
      </TableWrapper>
    </Spin>
  );
}
