import { AnchoringWindowIcon } from './assets/icons/anchoringWindow';
import { ManoeuvreBoardIcon } from './assets/icons/manoeuvreBoard';
import { OperationIcon } from './assets/icons/operation';
import { ReportIcon } from './assets/icons/reports';
import { SettingsIcon } from './assets/icons/settings';
import { StaymentManagementIcon } from './assets/icons/staymentManagement';
import { StopoverScheduleIcon } from './assets/icons/stopoverSchedule';
import { StorageTerminalIcon } from './assets/icons/storageTerminalIcon';
import { SideNavbarItensType } from './types';
import {
  AGENT_GROUP_ID,
  OPERATOR_GROUP_ID,
  STORAGE_TERMINAL_GROUP_ID,
  VEHICLES_WAREHOUSING_OPERATOR_GROUP_ID,
} from './utils/utils';

export const sideNavbarItens: SideNavbarItensType[] = [
  // {title: "Painel", key: '/dashboard', icon: dashboard},
  { title: 'Escalas', key: '/stopover-management', icon: StopoverScheduleIcon },
  {
    title: 'Atracações',
    key: '/stayment-management',
    icon: StaymentManagementIcon,
  },
  {
    title: 'Área do Agente',
    key: '/agent-area',
    icon: StopoverScheduleIcon,
    permissions: [AGENT_GROUP_ID],
  },
  {
    title: 'Janela de atracação',
    key: '/anchoring-window',
    icon: AnchoringWindowIcon,
  },
  {
    title: 'Quadro de manobras',
    key: '/manoeuvre-board',
    icon: ManoeuvreBoardIcon,
  },
  {
    title: 'Armazenamento',
    key: '/warehousing',
    icon: StopoverScheduleIcon,
    subItens: [
      { title: 'Pátio de veículos', key: '/warehousing/vehicles' },
      {
        title: 'Leitura de notas fiscais',
        key: '/warehousing/reading-invoices',
      },
      {
        title: 'Área do operador de Pátio',
        key: '/warehousing/vehicles/operator-area-warehousing',
        permissions: [VEHICLES_WAREHOUSING_OPERATOR_GROUP_ID],
      },
    ],
    permissions: [VEHICLES_WAREHOUSING_OPERATOR_GROUP_ID],
  },
  {
    title: 'Terminais de Armazenamento',
    key: '/warehousing/storage-terminal',
    icon: StorageTerminalIcon,
    permissions: [STORAGE_TERMINAL_GROUP_ID],
  },
  {
    title: 'Janela de bombeios',
    key: '/pump-window',
    icon: AnchoringWindowIcon,
    permissions: [STORAGE_TERMINAL_GROUP_ID],
  },
  {
    title: 'Área do Operador',
    key: '/operator-area',
    icon: OperationIcon,
    permissions: [OPERATOR_GROUP_ID],
  },
  {
    title: 'Faturamento',
    key: '/invoicing',
    icon: StopoverScheduleIcon,
  },
  {
    title: 'Relatórios',
    key: '/reports',
    icon: ReportIcon,
  },
  {
    title: 'Configurações',
    key: '/settings',
    icon: SettingsIcon,
    subItens: [
      { title: 'Usuários e permissões', key: '/settings/permissions' },
      { title: 'Cadastros', key: '/settings/registers' },
    ],
  },
  // {
  //   title: 'Operações',
  //   key: '/operations',
  //   icon: OperationIcon,
  // },
];
