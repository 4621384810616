import styled from 'styled-components';

export const CompartmentList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  color: var(--neutral_light);
  width: 100%;

  > span {
    border: 1px solid #ccdde9;
    padding: 0px 8px;
    border-right: 1px solid var(--neutral_light);
    margin-bottom: 2px;

    &.checked {
      background: var(--neutral_lighter);
      color: #000;
    }
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
`;
