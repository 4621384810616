import { Space } from 'antd';
import { Moment } from 'moment';
import { Button, Form, FormItemDatePicker, Modal } from '../../../components';
import { disabledDateAfterToday } from '../../../utils/utils';

type ActualTimeOfArrivalModalProps = {
  showModal: boolean;
  setShowModal: (e: boolean) => void;
  setAtaDate: (e: null | Moment) => void;
};

export function ActualTimeOfArrivalModal(props: ActualTimeOfArrivalModalProps) {
  const { showModal, setShowModal, setAtaDate } = props;
  const [form] = Form.useForm();

  function handleFormSubmit() {
    form.validateFields().then(async (values) => {
      setAtaDate(values.actual_time_of_arrival);
      form.resetFields();
      setShowModal(false);
    });
  }

  function onCancel() {
    form.resetFields();
    setAtaDate(null);
    setShowModal(false);
  }

  return (
    <Modal
      visible={showModal}
      title="Preencha a hora real de chegada"
      className="TOSPrimaryModal"
      onCancel={onCancel}
      width={600}
      footer={null}
    >
      <b>
        Para salvar esse formulário é necessário informar a hora real de chegada
        (ATA)
        <br />
        <br />
      </b>
      <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
        <FormItemDatePicker
          name="actual_time_of_arrival"
          label="Hora real de chegada (ATA):"
          colSpan={12}
          disabledDate={disabledDateAfterToday}
          rules={[
            {
              required: true,
              message: 'Por favor, preencha a data e hora real de chegada',
            },
          ]}
        />
        <Space
          size={12}
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            marginTop: '20px',
          }}
        >
          <Button type="text" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="primary" htmlType="submit">
            Finalizar
          </Button>
        </Space>
      </Form>
    </Modal>
  );
}
