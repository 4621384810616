import { Collapse, GenericDrawerHeader } from '../../../../components';
import {
  StoppageTypeDescription,
  WorkPeriodTypeDescription,
} from '../../../../types';
import { StoppagesByShiftTable } from './stoppagesByShiftTable/StoppagesByShiftTable';

type StoppagesByPeriodDescriptionProps = {
  selectedStoppages?: StoppageTypeDescription;
  selectedWorkPeriods?: WorkPeriodTypeDescription[];
  setDrawerVisible: (value: boolean) => void;
};

const { Panel } = Collapse;

export function StoppagesByPeriodDescription(
  props: StoppagesByPeriodDescriptionProps
) {
  const { selectedStoppages, selectedWorkPeriods, setDrawerVisible } = props;

  function getHeader() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span
          style={{
            fontSize: '16px',
            fontWeight: '700',
            color: 'var(--suape-black)',
          }}
        >
          Paralisações
        </span>
      </div>
    );
  }

  return (
    <>
      <GenericDrawerHeader
        onBack={() => setDrawerVisible(false)}
        title={getHeader()}
        showBackButton
      />
      <div>
        {selectedWorkPeriods?.map((workPeriod, index) => {
          return (
            <Collapse key={index} expandIconPosition="end">
              <Panel key={index} header={`Período ${index + 1}`}>
                {/* {workPeriod.work_shifts.map((workShift, index) => (
                  <StoppagesByShiftTable
                    key={index}
                    dataSource={workShift.stoppages}
                    totalHours={workShift.total_duration_stoppages}
                    index={index + 1}
                  />
                ))} */}
              </Panel>
            </Collapse>
          );
        })}
      </div>
    </>
  );
}
