import { formatStaymentLog } from '../components/common/staymentLog/formatStaymentLog';
import { FormatedStaymentLog, StaymentLogType } from '../types';
import { getHeaders, tosApi } from './tosApi';

export const stopoverLogApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getStopoverLog: build.query<
      FormatedStaymentLog[],
      { stopover_id?: number; docking_id?: number }
    >({
      query: (params) => {
        return {
          url: `/maritime/stopoveres/logs`,
          method: 'GET',
          params,
          headers: getHeaders,
        };
      },
      providesTags: (_result, _error, code) => [
        { type: 'StopoverLog', code: '1' },
      ],
      transformResponse: (response: StaymentLogType) => {
        return formatStaymentLog(response);
      },
    }),
  }),
});

export const { useGetStopoverLogQuery } = stopoverLogApi;
