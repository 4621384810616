import { ColumnsType } from 'antd/lib/table';

export function operatedContainersTableColumns(): ColumnsType<
  Record<string, any>
> {
  const columns: ColumnsType<Record<string, any>> = [
    {
      title: '',
      key: 'direction_container',
      dataIndex: 'direction_container',
      className: 'direction-container',
      align: 'right' as const,
      width: '30%',
    },
    {
      title: '20"',
      className: 'group',
      align: 'center' as const,
      width: '35%',
      children: [
        {
          title: 'Cheio',
          key: 'full',
          dataIndex: 'full',
          className: 'group-item',
          align: 'center' as const,
          render: (value: any, record: any) => {
            return record.size['20'].full;
          },
        },
        {
          title: 'Vazio',
          key: 'empty',
          dataIndex: 'empty',
          className: 'group-item',
          align: 'center' as const,
          render: (value: any, record: any) => {
            return record.size['20'].empty;
          },
        },
      ],
    },
    {
      title: '22"',
      className: 'group',
      align: 'center' as const,
      width: '35%',
      children: [
        {
          title: 'Cheio',
          key: 'full',
          dataIndex: 'full',
          className: 'group-item',
          align: 'center' as const,
          render: (value: any, record: any) => {
            return record.size['22'].full;
          },
        },
        {
          title: 'Vazio',
          key: 'empty',
          dataIndex: 'empty',
          className: 'group-item',
          align: 'center' as const,
          render: (value: any, record: any) => {
            return record.size['22'].empty;
          },
        },
      ],
    },
    {
      title: '40"',
      className: 'group',
      align: 'center' as const,
      width: '35%',
      children: [
        {
          title: 'Cheio',
          key: 'full',
          dataIndex: 'full',
          className: 'group-item',
          align: 'center' as const,
          render: (value: any, record: any) => {
            return record.size['40'].full;
          },
        },
        {
          title: 'Vazio',
          key: 'empty',
          dataIndex: 'empty',
          className: 'group-item',
          align: 'center' as const,
          render: (value: any, record: any) => {
            return record.size['40'].empty;
          },
        },
      ],
    },
  ];

  return columns;
}
