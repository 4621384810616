import {
  ArrowLeftOutlined,
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { ReactNode } from 'react';

import { Button } from '../../components/antd/Button';
import { FormHeaderWrapper } from '../../components/common/formHeader/styles';

export type RegisterHeaderFormProps = {
  children?: ReactNode;
  onClose: (show: null) => void;
  formName?: string;
  isLoading?: boolean;
  allowDelete?: boolean;
  onDelete?: () => void;
};

export function RegisterHeaderForm(props: RegisterHeaderFormProps) {
  function onTryDelete() {
    confirm({
      title: 'Deseja realmente excluir este cadastro?',
      icon: <ExclamationCircleOutlined />,
      content: 'O cadastro excluído não poderá ser recuperado',
      onOk() {
        if (props.onDelete) {
          props.onDelete();
        }
      },
      okText: 'Sim',
      cancelText: 'Não',
    });
  }

  return (
    <FormHeaderWrapper>
      <div className="buttons">
        <div style={{ marginLeft: '-15px' }}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => props.onClose(null)}
          >
            Voltar
          </Button>
        </div>
        <div>
          {props.allowDelete && (
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              danger
              disabled={props.isLoading}
              style={{ marginRight: '20px' }}
              onClick={() => onTryDelete()}
            >
              Excluir
            </Button>
          )}
          <Button
            type="primary"
            icon={props.isLoading ? <LoadingOutlined /> : <CheckOutlined />}
            htmlType="submit"
            name={props.formName}
            disabled={props.isLoading}
          >
            Salvar
          </Button>
        </div>
      </div>
      {props.children}
    </FormHeaderWrapper>
  );
}
