export function VesselRequestUpdateIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13423_1842)">
        <path
          d="M7.05576 4.69867L6.87426 7.09762L3.99035 8.86767C3.93908 8.89913 3.92288 8.96683 3.95434 9.0181L4.29834 9.57857C4.32981 9.62984 4.3975 9.64604 4.44877 9.61457L7.65778 7.64499C7.68808 7.62639 7.70746 7.59524 7.70992 7.56005L7.93228 4.77028C7.93683 4.70974 7.89174 4.6572 7.83237 4.65194L7.17339 4.5976C7.11169 4.59377 7.05915 4.63885 7.05576 4.69867Z"
          fill="#E65050"
        />
        <path
          d="M0.875 7C0.875 3.61758 3.61758 0.875 7 0.875C10.3824 0.875 13.125 3.61758 13.125 7C13.125 7.26347 13.1084 7.52306 13.0761 7.77778H12.0268C12.0657 7.5242 12.0859 7.26445 12.0859 7C12.0859 4.1918 9.8082 1.91406 7 1.91406C4.1918 1.91406 1.91406 4.1918 1.91406 7C1.91406 9.40677 3.58714 11.4239 5.83333 11.9514V13.014C3.00878 12.4692 0.875 9.98348 0.875 7Z"
          fill="#E65050"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6923 7.88889L13.5811 12.8889C13.6665 13.0375 13.5595 13.2222 13.3887 13.2222H7.61127C7.44045 13.2222 7.33351 13.0375 7.41892 12.8889L10.3076 7.88889C10.35 7.81458 10.425 7.77778 10.5 7.77778C10.575 7.77778 10.6493 7.81458 10.6923 7.88889ZM10.2083 9.33333H10.7917V11.0833H10.2083V9.33333ZM10.9861 12.1528C10.9861 12.4212 10.7685 12.6389 10.5 12.6389C10.2315 12.6389 10.0139 12.4212 10.0139 12.1528C10.0139 11.8843 10.2315 11.6667 10.5 11.6667C10.7685 11.6667 10.9861 11.8843 10.9861 12.1528Z"
          fill="#E65050"
        />
      </g>
      <defs>
        <clipPath id="clip0_13423_1842">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
