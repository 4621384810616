import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { Button, Collapse, Drawer } from '../../../components';
import {
  MerchantOperatorCompanyDescriptionType,
  OperationProductCeMercantDescriptionType,
} from '../../../types';
import { ConsigneeListItem } from './ConsigneeListItem';
import { OperationProductConsigneeForm } from './operationProductConsigneeForm';

const { Panel } = Collapse;

type OperationProductConsigneeDataProps = {
  consigneeCompanies: OperationProductCeMercantDescriptionType;
  selectedProduct?: any;
  setSelectedProduct?: (value: any) => void;
  isContainer?: boolean;
};

export function OperationProductConsigneeData(
  props: OperationProductConsigneeDataProps
) {
  const {
    consigneeCompanies,
    selectedProduct,
    setSelectedProduct,
    isContainer,
  } = props;
  const [isVisibleConsigneeDrawer, setIsVisibleConsigneeDrawer] =
    useState(false);
  const [selectedConsigneeCompany, setSelectedConsigneeCompany] =
    useState<MerchantOperatorCompanyDescriptionType>(
      {} as MerchantOperatorCompanyDescriptionType
    );
  const [selectedConsigneeCompanyId, setSelectedConsigneeCompanyId] =
    useState(0);

  function toggleDrawer() {
    setIsVisibleConsigneeDrawer(!isVisibleConsigneeDrawer);
  }
  function onEdit(companyId: number) {
    const company = consigneeCompanies[companyId];
    if (company) {
      setSelectedConsigneeCompanyId(companyId);
      setSelectedConsigneeCompany(company);
    }
    toggleDrawer();
  }
  return (
    <>
      <Drawer visible={isVisibleConsigneeDrawer} closable={false} width={700}>
        <OperationProductConsigneeForm
          selectedConsigneeCompanyId={selectedConsigneeCompanyId}
          selectedConsigneeCompany={selectedConsigneeCompany}
          setSelectedConsigneeCompany={setSelectedConsigneeCompany}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          onCloseDrawer={toggleDrawer}
          isContainer={isContainer}
        />
      </Drawer>
      <Collapse expandIconPosition="end">
        <Panel header="Consignatários" key="operation_products_consignees">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onEdit(0)}
          >
            Novo consignatário
          </Button>

          {consigneeCompanies &&
            Object.keys(consigneeCompanies).map((company: string, index) => {
              const companyId = parseInt(company, 10);
              return (
                <ConsigneeListItem
                  key={index}
                  companyName={consigneeCompanies[companyId].company_name}
                  companyCode={consigneeCompanies[companyId].company_code}
                  merchantCECodes={consigneeCompanies[
                    companyId
                  ].merchants_ce.map((ce) => ce.code)}
                  button={
                    <Button
                      onClick={() => onEdit(companyId)}
                      icon={<EditFilled />}
                    >
                      Editar
                    </Button>
                  }
                />
              );
            })}
        </Panel>
      </Collapse>
    </>
  );
}
