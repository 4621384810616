import { Row } from 'antd';
import styled from 'styled-components';

export const TableFormListStyle = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  & h1 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: var(--ocean_dark);
    display: inline-block;
  }
  & h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--neutral_light);
    display: inline-block;
    margin-left: 10px;
  }
  & .table {
    background: #ebf2f7;
    border-radius: 5px;
    padding: 5px;
    & .table-head {
      color: var(--ocean_dark);
      font-weight: 700;
      padding: 10px 16px 8px 16px;
      font-size: 12px;
    }
  }
  & .read-only {
    & table {
      padding: 5px;
      padding-bottom: 0px;
      padding-top: 0px;
      border-radius: 5px;
      background: #ebf2f7;
      border-spacing: 0 5px;
      & tbody,
      & thead {
        & > tr {
          padding: 0px;
          & .ant-table-cell {
            padding: 4px 16px 4px 16px;
          }
        }
      }
      & thead > tr > .ant-table-cell {
        color: var(--ocean_dark);
        font-size: 12px;
      }
    }
  }
`;
export const FormListItemRowStyle = styled(Row)`
  background: #fff;
  border-radius: 4px;
  transition: 150ms;
  margin: 8px 16px 8px 16px !important;
  padding-top: 8px;
  padding-bottom: 8px;
  &:hover {
    background: var(--neutral_lightest);
  }
`;
