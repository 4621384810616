import { GenericResponse } from '../types';
import {
  DueType,
  PortalStatusType,
  VehicleInvoice,
  DuesGroupByStopover,
  VehicleStatusType,
  VehicleType,
  AggregatedByStopoverType,
  StopoverVehiclesGroupedByPort,
  StopoverVehiclesImportType,
  RequestCargoRemovalType,
  VehiclesGroupByParkingSpaceType,
  VehiclesAggregatedByBrandType,
  VehiclesGroupByStatisticsDetailsType,
  VehiclesAggregatedByStatus,
} from '../typesWarehousing';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export type FilterInvoicesType = {
  due_is_null?: boolean;
  access_key?: string;
  order_by?: string;
  initial_date_of_read?: string | null;
  final_date_of_read?: string | null;
  page?: number;
  access_key_or_chassis?: string;
  chassis_status?: VehicleStatusType;
  portal_status?: PortalStatusType;
};

export type FilterDuesType = {
  access_key_or_chassis?: string;
  chassis_status?: VehicleStatusType | '';
  portal_status?: PortalStatusType | '';
  stopover_id?: number;
};

export type PatchInvoicesBodyType = {
  id: number;
  access_key: string;
  vehicle_id: number;
};

export type InvoiceToReceiptType = {
  id: number;
  access_key?: string;
  vehicle_id?: number;
};

export type requestCargoRemovalType = {
  bl_number: number;
};

export type GetVehiclesGroupByStatisticsParams = {
  start_date: string | null;
  end_date: string | null;
};

export type GetAntaqXmlParams = {
  initial_date_report?: string | null;
  final_date_report?: string | null;
};
export const warehousingVehiclesApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getInvoices: build.query<
      GenericResponse & { results: VehicleInvoice[] },
      void | FilterInvoicesType
    >({
      query: (queryParams) => {
        return {
          url: `/warehousing/invoices`,
          method: 'GET',
          params: queryParams || {},
        };
      },
      providesTags: () => [{ type: 'WarehousingInvoices' }],
    }),
    getInvoicesGroupedByVehicles: build.query<
      GenericResponse & { results: VehicleInvoice[] },
      void | FilterInvoicesType
    >({
      query: (queryParams) => {
        return {
          url: `/warehousing/invoices/groupby-vehicles`,
          method: 'GET',
          params: queryParams || {},
        };
      },
      providesTags: () => [{ type: 'WarehousingInvoices' }],
    }),
    createInvoice: build.mutation<VehicleInvoice, VehicleInvoice>({
      query: (invoice) => {
        return {
          url: `/warehousing/invoices`,
          method: 'POST',
          body: invoice,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'WarehousingInvoices' }]),
    }),
    patchInvoice: build.mutation<VehicleInvoice[], PatchInvoicesBodyType[]>({
      query: (invoices) => {
        return {
          url: '/warehousing/invoices',
          method: 'PATCH',
          body: invoices,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'WarehousingInvoices' }]),
    }),
    deleteInvoice: build.mutation<VehicleInvoice, number>({
      query: (invoiceId) => {
        return {
          url: `/warehousing/invoices/${invoiceId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'WarehousingInvoices' }]),
    }),
    updateVehicle: build.mutation<VehicleType, VehicleType[]>({
      query: (vehicles) => {
        return {
          url: `/warehousing/vehicles`,
          method: 'PUT',
          body: vehicles,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'WarehousingInvoices' }]),
    }),
    uploadOperatorReport: build.mutation<void, { files: File[] }>({
      query: (data) => {
        const formData = new FormData();
        formData.append('file[]', data.files[0]);
        return {
          url: `/warehousing/vehicles/upload-operator-report`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (_result, error) =>
        getInvalidatesTags(error, [{ type: 'WarehousingImporting' }]),
    }),
    uploadImportingDocumentations: build.mutation<
      void,
      { file: File; id: number; folderName: string }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('file[]', data.file);
        formData.append('local_folder_name', data.folderName);
        return {
          url: `/warehousing/importing-vehicles-group/upload-documentations/${data.id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getDuesGroupedByStopover: build.query<
      GenericResponse & { results: DuesGroupByStopover[] },
      FilterDuesType
    >({
      query: (queryParams) => {
        const { stopover_id } = queryParams;
        return {
          url: `/warehousing/invoices/groupby-stopover/${stopover_id}`,
          method: 'GET',
          params: queryParams || {},
        };
      },
    }),
    getDues: build.query<
      GenericResponse & { results: DueType[] },
      FilterDuesType | void
    >({
      query: (queryParams) => {
        return {
          url: '/warehousing/dues',
          method: 'GET',
          params: queryParams || {},
        };
      },
    }),
    getAggregatedByStopover: build.query<
      AggregatedByStopoverType,
      { stopoverId: number | undefined; direction: 'EXPORTING' | 'IMPORTING' }
    >({
      query: (filters) => {
        const params = { ...filters };
        delete params.stopoverId;
        return {
          url: `/warehousing/vehicles/aggregatedby-stopover/${filters.stopoverId}`,
          method: 'GET',
          params,
        };
      },
    }),
    receiptInvoices: build.mutation<VehicleInvoice[], InvoiceToReceiptType[]>({
      query: (invoices) => {
        return {
          url: `/warehousing/invoices/receipt-invoices`,
          method: 'PATCH',
          body: invoices,
        };
      },
    }),
    getStopoverVehiclesGroupedByPort: build.query<
      StopoverVehiclesGroupedByPort[],
      FilterDuesType
    >({
      query: (filters) => {
        return {
          url: `/warehousing/vehicles/groupby-stopover-export/${filters.stopover_id}`,
          method: 'GET',
          params: filters,
        };
      },
      providesTags: () => [{ type: 'WarehousingImporting' }],
    }),
    getStopoverVehiclesImport: build.query<
      StopoverVehiclesImportType[],
      FilterDuesType
    >({
      query: (filters) => {
        return {
          url: `/warehousing/importing-vehicles-group/`,
          method: 'GET',
          params: filters,
        };
      },
      transformResponse: (response: {
        results: Array<StopoverVehiclesImportType>;
      }) => {
        return response.results;
      },
      providesTags: () => [{ type: 'WarehousingImporting' }],
    }),
    requestCargoRemoval: build.mutation<void, RequestCargoRemovalType[]>({
      query: (bls) => {
        return {
          url: `/warehousing/importing-vehicles-group-by-bls`,
          method: 'PATCH',
          body: bls,
        };
      },
    }),
    patchImportVehiclesGroup: build.mutation<
      void,
      { id: number; status?: string; di_number?: string }
    >({
      query: (importVehiclesGroup) => {
        return {
          url: `/warehousing/importing-vehicles-group/${importVehiclesGroup.id}`,
          method: 'PATCH',
          body: importVehiclesGroup,
        };
      },
    }),
    getImportVehiclesGroupBL: build.query<
      { bl_number: string }[],
      { bl_number: string; importing_vehicles_group_status: string }
    >({
      query: (filters) => {
        return {
          url: `/warehousing/importing-vehicles-group-by-bls`,
          method: 'GET',
          params: filters,
        };
      },
      transformResponse: (response: {
        results: Array<{ bl_number: string }>;
      }) => {
        return response.results;
      },
    }),
    getStopoverVehiclesImportGroup: build.query<
      StopoverVehiclesImportType,
      { id: number }
    >({
      query: (filters) => {
        return {
          url: `/warehousing/importing-vehicles-group/${filters.id}`,
          method: 'GET',
        };
      },
    }),
    getImportingDocument: build.query<string, { path: string; name: string }>({
      query: (filters) => {
        const params = { ...filters };
        return {
          url: `/warehousing/importing-vehicles-group/upload-documentations/`,
          method: 'GET',
          params,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    deleteImportingDocument: build.mutation<
      void,
      { path: string; name: string; file_obj_id: number }
    >({
      query: (filters) => {
        return {
          url: `/warehousing/importing-vehicles-group/upload-documentations/`,
          method: 'DELETE',
          body: filters,
        };
      },
    }),

    getVehicleDetails: build.query<VehicleType, string>({
      query: (vehicleChassis) => {
        return {
          url: `/warehousing/vehicles/groupby-stopover-detailed-chassis/${vehicleChassis}`,
          method: 'GET',
        };
      },
    }),
    getVehiclesGroupByParkingSpace: build.query<
      VehiclesGroupByParkingSpaceType,
      void
    >({
      query: () => {
        return {
          url: `/warehousing/vehicles/groupby-parking-space`,
          method: 'GET',
        };
      },
    }),
    getVehiclesAggregatedByBrand: build.query<
      VehiclesAggregatedByBrandType,
      { storage_company_name: string }
    >({
      query: (params) => {
        return {
          url: '/warehousing/vehicles/aggregatedby-parking-space',
          params,
          method: 'GET',
        };
      },
    }),
    queryDUEStatus: build.query<DueType, string | null>({
      query: (dueNumber) => {
        return {
          url: `/warehousing/dues/query-due-status/${dueNumber}`,
          method: 'PATCH',
        };
      },
    }),
    getVehiclesGroupByStatisticsDeatils: build.query<
      GenericResponse & { results: VehiclesGroupByStatisticsDetailsType[] },
      GetVehiclesGroupByStatisticsParams
    >({
      query: (params) => {
        return {
          url: '/warehousing/vehicles/groupby-statistics-details',
          method: 'GET',
          params,
        };
      },
    }),
    exportAtaqXml: build.mutation<string, GetAntaqXmlParams>({
      query: (params) => {
        return {
          url: '/warehousing/vehicles/export-antaq-xml',
          method: 'PUT',
          body: params,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    requestStopoverCreation: build.mutation<
      void,
      { vessel_id: number | string; expected_arrival: string }
    >({
      query: (arg) => {
        return {
          url: '/warehousing/stopover-creation-request',
          method: 'POST',
          body: arg,
        };
      },
    }),
    segregateChassi: build.mutation<
      VehicleType,
      { chassi_id: number; segregation_reason: string }
    >({
      query: (segregation) => {
        return {
          url: `/warehousing/vehicles/segregate-chassis/${segregation.chassi_id}`,
          method: 'PATCH',
          body: {
            segregation_reason: segregation.segregation_reason,
          },
        };
      },
    }),
    getVehiclesByStatus: build.query<VehiclesAggregatedByStatus, void>({
      query: () => {
        return {
          url: '/warehousing/vehicles/aggregatedby-status',
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetInvoicesGroupedByVehiclesQuery,
  useGetDuesGroupedByStopoverQuery,
  useDeleteInvoiceMutation,
  usePatchInvoiceMutation,
  useUpdateVehicleMutation,
  useUploadOperatorReportMutation,
  useUploadImportingDocumentationsMutation,
  useDeleteImportingDocumentMutation,
  useGetDuesQuery,
  useGetAggregatedByStopoverQuery,
  useReceiptInvoicesMutation,
  useGetStopoverVehiclesGroupedByPortQuery,
  useGetStopoverVehiclesImportQuery,
  useGetStopoverVehiclesImportGroupQuery,
  useRequestCargoRemovalMutation,
  useGetImportVehiclesGroupBLQuery,
  useLazyGetImportingDocumentQuery,
  useGetVehicleDetailsQuery,
  useRequestStopoverCreationMutation,
  useGetVehiclesGroupByParkingSpaceQuery,
  useGetVehiclesAggregatedByBrandQuery,
  usePatchImportVehiclesGroupMutation,
  useQueryDUEStatusQuery,
  useGetVehiclesGroupByStatisticsDeatilsQuery,
  useExportAtaqXmlMutation,
  useSegregateChassiMutation,
  useGetVehiclesByStatusQuery,
} = warehousingVehiclesApi;
