import {
  CompaniesGroupByFinancialTableType,
  FinancialTableType,
} from '../../../types';
import { capitalizeFirstLetter } from '../../../utils/utils';

export const groupCompaniesByTableType = (input: FinancialTableType[]) => {
  const tablesList: CompaniesGroupByFinancialTableType[] = [];

  input.forEach((financialTable) => {
    const financialTableId = financialTable.financial_table_type.id;
    if (!tablesList.some((table) => table.id === financialTableId)) {
      const fullDescription =
        financialTable.financial_table_type.description.split(':');
      const formattedTitle = capitalizeFirstLetter(fullDescription[0]);
      const tableTypeDescription = fullDescription[1];
      const newTable: CompaniesGroupByFinancialTableType = {
        id: financialTable.financial_table_type.id,
        title: formattedTitle,
        description: tableTypeDescription,
        items: [],
      };
      tablesList.push(newTable);
    }
    tablesList.forEach((table) => {
      if (table.id === financialTable.financial_table_type.id) {
        table.items.push(financialTable);
      }
    });
  });
  tablesList.sort((table1, table2) => table1.id - table2.id);
  return tablesList;
};
