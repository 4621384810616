import { isObject } from 'lodash';
import { CargoType, CompanyType, Line, LineToSave } from '../../../../types';

export function formatLineToSave(line: Line): LineToSave {
  return {
    id: line.id,
    name: line.name,
    berth: line.berth,
    diameter: line.diameter,
    max_flow_rate: line.max_flow_rate,
    cargo_types: line.cargo_types as string[],
    storage_company_terminal: line.storage_company_terminal as number,
  };
}

export function formatLineToForm(line: Line) {
  return {
    ...line,
    cargo_types: line.cargo_types.map((cargoType: CargoType | string) =>
      isObject(cargoType) ? cargoType.name : cargoType
    ),
    storage_company_terminal: isObject(line.storage_company_terminal)
      ? line.storage_company_terminal.id
      : line.storage_company_terminal,
  };
}

export function formatLinesToForm(lines: Line[]) {
  return lines.map((line) => formatLineToForm(line));
}
