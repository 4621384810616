import { WarningFilled } from '@ant-design/icons';

import { Button } from '../antd/Button';
import { Modal } from '../antd/Modal';

type ModalBlockNavigationProps = {
  showModal: boolean;
  setHasEdited?: (hasEdited: boolean) => void;
  setBlockNavigate?: (blockNavigate: boolean) => void;
  setShowModal?: (showModal: boolean) => void;
  afterCloseModal?: () => void;
  name: string;
};

export function ModalBlockNavigation(props: ModalBlockNavigationProps) {
  const {
    showModal,
    setHasEdited,
    setBlockNavigate,
    setShowModal,
    afterCloseModal,
    name,
  } = props;

  function closeBlockNavigationModal() {
    if (setHasEdited) setHasEdited(false);
    if (setBlockNavigate) setBlockNavigate(false);
    if (setShowModal) setShowModal(false);

    if (afterCloseModal) {
      afterCloseModal();
    }
  }
  function backToEdition() {
    if (setBlockNavigate) setBlockNavigate(false);
  }

  return (
    <Modal
      visible={showModal}
      className="TOSWarningModal"
      width={520}
      title={
        <>
          <WarningFilled /> Atenção{' '}
        </>
      }
      onCancel={() => backToEdition()}
      footer={null}
    >
      <p>Você tem edições de cadastro não salvas.</p>
      <p>O que deseja fazer antes de sair?</p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '40px',
        }}
      >
        <Button danger onClick={() => closeBlockNavigationModal()}>
          Sair sem salvar
        </Button>
        <Button onClick={() => backToEdition()}>Voltar a edição</Button>
        <Button type="primary" htmlType="submit" form={name} key={name}>
          Salvar e sair
        </Button>
      </div>
    </Modal>
  );
}
