import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, message, Space, FormInstance } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  Form,
  FormItemInput,
  FormItemInputNumber,
  FormItemSelect,
  FormItemTreeSelect,
  GenericDrawerHeader,
} from '../../../../../components';
import { Modal } from '../../../../../components/antd/Modal';
import { useGetCargoTypeQuery } from '../../../../../services/cargoApi';
import { useGetCompaniesQuery } from '../../../../../services/companyApi';
import {
  useCreateLineMutation,
  useDeleteLineMutation,
  usePatchLineMutation,
  useUpdateLineMutation,
} from '../../../../../services/linesApi';
import {
  CargoType,
  DockingPlace,
  DockingPlaceForm,
  Line,
} from '../../../../../types';
import { formatCargoTypesGroupByCargoNature } from '../../formatters';
import { formatLineToSave } from '../formatters';
import { BerthLineCancelationData } from './berthLineCancelationData';

const BerthLineFormWrapper = styled.div`
  padding: 24px 16px;
`;

type BerthLineFormProps = {
  selectedBerth: DockingPlace;
  selectedLine: any;
  onBack: () => void;
  berthLineRowIndex: number;
  berthForm: FormInstance<DockingPlaceForm>;
  isEdit: boolean;
  addBerthLineToForm: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
  removeBerthLineFromForm: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
};

export function BerthLineForm(props: BerthLineFormProps) {
  const {
    selectedBerth,
    selectedLine,
    onBack,
    berthLineRowIndex,
    berthForm,
    isEdit,
    addBerthLineToForm,
    removeBerthLineFromForm,
  } = props;

  const [form] = Form.useForm<Line>();

  const [isDeactivationModalVisible, setIsDeactivationModalVisible] =
    useState(false);
  const [isReasonEmpty, setIsReasonEmpty] = useState(true);
  const [isLineActive, setIsLineActive] = useState(selectedLine.active);
  const [searchStorageCompanyName, setSearchStorageCompanyName] = useState('');
  const [displayStorageCompanyName, setDisplayStorageCompanyName] = useState(
    selectedLine.storage_company_terminal_name || ''
  );

  const { data: storageCompanyData, isLoading: isStorageCompanydata } =
    useGetCompaniesQuery({
      page_size: 30,
      company_type: 'STORAGE',
      name_or_cnpj: searchStorageCompanyName,
    });
  const { data: cargoTypeData, isLoading: isLoadingCargoTypeData } =
    useGetCargoTypeQuery();
  const [
    createLine,
    { isLoading: isLoadingCreateLine, isSuccess: isSuccessCreateLine },
  ] = useCreateLineMutation();
  const [
    patchLine,
    { isSuccess: isSuccessPatchLine, isError: isErrorPatchLine },
  ] = usePatchLineMutation();
  const [
    updateLine,
    { isSuccess: isSuccessUpdateLine, isError: isErrorUpdateLine },
  ] = useUpdateLineMutation();
  const [
    deleteLine,
    { isSuccess: isSuccessDeleteLine, isError: isErrorDeleteLine },
  ] = useDeleteLineMutation();

  useEffect(() => {
    if (isSuccessCreateLine) {
      message.success('Linha cadastrada com sucesso!');
    }

    if (isSuccessUpdateLine) {
      message.success('Linha atualizada com sucesso!');
    }

    if (isErrorUpdateLine) {
      message.error('Ocorreu um erro ao atualizar a linha');
    }

    if (isSuccessPatchLine) {
      message.success('Status da linha atualizado com sucesso!');
      handleCloseModal();
      onBack();
    }

    if (isErrorPatchLine) {
      message.error('Ocorreu um erro ao atualizar o status da linha');
    }

    if (isSuccessDeleteLine) {
      message.success('Linha removida com sucesso!');
      onBack();
    }

    if (isErrorDeleteLine) {
      message.error('Ocorreu um erro ao remover a linha');
    }
  }, [
    isSuccessCreateLine,
    isSuccessPatchLine,
    isErrorPatchLine,
    isSuccessUpdateLine,
    isErrorUpdateLine,
    isSuccessDeleteLine,
    isErrorDeleteLine,
  ]);

  function getHeaderTitle() {
    return isEmpty(selectedLine) ? (
      <span style={{ fontWeight: '700', fontSize: '16px' }}>Nova Linha</span>
    ) : (
      <span style={{ fontWeight: '700', fontSize: '16px' }}>
        {selectedLine.name}
      </span>
    );
  }

  async function handleDeleteLine() {
    confirm({
      title: 'Deseja realmente remover essa linha?',
      icon: <ExclamationCircleOutlined />,
      content: 'A linha removida não poderá ser recuperada',
      async onOk() {
        // linha existente
        if (!isEmpty(selectedLine) && selectedLine.id) {
          deleteLine(selectedLine.id);
          // linha não existente
        } else {
          removeBerthLineFromForm(berthLineRowIndex);
          message.success('Linha removida com sucesso');
          onBack();
        }
      },
    });
  }

  function handleDeactivateLine() {
    // linha existente
    if (!isEmpty(selectedLine) && selectedLine.id) {
      patchLine({
        id: selectedLine.id,
        active: false,
        reason_for_deactivation: form.getFieldValue('reason_for_deactivation'),
      });
      // linha não existente
    } else {
      const berthLines = berthForm.getFieldValue(['berth_lines']);
      const reasonForDeactivation = form.getFieldValue([
        'reason_for_deactivation',
      ]);
      berthForm.setFieldsValue({
        berth_lines: berthLines.map((berthLine: Line, index: number) =>
          index === berthLineRowIndex
            ? {
                ...berthLine,
                active: false,
                reason_for_deactivation: reasonForDeactivation,
              }
            : berthLine
        ),
      });
    }
    onBack();
  }

  function handleActivateLine() {
    // linha existente
    if (selectedLine && selectedLine.id) {
      patchLine({
        id: selectedLine.id,
        active: true,
      });
      // linha não existente
    } else {
      const berthLines = berthForm.getFieldValue(['berth_lines']);
      berthForm.setFieldsValue({
        berth_lines: berthLines.map((berthLine: Line, index: number) =>
          index === berthLineRowIndex
            ? {
                ...berthLine,
                active: true,
              }
            : berthLine
        ),
      });
    }
    onBack();
  }

  function handleFormSubmit(values: any) {
    form.validateFields().then(async (values) => {
      // berço existente
      if (isEdit) {
        // linha existente
        if (!isEmpty(selectedLine)) {
          updateLine(
            formatLineToSave({
              ...values,
              id: selectedLine.id,
              berth: selectedLine.berth,
            })
          );
          // linha não existente
        } else {
          createLine(formatLineToSave({ ...values, berth: selectedBerth.tag }));
        }
        // berço não existente
        // linha existente
      } else if (!isEmpty(selectedLine)) {
        const berthLines = berthForm.getFieldValue(['berth_lines']);
        berthForm.setFieldsValue({
          berth_lines: berthLines.map((berthLine: Line, index: number) => {
            return index === berthLineRowIndex
              ? {
                  ...values,
                  storage_company_terminal_name: displayStorageCompanyName,
                  active: selectedLine.active,
                }
              : berthLine;
          }),
        });

        // linha não existente
      } else {
        addBerthLineToForm({
          ...values,
          active: true,
          storage_company_terminal_name: displayStorageCompanyName,
        });
      }
      onBack();
    });
  }

  function handleCloseModal() {
    setIsDeactivationModalVisible(false);
  }

  function formButtons() {
    return isLineActive ? (
      <Button
        size="large"
        danger
        onClick={() => setIsDeactivationModalVisible(true)}
      >
        Desativar
      </Button>
    ) : (
      <Button size="large" onClick={() => handleActivateLine()}>
        Reativar
      </Button>
    );
  }

  function getAllowedCargoTypes() {
    if (isEmpty(cargoTypeData?.results)) return [];

    const berthCargoTypes = berthForm.getFieldValue(['cargo_type']);
    const allowedCargoTypes = cargoTypeData?.results.filter(
      (cargoType: CargoType) => berthCargoTypes.includes(cargoType.name)
    ) as CargoType[];

    return formatCargoTypesGroupByCargoNature(allowedCargoTypes);
  }

  function getStorageCompanyName(companyId: number) {
    const company = storageCompanyData?.results.find(
      (company) => company.id === companyId
    );
    if (company) {
      setDisplayStorageCompanyName(company.name);
    }
  }

  return (
    <Form
      form={form}
      name="berth-line"
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={selectedLine}
    >
      <Modal
        visible={isDeactivationModalVisible}
        title={<>Tem certeza que deseja desativar esta linha?</>}
        className="TOSDangerModal"
        onCancel={handleCloseModal}
        width={800}
        footer={null}
      >
        <Row gutter={24} style={{ marginBottom: '16px' }}>
          <span style={{ fontSize: '14px' }}>
            Para desativar a linha {selectedLine.name}, preencha o motivo
            abaixo:
          </span>
        </Row>
        <span style={{ color: 'var(--suape-black)' }}>Motivo:</span>
        <Row>
          <FormItemInput
            name={['reason_for_deactivation']}
            onChange={(value) => setIsReasonEmpty(isEmpty(value))}
            maxLength={100}
            showCount
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
            ]}
          />
        </Row>
        <Row justify="end">
          <Space size={12}>
            <Button type="text" onClick={() => handleCloseModal()}>
              Voltar
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleDeactivateLine()}
              disabled={isReasonEmpty}
            >
              Desativar
            </Button>
          </Space>
        </Row>
      </Modal>
      <GenericDrawerHeader
        title={getHeaderTitle()}
        formName="berth-line"
        onBack={onBack}
        onDelete={handleDeleteLine}
        buttons={() => !isEmpty(selectedLine) && formButtons()}
        showBackButton
        deleteButtonType="primary"
      />
      {!isEmpty(selectedLine) && !isLineActive && (
        <BerthLineCancelationData selectedLine={selectedLine} />
      )}
      <BerthLineFormWrapper>
        <Row gutter={24}>
          <FormItemInput colSpan={12} name={['name']} label="Nome da linha" />
          <FormItemSelect
            dataList={
              storageCompanyData?.results
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name)) || []
            }
            colSpan={12}
            name={['storage_company_terminal']}
            label="Terminal"
            onSearch={(value) => setSearchStorageCompanyName(value)}
            onSelect={(value) => !isEdit && getStorageCompanyName(value)}
            onClear={() => setSearchStorageCompanyName('')}
            debounceDelay={300}
            showSearch
            allowClear
          />
        </Row>
        <Row gutter={24}>
          <FormItemInput colSpan={12} name={['diameter']} label="Diâmetro" />
          <FormItemInputNumber
            colSpan={12}
            name={['max_flow_rate']}
            label="Vazão máxima"
            addonAfter="m³/h"
          />
        </Row>
        <Row>
          <FormItemTreeSelect
            tagTheme="neutral"
            name={['cargo_types']}
            label="Produtos"
            // treeData={formatCargoTypesGroupByCargoNature(
            //   cargoTypeData?.results || []
            // )}
            treeData={getAllowedCargoTypes()}
            showCheckedStrategy="SHOW_CHILD"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          />
        </Row>
      </BerthLineFormWrapper>
    </Form>
  );
}
