import {
  OperationProductType,
  OperationalOperationProductToSave,
} from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const operationProductApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    updateOperationProduct: build.mutation<
      OperationProductType,
      OperationalOperationProductToSave
    >({
      query: (product) => {
        return {
          url: `/operational/operation-products/${product.id}`,
          method: 'PUT',
          body: product,
        };
      },
    }),
    createOperationProduct: build.mutation<
      OperationProductType,
      OperationalOperationProductToSave
    >({
      query: (product) => {
        return {
          url: `/operational/operation-products`,
          method: 'POST',
          body: product,
        };
      },
    }),
    patchOperationProduct: build.mutation<
      OperationProductType,
      Partial<OperationProductType>
    >({
      query: (product) => {
        return {
          url: `/operational/operation-products/${product.id}`,
          method: 'PATCH',
          body: product,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: result?.operation_id },
          { type: 'OperationCalculus', code: result?.operation_id },
        ]),
    }),
  }),
});

export const {
  useUpdateOperationProductMutation,
  useCreateOperationProductMutation,
  usePatchOperationProductMutation,
} = operationProductApi;
