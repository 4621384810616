import {
  CargoStorageMerchantType,
  GenericResponse,
  GroupByVehiclesExportingType,
  GroupByVehiclesImportingType,
  OperationProductType,
} from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const cargosStorageMerchantApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    patchCSM: build.mutation<OperationProductType, CargoStorageMerchantType[]>({
      query: (csms) => {
        return {
          url: `/operational/cargos-storage-merchant`,
          method: 'PUT',
          body: csms,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: '6' },
          { type: 'Stopover', code: '7' },
        ]),
    }),
    groupCSMByVehiclesImporting: build.query<
      GenericResponse & {
        results: Array<GroupByVehiclesImportingType> | [];
      },
      { operation_id: number; merchant_ce_code_or_chassis?: string }
    >({
      query: (params) => {
        return {
          url: `/operational/cargos-storage-merchant/group-by-vehicles-importing/${params.operation_id}`,
          method: 'GET',
          params: {
            merchant_ce_code_or_chassis: params.merchant_ce_code_or_chassis,
          },
        };
      },
    }),
    patchCSMByVehiclesImporting: build.mutation<
      GenericResponse & {
        results: Array<GroupByVehiclesImportingType> | [];
      },
      { id: number }[]
    >({
      query: (vehicles) => {
        return {
          url: `/operational/cargos-storage-merchant/group-by-vehicles-importing`,
          method: 'PATCH',
          body: vehicles,
        };
      },
    }),
    groupCSMByVehiclesExporting: build.query<
      GenericResponse & {
        results: Array<GroupByVehiclesExportingType> | [];
      },
      { operation_id: number; due_number_or_chassis?: string }
    >({
      query: (params) => {
        return {
          url: `/operational/cargos-storage-merchant/group-by-vehicles-exporting/${params.operation_id}`,
          method: 'GET',
          params: {
            due_number_or_chassis: params.due_number_or_chassis,
          },
        };
      },
    }),
    patchCSMByVehiclesExporting: build.mutation<
      GenericResponse & {
        results: Array<GroupByVehiclesExportingType> | [];
      },
      { warehousing__id: number }[]
    >({
      query: (vehicles) => {
        return {
          url: `/warehousing/vehicles/groupby-stopover-export`,
          method: 'PATCH',
          body: vehicles,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  usePatchCSMMutation,
  useLazyGroupCSMByVehiclesExportingQuery,
  useLazyGroupCSMByVehiclesImportingQuery,
  usePatchCSMByVehiclesImportingMutation,
  usePatchCSMByVehiclesExportingMutation,
} = cargosStorageMerchantApi;
