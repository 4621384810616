import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Row, message } from 'antd';
import FormList from 'antd/lib/form/FormList';
import confirm from 'antd/lib/modal/confirm';
import { debounce, isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Collapse,
  Descriptions,
  Drawer,
  Form,
  FormItemSelect,
  FormatItem,
  GenericDrawerHeader,
  InnerCollapse,
  ModalBlockNavigation,
  Select,
  TableFormList,
} from '../../../../components';
import { useGetPriceTablesQuery } from '../../../../services/chargeApi';
import { useGetCompaniesQuery } from '../../../../services/companyApi';
import {
  useCreateFareMutation,
  useDeleteFareMutation,
  useUpdateFareMutation,
  useApportionMutation,
} from '../../../../services/fareApi';
import {
  // Creates
  useCreateTableOneMutation,
  useCreateTableTwoMutation,
  useCreateTableThreeMutation,
  useCreateTableFiveMutation,
  useCreateTableSixMutation,
  useCreateTableSevenMutation,
  useCreateTableNineMutation,
  // Updates
  useUpdateTableOneMutation,
  useUpdateTableTwoMutation,
  useUpdateTableThreeMutation,
  useUpdateTableFiveMutation,
  useUpdateTableSixMutation,
  useUpdateTableSevenMutation,
  useUpdateTableNineMutation,
  // Deletes
  useDeleteTableOneMutation,
  useDeleteTableTwoMutation,
  useDeleteTableThreeMutation,
  useDeleteTableFiveMutation,
  useDeleteTableSixMutation,
  useDeleteTableSevenMutation,
  useDeleteTableNineMutation,
} from '../../../../services/tablesFareApi';
import {
  AllTableItemsType,
  AllTableType,
  ChargeType,
  FareFormType,
  FareToSaveType,
  FareType,
  IndexTablesType,
  TableType,
} from '../../../../typesFinancial';
import { chargeTypeList, tableTypeList } from '../../../../utils/lists';
import { createDate, returnDiffDaysInHours } from '../../../../utils/utils';
import { formatTableItemsToSave } from '../../chargeDrawer/formatters';
import { TableCollapse } from '../../chargeDrawer/tableCollapse';
import { TableItemCollapse } from '../../chargeDrawer/tableItemCollapse';
import { FareItemsTable } from '../fareItemsTable/FareItemsTable';
import {
  formatApportmentTableOneToSave,
  formatApportmentTableThreeToSave,
} from './formatters';
import { ItemDescountRow, itemDescountRowColumns } from './ItemDescountRow';

type FareDrawerProps = {
  selectedCharge: ChargeType;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  hasEdited: boolean;
  setHasEdited: (value: boolean) => void;
  blockNavigate: boolean;
  setBlockNavigate: (value: boolean) => void;
  selectedFare: FareType;
  isAddNewTable: boolean;
  isEditTable: 0 | IndexTablesType;
  editTable: AllTableType;
  onCloseDrawer: () => void;
  isComplementary?: boolean;
  setSelectedFare: Dispatch<SetStateAction<FareType>>;
};

const { Item } = Descriptions;

const { Panel } = Collapse;
const { Panel: InnerPanel } = InnerCollapse;

export function FareDrawer(props: FareDrawerProps) {
  const {
    selectedCharge,
    isVisible,
    setIsVisible,
    hasEdited,
    setHasEdited,
    blockNavigate,
    setBlockNavigate,
    selectedFare,
    isAddNewTable,
    isEditTable,
    editTable,
    onCloseDrawer,
    isComplementary,
    setSelectedFare,
  } = props;

  const [form] = Form.useForm();

  const dockingDuration = useMemo(() => {
    if (
      !selectedCharge.docking?.first_cable_tied_in ||
      !selectedCharge.docking?.last_cable_untied_in
    ) {
      return 'Atracação não finalizada';
    }
    return returnDiffDaysInHours(
      selectedCharge.docking?.first_cable_tied_in || '',
      selectedCharge.docking?.last_cable_untied_in || ''
    );
  }, [selectedCharge]);

  const [searchCompanyName, setSearchCompanyName] = useState('');
  const { data: filteredCompanies, isFetching: isLoadingCompanies } =
    useGetCompaniesQuery({
      page_size: 100,
      name_or_cnpj: searchCompanyName,
    });

  const [
    createFare,
    { isLoading: isLoadingCreateFare, isSuccess: isSuccessCreateFare },
  ] = useCreateFareMutation();

  const [
    updateFare,
    { isLoading: isLoadingUpdateFare, isSuccess: isSuccessUpdateFare },
  ] = useUpdateFareMutation();

  const [
    deleteFare,
    { isLoading: isLoadingDeleteFare, isSuccess: isSuccessDeleteFare },
  ] = useDeleteFareMutation();

  const [
    createTableOne,
    { isLoading: isLoadingCreateTableOne, isSuccess: isSuccessCreateTableOne },
  ] = useCreateTableOneMutation();

  const [
    createTableTwo,
    { isLoading: isLoadingCreateTableTwo, isSuccess: isSuccessCreateTableTwo },
  ] = useCreateTableTwoMutation();

  const [
    createTableThree,
    {
      isLoading: isLoadingCreateTableThree,
      isSuccess: isSuccessCreateTableThree,
    },
  ] = useCreateTableThreeMutation();

  const [
    createTableFive,
    {
      isLoading: isLoadingCreateTableFive,
      isSuccess: isSuccessCreateTableFive,
    },
  ] = useCreateTableFiveMutation();

  const [
    createTableSix,
    { isLoading: isLoadingCreateTableSix, isSuccess: isSuccessCreateTableSix },
  ] = useCreateTableSixMutation();

  const [
    createTableSeven,
    {
      isLoading: isLoadingCreateTableSeven,
      isSuccess: isSuccessCreateTableSeven,
    },
  ] = useCreateTableSevenMutation();

  const [
    createTableNine,
    {
      isLoading: isLoadingCreateTableNine,
      isSuccess: isSuccessCreateTableNine,
    },
  ] = useCreateTableNineMutation();

  const [
    updateTableOne,
    { isLoading: isLoadingUpdateTableOne, isSuccess: isSuccessUpdateTableOne },
  ] = useUpdateTableOneMutation();

  const [
    updateTableTwo,
    { isLoading: isLoadingUpdateTableTwo, isSuccess: isSuccessUpdateTableTwo },
  ] = useUpdateTableTwoMutation();

  const [
    updateTableThree,
    {
      isLoading: isLoadingUpdateTableThree,
      isSuccess: isSuccessUpdateTableThree,
    },
  ] = useUpdateTableThreeMutation();

  const [
    updateTableFive,
    {
      isLoading: isLoadingUpdateTableFive,
      isSuccess: isSuccessUpdateTableFive,
    },
  ] = useUpdateTableFiveMutation();

  const [
    updateTableSix,
    { isLoading: isLoadingUpdateTableSix, isSuccess: isSuccessUpdateTableSix },
  ] = useUpdateTableSixMutation();

  const [
    updateTableSeven,
    {
      isLoading: isLoadingUpdateTableSeven,
      isSuccess: isSuccessUpdateTableSeven,
    },
  ] = useUpdateTableSevenMutation();

  const [
    updateTableNine,
    {
      isLoading: isLoadingUpdateTableNine,
      isSuccess: isSuccessUpdateTableNine,
    },
  ] = useUpdateTableNineMutation();

  const [
    deleteTableOne,
    { isLoading: isLoadingDeleteTableOne, isSuccess: isSuccessDeleteTableOne },
  ] = useDeleteTableOneMutation();

  const [
    deleteTableTwo,
    { isLoading: isLoadingDeleteTableTwo, isSuccess: isSuccessDeleteTableTwo },
  ] = useDeleteTableTwoMutation();

  const [
    deleteTableThree,
    {
      isLoading: isLoadingDeleteTableThree,
      isSuccess: isSuccessDeleteTableThree,
    },
  ] = useDeleteTableThreeMutation();

  const [
    deleteTableFive,
    {
      isLoading: isLoadingDeleteTableFive,
      isSuccess: isSuccessDeleteTableFive,
    },
  ] = useDeleteTableFiveMutation();

  const [
    deleteTableSix,
    { isLoading: isLoadingDeleteTableSix, isSuccess: isSuccessDeleteTableSix },
  ] = useDeleteTableSixMutation();

  const [
    deleteTableSeven,
    {
      isLoading: isLoadingDeleteTableSeven,
      isSuccess: isSuccessDeleteTableSeven,
    },
  ] = useDeleteTableSevenMutation();

  const [
    deleteTableNine,
    {
      isLoading: isLoadingDeleteTableNine,
      isSuccess: isSuccessDeleteTableNine,
    },
  ] = useDeleteTableNineMutation();

  const [
    apportionFare,
    { isLoading: isLoadingApportionFare, isSuccess: isSuccessApportionFare },
  ] = useApportionMutation();

  useEffect(() => {
    if (
      isSuccessCreateFare ||
      isSuccessUpdateFare ||
      isSuccessDeleteFare ||
      isSuccessApportionFare ||
      // Creates
      isSuccessCreateTableOne ||
      isSuccessCreateTableTwo ||
      isSuccessCreateTableThree ||
      isSuccessCreateTableFive ||
      isSuccessCreateTableSix ||
      isSuccessCreateTableSeven ||
      isSuccessCreateTableNine ||
      // Updates
      isSuccessUpdateTableOne ||
      isSuccessUpdateTableTwo ||
      isSuccessUpdateTableThree ||
      isSuccessUpdateTableFive ||
      isSuccessUpdateTableSix ||
      isSuccessUpdateTableSeven ||
      isSuccessUpdateTableNine ||
      // Deletes
      isSuccessDeleteTableOne ||
      isSuccessDeleteTableTwo ||
      isSuccessDeleteTableThree ||
      isSuccessDeleteTableFive ||
      isSuccessDeleteTableSix ||
      isSuccessDeleteTableSeven ||
      isSuccessDeleteTableNine
    ) {
      form.resetFields();
      onCloseDrawer();
      setIsVisible(false);
    }
  }, [
    isSuccessCreateFare,
    isSuccessUpdateFare,
    isSuccessDeleteFare,
    isSuccessCreateTableOne,
    isSuccessCreateTableTwo,
    isSuccessCreateTableThree,
    isSuccessCreateTableFive,
    isSuccessCreateTableNine,
    isSuccessUpdateTableOne,
    isSuccessUpdateTableTwo,
    isSuccessUpdateTableThree,
    isSuccessUpdateTableFive,
    isSuccessUpdateTableNine,
    isSuccessApportionFare,
    isSuccessDeleteTableOne,
    isSuccessDeleteTableTwo,
    isSuccessDeleteTableThree,
    isSuccessDeleteTableFive,
    isSuccessDeleteTableNine,
  ]);

  useEffect(() => {
    if (!isEmpty(selectedFare)) {
      setSearchCompanyName(selectedFare.company.name);
      form.setFieldsValue({ company: selectedFare.company });
      if (isAddNewTable) {
        form.setFieldsValue({ tables: [{ id: 4 }] });
      }
    }
    if (!isEmpty(editTable)) {
      const items = editTable?.items?.map((item) => {
        return {
          ...item,
          product_group:
            item.product_group?.map((productGroup) =>
              typeof productGroup === 'number' ? productGroup : productGroup.id
            ) || [],
        };
      });
      form.setFieldsValue({ tables: [{ ...editTable, items }] });
      form.setFieldsValue({
        discount: editTable?.discount,
      });
    }
  }, [selectedFare, editTable]);

  const avalilableTableTypeList = useMemo(() => {
    const list: number[] = [];
    if (!isEmpty(selectedFare.table_one)) {
      list.push(1);
    }
    if (!isEmpty(selectedFare.table_two)) {
      list.push(2);
    }
    if (!isEmpty(selectedFare.table_three)) {
      list.push(3);
    }
    if (!isEmpty(selectedFare.table_five) && selectedFare.table_five?.id) {
      list.push(5);
    }
    if (!isEmpty(selectedFare.table_six)) {
      list.push(6);
    }
    if (!isEmpty(selectedFare.table_seven)) {
      list.push(7);
    }
    if (!isEmpty(selectedFare.table_nine)) {
      list.push(9);
    }
    return tableTypeList.filter((tableType) => !list.includes(tableType.index));
  }, [selectedFare]);

  const [selectedTableType, setSelectedTableType] =
    useState<TableType>('table_one');

  useEffect(() => {
    if (!isEmpty(editTable)) {
      const table = tableTypeList.find(
        (tableType) => tableType.index === isEditTable
      )?.id;
      if (table) {
        setSelectedTableType(table);
      }
    }
  }, [editTable]);

  const allItemsTableDescription = useMemo(() => {
    if (editTable && !isEmpty(editTable?.discount)) {
      const discount: AllTableItemsType = {
        id: 0,
        name: 'Descontos',
        value: editTable?.discount?.total_value || 0,
      };
      return [...(editTable?.items || []), discount];
    }
    return editTable?.items || [];
  }, [editTable]);

  function getTitle() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ color: 'var(--neutral_light)' }}>
          {selectedCharge.stopover?.code}
        </span>
        <span style={{ fontWeight: 700, fontSize: '16px' }}>
          {isComplementary
            ? 'Complementar fatura'
            : !isEmpty(editTable)
            ? 'Editar tabela'
            : isAddNewTable
            ? 'Adicionar tabela'
            : isEmpty(selectedFare)
            ? 'Adicionar responsável'
            : 'Editar responsável'}
        </span>
      </div>
    );
  }

  function onClose() {
    if (hasEdited) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      setIsVisible(false);
      form.resetFields();
      onCloseDrawer();
    }
  }

  function handleFormSubmit() {
    form.validateFields().then((values: FareFormType) => {
      const existNewApportment =
        (values.tables &&
          values.tables.length > 0 &&
          values.tables[0].items.some(
            (item) =>
              item.childs &&
              item.childs.length > 0 &&
              item.childs.some((child) => child.id === undefined)
          )) ||
        false;
      if (!isEmpty(editTable)) {
        if (values.tables) {
          const updatedTable = {
            id: editTable?.id || 0,
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0].items || []),
            discount: {
              id: editTable?.discount?.id,
              items: values.discount.items,
            },
            notInvalidateTags: existNewApportment,
          };

          switch (selectedTableType) {
            case 'table_one': {
              updateTableOne(updatedTable)
                .then((response) => {
                  if ('data' in response) {
                    response.data.items?.forEach((item, index) => {
                      if (values.tables && existNewApportment) {
                        const apportionmentList =
                          formatApportmentTableOneToSave(
                            values,
                            index,
                            selectedFare,
                            item
                          );
                        apportionFare({
                          chargeId: selectedCharge.id || 0,
                          items: apportionmentList,
                        });
                      }
                    });
                  }
                })
                .then(() => {
                  message.success('Tabela atualizada com sucesso');
                });
              break;
            }
            case 'table_two': {
              updateTableTwo(updatedTable).then(() => {
                message.success('Tabela atualizada com sucesso');
              });
              break;
            }
            case 'table_three': {
              updateTableThree(updatedTable)
                .then((response) => {
                  if ('data' in response) {
                    response.data.items?.forEach((item, index) => {
                      if (values.tables && existNewApportment) {
                        const apportionmentList =
                          formatApportmentTableThreeToSave(
                            values,
                            index,
                            selectedFare,
                            item
                          );
                        apportionFare({
                          chargeId: selectedCharge.id || 0,
                          items: apportionmentList,
                        });
                      }
                    });
                  }
                })
                .then(() => {
                  message.success('Tabela atualizada com sucesso');
                });
              break;
            }
            case 'table_five': {
              let hasError = updatedTable.items.some(
                (item) =>
                  item.cargo_arrival_date &&
                  item.cargo_arrival_date !== null &&
                  item.cargo_departure_date &&
                  item.cargo_departure_date !== null &&
                  (createDate(item.cargo_arrival_date) || 0) >
                    (createDate(item.cargo_departure_date) || 1)
              );
              if (hasError) {
                message.error(
                  'Início de armazenamento não pode ser maior que o fim do armazenamento de um item'
                );
                break;
              }
              hasError =
                updatedTable.items?.some(
                  (item) =>
                    !item.cargo_arrival_date && item.cargo_departure_date
                ) || false;
              if (hasError) {
                message.error(
                  'Não é possível informar o fim do armazenamento sem informar o início'
                );
                break;
              }
              updateTableFive(updatedTable).then(() => {
                message.success('Tabela atualizada com sucesso');
              });
              break;
            }
            case 'table_six': {
              updateTableSix(updatedTable).then(() => {
                message.success('Tabela atualizada com sucesso');
              });
              break;
            }
            case 'table_seven': {
              updateTableSeven(updatedTable).then(() => {
                message.success('Tabela atualizada com sucesso');
              });
              break;
            }
            case 'table_nine': {
              updateTableNine(updatedTable).then(() => {
                message.success('Tabela atualizada com sucesso');
              });
              break;
            }
            default:
              break;
          }
        }
        return;
      }

      if (isAddNewTable && !isEmpty(selectedFare)) {
        if (values.tables && selectedTableType === 'table_one') {
          const tableOne = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
            notInvalidateTags: existNewApportment,
          };
          createTableOne(tableOne)
            .then((response) => {
              if ('data' in response) {
                response.data.items?.forEach((item, index) => {
                  if (values.tables && existNewApportment) {
                    const apportionmentList = formatApportmentTableOneToSave(
                      values,
                      index,
                      selectedFare,
                      item
                    );
                    apportionFare({
                      chargeId: selectedCharge.id || 0,
                      items: apportionmentList,
                    });
                  }
                });
              }
            })
            .then(() => {
              message.success('Tabela criada com sucesso');
            });
          return;
        }
        if (values.tables && selectedTableType === 'table_two') {
          const tableTwo = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
          };
          createTableTwo(tableTwo).then(() => {
            message.success('Tabela criada com sucesso');
          });
          return;
        }
        if (values.tables && selectedTableType === 'table_three') {
          const tableThree = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
            notInvalidateTags: existNewApportment,
          };
          createTableThree(tableThree)
            .then((response) => {
              if ('data' in response) {
                response.data.items?.forEach((item, index) => {
                  if (values.tables && existNewApportment) {
                    const apportionmentList = formatApportmentTableThreeToSave(
                      values,
                      index,
                      selectedFare,
                      item
                    );
                    apportionFare({
                      chargeId: selectedCharge.id || 0,
                      items: apportionmentList,
                    });
                  }
                });
              }
            })
            .then(() => {
              message.success('Tabela criada com sucesso');
            });

          return;
        }
        if (values.tables && selectedTableType === 'table_five') {
          const tableFive = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
          };
          const hasError = tableFive.items.some(
            (item) =>
              item.cargo_arrival_date &&
              item.cargo_arrival_date !== null &&
              item.cargo_departure_date &&
              item.cargo_departure_date !== null &&
              (createDate(item.cargo_arrival_date) || 0) >
                (createDate(item.cargo_departure_date) || 1)
          );
          if (hasError) {
            message.error(
              'O início de armazenamento não pode ser maior que o fim do armazenamento de um item'
            );
            return;
          }
          createTableFive(tableFive).then(() => {
            message.success('Tabela criada com sucesso');
          });
          return;
        }
        if (values.tables && selectedTableType === 'table_six') {
          const tableSix = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
          };
          createTableSix(tableSix).then(() => {
            message.success('Tabela criada com sucesso');
          });
          return;
        }
        if (values.tables && selectedTableType === 'table_seven') {
          const tableSeven = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
          };
          createTableSeven(tableSeven).then(() => {
            message.success('Tabela criada com sucesso');
          });
          return;
        }
        if (values.tables && selectedTableType === 'table_nine') {
          const tableNine = {
            fare_id: selectedFare.id,
            items: formatTableItemsToSave(values.tables[0]?.items || []),
            discount: values.discount,
          };
          createTableNine(tableNine).then(() => {
            message.success('Tabela criada com sucesso');
          });
          return;
        }
      }

      const fareObj: FareToSaveType = {
        company: values.company?.id,
        charge_id: selectedCharge.id,
      };
      if (isEmpty(selectedFare) || isAddNewTable) {
        fareObj[selectedTableType] = {
          name: values.table,
          discount: values.discount,
        };
      }
      if (isAddNewTable) {
        fareObj.company = selectedFare.company.id;
      }
      if (isComplementary) {
        fareObj.is_complementary = true;
        fareObj.parent_id = selectedFare.id;
        fareObj[selectedTableType] = {
          name: values.table,
          discount: values.discount,
          items:
            values.tables && values.tables.length > 0
              ? formatTableItemsToSave(values.tables[0]?.items || [])
              : [],
        };
      }
      if (isEmpty(selectedFare) || isComplementary) {
        createFare(fareObj);
      } else {
        fareObj.id = selectedFare.id;
        updateFare(fareObj);
      }
    });
  }

  function onSearchCompany(evt: string) {
    setSearchCompanyName(evt);
  }

  function companyRenderer(option: Record<string, any>) {
    return (
      <Select.Option key={option.id} value={option.id}>
        {`${option.identifier && `${option.identifier} - `} ${option.name}`}
      </Select.Option>
    );
  }

  function onSelectTableType(tableType: TableType) {
    setSelectedTableType(tableType);
  }

  function checkTableAndDelete() {
    confirm({
      title: 'Deseja realmente remover esta tabela?',
      icon: <ExclamationCircleOutlined />,
      content: 'A tabela removida não poderá ser recuperada',
      cancelText: 'Cancelar',
      onOk() {
        if (isEditTable === 1)
          deleteTableOne({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
        if (isEditTable === 2)
          deleteTableTwo({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
        if (isEditTable === 3)
          deleteTableThree({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
        if (isEditTable === 5)
          deleteTableFive({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
        if (isEditTable === 6)
          deleteTableSix({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
        if (isEditTable === 7)
          deleteTableSeven({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
        if (isEditTable === 9)
          deleteTableNine({
            tableId: editTable?.id || 0,
            chargeId: selectedCharge.id || 0,
          });
      },
      onCancel() {},
    });
  }

  function checkFareAndDelete(fareId: number) {
    confirm({
      title: 'Deseja realmente remover este responsável financeiro?',
      icon: <ExclamationCircleOutlined />,
      content: 'O responsável removido não poderá ser recuperado',
      cancelText: 'Cancelar',
      onOk() {
        deleteFare(fareId);
      },
      onCancel() {},
    });
  }

  function handleSelectCompany(company: number) {
    if (isComplementary) {
      const fareToComplement =
        selectedCharge.fare?.find((item) => item.company?.id === company) ||
        ({} as FareType);
      setSelectedFare(fareToComplement);
    }
  }

  const isLoading =
    isLoadingCreateFare ||
    isLoadingUpdateFare ||
    isLoadingDeleteFare ||
    isLoadingCreateTableOne ||
    isLoadingCreateTableTwo ||
    isLoadingCreateTableThree ||
    isLoadingCreateTableFive ||
    isLoadingCreateTableSix ||
    isLoadingCreateTableSeven ||
    isLoadingCreateTableNine ||
    isLoadingUpdateTableOne ||
    isLoadingUpdateTableTwo ||
    isLoadingUpdateTableThree ||
    isLoadingUpdateTableFive ||
    isLoadingUpdateTableSix ||
    isLoadingUpdateTableSeven ||
    isLoadingUpdateTableNine ||
    isLoadingApportionFare ||
    isLoadingDeleteTableOne ||
    isLoadingDeleteTableTwo ||
    isLoadingDeleteTableThree ||
    isLoadingDeleteTableFive ||
    isLoadingDeleteTableSix ||
    isLoadingDeleteTableSeven ||
    isLoadingDeleteTableNine;

  const { data: priceTables, isLoading: isLoadingPriceTables } =
    useGetPriceTablesQuery();
  return (
    <Drawer
      visible={isVisible}
      placement="right"
      width={690}
      closable={false}
      destroyOnClose
      onClose={onClose}
    >
      <ModalBlockNavigation
        name="fare_form"
        key="fare_form"
        setHasEdited={setHasEdited}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        setShowModal={setIsVisible}
      />
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        name="fare_form"
        id="fare_form"
        onFinish={handleFormSubmit}
        onValuesChange={() => setHasEdited(true)}
      >
        <GenericDrawerHeader
          title={getTitle()}
          showBackButton
          onBack={onClose}
          formName="fare_form"
          disabledSaveButton={!hasEdited || isLoading}
          isLoadingSave={isLoading}
          onDelete={
            isComplementary
              ? undefined
              : !isEmpty(editTable) && isEditTable > 0
              ? () => checkTableAndDelete()
              : isEmpty(selectedFare) || isAddNewTable || !isEmpty(editTable)
              ? undefined
              : () => checkFareAndDelete(selectedFare.id || 0)
          }
          isLoadingDelete={isLoadingDeleteFare}
        />
        <div
          style={{
            border: '16px solid var(--neutral_lightest)',
            borderRadius: '4px',
            padding: '16px',
          }}
        >
          {!isEmpty(selectedFare) && (
            <div style={{ paddingBottom: '12px' }}>
              <span
                style={{
                  fontWeight: '700',
                  fontSize: '16px',
                  color: 'var(--ocean_dark)',
                  marginRight: '10px',
                }}
              >
                {selectedFare.company?.name}
              </span>
              <span style={{ fontWeight: '400', fontFamily: 'Consolas' }}>
                {selectedFare.company?.identifier}
              </span>
            </div>
          )}
          <Descriptions column={2}>
            <Item label="Tipo de faturamento">
              <FormatItem>
                {
                  chargeTypeList.find(
                    (chargeType) => chargeType.id === selectedCharge.charge_type
                  )?.name
                }
              </FormatItem>
            </Item>
            <Item label="Atracação">
              <FormatItem>{selectedCharge.docking?.id}</FormatItem>
            </Item>
            <Item label="Finalidade da atracação">
              <FormatItem>{selectedCharge.docking?.docking_goal}</FormatItem>
            </Item>
            <Item label="Duração da atracação">
              <FormatItem>{dockingDuration}</FormatItem>
            </Item>
            <Item label="Berço">
              <FormatItem>
                {selectedCharge.docking?.docking_place_name}
              </FormatItem>
            </Item>
            <Item label="LOA">
              <FormatItem>{null}</FormatItem>
            </Item>
          </Descriptions>
          {!isEmpty(editTable) && (
            <>
              <span
                style={{
                  fontWeight: 700,
                  color: 'var(--ocean_dark)',
                  textTransform: 'uppercase',
                }}
              >
                {tableTypeList.find((item) => item.id === selectedTableType)
                  ?.name || ''}
              </span>
              <FareItemsTable fareItems={allItemsTableDescription || []} />
            </>
          )}
        </div>
        {isEmpty(editTable) && (
          <div style={{ padding: '16px' }}>
            <Row gutter={24}>
              {isAddNewTable || !isEmpty(editTable) ? (
                <FormList name="tables">
                  {() => (
                    <div style={{ width: '100%' }}>
                      <TableCollapse
                        key={0}
                        tableIndex={0}
                        form={form}
                        onSelectTableType={onSelectTableType}
                        availableTableTypes={avalilableTableTypeList}
                        selectedFare={selectedFare}
                      />
                    </div>
                  )}
                </FormList>
              ) : (
                <>
                  <FormItemSelect
                    colSpan={isComplementary || isEmpty(selectedFare) ? 12 : 24}
                    name={['company', 'id']}
                    label="Responsável Financeiro"
                    allowClear
                    onSearch={debounce(onSearchCompany, 300)}
                    dataList={
                      isComplementary
                        ? selectedCharge.fare
                            ?.filter((fare) =>
                              fare.status
                                ? ['REBILLED', 'BILLED'].includes(fare.status)
                                : fare
                            )
                            .map((fare) => fare.company)
                        : filteredCompanies?.results
                    }
                    optionRenderer={companyRenderer}
                    showSearch
                    isLoading={isLoadingCompanies}
                    required
                    placeholder="Selecione a empresa"
                    help={
                      isComplementary
                        ? 'Apenas empresas cadastradas na cobrança são apresentadas'
                        : 'Apenas empresas cadastradas no PMIS são apresentadas'
                    }
                    onSelect={handleSelectCompany}
                  />
                  {(isComplementary || isEmpty(selectedFare)) && (
                    <FormItemSelect
                      colSpan={12}
                      name="table"
                      label="Tabela"
                      dataList={tableTypeList}
                      required
                      placeholder="Selecione a tabela"
                      onSelect={onSelectTableType}
                    />
                  )}
                </>
              )}
            </Row>
          </div>
        )}
        {(isComplementary || !isEmpty(editTable)) && (
          <Collapse expandIconPosition="end" activeKey="table_items">
            <Panel key="table_items" header="Itens" forceRender>
              <FormList name={['tables', 0, 'items']}>
                {(tableItems, { add: addItem, remove: removeItem }) => (
                  <div>
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => addItem({})}
                      disabled={selectedTableType === undefined}
                    >
                      Adicionar item
                    </Button>
                    {tableItems.length > 0 && (
                      <InnerCollapse
                        expandIconPosition="end"
                        style={{ marginTop: '16px' }}
                        defaultActiveKey={tableItems.map(
                          (_item, index) => `table_item_${index}`
                        )}
                      >
                        {tableItems.map((tableItem, index) => (
                          <InnerPanel
                            key={`table_item_${index}`}
                            header=""
                            extra={
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                style={{ marginRight: '10px' }}
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  removeItem(index);
                                }}
                              >
                                Excluir
                              </Button>
                            }
                          >
                            <TableItemCollapse
                              form={form}
                              itemIndex={index}
                              tableIndex={0}
                              selectedTableType={selectedTableType}
                              selectedFare={selectedFare}
                              isComplementary={isComplementary}
                              selectedTableItems={
                                priceTables?.find(
                                  (priceTable) =>
                                    priceTable.number ===
                                    tableTypeList.find(
                                      (tableType) =>
                                        tableType.value === selectedTableType
                                    )?.index
                                )?.items
                              }
                            />
                          </InnerPanel>
                        ))}
                      </InnerCollapse>
                    )}
                  </div>
                )}
              </FormList>
            </Panel>
          </Collapse>
        )}

        <Collapse expandIconPosition="end" activeKey="discounts">
          <Panel key="discounts" header="Descontos" forceRender>
            <TableFormList
              formListName={['discount', 'items']}
              FormListItemRow={ItemDescountRow}
              columns={itemDescountRowColumns}
              style={{ marginTop: '-15px', marginBottom: '20px' }}
              itemToAdd={{
                name: '',
                percentual: null,
                value: null,
              }}
              addButtonText="Adicionar desconto"
            />
          </Panel>
        </Collapse>
      </Form>
    </Drawer>
  );
}
