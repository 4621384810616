import { Row } from 'antd';
import { debounce } from 'lodash';
import { useState } from 'react';

import {
  FormItemDatePicker,
  FormItemSelect,
  FormItemTextArea,
} from '../../../components';
import { useGetSystemParamByNameQuery } from '../../../services/systemParamsApi';
import { useGetUsersQuery } from '../../../services/usersApi';
import { Docking } from '../../../types';
import { onDutyList } from '../../../utils/lists';
import { dockingOptionRenderer } from './common';

type BreakdownFormItemsProps = {
  dockings: Docking[] | null | undefined;
  hideDockings?: boolean;
};

export function OccurrenceFormItems(props: BreakdownFormItemsProps) {
  const { dockings, hideDockings } = props;
  const [userOnDutyName, setUserOnDutyName] = useState('');

  const { data: usersOnDutyData, isLoading: isLoadingUsersOnDutyData } =
    useGetUsersQuery({
      role: 'ON_DUTY',
      first_name_or_last_name: userOnDutyName,
    });
  const { data: occurenceTypes } = useGetSystemParamByNameQuery({
    name: 'OCCURRENCE_TYPE',
  });
  return (
    <>
      <Row gutter={40}>
        <FormItemDatePicker
          colSpan={12}
          label="Data/Hora da ocorrência"
          name="date"
        />
        <FormItemSelect
          colSpan={12}
          name="duty"
          label="Plantão"
          dataList={onDutyList
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
        />
      </Row>
      <Row gutter={40}>
        <FormItemSelect
          colSpan={12}
          name={['on_duty', 'id']}
          label="Plantonista"
          allowClear
          notFoundContent="Não existem plantonistas cadastrados"
          dataList={usersOnDutyData?.results
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))}
          showSearch
          onSearch={debounce((e: string) => setUserOnDutyName(e), 400)}
          isLoading={isLoadingUsersOnDutyData}
        />
        <FormItemSelect
          colSpan={12}
          name={['breakdown_occurrence_type', 'id']}
          label="Tipo de ocorrência"
          dataList={occurenceTypes?.items}
        />
      </Row>
      <Row gutter={40}>
        {!hideDockings && (
          <FormItemSelect
            colSpan={12}
            name={['docking', 'id']}
            label="Atracação"
            dataList={dockings || []}
            optionRenderer={dockingOptionRenderer}
          />
        )}
      </Row>
      <Row gutter={40}>
        <FormItemTextArea
          name="description"
          label="Descrição da ocorrência"
          maxLength={500}
          rows={5}
        />
      </Row>
    </>
  );
}
