import { PlusOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Flag from 'react-flagpack';

import {
  Button,
  Collapse,
  ErrorBoundary,
  FormHeader,
  StatusTag,
  Progress,
  Title,
  FormatItem,
  Descriptions,
} from '../../components';
import { CountTag } from '../../components/common/CountTag';
import {
  FilterDuesType,
  FilterInvoicesType,
  useGetDuesGroupedByStopoverQuery,
  useGetStopoverVehiclesGroupedByPortQuery,
  useGetStopoverVehiclesImportQuery,
  useGetVehiclesAggregatedByBrandQuery,
  useGetVehiclesByStatusQuery,
  useGetVehiclesGroupByParkingSpaceQuery,
} from '../../services/warehousingVehiclesApi';
import { Stopover } from '../../types';
import {
  DuesGroupByStopover,
  PortalStatusType,
  StorageCompanyGroup,
  VehicleInvoice,
  VehicleStatusType,
} from '../../typesWarehousing';
import { chassisStatus } from '../../utils/lists';
import { ExportDetails } from './exportDetails';
import { ImportDetails } from './importDetails';
import { ModalOperatorReportUpload } from './modalOperatorReportUpload';
import { ResumeBackground, ResumeSection } from './styles';
import { OverviewDetails } from './warehousingVehiclesOverview/overviewDetails';

const { Item } = Descriptions;

type WarehousingVehiclesDetailsProps = {
  stopoverDues: Stopover;
  chassis?: VehicleInvoice[];
  totalChassis?: number;
  isLoading?: boolean;
  invoicesDueNullFilters: FilterInvoicesType;
  setInvoicesDueNullFilters: React.Dispatch<
    React.SetStateAction<FilterInvoicesType>
  >;
  onUpdateUnidentifiedDuesInvoices: () => void;
  isWarehousingOperatorArea: boolean;
  stopover?: Stopover;
  onChangePagination: (newPage?: number | undefined) => number;
  queryPage?: number;
};

export function WarehousingVehiclesDetails(
  props: WarehousingVehiclesDetailsProps
) {
  const {
    stopoverDues,
    chassis,
    totalChassis,
    invoicesDueNullFilters,
    setInvoicesDueNullFilters,
    onUpdateUnidentifiedDuesInvoices,
    isLoading,
    isWarehousingOperatorArea,
    stopover,
    onChangePagination,
    queryPage,
  } = props;

  function headerTitle() {
    return stopover?.id === -2 ? (
      <div
        style={{
          fontWeight: 700,
          lineHeight: '24px',
          fontSize: '16px',
          margin: '16px 0 16px 0',
        }}
      >
        Visão geral de ocupação
      </div>
    ) : (
      <Skeleton loading={isLoading}>
        {stopoverDues.id !== -1 ? (
          <div
            style={{ fontFamily: 'Consolas', color: 'var(--neutral_light)' }}
          >
            VIAGEM: {stopoverDues.shipowner_trip || 'PENDENTE'} &gt; IMO:{' '}
            {stopoverDues.vessel__imo} &gt; DUV: {stopoverDues.duv}
          </div>
        ) : null}
        <div
          style={{
            fontWeight: 700,
            lineHeight: '24px',
            fontSize: '16px',
            margin: '16px 0 16px 0',
          }}
        >
          {stopoverDues.id === -1 ? (
            `
            Chassis não associados
          `
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <ErrorBoundary replace="">
                <Flag code={stopoverDues.vessel__flag} hasBorder={false} />
              </ErrorBoundary>
              {stopoverDues.vessel__name}
            </div>
          )}
        </div>
        <StatusTag>
          <span className="blue">
            {stopoverDues.id === -1
              ? `Exportação: ${totalChassis} veículos`
              : `Exportação: ${
                  stopoverDues.dues?.flatMap((due) => due.vehicles).length
                } veiculos`}
          </span>
        </StatusTag>
        {stopover?.total_chassis_to_import_on_stopover && (
          <StatusTag style={{ marginLeft: '10px' }}>
            <span className="green-sea">
              {`Importação: ${stopover?.total_chassis_to_import_on_stopover} veiculos`}
            </span>
          </StatusTag>
        )}
      </Skeleton>
    );
  }
  const [showModaloperatorReportUpload, setShowModalOperatorReportUpload] =
    useState(false);

  const [DuesToRender, setDuesToRender] = useState<DuesGroupByStopover[]>(
    stopoverDues.dues || []
  );

  const [DuesFilter, setDuesFilter] = useState<FilterDuesType>({
    stopover_id: stopoverDues.id,
  });

  const [vehiclesImportFilter, setVehiclesImportFilter] =
    useState<FilterDuesType>({
      stopover_id: stopoverDues.id,
    });

  const {
    data: duesFiltered,
    refetch: refetchDues,
    isFetching: isFetchingDues,
  } = useGetDuesGroupedByStopoverQuery(DuesFilter, {
    skip:
      stopoverDues.id === -1 ||
      stopoverDues.id === -2 ||
      isEmpty(stopoverDues) ||
      stopoverDues.id === undefined,
  });

  const {
    data: vehiclesGroupedByPort,
    isFetching: isFetchingVehiclesGroupedByPort,
  } = useGetStopoverVehiclesGroupedByPortQuery(DuesFilter, {
    skip: !!(
      isEmpty(stopoverDues) ||
      stopoverDues.id === -1 ||
      stopoverDues.id === -2
    ),
  });

  const {
    data: vehiclesImport,
    refetch: refetchVehicleImport,
    isFetching: isFetchingVehicleImport,
  } = useGetStopoverVehiclesImportQuery(vehiclesImportFilter, {
    skip: !!(
      isEmpty(stopoverDues) ||
      stopoverDues.id === -1 ||
      stopoverDues.id === -2
    ),
  });

  useEffect(() => {
    setVehiclesImportFilter({ stopover_id: stopoverDues.id });
    setDuesFilter({ stopover_id: stopoverDues.id });
  }, [stopoverDues]);

  useEffect(() => {
    if (stopoverDues.dues) {
      setDuesToRender(stopoverDues.dues);
    }
    if (duesFiltered?.results) {
      setDuesToRender(duesFiltered.results);
    }
    return () => {
      setDuesToRender([]);
    };
  }, [stopoverDues, duesFiltered?.results]);

  function onSearch(value: string) {
    if (value !== '') {
      setInvoicesDueNullFilters((prev) => ({
        ...prev,
        access_key_or_chassis: value,
      }));
      setDuesFilter((prev) => ({
        ...prev,
        stopover_id: stopoverDues.id,
        access_key_or_chassis: value,
      }));
    } else {
      setInvoicesDueNullFilters((prev) => ({
        ...prev,
        access_key_or_chassis: '',
      }));
      setDuesFilter((prev) => ({
        ...prev,
        stopover_id: stopoverDues.id,
        access_key_or_chassis: '',
      }));
    }
  }

  function onSelectChassiStatus(value: any) {
    setInvoicesDueNullFilters({
      ...invoicesDueNullFilters,
      chassis_status: value,
    });
    setDuesFilter({
      ...DuesFilter,
      stopover_id: stopoverDues.id,
      chassis_status: value,
    });
  }

  function onSelectInvoiceStatus(value: any) {
    setInvoicesDueNullFilters({
      ...invoicesDueNullFilters,
      portal_status: value,
    });
    setDuesFilter({
      ...DuesFilter,
      stopover_id: stopoverDues.id,
      portal_status: value,
    });
  }

  function onClear(toClear: 'chassis_status' | 'portal_status') {
    const currentFilters = { ...invoicesDueNullFilters };
    delete currentFilters[toClear];
    setInvoicesDueNullFilters(currentFilters);
    setDuesFilter({
      ...currentFilters,
      stopover_id: stopoverDues.id,
    });
  }

  function onSearchImport(value: string) {
    setVehiclesImportFilter((prev) => ({
      ...prev,
      stopover_id: stopoverDues.id,
      access_key_or_chassis: value || '',
    }));
  }

  function onSelectChassiStatusImport(value: string) {
    setVehiclesImportFilter((prev) => ({
      ...prev,
      chassis_status: value as VehicleStatusType,
    }));
  }

  function onSelectInvoiceStatusImport(value: string) {
    setVehiclesImportFilter((prev) => ({
      ...prev,
      portal_status: value as PortalStatusType,
    }));
  }

  function onClearImport(toClear: 'chassis_status' | 'portal_status') {
    setVehiclesImportFilter((prev) => ({
      ...prev,
      portal_status:
        toClear === 'portal_status'
          ? ''
          : (prev.portal_status as PortalStatusType),
      chassis_status:
        toClear === 'chassis_status'
          ? ''
          : (prev.chassis_status as VehicleStatusType),
    }));
  }

  function onUpdate() {
    if (stopoverDues.id === -1) {
      onUpdateUnidentifiedDuesInvoices();
    } else {
      refetchDues();
    }
  }

  function collapseHeader() {
    return (
      stopoverDues.id !== -1 && (
        <div style={{ marginRight: '24px' }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowModalOperatorReportUpload(true);
            }}
            icon={<PlusOutlined />}
            type="primary"
            size="middle"
          >
            Importar relatório diário
          </Button>
        </div>
      )
    );
  }

  function overviewCollapseHeader(
    title: string,
    subtitle: string,
    totalVehicles: StorageCompanyGroup
  ) {
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div>
          {title}
          <span
            style={{
              color: 'var(--neutral_medium)',
              fontWeight: 400,
              fontStyle: 'normal',
              display: 'inline-block',
              marginLeft: '10px',
            }}
          >
            {subtitle}
          </span>
          <CountTag>{totalVehicles} veículos</CountTag>
        </div>
      </div>
    );
  }

  const { data: vehiclesGroupByParkingSpaceData } =
    useGetVehiclesGroupByParkingSpaceQuery();
  const { data: aggregatedByImport } = useGetVehiclesAggregatedByBrandQuery({
    storage_company_name: 'PPV 1',
  });
  const { data: aggregatedByExport } = useGetVehiclesAggregatedByBrandQuery({
    storage_company_name: 'PPV 2',
  });

  const { data: vehiclesAggregatedByStatus } = useGetVehiclesByStatusQuery();

  return (
    <div>
      <FormHeader
        selectedStopover={stopoverDues}
        onChangeSwitch={() => {}}
        name=""
        headerTitle={headerTitle()}
        hideEdit
        hideActionButtons
        openCollapsePanels={[]}
        hideCollapseAll
      />
      {stopover?.id === -2 ? (
        <>
          <ResumeBackground style={{ margin: '0px' }}>
            <ResumeSection
              style={{
                width: '100%',
              }}
            >
              <Title>Visão geral</Title>
              {vehiclesAggregatedByStatus &&
                vehiclesAggregatedByStatus.vehicle__status && (
                  <Descriptions column={4}>
                    {Object.keys(
                      vehiclesAggregatedByStatus.vehicle__status
                    ).map((key) => {
                      return (
                        <Item
                          label={
                            chassisStatus.find((status) => status.value === key)
                              ?.label
                          }
                        >
                          <FormatItem>
                            {vehiclesAggregatedByStatus?.vehicle__status[key]}{' '}
                            veículo(s)
                          </FormatItem>
                        </Item>
                      );
                    })}
                  </Descriptions>
                )}
            </ResumeSection>
          </ResumeBackground>
          <Collapse expandIconPosition="end" destroyInactivePanel>
            {vehiclesGroupByParkingSpaceData !== undefined
              ? Object.keys(vehiclesGroupByParkingSpaceData)
                  .filter((storageCompany) => storageCompany !== 'null')
                  .map((storageCompany, index) => {
                    return (
                      <Collapse.Panel
                        key={`overview${index}`}
                        header={overviewCollapseHeader(
                          storageCompany,
                          storageCompany === 'PPV 1'
                            ? 'Importação'
                            : storageCompany === 'PPV 2'
                            ? 'Exportação'
                            : '',
                          vehiclesGroupByParkingSpaceData[storageCompany]
                            .counter
                        )}
                        extra={
                          <div
                            style={{
                              display: 'flex',
                              width: '250px',
                            }}
                          >
                            <Progress
                              percent={30}
                              size="small"
                              format={(percent) => (
                                <div style={{ marginLeft: '15px' }}>
                                  {percent}% Ocupado
                                </div>
                              )}
                              style={{ marginBlockEnd: '0px' }}
                            />
                          </div>
                        }
                      >
                        <OverviewDetails
                          direction={
                            storageCompany === 'PPV 1' ? 'IMPORT' : 'EXPORT'
                          }
                          storageCompanyData={
                            vehiclesGroupByParkingSpaceData[storageCompany]
                          }
                          brandData={
                            storageCompany === 'PPV 1'
                              ? aggregatedByImport
                              : aggregatedByExport
                          }
                        />
                      </Collapse.Panel>
                    );
                  })
              : null}
          </Collapse>
        </>
      ) : (
        <Collapse expandIconPosition="end" destroyInactivePanel>
          <Collapse.Panel
            key="export"
            header="Exportação"
            extra={isWarehousingOperatorArea ? collapseHeader() : null}
          >
            <ExportDetails
              onSearch={onSearch}
              onSelectChassiStatus={onSelectChassiStatus}
              onSelectInvoiceStatus={onSelectInvoiceStatus}
              onClear={onClear}
              stopover={stopoverDues}
              isLoading={isLoading}
              totalChassis={totalChassis}
              chassis={chassis}
              onUpdate={onUpdate}
              DuesToRender={DuesToRender}
              isWarehousingOperatorArea={isWarehousingOperatorArea}
              vehiclesGroupedByPort={vehiclesGroupedByPort}
              isFetchingVehiclesGroupedByPort={isFetchingVehiclesGroupedByPort}
              onChangePagination={onChangePagination}
              queryPage={queryPage}
              isFetchingDues={isFetchingDues}
            />
          </Collapse.Panel>
          {stopoverDues.id !== -1 && (
            <Collapse.Panel
              key="import"
              header="Importação"
              extra={isWarehousingOperatorArea ? collapseHeader() : null}
            >
              <ImportDetails
                onSearch={onSearchImport}
                onSelectChassiStatus={onSelectChassiStatusImport}
                onSelectInvoiceStatus={onSelectInvoiceStatusImport}
                onClear={onClearImport}
                stopover={stopoverDues}
                isLoading={isLoading}
                onUpdate={onUpdate}
                refetchVehicleImport={refetchVehicleImport}
                isWarehousingOperatorArea={isWarehousingOperatorArea}
                importVehicles={vehiclesImport}
                isFetchingVehicleImport={isFetchingVehicleImport}
              />
            </Collapse.Panel>
          )}
        </Collapse>
      )}
      {isWarehousingOperatorArea && (
        <ModalOperatorReportUpload
          showModal={showModaloperatorReportUpload}
          setShowModal={setShowModalOperatorReportUpload}
        />
      )}
    </div>
  );
}
