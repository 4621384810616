import { Drawer as ANTDDrawer } from 'antd';
import styled from 'styled-components';

export const Drawer = styled(ANTDDrawer)`
  & .ant-drawer-body {
    padding-right: 0px;
    padding-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
  }
  & .ant-drawer-header {
    border-bottom: 0px;
  }
  & .ant-drawer-title {
    font-weight: 600;
    font-size: 18px;
  }
  & .ant-drawer-content-wrapper {
    max-width: 90vw;
  }
`;
