import { Alert, Row, Space, message } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

import {
  Button,
  Form,
  FormItemDatePicker,
  FormItemSelect,
} from '../../components';
import { useCheckBerthAvailableMutation } from '../../services/dockingApi';
import { useGetBerthsQuery } from '../../services/dockingPlacesApi';
import { usePatchStopoverMutation } from '../../services/stopoverApi';
import { Stopover, Vessel } from '../../types';
import { formatStopoverToSave } from '../../utils/formatters';
import {
  createDateString,
  createDateStringPtBr,
  disabledDateAfterToday,
  isNullOrUndefined,
} from '../../utils/utils';
import { BaseActionModalProps } from './staymentActions';

type ManoeuvreModalProps = BaseActionModalProps & {
  onManoeuvre: () => void;
};
export function ManoeuvreModal(props: ManoeuvreModalProps) {
  const {
    setIsVisible,
    onManoeuvre,
    stopover,
    currentDocking,
    updateDocking,
    isLoadingUpdateDocking,
  } = props;
  const [vessel] = useState<Vessel | undefined>(stopover?.vessel);
  const [form] = Form.useForm();

  const { data: berthData } = useGetBerthsQuery({ shipType: '' });

  const [checkBerthAvailable] = useCheckBerthAvailableMutation();
  const [checkInitialBerthAvailable, { data: intitialBerthAvailable }] =
    useCheckBerthAvailableMutation();

  const [patchStopover, { isLoading: isLoadingPatchStopover }] =
    usePatchStopoverMutation();

  // function validate(createdAt: string, pilotOnBoard: Moment) {
  //   const createdAtMoment = moment(createdAt);

  //   if (pilotOnBoard < createdAtMoment) {
  //     message.error(
  //       `A data de prático a bordo não pode ser menor que a data de criação da atracação(${createDateStringPtBr(
  //         createdAtMoment
  //       )})`
  //     );
  //     return false;
  //   }
  //   return true;
  // }

  function handleForm() {
    form.validateFields().then(async (values) => {
      // if (
      //   !validate(
      //     currentDocking?.created_at || '',
      //     values.docking_pilot_on_board
      //   )
      // )
      //   return;

      if (updateDocking) {
        updateDocking(
          {
            docking_place: values.docking_place,
            docking_pilot_on_board: createDateString(
              values.docking_pilot_on_board
            ),
            id: currentDocking?.id,
            notInvalidateTags: true,
          },
          onManoeuvre
        ).then((response) => {
          if ('data' in response) {
            registerArrival();
          }
        });
      }
    });
  }

  async function registerArrival() {
    if (!stopover?.actual_time_of_arrival) {
      const actualTimeOfArrival = form.getFieldValue('actual_time_of_arrival');
      const stopoverToUpdate: Stopover = {
        ...stopover,
        ...formatStopoverToSave(stopover),
        actual_time_of_arrival: actualTimeOfArrival
          ? moment(actualTimeOfArrival).format('YYYY-MM-DD HH:mm')
          : null,
        status: stopover?.current_status,
      };
      patchStopover(stopoverToUpdate);
    }
  }

  useEffect(() => {
    if (currentDocking) {
      form.setFieldsValue({
        docking_pilot_on_board:
          currentDocking?.docking_manoeuvre?.pilot_on_board,
        docking_place: currentDocking?.docking_place?.tag,
      });
      if (currentDocking.docking_place && currentDocking.expected_berthing) {
        checkInitialBerthAvailable({
          tag: form.getFieldValue('docking_place'),
          date: createDateString(currentDocking.expected_berthing) || undefined,
          id: currentDocking.id,
        });
      }
    }
  }, [currentDocking]);

  async function validateBerth() {
    if (currentDocking && currentDocking.expected_berthing) {
      const dockingsInBerth = await checkBerthAvailable({
        tag: form.getFieldValue('docking_place'),
        date: createDateString(currentDocking.expected_berthing) || undefined,
        id: currentDocking.id,
      });
      if ('data' in dockingsInBerth) {
        if (dockingsInBerth.data?.length > 0) {
          return Promise.reject(new Error('Berço ocupado'));
        }
      }
    }
    return Promise.resolve();
  }

  return (
    <>
      A embarcação <b>{vessel?.name}</b>{' '}
      {currentDocking?.docking_manoeuvre?.origin &&
        `está no ${currentDocking?.docking_manoeuvre?.origin.name}`}{' '}
      {currentDocking?.docking_manoeuvre?.destiny &&
        `com previsão de destino ao ${currentDocking?.docking_manoeuvre?.destiny?.name}`}
      {intitialBerthAvailable && intitialBerthAvailable.length > 0
        ? `, porém este berço está indisponível. Selecione um novo destino para continuar.`
        : `. Deseja continuar?`}
      <Form
        form={form}
        name="form_manoeuvre"
        id="form_manoeuvre"
        layout="vertical"
        onFinish={handleForm}
      >
        <Row gutter={32} style={{ marginTop: '24px' }}>
          {!stopover?.actual_time_of_arrival && (
            <FormItemDatePicker
              name="actual_time_of_arrival"
              label="Hora real de chegada"
              disabledDate={disabledDateAfterToday}
              colSpan={12}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
              required
            />
          )}
          <FormItemDatePicker
            name="docking_pilot_on_board"
            label="Prático a bordo em"
            disabledDate={disabledDateAfterToday}
            colSpan={12}
            rules={[
              {
                required: true,
                message: 'Por favor, preencha a data e hora do prático a bordo',
              },
            ]}
          />
          <FormItemSelect
            name="docking_place"
            label="Berço ou local de ancoragem de destino"
            colSpan={12}
            required
            dataList={berthData?.results}
            allowClear={isNullOrUndefined(
              currentDocking?.docking_manoeuvre?.pilot_on_board
            )}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
              },
              {
                validator: () => validateBerth(),
                message:
                  'Berço indisponível para a data de atracação prevista.',
                required: true,
              },
            ]}
          />
        </Row>
        <Alert
          type="info"
          message="Ao registrar a saída do fundeio o status da embarcação será alterado
          para “Atracação”."
        />
        {!stopover?.actual_time_of_arrival && (
          <Alert
            style={{ marginTop: '10px' }}
            type="warning"
            message="Preencher o campo obrigatório “Hora real de chegada”"
          />
        )}
        <Space
          size={12}
          style={{
            justifyContent: 'flex-end',
            display: 'flex',
            marginTop: '20px',
          }}
        >
          <Button type="text" onClick={() => setIsVisible(false)}>
            Cancelar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            form="form_manoeuvre"
            key="form_manoeuvre"
            loading={isLoadingUpdateDocking || isLoadingPatchStopover}
          >
            Manobrar
          </Button>
        </Space>
      </Form>
    </>
  );
}
