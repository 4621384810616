import { Divider } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import {
  OperationCalculusType,
  OperationContextType,
  OperationProductAndCraneType,
  OperationProductCalculusType,
  OperationProductTypeDescription,
  OperationalOperationType,
  Stopover as StopoverType,
} from '../../../types';
import {
  formatOperationCraneToDescription,
  formatOperationProductToDescription,
} from '../../operation/OperationsData/formatOperationToDescription';
import { ProductOperationDescription } from '../../operation/OperationsData/operationDescriptionsSections';
import { ProductResumeDescription } from '../../operation/OperationsData/operationDescriptionsSections/productResumeDescription';

type GeneralCargoOperationProductsDescriptionProps = {
  operationProduct?: OperationProductAndCraneType;
  operationType: OperationContextType;
  operation: OperationalOperationType;
  selectedStopover: StopoverType;
  setSelectedStopover: Dispatch<SetStateAction<StopoverType>>;
  operationProductCalculusData: OperationProductCalculusType;
  operationCalculusData: OperationCalculusType;
  isProvisioned?: boolean;
};

export function GeneralCargoOperationProductsDescription(
  props: GeneralCargoOperationProductsDescriptionProps
) {
  const {
    operationProduct,
    operationType,
    operation,
    selectedStopover,
    setSelectedStopover,
    operationProductCalculusData,
    operationCalculusData,
    isProvisioned,
  } = props;
  const isContainers = operationType === 'CONTAINER';
  const isVehicles = operationType === 'VEHICLES';
  const isSolidBulk = operationType === 'SOLID_BULK';

  const productDescription =
    !isContainers && operationProduct
      ? formatOperationProductToDescription(
          operationProduct,
          operationType,
          operation
        )
      : operationProduct && formatOperationCraneToDescription(operationProduct);

  return (
    <>
      <ProductResumeDescription
        operationProduct={productDescription as OperationProductTypeDescription}
        operationProductCalculusData={operationProductCalculusData}
        operationCalculusData={operationCalculusData}
        isProvisioned={isProvisioned}
      />
      <Divider />
      {!isProvisioned && (
        <ProductOperationDescription
          operationProduct={
            productDescription as OperationProductTypeDescription
          }
          selectedOperationProduct={operationProduct}
          isVehicles={isVehicles}
          isSolidBulk={isSolidBulk}
          unity={isVehicles ? 'un' : 'ton'}
          selectedStopover={selectedStopover}
          setSelectedStopover={setSelectedStopover}
        />
      )}
    </>
  );
}
