import {
  GenericQueryParamsType,
  GenericResponse,
  PortType,
  Vessel,
} from '../types';
import { getHeaders, tosApi } from './tosApi';

type VesselsQueryParamsType = GenericQueryParamsType & {
  imo_or_name?: string;
  ship_type?: string;
  tugboat_company?: number;
  active_stopover?: boolean;
};

export const vesselApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getVessels: build.query<
      GenericResponse & { results: Array<Vessel> },
      VesselsQueryParamsType | void
    >({
      query: (params: GenericQueryParamsType) => {
        return {
          url: `/maritime/vessels`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      providesTags: () => [{ type: 'Vessel', code: '2' }],
    }),
    getVessel: build.query<{ results: Array<Vessel> }, { name?: string }>({
      query: (arg) => {
        const { name } = arg;
        return {
          url: '/maritime/vessels',
          method: 'GET',
          headers: getHeaders,
          params: { imo_or_name: name },
        };
      },
      transformResponse: (response: { results: Array<Vessel> }) => {
        response.results.forEach((vessel: any) => {
          vessel.key = vessel.imo;
        });
        return { results: response.results };
      },
    }),
    createVessel: build.mutation<Vessel, Vessel>({
      query: (vessel) => {
        return {
          url: `/maritime/vessels`,
          method: 'POST',
          body: vessel,
        };
      },
      invalidatesTags: () => [{ type: 'Vessel', code: '2' }],
    }),
    updateVessel: build.mutation<Vessel, Vessel>({
      query: (vessel) => {
        return {
          url: `/maritime/vessels/${vessel.id}`,
          method: 'PUT',
          body: vessel,
        };
      },
      invalidatesTags: () => [{ type: 'Vessel', code: '2' }],
    }),
    patchVessel: build.mutation<Vessel, Vessel>({
      query: (vessel) => {
        return {
          url: `/maritime/vessels/${vessel.id}`,
          method: 'PATCH',
          body: vessel,
        };
      },
      invalidatesTags: () => [{ type: 'Vessel', code: '2' }],
    }),
    deleteVessel: build.mutation<Vessel, number>({
      query: (vesselId) => {
        return {
          url: `/maritime/vessels/${vesselId}`,
          method: 'DELETE',
        };
      },
    }),
    getPorts: build.query<
      { results: Array<any> },
      { countryCode?: string; name?: string; biTrigram?: string }
    >({
      query: (arg) => {
        const { name, countryCode, biTrigram } = arg;
        return {
          url: `/maritime/ports`,
          method: 'GET',
          headers: getHeaders,
          params: { country_code: countryCode, name, bi_trigram: biTrigram },
        };
      },
      transformResponse: (response: { results: Array<any> }) => {
        response.results.forEach((port: PortType) => {
          port.key = port.bi_trigram;
          port.id = port.bi_trigram;
        });
        return { results: response.results };
      },
    }),
    getPort: build.query<PortType, string>({
      query: (biTrigram) => {
        return {
          url: `/maritime/ports/${biTrigram}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    getPortsCountries: build.query<
      { results: Array<any> },
      { countryName?: string; countryCode?: string }
    >({
      query: (arg) => {
        const { countryName, countryCode } = arg;
        return {
          url: `/maritime/ports/countries`,
          method: 'GET',
          headers: getHeaders,
          params: { country_name: countryName, country_code: countryCode },
        };
      },
      transformResponse: (response: { results: Array<any> }) => {
        response.results.forEach((port: any) => {
          port.key = port.country;
          port.id = port.country_code;
          port.name = port.country_name;
        });
        return { results: response.results };
      },
    }),
    getTugboats: build.query<
      GenericResponse & { results: Vessel[] },
      GenericQueryParamsType & { imo_or_name?: string }
    >({
      query: (params) => {
        return {
          url: `/maritime/vessels?ship_type=TUGBOAT`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
    }),
    getPortTugboats: build.query<
      GenericResponse & { results: Vessel[] },
      GenericQueryParamsType & { imo_or_name?: string }
    >({
      query: (params) => {
        return {
          url: `/maritime/vessels?ship_type=PORT_TUGBOAT`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetVesselsQuery,
  useLazyGetVesselsQuery,
  useGetVesselQuery,
  useCreateVesselMutation,
  useUpdateVesselMutation,
  useDeleteVesselMutation,
  useGetPortsQuery,
  useLazyGetPortQuery,
  useGetPortsCountriesQuery,
  useGetTugboatsQuery,
  useGetPortTugboatsQuery,
} = vesselApi;
