import { message, Row } from 'antd';
import { useEffect } from 'react';

import {
  Form,
  FormItem,
  FormItemInput,
  FormItemSelect,
} from '../../../components';
import { useGetCompaniesQuery } from '../../../services/companyApi';
import {
  useCreatePilotMutation,
  useDeletePilotMutation,
  useUpdatePilotMutation,
} from '../../../services/pilotageApi';
import { PilotType } from '../../../types';
import { formatPilotToSave } from '../../../utils/formatters';
import { pilotFunctionList } from '../../../utils/lists';
import { isNullOrUndefined } from '../../../utils/utils';
import { FormItemsWrapper } from '../company/companyForm';
import { RegisterHeaderForm } from '../registerHeaderForm';

type PilotFormProps = {
  name: string;
  onClose: () => void;
  selectedPilot: any;
  onSavePilot: () => void;
  setQueryPage: (page: number) => void;
};
export function PilotForm(props: PilotFormProps) {
  const { name, onClose, selectedPilot, onSavePilot, setQueryPage } = props;
  const [
    createPilot,
    { isSuccess: isSuccessSavePilot, isError: isErrorSavePilot },
  ] = useCreatePilotMutation();
  const [
    updatePilot,
    { isSuccess: isSuccessUpdatePilot, isError: isErrorUpdatePilot },
  ] = useUpdatePilotMutation();
  const [
    deletePilot,
    { isSuccess: isSuccessDeletePilot, isError: isErrorDeletePilot },
  ] = useDeletePilotMutation();
  const { data: companiesData } = useGetCompaniesQuery({
    company_type: 'PILOTAGE',
    page_size: 100,
  });

  function validatePilot(pilot: PilotType): boolean {
    if (isNullOrUndefined(pilot.name)) {
      message.error('É necessario informar pelo menos o nome.');
      return false;
    }
    return true;
  }

  async function handleFormSubmit(values: any) {
    if (!validatePilot(values)) return;
    let savedPilot;
    if (selectedPilot) {
      savedPilot = await updatePilot(formatPilotToSave(values));
    } else {
      savedPilot = await createPilot(formatPilotToSave(values));
    }
    if ('data' in savedPilot) {
      onSavePilot();
    }
  }

  async function onDeletePilot() {
    if (selectedPilot && selectedPilot.id) {
      setQueryPage(1);
      await deletePilot(selectedPilot.id);
    }
  }
  useEffect(() => {
    if (isSuccessSavePilot || isSuccessUpdatePilot) {
      message.success('Prático salvo');
      onClose();
    } else if (isErrorSavePilot || isErrorUpdatePilot) {
      message.error(
        'Ocorreu um erro ao salvar ao Prático, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [
    isSuccessSavePilot,
    isErrorSavePilot,
    isSuccessUpdatePilot,
    isErrorUpdatePilot,
  ]);

  useEffect(() => {
    if (isSuccessDeletePilot) {
      message.success('Prático excluído');
      onClose();
      onSavePilot();
    } else if (isErrorDeletePilot) {
      message.error(
        'Ocorreu um erro ao excluir o prático, se o erro persistir entre em contato com o suporte'
      );
    }
  }, [isSuccessDeletePilot, isErrorDeletePilot]);
  return (
    <Form
      name={name}
      layout="vertical"
      onFinish={handleFormSubmit}
      initialValues={selectedPilot || {}}
    >
      <RegisterHeaderForm
        onClose={onClose}
        formName={name}
        allowDelete={!!selectedPilot}
        onDelete={onDeletePilot}
      >
        {selectedPilot ? (
          <div className="title">{selectedPilot.name}</div>
        ) : (
          <div className="title">NOVO PRÁTICO</div>
        )}
      </RegisterHeaderForm>
      <FormItemsWrapper>
        <Row>
          <FormItem noStyle name="id" />
          <FormItemInput
            label="Nome"
            name="name"
            required
            placeholder="Nome do prático"
          />
        </Row>
        <Row gutter={16}>
          <FormItemSelect
            colSpan={12}
            label="Cargo"
            name="function"
            dataList={pilotFunctionList
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
          <FormItemSelect
            colSpan={12}
            name={['company', 'id']}
            label="Empresa"
            debounceDelay={300}
            dataList={companiesData?.results
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))}
          />
        </Row>
      </FormItemsWrapper>
    </Form>
  );
}
