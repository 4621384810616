import { TabPane, Tabs, ScreenLayout } from '../../components';
import { Berth } from './berth/Berth';
import { Cargo } from './cargo/Cargo';
import { Company } from './company/Company';
import { Pilot } from './pilotage/Pilot';
import { Tugboat } from './tugboat/Tugboat';
import { Vessel } from './vessel/Vessel';

export function Registers() {
  const tabs = [
    { name: 'Mercadorias', key: 'CARGO', table: Cargo },
    { name: 'Empresas', key: 'COMPANY', table: Company },
    { name: 'Embarcações', key: 'VESSEL', table: Vessel },
    { name: 'Rebocadores Portuários', key: 'TUGBOAT', table: Tugboat },
    { name: 'Práticos', key: 'PILOT', table: Pilot },
    { name: 'Berços', key: 'BERTH', table: Berth },
  ];
  return (
    <ScreenLayout title="Cadastros">
      <Tabs defaultActiveKey="CARGO" size="large">
        {tabs.map((tab) => {
          return (
            <TabPane tab={tab.name} key={tab.key}>
              <tab.table />
            </TabPane>
          );
        })}
      </Tabs>
    </ScreenLayout>
  );
}
