import { MaskedInputProps } from '../../types';
import { MaskedInput } from './Input';

type InputCNPJProps = MaskedInputProps & {
  readOnly?: boolean;
};

export function InputCNPJ(props: InputCNPJProps) {
  return (
    <MaskedInput
      {...props}
      mask="00.000.000/0000-00"
      readOnly={props.readOnly}
    />
  );
}
