import {
  CompanyRoleType,
  CompanyType,
  GenericQueryParamsType,
  GenericResponse,
} from '../types';
import { formatCompanyToForm } from '../utils/formatters';
import { getHeaders, tosApi } from './tosApi';

export type CompanyResponse = GenericResponse & {
  results: Array<CompanyType> | [];
};

type CompanyQueryParamsType = GenericQueryParamsType & {
  company_type?: string;
  name_or_cnpj?: string;
  companies_ids?: number[];
};

type CompanyRolesResponse = GenericResponse & {
  results: CompanyRoleType[] | [];
};
export const companyApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<CompanyResponse, CompanyQueryParamsType | void>({
      query: (params: GenericQueryParamsType) => {
        return {
          url: `/core/companies`,
          method: 'GET',
          headers: getHeaders,
          params,
        };
      },
      transformResponse: (response: CompanyResponse) => {
        const transformResults = response.results.map((company) =>
          formatCompanyToForm(company)
        );
        response.results = transformResults;
        return response;
      },
      providesTags: () => [{ type: 'Company', code: '2' }],
    }),
    getCompany: build.query<CompanyType, { tag: string }>({
      query: (arg) => {
        const { tag } = arg;
        return {
          url: `/core/companies/${tag}`,
          method: 'GET',
          headers: getHeaders,
        };
      },
    }),
    createCompany: build.mutation<CompanyType, CompanyType>({
      query: (company) => {
        return {
          url: `/core/companies`,
          method: 'POST',
          body: company,
        };
      },
      transformResponse: (company: CompanyType) => {
        return formatCompanyToForm(company);
      },
      invalidatesTags: () => [{ type: 'Company', code: '2' }],
    }),
    updateCompany: build.mutation<
      CompanyType,
      Partial<CompanyType> & Pick<CompanyType, 'id'>
    >({
      query: ({ id, ...company }) => {
        return {
          url: `/core/companies/${id}`,
          method: 'PUT',
          body: company,
        };
      },
      invalidatesTags: () => [{ type: 'Company', code: '2' }],
    }),
    deleteCompany: build.mutation<CompanyType, number>({
      query: (companyId) => {
        return {
          url: `/core/companies/${companyId}`,
          method: 'DELETE',
        };
      },
    }),
    getCompanyRoles: build.query<
      CompanyRolesResponse,
      CompanyQueryParamsType | void
    >({
      query: (params: CompanyQueryParamsType) => {
        return {
          url: `/core/company-roles`,
          method: 'GET',
          params,
        };
      },
    }),
    filterCompanies: build.query<CompanyType[], string[]>({
      query: (types: string[]) => {
        let baseUrl: string;
        if (types.length > 0) {
          baseUrl = '/core/companies?';
        } else {
          baseUrl = '/core/companies';
        }
        types.forEach((type) => {
          baseUrl += `company_type=${type}&`;
        });
        return {
          url: baseUrl,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompanyRolesQuery,
  useFilterCompaniesQuery,
} = companyApi;
