import { CSSProperties, useEffect, useState } from 'react';

import { StatusTag } from '../../components';
import { operationStatusList } from '../../utils/lists';

type OperationStatusTagProps = {
  operationStatus?:
    | 'WAITING_OPERATION'
    | 'IN_OPERATION'
    | 'OPERATION_FINISHED'
    | 'PENDING'
    | 'CANCELED';
  style?: CSSProperties;
};

export function OperationStatusTag(props: OperationStatusTagProps) {
  const { operationStatus, style } = props;

  const [label, setLabel] = useState('');
  const [className, setClassName] = useState('');

  useEffect(() => {
    const operationStatusSelected = operationStatusList.find(
      (operation) => operation.status === operationStatus
    );
    if (operationStatusSelected) {
      setLabel(operationStatusSelected?.label);
      setClassName(operationStatusSelected?.className);
    }
  }, [operationStatus]);

  return (
    <StatusTag style={style}>
      <span className={className}>{label}</span>
    </StatusTag>
  );
}
