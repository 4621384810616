import { WorkPeriodType } from '../types';
import { tosApi } from './tosApi';
import { getInvalidatesTags } from './utils';

export const workPeriodApi = tosApi.injectEndpoints({
  endpoints: (build) => ({
    updateWorkPeriod: build.mutation<WorkPeriodType, WorkPeriodType>({
      query: (workPeriod) => {
        return {
          url: `/operational/work-periods/${workPeriod.id}`,
          method: 'PUT',
          body: workPeriod,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: result?.operation_id },
        ]),
    }),
    createWorkPeriod: build.mutation<WorkPeriodType, WorkPeriodType>({
      query: (workPeriod) => {
        return {
          url: `/operational/work-periods`,
          method: 'POST',
          body: workPeriod,
        };
      },
      invalidatesTags: (result, error) =>
        getInvalidatesTags(error, [
          { type: 'Stopover', code: result?.operation_id },
        ]),
    }),
    deleteWorkPeriod: build.mutation<WorkPeriodType, number>({
      query: (workPeriodId) => {
        return {
          url: `/operational/work-periods/${workPeriodId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useUpdateWorkPeriodMutation,
  useCreateWorkPeriodMutation,
  useDeleteWorkPeriodMutation,
} = workPeriodApi;
