import { useEffect, useState } from 'react';
import Flag from 'react-flagpack';

import {
  Collapse,
  Descriptions,
  ErrorBoundary,
  FormatItem,
} from '../../../../components';
import { panelHeaderTitle } from '../../../../components/common/utils';
import { Vessel as VesselType, VesselTypeDescription } from '../../../../types';
import { formatVesselToDescription } from '../../../../utils/formatters';
import { translateCountryCode } from '../../../../utils/translateCountryCode';
import { DescriptionVessel } from './styles';

const { Panel } = Collapse;
const { Item } = Descriptions;

type VesselDescriptionProps = {
  selectedVessel: VesselType;
  activeKey: string | string[];
  onOpenPanel: (panel: string | string[]) => void;
  updatedAt?: string;
  onEdit: (evt: any) => void;
  screen: 'AGENT_AREA' | 'STOPOVER';
};
export function VesselDescription(props: VesselDescriptionProps) {
  const { selectedVessel, updatedAt, activeKey, onOpenPanel, onEdit, screen } =
    props;

  const [vesselDescription, setVesselDescription] =
    useState<VesselTypeDescription>({} as VesselTypeDescription);

  useEffect(() => {
    setVesselDescription(formatVesselToDescription(selectedVessel));
  }, [selectedVessel]);

  function vesselData(): JSX.Element {
    return (
      <DescriptionVessel>
        {screen === 'AGENT_AREA' && (
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '4px', display: 'inline-block' }}>
              {vesselDescription.flag && (
                <ErrorBoundary replace="">
                  <Flag code={vesselDescription.flag} size="s" />
                </ErrorBoundary>
              )}
            </div>
            <h1>{vesselDescription.name}</h1>
          </div>
        )}
        <Descriptions column={2}>
          <Item label="Tipo de embarcação">
            <FormatItem>{vesselDescription.ship_type}</FormatItem>
          </Item>
          <Item label="Bandeira">
            <FormatItem>
              <div style={{ marginRight: '4px', display: 'inline-block' }}>
                {vesselDescription.flag && (
                  <ErrorBoundary replace="">
                    <Flag code={vesselDescription.flag} size="s" />
                  </ErrorBoundary>
                )}
              </div>
              {translateCountryCode(vesselDescription.flag || '')}
            </FormatItem>
          </Item>
          <Item label="Armador proprietário">
            <FormatItem>{vesselDescription.shipowner}</FormatItem>
          </Item>
          <Item label="Calado máximo">
            <FormatItem>{vesselDescription.draught_max}</FormatItem>
          </Item>
          <Item label="MMSI">
            <FormatItem>{vesselDescription.mmsi}</FormatItem>
          </Item>
          <Item label="Calado mínimo">
            <FormatItem>{vesselDescription.draught_min}</FormatItem>
          </Item>
          <Item label="Inscrição da capitania">
            <FormatItem>{vesselDescription.captaincy_registration}</FormatItem>
          </Item>
          <Item label="LOA comprimento">
            <FormatItem>{vesselDescription.loa}</FormatItem>
          </Item>
          <Item label="Avante à meia-nau?">
            <FormatItem>{vesselDescription.forward_amidship}</FormatItem>
          </Item>
          <Item label="Boca - Largura">
            <FormatItem>{vesselDescription.width}</FormatItem>
          </Item>
          <Item label="Tipo de cabo amarração">
            <FormatItem>{vesselDescription.dock_line_type}</FormatItem>
          </Item>
          <Item label="DWT Porte">
            <FormatItem>{vesselDescription.dwt}</FormatItem>
          </Item>
        </Descriptions>
      </DescriptionVessel>
    );
  }

  return screen === 'AGENT_AREA' ? (
    vesselData()
  ) : (
    <Collapse
      activeKey={activeKey}
      onChange={onOpenPanel}
      expandIconPosition="end"
    >
      <Panel
        forceRender
        header="Dados da embarcação"
        key="vessel"
        extra={panelHeaderTitle(updatedAt, onEdit)}
      >
        <Descriptions column={2} layout="vertical">
          <Item label="Embarcação">
            <FormatItem style={{ fontWeight: 600, fontSize: '14px' }}>
              {vesselDescription.name}
            </FormatItem>
          </Item>
          <Item>
            {/* <Button onClick={() => setShowVesselNamesHistoryModal(true)}>
              Ver histórico de nomes
            </Button> */}
          </Item>
        </Descriptions>
        {vesselData()}
      </Panel>
    </Collapse>
  );
}
