import { FormInstance } from 'antd';

import { Drawer } from '../../../../../components';
import { DockingPlace, DockingPlaceForm } from '../../../../../types';
import { BerthLineForm } from './berthLineForm';

type BerthLineDrawerProps = {
  selectedBerth: DockingPlace;
  visibleDrawer: boolean;
  selectedLine: any;
  onBack: () => void;
  berthLineRowIndex: number;
  form: FormInstance<DockingPlaceForm>;
  isEdit: boolean;
  addBerthLineToForm: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
  removeBerthLineFromForm: (
    defaultValue?: any,
    insertIndex?: number | undefined
  ) => void;
};
export function BerthLineDrawer(props: BerthLineDrawerProps) {
  const {
    selectedBerth,
    visibleDrawer,
    selectedLine,
    onBack,
    berthLineRowIndex,
    form,
    isEdit,
    addBerthLineToForm,
    removeBerthLineFromForm,
  } = props;

  return (
    <Drawer
      visible={visibleDrawer}
      placement="right"
      width={720}
      closable={false}
      destroyOnClose
      onClose={() => onBack()}
    >
      <BerthLineForm
        selectedBerth={selectedBerth}
        selectedLine={selectedLine}
        berthLineRowIndex={berthLineRowIndex}
        onBack={onBack}
        berthForm={form}
        isEdit={isEdit}
        addBerthLineToForm={addBerthLineToForm}
        removeBerthLineFromForm={removeBerthLineFromForm}
      />
    </Drawer>
  );
}
