import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import Flag from 'react-flagpack';
import styled from 'styled-components';

import { ErrorBoundary, FormatItem } from '../../../components';
import { createDateStringPtBr } from '../../../utils/utils';

const VesselInfo = styled.div`
  display: flex;
  align-items: center;

  & strong {
    margin-left: 5px;
  }
`;

const NoValueInformed = styled.div`
  font-style: italic;
  color: var(--neutral_medium);
`;

export function operationsTableColumns(
  onSelectItem: (item: any) => void
): ColumnsType<Record<string, any>> {
  const columns: ColumnsType<Record<string, any>> = [
    {
      title: 'Atracação',
      key: 'docking_code',
      dataIndex: 'docking_code',
      align: 'left' as const,
      width: '10%',
    },
    {
      title: 'Embarcação',
      align: 'left' as const,
      width: '15%',
      render: (record: any) => {
        return (
          <VesselInfo style={{ display: 'flex', alignItems: 'center' }}>
            {record.vessel_flag && (
              <ErrorBoundary replace="">
                <Flag code={record.vessel_flag} hasBorder={false} size="m" />
              </ErrorBoundary>
            )}{' '}
            <strong>{record.vessel_name}</strong>
          </VesselInfo>
        );
      },
    },
    {
      title: 'Período de estadia',
      align: 'left' as const,
      width: '15%',
      render: (stopover: any) => {
        return !stopover.actual_time_of_arrival ||
          !stopover.port_stay_finished ? (
          <NoValueInformed>Não informado</NoValueInformed>
        ) : (
          `${createDateStringPtBr(
            stopover.actual_time_of_arrival
          )} até ${createDateStringPtBr(stopover.port_stay_finished)}`
        );
      },
    },
    {
      title: 'Berço',
      key: 'docking_place',
      dataIndex: 'docking_place',
      align: 'left' as const,
      width: '10%',
    },
    {
      title: 'Período da operação',
      align: 'left' as const,
      width: '15%',
      render: (record: any) => {
        if (!record.real_operation_start && !record.real_operation_finish) {
          return <NoValueInformed>Não informado</NoValueInformed>;
        }
        return (
          <span>
            {record.real_operation_start ? (
              createDateStringPtBr(record.real_operation_start)
            ) : (
              <NoValueInformed>Não informado</NoValueInformed>
            )}{' '}
            até{' '}
            {record.real_operation_finish ? (
              createDateStringPtBr(record.real_operation_finish)
            ) : (
              <NoValueInformed>Não informado</NoValueInformed>
            )}
          </span>
        );
      },
    },
    {
      title: 'Grupo de produto',
      align: 'left' as const,
      width: '15%',
      key: 'product_group',
      dataIndex: 'product_group',
      render: (value: string) => <FormatItem>{value}</FormatItem>,
    },
    {
      title: 'Operador',
      align: 'left' as const,
      width: '15%',
      key: 'operator',
      dataIndex: 'operator',
    },
    {
      width: '5%',
      render: (_: any, record: any) => (
        <Button
          type="primary"
          ghost
          icon={<EyeOutlined />}
          style={{ float: 'right' }}
          onClick={() => onSelectItem(record)}
        />
      ),
    },
  ];

  return columns;
}
